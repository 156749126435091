import React from 'react';
import { LoadingButton } from '@atlaskit/button';
import iUser from '../../types/user/iUser';
import CompanyExceptionEmptyState from './CompanyExceptionEmptyState';

// eslint-disable-next-line
const CompanyExpired = ({ user }: { user: iUser }) => {
  const getPrimaryAction = () => (
    <LoadingButton
      appearance="primary"
      // eslint-disable-next-line
      onClick={() => {}}
    >
      Re-Charge
    </LoadingButton>
  );

  const props = {
    header: 'Unfortunately, it reaches the end of your trial time ',
    description:
      'Prior to continue to surf the MIESoft, please re-charge then come back; If you did,contact MIESoft support to see the issue. ',
    primaryAction: getPrimaryAction(),
  };
  // eslint-disable-next-line
  return <CompanyExceptionEmptyState {...props} />;
};

export default CompanyExpired;
