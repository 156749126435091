import React from 'react';
import { LoadingButton } from '@atlaskit/button';
import styled from 'styled-components';
// import EditorMoreIcon from '@atlaskit/icon/glyph/editor/more';

const LoadMoreContainer = styled.div`
  max-width: 200px;
  margin: 10px 10px 0px;
  box-sizing: border-box;
`;
const LoadMore = ({
  isLoading,
  onClick,
}: {
  isLoading: boolean;
  onClick: () => void;
}) => {
  return (
    <LoadMoreContainer className={'load-more'}>
      <LoadingButton
        appearance={'subtle'}
        isLoading={isLoading}
        onClick={onClick}
        shouldFitContainer
        testId={'view-more-button'}
      >
        View More
      </LoadingButton>
    </LoadMoreContainer>
  );
};

export default LoadMore;
