import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { handleNullException } from '../../../services/UtilsService';
import {
  getWarehouseListAsyncSearch,
  isWIPWarehouseLocation,
} from '../../../services/WarehouseService';
import AsyncSearchWithController from '../../../shared/form/hookForms/AsyncSearchWithController';
import { SpaceVerticalContainer } from '../../../shared/styles/styles';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { FixHeightWrapper } from '../sharedComponents/StockTransfer.style';

const MoveProdModalBody = ({
  control,
  onChange,
  errors,
  children,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  children: React.ReactNode;
}) => {
  //  eslint-disable-next-line
  const isWIPValidate = async (newValue: any) => {
    const id = handleNullException(newValue, 'id');
    if (!id) return 'must select an element/bad element id';
    try {
      const { isWIP }: { isWIP: boolean } = await isWIPWarehouseLocation(id);
      if (isWIP) return 'this warehouse location is WIP, can not transfer to';
      return true;
    } catch (error) {
      apiErrorToast(error);
      return 'Unknown network happens, please try again';
    }
  };
  return (
    <div>
      <AsyncSearchWithController
        label={'Destination warehouse location'}
        name={'destination'}
        control={control}
        onChange={onChange}
        errors={errors}
        promiseFn={(keyword: string) =>
          getWarehouseListAsyncSearch({
            like: `name:${keyword},locationCode:${keyword}`,
            filter: 'allowParts:true',
          })
        }
        optionLabel={['name', 'locationCode']}
        isRequired
        externalValidate={isWIPValidate}
      />
      <SpaceVerticalContainer />
      <FixHeightWrapper>{children}</FixHeightWrapper>
    </div>
  );
};

export default MoveProdModalBody;
