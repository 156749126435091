import React from 'react';
import TypeFilter from './FilterByType';

interface iSalesFilterProps {
  onSelectType: (type: string) => void;
  selectedTypes: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
}

const SalesFilter = ({
  onSelectType,
  selectedTypes,
  options,
}: iSalesFilterProps) => {
  return (
    <TypeFilter
      options={options}
      onSelectType={onSelectType}
      selectedTypes={selectedTypes}
      pageName="sales"
    />
  );
};

export default SalesFilter;
