import React from 'react';
import { useForm } from 'react-hook-form';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import PlusIconAfterTitle from '../../../components/settings/PlusIconAfterTitle';
import BackendPage from '../../../layout/BackendPage';

import {
  createCourier,
  deactivateCourier,
  getCouriers,
  updateCourier,
} from '../../../services/Settings/CourierService';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import iCourier from '../../../types/courier/iCourier';
import Sidebar from '../sidebar/Sidebar';
import CourierEditModal from './CourierEditModal';
import CourierListTable from './CourierListTable';

const CourierSetting = () => {
  const { state, edit, onCloseModal, onDelete, onOpenAddModal, onOpenEditModal, onSubmit } = useListCrudHook<iCourier>({
    getFn: getCouriers,
    createFn: createCourier,
    updateFn: updateCourier,
    deleteFn: deactivateCourier,
  });

  const { control, setValue, errors, handleSubmit, getValues } = useForm();

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'Couriers'} />
      <PlusIconAfterTitle pageTitle={'Couriers'} onClick={onOpenAddModal} />
    </>
  );
  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <CourierListTable data={state.data} onDelete={onDelete} onEdit={onOpenEditModal} isLoading={state.isLoading} />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0 || !getValues().name}
        modalBody={<CourierEditModal control={control} errors={errors} onChange={setValue} target={edit.target} />}
        modalHeading={edit.target ? edit.target.name : 'Create'}
        confirmBtnName={'Save'}
      />
    </BackendPage>
  );
};

export default CourierSetting;
