import Spinner from '@atlaskit/spinner';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import BackendPage from '../../../layout/BackendPage';
import ChannelService from '../../../services/integration/ChannelService';
import DeleteByRetype from '../../../shared/deleteWithReType/DeleteByReType';
import PopupContent from '../../../shared/popup/GeneralPopupContent';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import { FlexContainer, PageTitle } from '../../../shared/styles/styles';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { iRouteTypeId } from '../../../shared/UITypes/types';
import iChannel from '../../../types/integration/iChannel';
import Sidebar from '../sidebar/Sidebar';
import ChannelLogs from '../../../shared/channel/logs/ChannelLogs';
import { SETTINGS_INTEGRATIONS_URL } from '../../../shared/UrlMap';
import ChannelScriptHelper from '../../../shared/integrations/ChannelScriptHelper';
import iChannelScript from '../../../types/integration/iChannelScript';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';

const IntegrationHeader = ({ name, children }: { name: string; children: React.ReactNode }) => (
  <FlexContainer className={'flex-start space-between'}>
    <div>
      <BreadcrumbInHeader
        subName={name}
        extraLayer={<LinkBtnUrl url={SETTINGS_INTEGRATIONS_URL} btnName={'Integrations'} />}
      />
      <PageTitle>Integrations for {name}</PageTitle>
    </div>
    <div>{children}</div>
  </FlexContainer>
);

const ChannelDetail = () => {
  const { id } = useParams<iRouteTypeId>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [channel, setChannel] = useState<iChannel>();
  const [channelScript, setChannelScript] = useState<iChannelScript | null>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    ChannelService.getChannel(id, {
      include: 'script',
    })
      .then(resp => {
        if (isCanceled) return;
        setChannel(resp);
        setChannelScript(resp.script || null);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [id]);

  const onOpenDeleteModal = () => setIsDeleteModalOpen(true);

  const onDeleteChannel = async () => {
    try {
      await ChannelService.deactivateChannel(id);
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const handleChannelSaved = (savedChannel: iChannel) => {
    setChannel(savedChannel);
  };

  const getTabsPanel = () => {
    if (!channel || !channel.isOnBoarded) {
      return null;
    }

    return <ChannelLogs channel={channel} />;
  };

  const getEditPanel = () => {
    if (!channel || !channelScript) {
      return null;
    }

    return ChannelScriptHelper.getChannelEditPanelComponent({
      channel,
      channelScript,
      onSaved: handleChannelSaved,
    });
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (!channel) {
      return null;
    }
    return (
      <>
        {getEditPanel()}
        {getTabsPanel()}
      </>
    );
  };

  return (
    <BackendPage
      pageHeader={
        <IntegrationHeader name={channel?.name || ''}>
          <MoreIconPopup>
            <PopupContent id={id} onOpenDeleteModal={onOpenDeleteModal} />
          </MoreIconPopup>
          <DeleteByRetype
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onConfirm={async () => {
              await onDeleteChannel();
              history.push(SETTINGS_INTEGRATIONS_URL);
            }}
          />
        </IntegrationHeader>
      }
      leftMenu={<Sidebar />}
    >
      {getContent()}
    </BackendPage>
  );
};

export default ChannelDetail;
