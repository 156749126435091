import Spinner from '@atlaskit/spinner';
import React, { useState } from 'react';
import styled from 'styled-components';
import LoadingButton from '@atlaskit/button/loading-button';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import ProductImageService from '../../services/product/ProductImageService';
import iProductImage from '../../types/product/iProductImage';
import ProductImageDnDList from './ProductImageDnDList';
import EmptyState from '../EmptyState';
import Uploader from '../generalAttachments/Uploader';
import ProductImageViewer from './ProductImageViewer';

type iProductImageSlideShow = {
  className?: string;
  canCreate?: boolean;
  canDelete?: boolean;
  productId: string;
};

const Wrapper = styled.div`
  .image-viewer {
    margin-bottom: 0.4rem;
  }
`;

const ProductImageSlideShow = ({
  productId,
  className,
  canCreate = true,
  canDelete = true,
}: iProductImageSlideShow) => {
  const [selectedProductImage, setSelectedProductImage] = useState<iProductImage | undefined>(undefined);
  const { state, onDelete, onSubmit, onReorder } = useListCrudHook<iProductImage>({
    getFn: ProductImageService.getImages,
    createFn: ProductImageService.createImage,
    updateFn: ProductImageService.updateImage,
    deleteFn: ProductImageService.deleteImage,
    filter: `productId:${productId}`,
    sort: 'sortOrder:ASC',
    paginationApplied: true,
    perPage: 1000,
    keywordColumns: 'productId',
  });

  const getContent = () => {
    if (state.isLoading === true) {
      return <Spinner />;
    }

    if (state.data.length <= 0) {
      return (
        <Uploader onUploadFile={onSubmit} extraData={() => ({ productId })} acceptTypes={['image/*']} multiple>
          <EmptyState
            header={''}
            description={'No images found'}
            primaryAction={
              <LoadingButton appearance={'link'} isLoading={state.isConfirming === true}>
                <CameraFilledIcon label={'upload'} testId={'upload-trigger'} /> Upload
              </LoadingButton>
            }
          />
        </Uploader>
      );
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const selectedImage = selectedProductImage || state.data[0];
    return (
      <>
        <ProductImageViewer productImage={selectedImage} onDelete={onDelete} canDelete={canDelete} />
        <ProductImageDnDList
          productImages={state.data}
          productId={productId}
          selectedImage={selectedImage}
          isCreating={state.isConfirming === true}
          onCreate={onSubmit}
          onSelect={image => setSelectedProductImage(image)}
          onReorder={onReorder}
          canCreate={canCreate}
        />
      </>
    );
  };

  return <Wrapper className={className}>{getContent()}</Wrapper>;
};

export default ProductImageSlideShow;
