import AppService from './AppService';
import { iParams } from '../shared/UITypes/types';

export const getJobAttributes = (config?: iParams) => {
  return AppService.get('/jobAttribute', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const createJobAttribute = (postContent: iParams) => {
  return AppService.post('/jobAttribute', postContent).then(({ data }) => data);
};

export const updateJobAttribute = (id: string, updateContent: iParams) => {
  return AppService.put(`/jobAttribute/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const deleteJobAttribute = (id: string) => {
  return AppService.delete(`/jobAttribute/${id}`).then(({ data }) => data);
};

export const getJobAttributeTypes = () => {
  return AppService.get('/jobAttribute/valueType').then(({ data }) => data);
};

export default {
  getJobAttributes,
  createJobAttribute,
  updateJobAttribute,
  deleteJobAttribute,
  getJobAttributeTypes,
};
