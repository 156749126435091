import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import InlineEdit from '@atlaskit/inline-edit';
import { ErrorMessage } from '@atlaskit/form';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import Select, { ValueType } from '@atlaskit/select';
import { iLabelValuePair } from '../../../UITypes/types';
import { addsOnValue, numberRound } from '../../../../services/UtilsService';

const ReadViewContainer = styled.div`
  display: flex;
  line-height: ${(gridSize() * 2.5) / fontSize()};
  max-width: 100%;
  min-height: ${(gridSize() * 2.5) / fontSize()}em;
  padding: ${gridSize()}px 0px;
  word-break: break-word;
  color: ${props => (props.defaultValue === '' ? '#7a869a' : '#091E42')};
  font-weight: normal;
`;

type iState = {
  textValue: string;
  selectValue: string | null;
  focused: boolean;
};
const initialState: iState = {
  textValue: '',
  selectValue: '',
  focused: false,
};
const DivWrapper = styled.div`
  &.disabled {
    cursor: not-allowed;
  }
`;
export default ({
  name,
  textDefaultValue,
  selectDefaultValue,
  selectOptions,
  onConfirm,
  needFormatValue = false,
  isNumeric = true,
  isRequired = true,
  testId = 'inline-edit-job-attribute',
  isDisabled,
}: {
  name: string;
  textDefaultValue?: string;
  selectDefaultValue?: string;
  selectOptions?: Array<iLabelValuePair>;
  onConfirm?: (name: string, newValue: { textValue: string; selectValue: string | null }) => void;
  needFormatValue?: boolean;
  isNumeric?: boolean;
  isRequired?: boolean;
  testId?: string;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(s => ({
      ...s,
      textValue: textDefaultValue || initialState.textValue,
      selectValue: selectDefaultValue || initialState.selectValue,
    }));
  }, [textDefaultValue, selectDefaultValue]);

  const onSave = () => {
    // nothing is changed
    if (
      state.textValue === textDefaultValue &&
      (state.selectValue === selectDefaultValue ||
        (typeof selectDefaultValue === 'undefined' && state.selectValue === null))
    ) {
      setState({
        ...state,
        focused: false,
      });
      return;
    }
    if (typeof onConfirm === 'function') {
      onConfirm(name, {
        textValue: state.textValue.replace(',', ''),
        selectValue: state.selectValue,
      });
    }
    setState(preState => ({
      ...preState,
      focused: false,
    }));
  };
  const onTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    setState({ ...state, textValue: e.currentTarget.value });
  };
  const onSelectChange = (selected: ValueType<iLabelValuePair>) => {
    setState({
      ...state,
      selectValue: selected ? (selected as iLabelValuePair).value : null,
    });
  };
  const validate = () => {
    // escape , for number
    const escapeComma = state.textValue?.replace(',', '');
    // isRequired
    if (isRequired && !state.textValue) {
      return 'is required';
    }
    // is a number
    if (isNumeric && Number.isNaN(Number(escapeComma))) {
      return 'must be a number';
    }
    return undefined;
  };
  const getSelectValue = () => {
    const selectedOption = selectOptions?.find(
      (option: iLabelValuePair) => state.selectValue && option.value.toUpperCase() === state.selectValue.toUpperCase(),
    );
    return selectedOption || null;
  };
  return (
    <DivWrapper
      id={name}
      className={isDisabled ? 'disabled' : ''}
      tabIndex={0}
      onFocus={() => setState(preState => ({ ...preState, focused: !isDisabled }))}
    >
      <InlineEdit
        onConfirm={onSave}
        editView={({ errorMessage, ...fieldProps }) => (
          <>
            <div className={'job-attribute-wrapper'}>
              <Textfield
                //  eslint-disable-next-line
                {...fieldProps}
                autoFocus
                onFocus={e => e.target.select()}
                onChange={onTextChange}
                value={state.textValue}
                isInvalid={fieldProps.isInvalid}
                testId={`${testId}-textfield`}
              />
              <div className={'job-attribute-select-wrapper'}>
                <Select
                  options={selectOptions}
                  value={getSelectValue()}
                  placeholder={'unit'}
                  onChange={onSelectChange}
                  isClearable
                  isDisabled={isDisabled}
                />
              </div>
            </div>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
        defaultValue={{
          textValue: state.textValue,
          selectValue: state.selectValue,
        }}
        readView={() => (
          <ReadViewContainer defaultValue={state.textValue}>
            {
              //  eslint-disable-next-line
              state.textValue === ''
                ? isDisabled
                  ? 'disabled'
                  : 'Click to enter value'
                : needFormatValue
                ? addsOnValue(numberRound(state.textValue) || '', '', state.selectValue || '')
                : addsOnValue(state.textValue, '', state.selectValue || '')
            }
          </ReadViewContainer>
        )}
        isEditing={state.focused}
        onEdit={() => setState({ ...state, focused: !isDisabled })}
        onCancel={() =>
          setState({
            ...state,
            textValue: textDefaultValue || '',
            selectValue: selectDefaultValue || '',
            focused: false,
          })
        }
        validate={validate}
      />
    </DivWrapper>
  );
};
