import React, { useEffect, useState } from 'react';
import iDispatchNote from '../../types/dispatchNote/iDispatchNote';
import DispatchNoteService from '../../services/DispatchNoteService';
import { apiErrorToast } from '../toast/Toast';
import { DISPATCH_NOTE_DETAIL_URL } from '../UrlMap';

type iDispatchNoteNumberListPerJob = {
  jobId: string;
};
const DispatchNoteNumberListPerJob = ({ jobId }: iDispatchNoteNumberListPerJob) => {
  const [dns, setDns] = useState<iDispatchNote[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCanceled = false;

    setIsLoading(true);
    // eslint-disable-next-line import/no-named-as-default-member
    DispatchNoteService.getDispatchNoteList({
      advancedFilter: `DispatchNoteItem_id_dispatchNoteId.jobId:${jobId}`,
    })
      .then(resp => {
        if (isCanceled) return;
        setDns('data' in resp ? resp.data : []);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [jobId]);

  if (isLoading === true || dns.length <= 0) {
    return null;
  }

  return (
    <>
      {dns.map(dn => {
        return (
          <div key={dn.id}>
            <a href={DISPATCH_NOTE_DETAIL_URL.replace(':id', dn.id)}>{dn.dispatchNoteNumber}</a>
          </div>
        );
      })}
    </>
  );
};

export default DispatchNoteNumberListPerJob;
