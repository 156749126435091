import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import iIssue from '../../../types/NCR/iIssue';
import iProduct from '../../../types/product/iProduct';
import NCRDetActivities from './NCRDetActivities';
import InlineEdit from '../../../shared/form/InlineEdit';
import InlineEditSelect from '../../../shared/form/InlineEditSelect';
import InlineEditDatePicker from '../../../shared/form/inlineEdit/InlineEditDatePicker';
import InlineEditAsyncSelect from '../../../shared/form/inlineEdit/asyncSelect/InlineEditAsyncSelect';
import { handleNullException } from '../../../services/UtilsService';
import { getProductListAsyncSearch } from '../../../services/product/ProductService';
import { getProductOptionLabel } from './shared/NCRDetail.utils';
import { iDetailUpdateFieldAndEagerLoadFn, iDetailUpdateOneFieldFn } from '../../../shared/UITypes/types';
import { CHECK_OPTIONS, NO_OPTION, YES_OPTION } from './shared/NCRDetail.constant';
import { AttachmentsWrapper } from '../../../shared/styles/styles';
import GeneralAttachments from '../../../shared/generalAttachments/GeneralAttachments';
import TextArea from '../../../shared/form/TextArea';

const DetailLeftPanel = ({
  detail,
  onUpdate,
  onUpdateFieldAndEagerLoad,
  isDisabled = false,
}: {
  detail?: iIssue;
  onUpdate: iDetailUpdateOneFieldFn;
  onUpdateFieldAndEagerLoad: iDetailUpdateFieldAndEagerLoadFn;
  isDisabled?: boolean;
}) => {
  if (typeof detail === 'undefined') {
    return null;
  }
  return (
    <Page>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <InlineEditAsyncSelect
            name={'productId'}
            defaultValue={handleNullException(detail, 'product')}
            formatOptionLabel={getProductOptionLabel}
            promiseFn={(keyword: string) =>
              getProductListAsyncSearch({
                like: `productCode:${keyword},name:${keyword}`,
              })
            }
            label={'Reported Product'}
            onConfirm={(name: string, value: iProduct | null) => onUpdateFieldAndEagerLoad(name, value, 'product')}
            readViewField={'productCode'}
            testId={'productId'}
            isRequired
            placeholder={'search by productCode/name...'}
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={1}>
          <InlineEdit
            name={'reportedQty'}
            defaultValue={handleNullException(detail, 'reportedQty').toString()}
            label={'Reported Qty'}
            onConfirm={onUpdate}
            testId={'reportedQty'}
            postfix={handleNullException(detail, 'product.measurement.shortName')}
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={1}>
          <InlineEditSelect
            name={'isSampleReceived'}
            defaultValue={handleNullException(detail, 'isSampleReceived') ? YES_OPTION : NO_OPTION}
            label={'Sample Received'}
            selectOptions={CHECK_OPTIONS}
            onConfirm={(name: string, newValue: string | null) => onUpdate(name, newValue === YES_OPTION.value)}
            isDisabled={isDisabled}
          />
        </GridColumn>
        {handleNullException(detail, 'isSampleReceived') && (
          <GridColumn medium={1.5}>
            <InlineEditDatePicker
              label={'Sample Received Date'}
              name={'sampleReceivedDate'}
              defaultValue={detail.sampleReceivedDate}
              onConfirm={onUpdate}
              isDisabled={isDisabled}
            />
          </GridColumn>
        )}

        <GridColumn medium={handleNullException(detail, 'isSampleReceived') ? 1.5 : 3}>
          <InlineEditDatePicker
            label={'Due Date'}
            name={'dueDate'}
            defaultValue={detail.dueDate}
            onConfirm={onUpdate}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={7}>
          <TextArea
            name={'problem'}
            label={'Reported Issue'}
            defaultValue={handleNullException(detail, 'problem')}
            onChange={onUpdate}
            minimumRows={4}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>

      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={7}>
          <TextArea
            name={'cause'}
            label={'Cause/Reason'}
            defaultValue={handleNullException(detail, 'cause')}
            onChange={onUpdate}
            minimumRows={4}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>

      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={7}>
          <TextArea
            name={'conclusion'}
            label={'Conclusion'}
            defaultValue={handleNullException(detail, 'conclusion')}
            onChange={onUpdate}
            minimumRows={4}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>

      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={7}>
          <TextArea
            name={'correctiveActions'}
            label={'Corrective Actions'}
            defaultValue={handleNullException(detail, 'correctiveActions')}
            onChange={onUpdate}
            minimumRows={4}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={8}>
          <AttachmentsWrapper>
            <GeneralAttachments
              entityId={detail.id}
              entityName={'Issue'}
              canCreate={!isDisabled}
              canDelete={!isDisabled}
            />
          </AttachmentsWrapper>
        </GridColumn>
      </Grid>

      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={8}>
          <NCRDetActivities modelName={'Issue'} modelId={detail.id} isDisabled={isDisabled} />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default DetailLeftPanel;
