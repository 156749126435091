export const STOCK_FORECAST_PAGE_TITLE = 'Stock Required';
export const STOCK_FORECAST_TABLE_NAME = 'stock-forecast-table';

export const STOCK_FORECAST_TABLE_COLUMNS = [
  'Product SKU',
  'Required Qty',
  'Jobs',
];

export const JOB_STATUS_CODE_NEW = 'NEW';
export const JOB_STATUS_CODE_PLANNED = 'PLANNED';
