import React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import styled from 'styled-components';
import BasicModalBody from '../../../shared/asyncContactCompany/createModalBody/BasicModalBody';
import AsyncSearchWithController from '../../../shared/form/hookForms/AsyncSearchWithController';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import SelectWithController from '../../../shared/form/hookForms/SelectWithController';
import DateTimePickerWithController from '../../../shared/form/hookForms/DateTimePickerWithController';
import TextAreaWithController from '../../../shared/form/hookForms/TextAreaWithController';
import { getProductListAsyncSearch } from '../../../services/product/ProductService';
import { getJobListAsyncSearch } from '../../../services/JobService';
import { handleNullException, handleNullExceptionMultiFields } from '../../../services/UtilsService';

const TextAreaWrapper = styled.div`
  margin-top: 1rem;
`;

const sampleReceivedTypes = ['yes', 'no'];
const options = sampleReceivedTypes.map(type => ({
  label: type.toUpperCase(),
  value: type,
}));

const CreateNCRModalBody = ({
  control,
  errors,
  onChange,
  watch,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  watch: (names?: string | string[]) => unknown;
}) => {
  const watchIsSampleReceived = watch('isSampleReceived') === 'yes';
  const watchJobReported = watch('job');
  return (
    <BasicModalBody
      control={control}
      onChange={onChange}
      errors={errors}
      watch={watch}
      isCustomer
      verticalSpaceBeforeSelect={'two'}
    >
      <Grid spacing={'compact'}>
        <GridColumn medium={6}>
          <AsyncSearchWithController
            name={'job'}
            label={'Reported Job'}
            control={control}
            onChange={onChange}
            errors={errors}
            promiseFn={(keyword: string) =>
              getJobListAsyncSearch({
                like: `jobNumber:${keyword}`,
                sort: 'createdAt:DESC',
              })
            }
            optionLabel={['jobNumber', 'product.name']}
          />
        </GridColumn>
        <GridColumn medium={6}>
          <AsyncSearchWithController
            name={'product'}
            label={'Reported Product'}
            defaultValue={watchJobReported ? handleNullException(watchJobReported, 'product') : null}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired={!watchJobReported}
            isDisabled={!!watchJobReported}
            promiseFn={(keyword: string) =>
              getProductListAsyncSearch({
                like: `productCode:${keyword},name:${keyword}`,
              })
            }
            optionLabel={['productCode', 'name']}
          />
        </GridColumn>
      </Grid>
      <Grid spacing={'compact'}>
        <GridColumn medium={4}>
          <InputWithController
            name={'customerReference'}
            label={'Customer NCR Ref'}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired={false}
          />
        </GridColumn>
        <GridColumn medium={4}>
          <DateTimePickerWithController
            label={'Due Date'}
            name={'dueDate'}
            control={control}
            onChange={onChange}
            errors={errors}
            isDateOnly
          />
        </GridColumn>
        <GridColumn medium={4}>
          <InputWithController
            name={'customerOrderReference'}
            label={'Customer Order Ref'}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired={false}
            defaultValue={
              watchJobReported &&
              handleNullExceptionMultiFields(watchJobReported, 'customerOrderRef,salesOrder.customerRef')
            }
          />
        </GridColumn>
      </Grid>
      <Grid spacing={'compact'}>
        <GridColumn medium={watchIsSampleReceived ? 3 : 4}>
          <InputWithController
            name={'reportedQty'}
            label={'Reported Qty'}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            isNumeric
          />
        </GridColumn>
        <GridColumn medium={watchIsSampleReceived ? 3 : 4}>
          <DateTimePickerWithController
            label={'Reported Date'}
            name={'reportedDate'}
            control={control}
            onChange={onChange}
            errors={errors}
            isDateOnly
            isRequired
          />
        </GridColumn>
        <GridColumn medium={watchIsSampleReceived ? 3 : 4}>
          <SelectWithController
            name={'isSampleReceived'}
            label={'Sample Received'}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            defaultValue={'no'}
            options={options}
            className={'min-width-120'}
          />
        </GridColumn>
        {watchIsSampleReceived && (
          <GridColumn medium={3}>
            <DateTimePickerWithController
              label={'Sample Received Date'}
              name={'sampleReceivedDate'}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              isDateOnly
            />
          </GridColumn>
        )}
      </Grid>
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <TextAreaWrapper>
            <TextAreaWithController
              name={'problem'}
              label={'Reported Issue'}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              minimumRows={6}
            />
          </TextAreaWrapper>
        </GridColumn>
      </Grid>
    </BasicModalBody>
  );
};

export default CreateNCRModalBody;
