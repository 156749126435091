import React, { useEffect } from 'react';
import iAddress from '../../../../../types/contactCompany/iAddress';
import { iModalBodyData } from '../shared/CreateDNItem.types';
import iSalesOrderItem from '../../../../../types/sales/iSalesOrderItem';
import useListCrudHook from '../../../../../shared/hooks/useListCrudHook/useListCrudHook';
import useStatusSelector from '../../../../../shared/hooks/useStatusSelector';
import ModalBodyRender from '../shared/ModalBodyRender';
import { getEntityCategoryList } from '../../../../../services/CategoryService';
import { getSalesOrderItems } from '../../../../../services/SalesOrderItemService';
import { soRenderSpecialContent } from '../shared/SharedTableHelper';
import {
  SO_ITEM_CREATE_COLUMNS,
  SELECT_SO_ITEMS,
} from '../shared/CreateDNItem.constants';
import { SALES_ORDERS, SALES_QUOTES } from '../../../../sales/constants';

const CreateModalBody = ({
  onCreateCallback,
  customerId,
  DNId,
  onCreate,
  DNShippingAddress,
  DNItemLength,
}: {
  onCreateCallback?: () => void;
  customerId?: string;
  DNId: string;
  //  eslint-disable-next-line
  onCreate: (data: any) => Promise<void>;
  DNShippingAddress?: iAddress;
  DNItemLength?: number;
}) => {
  const {
    state,
    edit,
    onFilter,
    onSetCurrentPage,
    onSearch,
  } = useListCrudHook<iSalesOrderItem>({
    getFn: getSalesOrderItems,
    paginationApplied: true,
    sort: 'createdAt:DESC',
    notRenderWithoutFilter: true,
    keywordColumns: 'product.productCode,product.name,salesOrder.orderNumber',
  });

  const { selected, categories, isStatusLoading } = useStatusSelector({
    type: 'SalesOrder',
    isMulti: true,
    getFn: getEntityCategoryList,
  });

  useEffect(
    () => {
      if (!isStatusLoading) {
        onFilter(
          `salesOrder.customerId:${customerId},salesOrder.statusId:${selected.join(
            '|',
          )},salesOrder.type.name:${SALES_QUOTES}|${SALES_ORDERS}`,
        );
      }
    },
    //  eslint-disable-next-line
    [JSON.stringify(selected), customerId],
  );
  const exportData: iModalBodyData<iSalesOrderItem> = {
    isLoading: state.isLoading || isStatusLoading,
    state,
    keyword: edit.keyword,
    columns: SO_ITEM_CREATE_COLUMNS,
    pageName: SELECT_SO_ITEMS,
    categories,
    onSearch,
    onSetCurrentPage,
    renderSpecialContent: soRenderSpecialContent({
      onCreate,
      onCreateCallback,
      DNId,
      DNShippingAddress,
      DNItemLength,
    }),
  };
  //  eslint-disable-next-line
  return <ModalBodyRender {...exportData} />;
};

export default CreateModalBody;
