import React from 'react';
import {
  formatDate,
  handleMoney,
  handleNullException,
  handleNumber,
} from '../../../services/UtilsService';
import LinkBtnUrl from '../../buttons/LinkBtnUrl';
import { SALES_URL } from '../../UrlMap';
import { useProductCustomerContext } from '../ProductCustomerContext';

const LastSOInfo = ({
  lastSoKey,
  columnName,
}: {
  lastSoKey: string;
  columnName: string;
}) => {
  const { lastSOs } = useProductCustomerContext();

  // eslint-disable-next-line consistent-return
  const renderSwitch = (param: string) => {
    if (!handleNullException(lastSOs, lastSoKey)) return null;
    switch (param) {
      case 'orderNumber':
        return (
          <LinkBtnUrl
            btnName={handleNullException(
              lastSOs,
              `${lastSoKey}.salesOrder.orderNumber`,
            )}
            url={`${SALES_URL}/${handleNullException(
              lastSOs,
              `${lastSoKey}.salesOrder.id`,
            )}`}
          />
        );
      case 'orderDate':
        return (
          <>
            {formatDate(
              handleNullException(lastSOs, `${lastSoKey}.salesOrder.orderDate`),
              'DD MMM YYYY',
            )}
          </>
        );
      case 'unitPrice':
        return <>{handleMoney(lastSOs, `${lastSoKey}.unitPrice`, 2)}</>;
      case 'qty':
        return <>{handleNumber(lastSOs, `${lastSoKey}.qty`)}</>;
      default:
        return null;
    }
  };
  return <div data-testid={'last-so-info'}>{renderSwitch(columnName)}</div>;
};

export default LastSOInfo;
