import { iConfigColumn } from '../../../UITypes/types';
import { SERVICE_PROVIDER_URL } from '../../../UrlMap';

export const SERVICE_PROVIDER_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Type',
    key: 'serviceType.name',
    dataSource: [''],
    type: 'text',
    isSelected: true,
    isCompulsory: true,
    group: '',
  },
  {
    name: 'Company Name',
    key: 'contactCompany.name',
    dataSource: ['contactCompanyId'],
    type: 'link',
    isSelected: true,
    urlPrefix: SERVICE_PROVIDER_URL,
  },
  {
    name: 'Requested',
    key: 'requested',
    dataSource: ['qty', 'unitConversion'],
    type: 'sum-POItem-qty',
    isSelected: true,
  },
  {
    name: 'Sent',
    key: 'sent',
    dataSource: ['quantity'],
    type: 'sum-DNItem-qty',
    isSelected: true,
  },
  {
    name: 'Received',
    key: 'received',
    dataSource: ['receivedQty'],
    type: 'sum-POItem-receivedQty',
    isSelected: true,
    group: '',
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
  },
];

export const CREATE_PO = 'create PO';
export const CREATE_DN = 'create DN';
export const SERVICE_PROVIDER_TABLE_NAME = 'job-detail-service-provider-table';
