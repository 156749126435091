import React from 'react';
import { useSelector } from 'react-redux';
import iJob from '../../../../types/job/iJob';
import CommentList from '../../../comments/CommentList';
import LogList from '../../../log/LogList';
import CustomizeTab from '../../../tab/CustomizeTab';
import DNInJob from './dispatchNote/DNInJob';
import JobShiftsList from '../jobShifts/JobShiftsList';
import PurchaseOrderInJob from './purchaseOrder/PurchaseOrderInJob';
import { RootState } from '../../../../redux/makeReduxStore';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_DISPATCH_NOTES, ACCESS_CODE_PURCHASE_ORDERS } from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

const JobTabs = ({ detail }: { detail: iJob }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const jobTabs = [
    {
      label: 'Shifts',
      content: <JobShiftsList jobId={detail.id} />,
    },
    {
      label: 'Dispatch Notes',
      content: <DNInJob jobId={detail.id} />,
      isHidden: !AccessService.hasAccess(ACCESS_CODE_DISPATCH_NOTES, ACCESS_CAN_READ, user),
    },
    {
      label: 'Comments',
      content: <CommentList modelName={'Job'} modelId={detail.id} />,
    },
    {
      label: 'History',
      content: <LogList modelName={'Job'} modelId={detail.id} />,
    },
  ];
  if (detail.product.requireThirdPartyService) {
    jobTabs.splice(1, 0, {
      label: 'Purchase Orders',
      content: <PurchaseOrderInJob jobId={detail.id} />,
      isHidden: !AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_READ, user),
    });
  }
  return <CustomizeTab components={jobTabs} />;
};
export default JobTabs;
