import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import AppPage from './AppPage';
import TopNav from './TopNav/TopNav';
import { RootState } from '../redux/makeReduxStore';
import CompanyNotOnBoard from '../shared/company/CompanyNotOnBoard';
import CompanyExpired from '../shared/company/CompanyExpired';

// padding: 0rem 1.25rem;
const ContentWrapper = styled.div`
  padding: 2rem 3.5rem 1rem 3.5rem;
  .page-header-wrapper {
    padding-bottom: 1.25rem;
  }
  .page-children-wrapper {
    min-height: calc(100% - 160px);
    td {
      height: 3rem;
    }
  }
  .page-footer-wrapper {
    padding: 1rem 0rem 0rem;
  }

  &.page-content-wrapper-right {
    position: absolute;
    right: 0px;
    width: 80%;
  }
`;

type iBackendPage = {
  children: React.ReactNode;
  pageHeader?: React.ReactNode;
  // pageHeaderProps?: typeof PageHeader;
  pageFooter?: React.ReactNode;
  leftMenu?: React.ReactNode;
};
export default ({
  children,
  pageHeader,
  // pageHeaderProps,
  pageFooter,
  leftMenu,
}: iBackendPage) => {
  const { user } = useSelector((s: RootState) => s.auth);

  const assembleBackEnd = () => {
    if (user && !user.company.isOnBoarded) {
      return <CompanyNotOnBoard user={user} />;
    }
    if (user && user.company.expiry && moment().isAfter(user.company.expiry)) {
      return <CompanyExpired user={user} />;
    }
    return (
      <>
        {pageHeader && (
          <div className={'page-header-wrapper'} data-testid={'page-header-wrapper'}>
            {pageHeader}
          </div>
        )}
        <div className={'page-children-wrapper'} data-testid={'page-children-wrapper'}>
          {children}
        </div>
        {pageFooter && (
          <div className={'page-footer-wrapper'} data-testid={'page-footer-wrapper'}>
            {pageFooter}
          </div>
        )}
      </>
    );
  };

  return (
    <AppPage nav={<TopNav />} leftMenu={leftMenu}>
      <ContentWrapper className={'page-content-wrapper'}>{assembleBackEnd()}</ContentWrapper>
    </AppPage>
  );
};
