export const ROLL_NUMBER = { label: 'Roll(s)', value: 'rollNum' };
export const ROLL_WEIGHT = { label: 'Kg(s) Per Roll', value: 'kgsPerRoll' };
export const ROLL_LENGTH = {
  label: 'Meter(s) Per Roll',
  value: 'metersPerRoll',
};
export const KG_PER_1KM = {
  label: 'Yield Kg / 1,000 meters',
  value: 'yieldMetersKg',
};
export const OVER_QTY_HINT =
  "You might be creating a job producing over the ordered quantity for this product, You'd better check existed jobs or ordered quantity and come back";

export const ROLL_WEIGHT_PRODUCT_ATTRIBUTE_NAME = 'Roll Weight (Kg)';
