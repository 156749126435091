import React from 'react';
import { FlexSpaceBetweenContainer } from '../../../contactCompany/style';
import TypeFilter from './FilterByType';
// import { JOB_ALL, JOB_NEW, JOB_WIP, JOB_FINISHED } from '../../constants';
import { iLabelValuePair } from '../../../UITypes/types';

const JobFilter = ({
  options,
  onSelectJobType,
  selectedTypes,
  children,
}: {
  options: Array<iLabelValuePair>;
  onSelectJobType: (type: string) => void;
  selectedTypes: Array<string>;
  children: React.ReactNode;
}) => {
  return (
    <FlexSpaceBetweenContainer>
      <TypeFilter
        options={options}
        onSelectJobType={onSelectJobType}
        selectedTypes={selectedTypes}
      />
      <div style={{ maxWidth: '400px' }}>{children}</div>
    </FlexSpaceBetweenContainer>
  );
};

export default JobFilter;
