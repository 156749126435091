/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import iSalesOrderItem from '../../../../../../types/sales/iSalesOrderItem';
import ProdSellSoTable from './ProdSellSoTable';
import useListCrudHook from '../../../../../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooks from '../../../../../../shared/hooks/useShowHideColumns';
import useStatusSelector from '../../../../../../shared/hooks/useStatusSelector';
import CustomizePagination from '../../../../../../shared/pagination/CustomizePagination';
import SearchBar from '../../../../../../shared/search/SearchBar';
import ShowHideColumns from '../../../../../../shared/showHideColumns/ShowHideColumns';
import { getEntityCategoryList } from '../../../../../../services/CategoryService';
import { createSalesOrder, getSalesOrderTypes } from '../../../../../../services/SalesOrderService';
import { FlexSpaceBetweenContainer } from '../../../../../../shared/contactCompany/style';
import { FlexContainer, PageTitle } from '../../../../../../shared/styles/styles';
import { COLUMN_NAME, COLUMNS, FLEXIBLE_COLUMNS } from './constants';
import {
  deleteSalesOrderItem,
  getSalesOrderItems,
  updateSalesOrderItem,
} from '../../../../../../services/SalesOrderItemService';
import SalesFilter from '../../../../../../pages/sales/salesList/filter/SalesFilter';
import iSalesOrderType from '../../../../../../types/sales/iSalesOrderType';
import { apiErrorToast } from '../../../../../../shared/toast/Toast';
import { SO_TYPE_NAME_SALES_ORDER } from '../../../../../../pages/sales/salesDetail/constants';
import { RootState } from '../../../../../../redux/makeReduxStore';
import AccessService from '../../../../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_SALES_ORDERS } from '../../../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../../../types/settings/UserAccess/iRoleAccess';

const ProdSellSOs = ({ productId }: { productId: string }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [salesTypeList, setSalesTypeList] = useState<Array<iSalesOrderType>>([]);
  const [selectedColumns, onToggleColumn] = useColumnHooks(COLUMN_NAME, {
    default: COLUMNS,
    whole: COLUMNS,
  });
  const { state, edit, onSearch, onSetCurrentPage, onFilter } = useListCrudHook<iSalesOrderItem>({
    getFn: getSalesOrderItems,
    createFn: createSalesOrder,
    updateFn: updateSalesOrderItem,
    deleteFn: deleteSalesOrderItem,
    keywordColumns: 'salesOrder.orderNumber,salesOrder.customerRef,salesOrder.customer.name',
    filter: `productId:${productId}`,
    paginationApplied: true,
  });

  useEffect(() => {
    let mounted = true;
    if (selectedTypes.length > 0) {
      return;
    }
    const fetchData = async () => {
      try {
        if (mounted) {
          const soTypes = await getSalesOrderTypes();
          setSalesTypeList(soTypes);
          setSelectedTypes(
            soTypes
              .filter((type: iSalesOrderType) => type.name.toUpperCase() === SO_TYPE_NAME_SALES_ORDER)
              .map((type: iSalesOrderType) => type.id),
          );
        }
      } catch (e) {
        if (!mounted) return;
        apiErrorToast(e);
      }
    };

    fetchData();

    // eslint-disable-next-line consistent-return
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (selectedTypes.length > 0) {
      const filterType = selectedTypes.length === 0 ? '' : `salesOrder.typeId:${selectedTypes.join('|')}`;

      const filterStr = [filterType].filter((elem: string) => elem !== '').join(',');

      onFilter(`productId:${productId},${filterStr}`);
    }
  }, [JSON.stringify(selectedTypes)]);

  const { categories } = useStatusSelector({
    type: 'SalesOrder',
    isMulti: true,
    getFn: getEntityCategoryList,
  });

  const getPageHeader = () => (
    <FlexSpaceBetweenContainer>
      <PageTitle className={'small'}>Sales Orders</PageTitle>
      <div style={{ flexGrow: 0 }}>
        <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search'} />
      </div>
    </FlexSpaceBetweenContainer>
  );

  const onSelectType = (value: string) => {
    if (selectedTypes.includes(value)) {
      const newSelectedTypes = selectedTypes.filter(type => type !== value);
      setSelectedTypes(newSelectedTypes);
    } else {
      setSelectedTypes([...selectedTypes, value]);
    }
  };

  if (!AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_READ, user)) {
    return null;
  }

  return (
    <>
      {getPageHeader()}
      <FlexContainer className={'space-between space-above'}>
        <div>
          <SalesFilter onSelectType={onSelectType} selectedTypes={selectedTypes} options={[...salesTypeList]} />
        </div>
        <ShowHideColumns
          onToggleColumns={onToggleColumn}
          selectedColumns={selectedColumns}
          columnLists={[{ label: 'Sales Order', value: FLEXIBLE_COLUMNS }]}
        />
      </FlexContainer>
      <ProdSellSoTable
        soItems={state.data}
        isLoading={state.isLoading}
        columns={selectedColumns}
        categories={categories}
      />
      {state.total && state.perPage && state.currentPage ? (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      ) : null}
    </>
  );
};

export default ProdSellSOs;
