import { Edge, MarkerType, Node } from 'react-flow-renderer';
import iEntityStatus from '../../../types/status/iEntityStatus';
import WorkflowDiagramEdge from './WorkflowDiagramEdge';
import iWorkflow, { iPosition, iPosMap } from '../../../types/workflow/iWorkflow';
import { iWFDiagramState } from './WorkflowDiagramReducer';
import iEntityCategory from '../../../types/status/iEntityCategory';

export const NODE_ID_NEW_NODE = 'newNode-';
export const NODE_ID_START_NODE = 'startNode';
export const EDGE_ID_START_EDGE = 'startEdge';
export const NODE_ID_ANY_STATUS_PREFIX = 'any-';
export const EDGE_ID_ANY_STATUS_PREFIX = 'any-edge';

const getArrowEdge = (props: Edge) => {
  return {
    type: 'smoothstep',
    ...props,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  };
};

export const getAnyStatusNode = (node: Node, edgeLabel?: string) => {
  const anyNode = {
    id: `${NODE_ID_ANY_STATUS_PREFIX}${node.id}`,
    data: { label: 'Any Status' },
    position: { x: 43, y: -60 },
    type: 'input',
    className: 'any-node',
    draggable: false,
    parentNode: node.id,
    selectable: false,
  };
  const anyEdge = getArrowEdge({
    id: `${EDGE_ID_ANY_STATUS_PREFIX}${anyNode.id}-${node.id}`,
    source: anyNode.id,
    target: node.id,
    label: edgeLabel,
  });
  return {
    node: anyNode,
    edge: anyEdge,
  };
};

const getStartNodeAndEdge = (node: Node, position: { x: number; y: number }) => {
  const startNode = {
    id: NODE_ID_START_NODE,
    data: { label: '' },
    position,
    type: 'input',
    className: 'start-node',
  };
  const startEdge = getArrowEdge({
    id: EDGE_ID_START_EDGE,
    source: NODE_ID_START_NODE,
    target: node.id,
    className: 'start-edge',
    label: 'Create',
    type: 'smoothstep',
  });
  return {
    node: startNode,
    edge: startEdge,
  };
};

export const getStatusNode = (status: iEntityStatus, position: { x: number; y: number }) => {
  return {
    id: status.id,
    data: { label: status.name, status },
    position,
    className: `cate-${status.entityStatusCategory?.code || ''}`,
    connectable: false,
    selectable: true,
  };
};

export const getStatusEdge = (fromStatus: iEntityStatus, toStatus: iEntityStatus, name?: string) => {
  return getArrowEdge({
    id: `edge${fromStatus.id}-${toStatus.id}`,
    source: fromStatus.id,
    target: toStatus.id,
    type: 'wkflowEdge',
    label: name,
  });
};

export const workFlowEdge = {
  wkflowEdge: WorkflowDiagramEdge,
};

const getPosForNode = (nodeId: string, posMap: iPosMap, defaultPos: iPosition) => {
  if (!(nodeId in posMap)) {
    return defaultPos;
  }
  return posMap[nodeId];
};

export const getNewEntityStatus = (
  name: string,
  code: string,
  description: string,
  entityStatusCategory: iEntityCategory,
): iEntityStatus => {
  return {
    id: `${NODE_ID_NEW_NODE}${Math.random()}`,
    isActive: true,
    createdAt: '',
    updatedAt: '',
    createdById: '',
    updatedById: '',

    code,
    name,
    description,
    entityStatusCategory,
    sortOrder: 0,
    statusCategoryId: entityStatusCategory.id,
    entityStatusTypeId: '',
    entityStatusCategoryId: entityStatusCategory.id,
  };
};

export const initDiagram = (entityStatuses: iEntityStatus[], workflow: iWorkflow) => {
  if (entityStatuses.length <= 0) {
    return {};
  }
  const initX = 180;
  const initY = 100;
  const posMap = workflow?.wf.posMap || {};

  const statusMap: { [key: string]: iEntityStatus } = entityStatuses.reduce((map, status) => {
    return {
      ...map,
      [status.id]: status,
    };
  }, {});
  const statusIds = Object.keys(statusMap);
  if (statusIds.length <= 0) {
    return {};
  }
  const statusEdgeMap: { [key: string]: Edge } = {};
  const statusNodeMap: { [key: string]: Node } = {};
  let nodeOrder = 0;
  // start node, initial node and edge
  const initialStatusIds = statusIds.filter(statusId => statusId === workflow?.wf.initial);
  const initialStatusId = initialStatusIds.length > 0 ? initialStatusIds[0] : statusIds[0];
  const initialNode = getStatusNode(
    statusMap[initialStatusId],
    getPosForNode(initialStatusId, posMap, { x: ((nodeOrder += 1) + 1) * initX, y: initY }),
  );

  const { node: startNode, edge: startEdge } = getStartNodeAndEdge(
    initialNode,
    getPosForNode(NODE_ID_START_NODE, posMap, {
      x: initialNode.position.x - 100,
      y: initialNode.position.y,
    }),
  );
  statusNodeMap[startNode.id] = startNode;
  statusNodeMap[initialNode.id] = initialNode;
  statusEdgeMap[startEdge.id] = startEdge;

  (workflow?.wf.transitions || []).map(transition => {
    if (!(transition.to in statusMap)) {
      return null;
    }
    const toStatus = statusMap[transition.to];
    if (transition.from.trim() === '*') {
      const node =
        toStatus.id in statusNodeMap
          ? statusNodeMap[toStatus.id]
          : getStatusNode(
              toStatus,
              getPosForNode(toStatus.id, posMap, { x: ((nodeOrder += 1) + 1) * initX, y: initY }),
            );
      const { node: anyNode, edge: anyNodeEdge } = getAnyStatusNode(node, transition.name);
      statusNodeMap[node.id] = node;
      statusNodeMap[anyNode.id] = anyNode;
      statusEdgeMap[anyNodeEdge.id] = anyNodeEdge;
      return null;
    }

    if (!(transition.from in statusMap)) {
      return null;
    }
    const fromStatus = statusMap[transition.from];
    const fromNode =
      fromStatus.id in statusNodeMap
        ? statusNodeMap[fromStatus.id]
        : getStatusNode(
            fromStatus,
            getPosForNode(fromStatus.id, posMap, { x: ((nodeOrder += 1) + 1) * initX, y: initY }),
          );
    statusNodeMap[fromNode.id] = fromNode;
    const toNode =
      toStatus.id in statusNodeMap
        ? statusNodeMap[toStatus.id]
        : getStatusNode(toStatus, getPosForNode(toStatus.id, posMap, { x: ((nodeOrder += 1) + 1) * initX, y: initY }));
    statusNodeMap[toNode.id] = toNode;

    const edge = getStatusEdge(fromStatus, toStatus, transition.name);
    statusEdgeMap[edge.id] = edge;

    return null;
  });

  const usedStatusIds = Object.keys(statusNodeMap);
  // any statuses not in the workflow, we sign it with all the any-status
  entityStatuses
    .filter(status => usedStatusIds.indexOf(status.id) < 0)
    .map(status => {
      const node = getStatusNode(
        status,
        getPosForNode(status.id, posMap, { x: ((nodeOrder += 1) + 1) * initX, y: initY }),
      );
      const { node: anyNode, edge: anyNodeEdge } = getAnyStatusNode(node);
      statusNodeMap[node.id] = node;
      statusNodeMap[anyNode.id] = anyNode;
      statusEdgeMap[anyNodeEdge.id] = anyNodeEdge;
      return node;
    });
  const nodes = Object.values(statusNodeMap);
  const edges = Object.values(statusEdgeMap);
  return { nodes, edges };
};

export const validateGraph = (nodes: Node[], edges: Edge[]) => {
  const targetStatusIds = edges.map(edge => edge.target);
  const nodeMap = nodes.reduce((map, node) => {
    return {
      ...map,
      [node.id]: node,
    };
  }, {});
  const nodeErrorMsgs = nodes
    .filter(node => node.id !== NODE_ID_START_NODE && !node.id.startsWith(NODE_ID_ANY_STATUS_PREFIX))
    .map((node: Node) => {
      if (targetStatusIds.indexOf(node.id) < 0) {
        return `Status(${node.data.label}) can NOT be reached!`;
      }
      return '';
    });

  const edgeErrorMsgs = edges.map((edge: Edge) => {
    if (`${edge.source || ''}`.trim() === '') {
      return `${edge.label || edge.id} need to have a source!`;
    }
    if (!(edge.source in nodeMap)) {
      return `${edge.label || edge.id} is having a invalid source!`;
    }
    if (`${edge.target || ''}`.trim() === '') {
      return `${edge.label || edge.id} need to have a target!`;
    }
    if (!(edge.target in nodeMap)) {
      return `${edge.label || edge.id} is having a invalid target!`;
    }
    return '';
  });
  return [...edgeErrorMsgs, ...nodeErrorMsgs].filter(errorMsg => errorMsg.trim() !== '');
};

export const formatDiagramForWorkFlow = (nodes: Node[], edges: Edge[], state: iWFDiagramState) => {
  const startEdges = edges.filter(edge => edge.id === EDGE_ID_START_EDGE);
  const initialState = startEdges.length > 0 ? startEdges[0].target : undefined;
  const transitions = edges
    .filter(edge => edge.id !== EDGE_ID_START_EDGE)
    .map(edge => {
      return {
        from: edge.source.startsWith(NODE_ID_ANY_STATUS_PREFIX) ? '*' : edge.source,
        to: edge.target,
        ...('label' in edge ? { name: edge.label } : {}),
      };
    });

  const nodePositionMap = nodes.reduce((map, node) => {
    return {
      ...map,
      [node.id]: node.position,
    };
  }, {});

  return {
    ...state.workflow?.wf,
    initial: initialState,
    transitions,
    posMap: nodePositionMap,
  };
};
