import React from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@atlaskit/avatar';
import AddIcon from '@atlaskit/icon/glyph/add';
import Button from '@atlaskit/button';
import {
  AddOneBtnWrapper,
  FlexContainer,
} from '../../../../shared/styles/styles';
import { RootState } from '../../../../redux/makeReduxStore';

const CreateInvestigation = ({
  onOpenAddModal,
}: {
  onOpenAddModal: () => void;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  return (
    <FlexContainer>
      <div className={'create-avatar'}>
        <Avatar appearance="circle" src={user?.userAvatarUrl} size="medium" />
      </div>
      <AddOneBtnWrapper className={'space-left'}>
        <Button
          iconBefore={<AddIcon label="add" size={'small'} />}
          shouldFitContainer
          onClick={onOpenAddModal}
          testId={'add-one'}
        />
      </AddOneBtnWrapper>
    </FlexContainer>
  );
};

export default CreateInvestigation;
