import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import iRole from '../../types/settings/UserAccess/iRole';
import CustomizeModal from '../modal/CustomizeModal';
import { FlexContainer } from '../styles/styles';
import InputWithController from '../form/hookForms/InputWithController';

type iRoleEditPopup = {
  role?: iRole;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave: (data: any) => Promise<void>;
  onCancel?: () => void;
  isOpen: boolean;
  isSaving?: boolean;
};
const Wrapper = styled.div`
  .input-row {
    width: 100%;
    > div:first-child {
      width: 38%;
    }
    > div:last-child {
      width: 60%;
    }
  }
`;
const RoleEditPopup = ({ role, onSave, isOpen, onCancel, isSaving }: iRoleEditPopup) => {
  const { control, setValue, errors, handleSubmit } = useForm({});

  const getHeader = () => {
    if (role) {
      return <b>Editing role: {role.name}</b>;
    }
    return <b>Creating a role</b>;
  };

  const getRoleForm = () => {
    return (
      <Wrapper>
        <FlexContainer className={'space-between input-row space-below'}>
          <InputWithController
            ctrlClassName={'input'}
            name={'name'}
            label={'Name'}
            defaultValue={role?.name}
            control={control}
            onChange={(name, value) => setValue(name, value)}
            errors={errors}
            isRequired
            testId={'role-edit-popup-name'}
            isDisabled={isSaving}
          />
          <InputWithController
            ctrlClassName={'input'}
            name={'description'}
            label={'Description'}
            defaultValue={role?.description || ''}
            control={control}
            onChange={(name, value) => setValue(name, value)}
            errors={errors}
            isRequired={false}
            testId={'role-edit-popup-description'}
            isDisabled={isSaving}
          />
        </FlexContainer>
      </Wrapper>
    );
  };

  return (
    <CustomizeModal
      width={'large'}
      modalHeading={getHeader()}
      modalBody={getRoleForm()}
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSave)}
      isConfirming={isSaving}
      confirmBtnName={role ? 'OK' : 'Create'}
      shouldScrollInViewport
    />
  );
};

export default RoleEditPopup;
