export const MUST_COLUMNS_BEFORE = ['SO No.'];
export const FLEXIBLE_COLUMNS = [
  'Status',
  'Type',
  'Customer',
  'Reference',
  'Unit Price',
  'Qty',
  'Order Date',
  'EDD',
  'Created',
  'Updated',
];
export const COLUMNS = [...MUST_COLUMNS_BEFORE, ...FLEXIBLE_COLUMNS];
export const COLUMN_NAME = 'product-so-columns';
