import React, { useState } from 'react';
import styled from 'styled-components';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import Button from '@atlaskit/button';

const CollapseWrapper = styled.div`
  .collapse-button {
    background-color: transparent;
  }
  .collapse-children {
    padding-left: 1rem;
  }
`;

const Collapse = ({
  defaultOpen = false,
  iconSize = 'medium',
  text,
  children,
}: {
  defaultOpen?: boolean;
  iconSize?: 'small' | 'medium' | 'large' | 'xlarge' | undefined;
  text: string | React.ReactNode;
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(defaultOpen);
  const icon = state ? (
    <ChevronDownIcon label={'collapse'} size={iconSize} />
  ) : (
    <ChevronRightIcon label={'show'} size={iconSize} />
  );
  return (
    <CollapseWrapper>
      <Button
        iconAfter={icon}
        onClick={() => setState(!state)}
        className={'collapse-button'}
        testId={'collapse-button'}
      >
        {text}
      </Button>
      <div className={'collapse-children'}>{state && children}</div>
    </CollapseWrapper>
  );
};

export default Collapse;
