import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../../shared/constants/AsyncConstants';
import { iParams } from '../../shared/UITypes/types';
// import { fakeProductType } from '../__mock__/fakeData';
import AppService from '../AppService';

export const getProductTypes = (config?: iParams) => {
  return AppService.get('/productType', {
    include: 'measurement,createdBy,updatedBy,attributeSet',
    ...config,
  }).then(({ data }) => data);
};

export const updateProductType = (
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateContent: { [key: string]: any },
) => {
  return AppService.put(`/productType/${id}`, updateContent).then(({ data }) => data);
};

export const createProductType = (postData: { [key: string]: string | boolean | number }) => {
  return AppService.post('/productType', postData).then(({ data }) => data);
};

export const deactivateProductType = (id: string) => {
  return AppService.delete(`/productType/${id}`).then(({ data }) => data);
};

export const getProductTypeListAsyncSearch = (config?: iParams) => {
  return AppService.get('/productType', {
    ...config,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

export default {
  getProductTypes,
  updateProductType,
  createProductType,
  deactivateProductType,
  getProductTypeListAsyncSearch,
};
