import React from 'react';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import { SimpleTag as Tag } from '@atlaskit/tag';

import iProductAttribute from '../../../types/product/iProductAttribute';
import { iConfigColumn } from '../../../shared/UITypes/types';
import { handleNullException } from '../../../services/UtilsService';
import LinkBtn from '../../../shared/buttons/LinkBtn';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import { getDeleteBtn } from '../../../components/settings/utils';

export const getRows = (
  data: Array<iProductAttribute>,
  onEdit: (id: string) => void,
  onDelete: (id: string) => void,
  columns: Array<iConfigColumn>,
) => {
  return data.map((attr: iProductAttribute) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'modalLink':
          return {
            key: handleNullException(attr, key),
            content: (
              <LinkBtn
                btnName={handleNullException(attr, key)}
                onClick={() => onEdit(handleNullException(attr, dataSource[0]))}
                testId={`prodAttribute-list-linkBtn-${handleNullException(attr, dataSource[0])}`}
              />
            ),
          };
        case 'text':
          return {
            key: handleNullException(attr, key),
            content: handleNullException(attr, key),
          };
        case 'multi-text-input':
          return {
            key: handleNullException(attr, key),
            content:
              handleNullException(attr, key) !== ''
                ? JSON.parse(handleNullException(attr, key))?.map((elem: string, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Tag text={elem} key={index} />
                  ))
                : '',
          };
        case 'sortOrder':
          return {
            type: 'sortOrder',
            key: handleNullException(attr, key),
            content: handleNullException(attr, key),
          };
        case 'history':
          return {
            key: handleNullException(attr, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(attr, key)}
                operator={handleNullException(attr, dataSource[0])}
              />
            ),
          };
        case 'operation':
          return {
            key: `${attr.name}-operation`,
            content: getDeleteBtn({
              id: attr.id,
              answer: attr.name || 'Attribute Name n/a',
              onClick: onDelete,
            }),
          };
        case 'icon':
          return {
            key: `${attr.name}-icon`,
            content: <DragHandlerIcon label={'dragHandlerIcon'} />,
          };
        default:
          return { content: '' };
      }
    });
    return {
      cells,
      testId: 'admin-product-attribute-row',
      key: attr.id,
    };
  });
};
