import React, { useEffect, useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Grid, GridColumn } from '@atlaskit/page';
import BasicModalBody from '../../../shared/asyncContactCompany/createModalBody/BasicModalBody';
import DateTimePickerWithController from '../../../shared/form/hookForms/DateTimePickerWithController';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import AddressSelectWithController from '../../../shared/form/hookForms/CompanyAddressSelectorWithController';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { getContactCompany } from '../../../services/ContactCompanyService';
import { handleNullException } from '../../../services/UtilsService';
import iContactCompanyAddress from '../../../types/contactCompany/iContactCompanyAddress';
import { getCompanyAddressList } from '../../../services/ContactCompanyAddressService';
import iContactCompany from '../../../types/contactCompany/iContactCompany';

const SOModalBody = ({
  control,
  errors,
  onChange,
  watch,
  isCustomer,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  watch: (names?: string | string[]) => string;
  isCustomer: boolean;
}) => {
  const contactCompanyId = watch('customerId');

  const [
    localContactCompany,
    setLocalContactCompany,
  ] = useState<iContactCompany>();
  const [localCompanyAddressList, setLocalCompanyAddressList] = useState([]);

  useEffect(() => {
    if (!contactCompanyId) {
      // setLocalContactCompany(undefined);
      return;
    }
    const getData = async () => {
      try {
        const contactCompany = await getContactCompany(contactCompanyId);
        const { data } = await getCompanyAddressList({
          filter: `contactCompanyId:${contactCompanyId}`,
        });
        setLocalContactCompany(contactCompany);
        setLocalCompanyAddressList(data);
      } catch (e) {
        apiErrorToast(e);
      }
    };

    getData();
  }, [contactCompanyId]);

  const getOptions = () => {
    if (localCompanyAddressList.length === 0 || !localContactCompany) return [];
    if (
      localContactCompany.shippingAddressId ===
      localContactCompany.billingAddressId
    ) {
      return localCompanyAddressList.map((item: iContactCompanyAddress) => ({
        label: item.id,
        value: item,
      }));
    }

    // avoid use billing address for shipping
    return localCompanyAddressList
      .filter(
        (item: iContactCompanyAddress) =>
          item.addressId !== localContactCompany.billingAddressId,
      )
      .map((item: iContactCompanyAddress) => ({
        label: item.id,

        value: item,
      }));
  };

  return (
    <BasicModalBody
      control={control}
      errors={errors}
      onChange={onChange}
      watch={watch}
      isCustomer={isCustomer}
      verticalSpaceBeforeSelect={'eight'}
    >
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <AddressSelectWithController
            name={'shippingAddressId'}
            label={'Shipping Address'}
            control={control}
            defaultValue={
              contactCompanyId
                ? handleNullException(localContactCompany, 'shippingAddressId')
                : undefined
            }
            options={getOptions()}
            onChange={onChange}
            errors={errors}
            isRequired
            isClearable={false}
            customizedStyle={{ menu: () => ({ overflowY: 'visible' }) }}
          />
        </GridColumn>
      </Grid>
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <InputWithController
            label={'Customer Reference number'}
            name={'customerRef'}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
          />
        </GridColumn>
      </Grid>
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <DateTimePickerWithController
            label={'Order Date'}
            name={'orderDate'}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            isDateOnly
            isDefaultNow
          />
        </GridColumn>
      </Grid>
    </BasicModalBody>
  );
};

export default SOModalBody;
