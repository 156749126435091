import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import iProductServiceType from '../../../types/contactCompany/iProductServiceType';
import BackendPage from '../../../layout/BackendPage';
import DeleteByRetype from '../../../shared/deleteWithReType/DeleteByReType';
import PageTitleWithAddOne from '../../../shared/heading/PageTitleWithAddOne';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import SearchBar from '../../../shared/search/SearchBar';
import ServiceTypeTable from './ServiceTypeTable';
import { FlexContainer } from '../../../shared/styles/styles';
import { SERVICE_TYPE_COLUMNS, SERVICE_TYPE_PAGE_NAME } from './ServiceType.constants';
import {
  createProductServiceType,
  deactivateProductServiceType,
  getProductServiceTypes,
  updateProductServiceType,
} from '../../../services/product/ProductServiceTypeService';
import ServiceTypeModalBody from './ServiceTypeModalBody';
import { validateIsDirty } from '../../../services/UtilsService';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_SERVICE_PROVIDERS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE } from '../../../types/settings/UserAccess/iRoleAccess';

const ServiceTypeList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const {
    state,
    edit,
    onCloseModal,
    onOpenAddModal,
    onOpenEditModal,
    onOpenDeleteModal,
    onSubmit,
    onSearch,
    onDelete,
    onSetCurrentPage,
    onSetSort,
  } = useListCrudHook<iProductServiceType>({
    getFn: getProductServiceTypes,
    createFn: createProductServiceType,
    updateFn: updateProductServiceType,
    deleteFn: deactivateProductServiceType,
    keywordColumns: 'name',
    paginationApplied: true,
  });

  const { control, setValue, errors, handleSubmit, watch } = useForm();

  const getPageHeader = () => (
    <FlexContainer className={'space-between'}>
      <PageTitleWithAddOne
        title={SERVICE_TYPE_PAGE_NAME}
        onClickAddBtn={onOpenAddModal}
        className={'space-below'}
        isDisabled={!AccessService.hasAccess(ACCESS_CODE_SERVICE_PROVIDERS, ACCESS_CAN_DELETE, user)}
      />
      <FlexContainer>
        <SearchBar keyword={edit.keyword} onSearch={onSearch} placeholder={'search via name....'} />
      </FlexContainer>
    </FlexContainer>
  );

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <ServiceTypeTable
        pageName={SERVICE_TYPE_PAGE_NAME}
        data={state.data}
        isLoading={state.isLoading}
        onOpenDeleteModal={onOpenDeleteModal}
        onOpenEditModal={onOpenEditModal}
        onSetSort={onSetSort}
        columns={SERVICE_TYPE_COLUMNS}
        canDelete={AccessService.hasAccess(ACCESS_CODE_SERVICE_PROVIDERS, ACCESS_CAN_DELETE, user)}
        canUpdate={AccessService.hasAccess(ACCESS_CODE_SERVICE_PROVIDERS, ACCESS_CAN_DELETE, user)}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0 || !validateIsDirty(watch, edit.target, ['name', 'description'])}
        modalBody={<ServiceTypeModalBody control={control} errors={errors} onChange={setValue} target={edit.target} />}
        modalHeading={edit.target ? edit.target.name : 'Create'}
        confirmBtnName={'Save'}
      />
      <DeleteByRetype
        isOpen={edit.isModalOpen && !!edit.delTargetId}
        onClose={onCloseModal}
        onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
        answer={edit.target?.name}
        isConfirming={state.isConfirming}
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </BackendPage>
  );
};

export default ServiceTypeList;
