import { iConfigColumn } from '../shared/UITypes/types';
import { SERVICE_PROVIDER_URL, SERVICE_PROVIDER_DETAIL_URL, AOS_URL, AO_DETAIL_URL } from '../shared/UrlMap';
import { ModuleCode } from '../types/iModuleCode';
import AppService from './AppService';
import { handleNullException } from './UtilsService';

const pathRelatedModule = {
  [SERVICE_PROVIDER_URL]: ModuleCode.SERVICE_PROVIDER,
  [SERVICE_PROVIDER_DETAIL_URL]: ModuleCode.SERVICE_PROVIDER,
  [AOS_URL]: ModuleCode.PURCHASE_AO,
  [AO_DETAIL_URL]: ModuleCode.PURCHASE_AO,
};

const checkIsModuleEnabled = ({
  checkedModule,
  companyModules,
}: {
  checkedModule: ModuleCode;
  companyModules: Array<ModuleCode>;
}) => {
  return companyModules.includes(checkedModule);
};

const getCheckedModuleFromPath = (path?: string | string[]) => {
  if (!path || Array.isArray(path)) return undefined;
  return handleNullException(pathRelatedModule, path);
};

const filterListByModule = ({
  list,
  // targetIndexs,
  checkedModule,
  companyModules,
  filterFn,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: Array<any>;
  // targetIndexs: Array<number>;
  checkedModule: ModuleCode;
  companyModules: Array<ModuleCode>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterFn: (item: any, index: number) => boolean;
}) => {
  if (checkIsModuleEnabled({ checkedModule, companyModules })) {
    return list;
  }

  return list.filter(filterFn);
  // !targetIndexs.includes(index));
};

const filterConfigColumnsByModule = ({
  list,
  companyModules,
}: {
  list: Array<iConfigColumn>;
  companyModules: Array<ModuleCode>;
}) => {
  return list.filter(
    (item: iConfigColumn) =>
      !(item.moduleCode && !checkIsModuleEnabled({ checkedModule: item.moduleCode, companyModules })),
  );
};
// eslint-disable-next-line
const domainExchangeCompanyInfo = (hostname: string) => {
  // eslint-disable-next-line
  return AppService.get('/validateDomain', { hostname }).then(({ data }) => {
    // if(data.companyId) LocalStorageService.setCompanyId(data.companyId);
    return {
      companyId: data.companyId,
      companyLogoUrl: data.companyLogoUrl,
      companyName: data.companyName,
    };
  });
};
export default {
  checkIsModuleEnabled,
  getCheckedModuleFromPath,
  filterListByModule,
  filterConfigColumnsByModule,
  domainExchangeCompanyInfo,
};
