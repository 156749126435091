import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import InlineEdit from '../../../../shared/form/InlineEdit';
import iProfileCompany from '../../../../types/company/iProfileCompany';
import iSalesOrderType from '../../../../types/sales/iSalesOrderType';
import { getSalesOrderTypes } from '../../../../services/SalesOrderService';
import { addToastForAPIResponse, apiErrorToast } from '../../../../shared/toast/Toast';
import { FlexColumnContainer } from '../../../../shared/styles/styles';
import InlineRichTextEditor from '../../../../shared/form/InlineRichTextEditor';
import CompanyPreferenceService from '../../../../services/Settings/CompanyPreferenceService';
import iProfileCompanyPreference from '../../../../types/company/iProfileCompanyPreference';

const Wrapper = styled.div`
  .section {
    border-bottom: 1px #ddd solid;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    :last-child {
      border-bottom: none;
    }
  }
`;
type iSalesOrderSettings = {
  company: iProfileCompany;
};
const PDFSOSettings = ({ company }: iSalesOrderSettings) => {
  const settingsCode = 'SO_SETTINGS';
  const printingsKey = 'PRINTING';
  const [soTypes, setSoTypes] = useState<iSalesOrderType[]>([]);
  const [soSettings, setSoSettings] = useState<iProfileCompanyPreference | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    Promise.all([
      getSalesOrderTypes(),
      CompanyPreferenceService.getCompanyPreferences({
        filter: `companyId:${company.id},code:${settingsCode}`,
        perPage: 1,
        currentPage: 1,
        order: 'updatedAt:DESC',
      }),
    ])
      .then(resp => {
        if (isCanceled) return;
        setSoTypes(resp[0]);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const settings = resp[1].data || [];
        setSoSettings(settings.length <= 0 ? null : settings[0]);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [company]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateField = (name: string, value: any) => {
    if (!soSettings) {
      return;
    }
    setIsSaving(true);
    CompanyPreferenceService.updateCompanyPreference(soSettings.id, {
      value: {
        ...soSettings.value,
        [printingsKey]: {
          ...soSettings.value[printingsKey],
          [name]: value,
        },
      },
    })
      .then(resp => {
        setSoSettings(resp);
        addToastForAPIResponse('success', 'updated successfully.');
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      {soTypes.map(soType => {
        return (
          <FlexColumnContainer className={'space-below section'} key={soType.id}>
            <h4>{soType.name}</h4>
            <InlineEdit
              label={`Title for ${soType.name} PDF`}
              name={`title_${soType.name}`}
              isDisabled={isSaving}
              onConfirm={updateField}
              defaultValue={
                soSettings?.value[printingsKey] ? soSettings?.value[printingsKey][`title_${soType.name}`] : ''
              }
            />
            <InlineRichTextEditor
              label={'Policies'}
              testId={`policies_${soType.name}`}
              defaultValue={
                soSettings?.value[printingsKey] ? soSettings?.value[printingsKey][`policies_${soType.name}`] : ''
              }
              disabled={isSaving}
              onChangeConfirmed={newText => updateField(`policies_${soType.name}`, newText)}
            />
          </FlexColumnContainer>
        );
      })}
    </Wrapper>
  );
};

export default PDFSOSettings;
