import Checkbox from '@atlaskit/checkbox';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

const InlineCheckboxWrapper = styled.div`
  display: inline-block;
  margin-top: 8px;
  .inline-checkbox-label {
    font-size: 0.8571428571428571em;
    font-style: inherit;
    line-height: 1.3333333333333333;
    color: #6b778c;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 4px;
    margin-top: 0;
  }
  .inline-checkbox {
    color: #1e1e1e;
    font-size: 14px;
    padding: 8px 6px;
    display: block;
    min-height: 12px;
  }
`;
const initialState = {
  value: false,
};
type iInlineCheckbox = {
  label?: string;
  name: string;
  defaultValue: boolean;
  isDisabled?: boolean;
  onConfirm: (name: string, isChecked: boolean) => void;
};
const InlineCheckbox = ({
  label = 'Allow Parts',
  name,
  defaultValue,
  onConfirm,
  isDisabled = false,
}: iInlineCheckbox) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    setState(s => ({
      ...s,
      value: defaultValue || initialState.value,
    }));
  }, [defaultValue]);
  // eslint-disable-next-line
  const changeValue = (value: any) => {
    setState({
      ...state,
      value,
    });
    if (typeof onConfirm === 'function') {
      onConfirm(name, value);
    }
  };
  return (
    <InlineCheckboxWrapper className={'inline-checkbox-wrapper'}>
      <label htmlFor={'inline-checkbox'} className={'inline-checkbox-label'} data-testid={`testid-${name}`}>
        {label}
      </label>
      <div id={'inline-checkbox'} className={'inline-checkbox'}>
        <Checkbox
          isChecked={state.value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => changeValue(event.target.checked)}
          isDisabled={isDisabled}
        />
      </div>
    </InlineCheckboxWrapper>
  );
};

export default InlineCheckbox;
