import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
// import LinkBtn from '../buttons/LinkBtn';
import LinkBtnUrl from '../buttons/LinkBtnUrl';

export const BreadcrumbWrapper = styled.div`
  font-size: 0.9rem;
  color: ${colors.N100};
  padding-bottom: 0.5rem;
`;
const BreadcrumbsTwoLayers = ({
  name,
  url,
  // onClick,
  subName,
  extraLayer,
}: {
  name: string;
  url: string;
  // onClick: () => void;
  subName: string;
  extraLayer?: React.ReactNode;
}) => {
  const wrapperTestId = 'breadcrumb-wrapper';
  return extraLayer ? (
    <BreadcrumbWrapper data-testid={wrapperTestId}>
      <LinkBtnUrl url={url} btnName={name} /> / {extraLayer} / {subName}
    </BreadcrumbWrapper>
  ) : (
    <BreadcrumbWrapper data-testid={wrapperTestId}>
      <LinkBtnUrl url={url} btnName={name} /> / {subName}
    </BreadcrumbWrapper>
  );
};

export default BreadcrumbsTwoLayers;
