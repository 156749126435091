import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import iStockTakeItem from '../../../../../types/stockTake/iStockTakeItem';
import FinishReviewTable from './FinishReviewTable';
import { FINISHING_WARNING_TITLE } from '../../../shared/StockTake.constant';
import { SpaceVerticalContainer } from '../../../../../shared/styles/styles';

const FinishModalBody = ({
  stockTakeItems,
}: {
  stockTakeItems: Array<iStockTakeItem>;
}) => {
  return (
    <>
      <SectionMessage appearance="warning">
        <p>{FINISHING_WARNING_TITLE}</p>
      </SectionMessage>
      <SpaceVerticalContainer />
      <FinishReviewTable data={stockTakeItems} />
    </>
  );
};

export default FinishModalBody;
