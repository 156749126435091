import moment from 'moment';
import AppService from '../AppService';
import iProfileCompany from '../../types/company/iProfileCompany';

const getCompanyProfile = (id: string) => {
  return AppService.get(`/profileCompany/${id}`, {
    include: 'address,poTaxRate,poTaxBase,soTaxRate,soTaxBase',
  }).then(({ data }) => data);
};

const updateCompanyProfile = (id: string, updateContent: { [key: string]: string | boolean | number }) => {
  return AppService.put(`/profileCompany/${id}`, updateContent).then(({ data }) => data);
};

const getDaysFromNow = (dateString: string) => {
  return moment(dateString).diff(moment(), 'days');
};

const getExpiryInfo = (
  company: iProfileCompany,
): {
  expiresInDays: number | null;
  isForTrial: boolean;
} => {
  if (company.trialExpiresAt) {
    if (company.expiry) {
      if (moment(company.expiry).isSameOrBefore(moment(company.trialExpiresAt))) {
        return {
          expiresInDays: getDaysFromNow(company.trialExpiresAt),
          isForTrial: true,
        };
      }
      return {
        expiresInDays: getDaysFromNow(company.expiry),
        isForTrial: false,
      };
    }
    return {
      expiresInDays: getDaysFromNow(company.trialExpiresAt),
      isForTrial: true,
    };
  }

  if (company.expiry) {
    return { expiresInDays: getDaysFromNow(company.expiry), isForTrial: false };
  }

  return { expiresInDays: null, isForTrial: false };
};

export default { getCompanyProfile, updateCompanyProfile, getExpiryInfo };
