import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import { CreatableSelect } from '@atlaskit/select';
import { iLabelValuePair } from '../../UITypes/types';
import CustomizedLabel from './CustomizedLabel';
import { ErrorMsg } from '../../styles/styles';

const SelectWrapper = styled.div`
  margin-top: 8px;
  min-width: 200px;
  &.min-width-120 {
    min-width: 120px;
  }
  .validation-error > div {
    border-color: #de350b;
  }
`;

const createOption = (input: string) => {
  return {
    label: input.toUpperCase(),
    value: input,
  };
};

const getDefaultOptions = (defaultValue: string[]) => {
  return defaultValue.map(value => ({
    label: value.toUpperCase(),
    value,
  }));
};

const getMultiValues = (selected: iLabelValuePair[]) => {
  return selected.map((s: iLabelValuePair) => s.value);
};

const initialState = {
  inputValue: '',
  value: [],
};

type iState = {
  inputValue: string;
  value: iLabelValuePair[];
};

const CreatableSelectWithController = ({
  name,
  label,
  placeholder,
  defaultValue,
  control,
  onChange,
  testId = 'creatable-select-react-hook-form',
  isRequired = false,
  errors,
  className,
  isClearable = false,
  isMulti = false,
  isDisabled = false,
}: {
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: Array<string>;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  testId?: string;
  isRequired?: boolean;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  className?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState<iState>(initialState);

  const memoized = useCallback(() => {
    setState({
      ...state,
      value: getDefaultOptions(defaultValue as string[]),
    });
    onChange(name, defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultValue)]);

  useEffect(() => {
    if (defaultValue) {
      memoized();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultValue)]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (value: any) => {
    setState({
      ...state,
      value: value ? (value as iLabelValuePair[]) : [],
    });
    const valueToSubmit = value
      ? getMultiValues(value as iLabelValuePair[])
      : [];

    onChange(name, valueToSubmit);
  };

  const handleInputChange = (inputValue: string) => {
    setState({ ...state, inputValue });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (event: any) => {
    const { inputValue, value } = state;

    if (!inputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        // eslint-disable-next-line no-case-declarations
        const mergedValArr = [...value, createOption(inputValue)];
        setState({
          inputValue: '',
          value: mergedValArr,
        });
        // eslint-disable-next-line
        const convertedVal = getMultiValues(mergedValArr as iLabelValuePair[])
        onChange(name, convertedVal);
        event.preventDefault();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={getDefaultOptions(defaultValue as string[])}
      rules={{
        required: isRequired,
      }}
      render={ctrlProps => (
        <SelectWrapper className={className}>
          <CustomizedLabel
            label={label}
            htmlFor={testId}
            isRequired={isRequired}
          />
          <CreatableSelect
            //  eslint-disable-next-line
            {...ctrlProps}
            name={name}
            inputValue={state.inputValue}
            value={state.value}
            inputId={testId}
            //  eslint-disable-next-line
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            menuIsOpen={false}
            classNamePrefix={testId}
            className={`${testId} select-react-hook-form  ${
              _.get(errors, name) && 'validation-error'
            }`}
            isClearable={isClearable}
            isMulti={isMulti}
            isDisabled={isDisabled}
          />
          {_.get(errors, name) && <ErrorMsg>must select an element</ErrorMsg>}
        </SelectWrapper>
      )}
    />
  );
};

export default CreatableSelectWithController;
