import React, { useEffect, useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iBomItem from '../../../../../../types/product/iBomItem';
import iBomItemAttribute from '../../../../../../types/product/iBomItemAttribute';
import { iConfigColumn } from '../../../../../../shared/UITypes/types';
import { getRows } from '../../../../../../shared/tableHelper/SharedTableHelper';
import { getBOMs } from '../../../../../../services/BOMService';
import { apiErrorToast } from '../../../../../../shared/toast/Toast';
import { insertFlexToColumns } from '../../../../../../pages/contactCompany/list/ContactCompany.utils';
import { INSERT_INDEX, PRODBOM_COPY_FROM_COLUMS } from './ProdBOM.constants';
import { currencyFormat, getHeads, handleNullException } from '../../../../../../services/UtilsService';
import MathHelper from '../../../../../../services/MathHelper';

const CopyFromBomList = ({
  sourceProductId,
  bomItemAttrCluster,
}: {
  sourceProductId: string;
  bomItemAttrCluster: Array<iBomItemAttribute>;
}) => {
  const [sourceProductBoms, setSourceProductBoms] = useState<iBomItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getFLexColumns = (): Array<iConfigColumn> =>
    bomItemAttrCluster.map((item: iBomItemAttribute) => ({
      key: item.id,
      name: item.name,
      dataSource: [],
      type:
        item.name.toUpperCase() === 'Extruder %'.toUpperCase() ? 'flex-column-postfix-percentage' : 'flex-column-text',
    }));

  const columns = insertFlexToColumns(getFLexColumns(), PRODBOM_COPY_FROM_COLUMS, INSERT_INDEX);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBOMs({ filter: `productId:${sourceProductId}` });
        setSourceProductBoms(data);

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        apiErrorToast(e);
      }
    };

    fetchData();
  }, [sourceProductId]);

  const renderFlexBomAttribute = (item: iBomItem, column: iConfigColumn) => {
    const { type, key, dataSource } = column;
    switch (type) {
      case 'flex-column-text':
        return (
          <div>
            {
              item.bomItemAttributes.find((bomItem: iBomItemAttribute) => bomItem.id === key)?.bomItemAttributeValues
                .value
            }
          </div>
        );

      case 'flex-column-postfix-percentage': {
        const value = item.bomItemAttributes.find((bomItem: iBomItemAttribute) => bomItem.id === key)
          ?.bomItemAttributeValues.value;
        if (value && !Number.isNaN(Number(value))) {
          return <div>{MathHelper.mul(Number(value), 100)} %</div>;
        }
        return <div />;
      }
      case 'mul-money': {
        return handleNullException(item, dataSource[0]) && handleNullException(item, dataSource[1]) ? (
          <div>
            {currencyFormat(
              MathHelper.mul(handleNullException(item, dataSource[0]), handleNullException(item, dataSource[1])),
            )}
          </div>
        ) : (
          <div />
        );
      }
      default:
        return <div />;
    }
  };

  return (
    <DynamicTable
      head={getHeads(columns, 'BOM')}
      rows={getRows({
        data: sourceProductBoms,
        columns,
        categories: [],
        renderSpecialContent: renderFlexBomAttribute,
      })}
      isLoading={isLoading}
      testId={'BOM-list-table-copyFrom'}
      defaultSortKey={'material.productCode'}
      defaultSortOrder={'ASC'}
    />
  );
};

export default CopyFromBomList;
