import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Spinner from '@atlaskit/spinner';
import DetailTabs from './DetailTabs';
import iProduct from '../../../../types/product/iProduct';
import { iAdvancedLabelValuePair, iLabelValuePair } from '../../../../shared/UITypes/types';
import ProdGeneralInfo from './tabContents/ProdGeneralInfo';
import { hasKey } from '../../../../services/UtilsService';
import { TabContentWrapper } from '../shared/ProductDetail.style';
import { COMPONENT_MAP, TAB_ITEMS, TAB_ITEMS_CONDITIONS } from '../constants';
import iChannel from '../../../../types/integration/iChannel';
import ChannelService from '../../../../services/integration/ChannelService';
import { apiErrorToast } from '../../../../shared/toast/Toast';
import ChannelScriptHelper from '../../../../shared/integrations/ChannelScriptHelper';

type iDetailLeftPanel = {
  product?: iProduct;
  selectedTab: string;
  onUpdateProduct: (name: string, newValue: string | number | boolean | null) => void;
  isDisabled?: boolean;
  onSwitchTab: (tab: string) => void;
};
const DetailLeftPanel = ({ product, selectedTab, onUpdateProduct, onSwitchTab, isDisabled }: iDetailLeftPanel) => {
  const [isLoading, setIsLoading] = useState(false);
  const [channelMap, setChannelMap] = useState<{ [key: string]: iChannel }>({});

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    ChannelService.getChannelsForSales(true, {
      include: 'script.type',
    })
      .then(resp => {
        if (isCanceled) return;
        setChannelMap(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          resp.reduce((map: any, channel: iChannel) => {
            return { ...map, [channel.id]: channel };
          }, {}),
        );
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [product]);

  const hiddenTabItemsByIsFor = () => {
    const tabs = Object.keys(channelMap).map(channelId => {
      const channel = channelMap[channelId];
      return {
        value: channel.id,
        label: (
          <div className={'tab-item-channel'}>
            {channel.script ? <img src={ChannelScriptHelper.getLogoUrl(channel.script)} alt={channel.name} /> : null}
            <div className={'channel-name'}>{channel.name}</div>
          </div>
        ),
      };
    });
    return [...TAB_ITEMS, ...tabs].filter((tab: iAdvancedLabelValuePair) => {
      const condition = TAB_ITEMS_CONDITIONS.find((con: iLabelValuePair) => con.value === tab.value);
      return !(condition && _.get(product, condition.label) === false);
    });
  };
  const actualTabItems = product?.isTemplate ? TAB_ITEMS : hiddenTabItemsByIsFor();

  const getTabContent = () => {
    if (hasKey(COMPONENT_MAP, selectedTab) && actualTabItems.find(item => item.value === selectedTab)) {
      const Component = COMPONENT_MAP[selectedTab];
      return <Component product={product} onUpdateProduct={onUpdateProduct} isDisabled={isDisabled} />;
    }

    if (hasKey(channelMap, selectedTab)) {
      const channel = channelMap[selectedTab];
      if (!product || !channel || !channel.script) {
        return null;
      }
      return ChannelScriptHelper.getChannelProductEditPanelComponent({
        channel,
        product,
        channelScript: channel.script,
        isDisabled,
      });
    }
    return <ProdGeneralInfo product={product} onUpdateProduct={onUpdateProduct} isDisabled={isDisabled} />;
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <DetailTabs tabItems={actualTabItems} onSwitch={onSwitchTab} selected={selectedTab} />
      <TabContentWrapper>{getTabContent()}</TabContentWrapper>
    </>
  );
};

export default DetailLeftPanel;
