import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import _ from 'lodash';
import arrayToTree from 'array-to-tree';
import styled from 'styled-components';
// eslint-disable-next-line
import TableTree, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Cell,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Header,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Headers,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Row,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Rows,
} from '@atlaskit/table-tree';
import Button from '@atlaskit/button';
import { useSelector } from 'react-redux';
import GeneralPrintBtn from '../../shared/buttons/GeneralPrintBtn';
import ColorfulDotIcon from '../../shared/icons/ColorfulDotIcon';
import DeleteByRetype from '../../shared/deleteWithReType/DeleteByReType';
import CreateWarehouse from './list/popup/CreateWarehouse';
import RedirectToCreatedModal from './list/popup/RedirectToCreatedModal';
import MoreIconPopup from '../../shared/popup/MoreIconPopup';
import ComposeSecondaryText from '../../shared/text/ComposeSecondaryText';
import BackendPage from '../../layout/BackendPage';
import PlusIconAfterTitle from '../../components/settings/PlusIconAfterTitle';
import ChangeAllowPartsModal from './list/popup/ChangeAllowPartsModal';
import FacilityModal from './list/facility/FacilityModal';
import WarningModal from '../../shared/modal/WarningModal';
import LinkBtnUrl from '../../shared/buttons/LinkBtnUrl';
import TextBadge from './list/TextBadge';
import FacilityIcon from './list/facility/FacilityIcon';
import MoveWarehouseModal from './list/popup/MoveWarehouseModal';
import SpinWhenLoading from '../../shared/spin/SpinWhenLoading';
import iFacility from '../../types/warehouse/iFacility';
import iWarehouseLocation from '../../types/warehouse/iWarehouse';
import { compareStr } from '../../services/UtilsService';
import { DELETE } from '../../shared/constants/ActionConstants';
import { printLocationLabel } from '../../services/PrintService';
import { WAREHOUSE_URL } from '../../shared/UrlMap';
import { PopUpContentContainer, PrintBtnName } from '../../shared/styles/styles';
import { addToastForAPIResponse, apiErrorToast } from '../../shared/toast/Toast';
import { iWarehouseUI, iWLDropdownItem } from './type/types';
import { createFacility, deactivateFacility, getFacilityDetail, updateFacility } from '../../services/FacilityService';
import {
  ALLOW_PARTS,
  CREATE_EDIT_FACILITY,
  CREATE_SUB_LOCATION,
  EDIT_ALLOW_PART,
  MOVE_WAREHOUSE,
  PRINT_LOCATION_LABEL,
} from './shared/Warehouse.constants';
import {
  createWarehouse,
  deactivateWarehouse,
  getWarehouseDetail,
  getWarehousesByParentId,
  moveWarehouse,
  updateWarehouse,
} from '../../services/WarehouseService';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_WAREHOUSES } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE, ACCESS_CAN_UPDATE } from '../../types/settings/UserAccess/iRoleAccess';

const WarehouseListWrapper = styled.div`
  div[role='row'] {
    align-items: center;
    min-height: 60px;
  }
`;
type iState = {
  items: Array<iWarehouseLocation>;
  isLoading: boolean;
  deleteTarget?: iWarehouseUI;
  isCreateModalOpen?: boolean;
  isAllowPartsModalOpen?: boolean;
  isFacilityModalOpen?: boolean;
  isMoveModalOpen?: boolean;
  target?: iWarehouseUI;
  createAgainst?: iWarehouseUI;
  created?: iWarehouseLocation;
  moveFrom?: iWarehouseUI;
  facility?: iFacility;
  isConfirming: boolean;
};
const initialState: iState = {
  items: [],
  isLoading: true,
  isConfirming: false,
};

const WarehouseList = ({ id }: { id?: string }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [state, setState] = useState(initialState);
  const history = useHistory();

  const expandItem = async (parentItem: iWarehouseUI) => {
    // if (parentItem.children && parentItem.children.length > 0) return;
    setState({ ...state, isConfirming: true });
    try {
      const data: iWarehouseLocation[] = await getWarehousesByParentId(parentItem.id);
      const diff = data.filter((d: iWarehouseLocation) => {
        if (!parentItem.children || parentItem.children.length === 0) {
          return true;
        }
        return !parentItem.children.find((p: iWarehouseUI) => p.id === d.id);
      });
      setState({
        ...state,
        items: [...state.items, ...diff],
        isConfirming: false,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };

  useEffect(() => {
    let isCancelled = false;
    const loadTableData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const data: iWarehouseLocation[] = await getWarehousesByParentId();
        if (isCancelled) return;
        setState(preState => ({ ...preState, items: data, isLoading: false }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    loadTableData();
    return () => {
      isCancelled = true;
    };
  }, [id]);
  //  eslint-disable-next-line
  const updateLocalWarehouse = (newWarehouse: iWarehouseLocation) => {
    setState(preState => ({
      ...preState,
      isLoading: false,
      items: preState.items.map((i: iWarehouseLocation) => (newWarehouse.id === i.id ? newWarehouse : i)),
    }));
  };
  const allowDelete = (item: iWarehouseUI) => {
    if (
      (item.productsCount && item.productsCount > 0) ||
      item.hasChild ||
      (item.children && item.children.length > 0)
    ) {
      return false;
    }
    return true;
  };
  const onOpenDeleteModal = (item: iWarehouseUI) => {
    setState({ ...state, deleteTarget: item });
  };
  const onOpenCreateModal = (item?: iWarehouseUI) => {
    setState({ ...state, isCreateModalOpen: true, createAgainst: item });
  };
  const onOpenMoveModal = (item?: iWarehouseUI) => {
    setState({ ...state, isMoveModalOpen: true, moveFrom: item });
  };
  const onOpenFacilityModal = async (item: iWarehouseUI) => {
    // the warehouse clicked on is not a facility
    if (!item.isFacility) {
      setState({ ...state, isFacilityModalOpen: true, target: item });
      return;
    }
    try {
      const facility = await getFacilityDetail(item.facilityId);
      setState({ ...state, isFacilityModalOpen: true, target: item, facility });
    } catch (error) {
      apiErrorToast(error);
    }
  };
  const onCloseModal = () =>
    setState({
      ...state,
      isLoading: false,
      deleteTarget: undefined,
      isCreateModalOpen: undefined,
      isMoveModalOpen: undefined,
      createAgainst: undefined,
      isAllowPartsModalOpen: undefined,
      isFacilityModalOpen: undefined,
      target: undefined,
      facility: undefined,
      moveFrom: undefined,
    });

  const onDelete = async (targetId?: string) => {
    if (!targetId) return;
    setState({ ...state, isConfirming: true });
    try {
      await deactivateWarehouse(targetId);
      setState({
        ...state,
        isConfirming: false,
        items: state.items.filter((w: iWarehouseLocation) => w.id !== targetId),
        deleteTarget: undefined,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({
        ...state,
        isConfirming: false,
      });
    }
  };
  // eslint-disable-next-line
  const onCreate = async (postData: any) => {
    setState({ ...state, isConfirming: true });
    try {
      const created = await createWarehouse({
        ...postData,
        parentId: state.createAgainst ? state.createAgainst.id : null,
      });
      let newItems = state.items;
      // change parent's hasChild
      if (state.createAgainst) {
        newItems = state.items.map((i: iWarehouseLocation) =>
          i.id === state.createAgainst?.id ? { ...i, hasChild: true } : i,
        );
      }
      setState({
        ...state,
        isConfirming: false,
        items: [...newItems, created],
        isCreateModalOpen: false,
        created,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({
        ...state,
        isConfirming: false,
      });
    }
  };
  const onRedirectToCreated = () => {
    if (!state.created) return;
    history.push(`${WAREHOUSE_URL}/${state.created.id}`);
  };
  const onOpenChangeAllowPartsModal = (target: iWarehouseUI) => {
    setState({ ...state, isAllowPartsModalOpen: true, target });
  };
  const onChangeAllowParts = async () => {
    if (!state.target) return;
    const targetItem = state.items.find((w: iWarehouseLocation) => w.id === state.target?.id);
    if (!targetItem) return;
    setState({ ...state, isConfirming: true });
    try {
      await updateWarehouse(targetItem.id, {
        allowParts: !targetItem.allowParts,
      });
      const newItem = { ...targetItem, allowParts: !targetItem.allowParts };
      setState({
        ...state,
        items: state.items.map((w: iWarehouseLocation) => (w.id === targetItem.id ? newItem : w)),
        isConfirming: false,
        isAllowPartsModalOpen: undefined,
        target: undefined,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };
  const getPageHeader = () => (
    <PlusIconAfterTitle
      pageTitle={'Warehouses'}
      onClick={() => onOpenCreateModal()}
      isDisabled={!AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_CREATE, user)}
    />
  );
  const reloadWarehouse = async (reloadId: string) => {
    const reloaded = await getWarehouseDetail(reloadId);
    setState({
      ...state,
      items: state.items.map((i: iWarehouseLocation) => (i.id === reloaded.id ? reloaded : i)),
      isConfirming: false,
      isFacilityModalOpen: false,
      facility: undefined,
    });
  };
  //  eslint-disable-next-line
  const onOperateFacility = async (data: any) => {
    if (typeof state.target === 'undefined') {
      return;
    }
    setState({ ...state, isConfirming: true });
    try {
      // update facility
      if (data !== null && typeof state.facility !== 'undefined') {
        await updateFacility(state.facility.id, data);
        setState({
          ...state,
          isConfirming: false,
          isFacilityModalOpen: false,
          facility: undefined,
        });
        return;
      }
      // deactivate facility
      if (data === null && typeof state.facility !== 'undefined') {
        await deactivateFacility(state.facility.id);
        await updateWarehouse(state.target.id, {
          facilityId: null,
        });
        await reloadWarehouse(state.target.id);
      }
      // create facility
      if (data !== null && typeof state.facility === 'undefined') {
        const facility = await createFacility(data);
        await updateWarehouse(state.target.id, {
          facilityId: facility.id,
        });
        await reloadWarehouse(state.target.id);
      }
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };
  /**
   * to-do: after move
   * 1. moveFrom.parentId, then get parameter for expandItem()
   * 2. moveTo, repeat 1
   */
  //  eslint-disable-next-line
  const onMoveWarehouse = async (data: any) => {
    //  both from and to must exist
    if (typeof state.moveFrom === 'undefined') {
      return;
    }
    setState({ ...state, isConfirming: true });
    try {
      const movedItem: iWarehouseLocation = await moveWarehouse(data);
      let newItems = [...state.items];
      //  scenario: from's parent does not have any child after move
      if (state.moveFrom.parentId) {
        const reloadFromParent: iWarehouseLocation = await getWarehouseDetail(state.moveFrom.parentId);
        newItems = newItems.map((w: iWarehouseLocation) => (w.id === reloadFromParent.id ? reloadFromParent : w));
      }
      setState({
        ...state,
        items: newItems.map((w: iWarehouseLocation) => (w.id === movedItem.id ? movedItem : w)),
        isConfirming: false,
        isMoveModalOpen: undefined,
        moveFrom: undefined,
      });
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
      setState({
        ...state,
        isConfirming: false,
      });
    }
  };
  const renderDropdownItems = (items: Array<iWLDropdownItem>) => {
    return (
      <PopUpContentContainer>
        {items
          .filter(item => item.isHidden !== true)
          .map((item: iWLDropdownItem) => {
            switch (item.type) {
              case 'PrintBtn':
                return (
                  <GeneralPrintBtn serviceFunc={() => item.onClick()} key={item.label}>
                    <PrintBtnName>{item.label}</PrintBtnName>
                  </GeneralPrintBtn>
                );
              default:
                return (
                  <Button className={'popup-item'} onClick={() => item.onClick()} testId={item.label} key={item.label}>
                    {item.label}
                  </Button>
                );
            }
          })}
      </PopUpContentContainer>
    );
  };
  const listDropdownItems = (item: iWarehouseUI) => {
    return [
      {
        label: CREATE_SUB_LOCATION,
        onClick: () => onOpenCreateModal(item),
        type: 'LinkBtn',
        isHidden: !AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_CREATE, user),
      },
      {
        label: MOVE_WAREHOUSE,
        onClick: () => onOpenMoveModal(item),
        type: 'LinkBtn',
        isHidden: !AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_UPDATE, user),
      },
      {
        label: PRINT_LOCATION_LABEL,
        onClick: () => printLocationLabel(item.id),
        type: 'PrintBtn',
      },
      {
        label: CREATE_EDIT_FACILITY,
        onClick: () => onOpenFacilityModal(item),
        type: 'LinkBtn',
        isHidden: !AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_DELETE, user),
      },
      {
        label: EDIT_ALLOW_PART,
        onClick: () => onOpenChangeAllowPartsModal(item),
        type: 'LinkBtn',
        isHidden: !AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_UPDATE, user),
      },
      {
        label: DELETE,
        onClick: () => onOpenDeleteModal(item),
        type: 'LinkBtn',
        isHidden: !AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_DELETE, user),
      },
    ];
  };
  if (state.isLoading) {
    return <SpinWhenLoading />;
  }
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <WarehouseListWrapper className={'warehouse-list-wrapper'}>
        <TableTree>
          <Headers>
            <Header width={'50%'}>Name</Header>
            <Header width={'10%'}>Code</Header>
            <Header width={'10%'}>{ALLOW_PARTS}</Header>
            <Header width={'10%'}>No. of Products</Header>
            <Header width={'10%'}>Facility?</Header>
            <Header width={'10%'} />
          </Headers>
          <Rows
            items={arrayToTree(
              state.items.sort((a: iWarehouseLocation, b: iWarehouseLocation) => compareStr(a.name, b.name)),
              { parentProperty: 'parentId' },
            )}
            render={(item: iWarehouseUI) => (
              <Row
                expandLabel="Expand"
                collapseLabel="Collapse"
                itemId={item.id}
                onExpand={expandItem}
                items={item.children}
                hasChildren={item.hasChild || (item.children && item.children.length > 0)}
                testId={'warehouse-parent-item'}
                className={'table-tree-row'}
              >
                <Cell singleLine testId={`${item.id}-name`}>
                  <ComposeSecondaryText secondaryText={item.description}>
                    <LinkBtnUrl btnName={item.name} url={`${WAREHOUSE_URL}/${item.id}`} />
                  </ComposeSecondaryText>
                </Cell>
                <Cell singleLine>{item.locationCode}</Cell>
                <Cell singleLine>{item.allowParts && <ColorfulDotIcon />}</Cell>
                <Cell singleLine>
                  <TextBadge
                    badgeStr={item.productsCount ? item.productsCount.toString() : '0'}
                    badgeType={item.productsCount && item.productsCount > 0 ? 'primary' : 'default'}
                  />
                </Cell>
                <Cell singleLine>{item.isFacility && <FacilityIcon />}</Cell>
                <Cell singleLine className={'warehouse-list-actions'}>
                  <MoreIconPopup>{renderDropdownItems(listDropdownItems(item))}</MoreIconPopup>
                </Cell>
              </Row>
            )}
          />
        </TableTree>
        <DeleteByRetype
          isOpen={state.deleteTarget && allowDelete(state.deleteTarget)}
          onClose={onCloseModal}
          onConfirm={() => onDelete(state.deleteTarget?.id)}
          answer={state.deleteTarget && state.deleteTarget.name}
          isConfirming={state.isConfirming}
        />
        <WarningModal isOpen={state.deleteTarget && !allowDelete(state.deleteTarget)} onCancel={onCloseModal} />
        <CreateWarehouse
          isOpen={state.isCreateModalOpen}
          onClose={onCloseModal}
          onConfirm={onCreate}
          isConfirming={state.isConfirming}
        />
        <RedirectToCreatedModal
          isOpen={!!state.created}
          onConfirm={onRedirectToCreated}
          onClose={() => setState({ ...state, created: undefined })}
          heading={`Location:${state.created?.name} created successfully`}
        />
        <ChangeAllowPartsModal
          isOpen={!!state.isAllowPartsModalOpen && !!state.target}
          onConfirm={onChangeAllowParts}
          onClose={onCloseModal}
          isConfirming={state.isConfirming}
          warehouse={state.target}
        />
        <FacilityModal
          isOpen={!!state.isFacilityModalOpen}
          warehouse={state.target}
          facility={state.facility}
          isConfirming={state.isConfirming}
          onConfirm={onOperateFacility}
          onClose={onCloseModal}
        />
        {state.moveFrom && (
          <MoveWarehouseModal
            isOpen={!!state.isMoveModalOpen}
            isConfirming={state.isConfirming}
            onConfirm={onMoveWarehouse}
            onClose={onCloseModal}
            moveFrom={state.moveFrom}
          />
        )}
      </WarehouseListWrapper>
    </BackendPage>
  );
};
export default WarehouseList;
