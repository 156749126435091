import React, { useState } from 'react';
import _ from 'lodash';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import UserAvatarUpload from '../../user/UserAvatarUpload';
import { ErrorMsg } from '../../styles/styles';

const AvatarWithController = ({
  name,
  control,
  defaultValue,
  onChange,
  errors,
}: {
  name: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: string;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
}) => {
  const [isUploading, setIsUpLoading] = useState(false);
  const handleValueChange = (nameAlias: string, newValue: string) => {
    onChange(nameAlias, newValue, { shouldValidate: true });
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      rules={{
        validate: () => !isUploading,
      }}
      render={() => (
        <>
          <UserAvatarUpload
            name={name}
            defaultValue={defaultValue}
            onConfirm={handleValueChange}
            checkIsLoading={setIsUpLoading}
          />
          {_.get(errors, name) && (
            <ErrorMsg>Please wait images uploaded.</ErrorMsg>
          )}
        </>
      )}
    />
  );
};

export default AvatarWithController;
