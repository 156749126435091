import React, { useState } from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import CustomizeModal from '../../../../../../shared/modal/CustomizeModal';
import {
  MODAL_BODY_DESC,
  MODAL_BODY_TITLE,
} from '../../../shared/DataImport.constants';

const ActionButtons = ({
  btnName,
  modalHeading,
  onSubmit,
  isDisabled,
}: {
  btnName: string;
  modalHeading: string | React.ReactNode;
  onSubmit: () => void;
  isDisabled: boolean;
}) => {
  const [state, setState] = useState(false);
  const onConfirm = () => {
    setState(false);
    onSubmit();
  };
  return (
    <>
      <ButtonGroup>
        <Button
          appearance={'primary'}
          onClick={() => setState(true)}
          isDisabled={isDisabled}
        >
          {btnName}
        </Button>
      </ButtonGroup>
      <CustomizeModal
        isOpen={state}
        modalHeading={modalHeading}
        modalBody={
          <SectionMessage title={MODAL_BODY_TITLE} appearance={'warning'}>
            {MODAL_BODY_DESC}
          </SectionMessage>
        }
        onConfirm={onConfirm}
        onCancel={() => setState(false)}
      />
    </>
  );
};

export default ActionButtons;
