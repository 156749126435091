import { DraggableLocation } from 'react-beautiful-dnd';
import iJob from '../../../../types/job/iJob';
import { DATE_SHIFT_CONNECTOR, UNSCHEDULED } from './Schedule.constant';
import { iGroupPutReq, iScheduled, iShiftJob } from './Schedule.type';

export const abbreviateName = (firstName?: string, lastName?: string) => {
  if (!firstName || !lastName) {
    return 'N/A';
  }
  const fn = firstName.slice(0, 1).toUpperCase();
  const ln = lastName.slice(0, 1).toUpperCase();
  return `${fn}${ln}`;
};

/**
 * Move an item in a list
 */
export const reorder = (
  list: iJob[],
  startIndex: number,
  endIndex: number,
): iJob[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
export const move = (
  source: iJob[],
  destination: iJob[],
  //  eslint-disable-next-line
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
  draggableId: string,
) => {
  const sourceClone = [...source].filter((job: iJob) => job.id !== draggableId);
  const removed = [...source].find((job: iJob) => job.id === draggableId);
  const destClone = [...destination];
  // const [removed] = sourceClone.splice(droppableSource.index, 1);
  if (typeof removed !== 'undefined') {
    destClone.splice(droppableDestination.index, 0, removed);
  }

  return { newSourceJobs: sourceClone, newDestJobs: destClone };
};

export const extractDateShift = (
  source: DraggableLocation,
  destination: DraggableLocation,
) => {
  const sDate = source.droppableId.split(DATE_SHIFT_CONNECTOR)[0];
  const sShift = source.droppableId.split(DATE_SHIFT_CONNECTOR)[1];

  const dDate = destination.droppableId.split(DATE_SHIFT_CONNECTOR)[0];
  const dShift = destination.droppableId.split(DATE_SHIFT_CONNECTOR)[1];
  return { sDate, sShift, dDate, dShift };
};

export const isUnscheduled = (droppableId: string) =>
  droppableId.includes(UNSCHEDULED);

export const updatedScheduledDate = ({
  previous,
  fromGroup,
  newSourceJobs,
  targetGroup,
  newDestJobs,
}: {
  previous: Array<iScheduled>;
  fromGroup?: iGroupPutReq;
  newSourceJobs: Array<iJob>;
  targetGroup?: iGroupPutReq;
  newDestJobs: Array<iJob>;
}) => {
  let clone = [...previous];
  if (typeof fromGroup !== 'undefined') {
    clone = clone.map((s: iScheduled) =>
      s.date === fromGroup.shiftDate
        ? {
            ...s,
            shifts: s.shifts.map((shift: iShiftJob) =>
              shift.id === fromGroup.shiftId
                ? { ...shift, jobs: newSourceJobs }
                : shift,
            ),
          }
        : s,
    );
  }
  if (typeof targetGroup !== 'undefined') {
    clone = clone.map((s: iScheduled) =>
      s.date === targetGroup.shiftDate
        ? {
            ...s,
            shifts: s.shifts.map((shift: iShiftJob) =>
              shift.id === targetGroup.shiftId
                ? { ...shift, jobs: newDestJobs }
                : shift,
            ),
          }
        : s,
    );
  }
  return clone;
};

export const getItemStyle = (
  // eslint-disable-next-line
  draggableStyle: any,
  isDragging: boolean,
  moveDisabled: boolean,
) => ({
  userSelect: 'none',
  margin: '0 0 8px 0',
  //  eslint-disable-next-line
  background: moveDisabled
    ? 'rgb(255, 250, 230)'
    : isDragging
    ? 'lightGreen'
    : 'white',
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean, isDisable: boolean) => ({
  //  eslint-disable-next-line
  background: isDisable
    ? 'rgb(255, 250, 230)'
    : isDraggingOver
    ? 'lightblue'
    : 'rgb(244, 245, 247)',
  padding: 8,
  width: 220,
  height: '100%',
});
