import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iBomItemAttribute from '../../types/product/iBomItemAttribute';
import InputWithController from '../../shared/form/hookForms/InputWithController';

const BomAttributeEditModal = ({
  control,
  errors,
  onChange,
  target,
}: {
  //    eslint-disable-next-line
      control: Control<Record<string, any>>;
  //    eslint-disable-next-line
      onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
      errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iBomItemAttribute;
}) => {
  return (
    <Page>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'name'}
            label={'Attribute Name'}
            defaultValue={target?.name}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'bomAttribute-manage-modal-name'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default BomAttributeEditModal;
