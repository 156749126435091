/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { ButtonGroup } from '@atlaskit/button';
import { GridColumn } from '@atlaskit/page';

import iJob from '../../../types/job/iJob';
import { apiErrorToast } from '../../toast/Toast';
import { JOB_REPORT_FILE_NAME, PER_PAGE } from '../constants';
import { iJobPagination } from './types';
import JobListTable from './table/JobListTable';
import CustomizePagination from '../../pagination/CustomizePagination';
import DeleteByRetype from '../../deleteWithReType/DeleteByReType';
import iJobAttribute from '../../../types/job/iJobAttribute';
import useColumnHooks from '../../hooks/useShowHideColumns';
import { iColumnsProps } from '../../UITypes/types';
import iProductAttribute from '../../../types/product/iProductAttribute';
import ShowHideColumns from '../../showHideColumns/ShowHideColumns';
import { FlexContainer } from '../../styles/styles';
import { getReportArrSegments } from './Jobs.helper';
import ExportReport from '../../exportReport/ExportReport';
import BackendPage from '../../../layout/BackendPage';
import PaginationDetail from '../../pagination/paginationDetail/PaginationDetail';
import JobService from '../../../services/JobService';
import JobAttributeService from '../../../services/JobAttributeService';
import ProductAttributeService from '../../../services/product/ProductAttributeService';

type iState = {
  isLoading: boolean;
  total: number;
  jobList: Array<iJob>;
  currentPage: number;
  from: number;
  to: number;
};

const initialState: iState = {
  isLoading: true,
  total: 0,
  jobList: [],
  currentPage: 1,
  from: 1,
  to: 1,
};

type iEditInitialState = {
  targetJob?: iJob;
  isConfirming: boolean;
  currentPage: number;
  version: number;
};

const mapJobAttributeForColumns = (attrs: Array<iJobAttribute>) => attrs.map((attr: iJobAttribute) => attr.name);

const mapProdAttributeForColumns = (prodAttrs: Array<iProductAttribute>) => {
  const attrs = prodAttrs.map((attr: iProductAttribute) => attr.name);
  return _.uniq(attrs).sort();
};

const Jobs = ({
  isIndependent,
  getHeader,
  config,
  columns,
  version,
  canDelete = true,
}: {
  isIndependent: boolean;
  getHeader?: (children?: React.ReactNode) => React.ReactNode;
  config: {
    filter?: string;
    like?: string;
    sort?: string;
  };
  columns: iColumnsProps;
  version?: number;
  canDelete?: boolean;
}) => {
  const editInitialState: iEditInitialState = {
    isConfirming: false,
    currentPage: 1,
    version: version || 0,
  };

  const [state, setState] = useState(initialState);
  const [edit, setEdit] = useState(editInitialState);
  const [jobAttributes, setJobAttributes] = useState<Array<iJobAttribute>>([]);
  const [prodAttributes, setProdAttributes] = useState<Array<iProductAttribute>>([]);
  const [selectedColumns, onToggleColumn] = useColumnHooks(columns.name, {
    default: columns.default,
    whole: [
      ...columns.mustBefore,
      ...columns.positionFixed,
      ...mapJobAttributeForColumns(jobAttributes),
      ...mapProdAttributeForColumns(prodAttributes),
      ...columns.mustAfter,
    ],
  });

  useEffect(
    () => {
      let isCancelled = false;
      const fetchJobs = async () => {
        setState(preState => ({ ...preState, isLoading: true }));
        try {
          const jobWithPagination: iJobPagination = await JobService.getJobs(edit.currentPage, PER_PAGE, config);
          if (isCancelled) return;

          // if overpaged, roll back to page 1
          if (jobWithPagination.data.length === 0 && jobWithPagination.total !== 0) {
            setEdit({ ...edit, currentPage: 1 });
          } else {
            setState(preState => ({
              ...preState,
              jobList: jobWithPagination.data,
              total: jobWithPagination.total,
              currentPage: jobWithPagination.currentPage,
              isLoading: false,
              from: jobWithPagination.from,
              to: jobWithPagination.to,
            }));
          }
        } catch (error) {
          if (isCancelled) return;
          apiErrorToast(error);
          setState(preState => ({ ...preState, isLoading: false }));
        }
      };
      fetchJobs();
      return () => {
        isCancelled = true;
      };
    },
    //  eslint-disable-next-line
    [JSON.stringify(config), edit.currentPage, edit.version, version],
  );

  useEffect(() => {
    let isCancelled = false;
    const fetchJobAttributes = async () => {
      try {
        const jobAttrs = await JobAttributeService.getJobAttributes();
        const prodAttrs = await ProductAttributeService.getProdAttributes();
        if (isCancelled) return;
        setJobAttributes(jobAttrs);
        setProdAttributes(prodAttrs);
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setJobAttributes([]);
      }
    };
    fetchJobAttributes();
    return () => {
      isCancelled = true;
    };
  }, []);

  const paginationReload = (page: number) => setEdit({ ...edit, currentPage: page });

  const paginationReloadToFirstPage = () => setEdit({ ...edit, currentPage: 1, version: edit.version + 1 });

  const onDeleteJob = async (jobId?: string) => {
    if (!jobId) return;
    setEdit({ ...edit, isConfirming: true });
    try {
      await JobService.deactivateJob(jobId);
      setEdit({
        ...edit,
        isConfirming: false,
        targetJob: undefined,
      });

      setState({
        ...state,
        jobList: state.jobList.filter((j: iJob) => j.id !== jobId),
      });
    } catch (error) {
      apiErrorToast(error);
      setEdit({
        ...edit,
        isConfirming: false,
      });
    }
  };
  const onOpenDeleteJobModal = (job: iJob) =>
    setEdit({
      ...edit,
      targetJob: job,
    });
  const onCloseDeleteJobModal = () =>
    setEdit({
      ...edit,
      targetJob: undefined,
    });

  const columnsListForShowHide = [
    {
      label: 'Job',
      value: [...columns.positionFixed, ...mapJobAttributeForColumns(jobAttributes)],
    },
    {
      label: 'Product',
      value: mapProdAttributeForColumns(prodAttributes),
    },
  ];

  const getJobListBody = () => (
    <div data-testid={'jobs-wrapper'}>
      <FlexContainer className={'space-between space-below'}>
        {!!state.total && !!state.from && !!state.to ? (
          <PaginationDetail
            from={state.from}
            to={state.to}
            total={state.total}
            onRefreshResults={paginationReloadToFirstPage}
          />
        ) : (
          <div />
        )}
        <ButtonGroup>
          <ShowHideColumns
            onToggleColumns={onToggleColumn}
            selectedColumns={selectedColumns}
            columnLists={columnsListForShowHide}
          />
        </ButtonGroup>
      </FlexContainer>
      <JobListTable
        isLoading={state.isLoading}
        jobList={state.jobList}
        onOpenDeleteJobModal={onOpenDeleteJobModal}
        columns={selectedColumns.filter((col: string) => {
          if (canDelete) {
            return true;
          }
          return col.trim().toLowerCase() !== 'operation';
        })}
        prodAttrs={prodAttributes}
      />
      <CustomizePagination
        range={Math.ceil(state.total / PER_PAGE)}
        onChange={paginationReload}
        currentPage={state.currentPage}
      />
      <DeleteByRetype
        isOpen={!!edit.targetJob}
        onClose={onCloseDeleteJobModal}
        onConfirm={() => onDeleteJob(edit.targetJob?.id)}
        answer={edit.targetJob?.jobNumber}
        isConfirming={edit.isConfirming}
      />
    </div>
  );

  return isIndependent ? (
    getJobListBody()
  ) : (
    <BackendPage
      pageHeader={
        getHeader &&
        getHeader(
          <GridColumn medium={0.5}>
            <ExportReport
              className={'margin-above margin-left'}
              total={state.total}
              perPage={PER_PAGE}
              config={config}
              onFormatReport={jobs =>
                getReportArrSegments(
                  jobs,
                  selectedColumns.filter((columnName: string) => columnName !== 'Operation'),
                  prodAttributes,
                )
              }
              reportFileName={JOB_REPORT_FILE_NAME}
            />
          </GridColumn>,
        )
      }
    >
      {getJobListBody()}
    </BackendPage>
  );
};

export default Jobs;
