import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import { CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE } from '../../../../types/integration/iChannelScript';
import { iWooCommerceProductEdit } from '../../../integrations/ChannelScriptHelperTypes';
import { iChannelEntity } from '../../../../types/integration/iChannelEntity';
import { apiErrorToast } from '../../../toast/Toast';
import WooCommerceProductCreatePanel from './WooCommerceProductCreatePanel';
import ChannelEntityService from '../../../../services/integration/ChannelEntityService';
import WooCommerceProductEditPanel from './WooCommerceProductEditPanel';

const Wrapper = styled.div`
  .title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

const WooCommerceProductDetails = ({
  channel,
  channelScript,
  product,
  onSavedSuccess,
  isDisabled,
}: iWooCommerceProductEdit) => {
  const [isLoading, setIsLoading] = useState(false);
  const [channelEntities, setChannelEntities] = useState<iChannelEntity[]>([]);
  const [forceReload, setForceReload] = useState(0);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    ChannelEntityService.getChannelEntities(channel.id, 'Product', product.id)
      .then(resp => {
        if (isCanceled) return;
        setChannelEntities(resp);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [channel.id, product.id, forceReload]);

  if (
    !channel.isOnBoarded ||
    channelScript.script !== CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE ||
    channel.scriptId !== channelScript.id
  ) {
    return null;
  }

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (channelEntities.length === 0) {
      return (
        <WooCommerceProductCreatePanel
          channel={channel}
          product={product}
          onSaved={channelEntity => {
            // setChannelEntities([channelEntity]);
            setForceReload(forceReload + 1);
            if (onSavedSuccess) {
              onSavedSuccess(channelEntity);
            }
          }}
        />
      );
    }
    return (
      <WooCommerceProductEditPanel
        channel={channel}
        product={product}
        onSaved={channelEntity => {
          // setChannelEntities([channelEntity]);
          setForceReload(forceReload + 1);
          if (onSavedSuccess) {
            onSavedSuccess(channelEntity);
          }
        }}
        channelEntity={channelEntities[0]}
        isDisabled={isDisabled}
      />
    );
  };

  return <Wrapper>{getContent()}</Wrapper>;
};

export default WooCommerceProductDetails;
