import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iJobAttribute from '../../types/job/iJobAttribute';
import InputWithController from '../../shared/form/hookForms/InputWithController';
import { handleNullException } from '../../services/UtilsService';
import AsyncSelectWithController from '../../shared/form/hookForms/AsyncSelectWithController';
import { getJobAttributeTypes } from '../../services/JobAttributeService';

const JobAttributeEditModal = ({
  control,
  errors,
  onChange,
  target,
}: {
  //    eslint-disable-next-line
    control: Control<Record<string, any>>;
  //    eslint-disable-next-line
    onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
    errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iJobAttribute;
}) => {
  return (
    <Page>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'name'}
            label={'Attribute Name'}
            defaultValue={target?.name}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'settings-jobAttribute-modal-name'}
          />
        </GridColumn>
        <GridColumn medium={6}>
          {/* <SelectWithController
            name={'type'}
            label={'Type'}
            defaultValue={handleNullException(target, 'type')}
            options={attributeOptions}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            isClearable
            testId={'settings-jobAttribute-modal-type'}
          /> */}
          <AsyncSelectWithController
            name={'type'}
            label={'Type'}
            defaultValue={{
              label: handleNullException(target, 'type'),
              value: handleNullException(target, 'type'),
            }}
            control={control}
            onChange={onChange}
            errors={errors}
            promiseFn={getJobAttributeTypes}
            isRequired
            isCreatable={false}
            testId={'settings-jobAttribute-modal-type'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default JobAttributeEditModal;
