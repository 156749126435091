import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { PopupSelect } from '@atlaskit/select';
import SectionMessage from '@atlaskit/section-message';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import iChannel from '../../../../types/integration/iChannel';
import WooCommerceConnectorService from '../../../../services/integration/connectors/WooCommerceConnectorService';
import { apiErrorToast } from '../../../toast/Toast';
import EntityStatusService from '../../../../services/EntityStatusService';
import iEntityStatus from '../../../../types/status/iEntityStatus';
import { FlexContainer } from '../../../styles/styles';
import StatusLozenge from '../../../statusComponent/StatusLozenge';
import ChannelService from '../../../../services/integration/ChannelService';
import WorkflowService from '../../../../services/WorkflowService';

type iWooCommerceOrderStatusMapper = {
  channel: iChannel;
  onSaved: (updatedChannel: iChannel) => void;
};

const Wrapper = styled.div`
  .title-wrapper {
    margin-bottom: 0.4rem;
  }

  .woo-status {
    font-weight: bold;
    text-transform: uppercase;
    width: 8rem;
    &.title {
      font-size: 9px;
    }
  }
  .becomes-icon {
    width: 2rem;
  }
  .order-status-selector-wrapper {
    width: 10rem;
    &.title {
      font-size: 9px;
      font-weight: bold;
    }
    .status-menu-button {
      width: 100%;
    }
    [data-testid='selected-status'] {
      justify-content: space-between;
      width: 10rem;
    }
  }

  .place-holder {
    padding: 4px;
    .text {
      color: rgb(153, 153, 153);
      font-weight: normal;
      font-size: 12px;
    }
  }
`;
const WooCommerceOrderStatusMapper = ({ channel, onSaved }: iWooCommerceOrderStatusMapper) => {
  const [isLoading, setIsLoading] = useState(false);
  const [wooCommerceStatuses, setWooCommerceStatuses] = useState<string[]>([]);
  const [salesOrderStatuses, setSalesOrderStatuses] = useState<iEntityStatus[]>([]);
  const [selectedStatusMap, setSelectedStatusMap] = useState<{ [key: string]: iEntityStatus }>({});
  const [defaultStatus, setDefaultStatus] = useState<iEntityStatus | null>(null);

  useEffect(() => {
    if (channel.isOnBoarded !== true) {
      return undefined;
    }

    let isCanceled = false;
    setIsLoading(true);

    Promise.all([
      WooCommerceConnectorService.getOrderStatuses(channel.id),
      EntityStatusService.getEntityStatuses({
        filter: 'entityStatusType.entityName:SalesOrder',
        include: 'entityStatusType,entityStatusCategory',
      }),
    ])
      .then(resp => {
        if (isCanceled) return;
        setWooCommerceStatuses(resp[0]);
        setSalesOrderStatuses(resp[1]);
        const currentStatusMap = channel.settings.statusMap || {};
        setSelectedStatusMap(
          Object.keys(currentStatusMap).reduce((map, wooStatusName) => {
            return {
              ...map,
              [wooStatusName]: resp[1].filter(
                (status: iEntityStatus) => status.id === `${currentStatusMap[wooStatusName] || ''}`,
              )[0],
            };
          }, {}),
        );
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [channel]);

  useEffect(() => {
    let isCanceled = false;
    const getData = async () => {
      try {
        setIsLoading(true);
        const statuses = await EntityStatusService.getEntityStatuses({
          filter: 'entityStatusType.entityName:SalesOrder',
          include: 'entityStatusType,entityStatusCategory',
        });
        if (isCanceled) return;
        setSalesOrderStatuses(statuses);
        const statusMap = statuses.reduce((map: { [key: string]: iEntityStatus }, status: iEntityStatus) => {
          return {
            ...map,
            [status.id]: status,
          };
        }, {});

        if (statuses.length > 0) {
          const workflows = await WorkflowService.getWorkflows({
            filter: `entityName:EntityStatusType,entityId:${statuses[0].entityStatusTypeId}`,
          }).then(({ data }) => data);
          const initStatusId = `${workflows[0].wf.initial || ''}`;
          setDefaultStatus(initStatusId in statusMap ? statusMap[initStatusId] : null);
        }

        setIsLoading(false);
      } catch (err) {
        apiErrorToast(err);
        setIsLoading(false);
      }
    };

    getData();
    return () => {
      isCanceled = true;
    };
  }, [channel]);

  const getOptions = () => {
    if (salesOrderStatuses.length <= 0) {
      return [];
    }
    return salesOrderStatuses
      .sort((status1, status2) => (status1.sortOrder > status2.sortOrder ? 1 : -1))
      .map(status => ({
        label: (
          <div>
            <StatusLozenge status={status} categoryCode={status.entityStatusCategory?.code} />
            <div>
              <small>{status.description}</small>
            </div>
          </div>
        ),
        value: status.id,
        status,
      }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (selectedOption: any, wooStatusName: string) => {
    setIsLoading(true);
    ChannelService.updateChannel(channel.id, {
      settings: {
        ...channel.settings,
        statusMap: {
          ...(channel.settings.statusMap || {}),
          [wooStatusName]: selectedOption.status.id,
        },
      },
    })
      .then(resp => {
        if (onSaved) {
          onSaved(resp);
        }
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMapTable = () => {
    if (wooCommerceStatuses.length <= 0) {
      return null;
    }
    return (
      <>
        <FlexContainer className={'with-gap'}>
          <div className={'woo-status title'}>WooCommerce Status</div>
          <div className={'becomes-icon title'} />
          <div className={'order-status-selector-wrapper title'}>SalesOrder Status</div>
        </FlexContainer>
        {wooCommerceStatuses.map(wooStatus => {
          return (
            <FlexContainer key={wooStatus} className={'with-gap space-below'}>
              <div className={'woo-status'}>{wooStatus}</div>
              <div className={'becomes-icon'}>
                <ArrowRightIcon label={'becomes to'} />
              </div>
              <div className={'order-status-selector-wrapper'}>
                <PopupSelect
                  isLoading={isLoading}
                  options={getOptions()}
                  onChange={value => handleSelect(value, wooStatus)}
                  target={({ ref }) => (
                    <Button testId="popup-trigger-btn" spacing={'none'} ref={ref} className={'status-menu-button'}>
                      {wooStatus in selectedStatusMap ? (
                        <StatusLozenge
                          testId={'selected-status'}
                          status={selectedStatusMap[wooStatus]}
                          categoryCode={selectedStatusMap[wooStatus]?.entityStatusCategory?.code}
                        >
                          <ChevronDownIcon label={'dropdown'} />
                        </StatusLozenge>
                      ) : (
                        <FlexContainer className={'place-holder'}>
                          <div className={'text'}>Please select a status </div>
                          <ChevronDownIcon label={'dropdown'} />
                        </FlexContainer>
                      )}
                    </Button>
                  )}
                  maxMenuWidth={'200'}
                  minMenuWidth={'120'}
                  className={'status-menu'}
                  classNamePrefix={'status-menu-prefix'}
                />
              </div>
            </FlexContainer>
          );
        })}
      </>
    );
  };

  const getContent = () => {
    if (defaultStatus) {
      return (
        <>
          <SectionMessage appearance={'change'}>
            <small>All orders coming from {channel.name} will be assigned to status: </small>
            <StatusLozenge categoryCode={defaultStatus.entityStatusCategory?.code}>
              {defaultStatus.name}
            </StatusLozenge>{' '}
            <small>by default, if not assigned/mapped below</small>
          </SectionMessage>
        </>
      );
    }
    return null;
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Wrapper>
      <div className={'title-wrapper'}>
        <p>
          <small>
            Each WooCommerce Statuses needs to be mapped with an Order Status below.
            <br />
            More details refer to:{' '}
            <a href={'https://woocommerce.com/document/managing-orders/'} target={'__blank'}>
              WooCommerce Order Statuses
            </a>
          </small>
        </p>
      </div>

      <FlexContainer className={'space-below'}>{getContent()}</FlexContainer>
      {getMapTable()}
    </Wrapper>
  );
};

export default WooCommerceOrderStatusMapper;
