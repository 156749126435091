import React from 'react';
import moment from 'moment';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';
import { iConfigColumn } from '../../despatchNote/shared/DispatchNote.type';
import SummaryPurchaseOrder from './SummaryPurchaseOrder';
import { formatDate } from '../../../services/UtilsService';
import { DATE_FORMAT } from '../shared/Dashboard.constants';

export const getRows = ({
  purchaseOrderMonth,
  columns,
  date,
}: {
  purchaseOrderMonth: Array<iPurchaseOrderDetail>;
  columns: Array<iConfigColumn>;
  date: string;
}) => {
  const getPuchaseOrderDay = (day: string) =>
    purchaseOrderMonth.filter(
      (item: iPurchaseOrderDetail) =>
        item.orderDate && moment(day).isSame(item.eta, 'day'),
    );

  const getCells = (
    purchaseOrders: Array<iPurchaseOrderDetail>,
    //  eslint-disable-next-line
    fixCell: any,
  ) =>
    columns.map((column: iConfigColumn) => {
      const { key: statusId, type } = column;
      if (type === 'sort-type') return fixCell;
      return {
        key: statusId,
        content: (
          <SummaryPurchaseOrder
            data={purchaseOrders.filter(
              (item: iPurchaseOrderDetail) => item.statusId === statusId,
            )}
          />
        ),
      };
    });

  return [
    {
      cells: getCells(getPuchaseOrderDay(date), {
        content: `ETA@${formatDate(date, DATE_FORMAT)}`,
      }),
    },
    {
      cells: getCells(purchaseOrderMonth, {
        content: `ETA in ${formatDate(date, 'MMM YYYY')}`,
      }),
    },
  ];
};
