import iBaseType from '../../iBaseType';
import iRoleAccess from './iRoleAccess';

export const ROLE_NAME_DEFAULT_ADMIN = 'Default Admin';

type iRole = iBaseType & {
  name: string;
  description: string | null;
  companyId: string;
  roleAccesses?: iRoleAccess[];
};

export default iRole;
