import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import CompanyLogo from '../../../shared/company/CompanyLogo';
import AppPageCentered from '../shared/AppPageCentered';
import LoginFormWithForgot from './LoginFormWithForgot';
// import { apiErrorToast } from '../../../shared/toast/Toast';
import ModulingService from '../../../services/ModulingService';

type iState = {
  companyId?: string;
  companyLogoUrl?: string;
  isLoading: boolean;
};
const initialState: iState = {
  isLoading: true,
};
const LoginPage = () => {
  const [state, setState] = useState(initialState);
  const getContent = () => {
    if (state.isLoading) {
      return <Spinner />;
    }
    // if (typeof state.companyId === 'undefined') {
    //   return <CompanyDomainUnknown />;
    // }
    return (
      <>
        <CompanyLogo src={state.companyLogoUrl} />
        <LoginFormWithForgot />
      </>
    );
  };
  useEffect(() => {
    let isCancelled = false;
    const exchangeDomainWithCompanyInfo = async () => {
      try {
        const result = await ModulingService.domainExchangeCompanyInfo(window.location.hostname);
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          ...result,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        // error is not necessary to be handled here
        // apiErrorToast(error);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
      }
    };
    exchangeDomainWithCompanyInfo();
    return () => {
      isCancelled = true;
    };
  }, []);
  return <AppPageCentered>{getContent()}</AppPageCentered>;
};
export default LoginPage;
