import React from 'react';
import { PRODUCTS_URL, WAREHOUSE_URL } from '../../../shared/UrlMap';
import { iConfigColumn } from '../../despatchNote/shared/DispatchNote.type';

export const DELETE_INVALID_TEXT = (
  <span>
    This warehouse owns children warehouses or has products stored there.
    <p> Please clear them up before you delete it</p>
  </span>
);
export const NOT_ALLOW_DELETE = 'Not allowed to delete it';

export const CREATE_SUB_LOCATION = 'Create a sub location';
export const CREATE_ROOT_WAREHOUSE = 'Create root warehouse';
export const MOVE_WAREHOUSE = 'Move the location';
export const PRINT_LOCATION_LABEL = 'Print location label';
export const CREATE_EDIT_FACILITY = 'Create/Edit facility';
export const EDIT_ALLOW_PART = 'Allow products or not';

export const ITEM_LOCATION_LIST_TITLE = 'Item Locations';
export const ITEM_LOCATION_TABLE_NAME = 'item-location-table';
export const ITEM_LOCATION_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.id', 'product.name'],
    isCompulsory: true,
    type: 'link-secondary',
    urlPrefix: PRODUCTS_URL,
  },
  {
    name: 'Location',
    key: 'warehouseLocation.name',
    dataSource: ['warehouseLocation.id', 'warehouseLocation.locationCode'],
    isCompulsory: true,
    type: 'link-secondary',
    urlPrefix: WAREHOUSE_URL,
  },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: ['qty'],
    isCompulsory: true,
    // isSortable: true,
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Unit',
    key: 'product.measurement.shortName',
    dataSource: ['product.measurement.shortName'],
    isCompulsory: true,
    // isSortable: true,
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Batch/Lot No. ',
    key: 'reference',
    dataSource: ['reference'],
    isCompulsory: true,
    // isSortable: true,
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    isSelected: false,
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    isSelected: true,
    group: '',
    isSortable: false,
  },
];

export const MOVE_WARNING_MESSAGE =
  'All sub locations(if it has) will be moved as well, please make sure you want to continue';

export const ALLOW_PARTS = 'Allow product transfer in';
