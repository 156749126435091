import React from 'react';
import { Label } from '@atlaskit/field-base';
import { Grid, GridColumn } from '@atlaskit/page';
import iJob from '../../../../types/job/iJob';
import InlineEditDateTimePicker from '../../../form/inlineEdit/InlineEditDateTimePicker';
import InlineEditSelectFetchOptions from '../../../form/InlineEditSelectFetchOptions';
import { iLabelValuePair } from '../../../contactCompany/types';
import { iDetailUpdateFn } from '../../../UITypes/types';
import { handleNullException } from '../../../../services/UtilsService';
import { getMachines } from '../../../../services/Settings/MachineService';
import { getPalletSystems } from '../../../../services/Settings/PalletSystemService';
import { getWrappingMethods } from '../../../../services/Settings/WrappingMethodService';

const MACHINE = 'Machine';
const PALLET_SYSTEM = 'Pallet System';
const SCHEDULED_AT = 'Scheduled At';
const WRAPPING_METHOD = 'Wrapping Method';
const columns: Array<iLabelValuePair> = [
  { label: MACHINE, value: 'machineId' },
  { label: SCHEDULED_AT, value: 'scheduledAt' },
  { label: PALLET_SYSTEM, value: 'palletSystemId' },
  { label: WRAPPING_METHOD, value: 'wrappingMethodId' },
];
const getClassName = (attrName: string) => {
  switch (attrName) {
    case SCHEDULED_AT:
      return '';
    default:
      return 'bold-text';
  }
};
const EditableColumns = ({
  detail,
  onUpdate,
  isDisabled,
}: {
  detail: iJob;
  onUpdate: iDetailUpdateFn;
  isDisabled?: boolean;
}) => {
  const onSelect = (fieldName: string, newValue: string | number | boolean | null) =>
    onUpdate({ [fieldName]: newValue });

  const getEditBody = (column: iLabelValuePair) => {
    switch (column.label) {
      case MACHINE:
        return (
          <InlineEditSelectFetchOptions
            name={column.value}
            defaultValue={handleNullException(detail, column.value)}
            promiseFn={getMachines}
            label={''}
            onSelect={onSelect}
            isDisabled={isDisabled}
          />
        );
      case PALLET_SYSTEM:
        return (
          <InlineEditSelectFetchOptions
            name={column.value}
            defaultValue={handleNullException(detail, column.value)}
            promiseFn={getPalletSystems}
            label={''}
            onSelect={onSelect}
            isClearable
            isDisabled={isDisabled}
          />
        );
      case WRAPPING_METHOD:
        return (
          <InlineEditSelectFetchOptions
            name={column.value}
            defaultValue={handleNullException(detail, column.value)}
            promiseFn={getWrappingMethods}
            label={''}
            onSelect={onSelect}
            isClearable
            isDisabled={isDisabled}
          />
        );
      case SCHEDULED_AT:
        return (
          <InlineEditDateTimePicker
            onConfirm={(name: string, newValue: string | null) => onUpdate({ [name]: newValue })}
            defaultValue={handleNullException(detail, column.value)}
            label={''}
            name={column.value}
            isDisabled={isDisabled}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {columns.map((column: iLabelValuePair) => (
        <Grid layout={'fluid'} key={`job-attributes-${column.label}`}>
          <GridColumn medium={1}>
            <Label label={column.label} />
          </GridColumn>
          <GridColumn medium={3}>
            <div className={getClassName(column.label)}>{getEditBody(column)}</div>
          </GridColumn>
        </Grid>
      ))}
    </>
  );
};

export default EditableColumns;
