import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import iJobStatus from '../../../types/job/iJobStatus';
import iProduct from '../../../types/product/iProduct';
import iUnitOfMeasurements from '../../../types/iUnitOfMeasurement';
import iStockForecast from '../../../types/product/iStockForecast';
import { iInsStocksState } from '../shared/Dashboard.types';
import CreatePO from './dashboardCreatePO/DashboardCreatePO';
import InsStocksTable from './InsStocksTable';
import PartitionTitle from '../shared/PartitionTitle';
import { getJobStatuses } from '../../../services/JobService';
import { PartitionBodyWrapper } from '../shared/Dashboard.style';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { getStockForecastConfig } from '../../../services/BOMService';
import { getUnits } from '../../../services/Settings/UnitMeasurementService';
import { FlexContainer, SpaceHorizontalContainer } from '../../../shared/styles/styles';
import { JOB_STATUS_CODE_NEW, JOB_STATUS_CODE_PLANNED } from '../../stockForecast/constants';
import {
  DASHBOARD_INSSTOCKS,
  DASHBOARD_INSSTOCKS_COLUMNS,
  DATE_FORMAT,
  DATE_PERIOD,
} from '../shared/Dashboard.constants';
import useRowCheckboxHook from '../../../shared/hooks/useRowCheckboxHook/useRowCheckboxHook';
import { renderSpecialHeads, renderSpecialRows } from './TableHelper';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PURCHASE_ORDERS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE } from '../../../types/settings/UserAccess/iRoleAccess';

const initialState: iInsStocksState = {
  isLoading: true,
  stocks: [],
  // selectedProducts: [],
};
const InsuffcientStocks = ({ date }: { date: string }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [state, setState] = useState(initialState);
  const dateFrom = moment(date).format(DATE_FORMAT);
  const dateTo = moment(date).add(DATE_PERIOD, 'days').format(DATE_FORMAT);
  const canCreatePO = AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_CREATE, user);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const jobStatus = await getJobStatuses({
          filter: `code:${JOB_STATUS_CODE_NEW}|${JOB_STATUS_CODE_PLANNED}`,
        });
        const measurements: Array<iUnitOfMeasurements> = await getUnits();
        const jobStatusIds = jobStatus.map((status: iJobStatus) => status.id).join(',');
        const stocks = await getStockForecastConfig({
          jobStatusIds,
          scheduledAt: `${dateFrom},${dateTo}`,
        });
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          stocks: stocks
            .filter((item: iStockForecast) => item.requiredQty > Number(item.product.stockOnHand))
            .map((item: iStockForecast) => ({
              ...item,
              product: {
                ...item.product,
                measurement: measurements.find((mItem: iUnitOfMeasurements) => mItem.id === item.product.measurementId),
              },
            })),
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [dateFrom, dateTo]);
  const { selectAll, selectOneElement, state: selectedProducts } = useRowCheckboxHook<iProduct>({
    whole: state.stocks.map((item: iStockForecast) => item.product),
  });
  return (
    <>
      <FlexContainer>
        <PartitionTitle title={DASHBOARD_INSSTOCKS} dateFrom={dateFrom} dateTo={dateTo} />
        <SpaceHorizontalContainer className={'quarter'} />
        <CreatePO products={selectedProducts} />
      </FlexContainer>
      <PartitionBodyWrapper>
        <InsStocksTable
          isLoading={state.isLoading}
          stocks={state.stocks}
          date={date}
          columns={DASHBOARD_INSSTOCKS_COLUMNS.filter(col => {
            if (canCreatePO) {
              return true;
            }
            return col.key !== 'rowCheckbox';
          })}
          renderSpecialHeads={() =>
            renderSpecialHeads({
              isSelected: selectedProducts.length === state.stocks.length && selectedProducts.length > 0,
              onSelect: selectAll,
            })
          }
          renderSpecialRows={renderSpecialRows({
            selected: selectedProducts,
            onSelect: selectOneElement,
          })}
        />
      </PartitionBodyWrapper>
    </>
  );
};

export default InsuffcientStocks;
