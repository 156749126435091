import { iKeyValuePairs } from '../shared/UITypes/types';
import AppService from './AppService';

export const getComments = (config?: iKeyValuePairs) => {
  return AppService.get('/comment', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const createComment = (postData: iKeyValuePairs) => {
  return AppService.post('/comment', postData).then(({ data }) => data);
};

export const updateComment = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/comment/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const deleteComment = (id: string) => {
  return AppService.delete(`/comment/${id}`).then(({ data }) => data);
};

export default {
  getComments,
  createComment,
  updateComment,
  deleteComment,
};
