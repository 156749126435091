import React from 'react';
import styled from 'styled-components';
import { handleNullException } from '../../../../services/UtilsService';
import iJob from '../../../../types/job/iJob';
import TextArea from '../../../form/TextArea';
import { iDetailUpdateFn } from '../../../UITypes/types';

export const JobNotesContainer = styled.div`
  width: 100%;
`;

const JobNotes = ({
  detail,
  onUpdate,
  isDisabled,
}: {
  detail: iJob;
  onUpdate: iDetailUpdateFn;
  isDisabled?: boolean;
}) => {
  return (
    <JobNotesContainer>
      <TextArea
        name={'memo'}
        label="Notes"
        defaultValue={handleNullException(detail, 'memo')}
        onChange={(name: string, newValue: string | null) => onUpdate({ [name]: newValue })}
        minimumRows={2}
        debouncePeriod={700}
        isDisabled={isDisabled}
      />
    </JobNotesContainer>
  );
};

export default JobNotes;
