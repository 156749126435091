import React, { useState } from 'react';
import styled from 'styled-components';
import InlineCheckbox from '../form/InlineCheckbox';
import iRoleAccess from '../../types/settings/UserAccess/iRoleAccess';
import { getDeleteBtn } from '../../components/settings/utils';
import RoleAccessService from '../../services/Settings/UserAccess/RoleAccessService';
import { apiErrorToast } from '../toast/Toast';

type iRoleAccessList = {
  roleAccesses?: iRoleAccess[];
  // any accessIds in admin accessIds will created with all admin access and disabled for editing
  adminAccessIds?: string[];
  onDeleted?: (roleAccess: iRoleAccess) => void;
  onUpdated?: (roleAccess: iRoleAccess) => void;
};
const Wrapper = styled.div`
  .access-name {
    width: calc(100% - 250px);
  }
  .op-btns,
  .can-read,
  .can-create,
  .can-update,
  .can-delete {
    width: 50px;
    text-align: center;
  }
  .access-row {
    :hover {
      background-color: #e1e1e1;
    }
    height: auto;
    .inline-checkbox-wrapper {
      margin: 0px;
      .inline-checkbox-label {
        display: none;
      }
      .inline-checkbox {
        padding: 0px;
        margin: 0px;
      }
    }
  }
`;
const RoleAccessList = ({ onDeleted, roleAccesses = [], onUpdated, adminAccessIds = [] }: iRoleAccessList) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleDelete = (roleAccessId: string) => {
    if (!onDeleted) {
      return;
    }
    setIsSaving(true);
    RoleAccessService.deleteRoleAccess(roleAccessId)
      .then(res => {
        setIsSaving(false);
        onDeleted(res);
      })
      .catch(err => {
        apiErrorToast(err);
      });
  };

  const handleFlagChanged = (roleAccess: iRoleAccess, fieldName: string, canChange: boolean) => {
    if (!onUpdated) {
      return;
    }
    const updatingFields = {
      [fieldName]: canChange,
    };
    if (canChange === true) {
      if (fieldName === 'canUpdate') {
        updatingFields.canRead = true;
      } else if (fieldName === 'canCreate') {
        updatingFields.canRead = true;
        updatingFields.canUpdate = true;
      } else if (fieldName === 'canDelete') {
        updatingFields.canRead = true;
        updatingFields.canCreate = true;
        updatingFields.canUpdate = true;
      }
    }
    setIsSaving(true);
    RoleAccessService.updateRoleAccess(roleAccess.id, {
      ...roleAccess,
      ...updatingFields,
    })
      .then(res => {
        setIsSaving(false);
        onUpdated(res);
      })
      .catch(err => {
        apiErrorToast(err);
      });
  };

  const getRolesAccesses = () => {
    return (
      <>
        {roleAccesses.map(roleAccess => {
          return (
            <tr key={roleAccess.id} className={'access-row'}>
              <td className={'access-name'}>
                {roleAccess.access?.name}
                <div>
                  <small>{roleAccess.access?.description}</small>
                </div>
              </td>
              <td className={'can-read'}>
                <InlineCheckbox
                  defaultValue={roleAccess.canRead}
                  name={'canRead'}
                  label={''}
                  isDisabled
                  onConfirm={(name, checked) => handleFlagChanged(roleAccess, 'canRead', checked)}
                />
              </td>
              <td className={'can-update'}>
                <InlineCheckbox
                  defaultValue={roleAccess.canUpdate}
                  name={'canUpdate'}
                  label={''}
                  isDisabled={
                    !onUpdated ||
                    isSaving ||
                    roleAccess.canCreate ||
                    roleAccess.canDelete ||
                    adminAccessIds?.indexOf(roleAccess.accessId) >= 0
                  }
                  onConfirm={(name, checked) => handleFlagChanged(roleAccess, 'canUpdate', checked)}
                />
              </td>
              <td className={'can-create'}>
                <InlineCheckbox
                  defaultValue={roleAccess.canCreate}
                  name={'canCreate'}
                  label={''}
                  isDisabled={
                    !onUpdated || isSaving || roleAccess.canDelete || adminAccessIds?.indexOf(roleAccess.accessId) >= 0
                  }
                  onConfirm={(name, checked) => handleFlagChanged(roleAccess, 'canCreate', checked)}
                />
              </td>
              <td className={'can-delete'}>
                <InlineCheckbox
                  defaultValue={roleAccess.canDelete}
                  name={'canDelete'}
                  label={''}
                  isDisabled={!onUpdated || isSaving || adminAccessIds?.indexOf(roleAccess.accessId) >= 0}
                  onConfirm={(name, checked) => handleFlagChanged(roleAccess, 'canDelete', checked)}
                />
              </td>
              {onDeleted && (
                <td className={'op-btns'}>
                  {isSaving || !onDeleted
                    ? null
                    : getDeleteBtn({
                        id: roleAccess.id,
                        answer: roleAccess.access?.name || 'N/A',
                        onClick: handleDelete,
                      })}
                </td>
              )}
            </tr>
          );
        })}
      </>
    );
  };
  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            <th className={'access-name'}>Access</th>
            <th className={'can-read'}>Read</th>
            <th className={'can-create'}>Update</th>
            <th className={'can-update'}>Create</th>
            <th className={'can-delete'}>Admin</th>
            {onDeleted && <th className={'op-btns'}> </th>}
          </tr>
        </thead>
        <tbody>{getRolesAccesses()}</tbody>
      </table>
    </Wrapper>
  );
};

export default RoleAccessList;
