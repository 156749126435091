import Toggle from '@atlaskit/toggle';
import React from 'react';
import { ToggleProps } from '@atlaskit/toggle/dist/types/types';

type iInlineToggle = ToggleProps;
const InlineToggle = (props: iInlineToggle) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Toggle {...props} />;
};

export default InlineToggle;
