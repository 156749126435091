import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Grid, GridColumn } from '@atlaskit/page';
import { LoadingButton } from '@atlaskit/button';
import iProduct from '../../types/product/iProduct';
import iStockForecast from '../../types/product/iStockForecast';
import iJobStatus from '../../types/job/iJobStatus';
import iUnitOfMeasurements from '../../types/iUnitOfMeasurement';
import BackendPage from '../../layout/BackendPage';
import MultiSelect from '../../shared/form/MultiSelect';
import DatePickerWithoutReadview from '../../shared/form/DatePickerWithoutReadview';
// import StockForecastTable from './StockForecastTable';
import ResetBtn from '../../shared/buttons/ResetBtn';
import InsStocksTable from '../dashboard/insufficientStocks/InsStocksTable';
import CreatePO from '../dashboard/insufficientStocks/dashboardCreatePO/DashboardCreatePO';
import { getStockForecast } from '../../services/BOMService';
import { apiErrorToast } from '../../shared/toast/Toast';
import { iLabelValuePair } from '../../shared/UITypes/types';
import { compareStr, getDaysMore, mapOptionToValue } from '../../services/UtilsService';
import { getUnits } from '../../services/Settings/UnitMeasurementService';
import { getProductTypeListAsyncSearch } from '../../services/product/ProductTypeService';
import { DASHBOARD_INSSTOCKS_COLUMNS } from '../dashboard/shared/Dashboard.constants';
import { getJobStatuses, getJobStatusListAsyncSearch } from '../../services/JobService';
import {
  AdvancedSearchSectionWrapper,
  FlexContainer,
  PageTitle,
  SpaceVerticalContainer,
} from '../../shared/styles/styles';
import { JOB_STATUS_CODE_NEW, JOB_STATUS_CODE_PLANNED, STOCK_FORECAST_PAGE_TITLE } from './constants';
import useRowCheckboxHook from '../../shared/hooks/useRowCheckboxHook/useRowCheckboxHook';
import { renderSpecialHeads, renderSpecialRows } from '../dashboard/insufficientStocks/TableHelper';

const LOCAL_STORAGE_KEY_FOR_JOB_STATUS_ID = 'stockForecast-selectedJobStatusIds';

const StockForecast = () => {
  const todayStr = moment(new Date()).format('YYYY-MM-DD');

  const [data, setData] = useState<Array<iStockForecast>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jobStatusIds, setJobStatusIds] = useState<iLabelValuePair[]>([]);
  const [productTypeIds, setProductTypeIds] = useState<iLabelValuePair[]>([]);
  const [dateFrom, setDateFrom] = useState<string | undefined>(todayStr);
  const [dateTo, setDateTo] = useState<string | undefined>(getDaysMore(todayStr, 7));
  const [measurements, setMeasurements] = useState<iUnitOfMeasurements[]>([]);
  const [isJobStatusValid, setIsJobStatusValid] = useState(true);
  const [isDateFromValid, setIsDateFromValid] = useState(true);
  const [isDateToValid, setIsDateToValid] = useState(true);
  const [isValidating, setIsValidating] = useState(false);
  // const [selectedProducts, setSelectedProducts] = useState<Array<iProduct>>([]);
  const { selectAll, selectOneElement, state: selectedProducts } = useRowCheckboxHook<iProduct>({
    whole: data.map((item: iStockForecast) => item.product),
  });
  useEffect(() => {
    const mounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (!mounted) return;

        const previouslySelectedJobStatusIds = localStorage.getItem(LOCAL_STORAGE_KEY_FOR_JOB_STATUS_ID);
        const measurementData = await getUnits();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let mappedStatusIds: any[] = [];
        if (previouslySelectedJobStatusIds === null) {
          const jobStatusData = await getJobStatuses({
            filter: `code:${JOB_STATUS_CODE_NEW}|${JOB_STATUS_CODE_PLANNED}`,
          });
          mappedStatusIds = jobStatusData.map((status: iJobStatus) => ({
            label: status.name,
            value: status.id,
          }));
        } else {
          mappedStatusIds = JSON.parse(previouslySelectedJobStatusIds);
        }

        setJobStatusIds(mappedStatusIds);
        setMeasurements(measurementData);
        setIsLoading(false);
      } catch (e) {
        apiErrorToast(e);
        if (!mounted) return;
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const onValidate = () => {
    setIsValidating(true);

    if (jobStatusIds.length === 0) {
      setIsJobStatusValid(false);
      return false;
    }

    if (!dateFrom) {
      setIsDateFromValid(false);
      return false;
    }

    if (!dateTo) {
      setIsDateToValid(false);
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    if (!onValidate()) return;
    try {
      setIsLoading(true);
      const jobStatusIdsStr = mapOptionToValue(jobStatusIds).join(',');
      const scheduledAtRangeStr = `${dateFrom},${dateTo}`;
      const productTypeIdsStr = mapOptionToValue(productTypeIds).join(',');
      const bomData = await getStockForecast(jobStatusIdsStr, scheduledAtRangeStr, productTypeIdsStr);

      setData(
        bomData.map((item: iStockForecast) => ({
          ...item,
          product: {
            ...item.product,
            measurement:
              measurements.find((mItem: iUnitOfMeasurements) => mItem.id === item.product.measurementId) ||
              item.product.measurement,
          },
        })),
      );
      setIsValidating(false);
      setIsLoading(false);
    } catch (e) {
      apiErrorToast(e);
      setIsLoading(false);
    }
  };

  const onReset = () => {
    setData([]);
    setJobStatusIds([]);
    setDateFrom(todayStr);
    setDateTo(getDaysMore(todayStr, 7));
    setIsValidating(false);
  };

  const onMultiSelect = (name: string, newValues: iLabelValuePair[]) => {
    if (!name) return;

    switch (name) {
      case 'jobStatusIds': {
        localStorage.setItem(LOCAL_STORAGE_KEY_FOR_JOB_STATUS_ID, JSON.stringify(newValues));
        setJobStatusIds(newValues);
        break;
      }
      case 'productTypeIds': {
        setProductTypeIds(newValues);
        break;
      }
      default: {
        break;
      }
    }
  };

  const onPickDate = (name: string, newValues?: string) => {
    if (!name) return;
    if (name === 'dateFrom') {
      setDateFrom(newValues);
    }
    if (name === 'dateTo') {
      setDateTo(newValues);
    }
  };

  const getErrorMsg = (isRuleViolated: boolean, msg: string) => {
    return isRuleViolated ? msg : undefined;
  };

  const getPageHeader = () => (
    <>
      <FlexContainer className={'space-between'}>
        <FlexContainer>
          <PageTitle>{STOCK_FORECAST_PAGE_TITLE}</PageTitle>
        </FlexContainer>
        <div />
      </FlexContainer>
      <AdvancedSearchSectionWrapper className={'vertical-padding'}>
        <Grid layout={'fluid'} spacing={'cosy'}>
          <GridColumn medium={3}>
            <MultiSelect
              name={'jobStatusIds'}
              defaultValue={jobStatusIds}
              onConfirm={onMultiSelect}
              placeholder={'All job status..'}
              promiseFn={getJobStatusListAsyncSearch}
              optionLabel={'name'}
              sortBy={'sortOrder'}
              errorMessage={getErrorMsg(isValidating && !isJobStatusValid, 'is required')}
            />
          </GridColumn>
          <GridColumn medium={3}>
            <MultiSelect
              name={'productTypeIds'}
              defaultValue={productTypeIds}
              onConfirm={onMultiSelect}
              placeholder={'All product types...'}
              promiseFn={getProductTypeListAsyncSearch}
              optionLabel={'name'}
              sortBy={'name'}
            />
          </GridColumn>
          <GridColumn medium={2}>
            <DatePickerWithoutReadview
              name={'dateFrom'}
              dateFormat="DD MMM YYYY"
              defaultValue={dateFrom}
              onConfirm={onPickDate}
              placeholder={'Scheduled job from...'}
              testId={'stock-forecast-search-date-from'}
              errorMessage={getErrorMsg(isValidating && !isDateFromValid, 'is required')}
            />
          </GridColumn>
          <GridColumn medium={2}>
            <DatePickerWithoutReadview
              name={'dateTo'}
              dateFormat="DD MMM YYYY"
              defaultValue={dateTo}
              onConfirm={onPickDate}
              placeholder={'Scheduled job as of...'}
              testId={'stock-forecast-search-date-to'}
              errorMessage={getErrorMsg(isValidating && !isDateToValid, 'is required')}
            />
          </GridColumn>
          <GridColumn medium={1}>
            <div style={{ marginTop: '5px' }}>
              <LoadingButton onClick={onSubmit} appearance={'primary'}>
                Search
              </LoadingButton>
            </div>
          </GridColumn>
          <GridColumn medium={1}>
            <div style={{ marginTop: '5px' }}>
              <ResetBtn onClick={onReset} />
            </div>
          </GridColumn>
        </Grid>
      </AdvancedSearchSectionWrapper>
    </>
  );

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <CreatePO products={selectedProducts} />
      <SpaceVerticalContainer className={'half'} />
      <InsStocksTable
        stocks={data.sort((a: iStockForecast, b: iStockForecast) =>
          compareStr(a.product.productCode, b.product.productCode),
        )}
        isLoading={isLoading}
        date={dateFrom || ''}
        columns={DASHBOARD_INSSTOCKS_COLUMNS}
        renderSpecialHeads={() =>
          renderSpecialHeads({
            isSelected: selectedProducts.length === data.length && selectedProducts.length > 0,
            onSelect: selectAll,
          })
        }
        renderSpecialRows={renderSpecialRows({
          selected: selectedProducts,
          onSelect: selectOneElement,
        })}
      />
      {/* <StockForecastTable
        data={data}
        isLoading={isLoading}
        measurements={measurements}
      /> */}
    </BackendPage>
  );
};

export default StockForecast;
