import React from 'react';
import { useSelector } from 'react-redux';
import iPurchaseOrderItem from '../../../../../../types/purchases/iPurchaseOrderItem';
import ProdPurchasePoTable from './ProdPurchasePoTable';
import useListCrudHook from '../../../../../../shared/hooks/useListCrudHook/useListCrudHook';
import SearchBar from '../../../../../../shared/search/SearchBar';
import ShowHideColumns from '../../../../../../shared/showHideColumns/ShowHideColumns';
import useColumnHooks from '../../../../../../shared/hooks/useShowHideColumns';
import CustomizePagination from '../../../../../../shared/pagination/CustomizePagination';
import useStatusSelector from '../../../../../../shared/hooks/useStatusSelector';
import { getEntityCategoryList } from '../../../../../../services/CategoryService';
import { FlexSpaceBetweenContainer } from '../../../../../../shared/contactCompany/style';
import { COLUMN_NAME, COLUMNS, FLEXIBLE_COLUMNS } from './constants';
import { deletePurchaseOrderItem, getPurchaseOrderItems } from '../../../../../../services/PurchaseOrderItemService';
import { createPurchaseOrder, updatePurchaseOrder } from '../../../../../../services/PurchaseService';
import { FlexContainer, PageTitle } from '../../../../../../shared/styles/styles';
import { RootState } from '../../../../../../redux/makeReduxStore';
import AccessService from '../../../../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PURCHASE_ORDERS } from '../../../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../../../types/settings/UserAccess/iRoleAccess';

const ProdPurchasePOs = ({ productId }: { productId: string }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [selectedColumns, onToggleColumn] = useColumnHooks(COLUMN_NAME, {
    default: COLUMNS,
    whole: COLUMNS,
  });
  const { state, edit, onSearch, onSetCurrentPage } = useListCrudHook<iPurchaseOrderItem>({
    getFn: getPurchaseOrderItems,
    createFn: createPurchaseOrder,
    updateFn: updatePurchaseOrder,
    deleteFn: deletePurchaseOrderItem,
    keywordColumns: 'purchaseOrder.orderNumber,purchaseOrder.supplierRef,purchaseOrder.supplier.name',
    paginationApplied: true,
    perPage: 5,
    sort: 'createdAt:DESC',
    filter: `productId:${productId}`,
  });

  const { categories } = useStatusSelector({
    type: 'PurchaseOrder',
    isMulti: true,
    getFn: getEntityCategoryList,
  });

  const getPageHeader = () => (
    <FlexSpaceBetweenContainer>
      <PageTitle className={'small'}>Purchase Orders</PageTitle>
      <div style={{ flexGrow: 0 }}>
        <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search'} />
      </div>
    </FlexSpaceBetweenContainer>
  );

  if (!AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_READ, user)) {
    return null;
  }

  return (
    <>
      {getPageHeader()}
      <FlexContainer className={'space-between space-above'}>
        <div />
        <ShowHideColumns
          onToggleColumns={onToggleColumn}
          selectedColumns={selectedColumns}
          columnLists={[{ label: 'Purchase Order', value: FLEXIBLE_COLUMNS }]}
        />
      </FlexContainer>
      <ProdPurchasePoTable
        poItems={state.data}
        isLoading={state.isLoading}
        columns={selectedColumns}
        categories={categories}
      />
      {state.total && state.perPage && state.currentPage ? (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      ) : null}
    </>
  );
};

export default ProdPurchasePOs;
