import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import iContactCompanyAddress from '../../../../types/contactCompany/iContactCompanyAddress';
import { iConfigColumn } from '../../../UITypes/types';
import TransparentIconBtn from '../../../buttons/TransparentIconBtn';
import { handleNullException } from '../../../../services/UtilsService';
import CreatedOrUpdated from '../../../createdOrUpdated/CreatedOrUpdated';

export const getRows = ({
  data,
  columns,
  onOpenDeleteModal,
  onOpenEditModal,
}: {
  data: Array<iContactCompanyAddress>;
  columns: Array<iConfigColumn>;
  onOpenDeleteModal: (id: string) => void;
  //  eslint-disable-next-line
  onOpenEditModal: (id: string) => void;
}) => {
  return data.map((item: iContactCompanyAddress) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        case 'edit':
          return {
            content: (
              <TransparentIconBtn icon={<CommentIcon label={'edit'} />} onClick={() => onOpenEditModal(item.id)} />
            ),
          };
        case 'delete':
          return {
            content: (
              <TransparentIconBtn icon={<TrashIcon label={'remove'} />} onClick={() => onOpenDeleteModal(item.id)} />
            ),
          };
        default:
          return { content: handleNullException(item, key) };
      }
    });
    return { cells, testId: 'company-address-table' };
  });
};
