import Spinner from '@atlaskit/spinner';
import Button from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import React, { useEffect, useState } from 'react';
import ChannelService from '../../../services/integration/ChannelService';
import { getTaxRates } from '../../../services/Settings/TaxService';
import CustomizeModal from '../../modal/CustomizeModal';
import { addToastForAPIResponse, apiErrorToast } from '../../toast/Toast';
import iChannel from '../../../types/integration/iChannel';
import iMyobCompanyProfile from '../../../types/integration/iMyobCompanyProfile';
import { iTaxRate } from '../../../types/iTaxRate';
import TaxRateListTable from '../../../pages/settings/taxRates/TaxRateListTable';
import MyobCompanyProfileSelector from './MyobCompanyProfileSelector';
import MyobConnectorService from '../../../services/integration/connectors/MyobConnectorService';

const MyobChannelOnboardingForm = ({
  channel,
  callback,
}: {
  channel: iChannel;
  callback: (channelResp: iChannel) => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taxRates, setTaxRates] = useState<iTaxRate[]>();
  const [isLoadingTaxRates, setIsLoadingTaxRates] = useState(false);
  const [companyProfiles, setCompanyProfiles] = useState<iMyobCompanyProfile[]>();
  const [targetCompanyProfile, setTargetCompanyProfile] = useState<iMyobCompanyProfile>();
  const [isCompanyProfileSaving, setIsCompanyProfileSaving] = useState(false);
  const companyProfileSelectionHandler = (companyProfile: iMyobCompanyProfile) => {
    setTargetCompanyProfile(companyProfile);
  };
  const [currentStep, setCurrentStep] = useState(1);
  const [channelReloaded, setChannelReloaded] = useState<iChannel>();
  const totalSteps = 2;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyProfilesResp = await MyobConnectorService.getCompanyProfile(channel.id);
        setCompanyProfiles(companyProfilesResp);
        setIsModalOpen(true);
      } catch (error) {
        apiErrorToast(error);
      }
    };
    fetchData();
  }, [channel.id, channel.isOnBoarded]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingTaxRates(true);
        const taxRatesResp = await getTaxRates();
        setTaxRates(taxRatesResp);
        setIsLoadingTaxRates(false);
      } catch (error) {
        apiErrorToast(error);
        setIsLoadingTaxRates(false);
      }
    };
    fetchData();
  }, []);

  const confirmBtnWording = currentStep < totalSteps ? 'Next' : 'Finish';
  const isSaving = isCompanyProfileSaving;

  const handleBtnNext = async () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
      try {
        setIsCompanyProfileSaving(true);
        const channelResp = await ChannelService.updateChannel(channel.id, {
          name: targetCompanyProfile?.Name,
          settings: {
            ...channel.settings,
            companyProfile: targetCompanyProfile,
            companyFileId: targetCompanyProfile?.Id,
          },
          isOnBoarded: true,
        });
        setChannelReloaded(channelResp);
        setIsCompanyProfileSaving(false);
      } catch (error) {
        apiErrorToast(error);
        setIsCompanyProfileSaving(false);
      }
    } else {
      if (channelReloaded) {
        callback(channelReloaded);
      }
      setIsModalOpen(false);
      setCurrentStep(1);
      addToastForAPIResponse('success', 'MYOB setup is complete.');
    }
  };

  return (
    <>
      <SectionMessage title="MYOB setup is not complete" appearance="warning">
        <p>We might need to link a company file from MYOB.</p>
        <p>
          <Button
            appearance="link"
            style={{ padding: 0 }}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Link Now
          </Button>
        </p>
      </SectionMessage>
      <br />
      {isModalOpen && (
        <CustomizeModal
          isOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          isConfirming={isCompanyProfileSaving}
          isDisabled={isCompanyProfileSaving || !targetCompanyProfile}
          height={550}
          width="x-large"
          modalBody={
            <>
              {currentStep === 1 && companyProfiles && companyProfiles.length > 0 && (
                <MyobCompanyProfileSelector
                  companyProfiles={companyProfiles}
                  onSelected={companyProfileSelectionHandler}
                />
              )}
              {currentStep === 2 && taxRates && (
                <TaxRateListTable isLoading={isLoadingTaxRates} taxRates={taxRates} isCompact />
              )}
            </>
          }
          modalHeading="MYOB Onboarding"
          confirmBtnName={'Save'}
          customizedBtn={
            <>
              <Button appearance="primary" onClick={handleBtnNext} isDisabled={isSaving}>
                {isSaving ? <Spinner /> : confirmBtnWording}
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

export default MyobChannelOnboardingForm;
