import React, { useState, useEffect } from 'react';
import { Label } from '@atlaskit/field-base';
import { Grid, GridColumn } from '@atlaskit/page';
import iJob from '../../../../types/job/iJob';
import iUnitOfMeasurements from '../../../../types/iUnitOfMeasurement';
import iJobAttribute from '../../../../types/job/iJobAttribute';
import JobAttribute from './JobAttribute';
// import { roundUpJobAttribute } from '../JobDetails.utils';
import { getJobAttributes } from '../../../../services/JobAttributeService';
import { getUnits } from '../../../../services/Settings/UnitMeasurementService';
import { iLabelValuePair } from '../../../UITypes/types';
import { addToastForAPIResponse, apiErrorToast } from '../../../toast/Toast';
import { createJobAttributeValue, updateJobAttributeValue } from '../../../../services/JobService';

type iState = {
  attrCluster: Array<iJobAttribute>;
  units: Array<iLabelValuePair>;
};
const initialState: iState = {
  attrCluster: [],
  units: [],
};
const JobAttributes = ({
  detail,
  onRefresh,
  isDisabled,
}: {
  detail: iJob;
  onRefresh: () => void;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState(initialState);
  const { id: jobId, jobAttributes: attributes } = detail;
  useEffect(() => {
    let isCancelled = false;
    const fetchAllJobAttributes = async () => {
      try {
        const attrCluster = await getJobAttributes({ sort: 'sortOrder:ASC' });
        const units = await getUnits();
        const mappedUnits = units.map((u: iUnitOfMeasurements) => ({
          label: u.name,
          value: u.name,
        }));
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          attrCluster,
          units: mappedUnits,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
      }
    };
    fetchAllJobAttributes();
    return () => {
      isCancelled = true;
    };
  }, []);

  const onUpdateJobAttrValue = async (
    attributeValueId: string,
    value: { textValue: string; selectValue: string | null },
  ) => {
    try {
      await updateJobAttributeValue(attributeValueId, {
        value: value.textValue,
        postfix: value.selectValue,
      });
      onRefresh();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };
  const onCreateJobAttrValue = async (
    attr: iJobAttribute,
    value: { textValue: string; selectValue: string | null },
  ) => {
    try {
      await createJobAttributeValue({
        jobId,
        jobAttributeId: attr.id,
        value: value.textValue,
        postfix: value.selectValue,
      });
      onRefresh();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  return (
    <>
      {state.attrCluster
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((attr: iJobAttribute) => {
          const targetAttr = attributes.find((jobAttr: iJobAttribute) => jobAttr.id === attr.id);
          return (
            <Grid layout={'fluid'} key={`job-${jobId}-attributes-${attr.name}`}>
              <GridColumn medium={1}>
                <Label label={attr.name} />
              </GridColumn>
              <GridColumn medium={3}>
                <JobAttribute
                  name={targetAttr ? targetAttr.jobAttributeValues.id : 'undefined'}
                  textDefaultValue={
                    targetAttr?.jobAttributeValues?.value
                    // ? roundUpJobAttribute(targetAttr) || ''
                    // : undefined
                  }
                  selectDefaultValue={targetAttr ? targetAttr.jobAttributeValues.postfix || undefined : undefined}
                  selectOptions={state.units}
                  onConfirm={(name: string, newValue: { textValue: string; selectValue: string | null }) =>
                    targetAttr ? onUpdateJobAttrValue(name, newValue) : onCreateJobAttrValue(attr, newValue)
                  }
                  isDisabled={isDisabled}
                />
              </GridColumn>
            </Grid>
          );
        })}
    </>
  );
};

export default JobAttributes;
