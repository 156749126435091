import React, { useState } from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import Modal, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';

import { iBtnAppearance } from '../UITypes/types';
import { apiErrorToast } from '../toast/Toast';

const defaultModalHeading = <span>Are you sure to start?</span>;
const ApiModal = ({
  isOpen,
  onConfirm,
  onCancel,
  //   isConfirming = false,
  successFlagTitle = 'Success',
  confirmBtnName = 'Confirm',
  confirmBtnAppearance = 'primary',
  modalHeading = defaultModalHeading,
  isDisabled = false,
  modalBody,
  bottom = null,
  testIdPrefix,
  width,
  height,
}: {
  isOpen?: boolean;
  onConfirm: () => Promise<string>;
  onCancel: () => void;
  successFlagTitle?: string;
  isDisabled?: boolean;
  confirmBtnName?: string;
  confirmBtnAppearance?: iBtnAppearance;
  modalHeading?: React.ReactNode;
  modalBody?: React.ReactNode;
  bottom?: React.ReactNode;
  testIdPrefix?: string;
  width?: 'small' | 'medium' | 'large' | 'x-large' | number | string;
  height?: number | string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [localModalBody, setLocalModalBody] = useState(modalBody);
  const [localModalHeading, setLocalModalHeading] = useState(modalHeading);
  const [isLoadingBtn, setIsLoadingBtn] = useState(true);
  // const [localResponse, setLocalResponse] = useState();

  const sendAjax = () => {
    setIsLoading(true);
    onConfirm()
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(res => {
        setIsLoading(false);
        setLocalModalBody(modalBody);
        setLocalModalHeading(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'inline-block', marginRight: '10px' }}>
              <SuccessIcon primaryColor={G300} label="success info" />
            </div>
            <div style={{ display: 'inline-block' }}>{successFlagTitle}</div>
          </div>,
        );
        setIsLoadingBtn(false);
      })
      .catch(apiErrorToast)
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getModal = () => (
    <Modal
      testId={
        testIdPrefix ? `${testIdPrefix}-customize-modal` : 'customize-modal'
      }
      components={{
        Footer: () => {
          return (
            <ModalFooter>
              <div>{bottom}</div>
              <ButtonGroup>
                {isLoadingBtn && (
                  <LoadingButton
                    onClick={sendAjax}
                    appearance={confirmBtnAppearance}
                    isDisabled={isDisabled}
                    testId={
                      testIdPrefix
                        ? `${testIdPrefix}-confirm-button`
                        : 'confirm-button'
                    }
                    isLoading={isLoading}
                  >
                    {confirmBtnName}
                  </LoadingButton>
                )}
                <Button
                  onClick={onCancel}
                  appearance={isLoadingBtn ? 'default' : 'primary'}
                  testId={
                    testIdPrefix
                      ? `${testIdPrefix}-cancel-button`
                      : 'cancel-button'
                  }
                >
                  {isLoadingBtn ? 'Cancel' : 'Close'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          );
        },
      }}
      onClose={onCancel}
      heading={localModalHeading}
      width={width || 'medium'}
      height={height || 'auto'}
    >
      {localModalBody}
    </Modal>
  );
  return isOpen ? <ModalTransition>{getModal()}</ModalTransition> : null;
};

export default ApiModal;
