import React from 'react';
import { getMachines } from '../../../../../services/Settings/MachineService';
import InlineEditSelectFetchOptions from '../../../../../shared/form/InlineEditSelectFetchOptions';
import iProduct from '../../../../../types/product/iProduct';

const ProdOperations = ({
  product,
  onUpdateProduct,
  isDisabled,
}: {
  product?: iProduct;
  isDisabled?: boolean;
  onUpdateProduct?: (name: string, newValue: string | number | boolean | null) => void;
}) => {
  if (typeof product === 'undefined' || typeof onUpdateProduct === 'undefined') return null;
  return (
    <InlineEditSelectFetchOptions
      label={'Operating machine'}
      name={'defaultMachineId'}
      promiseFn={getMachines}
      defaultValue={product?.defaultMachineId}
      isClearable
      onSelect={onUpdateProduct}
      isDisabled={isDisabled}
    />
  );
};

export default ProdOperations;
