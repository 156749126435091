import React, { useState } from 'react';
import { ButtonGroup } from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SectionMessage from '@atlaskit/section-message';
import { useSelector } from 'react-redux';
import { getContactCompany } from '../../../../services/ContactCompanyService';
import { printSalesOrder } from '../../../../services/PrintService';
import { deactivateSale, getSalesOrderDetail, updateSalesOrder } from '../../../../services/SalesOrderService';
import iSalesOrderDetail from '../../../../types/sales/iSalesOrder';
import iSalesOrderType from '../../../../types/sales/iSalesOrderType';
import GeneralEmailBtn from '../../../../shared/buttons/GeneralEmailBtn';
import GeneralPrintBtn from '../../../../shared/buttons/GeneralPrintBtn';
import PopupContent from '../../../../shared/contactCompany/detail/popup/PopupContent';
import DeleteByRetype from '../../../../shared/deleteWithReType/DeleteByReType';
import SelectSingle from '../../../../shared/form/SingleSelect';
import FlexSpaceBetweenContainer from '../../../../shared/job/jobDetails/styles/FlexSpaceBetweenContainer';
import MoreIconPopup from '../../../../shared/popup/MoreIconPopup';
import { addToastForAPIResponse, apiErrorToast } from '../../../../shared/toast/Toast';
import { SALES_URL } from '../../../../shared/UrlMap';
import { createOption, createOptions } from '../../utilities';
import iEntityCategory from '../../../../types/status/iEntityCategory';
import { SelectorWrapper } from '../../../../shared/styles/styles';
import StatusMenu from '../../../../shared/statusComponent/StatusMenu';
import BreadcrumbsTwoLayers from '../../../../shared/heading/BreadcrumbsTwoLayers';
import { handleNullException } from '../../../../services/UtilsService';
import SelfManagePopup from '../../../../shared/popup/SelfManagePopup';
import { SO_TYPE_NAME_INVOICE, SO_TYPE_NAME_QUOTE, SO_TYPE_NAME_SALES_ORDER } from '../constants';
import { RootState } from '../../../../redux/makeReduxStore';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_INVOICES,
  ACCESS_CODE_QUOTES,
  ACCESS_CODE_SALES_ORDERS,
} from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

const Wrapper = styled.div`
  margin-bottom: 1rem;
  > * {
    margin: 0.5rem 0;
  }
  & .Breadcrumb {
    font-size: 0.9rem;
    color: ${colors.N100};
    font-weight: 500;
  }
  .sub-title {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    .select-wrapper {
      min-width: 7rem;
      z-index: 99;
    }
    h3 {
      margin: 0 0.5rem;
      padding: 0px;
    }
  }
`;

const customStyles = {
  singleValue: () => ({
    fontWeight: 500,
    fontSize: '1.03rem',
    paddingBottom: '0.1rem',
  }),
};

const emailSubjectTpl = 'Please find [REF_NUM] from [COMPANY_NAME]';
const emailBodyTpl = `Hi there,

Please click the link below to download the sales order.
[PDF_URL]

Feel free to contact us if you require anything further.

Kind Regards
[SENT_FROM]
[COMPANY_NAME]`;

const SalesDetailHeader = ({
  order,
  types,
  categories,
  isOrderDeletable,
  id,
  integrated,
  canUpdate = true,
  canDelete = true,
}: {
  order?: iSalesOrderDetail;
  types: iSalesOrderType[];
  categories: Array<iEntityCategory>;
  isOrderDeletable: boolean;
  id: string;
  integrated: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
}) => {
  const [localOrderDetail, setLocalOrderDetail] = useState(order);
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadQuotes = AccessService.hasAccess(ACCESS_CODE_QUOTES, ACCESS_CAN_READ, user);
  const canReadSOs = AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_READ, user);
  const canReadInvoices = AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_READ, user);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const history = useHistory();
  const isInvoice = handleNullException(localOrderDetail, 'type.name').toUpperCase() === SO_TYPE_NAME_INVOICE;

  const onRefresh = async () => {
    try {
      const salesOrderData = await getSalesOrderDetail(id);
      setLocalOrderDetail(salesOrderData);
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onUpdateDetail = async (fieldName: string, fieldValue: string | boolean | number | null | undefined) => {
    if (typeof localOrderDetail === 'undefined') {
      return;
    }
    try {
      await updateSalesOrder(id, { [fieldName]: fieldValue });
      onRefresh();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  // update order type
  const onConfirmSelect = async (targetId: string, newValue: string) => {
    try {
      await updateSalesOrder(targetId, {
        typeId: newValue,
      });
      addToastForAPIResponse('success');
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const onDelete = async () => {
    if (!localOrderDetail) return;
    try {
      await deactivateSale(localOrderDetail.id);
      history.push(`${SALES_URL}`);
    } catch (e) {
      setIsDeleteModalOpen(false);
      apiErrorToast(e);
    }
  };

  return (
    <>
      <FlexSpaceBetweenContainer>
        <Wrapper>
          <BreadcrumbsTwoLayers
            name={'Sales'}
            url={SALES_URL}
            subName={
              isInvoice
                ? `${SO_TYPE_NAME_INVOICE}: ${handleNullException(localOrderDetail, 'invoiceNumber')}`
                : `${localOrderDetail?.type?.name.toUpperCase()}: ${localOrderDetail?.orderNumber}`
            }
          />
          <div className={'sub-title'}>
            {isInvoice ? null : (
              <div className={'select-wrapper'}>
                <SelectSingle
                  name={id}
                  defaultValue={createOption(localOrderDetail?.type)}
                  label={''}
                  selectOptions={createOptions(
                    types.filter(type => {
                      if (type.id === localOrderDetail?.type?.id) {
                        return true;
                      }
                      if (type.name === SO_TYPE_NAME_INVOICE && !canReadInvoices) {
                        return false;
                      }
                      if (type.name === SO_TYPE_NAME_SALES_ORDER && !canReadSOs) {
                        return false;
                      }
                      if (type.name === SO_TYPE_NAME_QUOTE && !canReadQuotes) {
                        return false;
                      }
                      return true;
                    }),
                  )}
                  onConfirm={onConfirmSelect}
                  customStyles={customStyles}
                  isDisabled={isInvoice || !canUpdate}
                />
              </div>
            )}
            <h3>
              {isInvoice ? handleNullException(localOrderDetail, 'invoiceNumber') : localOrderDetail?.orderNumber}
            </h3>
            <SelectorWrapper>
              <StatusMenu
                defaultValue={localOrderDetail?.statusId}
                categories={categories}
                onSelect={(newValue: string) => onUpdateDetail('statusId', newValue)}
                isDisabled={!canUpdate}
              />
            </SelectorWrapper>
          </div>
        </Wrapper>
        <ButtonGroup>
          {localOrderDetail?.orderNumber && (
            <GeneralEmailBtn
              orderNum={localOrderDetail?.orderNumber}
              serviceFunc={() => printSalesOrder(id)}
              recipientServiceFunc={() => getContactCompany(localOrderDetail?.customerId)}
              emailSubjectTpl={emailSubjectTpl}
              emailBodyTpl={emailBodyTpl}
            >
              Email
            </GeneralEmailBtn>
          )}
          <GeneralPrintBtn
            serviceFunc={() => printSalesOrder(id)}
            onWarning={() => setIsWarningModalOpen(true)}
            shouldWarningModalOpen={integrated}
          >
            Print
          </GeneralPrintBtn>
          {canDelete && (
            <MoreIconPopup>
              <PopupContent id={id} onOpenModal={onOpenDeleteModal} btnName={'Delete'} />
            </MoreIconPopup>
          )}
        </ButtonGroup>
      </FlexSpaceBetweenContainer>
      <DeleteByRetype
        isOpen={isDeleteModalOpen}
        onConfirm={onDelete}
        onClose={() => setIsDeleteModalOpen(false)}
        answer={localOrderDetail?.orderNumber || 'order number N/A'}
        isDeletable={isOrderDeletable}
        warningMsg={'Cannot delete an order with job(s).'}
      />
      {isWarningModalOpen && (
        <SelfManagePopup
          isPopupOpen={isWarningModalOpen}
          isCancelBtn={false}
          onClick={() => setIsWarningModalOpen(false)}
          popupHeading={'Printing disabled'}
          popupContent={
            <SectionMessage appearance={'warning'}>
              <p>Printing disabled due to integration with Myob, please print within Myob.</p>
            </SectionMessage>
          }
          confirmBtnName={'OK'}
          confirmBtnAppearance={'primary'}
        />
      )}
    </>
  );
};
export default SalesDetailHeader;
