import React from 'react';
import moment from 'moment';
import LinkBtnUrl from '../../../buttons/LinkBtnUrl';
import PopupContent from './popup/PopupContent';
import MoreIconPopup from '../../../popup/MoreIconPopup';
import iJob from '../../../../types/job/iJob';
import iJobAttribute from '../../../../types/job/iJobAttribute';
import iProductAttribute from '../../../../types/product/iProductAttribute';
import iProductAttributeValue from '../../../../types/product/iProductAttributeValue';
import { handleNullException } from '../../../../services/UtilsService';
import { iJobRowData } from './types';
import { iKeyValuePairs } from '../../../UITypes/types';
import { getAlternativeField } from '../../../../pages/job/jobDetail/shared/JobDetail.utils';
import { JOB_URL } from '../../../UrlMap';
import CreatedOrUpdated from '../../../createdOrUpdated/CreatedOrUpdated';
import DispatchNoteNumberListPerJob from '../../../dispatchNote/DispatchNoteNumberListPerJob';
// import { roundUpJobAttribute } from '../../jobDetails/JobDetails.utils';

export const getRows = (
  jobList: Array<iJob>,
  onOpenDeleteJobModal: (job: iJob) => void,
  columns: Array<string>,
  prodAttrsCluster: Array<iProductAttribute>,
) => {
  const getJobListPopup = (job: iJob) => (
    <MoreIconPopup>
      <PopupContent job={job} onOpenDeleteJobModal={onOpenDeleteJobModal} />
    </MoreIconPopup>
  );

  const getJobNumberContent = (jobId: string, jobNum: string) => (
    <LinkBtnUrl btnName={jobNum} url={`${JOB_URL}/${jobId}`} />
  );
  const mapJobList = (): Array<iJobRowData> =>
    jobList.map((job: iJob) => {
      const jobAttrs = job.jobAttributes.reduce((acc: iKeyValuePairs, cur: iJobAttribute) => {
        return {
          ...acc,
          [cur.name]: cur.jobAttributeValues.value,
          // roundUpJobAttribute(cur),
        };
      }, {});

      const prodAttrs = handleNullException(job, 'product.productAttributeValues') || [];
      const prodAttrNameValuePair = prodAttrs.reduce((acc: iKeyValuePairs, cur: iProductAttributeValue) => {
        const pAttrName = prodAttrsCluster.find((p: iProductAttribute) => p.id === cur.attributeId)?.name;
        return pAttrName ? { ...acc, [pAttrName]: cur.value || '' } : acc;
      }, {});

      const jobRow = {
        id: job.id,
        Job: job.jobNumber,
        Order: getAlternativeField(job, 'salesOrder.orderNumber', 'orderNumber'),
        Customer: getAlternativeField(job, 'salesOrder.customer.name', 'customerName'),
        'Customer Ref': getAlternativeField(job, 'salesOrder.customerRef', 'customerOrderRef'),
        'Product Name': handleNullException(job, 'product.name'),
        'Product Code': handleNullException(job, 'product.productCode'),
        Machine: handleNullException(job, 'machine.name'),
        Operator: handleNullException(job, 'operator.username'),
        Status: handleNullException(job, 'status.name'),
        'Scheduled Date': job.scheduledAt ? moment(job.scheduledAt).format('DD MMM YYYY') : '',
        'Order Date': job.salesOrder?.orderDate ? moment(job.salesOrder?.orderDate).format('DD MMM YYYY') : '',
        EDD: getAlternativeField(job, 'salesOrder.edd', 'edd')
          ? moment(getAlternativeField(job, 'salesOrder.edd', 'edd')).format('DD MMM YYYY')
          : '',
        'Qty to Make': job.qtyToMake || '',
        'Qty Total': job.qtyTotal || '',
        UOM: handleNullException(job, 'product.measurement.shortName'),
        Created: (
          <CreatedOrUpdated
            operatedAt={handleNullException(job, 'createdAt')}
            operator={handleNullException(job, 'createdBy.firstName')}
          />
        ),
        Updated: (
          <CreatedOrUpdated
            operatedAt={handleNullException(job, 'updatedAt')}
            operator={handleNullException(job, 'updatedBy.firstName')}
          />
        ),
        ...(columns.indexOf('Dispatch Notes') >= 0
          ? { 'Dispatch Notes': <DispatchNoteNumberListPerJob jobId={job.id} /> }
          : {}),
        ...jobAttrs,
        ...prodAttrNameValuePair,
      };
      return jobRow;
    });
  //  eslint-disable-next-line
  function hasKey<O>(obj: O, key: keyof any): key is keyof O {
    return key in obj;
  }
  return mapJobList().map((row: iJobRowData, index: number) => {
    const cells = columns.map((column: string) => {
      switch (column.toUpperCase()) {
        case 'OPERATION':
          return {
            content: getJobListPopup(jobList[index]),
          };
        case 'JOB':
          return {
            content: getJobNumberContent(row.id, row.Job),
          };
        default:
          if (hasKey(row, column)) {
            return { content: row[column] };
          }
          return { content: '' };
      }
    });
    return { cells, testId: 'admin-jobList-row' };
  });
};
