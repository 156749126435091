import React from 'react';
import { iConfigColumn } from '../../../despatchNote/shared/DispatchNote.type';
import iPurchaseOrderItem from '../../../../types/purchases/iPurchaseOrderItem';
import {
  formatDate,
  getCategoryCode,
  handleNullException,
  handleNullExceptionMultiFields
} from '../../../../services/UtilsService';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import { PRODUCT_DETAIL_URL, PURCHASE_ORDER_DETAIL_URL, SUPPLIER_DETAIL_URL } from '../../../../shared/UrlMap';
import StatusLozenge from '../../../../shared/statusComponent/StatusLozenge';
import iEntityCategory from '../../../../types/status/iEntityCategory';
import MathHelper from '../../../../services/MathHelper';

export const purchaseHistoryColumns: iConfigColumn[] = [
  {
    type: 'link',
    key: 'product.productCode',
    name: 'Product Code',
    isSelected: true,
    isCompulsory: true,
    group: 'Extra',
    dataSource: ['product.productCode'],
    getUrlFn: (item: iPurchaseOrderItem) =>
      PRODUCT_DETAIL_URL.replace(':id', `${handleNullException(item, 'product.id')}`),
  },
  {
    type: 'link',
    key: 'product.name',
    name: 'Product Name',
    isSelected: true,
    group: 'Extra',
    dataSource: ['product.name'],
    getUrlFn: (item: iPurchaseOrderItem) =>
      PRODUCT_DETAIL_URL.replace(':id', `${handleNullException(item, 'product.id')}`),
  },
  {
    type: 'link',
    key: 'purchaseOrder.supplier.name',
    name: 'Supplier Name',
    group: 'Extra',
    isSelected: true,
    getUrlFn: (item: iPurchaseOrderItem) =>
      SUPPLIER_DETAIL_URL.replace(':id', `${handleNullException(item, 'purchaseOrder.supplierId')}`),
    dataSource: ['purchaseOrder.supplier.name'],
  },
  {
    type: 'link',
    key: 'purchaseOrder.orderNumber',
    name: 'PO #',
    group: 'Purchase Order',
    isSelected: true,
    getUrlFn: (item: iPurchaseOrderItem) =>
      PURCHASE_ORDER_DETAIL_URL.replace(':id', `${handleNullException(item, 'orderId')}`),
    dataSource: ['purchaseOrder.orderNumber'],
  },
  {
    type: 'text',
    key: 'purchaseOrder.supplierRef',
    name: 'PO Ref',
    group: 'Purchase Order',
    dataSource: [],
  },
  {
    type: 'date',
    key: 'purchaseOrder.orderDate',
    name: 'Order Date',
    group: 'Purchase Order',
    isSelected: true,
    dataSource: ['ll'],
  },
  {
    type: 'date',
    key: 'purchaseOrder.eta',
    name: 'PO ETA',
    group: 'Purchase Order',
    dataSource: ['ll'],
  },
  {
    type: 'status',
    key: 'purchaseOrder.status.name',
    name: 'PO Status',
    group: 'Purchase Order',
    isSelected: true,
    dataSource: ['purchaseOrder.status'],
  },
  {
    type: 'qty',
    key: 'qty',
    name: 'Supplier OUM Qty',
    group: 'Qty',
    dataSource: [],
  },
  {
    type: 'text',
    key: 'measurement.shortName',
    name: 'Supplier OUM',
    group: 'Qty',
    dataSource: [],
  },
  {
    type: 'convertedQty',
    key: 'unitConversion',
    name: 'Product OUM Qty',
    group: 'Qty',
    isSelected: true,
    dataSource: ['qty'],
  },
  {
    type: 'text',
    key: 'product.measurement.shortName',
    name: 'Product OUM',
    group: 'Qty',
    isSelected: true,
    dataSource: [],
  },
];

export const getRows = (
  poItems: iPurchaseOrderItem[],
  statusCategories: iEntityCategory[],
  columns: iConfigColumn[],
) => {
  return poItems
    ?.sort((a: iPurchaseOrderItem, b: iPurchaseOrderItem) =>
      a?.product.productCode.localeCompare(b?.product.productCode, 'en', {
        sensitivity: 'base',
      }),
    )
    .map((item: iPurchaseOrderItem) => ({
      cells: columns.map(column => {
        const { key, type, getUrlFn, dataSource } = column;
        switch (type) {
          // case 'linkWithSubTitle': {
          //   return {
          //     key: handleNullException(item, key),
          //     content: (
          //       <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[0])}>
          //         <LinkBtnUrl url={getUrlFn ? getUrlFn(item) : ''} btnName={handleNullException(item, dataSource[1])} />
          //       </ComposeSecondaryText>
          //     ),
          //   };
          // }
          case 'text': {
            return {
              key: handleNullException(item, key),
              content: handleNullExceptionMultiFields(item, key),
            };
          }
          case 'link': {
            return {
              key: handleNullException(item, key),
              content: (
                <LinkBtnUrl url={getUrlFn ? getUrlFn(item) : ''} btnName={handleNullException(item, dataSource[0])} />
              ),
            };
          }
          case 'date': {
            return {
              key: handleNullException(item, key),
              content: formatDate(handleNullException(item, key), dataSource[0]),
            };
          }
          case 'status': {
            return {
              key: handleNullException(item, key),
              content: (
                <StatusLozenge
                  status={handleNullException(item, dataSource[0])}
                  categoryCode={getCategoryCode(handleNullException(item, dataSource[0]), statusCategories)}
                />
              ),
            };
          }
          case 'qty': {
            return {
              key: handleNullException(item, key),
              content: handleNullExceptionMultiFields(item, key),
            };
          }
          case 'convertedQty': {
            const unitConversion = handleNullExceptionMultiFields(item, 'unitConversion') || 1;
            const qty = handleNullException(item, dataSource[0], true);
            return {
              key: handleNullException(item, key),
              content: MathHelper.mul(qty, unitConversion),
            };
          }
          default:
            return { content: '' };
        }
      }),
    }));
};

export const getReportArrSegments = (poItems: iPurchaseOrderItem[], selectedColumns: iConfigColumn[]) => {
  const selectedColumnNames = selectedColumns
    .map((column: iConfigColumn) => column.name)
    .filter((columnName: string) => columnName !== '');

  const reportCells = poItems.map((item: iPurchaseOrderItem) => {
    return selectedColumns.map((column: iConfigColumn) => {
      const { key, type, dataSource } = column;
      switch (type) {
        // case 'linkWithSubTitle':
        //   return handleNullException(item, key);
        case 'text':
          return handleNullException(item, key);
        case 'link':
          return handleNullException(item, dataSource[0]);
        case 'date':
          return formatDate(handleNullException(item, key), dataSource[0]);
        case 'status':
          return handleNullException(item, key);
        case 'qty': {
          return handleNullException(item, key);
        }
        case 'convertedQty': {
          const unitConversion = handleNullExceptionMultiFields(item, 'unitConversion') || 1;
          const qty = handleNullException(item, dataSource[0], true);
          return MathHelper.mul(qty, unitConversion);
        }
        default:
          return '';
      }
    });
  });

  return [selectedColumnNames, ...reportCells];
};
