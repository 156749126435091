import React from 'react';
import _ from 'lodash';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { ucFirst } from '../../../../services/UtilsService';
import iAddress from '../../../../types/contactCompany/iAddress';
import InputWithController from '../InputWithController';

const addressAttributes = ['street', 'suburb', 'state', 'postcode', 'country'];
const defaultCountry = 'Australia';
const AddressModalBody = ({
  control,
  errors,
  onChange,
  address,
  attributes = addressAttributes,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  address?: iAddress;
  attributes?: Array<string>;
}) => {
  return (
    <>
      {attributes.map((attr: string) => (
        <InputWithController
          key={attr}
          name={attr}
          label={ucFirst(attr)}
          defaultValue={
            _.get(address, attr) || (attr === 'country' && defaultCountry)
          }
          control={control}
          onChange={onChange}
          errors={errors}
          isRequired={addressAttributes.includes(attr)}
          testId={attr}
        />
      ))}
    </>
  );
};

export default AddressModalBody;
