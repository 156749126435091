import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Button from '@atlaskit/button';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import { JOB_SCHEDULER_URL } from '../UrlMap';

const ScheduleBtnWrapper = styled.div`
  .calendarIcon {
    border-radius: 100%;
    background-color: transparent;
    &:hover {
      color: #0052cc;
      background-color: rgba(222, 235, 255, 0.9);
      > span {
        color: #0052cc;
      }
    }
  }
`;

const ScheduleShortcut = () => {
  const history = useHistory();

  const navToScheduler = () => history.push(JOB_SCHEDULER_URL);

  return (
    <ScheduleBtnWrapper>
      <Button
        iconBefore={<CalendarIcon label={'scheduler'} size={'medium'} />}
        onClick={navToScheduler}
        className={'calendarIcon'}
        testId={'calendarIcon'}
      />
    </ScheduleBtnWrapper>
  );
};

export default ScheduleShortcut;
