import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iProductAttribute from '../../../types/product/iProductAttribute';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import SelectWithController from '../../../shared/form/hookForms/SelectWithController';
import { handleNullException } from '../../../services/UtilsService';
import CreatableSelectWithController from '../../../shared/form/hookForms/CreatableSelectWithController';

const attributeTypes = ['text', 'numeric', 'checkbox', 'select'];

const attributeOptions = attributeTypes.map(type => ({
  label: type.toUpperCase(),
  value: type,
}));

const ProdAttributeEditModal = ({
  control,
  errors,
  onChange,
  target,
  watch,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iProductAttribute;
  watch: (names?: string | string[]) => unknown;
}) => {
  const watchAttributeType = watch('type');

  return (
    <Page>
      <Grid layout={'fluid'} spacing={'cosy'}>
        <GridColumn medium={4}>
          <InputWithController
            label={'Attribute Name'}
            name={'name'}
            defaultValue={handleNullException(target, 'name')}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
          />
        </GridColumn>
        <GridColumn medium={4}>
          <InputWithController
            label={'Attribute Description'}
            name={'description'}
            defaultValue={handleNullException(target, 'description')}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired={false}
          />
        </GridColumn>
        <GridColumn medium={4}>
          <SelectWithController
            name={'type'}
            label={'Type'}
            control={control}
            defaultValue={handleNullException(target, 'type')}
            onChange={onChange}
            errors={errors}
            options={attributeOptions}
            isRequired
            isClearable
            className={'min-width-120'}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'cosy'}>
        <GridColumn medium={6}>
          <CreatableSelectWithController
            label={'Options'}
            name={'options'}
            defaultValue={
              target && watchAttributeType === 'select' && target.options
                ? JSON.parse(target?.options)
                : []
            }
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired={watchAttributeType === 'select'}
            isDisabled={watchAttributeType !== 'select'}
            isMulti={watchAttributeType === 'select'}
            isClearable
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default ProdAttributeEditModal;
