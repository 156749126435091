import styled from 'styled-components';

const InlineBlock = styled.div`
  display: inline-block;
  margin: 0.5em;

  &.vertical-margin-only {
    margin: 0.5em 0;
  }
`;

export default InlineBlock;
