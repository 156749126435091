import React, { useState } from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';

import { Grid, GridColumn } from '@atlaskit/page';
import { Label } from '@atlaskit/field-base';

import AsyncSearch from '../../../../../../shared/asyncSearch/AsyncSearch';
import { getProductListAsyncSearch } from '../../../../../../services/product/ProductService';
import { LabelValue } from '../../../../../../shared/styles/styles';
import { iActionTypes } from '../../../../../../types/iActionTypes';
import CopyFromBomList from './CopyFromBomList';
import iBomItemAttribute from '../../../../../../types/product/iBomItemAttribute';

const AsyncSearchWrapper = styled.div`
  width: 90%;
  min-width: 15rem;
  min-height: 20rem;
  overflow-y: hidden;
  margin: 1rem auto;

  & .copy-from-label {
    margin-bottom: 0.5rem;
  }
`;

const WarningWrapper = styled.div`
  font-size: 0.7rem;
  width: 60%;
  float: right;
  display: flex;
`;

const RetypeWrapper = styled.div`
  margin-top: 2rem;
  .soft-copy-confirm-info {
    display: block;
    margin: 4px 0px 8px;
  }
`;

const CopyFromBomListScrollable = styled.div`
  height: 12rem;
  overflow-y: auto;
  margin-top: 2rem;
  border: 1px solid grey;
  padding: 1rem;
`;

const CopyFromModal = ({
  bomItemAttrCluster,
  getSourceProductId,
  onCheckRetypeAnswer,
}: {
  getSourceProductId: (payload: string) => void;
  onCheckRetypeAnswer: (isRightAnswer: boolean) => void;
  bomItemAttrCluster: Array<iBomItemAttribute>;
}) => {
  const [sourceProductId, setSourceProductId] = useState('');
  const [sourceProductInfo, setSourceProductInfo] = useState<string[]>([]);
  const [retypeValue, setRetypeValue] = useState('');

  const onSelectCopyFrom = (payload: string, actionMeta: iActionTypes) => {
    if (!payload && actionMeta.action !== 'clear') return;

    if (!payload && actionMeta.action === 'clear') {
      setSourceProductId('');
      getSourceProductId('');
    }
    // cb to control modal state
    if (payload) {
      getSourceProductId(payload.split(':')[0]);
      setSourceProductId(payload.split(':')[0]);

      setSourceProductInfo([...payload.split(':')]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRetypeInputChange = (event: any) => {
    setRetypeValue(event.target.value);
    onCheckRetypeAnswer(event.target.value.trim() === sourceProductInfo[1]); // correct answer is target SKU
  };

  return (
    <>
      <AsyncSearchWrapper>
        <div className={'copy-from-label'}>Copy BOM from another product</div>
        <AsyncSearch
          onSelect={onSelectCopyFrom}
          // shouldControlRenderValue
          promiseFn={(keyword: string) =>
            getProductListAsyncSearch({
              like: `productCode:${keyword},name:${keyword}`,
            })
          }
          optionLabel={['productCode', 'name']}
          searchBarPlaceholder={'Type in a product name, sku...'}
        />

        {sourceProductId ? (
          <>
            <Grid layout={'fluid'} spacing={'compact'}>
              <GridColumn medium={6}>
                <Label label="SKU" />
                <LabelValue>{sourceProductInfo[1]}</LabelValue>
              </GridColumn>
              <GridColumn medium={6}>
                <Label label="Name" />
                <LabelValue>{sourceProductInfo[2]}</LabelValue>
              </GridColumn>
            </Grid>
            {/* <Grid layout={'fluid'} spacing={'compact'}>
              <GridColumn medium={12}> */}
            <CopyFromBomListScrollable>
              <CopyFromBomList sourceProductId={sourceProductId} bomItemAttrCluster={bomItemAttrCluster} />
            </CopyFromBomListScrollable>
            {/* </GridColumn>
            </Grid> */}
            <RetypeWrapper className={'copyFrom-retype-wrapper'}>
              <span className={'soft-copy-confirm-info'}>
                Before you copy it, please type <strong>{sourceProductInfo[1]}</strong> to confirm
              </span>
              <Textfield
                value={retypeValue}
                onChange={onRetypeInputChange}
                isCompact
                testId={'textfield-enter-answer'}
              />
            </RetypeWrapper>
          </>
        ) : null}
      </AsyncSearchWrapper>
      <WarningWrapper>
        <div>
          <EditorWarningIcon label="edit warning" primaryColor="orange" size="small" />
        </div>

        <div>
          By confirm the copying action, your current BOM information will be replaced by those from selected product
        </div>
      </WarningWrapper>
    </>
  );
};

export default CopyFromModal;
