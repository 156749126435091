import { ASYNC_SEARCH_PER_PAGE, ASYNC_SEARCH_CURRENT_PAGE } from '../shared/constants/AsyncConstants';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getAOList = (config?: iParams) => {
  return AppService.get('/acquisitionOrder', {
    include: 'supplier,status,createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const getAODetail = (id: string, params = {}) => {
  return AppService.get(`/acquisitionOrder/${id}`, {
    include: 'supplier,status,taxBase,status',
    ...params,
  }).then(({ data }) => data);
};

export const createAO = (postData: iKeyValuePairs) => {
  return AppService.post('/acquisitionOrder', postData).then(({ data }) => data);
};

export const updateAO = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/acquisitionOrder/${id}`, updateContent).then(({ data }) => data);
};

export const deleteAO = (id: string) => {
  return AppService.delete(`/acquisitionOrder/${id}`).then(({ data }) => data);
};

export const getAOListAsyncSearch = (config?: iParams) => {
  return AppService.get('/acquisitionOrder', {
    include: 'supplier,status,createdBy,updatedBy',
    ...config,
    perPage: ASYNC_SEARCH_PER_PAGE,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
  }).then(({ data }) => data);
};

export default {
  getAOList,
  getAODetail,
  createAO,
  updateAO,
  deleteAO,
};
