import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import PageTitleWithAddOne from '../../shared/heading/PageTitleWithAddOne';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import SearchBar from '../../shared/search/SearchBar';
import SpinWhenLoading from '../../shared/spin/SpinWhenLoading';
import iUnitOfMeasurements from '../../types/iUnitOfMeasurement';
import iProductAttributeSet from '../../types/product/iProductAttributeSet';
import iProductType from '../../types/product/iProductType';
import ProductTypeEditModal from './ProductTypeEditModal';
import ProductTypeListTable from './ProductTypeListTable';
import { FlexContainer } from '../../shared/styles/styles';
import { apiErrorToast } from '../../shared/toast/Toast';
import { getUnits } from '../../services/Settings/UnitMeasurementService';
import { getProdAttributeSets } from '../../services/product/ProductAttributeSetService';
import {
  createProductType,
  deactivateProductType,
  getProductTypes,
  updateProductType,
} from '../../services/product/ProductTypeService';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCTS } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE } from '../../types/settings/UserAccess/iRoleAccess';

type iState = {
  name?: string;
  value?: boolean;
  isConfirming: boolean;
  units: Array<iUnitOfMeasurements>;
  attributeSets: Array<iProductAttributeSet>;
};
const initialState: iState = {
  isConfirming: false,
  units: [],
  attributeSets: [],
};

const ProductTypeList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onSearch,
    onSubmit,
    onSetSort,
  } = useListCrudHook<iProductType>({
    getFn: getProductTypes,
    createFn: createProductType,
    updateFn: updateProductType,
    deleteFn: deactivateProductType,
    keywordColumns: 'name',
    sort: 'name:ASC',
  });

  const { control, setValue, errors, handleSubmit } = useForm();
  const [supState, setSupState] = useState(initialState);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = (data: any) => {
    onSubmit({
      ...data,
      measurementId: data.measurementId,
    });
  };

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const units = await getUnits();
        const attributeSets = await getProdAttributeSets();
        setSupState(prevState => ({ ...prevState, units, attributeSets }));
      } catch (e) {
        apiErrorToast(e);
      }
    };
    fetchUnits();
  }, []);

  const onEditIsForCheckbox = (name: string, value: boolean, id: string) => {
    setSupState({ ...supState, name, value });
    // setIsFor({ ...isFor, name, value });
    onOpenEditModal(id);
  };

  const onEdit = (id: string) => {
    setSupState({ ...supState, name: undefined, value: undefined });
    onOpenEditModal(id);
  };

  const getPageHeader = () => (
    <FlexContainer className={'space-between'}>
      <PageTitleWithAddOne
        title={'Product Types'}
        onClickAddBtn={onOpenAddModal}
        isDisabled={!AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_DELETE, user)}
      />
      <div style={{ flexGrow: 0 }}>
        <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search by name...'} />
      </div>
    </FlexContainer>
  );
  if (state.isLoading) return <SpinWhenLoading />;
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <ProductTypeListTable
        data={state.data}
        onDelete={onDelete}
        onEdit={onEdit}
        onEditFlag={onEditIsForCheckbox}
        onSetSort={onSetSort}
        isLoading={state.isLoading}
        canUpdate={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_DELETE, user)}
        canDelete={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_DELETE, user)}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(submit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <ProductTypeEditModal
            control={control}
            errors={errors}
            onChange={setValue}
            target={edit.target}
            isFor={{ name: supState.name, value: supState.value }}
            units={supState.units}
            attributeSets={supState.attributeSets}
          />
        }
        modalHeading={edit.target ? edit.target.name : 'Create'}
        confirmBtnName={'Save'}
      />
    </BackendPage>
  );
};

export default ProductTypeList;
