import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import DeleteByRetype from '../../shared/deleteWithReType/DeleteByReType';
import BreadcrumbsTwoLayers from '../../shared/heading/BreadcrumbsTwoLayers';
import useDetailHook from '../../shared/hooks/useDetailHook/useDetailHook';
import MoreIconPopup from '../../shared/popup/MoreIconPopup';
import DetailInfo from './detail/DetailInfo';
import PopupContent from '../../shared/popup/GeneralPopupContent';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import iEntityCategory from '../../types/status/iEntityCategory';
import iStockTake from '../../types/stockTake/iStockTake';
import SpinWhenLoading from '../../shared/spin/SpinWhenLoading';
import NotFinished from './detail/notFinished/NotFinished';
import Finished from './detail/finished/Finished';
import { STOCK_TAKE_URL } from '../../shared/UrlMap';
import { DETAIL_PAGE_NAME, DETAIL_PREFIX, FINISHING_WARNING_BOTTOM } from './shared/StockTake.constant';
import { getEntityCategoryList } from '../../services/CategoryService';
import { addToastForAPIResponse, apiErrorToast } from '../../shared/toast/Toast';
import {
  classifyEntityCategoryByStatusId,
  getStatusByCategories,
  handleNullException,
} from '../../services/UtilsService';
import { iParams, iRouteTypeId } from '../../shared/UITypes/types';
import { FlexContainer, PageTitle, SpaceVerticalContainer } from '../../shared/styles/styles';
import {
  deactivateStockTake,
  finishStockTake,
  getStockTakeDetail,
  previewFinishStockTake,
  startStockTake,
  updateStockTake,
} from '../../services/StockTakeService';
import { getWarehouseLocationPathNames } from '../../services/WarehouseService';
import iStockTakeItem from '../../types/stockTake/iStockTakeItem';
import FinishModalBody from './detail/foundItems/Finish/FinishModalBody';
import { SecondaryTextWrapper } from './shared/StockTake.style';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_TAKES } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE, ACCESS_CAN_UPDATE } from '../../types/settings/UserAccess/iRoleAccess';

type iSupportState = {
  isLoadingPreviewItems: boolean;
  isConfirming: boolean;
  isStartModalOpen: boolean;
  isFinishModalOpen: boolean;
  categories: Array<iEntityCategory>;
  idNameMap?: iParams;
  previewItems: Array<iStockTakeItem>;
};
const initialSupportState: iSupportState = {
  isLoadingPreviewItems: false,
  isConfirming: false,
  isStartModalOpen: false,
  isFinishModalOpen: false,
  categories: [],
  previewItems: [],
};
const StockTakeDetail = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const { id } = useParams<iRouteTypeId>();
  const history = useHistory();
  const {
    state,
    edit,
    onOpenDeleteModal,
    onDelete,
    onCloseModal,
    onUpdateOneField,
    onUpdateEagerLoadObject,
  } = useDetailHook<iStockTake>({
    id,
    getFn: getStockTakeDetail,
    updateFn: updateStockTake,
    deleteFn: deactivateStockTake,
    deleteCallback: () => history.push(STOCK_TAKE_URL),
  });
  const [supportState, setSupportState] = useState(initialSupportState);
  const { isNew, isWIP, isFinished } = classifyEntityCategoryByStatusId(supportState.categories, state.data?.statusId);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_STOCK_TAKES, ACCESS_CAN_UPDATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_STOCK_TAKES, ACCESS_CAN_DELETE, user);

  useEffect(() => {
    let isCancelled = false;
    const fetchDetail = async () => {
      try {
        const categories: Array<iEntityCategory> = await getEntityCategoryList({
          filter: 'entityStatuses.entityStatusType.entityName:StockTake',
        });
        if (isCancelled) return;
        setSupportState(prevState => ({ ...prevState, categories }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
      }
    };
    fetchDetail();
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(
    () => {
      let isCancelled = false;
      const fetchIdNameMap = async () => {
        if (typeof state.data === 'undefined') return;
        try {
          const stringIds = JSON.stringify([state.data.lostAndFoundLocationId, state.data.warehouseLocationId]);
          const { idNameMap } = await getWarehouseLocationPathNames(stringIds);
          if (isCancelled) return;
          setSupportState(prevState => ({ ...prevState, idNameMap }));
        } catch (error) {
          if (isCancelled) return;
          apiErrorToast(error);
        }
      };
      fetchIdNameMap();
      return () => {
        isCancelled = true;
      };
    },
    //  eslint-disable-next-line
    [
      //  eslint-disable-next-line
      JSON.stringify([
        state.data?.lostAndFoundLocationId,
        state.data?.warehouseLocationId,
      ]),
    ],
  );

  const onOpenStartModal = () => setSupportState({ ...supportState, isStartModalOpen: true });

  const onCloseStartModal = () =>
    setSupportState({
      ...supportState,
      isStartModalOpen: false,
      isConfirming: false,
    });

  const onOpenFinishModal = async () => {
    setSupportState({ ...supportState, isLoadingPreviewItems: true });
    try {
      const previewItems: Array<iStockTakeItem> = await previewFinishStockTake(id);
      setSupportState({
        ...supportState,
        isFinishModalOpen: true,
        previewItems,
        isLoadingPreviewItems: false,
      });
    } catch (error) {
      apiErrorToast(error);
      setSupportState({ ...supportState, isLoadingPreviewItems: false });
    }
  };
  const onCloseFinishModal = () =>
    setSupportState({
      ...supportState,
      isFinishModalOpen: false,
      previewItems: [],
      isConfirming: false,
    });

  /**
   * start will affect started at and status
   * started at directly from after start
   * status need to by the help of supportState.categories
   */
  const onStart = async () => {
    setSupportState({ ...supportState, isConfirming: true });
    try {
      const afterStart: iStockTake = await startStockTake(id);
      const status = getStatusByCategories(supportState.categories, afterStart.statusId);
      if (typeof status === 'undefined') return;
      onUpdateEagerLoadObject({
        status,
        statusId: status.id,
        startedAt: afterStart.startedAt,
      });
      onCloseStartModal();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
      setSupportState({ ...supportState, isConfirming: false });
    }
  };
  /**
   * finish will affect finished at and status
   * started at directly from after start
   * status need to by the help of supportState.categories
   */
  const onFinish = async () => {
    setSupportState({ ...supportState, isConfirming: true });
    try {
      const afterFinish: iStockTake = await finishStockTake(id);
      const status = getStatusByCategories(supportState.categories, afterFinish.statusId);
      if (typeof status === 'undefined') return;
      onUpdateEagerLoadObject({
        status,
        statusId: status.id,
        finishedAt: afterFinish.finishedAt,
      });
      onCloseFinishModal();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
      setSupportState({ ...supportState, isConfirming: false });
    }
  };

  const breadCrumbsSubName = `${DETAIL_PREFIX}: ${handleNullException(state.data, 'stocktakeNum')}`;
  const getPageHeader = () => {
    return (
      <FlexContainer className={'space-between'}>
        <div>
          <BreadcrumbsTwoLayers
            name={DETAIL_PAGE_NAME}
            url={STOCK_TAKE_URL}
            // onClick={() => history.push(STOCK_TAKE_URL)}
            subName={breadCrumbsSubName}
          />
          <FlexContainer>
            <PageTitle className={'small space-right'}>{breadCrumbsSubName}</PageTitle>
            {/* <StatusMenu*/}
            {/*  defaultValue={state.data?.statusId}*/}
            {/*  categories={supportState.categories}*/}
            {/*  onSelect={(newValue: string) => onUpdate({ statusId: newValue })}*/}
            {/* />*/}
          </FlexContainer>
        </div>
        <ButtonGroup>
          {isNew && canUpdate ? (
            <Button appearance={'primary'} onClick={onOpenStartModal}>
              Start
            </Button>
          ) : null}
          {canDelete ? (
            <MoreIconPopup>
              <PopupContent id={id} onOpenDeleteModal={onOpenDeleteModal} />
            </MoreIconPopup>
          ) : null}
        </ButtonGroup>
      </FlexContainer>
    );
  };

  const getDataTable = () => {
    if (!state.data) {
      return null;
    }
    if (isFinished) {
      return <Finished stockTakeDetail={state.data} canUpdate={canUpdate} />;
    }

    return (
      <NotFinished stockTakeDetail={state.data} categoryClassify={{ isNew, isWIP, isFinished }} canUpdate={canUpdate}>
        <LoadingButton
          appearance={'primary'}
          onClick={onOpenFinishModal}
          isLoading={supportState.isLoadingPreviewItems}
          isDisabled={!canUpdate}
        >
          Finish
        </LoadingButton>
      </NotFinished>
    );
  };

  if (state.isLoading) return <SpinWhenLoading />;
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <DetailInfo
        detail={state.data}
        onUpdate={onUpdateOneField}
        idNameMap={supportState.idNameMap}
        categoryClassify={{ isNew, isWIP, isFinished }}
        canUpdate={canUpdate}
      />
      <SpaceVerticalContainer />
      {getDataTable()}
      <DeleteByRetype
        isOpen={edit.isDeleteModalOpen}
        onClose={onCloseModal}
        onConfirm={() => onDelete(id)}
        answer={state.data?.id}
        isConfirming={state.isConfirming}
      />
      <CustomizeModal
        isOpen={supportState.isStartModalOpen}
        onConfirm={onStart}
        onCancel={onCloseStartModal}
        isConfirming={supportState.isConfirming}
      />
      <CustomizeModal
        isOpen={supportState.isFinishModalOpen}
        onConfirm={onFinish}
        onCancel={onCloseFinishModal}
        isConfirming={supportState.isConfirming}
        modalBody={<FinishModalBody stockTakeItems={supportState.previewItems} />}
        modalHeading={`Finishing stocktake: ${state.data?.stocktakeNum}`}
        bottom={
          <FlexContainer>
            <WarningIcon label={'warning'} primaryColor={'#FF8B00'} />
            <SecondaryTextWrapper>{FINISHING_WARNING_BOTTOM}</SecondaryTextWrapper>
          </FlexContainer>
        }
        width={'large'}
      />
    </BackendPage>
  );
};

export default StockTakeDetail;
