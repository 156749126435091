import React from 'react';
import styled from 'styled-components';

const HeadingContainer = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #172b4d;
  font-weight: 500;
  &.space-below {
    padding-bottom: 1rem;
  }
  &.more-space-below {
    padding-bottom: 2.5rem;
  }
`;

const PageHeading = ({
  content,
  className,
}: {
  content: string;
  className?: string;
}) => {
  return <HeadingContainer className={className}>{content}</HeadingContainer>;
};

export default PageHeading;
