import React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import { Label } from '@atlaskit/field-base';
import iContactCompany from '../../types/contactCompany/iContactCompany';
import InlineEdit from '../form/InlineEdit';
import UpdateAddressPopup from '../popup/UpdateAddressPopup';
import { updateContactCompany } from '../../services/ContactCompanyService';
import { addToastForAPIResponse, apiErrorToast } from '../toast/Toast';
import { iAddressContent } from '../../types/iAddressContent';
import AddressService from '../../services/AddressService';
import { LabelValue, AddressPlaceholderWrapper } from '../styles/styles';
import { assembleAddress } from '../../services/UtilsService';

const ContCompanyKeyInfo = ({
  contComp,
  isShowShippingAddress,
  onUpdateContComp,
}: {
  contComp: iContactCompany;
  isShowShippingAddress: boolean;
  //    eslint-disable-next-line
  onUpdateContComp: (name: string, value: any) => void;
}) => {
  const onConfirm = async (name: string, value: string) => {
    try {
      await updateContactCompany(contComp.id, {
        [name]: value,
      });
      onUpdateContComp(name, value);
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };
  const onAddressChange = async (isBillingAddress: boolean, data: iAddressContent) => {
    try {
      const addressId = isBillingAddress ? contComp.shippingAddressId : contComp.billingAddressId;
      const isSameId = contComp.shippingAddressId === contComp.billingAddressId;

      //  eslint-disable-next-line
      const updateAddressResponse = addressId
        ? isSameId
          ? await AddressService.createAddress(data)
          : await AddressService.editAddress(addressId, data)
        : await AddressService.createAddress(data);
      await onConfirm(`${isBillingAddress ? 'billingAddressId' : 'shippingAddressId'}`, updateAddressResponse.id);
      onUpdateContComp(`${isBillingAddress ? 'billingAddress' : 'shippingAddress'}`, updateAddressResponse);
    } catch (error) {
      apiErrorToast(error);
    }
  };
  const getAddressGridColumn = (isBillingAddress: boolean) => {
    const gridSize = isShowShippingAddress ? 6 : 12;
    const address = isBillingAddress ? contComp.billingAddress : contComp.shippingAddress;
    //    eslint-disable-next-line
    const labelAndHeading = isBillingAddress
      ? 'Billing Address'
      : 'Shipping Address';
    return (
      <GridColumn medium={gridSize}>
        <Label label={labelAndHeading} isFirstChild />
        <UpdateAddressPopup
          address={address}
          heading={labelAndHeading}
          onClick={(data: iAddressContent) => onAddressChange(isBillingAddress, data)}
        >
          <LabelValue>
            {assembleAddress(address) || <AddressPlaceholderWrapper>Click to enter value</AddressPlaceholderWrapper>}
          </LabelValue>
        </UpdateAddressPopup>
      </GridColumn>
    );
  };

  return (
    <>
      <Grid spacing={'compact'}>
        <GridColumn medium={6}>
          <InlineEdit name={'email'} defaultValue={contComp.email} label={'Email'} onConfirm={onConfirm} />
        </GridColumn>
        <GridColumn medium={6}>
          <InlineEdit name={'phone'} defaultValue={contComp.phone} label={'Phone'} onConfirm={onConfirm} />
        </GridColumn>
      </Grid>
      <Grid spacing={'compact'}>
        {isShowShippingAddress && getAddressGridColumn(false)}
        {getAddressGridColumn(true)}
      </Grid>
    </>
  );
};

export default ContCompanyKeyInfo;
