import React from 'react';
import { SpaceVerticalContainer } from '../../../shared/styles/styles';
import { RedirectWrapper } from './Auth.style';

const RedirectAfterSuccessAction = ({
  message,
  children,
}: {
  message: string;
  children?: React.ReactNode;
}) => {
  return (
    <RedirectWrapper data-testid={'redirect-wrapper'}>
      <div className={'message'}>{message}</div>
      <SpaceVerticalContainer
        className={'quarter'}
        data-testid={'space-vertical-quarter'}
      />
      {children}
    </RedirectWrapper>
  );
};

export default RedirectAfterSuccessAction;
