import React from 'react';
import styled from 'styled-components';
import { iAdvancedLabelValuePair } from '../../../../shared/UITypes/types';

const TabItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  .tab-underscore {
    background-color: rgb(235, 236, 240);
    border-radius: 2px;
    bottom: 0px;
    content: '';
    height: 2px;
    left: 0px;
    margin: 0px;
    position: absolute;
    right: 0px;
    width: inherit;
  }
  .tab-items {
    display: flex;
    font-weight: 500;
    color: rgb(66, 82, 110);
  }
  .tab-item {
    .tab-underscore {
      left: 1rem;
      right: 1rem;
    }
    &.selected {
      color: rgb(0, 82, 204) !important;
      .tab-underscore {
        background-color: rgb(0, 82, 204) !important;
      }
    }
    &.first-tab-item {
      padding-left: 0px;
      .tab-underscore {
        left: 0px;
      }
    }
    cursor: pointer;
    line-height: 1.8;
    margin: 0px;
    padding: 4px 1rem;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tab-item-channel {
    display: flex;
    img {
      width: 24px;
      height: auto;
      margin-right: 5px;
    }
    .channel-name {
      max-width: 3.4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
const ItemWrapper = styled.div``;
const DetailTabs = ({
  selected,
  tabItems,
  onSwitch,
  extraItem,
}: {
  tabItems: Array<iAdvancedLabelValuePair>;
  onSwitch: (type: string) => void;
  selected?: string;
  extraItem?: React.ReactNode;
}) => {
  return (
    <TabItemsWrapper>
      <span className={'tab-underscore'} />
      <div className="tab-items" data-testid={'product-detail-tab-items'}>
        {tabItems.map((item, index) => (
          <ItemWrapper
            key={`tab-item-${item.value}`}
            className={`tab-item ${selected === item.value && 'selected'} ${index === 0 && 'first-tab-item'}`}
            onClick={() => onSwitch(item.value)}
            data-testid={`product-detail-tab-item-${item.value}`}
          >
            {item.label}
            <span className={'tab-underscore'} />
          </ItemWrapper>
        ))}
      </div>
      <div className={'tab-item'}>{extraItem}</div>
    </TabItemsWrapper>
  );
};

export default DetailTabs;
