import Button from '@atlaskit/button';
import React, { useEffect, useState } from 'react';
import { Edge, Node, useEdges, useNodes } from 'react-flow-renderer';
import { iWFDiagramState } from '../../../shared/workflow/diagram/WorkflowDiagramReducer';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import WorkflowTransitDisplay from './WorkflowTransitDisplay';

type iWorkflowDeleteStatusPopupBtn = {
  deletingNode: Node;
  state: iWFDiagramState;
};
const WorkflowDeleteStatusPopupBtn = ({ deletingNode, state }: iWorkflowDeleteStatusPopupBtn) => {
  const [relatedEdges, setRelatedEdges] = useState<Edge[]>([]);
  const [showingConfirmDeleting, setShowingConfirmDeleting] = useState(false);
  const nodes = useNodes();
  const edges = useEdges();

  useEffect(() => {
    setRelatedEdges(edges.filter(edge => edge.source === deletingNode.id || edge.target === deletingNode.id));
  }, [edges, deletingNode.id]);

  const deleteNode = () => {
    const relatedEdgeIds = relatedEdges.map(edge => edge.id);
    state.setNodes(nodes.filter(node => node.id !== deletingNode.id && node.parentNode !== deletingNode.id));
    state.setEdges(edges.filter(edge => relatedEdgeIds.indexOf(edge.id) < 0));
  };

  const getAllRelatedEdges = () => {
    if (relatedEdges.length <= 0) {
      return null;
    }
    return (
      <div>
        and all its transitions:
        {relatedEdges.map(edge => (
          <WorkflowTransitDisplay edge={edge} key={edge.id} />
        ))}
      </div>
    );
  };

  const getConfirmModal = () => {
    if (showingConfirmDeleting !== true) {
      return null;
    }
    return (
      <CustomizeModal
        isOpen={showingConfirmDeleting === true}
        onCancel={() => setShowingConfirmDeleting(false)}
        onConfirm={deleteNode}
        modalHeading={'Are you sure?'}
        confirmBtnAppearance={'danger'}
        confirmBtnName={'Delete'}
        modalBody={
          <>
            <p>
              Are you sure to delete status <b>{deletingNode.data.label}</b>?
            </p>
            {getAllRelatedEdges()}
          </>
        }
      />
    );
  };

  return (
    <>
      <Button appearance={'subtle'} onClick={() => setShowingConfirmDeleting(true)}>
        Delete
      </Button>
      {getConfirmModal()}
    </>
  );
};

export default WorkflowDeleteStatusPopupBtn;
