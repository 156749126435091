import React from 'react';
import { getEntityCategoryList } from '../../../../services/CategoryService';
import {
  createPoReceiving,
  deletePoReceiving,
  getPoReceivings,
  updatePoReceiving,
} from '../../../../services/PoReceivingService';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';

import useColumnHooks from '../../../../shared/hooks/useShowHideColumns';
import useStatusSelector from '../../../../shared/hooks/useStatusSelector';
import CustomizePagination from '../../../../shared/pagination/CustomizePagination';
import ShowHideColumns from '../../../../shared/showHideColumns/ShowHideColumns';
import { FlexContainer } from '../../../../shared/styles/styles';
import iPoReceiving from '../../../../types/purchases/iPoReceiving';
import { PO_RECEIVES_TABLE_NAME, PO_RECEIVES_TABLE_COLUMNS, PO_RECEIVES_TABLE_FLEX_COLUMNS } from '../../constants';
import PoReceiveTable from './PoReceiveTable';

const PoReceiveList = ({ poId }: { poId: string }) => {
  const [selectedColumns, onToggleColumn] = useColumnHooks(PO_RECEIVES_TABLE_NAME, {
    default: PO_RECEIVES_TABLE_COLUMNS,
    whole: PO_RECEIVES_TABLE_COLUMNS,
  });

  const { categories } = useStatusSelector({
    type: 'PurchaseOrderReceiving',
    isMulti: true,
    getFn: getEntityCategoryList,
  });

  const { state, onDelete, onSetCurrentPage } = useListCrudHook<iPoReceiving>({
    getFn: getPoReceivings,
    deleteFn: deletePoReceiving,
    createFn: createPoReceiving,
    updateFn: updatePoReceiving,
    paginationApplied: true,
    perPage: 5,
    filter: `purchaseOrderId:${poId}`,
    sort: 'createdAt:DESC',
  });
  return (
    <>
      <FlexContainer className={'space-between'}>
        <div />
        <ShowHideColumns
          onToggleColumns={onToggleColumn}
          selectedColumns={selectedColumns}
          columnLists={[{ label: '', value: PO_RECEIVES_TABLE_FLEX_COLUMNS }]}
        />
      </FlexContainer>
      <PoReceiveTable
        data={state.data}
        onDelete={onDelete}
        isLoading={state.isLoading}
        columns={selectedColumns}
        categories={categories}
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );
};

export default PoReceiveList;
