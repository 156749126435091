import styled from 'styled-components';
import { NCR_TABLE_NAME } from './NCRList.constant';

export const NCRTableWrapper = styled.div`
  th {
    padding-left: 4px;
    padding-right: 4px;
  }
  th[data-testid='${NCR_TABLE_NAME}-column-problem--head--cell'] {
    min-width: 200px;
    max-width: 300px;
  }
  th[data-testid='${NCR_TABLE_NAME}-column-operation--head--cell'] {
    max-width: 4rem;
    min-width: 3rem;
  }
`;
