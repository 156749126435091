import AppService from '../../AppService';
import { iPaginatedResult } from '../../../types/iPagination';
import iRoleAccess from '../../../types/settings/UserAccess/iRoleAccess';
import { iParams } from '../../../shared/UITypes/types';

const endPoint = '/roleAccess';

const getRoleAccessList = (config = {}): Promise<iPaginatedResult<iRoleAccess>> => {
  return AppService.get(endPoint, config).then(({ data }) => data);
};

const getRoleAccess = (id: string, config = {}): Promise<iRoleAccess> => {
  return AppService.get(`${endPoint}/${id}`, config).then(({ data }) => data);
};

const createRoleAccess = (params: iParams): Promise<iRoleAccess> => {
  return AppService.post(endPoint, params).then(({ data }) => data);
};

const updateRoleAccess = (id: string, params: iParams): Promise<iRoleAccess> => {
  return AppService.put(`${endPoint}/${id}`, params).then(({ data }) => data);
};

const deleteRoleAccess = (id: string, config = {}): Promise<iRoleAccess> => {
  return AppService.delete(`${endPoint}/${id}`, config).then(({ data }) => data);
};

export default {
  getRoleAccessList,
  getRoleAccess,
  createRoleAccess,
  updateRoleAccess,
  deleteRoleAccess,
};
