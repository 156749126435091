import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { BACK_END_URL, BILLING_HOME_URL } from '../UrlMap';

export const HEAD_CONTENT = 'Service not activated';
export const BODY_CONTENT =
  'Unfortunately,This service is not activated in your current plan. Do you want to upgrade your plan to enable it?';
const ModuleException = () => {
  const history = useHistory();
  return (
    <Modal autoFocus={false} testId={'popup-modal'}>
      <ModalHeader>
        <ModalTitle>{HEAD_CONTENT}</ModalTitle>
      </ModalHeader>
      <ModalBody>{BODY_CONTENT}</ModalBody>
      <ModalFooter>
        <div />
        <div>
          <Button appearance={'subtle'} onClick={() => history.push(BACK_END_URL)} testId={'cancel-btn'}>
            To Dashboard
          </Button>
          <Button testId={'save-btn'} appearance={'primary'} onClick={() => history.push(BILLING_HOME_URL)}>
            Upgrade Plan
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ModuleException;
