import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import iContactCompanyProduct from '../../types/contactCompany/iContactCompanyProduct';
import iUnitOfMeasurements from '../../types/iUnitOfMeasurement';
import LinkBtn from '../buttons/LinkBtn';
import TransparentIconBtn from '../buttons/TransparentIconBtn';
import ComposeSecondaryText from '../text/ComposeSecondaryText';
import ConversionText from '../text/ConversionText';
import LastSOInfo from './CCPProductCustomer/LastSOInfo';
import CCPCreateOrderButton from './CCPCreateOrder/CCPCreateOrderButton';
import { iConfigColumn } from '../UITypes/types';
import { handleNullException, handleMoney, handleNumber } from '../../services/UtilsService';

export const mapMeasurementToLabelValuePair = (list: Array<iUnitOfMeasurements>) => {
  if (list?.length === 0) return [];
  //    eslint-disable-next-line
  return list?.map((l: iUnitOfMeasurements) => ({
    label: l.shortName,
    value: l.id,
  }));
};

export const getRows = ({
  data,
  onOpenDeleteModal,
  onOpenEditModal,
  columns,
  fixedProduct,
  isCustomer,
}: {
  data: Array<iContactCompanyProduct>;
  onOpenDeleteModal: (id: string) => void;
  onOpenEditModal: (id: string) => void;
  columns: Array<iConfigColumn>;
  fixedProduct: boolean;
  isCustomer: boolean;
}) => {
  return data.map((item: iContactCompanyProduct) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'edit-trigger-seconday':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[0])}>
                <LinkBtn btnName={handleNullException(item, key)} onClick={() => onOpenEditModal(item.id)} />
              </ComposeSecondaryText>
            ),
          };
        case 'secondary-text':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[0])}>
                {handleNullException(item, key)}
              </ComposeSecondaryText>
            ),
          };
        case 'edit-trigger':
          return {
            key: handleNullException(item, key),
            content: <LinkBtn btnName={handleNullException(item, key)} onClick={() => onOpenEditModal(item.id)} />,
          };
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'money-unit':
          return {
            key: handleNullException(item, key),
            content: `${handleMoney(item, key, 2)} / ${
              handleNullException(item, dataSource[0]) || handleNullException(item, dataSource[1])
            }`,
          };
        case 'number':
          return {
            key: handleNullException(item, key),
            content: handleNumber(item, key),
          };
        case 'conversion-unit':
          return {
            key: handleNullException(item, key),
            content: (
              <ConversionText
                value={1}
                conversion={handleNullException(item, key)}
                contactCompanyUnit={
                  handleNullException(item, dataSource[0]) || handleNullException(item, dataSource[1])
                }
                productUnit={handleNullException(item, dataSource[1])}
              />
            ),
          };
        case 'lastSOInfo':
          return {
            content: (
              <LastSOInfo
                lastSoKey={fixedProduct ? item.contactCompanyId : item.productId}
                columnName={dataSource[0]}
              />
            ),
          };
        case 'add':
          return {
            key: handleNullException(item, key),
            content: <CCPCreateOrderButton ccp={item} isCustomer={isCustomer} />,
          };
        case 'instructions-edit':
          return {
            content: handleNullException(item, key) && (
              <TransparentIconBtn
                icon={<CommentIcon label="instructions-edit" />}
                onClick={() => onOpenEditModal(handleNullException(item, 'id'))}
              />
            ),
          };
        case 'delete':
          return {
            key: handleNullException(item, key),
            content: (
              <TransparentIconBtn
                icon={<TrashIcon label="delete" />}
                onClick={() => onOpenDeleteModal(handleNullException(item, 'id'))}
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'AO-items' };
  });
};

export const filterColumns = (columns: iConfigColumn[], filterTargets: Array<string>) => {
  return columns.filter((item: iConfigColumn) => !filterTargets.includes(item.key));
};
