import React from 'react';
import {
  createPoReceivingItem,
  deletePoReceivingItem,
  getPoReceivingItems,
  updatePoReceivingItem,
} from '../../../../services/PoReceivingItemService';
import { STATUS_CATEGORY_FINISHED } from '../../../../shared/constants/StatusCategories';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../../../../shared/hooks/useShowHideColumnHook/useColumnHook';
import CustomizePagination from '../../../../shared/pagination/CustomizePagination';
import { FlexContainer } from '../../../../shared/styles/styles';

import iReceivingItem from '../../../../types/purchases/iReceivingItem';
import { PO_RECEIVED_PRODUCTS_TABLE_COLUMNS, PO_RECEIVED_PRODUCTS_TABLE_NAME } from '../../constants';
import ReceivedProductTable from './ReceivedProductTable';

const ReceivedProductList = ({ poId }: { poId: string }) => {
  const { columns, getShowHideColumns } = useColumnHooksUpgrade(
    PO_RECEIVED_PRODUCTS_TABLE_NAME,
    PO_RECEIVED_PRODUCTS_TABLE_COLUMNS,
  );

  const { state, onSetCurrentPage } = useListCrudHook<iReceivingItem>({
    getFn: getPoReceivingItems,
    deleteFn: deletePoReceivingItem,
    createFn: createPoReceivingItem,
    updateFn: updatePoReceivingItem,
    paginationApplied: true,
    perPage: 5,
    sort: 'createdAt:DESC',
    filter: `purchaseOrderReceiving.status.entityStatusCategory.code:${STATUS_CATEGORY_FINISHED},purchaseOrderReceiving.purchaseOrderId:${poId}`,
  });

  return state.isLoading ? null : (
    <>
      <FlexContainer className={'space-between'}>
        <div />
        {getShowHideColumns()}
      </FlexContainer>
      <ReceivedProductTable isLoading={state.isLoading} data={state.data} columns={columns} />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );
};

export default ReceivedProductList;
