/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import moment from 'moment';
import BillingAccountService from '../../../../services/billing/BillingAccountService';
import { FlexContainer, SpaceWrapper } from '../../../../shared/styles/styles';
import { currencyFormatter } from '../../../../services/UtilsService';
import { iStripeInvoice } from '../../../../shared/UITypes/types';
import MathHelper from '../../../../services/MathHelper';

const BillingHistoryWrapper = styled.div`
  .invoice-row {
    padding: 4px 10px;
    &:hover {
      background: #f1f1f1 !important;
    }
    &:nth-child(odd) {
      background: #fafafa;
    }
    .info {
      > div {
        padding: 0px 20px 0 0px;
      }
    }
  }
`;

const BillingHistory = () => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState<iStripeInvoice[]>([]);

  useEffect(() => {
    let isCanceled = false;
    if (loading !== true) {
      return undefined;
    }
    const getInvoices = () => {
      BillingAccountService.getInvoices().then(resp => {
        if (isCanceled === true) {
          return;
        }
        setLoading(false);
        setInvoices(resp.data);
      });
    };
    getInvoices();

    return () => {
      isCanceled = true;
    };
  }, [loading]);

  const getContent = () => {
    if (loading === true) {
      return <Spinner />;
    }
    if (invoices.length <= 0) {
      return null;
    }
    return invoices.map(invoice => {
      return (
        <FlexContainer className={'invoice-row space-between'} key={invoice.id} data-testid={`invoice-${invoice.id}`}>
          <FlexContainer className={'info'}>
            <div className={'created'} data-testid={'created'}>
              {invoice.created && moment.unix(invoice.created).format('ll')}
            </div>
            <div className={'amount'} data-testid={'amount'}>
              Total(incl.) <b>{currencyFormatter(MathHelper.div(invoice.amount_due, 100))}</b>
            </div>
          </FlexContainer>
          <div className={'link'}>
            <Button testId={'pdf-link'} appearance={'link'} href={invoice.invoice_pdf} target={'__blank'}>
              View Invoice
            </Button>
          </div>
        </FlexContainer>
      );
    });
  };

  return (
    <BillingHistoryWrapper>
      <SpaceWrapper className={'space-bottom'}>
        <h2 data-testid={'title'}>Billing History</h2>
        <small data-testid={'sub-title'}>
          You can view and download all your previous invoices here. If you’ve just made a payment, it may take a few
          hours for it to appear.
        </small>
      </SpaceWrapper>
      <div data-testid={'invoices'}>{getContent()}</div>
    </BillingHistoryWrapper>
  );
};

export default BillingHistory;
