import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const previewJobAttributeValues = (config: iParams) => {
  return AppService.get('/jobAttributeValue/previewValues', config).then(
    ({ data }) => data,
  );
};
export const getJobAttributeValues = (config?: iParams) => {
  return AppService.get('/jobAttributeValue', config).then(({ data }) => data);
};

export default { previewJobAttributeValues, getJobAttributeValues };
