import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { useSelector } from 'react-redux';
import LogList from '../../../shared/log/LogList';
import Jobs from '../../../shared/job/jobs/Jobs';
import CustomizeTab from '../../../shared/tab/CustomizeTab';
import {
  COLUMN_NAME_IN_STORAGE,
  DEFAULT_COLUMNS,
  FIXED_COLUMNS,
  MUST_COLUMNS_AFTER,
  MUST_COLUMNS_BEFORE,
} from './constants';
import CommentList from '../../../shared/comments/CommentList';
import PaymentList from './payments/PaymentList';
import DispatchNoteList from '../../despatchNote/DispatchNoteList';
import { getSalesOrderDetail } from '../../../services/SalesOrderService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import iSalesOrderItem from '../../../types/sales/iSalesOrderItem';
import { getDispatchNoteItems } from '../../../services/DispatchNoteItemService';
import iDispatchNoteItem from '../../../types/dispatchNote/iDispatchNoteItem';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_DISPATCH_NOTES, ACCESS_CODE_JOBS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';
import { ENTITY_STATUS_TYPE_DISPATCH_NOTE } from '../../../types/status/iEntityStatusType';

type iSOTabs = {
  orderId: string;
  jobsVersion: number;
  givenTabName?: string;
  isShowPayments: boolean;
};
const SOTabs = ({ orderId, jobsVersion, givenTabName, isShowPayments }: iSOTabs) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [dispatchNoteIds, setDispatchNoteIds] = useState<string[]>([]);

  useEffect(() => {
    let isCanceled = false;
    const getData = async () => {
      setIsLoading(true);
      try {
        const soDetails = await getSalesOrderDetail(orderId);
        const soOrderItemIds = soDetails.salesOrderItems.map((item: iSalesOrderItem) => item.id);
        const dnItems = await getDispatchNoteItems({
          jsonFilter: JSON.stringify({ salesOrderItemId: soOrderItemIds }),
          pageSize: 9999,
        });
        const dnIds = dnItems.data.map((dnItem: iDispatchNoteItem) => dnItem.dispatchNoteId);
        if (`${soDetails.createdFromEntityName || ''}` === ENTITY_STATUS_TYPE_DISPATCH_NOTE) {
          dnIds.push(soDetails?.createdFromEntityId);
        }
        if (isCanceled) return;
        setIsLoading(false);
        setDispatchNoteIds(dnIds);
      } catch (error) {
        apiErrorToast(error);
        setIsLoading(false);
      }
    };
    getData();
    return () => {
      isCanceled = true;
    };
  }, [orderId]);

  if (isLoading) {
    return <Spinner />;
  }

  const tabs = [
    {
      label: 'Jobs',
      content: (
        <Jobs
          isIndependent
          config={{ filter: `salesOrderId:${orderId}`, sort: 'updatedAt:DESC' }}
          columns={{
            name: COLUMN_NAME_IN_STORAGE,
            default: DEFAULT_COLUMNS,
            mustBefore: MUST_COLUMNS_BEFORE,
            mustAfter: MUST_COLUMNS_AFTER,
            positionFixed: FIXED_COLUMNS,
          }}
          version={jobsVersion}
        />
      ),
      isHidden: !AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_READ, user),
    },
    {
      label: 'Dispatch Notes',
      content:
        dispatchNoteIds.length > 0 ? (
          <DispatchNoteList independent={false} jsonFilter={JSON.stringify({ id: dispatchNoteIds })} />
        ) : null,
      isHidden: !AccessService.hasAccess(ACCESS_CODE_DISPATCH_NOTES, ACCESS_CAN_READ, user),
    },
    {
      label: 'Comments',
      content: <CommentList modelName={'SalesOrder'} modelId={orderId} />,
    },
    {
      label: 'History',
      content: <LogList modelName={'SalesOrder'} modelId={orderId} />,
    },
  ].filter(tab => tab.isHidden !== true);
  if (isShowPayments) {
    tabs.splice(1, 0, {
      label: 'Payments',
      content: <PaymentList modelName={'SalesOrder'} modelId={orderId} />,
    });
  }

  // console.log(components);
  return <CustomizeTab components={tabs} givenTabName={givenTabName} />;
};

export default SOTabs;
