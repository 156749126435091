import React from 'react';
import { useSelector } from 'react-redux';
import CommentList from '../../../shared/comments/CommentList';
import LogList from '../../../shared/log/LogList';
import CustomizeTab from '../../../shared/tab/CustomizeTab';
import PurchaseOrderList from '../../purchases/list/PurchaseOrderList';
import PurchasedProducts from './purchasedProducts/PurchasedProducts';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCTS, ACCESS_CODE_PURCHASE_ORDERS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';

const AOActivities = ({ AOId, AOItemIds }: { AOId: string; AOItemIds: Array<string> }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const getTabs = () =>
    [
      {
        label: 'Purchase Orders',
        content: (
          <PurchaseOrderList
            independent={false}
            extraAdvancedFilter={
              AOItemIds.length > 0
                ? `PurchaseOrderItem_id_orderId.AcquisitionOrderItem_acquisitionOrderItemId.id:${AOItemIds.join('|')}`
                : undefined
            }
          />
        ),
        isHidden: !AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_READ, user),
      },
      {
        label: 'Purchased Products',
        content: <PurchasedProducts AOItemIds={AOItemIds} />,
        isHidden: !AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user),
      },
      {
        label: 'Comments',
        content: <CommentList modelName={'AcquisitionOrder'} modelId={AOId} />,
      },
      {
        label: 'History',
        content: <LogList modelName={'AcquisitionOrder'} modelId={AOId} />,
      },
    ].filter(tab => tab.isHidden !== true);
  return <CustomizeTab components={getTabs()} />;
};

export default AOActivities;
