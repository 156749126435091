import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import { NA } from '../constants/ActionConstants';

const ModalContentWrapper = styled.div`
  min-height: 40px;
  .soft-delete-confirm-info {
    display: flex;
    margin: 4px 0px 8px;
    strong {
      padding: 0px 4px;
      background-color: lightgray;
      margin: 0 4px;
    }
  }
`;
const DeleteModalContent = ({
  answer,
  onTypeAnswer,
}: {
  answer: string;
  onTypeAnswer: (isRightAnswer: boolean) => void;
}) => {
  const [value, setValue] = useState('');

  const convertAnswer = answer || NA;
  useEffect(() => {
    return () => {
      onTypeAnswer(false);
    };
  }, [onTypeAnswer]);

  //  eslint-disable-next-line
  const onChange = (event: any) => {
    setValue(event.target.value);
    onTypeAnswer(event.target.value.trim() === answer.trim());
  };
  return (
    <ModalContentWrapper className={'modal-content-wrapper'}>
      <span className={'soft-delete-confirm-info'}>
        Before you delete it, please type
        <strong>
          <pre>{convertAnswer}</pre>
        </strong>
        to confirm
      </span>
      <Textfield value={value} onChange={onChange} isCompact testId={'textfield-enter-answer'} />
    </ModalContentWrapper>
  );
};

export default DeleteModalContent;
