import Button from '@atlaskit/button';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import iContactCompanyProduct from '../../../../../types/contactCompany/iContactCompanyProduct';
import MoreIconPopup from '../../../../popup/MoreIconPopup';
import CreateDNServiceProvider from './CreateDNServiceProvider';
import CreatePOServiceProvider from './CreatePOServiceProvider';
import { PopUpContentContainer } from '../../../../styles/styles';
import { CREATE_DN, CREATE_PO } from '../ServiceProvider.constants';
import { RootState } from '../../../../../redux/makeReduxStore';
import AccessService from '../../../../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_DISPATCH_NOTES,
  ACCESS_CODE_PURCHASE_ORDERS,
} from '../../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE } from '../../../../../types/settings/UserAccess/iRoleAccess';

type iState = {
  operationItem?: string;
  isLoading: boolean;
  isConfirming: boolean;
};
const initialState: iState = {
  isLoading: false,
  isConfirming: false,
};
const DotOperation = ({ jobId, ccp }: { jobId: string; ccp: iContactCompanyProduct }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canCreateDN = AccessService.hasAccess(ACCESS_CODE_DISPATCH_NOTES, ACCESS_CAN_CREATE, user);
  const canCreatePO = AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_CREATE, user);
  const [state, setState] = useState(initialState);
  const onCloseModal = () => setState({ ...state, operationItem: undefined });
  const onOpenModal = (item: string) => setState({ ...state, operationItem: item });

  const getOperationModal = () => {
    let Component;
    switch (state.operationItem) {
      case CREATE_PO:
        Component = CreatePOServiceProvider;
        break;
      case CREATE_DN:
        Component = CreateDNServiceProvider;
        break;
      default:
        break;
    }
    if (!Component) return null;
    return <Component jobId={jobId} ccp={ccp} onCancel={onCloseModal} />;
  };

  if (!canCreateDN && !canCreatePO) {
    return null;
  }

  return (
    <>
      <MoreIconPopup iconSize={'small'}>
        <PopUpContentContainer>
          {[...(canCreatePO ? [CREATE_PO] : []), ...(canCreateDN ? [CREATE_DN] : [])].map((item: string) => (
            <Button key={item} className={'popup-item'} onClick={() => onOpenModal(item)} testId={item}>
              {item}
            </Button>
          ))}
        </PopUpContentContainer>
      </MoreIconPopup>
      {getOperationModal()}
    </>
  );
};

export default DotOperation;
