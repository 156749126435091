import React from 'react';
import Spinner from '@atlaskit/spinner';
import iMachine from '../../../types/machine/iMachine';
import iJobStatus from '../../../types/job/iJobStatus';
import iJobCategory from '../../../types/job/iJobCategory';
import PartitionTitle from '../shared/PartitionTitle';
import ProductionMachine from './ProductionMachine';
import { DASHBOARD_PRODUCTION } from '../shared/Dashboard.constants';
import { STATUS_CATEGORY_FINISHED } from '../../../shared/job/constants';
import {
  PartitionBodyWrapper,
  ProductionMachineWrapper,
} from '../shared/Dashboard.style';
import { useDashboardJobContext } from '../jobContext/DashboardJobContext';

const Production = ({ date }: { date: string }) => {
  const { isLoading, machines, jobStatusCategories } = useDashboardJobContext();
  const getStatusFilterConfig = () => {
    return jobStatusCategories
      .filter(
        (category: iJobCategory) => category.name !== STATUS_CATEGORY_FINISHED,
      )
      .map((category: iJobCategory) =>
        category.jobStatuses.map((status: iJobStatus) => status.id).join('|'),
      )
      .join('|');
  };
  return (
    <>
      <PartitionTitle
        title={DASHBOARD_PRODUCTION}
        dateFrom={date}
        dateTo={date}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <PartitionBodyWrapper>
          <ProductionMachineWrapper>
            {machines.map((machine: iMachine) => (
              <ProductionMachine
                key={machine.id}
                machine={machine}
                jobStatusCategories={jobStatusCategories}
                statusFilterConfig={getStatusFilterConfig()}
              />
            ))}
          </ProductionMachineWrapper>
        </PartitionBodyWrapper>
      )}
    </>
  );
};

export default Production;
