import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonGroup } from '@atlaskit/button';
import { useSelector } from 'react-redux';
import {
  deactivateWarehouse,
  getWarehouseDetail,
  moveWarehouse,
  updateWarehouse,
} from '../../../services/WarehouseService';
import BasicDetail from './BasicDetail';
import DeleteByRetype from '../../../shared/deleteWithReType/DeleteByReType';
import BackendPage from '../../../layout/BackendPage';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import WarehouseTabs from './WarehouseTabs';
import GeneralPrintBtn from '../../../shared/buttons/GeneralPrintBtn';
import useDetailHook from '../../../shared/hooks/useDetailHook/useDetailHook';
import BreadcrumbsTwoLayers from '../../../shared/heading/BreadcrumbsTwoLayers';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import WarehouseMoreActions from '../list/popup/MoreActions';
import { WAREHOUSE_URL } from '../../../shared/UrlMap';
import { printLocationLabel } from '../../../services/PrintService';
import { handleNullException } from '../../../services/UtilsService';
import { FlexContainer, SpaceVerticalContainer } from '../../../shared/styles/styles';
import ItemLocationList from './ItemInLocation/ItemLocationList';
import MoveWarehouseModal from '../list/popup/MoveWarehouseModal';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/Toast';
import SpinWhenLoading from '../../../shared/spin/SpinWhenLoading';
import WarningModal from '../../../shared/modal/WarningModal';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_WAREHOUSES } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE, ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';

type iState = {
  isConfirming: boolean;
  isMoveModalOpen: boolean;
};
const initialState: iState = {
  isConfirming: false,
  isMoveModalOpen: false,
};
const WarehouseDetail = ({ id }: { id: string }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [supState, setSupState] = useState(initialState);
  const history = useHistory();
  const {
    state,
    edit,
    onOpenDeleteModal,
    onCloseModal,
    onDelete,
    onUpdateOneField,
  } = useDetailHook<iWarehouseLocation>({
    id,
    getFn: getWarehouseDetail,
    updateFn: updateWarehouse,
    deleteFn: deactivateWarehouse,
    deleteCallback: () => history.push('/b/warehouse'),
  });

  const allowDelete = (warehouse: iWarehouseLocation) => {
    if (warehouse.hasChild || (warehouse.productsCount && warehouse.productsCount > 0)) {
      return false;
    }
    return true;
  };
  const onOpenMoveModal = () => setSupState({ ...supState, isMoveModalOpen: true });
  const onCloseMoveModal = () => setSupState({ ...supState, isMoveModalOpen: false });

  // eslint-disable-next-line
  const onMoveWarehouse = async (data: any) => {
    //  both from and to must exist
    if (typeof state.data === 'undefined') {
      return;
    }
    setSupState({ ...supState, isConfirming: true });
    try {
      await moveWarehouse(data);
      setSupState({
        ...supState,
        isConfirming: false,
        isMoveModalOpen: false,
      });
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
      setSupState({
        ...supState,
        isConfirming: false,
      });
    }
  };
  const getPageHeader = () => (
    <>
      <BreadcrumbsTwoLayers name={'Warehouses'} url={WAREHOUSE_URL} subName={handleNullException(state.data, 'name')} />
      <FlexContainer className={'space-between'}>
        <h3>{handleNullException(state.data, 'name')}</h3>
        <ButtonGroup>
          <GeneralPrintBtn serviceFunc={() => printLocationLabel(id)}>Print Location Label</GeneralPrintBtn>
          {!AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_DELETE, user) &&
          !AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_UPDATE, user) ? null : (
            <MoreIconPopup>
              <WarehouseMoreActions
                onOpenDeleteModal={
                  AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_DELETE, user)
                    ? onOpenDeleteModal
                    : undefined
                }
                onOpenMoveModal={
                  AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_UPDATE, user) ? onOpenMoveModal : undefined
                }
              />
            </MoreIconPopup>
          )}
        </ButtonGroup>
      </FlexContainer>
    </>
  );
  if (!state.data) return null;
  if (state.isLoading) return <SpinWhenLoading />;
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <BasicDetail
        warehouse={state.data}
        onUpdate={onUpdateOneField}
        isDisabled={!AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_UPDATE, user)}
      />
      <SpaceVerticalContainer className={'one'} />
      {state.data.allowParts && <ItemLocationList warehouseLocationId={id} />}
      <SpaceVerticalContainer className={'two'} />
      <WarehouseTabs id={id} />
      {allowDelete(state.data) ? (
        <DeleteByRetype
          isOpen={edit.isDeleteModalOpen}
          onClose={onCloseModal}
          onConfirm={() => onDelete(id)}
          answer={handleNullException(state.data, 'name')}
          isConfirming={state.isLoading}
        />
      ) : (
        <WarningModal isOpen={edit.isDeleteModalOpen} onCancel={onCloseModal} />
      )}
      <MoveWarehouseModal
        isOpen={supState.isMoveModalOpen}
        isConfirming={supState.isConfirming}
        onClose={onCloseMoveModal}
        onConfirm={onMoveWarehouse}
        moveFrom={state.data}
      />
    </BackendPage>
  );
};

export default WarehouseDetail;
