import React, { useState } from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import Modal, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import { iBtnAppearance } from '../../../UITypes/types';

const BtnWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
const initialState = {
  showPopup: false,
  confirming: false,
};

const defaultHeading = 'Are you sure to start?';
const defaultPopupContent = <p>Please confirm you are about to start it.</p>;

const ElementWithPopup = ({
  onClick,
  popupHeading,
  popupContent,
  isConfirming,
  isDisabled = false,
  confirmBtnAppearance,
  confirmBtnName,
  bottom,
  bottomHandler,
  extraHandler,
  children,
}: {
  onClick?: () => void;
  popupHeading?: string;
  popupContent?: React.ReactNode;
  isConfirming?: boolean;
  isDisabled?: boolean;
  confirmBtnAppearance?: iBtnAppearance;
  confirmBtnName?: string;
  bottom?: React.ReactNode;
  bottomHandler?: () => void;
  extraHandler?: React.ReactNode;
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(initialState);

  const handleClicked = () => {
    setState({
      ...state,
      showPopup: true,
    });
  };

  const close = () => {
    setState({
      ...state,
      showPopup: false,
    });
  };
  const doBottomHandler = async () => {
    if (typeof bottomHandler === 'function') {
      await bottomHandler();
      close();
    }
  };
  const doConfirm = () => {
    setState({
      ...state,
      showPopup: false,
      confirming: true,
    });
    if (typeof onClick === 'function') {
      onClick();
    }
    setState({
      ...state,
      showPopup: false,
      confirming: false,
    });
  };
  const getModalContent = () => {
    if (state.showPopup !== true) return null;
    return (
      <Modal
        testId="start-modal"
        components={{
          Footer: () => {
            return (
              <ModalFooter>
                <BtnWrapper onClick={doBottomHandler}>{bottom}</BtnWrapper>
                <ButtonGroup>
                  <Button
                    onClick={() => doConfirm()}
                    appearance={confirmBtnAppearance || 'primary'}
                    isDisabled={isDisabled}
                    testId={'confirm-button'}
                  >
                    {confirmBtnName || 'Confirm'}
                  </Button>
                  {extraHandler}
                  <Button onClick={close} testId={'cancel-button'}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            );
          },
        }}
        /*
        actions={[
          {
            text: confirmBtnName || 'Confirm',
            onClick: doConfirm,
            isDisabled,
            appearance: confirmBtnAppearance || 'primary',
            testId: 'confirm-button',
          },
          { text: 'Cancel', onClick: close, testId: 'cancel-button' },
        ]}
        */
        onClose={close}
        heading={popupHeading || defaultHeading}
      >
        {popupContent || defaultPopupContent}
      </Modal>
    );
  };

  const getBtn = () => {
    if (isConfirming === undefined) {
      return state.confirming ? (
        <Spinner testId="start-spinner" />
      ) : (
        <BtnWrapper onClick={handleClicked}>{children}</BtnWrapper>
      );
    }
    return isConfirming ? (
      <Spinner testId="start-spinner" />
    ) : (
      <BtnWrapper onClick={handleClicked}>{children}</BtnWrapper>
    );
  };

  return (
    <>
      {getBtn()}
      <ModalTransition>{getModalContent()}</ModalTransition>
    </>
  );
};
export default ElementWithPopup;
