import React from 'react';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .scheduler-title {
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
  }
`;
const UnscheduledDateTitle = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <TitleWrapper>
      <div className={'scheduler-title'}>{title}</div>
      <div>{children}</div>
    </TitleWrapper>
  );
};

export default UnscheduledDateTitle;
