import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import SelectWithController from '../../../../shared/form/hookForms/SelectWithController';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import { apiErrorToast } from '../../../../shared/toast/Toast';
import iChannelScript from '../../../../types/integration/iChannelScript';
import CardBtn from './IntegrationCardBtn';
import ChannelCreationForm from './ChannelCreationForm';
import ChannelScriptHelper from '../../../../shared/integrations/ChannelScriptHelper';

const IntegrationAddMoreBtn = ({ channelScripts }: { channelScripts: iChannelScript[] }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreatingChannel, setIsCreatingChannel] = useState(false);
  const [selectedScript, setSelectedScript] = useState<iChannelScript | undefined>(undefined);
  const [isFormValid, setIsFormValid] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [oauthData, setOauthData] = useState<any>({});
  const channelScriptOptions = channelScripts.map(channelScript => {
    return {
      label: channelScript.name,
      value: channelScript.id,
    };
  });
  const { control } = useForm();

  const getModalBox = () => {
    if (!isModalOpen) {
      return null;
    }
    return (
      <CustomizeModal
        shouldScrollInViewport
        isOpen={isModalOpen}
        onConfirm={async () => {
          setIsCreatingChannel(true);
          try {
            if (!selectedScript) {
              throw new Error('Please select a script first.');
            }
            setIsCreatingChannel(false);
            await ChannelScriptHelper.handleChannelRedirect(selectedScript, oauthData);
          } catch (error) {
            apiErrorToast(error);
          }
        }}
        onCancel={() => setIsModalOpen(false)}
        isConfirming={isCreatingChannel}
        isDisabled={!selectedScript || !isFormValid}
        modalBody={
          <>
            <SelectWithController
              name={'scriptSelector'}
              label={'Channel selection'}
              onChange={(_, selected) => {
                const [targetScript] = channelScripts.filter(channelScript => channelScript.id === selected);
                if (targetScript) {
                  setSelectedScript(targetScript);
                  if (ChannelScriptHelper.requireChannelCreationForm(targetScript) !== true) {
                    setIsFormValid(true);
                  }
                }
              }}
              options={channelScriptOptions}
              control={control}
            />
            <ChannelCreationForm
              channelScript={selectedScript}
              onChange={(fieldName, newValue) =>
                setOauthData({
                  ...oauthData,
                  [fieldName]: newValue,
                })
              }
              setFormValid={setIsFormValid}
            />
          </>
        }
        modalHeading="Create a channel"
        confirmBtnName={'Save'}
      />
    );
  };
  return (
    <>
      <CardBtn
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <div className="addIntegrationBtn">+</div>
      </CardBtn>
      {getModalBox()}
    </>
  );
};

export default IntegrationAddMoreBtn;
