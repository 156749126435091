import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { ButtonGroup, LoadingButton } from '@atlaskit/button';
import { useForm } from 'react-hook-form';
import SectionMessage from '@atlaskit/section-message';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import BreadcrumbsTwoLayers from '../../shared/heading/BreadcrumbsTwoLayers';
import PopupContent from '../../shared/popup/GeneralPopupContent';
import MoreIconPopup from '../../shared/popup/MoreIconPopup';
import iDispatchNote from '../../types/dispatchNote/iDispatchNote';
import Activities from './detail/Activities';
import DetailEdit from './detail/DetailEdit';
import DeleteByRetype from '../../shared/deleteWithReType/DeleteByReType';
import iCourier from '../../types/courier/iCourier';
import iEntityCategory from '../../types/status/iEntityCategory';
import iSalesOrderType from '../../types/sales/iSalesOrderType';
import iSalesOrderDetail from '../../types/sales/iSalesOrder';
import { iRouteTypeId } from '../../shared/contactCompany/detail/types';
import StatusMenu from '../../shared/statusComponent/StatusMenu';
import useDetailHook from '../../shared/hooks/useDetailHook/useDetailHook';
import GeneralPrintBtn from '../../shared/buttons/GeneralPrintBtn';
import GeneralEmailBtn from '../../shared/buttons/GeneralEmailBtn';
import GeneralAttachments from '../../shared/generalAttachments/GeneralAttachments';
import DispatchNoteItems from './detail/dispatchNoteItems/DispatchNoteItems';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import SalesOrderList from '../sales/salesList/SalesOrderList';
import CouriersService from '../../services/Settings/CouriersService';
import { INVOICE } from '../sales/salesDetail/constants';
import { getEntityCategoryList } from '../../services/CategoryService';
import { printDispatchNote } from '../../services/PrintService';
import { getContactCompany } from '../../services/ContactCompanyService';
import { DISPATCH_NOTES_URL, SALES_URL } from '../../shared/UrlMap';
import { addToastForAPIResponse, apiErrorToast } from '../../shared/toast/Toast';
import { getDispatchNoteDetail, removeDispatchNote, updateDispatchNote } from '../../services/DispatchNoteService';
import { AttachmentsWrapper, FlexContainer, PageTitle, SpaceVerticalContainer } from '../../shared/styles/styles';
import { DETAIL_SUB_NAME_PREFIX, ITEM_COLUMNS, PAGE_NAME } from './shared/DispatchNote.constant';
import { DispatchNoteItemsWrapper, SelectWrapper } from './shared/DispatchNote.style';
import { createInvoiceFromDispatchNote, getSalesOrderList, getSalesOrderTypes } from '../../services/SalesOrderService';
import { classifyEntityCategoryByStatusId } from '../../services/UtilsService';
import { SERVICE_PROVIDER } from '../contactCompany/list/ContactCompany.constants';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_DISPATCH_NOTES, ACCESS_CODE_INVOICES } from '../../types/settings/UserAccess/iAccess';
import {
  ACCESS_CAN_CREATE,
  ACCESS_CAN_DELETE,
  ACCESS_CAN_READ,
  ACCESS_CAN_UPDATE,
} from '../../types/settings/UserAccess/iRoleAccess';

const emailSubjectTpl = 'Please find [REF_NUM] from [COMPANY_NAME]';
const emailBodyTpl = `Hi there,

Please click the link below to download the dispatch note.
[PDF_URL]

Feel free to contact us if you require anything further.

Kind Regards
[SENT_FROM]
[COMPANY_NAME]`;

type iSupportState = {
  couriers: Array<iCourier>;
  categories: Array<iEntityCategory>;
  salesOrderTypes: Array<iSalesOrderType>;
};
const initialSupportState: iSupportState = {
  couriers: [],
  categories: [],
  salesOrderTypes: [],
};
const DispatchNoteDetail = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_DISPATCH_NOTES, ACCESS_CAN_UPDATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_DISPATCH_NOTES, ACCESS_CAN_DELETE, user);
  const canCreateInvoice = AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_CREATE, user);
  const canReadInvoice = AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_READ, user);

  const { id } = useParams<iRouteTypeId>();
  const history = useHistory();
  const {
    state,
    edit,
    onOpenDeleteModal,
    onDelete,
    onUpdate,
    onUpdateEagerLoadObject,
    onCloseModal,
  } = useDetailHook<iDispatchNote>({
    id,
    getFn: getDispatchNoteDetail,
    updateFn: updateDispatchNote,
    deleteFn: removeDispatchNote,
    deleteCallback: () => history.push(DISPATCH_NOTES_URL),
  });
  const [supportState, setSupportState] = useState(initialSupportState);
  const { errors, handleSubmit } = useForm();
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] = useState<boolean>(false);
  const [localInvoices, setLocalInvoices] = useState<iSalesOrderDetail[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const breadCrumbsSubName = `${DETAIL_SUB_NAME_PREFIX}: ${state.data?.dispatchNoteNumber}`;

  useEffect(() => {
    let isCancelled = false;
    const fetchDetail = async () => {
      setIsLoading(true);
      try {
        const couriers: Array<iCourier> = await CouriersService.getCourierList();
        const categories: Array<iEntityCategory> = await getEntityCategoryList({
          filter: 'entityStatuses.entityStatusType.entityName:DispatchNote',
        });
        const salesOrderTypes: Array<iSalesOrderType> = await getSalesOrderTypes();

        if (isCancelled) return;
        setIsLoading(false);
        setSupportState({ couriers, categories, salesOrderTypes });
      } catch (error) {
        if (isCancelled) return;
        setIsLoading(false);
        apiErrorToast(error);
      }
    };
    fetchDetail();
    return () => {
      isCancelled = true;
    };
  }, []);

  const onCreateInvoice = async () => {
    try {
      setIsLoading(true);
      const data = await createInvoiceFromDispatchNote(id);
      addToastForAPIResponse('success');
      setIsLoading(false);
      setIsCreateInvoiceModalOpen(false);
      history.push(`${SALES_URL}/${data?.id}`);
    } catch (e) {
      apiErrorToast(e);
      setIsLoading(false);
    }
  };

  const getInvoiceTypeId = () => {
    return supportState.salesOrderTypes.find((type: iSalesOrderType) => type.name.toUpperCase() === INVOICE)?.id;
  };

  const onOpenCreateInvoiceModal = async () => {
    const { data } = await getSalesOrderList({
      filter: `customerId:${
        state.data?.customerId
      },typeId:${getInvoiceTypeId()},createdFromEntityName:DispatchNote,createdFromEntityId:${state.data?.id}`,
    });

    setLocalInvoices(data);
    setIsCreateInvoiceModalOpen(true);
  };

  const getMoreActionBtn = () => {
    if (!canDelete && !canCreateInvoice) {
      return null;
    }
    return (
      <MoreIconPopup>
        <PopupContent id={id} onOpenDeleteModal={canDelete ? onOpenDeleteModal : undefined}>
          {canCreateInvoice && (
            <LoadingButton className={'popup-item'} onClick={onOpenCreateInvoiceModal}>
              Create an Invoice
            </LoadingButton>
          )}
        </PopupContent>
      </MoreIconPopup>
    );
  };

  const getPageHeader = () => {
    return (
      <FlexContainer className={'space-between'}>
        <div>
          <BreadcrumbsTwoLayers name={PAGE_NAME} url={DISPATCH_NOTES_URL} subName={breadCrumbsSubName} />
          <FlexContainer>
            <PageTitle className={'small space-right'}>{breadCrumbsSubName}</PageTitle>
            <SelectWrapper>
              <StatusMenu
                defaultValue={state.data?.statusId}
                categories={supportState.categories}
                onSelect={(newValue: string) => onUpdate({ statusId: newValue })}
                isDisabled={!canUpdate}
              />
            </SelectWrapper>
          </FlexContainer>
        </div>
        <ButtonGroup>
          {state.data?.dispatchNoteNumber && (
            <GeneralEmailBtn
              orderNum={state.data?.dispatchNoteNumber}
              serviceFunc={() => printDispatchNote(id)}
              recipientServiceFunc={() => getContactCompany(state.data?.customerId as string)}
              emailSubjectTpl={emailSubjectTpl}
              emailBodyTpl={emailBodyTpl}
            >
              Email
            </GeneralEmailBtn>
          )}
          <GeneralPrintBtn serviceFunc={() => printDispatchNote(id)}>Print</GeneralPrintBtn>
          {getMoreActionBtn()}
        </ButtonGroup>
      </FlexContainer>
    );
  };
  const { isFinished } = classifyEntityCategoryByStatusId(supportState.categories, state.data?.statusId);
  if (state.isLoading) {
    return (
      <BackendPage>
        <Spinner />
      </BackendPage>
    );
  }
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <DetailEdit
        detail={state.data}
        onUpdateDetail={onUpdate}
        onUpdateLocal={onUpdateEagerLoadObject}
        couriers={supportState.couriers}
        isDisabled={isFinished || !canUpdate}
      />
      <DispatchNoteItemsWrapper>
        <DispatchNoteItems
          dispatchNoteId={id}
          customerId={state.data?.customerId}
          columns={ITEM_COLUMNS}
          DNShippingAddress={state.data?.shippingAddress}
          onUpdateDetail={onUpdate}
          onUpdateLocal={onUpdateEagerLoadObject}
          isFinished={isFinished}
          isServiceProvider={state.data?.customer.type.name.toUpperCase() === SERVICE_PROVIDER.toUpperCase()}
          customerShippingAddress={state.data?.customer.shippingAddress}
          canDelete={canUpdate}
          canCreate={canUpdate}
          canUpdate={canUpdate}
        />
      </DispatchNoteItemsWrapper>
      <SpaceVerticalContainer className={'one'} />
      <AttachmentsWrapper>
        <GeneralAttachments
          entityId={id}
          entityName={'DispatchNote'}
          thumbnailSize={'small'}
          canCreate={canUpdate}
          canDelete={canUpdate}
        />
      </AttachmentsWrapper>
      <Activities
        dispatchNoteId={id}
        extraTabs={
          canReadInvoice
            ? [
                {
                  label: 'Invoice',
                  content: supportState.salesOrderTypes.length !== 0 && (
                    <SalesOrderList
                      independent={false}
                      showAllTypes
                      showAllStatuses
                      customerId={state.data?.customerId}
                      extraFilter={`typeId:${getInvoiceTypeId()},createdFromEntityName:DispatchNote,createdFromEntityId:${
                        state.data?.id
                      }`}
                    />
                  ),
                },
              ]
            : undefined
        }
      />

      <DeleteByRetype
        isOpen={edit.isDeleteModalOpen}
        onClose={onCloseModal}
        onConfirm={() => onDelete(id)}
        answer={state.data?.dispatchNoteNumber}
        isConfirming={state.isConfirming}
      />
      <CustomizeModal
        isOpen={isCreateInvoiceModalOpen}
        onConfirm={handleSubmit(onCreateInvoice)}
        onCancel={() => setIsCreateInvoiceModalOpen(false)}
        isConfirming={isLoading}
        isDisabled={Object.keys(errors).length > 0}
        modalHeading={
          localInvoices.length >= 1
            ? 'Warning: Repeated Invoice(s)'
            : 'Are you sure to create an invoice from the dispatch note?'
        }
        modalBody={
          localInvoices.length >= 1 ? (
            <SectionMessage appearance={'warning'}>
              <p>
                There are already invoice(s) against this DispatchNote, do you want to continue creating invoice from
                this DispatchNote?
              </p>
            </SectionMessage>
          ) : (
            'If successful, you will be redirected to the new invoice page.'
          )
        }
        confirmBtnName={localInvoices.length >= 1 ? 'Continue' : 'Create'}
        confirmBtnAppearance={localInvoices.length >= 1 ? 'danger' : 'primary'}
      />
    </BackendPage>
  );
};

export default DispatchNoteDetail;
