import React from 'react';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import useAttachmentHook from '../hooks/useAttachmentHook/useAttachmentHook';
import { FlexContainer } from '../styles/styles';

const GeneralAttachments = ({
  entityId,
  entityName,
  thumbnailSize = 'normal',
  canCreate = true,
  canDelete = true,
}: {
  entityId: string;
  entityName: string;
  thumbnailSize?: string;
  canCreate?: boolean;
  canDelete?: boolean;
}) => {
  const { files, getUploader, getThumbnail, total } = useAttachmentHook({
    entityId,
    entityName,
    childrenForUploader: <CameraFilledIcon label={'camera'} testId={'upload-trigger'} />,
    thumbnailSize,
  });
  return (
    <>
      <FlexContainer>
        <span>Attachments({total > 0 ? `${files.length}/${total}` : 0})</span>
        {canCreate && getUploader()}
      </FlexContainer>
      {getThumbnail(!canDelete)}
    </>
  );
};

export default GeneralAttachments;
