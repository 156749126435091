import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import { iConfigColumn } from '../../../shared/UITypes/types';
import { getHeads, handleNullException } from '../../../services/UtilsService';

export const getRows = (
  data: Array<iItemLocationTransfer>,
  columns: Array<iConfigColumn>,
) => {
  if (typeof data === 'undefined') return [];
  const getLeftQty = (
    item: iItemLocationTransfer,
    sum: string,
    minus: string,
  ) => {
    const qty = handleNullException(item, sum);
    const movingQty = handleNullException(item, minus);
    if (!qty || !movingQty) return '';
    return (qty - movingQty).toString();
  };
  return data.map((item: iItemLocationTransfer) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, type, dataSource } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'calculate-minus':
          return {
            content: getLeftQty(item, key, dataSource[0]),
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};

const ReviewTable = ({
  data,
  columns,
}: {
  data: Array<iItemLocationTransfer>;
  columns: Array<iConfigColumn>;
}) => {
  return (
    <DynamicTable
      head={getHeads(columns, 'stockTransfer-review')}
      rows={getRows(data, columns)}
      testId={'stock-transfer-review-table'}
    />
  );
};

export default ReviewTable;
