import Checkbox from '@atlaskit/checkbox';
import Tooltip from '@atlaskit/tooltip';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../../../services/UtilsService';
import { FlexContainer } from '../../../../shared/styles/styles';
import { iLabelValuePair } from '../../../../shared/UITypes/types';
//  eslint-disable-next-line
import { useJobSchContext } from '../../JobScheduleContext';
import { iDisabledShift } from './Schedule.type';

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;
  .scheduler-title {
    font-size: 14px;
    font-weight: 700;
  }
`;
const ScheduleDateTitle = ({
  shiftsSort,
  date,
  children,
  isDisabled,
}: {
  shiftsSort: Array<iLabelValuePair>;
  date: string;
  children: React.ReactNode;
  isDisabled?: boolean;
}) => {
  const { disabledShifts, toggleShifts } = useJobSchContext();

  // all shifts under the date are disabled, then the date is disabled
  const separateShifts = () => {
    const disabled = shiftsSort.filter((s: iLabelValuePair) =>
      disabledShifts.find(
        (d: iDisabledShift) =>
          d.shiftId === s.value && moment(d.date, 'YYYY-MM-DD').toString() === moment(date, 'DD/MM/YYYY').toString(),
      ),
    );
    const notDisabled = shiftsSort.filter(
      (s: iLabelValuePair) =>
        !disabledShifts.find(
          (d: iDisabledShift) =>
            d.shiftId === s.value && moment(d.date, 'YYYY-MM-DD').toString() === moment(date, 'DD/MM/YYYY').toString(),
        ),
    );
    return { disabled, notDisabled };
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (toggleShifts) {
      toggleShifts(
        moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        e.currentTarget.checked ? separateShifts().notDisabled : separateShifts().disabled,
        e.currentTarget.checked,
      );
    }
  };
  return (
    <TitleWrapper>
      <FlexContainer>
        <Tooltip content={'Click to block/un-block'}>
          <Checkbox isChecked={separateShifts().notDisabled.length === 0} onChange={onChange} isDisabled={isDisabled} />
        </Tooltip>

        <div className={'scheduler-title'}>
          {formatDate(moment(date, 'DD/MM/YYYY').toString(), 'DD MMM YYYY [(]ddd[)]')}
        </div>
      </FlexContainer>
      <div>{children}</div>
    </TitleWrapper>
  );
};

export default ScheduleDateTitle;
