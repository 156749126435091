import React from 'react';
import { useSelector } from 'react-redux';
import iProduct from '../../../../types/product/iProduct';
import LogFooter from '../../../../shared/log/LogFooter';
import ProductToggles from './ProdToggles';
import RightPanelTop from './RightPanelTop';
import MyobEntitySelector from '../../../../shared/channel/myob/MyobEntitySelector';
import IntegrationLinkEntityWithAccountingChannels from '../../../../pages/settings/integrations/IntegrationLinkEntityWithAccountingChannels';
import { handleMoney, handleNullException } from '../../../../services/UtilsService';
import MyobConnectorService from '../../../../services/integration/connectors/MyobConnectorService';
import ModulingService from '../../../../services/ModulingService';
import { ModuleCode } from '../../../../types/iModuleCode';
import { RootState } from '../../../../redux/makeReduxStore';
import { iToggleAttribute } from '../../../../pages/NCR/detail/shared/NCRDetail.type';
import { ACCESS_CODE_PRODUCTS } from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../../../types/settings/UserAccess/iRoleAccess';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';

type iDetailRightPanel = {
  product?: iProduct;
  onUpdateProduct: (name: string, newValue: string | number | boolean) => void;
  canUpdate?: boolean;
};
const DetailRightPanel = ({ product, onUpdateProduct, canUpdate = true }: iDetailRightPanel) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const getEditAttributes = () => {
    if (!product) return [];
    const measurement = handleNullException(product, 'measurement.shortName');
    const withSlash = measurement ? `/${measurement}` : '';
    return [
      {
        label: 'Unit Price (Ex.)',
        value: handleMoney(product, 'unitPrice', 2, false),
        prefix: '$',
        postfix: withSlash,
        fieldName: 'unitPrice',
        disabled: !handleNullException(product, 'isForSell'),
      },
      {
        label: 'Stock on Hand',
        value: handleNullException(product, 'stockOnHand', true),
        postfix: measurement,
        fieldName: 'stockOnHand',
      },
      {
        label: 'Average Cost (Ex.)',
        value: handleNullException(product, 'averageCost', true),
        prefix: '$',
        postfix: withSlash,
        fieldName: 'totalValue',
        multiplier: Number(handleNullException(product, 'stockOnHand', true)),
      },
    ];
  };

  const getExtraToggleAttributesByModule = (checkedModule: ModuleCode, examiningObj: iToggleAttribute) => {
    if (!ModulingService.checkIsModuleEnabled({ checkedModule, companyModules: user?.companyModules || [] })) {
      return null;
    }
    return examiningObj;
  };

  const getToggleAttributes = (): iToggleAttribute[] => {
    if (product?.isTemplate === true) {
      return [
        {
          title: 'We use this product as a template',
          description: 'This flag determines if this is a Template or a Product',
          name: 'isTemplate',
          isChecked: product?.isTemplate,
        },
      ];
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return [
      {
        title: 'We use this product as a template',
        description: 'To set the product as a template or not',
        name: 'isTemplate',
        isChecked: product?.isTemplate,
      },
      {
        title: 'We sell this product',
        description: 'Change yours selling prices',
        name: 'isForSell',
        isChecked: product?.isForSell,
      },
      {
        title: 'We purchase this product',
        description: 'Click here to change purchase details, like preferred supplier',
        name: 'isForPurchase',
        isChecked: product?.isForPurchase,
      },
      {
        title: 'We make this product',
        description: 'You can change your BOM,Operations',
        name: 'isForMake',
        isChecked: product?.isForMake,
      },
      {
        title: 'We treat this product as waste',
        description: 'Will track your waste',
        name: 'isForWaste',
        isChecked: product?.isForWaste,
      },
      getExtraToggleAttributesByModule(ModuleCode.SERVICE_PROVIDER, {
        title: 'This product requires third party service',
        description: 'To manage third party service providers',
        name: 'requireThirdPartyService',
        isChecked: product?.requireThirdPartyService,
      }),
    ].filter(obj => obj !== null);
  };

  return (
    <>
      <RightPanelTop
        product={product}
        attributes={getEditAttributes()}
        onUpdateProduct={onUpdateProduct}
        isDisabled={!AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
      />
      <ProductToggles
        onToggle={onUpdateProduct}
        attributes={getToggleAttributes()}
        isDisabled={!AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
      />
      {product && (
        <IntegrationLinkEntityWithAccountingChannels
          getChannelEntitySelector={channelId => (
            <MyobEntitySelector
              key={channelId}
              label="MYOB Stock Item"
              channelId={channelId}
              entityId={product.id}
              entityName="Product"
              promiseFn={MyobConnectorService.getMyobProducts}
              optionLabel={['Name', 'Number']}
              isDisabled={!canUpdate}
            />
          )}
        />
      )}
      <LogFooter
        created={{
          operator: product?.createdBy?.username,
          time: product?.createdAt,
        }}
        updated={{
          operator: product?.updatedBy?.username,
          time: product?.updatedAt,
        }}
      />
    </>
  );
};

export default DetailRightPanel;
