import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import SectionMessage from '@atlaskit/section-message';
import styled from 'styled-components';
import InputWithController from '../../../form/hookForms/InputWithController';

const WarningMsgContainer = styled.div`
  margin-top: 10px;
`;

const QtyUpdateModal = ({
  control,
  watch,
  errors,
  onChange,
  qtyToMake,
  qtyTotal,
  unit,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  watch: (names?: string | string[]) => unknown;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  qtyToMake: number;
  qtyTotal: number;
  unit?: string;
}) => {
  const watchQtyToMake = watch('qtyToMake');

  return (
    <>
      <InputWithController
        name={'qtyTotal'}
        label={'Quantity total'}
        defaultValue={qtyTotal.toString()}
        control={control}
        onChange={onChange}
        errors={errors}
        isRequired
        testId={'qtyTotal'}
        suffix={unit}
      />
      <InputWithController
        name={'qtyToMake'}
        label={'Quantity remaining'}
        defaultValue={qtyToMake.toString()}
        control={control}
        onChange={onChange}
        errors={errors}
        isRequired
        testId={'qtyToMake'}
        suffix={unit}
      />
      {watchQtyToMake && watchQtyToMake !== qtyToMake.toString() && (
        <WarningMsgContainer>
          <SectionMessage appearance="warning">
            <p>
              The <span>remaining quantity</span> could be overwritten by the
              operators through the manufacturing process later.
            </p>
          </SectionMessage>
        </WarningMsgContainer>
      )}
    </>
  );
};

export default QtyUpdateModal;
