import styled from 'styled-components';
import { colors, fontSize } from '@atlaskit/theme';

export const JobDetailsWrapper = styled.div`
  .left-panel {
    margin-right: 2rem;
  }
  .large-text {
    font-size: ${fontSize() * 1.5}px;
  }
  .bold-text {
    font-weight: bold;
  }
  .job-attribute-wrapper {
    display: flex;
    .job-attribute-select-wrapper {
      min-width: 120px;
    }
  }
`;

export const AddIconWrapper = styled.div`
  background-color: ${colors.backgroundActive};
`;
