import React from 'react';
import iAddress from '../../../../../types/contactCompany/iAddress';
import { assembleAddress } from '../../../../../services/UtilsService';
import { SpaceVerticalContainer } from '../../../../../shared/styles/styles';

const DN_ADDRESS = 'Current shipping address:';
const SO_ADDRESS = "The product's shipping address:";
/**
 * if the conditions become more complex, try to use composition modal to refactor
 * like getDNPart(),getSOPart()
 */
const CompareAddressInfo = ({
  DNWording = DN_ADDRESS,
  SOWording = SO_ADDRESS,
  DNShippingAddress,
  SOShippingAddress,
}: {
  DNWording?: string;
  SOWording?: string;
  DNShippingAddress?: iAddress;
  SOShippingAddress?: iAddress;
}) => {
  return (
    <>
      <span>{DNWording}</span>
      <div>{assembleAddress(DNShippingAddress)}</div>
      <SpaceVerticalContainer className={'half'} />
      <span>{SOWording}</span>
      <div>{assembleAddress(SOShippingAddress)}</div>
    </>
  );
};

export default CompareAddressInfo;
