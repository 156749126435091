import { getSupplierListAsyncSearch } from '../../services/ContactCompanyService';
import { iConfigColumn } from '../../shared/UITypes/types';
import { PRODUCTS_URL } from '../../shared/UrlMap';
import { ModuleCode } from '../../types/iModuleCode';

export const CLONE_THIS_ORDER = 'Clone order';
export const DELETE_THIS_ORDER = 'Delete order';
export const COMPANY_TYPE_SUPPLIER = 'supplier';
export const COMPANY_TYPE_CUSTOMER = 'customer';
export const PO_RECEIVES_TABLE_NAME = 'PO_RECEIVES_TABLE';
export const PO_RECEIVES_TABLE_TEST_ID = 'PO_RECEIVES_TABLE';
export const PO_RECEIVES_DOCKET_NUMBER_ALIAS = 'Delivery Docket No. / Ref.';
export const PO_RECEIVED_PRODUCTS_TABLE_NAME = 'PO_RECEIVED_PRODUCTS_TABLE';
export const PO_RECEIVED_PRODUCTS_TABLE_TEST_ID = 'PO_RECEIVED_PRODUCTS_TABLE';
export const COLUMNS_AFTER = ['Operation'];
export const PO_TABLE_NAME = 'purchaseOrder-table';
export const PO_TABLE_TEST_ID = 'purchaseOrder-table';
export const PO_LIST_TITLE = 'Purchase Orders';
export const PO_STATUS_IN_PROGRESS = 'STATUS_CATEGORY_IN_PROGRESS';

export const PO_RECEIVING_STATUS_CLOSED_CATEGORY = 'STATUS_CATEGORY_FINISHED';
export const PO_RECEIVING_PRODUCT_ASYNC_SEARCH = 'po-receiving-product-async-search';

export const COLUMN_NAMES = ['PO No.', 'Status', 'Reference', 'Supplier', 'ETA', 'Total Amt.', 'Created', 'Updated'];

export const PURCHASE_ORDER_ITEM_TABLE_COLUMNS = [
  'Product Code / SKU',
  'Supplier P/Code',
  'AO',
  'Description',
  'Quantity',
  "Recv'd Qty",
  'Unit Price',
  'Disc %',
  'Tax',
  'Total (Inc GST)',
  ...COLUMNS_AFTER,
];

export const PO_RECEIVES_TABLE_FLEX_COLUMNS = ['Status', 'No of Line Items', 'Attachments', 'Created', 'Updated'];
export const PO_RECEIVES_TABLE_COLUMNS = [
  PO_RECEIVES_DOCKET_NUMBER_ALIAS,
  ...PO_RECEIVES_TABLE_FLEX_COLUMNS,
  ...COLUMNS_AFTER,
];

export const PO_RECEIVED_PRODUCTS_TABLE_COLUMNS = [
  {
    name: 'Product Code / SKU',
    key: 'product.productCode',
    dataSource: ['productId', 'product.name'],
    isCompulsory: true,
    isSortable: false,
    type: 'link-two-layer',
    urlPrefix: PRODUCTS_URL,
    isSelected: true,
    group: '',
  },
  {
    name: PO_RECEIVES_DOCKET_NUMBER_ALIAS,
    key: 'purchaseOrderReceiving.reference',
    dataSource: ['purchaseOrderReceiving.id'],
    type: 'link',
    urlPrefix: '/b/purchases/receiving',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Supplier Product Code',
    key: 'purchaseOrderItem.supplierProductCode',
    dataSource: ['purchaseOrderItem.id'],
    type: 'text',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: [
      'qty',
      'purchaseOrderItem.measurement.shortName',
      'purchaseOrderItem.unitConversion',
      'product.measurement.shortName',
    ],
    type: 'qty',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'To Location',
    key: 'toWarehouseLocation.name',
    dataSource: ['toWarehouseLocationId', 'toWarehouseLocation.locationCode'],
    type: 'link-two-layer',
    urlPrefix: '/b/warehouse',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Lot / Batch No.',
    key: 'reference',
    dataSource: ['reference'],
    type: 'text',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
];

export const PO_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Order No.',
    key: 'orderNumber',
    dataSource: ['id'],
    isCompulsory: true,
    isSortable: true,
    type: 'link',
    urlPrefix: '/b/purchases',
    isSelected: true,
    group: '',
  },
  {
    name: 'Status',
    key: 'status',
    dataSource: ['status'],
    type: 'status',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Reference',
    key: 'supplierRef',
    dataSource: ['supplierRef'],
    type: 'text',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Supplier',
    key: 'supplier.id',
    dataSource: ['supplier'],
    type: 'company-link',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Order Date',
    key: 'orderDate',
    dataSource: ['orderDate'],
    type: 'date',
    isSelected: true,
    group: '',
    isSortable: true,
  },
  {
    name: 'ETA',
    key: 'eta',
    dataSource: ['eta'],
    type: 'date',
    isSelected: true,
    group: '',
    isSortable: true,
  },
  {
    name: 'Total Amt.',
    key: 'totalAmt',
    dataSource: ['totalAmt'],
    type: 'money',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'AO(s)',
    key: 'aos',
    dataSource: [''],
    type: 'related-elements',
    isSelected: true,
    group: '',
    isSortable: false,
    moduleCode: ModuleCode.PURCHASE_AO,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];

export const PO_ADVANCED_SEARCH_CONFIGS = [
  {
    name: 'supplierId',
    label: 'Suppliers',
    type: 'multi',
    selected: [],
    placeholder: 'All suppliers...',
    promiseFn: getSupplierListAsyncSearch,
    optionLabel: 'name',
    sortBy: 'name',
    testId: 'po-advanced-search-suppliers',
    className: 'normal-width',
  },
  {
    name: 'orderDate>=',
    label: 'Order Date',
    type: 'date',
    selected: '',
    placeholder: 'From',
    testId: 'po-advanced-search-orderDate-from',
  },
  {
    name: 'orderDate<=',
    type: 'date',
    selected: '',
    placeholder: 'To',
    testId: 'po-advanced-search-orderDate-to',
    className: 'remove-1-gutter-before',
  },
];
