import iUser from '../../../types/user/iUser';
import { validateUserPassword } from '../../../services/Settings/UserService';
import { handleNullException } from '../../../services/UtilsService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { iHookFormField, iKeyValuePairs, iValidationPassword } from '../../../shared/UITypes/types';

export const generateInitialValues = (formFields: Array<iHookFormField>) => {
  return formFields.reduce((acc: iKeyValuePairs, cur: iHookFormField) => {
    // defaultValue decide by cur.type to avoid controlled/un-controlled input switch warning
    // const defaultValueByType = cur.type === 'text' ? '' : null;
    const defaultValueByType = '';
    return {
      ...acc,
      [cur.value]: typeof cur.initialValue === 'undefined' ? defaultValueByType : cur.initialValue,
    };
  }, {});
};

export const validatePassword = async (value: string) => {
  try {
    const result: iValidationPassword = await validateUserPassword(value);
    if (result.isValid) return true;
    return false;
  } catch (error) {
    apiErrorToast(error);
    return false;
  }
};

export const initialFieldValues = ({
  user,
  fields,
  initialRange,
}: {
  user?: iUser;
  fields: Array<iHookFormField>;
  initialRange: Array<string>;
}): Array<iHookFormField> =>
  fields.map((field: iHookFormField) =>
    initialRange.includes(field.value) ? { ...field, initialValue: handleNullException(user, field.value) } : field,
  );

export const addCompareRules = (
  fields: Array<iHookFormField>,
  //  eslint-disable-next-line
  getValues: (payload?: string | string[]) => any,
) =>
  fields.map((field: iHookFormField) => {
    let newValidate = { ...field.rules.validate };
    if (field.compareTarget) {
      newValidate = {
        ...newValidate,
        //  eslint-disable-next-line
        matchesPreviousPassword: (value: any) => {
          if (!field.compareTarget) return true;
          const target = getValues(field.compareTarget);
          return target === value || `${field.compareTarget} should match!`;
        },
      };
    }
    return { ...field, rules: { ...field.rules, validate: newValidate } };
  });

export default {
  addCompareRules,
  validatePassword,
  initialFieldValues,
  generateInitialValues,
};
