import React from 'react';
import DataInfo from '../../../shared/DataInfo';

const ResultsSummary = ({
  successLength,
  failedLength,
}: {
  successLength: number;
  failedLength: number;
}) => {
  return (
    <>
      {successLength > 0 && (
        <DataInfo
          desc={' record(s) successfully imported.'}
          lozenge={successLength}
          appearance={'success'}
        />
      )}
      {failedLength > 0 && (
        <DataInfo
          desc={' record(s) failed.'}
          lozenge={failedLength}
          appearance={'error'}
        />
      )}
    </>
  );
};

export default ResultsSummary;
