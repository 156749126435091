import React from 'react';
// import styled from 'styled-components';
import LocationIcon from '@atlaskit/icon/glyph/location';
import { colors } from '@atlaskit/theme';

// const DivWrapper = styled.div`
//   cursor: pointer;
// `;
const FacilityIcon = () => {
  return (
    <LocationIcon
      size={'small'}
      label={'location'}
      testId={'facility-location-icon'}
      primaryColor={`${colors.G500}`}
    />
  );
};

export default FacilityIcon;
