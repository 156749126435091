/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid, GridColumn } from '@atlaskit/page';

import { useSelector } from 'react-redux';
import {
  createSalesOrder,
  deactivateSale,
  getSalesOrderList,
  getSalesOrderTypes,
  updateSalesOrder,
} from '../../../services/SalesOrderService';
import SearchBar from '../../../shared/search/SearchBar';
import Advanced from '../../../shared/contactCompany/list/Advanced';
import SalesFilter from './filter/SalesFilter';
import { apiErrorToast } from '../../../shared/toast/Toast';
import iSalesOrderDetail from '../../../types/sales/iSalesOrder';
import { AdvancedSearchSectionWrapper, FlexContainer } from '../../../shared/styles/styles';
import { ALL, SO_ADVANCED_SEARCH_CONFIGS, SO_COLUMNS, SO_LIST_TITLE } from '../constants';
import useStatusSelector from '../../../shared/hooks/useStatusSelector';
import { getEntityCategoryList } from '../../../services/CategoryService';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import iSalesOrderType from '../../../types/sales/iSalesOrderType';
import useColumnHooksUpgrade from '../../../shared/hooks/useShowHideColumnHook/useColumnHook';
import PageTitleWithAddOne from '../../../shared/heading/PageTitleWithAddOne';
import { SALES_ORDER_DETAIL_URL } from '../../../shared/UrlMap';
import PaginationDetail from '../../../shared/pagination/paginationDetail/PaginationDetail';
import useAdvancedSearchHooks from '../../../shared/advancedSearch/AdvancedSearch';
import ExportReport from '../../../shared/exportReport/ExportReport';
import { getReportArrSegments } from './table/TableHelper';
import { getExportReportName } from '../../../services/UtilsService';
import { SALES_ORDER } from '../../../shared/topNavPopups/createPopup/Create.constant';
import { SO_TYPE_NAME_INVOICE, SO_TYPE_NAME_QUOTE, SO_TYPE_NAME_SALES_ORDER } from '../salesDetail/constants';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_CUSTOMERS,
  ACCESS_CODE_INVOICES,
  ACCESS_CODE_QUOTES,
  ACCESS_CODE_SALES_ORDERS,
} from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';
import RestrictedAccessDiv from '../../../shared/UserAccess/RestrictedAccessDiv';
import SalesListTable from './table/SalesListTable';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import SOModalBody from './SOModalBody';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import DeleteByReType from '../../../shared/deleteWithReType/DeleteByReType';
import BackendPage from '../../../layout/BackendPage';

const SalesOrderList = ({
  independent = true,
  extraFilter,
  customerId,
  showAllStatuses,
  showAllTypes,
  showTypeSwitches = false,
  startWithInvoiceList = false,
}: {
  showTypeSwitches?: boolean;
  independent?: boolean;
  showAllStatuses?: boolean;
  extraFilter?: string;
  showAllTypes?: boolean;
  customerId?: string;
  startWithInvoiceList?: boolean;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadQuotes = AccessService.hasAccess(ACCESS_CODE_QUOTES, ACCESS_CAN_READ, user);
  const canReadSOs = AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_READ, user);
  const canReadInvoices = AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_READ, user);

  const canCreateQuotes = AccessService.hasAccess(ACCESS_CODE_QUOTES, ACCESS_CAN_CREATE, user);
  const canCreateSOs = AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_CREATE, user);

  const [salesTypeList, setSalesTypeList] = useState<Array<iSalesOrderType>>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);

  const history = useHistory();
  const { control, setValue, errors, handleSubmit, watch } = useForm();

  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onSearch,
    onSetCurrentPage,
    onSetSort,
    onOpenDeleteModal,
    onFilter,
    onSubmit,
    onRefreshList,
  } = useListCrudHook<iSalesOrderDetail>({
    getFn: getSalesOrderList,
    deleteFn: deactivateSale,
    createFn: createSalesOrder,
    updateFn: updateSalesOrder,
    keywordColumns: 'orderNumber,invoiceNumber,customerRef',
    filter: extraFilter,
    sort: 'createdAt:DESC',
    paginationApplied: true,
    notRenderWithoutFilter: true,
    createCallBack: (id: string) => history.push(SALES_ORDER_DETAIL_URL.replace(':id', id)),
  });

  const { columns, getShowHideColumns } = useColumnHooksUpgrade('salesOrder', SO_COLUMNS);

  const { selected, getTypeSelectorComponent, categories, isStatusLoading } = useStatusSelector({
    type: 'SalesOrder',
    isMulti: true,
    getFn: getEntityCategoryList,
  });

  const { getAdvancedFilterStrArr, getSearchControllers } = useAdvancedSearchHooks(SO_ADVANCED_SEARCH_CONFIGS, false);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const soTypes = (await getSalesOrderTypes()).filter(type => {
          if (type.name.toUpperCase() === SO_TYPE_NAME_INVOICE && !canReadInvoices) {
            return false;
          }
          if (type.name.toUpperCase() === SO_TYPE_NAME_SALES_ORDER && !canReadSOs) {
            return false;
          }
          if (type.name.toUpperCase() === SO_TYPE_NAME_QUOTE && !canReadQuotes) {
            return false;
          }
          return true;
        });
        if (!mounted) return;
        setIsLoading(false);
        setSalesTypeList(soTypes);
        setSelectedTypes(
          soTypes.length === 1
            ? [soTypes[0].id]
            : soTypes
                .filter((type: iSalesOrderType) =>
                  startWithInvoiceList
                    ? type.name.toUpperCase() === SO_TYPE_NAME_INVOICE
                    : type.name.toUpperCase() !== SO_TYPE_NAME_INVOICE,
                )
                .map((type: iSalesOrderType) => type.id),
        );
      } catch (e) {
        if (!mounted) return;
        setIsLoading(false);
        apiErrorToast(e);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [startWithInvoiceList]);

  useEffect(() => {
    if (!isLoading && !isStatusLoading) {
      const filterType = showAllTypes === true || selectedTypes.length === 0 ? '' : `typeId:${selectedTypes.join('|')}`;
      const filterCustomer = `${customerId || ''}`.trim() === '' ? '' : `customerId:${customerId}`;

      const filterStatus =
        showAllStatuses === true || selected.includes(ALL) || selected.length === 0
          ? ''
          : `statusId:${selected.join('|')}`;

      const filterStr = [filterType, filterStatus, filterCustomer, extraFilter || ''].filter((elem: string) => elem !== '').join(',');

      const advancedSearchStr = getAdvancedFilterStrArr()
        .filter(strFilter => strFilter !== '')
        .join(',');

      if (advancedSearchStr) {
        onFilter(`${advancedSearchStr},${filterStr}`);
      } else {
        onFilter(filterStr);
      }
    }
  }, [
    JSON.stringify(selectedTypes),
    JSON.stringify(selected),
    JSON.stringify(getAdvancedFilterStrArr()),
    customerId,
    showAllStatuses,
  ]);

  // for report export
  const getAdvancedFilterStr = () => {
    const filterType = selectedTypes.length === 0 ? '' : `typeId:${selectedTypes.join('|')}`;

    const filterStatus = selected.includes(ALL) || selected.length === 0 ? '' : `statusId:${selected.join('|')}`;

    const filterStr = [filterType, filterStatus].filter((elem: string) => elem !== '').join(',');

    const advancedSearchStr = getAdvancedFilterStrArr()
      .filter(strFilter => strFilter !== '')
      .join(',');

    if (advancedSearchStr) {
      return `${advancedSearchStr},${filterStr}`;
    }
    return filterStr;
  };

  const onSelectType = (value: string) => {
    if (selectedTypes.includes(value)) {
      const newSelectedTypes = selectedTypes.filter(type => type !== value);
      setSelectedTypes(newSelectedTypes);
    } else {
      setSelectedTypes([...selectedTypes, value]);
    }
  };

  const getPageHeader = () => (
    <>
      <FlexContainer className={'space-between'}>
        <PageTitleWithAddOne
          title={SO_LIST_TITLE}
          onClickAddBtn={onOpenAddModal}
          className={'space-below'}
          isDisabled={!canCreateQuotes && !canCreateSOs}
        />

        <SalesFilter onSelectType={onSelectType} selectedTypes={selectedTypes} options={[...salesTypeList]} />
      </FlexContainer>

      <FlexContainer className={'space-between'}>
        {independent && getTypeSelectorComponent()}
        <FlexContainer>
          <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Order # or customer ref...'} />
          <Advanced onClick={() => setIsAdvancedSearch(prev => !prev)} />
        </FlexContainer>
      </FlexContainer>
      {isAdvancedSearch && (
        <AdvancedSearchSectionWrapper>
          <Grid layout={'fluid'} spacing={'cosy'}>
            {getSearchControllers()}

            <GridColumn medium={0.5}>
              <ExportReport
                className={'margin-above margin-below'}
                total={state.total}
                perPage={state.perPage}
                getFn={(currentPage: number) =>
                  getSalesOrderList({
                    currentPage: currentPage.toString(),
                    perPage: state.perPage?.toString() || '10',
                    advancedFilter: getAdvancedFilterStr() || '',
                    sort: 'createdAt:DESC',
                    like: edit.keyword
                      ? `orderNumber:${edit.keyword},customerRef:${edit.keyword},invoiceNumber:${edit.keyword}`
                      : '',
                  })
                }
                onFormatReport={orders => getReportArrSegments(orders, columns)}
                reportFileName={getExportReportName(SALES_ORDER)}
              />
            </GridColumn>
          </Grid>
        </AdvancedSearchSectionWrapper>
      )}
    </>
  );
  const getPageBody = () => (
    <RestrictedAccessDiv
      expectedUserAccessCodes={[ACCESS_CODE_INVOICES, ACCESS_CODE_SALES_ORDERS, ACCESS_CODE_QUOTES]}
      expectedCodeMatchedWithAny
    >
      <div data-testid={'salesOrders-wrapper'}>
        <FlexContainer className={'space-between space-below space-above-reverse'}>
          <FlexContainer>
            {!!state.total && !!state.from && !!state.to && independent ? (
              <PaginationDetail from={state.from} to={state.to} total={state.total} onRefreshResults={onRefreshList} />
            ) : (
              <div />
            )}
          </FlexContainer>
          <FlexContainer className={'align-items-stretch'}>
            {showTypeSwitches !== true ? null : (
              <SalesFilter onSelectType={onSelectType} selectedTypes={selectedTypes} options={[...salesTypeList]} />
            )}
            {getShowHideColumns()}
          </FlexContainer>
        </FlexContainer>
        <SalesListTable
          isLoading={state.isLoading}
          salesOrders={state.data}
          onOpenDeleteModal={onOpenDeleteModal}
          onSetSort={onSetSort}
          columns={columns.map(col => {
            if (col.key === 'customer.name' && !AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_READ, user)) {
              return {
                ...col,
                type: 'text',
              };
            }
            return col;
          })}
          categories={categories}
        />
        <CustomizeModal
          isOpen={edit.isModalOpen && !edit.delTargetId}
          onConfirm={handleSubmit(onSubmit)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
          modalBody={<SOModalBody control={control} onChange={setValue} errors={errors} watch={watch} isCustomer />}
          modalHeading={'Creating a Sales Order'}
          confirmBtnName={'Create'}
        />
        {!!state.total && !!state.perPage && !!state.currentPage && (
          <CustomizePagination
            range={Math.ceil(state.total / state.perPage)}
            currentPage={state.currentPage}
            onChange={onSetCurrentPage}
          />
        )}
        <DeleteByReType
          isOpen={!!edit.delTargetId && edit.isModalOpen}
          onClose={onCloseModal}
          onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
          answer={
            state.data.find((order: iSalesOrderDetail) => order.id === edit.target?.id)?.orderNumber ||
            'order number N/A'
          }
          isConfirming={state.isConfirming}
          isDeletable={state.data.find((order: iSalesOrderDetail) => order.id === edit.target?.id)?.jobs.length === 0}
          warningMsg={'Cannot delete an order with job(s).'}
        />
      </div>
    </RestrictedAccessDiv>
  );

  return independent ? <BackendPage pageHeader={getPageHeader()}>{getPageBody()}</BackendPage> : getPageBody();
};

export default SalesOrderList;
