import Toggle from '@atlaskit/toggle';
import Button from '@atlaskit/button';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Edge, Node, useEdges, useNodes } from 'react-flow-renderer';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { FlexContainer } from '../../../shared/styles/styles';
import EntityStatusEditModal from '../../settings/entityStatusTypes/detail/EntityStatusEditModal';
import {
  getAnyStatusNode,
  getStatusNode,
  NODE_ID_ANY_STATUS_PREFIX,
} from '../../../shared/workflow/diagram/WorkflowDiagramHelper';
import WorkflowTransitDisplay from './WorkflowTransitDisplay';
import { iWFDiagramState } from '../../../shared/workflow/diagram/WorkflowDiagramReducer';
import WorkflowDeleteStatusPopupBtn from './WorkflowDeleteStatusPopupBtn';

type iWorkflowEditStatusPanel = {
  editingNode: Node;
  state: iWFDiagramState;
};
const WorkflowEditStatusPanel = ({ editingNode, state }: iWorkflowEditStatusPanel) => {
  const { control, setValue, errors, handleSubmit } = useForm();
  const [categoryMap, setCategoryMap] = useState<{ [key: string]: iEntityCategory }>({});
  const [relatedEdges, setRelatedEdges] = useState<Edge[]>([]);
  const nodes = useNodes();
  const edges = useEdges();

  useEffect(() => {
    setCategoryMap(
      state.entityStatusCategories.reduce((map, category) => {
        return {
          ...map,
          [category.id]: category,
        };
      }, {}),
    );
  }, [state.entityStatusCategories]);

  useEffect(() => {
    setRelatedEdges(edges.filter(edge => edge.source === editingNode.id || edge.target === editingNode.id));
  }, [edges, editingNode.id]);

  const updateNode = (data: { [key: string]: string }) => {
    const entityStatus = editingNode.data.status || {};
    const newEntityStatusData = {
      ...entityStatus,
      ...data,
      entityStatusCategory: categoryMap[data.entityStatusCategoryId],
    };
    state.setNodes(
      nodes.map((node: Node) => {
        if (node.data.status?.id === editingNode.data.status.id) {
          return {
            ...getStatusNode(newEntityStatusData, editingNode.position),
            selected: false,
          };
        }
        return node;
      }),
    );
    return true;
  };

  const getTransitFromAnyStatus = () => {
    return relatedEdges.filter(edge => edge.source.toLowerCase().startsWith(NODE_ID_ANY_STATUS_PREFIX));
  };

  const setEditingEdge = (edge: Edge) => {
    return state.setEdges(
      edges.map(edg => {
        if (edg.id !== edge.id) {
          return edg;
        }
        return {
          ...edg,
          selected: true,
        };
      }),
    );
  };

  const getEdgesPanel = () => {
    if (relatedEdges.length <= 0) {
      return null;
    }
    return (
      <>
        {relatedEdges.map(relatedEdge => {
          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div onClick={() => setEditingEdge(relatedEdge)} key={relatedEdge.id} style={{ cursor: 'pointer' }}>
              <WorkflowTransitDisplay edge={relatedEdge} />
            </div>
          );
        })}
      </>
    );
  };

  const toggleFromAnyStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      const anyStatusTransIds = getTransitFromAnyStatus().map(edge => edge.id);
      const anyStatusIds = getTransitFromAnyStatus().map(edge => edge.source);
      state.setEdges(edges.filter(edge => anyStatusTransIds.indexOf(edge.id) < 0));
      state.setNodes(nodes.filter(node => anyStatusIds.indexOf(node.id) < 0));
      return;
    }
    const { node, edge } = getAnyStatusNode(editingNode);
    state.setEdges([...edges, edge]);
    state.setNodes([...nodes, node]);
  };

  return (
    <div>
      <h3>Status</h3>
      <div>
        <small>Statuses capture the stages of your working process.</small>
      </div>
      <EntityStatusEditModal
        target={editingNode.data.status}
        categoryList={state.entityStatusCategories}
        control={control}
        errors={errors}
        onChange={setValue}
        gridWidth={12}
      />

      <FlexContainer className={'space-above'}>
        <h5>Transitions</h5>
      </FlexContainer>
      <small>
        Transitions connect statuses. They represent actions people take to move job status through this workflow.
      </small>
      <FlexContainer>
        <Toggle isChecked={getTransitFromAnyStatus().length > 0} onChange={toggleFromAnyStatus} />
        <div>Allow any status to move to this one</div>
      </FlexContainer>

      {getEdgesPanel()}
      <FlexContainer className={'space-above space-between'}>
        <WorkflowDeleteStatusPopupBtn deletingNode={editingNode} state={state} />
        <Button appearance={'primary'} onClick={handleSubmit(updateNode)} isDisabled={Object.keys(errors).length > 0}>
          Update
        </Button>
      </FlexContainer>
    </div>
  );
};

export default WorkflowEditStatusPanel;
