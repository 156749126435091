import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { iRowContent } from '../../shared/DispatchNote.type';
import iDispatchNoteItem from '../../../../types/dispatchNote/iDispatchNoteItem';
import iAddress from '../../../../types/contactCompany/iAddress';
import QtyByCCP from './QtyByCCP';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import TransparentIconBtn from '../../../../shared/buttons/TransparentIconBtn';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import ConversionCalculateText from '../../../../shared/conversionCalculate/ConCalculateText';
import { BACK_END_URL, JOB_URL, PRODUCTS_URL, SALES_URL } from '../../../../shared/UrlMap';
import {
  // handleMoney,
  handleNullException,
  handleNullExceptionMultiFields,
  hasKey,
} from '../../../../services/UtilsService';
import WarningIconWithTooltip from '../createDNItem/shared/WarningIconWithTooltip';
import iUser from '../../../../types/user/iUser';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_JOBS,
  ACCESS_CODE_PRODUCTS,
  ACCESS_CODE_SALES_ORDERS,
} from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

export const isValidDispatchNoteItem = (item: iDispatchNoteItem) => {
  const FIXED_BEGIN = 'The info of this row might not be accurate due to';
  if (!item.salesOrderItemId || !item.salesOrderItem) {
    return {
      result: false,
      message: `${FIXED_BEGIN} sales order item being deleted`,
    };
  }
  if (item.productId && !item.product) {
    return {
      result: false,
      message: `${FIXED_BEGIN} due to product being deleted`,
    };
  }
  return { result: true };
};
export const getHeads = (columns: Array<string>, tableName: string) => {
  const cells = columns.map((column: string) => {
    switch (column) {
      case 'Operation':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'Warning':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      default:
        return {
          key: column,
          content: column,
          testId: `${tableName}-table-column-${column}`,
        };
    }
  });
  return { cells };
};

export const getRows = (
  data: Array<iDispatchNoteItem>,
  onOpenDeleteModal: (id: string) => void,
  //  eslint-disable-next-line
  onEdit: (targetId: string, data: any) => Promise<void>,
  columns: Array<string>,
  isDisabled: boolean,
  customerId?: string,
  user?: iUser,
) => {
  const mapList = () =>
    data.map((dispatchNoteItem: iDispatchNoteItem) => {
      const row: iRowContent = {
        id: dispatchNoteItem.id,
        'Product Code/SKU': (
          <ComposeSecondaryText secondaryText={dispatchNoteItem.product.name}>
            {AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user) ? (
              <LinkBtnUrl
                btnName={dispatchNoteItem.product.productCode}
                url={`${PRODUCTS_URL}/${dispatchNoteItem.productId}`}
              />
            ) : (
              dispatchNoteItem.product.productCode
            )}
          </ComposeSecondaryText>
        ),
        Description: handleNullException(dispatchNoteItem, 'product.name'),
        Quantity: (
          <>
            {dispatchNoteItem.salesOrderItem &&
            dispatchNoteItem.salesOrderItem.salesOrder?.customerId === customerId ? (
              <ConversionCalculateText
                name={`${dispatchNoteItem.id}-quantity`}
                defaultValue={dispatchNoteItem.quantity.toString()}
                onConfirm={(name: string, value: string | number) => onEdit(dispatchNoteItem.id, { quantity: value })}
                conversion={handleNullException(dispatchNoteItem, 'salesOrderItem.unitConversion') || 1}
                productUnit={handleNullException(dispatchNoteItem, 'product.measurement.shortName')}
                contactCompanyUnit={handleNullExceptionMultiFields(
                  dispatchNoteItem,
                  'product.measurement.shortName,salesOrderItem.measurement.shortName',
                )}
                key={dispatchNoteItem.id}
                isDisabled={isDisabled}
              />
            ) : (
              <QtyByCCP
                contactCompanyId={customerId}
                qty={dispatchNoteItem.quantity.toString()}
                productId={dispatchNoteItem.productId}
                productUnit={handleNullException(dispatchNoteItem, 'product.measurement.shortName')}
                onEdit={(value: string | number) => onEdit(dispatchNoteItem.id, { quantity: value })}
                isDisabled={isDisabled}
                key={dispatchNoteItem.id}
              />
            )}
          </>
        ),
        'Job No.': handleNullException(dispatchNoteItem, 'job.jobNumber') && (
          <ComposeSecondaryText
            secondaryText={`qtyTotal:${handleNullException(dispatchNoteItem, 'job.qtyTotal')} ${handleNullException(
              dispatchNoteItem,
              'product.measurement.shortName',
            )}`}
          >
            {AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_READ, user) ? (
              <LinkBtnUrl
                btnName={handleNullException(dispatchNoteItem, 'job.jobNumber')}
                url={`${JOB_URL}/${dispatchNoteItem.jobId}`}
              />
            ) : (
              handleNullException(dispatchNoteItem, 'job.jobNumber')
            )}
          </ComposeSecondaryText>
        ),

        'Sales Order No.': AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_READ, user) ? (
          <LinkBtnUrl
            btnName={handleNullException(dispatchNoteItem, 'salesOrderItem.salesOrder.orderNumber')}
            url={
              handleNullException(dispatchNoteItem, 'salesOrderItem.salesOrder.id')
                ? `${SALES_URL}/${handleNullException(dispatchNoteItem, 'salesOrderItem.salesOrder.id')}`
                : BACK_END_URL
            }
          />
        ) : (
          handleNullException(dispatchNoteItem, 'salesOrderItem.salesOrder.orderNumber')
        ),
        'Customer Order No.':
          handleNullException(dispatchNoteItem, 'job.customerOrderRef') ||
          handleNullException(dispatchNoteItem, 'salesOrderItem.salesOrder.customerRef'),
        Warning: !isValidDispatchNoteItem(dispatchNoteItem).result && (
          <WarningIconWithTooltip tooltipContent={isValidDispatchNoteItem(dispatchNoteItem).message} />
        ),
        Operation: !isDisabled && (
          <TransparentIconBtn
            icon={<TrashIcon label="delete" />}
            onClick={() => onOpenDeleteModal(dispatchNoteItem.id)}
          />
        ),
      };
      return row;
    });

  return mapList().map((row: iRowContent) => {
    const cells = columns.map((column: string) => ({
      content: hasKey(row, column) ? row[column] : '',
    }));
    return { cells, testId: 'admin-dispatch-note-item-row' };
  });
};

export const compareAddress = (a?: iAddress, b?: iAddress) => {
  if (!a && !b) return true;
  const fields = ['street', 'suburb', 'state', 'country', 'postcode'];
  return !fields.find(
    (field: string) =>
      (handleNullException(a, field) as string).trim().replace(/\s+/g, '').toUpperCase() !==
      (handleNullException(b, field) as string).trim().replace(/\s+/g, '').toUpperCase(),
  );
};
