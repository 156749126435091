import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import AOPOModalBody from '../../../asyncContactCompany/createModalBody/AOPOModalBody';

const POModalBody = ({
  control,
  onChange,
  errors,
  watch,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  //    eslint-disable-next-line
  watch: (names?: string | string[]) => any;
}) => {
  return (
    <AOPOModalBody
      control={control}
      onChange={onChange}
      errors={errors}
      watch={watch}
      isCustomer={false}
    />
  );
};

export default POModalBody;
