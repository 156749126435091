import styled from 'styled-components';
import { TABLE_NAME } from './Contact.constant';

export const TableWrapper = styled.div`
  th[data-testid='${TABLE_NAME}-column-delete--head--cell'] {
    width: 36px;
    min-width: 36px;
  }
  th[data-testid='${TABLE_NAME}-column-password--head--cell'] {
    width: 100px;
    min-width: 100px;
  }
  th[data-testid='${TABLE_NAME}-column-updatedAt--head--cell'],
  th[data-testid='${TABLE_NAME}-column-createdAt--head--cell'] {
    min-width: 160px;
    width: 160px;
  }
`;
