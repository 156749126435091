import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import iSalesOrderDetail from '../../../types/sales/iSalesOrder';
import { formatDate } from '../../../services/UtilsService';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';

const FooterContainer = styled.div`
  width: 30%;
  border-top: 1px solid ${colors.placeholderText};
  display: flex;
  flex-direction: column;
  .footer-item {
    margin-top: 5px;
  }
`;
const LogFooter = ({
  order,
}: {
  order?: iSalesOrderDetail | iPurchaseOrderDetail;
}) => {
  return (
    <FooterContainer>
      <small className={'footer-item'}>
        Created by {order?.createdBy?.firstName} @{' '}
        {formatDate(order?.createdAt)}
      </small>
      <small className={'footer-item'}>
        Updated by {order?.updatedBy?.firstName} @{' '}
        {formatDate(order?.updatedAt)}
      </small>
    </FooterContainer>
  );
};

export default LogFooter;
