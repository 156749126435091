import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Textfield from '@atlaskit/textfield';
import Avatar from '@atlaskit/avatar';
import { RootState } from '../../redux/makeReduxStore';
import TextArea from '../form/TextArea';
import { FlexContainer } from '../styles/styles';
import SaveCancelButtonGroup from './SaveCancelButtonGroup';

type iState = {
  value?: string;
};
const initialState: iState = {};
const CreateComment = ({
  onCreate,
  onOffReadView,
  onCancel,
  isConfirming,
  isReadView,
}: {
  onCreate: (value?: string) => void;
  onOffReadView: () => void;
  onCancel: () => void;
  isConfirming?: boolean;
  isReadView: boolean;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [state, setState] = useState(initialState);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const onConfirm = () => {
    onCreate(state.value);
  };
  const onReadViewFocused = () => {
    onOffReadView();
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
        textAreaRef.current.select();
      }
    }, 0);
  };
  const getReadView = () => (
    <Textfield
      placeholder={'Add a comment...'}
      onFocus={() => onReadViewFocused()}
      testId={'comment-read-view-textfield'}
    />
  );

  const getEditView = () => {
    return (
      <div
        className={'comment-create-view'}
        data-testid={'comment-create-view'}
      >
        <TextArea
          name={'comment-textarea'}
          onChange={(name: string, newValue: string) =>
            setState({ ...state, value: newValue })
          }
          minimumRows={4}
          debouncePeriod={100}
          textareaRef={textAreaRef}
        />
        <SaveCancelButtonGroup
          isConfirming={isConfirming}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </div>
    );
  };
  return (
    <FlexContainer>
      <div className={'item-avatar'}>
        <Avatar appearance="circle" src={user?.userAvatarUrl} size="medium" />
      </div>
      {!isReadView ? getEditView() : getReadView()}
    </FlexContainer>
  );
};

export default CreateComment;
