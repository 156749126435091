import { iKeyValuePairs } from '../shared/UITypes/types';
import AppService from './AppService';

export const getNCRs = (config?: iKeyValuePairs) => {
  return AppService.get('/issue', {
    include: 'createdBy,updatedBy,product,customer,job.machine,job.salesOrder,status',
    ...config,
  }).then(({ data }) => data);
};

export const getNCRDetail = (id: string, config?: iKeyValuePairs) => {
  return AppService.get(`/issue/${id}`, {
    include: 'product.measurement,customer,job,salesOrder',
    ...config,
  }).then(({ data }) => data);
};
export const deactivateNCR = (id: string) => {
  return AppService.delete(`/issue/${id}`).then(({ data }) => data);
};

export const createNCR = (postData: iKeyValuePairs) => {
  return AppService.post('/issue', postData).then(({ data }) => data);
};

export const updateNCR = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/issue/${id}`, updateContent).then(({ data }) => data);
};

export default {
  getNCRs,
  getNCRDetail,
  deactivateNCR,
  createNCR,
  updateNCR,
};
