import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getPurchaseOrderItems = (config?: iParams) => {
  return AppService.get('/purchaseOrderItem', {
    include:
      'product.measurement,purchaseOrder.createdBy,purchaseOrder.updatedBy,purchaseOrder.status,purchaseOrder.supplier,measurement,createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const updatePurchaseOrderItem = (
  id: string,
  updatedContent: iKeyValuePairs,
) => {
  return AppService.put(`/purchaseOrderItem/${id}`, updatedContent).then(
    ({ data }) => data,
  );
};

export const createPurchaseOrderItem = (
  orderId: string,
  productId: string,
  qty = 0,
) => {
  return AppService.post('/purchaseOrderItem', {
    orderId,
    productId,
    qty,
  }).then(({ data }) => data);
};

export const deletePurchaseOrderItem = (id: string) => {
  return AppService.delete(`/purchaseOrderItem/${id}`).then(({ data }) => data);
};

export const createPOItemConfig = (postData: iKeyValuePairs) => {
  return AppService.post('/purchaseOrderItem', postData).then(
    ({ data }) => data,
  );
};

export const getPOItemConfig = (config?: iParams) => {
  return AppService.get('/purchaseOrderItem', config).then(({ data }) => data);
};

export default {
  getPurchaseOrderItems,
  updatePurchaseOrderItem,
  createPurchaseOrderItem,
  deletePurchaseOrderItem,
  createPOItemConfig,
};
