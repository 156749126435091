import React from 'react';
import styled from 'styled-components';
import iProduct from '../../../../../types/product/iProduct';
import InlineEdit from '../../../../../shared/form/InlineEdit';
import InlineEditSelectFetchOptions from '../../../../../shared/form/InlineEditSelectFetchOptions';
import { getProductTypes } from '../../../../../services/product/ProductTypeService';
import { getUnits } from '../../../../../services/Settings/UnitMeasurementService';
import InlineRichTextEditor from '../../../../../shared/form/InlineRichTextEditor';
import ProductImageSlideShow from '../../../../../shared/productImage/ProductImageSlideShow';
import { FlexContainer } from '../../../../../shared/styles/styles';

const GeneInfoWrapper = styled.div`
  & > div {
    margin-bottom: 0.8rem;
  }

  .images {
    padding-right: 1rem;
    max-width: 35rem;
    min-width: 30rem;
  }

  .info-wrapper {
    width: auto;
    min-width: 20rem;
  }
`;

type iProdGeneralInfo = {
  product?: iProduct;
  isDisabled?: boolean;
  onUpdateProduct?: (name: string, newValue: string | number | boolean | null) => void;
};
const ProdGeneralInfo = ({ product, onUpdateProduct, isDisabled }: iProdGeneralInfo) => {
  if (typeof product === 'undefined' || typeof onUpdateProduct === 'undefined') return null;
  return (
    <GeneInfoWrapper data-testid={'product-detail-general-info'}>
      <FlexContainer className={'flex-start wrap-items'}>
        {product.isTemplate === true ? null : (
          <ProductImageSlideShow
            productId={product.id}
            className={'images'}
            canCreate={!isDisabled}
            canDelete={!isDisabled}
          />
        )}
        <div className={'info-wrapper'}>
          <InlineEdit
            name={'productCode'}
            defaultValue={product?.productCode}
            label={'Product Code/SKU'}
            onConfirm={onUpdateProduct}
            testId={'productCode'}
            isDisabled={isDisabled}
          />
          <InlineEdit
            name={'name'}
            defaultValue={product?.name}
            label={'Product Name'}
            onConfirm={onUpdateProduct}
            testId={'productName'}
            isDisabled={isDisabled}
          />
          <InlineEdit
            name={'shortDescription'}
            defaultValue={product?.shortDescription}
            label={'Short Description'}
            onConfirm={onUpdateProduct}
            testId={'shortDescription'}
            isDisabled={isDisabled}
          />
          <FlexContainer className={'flex-start even-split'}>
            <InlineEditSelectFetchOptions
              readViewFitContainerWidth
              label={'Product Type'}
              name={'productTypeId'}
              promiseFn={getProductTypes}
              defaultValue={product?.productTypeId || undefined}
              isClearable
              onSelect={onUpdateProduct}
              isDisabled={isDisabled}
            />
            <InlineEditSelectFetchOptions
              readViewFitContainerWidth
              label={'Unit of Measurement'}
              name={'measurementId'}
              promiseFn={getUnits}
              defaultValue={product?.measurementId}
              isClearable
              onSelect={onUpdateProduct}
              targetField={'shortName'}
              isDisabled={isDisabled}
            />
          </FlexContainer>
        </div>
        <div className={'full-width'}>
          <InlineRichTextEditor
            label={'Full Description'}
            testId={'fullDescription'}
            defaultValue={product?.fullDescription}
            onChangeConfirmed={newText => onUpdateProduct('fullDescription', newText)}
            disabled={isDisabled}
          />
        </div>
      </FlexContainer>
    </GeneInfoWrapper>
  );
};

export default ProdGeneralInfo;
