import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import iContact from '../../../../types/contactCompany/iContact';
import iContactCompany from '../../../../types/contactCompany/iContactCompany';
import iContactCompanyRole from '../../../../types/contactCompany/iContactCompanyRole';
import InlineEdit from '../../../form/InlineEdit';
import InlineEditSelect from '../../../form/InlineEditSelect';
import InlineEditLabel from '../../../form/inlineEdit/InlineEditLabel';
import TextArea from '../../../form/TextArea';
import AddressSelector from '../address/AddressSelector';
import MyobEntitySelector from '../../../channel/myob/MyobEntitySelector';
import IntegrationLinkEntityWithAccountingChannels from '../../../../pages/settings/integrations/IntegrationLinkEntityWithAccountingChannels';
import { handleNullException } from '../../../../services/UtilsService';
import { InlineEditContainer, SpaceVerticalContainer } from '../../../styles/styles';
import { getContactForRole, mapContactsForSelectOptions, onConfirm, onConfirmSelect } from '../Helper';
import MyobConnectorService from '../../../../services/integration/connectors/MyobConnectorService';

type iSupplierDetailEdit = {
  contactCompany?: iContactCompany;
  //  eslint-disable-next-line
  updateLocalContactCompany: (columnName: string, newValue: any) => void;
  contacts?: Array<iContact>;
  isDisabled?: boolean;
};
const SupplierDetailEdit = ({
  contactCompany,
  updateLocalContactCompany,
  contacts,
  isDisabled = false,
}: iSupplierDetailEdit) => {
  if (!contactCompany) return null;
  return (
    <Page>
      <Grid layout={'fluid'} spacing={'cosy'}>
        <GridColumn medium={8}>
          <Grid layout={'fluid'}>
            <GridColumn medium={4}>
              <InlineEdit
                name={'name'}
                label={'Name'}
                defaultValue={contactCompany?.name}
                onConfirm={(name: string, value: string) =>
                  onConfirm(name, value, updateLocalContactCompany, contactCompany)
                }
                isDisabled={isDisabled}
              />
            </GridColumn>
            <GridColumn medium={2}>
              <InlineEdit
                name={'email'}
                label={'Email'}
                defaultValue={contactCompany?.email}
                onConfirm={(name: string, value: string) =>
                  onConfirm(name, value, updateLocalContactCompany, contactCompany)
                }
                isDisabled={isDisabled}
              />
            </GridColumn>
            <GridColumn medium={2}>
              <InlineEdit
                name={'phone'}
                label={'Phone'}
                defaultValue={contactCompany?.phone}
                onConfirm={(name: string, value: string) =>
                  onConfirm(name, value, updateLocalContactCompany, contactCompany)
                }
                isDisabled={isDisabled}
              />
            </GridColumn>
          </Grid>
          <Grid layout={'fluid'}>
            {contactCompany?.type?.roles?.map((role: iContactCompanyRole) => (
              <GridColumn medium={2} key={role.id}>
                <InlineEditSelect
                  name={role.id}
                  label={`${role.name} Contact`}
                  defaultValue={getContactForRole(role.id, contactCompany, contacts)}
                  selectOptions={mapContactsForSelectOptions(contacts)}
                  onConfirm={(roleId: string, contactId: string | null) =>
                    onConfirmSelect(roleId, contactId, updateLocalContactCompany, contactCompany)
                  }
                  isDisabled={isDisabled}
                />
              </GridColumn>
            ))}
            <GridColumn medium={4}>
              <SpaceVerticalContainer className={'quarter'} />
              <InlineEditLabel label={'Billing Address'} />
              <AddressSelector
                selectedAddressId={contactCompany.billingAddressId}
                onChange={(value: null | string) =>
                  onConfirm('billingAddressId', value, updateLocalContactCompany, contactCompany)
                }
                isDisabled={isDisabled}
              />
            </GridColumn>
          </Grid>
          <Grid layout={'fluid'}>
            <GridColumn medium={6}>
              <InlineEditContainer>
                <TextArea
                  name={'description'}
                  label="Notes"
                  defaultValue={handleNullException(contactCompany, 'description')}
                  onChange={(name: string, value: string) =>
                    onConfirm(name, value, updateLocalContactCompany, contactCompany)
                  }
                  minimumRows={4}
                  debouncePeriod={500}
                  isDisabled={isDisabled}
                />
              </InlineEditContainer>
            </GridColumn>
            {contactCompany && (
              <GridColumn medium={6}>
                <InlineEditContainer>
                  <IntegrationLinkEntityWithAccountingChannels
                    getChannelEntitySelector={channelId => (
                      <MyobEntitySelector
                        key={channelId}
                        label="MYOB Contact"
                        channelId={channelId}
                        entityId={contactCompany.id}
                        entityName="ContactCompany"
                        promiseFn={MyobConnectorService.getMyobSuppliers}
                        optionLabel={['CompanyName', 'DisplayID']}
                        isDisabled={isDisabled}
                      />
                    )}
                  />
                </InlineEditContainer>
              </GridColumn>
            )}
          </Grid>
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default SupplierDetailEdit;
