import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import Page, { Grid, GridColumn } from '@atlaskit/page';

import iShift from '../../../types/iShift';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import TimePickerWithController from '../../../shared/form/hookForms/TimePickerWithController';

const ShiftEditModal = ({
  control,
  errors,
  onChange,
  targetShift,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  targetShift?: iShift;
}) => {
  // console.log(control.getValues());

  return (
    <Page>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'name'}
            label={'Name'}
            defaultValue={targetShift?.name}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'settings-shift-modal-name'}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={6}>
          <TimePickerWithController
            label={'Start Time'}
            name={'startTime'}
            control={control}
            defaultValue={targetShift?.startTime}
            errors={errors}
            isRequired
            onChange={onChange}
            testId={'settings-shift-modal-startTime'}
          />
        </GridColumn>
        <GridColumn medium={6}>
          <TimePickerWithController
            label={'End Time'}
            name={'endTime'}
            control={control}
            defaultValue={targetShift?.endTime}
            errors={errors}
            isRequired
            onChange={onChange}
            testId={'settings-shift-modal-endTime'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default ShiftEditModal;
