import React from 'react';
import { printNCR } from '../../../services/PrintService';
import {
  assembleAddress,
  formatDate,
  getCategoryCode,
  handleMoney,
  handleNullException,
} from '../../../services/UtilsService';
import GeneralPrintBtn from '../../../shared/buttons/GeneralPrintBtn';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import PopupContent from '../../../shared/popup/GeneralPopupContent';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import StatusLozenge from '../../../shared/statusComponent/StatusLozenge';
import { PrintBtnName } from '../../../shared/styles/styles';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import iIssue from '../../../types/NCR/iIssue';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { iConfigColumn } from '../../despatchNote/shared/DispatchNote.type';

export const getRows = (
  data: Array<iIssue>,
  onOpenDeleteModal: ((id: string) => void) | undefined,
  columns: Array<iConfigColumn>,
  categories: Array<iEntityCategory>,
) => {
  return data.map((issue: iIssue) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(issue, key),
            content: handleNullException(issue, key),
          };
        case 'date':
          return {
            key: handleNullException(issue, key),
            content: formatDate(handleNullException(issue, key), 'Do MMM YYYY'),
          };
        case 'money':
          return {
            key: handleNullException(issue, key),
            content: handleMoney(issue, key, 2),
          };
        case 'address':
          return {
            key: handleNullException(issue, key),
            content: assembleAddress(handleNullException(issue, key)),
          };
        case 'link-two-layer':
          return {
            key: handleNullException(issue, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(issue, dataSource[1])}>
                {column.urlPrefix ? (
                  <LinkBtnUrl
                    btnName={handleNullException(issue, key)}
                    url={`${column.urlPrefix}/${handleNullException(issue, dataSource[0])}`}
                  />
                ) : (
                  handleNullException(issue, key)
                )}
              </ComposeSecondaryText>
            ),
          };
        case 'link':
          return {
            key: handleNullException(issue, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(issue, key)}
                url={`${column.urlPrefix}/${handleNullException(issue, dataSource[0])}`}
              />
            ),
          };
        case 'status':
          return {
            key: handleNullException(issue, `${key}.id`),
            content: (
              <StatusLozenge
                status={handleNullException(issue, key)}
                categoryCode={getCategoryCode(handleNullException(issue, key), categories)}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(issue, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(issue, key)}
                operator={handleNullException(issue, dataSource[0])}
              />
            ),
          };
        case 'operation':
          return {
            content: (
              <MoreIconPopup>
                <PopupContent id={issue.id} onOpenDeleteModal={onOpenDeleteModal}>
                  <GeneralPrintBtn serviceFunc={() => printNCR(issue.id)}>
                    <PrintBtnName>Print</PrintBtnName>
                  </GeneralPrintBtn>
                </PopupContent>
              </MoreIconPopup>
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'admin-ncr-row' };
  });
};
