import AppService from '../AppService';
import { iKeyValuePairs, iParams } from '../../shared/UITypes/types';
import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../../shared/constants/AsyncConstants';

export const getProductServiceTypes = (config?: iKeyValuePairs) => {
  return AppService.get('/productServiceType', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const deactivateProductServiceType = (id: string) => {
  return AppService.delete(`/productServiceType/${id}`).then(({ data }) => data);
};

export const createProductServiceType = (postData: iKeyValuePairs) => {
  return AppService.post('/productServiceType', postData).then(({ data }) => data);
};

export const updateProductServiceType = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/productServiceType/${id}`, updateContent).then(({ data }) => data);
};

export const getPSTListAsyncSearch = (config?: iParams) => {
  return AppService.get('/productServiceType', {
    ...config,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};
export default {
  getProductServiceTypes,
  deactivateProductServiceType,
  createProductServiceType,
  updateProductServiceType,
  getPSTListAsyncSearch,
};
