import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iProductAttributeSet from '../../../types/product/iProductAttributeSet';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import { handleNullException } from '../../../services/UtilsService';

const CloneSetModalBody = ({
  control,
  errors,
  onChange,
  target,
}: {
  //    eslint-disable-next-line
    control: Control<Record<string, any>>;
  //    eslint-disable-next-line
    onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
    errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iProductAttributeSet;
}) => {
  return (
    <Page>
      <Grid layout={'fluid'}>
        <GridColumn medium={6}>
          <InputWithController
            label={'Name'}
            name={'name'}
            defaultValue={`copy of ${handleNullException(target, 'name')}`}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
          />
        </GridColumn>
        <GridColumn medium={6}>
          <InputWithController
            label={'Description'}
            name={'description'}
            defaultValue={handleNullException(target, 'description')}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired={false}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default CloneSetModalBody;
