import React from 'react';
import Spinner from '@atlaskit/spinner';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import SearchBar from '../../../../shared/search/SearchBar';
import TransparentIconBtn from '../../../../shared/buttons/TransparentIconBtn';
import iMachine from '../../../../types/machine/iMachine';
import { UnScheduledWrapper } from '../shared/Schedule.style';
import {
  UNSCHEDULED,
  UNSCHEDULED_SORT_ITEMS,
  UNSCHEDULED_SORT_KEY,
  UNSCHEDULED_TITLE,
} from '../shared/Schedule.constant';
import { iUnScheduledState, iUnSortState } from '../shared/Schedule.type';
//  eslint-disable-next-line
import UnScheduleMachineGroups from './UnScheduleMachineGroups';
import UnscheduledDateTitle from './UnscheduleDateTitle';
import UnScheduleSort from './UnScheduleSort';
import useLocalStorageHook from '../../../../shared/hooks/useLocalStorageHook/useLocalStorageHook';

const initialState: iUnSortState = {
  sortOrder: 'ASC',
  sortKey: UNSCHEDULED_SORT_ITEMS[0],
};

const UnScheduledJobs = ({
  unScheduledData,
  onCloseUnScheduled,
  onSearch,
  currentMachine,
  isDisabled,
}: {
  unScheduledData: iUnScheduledState;
  onCloseUnScheduled: () => void;
  onSearch: (keyword: string) => void;
  currentMachine?: iMachine;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useLocalStorageHook<iUnSortState>({
    key: UNSCHEDULED_SORT_KEY,
    defaultValue: initialState,
  });
  const onSetSort = (data: iUnSortState) => setState({ ...state, ...data });

  return (
    <UnScheduledWrapper>
      <div className={'space-bottom'}>
        <UnscheduledDateTitle title={UNSCHEDULED_TITLE}>
          <TransparentIconBtn icon={<CrossIcon label={'close'} size={'medium'} />} onClick={onCloseUnScheduled} />
        </UnscheduledDateTitle>
      </div>
      <div className={'space-bottom'}>
        <SearchBar onSearch={onSearch} placeholder={'search unscheduled jobs...'} keyword={unScheduledData.keyword} />
      </div>
      <div className={'space-bottom'}>
        <UnScheduleSort sortKey={state.sortKey} sortOrder={state.sortOrder} onSetSort={onSetSort} />
      </div>
      {unScheduledData.isLoading ? (
        <Spinner size={'large'} />
      ) : (
        currentMachine && (
          <UnScheduleMachineGroups
            date={UNSCHEDULED}
            jobs={unScheduledData.jobs}
            currentMachine={currentMachine}
            sortKey={state.sortKey}
            sortOrder={state.sortOrder}
            isDisabled={isDisabled}
          />
        )
      )}
    </UnScheduledWrapper>
  );
};

export default UnScheduledJobs;
