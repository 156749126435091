import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import InputWithController from '../../../../shared/form/hookForms/InputWithController';
import iStockTakeItem from '../../../../types/stockTake/iStockTakeItem';
import { handleNullException } from '../../../../services/UtilsService';

const FoundItemModalBody = ({
  control,
  errors,
  onChange,
  targetStockTakeItem,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  targetStockTakeItem?: iStockTakeItem;
}) => {
  return (
    <>
      <InputWithController
        name={'qty'}
        label={'Quantity'}
        defaultValue={handleNullException(targetStockTakeItem, 'qty')}
        control={control}
        onChange={onChange}
        errors={errors}
        isRequired
        testId={'quantity'}
      />
      <InputWithController
        name={'reference'}
        label={'Batch/Lot No.'}
        defaultValue={handleNullException(targetStockTakeItem, 'reference')}
        control={control}
        onChange={onChange}
        errors={errors}
        testId={'reference'}
      />
    </>
  );
};

export default FoundItemModalBody;
