import React from 'react';
import { useForm } from 'react-hook-form';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import PlusIconAfterTitle from '../../../components/settings/PlusIconAfterTitle';
import BackendPage from '../../../layout/BackendPage';
import {
  createShift,
  deleteShift,
  getShifts,
  updateShift,
} from '../../../services/ShiftService';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import iShift from '../../../types/iShift';
import ShiftEditModal from './ShiftEditModal';
import ShiftListTable from './ShiftListTable';
import { objectContainsInvalidValues } from '../../sales/utilities';
import Sidebar from '../sidebar/Sidebar';

const ShiftSetting = () => {
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onSubmit,
  } = useListCrudHook<iShift>({
    getFn: getShifts,
    createFn: createShift,
    updateFn: updateShift,
    deleteFn: deleteShift,
  });

  const { control, setValue, errors, handleSubmit } = useForm({});

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'Shifts'} />
      <PlusIconAfterTitle pageTitle={'Shifts'} onClick={onOpenAddModal} />
    </>
  );

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <ShiftListTable
        shifts={state.data}
        onDelete={onDelete}
        onEdit={onOpenEditModal}
        isLoading={state.isLoading}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={
          Object.keys(errors).length > 0 ||
          objectContainsInvalidValues(control.getValues())
        }
        modalBody={
          <ShiftEditModal
            control={control}
            errors={errors}
            onChange={setValue}
            targetShift={edit.target}
          />
        }
        modalHeading={edit.target ? edit.target.name : 'Create'}
        confirmBtnName={'Save'}
      />
    </BackendPage>
  );
};

export default ShiftSetting;
