import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../../shared/constants/AsyncConstants';
import { iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

export const getMachines = (config?: { [key: string]: string }) => {
  return AppService.get('/machine', {
    include: 'createdBy,updatedBy,warehouseLocation',
    ...config,
  }).then(({ data }) => data);
};

export const deactivateMachine = (id: string) => {
  return AppService.delete(`/machine/${id}`).then(({ data }) => data);
};

export const createMachine = (postData: { [key: string]: string }) => {
  return AppService.post('/machine', postData).then(({ data }) => data);
};

export const updateMachine = (id: string, updateContent: { [key: string]: string }) => {
  return AppService.put(`/machine/${id}`, updateContent).then(({ data }) => data);
};

export const getMachineListAsyncSearch = (config?: iParams) => {
  return AppService.get('/machine', {
    ...config,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

export default {
  getMachines,
  deactivateMachine,
  createMachine,
  updateMachine,
  getMachineListAsyncSearch,
};
