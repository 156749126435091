import Page, { Grid, GridColumn } from '@atlaskit/page';
import _ from 'lodash';
import React, { useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { searchFacility } from '../../../services/FacilityService';
import { getMachines } from '../../../services/Settings/MachineService';
import { checkDuplicateUser, validateUserPassword } from '../../../services/Settings/UserService';
import { handleNullException } from '../../../services/UtilsService';
import AsyncSearchWithController from '../../../shared/form/hookForms/AsyncSearchWithController';
import AvatarWithController from '../../../shared/form/hookForms/AvatarWithController';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import iUser from '../../../types/user/iUser';
import PasswordRuleSection from './PasswordRuleSection';
import RoleService from '../../../services/Settings/UserAccess/RoleService';
import { FlexContainer } from '../../../shared/styles/styles';
import InlineToggle from '../../../shared/form/InlineToggle';

type iUserModalBody = {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  setError: (name: string, error: FieldError) => void;
  watch: (names?: string | string[]) => unknown;
  targetUser?: iUser;
};
const UserModalBody = ({ control, errors, onChange, setError, targetUser, watch }: iUserModalBody) => {
  const [showingPasswordChangePanel, setShowingPasswordChangePanel] = useState(!targetUser);
  //  eslint-disable-next-line
  const confirmPassword = (newValue: any) => {
    const confirmPwdErrorMessage = 'must enter a same password';
    const password = watch('password');
    if (!newValue && !password) return true;
    if (newValue !== password) return confirmPwdErrorMessage;
  };

  const checkDuplicateUsername = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.target.value.trim()) return;
    const { isDuplicate } = await checkDuplicateUser(event.target.value.trim(), handleNullException(targetUser, 'id'));

    if (isDuplicate)
      setError('username', {
        type: 'manual',
        message: 'This username has been taken.',
      });
  };

  const checkPasswordComplexity = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.target.value.trim()) return;
    const { isValid } = await validateUserPassword(event.target.value.trim());

    if (!isValid)
      setError('password', {
        type: 'manual',
        message: 'Invalid password',
      });
  };

  const getPasswordChangePanel = () => {
    if (!showingPasswordChangePanel) {
      return null;
    }
    return (
      <div>
        <FlexContainer className={'with-gap even-split'}>
          <InputWithController
            name={'password'}
            label={'Password'}
            control={control}
            onChange={onChange}
            errors={errors}
            type={'password'}
            onPreCheck={checkPasswordComplexity}
            isRequired={!targetUser}
            testId={'add-user-modal-password-password'}
          />
          <InputWithController
            name={'confirm-password'}
            label={'Confirm password'}
            control={control}
            onChange={onChange}
            errors={errors}
            type={'password'}
            externalValidate={confirmPassword}
            isRequired={!targetUser}
            testId={'add-user-modal-password-confirm-password'}
          />
        </FlexContainer>
        <PasswordRuleSection />
      </div>
    );
  };

  const handleShowPasswordPanel = (showing: boolean) => {
    setShowingPasswordChangePanel(showing);
  };

  return (
    <Page>
      <Grid spacing="cosy">
        <GridColumn medium={4}>
          <AvatarWithController
            name={'userAvatarUrl'}
            defaultValue={targetUser?.userAvatarUrl}
            onChange={onChange}
            errors={errors}
            control={control}
          />
        </GridColumn>
        <GridColumn medium={8}>
          <FlexContainer className={'with-gap even-split'}>
            <InputWithController
              name={'firstName'}
              label={'First name'}
              defaultValue={targetUser?.firstName}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              testId={'add-user-modal-firstName'}
            />
            <InputWithController
              name={'lastName'}
              label={'Last name'}
              defaultValue={targetUser?.lastName}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              testId={'add-user-modal-lastName'}
            />
          </FlexContainer>
          <InputWithController
            name={'username'}
            label={'Username'}
            defaultValue={targetUser?.username}
            control={control}
            onChange={onChange}
            errors={errors}
            onPreCheck={checkDuplicateUsername}
            isRequired
            testId={'add-user-modal-username'}
          />
          <InputWithController
            name={'email'}
            label={'Email'}
            defaultValue={targetUser?.email}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            isEmail
            type={'email'}
            testId={'add-user-modal-email'}
          />
          <AsyncSearchWithController
            name={'roleId'}
            label={'Role'}
            testId={'roleId'}
            className={'role-selector'}
            defaultValue={targetUser?.role}
            //  eslint-disable-next-line
            onChange={(name: string, value: any, config?: Object) =>
              onChange(name, _.get(value, 'id'), config)
            }
            errors={errors}
            control={control}
            isRequired
            optionLabel={['name']}
            promiseFn={(keyword: string) =>
              RoleService.getRoleList({
                like: `name:${keyword}`,
              }).then(data => ({ data: data.data }))
            }
          />
          <AsyncSearchWithController
            name={'defaultOperatingMachineId'}
            label={'Default Machine'}
            defaultValue={targetUser?.defaultOperatingMachine}
            //  eslint-disable-next-line
            onChange={(name: string, value: any, config?: Object) =>
              onChange(name, _.get(value, 'id'), config)
            }
            errors={errors}
            control={control}
            optionLabel={['name']}
            promiseFn={(keyword: string) =>
              getMachines({
                like: `name:${keyword}`,
              }).then(data => ({ data }))
            }
          />
          <AsyncSearchWithController
            name={'defaultFacilityId'}
            label={'Default Facility'}
            defaultValue={targetUser?.defaultFacility}
            //  eslint-disable-next-line
            onChange={(name: string, value: any, config?: Object) =>
              onChange(name, _.get(value, 'id'), config)
            }
            errors={errors}
            control={control}
            optionLabel={['name']}
            promiseFn={(keyword: string) =>
              searchFacility({
                like: `name:${keyword}`,
              }).then(data => ({ data }))
            }
          />
          {targetUser ? (
            <FlexContainer className={'with-gap'}>
              <InlineToggle
                testId={'toggle-change-passwd'}
                isChecked={showingPasswordChangePanel}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleShowPasswordPanel(event.currentTarget.checked)
                }
              />
              <div>Change Password?</div>
            </FlexContainer>
          ) : null}
          {getPasswordChangePanel()}
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default UserModalBody;
