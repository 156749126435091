import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import { useHistory } from 'react-router-dom';
import IChannelScriptType from '../../../../types/integration/iChannelScriptType';
import iChannel from '../../../../types/integration/iChannel';
import { apiErrorToast } from '../../../../shared/toast/Toast';
import { FlexContainer, SpaceWrapper } from '../../../../shared/styles/styles';
import IntegrationCard from '../styled/IntegrationCard';
import IntegrationAddMoreBtn from './IntegrationAddMoreBtn';
import iChannelScript from '../../../../types/integration/iChannelScript';
import ChannelScriptService from '../../../../services/integration/ChannelScriptService';
import CardBtn from './IntegrationCardBtn';
import IntegrationChannelItem from './IntegrationChannelItem';

type iChannelsPerType = {
  channelScriptType: IChannelScriptType;
  channels: iChannel[];
};

const Wrapper = styled.div``;
const ChannelsPerType = ({ channelScriptType, channels }: iChannelsPerType) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [channelScripts, setChannelScripts] = useState<iChannelScript[]>([]);
  const [displayingChannels, setDisplayingChannels] = useState<iChannel[]>([]);

  useEffect(() => {
    let isCanceled = false;

    setIsLoading(true);
    ChannelScriptService.getChannelScripts({ filter: `typeId:${channelScriptType.id}` })
      .then(resp => {
        if (isCanceled) return;
        setChannelScripts(resp);
        const scriptIds = resp.map((script: iChannelScript) => script.id);
        setDisplayingChannels(channels.filter(channel => scriptIds.indexOf(channel.scriptId) >= 0));
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [channelScriptType.id, channels]);

  const getAddBtn = () => {
    if (!channelScriptType.allowMultiple && displayingChannels.length > 0) {
      return null;
    }
    return (
      <IntegrationCard>
        <IntegrationAddMoreBtn channelScripts={channelScripts} />
      </IntegrationCard>
    );
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <FlexContainer className={'space-below'}>
        {displayingChannels.map(channel => (
          <IntegrationCard key={channel.id}>
            <CardBtn
              onClick={() => {
                history.push(`channel/${channel.id}`);
              }}
            >
              <IntegrationChannelItem
                channel={channel}
                channelScript={channelScripts.filter(channelScript => channelScript.id === channel.scriptId)[0]}
              />
            </CardBtn>
          </IntegrationCard>
        ))}
        {getAddBtn()}
      </FlexContainer>
    );
  };

  return (
    <Wrapper>
      <FlexContainer className={'align-items-baseline with-gap'}>
        <b>{channelScriptType.name}</b>
        {channelScriptType.allowMultiple === true ? null : <small>Can ONLY have one</small>}
      </FlexContainer>
      <SpaceWrapper className={'space-bottom-sm'} />
      <div>{getContent()}</div>
    </Wrapper>
  );
};

export default ChannelsPerType;
