import { DatePicker } from '@atlaskit/datetime-picker';
import { Label } from '@atlaskit/field-base';
import { Grid, GridColumn } from '@atlaskit/page';
import React from 'react';
import styled from 'styled-components';
import { updatePurchaseOrder } from '../../../services/PurchaseService';
import { handleNullException } from '../../../services/UtilsService';
import CompanyLinkBtn from '../../../shared/buttons/CompanyLinkBtn';
import InlineEdit from '../../../shared/form/InlineEdit';
import TextArea from '../../../shared/form/TextArea';
import { LabelValue } from '../../../shared/styles/styles';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/Toast';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';
import MyobEntitySyncBtn from '../../../shared/channel/myob/MyobEntitySyncBtn';
import IntegrationLinkEntityWithAccountingChannels from '../../settings/integrations/IntegrationLinkEntityWithAccountingChannels';
import MyobConnectorService from '../../../services/integration/connectors/MyobConnectorService';

const InlineEditContainer = styled.div`
  max-width: 30rem;

  &:first-child {
    padding-top: 0.5rem;
  }
`;

const DatePickerContainer = styled.div`
  width: 90%;
  margin-top: -0.2rem;
`;

const PODetailEdit = ({
  id,
  order,
  onRefresh,
  isDisabled = false,
}: {
  id: string;
  order?: iPurchaseOrderDetail;
  onRefresh: () => void;
  isDisabled?: boolean;
}) => {
  const onConfirm = async (name: string, newValue: string) => {
    const nullable = newValue || null;
    try {
      await updatePurchaseOrder(name.split(':')[0], {
        [`${name.split(':')[1]}`]: nullable,
      });
      onRefresh();
      addToastForAPIResponse('success');
    } catch (e) {
      apiErrorToast('error');
    }
  };

  return (
    <>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="Supplier" />
          <LabelValue>
            <CompanyLinkBtn company={handleNullException(order, 'supplier')} />
          </LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Email" />
          <LabelValue>{handleNullException(order, 'supplier.email')}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Phone" />
          <LabelValue>{handleNullException(order, 'supplier.phone')}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEditContainer className="supplier-order-ref">
            <InlineEdit
              name={`${id}:supplierRef`}
              defaultValue={handleNullException(order, 'supplierRef')}
              label={'Reference'}
              onConfirm={onConfirm}
              isDisabled={isDisabled}
            />
          </InlineEditContainer>
        </GridColumn>
      </Grid>

      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <DatePickerContainer className="po-orderDate">
            <Label htmlFor="PO-orderDate-datepicker" label={'Order Date'} />
            <DatePicker
              id={'PO-orderDate-datepicker'}
              onChange={(value: string) => onConfirm(`${id}:orderDate`, value)}
              dateFormat="DD MMM YYYY"
              defaultValue={handleNullException(order, 'orderDate')}
              placeholder={' '}
              testId={'PO-orderDate-datepicker'}
              isDisabled={isDisabled}
            />
          </DatePickerContainer>
        </GridColumn>
        <GridColumn medium={3}>
          <DatePickerContainer className="supplier-order-eta">
            <Label htmlFor="PO-eta-datepicker" label={'ETA'} />
            <DatePicker
              id={'PO-eta-datepicker'}
              onChange={(value: string) => onConfirm(`${id}:eta`, value)}
              dateFormat="DD MMM YYYY"
              defaultValue={handleNullException(order, 'eta')}
              placeholder={' '}
              testId={'PO-eta-datepicker'}
              isDisabled={isDisabled}
            />
          </DatePickerContainer>
        </GridColumn>
        <GridColumn medium={handleNullException(order, 'acquisitionOrderId') ? 3 : 6}>
          <InlineEditContainer>
            <TextArea
              name={`${id}:notes`}
              label="Notes"
              defaultValue={`${order?.notes || ''}`}
              onChange={onConfirm}
              minimumRows={4}
              debouncePeriod={500}
              isDisabled={isDisabled}
            />
          </InlineEditContainer>
        </GridColumn>
      </Grid>

      {order && (
        <Grid layout={'fluid'} spacing={'compact'}>
          <GridColumn medium={6}>
            <IntegrationLinkEntityWithAccountingChannels
              getChannelEntitySelector={channelId => (
                <MyobEntitySyncBtn
                  key={channelId}
                  channelId={channelId}
                  entityId={order.id}
                  entityName="PurchaseOrder"
                  promiseFnForCreation={MyobConnectorService.createMyobPurchaseOrder}
                  promiseFnForUpdate={MyobConnectorService.updateMyobPurchaseOrder}
                  labelAttributePath="Number"
                />
              )}
            />
          </GridColumn>
        </Grid>
      )}
    </>
  );
};

export default PODetailEdit;
