import React from 'react';
import styled from 'styled-components';
import Badge from '@atlaskit/badge';

const BadgeWrapper = styled.div`
  display: flex;
`;
const TextBadge = ({
  badgeStr,
  badgeType = 'default',
}: {
  badgeStr: string;
  badgeType?:
    | 'added'
    | 'default'
    | 'important'
    | 'primary'
    | 'primaryInverted'
    | 'removed';
}) => {
  return (
    <BadgeWrapper className={'badge-wrapper'}>
      <Badge testId={'text-suffix-badge'} appearance={badgeType}>
        {badgeStr}
      </Badge>
    </BadgeWrapper>
  );
};

export default TextBadge;
