import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Label } from '@atlaskit/field-base';
import { DatePicker } from '@atlaskit/datetime-picker';
import { useSelector } from 'react-redux';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import InlineEdit from '../../../shared/form/InlineEdit';
import TextArea from '../../../shared/form/TextArea';
import iAcquisitionOrder from '../../../types/acquisitionOrder/iAcquisitionOrder';
import { handleNullException } from '../../../services/UtilsService';
import { SUPPLIER_URL } from '../../../shared/UrlMap';
import { iDetailUpdateFn } from '../../../shared/UITypes/types';
import { InlineEditContainer, LabelValue, SpaceVerticalContainer } from '../../../shared/styles/styles';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_SUPPLIERS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';

const AODetailEdit = ({
  detail,
  onUpdateDetail,
  isDisabled,
}: {
  detail?: iAcquisitionOrder;
  onUpdateDetail: iDetailUpdateFn;
  isDisabled?: boolean;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadSupplier = AccessService.hasAccess(ACCESS_CODE_SUPPLIERS, ACCESS_CAN_READ, user);

  if (typeof detail === 'undefined') return null;
  return (
    <Page>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="Supplier" />
          <LabelValue>
            {canReadSupplier ? (
              <LinkBtnUrl btnName={detail.supplier.name} url={`${SUPPLIER_URL}/${detail.supplier.id}`} />
            ) : (
              detail.supplier.name
            )}
          </LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Email" />
          <LabelValue>{detail.supplier.email}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Phone" />
          <LabelValue>{detail.supplier.phone}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEditContainer>
            <InlineEdit
              name={'supplierRef'}
              label={'Supplier reference number'}
              defaultValue={detail.supplierRef || undefined}
              onConfirm={(name: string, newValue: string) => onUpdateDetail({ [name]: newValue })}
              isDisabled={isDisabled}
            />
          </InlineEditContainer>
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label htmlFor="AO-order-date" label={'Order date'} />
          <DatePicker
            id={'AO-order-date'}
            onChange={(value: string) => onUpdateDetail({ orderDate: value || null })}
            dateFormat="Do MMM YYYY"
            defaultValue={handleNullException(detail, 'orderDate')}
            placeholder={'order date'}
            testId={'AO-order-date'}
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={3}>
          <Label htmlFor="AO-eta" label={'ETA'} />
          <DatePicker
            id={'AO-eta'}
            onChange={(value: string) => onUpdateDetail({ eta: value || null })}
            dateFormat="Do MMM YYYY"
            defaultValue={handleNullException(detail, 'eta')}
            placeholder={'eta'}
            testId={'AO-eta'}
            isDisabled={isDisabled}
          />
        </GridColumn>

        <GridColumn medium={4}>
          <SpaceVerticalContainer className={'half'} />
          <TextArea
            name={'notes'}
            label="Notes"
            defaultValue={detail.notes || undefined}
            onChange={(name: string, newValue: string) => onUpdateDetail({ [name]: newValue })}
            minimumRows={4}
            debouncePeriod={500}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default AODetailEdit;
