import React from 'react';
import styled from 'styled-components';
import EditorMoreIcon from '@atlaskit/icon/glyph/editor/more';
import DropdownMenu from '@atlaskit/dropdown-menu';

const HoverDotsWrapper = styled.div`
  display: none;
  position: absolute;
  left: 8px;
  top: 8px;
  background-color: rgb(244, 245, 247);
  border-radius: 3px;
`;

const HoverDots = ({ children }: { children: React.ReactNode }) => {
  return (
    <HoverDotsWrapper className={'hover-dots'}>
      <DropdownMenu
        triggerButtonProps={{
          iconBefore: <EditorMoreIcon label={'more'} testId={'more'} />,
        }}
        triggerType="button"
        position={'bottom left'}
      >
        {children}
      </DropdownMenu>
    </HoverDotsWrapper>
  );
};

export default HoverDots;
