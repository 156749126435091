import { iConfigColumn } from './DispatchNote.type';

export const ALL = 'All';
export const PAGE_NAME = 'Dispatch Notes';
export const PAGE_TYPE = 'dispatchNotes';
export const DISPATCH_NOTE_NUMBER_ALLIE = 'Disp. Note No.';
export const MUST_COLUMNS_BEFORE = [DISPATCH_NOTE_NUMBER_ALLIE];
export const MUST_COLUMNS_AFTER = ['Operation'];
export const DEFAULT_COLUMNS = [
  'Status',
  'Customer',
  'Total Amt.',
  'Sent Date',
  'Courier',
  'Reference',
  'Shipping Addr.',
  // 'Billing Addr.',
  'Created',
  'Updated',
];
export const COLUMNS = [...MUST_COLUMNS_BEFORE, ...DEFAULT_COLUMNS, ...MUST_COLUMNS_AFTER];

export const DETAIL_SUB_NAME_PREFIX = 'Dispatch Note';

export const EMPTY_OPTION = { label: 'remove', value: 'null' };

export const ITEM_COLUMNS = [
  'Product Code/SKU',
  'Description',
  'Quantity',
  'Job No.',
  'Sales Order No.',
  'Customer Order No.',
  // 'Unit Price',
  // 'Total',
  'Warning',
  ...MUST_COLUMNS_AFTER,
];
export const TABLE_NAME = 'dispatchNote-table';
export const TABLE_TEST_ID = 'dispatchNote-table';

export const DISPATCH_COLUMNS: Array<iConfigColumn> = [
  {
    name: '',
    key: 'rowCheckbox',
    dataSource: [],
    type: 'rowCheckbox',
    isCompulsory: true,
    isSelected: true,
    group: 'Dispatch Note',
  },
  {
    name: 'Disp. Note No.',
    key: 'dispatchNoteNumber',
    dataSource: ['id'],
    isCompulsory: true,
    isSortable: true,
    type: 'link',
    urlPrefix: '/b/dispatchNotes',
    isSelected: true,
    group: 'Dispatch Note',
  },
  {
    name: 'Status',
    key: 'status',
    dataSource: ['status'],
    type: 'status',
    isSelected: true,
    group: 'Dispatch Note',
    isSortable: false,
  },
  {
    name: 'Customer',
    key: 'customerId',
    dataSource: ['customer'],
    type: 'company-link',
    isSelected: true,
    group: 'Dispatch Note',
    isSortable: false,
  },
  {
    name: 'Total Amt.',
    key: 'totalAmt',
    dataSource: ['totalAmt'],
    type: 'money',
    isSelected: true,
    group: 'Dispatch Note',
    isSortable: false,
  },
  {
    name: 'Sent Date',
    key: 'dispatchDate',
    dataSource: ['dispatchDate'],
    type: 'date',
    isSelected: true,
    group: 'Dispatch Note',
    isSortable: false,
  },
  {
    name: 'Courier',
    key: 'courier.name',
    dataSource: ['courier.name'],
    type: 'text',
    isSelected: true,
    group: 'Dispatch Note',
    isSortable: false,
  },
  {
    name: 'Reference',
    key: 'courierReference',
    dataSource: ['courierReference'],
    type: 'text',
    isSelected: true,
    group: 'Dispatch Note',
    isSortable: false,
  },
  {
    name: 'Shipping Addr.',
    key: 'shippingAddress',
    dataSource: ['shippingAddress'],
    type: 'address',
    isSelected: true,
    group: 'Dispatch Note',
    isSortable: false,
  },
  {
    name: 'Invoices',
    key: 'invoices',
    dataSource: [],
    type: 'invoices',
    isSelected: false,
    group: 'Dispatch Note',
    isSortable: false,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: 'Dispatch Note',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    isSelected: true,
    group: 'Dispatch Note',
    isSortable: false,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: 'Dispatch Note',
  },
];

export const OVERWRITE_TEXT =
  "The job/so you added has a different address with the DD's shipping address, Would you like to overwrite the DD's shipping address?";
