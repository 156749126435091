import React from 'react';
import styled from 'styled-components';
import { Grid, GridColumn } from '@atlaskit/page';
import SectionMessage from '@atlaskit/section-message';
import { Label } from '@atlaskit/field-base';

import { LabelValue } from '../../../shared/styles/styles';
import { formatDate } from '../../../services/UtilsService';
import iPoReceiving from '../../../types/purchases/iPoReceiving';
import InlineEdit from '../../../shared/form/InlineEdit';
import { PO_RECEIVING_STATUS_CLOSED_CATEGORY } from '../constants';
import { updatePoReceiving } from '../../../services/PoReceivingService';
import {
  addToastForAPIResponse,
  apiErrorToast,
} from '../../../shared/toast/Toast';

const SectionMessageContainer = styled.div`
  margin: 2rem auto;
`;

const InlineEditContainer = styled.div`
  padding-top: 0.5rem;
`;

const PoDetail = ({
  poReceiving,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  id,
  onRefresh,
}: {
  poReceiving?: iPoReceiving;
  id: string;
  onRefresh: () => void;
}) => {
  const onUpdateDetail = async (name: string, value: string) => {
    try {
      await updatePoReceiving(name.split(':')[0], {
        [`${name.split(':')[1]}`]: value,
      });
      onRefresh();
      addToastForAPIResponse('success');
    } catch (e) {
      apiErrorToast('error');
    }
  };

  return (
    <>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="Supplier" />
          <LabelValue>{poReceiving?.purchaseOrder?.supplier?.name}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Email" />
          <LabelValue>{poReceiving?.purchaseOrder?.supplier?.email}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Phone" />
          <LabelValue>{poReceiving?.purchaseOrder?.supplier?.phone}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Reference" />
          <LabelValue>{poReceiving?.purchaseOrder?.supplierRef}</LabelValue>
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="Order Date" />
          <LabelValue>
            {formatDate(poReceiving?.purchaseOrder?.orderDate, 'DD MMM YYYY')}
          </LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="ETA" />
          <LabelValue>
            {formatDate(poReceiving?.purchaseOrder?.eta, 'DD MMM YYYY')}
          </LabelValue>
        </GridColumn>
        {poReceiving?.status?.entityStatusCategory?.code ===
        PO_RECEIVING_STATUS_CLOSED_CATEGORY ? (
          <GridColumn medium={3}>
            <Label label="Docket No. / Ref." />
            <LabelValue>{poReceiving.reference}</LabelValue>
          </GridColumn>
        ) : (
          <GridColumn medium={3}>
            <InlineEditContainer>
              <InlineEdit
                name={`${poReceiving?.id}:reference`}
                label={'Docket No. / Ref.'}
                defaultValue={poReceiving?.reference}
                onConfirm={onUpdateDetail}
              />
            </InlineEditContainer>
          </GridColumn>
        )}
      </Grid>

      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={5}>
          <SectionMessageContainer>
            {poReceiving?.purchaseOrder?.notes ? (
              <SectionMessage title="">
                <p>{poReceiving?.purchaseOrder?.notes}</p>
              </SectionMessage>
            ) : null}
          </SectionMessageContainer>
        </GridColumn>
      </Grid>
    </>
  );
};

export default PoDetail;
