import React, { useState, useEffect, useContext, createContext } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import useColumnHooks from '../../shared/hooks/useShowHideColumns';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import iJob from '../../types/job/iJob';
import iJobAttribute from '../../types/job/iJobAttribute';
import iMachine from '../../types/machine/iMachine';
import iJobCategory from '../../types/job/iJobCategory';
import iJobStatus from '../../types/job/iJobStatus';
import iProductAttribute from '../../types/product/iProductAttribute';
import DisableShiftModal from './schedule/shared/DisableShiftModal';
import { getJobCategories, updateJob } from '../../services/JobService';
import { STATUS_CATEGORY_FINISHED } from '../../shared/job/constants';
import { getJobAttributes } from '../../services/JobAttributeService';
import { getMachines } from '../../services/Settings/MachineService';
import { getProdAttributes } from '../../services/product/ProductAttributeService';
import { apiErrorToast } from '../../shared/toast/Toast';
import { iLabelValuePair } from '../../shared/UITypes/types';
import { mapJobAttributeForColumns, mapProdAttributeForColumns } from '../../services/UtilsService';
import { iDisabledShift, iScheduled, iShiftJob } from './schedule/shared/Schedule.type';
import {
  createDisabledShiftJobSchedule,
  deactivateDisabledShiftJobSchedule,
  getDisabledShiftList,
  getJobSchedule,
  getUnscheduledJobs,
} from '../../services/JobScheduleService';
import {
  COLUMN_NAME,
  DEFAULT_COLUMNS,
  jsContextInitialState,
  POSITION_FIXED_COLUMNS,
  supportInitialState,
  disabledInitialState,
  GAPS,
  scheduledInitialState,
  unScheduledInitialState,
} from './schedule/shared/Schedule.constant';
import { isUnscheduled } from './schedule/shared/Schedule.utils';

const JSContext = createContext(jsContextInitialState);

const JobScheduleContext = ({ children }: { children: React.ReactNode }) => {
  const [supportState, setSupportState] = useState(supportInitialState);
  const [disabledState, setDisabledState] = useState(disabledInitialState);
  const [schState, setSchState] = useState(scheduledInitialState);
  const [unSchState, setUnSchState] = useState(unScheduledInitialState);
  const [selectedMachine, setSelectedMachine] = useState<iMachine>();
  const [selectedPeriod, setSelectedPeriod] = useState<string>(GAPS[0].value);
  const [startDate, setStartDate] = useState<string>(moment().toString());

  const { control, setValue, errors, handleSubmit } = useForm();

  // fetch machines/jobAttributes/prodAttributes/job open statuses
  useEffect(() => {
    let isCancelled = false;
    const fetchSupportData = async () => {
      try {
        const machines: Array<iMachine> = await getMachines({
          sort: 'name:ASC',
        });
        const jobAttributes: Array<iJobAttribute> = await getJobAttributes();
        const prodAttributes: Array<iProductAttribute> = await getProdAttributes();
        const categories: Array<iJobCategory> = await getJobCategories();
        const statuses: Array<iJobStatus> = categories.reduce(
          (acc: Array<iJobStatus>, cur: iJobCategory) =>
            cur.name === STATUS_CATEGORY_FINISHED ? acc : [...acc, ...cur.jobStatuses],
          [],
        );
        if (isCancelled) return;
        setSupportState(prevState => ({
          ...prevState,
          machines,
          jobAttributes,
          prodAttributes,
          statuses,
        }));
        setSelectedMachine(() => (machines.length > 0 ? machines[0] : undefined));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
      }
    };
    fetchSupportData();
    return () => {
      isCancelled = true;
    };
  }, []);

  // fetch disabled shifts
  useEffect(
    () => {
      let isCancelled = false;
      const fetchDisableShifts = async () => {
        if (typeof selectedMachine === 'undefined' || !startDate) return;
        //  eslint-disable-next-line
        const endDate = moment(startDate).add(selectedPeriod, 'day').toString();
        try {
          const disabledShifts: Array<iDisabledShift> = await getDisabledShiftList({
            filter: `machineId:${selectedMachine.id},date>=${moment(startDate).format('YYYY-MM-DD')},date<=${moment(
              endDate,
            ).format('YYYY-MM-DD')}`,
          });
          if (isCancelled) return;
          setDisabledState(prevState => ({
            ...prevState,
            disabledShifts,
          }));
        } catch (error) {
          if (isCancelled) return;
          apiErrorToast(error);
        }
      };
      fetchDisableShifts();
      return () => {
        isCancelled = true;
      };
    },
    //  eslint-disable-next-line
    [JSON.stringify(selectedMachine), startDate, selectedPeriod],
  );

  // fetch scheduled jobs
  useEffect(
    () => {
      let isCancelled = false;
      const getConfig = () => {
        //  eslint-disable-next-line
        const endDate = moment(startDate).add(selectedPeriod, 'day').toString();
        const basicConfig = {
          startDate: moment(startDate).format('DD/MM/yyyy'),
          endDate: moment(endDate).format('DD/MM/yyyy'),
          filter: `machineId:${selectedMachine?.id},statusId:${supportState.statuses
            .map((status: iJobStatus) => status.id)
            .join('|')}`,
        };
        return schState.keyword ? { like: `jobNumber:${schState.keyword}`, ...basicConfig } : basicConfig;
      };
      const sortShifts = (data: Array<iScheduled>) => {
        if (data.length === 0) return [];
        return data[0].shifts.map((s: iShiftJob) => ({
          label: s.name,
          value: s.id,
        }));
      };
      const fetchScheduledData = async () => {
        if (!selectedMachine || supportState.statuses.length === 0 || !startDate) {
          return;
        }
        setSchState(prevState => ({ ...prevState, isLoading: true }));
        try {
          const data: Array<iScheduled> = await getJobSchedule(getConfig());
          const shiftsSort = sortShifts(data);
          if (isCancelled) return;
          setSchState(prevState => ({
            ...prevState,
            isLoading: false,
            data,
            shiftsSort,
          }));
        } catch (error) {
          if (isCancelled) return;
          setSchState(prevState => ({ ...prevState, isLoading: false }));
          apiErrorToast(error);
        }
      };
      fetchScheduledData();
      return () => {
        isCancelled = true;
      };
    },
    //  eslint-disable-next-line
    [
      startDate,
      //  eslint-disable-next-line
      selectedPeriod,
      //  eslint-disable-next-line
      JSON.stringify(selectedMachine),
      schState.keyword,
      //  eslint-disable-next-line
      JSON.stringify(supportState.statuses),
    ],
  );
  // fetch unscheduled jobs
  useEffect(
    () => {
      let isCancelled = false;
      const fetchUnScheduledData = async () => {
        if (supportState.statuses.length === 0) {
          return;
        }
        setUnSchState(prevState => ({ ...prevState, isLoading: true }));
        try {
          const unScheduled: Array<iJob> = await getUnscheduledJobs({
            filter: `scheduledAt:null,statusId:${supportState.statuses
              .map((status: iJobStatus) => status.id)
              .join('|')}`,
            like: `jobNumber:${unSchState.keyword || ''}`,
          });
          if (isCancelled) return;
          setUnSchState(prevState => ({
            ...prevState,
            isLoading: false,
            jobs: unScheduled,
          }));
        } catch (error) {
          if (isCancelled) return;
          setUnSchState(prevState => ({ ...prevState, isLoading: false }));
        }
      };
      fetchUnScheduledData();
      return () => {
        isCancelled = true;
      };
    },
    //  eslint-disable-next-line
    [
      unSchState.keyword,
      //  eslint-disable-next-line
      JSON.stringify(supportState.statuses),
    ],
  );

  const [selectedColumns, onToggleColumn] = useColumnHooks(COLUMN_NAME, {
    default: DEFAULT_COLUMNS,
    whole: [
      ...DEFAULT_COLUMNS,
      ...POSITION_FIXED_COLUMNS,
      ...mapJobAttributeForColumns(supportState.jobAttributes),
      ...mapProdAttributeForColumns(supportState.prodAttributes),
    ],
  });
  const columnGroups = [
    {
      label: 'Job',
      value: [...POSITION_FIXED_COLUMNS, ...mapJobAttributeForColumns(supportState.jobAttributes)],
    },
    {
      label: 'Product',
      value: mapProdAttributeForColumns(supportState.prodAttributes),
    },
  ];

  const getModalHeading = () => {
    const target = `${disabledState.targetDate}:${
      disabledState.targetShifts && disabledState.targetShifts.map((shift: iLabelValuePair) => shift.label)?.join(',')
    }`;
    return `Are you sure to ${disabledState.isDisable ? 'block' : 'un-block'} ${target}`;
  };
  const toggleShifts = (date: string, shiftIds: Array<iLabelValuePair>, isDisable: boolean) => {
    setDisabledState({
      ...disabledState,
      isModalOpen: true,
      isDisable,
      targetDate: date,
      targetShifts: shiftIds,
    });
  };

  //  eslint-disable-next-line
  const onDisableShifts = async (data: any) => {
    if (!disabledState.targetDate || !disabledState.targetShifts || typeof selectedMachine === 'undefined') {
      return;
    }
    setDisabledState({ ...disabledState, isConfirming: true });
    try {
      const effects = await Promise.all(
        disabledState.targetShifts.map(async (shift: iLabelValuePair) => {
          return createDisabledShiftJobSchedule({
            date: disabledState.targetDate,
            shiftId: shift.value,
            machineId: selectedMachine.id,
            ...data,
          });
        }),
      );

      setDisabledState({
        ...disabledState,
        disabledShifts: [...disabledState.disabledShifts, ...effects],
        isConfirming: false,
        isModalOpen: false,
        targetDate: undefined,
        targetShifts: undefined,
      });
    } catch (error) {
      setDisabledState({
        ...disabledState,
        isConfirming: false,
      });
      apiErrorToast(error);
    }
  };
  const onEnableShifts = async () => {
    if (!disabledState.targetDate || !disabledState.targetShifts || typeof selectedMachine === 'undefined') {
      return;
    }
    setDisabledState({ ...disabledState, isConfirming: true });
    try {
      const effects = await Promise.all(
        disabledState.targetShifts.map(async (shift: iLabelValuePair) => {
          const existed = disabledState.disabledShifts.find(
            (c: iDisabledShift) =>
              c.date === disabledState.targetDate && c.shiftId === shift.value && c.machineId === selectedMachine.id,
          );
          if (!existed) return undefined;
          return deactivateDisabledShiftJobSchedule(existed.id);
        }),
      );

      setDisabledState({
        ...disabledState,
        disabledShifts: disabledState.disabledShifts.filter(
          (d: iDisabledShift) =>
            //  eslint-disable-next-line
            !effects.find((effect: any) => effect.id === d.id),
        ),
        isConfirming: false,
        isModalOpen: false,
        targetDate: undefined,
        targetShifts: undefined,
      });
    } catch (error) {
      setDisabledState({
        ...disabledState,
        isConfirming: false,
      });
      apiErrorToast(error);
    }
  };
  const onMachineChange = (id: string) => {
    const target = supportState.machines.find((item: iMachine) => item.id === id);
    setSelectedMachine(target);
  };

  const onToggleLock = async (date: string, shift: iLabelValuePair, jobId: string, newValue: boolean) => {
    try {
      await updateJob(jobId, { moveDisabled: newValue });
      if (isUnscheduled(date)) {
        setUnSchState({
          ...unSchState,
          jobs: unSchState.jobs.map((item: iJob) => (item.id === jobId ? { ...item, moveDisabled: newValue } : item)),
        });
        return;
      }
      setSchState({
        ...schState,
        data: schState.data.map((item: iScheduled) => {
          if (item.date !== date) return item;
          const targetShiftJob = item.shifts.find((itemShift: iShiftJob) => itemShift.id === shift.value);
          if (typeof targetShiftJob === 'undefined') return item;
          const shiftJobs = targetShiftJob.jobs.map((job: iJob) =>
            job.id === jobId ? { ...job, moveDisabled: newValue } : job,
          );
          const newShiftJob = { ...targetShiftJob, jobs: shiftJobs };
          return {
            ...item,
            shifts: item.shifts.map((shiftJob: iShiftJob) => (shiftJob.id === newShiftJob.id ? newShiftJob : shiftJob)),
          };
        }),
      });
    } catch (error) {
      apiErrorToast(error);
    }
  };
  return (
    <JSContext.Provider
      value={{
        ...supportState,
        schState,
        unSchState,
        selectedMachine,
        selectedPeriod,
        periods: GAPS,
        columns: selectedColumns,
        disabledShifts: disabledState.disabledShifts,
        columnLists: columnGroups,
        startDate,
        onChangeStartDate: setStartDate,
        onToggleColumn,
        onTogglePeriod: setSelectedPeriod,
        toggleShifts,
        onMachineChange,
        setSchState,
        setUnSchState,
        onToggleLock,
      }}
    >
      {children}
      <CustomizeModal
        isOpen={disabledState.isModalOpen}
        onConfirm={disabledState.isDisable ? handleSubmit(onDisableShifts) : handleSubmit(onEnableShifts)}
        onCancel={() => setDisabledState({ ...disabledState, isModalOpen: false })}
        isConfirming={disabledState.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          disabledState.isDisable && <DisableShiftModal control={control} errors={errors} onChange={setValue} />
        }
        modalHeading={getModalHeading()}
        confirmBtnName={'Confirm'}
      />
    </JSContext.Provider>
  );
};
export const useJobSchContext = () => useContext(JSContext);
export default JobScheduleContext;
