import React from 'react';
import CompanyLogo from '../../../shared/company/CompanyLogo';
import AppPageCentered from '../shared/AppPageCentered';
import RedirectToActivation from '../shared/RedirectToActivation';
import ForgotPassword from './ForgotPassword';
import { ENTERPRISE_RETRIEVAL_HINT } from '../shared/Auth.constants';
import { isEnterpriseMode } from '../../../services/UtilsService';

const RetrievalEnterpriseCheck = () => {
  return (
    <AppPageCentered>
      <CompanyLogo />
      {isEnterpriseMode() !== true ? <ForgotPassword /> : <RedirectToActivation message={ENTERPRISE_RETRIEVAL_HINT} />}
    </AppPageCentered>
  );
};

export default RetrievalEnterpriseCheck;
