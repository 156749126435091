import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import TransparentIconBtn from '../../../shared/buttons/TransparentIconBtn';
import { handleNullException } from '../../../services/UtilsService';
import { FlexEndWrapper } from './StockTransfer.style';
import { iItemLocationTransfer } from './StockTransfer.type';
import { iConfigColumn } from '../../../shared/UITypes/types';

export const getRows = (
  data: Array<iItemLocationTransfer>,
  columns: Array<iConfigColumn>,
  onOpenDeleteModal: (id: string) => void,
) => {
  return data.map((item: iItemLocationTransfer) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'delete':
          return {
            content: (
              <FlexEndWrapper>
                <TransparentIconBtn
                  icon={<TrashIcon label={'remove'} />}
                  onClick={() =>
                    onOpenDeleteModal(handleNullException(item, key))
                  }
                />
              </FlexEndWrapper>
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};
