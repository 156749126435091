import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../../shared/constants/AsyncConstants';
import { iKeyValuePairs, iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

export const getUnits = (config?: iParams) => {
  return AppService.get('/unitOfMeasurement', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const createUnit = (postData: iKeyValuePairs) => {
  return AppService.post('/unitOfMeasurement', postData).then(({ data }) => data);
};

export const deleteUnit = (id: string) => {
  return AppService.delete(`/unitOfMeasurement/${id}`).then(({ data }) => data);
};

export const updateUnit = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/unitOfMeasurement/${id}`, updateContent).then(({ data }) => data);
};

export const getUnitsAsyncSearch = (config?: iParams) => {
  return AppService.get('/unitOfMeasurement', {
    ...config,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};
export default {
  getUnits,
  createUnit,
  deleteUnit,
  updateUnit,
  getUnitsAsyncSearch,
};
