import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getPurchaseOrderList = (config?: { [key: string]: string }) => {
  return AppService.get('/purchaseOrder', {
    include: 'supplier.type,createdBy,updatedBy,status',
    ...config,
  }).then(({ data }) => data);
};

export const getPurchaseOrders = (config?: { [key: string]: string }) => {
  return AppService.get('/purchaseOrder', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const getPurchaseOrderDetail = (id: string) => {
  return AppService.get(`/purchaseOrder/${id}`, {
    include:
      'supplier.type,taxBase,createdBy,updatedBy,purchaseOrderItems.measurement,purchaseOrderItems.product.measurement,purchaseOrderItems.unitPriceMeasurement,purchaseOrderItems.acquisitionOrderItem.acquisitionOrder,status.entityStatusCategory',
  }).then(({ data }) => data);
};

export const deactivatePurchaseOrder = (id: string) => {
  return AppService.delete(`/purchaseOrder/${id}`).then(({ data }) => data);
};

export const createPurchaseOrder = (postData: { [key: string]: string }) => {
  return AppService.post('/purchaseOrder', {
    ...postData,
  }).then(({ data }) => data);
};

export const updatePurchaseOrder = (
  orderId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateContent: { [key: string]: any },
) => {
  return AppService.put(`/purchaseOrder/${orderId}`, updateContent).then(
    ({ data }) => data,
  );
};

export const getPurchaseOrderListDashboard = (config?: iParams) => {
  return AppService.get('/purchaseOrder', {
    include:
      'purchaseOrderItems.measurement,purchaseOrderItems.product.measurement',
    ...config,
  }).then(({ data }) => data);
};

// export const getPurchaseOrderListDashboard=(config)
export default {
  getPurchaseOrderList,
  getPurchaseOrders,
  getPurchaseOrderDetail,
  deactivatePurchaseOrder,
  createPurchaseOrder,
  updatePurchaseOrder,
  getPurchaseOrderListDashboard,
};
