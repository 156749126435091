import React from 'react';
import { Content, LeftSidebar, Main } from '@atlaskit/page-layout';
import iStockTake from '../../../../types/stockTake/iStockTake';
import FoundItemsInBin from '../foundItems/FoundItemsInBin';
import SupposedItemsInBin from '../supposedItems/SupposedItemsInBin';
import AttachmentsAndActivity from '../AttachmentsAndActivity';
import { FoundWrapper } from '../../shared/StockTake.style';
import { SpaceVerticalContainer } from '../../../../shared/styles/styles';
import { handleNullException } from '../../../../services/UtilsService';
import { iCategoryClassify } from '../../shared/StockTake.type';

const NotFinished = ({
  stockTakeDetail,
  categoryClassify,
  children,
  canUpdate = true,
}: {
  stockTakeDetail: iStockTake;
  categoryClassify: iCategoryClassify;
  children: React.ReactNode;
  canUpdate?: boolean;
}) => {
  return (
    <Content>
      <div css={{ overflowY: 'hidden' }}>
        <LeftSidebar isFixed={false} width={550} id={'page-left-table'} shouldPersistWidth>
          <SupposedItemsInBin
            items={handleNullException(stockTakeDetail, 'warehouseLocation.warehouseItemLocations')}
            locationCode={handleNullException(stockTakeDetail, 'warehouseLocation.locationCode')}
          />
        </LeftSidebar>
      </div>
      <Main>
        <FoundWrapper>
          <FoundItemsInBin
            stockTakeDetail={stockTakeDetail}
            locationCode={handleNullException(stockTakeDetail, 'warehouseLocation.locationCode')}
            categoryClassify={categoryClassify}
            canCreate={canUpdate}
            canDelete={canUpdate}
            canUpdate={canUpdate}
          >
            {children}
          </FoundItemsInBin>
          <SpaceVerticalContainer />
          <AttachmentsAndActivity
            entityId={handleNullException(stockTakeDetail, 'id')}
            entityName={'StockTake'}
            canCreate={canUpdate}
            canDelete={canUpdate}
          />
        </FoundWrapper>
      </Main>
    </Content>
  );
};

export default NotFinished;
