import AppService from '../../AppService';
import { iPaginatedResult } from '../../../types/iPagination';
import iRole from '../../../types/settings/UserAccess/iRole';
import { iParams } from '../../../shared/UITypes/types';

const endPoint = '/role';

const getRoleList = (config = {}): Promise<iPaginatedResult<iRole>> => {
  return AppService.get(endPoint, config).then(({ data }) => data);
};

const getRole = (id: string, config = {}): Promise<iRole> => {
  return AppService.get(`${endPoint}/${id}`, config).then(({ data }) => data);
};

const createRole = (params: iParams): Promise<iRole> => {
  return AppService.post(endPoint, params).then(({ data }) => data);
};

const updateRole = (id: string, params: iParams): Promise<iRole> => {
  return AppService.put(`${endPoint}/${id}`, params).then(({ data }) => data);
};

const deleteRole = (id: string, config = {}): Promise<iRole> => {
  return AppService.delete(`${endPoint}/${id}`, config).then(({ data }) => data);
};

export default {
  getRoleList,
  getRole,
  createRole,
  updateRole,
  deleteRole,
};
