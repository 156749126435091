import React, { useEffect, useState } from 'react';
import Select, { ValueType } from '@atlaskit/select';
import { iLabelValuePair } from '../UITypes/types';

type iSelectSingle = {
  name: string;
  defaultValue?: iLabelValuePair;
  label?: string;
  selectOptions: Array<iLabelValuePair>;
  onConfirm?: (name: string, newValue: string) => void;
  customStyles?: Record<string, unknown>;
  isDisabled?: boolean;
  openMenuOnFocus?: boolean;
  className?: string;
  cleanUpOnSelected?: boolean;
  placeholder?: string;
};

const SelectSingle = ({
  name,
  defaultValue,
  label,
  selectOptions,
  onConfirm,
  customStyles,
  isDisabled = false,
  openMenuOnFocus = true,
  cleanUpOnSelected = false,
  placeholder,
  className,
}: iSelectSingle) => {
  const [value, setValue] = useState<ValueType<iLabelValuePair>>();

  useEffect(() => {
    const defaultSelectedOption = selectOptions?.find((o: iLabelValuePair) => o.value === defaultValue?.value);

    setValue(cleanUpOnSelected === true ? null : defaultSelectedOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, JSON.stringify(selectOptions)]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (selectValue: any) => {
    if (!selectValue) return;

    setValue(cleanUpOnSelected === true ? null : selectValue);
    if (onConfirm) {
      onConfirm(name, selectValue.value);
    }
  };
  return (
    <Select<ValueType<iLabelValuePair>>
      openMenuOnFocus={openMenuOnFocus}
      className={className}
      label={label}
      value={value}
      options={selectOptions}
      onChange={onChange}
      // defaultValue={cleanUpOnSelected === true ? undefined : defaultValue}
      styles={customStyles}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

export default SelectSingle;
