import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import iIssue from '../../../types/NCR/iIssue';
import SearchBar from '../../../shared/search/SearchBar';
import BackendPage from '../../../layout/BackendPage';
import NCRListTable from './NCRListTable';
import CreateNCRModalBody from './CreateNCRModalBody';
import Advanced from '../../../shared/contactCompany/list/Advanced';
import PageTitleWithAddOne from '../../../shared/heading/PageTitleWithAddOne';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../../../shared/hooks/useShowHideColumnHook/useColumnHook';
import useStatusSelector from '../../../shared/hooks/useStatusSelector';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import { getEntityCategoryList } from '../../../services/CategoryService';
import { handleNullException } from '../../../services/UtilsService';
import { FlexContainer } from '../../../shared/styles/styles';
import { NCRS_URL } from '../../../shared/UrlMap';
import { ALL, NCR_COLUMNS, NCR_LIST_TITLE } from './NCRList.constant';
import { createNCR, deactivateNCR, getNCRs, updateNCR } from '../../../services/NCRService';
import PaginationDetail from '../../../shared/pagination/paginationDetail/PaginationDetail';
import { RootState } from '../../../redux/makeReduxStore';
import {
  ACCESS_CODE_CUSTOMERS,
  ACCESS_CODE_PRODUCTS,
  ACCESS_CODE_WARRANTY,
} from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE, ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';
import AccessService from '../../../services/Settings/UserAccess/AccessService';

const NCRList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const history = useHistory();
  const { control, setValue, errors, handleSubmit, watch } = useForm();
  const {
    state,
    edit,
    onCloseModal,
    onOpenDeleteModal,
    onOpenAddModal,
    onSearch,
    onSetCurrentPage,
    onSetSort,
    onFilter,
    onSubmit,
    onRefreshList,
  } = useListCrudHook<iIssue>({
    getFn: getNCRs,
    deleteFn: deactivateNCR,
    createFn: createNCR,
    updateFn: updateNCR,
    keywordColumns: 'issueNumber',
    sort: 'createdAt:DESC',
    paginationApplied: true,
    notRenderWithoutFilter: true,
    createCallBack: (id: string) => history.push(`${NCRS_URL}/${id}`),
  });

  const { columns, getShowHideColumns } = useColumnHooksUpgrade('issue', NCR_COLUMNS);

  const { selected, getTypeSelectorComponent, categories, isStatusLoading } = useStatusSelector({
    type: 'Issue',
    isMulti: true,
    getFn: getEntityCategoryList,
  });

  useEffect(() => {
    if (!isStatusLoading) {
      const filter = selected.includes(ALL) || selected.length === 0 ? '' : `statusId:${selected.join('|')}`;
      onFilter(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selected)]);

  const onAdvanceClick = () => {
    // eslint-disable-next-line no-console
    console.log('click');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCreateNCR = async (data: { [key: string]: any }) =>
    onSubmit({
      ...data,
      productId: data.product.id,
      jobId: handleNullException(data, 'job.id') || null,
      reportedQty: Number(data.reportedQty),
      reportedDate: handleNullException(data, 'reportedDate') || null,
      customerId: data.customerId,
      isSampleReceived: data.isSampleReceived === 'yes',
      sampleReceivedDate: handleNullException(data, 'sampleReceivedDate') || null,
    });

  const getPageHeader = () => (
    <>
      <PageTitleWithAddOne
        title={NCR_LIST_TITLE}
        onClickAddBtn={onOpenAddModal}
        className={'space-below'}
        isCustomizedTitle
        isDisabled={!AccessService.hasAccess(ACCESS_CODE_WARRANTY, ACCESS_CAN_CREATE, user)}
      />
      <FlexContainer className={'space-between'}>
        {getTypeSelectorComponent()}
        <FlexContainer>
          <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search Issue #'} />
          <Advanced onClick={onAdvanceClick} />
        </FlexContainer>
      </FlexContainer>
    </>
  );

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <>
        <FlexContainer className={'space-between space-below'}>
          {!!state.total && !!state.from && !!state.to && (
            <PaginationDetail from={state.from} to={state.to} total={state.total} onRefreshResults={onRefreshList} />
          )}
          {getShowHideColumns()}
        </FlexContainer>
        <NCRListTable
          isLoading={state.isLoading}
          data={state.data}
          onOpenDeleteModal={onOpenDeleteModal}
          onSetSort={onSetSort}
          columns={columns.map(col => {
            if (col.key === 'customer.name' && !AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_READ, user)) {
              return {
                ...col,
                type: 'text',
              };
            }
            if (
              col.key === 'product.productCode' &&
              !AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user)
            ) {
              return {
                ...col,
                urlPrefix: undefined,
              };
            }
            return col;
          })}
          categories={categories}
          canDelete={AccessService.hasAccess(ACCESS_CODE_WARRANTY, ACCESS_CAN_DELETE, user)}
        />
        <CustomizeModal
          isOpen={edit.isModalOpen && !edit.delTargetId}
          onConfirm={handleSubmit(onCreateNCR)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
          modalBody={<CreateNCRModalBody control={control} onChange={setValue} errors={errors} watch={watch} />}
          modalHeading={'Creating a NCR'}
          confirmBtnName={'Create'}
          width={'large'}
        />
        {!!state.total && !!state.perPage && !!state.currentPage && (
          <CustomizePagination
            range={Math.ceil(state.total / state.perPage)}
            currentPage={state.currentPage}
            onChange={onSetCurrentPage}
          />
        )}
      </>
    </BackendPage>
  );
};

export default NCRList;
