import { NavigationContent, SideNavigation } from '@atlaskit/side-navigation';
import { RightSidebar } from '@atlaskit/page-layout';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Node, useNodes } from 'react-flow-renderer';
import CompanyExceptionEmptyState from '../../../shared/company/CompanyExceptionEmptyState';
import WorkflowEditStatusPanel from './WorkflowEditStatusPanel';
import { iWFDiagramState } from '../../../shared/workflow/diagram/WorkflowDiagramReducer';
import { NODE_ID_START_NODE } from '../../../shared/workflow/diagram/WorkflowDiagramHelper';
import WorkflowStartStatusPanel from './WorkflowStartStatusPanel';

const Wrapper = styled.div`
  margin-left: 1rem;
  [aria-label='right-nav'] {
    height: calc(100vh - (var(--bannerHeight, 0px) + var(--topNavigationHeight, 0px)));
  }
  .right-nav-content {
    padding: 1rem;
  }

  [data-testid='entity-status-edit-panel'] {
    padding: 0px;
    > div {
      margin: 0px;
    }
  }
`;
type iWorkflowEditRightMenu = {
  state: iWFDiagramState;
};
const WorkflowEditRightMenu = ({ state }: iWorkflowEditRightMenu) => {
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const nodes = useNodes();

  useEffect(() => {
    const selectedNodes = nodes.filter(node => node.selected === true);
    setSelectedNode(selectedNodes.length <= 0 ? null : selectedNodes[0]);
  }, [nodes]);

  const getContent = () => {
    if (selectedNode === null) {
      return (
        <CompanyExceptionEmptyState
          header={'Make work flow your way'}
          imageUrl={'/images/workflow.png'}
          description={
            <small>
              Workflows represent your process and control how people progress their work.
              <br />
              Select a status to reveal more details.
            </small>
          }
          primaryAction={null}
        />
      );
    }

    if (selectedNode.id === NODE_ID_START_NODE) {
      return <WorkflowStartStatusPanel editingNode={selectedNode} state={state} />;
    }

    return <WorkflowEditStatusPanel editingNode={selectedNode} state={state} />;
  };

  return (
    <Wrapper>
      <RightSidebar width={330}>
        <SideNavigation label={'right-nav'}>
          <NavigationContent>
            <div className={'right-nav-content'}>{getContent()}</div>
          </NavigationContent>
        </SideNavigation>
      </RightSidebar>
    </Wrapper>
  );
};

export default WorkflowEditRightMenu;
