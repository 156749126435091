/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import _ from 'lodash';
import Button from '@atlaskit/button';
import { AsyncSelect } from '@atlaskit/select';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';

type iSingleSelectOption<T> = iOption<T> | null | undefined;

type iOption<T> = {
  label: string;
  value: T;
};

const Menu = (props: any) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      css={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
const Blanket = (props: any) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  target: any;
  onClose: () => void;
}) => (
  <div css={{ position: 'relative', display: 'flex' }}>
    <div style={{ display: 'inline-block' }}>{target}</div>
    <div style={{ display: 'inline-block', width: '100%' }}>
      {isOpen ? <Menu>{children}</Menu> : null}
    </div>
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const PopupAsyncSelect = <T extends { id: string }>({
  name,
  // defaultValue,
  popupBtnName,
  onConfirm,
  placeholder,
  promiseFn,
  formatOptionLabel,
  testId = 'popup-async-select',
}: {
  name: string;
  // defaultValue: iLabelValuePair;
  popupBtnName: string;
  onConfirm?: (name: string, newValue: T | null) => void;
  placeholder?: string;
  promiseFn: (keyword: string) => Promise<any>;
  formatOptionLabel: (
    option: iSingleSelectOption<T>,
  ) => React.ReactNode | string;
  testId?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<iOption<T>[]>([]);

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  const promiseOptions = (
    inputValue: string,
    callback: (options: iOption<T>[]) => void,
  ) => {
    promiseFn(inputValue).then(({ data: items }) => {
      callback(
        items.map((item: T) => ({
          label: item.id,
          value: item,
        })),
      );
    });
  };
  const debouncedOptions = _.debounce(promiseOptions, 700);

  const handleValueChange = (selectValue: any) => {
    const selected = selectValue;
    setValue(selected);
    toggleOpen();

    if (typeof onConfirm === 'function') {
      onConfirm(name, selected);
    }
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <Button
          iconBefore={<EditorAddIcon label={'addMore'} />}
          onClick={toggleOpen}
          isSelected={isOpen}
        >
          {popupBtnName}
        </Button>
      }
    >
      <AsyncSelect
        loadOptions={debouncedOptions}
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholder}
        value={value}
        onChange={handleValueChange}
        testId={testId}
      />
    </Dropdown>
  );
};

export default PopupAsyncSelect;
