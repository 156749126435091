import React, { useState } from 'react';
import Popup from '@atlaskit/popup';
import Button from '@atlaskit/button';
import EditorMoreIcon from '@atlaskit/icon/glyph/editor/more';
import styled from 'styled-components';

const PopupWrapper = styled.div`
  .popup-open {
    background-color: rgb(37, 56, 88);
    color: rgb(244, 245, 247) !important;
  }
`;
const MoreIconPopup = ({
  children,
  iconSize = 'medium',
}: {
  children: React.ReactNode;
  iconSize?: 'small' | 'medium' | 'large' | 'xlarge' | undefined;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PopupWrapper>
      <Popup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        content={() => children}
        trigger={triggerProps => (
          <Button
            //    eslint-disable-next-line
            {...triggerProps}
            id="popup-trigger"
            className={isOpen ? 'popup-open' : ''}
            onClick={() => setIsOpen(!isOpen)}
            iconBefore={<EditorMoreIcon label={'more'} size={iconSize} />}
            testId="popup-trigger-btn"
          />
        )}
        placement={'bottom-end'}
        autoFocus
      />
    </PopupWrapper>
  );
};

export default MoreIconPopup;
