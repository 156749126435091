import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import {
  createBomAttribute,
  deleteBomAttribute,
  getBomItemAttributes,
  updateBomAttribute,
} from '../../services/BomAttributeService';
import DndTable from '../../shared/dndTable/DndTable';
import PageTitleWithAddOne from '../../shared/heading/PageTitleWithAddOne';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../../shared/hooks/useShowHideColumnHook/useColumnHook';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import iBomItemAttribute from '../../types/product/iBomItemAttribute';

import BomAttributeEditModal from './BomAttributeEditModal';
import { BOM_ATTRIBUTE_COLUMNS, BOM_ATTRIBUTE_LIST_TITLE } from './BomAttributeList.constants';
import { getRows } from './BomAttributeList.tableHelper';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCT_ATTRIBUTES } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE, ACCESS_CAN_UPDATE } from '../../types/settings/UserAccess/iRoleAccess';
import DataTable from '../../shared/tableHelper/DataTable';
import { iConfigColumn } from '../../shared/UITypes/types';

const BomAttributeList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const { control, setValue, errors, handleSubmit } = useForm();
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onSubmit,
    onReorder,
    onOpenEditModal,
  } = useListCrudHook<iBomItemAttribute>({
    getFn: getBomItemAttributes,
    deleteFn: deleteBomAttribute,
    createFn: createBomAttribute,
    updateFn: updateBomAttribute,
    sort: 'sortOrder:ASC',
  });

  const { columns } = useColumnHooksUpgrade('bomAttribute', BOM_ATTRIBUTE_COLUMNS);
  const canCreate = AccessService.hasAccess(ACCESS_CODE_PRODUCT_ATTRIBUTES, ACCESS_CAN_CREATE, user);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_PRODUCT_ATTRIBUTES, ACCESS_CAN_UPDATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_PRODUCT_ATTRIBUTES, ACCESS_CAN_DELETE, user);

  const getPageHeader = () => (
    <PageTitleWithAddOne
      title={BOM_ATTRIBUTE_LIST_TITLE}
      onClickAddBtn={onOpenAddModal}
      className={'space-below'}
      isCustomizedTitle
      isDisabled={!canCreate}
    />
  );

  const getFilteredColumns = (cols: iConfigColumn[]) => {
    return cols.filter(col => {
      if (col.key === 'sortOrder' && !canUpdate) {
        return false;
      }
      if (col.key === 'dragHandlerIcon' && !canUpdate) {
        return false;
      }
      if (col.key === 'operation' && !canDelete) {
        return false;
      }
      return true;
    });
  };

  const getListTable = () => {
    if (!AccessService.hasAccess(ACCESS_CODE_PRODUCT_ATTRIBUTES, ACCESS_CAN_UPDATE, user)) {
      return (
        <DataTable
          rows={getRows(
            state.data,
            onOpenEditModal,
            onDelete,
            getFilteredColumns(columns).map(col => {
              if (col.key === 'name' && !canUpdate) {
                return {
                  ...col,
                  type: 'text',
                };
              }
              return col;
            }),
          )}
          head={{ cells: getFilteredColumns(columns).map(col => ({ content: col.name })) }}
        />
      );
    }
    return (
      <DndTable
        isLoading={state.isLoading}
        data={state.data}
        onReorder={onReorder}
        columns={getFilteredColumns(columns)}
        getRows={getRows(state.data, onOpenEditModal, onDelete, getFilteredColumns(columns))}
        contentColumnNumber={3}
      />
    );
  };

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <>
        {getListTable()}
        <CustomizeModal
          isOpen={edit.isModalOpen}
          onConfirm={handleSubmit(onSubmit)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
          modalBody={
            <BomAttributeEditModal control={control} errors={errors} onChange={setValue} target={edit.target} />
          }
          modalHeading={edit.target ? edit.target.name : 'Create a BOM attribute'}
          confirmBtnName={'Save'}
          height={'23rem'}
        />
      </>
    </BackendPage>
  );
};

export default BomAttributeList;
