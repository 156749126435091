import SectionMessage from '@atlaskit/section-message';
import React from 'react';

const PasswordRuleSection = () => {
  return (
    <div style={{ marginTop: '10px' }}>
      <SectionMessage title={'Passwords need to'}>
        <ul style={{ margin: 0, padding: 0 }}>
          <li>Be at least 8 characters long.</li>
          <li>Include both lower and upper case characters.</li>
          <li>Include at least one number and special character</li>
          <li>Example: Abc1234#</li>
        </ul>
      </SectionMessage>
    </div>
  );
};

export default PasswordRuleSection;
