/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ParseResult } from 'papaparse';
import { CSVReader } from 'react-papaparse';
import ReadFrame from './ReadFrame';
import { ReadImportWrapper } from '../../shared/DataImport.style';
import { SpaceVerticalContainer } from '../../../../../shared/styles/styles';
import { addToastForAPIResponse } from '../../../../../shared/toast/Toast';

const ReadImport = ({
  handleDataRead,
  isReset,
}: {
  handleDataRead: (result: any[][]) => void;
  isReset: boolean;
}) => {
  const handleOnDrop = (data: ParseResult<any>[]) => {
    const result = data.map((item: ParseResult<any>) => item.data);
    handleDataRead(result);
  };

  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    handleDataRead([]);
    addToastForAPIResponse('error', reason.toString());
  };

  const handleOnRemoveFile = () => handleDataRead([]);

  return (
    <ReadImportWrapper>
      <SpaceVerticalContainer className={'quarter'} />
      <CSVReader
        onDrop={handleOnDrop}
        onError={handleOnError}
        addRemoveButton
        onRemoveFile={handleOnRemoveFile}
        isReset={isReset}
      >
        <ReadFrame />
      </CSVReader>
    </ReadImportWrapper>
  );
};

export default ReadImport;
