import { iConfigColumn } from '../../../../../shared/UITypes/types';
import { AOS_URL, PRODUCTS_URL } from '../../../../../shared/UrlMap';

export const SELECT_AO_ITEMS = 'select-aoItem-for-poItem';
export const AO_ITEM_CREATE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.id', 'product.name'],
    isCompulsory: true,
    type: 'link-secondary',
    urlPrefix: PRODUCTS_URL,
    group: '',
  },
  {
    name: 'AO',
    key: 'acquisitionOrder.orderNumber',
    dataSource: ['acquisitionOrder.id'],
    type: 'link',
    urlPrefix: AOS_URL,
    group: '',
  },
  {
    name: 'AO Status',
    key: 'acquisitionOrder.status',
    dataSource: ['acquisitionOrder.status'],
    type: 'status',
    isSelected: true,
    group: '',
  },
  {
    name: 'Supplier Product Code',
    key: 'supplierProductCode',
    dataSource: [''],
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Unit Price',
    key: 'unitPrice',
    dataSource: [
      'product.measurement.shortName',
      'unitPriceMeasurement.shortName',
    ],
    type: 'unit-price',
    isSelected: true,
    group: '',
  },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: [
      'unitConversion',
      'measurement.shortName',
      'product.measurement.shortName',
    ],
    type: 'conversion-text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Confirmed Qty',
    key: 'confirmedQty',
    dataSource: [
      'unitConversion',
      'measurement.shortName',
      'product.measurement.shortName',
    ],
    type: 'conversion-text',
    isSelected: true,
    group: '',
  },

  {
    name: 'Purchased Qty',
    key: 'purchasedQty',
    dataSource: [
      'unitConversion',
      'measurement.shortName',
      'product.measurement.shortName',
    ],
    type: 'conversion-text',
    isSelected: true,
    group: '',
  },
  {
    name: '',
    key: 'operation',
    dataSource: ['id'],
    type: 'operation',
    isCompulsory: true,
    group: '',
  },
];
