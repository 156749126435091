import React from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import Button from '@atlaskit/button';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import iFacility from '../../../../types/warehouse/iFacility';
import FacilityModalBody from './FacilityModalBody';
import { iWarehouseUI } from '../../type/types';
import { iParams } from '../../../../shared/UITypes/types';
import { CREATE_FIELDS } from './constants';

type iParam = {
  isOpen: boolean;
  onClose: () => void;
  //    eslint-disable-next-line
  onConfirm: (data: any) => void;
  isConfirming?: boolean;
  warehouse?: iWarehouseUI;
  facility?: iFacility;
};

const FacilityModal = ({
  isOpen,
  onClose,
  onConfirm,
  isConfirming,
  warehouse,
  facility,
}: iParam) => {
  const { control, setValue, errors, handleSubmit } = useForm({});
  //  eslint-disable-next-line
  const onExtractData = (data: any) => {
    // console.log(data);
    const afterMappingData = CREATE_FIELDS.reduce(
      (acc: iParams, cur: string) =>
        _.get(data, cur)
          ? { ...acc, [`${cur}Id`]: _.get(data, `${cur}.id`) }
          : acc,
      {},
    );
    // console.log(afterMappingData);
    onConfirm({ ...afterMappingData, warehouseLocationId: warehouse?.id });
  };
  const getRemove = () => (
    <Button
      appearance={'danger'}
      testId={'remove-button'}
      onClick={() => onConfirm(null)}
    >
      Remove
    </Button>
  );
  const createHeading = `Make Location(${warehouse?.name}) as a Facility`;
  const updateHeading = 'Update the facility';
  if (typeof warehouse === 'undefined') return null;
  return (
    <CustomizeModal
      isOpen={isOpen}
      isConfirming={isConfirming}
      onConfirm={handleSubmit(onExtractData)}
      onCancel={onClose}
      isDisabled={Object.keys(errors).length > 0}
      confirmBtnName={facility ? 'Update' : 'Create'}
      modalHeading={facility ? updateHeading : createHeading}
      modalBody={
        <FacilityModalBody
          control={control}
          errors={errors}
          onChange={setValue}
          facility={facility}
        />
      }
      bottom={facility && getRemove()}
    />
  );
};
export default FacilityModal;
