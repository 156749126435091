import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iJob from '../../../types/job/iJob';
import iMachine from '../../../types/machine/iMachine';
import { iConfigColumn } from '../../../shared/UITypes/types';
import { DASHBOARD_ORDERS_BY_MACHINE } from '../shared/Dashboard.constants';
import { getHeads } from '../../../services/UtilsService';
import { getRows } from './TableHelper';

const JobsByMachineTable = ({
  machines,
  unscheduledJobs,
  scheduledJobs,
}: {
  machines: Array<iMachine>;
  unscheduledJobs: Array<iJob>;
  scheduledJobs: Array<iJob>;
}) => {
  const getColumns = (): Array<iConfigColumn> => {
    const machineConfigs = machines.map((machine: iMachine) => ({
      key: machine.id,
      name: machine.name,
      isSelected: true,
      dataSource: [],
      type: 'orders-by-machine',
    }));
    return [
      {
        key: 'sort-type',
        name: '',
        isSelected: true,
        dataSource: [],
        type: 'sort-type',
      },
      ...machineConfigs,
    ];
  };

  return (
    <DynamicTable
      head={getHeads(getColumns(), DASHBOARD_ORDERS_BY_MACHINE)}
      rows={getRows({
        unscheduledJobs,
        scheduledJobs,
        columns: getColumns(),
      })}
    />
  );
};

export default JobsByMachineTable;
