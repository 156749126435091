import React from 'react';
import { useForm } from 'react-hook-form';
import iAddress from '../../../../types/contactCompany/iAddress';
import CustomizeModal from '../../../modal/CustomizeModal';
import PageTitleWithAddOne from '../../../heading/PageTitleWithAddOne';
import DeleteByRetype from '../../../deleteWithReType/DeleteByReType';
import CustomizePagination from '../../../pagination/CustomizePagination';
import CompanyAddressTable from './CompanyAddressTable';
import AddressModalBody from '../../../form/hookForms/AddressWithController/AddressModalBody';
import { apiErrorToast } from '../../../toast/Toast';
import { SpaceVerticalContainer } from '../../../styles/styles';
import { handleNullException, validateIsDirty } from '../../../../services/UtilsService';
import { useContactCompanyContext } from '../context/ContactCompanyContext';
import { ADDRESS_FIELDS, COMPANY_ADDRESS_COLUMNS } from './CompanyAddress.constants';
import AddressService from '../../../../services/AddressService';
import { ADDRESS } from '../constants';

const CompanyAddress = ({ companyId, isDisabled = false }: { companyId?: string; isDisabled?: boolean }) => {
  const {
    addressContext: {
      state,
      edit,
      onCreate,
      onEdit,
      onDelete,
      onSetCurrentPage,
      onOpenAddModal,
      onOpenEditModal,
      onOpenDeleteModal,
      onCloseModal,
    },
  } = useContactCompanyContext();

  const { control, setValue, errors, handleSubmit, watch } = useForm({});
  //  eslint-disable-next-line
  const onAddMiddleWare = async (data: any) => {
    try {
      const address: iAddress = await AddressService.createAddress(data);
      await onCreate({ addressId: address.id, contactCompanyId: companyId });
    } catch (error) {
      apiErrorToast(error);
    }
  };
  //  eslint-disable-next-line
  const onEditMiddleWare = async (data: any) => {
    if (!edit.target) return;
    try {
      await AddressService.editAddress(edit.target.addressId, data);
      await onEdit(edit.target.id, {});
    } catch (error) {
      apiErrorToast(error);
    }
  };

  return (
    <div data-testid={'addresses-wrapper'}>
      <PageTitleWithAddOne
        onClickAddBtn={onOpenAddModal}
        title={`${state.data.length} ${ADDRESS}`}
        classNameTitle={'small space-right'}
        isDisabled={isDisabled}
      />
      <SpaceVerticalContainer className={'half'} />
      <CompanyAddressTable
        data={state.data}
        onOpenDeleteModal={onOpenDeleteModal}
        onOpenEditModal={onOpenEditModal}
        columns={COMPANY_ADDRESS_COLUMNS.filter(col => (isDisabled ? ['edit', 'delete'].indexOf(col.key) < 0 : true))}
        isLoading={state.isLoading}
      />
      <DeleteByRetype
        isOpen={!!edit.delTargetId}
        answer={handleNullException(edit.target?.address, 'street')}
        onClose={onCloseModal}
        isConfirming={state.isConfirming}
        onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen && !edit.delTargetId}
        onCancel={onCloseModal}
        onConfirm={handleSubmit(edit.target ? onEditMiddleWare : onAddMiddleWare)}
        isDisabled={Object.keys(errors).length > 0 || !validateIsDirty(watch, edit.target?.address, ADDRESS_FIELDS)}
        modalHeading={edit.target ? 'Update' : 'Create'}
        confirmBtnName={'Save'}
        modalBody={
          <AddressModalBody
            control={control}
            errors={errors}
            onChange={setValue}
            address={edit.target?.address}
            attributes={ADDRESS_FIELDS}
          />
        }
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </div>
  );
};

export default CompanyAddress;
