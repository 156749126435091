import React, { useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import DeleteByRetype from '../../shared/deleteWithReType/DeleteByReType';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import AddProds from './addProducts/AddProds';
import AfterTransfer from './afterTransfer/AfterTransfer';
import MoveProdModalBody from './moveProducts/MoveProdModalBody';
import MoveButtonGroup from './moveProducts/MoveButtonGroup';
import ReviewTable from './moveProducts/ReviewTable';
import StockTransferHeader from './sharedComponents/StockTransferHeader';
import TransferProdsTable from './sharedComponents/TransferProdsTable';
import iWarehouseLocation from '../../types/warehouse/iWarehouse';
import { FlexContainer } from '../../shared/styles/styles';
import { handleNullException } from '../../services/UtilsService';
import {
  PAGE_DESCRIPTION,
  REVIEW_COLUMNS,
  STOCK_TRANSFER_LIST_COLUMNS,
} from './sharedComponents/StockTransfer.constants';
import { PageDescriptionWrapper } from './sharedComponents/StockTransfer.style';
import { iItemLocationTransfer } from './sharedComponents/StockTransfer.type';
import { transfer } from '../../services/StockTransferService';
import { apiErrorToast } from '../../shared/toast/Toast';
import { mergeRecords } from './sharedComponents/StockTransfer.util';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_TRANSFER } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE } from '../../types/settings/UserAccess/iRoleAccess';

type iState = {
  data: Array<iItemLocationTransfer>;
  target?: iItemLocationTransfer;
  isModalOpen: boolean;
  isConfirming: boolean;
  transferDone: boolean;
  destination?: iWarehouseLocation;
};
const initialState: iState = {
  data: [],
  isModalOpen: false,
  isConfirming: false,
  transferDone: false,
};
const StockTransfer = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [state, setState] = useState(initialState);
  //  eslint-disable-next-line
  const { control, setValue, errors, handleSubmit } = useForm();
  const canDelete = AccessService.hasAccess(ACCESS_CODE_STOCK_TRANSFER, ACCESS_CAN_DELETE, user);

  const onOpenDeleteModal = (id: string) =>
    setState({
      ...state,
      target: state.data.find((i: iItemLocationTransfer) => i.id === id),
      isModalOpen: true,
    });
  const onCloseModal = () =>
    setState({
      ...state,
      target: undefined,
      isModalOpen: false,
      transferDone: false,
    });

  const onDelete = () =>
    setState({
      ...state,
      data: state.data.filter((i: iItemLocationTransfer) => i.id !== state.target?.id),
      target: undefined,
      isModalOpen: false,
    });

  //  eslint-disable-next-line
  const onTransfer = async (data: any) => {
    setState({ ...state, isConfirming: true });
    try {
      const destinationWarehouseId = _.get(data, 'destination.id');
      const items = state.data.map((item: iItemLocationTransfer) => ({
        itemLocationId: item.id,
        qty: item.movingQty,
      }));
      await transfer({ destinationWarehouseId, items });
      // console.log(transferResult);
      setState({
        ...state,
        destination: _.get(data, 'destination'),
        transferDone: true,
        isModalOpen: false,
        isConfirming: false,
        target: undefined,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };
  if (state.transferDone) {
    return (
      <BackendPage pageHeader={<StockTransferHeader />}>
        <AfterTransfer
          destination={state.destination}
          transferred={state.data}
          onRefresh={() => setState(initialState)}
        />
      </BackendPage>
    );
  }
  const updateRecords = (newRecords: Array<iItemLocationTransfer>) =>
    setState({ ...state, data: mergeRecords(newRecords, state.data) });

  return (
    <BackendPage pageHeader={<StockTransferHeader />}>
      <PageDescriptionWrapper>{PAGE_DESCRIPTION}</PageDescriptionWrapper>
      <TransferProdsTable
        data={state.data}
        onOpenDeleteModal={onOpenDeleteModal}
        columns={STOCK_TRANSFER_LIST_COLUMNS}
        canDelete={canDelete}
      />
      <FlexContainer className={'space-between'}>
        <AddProds updateRecords={updateRecords} records={state.data} isDisabled={!canDelete} />
        <MoveButtonGroup
          length={state.data.length}
          onConfirm={canDelete ? () => setState({ ...state, isModalOpen: true }) : undefined}
          onCancel={canDelete ? () => setState(initialState) : undefined}
        />
      </FlexContainer>
      {state.target && (
        <DeleteByRetype
          isOpen={state.isModalOpen}
          isConfirming={state.isConfirming}
          answer={handleNullException(state.target, 'product.productCode') || 'N/A'}
          onConfirm={onDelete}
          onClose={onCloseModal}
        />
      )}
      <CustomizeModal
        isOpen={state.isModalOpen && !state.target}
        onConfirm={handleSubmit(onTransfer)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <MoveProdModalBody control={control} onChange={setValue} errors={errors}>
            <ReviewTable data={state.data} columns={REVIEW_COLUMNS} />
          </MoveProdModalBody>
        }
        modalHeading={'Transfer selected items to:'}
        confirmBtnName={'Transfer'}
      />
    </BackendPage>
  );
};

export default StockTransfer;
