import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { gridSize } from '@atlaskit/theme';

import { getLogs } from '../../services/LogService';
import { compareStr } from '../../services/UtilsService';
import { addToastForAPIResponse } from '../toast/Toast';
import { FlexContainer } from '../contactCompany/style';
import LogItem from './LogItem';
import LoadMore from '../pagination/loadMore/LoadMore';
import iLogItem from '../../types/log/iLogItem';

const MarginVerticalContainer = styled.div`
  margin: ${gridSize() * 2}px 0;
`;

interface iProps {
  modelName: string;
  modelId: string;
}

const LogList = ({ modelName, modelId }: iProps) => {
  const [logs, setLogs] = useState<Array<iLogItem>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isEnd, setIsEnd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const perPage = 5;
  useEffect(() => {
    let isCancelled = false;
    const loadLogList = async () => {
      setIsLoading(true);
      try {
        const response = await getLogs(
          modelName,
          modelId,
          perPage,
          currentPage,
        );
        if (isCancelled) return;
        setLogs(preLogs =>
          [...response.data, ...preLogs].sort((a: iLogItem, b: iLogItem) =>
            compareStr(b.updatedAt, a.updatedAt),
          ),
        );
        setIsEnd(response.to >= response.total);
        setCurrentPage(response.currentPage);
      } catch (error) {
        if (isCancelled) return;
        addToastForAPIResponse('error');
      } finally {
        setIsLoading(false);
      }
    };
    loadLogList();
    return () => {
      isCancelled = true;
    };
  }, [modelName, modelId, currentPage]);

  return (
    <MarginVerticalContainer>
      <div>
        {logs?.map((log: iLogItem) => (
          <FlexContainer key={`${log.id}-${Math.random()}`}>
            <LogItem log={log} />
          </FlexContainer>
        ))}
      </div>
      {!isEnd && (
        <LoadMore
          isLoading={isLoading}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      )}
    </MarginVerticalContainer>
  );
};

export default LogList;
