import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import BackendPage from '../../../layout/BackendPage';
import { FlexSpaceBetweenContainer } from '../../../shared/contactCompany/style';
import iUser from '../../../types/user/iUser';
import { createUser, deactivateUser, getUsers, updateUser } from '../../../services/Settings/UserService';
import SearchBar from '../../../shared/search/SearchBar';
import UserList from './UserList';
import PlusIconAfterTitle from '../../../components/settings/PlusIconAfterTitle';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import { RootState } from '../../../redux/makeReduxStore';
import UserModalBody from './UserModalBody';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import Sidebar from '../sidebar/Sidebar';
import WarningModal from '../../../shared/modal/WarningModal';
import './userSetting.css';

type iState = {
  isWarningModalOpen: boolean;
};
const initialState: iState = {
  isWarningModalOpen: false,
};
const UserSetting = () => {
  const [maxUserState, setMaxUserState] = useState(initialState);
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onSearch,
    onSubmit,
    onSetCurrentPage,
  } = useListCrudHook<iUser>({
    getFn: useCallback(
      (config = {}) =>
        getUsers({ ...config, include: 'createdBy,updatedBy,defaultOperatingMachine,defaultFacility,role' }),
      [],
    ),

    // getUsers({ ...config, include: 'createdBy,updatedBy,defaultOperatingMachine,defaultFacility,role' }),
    createFn: createUser,
    updateFn: updateUser,
    deleteFn: deactivateUser,
    keywordColumns: 'username',
    paginationApplied: true,
    //  by default each page has 30 items
    // perPage: 1,
  });
  const { user } = useSelector((s: RootState) => s.auth);
  const { control, setValue, errors, handleSubmit, setError, watch } = useForm({});
  // eslint-disable-next-line
  const onAddCompanyId = (data: any) => {
    //  when update a user, password is not required, can be null/'' but need to be removed
    if (edit.target && !data.password) {
      // eslint-disable-next-line
      delete data.password;
    }
    // Convert collected undefined -> null as API would only set null value while ignore undefined value
    const convertedData = Object.keys(data).reduce((carry, key) => {
      return {
        ...carry,
        [key]: data[key] !== undefined ? data[key] : null,
      };
    }, {});
    onSubmit({ ...convertedData, companyId: user?.company.id });
  };
  const onTryAddUser = () => {
    if (user?.company.maxNoOfUsers && state.data.length === user?.company.maxNoOfUsers) {
      setMaxUserState({ ...maxUserState, isWarningModalOpen: true });
      return;
    }
    onOpenAddModal();
  };
  const closeWarningModal = () => setMaxUserState({ ...maxUserState, isWarningModalOpen: false });
  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'Users'} />
      <FlexSpaceBetweenContainer>
        <PlusIconAfterTitle pageTitle={'Users'} onClick={onTryAddUser} />
        <div style={{ flexGrow: 0 }}>
          <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'search by username'} />
        </div>
      </FlexSpaceBetweenContainer>
    </>
  );
  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <UserList users={state.data} onDelete={onDelete} onEdit={onOpenEditModal} isLoading={state.isLoading} />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onAddCompanyId)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        shouldScrollInViewport
        modalBody={
          <div className="modalWithMarginBottom">
            <UserModalBody
              control={control}
              errors={errors}
              onChange={setValue}
              setError={setError}
              watch={watch}
              targetUser={edit.target}
            />
          </div>
        }
        modalHeading={edit.target ? edit.target.username : 'Create'}
        confirmBtnName={'Save'}
      />
      <WarningModal
        isOpen={maxUserState.isWarningModalOpen}
        onCancel={closeWarningModal}
        modalHeading={'Max number of users reached'}
        modalBody={`The max number of users of your account is ${user?.company.maxNoOfUsers}; please upgrade your account to add more users`}
      />
      {state.total && state.perPage && state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </BackendPage>
  );
};

export default UserSetting;
