import React, { useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import AddIcon from '@atlaskit/icon/glyph/add';
import Button, { LoadingButton } from '@atlaskit/button';
import iJob from '../../../../types/job/iJob';
import iSalesOrderItem from '../../../../types/sales/iSalesOrderItem';
import iJobAttribute from '../../../../types/job/iJobAttribute';
import iJobAttributeValue from '../../../../types/job/iJobAttributeValue';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import CreateJobModalBody from './CreateJobModalBody';
import { apiErrorToast } from '../../../../shared/toast/Toast';
import { iCreateJobParams } from '../../types';
import { getJobAttributeValues, previewJobAttributeValues } from '../../../../services/JobAttributeValueService';
import { iKeyValuePairs } from '../../../../shared/UITypes/types';
import { getProdAttributeValues } from '../../../../services/product/ProductAttributeValueService';
import { createJob, createJobAttributeValue, getJobs, updateJobAttributeValue } from '../../../../services/JobService';
import { ROLL_WEIGHT, ROLL_WEIGHT_PRODUCT_ATTRIBUTE_NAME } from './CreateJob.constants';
import MathHelper from '../../../../services/MathHelper';
import CompanyPreferenceService from '../../../../services/Settings/CompanyPreferenceService';
import { JOB_DETAILS_URL } from '../../../../shared/UrlMap';

type iState = {
  isLoading: boolean;
  isConfirming: boolean;
  isOpen: boolean;
  existedQty: number;
  defaultValues: iKeyValuePairs;
  previewJobAttributesOnChange: boolean;
  newlyCreatedJob?: iJob;
};
const initialState: iState = {
  isLoading: false,
  isConfirming: false,
  isOpen: false,
  existedQty: 0,
  defaultValues: {},
  previewJobAttributesOnChange: false,
  newlyCreatedJob: undefined,
};

type iJobAttributeParams = {
  jobAttributeId: string;
  value: string;
  postfix?: string | null;
};

type iCreateJob = {
  targetItem?: iSalesOrderItem;
  createCallback?: () => void;
  params: iCreateJobParams;
  salesOrderId: string;
  defaultPalletSystemId?: string;
};
const CreateJob = ({ targetItem, createCallback, params, salesOrderId, defaultPalletSystemId }: iCreateJob) => {
  const [state, setState] = useState(initialState);
  const { control, setValue, watch, getValues, errors, handleSubmit } = useForm({});

  const onCloseModal = () => setState(initialState);

  const onPrepareModal = async () => {
    if (typeof targetItem === 'undefined') return;
    setState({ ...state, isLoading: true });
    try {
      const [{ data }, prodAttrValues, isForFilmCompany] = await Promise.all([
        getJobs(1, 9999, {
          filter: `salesOrderId:${salesOrderId},productId:${targetItem?.productId}`,
        }),
        getProdAttributeValues({
          advancedFilter: `ProductAttribute_attributeId.name:${ROLL_WEIGHT_PRODUCT_ATTRIBUTE_NAME},productId:${targetItem?.productId}`,
        }),
        CompanyPreferenceService.isForFilmCompany(),
      ]);
      const existedQty = (data as Array<iJob>).reduce((acc: number, cur: iJob) => MathHelper.add(acc, cur.qtyTotal), 0);
      const leftQty = MathHelper.sub(MathHelper.mul(targetItem.qty, targetItem.unitConversion || 1), existedQty);
      let previews: iKeyValuePairs = {};

      if (prodAttrValues.length === 1 && !Number.isNaN(Number(prodAttrValues[0].value)) && isForFilmCompany === true) {
        const defaultRollWeight = Number(prodAttrValues[0].value);
        if (leftQty > 0) {
          previews = await previewJobAttributeValues({
            productId: targetItem.productId,
            qtyTotal: leftQty.toString(),
            [ROLL_WEIGHT.value]: defaultRollWeight.toString(),
          });
        }
        previews = { ...previews, [ROLL_WEIGHT.value]: defaultRollWeight };
      }
      setState({
        ...state,
        isLoading: false,
        isOpen: true,
        previewJobAttributesOnChange: isForFilmCompany,
        existedQty,
        defaultValues: previews,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isLoading: false, previewJobAttributesOnChange: false });
    }
  };
  const collectData = () => {
    // eslint-disable-next-line
    const data = getValues();
    const jobAttributeParams: iJobAttributeParams[] = [];
    params.jobAttributes.forEach((j: iJobAttribute) => {
      if (data[`${j.id}-value`]) {
        jobAttributeParams.push({
          jobAttributeId: j.id,
          value: data[`${j.id}-value`],
          postfix: data[`${j.id}-postfix`],
        });
      }
      delete data[`${j.id}-value`];
      delete data[`${j.id}-postfix`];
    });
    return { jobData: data, jobAttributeParams };
  };

  const collectDataAndCreate = async () => {
    if (!targetItem) return;
    const { jobData, jobAttributeParams } = collectData();
    // to trigger showPopup:false
    setState({ ...state, isConfirming: true });
    try {
      // create job
      const createdJob = await createJob({
        ...jobData,
        salesOrderItemId: targetItem.id,
        salesOrderId,
      });
      const existedJobAttributeValues = await getJobAttributeValues({
        filter: `jobId:${createdJob.id}`,
      });
      // create job attribute value if not exist;update if value not same
      for (let i = 0; i < jobAttributeParams.length; i++) {
        const jobAttr = jobAttributeParams[i];
        const exist: iJobAttributeValue | undefined = existedJobAttributeValues.find(
          (item: iJobAttributeValue) => item.jobAttributeId === jobAttr.jobAttributeId,
        );
        if (typeof exist === 'undefined') {
          //  eslint-disable-next-line
          await createJobAttributeValue({
            jobId: createdJob.id,
            ...jobAttr,
          });
        } else if (
          !_.eq(Number(exist.value), Number(jobAttr.value)) ||
          !_.eq(exist.postfix || '', jobAttr.postfix || '')
        ) {
          //  eslint-disable-next-line
          await updateJobAttributeValue(exist.id, jobAttr);
        }
      }
      setState({
        ...state,
        isConfirming: false,
        newlyCreatedJob: createdJob,
        previewJobAttributesOnChange: false,
      });
      if (typeof createCallback === 'function') {
        createCallback();
      }
    } catch (error) {
      setState({ ...state, isConfirming: false, previewJobAttributesOnChange: false });
      apiErrorToast(error);
    }
  };

  const getModal = () => {
    if (state.isOpen !== true) {
      return null;
    }

    if (state.newlyCreatedJob) {
      const jobDetailsUrl = JOB_DETAILS_URL.replace(':id', state.newlyCreatedJob.id);
      return (
        <CustomizeModal
          isOpen
          customizedBtn={
            <Button appearance={'primary'} href={jobDetailsUrl}>
              View Job Details
            </Button>
          }
          onCancel={onCloseModal}
          modalHeading={'Success'}
          cancelBtnName={'Close'}
          modalBody={
            <p>
              Job <a href={jobDetailsUrl}>{state.newlyCreatedJob.jobNumber}</a> created successfully
            </p>
          }
        />
      );
    }

    return (
      <CustomizeModal
        isOpen
        onConfirm={handleSubmit(collectDataAndCreate)}
        onCancel={onCloseModal}
        modalHeading={`Creating a job against ${targetItem?.name}`}
        confirmBtnName={'Create'}
        isDisabled={Object.keys(errors).length > 0}
        isConfirming={state.isConfirming}
        modalBody={
          <CreateJobModalBody
            previewJobAttributesOnChange={state.previewJobAttributesOnChange}
            control={control}
            onChange={setValue}
            errors={errors}
            watch={watch}
            targetSOItem={targetItem}
            createJobParams={params}
            defaultPalletId={defaultPalletSystemId}
            existedQty={state.existedQty}
            defaultValues={state.defaultValues}
          />
        }
      />
    );
  };

  return (
    <>
      <LoadingButton
        iconBefore={<AddIcon label="add" size={'small'} />}
        testId={'trigger-button'}
        onClick={onPrepareModal}
        isLoading={state.isLoading}
      />
      {getModal()}
    </>
  );
};

export default CreateJob;
