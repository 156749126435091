import React from 'react';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import { ucFirst } from '../../services/UtilsService';
import { MarginLeftContainer } from '../contactCompany/style';
import { AddOneBtnWrapper, FlexContainer, PageTitle } from '../styles/styles';

type iPageTitleWithAddOne = {
  title: string;
  onClickAddBtn: () => void;
  className?: string;
  classNameTitle?: string;
  isCustomizedTitle?: boolean;
  extraButtons?: React.ReactNode;
  isDisabled?: boolean;
};

const PageTitleWithAddOne = ({
  title,
  onClickAddBtn,
  className,
  classNameTitle,
  extraButtons,
  isCustomizedTitle = false,
  isDisabled = false,
}: iPageTitleWithAddOne) => {
  return (
    <FlexContainer className={className}>
      <PageTitle className={classNameTitle}>{isCustomizedTitle ? title : ucFirst(title)}</PageTitle>
      <FlexContainer>
        <MarginLeftContainer>
          {isDisabled ? null : (
            <AddOneBtnWrapper>
              <Button
                iconBefore={<AddIcon label="add" size={'small'} />}
                shouldFitContainer
                onClick={onClickAddBtn}
                testId={'add-one-after-page-title'}
              />
            </AddOneBtnWrapper>
          )}
        </MarginLeftContainer>
        {extraButtons || null}
      </FlexContainer>
    </FlexContainer>
  );
};

export default PageTitleWithAddOne;
