import React, { useState } from 'react';
import { LoadingButton } from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import iChannel from '../../../../types/integration/iChannel';
import WooCommerceOrderStatusMapper from './WooCommerceOrderStatusMapper';
import { FlexContainer, textDanger } from '../../../styles/styles';
import { apiErrorToast } from '../../../toast/Toast';
import ChannelService from '../../../../services/integration/ChannelService';
import WooCommerceDefaultTaxBaseMapper from './WooCommerceDefaultTaxBaseMapper';
import WooCommerceOrderNoPrefixEditor from './WooCommerceOrderNoPrefixEditor';
import InlineEdit from '../../../form/InlineEdit';
import WooCommerceReconnectBtn from './WooCommerceReconnectBtn';
// import WooCommerceDefaultGuestCustomerSelector from './WooCommerceDefaultGuestCustomerSelector';

type iWooCommerceOnBoardingForm = {
  channel: iChannel;
  onSaved: (updatedChannel: iChannel) => void;
};
const WooCommerceOnBoardingForm = ({ channel, onSaved }: iWooCommerceOnBoardingForm) => {
  const [isSaving, setIsSaving] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChannelSave = (fieldName: string, value: any) => {
    setIsSaving(true);
    ChannelService.updateChannel(channel.id, {
      [fieldName]: value,
    })
      .then(resp => {
        if (onSaved) {
          onSaved(resp);
        }
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getNextButtonDisabled = () => {
    if (`${channel.settings.defaultTaxBaseId || ''}`.trim() === '') {
      return true;
    }

    return false;
  };

  if (
    `${channel.settings.auth?.consumer_key || ''}`.trim() === '' ||
    `${channel.settings.auth?.consumer_secret || ''}`.trim() === ''
  ) {
    return (
      <>
        <SectionMessage appearance={'error'} title={'Need to connect first'}>
          <p>
            You need to connect with your WooCommerce / Wordpress Store (<b>{channel.settings.storeUrl || ''}</b>)
            first. Or, you can delete this channel and recreate another one.
          </p>
          <FlexContainer className={'space-above'}>
            <WooCommerceReconnectBtn channel={channel} />
          </FlexContainer>
        </SectionMessage>
      </>
    );
  }

  return (
    <>
      <FlexContainer className={'space-below'}>
        <SectionMessage appearance={'warning'}>
          You are almost there, please fill in the following details to get your channel {channel.name} in action.
        </SectionMessage>
      </FlexContainer>

      <FlexContainer className={'space-below'}>
        <div>
          <h4>
            Your WooCommerce Store Name <small style={textDanger}>*</small>
          </h4>
          <InlineEdit
            isRequired
            defaultValue={channel.name}
            label={''}
            name={'name'}
            onConfirm={(name, value) => handleChannelSave(name, value)}
            isDisabled={isSaving}
            testId={'channel-name'}
          />
        </div>
      </FlexContainer>

      <FlexContainer className={'space-below'}>
        <div>
          <h4>
            Default Tax Base <small style={textDanger}>*</small>
          </h4>
          <WooCommerceDefaultTaxBaseMapper channel={channel} onSaved={onSaved} />
        </div>
      </FlexContainer>

      <FlexContainer className={'space-below'}>
        <div>
          <h4>Order Number Prefix</h4>
          <WooCommerceOrderNoPrefixEditor channel={channel} onSaved={onSaved} />
        </div>
      </FlexContainer>

      <FlexContainer className={'space-below'}>
        <div>
          <h4>Order Status Map</h4>
          <WooCommerceOrderStatusMapper channel={channel} onSaved={onSaved} />
        </div>
      </FlexContainer>
      {/* <FlexContainer className={'space-below'}>*/}
      {/*  <h4>Guest Customer Company</h4>*/}
      {/*  <WooCommerceDefaultGuestCustomerSelector channel={channel} onSaved={onSaved} />*/}
      {/* </FlexContainer>*/}

      <FlexContainer className={'space-above'}>
        <LoadingButton
          isLoading={isSaving}
          appearance={'primary'}
          isDisabled={getNextButtonDisabled()}
          onClick={() => handleChannelSave('isOnBoarded', true)}
        >
          Continue
        </LoadingButton>
      </FlexContainer>
    </>
  );
};

export default WooCommerceOnBoardingForm;
