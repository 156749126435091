import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import Select from '@atlaskit/select';
import type { ValueType } from '@atlaskit/select/types';
import { iLabelValuePair } from '../../UITypes/types';
import CustomizedLabel from './CustomizedLabel';
import { ErrorMsg } from '../../styles/styles';

const SelectWrapper = styled.div`
  margin-top: 10px;
  min-width: 200px;
  &.min-width-120 {
    min-width: 120px;
  }
  &.non-margin-top {
    margin-top: 0px;
  }
  .validation-error > div {
    border-color: #de350b;
  }
`;
const SelectWithController = ({
  name,
  label,
  placeholder,
  defaultValue,
  control,
  onChange,
  options,
  testId = 'select-react-hook-form',
  isRequired = false,
  errors,
  className,
  isClearable = false,
  isDisabled = false,
}: {
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string | Array<string>;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: Array<iLabelValuePair>;
  testId?: string;
  isRequired?: boolean;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  className?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
}) => {
  const [value, setValue] = useState<ValueType<iLabelValuePair>>();
  useEffect(() => {
    const defaultSelectedOption = options?.find((o: iLabelValuePair) => o.value === defaultValue);

    setValue(defaultSelectedOption);
    onChange(name, defaultValue || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, JSON.stringify(options)]);

  // isMulti validation not done
  const handleValueChange = (selected: ValueType<iLabelValuePair>) => {
    setValue(selected);
    onChange(name, selected ? (selected as iLabelValuePair).value : null, {
      shouldValidate: true,
    });
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? null}
      rules={{
        required: isRequired,
      }}
      render={ctrlProps => (
        <SelectWrapper className={className}>
          <CustomizedLabel label={label} htmlFor={testId} isRequired={isRequired} />
          <Select
            //  eslint-disable-next-line
            {...ctrlProps}
            name={name}
            value={value}
            inputId={testId}
            // id={testId}
            //  eslint-disable-next-line
            onChange={(selected: any) => handleValueChange(selected)}
            placeholder={placeholder}
            options={options}
            classNamePrefix={testId}
            className={`${testId} select-react-hook-form  ${_.get(errors, name) && 'validation-error'}`}
            isClearable={isClearable}
            isDisabled={isDisabled}
          />
          {_.get(errors, name) && <ErrorMsg>must select an element</ErrorMsg>}
        </SelectWrapper>
      )}
    />
  );
};

export default SelectWithController;
