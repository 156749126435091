import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import Select from '@atlaskit/select';
import type { ValueType } from '@atlaskit/select/types';

import { Styles } from 'react-select';
import CustomizedLabel from './CustomizedLabel';
import { ErrorMsg } from '../../styles/styles';
import ComposeSecondaryText from '../../text/ComposeSecondaryText';
import iContactCompanyAddress from '../../../types/contactCompany/iContactCompanyAddress';
import { assembleAddress } from '../../../services/UtilsService';

type iOption<T> = {
  label: string;
  value: T;
};
type iSingleSelectOption<T> = iOption<T> | null | undefined;

const SelectWrapper = styled.div`
  margin-top: 10px;
  min-width: 200px;
  &.min-width-120 {
    min-width: 120px;
  }
  &.non-margin-top {
    margin-top: 0px;
  }
  .validation-error > div {
    border-color: #de350b;
  }
`;
const AddressSelectWithController = ({
  name,
  label,
  placeholder,
  defaultValue,
  options,
  control,
  onChange,
  testId = 'select-react-hook-form',
  isRequired = false,
  errors,
  className,
  isClearable = false,
  isDisabled = false,
  triggerDefault = true,
  customizedStyle,
}: {
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string | Array<string>;
  options: iOption<iContactCompanyAddress>[];
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  testId?: string;
  isRequired?: boolean;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  className?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  triggerDefault?: boolean;
  customizedStyle?: Partial<Styles> | undefined;
}) => {
  const [value, setValue] = useState<
    ValueType<iOption<iContactCompanyAddress>>
  >();

  useEffect(() => {
    if (!defaultValue && triggerDefault) {
      setValue(null);
      onChange(name, null);
      return;
    }

    const defaultSelectedOption = options.find(
      (item: iOption<iContactCompanyAddress>) =>
        item.value.addressId === defaultValue,
    );
    setValue(defaultSelectedOption);
    onChange(name, defaultSelectedOption?.value.addressId, {
      shouldValidate: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, JSON.stringify(options)]);

  const getAddressOptionLabel = (
    option: iSingleSelectOption<iContactCompanyAddress>,
  ) => {
    if (!option) return null;
    return (
      <ComposeSecondaryText
        secondaryText={assembleAddress(option.value.address)}
      >
        {option.value.address?.name}
      </ComposeSecondaryText>
    );
  };
  const onChangeMiddleWare = (
    selected: ValueType<iOption<iContactCompanyAddress>>,
  ) => {
    if (!selected) {
      onChange(name, null, { shouldValidate: true });
      return;
    }
    setValue(selected);
    onChange(
      name,
      (selected as iOption<iContactCompanyAddress>).value.addressId,
      { shouldValidate: true },
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      rules={{
        required: isRequired,
      }}
      render={ctrlProps => (
        <SelectWrapper className={className}>
          <CustomizedLabel
            label={label}
            htmlFor={testId}
            isRequired={isRequired}
          />
          <Select
            //  eslint-disable-next-line
            {...ctrlProps}
            name={name}
            inputId={testId}
            options={options}
            value={value}
            formatOptionLabel={getAddressOptionLabel}
            onChange={onChangeMiddleWare}
            placeholder={placeholder}
            classNamePrefix={testId}
            className={`${testId} select-react-hook-form  ${
              _.get(errors, name) && 'validation-error'
            }`}
            isClearable={isClearable}
            isDisabled={isDisabled}
            styles={customizedStyle}
          />
          {_.get(errors, name) && <ErrorMsg>must select an element</ErrorMsg>}
        </SelectWrapper>
      )}
    />
  );
};

export default AddressSelectWithController;
