/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React from 'react';
import HandleData from './HandleData';
import Collapse from '../../../../../shared/collapse/Collapse';
import { STEP3_TEXT } from '../../shared/DataImport.constants';
import { iPrefills, iTemplateColumn } from '../../shared/DataImport.types';

const StepSubmit = ({
  detailPageName,
  data,
  prefills,
  columns,
  children,
}: {
  detailPageName: string;
  data: any[][];
  prefills: iPrefills;
  columns: Array<iTemplateColumn>;
  children: React.ReactNode;
}) => {
  //  eslint-disable-
  const [, ...others] = data;
  return (
    <Collapse text={STEP3_TEXT} defaultOpen>
      <HandleData
        data={others}
        detailPageName={detailPageName}
        prefills={prefills}
        columns={columns}
      >
        {children}
      </HandleData>
    </Collapse>
  );
};

export default StepSubmit;
