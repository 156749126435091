/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import { useParams } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';

import SectionMessage from '@atlaskit/section-message';
import Button from '@atlaskit/button';
import { useForm } from 'react-hook-form';
import iProduct from '../../../../../../types/product/iProduct';
import iProductAttribute from '../../../../../../types/product/iProductAttribute';
import { getProdAttributes } from '../../../../../../services/product/ProductAttributeService';
import { addToastForAPIResponse, apiErrorToast } from '../../../../../../shared/toast/Toast';
import InlineEdit from '../../../../../../shared/form/InlineEdit';
import iParamTypes from '../../../../../../types/iParamTypes';
import {
  cloneProdAttributeValues,
  createProdAttributeValue,
  deleteProdAttributeValue,
  getProdAttributeValues,
  updateProdAttributeValue,
} from '../../../../../../services/product/ProductAttributeValueService';
import iProductAttributeValue from '../../../../../../types/product/iProductAttributeValue';
import InlineEditSelect from '../../../../../../shared/form/InlineEditSelect';
import iProductAttributeSet from '../../../../../../types/product/iProductAttributeSet';
import { iLabelValuePair, iParams } from '../../../../../../shared/UITypes/types';
import { getProdAttributeSets } from '../../../../../../services/product/ProductAttributeSetService';
import InlineCheckbox from '../../../../../../shared/form/InlineCheckbox';
import { FlexContainer, WarningMsgWrapper } from '../../../../../../shared/styles/styles';
import CustomizeModal from '../../../../../../shared/modal/CustomizeModal';
import SingleSelect from '../../../../../../shared/form/SingleSelect';
import LinkBtnUrl from '../../../../../../shared/buttons/LinkBtnUrl';
import { PRODUCT_ATTRIBUTE_SETS_URL } from '../../../../../../shared/UrlMap';
import { useWindowSize } from '../../../../../../shared/hooks/useWindowSizeHook';
// eslint-disable-next-line import/no-cycle
import { CHANGE_PRODUCT_ATTRIBUTE_SET_WARNING } from '../../../constants';
import CopyAttributeValueModal from './CopyAttributeValueModal';

const InlineEditContainer = styled.div`
  min-width: 10rem;
  width: 50%;
  margin: auto;
`;

const mapProductAttributeSets = (sets: iProductAttributeSet[]): iLabelValuePair[] => {
  return sets.map((s: iProductAttributeSet) => ({
    label: s.name,
    value: s.id,
  }));
};

const mapSelectOptions = (options: string[]): iLabelValuePair[] => {
  return options.map(o => ({
    label: o,
    value: o,
  }));
};

const ProdAttributes = ({
  product,
  onUpdateProduct,
  isDisabled,
}: {
  product?: iProduct;
  isDisabled?: boolean;
  onUpdateProduct: (name: string, newValue: string | number | boolean | null) => void;
}) => {
  const { id } = useParams<iParamTypes>();
  const { control, setValue, errors, handleSubmit, watch } = useForm();
  const [localProductAttributeValues, setLocalProductAttributeValues] = useState<iProductAttributeValue[]>([]);
  const [localProductAttributes, setLocalProductAttributes] = useState<iProductAttribute[]>([]);
  const [localAttributeSets, setLocalAttributeSets] = useState<iProductAttributeSet[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [version, setVersion] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopyFromModalOpen, setIsCopyFromModalOpen] = useState(false);
  const [localUpdateFn, setLocalUpdateFn] = useState<() => void>();
  const [newAttributeSetName, setNewAttributeSetName] = useState<string>('N/A');
  const [retypeValue, setRetypeValue] = useState('');
  const [isRetypeValid, setIsRetypeValid] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [width, height] = useWindowSize();

  const getGridColumnSize = () => {
    switch (true) {
      case width >= 1080 && width < 1280:
        return 4;
      case width < 1080:
        return 6;
      default:
        return 3;
    }
  };
  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const attributeSets = await getProdAttributeSets();
        const attributes = await getProdAttributes({
          filter: `attributeSetId:${product?.attributeSetId}`,
        });

        const attributeValues = await getProdAttributeValues({
          filter: `productId:${id}`,
        });
        if (!mounted) return;
        setLocalAttributeSets(attributeSets);
        setLocalProductAttributeValues(attributeValues);
        setLocalProductAttributes(attributes);
        setIsLoading(false);
      } catch (e) {
        apiErrorToast(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.attributeSetId, version, id, JSON.stringify(product)]);

  const onUpdateOrCreateProductAttributeValue = async (
    targetId: string,
    attributeId: string,
    name: string,
    newValue: string | number | boolean | null,
  ) => {
    try {
      // eslint-disable-next-line no-unused-expressions
      targetId
        ? newValue
          ? await updateProdAttributeValue(targetId, {
              [name]: newValue,
            })
          : await deleteProdAttributeValue(targetId)
        : await createProdAttributeValue({
            [name]: newValue,
            productId: id,
            attributeId,
          });
      setVersion(prev => prev + 1);
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const dict = localProductAttributeValues.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acc: any, attrValue: iProductAttributeValue) => ({
      ...acc,
      [attrValue.attributeId]: {
        id: attrValue.id,
        value: attrValue.value,
        prefix: attrValue.prefix,
        postfix: attrValue.postfix,
      },
    }),
    {},
  );

  const getAttributeInputs = () => {
    return localProductAttributes
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((attribute: iProductAttribute, index: number) => {
        const defaultValue = dict[attribute.id]?.value || null;
        const targetValueId = dict[attribute.id]?.id || null;
        const prefix = dict[attribute.id]?.prefix || '';
        const postfix = dict[attribute.id]?.postfix || '';
        switch (attribute.type.toUpperCase()) {
          case 'TEXT':
            return (
              <GridColumn medium={getGridColumnSize()} key={index}>
                <InlineEdit
                  name={'value'}
                  defaultValue={defaultValue}
                  label={attribute.name}
                  onConfirm={(name, value) =>
                    onUpdateOrCreateProductAttributeValue(targetValueId, attribute.id, name, value)
                  }
                  prefix={prefix}
                  postfix={postfix}
                  testId={'attributeValue'}
                  isDisabled={isDisabled}
                />
              </GridColumn>
            );
          case 'NUMERIC':
            return (
              <GridColumn medium={getGridColumnSize()} key={index}>
                <InlineEdit
                  name={'value'}
                  defaultValue={defaultValue}
                  label={attribute.name}
                  onConfirm={(name, value) =>
                    onUpdateOrCreateProductAttributeValue(targetValueId, attribute.id, name, value)
                  }
                  isNumeric
                  prefix={prefix}
                  postfix={postfix}
                  testId={'attributeValue'}
                  isDisabled={isDisabled}
                />
              </GridColumn>
            );
          case 'CHECKBOX':
            return (
              <GridColumn medium={getGridColumnSize()} key={index}>
                <InlineCheckbox
                  name={'value'}
                  defaultValue={defaultValue && (defaultValue as string).toUpperCase() === 'TRUE'}
                  label={attribute.name}
                  onConfirm={(name, value) =>
                    onUpdateOrCreateProductAttributeValue(targetValueId, attribute.id, name, value)
                  }
                  isDisabled={isDisabled}
                />
              </GridColumn>
            );
          case 'SELECT':
            return (
              <GridColumn medium={getGridColumnSize()} key={index}>
                <InlineEditSelect
                  name={'value'}
                  defaultValue={mapSelectOptions(JSON.parse(attribute.options) || []).find(
                    (option: iLabelValuePair) => targetValueId && option.value === defaultValue,
                  )}
                  label={attribute.name}
                  selectOptions={mapSelectOptions(JSON.parse(attribute.options) || [])}
                  onConfirm={(name, value) =>
                    onUpdateOrCreateProductAttributeValue(targetValueId, attribute.id, name, value)
                  }
                  isClearable
                  isDisabled={isDisabled}
                />
              </GridColumn>
            );
          default:
            return null;
        }
      });
  };

  const getAttributeSetName = (setId: string | null) => {
    return localAttributeSets.find(set => setId === set.id)?.name;
  };

  const onConfirmUpdateSet = (name: string, newValue: string | null) => {
    onUpdateProduct(name, newValue);
    setIsModalOpen(false);
  };

  const onSelectProductAttributeSet = (name: string, newValue: string | null) => {
    setIsModalOpen(true);
    // newValue is attributeSetId
    setLocalUpdateFn(() => () => onConfirmUpdateSet(name, newValue));

    // newSetToUpdate refers to the set name
    const newSetToUpdate = getAttributeSetName(newValue);

    setNewAttributeSetName(newSetToUpdate || 'Set Name N/A');
    setRetypeValue('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRetypeInputChange = (event: any) => {
    setRetypeValue(event.target.value);
    setIsRetypeValid(event.target.value.trim().toUpperCase() === newAttributeSetName.trim().toUpperCase());
  };

  const onClickCopyFrom = () => {
    setIsCopyFromModalOpen(true);
  };

  const onConfirmCopyAttributeValues = async (data: iParams) => {
    if (!product) return;
    try {
      await cloneProdAttributeValues(data.sourceProductId, id);
      addToastForAPIResponse('success');
      setVersion(prev => prev + 1);
      setIsCopyFromModalOpen(false);
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onCheckRetypeAnswer = (isRightAnswer: boolean) => {
    setIsRetypeValid(isRightAnswer);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={12}>
          <FlexContainer className={'space-between space-below margin-bottom space-above'}>
            <h4>Product Attribute Set</h4>
            <InlineEditContainer>
              <SingleSelect
                label={''}
                name={'attributeSetId'}
                defaultValue={mapProductAttributeSets(localAttributeSets).find(
                  (option: iLabelValuePair) => product?.attributeSetId && option.value === product?.attributeSetId,
                )}
                selectOptions={mapProductAttributeSets(localAttributeSets)}
                onConfirm={onSelectProductAttributeSet}
                isDisabled={isDisabled}
              />
            </InlineEditContainer>

            {isDisabled ? null : (
              <div style={{ marginTop: 'auto', marginRight: 'auto' }}>
                <LinkBtnUrl
                  btnName={`Edit ${product?.attributeSet.name}` || ''}
                  url={`${PRODUCT_ATTRIBUTE_SETS_URL}/${product?.attributeSetId}`}
                />
              </div>
            )}
            {isDisabled ? null : (
              <Button
                className={'popup-item'}
                onClick={onClickCopyFrom}
                testId={'product-attributes-copyFrom-button'}
                iconBefore={<DocumentsIcon label={'copyFrom'} />}
              >
                Copy From
              </Button>
            )}
          </FlexContainer>
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        {getAttributeInputs()}
      </Grid>
      {isModalOpen && localUpdateFn && (
        <CustomizeModal
          onConfirm={localUpdateFn}
          isOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          isDisabled={!isRetypeValid}
          confirmBtnName={'Confirm'}
          confirmBtnAppearance={'primary'}
          modalHeading={`Please enter ${newAttributeSetName} to update`}
          modalBody={
            <>
              <Textfield
                value={retypeValue}
                onChange={onRetypeInputChange}
                isCompact
                testId={'textfield-enter-answer'}
              />
              <WarningMsgWrapper>
                <SectionMessage appearance="warning">{CHANGE_PRODUCT_ATTRIBUTE_SET_WARNING}</SectionMessage>
              </WarningMsgWrapper>
            </>
          }
          width={'medium'}
        />
      )}
      {isCopyFromModalOpen && (
        <CustomizeModal
          onConfirm={handleSubmit(onConfirmCopyAttributeValues)}
          isOpen={isCopyFromModalOpen}
          onCancel={() => setIsCopyFromModalOpen(false)}
          isDisabled={!isRetypeValid}
          confirmBtnName={'Confirm'}
          confirmBtnAppearance={'primary'}
          modalHeading={'Copy Attribute Values'}
          modalBody={
            <CopyAttributeValueModal
              control={control}
              errors={errors}
              onChange={setValue}
              watch={watch}
              onCheckRetypeAnswer={onCheckRetypeAnswer}
              targetProduct={product}
            />
          }
          width={'medium'}
        />
      )}
    </>
  );
};

export default ProdAttributes;
