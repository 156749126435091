import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import React, { useState } from 'react';
import styled from 'styled-components';
import iChannel from '../../../../types/integration/iChannel';
import { FlexColumnContainer, FlexContainer } from '../../../styles/styles';
import Textfield from '../../../form/Textfield';
import { handleNullException } from '../../../../services/UtilsService';
import LogoSVG from '../../../integrations/svgs/woocommerce-logo.svg';
import ChannelService from '../../../../services/integration/ChannelService';
import { addToastForAPIResponse, apiErrorToast } from '../../../toast/Toast';
import InlineEdit from '../../../form/InlineEdit';
import iChannelScript, { CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE } from '../../../../types/integration/iChannelScript';
import WooCommerceOrderStatusMapper from './WooCommerceOrderStatusMapper';
import WooCommerceDefaultTaxBaseMapper from './WooCommerceDefaultTaxBaseMapper';
import WooCommerceOrderNoPrefixEditor from './WooCommerceOrderNoPrefixEditor';
import WooCommerceDefaultGuestCustomerMyobSelector from './WooCommerceDefaultGuestCustomerMyobSelector';
import WooCommerceReconnectBtn from './WooCommerceReconnectBtn';
// import WooCommerceDefaultGuestCustomerSelector from './WooCommerceDefaultGuestCustomerSelector';

type iWooCommerceEditForm = {
  channel: iChannel;
  channelScript: iChannelScript;
  callback: (channel: iChannel) => void;
};

const Wrapper = styled.div`
  .form-content {
    width: 60%;
    max-width: 60rem;
    min-width: 30rem;
  }

  .storeUrl {
    svg {
      width: 14px;
      height: 14px;
    }
  }

  .logo {
    width: 20%;
    max-width: 8rem;
    min-width: 2rem;
    img {
      width: 100%;
      height: auto;
      margin: 0.4rem;
    }
    .linkBtn {
      text-align: center;
    }
  }

  .default-guest-customer,
  .default-tax-base-wrapper,
  .order-no-prefix {
    width: 50%;
    min-width: 17rem;
    max-width: 22rem;
  }
`;
const WooCommerceEditForm = ({ channel, channelScript, callback }: iWooCommerceEditForm) => {
  const [isSaving, setISaving] = useState(false);

  const onChange = (fieldName: string, newValue: string | number) => {
    setISaving(true);
    ChannelService.updateChannel(channel.id, {
      [fieldName]: newValue,
    })
      .then(resp => {
        addToastForAPIResponse('success', 'Channel updated.');
        if (callback) {
          callback(resp);
        }
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setISaving(false);
      });
  };

  if (channelScript.script !== CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE) {
    return null;
  }

  return (
    <Wrapper>
      <FlexContainer className="space-between with-gap space-below">
        <div className={'form-content'}>
          <FlexContainer className="space-between even-split with-gap">
            <div className="firstCol">
              <InlineEdit
                isRequired
                defaultValue={channel.name}
                label={'Name'}
                name={'name'}
                onConfirm={onChange}
                isDisabled={isSaving}
                testId={'channel-name'}
              />
              <Textfield
                defaultValue={handleNullException(channel, 'settings.auth.consumer_key')}
                label="Consumer Key"
                name="consumer_key"
                testId={'consumer_key'}
                isDisabled
              />
            </div>
            <div className="secondCol">
              <Textfield
                defaultValue={handleNullException(channel, 'settings.storeUrl')}
                label={
                  <FlexContainer className={'flex-start with-gap'}>
                    <span>Store Url</span>
                    <a
                      className={'storeUrl'}
                      href={handleNullException(channel, 'settings.storeUrl')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ShortcutIcon label="link" />
                    </a>
                  </FlexContainer>
                }
                testId={'storeUrl'}
                name="storeUrl"
                isDisabled
              />
              <Textfield
                defaultValue={handleNullException(channel, 'settings.auth.consumer_secret')}
                label="Consumer Secret"
                name="consumer_secret"
                testId={'consumer_secret'}
                isDisabled
              />
            </div>
          </FlexContainer>
        </div>

        <FlexColumnContainer className={'logo'}>
          <img src={LogoSVG} alt="WooCommerce Logo" />
          <div className="linkBtn">
            <WooCommerceReconnectBtn channel={channel} />
          </div>
        </FlexColumnContainer>
      </FlexContainer>

      <FlexContainer className={'wrap-items with-gap align-items-flex-start'}>
        <div className={'default-tax-base-wrapper'}>
          <h4 style={{ margin: '1rem 0' }}>Default Tax Base</h4>
          <WooCommerceDefaultTaxBaseMapper channel={channel} onSaved={callback} />
        </div>
        <div className={'order-no-prefix'}>
          <h4 style={{ margin: '1rem 0' }}>Order Number Prefix</h4>
          <WooCommerceOrderNoPrefixEditor channel={channel} onSaved={callback} />
        </div>
        {/* <div className={'default-guest-customer'}>*/}
        {/*  <h4 style={{ margin: '1rem 0' }}>Guest Customer Company</h4>*/}
        {/*  <WooCommerceDefaultGuestCustomerSelector channel={channel} onSaved={callback} />*/}
        {/* </div>*/}

        <div className={'default-guest-customer'}>
          <h4 style={{ margin: '1rem 0' }}>Guest Customer Company Acc.</h4>
          <WooCommerceDefaultGuestCustomerMyobSelector channel={channel} onSaved={callback} />
        </div>
      </FlexContainer>

      <div>
        <h4 style={{ margin: '1rem 0' }}>Status Map</h4>
        <WooCommerceOrderStatusMapper channel={channel} onSaved={callback} />
      </div>
    </Wrapper>
  );
};

export default WooCommerceEditForm;
