import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import InlineEdit from '@atlaskit/inline-edit';
import { FieldTextAreaStateless } from '@atlaskit/field-text-area';
import { ErrorMessage } from '@atlaskit/form';

const textAreaLineHeightFactor = 2;

const DivWrapper = styled.div``;
const ReadViewContainer = styled.div`
  line-height: ${(gridSize() * textAreaLineHeightFactor) / fontSize()};
  min-height: ${gridSize() * textAreaLineHeightFactor * 2}px;
  padding: ${gridSize() - 2}px ${gridSize() - 2}px;
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: ${props => (props.defaultValue === '' ? '#7a869a' : '#091E42')};
`;
type iState = {
  value: string;
  focused: boolean;
};
const initialState: iState = {
  value: '',
  focused: false,
};

type iInlineEditTextarea = {
  name: string;
  label: string;
  defaultValue?: string;
  onConfirm?: (name: string, value: string) => void;
  hideActionButtons?: boolean;
  testId?: string;
  minRows?: number;
  isDisabled?: boolean;
};
const InlineEditTextarea = ({
  name,
  label,
  defaultValue,
  onConfirm,
  hideActionButtons,
  testId = 'inline-edit-text-area',
  minRows = 5,
  isDisabled,
}: iInlineEditTextarea) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    setState(s => ({
      ...s,
      value: defaultValue || initialState.value,
    }));
  }, [defaultValue]);
  // eslint-disable-next-line
  const changeValue = (value: any) => {
    // value is null or is default value
    // don't change it
    if (!value || value === defaultValue) {
      setState({
        ...state,
        focused: false,
      });
      return;
    }
    setState(preState => ({
      ...preState,
      value,
      focused: false,
    }));
    if (typeof onConfirm === 'function') {
      onConfirm(name, value);
    }
  };

  return (
    <DivWrapper
      id={name}
      tabIndex={0}
      onFocus={() => {
        setState(preState => ({ ...preState, focused: !isDisabled }));
      }}
    >
      <InlineEdit
        label={label}
        defaultValue={state.value}
        hideActionButtons={hideActionButtons}
        editView={({ errorMessage, ...fieldProps }) => (
          <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <FieldTextAreaStateless
              //    eslint-disable-next-line
            {...fieldProps}
              onFocus={e => e.target.select()}
              autoFocus
              minimumRows={minRows}
              isInvalid={fieldProps.isInvalid}
              shouldFitContainer
              compact
              isLabelHidden
              data-testid={testId}
            />

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
        readView={() => (
          <ReadViewContainer defaultValue={state.value}>{state.value || 'Click to enter value'}</ReadViewContainer>
        )}
        onConfirm={changeValue}
        readViewFitContainerWidth
        isEditing={state.focused}
        onEdit={() => setState(preState => ({ ...preState, focused: !isDisabled }))}
        onCancel={() => setState({ ...state, focused: false })}
      />
    </DivWrapper>
  );
};

export default InlineEditTextarea;
