import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';

import {
  createJobAttribute,
  deleteJobAttribute,
  getJobAttributes,
  updateJobAttribute,
} from '../../services/JobAttributeService';
import DndTable from '../../shared/dndTable/DndTable';
import PageTitleWithAddOne from '../../shared/heading/PageTitleWithAddOne';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../../shared/hooks/useShowHideColumnHook/useColumnHook';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import iJobAttribute from '../../types/job/iJobAttribute';
import JobAttributeEditModal from './JobAttributeEditModal';
import { JOB_ATTRIBUTE_COLUMNS, JOB_ATTRIBUTE_LIST_TITLE } from './JobAttributeList.constants';
import { getRows } from './JobAttributeList.tableHelper';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_JOB_ATTRIBUTES } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE, ACCESS_CAN_UPDATE } from '../../types/settings/UserAccess/iRoleAccess';
import DataTable from '../../shared/tableHelper/DataTable';
import { iConfigColumn } from '../../shared/UITypes/types';

const JobAttributeList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canCreate = AccessService.hasAccess(ACCESS_CODE_JOB_ATTRIBUTES, ACCESS_CAN_CREATE, user);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_JOB_ATTRIBUTES, ACCESS_CAN_UPDATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_JOB_ATTRIBUTES, ACCESS_CAN_DELETE, user);
  const { control, setValue, errors, handleSubmit } = useForm();
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onSubmit,
    onReorder,
    onOpenEditModal,
  } = useListCrudHook<iJobAttribute>({
    getFn: getJobAttributes,
    deleteFn: deleteJobAttribute,
    createFn: createJobAttribute,
    updateFn: updateJobAttribute,
    sort: 'sortOrder:ASC',
  });

  const { columns } = useColumnHooksUpgrade('jobAttribute', JOB_ATTRIBUTE_COLUMNS);

  const getPageHeader = () => (
    <PageTitleWithAddOne
      title={JOB_ATTRIBUTE_LIST_TITLE}
      onClickAddBtn={onOpenAddModal}
      className={'space-below'}
      isCustomizedTitle
      isDisabled={!canCreate}
    />
  );

  const getFilteredColumns = (cols: iConfigColumn[]) => {
    return cols
      .filter(col => {
        if (col.key === 'dragHandlerIcon' && !canUpdate) {
          return false;
        }

        if (col.key === 'sortOrder') {
          return false;
        }

        if (col.key === 'operation' && !canDelete) {
          return false;
        }
        return true;
      })
      .map(col => {
        if (col.key === 'name' && !canUpdate) {
          return {
            ...col,
            type: 'text',
          };
        }
        return col;
      });
  };

  const getTable = () => {
    if (!canUpdate) {
      return (
        <DataTable
          rows={getRows(state.data, onOpenEditModal, onDelete, getFilteredColumns(columns))}
          head={{ cells: getFilteredColumns(columns).map(col => ({ content: col.name })) }}
        />
      );
    }
    return (
      <DndTable
        isLoading={state.isLoading}
        data={state.data}
        onReorder={onReorder}
        columns={getFilteredColumns(columns)}
        getRows={getRows(state.data, onOpenEditModal, onDelete, getFilteredColumns(columns))}
        contentColumnNumber={4}
      />
    );
  };

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <>
        {getTable()}
        <CustomizeModal
          isOpen={edit.isModalOpen}
          onConfirm={handleSubmit(onSubmit)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
          modalBody={
            <JobAttributeEditModal control={control} errors={errors} onChange={setValue} target={edit.target} />
          }
          modalHeading={edit.target ? edit.target.name : 'Create a job attribute'}
          confirmBtnName={'Save'}
          height={'30rem'}
        />
      </>
    </BackendPage>
  );
};

export default JobAttributeList;
