import { Grid, GridColumn } from '@atlaskit/page';
import _ from 'lodash';
import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { getUnitsAsyncSearch } from '../../services/Settings/UnitMeasurementService';
import { handleNullException } from '../../services/UtilsService';
import iContactCompanyProduct from '../../types/contactCompany/iContactCompanyProduct';
import iUnitOfMeasurements from '../../types/iUnitOfMeasurement';
import iProduct from '../../types/product/iProduct';
import AsyncSearchWithController from '../form/hookForms/AsyncSearchWithController';
import InputWithController from '../form/hookForms/InputWithController';
import SelectWithController from '../form/hookForms/SelectWithController';
import { MarginTopWrapper } from '../styles/styles';
import ConversionText from '../text/ConversionText';
import { mapMeasurementToLabelValuePair } from './TableHelper';

const CCPModalCommonFields = ({
  control,
  errors,
  onChange,
  watch,
  target,
  measurementLabel,
  fixedProduct,
}: {
  // eslint-disable-next-line
  control: Control<Record<string, any>>;
  // eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  // eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  // eslint-disable-next-line
  watch: (names?: string | string[]) => any;
  target?: iContactCompanyProduct;
  measurementLabel: 'Customer' | 'Supplier' | 'Service Provider';
  fixedProduct?: iProduct;
}) => {
  /**
   * @returns productMeasurement and supplierMeasurement can be undefined/null
   */
  const getProdSuppMeasuremt = () => {
    const productMeasurement =
      handleNullException(watch('product'), 'measurement') || target?.product.measurement || fixedProduct?.measurement;

    const companyMeasurement = watch('measurement') || target?.measurement;

    const unitConversion = watch('unitConversion') || target?.unitConversion;

    return { productMeasurement, companyMeasurement, unitConversion };
  };

  const getOptionsForUnitPriceMeasurement = () => {
    const { productMeasurement, companyMeasurement } = getProdSuppMeasuremt();
    const options = _.uniqBy(
      [productMeasurement, companyMeasurement].filter((item: iUnitOfMeasurements) => item),
      'id',
    );
    return mapMeasurementToLabelValuePair(options);
  };

  // make sure unitConversion&&productMeasurement & supplierMeasurement exists
  const showConversionEquation = () => {
    const { productMeasurement, companyMeasurement, unitConversion } = getProdSuppMeasuremt();
    return unitConversion && productMeasurement && companyMeasurement;
  };
  return (
    <>
      <Grid spacing={'compact'}>
        <GridColumn medium={6}>
          <AsyncSearchWithController
            name={'measurement'}
            label={`${measurementLabel} Unit`}
            defaultValue={target?.measurement}
            control={control}
            onChange={onChange}
            promiseFn={(keyword: string) => getUnitsAsyncSearch({ like: `shortName:${keyword}` })}
            optionLabel={['shortName']}
            errors={errors}
            isRequired
            testId={'measurement'}
          />
        </GridColumn>
        <GridColumn medium={6}>
          {showConversionEquation() && (
            <>
              <MarginTopWrapper className={'large'} />
              <ConversionText
                value={1}
                conversion={getProdSuppMeasuremt().unitConversion}
                contactCompanyUnit={getProdSuppMeasuremt().companyMeasurement.shortName}
                productUnit={getProdSuppMeasuremt().productMeasurement.shortName}
              />
            </>
          )}
        </GridColumn>
      </Grid>
      <Grid spacing={'compact'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'unitConversion'}
            label={'Conversion'}
            defaultValue={target?.unitConversion}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            isNumeric
            testId={'unitConversion'}
          />
        </GridColumn>
        {measurementLabel === 'Supplier' && (
          <GridColumn medium={6}>
            <InputWithController
              name={'code'}
              label={'Supplier Product Code / SKU'}
              defaultValue={target?.code}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              testId={'code'}
            />
          </GridColumn>
        )}
      </Grid>
      <Grid spacing={'compact'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'unitPrice'}
            label={'Unit Price'}
            defaultValue={target?.unitPrice}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            isNumeric
            testId={'unitPrice'}
          />
        </GridColumn>
        <GridColumn medium={6}>
          <SelectWithController
            name={'unitPriceMeasurementId'}
            label={'Measure for this Price'}
            defaultValue={target?.unitPriceMeasurementId}
            options={getOptionsForUnitPriceMeasurement()}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'unitPriceMeasureId'}
          />
        </GridColumn>
      </Grid>
    </>
  );
};

export default CCPModalCommonFields;
