import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Label } from '@atlaskit/field-base';
import { useSelector } from 'react-redux';
import TextArea from '../../../shared/form/TextArea';
import iStockTake from '../../../types/stockTake/iStockTake';
import InlineEditDateTimePicker from '../../../shared/form/inlineEdit/InlineEditDateTimePicker';
import { LabelValue } from '../../../shared/styles/styles';
import { formatDate, handleNullException } from '../../../services/UtilsService';
import { iDetailUpdateOneFieldFn, iParams } from '../../../shared/UITypes/types';
import { iCategoryClassify } from '../shared/StockTake.type';
import { WAREHOUSE_DETAIL_URL } from '../../../shared/UrlMap';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_WAREHOUSES } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';
import { RootState } from '../../../redux/makeReduxStore';

const DetailInfo = ({
  detail,
  idNameMap,
  onUpdate,
  categoryClassify,
  canUpdate = true,
}: {
  detail?: iStockTake;
  idNameMap?: iParams;
  onUpdate: iDetailUpdateOneFieldFn;
  categoryClassify: iCategoryClassify;
  canUpdate?: boolean;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadWH = AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_READ, user);
  const { isFinished } = categoryClassify;
  const getLocation = (locationObjName: string) => {
    if (`${detail?.warehouseLocationId}`.trim() === '') {
      return '';
    }
    // const code = handleNullException(detail, `${locationObjName}.locationCode`);
    const path = handleNullException(idNameMap, handleNullException(detail, `${locationObjName}Id`));
    if (canReadWH) {
      return <a href={`${WAREHOUSE_DETAIL_URL}`.replace(':id', detail?.warehouseLocationId || '')}>{path}</a>;
    }
    return path;
  };
  return (
    <Page>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="For Location" />
          <LabelValue>{getLocation('warehouseLocation')}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Lost&Found Location" />
          <LabelValue>{getLocation('lostAndFoundLocation')}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Started At" />
          <LabelValue>{formatDate(handleNullException(detail, 'startedAt'), 'Do MMMM  YYYY, h:mm a')}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Finished At" />
          <LabelValue>{formatDate(handleNullException(detail, 'finishedAt'), 'Do MMMM  YYYY, h:mm a')}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <div style={{ marginTop: '16px' }}>
            <InlineEditDateTimePicker
              defaultValue={handleNullException(detail, 'scheduledAt')}
              label="Scheduled At"
              name="scheduledAt"
              timeFormat={'h:mm a'}
              dateFormat={'Do MMMM  YYYY'}
              onConfirm={onUpdate}
              isDisabled={isFinished || !canUpdate}
            />
          </div>
        </GridColumn>
        <GridColumn medium={6}>
          <div style={{ marginTop: '16px' }}>
            <TextArea
              name={'memo'}
              label="Notes"
              defaultValue={handleNullException(detail, 'memo')}
              onChange={onUpdate}
              minimumRows={4}
              debouncePeriod={500}
              isDisabled={isFinished || !canUpdate}
            />
          </div>
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default DetailInfo;
