import React, { useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import iFaultCategory from '../../../types/NCR/iFaultCategory';
import { getHeads, handleNullException } from '../../../services/UtilsService';
import { iConfigColumn, iSortOrderType } from '../../../shared/UITypes/types';
import { FAULT_CATEGORY_PAGE_NAME } from './FaultCategoryList.constant';
import LinkBtn from '../../../shared/buttons/LinkBtn';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import TransparentIconBtn from '../../../shared/buttons/TransparentIconBtn';

const getRows = (
  data: Array<iFaultCategory>,
  onDelete: (id: string) => void,
  onEdit: (id: string) => void,
  columns: Array<iConfigColumn>,
) => {
  return data.map((item: iFaultCategory) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'edit-trigger':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtn
                btnName={handleNullException(item, key)}
                onClick={() => onEdit(item.id)}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        case 'delete':
          return {
            content: (
              <TransparentIconBtn
                icon={
                  <TrashIcon label="delete" testId={'delete-trigger-icon'} />
                }
                onClick={() => onDelete(item.id)}
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: FAULT_CATEGORY_PAGE_NAME };
  });
};

const FaultCategoryListTable = ({
  isLoading,
  data,
  onEdit,
  onDelete,
  onSetSort,
  columns,
}: {
  isLoading: boolean;
  data: iFaultCategory[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onSetSort: (sortStr: string) => void;
  columns: Array<iConfigColumn>;
}) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  return (
    <DynamicTable
      head={getHeads(columns, FAULT_CATEGORY_PAGE_NAME)}
      rows={getRows(data, onDelete, onEdit, columns)}
      loadingSpinnerSize="large"
      isLoading={isLoading}
      onSort={
        //  eslint-disable-next-line
        (data: any) => {
          setSortKey(data.key);
          setSortOrder(data.sortOrder);
          onSetSort(`${data.key}:${data.sortOrder}`);
        }
      }
      sortKey={sortKey}
      sortOrder={sortOrder}
      testId={FAULT_CATEGORY_PAGE_NAME}
    />
  );
};

export default FaultCategoryListTable;
