import { getCustomerListAsyncSearch } from '../../services/ContactCompanyService';
import { iConfigColumn } from '../despatchNote/shared/DispatchNote.type';
import CouriersService from '../../services/Settings/CouriersService';
import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../../shared/constants/AsyncConstants';
import { CUSTOMER_URL, SALES_URL } from '../../shared/UrlMap';
import { SO_TYPE_NAME_INVOICE, SO_TYPE_NAME_QUOTE, SO_TYPE_NAME_SALES_ORDER } from './salesDetail/constants';

export const SALES_ALL = 'ALL';
export const SALES_QUOTES = SO_TYPE_NAME_QUOTE;
export const SALES_ORDERS = SO_TYPE_NAME_SALES_ORDER;
export const SALES_INVOICES = SO_TYPE_NAME_INVOICE;
export const PER_PAGE = 10;
export const CLONE_THIS_ORDER = 'Clone order';
export const DELETE_THIS_ORDER = 'Delete order';
export const COMPANY_TYPE_SUPPLIER = 'supplier';
export const COMPANY_TYPE_CUSTOMER = 'customer';
export const COMPANY_TYPE_SERVICE_PROVIDER = 'serviceProvider';
export const ALL = 'All';
export const SO_TABLE_NAME = 'salesOrder-table';
export const SO_TABLE_TEST_ID = 'salesOrder-table';
export const SO_LIST_TITLE = 'Sales';

export const COLUMN_NAMES = [
  'Order No.',
  'Status',
  'Type',
  'Customer',
  'Total Amt.',
  'Amt Due',
  'Shipping Addr.',
  'Billing Addr.',
  'Created',
  'Updated',
];

export const SALES_ORDER_ITEM_TABLE_COLUMNS = [
  'Product Code / SKU',
  'Description',
  'Quantity',
  'Unit Price',
  'Disc %',
  'Tax',
  'Margin',
  'Total (Inc.)',
  'Add',
  'Delete',
];

export const SALES_ORDER_ITEM_TABLE_COLUMNS_HIDE_PRICE = [
  'Product Code / SKU',
  'Description',
  'Quantity',
  'Add',
  'Delete',
];

export const JOBS_TABLE_COLUMNS = ['Job #', 'Product', 'Machine', 'Operator', 'Status', 'Scheduled'];

export const COLUMNS_AFTER = ['Operation'];

export const SO_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Order No.',
    key: 'orderNumber',
    alternativeKey: 'invoiceNumber',
    dataSource: ['id'],
    isCompulsory: true,
    isSortable: true,
    type: 'link',
    urlPrefix: SALES_URL,
    isSelected: true,
    group: '',
  },
  {
    name: 'Status',
    key: 'status',
    dataSource: ['status'],
    type: 'status',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Type',
    key: 'type.name',
    dataSource: ['type'],
    isCompulsory: true,
    isSortable: false,
    isSelected: true,
    type: 'text',
    group: '',
  },
  {
    name: 'Customer',
    key: 'customer.name',
    dataSource: ['customer.id'],
    urlPrefix: CUSTOMER_URL,
    type: 'link',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Customer Ref',
    key: 'customerRef',
    dataSource: ['customerRef'],
    type: 'text',
    isSelected: true,
    group: '',
    isSortable: true,
  },
  {
    name: 'Order Date',
    key: 'orderDate',
    alternativeKey: 'invoiceDate',
    dataSource: ['orderDate'],
    type: 'date',
    isSelected: true,
    group: '',
    isSortable: true,
  },
  {
    name: 'Total Amt.',
    key: 'totalAmt',
    dataSource: ['totalAmt'],
    type: 'money',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Amt Due',
    key: 'totalAmtDue',
    dataSource: ['totalAmtDue'],
    type: 'money',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: '# of Jobs',
    key: 'jobs',
    dataSource: ['jobs'],
    type: 'count',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Shipping Addr.',
    key: 'shippingAddress',
    dataSource: ['shippingAddress'],
    type: 'address',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Billing Addr.',
    key: 'billingAddress',
    dataSource: ['billingAddress'],
    type: 'address',
    isSelected: false,
    group: '',
    isSortable: false,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];

export const SO_ADVANCED_SEARCH_CONFIGS = [
  {
    name: 'customerId',
    label: 'Customers',
    type: 'multi',
    selected: [],
    placeholder: 'All customers...',
    promiseFn: getCustomerListAsyncSearch,
    optionLabel: 'name',
    sortBy: 'name',
    testId: 'so-advanced-search-customers',
    className: 'normal-width',
  },
  {
    name: 'orderDate>=',
    label: 'Order Date',
    type: 'date',
    selected: '',
    placeholder: 'From',
    testId: 'so-advanced-search-orderDate-from',
  },
  {
    name: 'orderDate<=',
    type: 'date',
    selected: '',
    placeholder: 'To',
    testId: 'so-advanced-search-orderDate-to',
    className: 'remove-1-gutter-before',
  },
];

export const DN_ADVANCED_SEARCH_CONFIGS = [
  {
    name: 'customerId',
    label: 'Customers',
    type: 'multi',
    selected: [],
    placeholder: 'All customers...',
    promiseFn: getCustomerListAsyncSearch,
    optionLabel: 'name',
    sortBy: 'name',
    testId: 'so-advanced-search-customers',
    className: 'normal-width',
  },
  {
    name: 'dispatchDate>=',
    label: 'Dispatch Date',
    type: 'date',
    selected: '',
    placeholder: 'From',
    testId: 'dn-advanced-search-dispatchDate-from',
  },
  {
    name: 'dispatchDate<=',
    type: 'date',
    selected: '',
    placeholder: 'To',
    testId: 'dn-advanced-search-dispatchDate-to',
    className: 'remove-1-gutter-before',
  },
  {
    name: 'courierId',
    label: 'Couriers',
    type: 'multi',
    selected: [],
    placeholder: 'All Couriers...',
    promiseFn: (params = {}) =>
      CouriersService.getCourierList({
        ...params,
        currentPage: ASYNC_SEARCH_CURRENT_PAGE,
        perPage: ASYNC_SEARCH_PER_PAGE,
      }),
    optionLabel: 'name',
    sortBy: 'name',
    testId: 'so-advanced-search-courier',
    className: 'normal-width',
  },
];
