import React, { useState, useEffect } from 'react';
// import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success';
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline';
import Button, { ButtonGroup } from '@atlaskit/button';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import { AfterTransferWrapper } from '../sharedComponents/StockTransfer.style';
import { getWarehouseDetail } from '../../../services/WarehouseService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import ExportCsv from './ExportCsv';

const AfterTransferDescription = ({
  destination,
  onRefresh,
  data,
}: {
  destination: iWarehouseLocation;
  data: Array<iItemLocationTransfer>;
  onRefresh: () => void;
}) => {
  const { path } = destination;
  const [state, setState] = useState<string>();
  useEffect(() => {
    let isCancelled = false;
    const fetchPath = async () => {
      try {
        const ids = path.split('_');
        ids.shift();
        const locationsOnPath = await Promise.all(
          ids.map(async (id: string) => getWarehouseDetail(id)),
        );
        if (isCancelled) return;
        setState(() =>
          locationsOnPath.map((l: iWarehouseLocation) => l.name).join('/'),
        );
      } catch (error) {
        apiErrorToast(error);
      }
    };
    fetchPath();
    return () => {
      isCancelled = true;
    };
  }, [path]);
  return (
    <AfterTransferWrapper>
      <div>
        <CheckCircleOutlineIcon
          label={'success'}
          primaryColor={'green'}
          size={'xlarge'}
        />
      </div>
      <div className={'title'}>
        The following items has been successfully moved to:
      </div>
      <div className={'breadcrumbs'}>{state}</div>
      <div>
        <LinkBtnUrl
          btnName={destination.locationCode}
          url={`/b/warehouse/${destination?.id}`}
        />
      </div>
      <ButtonGroup>
        <ExportCsv data={data} />
        <Button appearance={'default'} onClick={onRefresh}>
          Start a new transfer
        </Button>
      </ButtonGroup>
    </AfterTransferWrapper>
  );
};

export default AfterTransferDescription;
