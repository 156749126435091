import React from 'react';
import { useForm } from 'react-hook-form';

import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import PlusIconAfterTitle from '../../../components/settings/PlusIconAfterTitle';
import BackendPage from '../../../layout/BackendPage';
import {
  createWrappingMethod,
  deactivateWrappingMethod,
  getWrappingMethods,
  updateWrappingMethod,
} from '../../../services/Settings/WrappingMethodService';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import iWrappingMethod from '../../../types/iWrappingMethod';
import Sidebar from '../sidebar/Sidebar';
import WrappingMethodEditModal from './WrappingMethodEditModal';
import WrappingMethodListTable from './WrappingMethodListTable';

const WrappingMethodSetting = () => {
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onSubmit,
  } = useListCrudHook<iWrappingMethod>({
    getFn: getWrappingMethods,
    createFn: createWrappingMethod,
    updateFn: updateWrappingMethod,
    deleteFn: deactivateWrappingMethod,
  });

  const { control, setValue, errors, handleSubmit } = useForm();

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'Wrapping Methods'} />
      <PlusIconAfterTitle pageTitle={'Wrapping Methods'} onClick={onOpenAddModal} />
    </>
  );

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <WrappingMethodListTable
        data={state.data}
        onDelete={onDelete}
        onEdit={onOpenEditModal}
        isLoading={state.isLoading}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <WrappingMethodEditModal control={control} errors={errors} onChange={setValue} target={edit.target} />
        }
        modalHeading={edit.target ? edit.target.name : 'Create'}
        confirmBtnName={'Save'}
      />
    </BackendPage>
  );
};

export default WrappingMethodSetting;
