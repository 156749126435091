import Button from '@atlaskit/button';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Edge, Node, useEdges, useNodes } from 'react-flow-renderer';
import { FlexContainer } from '../../../shared/styles/styles';
import { iWFDiagramState } from '../../../shared/workflow/diagram/WorkflowDiagramReducer';
import SelectWithController from '../../../shared/form/hookForms/SelectWithController';
import iEntityStatus from '../../../types/status/iEntityStatus';

type iWorkflowStartStatusPanel = {
  editingNode: Node;
  state: iWFDiagramState;
};
const WorkflowStartStatusPanel = ({ editingNode, state }: iWorkflowStartStatusPanel) => {
  const { control, setValue, errors, handleSubmit } = useForm();
  const [statusMap, setStatusMap] = useState<{ [key: string]: iEntityStatus }>({});
  const [startEdge, setStartEdge] = useState<Edge | null>(null);
  const nodes = useNodes();
  const edges = useEdges();

  useEffect(() => {
    setStatusMap(
      nodes.reduce((map, node: Node) => {
        if (!('status' in node.data)) {
          return map;
        }
        return {
          ...map,
          [node.data.status.id]: node.data.status,
        };
      }, {}),
    );
  }, [nodes]);

  useEffect(() => {
    const startEdges = edges.filter(edge => edge.source === editingNode.id);
    setStartEdge(startEdges.length > 0 ? startEdges[0] : null);
  }, [edges, editingNode.id]);

  const getStatusOptions = () => {
    const statuses = Object.values(statusMap);
    return statuses.map((status: iEntityStatus) => ({
      label: status.name,
      value: status.id,
    }));
  };

  const updateTransition = (data: { [key: string]: string }) => {
    const { initStatus } = data;
    const initStatusId = `${initStatus || ''}`.trim();
    if (initStatusId === '') {
      return;
    }

    state.setEdges(
      edges.map(edge => {
        if (edge.id === startEdge?.id) {
          return { ...edge, target: initStatusId };
        }
        return edge;
      }),
    );
    state.setNodes(
      nodes.map(node => ({
        ...node,
        selected: false,
      })),
    );
  };

  return (
    <div>
      <h3>Start Status</h3>
      <div>
        <small>Start Status is when a work or any other entities been created.</small>
      </div>
      <p>Please select the initial status below to start the workflow:</p>

      <FlexContainer className={'space-below'}>
        <SelectWithController
          name={'initStatus'}
          label={'Initial Status'}
          defaultValue={startEdge?.target || ''}
          control={control}
          onChange={setValue}
          errors={errors}
          options={getStatusOptions()}
          isRequired
          isClearable
        />
      </FlexContainer>

      <FlexContainer className={'space-above space-between'}>
        <div />
        <Button
          appearance={'primary'}
          onClick={handleSubmit(updateTransition)}
          isDisabled={Object.keys(errors).length > 0}
        >
          Update
        </Button>
      </FlexContainer>
    </div>
  );
};

export default WorkflowStartStatusPanel;
