import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import CheckboxWithController from '../../../../shared/form/hookForms/CheckboxWithController';
import InputWithController from '../../../../shared/form/hookForms/InputWithController';
import { ALLOW_PARTS } from '../../shared/Warehouse.constants';

const ModalContainer = styled.div`
  min-height: 40px;
`;

type iParam = {
  isOpen?: boolean;
  onClose?: () => void;
  //    eslint-disable-next-line
  onConfirm?: (data: any) => void;
  isConfirming?: boolean;
};

const CreateWarehouse = ({
  isOpen,
  onClose,
  onConfirm,
  isConfirming,
}: iParam) => {
  const { control, setValue, getValues, errors, handleSubmit } = useForm({});
  const collectData = () => {
    const data = getValues();
    if (typeof onConfirm === 'function') {
      onConfirm(data);
    }
  };
  const getModalContent = () => (
    <>
      <InputWithController
        label={'Name'}
        name={'name'}
        onChange={setValue}
        control={control}
        errors={errors}
        testId={'input-name'}
      />
      <InputWithController
        label={'Location Code'}
        name={'locationCode'}
        onChange={setValue}
        control={control}
        testId={'input-location-code'}
      />
      <CheckboxWithController
        defaultValue={false}
        label={ALLOW_PARTS}
        name={'allowParts'}
        onChange={setValue}
        control={control}
      />
    </>
  );
  const getModal = () => (
    <Modal
      testId="create-warehouse-modal"
      actions={[
        {
          text: 'Create',
          onClick: handleSubmit(collectData),
          appearance: 'primary',
          isDisabled: Object.keys(errors).length > 0,
          testId: 'create-warehouse-btn-in-modal',
        },
        { text: 'Cancel', onClick: onClose, testId: 'cancel-btn-in-modal' },
      ]}
      onClose={onClose}
      heading={'Create a location'}
    >
      <ModalContainer className={'modal-container'}>
        {isConfirming ? (
          <Spinner testId="spinner-in-modal" size={'small'} />
        ) : (
          getModalContent()
        )}
      </ModalContainer>
    </Modal>
  );
  if (!isOpen) return null;
  return <ModalTransition>{isOpen && getModal()}</ModalTransition>;
};

export default CreateWarehouse;
