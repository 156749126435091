import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import DeleteWithReType from '../../shared/deleteWithReType/SelfManagedConfirmWithReType';
import { formatDate } from '../../services/UtilsService';

export const getHeads = (columns: Array<string>, tableName: string) => {
  const cells = columns.map((column: string) => {
    switch (column.toUpperCase()) {
      case 'SKU':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
          content: column,
          isSortable: true,
        };
      case 'NAME':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
          content: column,
          isSortable: true,
        };
      case 'MACHINE NAME':
        return {
          key: 'name',
          testId: `${tableName}-table-column-${column}`,
          content: column,
          isSortable: true,
        };
      case 'ENTITY NAME':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
          content: column,
          isSortable: true,
        };
      case 'DEFAULT WAREHOUSE':
        return {
          key: 'warehouseLocation.name',
          testId: `${tableName}-table-column-${column}`,
          content: column,
          isSortable: true,
        };
      case 'DATE TIME':
        return {
          key: 'createdAt',
          testId: `${tableName}-table-column-${column}`,
          content: column,
          isSortable: true,
        };
      case 'OPERATION':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'ADD':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'MARGIN':
        return {
          key: column,
          content: <div className={'total-price'}>{column}</div>,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'DELETE':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'TOTAL (INC.)':
        return {
          key: column,
          content: <div className={'total-price'}>{column}</div>,
          testId: `${tableName}-table-column-${column}`,
        };
      default:
        return {
          key: column,
          content: column,
          testId: `${tableName}-table-column-${column}`,
        };
    }
  });
  return { cells };
};

export const getCreatedOrUpdatedAt = ({ operatedAt, operator }: { operatedAt?: string; operator?: string }) => {
  return (
    <>
      {operator && <div data-testid={'record-operator'}>{operator}</div>}
      {operatedAt && <div data-testid={'record-operatedAt'}>@{formatDate(operatedAt)}</div>}
    </>
  );
};

export const getDeleteBtn = ({
  id,
  answer = 'N/A',
  onClick,
}: {
  id: string;
  answer?: string;
  onClick: (id: string) => void;
}) => {
  return (
    <DeleteWithReType answer={answer} onConfirm={() => onClick(id)}>
      <TrashIcon label="delete" testId={'delete-trigger-icon'} />
    </DeleteWithReType>
  );
};
