import React from 'react';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import BackendPage from '../../../layout/BackendPage';
import EntityStatusTypeService from '../../../services/EntityStatusTypeService';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import { PageTitle } from '../../../shared/styles/styles';
import iEntityStatusType from '../../../types/status/iEntityStatusType';
import Sidebar from '../sidebar/Sidebar';
import EntityStatusTypeListTable from './EntityStatusTypeListTable';

const EntityStatusTypeSetting = () => {
  const { state } = useListCrudHook<iEntityStatusType>({
    getFn: EntityStatusTypeService.getEntityStatusTypes,
    createFn: EntityStatusTypeService.createEntityStatusType,
    updateFn: EntityStatusTypeService.updateEntityStatusType,
  });

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'Statuses'} />
      <PageTitle>Statuses</PageTitle>
    </>
  );

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <EntityStatusTypeListTable data={state.data} isLoading={state.isLoading} />
    </BackendPage>
  );
};

export default EntityStatusTypeSetting;
