import React from 'react';
import OfficeBuildingFilledIcon from '@atlaskit/icon/glyph/office-building-filled';
// import SettingsIcon from '@atlaskit/icon/glyph/settings';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import ImageResizeIcon from '@atlaskit/icon/glyph/image-resize';
import BillingIcon from '@atlaskit/icon/glyph/billing';
import PortfolioIcon from '@atlaskit/icon/glyph/portfolio';
import RecentIcon from '@atlaskit/icon/glyph/recent';
import SendIcon from '@atlaskit/icon/glyph/send';
import BacklogIcon from '@atlaskit/icon/glyph/backlog';
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';
import EditorMediaWrapLeftIcon from '@atlaskit/icon/glyph/editor/media-wrap-left';
import UploadIcon from '@atlaskit/icon/glyph/upload';
import DepartmentIcon from '@atlaskit/icon/glyph/department';
import CreditcardFilledIcon from '@atlaskit/icon/glyph/creditcard-filled';
import MediaServicesPdfIcon from '@atlaskit/icon/glyph/media-services/pdf';
import AppAccessIcon from '@atlaskit/icon/glyph/app-access';

import {
  // ButtonItem,
  HeadingItem,
  LinkItem,
  NavigationContent,
  // NavigationFooter,
  NavigationHeader,
  SideNavigation,
} from '@atlaskit/side-navigation';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import SidebarHeader from './Header';
import {
  SETTINGS_COMPANY_URL,
  SETTINGS_MACHINES_URL,
  SETTINGS_TAX_URL,
  SETTINGS_UNIT_URL,
  SETTINGS_USERS_URL,
  SETTINGS_SHIFTS_URL,
  SETTINGS_COURIERS_URL,
  SETTINGS_PALLETS_URL,
  SETTINGS_INTEGRATIONS_URL,
  SETTINGS_ENTITY_STATUS_TYPES_URL,
  SETTINGS_WRAPPINGS_URL,
  SETTINGS_DATA_IMPORT_URL,
  BILLING_HOME_URL,
  SETTINGS_PRINTING_URL,
  SETTINGS_ROLES_URL,
} from '../../../shared/UrlMap';
import { isEnterpriseMode } from '../../../services/UtilsService';

const NavContentSection = styled.section`
  padding-left: 1rem;

  &.heading {
    color: #6b778c;
    font-size: 0.7rem;
  }
`;

const Sidebar = () => {
  const { pathname } = useLocation();

  return (
    <SideNavigation label="settings-nav" testId="settings-nav">
      <NavigationHeader>
        <SidebarHeader />
      </NavigationHeader>
      <NavigationContent>
        <NavContentSection data-testid={'nav-list'}>
          <LinkItem
            href={SETTINGS_COMPANY_URL}
            iconBefore={<OfficeBuildingFilledIcon label="company-profile" />}
            isSelected={SETTINGS_COMPANY_URL === pathname}
            testId={`lint-item-${SETTINGS_COMPANY_URL}`}
          >
            Company Profile
          </LinkItem>
          <LinkItem
            href={SETTINGS_USERS_URL}
            iconBefore={<PeopleIcon label="users" />}
            isSelected={SETTINGS_USERS_URL === pathname}
            testId={`lint-item-${SETTINGS_USERS_URL}`}
          >
            Users
          </LinkItem>
          <LinkItem
            href={SETTINGS_ROLES_URL}
            iconBefore={<AppAccessIcon label="Roles" />}
            isSelected={SETTINGS_ROLES_URL === pathname}
            testId={`lint-item-${SETTINGS_ROLES_URL}`}
          >
            Roles
          </LinkItem>

          {isEnterpriseMode() === true ? null : (
            <LinkItem
              href={BILLING_HOME_URL}
              iconBefore={<CreditcardFilledIcon label="billing" />}
              isSelected={BILLING_HOME_URL === pathname}
              testId={`lint-item-${BILLING_HOME_URL}`}
            >
              Billing
            </LinkItem>
          )}

          <LinkItem
            href={SETTINGS_INTEGRATIONS_URL}
            iconBefore={<AppSwitcherIcon label="integrations" />}
            isSelected={SETTINGS_INTEGRATIONS_URL === pathname}
            testId={`lint-item-${SETTINGS_INTEGRATIONS_URL}`}
          >
            Integrations
          </LinkItem>
          <HeadingItem>SYSTEM</HeadingItem>
          <LinkItem
            href={SETTINGS_COURIERS_URL}
            iconBefore={<SendIcon label="couriers" />}
            isSelected={SETTINGS_COURIERS_URL === pathname}
            testId={`lint-item-${SETTINGS_COURIERS_URL}`}
          >
            Couriers
          </LinkItem>

          <LinkItem
            href={SETTINGS_MACHINES_URL}
            iconBefore={<PortfolioIcon label="machines" />}
            isSelected={SETTINGS_MACHINES_URL === pathname}
            testId={`lint-item-${SETTINGS_MACHINES_URL}`}
          >
            Machines
          </LinkItem>
          <LinkItem
            href={SETTINGS_PALLETS_URL}
            iconBefore={<BacklogIcon label="pallets" />}
            isSelected={SETTINGS_PALLETS_URL === pathname}
            testId={`lint-item-${SETTINGS_PALLETS_URL}`}
          >
            Pallet Systems
          </LinkItem>
          <LinkItem
            href={SETTINGS_PRINTING_URL}
            iconBefore={<MediaServicesPdfIcon label="pdf/printing" />}
            isSelected={SETTINGS_PRINTING_URL === pathname}
            testId={`lint-item-${SETTINGS_PRINTING_URL}`}
          >
            PDF / Printing
          </LinkItem>
          <LinkItem
            href={SETTINGS_SHIFTS_URL}
            iconBefore={<RecentIcon label="shifts" />}
            isSelected={SETTINGS_SHIFTS_URL === pathname}
            testId={`lint-item-${SETTINGS_SHIFTS_URL}`}
          >
            Shifts
          </LinkItem>
          <LinkItem
            href={SETTINGS_ENTITY_STATUS_TYPES_URL}
            iconBefore={<DepartmentIcon label="entityStatusTypes" />}
            isSelected={SETTINGS_ENTITY_STATUS_TYPES_URL === pathname}
            testId={`lint-item-${SETTINGS_ENTITY_STATUS_TYPES_URL}`}
          >
            Statuses
          </LinkItem>
          <LinkItem
            href={SETTINGS_TAX_URL}
            iconBefore={<BillingIcon label="taxRates" />}
            isSelected={SETTINGS_TAX_URL === pathname}
            testId={`lint-item-${SETTINGS_TAX_URL}`}
          >
            Tax rates
          </LinkItem>
          <LinkItem
            href={SETTINGS_UNIT_URL}
            iconBefore={<ImageResizeIcon label="units-of-measure" />}
            isSelected={SETTINGS_UNIT_URL === pathname}
            testId={`lint-item-${SETTINGS_UNIT_URL}`}
          >
            Units of measure
          </LinkItem>
          <LinkItem
            href={SETTINGS_WRAPPINGS_URL}
            iconBefore={<EditorMediaWrapLeftIcon label="wrappings" />}
            isSelected={SETTINGS_WRAPPINGS_URL === pathname}
            testId={`lint-item-${SETTINGS_WRAPPINGS_URL}`}
          >
            Wrapping Methods
          </LinkItem>
          <HeadingItem>DATA</HeadingItem>
          <LinkItem
            href={SETTINGS_DATA_IMPORT_URL}
            iconBefore={<UploadIcon label="importData" />}
            isSelected={SETTINGS_DATA_IMPORT_URL === pathname}
            testId={`lint-item-${SETTINGS_DATA_IMPORT_URL}`}
          >
            Data Imports
          </LinkItem>
        </NavContentSection>
      </NavigationContent>
    </SideNavigation>
  );
};

export default Sidebar;
