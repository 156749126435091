import { LoadingButton } from '@atlaskit/button';
import printJS from 'print-js';
import React, { ReactNode, useState } from 'react';
import { apiErrorToast } from '../toast/Toast';

const GeneralPrintBtn = ({
  serviceFunc,
  children,
  shouldWarningModalOpen = false,
  onWarning,
  isDisabled = false,
}: {
  serviceFunc: () => Promise<string>;
  children: ReactNode;
  shouldWarningModalOpen?: boolean;
  onWarning?: () => void;
  isDisabled?: boolean;
}) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const printOrder = () => {
    setIsPrinting(true);
    serviceFunc()
      .then(pdfUrl => {
        setIsPrinting(false);
        printJS({ printable: pdfUrl, type: 'pdf', showModal: true });
      })
      .catch(apiErrorToast)
      .finally(() => {
        setIsPrinting(false);
      });
  };
  return (
    <LoadingButton
      className={'popup-item'}
      onClick={shouldWarningModalOpen ? onWarning : () => printOrder()}
      isLoading={isPrinting}
      testId="print-order"
      isSelected
      isDisabled={isDisabled}
    >
      {children}
    </LoadingButton>
  );
};

export default GeneralPrintBtn;
