import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getFaultCategories = (config?: iParams) => {
  return AppService.get('/faultCategory', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const createFaultCategory = (postData: iKeyValuePairs) => {
  return AppService.post('/faultCategory', postData).then(({ data }) => data);
};

export const updateFaultCategory = (
  id: string,
  updateContent: iKeyValuePairs,
) => {
  return AppService.put(`/faultCategory/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const deleteFaultCategory = (id: string) => {
  return AppService.delete(`/faultCategory/${id}`).then(({ data }) => data);
};

export default {
  getFaultCategories,
  createFaultCategory,
  updateFaultCategory,
  deleteFaultCategory,
};
