import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import SectionMessage from '@atlaskit/section-message';
import AsyncSearchWithController from '../../../../shared/form/hookForms/AsyncSearchWithController';
import { getWarehouseListAsyncSearch } from '../../../../services/WarehouseService';
import {
  SpaceVerticalContainer,
  SpaceWrapper,
} from '../../../../shared/styles/styles';
import { MOVE_WARNING_MESSAGE } from '../../shared/Warehouse.constants';
import CheckboxWithController from '../../../../shared/form/hookForms/CheckboxWithController';

const MoveModalBody = ({
  control,
  errors,
  onChange,
  watch,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  //    eslint-disable-next-line
  watch: (names?: string | string[]) => any;
}) => {
  return (
    <SpaceWrapper className={'space-bottom-5'}>
      <SectionMessage appearance="warning">
        <p>{MOVE_WARNING_MESSAGE}</p>
      </SectionMessage>
      <SpaceVerticalContainer className={'one'} />
      <CheckboxWithController
        label={'Move to the root'}
        name={'asRoot'}
        defaultValue={false}
        control={control}
        onChange={onChange}
        errors={errors}
      />
      {!watch('asRoot') && (
        <AsyncSearchWithController
          name={'toLocation'}
          label={'Move under'}
          control={control}
          onChange={onChange}
          errors={errors}
          isRequired
          testId={'moveTo'}
          optionLabel={['name', 'locationCode']}
          promiseFn={(keyword: string) =>
            getWarehouseListAsyncSearch({
              like: `name:${keyword},locationCode:${keyword}`,
            })
          }
          placeholder={'Search by locationCode/name'}
        />
      )}
    </SpaceWrapper>
  );
};

export default MoveModalBody;
