import { getProductListAsyncSearch } from '../../services/product/ProductService';
import { getWarehouseListAsyncSearch } from '../../services/WarehouseService';

export const ITEMLOCATION_ADVANCED_SEARCH_CONFIG = [
  {
    name: 'productId',
    label: 'Products',
    type: 'multi',
    selected: [],
    placeholder: 'All products...',
    promiseFn: getProductListAsyncSearch,
    optionLabel: 'productCode',
    sortBy: 'productCode',
    testId: 'itemLocation-advanced-search-product',
  },
  {
    name: 'warehouseLocationId',
    label: 'Warehouse Locations',
    type: 'multi',
    selected: [],
    placeholder: 'All warehouse locations...',
    promiseFn: getWarehouseListAsyncSearch,
    optionLabel: 'name',
    sortBy: 'name',
    testId: 'itemLocation-advanced-search-warehouseLocation',
  },
  {
    name: 'reference',
    label: 'Batch No.',
    type: 'multi-text',
    selected: [],
    placeholder: 'Type batch # and press enter...',
    testId: 'itemLocation-advanced-search-batchNumber',
  },
];
