import React from 'react';
import Checkbox from '@atlaskit/checkbox';
import iJob from '../../../types/job/iJob';
import iProduct from '../../../types/product/iProduct';
import iStockForecast from '../../../types/product/iStockForecast';
import { iConfigColumn } from '../../despatchNote/shared/DispatchNote.type';
import SummaryPOItems from './SummaryPOItems';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import { JOB_URL } from '../../../shared/UrlMap';
import { handleNullException, intersperse } from '../../../services/UtilsService';
import MathHelper from '../../../services/MathHelper';

export const getRows = ({
  stocks,
  date,
  columns,
  renderSpecialRows,
}: {
  stocks: Array<iStockForecast>;
  date: string;
  columns: Array<iConfigColumn>;
  renderSpecialRows: (item: iProduct, column: iConfigColumn) => JSX.Element;
}) => {
  return stocks.map((item: iStockForecast) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'link-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                <LinkBtnUrl
                  btnName={handleNullException(item, key)}
                  url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
                />
              </ComposeSecondaryText>
            ),
          };
        case 'related-elements':
          return {
            content: intersperse(
              item.jobs
                .sort((a: iJob, b: iJob) =>
                  a.jobNumber.localeCompare(b.jobNumber, 'en', {
                    sensitivity: 'base',
                  }),
                )
                .map((job: iJob, index: number) => (
                  <LinkBtnUrl
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}-${job.id}`}
                    url={`${JOB_URL}/${job.id}`}
                    btnName={handleNullException(job, 'jobNumber')}
                  />
                )),
              ', ',
            ),
          };
        case 'number-postfix':
          return {
            content: `${handleNullException(item, key)} ${handleNullException(item, dataSource[0])}`,
          };
        case 'number-minue-postfix': {
          const minus = MathHelper.sub(
            Number(handleNullException(item, dataSource[0])),
            Number(handleNullException(item, dataSource[1])),
          );
          return {
            content: minus > 0 ? `${minus} ${handleNullException(item, dataSource[2])}` : '',
          };
        }
        case 'sum-purchaseOrderItems':
          return {
            content: <SummaryPOItems date={date} product={item.product} />,
          };
        default:
          return { content: renderSpecialRows(item.product, column) };
      }
    });
    return { cells };
  });
};

export const getHeads = ({
  columns,
  tableName,
  renderSpecialHeads,
}: {
  columns: Array<iConfigColumn>;
  tableName: string;
  renderSpecialHeads: () => JSX.Element;
}) => {
  const cells = columns.map((column: iConfigColumn) => {
    switch (column.type) {
      case 'operation':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      case 'delete':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      case 'rowCheckbox':
        return { key: column.key, content: renderSpecialHeads() };
      default:
        return {
          key: column.key,
          content: column.name,
          testId: `${tableName}-column-${column.key}`,
          isSortable: !!column.isSortable,
        };
    }
  });
  return { cells };
};

export const renderSpecialHeads = ({ isSelected, onSelect }: { isSelected: boolean; onSelect: () => void }) => (
  <Checkbox isChecked={isSelected} onClick={onSelect} className={'align-top'} testId={'align-top'} />
);

export const renderSpecialRows = <T extends { id: string }>({
  selected,
  onSelect,
}: {
  selected: Array<T>;
  onSelect: (select: T) => void;
}) => (item: T, column: iConfigColumn) => {
  const { type } = column;
  switch (type) {
    case 'rowCheckbox':
      return (
        <Checkbox
          isChecked={!!selected.find((selectedItem: T) => selectedItem.id === item.id)}
          onClick={() => onSelect(item)}
        />
      );
    default:
      return <div />;
  }
};
