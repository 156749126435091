import React from 'react';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import TextEllipsisTooltip from '../../../shared/text/TextEllipsisTooltip';
import { iSum } from './Dashboard.types';
import { getSummary } from './Dashboard.utils';

const Summary = ({
  children,
  data,
}: {
  children: React.ReactNode;
  data: Array<iSum>;
}) => {
  return (
    <ComposeSecondaryText
      secondaryText={<TextEllipsisTooltip content={getSummary(data)} />}
    >
      {children}
    </ComposeSecondaryText>
  );
};
export default Summary;
