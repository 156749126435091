/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import Collapse from '../../../../../shared/collapse/Collapse';
import { STEP3_TEXT } from '../../shared/DataImport.constants';
import { iKeyValuePairs } from '../../../../../shared/UITypes/types';
import HandleData from '../step3/HandleData';
import { getUnits } from '../../../../../services/Settings/UnitMeasurementService';
import iUnitOfMeasurements from '../../../../../types/iUnitOfMeasurement';
import { iTemplateColumn } from '../../shared/DataImport.types';

type iState = {
  isLoading: boolean;
  loadedPrefills: any;
};
const initialState: iState = {
  isLoading: true,
  loadedPrefills: {},
};
const StepSubmit = ({
  detailPageName,
  data,
  prefills,
  columns,
  children,
}: {
  detailPageName: string;
  data: any[][];
  prefills: iKeyValuePairs;
  columns: Array<iTemplateColumn>;
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const unitMeasurements: Array<iUnitOfMeasurements> = await getUnits();
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          loadedPrefills: { unitMeasurements },
          isLoading: false,
        }));
      } catch (error) {
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);
  //  eslint-disable-
  const [, ...others] = data;
  return (
    <Collapse text={STEP3_TEXT} defaultOpen>
      {state.isLoading ? (
        <Spinner />
      ) : (
        <HandleData
          data={others}
          detailPageName={detailPageName}
          prefills={{ ...prefills, ...state.loadedPrefills }}
          columns={columns}
        >
          {children}
        </HandleData>
      )}
    </Collapse>
  );
};

export default StepSubmit;
