import React from 'react';
import { DataInfoWrapper } from './DataImport.style';

const DataInfo = ({
  lozenge,
  desc,
  appearance = 'default',
}: {
  lozenge: number | string;
  desc: string;
  appearance?: string;
}) => {
  return (
    <DataInfoWrapper className={appearance}>
      <span className={'lozenge'}>{lozenge}</span>
      <span className={'desc'}>{desc} &nbsp;</span>
    </DataInfoWrapper>
  );
};

export default DataInfo;
