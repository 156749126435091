import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import { AsyncSelect } from '@atlaskit/select';
import CustomizedLabel from './CustomizedLabel';
import { ErrorMsg } from '../../styles/styles';
import { mapLabelValuePairObject } from '../../../pages/sales/utilities';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';

const SelectWrapper = styled.div`
  min-width: 200px;
  &.min-width-120 {
    min-width: 120px;
  }
  .validation-error > div {
    border-color: #de350b;
  }
`;

type iOption = {
  label: string | JSX.Element;
  //    eslint-disable-next-line
  value: any;
};

const AsyncSearchWithController = ({
  name,
  label,
  placeholder,
  control,
  onChange,
  defaultValue,
  testId = 'asyncSelect-react-hook-form',
  errors,
  className,
  promiseFn,
  optionLabel,
  isRequired = false,
  isDisabled = false,
  externalValidate,
  noOptionsMessage = 'Seems no result could be found. Please try with another keyword search.',
  isClearable = true,
}: {
  name: string;
  label?: string;
  placeholder?: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  defaultValue?: any;
  testId?: string;
  isRequired?: boolean;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  valid?: boolean;
  className?: string;
  //    eslint-disable-next-line
  promiseFn: (keyword: string) => Promise<any>;
  optionLabel: string[];
  isDisabled?: boolean;
  //    eslint-disable-next-line
  externalValidate?: (newValue: any) => any;
  noOptionsMessage?: string;
  isClearable?: boolean;
}) => {
  const [value, setValue] = useState<iOption>();
  const isFirstRender = useIsFirstRender();

  useEffect(
    () => {
      if (typeof defaultValue === 'undefined' || defaultValue === null) {
        return;
      }
      const mapped = mapLabelValuePairObject([defaultValue], optionLabel);

      if (mapped && mapped.length > 0) {
        setValue(mapLabelValuePairObject([defaultValue], optionLabel)[0]);
        // eslint-disable-next-line no-unused-expressions
        isFirstRender
          ? onChange(name, mapped[0].value)
          : onChange(name, mapped[0].value, { shouldValidate: true });
      }
    },
    //  eslint-disable-next-line
    [JSON.stringify(defaultValue)],
  );

  const promiseOptions = (
    inputValue: string,
    callback: (options: iOption[]) => void,
  ) => {
    promiseFn(inputValue).then(({ data: items }) => {
      const mappedOptions = mapLabelValuePairObject(items, optionLabel);
      if (mappedOptions) {
        callback(mappedOptions);
      }
    });
  };

  const debouncedOptions = _.debounce(promiseOptions, 700);

  const handleValueChange = (
    //  eslint-disable-next-line
    selected: any,
    // actionMeta: iActionTypes,
  ) => {
    if (selected) {
      setValue(selected);
      onChange(name, selected.value, { shouldValidate: true });
    } else {
      setValue(undefined);
      onChange(name, null, { shouldValidate: true });
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: isRequired, message: 'must select an element' },
        validate: newValue =>
          typeof externalValidate === 'function'
            ? externalValidate(newValue)
            : true,
      }}
      defaultValue={defaultValue || null}
      render={ctrlProps => (
        <SelectWrapper className={className}>
          <CustomizedLabel
            label={label}
            htmlFor={testId}
            isRequired={isRequired}
          />
          <AsyncSelect
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...ctrlProps}
            isClearable={isClearable}
            loadOptions={debouncedOptions}
            // eslint-disable-next-line
            onChange={(selected: any) => handleValueChange(selected)}
            value={value}
            placeholder={placeholder}
            classNamePrefix={testId}
            className={`${testId} async-search-react-hook-form ${
              _.get(errors, name) && 'validation-error'
            }`}
            isDisabled={isDisabled}
            noOptionsMessage={() => noOptionsMessage}
          />
          {_.get(errors, name) && (
            <ErrorMsg>{_.get(errors, name).message}</ErrorMsg>
          )}
        </SelectWrapper>
      )}
    />
  );
};

export default AsyncSearchWithController;
