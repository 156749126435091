import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import CreditcardIcon from '@atlaskit/icon/glyph/creditcard';
import SectionMessage from '@atlaskit/section-message';
import Stripe from '@stripe/stripe-js';
import { SpaceWrapper } from '../../../../shared/styles/styles';
import BillingMethodSetupPopupBtn from './BillingMethodSetupPopupBtn';
import BillingAccountService from '../../../../services/billing/BillingAccountService';
import StripeDisclaimer from './StripeDisclaimer';

const PaymentWrapper = styled.div`
  .flex-row {
    display: flex;
    .title {
      width: 15%;
      padding-top: 7px;
    }
  }
  .payment-method-wrapper {
    background-color: #f9f9f9;
    padding: 16px;
  }
  .payment-method {
    flex: auto;
    padding: 4px 8px;
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      > div {
        padding: 0 8px;
      }
    }
    .brand {
      display: flex;
      align-items: center;
    }
  }
`;

const BillingPaymentInfo = () => {
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    let isCanceled = false;
    if (loading !== true) {
      return undefined;
    }

    const getData = () => {
      BillingAccountService.getPaymentMethods().then(resp => {
        if (isCanceled === true) {
          return;
        }
        setLoading(false);
        setPaymentMethods(resp.data);
      });
    };
    getData();
    return () => {
      isCanceled = true;
    };
  }, [loading]);

  const getContent = () => {
    if (loading === true) {
      return <Spinner />;
    }
    if (paymentMethods.length === 0) {
      return (
        <BillingMethodSetupPopupBtn savedFn={() => setLoading(true)}>Add a payment method</BillingMethodSetupPopupBtn>
      );
    }
    return paymentMethods.splice(0, 1).map((paymentMethod: Stripe.PaymentMethod) => {
      return (
        <div
          className={'flex-row payment-method-wrapper'}
          key={paymentMethod.id}
          data-testid={`payment-method-${paymentMethod.id}`}
        >
          <div className={'title'}>Payment Method:</div>
          <div className={'payment-method'} data-testid={'payment-method'}>
            <div>
              <div className={'brand'}>
                <CreditcardIcon label={paymentMethod.card?.brand || ''} />
                {paymentMethod.card?.brand}
              </div>
              <div className={'number'}>****{paymentMethod.card?.last4}</div>
              <div className={'exp'}>
                Expires: {paymentMethod.card?.exp_month}/{paymentMethod.card?.exp_year}
              </div>
            </div>
            <div className={'actions'} data-testid={'actions'}>
              <BillingMethodSetupPopupBtn savedFn={() => setLoading(true)}>Edit</BillingMethodSetupPopupBtn>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <PaymentWrapper>
      <SpaceWrapper className={'space-bottom'} data-testid={'title'}>
        <h2>Payment Information:</h2>
      </SpaceWrapper>
      <SpaceWrapper className={'space-bottom'} data-testid={'disclaimer'}>
        <SectionMessage appearance={'info'}>
          <StripeDisclaimer />
        </SectionMessage>
      </SpaceWrapper>
      {getContent()}
    </PaymentWrapper>
  );
};

export default BillingPaymentInfo;
