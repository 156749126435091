import styled from 'styled-components';
import { AO_DETAIL_PAGE_NAME } from './AO.constants';

export const Wrapper = styled.div``;

export const AOItemsTableWrapper = styled.div`
  th {
    padding-left: 4px;
    padding-right: 4px;
  }
  th[data-testid='${AO_DETAIL_PAGE_NAME}-column-product.productCode--head--cell'] {
    width: 10rem;
    min-width: 10rem;
  }
  th[data-testid='${AO_DETAIL_PAGE_NAME}-column-qty--head--cell'],
  th[data-testid='${AO_DETAIL_PAGE_NAME}-column-confirmedQty--head--cell'] {
    width: 16rem;
    min-width: 16rem;
  }
  th[data-testid='${AO_DETAIL_PAGE_NAME}-column-purchasedQty--head--cell'] {
    width: 12rem;
    min-width: 12rem;
  }
  th[data-testid='${AO_DETAIL_PAGE_NAME}-column-unitPrice--head--cell'] {
    width: 8rem;
    min-width: 8rem;
  }
  th[data-testid='${AO_DETAIL_PAGE_NAME}-column-discountPercentage--head--cell'] {
    width: 5rem;
    min-width: 5rem;
  }
  th[data-testid='${AO_DETAIL_PAGE_NAME}-column-totalPrice--head--cell'] {
    width: 6rem;
    min-width: 6rem;
  }
  th[data-testid='${AO_DETAIL_PAGE_NAME}-column-taxRateId--head--cell'] {
    width: 10rem;
    min-width: 6rem;
  }
  th[data-testid='${AO_DETAIL_PAGE_NAME}-column-delete--head--cell'] {
    width: 3rem;
    min-width: 2rem;
  }
`;
