export const BOOLEANS: Array<string> = ['True', 'False'];
export const isRequiredValidation = (name: string) => ({
  validation: (item: string | number) => !!item,
  errorMessage: `${name} is required`,
});

export const isNumberValidation = (name: string) => ({
  validation: (item: string | number) => !item || !Number.isNaN(Number(item)),
  errorMessage: `${name} must be a number`,
});

export const isSelectValidation = (options: Array<string>, name: string) => ({
  validation: (item: string | number) =>
    !item ||
    !!options.find(
      (option: string) =>
        option.toUpperCase() === item.toString().toUpperCase(),
    ),
  errorMessage: `${name} must be ${options.join('/')}`,
});

export const isBooleanValidation = (name: string) =>
  isSelectValidation(BOOLEANS, name);

export const isRequiredNumberValidation = (name: string) => ({
  validation: (item: string | number) =>
    !!(item && !Number.isNaN(Number(item))),
  errorMessage: `${name} is required and must be a number`,
});
