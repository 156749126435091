import DynamicTable from '@atlaskit/dynamic-table';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import React from 'react';
import {
  getHeads,
  handleNullException,
} from '../../../../../services/UtilsService';
import { FlexContainer } from '../../../../../shared/styles/styles';
import ComposeSecondaryText from '../../../../../shared/text/ComposeSecondaryText';
import { iConfigColumn } from '../../../../../shared/UITypes/types';
import iStockTakeItem from '../../../../../types/stockTake/iStockTakeItem';
import { FINISH_PREVIEW_TABLE_COLUMNS } from '../../../shared/StockTake.constant';

const getRows = (
  data: Array<iStockTakeItem>,
  columns: Array<iConfigColumn>,
) => {
  return data.map((item: iStockTakeItem) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'text-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText
                secondaryText={handleNullException(item, dataSource[0])}
              >
                {handleNullException(item, key)}
              </ComposeSecondaryText>
            ),
          };
        case 'text-arrow-text':
          return {
            key: handleNullException(item, key),
            content: (
              <FlexContainer>
                <div>{handleNullException(item, dataSource[0])}</div>
                <ArrowRightIcon label={'arrow-right'} />
                <div>{handleNullException(item, key)}</div>
              </FlexContainer>
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};
const FinishReviewTable = ({ data }: { data: Array<iStockTakeItem> }) => {
  return (
    <DynamicTable
      head={getHeads(FINISH_PREVIEW_TABLE_COLUMNS, 'finish-preview-table')}
      rows={getRows(data, FINISH_PREVIEW_TABLE_COLUMNS)}
    />
  );
};

export default FinishReviewTable;
