import { iConfigColumn } from '../../../shared/UITypes/types';
import {
  CUSTOMER_URL,
  JOB_URL,
  PRODUCTS_URL,
  SALES_URL,
} from '../../../shared/UrlMap';

export const ALL = 'All';
export const NCR_LIST_TITLE = 'NCRs';
export const NCR_TABLE_NAME = 'NCR_LIST';
export const NCR_TABLE_TEST_ID = 'NCR_LIST';

export const NCR_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'NCR #',
    key: 'issueNumber',
    dataSource: ['id'],
    isCompulsory: true,
    isSortable: true,
    type: 'link',
    urlPrefix: '/b/NCR',
    isSelected: true,
    group: '',
  },
  {
    name: 'Status',
    key: 'status',
    dataSource: ['status'],
    type: 'status',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Customer NCR Ref',
    key: 'customerReference',
    dataSource: [''],
    isSortable: false,
    type: 'text',
    group: '',
  },
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['productId', 'product.name'],
    isSortable: false,
    type: 'link-two-layer',
    urlPrefix: PRODUCTS_URL,
    isSelected: true,
    group: '',
  },
  {
    name: 'Customer',
    key: 'customer.name',
    dataSource: ['customer.id'],
    urlPrefix: CUSTOMER_URL,
    type: 'link',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Job',
    key: 'job.jobNumber',
    dataSource: ['job.id'],
    isSortable: false,
    type: 'link',
    urlPrefix: JOB_URL,
    isSelected: true,
    group: '',
  },
  {
    name: 'Sales Order',
    key: 'job.salesOrder.orderNumber',
    dataSource: ['job.salesOrder.id'],
    isSortable: false,
    type: 'link',
    urlPrefix: SALES_URL,
    isSelected: true,
    group: '',
  },
  {
    name: 'Customer Order Ref',
    key: 'customerOrderReference',
    dataSource: [''],
    isSortable: false,
    type: 'text',
    group: '',
  },
  {
    name: 'Issue',
    key: 'problem',
    dataSource: ['problem'],
    isSortable: false,
    type: 'text',
    isSelected: true,
    group: '',
  },

  {
    name: 'Machine',
    key: 'job.machine.name',
    dataSource: ['job.machine.name'],
    isSortable: false,
    type: 'text',
    group: '',
  },
  {
    name: 'Reporter',
    key: 'reporter',
    dataSource: ['reporter'],
    isSortable: false,
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Reported Date',
    key: 'reportedDate',
    dataSource: ['reportedDate'],
    isSortable: false,
    type: 'date',
    isSelected: true,
    group: '',
  },
  {
    name: 'Due Date',
    key: 'dueDate',
    dataSource: ['dueDate'],
    isSortable: false,
    type: 'date',
    isSelected: true,
    group: '',
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];
