import { iConfigColumn } from '../../shared/UITypes/types';

export const ATTRIBUTE_LIST_COLUMNS = [
  'Attribute Name',
  'Attribute Description',
  'Type',
  'Options',
  'Sort Order',
  'Operation',
];

export const LIST_PAGE_NAME = 'Product Attribute Sets';
export const PRODUCT_ATTRIBUTES = 'Product Attributes';

export const PRODUCT_ATTRIBUTE_LIST_TITLE = 'PRODUCT Attributes';
export const PRODUCT_ATTRIBUTE_TABLE_NAME = 'PRODUCT_ATTRIBUTE_LIST';
export const PRODUCT_ATTRIBUTE_TABLE_TEST_ID = 'PRODUCT_ATTRIBUTE_LIST';

export const PRODUCT_ATTRIBUTE_COLUMNS: Array<iConfigColumn> = [
  {
    name: '',
    key: 'dragHandlerIcon',
    dataSource: [],
    type: 'icon',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
  {
    name: 'Name',
    key: 'name',
    dataSource: ['id'],
    isCompulsory: true,
    isSortable: true,
    type: 'modalLink',
    isSelected: true,
    group: '',
  },
  {
    name: 'Description',
    key: 'description',
    dataSource: ['description'],
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Type',
    key: 'type',
    dataSource: ['type'],
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Options',
    key: 'options',
    dataSource: ['options'],
    type: 'multi-text-input',
    isSelected: true,
    group: '',
  },
  {
    name: 'Sort Order',
    key: 'sortOrder',
    dataSource: ['sortOrder'],
    type: 'sortOrder',
    isSortable: true,
    isSelected: true,
    group: '',
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
    isSelected: true,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
    isSelected: true,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];
