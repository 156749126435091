/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styled from 'styled-components';
import { GridColumn } from '@atlaskit/page';

import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import _ from 'lodash';
import MultiSelect from '../form/MultiSelect';
import { iLabelValuePair, iParams } from '../UITypes/types';
import DatePickerWithoutReadview from '../form/DatePickerWithoutReadview';
import ResetBtn from '../buttons/ResetBtn';

import { handleNullException, mapOptionToValue } from '../../services/UtilsService';
import CreatableMultiSelect from '../form/CreatableSelect';
import CheckboxMultiSelect from '../form/CheckboxMultiSelect';
import { getProductAttributesAsyncSearch } from '../../services/product/ProductAttributeService';
import PopupAsyncSelect from '../form/inlineEdit/asyncSelect/PopupAsyncSelect';
import { iSingleSelectOption } from '../../pages/NCR/detail/shared/NCRDetail.type';
import iProductAttribute from '../../types/product/iProductAttribute';
import iProductAttributeSet from '../../types/product/iProductAttributeSet';

const ControllerWrapper = styled.div`
  position: relative;
  padding: 5px 0 10px 0;
  width: 100%;

  &.remove-1-gutter-before {
    margin-left: -16px;
  }

  &.remove-2-gutter-before {
    margin-left: -32px;
  }
`;

const AddMoreWrapper = styled.div`
  margin-top: 1.3rem;
  padding: 5px 0 10px 0;
  width: 100%;
`;

const CustomizedLabel = styled.label`
  color: #6b778c;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3333333333;
  padding: 0 0 3px 1px;
`;

const RemovableBtn = styled.div`
  position: absolute;
  top: 9px;
  right: 0;
  color: grey;

  &:hover {
    color: black;
  }
`;

type iFilterConfig = {
  name: string;
  label?: string;
  type: string;
  selected: any;
  placeholder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promiseFn?: (config?: iParams | undefined) => Promise<any>;
  options?: iLabelValuePair[];
  optionLabel?: string;
  sortBy?: string;
  removable?: boolean;
  testId: string;
  className?: string;
};

type iSelected = {
  [key: string]: any;
};

const getInitialSelected = (configs: iFilterConfig[]) => {
  return configs.reduce(
    (acc: any, config: iFilterConfig) => ({
      ...acc,
      [config.name]: config.selected,
    }),
    {},
  );
};

const useAdvancedSearchHooks = (
  configs: iFilterConfig[],
  allowAddMore = false,
  productAttributeSets?: iProductAttributeSet[],
) => {
  const [localConfigs, setLocalConfigs] = useState<iFilterConfig[]>(configs);
  const [localSelected, setLocalSelected] = useState<iSelected>({});

  const onAddNewConfig = (newConfig: iFilterConfig) => {
    setLocalConfigs([...localConfigs, newConfig]);
    setLocalSelected({
      ...localSelected,
      [newConfig.name]: newConfig.selected,
    });
  };

  const onRemoveConfig = (currentConfig: iFilterConfig) => {
    const updatedConfigs = localConfigs.filter((c: iFilterConfig) => c.name !== currentConfig.name);
    setLocalConfigs(updatedConfigs);
    const updatedSelected = _.omit(localSelected, `${currentConfig.name}`);
    setLocalSelected({ ...updatedSelected });
  };

  const onResetAdvancedSearch = () => {
    // remove all dynamic controls
    setLocalConfigs(configs);
    setLocalSelected({
      ...getInitialSelected(configs),
    });
  };

  const onConfirmSelect = (name: string, newValues: iLabelValuePair[] | string) => {
    if (!name) return;
    setLocalSelected({
      ...localSelected,
      [name]: newValues,
    });
  };

  const convertCheckboxOptionArrToObj = (valueArr: iLabelValuePair[]) => {
    return valueArr.reduce(
      (acc, cur) => ({
        ...acc,
        [`${[cur.label]}:`]: !!cur.value,
      }),
      {},
    );
  };

  const onConfirmCheckbox = (options: iLabelValuePair[], newValues: iLabelValuePair[]) => {
    const defaultOptions = options.reduce(
      (acc, cur) => ({
        ...acc,
        [`${[cur.label]}:`]: false,
      }),
      {},
    );

    const selectedObj = convertCheckboxOptionArrToObj(newValues);

    setLocalSelected({
      ...localSelected,
      ...defaultOptions,
      ...selectedObj,
    });
  };

  const onAddDynamicSearchField = (name: string, newValues: any) => {
    const newConfig: iFilterConfig = {
      name: `ProductAttributeValue_id_productId.attributeId:${newValues?.value.id},ProductAttributeValue_id_productId.value`,
      label: newValues?.value.name,
      type: 'multi-text',
      selected: [],
      placeholder: 'All values...',
      optionLabel: 'value',
      sortBy: 'value',
      removable: true,
      testId: `product-advanced-search-${newValues?.value.name}`,
      className: 'normal-width',
    };
    onAddNewConfig(newConfig);
  };

  const getAdvancedFilterStrArr = () => {
    const advancedFilterArr = Object.keys(localSelected).map((selectedName: string) => {
      // if selected outcome is an array, then join its elements to a string
      if (Array.isArray(localSelected[selectedName])) {
        return localSelected[selectedName].length === 0
          ? ''
          : `${selectedName}:${mapOptionToValue(localSelected[selectedName]).join('|')}`;
      }

      return localSelected[selectedName] ? `${selectedName}${localSelected[selectedName]}` : '';
    });
    return advancedFilterArr;
  };

  const getSearchControllers = () => {
    const controllers = localConfigs.map((c: iFilterConfig) => {
      const { type } = c;
      switch (type) {
        case 'multi':
          return (
            <GridColumn medium={2.9} key={c.name}>
              <ControllerWrapper className={c.className}>
                <CustomizedLabel htmlFor={c.name}>{c.label}</CustomizedLabel>
                {c.removable && (
                  <RemovableBtn onClick={() => onRemoveConfig(c)}>
                    <EditorCloseIcon label={'removable'} size={'small'} />
                  </RemovableBtn>
                )}
                <MultiSelect
                  name={c.name}
                  label={c.label}
                  defaultValue={localSelected[c.name]}
                  onConfirm={onConfirmSelect}
                  placeholder={c.placeholder}
                  promiseFn={c.promiseFn}
                  optionLabel={c.optionLabel}
                  sortBy={c.sortBy}
                  testId={c.testId}
                />
              </ControllerWrapper>
            </GridColumn>
          );
        case 'multi-text':
          return (
            <GridColumn medium={2.9} key={c.name}>
              <ControllerWrapper className={c.className}>
                <CustomizedLabel htmlFor={c.name}>{c.label}</CustomizedLabel>
                {c.removable && (
                  <RemovableBtn onClick={() => onRemoveConfig(c)}>
                    <EditorCloseIcon label={'removable'} size={'small'} />
                  </RemovableBtn>
                )}
                <CreatableMultiSelect
                  name={c.name}
                  defaultValue={localSelected[c.name]}
                  onChange={onConfirmSelect}
                  placeholder={c.placeholder}
                  testId={c.testId}
                  isClearable
                  isMulti
                />
              </ControllerWrapper>
            </GridColumn>
          );
        case 'checkbox-select':
          return (
            <GridColumn medium={2.9} key={c.name}>
              <ControllerWrapper className={c.className}>
                <CustomizedLabel htmlFor={c.name}>{c.label}</CustomizedLabel>
                <CheckboxMultiSelect
                  name={c.name}
                  value={c.options?.filter(o => localSelected[`${o.label}:`])}
                  onConfirm={onConfirmCheckbox}
                  selectOptions={c.options || []}
                  placeholder={c.placeholder}
                  testId={c.testId}
                />
              </ControllerWrapper>
            </GridColumn>
          );
        case 'date':
          return (
            <GridColumn key={c.name} medium={1.5}>
              <ControllerWrapper className={c.className}>
                <CustomizedLabel htmlFor={c.name}>{c.label}</CustomizedLabel>
                <DatePickerWithoutReadview
                  name={c.name}
                  dateFormat="DD MMM YYYY"
                  defaultValue={localSelected[c.name]}
                  onConfirm={onConfirmSelect}
                  placeholder={c.placeholder}
                  testId={c.testId}
                />
              </ControllerWrapper>
            </GridColumn>
          );
        default:
          return null;
      }
    });

    const getFormatOptionLabel = (option: iSingleSelectOption<iProductAttribute>) => {
      if (typeof option === 'undefined' || option === null) return null;
      const { value } = option;
      const attributeSet = productAttributeSets?.find(
        (set: iProductAttributeSet) => set.id === handleNullException(value, 'attributeSetId'),
      );
      return attributeSet
        ? `${handleNullException(value, 'name')} - ${attributeSet.name}`
        : handleNullException(value, 'name');
    };

    return (
      <>
        {controllers}
        {allowAddMore && (
          <GridColumn medium={2}>
            <AddMoreWrapper>
              <PopupAsyncSelect
                name={'addMore'}
                popupBtnName={'More'}
                promiseFn={(keyword: string) =>
                  getProductAttributesAsyncSearch({
                    like: `name:${keyword}`,
                    sort: 'createdAt:ASC',
                  })
                }
                onConfirm={onAddDynamicSearchField}
                formatOptionLabel={getFormatOptionLabel}
                placeholder={'Search'}
              />
            </AddMoreWrapper>
          </GridColumn>
        )}
        <GridColumn medium={0.5}>
          <div style={{ margin: '25px 0 0 -10px' }}>
            <ResetBtn onClick={onResetAdvancedSearch} />
          </div>
        </GridColumn>
      </>
    );
  };

  return {
    getAdvancedFilterStrArr,
    getSearchControllers,
  };
};

export default useAdvancedSearchHooks;
