import React from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Tooltip from '@atlaskit/tooltip';

const WarningIconWithTooltip = ({
  tooltipContent,
}: {
  tooltipContent: React.ReactNode;
}) => {
  return (
    <Tooltip content={tooltipContent}>
      <WarningIcon
        label={'warning-diff-address'}
        primaryColor={'rgb(222, 53, 11)'}
        size={'medium'}
      />
    </Tooltip>
  );
};

export default WarningIconWithTooltip;
