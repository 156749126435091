import React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import Sidebar from '../sidebar/Sidebar';
import BackendPage from '../../../layout/BackendPage';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import { PageTitle } from '../../../shared/styles/styles';
import CompanyPrintingPreferenceTabPanel from '../companyProfile/components/CompanyPrintingPreferenceTabPanel';

const PrintingSettingPage = () => {
  const PageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'PDF Printing'} />
      <PageTitle data-testid={'page-title'}>PDF Printing</PageTitle>
      <p>You can set customize your PDF printing here below:</p>
    </>
  );

  return (
    <BackendPage pageHeader={<PageHeader />} leftMenu={<Sidebar />}>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={12}>
          <CompanyPrintingPreferenceTabPanel />
        </GridColumn>
      </Grid>
    </BackendPage>
  );
};

export default PrintingSettingPage;
