import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iContactCompanyAddress from '../../../../types/contactCompany/iContactCompanyAddress';
import { iConfigColumn } from '../../../UITypes/types';
import { getHeads } from '../../../../services/UtilsService';

import { getRows } from './TableHelper';

const CompanyAddressTable = ({
  data,
  onOpenDeleteModal,
  onOpenEditModal,
  columns,
  isLoading,
}: {
  data: Array<iContactCompanyAddress>;
  columns: Array<iConfigColumn>;
  onOpenDeleteModal: (id: string) => void;
  //  eslint-disable-next-line
  onOpenEditModal: (id: string) => void;
  isLoading: boolean;
}) => {
  return (
    <DynamicTable
      head={getHeads(columns, 'company-address-table')}
      rows={getRows({ data, columns, onOpenDeleteModal, onOpenEditModal })}
      isLoading={isLoading}
    />
  );
};

export default CompanyAddressTable;
