import React from 'react';
import BreadcrumbsTwoLayers from '../../heading/BreadcrumbsTwoLayers';
import { MarginBottomContainer } from './styles';

const DetailNav = ({
  contactCompanyType,
  contactCompanyName,
  urlPrefix,
}: {
  contactCompanyType: string;
  contactCompanyName?: string;
  urlPrefix: string;
}) => {
  return (
    <MarginBottomContainer>
      <BreadcrumbsTwoLayers
        name={contactCompanyType || ''}
        subName={contactCompanyName || ''}
        url={urlPrefix}
      />
    </MarginBottomContainer>
  );
};

export default DetailNav;
