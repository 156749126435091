import { Label } from '@atlaskit/field-base';
import React from 'react';

import styled from 'styled-components';
import InlineEdit from '../../../../shared/form/InlineEdit';
import { SpaceVerticalContainer } from '../../../../shared/styles/styles';
import { iLabelValuePair } from '../../../../shared/UITypes/types';
import iProduct from '../../../../types/product/iProduct';

type iAttribute = iLabelValuePair & {
  prefix?: string;
  postfix?: string;
  fieldName: string;
  multiplier?: number;
  disabled?: boolean;
};

type iRightPanelTop = {
  product?: iProduct;
  attributes: Array<iAttribute>;
  onUpdateProduct: (fieldName: string, fieldValue: string | number | boolean) => void;
  isDisabled?: boolean;
};
const Wrapper = styled.div`
  display: flex;
  gap: 0.3rem;
  flex-wrap: wrap;
  justify-content: space-between;
  .cell-div {
    label {
      > div {
        padding: 0px;
        margin-top: 8px;
        margin-bottom: 4px;
      }
    }
  }
`;
const RightPanelTop = ({ product, attributes, onUpdateProduct, isDisabled }: iRightPanelTop) => {
  const getSectionDiv = (attr: iAttribute) => {
    if (`${attr.fieldName || ''}`.trim() === 'totalValue') {
      return (
        <InlineEdit
          label={attr.label}
          name={attr.fieldName}
          prefix={attr.prefix}
          postfix={attr.postfix}
          defaultValue={product?.stockOnHand ? attr.value : product?.totalValue}
          onConfirm={onUpdateProduct}
          isLargeText
          multiplier={attr.multiplier}
          isDisabled={isDisabled}
        />
      );
    }
    if (attr.disabled === true) {
      return (
        <>
          <Label label={attr.label} />
          <SpaceVerticalContainer />
        </>
      );
    }
    return (
      <InlineEdit
        label={attr.label}
        name={attr.fieldName}
        prefix={attr.prefix}
        postfix={attr.postfix}
        defaultValue={attr.value}
        onConfirm={onUpdateProduct}
        isLargeText
        isDisabled={isDisabled}
      />
    );
  };
  return (
    <Wrapper>
      {attributes.map((attr: iAttribute) => (
        <div className={'cell-div'} key={attr.fieldName}>
          {getSectionDiv(attr)}
        </div>
      ))}
    </Wrapper>
  );
};

export default RightPanelTop;
