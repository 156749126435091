import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getStockTakeList = (config?: { [key: string]: string }) => {
  return AppService.get('/stockTake', {
    include: 'createdBy,updatedBy,warehouseLocation,status',
    ...config,
  }).then(({ data }) => data);
};
export const getStockTakeDetail = (id: string) => {
  return AppService.get(`/stockTake/${id}`, {
    include:
      'createdBy,updatedBy,lostAndFoundLocation,warehouseLocation.warehouseItemLocations.product.measurement',
  }).then(({ data }) => data);
};

export const createStockTake = (postData: iParams) => {
  return AppService.post('/stockTake', postData).then(({ data }) => data);
};

export const deactivateStockTake = (id: string) => {
  return AppService.delete(`/stockTake/${id}`).then(({ data }) => data);
};

export const updateStockTake = (id: string, updateContent: iParams) => {
  return AppService.put(`/stockTake/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const startStockTake = (id: string) => {
  return AppService.post(`/stockTake/${id}/start`, {}).then(({ data }) => data);
};

export const previewFinishStockTake = (id: string) => {
  return AppService.post(`/stockTake/${id}/preview`, {}).then(
    ({ data }) => data,
  );
};

export const finishStockTake = (id: string) => {
  return AppService.post(`/stockTake/${id}/finish`, {}).then(
    ({ data }) => data,
  );
};

export default {
  getStockTakeList,
  getStockTakeDetail,
  createStockTake,
  deactivateStockTake,
  updateStockTake,
  startStockTake,
  previewFinishStockTake,
};
