import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import InputWithController from '../../../form/hookForms/InputWithController';
import SelectWithController from '../../../form/hookForms/SelectWithController';
import { SpaceVerticalContainer } from '../../../styles/styles';
import { LOGO_OPTIONS } from '../../constants';

const RollLabelModalBody = ({
  control,
  errors,
  onChange,
  length,
  nominalWeight,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  //    eslint-disable-next-line
  length?: any;
  //    eslint-disable-next-line
  nominalWeight?: any;
}) => {
  return (
    <div data-testid={'modal-body-roll-label'}>
      <InputWithController
        name={'length'}
        label={'Length'}
        defaultValue={length?.value}
        suffix={length?.postfix}
        control={control}
        onChange={onChange}
        errors={errors}
        isNumeric
        testId={'roll-label-length'}
      />
      <InputWithController
        name={'nominalWeight'}
        label={'Nominal Weight'}
        defaultValue={nominalWeight?.value}
        suffix={nominalWeight?.postfix}
        control={control}
        onChange={onChange}
        errors={errors}
        isNumeric
        testId={'roll-label-nominalWeight'}
      />
      <SelectWithController
        name={'includeLogo'}
        label={'Print With Logo or not'}
        control={control}
        options={LOGO_OPTIONS}
        defaultValue={'true'}
        onChange={onChange}
        errors={errors}
        testId={'roll-label-includeLogo'}
      />
      <SpaceVerticalContainer className={'three'} />
    </div>
  );
};

export default RollLabelModalBody;
