import React from 'react';
import iAcquisitionOrderItem from '../../../../types/acquisitionOrder/iAcquisitionOrderItem';
import iEntityCategory from '../../../../types/status/iEntityCategory';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import ConversionText from '../../../../shared/text/ConversionText';
import StatusLozenge from '../../../../shared/statusComponent/StatusLozenge';
import CreateBtnPlusIcon from '../../../../shared/buttons/CreateBtnPlusIcon';
import { iConfigColumn } from '../../../despatchNote/shared/DispatchNote.type';
import { createPOItemConfig } from '../../../../services/PurchaseOrderItemService';
import {
  handleNullException,
  handleMoney,
  handleNullExceptionMultiFields,
  handleNumber,
  getCategoryCode,
} from '../../../../services/UtilsService';

export const getRows = ({
  data,
  onCreateCallback,
  columns,
  POId,
  categories,
}: {
  data: Array<iAcquisitionOrderItem>;
  onCreateCallback: (id?: string) => void;
  columns: Array<iConfigColumn>;
  POId: string;
  categories: Array<iEntityCategory>;
}) => {
  return data.map((item: iAcquisitionOrderItem) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'unit-price':
          return {
            key: handleNullException(item, key),
            content: `${handleMoney(
              item,
              key,
              2,
            )} / ${handleNullExceptionMultiFields(item, dataSource.join(','))}`,
          };
        case 'number':
          return {
            key: handleNullException(item, key),
            content: handleNumber(item, key),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(
                  item,
                  dataSource[0],
                )}`}
              />
            ),
          };
        case 'link-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText
                secondaryText={handleNullException(item, dataSource[1])}
              >
                <LinkBtnUrl
                  btnName={handleNullException(item, key)}
                  url={`${column.urlPrefix}/${handleNullException(
                    item,
                    dataSource[0],
                  )}`}
                />
              </ComposeSecondaryText>
            ),
          };
        case 'conversion-text':
          return {
            key: handleNullException(item, key),
            content: (
              <ConversionText
                value={handleNullException(item, key)}
                conversion={handleNullException(item, dataSource[0])}
                contactCompanyUnit={
                  handleNullException(item, dataSource[1]) ||
                  handleNullException(item, dataSource[2])
                }
                productUnit={handleNullException(item, dataSource[2])}
              />
            ),
          };
        case 'status':
          return {
            content: (
              <StatusLozenge
                status={handleNullException(item, key)}
                categoryCode={getCategoryCode(
                  handleNullException(item, key),
                  categories,
                )}
              />
            ),
          };
        case 'operation':
          return {
            content: (
              <CreateBtnPlusIcon
                createFn={() =>
                  createPOItemConfig({
                    orderId: POId,
                    acquisitionOrderItemId: handleNullException(item, 'id'),
                  })
                }
                onCreateCallback={onCreateCallback}
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'AO-items' };
  });
};
