import React, { useState } from 'react';
import Spinner from '@atlaskit/spinner';
import AsyncSearch from '../../../shared/asyncSearch/AsyncSearch';
import AddProdsPopup from './AddProdsPopup';
import WarningModal from '../../../shared/modal/WarningModal';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { iActionTypes } from '../../../types/iActionTypes';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import { FlexContainer } from '../../../shared/styles/styles';
import {
  getWarehouseDetail,
  getWarehouseListAsyncSearch,
  isWIPWarehouseLocation,
} from '../../../services/WarehouseService';

type iState = {
  warehouseLocation?: iWarehouseLocation;
  isWIP: boolean;
  isLoading: boolean;
};
const initialState: iState = {
  isWIP: false,
  isLoading: false,
};

const AddProds = ({
  updateRecords,
  records,
  isDisabled,
}: {
  updateRecords: (items: Array<iItemLocationTransfer>) => void;
  records: Array<iItemLocationTransfer>;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState(initialState);

  const onCloseModal = () =>
    setState({
      ...state,
      warehouseLocation: undefined,
      isLoading: false,
      isWIP: false,
    });
  //  eslint-disable-next-line

  const onSelectWarehouseLocation = async (
    payload: string,
    //  eslint-disable-next-line
    actionMeta: iActionTypes,
  ) => {
    if (!payload) return;
    const id = payload.split(':')[0];
    if (!id) return;
    setState({ ...state, isLoading: true });
    try {
      const { isWIP }: { isWIP: boolean } = await isWIPWarehouseLocation(id);
      if (isWIP) {
        setState({
          ...state,
          warehouseLocation: undefined,
          isWIP,
        });
        return;
      }
      const warehouseLocation: iWarehouseLocation = await getWarehouseDetail(id);
      setState({ ...state, warehouseLocation });
    } catch (error) {
      setState({ ...state, isLoading: false });
      apiErrorToast(error);
    }
  };

  return (
    <>
      <FlexContainer>
        <div style={{ minWidth: '320px' }}>
          <AsyncSearch
            onSelect={onSelectWarehouseLocation}
            promiseFn={(keyword: string) =>
              getWarehouseListAsyncSearch({
                like: `name:${keyword},locationCode:${keyword}`,
              })
            }
            optionLabel={['name', 'locationCode']}
            searchBarPlaceholder={'search From Location by code or name...'}
            isDisabled={isDisabled}
          />
        </div>
        {state.isLoading && <Spinner />}
      </FlexContainer>
      {state.warehouseLocation && (
        <AddProdsPopup
          records={records}
          updateRecords={updateRecords}
          onCancel={onCloseModal}
          warehouseLocation={state.warehouseLocation}
        />
      )}
      <WarningModal
        isOpen={state.isWIP}
        onCancel={onCloseModal}
        modalHeading={'Invalid to take a stock transfer'}
        modalBody={
          'Current warehouse location is WIP, not allowed to take any actions against it until WIP is completed'
        }
      />
    </>
  );
};

export default AddProds;
