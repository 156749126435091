import React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import iJob from '../../../../types/job/iJob';
//  eslint-disable-next-line
import JobCard from './JobCard';
import { getItemStyle } from '../shared/Schedule.utils';
import { iLabelValuePair } from '../../../../shared/UITypes/types';

const DraggableList = ({
  jobs,
  date,
  shift,
  isDragDisabled,
}: {
  jobs: Array<iJob>;
  date: string;
  shift: iLabelValuePair;
  isDragDisabled?: boolean;
}) => {
  return (
    <>
      {jobs.map((job: iJob, index: number) => (
        <Draggable key={job.id} draggableId={job.id} index={index} isDragDisabled={job.moveDisabled || isDragDisabled}>
          {(providedDrag: DraggableProvided, snapshotDrag: DraggableStateSnapshot) => (
            <div
              ref={providedDrag.innerRef}
              //    eslint-disable-next-line
              {...providedDrag.draggableProps}
              //    eslint-disable-next-line
              {...providedDrag.dragHandleProps}
              style={getItemStyle(providedDrag.draggableProps.style, snapshotDrag.isDragging, !!job.moveDisabled)}
            >
              <JobCard job={job} shift={shift} date={date} isDisabled={isDragDisabled} />
            </div>
          )}
        </Draggable>
      ))}
    </>
  );
};

export default DraggableList;
