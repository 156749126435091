import React from 'react';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';

import { getDeleteBtn } from '../../../../components/settings/utils';
import {
  getCategoryCode,
  getCategoryName,
  handleNullException,
} from '../../../../services/UtilsService';
import LinkBtn from '../../../../shared/buttons/LinkBtn';
import CreatedOrUpdated from '../../../../shared/createdOrUpdated/CreatedOrUpdated';
import { iConfigColumn } from '../../../../shared/UITypes/types';
import iEntityStatus from '../../../../types/status/iEntityStatus';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import StatusLozenge from '../../../../shared/statusComponent/StatusLozenge';
import iEntityCategory from '../../../../types/status/iEntityCategory';

export const getRows = (
  data: Array<iEntityStatus>,
  onEdit: (id: string) => void,
  onDelete: (id: string) => void,
  columns: Array<iConfigColumn>,
  categories: Array<iEntityCategory>,
) => {
  return data.map((attr: iEntityStatus) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'modalLink':
          return {
            key: `modalLink-${handleNullException(attr, key)}`,
            content: (
              <LinkBtn
                btnName={handleNullException(attr, key)}
                onClick={() => onEdit(handleNullException(attr, dataSource[0]))}
                testId={`entityStatus-list-linkBtn-${handleNullException(
                  attr,
                  dataSource[0],
                )}`}
              />
            ),
          };
        case 'link-two-layer':
          return {
            key: `link-two-layer-${handleNullException(attr, key)}`,
            content: (
              <ComposeSecondaryText
                secondaryText={handleNullException(attr, dataSource[1])}
              >
                <LinkBtn
                  btnName={handleNullException(attr, key)}
                  onClick={() =>
                    onEdit(handleNullException(attr, dataSource[0]))
                  }
                />
              </ComposeSecondaryText>
            ),
          };
        case 'status':
          return {
            key: handleNullException(attr, key),
            content: (
              <StatusLozenge
                categoryCode={getCategoryCode(attr, categories)}
                categoryName={getCategoryName(attr, categories)}
                isCategoryLozenge
              />
            ),
          };
        case 'text':
          return {
            key: handleNullException(attr, key),
            content: handleNullException(attr, key),
          };
        case 'sortOrder':
          return {
            type: 'sortOrder',
            key: handleNullException(attr, key),
            content: handleNullException(attr, key),
          };
        case 'history':
          return {
            key: handleNullException(attr, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(attr, key)}
                operator={handleNullException(attr, dataSource[0])}
              />
            ),
          };
        case 'operation':
          return {
            key: `${attr.name}-operation`,
            content: getDeleteBtn({
              id: attr.id,
              answer: attr.name || 'Entity Status Name n/a',
              onClick: onDelete,
            }),
          };
        case 'icon':
          return {
            key: `${attr.name}-icon`,
            content: <DragHandlerIcon label={'dragHandlerIcon'} />,
          };
        default:
          return { content: '' };
      }
    });
    return {
      cells,
      testId: 'admin-entity-status-row',
      key: attr.id,
    };
  });
};
