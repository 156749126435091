import React from 'react';
import _ from 'lodash';
import iSalesOrderDetail from '../../../types/sales/iSalesOrder';
import iSalesOrderItem from '../../../types/sales/iSalesOrderItem';
import Summary from '../shared/Summary';
import { handleNullExceptionMultiFields } from '../../../services/UtilsService';
import MathHelper from '../../../services/MathHelper';

const SummarySalesOrder = ({ data }: { data: Array<iSalesOrderDetail> }) => {
  const getSums = () => {
    const salesOrderItems = data.reduce(
      (acc: Array<iSalesOrderItem>, cur: iSalesOrderDetail) => [...acc, ...cur.salesOrderItems],
      [],
    );
    const grouped = _.groupBy(salesOrderItems, (item: iSalesOrderItem) =>
      handleNullExceptionMultiFields(item, 'product.measurement.shortName,measurement.shortName'),
    );
    const sums = Object.keys(grouped).map((unitName: string) => {
      const sum = grouped[unitName].reduce((acc: number, cur: iSalesOrderItem) => MathHelper.add(acc, cur.qty || 0), 0);
      return { unitName, sum };
    });
    return sums;
  };
  return <Summary data={getSums()}>{data.length} Order(s)</Summary>;
};

export default SummarySalesOrder;
