import React, { useEffect, useState } from 'react';
import Toggle from '@atlaskit/toggle';
import Tooltip from '@atlaskit/tooltip';
import iProduct from '../../types/product/iProduct';
import iAcquisitionOrder from '../../types/acquisitionOrder/iAcquisitionOrder';
import iContactCompanyProduct from '../../types/contactCompany/iContactCompanyProduct';
import iPurchaseOrderDetail from '../../types/purchases/iPurchaseOrderDetail';
import iSalesOrderDetail from '../../types/sales/iSalesOrder';
import AsyncSearch from '../asyncSearch/AsyncSearch';
import { apiErrorToast } from '../toast/Toast';
import { getCCPListAsyncSearch, getContactCompanyProducts } from '../../services/ContactCompanyProductService';
import { getProductListAsyncSearch } from '../../services/product/ProductService';
import { handleNullException } from '../../services/UtilsService';
import { AsyncSearchWrapper, FlexColumnContainer, FlexContainer, LabelValue } from '../styles/styles';

const AddOrderItem = ({
  order,
  isCustomer = true,
  onSelect,
  children,
  isDisabled,
}: {
  order?: iSalesOrderDetail | iAcquisitionOrder | iPurchaseOrderDetail;
  isCustomer?: boolean;
  onSelect: (payload: iProduct | iContactCompanyProduct, isCCP: boolean) => void;
  children?: React.ReactNode;
  isDisabled?: boolean;
}) => {
  const [shouldSearchCCP, setShouldSearchCCP] = useState(false);
  const [localCCP, setLocalCCPs] = useState<iContactCompanyProduct[]>([]);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      try {
        const ccp = await getContactCompanyProducts({
          filter: `contactCompanyId:${handleNullException(order, isCustomer ? 'customerId' : 'supplierId')}`,
        });
        setLocalCCPs(ccp);
        if (isCancelled) return;
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomer]);

  return (
    <FlexContainer className={'space-between'} style={{ flexGrow: 0.1 }}>
      <AsyncSearchWrapper>
        {shouldSearchCCP ? (
          <AsyncSearch
            onSelect={(payload: iContactCompanyProduct) => onSelect(payload, true)}
            promiseFn={(keyword: string) =>
              getCCPListAsyncSearch({
                like: `product.productCode:${keyword},product.name:${keyword}`,
                filter: `contactCompanyId:${handleNullException(order, isCustomer ? 'customerId' : 'supplierId')}`,
              })
            }
            optionLabel={['product.productCode', 'product.name']}
            searchBarPlaceholder={'Search productCode/name to add...'}
            idPayload={false}
            isDisabled={isDisabled}
          />
        ) : (
          <AsyncSearch
            onSelect={(payload: iProduct) => onSelect(payload, false)}
            promiseFn={(keyword: string) =>
              getProductListAsyncSearch({
                like: `productCode:${keyword},name:${keyword}`,
                filter: `${isCustomer ? 'isForSell:true' : 'isForPurchase:true'}`,
              })
            }
            optionLabel={['productCode', 'name']}
            searchBarPlaceholder={'Search productCode/name to add...'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            // filterFn={(item: any) => item.isForSell === true}
            idPayload={false}
            isDisabled={isDisabled}
          />
        )}
      </AsyncSearchWrapper>
      {localCCP.length !== 0 && (
        <FlexColumnContainer className={'horizontal-center'} style={{ marginTop: '-0.5rem' }}>
          <LabelValue className={'medium-size'}>Preferred Products</LabelValue>
          <Tooltip
            content={shouldSearchCCP ? 'Disable preferred products search' : 'Enable preferred products search'}
            position={'right'}
          >
            <Toggle
              id="toggle-product-preference-so"
              onChange={() => setShouldSearchCCP(prev => !prev)}
              isDisabled={isDisabled}
            />
          </Tooltip>
        </FlexColumnContainer>
      )}
      {children}
    </FlexContainer>
  );
};

export default AddOrderItem;
