import Toggle from '@atlaskit/toggle';
import Tooltip from '@atlaskit/tooltip';
import React, { useState } from 'react';
import { iLabelValuePair } from '../../../UITypes/types';
import FloatSection from '../styles/FloatSection';
import VerticalLabel from '../VerticalLabel';

const ProductAttributesRender = ({
  attributes,
}: {
  attributes: iLabelValuePair[];
}) => {
  const [isChecked, handleOnchange] = useState(false);
  const checkedText = 'Display all attributes';
  const uncheckedText = 'Hide empty attributes';
  const getContent = () => (isChecked ? uncheckedText : checkedText);

  return (
    <FloatSection>
      <Tooltip content={getContent()} position="right">
        <Toggle
          size="regular"
          onChange={() => handleOnchange(!isChecked)}
          id="product-attribute-toggler"
          testId="product-attribute-toggler"
        />
      </Tooltip>
      {attributes
        .filter(attr => {
          if (!isChecked) return !!attr.value || attr.value === 'false';
          return attr;
        })
        .map(attr => (
          <VerticalLabel
            key={attr.label}
            label={attr.label}
            value={attr.value}
          />
        ))}
    </FloatSection>
  );
};
export default ProductAttributesRender;
