import { iConfigColumn } from '../../UITypes/types';

export const PRODUCTS_SUPPLIER: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    // dataSource: ['id'],
    dataSource: ['product.name'],
    isSortable: true,
    type: 'edit-trigger-seconday',
    isSelected: true,
    isCompulsory: true,
    group: '',
  },
  {
    name: 'Supplier',
    key: 'contactCompany.name',
    dataSource: ['id'],
    isSortable: true,
    type: 'edit-trigger',
    isSelected: true,
  },
  {
    name: 'Supplier Product Code/SKU',
    key: 'code',
    dataSource: ['code'],
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Unit Price',
    key: 'unitPrice',
    dataSource: [
      'unitPriceMeasurement.shortName',
      'product.measurement.shortName',
    ],
    type: 'money-unit',
    isSelected: true,
    group: '',
  },
  {
    name: 'Supplier Unit',
    key: 'measurement.shortName',
    dataSource: ['product.measurement.shortName'],
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Conversion',
    key: 'unitConversion',
    dataSource: ['measurement.shortName', 'product.measurement.shortName'],
    type: 'conversion-unit',
    isSelected: true,
    group: '',
  },
  {
    name: '',
    key: 'add',
    dataSource: [],
    type: 'add',
    isCompulsory: true,
  },
  {
    name: '',
    key: 'delete',
    dataSource: ['id'],
    type: 'delete',
    isCompulsory: true,
  },
];
