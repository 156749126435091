import React, { useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iContactCompany from '../../../types/contactCompany/iContactCompany';
import { getHeads } from '../../../services/UtilsService';
import { iConfigColumn, iSortOrderType } from '../../../shared/UITypes/types';
import { getRows } from './TableHelper';

const ContactCompanyTable = ({
  pageName,
  data,
  isLoading,
  columns,
  onSetSort,
  onOpenDeleteModal,
}: {
  pageName: string;
  data: Array<iContactCompany>;
  isLoading: boolean;
  columns: Array<iConfigColumn>;
  onSetSort: (sortStr: string) => void;
  onOpenDeleteModal: (id: string) => void;
}) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  return (
    <DynamicTable
      testId={'contactCompanyTbl'}
      head={getHeads(columns, pageName)}
      rows={getRows({
        data,
        onOpenDeleteModal,
        columns,
      })}
      isLoading={isLoading}
      onSort={
        //  eslint-disable-next-line
        (data: any) => {
          setSortKey(data.key);
          setSortOrder(data.sortOrder);
          onSetSort(`${data.key}:${data.sortOrder}`);
        }
      }
      sortKey={sortKey}
      sortOrder={sortOrder}
    />
  );
};

export default ContactCompanyTable;
