import React from 'react';
import iItemLocation from '../../../../types/warehouse/iItemLocation';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import CreatedOrUpdated from '../../../../shared/createdOrUpdated/CreatedOrUpdated';
import { iConfigColumn } from '../../../../shared/UITypes/types';
import { handleNullException } from '../../../../services/UtilsService';

export const getRows = (data: Array<iItemLocation>, columns: Array<iConfigColumn>) => {
  return data.map((item: iItemLocation) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'link-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                {column.urlPrefix ? (
                  <LinkBtnUrl
                    btnName={handleNullException(item, key)}
                    url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
                  />
                ) : (
                  handleNullException(item, key)
                )}
              </ComposeSecondaryText>
            ),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        default:
          return { content: handleNullException(item, key) };
      }
    });
    return { cells, testId: 'item-location' };
  });
};
