import React from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import Modal, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog';
import { iBtnAppearance } from '../UITypes/types';

const defaultModalHeading = <span>Are you sure to start?</span>;
const defaultModalBody = <p>Please confirm you are about to start it.</p>;
export type iCustomizeModal = {
  isOpen?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  isConfirming?: boolean;
  isDisabled?: boolean;
  confirmBtnName?: string;
  confirmBtnAppearance?: iBtnAppearance;
  modalHeading?: React.ReactNode;
  modalBody?: React.ReactNode;
  bottom?: React.ReactNode;
  testIdPrefix?: string;
  width?: 'small' | 'medium' | 'large' | 'x-large' | number | string;
  height?: number | string;
  customizedBtn?: React.ReactNode;
  cancelBtnName?: string;
  cancelNotAllowed?: boolean;
  shouldScrollInViewport?: boolean;
};
const CustomizeModal = ({
  isOpen,
  onConfirm,
  onCancel,
  isConfirming = false,
  confirmBtnName = 'Confirm',
  confirmBtnAppearance = 'primary',
  modalHeading = defaultModalHeading,
  isDisabled = false,
  modalBody = defaultModalBody,
  bottom = null,
  testIdPrefix,
  width,
  height,
  customizedBtn,
  cancelBtnName = 'Cancel',
  cancelNotAllowed = false,
  shouldScrollInViewport = false,
}: iCustomizeModal) => {
  const getModal = () => (
    <Modal
      testId={testIdPrefix ? `${testIdPrefix}-customize-modal` : 'customize-modal'}
      scrollBehavior={shouldScrollInViewport === true ? 'outside' : undefined}
      components={{
        Footer: () => {
          return (
            <ModalFooter>
              <div>{bottom}</div>
              <ButtonGroup>
                {customizedBtn || (
                  <LoadingButton
                    onClick={onConfirm}
                    appearance={confirmBtnAppearance}
                    isDisabled={isDisabled}
                    testId={testIdPrefix ? `${testIdPrefix}-confirm-button` : 'confirm-button'}
                    isLoading={isConfirming}
                  >
                    {confirmBtnName}
                  </LoadingButton>
                )}
                {!cancelNotAllowed && onCancel && (
                  <Button onClick={onCancel} testId={testIdPrefix ? `${testIdPrefix}-cancel-button` : 'cancel-button'}>
                    {cancelBtnName}
                  </Button>
                )}
              </ButtonGroup>
            </ModalFooter>
          );
        },
      }}
      onClose={() => !cancelNotAllowed && onCancel && onCancel()}
      heading={modalHeading}
      width={width || 'medium'}
      height={height || 'auto'}
    >
      {modalBody}
    </Modal>
  );
  return isOpen ? <ModalTransition>{getModal()}</ModalTransition> : null;
};

export default CustomizeModal;
