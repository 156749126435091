import React, { Ref, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DeepMap, FieldError, useForm } from 'react-hook-form';
import Textfield from '@atlaskit/textfield';
import { ErrorMessage } from '@atlaskit/form';

import ElementWithPopup from '../contactCompany/list/table/ElementWithPopup';
import iAddress from '../../types/contactCompany/iAddress';
import { iAddressPopup, iKeyValuePairs } from '../UITypes/types';
import AddressService from '../../services/AddressService';
import { apiErrorToast } from '../toast/Toast';
import AddressSelectWithController from '../form/hookForms/CompanyAddressSelectorWithController';
import iContactCompanyAddress from '../../types/contactCompany/iContactCompanyAddress';

const AddressPrefContainer = styled.div`
  margin-bottom: 1rem;
`;

type iOption<T> = {
  label: string;
  value: T;
};

const GetField = ({
  label,
  name,
  placeholder,
  errorMsg,
  defaultValue,
  type = 'text',
  register,
  errors,
}: {
  label: string;
  name: string;
  placeholder: string;
  errorMsg: string;
  defaultValue: string;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (rules: iKeyValuePairs) => Ref<any> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: DeepMap<Record<string, any>, FieldError>;
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <fieldset>
      {label}
      <Textfield
        type={type}
        value={value}
        onChange={onChange}
        id={name}
        name={name}
        css={'default'}
        placeholder={placeholder}
        ref={register({ required: true })}
      />
      {name in errors ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
    </fieldset>
  );
};

const UpdateAddressPopup = ({
  onClick,
  address,
  isAddConfirming,
  heading = 'Address',
  bottom,
  bottomHandler,
  children,
  needAddressSelection = false,
  options = [],
  isDisabled = false,
}: {
  onClick?: (updateContent: iAddressPopup) => void;
  address?: iAddress;
  heading?: string;
  isAddConfirming?: boolean;
  bottomHandler?: () => void;
  bottom?: React.ReactNode;
  children: React.ReactNode;
  needAddressSelection?: boolean;
  isDisabled?: boolean;
  options?: iOption<iContactCompanyAddress>[];
}) => {
  const { register, errors, getValues, control } = useForm<iAddressPopup>();

  const [localAddress, setLocalAddress] = useState<iAddress>();

  useEffect(() => {
    setLocalAddress(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(address)]);

  const onSelectPreferredAddress = async (name: string, value: string) => {
    if (!value) return;
    try {
      const data = await AddressService.getAddressDetail(value);
      setLocalAddress(data);
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const editAddress = () => {
    return (
      <form>
        <>
          {needAddressSelection && (
            <AddressPrefContainer>
              <AddressSelectWithController
                name={'shippingAddressId'}
                label={'Preferred Shipping Address'}
                control={control}
                defaultValue={localAddress?.id || undefined}
                options={options}
                onChange={onSelectPreferredAddress}
                errors={errors}
                isRequired={false}
                isClearable={false}
                triggerDefault={false}
              />
            </AddressPrefContainer>
          )}

          <GetField
            label={'Name'}
            name={'name'}
            placeholder={'name'}
            errorMsg={'Please provide name.'}
            defaultValue={localAddress?.name || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
          <GetField
            label={'Street'}
            name={'street'}
            placeholder={'street'}
            errorMsg={'Please provide street.'}
            defaultValue={localAddress?.street || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
          <GetField
            label={'Suburb'}
            name={'suburb'}
            placeholder={'suburb'}
            errorMsg={'Please provide suburb.'}
            defaultValue={localAddress?.suburb || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
          <GetField
            label={'State'}
            name={'state'}
            placeholder={'state'}
            errorMsg={'Please provide state.'}
            defaultValue={localAddress?.state || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
          <GetField
            label={'Postcode'}
            name={'postcode'}
            placeholder={'postcode'}
            errorMsg={'Please provide postcode.'}
            defaultValue={localAddress?.postcode || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
          <GetField
            label={'Country'}
            name={'country'}
            placeholder={'country'}
            errorMsg={'Please provide country.'}
            defaultValue={localAddress?.country || ''}
            type={'text'}
            register={register}
            errors={errors}
          />
        </>
      </form>
    );
  };

  const handleConfirm = () => {
    const data = getValues();
    if (typeof onClick === 'function') {
      onClick(data);
    }
  };

  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <ElementWithPopup
      onClick={handleConfirm}
      popupContent={editAddress()}
      popupHeading={heading}
      isConfirming={isAddConfirming}
      bottomHandler={bottomHandler}
      bottom={bottom}
      isDisabled={isDisabled}
    >
      {children}
    </ElementWithPopup>
  );
};
export default UpdateAddressPopup;
