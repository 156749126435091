import React from 'react';
import { useForm } from 'react-hook-form';
import iContactCompanyProduct from '../../types/contactCompany/iContactCompanyProduct';
import iProduct from '../../types/product/iProduct';
import CCPList from './CCPList';
import CCPModal from './CCPModal';
import ProductsCustomerContext from './ProductCustomerContext';
import CustomizeModal from '../modal/CustomizeModal';
import PlusIconAfterTitle from '../../components/settings/PlusIconAfterTitle';
import DeleteByRetype from '../deleteWithReType/DeleteByReType';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../hooks/useShowHideColumnHook/useColumnHook';
import CustomizePagination from '../pagination/CustomizePagination';
import { filterColumns } from './TableHelper';
import { SpaceVerticalContainer, FlexContainer } from '../styles/styles';
import {
  CONTCOMP_FIXED_FILTER,
  PRODUCT_CUSTOMER,
  PRODUCT_FIXED_FILTER,
} from './CCPProductCustomer/ProductCustomer.constants';
import { PRODUCTS_SUPPLIER } from './CCPProductSupplier/ProductsSupplier.constants';
import {
  getContactCompanyProducts,
  createContactCompanyProduct,
  updateContactCompanyProduct,
  deleteContactCompanyProduct,
} from '../../services/ContactCompanyProductService';
import { iConfigColumn } from '../UITypes/types';

const CCPProdContComp = ({
  startFieldId,
  fixedProduct,
  isCustomer,
  canCreate = true,
  canUpdate = true,
  canDelete = true,
}: {
  startFieldId: string;
  fixedProduct?: iProduct;
  isCustomer: boolean;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
}) => {
  const contCompType = isCustomer ? 'Customer' : 'Supplier';
  const contCompWording = isCustomer ? 'customer' : 'supplier';
  const getColumnName = () => {
    const fixedProductWording = fixedProduct ? 'product' : '';
    return `ccp-${contCompWording}-${fixedProductWording}`;
  };
  const filter = fixedProduct
    ? `productId:${startFieldId},contactCompany.type.name:${contCompType}`
    : `contactCompanyId:${startFieldId},contactCompany.type.name:${contCompType}`;

  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenDeleteModal,
    onOpenAddModal,
    onOpenEditModal,
    onSubmit,
    onSetCurrentPage,
  } = useListCrudHook<iContactCompanyProduct>({
    getFn: getContactCompanyProducts,
    createFn: createContactCompanyProduct,
    updateFn: updateContactCompanyProduct,
    deleteFn: deleteContactCompanyProduct,
    filter,
    sort: 'updatedAt:DESC',
    notRenderWithoutFilter: true,
    paginationApplied: true,
  });

  const { control, setValue, errors, handleSubmit, getValues, watch } = useForm();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmitMiddle = async (data: any) => {
    //    the place contactCompanyId from
    //    eslint-disable-next-line
    const contactCompanyId = fixedProduct
      ? isCustomer
        ? data.customerId
        : data.supplierId
      : startFieldId;

    onSubmit({
      contactCompanyId,
      code: data.code,
      unitPrice: data.unitPrice,
      unitConversion: data.unitConversion,
      measurementId: data.measurement.id,
      productId: fixedProduct ? startFieldId : data.product.id,
      unitPriceMeasurementId: data.unitPriceMeasurementId,
    });
  };

  const { columns: customerColumns, getShowHideColumns } = useColumnHooksUpgrade(
    getColumnName(),
    filterColumns(PRODUCT_CUSTOMER, fixedProduct ? PRODUCT_FIXED_FILTER : CONTCOMP_FIXED_FILTER),
  );
  const supplierColumns = filterColumns(PRODUCTS_SUPPLIER, fixedProduct ? PRODUCT_FIXED_FILTER : CONTCOMP_FIXED_FILTER);

  /**
   * isCustomer validate customerId
   * !isCustomer or is supplier validate code and supplierId
   * not redundant create not errors not value
   */
  const formValidate = () => {
    const commonValidate =
      Object.keys(errors).length > 0 ||
      !getValues().unitPrice ||
      !getValues().unitConversion ||
      !getValues().measurement;

    if (fixedProduct) {
      return commonValidate || (isCustomer ? !getValues().customerId : !getValues().supplierId || !getValues().code);
    }
    return commonValidate || !getValues().product;
  };

  const getModalHeading = () => {
    if (fixedProduct) {
      return edit.target ? edit.target.contactCompany.name : `Please select a ${contCompWording} to create`;
    }
    return edit.target ? edit.target.product.productCode : 'Please select a product to create';
  };

  const getPageTitle = () => {
    const entityName = fixedProduct ? contCompWording : 'product';
    const forEntityName = fixedProduct ? 'product' : contCompWording;
    return `${state.total} ${entityName}(s) for this ${forEntityName}`;
  };

  return (
    <ProductsCustomerContext>
      <div data-testid={'products-wrapper'}>
        <SpaceVerticalContainer className={'half'} />
        <FlexContainer className={'space-between'}>
          <PlusIconAfterTitle
            pageTitle={getPageTitle()}
            onClick={onOpenAddModal}
            titleSize={'small'}
            isDisabled={!canCreate}
          />
          {isCustomer ? getShowHideColumns() : <div />}
        </FlexContainer>
        <SpaceVerticalContainer className={'half'} />
        <CCPList
          list={state.data}
          onOpenDeleteModal={onOpenDeleteModal}
          onOpenEditModal={onOpenEditModal}
          isLoading={state.isLoading}
          columns={(isCustomer ? customerColumns : supplierColumns)
            .filter(
              (item: iConfigColumn) =>
                [...(canCreate ? [] : ['add']), ...(canDelete ? [] : ['delete'])].indexOf(item.key) < 0,
            )
            .map((col: iConfigColumn) => {
              if (col.key === 'contactCompany.name' && !canUpdate) {
                return {
                  ...col,
                  type: 'text',
                };
              }
              if (col.key === 'product.productCode' && !canUpdate) {
                return {
                  ...col,
                  type: 'secondary-text',
                };
              }
              return col;
            })}
          fixedProduct={!!fixedProduct}
          isCustomer={isCustomer}
        />
        <CustomizeModal
          isOpen={edit.isModalOpen && !edit.delTargetId}
          onConfirm={handleSubmit(onSubmitMiddle)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={formValidate()}
          modalBody={
            <CCPModal
              control={control}
              errors={errors}
              onChange={setValue}
              watch={watch}
              target={edit.target}
              fixedProduct={fixedProduct}
              isCustomer={isCustomer}
            />
          }
          modalHeading={getModalHeading()}
          confirmBtnName={'Save'}
        />
        {edit.delTargetId && (
          <DeleteByRetype
            isOpen={!!edit.delTargetId}
            answer={fixedProduct ? edit.target?.contactCompany.name : edit.target?.product.productCode}
            onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
            onClose={onCloseModal}
          />
        )}
        {!!state.total && !!state.perPage && !!state.currentPage && (
          <CustomizePagination
            range={Math.ceil(state.total / state.perPage)}
            currentPage={state.currentPage}
            onChange={onSetCurrentPage}
          />
        )}
      </div>
    </ProductsCustomerContext>
  );
};

export default CCPProdContComp;
