import React from 'react';
import { CheckboxSelect } from '@atlaskit/select';
import { iLabelValuePair } from '../UITypes/types';

const CheckboxMultiSelect = ({
  name,
  value,
  label,
  onConfirm,
  selectOptions,
  placeholder,
  testId = 'inline-checkbox-select',
}: {
  name: string;
  value: iLabelValuePair[] | undefined;
  label?: string;
  onConfirm?: (options: iLabelValuePair[], newValue: iLabelValuePair[]) => void;
  selectOptions: Array<iLabelValuePair>;
  placeholder?: string;
  testId?: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (selectValue: any) => {
    const selected = selectValue || [];

    if (typeof onConfirm === 'function') {
      onConfirm(selectOptions, selected);
    }
  };

  return (
    <>
      <CheckboxSelect
        value={value}
        label={label}
        inputId={name}
        options={selectOptions}
        onChange={onChange}
        placeholder={placeholder}
        testId={testId}
      />
    </>
  );
};

export default CheckboxMultiSelect;
