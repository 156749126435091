import React from 'react';
import { DragDropContext, DraggableLocation, DropResult } from 'react-beautiful-dnd';
import Spinner from '@atlaskit/spinner';
import { useSelector } from 'react-redux';
import iJob from '../../types/job/iJob';
import ShowHideColumns from '../../shared/showHideColumns/ShowHideColumns';
import BackendPage from '../../layout/BackendPage';
import Scheduler from './schedule/Scheduler';
import PageHeading from '../../shared/heading/PageHeading';
import SearchBar from '../../shared/search/SearchBar';
import UnScheduledJobs from './schedule/unscheduled/UnScheduledJobs';
import DateMover from './schedule/dateMover/DateMover';
import UnScheduledTrigger from './schedule/unscheduled/UnScheduledTrigger';
import RightFixedWrapper from './schedule/unscheduled/RightFixedWrapper';
import TypeFilter from '../../shared/filter/TypeFilter';
import { useJobSchContext } from './JobScheduleContext';
import { FlexContainer } from '../../shared/styles/styles';
import { SearchWrapper } from './schedule/shared/Schedule.style';
import { apiErrorToast } from '../../shared/toast/Toast';
import { scheduleJobs } from '../../services/JobScheduleService';
import { mapToLabelValuePair } from '../../services/UtilsService';
import { iDateAndShift, iScheduled, iShiftJob } from './schedule/shared/Schedule.type';
import { PAGE_NAME, unScheduledInitialState } from './schedule/shared/Schedule.constant';
import { extractDateShift, isUnscheduled, move, reorder, updatedScheduledDate } from './schedule/shared/Schedule.utils';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_JOB_SCHEDULER } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../types/settings/UserAccess/iRoleAccess';

const JobSchedule = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_JOB_SCHEDULER, ACCESS_CAN_UPDATE, user);
  const {
    machines,
    selectedMachine,
    columnLists,
    columns,
    periods,
    selectedPeriod,
    startDate,
    schState,
    unSchState,
    setSchState,
    setUnSchState,
    onChangeStartDate,
    onMachineChange,
    onToggleColumn,
    onTogglePeriod,
  } = useJobSchContext();

  const findFromTargetJobs = ({ sDate, sShift, dDate, dShift }: iDateAndShift) => {
    const fromShift = schState.data
      .find((scheduled: iScheduled) => scheduled.date === sDate)
      ?.shifts.find((shift: iShiftJob) => shift.id === sShift);
    const fromJobs = fromShift ? fromShift.jobs : unSchState.jobs;

    const targetShift = schState.data
      .find((scheduled: iScheduled) => scheduled.date === dDate)
      ?.shifts.find((shift: iShiftJob) => shift.id === dShift);
    const targetJobs = targetShift ? targetShift.jobs : unSchState.jobs;

    return { fromJobs, targetJobs };
  };

  const getGroups = (source: DraggableLocation, destination: DraggableLocation, draggableId: string) => {
    const { sDate, sShift, dDate, dShift } = extractDateShift(source, destination);
    const { fromJobs, targetJobs } = findFromTargetJobs({
      sDate,
      sShift,
      dDate,
      dShift,
    });
    const unscheduleJobId =
      isUnscheduled(dDate) && fromJobs.length > source.index ? fromJobs[source.index].id : undefined;
    const { newSourceJobs, newDestJobs } = move([...fromJobs], [...targetJobs], source, destination, draggableId);
    const fromGroup =
      !isUnscheduled(sDate) && sShift
        ? {
            shiftDate: sDate,
            shiftId: sShift,
            jobIds: newSourceJobs.map((job: iJob) => job.id),
          }
        : undefined;
    const targetGroup =
      !isUnscheduled(dDate) && dShift
        ? {
            shiftDate: dDate,
            shiftId: dShift,
            jobIds: newDestJobs.map((job: iJob) => job.id),
          }
        : undefined;
    return {
      fromGroup,
      targetGroup,
      newSourceJobs,
      newDestJobs,
      unscheduleJobId,
    };
  };

  const moveBetweenShifts = async (source: DraggableLocation, destination: DraggableLocation, draggableId: string) => {
    if (!selectedMachine) return;
    const { fromGroup, targetGroup, newSourceJobs, newDestJobs, unscheduleJobId } = getGroups(
      source,
      destination,
      draggableId,
    );

    try {
      await scheduleJobs({
        fromGroup,
        targetGroup,
        unscheduleJobId,
        machineId: selectedMachine?.id,
      });
    } catch (error) {
      apiErrorToast(error);
      return;
    }
    if (isUnscheduled(source.droppableId) && typeof setUnSchState === 'function') {
      setUnSchState({ ...unSchState, jobs: newSourceJobs });
    }
    if (isUnscheduled(destination.droppableId) && typeof setUnSchState === 'function') {
      setUnSchState({ ...unSchState, jobs: newDestJobs });
    }
    // when move from unschedule to schedule, machineId needs to be updated
    const newSchData = updatedScheduledDate({
      previous: schState.data,
      fromGroup,
      targetGroup,
      newSourceJobs,
      newDestJobs: newDestJobs.map((job: iJob) => ({
        ...job,
        machineId: selectedMachine.id,
      })),
    });
    if (typeof setSchState === 'function') {
      setSchState({
        ...schState,
        data: newSchData,
      });
    }
  };

  const reOrderScheduledShift = async (source: DraggableLocation, destination: DraggableLocation) => {
    if (!selectedMachine) return;
    const { sDate, sShift } = extractDateShift(source, destination);
    const targetShift = schState.data
      .find((scheduled: iScheduled) => scheduled.date === sDate)
      ?.shifts.find((shift: iShiftJob) => shift.id === sShift);
    if (!targetShift) return;
    const newJobs = reorder(targetShift.jobs, source.index, destination.index);
    const targetGroup = {
      shiftDate: sDate,
      shiftId: targetShift.id,
      jobIds: newJobs.map((job: iJob) => job.id),
    };
    try {
      await scheduleJobs({
        targetGroup,
        machineId: selectedMachine.id,
      });
    } catch (error) {
      apiErrorToast(error);
      return;
    }
    if (typeof setSchState === 'function') {
      setSchState({
        ...schState,
        data: schState.data.map((s: iScheduled) =>
          s.date === sDate
            ? {
                ...s,
                shifts: s.shifts.map((shift: iShiftJob) => (shift.id === sShift ? { ...shift, jobs: newJobs } : shift)),
              }
            : s,
        ),
      });
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    // if no machine is selected, don't allow move jobs
    if (!selectedMachine) return;
    // same droppableId means reorder not move
    if (source.droppableId === destination.droppableId) {
      // reorder unscheduled not make sense
      if (isUnscheduled(source.droppableId)) {
        return;
      }
      reOrderScheduledShift(source, destination);
      return;
    }
    moveBetweenShifts(source, destination, draggableId);
  };

  const getPageHeader = () => (
    <>
      <PageHeading className={'space-below'} content={PAGE_NAME} />
      <FlexContainer className={'space-between'}>
        <TypeFilter
          options={mapToLabelValuePair(machines)}
          onSelect={(id: string) => typeof onMachineChange === 'function' && onMachineChange(id)}
          selectedTypes={[selectedMachine?.id || '']}
        />
        <FlexContainer>
          <UnScheduledTrigger
            count={unSchState.jobs.length}
            onClick={() => typeof setUnSchState === 'function' && setUnSchState({ ...unSchState, isShow: true })}
          />
          <SearchWrapper>
            <SearchBar
              onSearch={(keyword: string) => typeof setSchState === 'function' && setSchState({ ...schState, keyword })}
              keyword={schState.keyword}
              placeholder={'search by job number'}
            />
          </SearchWrapper>
        </FlexContainer>
      </FlexContainer>
    </>
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <BackendPage pageHeader={getPageHeader()}>
        {schState.isLoading ? (
          <Spinner size={'large'} />
        ) : (
          <>
            <FlexContainer className={'space-between space-below'}>
              <FlexContainer>
                <DateMover
                  date={startDate}
                  onChangeDate={(newDate: string) =>
                    typeof onChangeStartDate === 'function' && onChangeStartDate(newDate)
                  }
                  gap={selectedPeriod}
                />
                <TypeFilter
                  options={periods}
                  selectedTypes={[selectedPeriod]}
                  onSelect={(gap: string) => typeof onTogglePeriod === 'function' && onTogglePeriod(gap)}
                />
              </FlexContainer>
              <ShowHideColumns
                columnLists={columnLists}
                selectedColumns={columns}
                onToggleColumns={(columnName: string, value: boolean) =>
                  typeof onToggleColumn === 'function' && onToggleColumn(columnName, value)
                }
              />
            </FlexContainer>
            <RightFixedWrapper
              isFixedShown={unSchState.isShow}
              fixed={
                <UnScheduledJobs
                  unScheduledData={unSchState}
                  onCloseUnScheduled={() =>
                    typeof setUnSchState === 'function' &&
                    setUnSchState({
                      ...unSchState,
                      isShow: false,
                      keyword: unScheduledInitialState.keyword,
                    })
                  }
                  onSearch={(keyword: string) =>
                    typeof setUnSchState === 'function' && setUnSchState({ ...unSchState, keyword })
                  }
                  currentMachine={selectedMachine}
                  isDisabled={!canUpdate}
                />
              }
            >
              <Scheduler scheduled={schState.data} shiftsSort={schState.shiftsSort} isDisabled={!canUpdate} />
            </RightFixedWrapper>
          </>
        )}
      </BackendPage>
    </DragDropContext>
  );
};

export default JobSchedule;
