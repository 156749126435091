import React from 'react';
import { FlexContainer, PageTitle as PageTitleWrapper } from '../styles/styles';

type iPageTitle = {
  title: string | React.ReactElement;
  className?: string;
  titleClassName?: string;
  actionButtons?: React.ReactElement;
};

const PageTitle = ({ title, className, titleClassName, actionButtons }: iPageTitle) => {
  const getActionButtons = () => {
    if (!actionButtons) {
      return null;
    }
    return <FlexContainer>{actionButtons}</FlexContainer>;
  };
  return (
    <FlexContainer className={className}>
      <PageTitleWrapper className={titleClassName}>{title}</PageTitleWrapper>
      {getActionButtons()}
    </FlexContainer>
  );
};

export default PageTitle;
