import React, { useState } from 'react';
import { LoadingButton } from '@atlaskit/button';
import { useForm } from 'react-hook-form';
import RenderForm from '../shared/RenderForm';
import RedirectToActivation from '../shared/RedirectToActivation';
import PageHeading from '../../../shared/heading/PageHeading';
import BackToLogin from '../shared/BackToLogin';
import { generateInitialValues } from '../shared/Form.utils';
import { SpaceVerticalContainer } from '../../../shared/styles/styles';
import { retrieval } from '../../../services/AuthService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_DESCRIPTION,
  FORGOT_PASSWORD_FIELDS,
  FORGOT_SUBMIT,
  RETRIEVAL_HINT,
} from '../shared/Auth.constants';

type iState = {
  isLoading: boolean;
  success: boolean;
};
const initialState: iState = {
  isLoading: false,
  success: false,
};
const ForgotPassword = () => {
  const [state, setState] = useState(initialState);
  const { control, errors, handleSubmit } = useForm({
    defaultValues: generateInitialValues(FORGOT_PASSWORD_FIELDS),
    reValidateMode: 'onBlur',
  });
  // eslint-disable-next-line
  const onSubmit = async (data: any) => {
    setState({ ...state, isLoading: true });
    try {
      await retrieval(data);
      setState({
        ...state,
        isLoading: true,
        success: true,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isLoading: false });
    }
  };

  return (
    <>
      {state.success ? (
        <RedirectToActivation message={RETRIEVAL_HINT} />
      ) : (
        <>
          <PageHeading content={FORGOT_PASSWORD} className={'space-below'} />
          <div>{FORGOT_PASSWORD_DESCRIPTION}</div>
          <SpaceVerticalContainer className={'quarter'} />
          <RenderForm
            control={control}
            fields={FORGOT_PASSWORD_FIELDS}
            errors={errors}
          />
          <SpaceVerticalContainer className={'half'} />
          <LoadingButton
            appearance={'primary'}
            isLoading={state.isLoading}
            shouldFitContainer
            onClick={handleSubmit(onSubmit)}
          >
            {FORGOT_SUBMIT}
          </LoadingButton>
        </>
      )}
      <SpaceVerticalContainer className={'quarter'} />
      <BackToLogin />
    </>
  );
};

export default ForgotPassword;
