import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getAOItemList = (config?: iParams) => {
  return AppService.get('/acquisitionOrderItem', {
    include: 'product.measurement,measurement,unitPriceMeasurement',
    ...config,
  }).then(({ data }) => data);
};

export const getAOItemDetail = (id: string) => {
  return AppService.get(`/acquisitionOrderItem/${id}`, {
    include: 'product.measurement,measurement,unitPriceMeasurement',
  }).then(({ data }) => data);
};

export const createAOItem = (postData: iKeyValuePairs) => {
  return AppService.post('/acquisitionOrderItem', postData).then(
    ({ data }) => data,
  );
};

export const updateAOItem = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/acquisitionOrderItem/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const deleteAOItem = (id: string) => {
  return AppService.delete(`/acquisitionOrderItem/${id}`).then(
    ({ data }) => data,
  );
};

export const getAOItemListEagerLoadOrder = (config?: iParams) => {
  return AppService.get('/acquisitionOrderItem', {
    include:
      'product.measurement,measurement,unitPriceMeasurement,acquisitionOrder.status',
    ...config,
  }).then(({ data }) => data);
};

export default {
  getAOItemList,
  getAOItemDetail,
  createAOItem,
  updateAOItem,
  deleteAOItem,
  getAOItemListEagerLoadOrder,
};
