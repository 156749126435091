import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import iProductAttribute from '../../../../types/product/iProductAttribute';
import iProductAttributeValue from '../../../../types/product/iProductAttributeValue';
import ProductAttributesRender from './ProductAttributesRender';
import { apiErrorToast } from '../../../toast/Toast';
import { getProdAttributes } from '../../../../services/product/ProductAttributeService';
import { getProdAttributeValues } from '../../../../services/product/ProductAttributeValueService';
import { getProductAttributes } from '../../../../pages/job/jobDetail/shared/JobDetail.utils';

type iState = {
  isLoading: boolean;
  productAttributes: Array<iProductAttribute>;
  productAttributesValues: Array<iProductAttributeValue>;
};
const initialState: iState = {
  isLoading: true,
  productAttributes: [],
  productAttributesValues: [],
};

const ProductAttributesData = ({
  productId,
  productAttributeSetId,
}: {
  productId: string;
  productAttributeSetId: string;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      if (!productId || !productAttributeSetId) {
        setState(prevState => ({ ...prevState, isLoading: false }));
        return;
      }
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));
        const productAttributes: Array<iProductAttribute> = await getProdAttributes({
          filter: `attributeSetId:${productAttributeSetId}`,
          sort: 'sortOrder:ASC',
        });
        const productAttributesValues: Array<iProductAttributeValue> = await getProdAttributeValues({
          filter: `productId:${productId}`,
        });

        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          productAttributes,
          productAttributesValues,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [productId, productAttributeSetId]);

  if (state.isLoading) return <Spinner />;
  return (
    <ProductAttributesRender
      attributes={getProductAttributes(state.productAttributes, state.productAttributesValues)}
    />
  );
};

export default ProductAttributesData;
