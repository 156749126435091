import React from 'react';
import {
  assembleAddress,
  formatDate,
  handleMoney,
  handleNullException,
} from '../../../../services/UtilsService';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import CreatedOrUpdated from '../../../../shared/createdOrUpdated/CreatedOrUpdated';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import iReceivingItem from '../../../../types/purchases/iReceivingItem';
import { iConfigColumn } from '../../../despatchNote/shared/DispatchNote.type';

export const getRows = (
  data: Array<iReceivingItem>,
  columns: Array<iConfigColumn>,
) => {
  return data.map((item: iReceivingItem) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'date':
          return {
            key: handleNullException(item, key),
            content: formatDate(handleNullException(item, key), 'Do MMM YYYY'),
          };
        case 'money':
          return {
            key: handleNullException(item, key),
            content: handleMoney(item, key, 2),
          };
        case 'address':
          return {
            key: handleNullException(item, key),
            content: assembleAddress(handleNullException(item, key)),
          };
        case 'link-two-layer':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText
                secondaryText={handleNullException(item, dataSource[1])}
              >
                <LinkBtnUrl
                  btnName={handleNullException(item, key)}
                  url={`${column.urlPrefix}/${handleNullException(
                    item,
                    dataSource[0],
                  )}`}
                />
              </ComposeSecondaryText>
            ),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(
                  item,
                  dataSource[0],
                )}`}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        case 'qty':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, dataSource[1])
              ? `${
                  Number(item.qty) /
                  Number(handleNullException(item, dataSource[2]))
                } ${handleNullException(item, dataSource[1])} = ${
                  item.qty
                } ${handleNullException(item, dataSource[3])}`
              : `${item.qty} ${handleNullException(item, dataSource[3])} = ${
                  item.qty
                } ${handleNullException(item, dataSource[3])}`,
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'admin-purchaseOrder-row' };
  });
};
