import React, { useState } from 'react';
import Badge from '@atlaskit/badge';
import moment from 'moment/moment';
import iJob from '../../../types/job/iJob';
import { iScheduled, iShiftJob } from './shared/Schedule.type';
import { JobScheduleListWrapper } from './shared/Schedule.style';
import { iLabelValuePair } from '../../../shared/UITypes/types';
//  eslint-disable-next-line
import ScheduleDroppable from './dnd/ScheduleDroppable';
//  eslint-disable-next-line
import ScheduleDateTitle from './shared/ScheduleDateTitle';
import MathHelper from '../../../services/MathHelper';
import { formatDate, handleNullException, numberFormat } from '../../../services/UtilsService';
import CustomizeModal from '../../../shared/modal/CustomizeModal';

const Scheduler = ({
  scheduled,
  shiftsSort,
  isDisabled,
}: {
  scheduled: Array<iScheduled>;
  shiftsSort: Array<iLabelValuePair>;
  isDisabled?: boolean;
}) => {
  const [showingTotalPopup, setShowingTotalPopup] = useState(false);
  const sumQtyToMakeMap = (target: iScheduled) => {
    const sumQtyToMakeShiftMap = (shift: iShiftJob): { [key: string]: number } =>
      shift.jobs.reduce((map: { [key: string]: number }, cur: iJob) => {
        const measureShortName = `${handleNullException(cur, 'product.measurement.shortName') || ''}`;
        if (!(measureShortName in map)) {
          return {
            ...map,
            [measureShortName]: cur.qtyToMake,
          };
        }

        return {
          ...map,
          [measureShortName]: MathHelper.add(map[measureShortName], cur.qtyToMake),
        };
      }, {});
    return target.shifts
      .map((cur: iShiftJob) => sumQtyToMakeShiftMap(cur))
      .reduce((map, curr) => {
        return {
          ...map,
          ...Object.keys(curr).reduce((m, key) => {
            if (!(key in map)) {
              return {
                ...m,
                [key]: curr[key],
              };
            }

            return {
              ...m,
              [key]: MathHelper.add(map[key], curr[key]),
            };
          }, {}),
        };
      }, {});
  };

  const getSumQty = (s: iScheduled) => {
    const sumMap = sumQtyToMakeMap(s);
    const shortNames = Object.keys(sumMap);
    if (shortNames.length < 1) {
      return null;
    }
    if (shortNames.length === 1) {
      return <Badge appearance="added">{`${numberFormat(sumMap[shortNames[0]])} ${shortNames[0]}`}</Badge>;
    }
    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span onClick={() => setShowingTotalPopup(true)}>
          <Badge appearance="primary" testId={'multi-sum'}>
            Multi
          </Badge>
        </span>
        <CustomizeModal
          width={'small'}
          isOpen={showingTotalPopup}
          modalHeading={`${formatDate(moment(s.date, 'DD/MM/YYYY').toString(), 'DD MMM YYYY [(]ddd[)]')} Summary`}
          modalBody={
            <>
              {shortNames.map(shortName => (
                <div key={shortName}>
                  {numberFormat(sumMap[shortName])} {shortName}
                </div>
              ))}
            </>
          }
          shouldScrollInViewport
          onConfirm={() => setShowingTotalPopup(false)}
          confirmBtnName={'OK'}
        />
      </>
    );
  };

  return (
    <JobScheduleListWrapper>
      {shiftsSort.map((shift: iLabelValuePair, index: number) => (
        <div className={'day-shift-wrapper'} key={shift.value}>
          {scheduled.map((s: iScheduled) => {
            const targetShift = s.shifts.find((shiftJob: iShiftJob) => shiftJob.id === shift.value);
            if (!targetShift) return null;
            return (
              <div key={s.date}>
                {index === 0 && (
                  <ScheduleDateTitle date={s.date} shiftsSort={shiftsSort} isDisabled={isDisabled}>
                    {getSumQty(s)}
                  </ScheduleDateTitle>
                )}
                <ScheduleDroppable date={s.date} jobs={targetShift.jobs} shift={shift} isDisabled={isDisabled} />
              </div>
            );
          })}
        </div>
      ))}
    </JobScheduleListWrapper>
  );
};
export default Scheduler;
