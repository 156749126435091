import React from 'react';
// import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';

import iPoReceiving from '../../../../types/purchases/iPoReceiving';
import iEntityCategory from '../../../../types/status/iEntityCategory';
import {
  PO_RECEIVES_TABLE_NAME,
  PO_RECEIVES_TABLE_TEST_ID,
} from '../../constants';
import { getHeads, getRows } from './PoReceiveTableHelper';

// const TableWrapper = styled.div``;

const PoReceiveTable = ({
  data,
  isLoading,
  onDelete,
  columns,
  categories,
}: {
  data: iPoReceiving[];
  isLoading: boolean;
  onDelete: (id: string) => void;
  columns: string[];
  categories: Array<iEntityCategory>;
}) => {
  return (
    // <TableWrapper>
    <div>
      <DynamicTable
        head={getHeads(columns, PO_RECEIVES_TABLE_NAME)}
        rows={getRows(data, onDelete, columns, categories)}
        testId={PO_RECEIVES_TABLE_TEST_ID}
        isLoading={isLoading}
      />
    </div>
    // </TableWrapper>
  );
};

export default PoReceiveTable;
