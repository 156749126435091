import React from 'react';
import iChannelScript, {
  CHANNEL_SCRIPT_SCRIPT_MYOB,
  CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE,
} from '../../types/integration/iChannelScript';
import { getCurrentBaseUrl } from '../../services/UtilsService';
import { SETTINGS_INTEGRATIONS_CALLBACK_URL } from '../UrlMap';
import iChannel from '../../types/integration/iChannel';
import ChannelService from '../../services/integration/ChannelService';
import myobLogoSVG from './svgs/myob-logo.svg';
import wooCommerceLogoSVG from './svgs/woocommerce-logo.svg';
import MyobChannelEditPanel from '../channel/myob/MyobChannelEditPanel';
import WooCommerceEditPanel from '../channel/wooCommerce/setup/WooCommerceEditPanel';
import WooCommerceProductEdit from '../channel/wooCommerce/product/WooCommerceProductDetails';
import {
  iChannelCreationCallBackParams,
  iChannelEditPanelComponentParams,
  iWooCommerceProductEdit,
} from './ChannelScriptHelperTypes';
import ChannelScriptService from '../../services/integration/ChannelScriptService';
import MyobChannelSetupCallback from '../channel/myob/MyobChannelSetupCallback';
import WooCommerceChannelSetupCallback from '../channel/wooCommerce/setup/WooCommerceChannelSetupCallback';

const getOAuthUrlData = (channelScript: iChannelScript, channel: iChannel) => {
  switch (channelScript.script) {
    case CHANNEL_SCRIPT_SCRIPT_MYOB: {
      return {
        redirectUrl:
          process.env.REACT_APP_MYOB_REDIRECT_URL || `${getCurrentBaseUrl()}${SETTINGS_INTEGRATIONS_CALLBACK_URL}`,
      };
    }
    case CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE: {
      return {
        redirectUrl: `${getCurrentBaseUrl()}${SETTINGS_INTEGRATIONS_CALLBACK_URL}?state=${channel.id}`,
      };
    }
    default: {
      return {};
    }
  }
};

const redirectLocation = (channelScript: iChannelScript, channel: iChannel, redirectUrl: string) => {
  switch (channelScript.script) {
    case CHANNEL_SCRIPT_SCRIPT_MYOB: {
      window.location.href = `${redirectUrl}&state=${channel.id}`;
      break;
    }
    case CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE: {
      window.location.href = redirectUrl;
      break;
    }
    default: {
      break;
    }
  }
};

const handleChannelRedirect = async (channelScript: iChannelScript, data = {}, channel?: iChannel) => {
  const newChannel =
    channel ||
    (await ChannelService.createChannel({
      name: channelScript.name,
      scriptId: channelScript.id,
      settings: data,
    }));
  const authData = getOAuthUrlData(channelScript, newChannel);
  const { url: redirectUrl } = await ChannelScriptService.getChannelScriptOauth2Url(channelScript.id, {
    ...data,
    ...authData,
    channelId: newChannel.id,
  });
  redirectLocation(channelScript, newChannel, redirectUrl);
};

const requireChannelCreationForm = (channelScript?: iChannelScript) => {
  if (!channelScript) {
    return false;
  }
  switch (channelScript.script) {
    case CHANNEL_SCRIPT_SCRIPT_MYOB: {
      return false;
    }
    case CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE: {
      return true;
    }
    default: {
      return false;
    }
  }
};

const getLogoUrl = (channelScript: iChannelScript): string => {
  switch (channelScript.script) {
    case CHANNEL_SCRIPT_SCRIPT_MYOB: {
      return myobLogoSVG;
    }
    case CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE: {
      return wooCommerceLogoSVG;
    }
    default: {
      return '';
    }
  }
};

const getChannelEditPanelComponent = (params: iChannelEditPanelComponentParams) => {
  const { channelScript } = params;
  switch (channelScript.script) {
    case CHANNEL_SCRIPT_SCRIPT_MYOB: {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <MyobChannelEditPanel {...params} />;
    }
    case CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE: {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WooCommerceEditPanel {...params} />;
    }
    default: {
      return null;
    }
  }
};

const getChannelProductEditPanelComponent = (params: iWooCommerceProductEdit) => {
  const { channelScript } = params;
  switch (channelScript.script) {
    case CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE: {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WooCommerceProductEdit {...params} />;
    }
    default: {
      return null;
    }
  }
};

const getChannelSetupCallbackHandler = (params: iChannelCreationCallBackParams) => {
  const { channelScript } = params;
  switch (channelScript.script) {
    case CHANNEL_SCRIPT_SCRIPT_MYOB: {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <MyobChannelSetupCallback {...params} />;
    }
    case CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE: {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WooCommerceChannelSetupCallback {...params} />;
    }
    default: {
      return null;
    }
  }
};

export default {
  getLogoUrl,
  getChannelEditPanelComponent,
  getChannelProductEditPanelComponent,
  handleChannelRedirect,
  requireChannelCreationForm,
  getChannelSetupCallbackHandler,
};
