import React from 'react';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import iJob from '../../../../types/job/iJob';
import { DATE_SHIFT_CONNECTOR } from '../shared/Schedule.constant';
import { iLabelValuePair } from '../../../../shared/UITypes/types';
import { getListStyle } from '../shared/Schedule.utils';
import { ucFirst } from '../../../../services/UtilsService';
//  eslint-disable-next-line
import DraggableList from '../dnd/DraggableList';

const UnScheduleDroppable = ({
  date,
  shift,
  jobs,
  isDragDisabled,
}: {
  date: string;
  shift: iLabelValuePair;
  jobs: Array<iJob>;
  isDragDisabled?: boolean;
}) => {
  return (
    <Droppable key={date + DATE_SHIFT_CONNECTOR + shift.label} droppableId={date + DATE_SHIFT_CONNECTOR + shift.label}>
      {(providedDrop: DroppableProvided, snapshotDrop: DroppableStateSnapshot) => (
        <div
          ref={providedDrop.innerRef}
          style={getListStyle(snapshotDrop.isDraggingOver, isDragDisabled || false)}
          //    eslint-disable-next-line
          {...providedDrop.droppableProps}
        >
          <div className={'shift-name'}>{ucFirst(shift.label)}</div>
          <DraggableList jobs={jobs} date={date} shift={shift} isDragDisabled={isDragDisabled} />
          {providedDrop.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default UnScheduleDroppable;
