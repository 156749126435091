import React from 'react';
import iJob from '../../../../types/job/iJob';
import iMachine from '../../../../types/machine/iMachine';
//  eslint-disable-next-line
import UnScheduleDroppable from './UnScheduleDroppable';
import { iSortOrderType } from '../../../../shared/UITypes/types';
import { iUnscheSortOption } from '../shared/Schedule.type';
import { compareDate, compareStr, handleNullExceptionMultiFields } from '../../../../services/UtilsService';

const UnScheduleMachineGroups = ({
  jobs,
  currentMachine,
  date,
  sortKey,
  sortOrder,
  isDisabled,
}: {
  jobs: Array<iJob>;
  currentMachine: iMachine;
  date: string;
  sortKey?: iUnscheSortOption;
  sortOrder: iSortOrderType;
  isDisabled?: boolean;
}) => {
  const sortJobList = (list: Array<iJob>) => {
    if (!sortKey || !sortOrder) return list;
    return list.sort((a: iJob, b: iJob) => {
      let compareResult = 0;
      const { value, type } = sortKey;
      const aValue = handleNullExceptionMultiFields(a, value);
      const bValue = handleNullExceptionMultiFields(b, value);
      switch (type) {
        case 'date':
          compareResult = compareDate(aValue, bValue);
          break;
        case 'number':
          compareResult = Number(aValue) - Number(bValue);
          break;
        default:
          compareResult = compareStr(aValue, bValue);
          break;
      }
      return sortOrder === 'ASC' ? compareResult : -1 * compareResult;
    });
  };

  const specialGroupJobs = sortJobList(jobs.filter((job: iJob) => job.machineId === currentMachine.id));
  const otherJobs = sortJobList(jobs.filter((job: iJob) => job.machineId !== currentMachine.id));

  return (
    <>
      <UnScheduleDroppable
        jobs={specialGroupJobs}
        date={date}
        shift={{ label: currentMachine.name, value: '' }}
        isDragDisabled={isDisabled}
      />
      <UnScheduleDroppable
        jobs={otherJobs}
        date={date}
        shift={{ label: 'others', value: '' }}
        isDragDisabled={isDisabled}
      />
    </>
  );
};

export default UnScheduleMachineGroups;
