import React, { useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';

import { getHeads } from '../../../services/UtilsService';
import { iConfigColumn, iSortOrderType } from '../../../shared/UITypes/types';
import iIssue from '../../../types/NCR/iIssue';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { NCR_TABLE_NAME, NCR_TABLE_TEST_ID } from './NCRList.constant';
import { getRows } from './NCRList.tableHelper';
import { NCRTableWrapper } from './NCRList.style';

const NCRListTable = ({
  isLoading,
  data,
  onOpenDeleteModal,
  onSetSort,
  columns,
  categories,
  canDelete = true,
}: {
  canDelete?: boolean;
  isLoading: boolean;
  data: iIssue[];
  onOpenDeleteModal: (id: string) => void;
  onSetSort: (sortStr: string) => void;
  columns: Array<iConfigColumn>;
  categories: Array<iEntityCategory>;
}) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  return (
    <NCRTableWrapper>
      <DynamicTable
        head={getHeads(columns, NCR_TABLE_NAME)}
        rows={getRows(data, canDelete ? onOpenDeleteModal : undefined, columns, categories)}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        onSort={
          //  eslint-disable-next-line
          (data: any) => {
            setSortKey(data.key);
            setSortOrder(data.sortOrder);
            onSetSort(`${data.key}:${data.sortOrder}`);
          }
        }
        sortKey={sortKey}
        sortOrder={sortOrder}
        testId={NCR_TABLE_TEST_ID}
      />
    </NCRTableWrapper>
  );
};

export default NCRListTable;
