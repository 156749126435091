import React, { useState, useCallback } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import SectionMessage from '@atlaskit/section-message';
import { useSelector } from 'react-redux';
import iAddress from '../../../../types/contactCompany/iAddress';
import iDispatchNoteItem from '../../../../types/dispatchNote/iDispatchNoteItem';
import DeleteByRetype from '../../../../shared/deleteWithReType/DeleteByReType';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import CreateNoteItem from './CreateNoteItem';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import CompareAddressInfo from '../createDNItem/shared/CompareAddressInfo';
import { NA } from '../../../../shared/constants/ActionConstants';
import { compareAddress, getHeads, getRows } from './TableHelper';
import { handleNullException } from '../../../../services/UtilsService';
import { iDetailUpdateEagerLoadFn, iDetailUpdateFn, iParams } from '../../../../shared/UITypes/types';
import { addToastForAPIResponse, apiErrorToast } from '../../../../shared/toast/Toast';
import AddressService from '../../../../services/AddressService';
import {
  createDispatchNoteItem,
  deactivateDispatchNoteItem,
  getDispatchNoteItemList,
  updateDispatchNoteItem,
} from '../../../../services/DispatchNoteItemService';
import { SERVICE_PRODIVDER_SHIPPING, SO_SHIPPING_WORDING } from '../createDNItem/shared/CreateDNItem.constants';
import { RootState } from '../../../../redux/makeReduxStore';

type iOverwriteState = {
  overwrite: boolean;
  isConfirming: boolean;
};
const initialState: iOverwriteState = {
  overwrite: false,
  isConfirming: false,
};
const DispatchNoteItems = ({
  dispatchNoteId,
  customerId,
  columns,
  DNShippingAddress,
  onUpdateDetail,
  onUpdateLocal,
  isFinished,
  isServiceProvider = false,
  customerShippingAddress,
  canDelete,
  canUpdate,
  canCreate,
}: {
  dispatchNoteId: string;
  customerId?: string;
  columns: Array<string>;
  DNShippingAddress?: iAddress;
  onUpdateDetail: iDetailUpdateFn;
  onUpdateLocal: iDetailUpdateEagerLoadFn;
  isFinished: boolean;
  isServiceProvider?: boolean;
  canDelete?: boolean;
  canUpdate?: boolean;
  canCreate?: boolean;
  customerShippingAddress?: iAddress;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [overwriteState, setOverwriteState] = useState(initialState);

  const getItems = useCallback((config?: iParams) => getDispatchNoteItemList(dispatchNoteId, config), [dispatchNoteId]);

  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenDeleteModal,
    onCreate,
    onEdit,
  } = useListCrudHook<iDispatchNoteItem>({
    getFn: getItems,
    deleteFn: deactivateDispatchNoteItem,
    createFn: createDispatchNoteItem,
    updateFn: updateDispatchNoteItem,
    paginationApplied: true,
    sort: 'createdAt:DESC',
    createCallBack: (createId: string) => {
      if (state.data.length === 0) setOverwriteState({ ...overwriteState, overwrite: true });
      setTimeout(() => {
        //  eslint-disable-next-line
        document.getElementById(`${createId}-quantity`)?.click();
        addToastForAPIResponse('success');
      }, 200);
    },
    //  eslint-disable-next-line
    editCallBack: (createId: string) => addToastForAPIResponse('success'),
  });

  //  eslint-disable-next-line
  const overwriteAddressFrom = isServiceProvider
    ? customerShippingAddress
    : state.data.length > 0
    ? state.data[0].salesOrderItem?.salesOrder?.shippingAddress
    : undefined;

  const overwriteAddressWording = isServiceProvider ? SERVICE_PRODIVDER_SHIPPING : SO_SHIPPING_WORDING;
  const onOverwriteAddress = async () => {
    if (!overwriteAddressFrom) return;
    const content = {
      street: overwriteAddressFrom.street,
      suburb: overwriteAddressFrom.suburb,
      state: overwriteAddressFrom.state,
      country: overwriteAddressFrom.country,
      postcode: overwriteAddressFrom.postcode,
      name: overwriteAddressFrom.name,
    };
    try {
      const newAddress: iAddress = DNShippingAddress
        ? await AddressService.editAddress(DNShippingAddress.id, content)
        : await AddressService.createAddress(content);
      if (!DNShippingAddress) {
        await onUpdateDetail({ shippingAddressId: newAddress.id });
      }
      onUpdateLocal({
        shippingAddressId: newAddress.id,
        shippingAddress: newAddress,
      });
      setOverwriteState({
        ...overwriteState,
        isConfirming: false,
        overwrite: false,
      });
    } catch (error) {
      apiErrorToast(error);
      setOverwriteState({ ...overwriteState, isConfirming: false });
    }
  };

  const getFilteredColumns = (cols: string[]) => {
    return cols.filter(col => {
      if (col === 'Operation' && !canDelete) {
        return false;
      }
      return true;
    });
  };

  return (
    <>
      <DynamicTable
        head={getHeads(getFilteredColumns(columns), 'dispatchNoteItem')}
        rows={getRows(
          state.data,
          onOpenDeleteModal,
          onEdit,
          getFilteredColumns(columns),
          isFinished || !canUpdate,
          customerId,
          user,
        )}
        testId={'despatchNote-Item-table'}
        isLoading={state.isLoading}
      />
      {!isFinished && canCreate && (
        <CreateNoteItem
          dispatchNoteId={dispatchNoteId}
          customerId={customerId}
          DNShippingAddress={DNShippingAddress}
          DNItemLength={state.data.length}
          onCreate={onCreate}
          isServiceProvider={isServiceProvider}
          customerShippingAddress={customerShippingAddress}
        />
      )}
      {edit.delTargetId && (
        <DeleteByRetype
          isOpen={edit.isModalOpen}
          onClose={onCloseModal}
          onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
          answer={handleNullException(edit, 'target.product.productCode') || NA}
          isConfirming={state.isConfirming}
        />
      )}
      {overwriteState.overwrite && state.data.length === 1 && !compareAddress(DNShippingAddress, overwriteAddressFrom) && (
        <CustomizeModal
          isOpen={overwriteState.overwrite && state.data.length === 1}
          onCancel={() => setOverwriteState({ ...overwriteState, overwrite: false })}
          onConfirm={onOverwriteAddress}
          modalBody={
            <SectionMessage appearance={'warning'}>
              <CompareAddressInfo
                DNShippingAddress={DNShippingAddress}
                SOWording={overwriteAddressWording}
                SOShippingAddress={overwriteAddressFrom}
              />
            </SectionMessage>
          }
          confirmBtnName={'Overwrite'}
          modalHeading={'Do you want to overwrite the shipping address?'}
        />
      )}
    </>
  );
};

export default DispatchNoteItems;
