/* eslint-disable */
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Spinner from '@atlaskit/spinner';
import ActivateForm from './ActivateForm';
import AppPageCentered from '../shared/AppPageCentered';
import iUser from '../../../types/user/iUser';
import {
  SIGN_UP_SUCCESS_HINT,
  USER_ACTIVATE_FIELDS,
} from '../shared/Auth.constants';
import { validateTempToken } from '../../../services/AuthService';
import { initialFieldValues } from '../shared/Form.utils';
import {
  addToastForAPIResponse,
  apiErrorToast,
} from '../../../shared/toast/Toast';
import RedirectAfterSuccessAction from '../shared/RedirectToActivation';
import ResendToken from '../shared/ResendToken';
import CompanyLogo from '../../../shared/company/CompanyLogo';

type iState = {
  isLoading: boolean;
  isValid: boolean;
  user?: iUser;
};
const initialState: iState = {
  isLoading: true,
  isValid: false,
};
const initialRange = ['firstName', 'lastName', 'company.name', 'email'];
const Activate = () => {
  const { token } = queryString.parse(window.location.search);
  const [state, setState] = useState(initialState);

  // initialize flex fields by activation token
  useEffect(() => {
    let isCancelled = false;
    const validate = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const { isValid, message, user } = await validateTempToken(
          token as string,
        );
        if (!isValid) {
          addToastForAPIResponse('error', message);
          setState(prevState => ({
            ...prevState,
            isLoading: false,
            isValid: false,
          }));
          return;
        }
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          isValid: true,
          user,
        }));
      } catch (error) {
        apiErrorToast(error);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          isValid: false,
        }));
      }
    };
    validate();
    return () => {
      isCancelled = true;
    };
  }, [token]);

  if (state.isLoading)
    return (
      <AppPageCentered>
        <Spinner />
      </AppPageCentered>
    );

  return (
    <AppPageCentered>
      <CompanyLogo />
      {state.isValid ? (
        <ActivateForm
          fields={initialFieldValues({
            user: state.user,
            fields: USER_ACTIVATE_FIELDS,
            initialRange,
          })}
          onSubmitExtraFields={{
            token: token as string,
            userId: state.user?.id,
          }}
        />
      ) : (
        <ResendToken
          resendSubmitData={{
            token: token as string,
            activateCompany: 'true',
          }}
        >
          <RedirectAfterSuccessAction message={SIGN_UP_SUCCESS_HINT} />
        </ResendToken>
      )}
    </AppPageCentered>
  );
};

export default Activate;
