import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import LinkBtn from '../../../../shared/buttons/LinkBtn';
import TransparentIconBtn from '../../../../shared/buttons/TransparentIconBtn';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import iStockTakeItem from '../../../../types/stockTake/iStockTakeItem';
import { iConfigColumn } from '../../../../shared/UITypes/types';
import { handleNullException } from '../../../../services/UtilsService';

export const getRows = (
  data: Array<iStockTakeItem>,
  columns: Array<iConfigColumn>,
  onOpenEditModal: (targetId: string) => void,
  onOpenDeleteModal: (targetId: string) => void,
  isWIP: boolean,
) => {
  return data.map((item: iStockTakeItem) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'edit-trigger':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[0])}>
                <LinkBtn
                  btnName={handleNullException(item, key)}
                  onClick={() => onOpenEditModal(handleNullException(item, 'id'))}
                />
              </ComposeSecondaryText>
            ),
          };
        case 'text-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[0])}>
                {handleNullException(item, key)}
              </ComposeSecondaryText>
            ),
          };
        case 'delete':
          return {
            key: handleNullException(item, key),
            content: isWIP ? (
              <TransparentIconBtn
                icon={<TrashIcon label="delete" />}
                onClick={() => onOpenDeleteModal(handleNullException(item, 'id'))}
              />
            ) : null,
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};
