import { iConfigColumn } from '../../UITypes/types';

export const PRODUCT_CUSTOMER: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.name'],
    isSortable: true,
    type: 'edit-trigger-seconday',
    isSelected: true,
    isCompulsory: true,
    group: '',
  },
  {
    name: 'Customer',
    key: 'contactCompany.name',
    dataSource: [],
    isSortable: true,
    type: 'edit-trigger',
    isSelected: true,
    isCompulsory: true,
    group: '',
  },
  {
    name: 'Unit Price',
    key: 'unitPrice',
    dataSource: [
      'unitPriceMeasurement.shortName',
      'product.measurement.shortName',
    ],
    type: 'money-unit',
    isSelected: true,
    group: '',
  },
  {
    name: 'Customer Unit',
    key: 'measurement.shortName',
    dataSource: ['product.measurement.shortName'],
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Conversion',
    key: 'unitConversion',
    dataSource: ['measurement.shortName', 'product.measurement.shortName'],
    type: 'conversion-unit',
    isSelected: true,
    group: '',
  },
  {
    name: 'Last SO #',
    key: 'lastSOInfo-orderNumber',
    dataSource: ['orderNumber'],
    type: 'lastSOInfo',
    isSelected: true,
    group: '',
  },
  {
    name: 'Last SO Date',
    key: 'lastSOInfo-orderDate',
    dataSource: ['orderDate'],
    type: 'lastSOInfo',
    isSelected: true,
    group: '',
  },
  {
    name: 'Last SO Unit Price',
    key: 'lastSO-unitPrice',
    dataSource: ['unitPrice'],
    type: 'lastSOInfo',
    isSelected: true,
    group: '',
  },
  {
    name: 'Last SO Qty',
    key: 'lastSOInfo-qty',
    dataSource: ['qty'],
    type: 'lastSOInfo',
    isSelected: true,
    group: '',
  },
  {
    name: '',
    key: 'add',
    dataSource: [],
    type: 'add',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
  {
    name: '',
    key: 'delete',
    dataSource: ['id'],
    type: 'delete',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];
// export const PRODUCT_FIXED_FILTER = ['add', 'product.productCode'];
export const PRODUCT_FIXED_FILTER = ['product.productCode'];
export const CONTCOMP_FIXED_FILTER = ['contactCompany.name'];
