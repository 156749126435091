import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import iJobShiftJob from '../../../../types/job/iJobShiftJob';
import useListCrudHook from '../../../hooks/useListCrudHook/useListCrudHook';
import { JOB_SHIFTS_COLUMNS } from '../../constants';
import { getDeleteBtn, getHeads } from '../../../../components/settings/utils';
import {
  deleteJobShiftJob,
  getJobShiftJob,
} from '../../../../services/JobService';
import {
  formatDate,
  handleNullException,
  ucFirst,
} from '../../../../services/UtilsService';
import { PRODUCTS_URL } from '../../../UrlMap';

const TableWrapper = styled.div`
  width: 65%;
`;

const JobShiftsList = ({ jobId }: { jobId: string }) => {
  const { state, onDelete } = useListCrudHook<iJobShiftJob>({
    getFn: getJobShiftJob,
    deleteFn: deleteJobShiftJob,
    filter: `jobId:${jobId}`,
  });

  const history = useHistory();

  const onRedirect = (id: string) => {
    history.push({
      pathname: `${PRODUCTS_URL}/${id}`,
    });
  };

  const getRows = () => {
    return state.data
      ?.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
      .map((item: iJobShiftJob) => ({
        cells: [
          {
            key: handleNullException(item, 'jobShift.id'),
            content: `${formatDate(
              handleNullException(item, 'createdAt'),
              'DD MMMM YYYY h:m a',
            )}`,
          },
          {
            key: handleNullException(item, 'jobShift.operatorId'),
            content: handleNullException(item, 'jobShift.operator.firstName'),
          },
          {
            key: handleNullException(item, 'qtyProduced'),
            content: `${handleNullException(item, 'qtyProduced')} ${ucFirst(
              handleNullException(item, 'job.product.measurement.shortName'),
            )}`,
          },
          {
            content: item.jobShiftWastes?.map(waste => (
              <li>
                <Button
                  appearance={'link'}
                  spacing={'none'}
                  onClick={() =>
                    onRedirect(handleNullException(waste, 'productId'))
                  }
                >
                  {`${
                    handleNullException(waste, 'product.productCode') ||
                    'SKU N/A'
                  }`}
                </Button>
                {`: ${handleNullException(waste, 'qty')} ${ucFirst(
                  handleNullException(waste, 'product.measurement.shortName'),
                )}`}
              </li>
            )),
          },
          {
            content: getDeleteBtn({
              id: item.id,
              answer:
                handleNullException(item, 'jobShift.operator.firstName') ||
                'n/a',
              onClick: onDelete,
            }),
          },
        ],
      }));
  };

  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(JOB_SHIFTS_COLUMNS, 'job-shifts')}
        rows={getRows()}
        testId={'job-shifts-list-table'}
        isLoading={state.isLoading}
      />
    </TableWrapper>
  );
};

export default JobShiftsList;
