import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';

import { getDeleteBtn, getHeads } from '../../../components/settings/utils';
import { handleNullException } from '../../../services/UtilsService';
import LinkBtn from '../../../shared/buttons/LinkBtn';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import iCourier from '../../../types/courier/iCourier';

const TableWrapper = styled.div`
  th[data-testid='settings-courier-table-column-Name--head--cell'] {
    width: 60%;
    min-width: 20rem;
  }

  td:last-child {
    max-width: 2rem;
  }
`;

export const COLUMNS = ['Name', 'Created', 'Updated', 'Operation'];

const CourierListTable = ({
  data,
  onDelete,
  onEdit,
  isLoading,
}: {
  data: iCourier[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  isLoading: boolean;
}) => {
  const getRows = () => {
    return data
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iCourier) => ({
        cells: [
          {
            key: item.name,
            content: (
              <LinkBtn
                btnName={item.name}
                onClick={() => onEdit(item.id)}
                testId={`courier-list-linkBtn-${item.id}`}
              />
            ),
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'createdAt')}
                operator={handleNullException(item, 'createdBy.firstName')}
              />
            ),
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'updatedAt')}
                operator={handleNullException(item, 'updatedBy.firstName')}
              />
            ),
          },
          {
            content: getDeleteBtn({
              id: item.id,
              answer: item.name || 'name n/a',
              onClick: onDelete,
            }),
          },
        ],
      }));
  };

  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(COLUMNS, 'settings-courier')}
        rows={getRows()}
        testId={'settings-courier-list-table'}
        isLoading={isLoading}
        rowsPerPage={10}
        defaultPage={1}
      />
    </TableWrapper>
  );
};

export default CourierListTable;
