import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonGroup } from '@atlaskit/button';
import styled from 'styled-components';
import BackendPage from '../../../layout/BackendPage';
import Sidebar from '../sidebar/Sidebar';
import RoleService from '../../../services/Settings/UserAccess/RoleService';
import iRole from '../../../types/settings/UserAccess/iRole';
import { iRouteTypeId } from '../../../shared/UITypes/types';
import useDetailHook from '../../../shared/hooks/useDetailHook/useDetailHook';
import { SETTINGS_ROLES_URL } from '../../../shared/UrlMap';
import { FlexContainer } from '../../../shared/styles/styles';
import Title from '../../../shared/job/jobDetails/styles/Title';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import PopupContent from '../../../shared/popup/GeneralPopupContent';
import { handleNullException } from '../../../services/UtilsService';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import InlineEdit from '../../../shared/form/InlineEdit';
import RoleAccessList from '../../../shared/UserAccess/RoleAccessList';
import SelectSingle from '../../../shared/form/SingleSelect';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import iAccess, {
  ACCESS_CODE_STOCK_TRANSFER,
  ACCESS_CODE_SYSTEM_SETTINGS,
} from '../../../types/settings/UserAccess/iAccess';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/Toast';
import DeleteByRetype from '../../../shared/deleteWithReType/DeleteByReType';
import RoleAccessService from '../../../services/Settings/UserAccess/RoleAccessService';

const Wrapper = styled.div`
  .new-access-input {
    width: 200px;
  }
`;

const ACCESS_WITH_ONLY_ADMIN = [ACCESS_CODE_SYSTEM_SETTINGS, ACCESS_CODE_STOCK_TRANSFER];
const RoleDetailsPage = () => {
  const { id } = useParams<iRouteTypeId>();
  const history = useHistory();
  const [accesses, setAccesses] = useState<iAccess[]>([]);
  const [isGettingAccesses, setIsGettingAccesses] = useState(false);
  const [isCreatingRoleAccess, setIsCreatingRoleAccess] = useState(false);
  const [existingAccessIds, setExistingAccessIds] = useState<string[]>([]);
  const [adminAccessIds, setAdminAccessIds] = useState<string[]>([]);
  const { state, edit, onOpenDeleteModal, onDelete, onUpdateOneField, onCloseModal, onRefresh } = useDetailHook<iRole>({
    id,
    getFn: useCallback(
      (roleId: string) =>
        RoleService.getRole(roleId, {
          include: 'createdBy,updatedBy,roleAccesses.access',
        }),
      [],
    ),
    updateFn: RoleService.updateRole,
    deleteFn: RoleService.deleteRole,
    deleteCallback: () => history.push(SETTINGS_ROLES_URL),
  });

  useEffect(() => {
    let isCanceled = false;
    setIsGettingAccesses(true);
    AccessService.getAllAccesses()
      .then(res => {
        if (isCanceled) return;
        setAccesses(res.sort((access1, access2) => (access1.name > access2.name ? 1 : -1)));
        setAdminAccessIds(
          res.filter(assess => ACCESS_WITH_ONLY_ADMIN.indexOf(assess.code) >= 0).map(access => access.id),
        );
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsGettingAccesses(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [id]);

  useEffect(() => {
    setExistingAccessIds((state.data?.roleAccesses || []).map(roleAccess => roleAccess.accessId));
  }, [state.data]);

  const handleNewRoleAccess = (accessId: string) => {
    setIsCreatingRoleAccess(true);
    RoleAccessService.createRoleAccess({
      roleId: id,
      accessId,
      canRead: true,
      ...(adminAccessIds.indexOf(accessId) >= 0 ? { canUpdate: true, canCreate: true, canDelete: true } : {}),
    })
      .then(() => {
        addToastForAPIResponse('success', 'Created a new access.');
        onRefresh();
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsCreatingRoleAccess(false);
      });
  };

  const getPageHeader = () => {
    if (!state.data) {
      return null;
    }
    return (
      <FlexContainer className={'space-between'}>
        <div>
          <BreadcrumbInHeader
            subName={handleNullException(state, 'data.name')}
            extraLayer={<LinkBtnUrl url={SETTINGS_ROLES_URL} btnName={'Roles'} />}
          />

          <FlexContainer>
            <Title className={'space-right'}>{state.data?.name}</Title>
          </FlexContainer>
        </div>
        <ButtonGroup>
          <MoreIconPopup>
            <PopupContent id={state.data?.id} onOpenDeleteModal={onOpenDeleteModal} />
          </MoreIconPopup>
        </ButtonGroup>
      </FlexContainer>
    );
  };

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <Wrapper>
        <FlexContainer className={'with-gap space-below'}>
          <InlineEdit
            name={'name'}
            label={'Name'}
            defaultValue={state.data?.name || ''}
            onConfirm={(name, newValue) => onUpdateOneField(name, newValue, true)}
          />
          <InlineEdit
            name={'description'}
            label={'Description'}
            defaultValue={state.data?.description || ''}
            onConfirm={(name, newValue) => onUpdateOneField(name, newValue, true)}
          />
        </FlexContainer>

        <RoleAccessList
          roleAccesses={(state.data?.roleAccesses || []).sort((roleAccess1, roleAccess2) =>
            `${roleAccess1.access?.name || ''}` > `${roleAccess2.access?.name || ''}` ? 1 : -1,
          )}
          onDeleted={() => onRefresh()}
          onUpdated={() => onRefresh()}
          adminAccessIds={adminAccessIds}
        />

        <FlexContainer className={'with-gap space-above'}>
          <SelectSingle
            name={'new-role-access'}
            cleanUpOnSelected
            placeholder={'Select an access area'}
            selectOptions={accesses
              .filter(access => existingAccessIds.indexOf(access.id) < 0)
              .map(access => {
                return {
                  label: access.name,
                  value: access.id,
                };
              })}
            onConfirm={(name, newValue) => handleNewRoleAccess(newValue)}
            className={'new-access-input'}
            isDisabled={isGettingAccesses || state.isLoading || isCreatingRoleAccess}
          />
        </FlexContainer>
        <DeleteByRetype
          isOpen={edit.isDeleteModalOpen}
          onClose={onCloseModal}
          onConfirm={() => onDelete(state.data?.id || '')}
          answer={state.data?.name}
        />
      </Wrapper>
    </BackendPage>
  );
};

export default RoleDetailsPage;
