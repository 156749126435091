import { Label } from '@atlaskit/field-base';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AsyncSearchWithController from '../../form/hookForms/AsyncSearchWithController';
import { FlexContainer } from '../../styles/styles';
import { addToastForAPIResponse, apiErrorToast } from '../../toast/Toast';
import { iChannelEntity } from '../../../types/integration/iChannelEntity';
import iMyobContact from '../../../types/integration/iMyobContact';
import MyobUserSelectContainer from './styled/MyobUserSelectContainer';
import myobLogoSVG from '../../integrations/svgs/myob-logo.svg';
import ChannelEntityService from '../../../services/integration/ChannelEntityService';

const MyobEntitySelector = ({
  label,
  channelId,
  entityId,
  entityName,
  promiseFn,
  optionLabel,
  isDisabled = false,
}: {
  label: string;
  channelId: string;
  entityId: string;
  entityName: string;
  promiseFn: (channelId: string, keyword: string) => Promise<{ data: iMyobContact[] }>;
  optionLabel: string[];
  isDisabled?: boolean;
}) => {
  const [channelEntities, setChannelEntities] = useState<iChannelEntity[]>();

  useEffect(() => {
    const fetchData = async () => {
      const channelEntitiesResp = await ChannelEntityService.getChannelEntities(channelId, entityName, entityId);
      setChannelEntities(channelEntitiesResp);
    };
    fetchData();
  }, [channelId, entityId, entityName]);

  const selectChangeHandler = async (name: string, selected: iMyobContact) => {
    const targetChannelEntities = channelEntities;
    const targetChannelEntity = targetChannelEntities && targetChannelEntities[0];
    if (
      selected &&
      (selected?.UID !== targetChannelEntity?.externalObj?.UID ||
        (targetChannelEntity && JSON.stringify(selected) !== JSON.stringify(targetChannelEntity)))
    ) {
      try {
        const channelEntityBody = {
          channelId,
          externalId: selected.UID,
          entityName,
          entityId,
          externalObj: selected,
        };
        if (targetChannelEntity) {
          await ChannelEntityService.updateChannelEntity(targetChannelEntity.id, channelEntityBody);
        } else {
          const channelEntityResp = await ChannelEntityService.createChannelEntity(channelEntityBody);
          setChannelEntities([channelEntityResp]);
        }
        addToastForAPIResponse('success', 'Link success.');
      } catch (error) {
        apiErrorToast(error);
      }
    } else if (selected === null && targetChannelEntity) {
      try {
        await ChannelEntityService.deactivateChannelEntity(targetChannelEntity.id);
        setChannelEntities([]);
      } catch (error) {
        apiErrorToast(error);
      }
    }
  };

  const { control, errors } = useForm();
  const myobCaseSensitiveWarning = 'Note on case sensitivity as requirement from MYOB.';
  return (
    <MyobUserSelectContainer>
      <Label label={myobCaseSensitiveWarning} />
      <FlexContainer className="flex-start">
        <AsyncSearchWithController
          name={label}
          label={label}
          placeholder="Please enter a keyword to search"
          control={control}
          onChange={selectChangeHandler}
          promiseFn={(keyword: string) => promiseFn(channelId, keyword)}
          defaultValue={channelEntities && channelEntities[0] && channelEntities[0].externalObj}
          optionLabel={optionLabel}
          errors={errors}
          noOptionsMessage={myobCaseSensitiveWarning}
          isClearable={false}
          isDisabled={isDisabled}
        />
        <img className="logo" src={myobLogoSVG} alt="channel logo" />
      </FlexContainer>
    </MyobUserSelectContainer>
  );
};

export default MyobEntitySelector;
