import React from 'react';
import _ from 'lodash';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';
import iPurchaseOrderItem from '../../../types/purchases/iPurchaseOrderItem';
import Summary from '../shared/Summary';
import { handleNullExceptionMultiFields } from '../../../services/UtilsService';
import MathHelper from '../../../services/MathHelper';

const SummaryPurchaseOrder = ({ data }: { data: Array<iPurchaseOrderDetail> }) => {
  const getSums = () => {
    const purchaseOrderItems = data.reduce(
      (acc: Array<iPurchaseOrderItem>, cur: iPurchaseOrderDetail) => [...acc, ...cur.purchaseOrderItems],
      [],
    );
    const grouped = _.groupBy(purchaseOrderItems, (item: iPurchaseOrderItem) =>
      handleNullExceptionMultiFields(item, 'product.measurement.shortName,measurement.shortName'),
    );
    const sums = Object.keys(grouped).map((unitName: string) => {
      const sum = grouped[unitName].reduce(
        (acc: number, cur: iPurchaseOrderItem) => MathHelper.add(acc, cur.qty || 0),
        0,
      );
      return { unitName, sum };
    });
    return sums;
  };
  return <Summary data={getSums()}>{data.length} Order(s)</Summary>;
};

export default SummaryPurchaseOrder;
