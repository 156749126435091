import React, { useEffect, useState } from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Label } from '@atlaskit/field-base';
import { useSelector } from 'react-redux';
import InlineEditSelect from '../../../shared/form/InlineEditSelect';
import iAddress from '../../../types/contactCompany/iAddress';
import iCourier from '../../../types/courier/iCourier';
import iDispatchNote from '../../../types/dispatchNote/iDispatchNote';
import InlineEdit from '../../../shared/form/InlineEdit';
import CompanyLinkBtn from '../../../shared/buttons/CompanyLinkBtn';
import UpdateAddressPopup from '../../../shared/popup/UpdateAddressPopup';
import InlineEditDatePicker from '../../../shared/form/inlineEdit/InlineEditDatePicker';
import AddressService from '../../../services/AddressService';
import { iAddressContent } from '../../../types/iAddressContent';
import { iDetailUpdateEagerLoadFn, iDetailUpdateFn, iLabelValuePair } from '../../../shared/UITypes/types';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { AddressPlaceholderWrapper, LabelValue } from '../../../shared/styles/styles';
import { assembleAddress, handleNullException, mapToLabelValuePair } from '../../../services/UtilsService';
import iContactCompanyAddress from '../../../types/contactCompany/iContactCompanyAddress';
import { getCompanyAddressList } from '../../../services/ContactCompanyAddressService';
import TextArea from '../../../shared/form/TextArea';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_CUSTOMERS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';

const DetailEdit = ({
  detail,
  onUpdateDetail,
  onUpdateLocal,
  couriers,
  isDisabled,
}: {
  detail?: iDispatchNote;
  onUpdateDetail: iDetailUpdateFn;
  onUpdateLocal: iDetailUpdateEagerLoadFn;
  couriers: Array<iCourier>;
  isDisabled: boolean;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadCustomers = AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_READ, user);

  const [localCompanyAddressList, setLocalCompanyAddressList] = useState([]);

  useEffect(() => {
    if (!detail?.customerId) {
      return;
    }
    const getData = async () => {
      try {
        const { data } = await getCompanyAddressList({
          filter: `contactCompanyId:${detail?.customerId}`,
        });
        setLocalCompanyAddressList(data);
      } catch (e) {
        apiErrorToast(e);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail?.customerId]);

  const onUpdateAddress = async (updateContent: iAddressContent) => {
    if (typeof detail === 'undefined') return;
    try {
      const updated: iAddress = detail.shippingAddress
        ? await AddressService.editAddress(detail.shippingAddress.id, updateContent)
        : await AddressService.createAddress(updateContent);
      onUpdateDetail({ shippingAddressId: updated.id });
      onUpdateLocal({ shippingAddress: updated });
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const getOptions = () => {
    if (localCompanyAddressList.length === 0) return [];
    return localCompanyAddressList.map((item: iContactCompanyAddress) => ({
      label: item.id,
      value: item,
    }));
  };

  if (typeof detail === 'undefined') return null;

  return (
    <Page>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="Customer" />
          <LabelValue>
            {canReadCustomers ? (
              <CompanyLinkBtn company={handleNullException(detail, 'customer')} />
            ) : (
              `${handleNullException(detail, 'customer')?.name || ''}`
            )}
            {/* <LinkBtnUrl
              btnName={detail.customer.name}
              url={`/b/customer/${detail.customer.id}`}
            /> */}
          </LabelValue>
        </GridColumn>
        <GridColumn medium={2}>
          <Label label="Email" />
          <LabelValue>{detail.customer.email}</LabelValue>
        </GridColumn>
        <GridColumn medium={2}>
          <Label label="Phone" />
          <LabelValue>{detail.customer.phone}</LabelValue>
        </GridColumn>
        <GridColumn medium={5}>
          <Label label="Shipping Address" />
          {isDisabled ? (
            <LabelValue>{assembleAddress(detail.shippingAddress)}</LabelValue>
          ) : (
            <UpdateAddressPopup
              address={detail.shippingAddress}
              heading={'Shipping Address'}
              onClick={onUpdateAddress}
              needAddressSelection
              options={getOptions()}
            >
              <LabelValue>
                {assembleAddress(detail.shippingAddress) || (
                  <AddressPlaceholderWrapper>Click to enter value</AddressPlaceholderWrapper>
                )}
              </LabelValue>
            </UpdateAddressPopup>
          )}
        </GridColumn>
      </Grid>

      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <InlineEditSelect
            name={'courierId'}
            label={'Courier'}
            defaultValue={mapToLabelValuePair(couriers).find((o: iLabelValuePair) => o.value === detail.courierId)}
            selectOptions={mapToLabelValuePair(couriers)}
            onConfirm={(name: string, newValue: string | null) => onUpdateDetail({ [name]: newValue })}
            isClearable
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={2}>
          <InlineEdit
            name={'courierReference'}
            label={'Courier Reference'}
            defaultValue={detail.courierReference}
            onConfirm={(name: string, newValue: string) => onUpdateDetail({ [name]: newValue })}
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={2}>
          <InlineEditDatePicker
            name={'dispatchDate'}
            label={'Dispatched Date'}
            defaultValue={detail.dispatchDate}
            onConfirm={(name: string, newValue: string | null) => onUpdateDetail({ [name]: newValue })}
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={5}>
          <TextArea
            name={'notes'}
            label="Notes"
            defaultValue={detail.notes}
            onChange={(name: string, newValue: string) => onUpdateDetail({ [name]: newValue })}
            minimumRows={4}
            debouncePeriod={500}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default DetailEdit;
