import React, { useState } from 'react';
import { LoadingButton } from '@atlaskit/button';
import { useForm } from 'react-hook-form';
import BackToLogin from '../shared/BackToLogin';
import CompanyLogo from '../../../shared/company/CompanyLogo';
import RenderForm from '../shared/RenderForm';
import PageHeading from '../../../shared/heading/PageHeading';
import RedirectAfterSuccessAction from '../shared/RedirectToActivation';
import { iHookFormField, iKeyValuePairs } from '../../../shared/UITypes/types';
import { SpaceVerticalContainer } from '../../../shared/styles/styles';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { addCompareRules, generateInitialValues } from '../shared/Form.utils';
import { resetPassword } from '../../../services/AuthService';
import {
  RESET_PASSWORD_FIELDS,
  RESET_PASSWORD,
  RESET_SUCCESS_HINT,
} from '../shared/Auth.constants';

type iState = {
  isLoading: boolean;
  success: boolean;
};
const initialState: iState = {
  isLoading: false,
  success: false,
};
const ResetPasswordForm = ({
  fields,
  onSubmitExtraFields,
}: {
  fields: Array<iHookFormField>;
  onSubmitExtraFields?: iKeyValuePairs;
}) => {
  const [state, setState] = useState(initialState);
  const { control, errors, handleSubmit, getValues } = useForm({
    mode: 'onBlur',
    defaultValues: generateInitialValues(fields),
    reValidateMode: 'onBlur',
  });

  // eslint-disable-next-line
  const onSubmit = async (data: any) => {
    setState({ ...state, isLoading: true });
    try {
      await resetPassword({
        ...data,
        ...onSubmitExtraFields,
      });
      setState({
        ...state,
        isLoading: true,
        success: true,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isLoading: false });
    }
  };
  return (
    <>
      <CompanyLogo />
      {state.success ? (
        <RedirectAfterSuccessAction message={RESET_SUCCESS_HINT}>
          <BackToLogin />
        </RedirectAfterSuccessAction>
      ) : (
        <>
          <PageHeading content={RESET_PASSWORD} className={'space-below'} />
          <RenderForm
            control={control}
            fields={addCompareRules(RESET_PASSWORD_FIELDS, getValues)}
            errors={errors}
          />
          <SpaceVerticalContainer className={'half'} />
          <LoadingButton
            appearance={'primary'}
            isLoading={state.isLoading}
            shouldFitContainer
            onClick={handleSubmit(onSubmit)}
          >
            Reset
          </LoadingButton>
        </>
      )}
    </>
  );
};

export default ResetPasswordForm;
