import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import moment from 'moment';
import styled from 'styled-components';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import iPurchaseOrderItem from '../../../types/purchases/iPurchaseOrderItem';
import { getHeads } from '../../../components/settings/utils';
import iPoReceiving from '../../../types/purchases/iPoReceiving';
import {
  handleNullException,
  handleNullExceptionMultiFields,
} from '../../../services/UtilsService';
import { PRODUCTS_URL } from '../../../shared/UrlMap';
import { getReceivedQtyBeforeConversion } from '../Purchase.utilities';
import ConversionText from '../../../shared/text/ConversionText';

const COLUMNS = [
  'Product',
  'Supplier Product Code',
  'Quantity',
  'Received Qty',
];

const Title = styled.h3`
  margin-top: 0.8rem;
`;

const TableWrapper = styled.div`
  padding: 1rem 1rem 0 0;
  height: 80vh;
`;

const PoItems = ({ poReceiving }: { poReceiving?: iPoReceiving }) => {
  const getNameContent = (item: iPurchaseOrderItem) => (
    <ComposeSecondaryText secondaryText={item.product.name}>
      <LinkBtnUrl
        btnName={item.product.productCode}
        url={`${PRODUCTS_URL}/${item.productId}`}
      />
    </ComposeSecondaryText>
  );

  const getRows = () => {
    return poReceiving?.purchaseOrder?.purchaseOrderItems
      ?.sort((a, b) => moment(b.createdAt).diff(moment(b.createdAt)))
      .map((item: iPurchaseOrderItem) => ({
        cells: [
          {
            key: item.product.productCode,
            content: getNameContent(item),
          },
          { content: handleNullException(item, 'supplierProductCode') },
          {
            content: (
              <ConversionText
                value={item.qty}
                conversion={item.unitConversion || 1}
                contactCompanyUnit={handleNullExceptionMultiFields(
                  item,
                  'product.measurement.shortName,measurement.shortName',
                )}
                productUnit={handleNullException(
                  item,
                  'product.measurement.shortName',
                )}
              />
            ),
          },
          {
            content: getReceivedQtyBeforeConversion(item),
          },
        ],
      }));
  };

  return (
    <>
      <Title>PO Items</Title>
      <TableWrapper>
        <DynamicTable
          head={getHeads(COLUMNS, 'po-receiving-poItems')}
          rows={getRows()}
          testId={'po-receiving-poItems-table'}
        />
      </TableWrapper>
    </>
  );
};

export default PoItems;
