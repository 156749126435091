import AppService from './AppService';

export const getLogs = (
  modelName: string,
  modelId: string,
  perPage = 5,
  currentPage = 1,
) => {
  return AppService.get(`/log/${modelName}/${modelId}`, {
    perPage: perPage.toString(),
    currentPage: currentPage.toString(),
    include: 'createdBy',
  }).then(({ data }) => data);
};

export default {
  getLogs,
};
