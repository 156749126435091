/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import Avatar from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';
import iLogItem from '../../types/log/iLogItem';
import { formatDate, ucFirst } from '../../services/UtilsService';

const HistoryItemContainer = styled.div`
  margin: 6px 0;
  color: rgb(9, 30, 66);
  display: flex;
  .history-item-avatar {
    margin-right: 6px;
  }
  .history-item-content {
    flex-grow: 1;
    align-self: center;
    .key-info {
      font-weight: 500;
    }
    .date-info {
      color: rgb(66, 82, 110);
    }
  }
`;
const HistoryItem = ({ log }: { log: iLogItem }) => {
  return (
    <>
      <HistoryItemContainer className={'history-item-container'} data-testid={'history-item'}>
        <div className={'history-item-avatar'}>
          <Avatar appearance="circle" src={log.createdBy?.userAvatarUrl} size="medium" testId={'user-avatar'} />
        </div>
        <div className={'history-item-content'} data-testid={'history-item-content'}>
          <span className={'key-info'} data-testid={'firstName'}>
            {log.createdBy?.firstName || ''}
          </span>
          <span> {log.action}d the </span>
          <span className={'key-info'} data-testid={'fieldName'}>
            {ucFirst(log.fieldName)}
          </span>
          <span> at </span>
          <span className={'date-info'} data-testid={'log-time'}>
            {formatDate(log.createdAt)}
          </span>
          <div>
            <span style={{ color: colors.N100 }} data-testid={'previous-value'}>
              {log.previousValue === 'null' ? 'None' : log.previousValue}
            </span>
            <span> &rarr; </span>
            <span data-testid={'current-value'}>{log.currentValue}</span>
          </div>
        </div>
      </HistoryItemContainer>
    </>
  );
};

export default HistoryItem;
