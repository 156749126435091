import React from 'react';
import { useForm } from 'react-hook-form';
import SelectWithController from '../../form/hookForms/SelectWithController';
import iMyobCompanyProfile from '../../../types/integration/iMyobCompanyProfile';

const MyobCompanyProfileSelector = ({
  companyProfiles,
  onSelected,
}: {
  companyProfiles: iMyobCompanyProfile[];
  onSelected: (companyProfile: iMyobCompanyProfile) => void;
}) => {
  const options = companyProfiles.map(companyProfile => ({
    label: companyProfile.Name,
    value: companyProfile.Id,
  }));
  const { control, errors } = useForm();
  return (
    <>
      {options && (
        <SelectWithController
          name={'myobCompanyProfile'}
          label={'MYOB Company Profile'}
          control={control}
          onChange={(name, selected) => {
            const [targetCompanyProfile] = companyProfiles.filter(companyProfile => companyProfile.Id === selected);
            onSelected(targetCompanyProfile);
          }}
          errors={errors}
          isRequired
          defaultValue={options[0]?.value || ''}
          options={options}
        />
      )}
    </>
  );
};

export default MyobCompanyProfileSelector;
