import { iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

export const getWrappingMethods = (config?: iParams) => {
  return AppService.get('/wrappingMethod', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const deactivateWrappingMethod = (id: string) => {
  return AppService.delete(`/wrappingMethod/${id}`).then(({ data }) => data);
};

export const createWrappingMethod = (postData: iParams) => {
  return AppService.post('/wrappingMethod', postData).then(({ data }) => data);
};

export const updateWrappingMethod = (id: string, updateContent: iParams) => {
  return AppService.put(`/wrappingMethod/${id}`, updateContent).then(({ data }) => data);
};

export default {
  getWrappingMethods,
  deactivateWrappingMethod,
  createWrappingMethod,
  updateWrappingMethod,
};
