import styled from 'styled-components';

export const CropWrapper = styled.div`
  position: relative;
  display: block;
  min-height: 300px;
  .close-crop {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: transparent;
    &:hover {
      background-color: rgb(245, 245, 245);
    }
  }
`;

export const DropzoneWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 250px;
  box-sizing: border-box;
  .dropzone {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    color: rgb(107, 119, 140);
    ::after {
      content: '';
      border: 2px dashed rgb(208, 214, 208);
      border-radius: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transition: border-color 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    }
    .dropzone-circle {
      border: none;
    }
  }
`;
