import React, { useEffect, useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import Spinner from '@atlaskit/spinner';
import Button, { LoadingButton } from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import styled from 'styled-components';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { useForm } from 'react-hook-form';
import { StripeError } from '@stripe/stripe-js/types/stripe-js/stripe';
import BillingSubscriptionService from '../../../../services/billing/BillingSubscriptionService';
import StripeDisclaimer from './StripeDisclaimer';

const CardInputWrapper = styled.div`
  margin: 10px 0;
  .error-msg {
    margin: 10px 0;
  }
`;

type iStripeResult = never | { error: StripeError };
const CheckoutForm = ({
  formId,
  handleSubmit,
  savingFn,
  successFn,
}: {
  formId: string;
  handleSubmit: (onSubmit: () => void) => () => void;
  savingFn?: (isSaving: boolean) => void;
  successFn?: (result: iStripeResult) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState('');

  const onSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if (savingFn) {
      await savingFn(true);
    }
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (result.error) {
      setError(result.error.message as string);
      if (savingFn) {
        await savingFn(false);
      }
      return;
    }

    if (successFn) {
      await successFn(result);
    }
    if (savingFn) {
      await savingFn(false);
    }
  };

  const getErrorMsg = () => {
    if (error.trim() === '') return null;
    return (
      <div className={'error-msg'} data-testid={'err-msg'}>
        <SectionMessage appearance="error">{error}</SectionMessage>
      </div>
    );
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} data-testid={'card-form'}>
      <CardInputWrapper>
        <PaymentElement />
        {getErrorMsg()}
      </CardInputWrapper>
      <div data-testid={'disclaimer'}>
        <small>
          <StripeDisclaimer />
        </small>
      </div>
      <div data-testid={'explain-text'}>
        <small>
          This card <b>will replace</b> your current card and be used on your next bill.
        </small>
      </div>
    </form>
  );
};

const BillingMethodSetupPopup = ({
  savedFn,
  closePopupFn,
}: {
  savedFn?: (result: iStripeResult) => void;
  closePopupFn: () => void;
}) => {
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [stripeApiKey, setStripeApiKey] = useState('');
  const [savingCard, setSavingCard] = useState(false);
  const { handleSubmit } = useForm();
  const carFormId = 'card-setup-form';

  useEffect(() => {
    let isCanceled = false;
    if (loading !== true) {
      return undefined;
    }

    const getSetupIntent = () => {
      BillingSubscriptionService.getPaymentIntent({ isForSetup: true }).then(resp => {
        if (isCanceled) {
          return;
        }
        setClientSecret(resp.client_secret || '');
        setStripeApiKey(resp.pk || '');
        setLoading(false);
      });
    };
    getSetupIntent();
    return () => {
      isCanceled = true;
    };
  }, [loading]);

  const getStripPromise = () => {
    return loadStripe(stripeApiKey);
  };
  const getContent = () => {
    if (loading === true || stripeApiKey === '' || clientSecret === '') {
      return <Spinner />;
    }
    return (
      <Elements stripe={getStripPromise()} options={{ clientSecret }}>
        <CheckoutForm
          handleSubmit={handleSubmit}
          formId={carFormId}
          savingFn={setSavingCard}
          successFn={async (resp: iStripeResult) => {
            if (savedFn) {
              await savedFn(resp);
            }
            closePopupFn();
          }}
        />
      </Elements>
    );
  };

  return (
    <Modal autoFocus={false} onClose={() => closePopupFn()} testId={'popup-modal'}>
      <ModalHeader>
        <ModalTitle>Payment Method</ModalTitle>
      </ModalHeader>
      <ModalBody>{getContent()}</ModalBody>
      <ModalFooter>
        <div />
        <div>
          <Button appearance={'subtle'} onClick={() => closePopupFn()} testId={'cancel-btn'}>
            Cancel
          </Button>
          <LoadingButton
            testId={'save-btn'}
            appearance={'primary'}
            type={'submit'}
            form={carFormId}
            isLoading={savingCard === true}
            isDisabled={loading === true || stripeApiKey === '' || clientSecret === ''}
          >
            Save
          </LoadingButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const BillingMethodSetupPopupBtn = ({
  children,
  savedFn,
}: {
  children: React.ReactNode;
  savedFn?: (result: iStripeResult) => void;
}) => {
  const [showingPopup, setShowingPopup] = useState(false);

  const getCardEditingPopup = () => {
    if (showingPopup !== true) {
      return null;
    }
    return <BillingMethodSetupPopup savedFn={savedFn} closePopupFn={() => setShowingPopup(false)} />;
  };

  return (
    <div className={'payment-method-popup-btn'}>
      <Button testId={'popup-btn'} appearance={'danger'} spacing={'compact'} onClick={() => setShowingPopup(true)}>
        {children}
      </Button>
      {getCardEditingPopup()}
    </div>
  );
};

export default BillingMethodSetupPopupBtn;
