import { Edge, Node } from 'react-flow-renderer';
import iWorkflow from '../../../types/workflow/iWorkflow';
import iEntityStatusType from '../../../types/status/iEntityStatusType';
import iEntityCategory from '../../../types/status/iEntityCategory';
import iEntityStatus from '../../../types/status/iEntityStatus';

export type iWFDiagramState = {
  isLoading: boolean;
  entityStatusTypeId: string;
  workflow: iWorkflow | null;
  entityStatusType: iEntityStatusType | null;
  entityStatusCategories: iEntityCategory[];
  entityStatuses: iEntityStatus[];
  initials: { nodes: Node[]; edges: Edge[] };
  errors: string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  setNodes: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setEdges: Function;
};

export const WKDiagramInitialState: iWFDiagramState = {
  isLoading: false,
  initials: { nodes: [], edges: [] },
  entityStatusTypeId: '',
  workflow: null,
  entityStatusType: null,
  entityStatusCategories: [],
  entityStatuses: [],
  errors: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNodes: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setEdges: () => {},
};

export enum WKDiagramActionKind {
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Update = 'UPDATE',
}
export type WKDiagramAction = {
  type: WKDiagramActionKind;
  payload?: iWFDiagramState;
};

export const WKDiagramReducer = (state: iWFDiagramState, action: WKDiagramAction) => {
  switch (action.type) {
    case WKDiagramActionKind.Loading:
      return {
        ...state,
        isLoading: true,
      };
    case WKDiagramActionKind.Loaded:
      return {
        ...state,
        isLoading: false,
      };
    case WKDiagramActionKind.Update:
      return {
        ...state,
        ...(action.payload || {}),
        isLoading: false,
      };
    default:
      throw new Error();
  }
};
