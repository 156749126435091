import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { iConfigColumn } from '../../../shared/UITypes/types';
import { DASHBOARD_SALES } from '../shared/Dashboard.constants';
import { getHeads } from '../../../services/UtilsService';
import { iSalesState } from '../shared/Dashboard.types';
import { getRows } from './TableHelper';

const DashBoardSalesTable = ({
  columns,
  date,
  state,
}: {
  columns: Array<iConfigColumn>;
  state: iSalesState;
  date: string;
}) => {
  return (
    <div className={'table-wrapper'}>
      <DynamicTable
        head={getHeads(columns, DASHBOARD_SALES)}
        rows={getRows({
          state,
          columns,
          date,
        })}
        isLoading={state.isLoading}
        testId={'dashboard-sales-table'}
      />
    </div>
  );
};

export default DashBoardSalesTable;
