import styled from 'styled-components';
import { TABLE_NAME } from './DispatchNote.constant';

export const DispatchNoteTableWrapper = styled.div`
  th[data-testid='${TABLE_NAME}-column-operation--head--cell'] {
    min-width: 36px;
    max-width: 50px;
  }
  th[data-testid='${TABLE_NAME}-column-updatedAt--head--cell'],
  th[data-testid='${TABLE_NAME}-column-createdAt--head--cell'] {
    min-width: 160px;
  }
  th[data-testid='${TABLE_NAME}-column-dispatchNoteNumber--head--cell'] {
    min-width: 100px;
  }
  th[data-testid='${TABLE_NAME}-column-dispatchDate--head--cell'] {
    min-width: 110px;
  }
  th[data-testid='${TABLE_NAME}-column-totalAmt--head--cell'] {
    min-width: 80px;
  }
`;

export const DispatchNoteItemsWrapper = styled.div`
  margin-top: 4rem;
  th[data-testid='dispatchNoteItem-table-column-Operation--head--cell'] {
    min-width: 24px;
    max-width: 36px;
  }
  div[data-testid='div-wrapper-inline-edit-no-margin-top'] > form > div {
    margin-top: 0 !important;
  }
`;
export const CreateNoteItemWrapper = styled.div`
  margin-top: -1rem;
`;
export const SearchWrapper = styled.div`
  min-width: 300px;
`;
export const SelectWrapper = styled.div`
  z-index: 9;
`;
