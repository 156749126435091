import React from 'react';
import Button from '@atlaskit/button';
import { PopUpContentContainer } from '../styles/styles';
import { DELETE } from '../constants/ActionConstants';

const PopupContent = ({
  id,
  onOpenDeleteModal,
  children,
}: {
  id: string;
  onOpenDeleteModal?: (id: string) => void;
  children?: React.ReactNode;
}) => {
  return (
    <PopUpContentContainer className={'popup-content'}>
      {
        //  <Button className={'popup-item'}>{CLONE}</Button>
      }
      {children}
      {onOpenDeleteModal ? (
        <Button className={'popup-item'} onClick={() => onOpenDeleteModal(id)} testId={'delete-button'}>
          {DELETE}
        </Button>
      ) : null}
    </PopUpContentContainer>
  );
};

export default PopupContent;
