import React from 'react';
import moment from 'moment';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import DashBoardSales from './dashboardSales/DashBoardSales';
import InsuffcientStocks from './insufficientStocks/InsuffcientStocks';
import Production from './dashboardProduction/Production';
import OrdersByMachine from './dashboardJobsByMachine/JobsByMachine';
import Purchase from './dashboardPurchase/Purchase';
import DashboardJobContext from './jobContext/DashboardJobContext';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_JOBS, ACCESS_CODE_REPORTS } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../types/settings/UserAccess/iRoleAccess';

export default () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadJobs = AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_READ, user);
  const canReadReports = AccessService.hasAccess(ACCESS_CODE_REPORTS, ACCESS_CAN_READ, user);

  const getContents = () => {
    if (!canReadJobs && !canReadReports) {
      return <h3>Welcome, {user?.firstName}</h3>;
    }
    return (
      <Page>
        {canReadJobs && (
          <>
            <DashboardJobContext>
              <Production date={moment().toString()} />
              <OrdersByMachine date={moment().toString()} />
            </DashboardJobContext>
            <Grid spacing={'compact'} layout={'fluid'}>
              <GridColumn medium={6}>
                <DashBoardSales date={moment().toString()} />
              </GridColumn>
              <GridColumn medium={6}>
                <Purchase date={moment().toString()} />
              </GridColumn>
            </Grid>
          </>
        )}
        {canReadReports && <InsuffcientStocks date={moment().toString()} />}
      </Page>
    );
  };

  return <BackendPage>{getContents()}</BackendPage>;
};
