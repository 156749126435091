import React from 'react';
import styled from 'styled-components';

const ComposeSecTextWrapper = styled.div`
  .secondary-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgb(107, 119, 140);
    display: block;
  }
`;

const ComposeSecondaryText = ({
  children,
  secondaryText,
}: {
  children: React.ReactNode;
  secondaryText: string | React.ReactNode;
}) => {
  return (
    <ComposeSecTextWrapper data-testid={'compose-seconday-text'}>
      {children}
      <span className={'secondary-text'}>{secondaryText}</span>
    </ComposeSecTextWrapper>
  );
};

export default ComposeSecondaryText;
