import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { EllipsisWrapper } from '../styles/styles';

// if necessary passing a className to control the max-width
const TextEllipsisTooltip = ({ content }: { content: string }) => {
  return (
    <Tooltip content={content}>
      <EllipsisWrapper>{content}</EllipsisWrapper>
    </Tooltip>
  );
};

export default TextEllipsisTooltip;
