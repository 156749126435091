import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Spinner from '@atlaskit/spinner';
import iProduct from '../../../types/product/iProduct';
import iPurchaseOrderItem from '../../../types/purchases/iPurchaseOrderItem';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import { getPOItemConfig } from '../../../services/PurchaseOrderItemService';
import {
  formatDate,
  handleNullException,
} from '../../../services/UtilsService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { DATE_FORMAT } from '../shared/Dashboard.constants';
import { AVOID_PAGINATION_PER_PAGE } from '../../../shared/constants/AsyncConstants';
import MathHelper from '../../../services/MathHelper';

type iState = {
  isLoading: boolean;
  data: Array<iPurchaseOrderItem>;
};
const initialState: iState = {
  isLoading: true,
  data: [],
};
const SummaryPOItems = ({
  date,
  product,
}: {
  date: string;
  product: iProduct;
}) => {
  const { id: productId } = product;
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));
        const { data } = await getPOItemConfig({
          advancedFilter: `PurchaseOrder_orderId_id.eta>=${formatDate(
            date,
            DATE_FORMAT,
          )},productId:${productId}`,
          currentPage: '1',
          perPage: AVOID_PAGINATION_PER_PAGE,
          include: 'purchaseOrder',
        });
        const availablePOItems: Array<iPurchaseOrderItem> = (data as Array<iPurchaseOrderItem>).filter(
          (item: iPurchaseOrderItem) =>
            item.receivedQty < item.qty * (item.unitConversion || 1),
        );
        setState(prevState => ({
          ...prevState,
          data: availablePOItems,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [date, productId]);

  if (state.isLoading) return <Spinner />;
  return (
    <ComposeSecondaryText
      secondaryText={
        state.data.length === 0
          ? ''
          : `Closest ETA @${formatDate(
              _.sortBy(state.data, 'purchaseOrder.eta', 'asc')[0].purchaseOrder
                ?.eta,
              DATE_FORMAT,
            )}`
      }
    >
      {`${state.data.reduce(
        (acc: number, cur: iPurchaseOrderItem) =>
          MathHelper.add(acc, MathHelper.mul(cur.qty, cur.unitConversion || 1)),
        0,
      )} ${handleNullException(product, 'measurement.shortName')}`}
    </ComposeSecondaryText>
  );
};

export default SummaryPOItems;
