import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import SectionMessage from '@atlaskit/section-message';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import CreateJobAttribute from './JobAttributeWithPostfix';
import InputWithController from '../../../../shared/form/hookForms/InputWithController';
import SelectWithController from '../../../../shared/form/hookForms/SelectWithController';
import CustomizedLabel from '../../../../shared/form/hookForms/CustomizedLabel';
import Textfield from '../../../../shared/form/Textfield';
import { compareStr, handleNullException } from '../../../../services/UtilsService';
import iSalesOrderItem from '../../../../types/sales/iSalesOrderItem';
import iJobAttribute from '../../../../types/job/iJobAttribute';
import { previewJobAttributeValues } from '../../../../services/JobAttributeValueService';
import { apiErrorToast } from '../../../../shared/toast/Toast';
import { SpaceVerticalContainer } from '../../../../shared/styles/styles';
import { iCreateJobParams } from '../../types';
import { iKeyValuePairs, iLabelValuePair } from '../../../../shared/UITypes/types';
import {KG_PER_1KM, OVER_QTY_HINT, ROLL_LENGTH, ROLL_NUMBER, ROLL_WEIGHT} from './CreateJob.constants';
import MathHelper from '../../../../services/MathHelper';

const ModalContentWrapper = styled.div`
  margin-bottom: 1rem;
  & > div {
    margin-bottom: 4px;
  }
  .machine-select {
    margin-top: 8px;
  }
`;
type iCreateJobModalBody = {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  //    eslint-disable-next-line
  watch: (names?: string | string[]) => any;
  targetSOItem?: iSalesOrderItem;
  createJobParams: iCreateJobParams;
  defaultPalletId?: string;
  existedQty: number;
  defaultValues: iKeyValuePairs;
  previewJobAttributesOnChange?: boolean;
};
const CreateJobModalBody = ({
  control,
  errors,
  onChange,
  watch,
  targetSOItem,
  createJobParams,
  defaultPalletId,
  existedQty,
  defaultValues,
  previewJobAttributesOnChange = false,
}: iCreateJobModalBody) => {
  const [state, setState] = useState<iKeyValuePairs>(defaultValues);
  const convertedSOItemQty = targetSOItem && MathHelper.mul(targetSOItem.qty, targetSOItem?.unitConversion || 1);
  const filmAttributeName = [ROLL_LENGTH, ROLL_NUMBER, ROLL_WEIGHT, KG_PER_1KM];

  const findFieldName = (name: string) => {
    if (previewJobAttributesOnChange !== true) {
      return null;
    }
    return filmAttributeName.find((item: iLabelValuePair) => item.label.includes(name))?.value;
  };

  const findJobAttr = (fieldName: string) => {
    if (previewJobAttributesOnChange !== true) {
      return null;
    }
    const jobAttrName = filmAttributeName.find((item: iLabelValuePair) =>
      item.value.includes(fieldName),
    )?.label;
    const jobAttr = createJobParams.jobAttributes.find(
      (item: iJobAttribute) => jobAttrName && item.name.includes(jobAttrName),
    );
    return jobAttr;
  };

  const watchValues = (name: string) => {
    if (previewJobAttributesOnChange !== true) {
      return null;
    }
    const qtyTotal = watch('qtyTotal');

    const rollNumWatchedName = createJobParams.jobAttributes.find((item: iJobAttribute) =>
      ROLL_NUMBER.label.includes(item.name),
    )?.id;

    const rollWeightWatchedName = createJobParams.jobAttributes.find((item: iJobAttribute) =>
      ROLL_WEIGHT.label.includes(item.name),
    )?.id;

    const optionalFieldName =
      //  eslint-disable-next-line
      name.includes('qtyTotal') ||
      (rollWeightWatchedName && name.includes(rollWeightWatchedName))
        ? ROLL_WEIGHT.value
        : rollNumWatchedName && name.includes(rollNumWatchedName)
        ? ROLL_NUMBER.value
        : '';

    const rollNum = watch(`${rollNumWatchedName}-value`);
    const rollWeight = watch(`${rollWeightWatchedName}-value`);
    return { qtyTotal, rollNum, rollWeight, optionalFieldName };
  };

  const triggerSetValue = (previews: iKeyValuePairs) => {
    if (previewJobAttributesOnChange !== true) {
      return;
    }
    Object.keys(previews).forEach((key: string) => {
      const jobAttr = findJobAttr(key);
      if (typeof jobAttr === 'undefined') return;
      onChange(`${jobAttr?.id}-value`, previews[key], { shouldValidate: true });
    });
  };

  const onChangePreviewValue = async (
    name: string,
    //    eslint-disable-next-line
    value: any,
    //    eslint-disable-next-line
    config?: Object,
  ) => {
    onChange(name, value, config);
    if (previewJobAttributesOnChange !== true) {
      return;
    }
    setTimeout(async () => {
      // postfix not trigger preview
      if (_.get(errors, name) || name.includes('postfix')) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { qtyTotal, rollNum, rollWeight, optionalFieldName } = watchValues(name);
      const productId = targetSOItem?.productId;
      // only valid rollNumber/rollWeight and qtyTotal will trigger  preview values
      if (!productId || !qtyTotal || !Number(qtyTotal) || !optionalFieldName) {
        return;
      }
      if (
        (optionalFieldName === ROLL_NUMBER.value && !rollNum) ||
        (optionalFieldName === ROLL_WEIGHT.value && !rollWeight)
      ) {
        setState({});
        triggerSetValue({});
        return;
      }
      try {
        const previews: iKeyValuePairs = await previewJobAttributeValues({
          productId,
          qtyTotal,
          [optionalFieldName]: optionalFieldName === ROLL_NUMBER.value ? rollNum : rollWeight,
        });
        setState({ ...state, ...previews });
        triggerSetValue(previews);
      } catch (error) {
        apiErrorToast(error);
        setState({});
      }
    }, 0);
  };

  const isOverQty = () => {
    const watchQty = watch('qtyTotal');
    if (
      convertedSOItemQty &&
      !Number.isNaN(Number(watchQty)) &&
      typeof targetSOItem !== 'undefined' &&
      MathHelper.add(Number(watchQty), existedQty) > convertedSOItemQty
    )
      return true;
    return false;
  };
  const getLeftQtyToProduct = () => {
    if (!convertedSOItemQty) return 0;
    const leftQty = MathHelper.sub(convertedSOItemQty, existedQty);
    return leftQty > 0 ? leftQty : 0;
  };

  return (
    <ModalContentWrapper className={'create-job-modal-content'}>
      <Page>
        <Grid>{isOverQty() && <SectionMessage appearance="warning">{OVER_QTY_HINT}</SectionMessage>}</Grid>
        <Grid>
          <GridColumn medium={6}>
            <InputWithController
              label={'Qty to produce'}
              name={'qtyTotal'}
              defaultValue={getLeftQtyToProduct().toString()}
              suffix={handleNullException(targetSOItem, 'product.measurement.shortName')}
              onChange={_.debounce(onChangePreviewValue, 500)}
              control={control}
              testId={'qty-to-produce'}
              isRequired
              errors={errors}
              min={0}
              isNumeric
              triggerOnChangeForDefaultValue={false}
            />
          </GridColumn>
          <GridColumn medium={6}>
            <SelectWithController
              label={'Machine'}
              name={'machineId'}
              onChange={onChange}
              control={control}
              options={createJobParams.machines.sort((a: iLabelValuePair, b: iLabelValuePair) =>
                compareStr(a.label, b.label),
              )}
              testId={'machine'}
              isRequired
              errors={errors}
              defaultValue={targetSOItem?.product.defaultMachineId}
              className={'machine-select'}
            />
          </GridColumn>
          <GridColumn medium={12}>
            <SelectWithController
              label={'Pallet system'}
              name={'palletSystemId'}
              defaultValue={defaultPalletId || targetSOItem?.product?.palletSystem?.id}
              onChange={onChange}
              control={control}
              options={createJobParams.palletSystems.sort((a: iLabelValuePair, b: iLabelValuePair) =>
                compareStr(a.label, b.label),
              )}
              testId={'pallet-system'}
            />
          </GridColumn>
          {createJobParams.jobAttributes.map((j: iJobAttribute) => (
            <GridColumn medium={6} key={j.id}>
              <CreateJobAttribute
                jobAttribute={j}
                onChange={_.debounce(onChangePreviewValue, 500)}
                control={control}
                errors={errors}
                units={createJobParams.units}
                defaultValue={watch('qtyTotal') && handleNullException(state, findFieldName(j.name) || '')}
              />
            </GridColumn>
          ))}
          {previewJobAttributesOnChange !== true ? null : (
            <GridColumn medium={6}>
              <SpaceVerticalContainer className={'quarter'} />
              <CustomizedLabel label={'Diameter'} />
              <Textfield name={'rollDiameter'} isDisabled defaultValue={handleNullException(state, 'rollDiameter')} />
            </GridColumn>
          )}
        </Grid>
      </Page>
      <SpaceVerticalContainer className={'five'} />
    </ModalContentWrapper>
  );
};

export default CreateJobModalBody;
