import React, { createContext, useContext } from 'react';
import {
  getCompanyAddressList,
  createCompanyAddress,
  updateCompanyAddress,
  deactivateCompanyAddress,
} from '../../../../services/ContactCompanyAddressService';
// import {
//   createContactConfig,
//   getContactsConfig,
//   removeContact,
//   updateContact,
// } from '../../../../services/ContactService';
// import iAddress from '../../../../types/contactCompany/iAddress';
// import iContact from '../../../../types/contactCompany/iContact';
import iContactCompanyAddress from '../../../../types/contactCompany/iContactCompanyAddress';
import useListCrudHook, {
  iListCrudHookResult,
} from '../../../hooks/useListCrudHook/useListCrudHook';

type iCCContext = {
  // contactContext: iListCrudHookResult<iContact>;
  addressContext: iListCrudHookResult<iContactCompanyAddress>;
};
// eslint-disable-next-line
const CCContext = createContext<iCCContext | null>(null);
const ContactCompanyContextProvider = ({
  contactCompanyId,
  children,
}: {
  contactCompanyId: string;
  children: React.ReactNode;
}) => {
  // const contactContext = useListCrudHook<iContact>({
  //   getFn: getContactsConfig,
  //   createFn: createContactConfig,
  //   updateFn: updateContact,
  //   deleteFn: removeContact,
  //   notRenderWithoutFilter: true,
  //   filter: `companyId:${contactCompanyId}`,
  //   paginationApplied: true,
  // });

  const addressContext = useListCrudHook<iContactCompanyAddress>({
    getFn: getCompanyAddressList,
    createFn: createCompanyAddress,
    updateFn: updateCompanyAddress,
    deleteFn: deactivateCompanyAddress,
    notRenderWithoutFilter: true,
    filter: contactCompanyId ? `contactCompanyId:${contactCompanyId}` : '',
    paginationApplied: true,
  });

  return (
    <CCContext.Provider value={{ addressContext }}>
      {children}
    </CCContext.Provider>
  );
};
export const useContactCompanyContext = () =>
  useContext(CCContext) as iCCContext;
export default ContactCompanyContextProvider;
