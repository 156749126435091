import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Spinner from '@atlaskit/spinner';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { handleNullException, intersperse, numberRound } from '../../../services/UtilsService';
import { NUMBER_ROUND_DECIMAL } from '../../../shared/constants/ActionConstants';

type iState<T> = {
  isLoading: boolean;
  data: Array<T>;
};

const FetchRelatedElements = <T extends { id: string }>({
  fetchFn,
  renderType,
  dataSource,
  urlPrefix,
  connectElementType = 'concat',
  suffix = 'Kg',
  //  eslint-disable-next-line
  prefix = '$',
  extraTimes = 1,
  readOnly = false,
}: {
  //  eslint-disable-next-line
  fetchFn: () => Promise<any>;
  renderType: string;
  dataSource: Array<string>;
  urlPrefix?: string;
  connectElementType?: string;
  suffix?: string;
  prefix?: string;
  extraTimes?: number;
  readOnly?: boolean;
}) => {
  const initialState: iState<T> = {
    isLoading: true,
    data: [],
  };
  const [state, setState] = useState(initialState);

  useEffect(
    () => {
      const fetchData = async () => {
        setState(prevState => ({ ...prevState, isLoading: true }));
        try {
          const { data } = await fetchFn();
          setState(prevState => ({ ...prevState, isLoading: false, data }));
        } catch (error) {
          apiErrorToast(error);
          setState(prevState => ({ ...prevState, isLoading: false }));
        }
      };
      fetchData();
    },
    //  eslint-disable-next-line
    [],
  );

  const getRenderComponents = () => {
    const parts = state.data.map((item: T) => {
      switch (renderType) {
        case 'text':
          return handleNullException(item, dataSource[0]);
        case 'number':
          return Number(handleNullException(item, dataSource[0]));
        case 'number-conversion':
          return (
            Number(handleNullException(item, dataSource[0])) * (Number(handleNullException(item, dataSource[1])) || 1)
          );
        case 'link':
          return readOnly ? (
            handleNullException(item, dataSource[1])
          ) : (
            <LinkBtnUrl
              key={handleNullException(item, dataSource[0])}
              btnName={handleNullException(item, dataSource[1])}
              url={`${urlPrefix}/${handleNullException(item, dataSource[0])}`}
            />
          );
        default:
          return '';
      }
    });
    switch (connectElementType) {
      case 'concat':
        return intersperse(
          //  eslint-disable-next-line
          parts.filter((item: any) => item !== ''),
          ', ',
        );
      case 'sum':
        return _.sum(parts) === 0 ? '' : `${numberRound(_.sum(parts) * extraTimes, NUMBER_ROUND_DECIMAL)} ${suffix}`;
      default:
        return '';
    }
  };
  if (state.isLoading) return <Spinner size={'small'} />;
  return <div>{getRenderComponents()}</div>;
};

export default FetchRelatedElements;
