import React, { useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iProduct from '../../../types/product/iProduct';
import { iSortOrderType } from '../../../shared/UITypes/types';
import { getHeads, getRows } from './TableHelper';
import iProductAttribute from '../../../types/product/iProductAttribute';

const ProductListTable = ({
  data,
  onDelete,
  onEdit,
  onSetSort,
  isLoading,
  columns,
  prodAttrs,
}: {
  data: Array<iProduct>;
  onDelete: (id: string) => void;
  onEdit: (name: string, value: boolean, id: string) => void;
  onSetSort: (sortStr: string) => void;
  isLoading: boolean;
  columns: Array<string>;
  prodAttrs: Array<iProductAttribute>;
}) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  return (
    <DynamicTable
      head={getHeads(columns, 'product')}
      rows={getRows(data, columns, onEdit, onDelete, prodAttrs)}
      testId={'product-list-table'}
      isLoading={isLoading}
      onSort={
        //  eslint-disable-next-line
        (data: any) => {
          setSortKey(data.key);
          setSortOrder(data.sortOrder);
          onSetSort(`productCode:${data.sortOrder}`);
        }
      }
      sortKey={sortKey}
      sortOrder={sortOrder}
    />
  );
};

export default ProductListTable;
