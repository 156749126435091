import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iFaultCategory from '../../../types/NCR/iFaultCategory';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import { handleNullException } from '../../../services/UtilsService';

const CreateAndEditModalBody = ({
  control,
  errors,
  onChange,
  target,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iFaultCategory;
}) => {
  return (
    <>
      <InputWithController
        name={'name'}
        label={'Name'}
        control={control}
        onChange={onChange}
        defaultValue={target && handleNullException(target, 'name')}
        errors={errors}
        isRequired
      />
      <InputWithController
        name={'comments'}
        label={'Description'}
        control={control}
        onChange={onChange}
        defaultValue={target && handleNullException(target, 'comments')}
        errors={errors}
        isRequired
      />
    </>
  );
};

export default CreateAndEditModalBody;
