import React from 'react';
import JobSchedule from '../JobSchedule';
import JobScheduleContext from '../JobScheduleContext';
/**
 * JobScheduleContext,DragDropContext not at the same level
 */
const JobScheduleWithContext = () => {
  return (
    <JobScheduleContext>
      <JobSchedule />
    </JobScheduleContext>
  );
};

export default JobScheduleWithContext;
