import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iEntityStatus from '../../../../types/status/iEntityStatus';
import InputWithController from '../../../../shared/form/hookForms/InputWithController';
import { handleNullException } from '../../../../services/UtilsService';
import iEntityCategory from '../../../../types/status/iEntityCategory';
import SelectWithController from '../../../../shared/form/hookForms/SelectWithController';

type iEntityStatusEditModal = {
  categoryList: iEntityCategory[];
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iEntityStatus;
  gridWidth?: number;
  //   watch: (names?: string | string[]) => unknown;
};

const EntityStatusEditModal = ({
  categoryList,
  control,
  errors,
  onChange,
  target,
  gridWidth = 6,
}: iEntityStatusEditModal) => {
  const getCategoryOptions = () => {
    return categoryList.map((category: iEntityCategory) => ({
      label: category.name,
      value: category.id,
    }));
  };

  return (
    <Page>
      <Grid layout={'fluid'} spacing={'cosy'} testId={'entity-status-edit-panel'}>
        <GridColumn medium={gridWidth}>
          <InputWithController
            label={'Name'}
            name={'name'}
            defaultValue={handleNullException(target, 'name')}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
          />
        </GridColumn>
        <GridColumn medium={gridWidth}>
          <InputWithController
            label={'Code'}
            name={'code'}
            defaultValue={handleNullException(target, 'code')}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
          />
        </GridColumn>
        <GridColumn medium={gridWidth}>
          <InputWithController
            label={'Description'}
            name={'description'}
            defaultValue={handleNullException(target, 'description')}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired={false}
          />
        </GridColumn>
        <GridColumn medium={gridWidth}>
          <SelectWithController
            name={'entityStatusCategoryId'}
            label={'Category'}
            control={control}
            defaultValue={handleNullException(target, 'entityStatusCategoryId')}
            onChange={onChange}
            errors={errors}
            options={getCategoryOptions()}
            isRequired
            isClearable
            className={'min-width-120'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default EntityStatusEditModal;
