import React from 'react';
import { Grid } from '@atlaskit/page';
import { useParams } from 'react-router-dom';

import BreadcrumbInHeader from '../../../../components/settings/BreadcrumbInHeader';
import BackendPage from '../../../../layout/BackendPage';
import EntityStatusTypeService from '../../../../services/EntityStatusTypeService';
import useDetailHook from '../../../../shared/hooks/useDetailHook/useDetailHook';
import { iRouteTypeId } from '../../../../shared/UITypes/types';

import iEntityStatusType from '../../../../types/status/iEntityStatusType';
import Sidebar from '../../sidebar/Sidebar';
import EntityStatus from './EntityStatus';
import { handleNullException } from '../../../../services/UtilsService';
import SpinWhenLoading from '../../../../shared/spin/SpinWhenLoading';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import { SETTINGS_ENTITY_STATUS_TYPES_URL } from '../../../../shared/UrlMap';

const EntityStatusTypeDetail = () => {
  const { id } = useParams<iRouteTypeId>();

  const { state, onUpdateOneField } = useDetailHook<iEntityStatusType>({
    id: `${id}`,
    getFn: EntityStatusTypeService.getEntityStatusTypeDetail,
    updateFn: EntityStatusTypeService.updateEntityStatusType,
  });

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader
        subName={handleNullException(state, 'data.entityName')}
        extraLayer={<LinkBtnUrl url={SETTINGS_ENTITY_STATUS_TYPES_URL} btnName={'Statuses'} />}
      />
      {/* <PageTitle>{handleNullException(state, 'data.entityName')}</PageTitle> */}
    </>
  );

  if (state.isLoading) return <SpinWhenLoading />;

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <Grid layout={'fluid'} />
      <EntityStatus
        typeId={id}
        typeName={handleNullException(state, 'data.entityName')}
        onUpdateOneField={onUpdateOneField}
      />
    </BackendPage>
  );
};

export default EntityStatusTypeDetail;
