import React from 'react';
import { AppearanceTypes } from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import CheckIcon from '@atlaskit/icon/glyph/check';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Tooltip from '@atlaskit/tooltip';

const PreviewStatus = ({
  appearance,
  message,
}: {
  appearance: AppearanceTypes | undefined;
  message: Array<string> | undefined;
}) => {
  const getMessageComponent = () => {
    if (message && message.length > 0)
      return (
        <ul>
          {message.map((item: string) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      );
    return null;
  };
  if (appearance === 'error')
    return (
      <Tooltip content={getMessageComponent()}>
        <ErrorIcon label={'error'} primaryColor={'#E31515'} />
      </Tooltip>
    );
  if (appearance === 'warning')
    return (
      <Tooltip content={getMessageComponent()}>
        <WarningIcon label={'warning'} primaryColor={'rgb(255, 196, 0)'} />
      </Tooltip>
    );

  return <CheckIcon label={'success'} primaryColor={'#14DA0C'} />;
};

export default PreviewStatus;
