import Spinner from '@atlaskit/spinner';
import React, { useEffect, useState } from 'react';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import BackendPage from '../../../layout/BackendPage';
import { PageTitle } from '../../../shared/styles/styles';
import Sidebar from '../sidebar/Sidebar';
import ChannelScriptTypeService from '../../../services/integration/ChannelScriptTypeService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import iChannelScriptType from '../../../types/integration/iChannelScriptType';
import ChannelsPerType from './components/ChannelsPerType';
import iChannel from '../../../types/integration/iChannel';
import ChannelService from '../../../services/integration/ChannelService';

const IntegrationHeader = () => (
  <>
    <BreadcrumbInHeader subName={'Integrations'} />
    <PageTitle>Integrations</PageTitle>
  </>
);

const IntegrationSetting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [channelScriptTypes, setChannelScriptTypes] = useState<iChannelScriptType[]>([]);
  const [channels, setChannels] = useState<iChannel[]>([]);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    Promise.all([ChannelScriptTypeService.getChannelScriptTypes(), ChannelService.getChannels()])
      .then(resp => {
        if (isCanceled) {
          return;
        }
        setChannelScriptTypes(resp[0]);
        setChannels(resp[1]);
      })
      .catch(err => {
        if (isCanceled) {
          return;
        }
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, []);

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <div>
        {channelScriptTypes.map(channelScriptType => (
          <ChannelsPerType channelScriptType={channelScriptType} channels={channels} key={channelScriptType.id} />
        ))}
      </div>
    );
  };
  return (
    <BackendPage pageHeader={<IntegrationHeader />} leftMenu={<Sidebar />}>
      {getContent()}
    </BackendPage>
  );
};

export default IntegrationSetting;
