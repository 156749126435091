import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import InlineEdit from '@atlaskit/inline-edit';
import { AsyncSelect, ValueType } from '@atlaskit/select';
import { ErrorMessage } from '@atlaskit/form';
import { handleNullException } from '../../../../services/UtilsService';
import InlineEditReadView from '../../InlineEditReadView';

type iSingleSelectOption<T> = iOption<T> | null | undefined;

type iOption<T> = {
  label: string;
  value: T;
};
const DivWrapper = styled.div``;

type iState = {
  focused: boolean;
};
const initialState: iState = {
  focused: false,
};

const InlineEditAsyncSelect = <T extends { id: string }>({
  label,
  name,
  defaultValue,
  promiseFn,
  formatOptionLabel,
  onConfirm,
  isRequired = false,
  readViewField = 'name',
  isClearable = true,
  hideActionButtons = false,
  placeholder = 'Search...',
  testId = 'inline-edit-select',
  isDisabled,
}: {
  label: string;
  name: string;
  defaultValue?: T;
  //  eslint-disable-next-line
  promiseFn: (keyword: string) => Promise<any>;
  formatOptionLabel: (option: iSingleSelectOption<T>) => React.ReactNode | string;
  //  eslint-disable-next-line
  onConfirm: (name: string, newValue: T | null) => Promise<any>;
  isRequired?: boolean;
  readViewField?: string;
  isClearable?: boolean;
  hideActionButtons?: boolean;
  placeholder?: string;
  testId?: string;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState(initialState);
  const generateDefaultOption = () => {
    return typeof defaultValue === 'undefined'
      ? undefined
      : {
          label: handleNullException(defaultValue, 'id'),
          value: defaultValue,
        };
  };
  const onEditConfirm = (selected: iSingleSelectOption<T>) => {
    const value = selected ? selected.value : null;
    onConfirm(name, value).then(() => setState(prevState => ({ ...prevState, focused: false })));
  };
  const promiseOptions = (inputValue: string, callback: (options: iOption<T>[]) => void) => {
    promiseFn(inputValue).then(({ data: items }) => {
      callback(
        items.map((item: T) => ({
          label: item.id,
          value: item,
        })),
      );
    });
  };
  const debouncedOptions = _.debounce(promiseOptions, 700);
  const validate = (value: iSingleSelectOption<T>) => {
    if (isRequired && !value) {
      return 'is Required';
    }
    return undefined;
  };
  return (
    <DivWrapper
      id={name}
      tabIndex={0}
      onFocus={() => {
        if (isDisabled === false) {
          setState(preState => ({ ...preState, focused: true }));
        }
      }}
      data-testid={`div-wrapper-${testId}`}
    >
      <InlineEdit<ValueType<iOption<T>>>
        defaultValue={generateDefaultOption()}
        onConfirm={onEditConfirm}
        label={label}
        editView={({ errorMessage, ...fieldProps }) => (
          <>
            <AsyncSelect
              //  eslint-disable-next-line
              {...fieldProps}
              isClearable={isClearable}
              loadOptions={debouncedOptions}
              placeholder={placeholder}
              formatOptionLabel={formatOptionLabel}
              autoFocus
              isInvalid={fieldProps.isInvalid}
              isDisabled={isDisabled}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
        readView={() => (
          <InlineEditReadView isDisabled={isDisabled} value={handleNullException(defaultValue, readViewField)} />
        )}
        hideActionButtons={hideActionButtons}
        isEditing={state.focused}
        onEdit={() => {
          if (isDisabled === false) {
            setState(preState => ({ ...preState, focused: true }));
          }
        }}
        onCancel={() => setState({ ...state, focused: false })}
        validate={validate}
      />
    </DivWrapper>
  );
};

export default InlineEditAsyncSelect;
