import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Spinner from '@atlaskit/spinner';
import iUser from '../../../types/user/iUser';
import RedirectAfterSuccessAction from '../shared/RedirectToActivation';
import ResendToken from '../shared/ResendToken';
import CompanyLogo from '../../../shared/company/CompanyLogo';
import ResetPasswordForm from './ResetPasswordForm';
import AppPageCentered from '../shared/AppPageCentered';
import { validateTempToken } from '../../../services/AuthService';
import {
  RESET_PASSWORD_FIELDS,
  RETRIEVAL_HINT,
} from '../shared/Auth.constants';
import {
  addToastForAPIResponse,
  apiErrorToast,
} from '../../../shared/toast/Toast';

type iState = {
  isLoading: boolean;
  isValid: boolean;
  user?: iUser;
};
const initialState: iState = {
  isLoading: true,
  isValid: false,
};
const ResetPassword = () => {
  const { token } = queryString.parse(window.location.search);
  const [state, setState] = useState(initialState);
  // validate token
  useEffect(() => {
    let isCancelled = false;
    const validate = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const { isValid, message, user } = await validateTempToken(
          token as string,
        );
        if (isCancelled) return;
        if (!isValid) {
          addToastForAPIResponse('error', message);
          setState(prevState => ({ ...prevState, isLoading: false, isValid }));
          return;
        }
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          isValid,
          user,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    validate();
    return () => {
      isCancelled = true;
    };
  }, [token]);

  if (state.isLoading)
    return (
      <AppPageCentered>
        <Spinner />
      </AppPageCentered>
    );

  if (!state.isValid) {
    return (
      <AppPageCentered>
        <CompanyLogo />
        <ResendToken
          resendSubmitData={{ token: token as string, resetPassword: 'true' }}
        >
          <RedirectAfterSuccessAction message={RETRIEVAL_HINT} />
        </ResendToken>
      </AppPageCentered>
    );
  }
  return (
    <AppPageCentered>
      <ResetPasswordForm
        fields={RESET_PASSWORD_FIELDS}
        onSubmitExtraFields={{
          token: token as string,
          userId: state.user?.id,
        }}
      />
    </AppPageCentered>
  );
};

export default ResetPassword;
