import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, GridColumn } from '@atlaskit/page';
import Spinner from '@atlaskit/spinner';
import Button from '@atlaskit/button';
import { useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';
import BackendPage from '../../../layout/BackendPage';
import {
  cloneProdAttributeSet,
  deleteProdAttributeSet,
  getProdAttributeSetDetail,
  updateProdAttributeSet,
} from '../../../services/product/ProductAttributeSetService';
import { handleNullException } from '../../../services/UtilsService';
import BreadcrumbsTwoLayers from '../../../shared/heading/BreadcrumbsTwoLayers';
import useDetailHook from '../../../shared/hooks/useDetailHook/useDetailHook';
import PopupContent from '../../../shared/popup/GeneralPopupContent';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import { FlexContainer, PageTitle } from '../../../shared/styles/styles';
import { iParams, iRouteTypeId } from '../../../shared/UITypes/types';
import iProductAttributeSet from '../../../types/product/iProductAttributeSet';
import { LIST_PAGE_NAME } from '../constants';
import DeleteByRetype from '../../../shared/deleteWithReType/DeleteByReType';
import InlineEdit from '../../../shared/form/InlineEdit';
import { PRODUCT_ATTRIBUTE_SETS_URL } from '../../../shared/UrlMap';
import ProdAttribute from './ProdAttribute';
import Activities from '../../../shared/activitySection/GeneralActivity';
import { CLONE } from '../../../shared/constants/ActionConstants';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/Toast';
import CloneSetModalBody from './CloneSetModalBody';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCT_ATTRIBUTES } from '../../../types/settings/UserAccess/iAccess';
import {
  ACCESS_CAN_CREATE,
  ACCESS_CAN_DELETE,
  ACCESS_CAN_UPDATE,
} from '../../../types/settings/UserAccess/iRoleAccess';

const ProdAttributeSetDetail = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const { id } = useParams<iRouteTypeId>();
  const history = useHistory();

  const { control, setValue, errors, handleSubmit } = useForm();
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const canCreate = AccessService.hasAccess(ACCESS_CODE_PRODUCT_ATTRIBUTES, ACCESS_CAN_CREATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_PRODUCT_ATTRIBUTES, ACCESS_CAN_DELETE, user);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_PRODUCT_ATTRIBUTES, ACCESS_CAN_UPDATE, user);

  const {
    state,
    edit,
    onUpdateOneField,
    onOpenDeleteModal,
    onDelete,
    onCloseModal,
  } = useDetailHook<iProductAttributeSet>({
    id: `${id}`,
    getFn: getProdAttributeSetDetail,
    updateFn: updateProdAttributeSet,
    deleteFn: deleteProdAttributeSet,
    deleteCallback: () => history.push(PRODUCT_ATTRIBUTE_SETS_URL),
  });

  const onSubmit = async (data: iParams) => {
    try {
      setIsLoading(true);
      const cloned = await cloneProdAttributeSet(id, {
        name: data.name,
        description: data.description,
      });
      addToastForAPIResponse('success');
      setIsLoading(false);
      setIsCloneModalOpen(false);
      history.push(`${PRODUCT_ATTRIBUTE_SETS_URL}/${cloned?.id}`);
    } catch (e) {
      apiErrorToast(e);
      setIsLoading(false);
    }
  };

  const getCornerBtns = () => {
    if (!canCreate && !canDelete) {
      return null;
    }
    return (
      <MoreIconPopup>
        <PopupContent id={id} onOpenDeleteModal={canDelete ? onOpenDeleteModal : undefined}>
          {canCreate ? (
            <Button className={'popup-item'} onClick={() => setIsCloneModalOpen(true)}>
              {CLONE}
            </Button>
          ) : null}
        </PopupContent>
      </MoreIconPopup>
    );
  };

  const getPageHeader = () => {
    return (
      <FlexContainer className={'space-between'}>
        <div>
          <BreadcrumbsTwoLayers
            name={LIST_PAGE_NAME}
            url={PRODUCT_ATTRIBUTE_SETS_URL}
            subName={handleNullException(state, 'data.name')}
          />

          <PageTitle className={'space-right'}>{handleNullException(state, 'data.name')}</PageTitle>
        </div>
        {getCornerBtns()}
      </FlexContainer>
    );
  };

  if (state.isLoading) {
    return (
      <BackendPage>
        <Spinner />
      </BackendPage>
    );
  }

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <InlineEdit
            name={'name'}
            defaultValue={handleNullException(state, 'data.name')}
            label={'Name'}
            onConfirm={onUpdateOneField}
            testId={'productAttributeSet-name'}
            isDisabled={!canUpdate}
          />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEdit
            name={'description'}
            defaultValue={handleNullException(state, 'data.description')}
            label={'Description'}
            onConfirm={onUpdateOneField}
            testId={'productAttributeSet-description'}
            isDisabled={!canUpdate}
          />
        </GridColumn>
      </Grid>

      <ProdAttribute setId={id} canCreate={canUpdate} canDelete={canUpdate} canUpdate={canUpdate} />

      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={8}>
          <Activities modelName={'ProductAttributeSet'} modelId={id} />
        </GridColumn>
      </Grid>
      <DeleteByRetype
        isOpen={edit.isDeleteModalOpen}
        onClose={onCloseModal}
        onConfirm={() => onDelete(id)}
        answer={handleNullException(state, 'data.name')}
      />

      <CustomizeModal
        isOpen={isCloneModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={() => setIsCloneModalOpen(false)}
        isConfirming={isLoading}
        isDisabled={Object.keys(errors).length > 0}
        modalHeading={'Are you sure to create a clone of this set?'}
        modalBody={<CloneSetModalBody control={control} errors={errors} onChange={setValue} target={state.data} />}
        confirmBtnName={'Clone'}
      />
    </BackendPage>
  );
};

export default ProdAttributeSetDetail;
