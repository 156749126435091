import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select, { ValueType } from '@atlaskit/select';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import InlineEdit from '@atlaskit/inline-edit';
import { colors } from '@atlaskit/theme';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import { iLabelValuePair } from '../UITypes/types';

const ReadViewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${fontSize()}px;
  height: ${(gridSize() * 2.5) / fontSize()}em;
  line-height: ${(gridSize() * 2.5) / fontSize()};
  max-width: 100%;
  padding: ${gridSize()}px ${gridSize() - 2}px;
  background-color: ${colors.N20};

  & span {
    margin-left: 0.4rem;
    margin-top: 0.1rem;
  }

  .viewer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const DivWrapper = styled.div``;

type iState = {
  focused: boolean;
  value: ValueType<iLabelValuePair>;
};
const initialState: iState = {
  focused: false,
  value: undefined,
};
export default ({
  name,
  defaultValue,
  label,
  selectOptions,
  onConfirm,
  testId = 'inline-edit-select',
  isClearable = false,
  hideActionButtons = false,
  isDisabled = false,
  readViewFitContainerWidth = false,
}: {
  name: string;
  defaultValue?: iLabelValuePair | null;
  label?: string;
  selectOptions: Array<iLabelValuePair>;
  //  eslint-disable-next-line
  onConfirm?: (name: string, newValue: string | null) => any;
  testId?: string;
  isClearable?: boolean;
  hideActionButtons?: boolean;
  isDisabled?: boolean;
  readViewFitContainerWidth?: boolean;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    setState(s => ({
      ...s,
      value: defaultValue,
    }));
  }, [defaultValue, selectOptions]);

  const changeValue = (selected: ValueType<iLabelValuePair>) => {
    const value = selected ? (selected as iLabelValuePair).value : null;
    // not change
    if (value === defaultValue?.value || (value === null && typeof defaultValue === 'undefined')) {
      setState({
        ...state,
        focused: false,
      });
      return;
    }
    setState({
      ...state,
      value: selected,
      focused: false,
    });
    if (typeof onConfirm === 'function') {
      onConfirm(name, value);
    }
  };
  return (
    <DivWrapper
      id={name}
      tabIndex={0}
      onFocus={() => setState(preState => ({ ...preState, focused: true }))}
      data-testid={`div-wrapper-${testId}`}
    >
      <InlineEdit<ValueType<iLabelValuePair>>
        defaultValue={defaultValue}
        onConfirm={changeValue}
        hideActionButtons={hideActionButtons}
        label={label}
        readViewFitContainerWidth={readViewFitContainerWidth}
        editView={fieldProps => (
          <Select
            // eslint-disable-next-line
            {...fieldProps}
            options={selectOptions}
            autoFocus
            defaultMenuIsOpen
            classNamePrefix={`${testId}-prefix`}
            className={testId}
            isClearable={isClearable}
            isDisabled={isDisabled}
          />
        )}
        readView={() => (
          <ReadViewContainer>
            <div className={'viewer'}>
              {!state.value ? 'Click to select value' : (state.value as iLabelValuePair).label}
            </div>
            <span>
              <HipchatChevronDownIcon label={'ChevronDropdown'} size="small" />
            </span>
          </ReadViewContainer>
        )}
        isEditing={!isDisabled && state.focused}
        onEdit={() => setState({ ...state, focused: true })}
        onCancel={() => setState({ ...state, focused: false })}
      />
    </DivWrapper>
  );
};
