import Button from '@atlaskit/button';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import HipchatChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up';
import React from 'react';

const TableToggle = ({
  state,
  onClick,
}: {
  state: boolean;
  onClick: () => void;
}) => (
  <Button
    iconAfter={
      state ? (
        <HipchatChevronDownIcon label={'down'} />
      ) : (
        <HipchatChevronUpIcon label={'up'} />
      )
    }
    appearance={'subtle'}
    onClick={onClick}
  >
    Moved Items
  </Button>
);

export default TableToggle;
