import DynamicTable from '@atlaskit/dynamic-table';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { getDeleteBtn, getHeads } from '../../../components/settings/utils';
import { handleNullException } from '../../../services/UtilsService';
import LinkBtn from '../../../shared/buttons/LinkBtn';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import iWrappingMethod from '../../../types/iWrappingMethod';

const TableWrapper = styled.div`
  th[data-testid='settings-wrappingMethod-table-column-Name--head--cell'] {
    width: 30%;
    min-width: 10rem;
  }
  th[data-testid='settings-wrappingMethod-table-column-Description--head--cell'] {
    width: 30%;
    min-width: 10rem;
  }

  td:last-child {
    max-width: 2rem;
  }
`;

export const COLUMNS = [
  'Name',
  'Description',
  'Created',
  'Updated',
  'Operation',
];

const WrappingMethodListTable = ({
  data,
  onDelete,
  onEdit,
  isLoading,
}: {
  data: Array<iWrappingMethod>;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  isLoading: boolean;
}) => {
  const getRows = () => {
    return data
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iWrappingMethod) => ({
        cells: [
          {
            key: item.name,
            content: (
              <LinkBtn
                btnName={handleNullException(item, 'name')}
                testId={`wrappingMethod-list-linkBtn-${item.id}`}
                onClick={() => onEdit(item.id)}
              />
            ),
          },
          {
            key: item.description,
            content: item.description,
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'createdAt')}
                operator={handleNullException(item, 'createdBy.firstName')}
              />
            ),
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'updatedAt')}
                operator={handleNullException(item, 'updatedBy.firstName')}
              />
            ),
          },
          {
            content: getDeleteBtn({
              id: item.id,
              answer: item.name || 'name n/a',
              onClick: onDelete,
            }),
          },
        ],
      }));
  };
  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(COLUMNS, 'settings-wrappingMethod')}
        rows={getRows()}
        testId={'settings-wrappingMethod-list-table'}
        isLoading={isLoading}
        rowsPerPage={10}
        defaultPage={1}
      />
    </TableWrapper>
  );
};

export default WrappingMethodListTable;
