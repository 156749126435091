import React from 'react';
import { useForm } from 'react-hook-form';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import PlusIconAfterTitle from '../../../components/settings/PlusIconAfterTitle';
import BackendPage from '../../../layout/BackendPage';
import {
  createPallet,
  deactivatePallet,
  getPalletSystems,
  updatePallet,
} from '../../../services/Settings/PalletSystemService';

import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import iPalletSystem from '../../../types/iPalletSystem';
import Sidebar from '../sidebar/Sidebar';
import PalletEditModal from './PalletEditModal';
import PalletListTable from './PalletListTable';

const PalletSetting = () => {
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onSubmit,
  } = useListCrudHook<iPalletSystem>({
    getFn: getPalletSystems,
    createFn: createPallet,
    updateFn: updatePallet,
    deleteFn: deactivatePallet,
  });
  const { control, setValue, errors, handleSubmit } = useForm();

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'Pallet Systems'} />
      <PlusIconAfterTitle pageTitle={'Pallet Systems'} onClick={onOpenAddModal} />
    </>
  );

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <PalletListTable data={state.data} onDelete={onDelete} onEdit={onOpenEditModal} isLoading={state.isLoading} />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={<PalletEditModal control={control} errors={errors} onChange={setValue} target={edit.target} />}
        modalHeading={edit.target ? edit.target.name : 'Create'}
        confirmBtnName={'Save'}
      />
    </BackendPage>
  );
};

export default PalletSetting;
