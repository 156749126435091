import React from 'react';
import Button from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';

const FallBackFeedback = () => (
  <SectionMessage title="Oops, an error occurs here" appearance="error">
    <p>The development team has just been notified. Thank you!</p>
    <Button
      appearance="primary"
      onClick={() => window.location.reload()}
      testId={'reload-page'}
    >
      Reload Page
    </Button>
  </SectionMessage>
);
export default FallBackFeedback;
