import React from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';

import iReceivingItem from '../../../../types/purchases/iReceivingItem';
import { iConfigColumn } from '../../../despatchNote/shared/DispatchNote.type';

import {
  PO_RECEIVED_PRODUCTS_TABLE_NAME,
  PO_RECEIVED_PRODUCTS_TABLE_TEST_ID,
} from '../../constants';

import { getRows } from './ReceivedProductTableHelper';
import { getHeads } from '../../list/TableHelper';

const TableWrapper = styled.div``;
const ReceivedProductTable = ({
  isLoading,
  data,
  columns,
}: {
  isLoading: boolean;
  data: iReceivingItem[];
  columns: Array<iConfigColumn>;
}) => {
  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(columns, PO_RECEIVED_PRODUCTS_TABLE_NAME)}
        rows={getRows(data, columns)}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        testId={PO_RECEIVED_PRODUCTS_TABLE_TEST_ID}
      />
    </TableWrapper>
  );
};

export default ReceivedProductTable;
