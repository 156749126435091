import React, { useEffect, useState } from 'react';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import Button from '@atlaskit/button';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useEdges, useNodes } from 'react-flow-renderer';
import { FlexContainer } from '../../../shared/styles/styles';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import EntityStatusEditModal from '../../settings/entityStatusTypes/detail/EntityStatusEditModal';
import iEntityCategory from '../../../types/status/iEntityCategory';
import {
  getAnyStatusNode,
  getNewEntityStatus,
  getStatusNode,
} from '../../../shared/workflow/diagram/WorkflowDiagramHelper';
import WorkflowTransitionEditModal from './WorkflowTransitionEditModal';
import { iWFDiagramState } from '../../../shared/workflow/diagram/WorkflowDiagramReducer';

type iWorkflowEditCreateButtons = {
  className?: string;
  state: iWFDiagramState;
};

const Wrapper = styled.div`
  .select-react-hook-form__menu {
    z-index: 99999;
  }
`;

const WorkflowEditCreateButtons = ({ className, state }: iWorkflowEditCreateButtons) => {
  const [categoryMap, setCategoryMap] = useState<{ [key: string]: iEntityCategory }>({});
  const [showNewStatusPanel, setShowNewStatusPanel] = useState(false);
  const [showNewTransitPanel, setShowNewTransitPanel] = useState(false);
  const { control, setValue, errors, handleSubmit } = useForm();
  const nodes = useNodes();
  const edges = useEdges();

  useEffect(() => {
    setCategoryMap(
      state.entityStatusCategories.reduce((map, category) => {
        return {
          ...map,
          [category.id]: category,
        };
      }, {}),
    );
  }, [state.entityStatusCategories]);

  const newNewStatusInWorkflow = (data: { [key: string]: string }) => {
    const newEntityStatusData = getNewEntityStatus(
      `${data.name || ''}`,
      `${data.code || ''}`,
      `${data.description || ''}`,
      categoryMap[data.entityStatusCategoryId],
    );
    const newNode = getStatusNode(newEntityStatusData, {
      x: Math.round(window.innerWidth / 2) - 200,
      y: Math.round(window.innerHeight / 2),
    });

    const { node, edge } = getAnyStatusNode(newNode);
    state.setEdges([...edges, edge]);
    state.setNodes([...nodes, newNode, node]);
    return true;
  };

  const getNewStatusPanel = () => {
    if (!showNewStatusPanel) {
      return null;
    }
    return (
      <Wrapper data-testid={'new-status-modal'}>
        <CustomizeModal
          shouldScrollInViewport
          onCancel={() => setShowNewStatusPanel(false)}
          modalHeading={'Creating a new status'}
          isOpen={showNewStatusPanel}
          modalBody={
            <div>
              <p>Statuses capture the stages of your working process.</p>
              <EntityStatusEditModal
                control={control}
                onChange={setValue}
                errors={errors}
                categoryList={state.entityStatusCategories}
              />
            </div>
          }
          confirmBtnName={'Create'}
          onConfirm={handleSubmit(newNewStatusInWorkflow)}
        />
      </Wrapper>
    );
  };

  const getNewTransitPanel = () => {
    if (!showNewTransitPanel) {
      return null;
    }
    return (
      <WorkflowTransitionEditModal
        onCancel={() => setShowNewTransitPanel(false)}
        isOpen={showNewTransitPanel}
        state={state}
      />
    );
  };

  return (
    <FlexContainer data-testid={'wkflow-create-btns'} className={`wkflow-edit-create-btns-wrapper ${className || ''}`}>
      <Button
        testId={'create-status-btn'}
        appearance={'subtle'}
        iconBefore={<EditorAddIcon label="add" />}
        onClick={() => setShowNewStatusPanel(true)}
      >
        Status
      </Button>
      <Button
        testId={'create-transition-btn'}
        appearance={'subtle'}
        iconBefore={<EditorAddIcon label="add" />}
        onClick={() => setShowNewTransitPanel(true)}
      >
        Transition
      </Button>
      {getNewStatusPanel()}
      {getNewTransitPanel()}
    </FlexContainer>
  );
};

export default WorkflowEditCreateButtons;
