import AppService from './AppService';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';

export const getDispatchNoteItemList = (
  dispatchNoteId: string,
  config?: iParams,
) => {
  return AppService.get(`/dispatchNoteItem/dispatchNote/${dispatchNoteId}`, {
    ...config,
    include:
      'job,salesOrderItem.salesOrder.shippingAddress,salesOrderItem.measurement,product.measurement',
  }).then(({ data }) => data);
};

export const deactivateDispatchNoteItem = (id: string) => {
  return AppService.delete(`/dispatchNoteItem/${id}`).then(({ data }) => data);
};

export const createDispatchNoteItem = (postData: iKeyValuePairs) => {
  return AppService.post('/dispatchNoteItem', postData).then(
    ({ data }) => data,
  );
};

export const updateDispatchNoteItem = (id: string, updateContent: iParams) => {
  return AppService.put(`/dispatchNoteItem/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const getDispatchNoteItems = (config?: iParams) => {
  return AppService.get('/dispatchNoteItem', {
    ...config,
    include: 'job,salesOrderItem.salesOrder,product.measurement,dispatchNote',
  }).then(({ data }) => data);
};

export const getDNItemConfig = (config?: iParams) => {
  return AppService.get('/dispatchNoteItem', config).then(({ data }) => data);
};

export default {
  getDispatchNoteItemList,
  deactivateDispatchNoteItem,
  createDispatchNoteItem,
  updateDispatchNoteItem,
  getDispatchNoteItems,
  getDNItemConfig,
};
