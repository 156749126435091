import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getDeleteBtn, getHeads } from '../../../components/settings/utils';
import ChannelService from '../../../services/integration/ChannelService';
import { handleNullException } from '../../../services/UtilsService';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import { apiErrorToast } from '../../../shared/toast/Toast';
import iChannel from '../../../types/integration/iChannel';
import { iTaxRate } from '../../../types/iTaxRate';
import MyobEntitySelector from '../../../shared/channel/myob/MyobEntitySelector';
import MyobConnectorService from '../../../services/integration/connectors/MyobConnectorService';

const TaxRateListTable = ({
  taxRates,
  onDelete,
  onEdit,
  isLoading,
  isCompact = false,
}: {
  taxRates: Array<iTaxRate>;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
  isLoading: boolean;
  isCompact?: boolean;
}) => {
  const [isLoadingChannels, setIsLoadingChannels] = useState(false);
  const [channelsForAccouting, setChannelsForAccouting] = useState<iChannel[]>([]);
  useEffect(() => {
    setIsLoadingChannels(true);
    const fetchData = async () => {
      try {
        const channelsResp = await ChannelService.getChannelsForAccounting();
        setChannelsForAccouting(channelsResp);
        setIsLoadingChannels(false);
      } catch (error) {
        apiErrorToast(error);
      }
    };
    fetchData();
  }, []);

  const getNameContent = (taxRate: iTaxRate) => (
    <Button
      appearance={'link'}
      spacing={'none'}
      onClick={() => onEdit && onEdit(taxRate.id)}
      testId={`tax-list-linkBtn-${taxRate.id}`}
    >
      {taxRate.name}
    </Button>
  );

  const getColumns = () => {
    const channelNames = channelsForAccouting.map(channel => `Accounting: ${channel.name}`);
    return ['Tax name', 'Rate', ...channelNames, ...(isCompact ? [] : ['Created', 'Updated', 'Operation'])];
  };

  const getAccountingChannelCols = (taxRate: iTaxRate) => {
    return channelsForAccouting.map(channel => ({
      key: channel.id,
      content: (
        <MyobEntitySelector
          key={taxRate.id}
          label={taxRate.name}
          channelId={channel.id}
          entityId={taxRate.id}
          entityName="TaxRate"
          promiseFn={MyobConnectorService.getMyobTaxRates}
          optionLabel={['Code', 'Type']}
        />
      ),
    }));
  };

  const getRows = () => {
    return taxRates
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iTaxRate) => ({
        cells: [
          {
            key: item.name,
            content: onEdit ? getNameContent(item) : item.name,
          },
          { key: item.rate, content: `${item.rate}%` },
          ...getAccountingChannelCols(item),
          ...(isCompact
            ? []
            : [
                {
                  content: (
                    <CreatedOrUpdated
                      operatedAt={handleNullException(item, 'createdAt')}
                      operator={handleNullException(item, 'createdBy.firstName')}
                    />
                  ),
                },
                {
                  content: (
                    <CreatedOrUpdated
                      operatedAt={handleNullException(item, 'updatedAt')}
                      operator={handleNullException(item, 'updatedBy.firstName')}
                    />
                  ),
                },
              ]),
          ...(onDelete
            ? [
                {
                  content: getDeleteBtn({
                    id: item.id,
                    answer: item.name,
                    onClick: onDelete,
                  }),
                },
              ]
            : []),
        ],
      }));
  };

  return (
    <DynamicTable
      head={getHeads(getColumns(), 'settings-tax')}
      rows={getRows()}
      testId={'settings-tax-list-table'}
      isLoading={isLoading || isLoadingChannels}
    />
  );
};

export default TaxRateListTable;
