import { Checkbox } from '@atlaskit/checkbox';
import React, { ChangeEvent } from 'react';
import { printDispatchNote } from '../../../services/PrintService';
import {
  assembleAddress,
  formatDate,
  getCategoryCode,
  handleMoney,
  handleNullException,
} from '../../../services/UtilsService';
import { getSalesOrderList } from '../../../services/SalesOrderService';
import CompanyLinkBtn from '../../../shared/buttons/CompanyLinkBtn';
import GeneralPrintBtn from '../../../shared/buttons/GeneralPrintBtn';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import PopupContent from '../../../shared/popup/GeneralPopupContent';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import StatusLozenge from '../../../shared/statusComponent/StatusLozenge';
import { PrintBtnName } from '../../../shared/styles/styles';
import iDispatchNote from '../../../types/dispatchNote/iDispatchNote';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { iConfigColumn } from '../shared/DispatchNote.type';
import FetchRelatedElements from '../../purchases/list/FetchRelatedElements';
import { ENTITY_STATUS_TYPE_DISPATCH_NOTE } from '../../../types/status/iEntityStatusType';
import { SALES_URL } from '../../../shared/UrlMap';
import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../../../shared/constants/AsyncConstants';
import ChannelEntityService from '../../../services/integration/ChannelEntityService';
import iSalesOrderDetail from '../../../types/sales/iSalesOrder';

export const getHeads = (columns: Array<iConfigColumn>, tableName: string) => {
  const cells = columns.map((column: iConfigColumn) => {
    switch (column.type) {
      case 'operation':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      default:
        return {
          key: column.key,
          content: column.name,
          testId: `${tableName}-column-${column.key}`,
          isSortable: !!column.isSortable,
        };
    }
  });
  return { cells };
};

export const getRows = (
  data: Array<iDispatchNote>,
  onOpenDeleteModal: ((id: string) => void) | undefined,
  onCheck: (id: string, value: boolean) => void,
  columns: Array<iConfigColumn>,
  categories: Array<iEntityCategory>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  invoiceTypeId = '',
) => {
  return data.map((dispatchNote: iDispatchNote) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type, isDisabled } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(dispatchNote, key),
            content: handleNullException(dispatchNote, key),
          };
        case 'date':
          return {
            key: handleNullException(dispatchNote, key),
            content: formatDate(handleNullException(dispatchNote, key), 'Do MMM YYYY'),
          };
        case 'money':
          return {
            key: handleNullException(dispatchNote, key),
            content: handleMoney(dispatchNote, key, 2),
          };
        case 'address':
          return {
            key: handleNullException(dispatchNote, key),
            content: assembleAddress(handleNullException(dispatchNote, key)),
          };
        case 'link':
          return {
            key: handleNullException(dispatchNote, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(dispatchNote, key)}
                url={`${column.urlPrefix}/${handleNullException(dispatchNote, dataSource[0])}`}
              />
            ),
          };
        case 'company-link':
          return {
            key: handleNullException(dispatchNote, key),
            content: isDisabled ? (
              `${handleNullException(dispatchNote, dataSource[0])?.name || ''}`
            ) : (
              <CompanyLinkBtn company={handleNullException(dispatchNote, dataSource[0])} />
            ),
          };
        case 'status':
          return {
            key: handleNullException(dispatchNote, `${key}.id`),
            content: (
              <StatusLozenge
                status={handleNullException(dispatchNote, key)}
                categoryCode={getCategoryCode(handleNullException(dispatchNote, key), categories)}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(dispatchNote, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(dispatchNote, key)}
                operator={handleNullException(dispatchNote, dataSource[0])}
              />
            ),
          };
        case 'invoices':
          return {
            content: (
              <FetchRelatedElements
                key={dispatchNote.id}
                fetchFn={() =>
                  getSalesOrderList(
                    {
                      // advancedFilter: `AcquisitionOrderItem_id_orderId.PurchaseOrderItem_id_acquisitionOrderItemId.orderId:${po.id}`,
                      filter: `customerId:${dispatchNote.customerId},createdFromEntityName:${ENTITY_STATUS_TYPE_DISPATCH_NOTE},createdFromEntityId:${dispatchNote.id}`,
                      sort: 'createdAt:DESC',
                      currentPage: ASYNC_SEARCH_CURRENT_PAGE,
                      perPage: ASYNC_SEARCH_PER_PAGE,
                    },
                    true,
                  )
                }
                renderType={'link'}
                dataSource={['id', 'invoiceNumber']}
                urlPrefix={SALES_URL}
                readOnly={isDisabled}
              />
            ),
          };
        case 'channelInvoice':
          return {
            content: (
              <FetchRelatedElements
                key={dispatchNote.id}
                fetchFn={async () => {
                  const channelId = `${dataSource[0]}`.trim();
                  // const channelTypeName = `${dataSource[2]}`.trim();
                  const invoices = await getSalesOrderList(
                    {
                      // advancedFilter: `AcquisitionOrderItem_id_orderId.PurchaseOrderItem_id_acquisitionOrderItemId.orderId:${po.id}`,
                      filter: `customerId:${dispatchNote.customerId},createdFromEntityName:${ENTITY_STATUS_TYPE_DISPATCH_NOTE},createdFromEntityId:${dispatchNote.id}`,
                      sort: 'createdAt:DESC',
                      currentPage: ASYNC_SEARCH_CURRENT_PAGE,
                      perPage: ASYNC_SEARCH_PER_PAGE,
                    },
                    true,
                  );
                  if (invoices.data.length <= 0) {
                    return invoices;
                  }
                  const channelEntities = await ChannelEntityService.getChannelEntities('', '', '', {
                    filter: `channelId:${channelId},entityName:SalesOrder,entityId:${invoices.data
                      .map((invoice: iSalesOrderDetail) => invoice.id)
                      .join('|')}`,
                  });
                  return {
                    data: channelEntities.map(entity => ({
                      ...entity,
                      invoiceNumber: `${entity.externalObj?.Number || ''}`,
                    })),
                  };
                }}
                renderType={'link'}
                dataSource={['entityId', 'invoiceNumber']}
                urlPrefix={SALES_URL}
                readOnly={isDisabled}
              />
            ),
          };
        case 'operation':
          return {
            content: (
              <MoreIconPopup>
                <PopupContent id={dispatchNote.id} onOpenDeleteModal={onOpenDeleteModal}>
                  <GeneralPrintBtn serviceFunc={() => printDispatchNote(dispatchNote.id)}>
                    <PrintBtnName>Print</PrintBtnName>
                  </GeneralPrintBtn>
                </PopupContent>
              </MoreIconPopup>
            ),
          };
        case 'rowCheckbox':
          return {
            content: (
              <Checkbox
                onChange={(event: ChangeEvent<HTMLInputElement>) => onCheck(dispatchNote.id, event.target.checked)}
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'admin-despatch-note-row' };
  });
};

export const getReportArrSegments = (
  data: iDispatchNote[],
  selectedColumns: iConfigColumn[],
  // categories: Array<iEntityCategory>,
) => {
  const selectedColumnNames = selectedColumns
    .map((column: iConfigColumn) => column.name)
    .filter((columnName: string) => columnName !== '');

  const reportCells = data.map((item: iDispatchNote) => {
    return selectedColumns.map((column: iConfigColumn) => {
      const { key, dataSource, type, alternativeKey } = column;
      switch (type) {
        case 'text':
          return handleNullException(item, key);
        case 'link':
          return handleNullException(item, key) || handleNullException(item, alternativeKey || '');
        case 'company-link':
          return handleNullException(item, 'customer.name');
        case 'address':
          return assembleAddress(handleNullException(item, key));
        case 'date':
          return (
            formatDate(handleNullException(item, key), 'DD MMM YYYY') ||
            formatDate(handleNullException(item, alternativeKey || ''), 'DD MMM YYYY')
          );
        case 'money':
          return handleMoney(item, key, 2);
        case 'status':
          return handleNullException(item, 'status.name');
        case 'count':
          return handleNullException(item, dataSource[0])?.length;
        case 'history':
          return `${handleNullException(item, dataSource[0])} @ ${formatDate(
            handleNullException(item, key),
            'DD MMM YYYY',
          )}`;
        default:
          return '';
      }
    });
  });

  return [selectedColumnNames, ...reportCells];
};
