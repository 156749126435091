/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useParams } from 'react-router-dom';
import { iRouteTypeId } from '../../../../shared/UITypes/types';
import Sidebar from '../../sidebar/Sidebar';
import BackendPage from '../../../../layout/BackendPage';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import BreadcrumbInHeader from '../../../../components/settings/BreadcrumbInHeader';
import { PageTitle } from '../../../../shared/styles/styles';
import { SETTINGS_DATA_IMPORT_URL } from '../../../../shared/UrlMap';
import { LIST_PAGE_NAME } from '../shared/DataImport.constants';
import { getPageBodyComponent } from '../shared/DataImport.util';

const DataImportDetail = () => {
  const { id: detailPageName } = useParams<iRouteTypeId>();

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader
        subName={detailPageName}
        extraLayer={
          <LinkBtnUrl url={SETTINGS_DATA_IMPORT_URL} btnName={LIST_PAGE_NAME} />
        }
      />
      <PageTitle>{detailPageName}</PageTitle>
    </>
  );

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      {getPageBodyComponent(detailPageName)}
    </BackendPage>
  );
};

export default DataImportDetail;
