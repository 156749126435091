import styled from 'styled-components';

export const ProductDetailWrapper = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  grid-gap: 10px;
  .left-panel {
    padding-right: 1.5rem;
  }
  .right-panel {
    padding-left: 1.5rem;
  }
`;

export const TabContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  padding-top: 0.5rem;
  min-height: 40vh;
`;
