import React, { useState } from 'react';
import LoadingButton from '@atlaskit/button/loading-button';
import ChannelScriptService from '../../../../services/integration/ChannelScriptService';
import { getCurrentBaseUrl } from '../../../../services/UtilsService';
import { SETTINGS_INTEGRATIONS_CALLBACK_URL } from '../../../UrlMap';
import { apiErrorToast } from '../../../toast/Toast';
import iChannel from '../../../../types/integration/iChannel';

type iWooCommerceReconnectBtn = {
  channel: iChannel;
};
const WooCommerceReconnectBtn = ({ channel }: iWooCommerceReconnectBtn) => {
  const [isLoading, setIsLoading] = useState(false);

  const reconnect = () => {
    setIsLoading(true);
    ChannelScriptService.getChannelScriptOauth2Url(channel.scriptId, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      storeUrl: channel.settings.storeUrl || '',
      redirectUrl: `${getCurrentBaseUrl()}${SETTINGS_INTEGRATIONS_CALLBACK_URL}?state=${channel.id}`,
      channelId: channel.id,
    })
      .then(resp => {
        const { url: redirectUrl } = resp;
        window.location.href = redirectUrl;
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LoadingButton appearance="primary" isLoading={isLoading} onClick={() => reconnect()} testId={'linkBtn'}>
      Reconnect
    </LoadingButton>
  );
};

export default WooCommerceReconnectBtn;
