import React from 'react';
import { handleNullException } from '../../../../../services/UtilsService';
import CCPServiceProvider from '../../../../../shared/CCPProductContactCompany/CCPServiceProvider/CCPServiceProvider';
import iProduct from '../../../../../types/product/iProduct';

const ProdThirdParty = ({ product, isDisabled }: { product?: iProduct; isDisabled?: boolean }) => {
  return (
    <CCPServiceProvider
      startFieldId={handleNullException(product, 'id')}
      fixedProduct={product}
      canCreate={!isDisabled}
      canUpdate={!isDisabled}
      canDelete={!isDisabled}
    />
  );
};

export default ProdThirdParty;
