import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

export const PopUpContentContainer = styled.div`
  align-items: center;
  max-height: 200px;
  overflow: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  vertical-align: center;
  box-sizing: border-box;
  .popup-item {
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    display: block;
    width: 100%;
  }
  .popup-item:hover {
    background-color: rgb(222, 235, 255);
  }
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ErrorMsg = styled.p`
  margin-top: 6px;
  color: #bf1650;
  &:before {
    content: '⚠ ';
  }
`;

export const Page = styled.div`
  over-flow: scroll;
`;
export const Header = styled.div`
  margin-top: 1rem;
  padding: 0 2.5rem;
`;

export const TableContainer = styled.div`
  padding: 0 1.25rem;
`;

export const PageTitle = styled.p`
  font-size: 1.75rem;
  font-weight: 500;
  color: #172b4d;

  &.small {
    font-size: 1.1rem;
  }
  &.medium {
    font-size: 1.4rem;
  }
  &.space-right {
    margin-right: 0.5rem;
  }
`;

export const FilterBtnWrapper = styled.div`
  color: #42526e;
  border-radius: 3px;
  min-width: 5rem;
  height: 2.28rem;
  margin-right: 0.7rem;
`;

export const AddOneBtnWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  color: #42526e;
  &.space-left {
    margin-left: 1rem;
    background-color: ${colors.backgroundActive};
  }
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  width: 22rem;
  float: right;
`;

export const AddressPlaceholderWrapper = styled.span`
  display: inline-block;
  font-weight: 400;
  padding-left: 0.2rem;
  color: #7a869a;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &.with-gap {
    gap: 1rem;
  }
  &.space-below {
    padding-bottom: 12px;
  }
  &.margin-bottom {
    margin-bottom: 1rem;
  }
  &.space-evenly {
    justify-content: space-evenly;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.space-above {
    padding-top: 1rem;
  }
  &.space-above-reverse {
    margin-top: -0.5rem;
  }

  &.small-font {
    font-size: 0.5rem;
  }
  &.flex-start {
    align-items: flex-start;
  }
  &.flex-end {
    justify-content: flex-end;
  }
  &.align-items-flex-start {
    align-items: flex-start;
  }
  &.align-items-flex-end {
    align-items: flex-end;
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-items-baseline {
    align-items: baseline;
  }
  &.align-items-stretch {
    align-items: stretch;
  }
  &.wrap-items {
    flex-wrap: wrap;
  }

  &.even-split {
    > * {
      width: 50%;
    }
  }

  .full-width {
    width: 100%;
  }
`;

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  &.space-evenly {
    justify-content: space-evenly;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.flex-start {
    justify-content: flex-start;
  }
  &.horizontal-center {
    align-items: center;
  }
  &.space-below {
    padding-bottom: 12px;
  }
`;

export const LabelValue = styled.span`
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3333333333333333;
  padding: 10px 0 5px;
  display: inline-block;
  min-height: 12px;
  width: 100%;
  overflow-wrap: break-word;
  &.medium-size {
    font-size: 12px;
  }
`;

export const ItalicizedFont = styled.span`
  font-style: italic;
  font-size: 0.9rem;
`;

export const BoldFont = styled.span`
  font-weight: bold;
  font-size: 150%;
`;

export const SpaceVerticalContainer = styled.div`
  padding: 1.5rem 0;
  &.quarter {
    padding: 0.25rem 0;
  }
  &.half {
    padding: 0.5rem 0;
  }
  &.one {
    padding: 1rem 0;
  }
  &.two {
    padding: 2rem 0;
  }
  &.three {
    padding: 3rem 0;
  }
  &.five {
    padding: 5rem 0;
  }
  &.seven {
    padding: 7rem 0;
  }
  &.eight {
    padding: 8rem 0;
  }
  &.nine {
    padding: 9rem 0;
  }
  &.ten {
    padding: 10rem 0;
  }
`;

export const SpaceHorizontalContainer = styled.div`
  padding: 0 1.5rem;
  &.quarter {
    padding: 0 0.25rem;
  }
  &.half {
    padding: 0 0.5rem;
  }
  &.one {
    padding: 0 1rem;
  }
  &.half {
    padding: 0 0.5rem;
  }
  &.two {
    padding: 0 2rem;
  }
  &.three {
    padding: 0 3rem;
  }
  &.five {
    padding: 0 5rem;
  }
  &.seven {
    padding: 0 7rem;
  }
  &.eight {
    padding: 0 8rem;
  }
  &.ten {
    padding: 0 10rem;
  }
`;

export const SpaceWrapper = styled.div`
  &.space-bottom {
    margin-bottom: 1rem;
  }
  &.space-bottom-sm {
    margin-bottom: 0.6rem;
  }
  &.space-bottom-5 {
    margin-bottom: 5rem;
  }
`;

export const SelectorWrapper = styled.div`
  z-index: 9;
`;

export const PrintBtnName = styled.span`
  color: rgb(66, 82, 110);
`;

export const SpaceBottomForSelect = styled.div`
  padding-bottom: 5rem;
`;

export const DividerWrapper = styled.div`
  border-top: 2px solid rgba(9, 30, 66, 0.08);
`;

export const InlineEditContainer = styled.div`
  max-width: 30rem;
  &:first-child,
  &:last-child {
    margin-top: 1rem;
  }
`;

export const statusSelectorStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  minWidth: '70px',
  height: '25px',
  fontSize: '12px',
};

export const ZIndexWrapper = styled.div`
  z-index: 9;
`;

export const AdvancedSearchSectionWrapper = styled.div`
  border: 1px solid rgb(204, 204, 204);
  margin-top: 20px;

  &.vertical-padding {
    padding: 1rem 0;
  }

  &.no-border {
    border: none;
  }
`;

export const StyledLink = styled.a`
  font-weight: 500;
  &:focus {
    outline: none;
  }
`;

export const MarginTopWrapper = styled.div`
  &.small {
    margin-top: 0.5rem;
  }
  &.medium {
    margin-top: 1rem;
  }
  &.large {
    margin-top: 2rem;
  }
`;

export const AttachmentsWrapper = styled.div`
  max-width: 100%;
  position: relative;
  z-index: 0;
  display: inline-block;
  margin-top: 1rem;
`;

export const AsyncSpaceWrapper = styled.div`
  padding-bottom: 15rem;
`;

export const ProgressBarContainer = styled.div`
  //boxsizng: border-box;
  //padding: 20px;
  //width: 100%;
  //border: none;
`;

export const AsyncSearchWrapper = styled.div`
  min-width: 23rem;
`;

export const WarningMsgWrapper = styled.div`
  margin: 10px 0;
`;

export const SeparatorWrapper = styled.div`
  margin: 4px 0px;
  border-bottom-width: 2px;
  &.mutual {
    border-bottom-color: ${colors.N40};
  }
  &.solid {
    border-bottom-style: solid;
  }
  &.groove {
    border-bottom-style: groove;
  }
  &.double {
    border-bottom-style: double;
    border-bottom-width: 5px;
  }
`;

export const BtnTransparentWrapper = styled.div`
  display: flex;
  .transparent-button {
    background-color: transparent;
  }
`;

export const PaginationWrapper = styled.div`
  &.pagination-wrapper {
    margin: 6px auto;
    width: fit-content;
  }
`;
export const EllipsisWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
`;

export const FullHeightWrapper = styled.div`
  height: 100%;
  .auth-div {
    height: 100%;
    .centered-div {
      width: calc(100% - 1rem);
      max-width: 400px;
    }
  }
`;

export const ThumbnailWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  // padding: 2px;
  margin: 2px;
  flex-shrink: 0;
  width: 180px;
  height: 170px;
  &.load-more {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .file-name {
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: RGB(23, 43, 77);
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .upload-time {
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: RGB(107, 119, 140);
    margin-left: 5px;
  }
  .image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    &:hover {
      .delete-button-admin {
        display: block;
      }
    }
    img {
      width: 100%;
      height: 120px;
    }
    .delete-button-admin {
      display: none;
    }
    .delete-button-tablet {
      display: block;
    }
  }
  &.small-size {
    width: 130px;
    height: 110px;
    .file-name {
      height: 14px;
      font-size: 10px;
    }
    .upload-time {
      height: 12px;
      font-size: 6px;
    }
    .image-wrapper {
      height: 70px;
    }
    img {
      height: 70px;
    }
  }
`;
export const CursorPointerWrapper = styled.div`
  cursor: pointer;
`;

export const statusCategoryAny = {
  color: '#ffffff',
  'border-color': '#42526e',
  'background-color': 'rgb(61, 75, 102)',
};

export const statusCategoryNew = {
  color: '#42526e',
  'border-color': '#42526e',
  'background-color': '#dfe1e6',
};

export const statusCategoryInProgress = {
  color: '#0747a6',
  'border-color': '#0747a6',
  'background-color': '#deebff',
};

export const statusCategoryFinished = {
  color: '#006644',
  'border-color': '#006644',
  'background-color': '#e3fcef',
};

export const textDanger = {
  color: 'red',
};
