import { ValidationRule } from 'react-hook-form';
import { emailPattern } from './RegexConstants';

export const HOOK_FORM_REQUIRED: string | ValidationRule<boolean> = {
  value: true,
  message: 'is Required',
};
export const HOOK_FORM_EMAIL = {
  value: emailPattern,
  message: 'invalid email address',
};

export const GIRDCOLUMN_MAX_SIZE = 12;
