import React, { useState } from 'react';
import Avatar from '@atlaskit/avatar';
import ImageCrop from '../../../shared/imageCrop/ImageCrop';

type iState = {
  isOpen: boolean;
};
const initialState: iState = {
  isOpen: false,
};
const EditableCompanyLogo = ({
  companyLogo,
  onConfirm,
}: {
  companyLogo?: string;
  onConfirm: (key: string, value: string) => Promise<void>;
}) => {
  const [state, setState] = useState(initialState);
  return (
    <>
      <Avatar
        size="xlarge"
        appearance="square"
        src={companyLogo}
        onClick={() => setState({ ...state, isOpen: true })}
        testId={'avatar'}
      />
      {state.isOpen && (
        <ImageCrop
          onCancel={() => setState({ ...state, isOpen: false })}
          onUploaded={async (url: string) => {
            await onConfirm('logoUrl', url);
            setState({ ...state, isOpen: false });
          }}
        />
      )}
    </>
  );
};

export default EditableCompanyLogo;
