import React, { useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AddIcon from '@atlaskit/icon/glyph/add';
import TransparentIconBtn from '../../buttons/TransparentIconBtn';
import CustomizeModal from '../../modal/CustomizeModal';
import iContactCompanyProduct from '../../../types/contactCompany/iContactCompanyProduct';
import CCPCreateOrderModalBody from './CCPCreateOrderModalBody';
import { createSalesOrder } from '../../../services/SalesOrderService';
import { createPurchaseOrder } from '../../../services/PurchaseService';
import { PURCHASES_URL, SALES_URL } from '../../UrlMap';
import { addToastForAPIResponse, apiErrorToast } from '../../toast/Toast';
import { createPOItemConfig } from '../../../services/PurchaseOrderItemService';
import { createSOItemConfig } from '../../../services/SalesOrderItemService';
import { NUMBER_ROUND_DECIMAL } from '../../constants/ActionConstants';

type iState = {
  isOpen: boolean;
  isConfirming: boolean;
};
const initialState: iState = {
  isOpen: false,
  isConfirming: false,
};
const CCPCreateOrderButton = ({
  ccp,
  isCustomer,
}: {
  ccp: iContactCompanyProduct;
  isCustomer: boolean;
}) => {
  const history = useHistory();
  const [state, setState] = useState(initialState);
  const { control, setValue, errors, handleSubmit } = useForm();
  const onOpen = () => setState({ ...state, isOpen: true });
  const onClose = () => setState({ ...state, isOpen: false });

  /**
   * 1. create salesOrder/purchaseOrder
   * 2. create salesOrderItem/purchaseOrderItem
   */
  //    eslint-disable-next-line
  const onSubmit = async (data: any) => {
    setState({ ...state, isConfirming: true });
    const createOrderFn = isCustomer ? createSalesOrder : createPurchaseOrder;
    const createOrderItemFn = isCustomer
      ? createSOItemConfig
      : createPOItemConfig;
    const urlPrefix = isCustomer ? SALES_URL : PURCHASES_URL;
    try {
      const { id } = await createOrderFn({ ...data });
      await createOrderItemFn({
        orderId: id,
        productId: ccp.productId,
        // double guarantee for rounding qty
        qty: _.round(data.qty, NUMBER_ROUND_DECIMAL),
      });
      addToastForAPIResponse('success');
      setState({ ...state, isConfirming: false, isOpen: false });
      history.push(`${urlPrefix}/${id}`);
    } catch (e) {
      apiErrorToast(e);
      setState({ ...state, isConfirming: false });
    }
  };
  return (
    <>
      <TransparentIconBtn
        icon={<AddIcon label="ccp-create-order" />}
        onClick={onOpen}
        testId={'ccp-create-order'}
      />
      {state.isOpen && (
        <CustomizeModal
          isOpen={state.isOpen}
          isConfirming={state.isConfirming}
          onCancel={onClose}
          onConfirm={handleSubmit(onSubmit)}
          confirmBtnName={'Create'}
          isDisabled={Object.keys(errors).length > 0}
          modalBody={
            <CCPCreateOrderModalBody
              control={control}
              errors={errors}
              onChange={setValue}
              ccp={ccp}
              isCustomer={isCustomer}
            />
          }
          modalHeading={
            isCustomer ? 'Create a SalesOrder' : 'Create a PurchaseOrder'
          }
        />
      )}
    </>
  );
};

export default CCPCreateOrderButton;
