import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { useSelector } from 'react-redux';
import iItemLocation from '../../../../types/warehouse/iItemLocation';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import { ItemsTitle, SupposedWrapper } from '../../shared/StockTake.style';
import { iConfigColumn } from '../../../../shared/UITypes/types';
import { getHeads, handleNullException } from '../../../../services/UtilsService';
import { SUPPOSED_ITEMS_TITLE, SUPPOSED_ITEM_TABLE_COLUMNS } from '../../shared/StockTake.constant';
import { RootState } from '../../../../redux/makeReduxStore';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCTS } from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

const getRows = (data: Array<iItemLocation>, columns: Array<iConfigColumn>) => {
  return data.map((item: iItemLocation) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'link-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                <LinkBtnUrl
                  btnName={handleNullException(item, key)}
                  url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
                />
              </ComposeSecondaryText>
            ),
          };
        case 'text-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                {handleNullException(item, key)}
              </ComposeSecondaryText>
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};

const SupposedItemsInBin = ({ items, locationCode }: { items: Array<iItemLocation>; locationCode: string }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadProduct = AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user);
  return (
    <SupposedWrapper>
      <ItemsTitle>{`${SUPPOSED_ITEMS_TITLE}${locationCode}`}</ItemsTitle>
      <DynamicTable
        head={getHeads(SUPPOSED_ITEM_TABLE_COLUMNS, SUPPOSED_ITEMS_TITLE)}
        rows={getRows(
          items,
          SUPPOSED_ITEM_TABLE_COLUMNS.map(col => {
            if (col.key === 'product.productCode' && !canReadProduct) {
              return {
                ...col,
                type: 'text-secondary',
              };
            }
            return col;
          }),
        )}
      />
    </SupposedWrapper>
  );
};

export default SupposedItemsInBin;
