/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import Select, { ValueType } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import iProductAttributeSet from '../../../../../types/product/iProductAttributeSet';
import iProductType from '../../../../../types/product/iProductType';
import { iLabelValuePair } from '../../../../../shared/UITypes/types';
import Collapse from '../../../../../shared/collapse/Collapse';
import CustomizedLabel from '../../../../../shared/form/hookForms/CustomizedLabel';
import { apiErrorToast } from '../../../../../shared/toast/Toast';
import { PRODUCT_PRE_TEXT } from '../../shared/DataImport.constants';
import { getSelectLabelValuePair } from '../../shared/DataImport.util';
import { getProdAttributeSets } from '../../../../../services/product/ProductAttributeSetService';
import { getProductTypes } from '../../../../../services/product/ProductTypeService';
import { SelectWidthWrapper } from '../../shared/DataImport.style';
import { FlexContainer, SpaceHorizontalContainer, SpaceVerticalContainer } from '../../../../../shared/styles/styles';
import { handleNullException } from '../../../../../services/UtilsService';
import { iPrefills } from '../../shared/DataImport.types';

type iState = {
  isLoading: boolean;
  productTypes: Array<iProductType>;
  productAttributeSets: Array<iProductAttributeSet>;
};
const initialState: iState = {
  isLoading: true,
  productTypes: [],
  productAttributeSets: [],
};
const PreCheckProductImport = ({
  onPreCheckResult,
  prefills,
}: {
  onPreCheckResult: (preCheckResult: iPrefills) => void;
  prefills: iPrefills;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      try {
        setState(prev => ({
          ...prev,
          isLoading: true,
        }));
        const productTypes: Array<iProductType> = await getProductTypes({
          include: '',
        });
        const productAttributeSets: Array<iProductAttributeSet> = await getProdAttributeSets({
          include: 'productAttributes',
        });
        if (isCancelled) return;
        setState(prev => ({
          ...prev,
          isLoading: false,
          productTypes,
          productAttributeSets,
        }));
      } catch (error) {
        apiErrorToast(error);
        setState(prev => ({
          ...prev,
          isLoading: false,
        }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);

  const onSelectProductType = (option: ValueType<iLabelValuePair>) => {
    if (!option) {
      onPreCheckResult({ productTypeId: undefined });
      return;
    }
    const productType = state.productTypes.find((item: iProductType) => item.id === (option as iLabelValuePair).value);
    if (!productType) return;
    const productTypeId = productType.id;
    const productAttributeSetId = productType.attributeSetId || handleNullException(prefills, 'productAttributeSetId');
    const productAttributes =
      state.productAttributeSets.find((item: iProductAttributeSet) => item.id === productAttributeSetId)
        ?.productAttributes || [];
    onPreCheckResult({
      productTypeId,
      productAttributeSetId,
      productAttributes,
    });
  };
  const onSelectProductAttibuteSet = (option: ValueType<iLabelValuePair>) => {
    const productAttributeSetId = option ? (option as iLabelValuePair).value : undefined;
    const productAttributes =
      state.productAttributeSets.find((item: iProductAttributeSet) => item.id === productAttributeSetId)
        ?.productAttributes || [];
    onPreCheckResult({
      productAttributeSetId,
      productAttributes,
    });
  };

  return (
    <Collapse text={PRODUCT_PRE_TEXT} defaultOpen>
      {state.isLoading ? (
        <Spinner />
      ) : (
        <>
          <SpaceVerticalContainer className={'quarter'} />
          <FlexContainer>
            <SelectWidthWrapper>
              <CustomizedLabel isRequired label={'Product type'} />
              <Select<iLabelValuePair>
                label={'Product type'}
                value={getSelectLabelValuePair(state.productTypes, handleNullException(prefills, 'productTypeId'))}
                options={state.productTypes.map((item: iProductType) => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={onSelectProductType}
              />
            </SelectWidthWrapper>
            <SpaceHorizontalContainer className={'half'} />
            <SelectWidthWrapper>
              <CustomizedLabel isRequired label={'Product Attribute Set'} />
              <Select<iLabelValuePair>
                label={'Product Attribute Set'}
                value={getSelectLabelValuePair(
                  state.productAttributeSets,
                  handleNullException(prefills, 'productAttributeSetId'),
                )}
                options={state.productAttributeSets.map((item: iProductAttributeSet) => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={onSelectProductAttibuteSet}
              />
            </SelectWidthWrapper>
          </FlexContainer>
        </>
      )}
    </Collapse>
  );
};

export default PreCheckProductImport;
