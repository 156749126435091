import React from 'react';
import styled from 'styled-components';

type WrapperProps = {
  isFixedShown: boolean;
  space: number;
};
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  left: ${(props: WrapperProps) =>
    props.isFixedShown
      ? `calc(100vw - ${props.space}px)`
      : 'calc(100vw - 260px)'};
`;
const MainWrapper = styled.div`
  margin-right: ${(props: WrapperProps) =>
    props.isFixedShown ? `${props.space}px` : '0px'};
`;
const RightFixedWrapper = ({
  children,
  fixed,
  isFixedShown = false,
  fixedWidthPx = 260,
  mainMarginRightPx = 200,
}: {
  children: React.ReactNode;
  fixed: React.ReactNode;
  isFixedShown?: boolean;
  fixedWidthPx?: number;
  mainMarginRightPx?: number;
}) => {
  return (
    <>
      <MainWrapper isFixedShown={isFixedShown} space={mainMarginRightPx}>
        {children}
      </MainWrapper>
      {isFixedShown && (
        <Wrapper isFixedShown space={fixedWidthPx}>
          {fixed}
        </Wrapper>
      )}
    </>
  );
};

export default RightFixedWrapper;
