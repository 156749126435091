import moment from 'moment';
import { getCustomerListAsyncSearch } from '../../services/ContactCompanyService';
import { getMachineListAsyncSearch } from '../../services/Settings/MachineService';
import { getUserListAsyncSearch } from '../../services/Settings/UserService';
import { formatDate } from '../../services/UtilsService';
import { iLabelValuePair } from '../UITypes/types';
import { PRODUCTS_URL } from '../UrlMap';

export const JOB_ALL = 'All';
export const JOB_NEW = 'NEW';
export const JOB_WIP = 'WIP';
export const JOB_FINISHED = 'Finished';
export const STATUS_CATEGORY_IN_PROGRESS = 'STATUS_CATEGORY_IN_PROGRESS';
export const STATUS_CATEGORY_FINISHED = 'STATUS_CATEGORY_FINISHED';
export const STATUS_CATEGORY_NEW = 'STATUS_CATEGORY_NEW';

export const PER_PAGE = 10;
export const DOWNLOAD_JOB_CARD = 'Download Job Card';
export const CLONE_JOB = 'Clone Job';
export const DELETE_JOB = 'Delete Job';

export const QTY_TO_PRODUCE = 'Qty To Produce';
export const MACHINE = 'Machine';
export const SCHEDULED_AT = 'Scheduled At';
export const PALLET_SYSTEM = 'Pallet System';

export const BOM_ATTRIBUTE_HOPPER = 'HOPPER';
export const BOM_ATTRIBUTE_EXTRUDER_PERCENTAGE = 'EXTRUDER %';

export const COLUMN_NAME_IN_STORAGE = 'admin-jobList-columns';

export const FIXED_COLUMNS = [
  'Status',
  'Order',
  'Customer',
  'Customer Ref',
  'Product Name',
  'Product Code',
  'Machine',
  'Operator',
  'Scheduled Date',
  'Order Date',
  'EDD',
  'Qty to Make',
  'Qty Total',
  'UOM',
  'Created',
  'Updated',
  'Dispatch Notes',
];
export const MUST_COLUMNS_BEFORE = ['Job'];
export const MUST_COLUMNS_AFTER = ['Operation'];
export const DEFAULT_COLUMNS = [
  ...MUST_COLUMNS_BEFORE,
  'Status',
  'Order',
  'Customer',
  'Customer Ref',
  'Product Name',
  'Product Code',
  'Machine',
  'Operator',
  'Scheduled Date',
  ...MUST_COLUMNS_AFTER,
];

export const INCLUDE_LOGO = 'Shamrock';
export const EXCLUDE_LOGO = 'Plain';
export const LOGO_OPTIONS: Array<iLabelValuePair> = [
  { label: INCLUDE_LOGO, value: 'true' },
  { label: EXCLUDE_LOGO, value: 'false' },
];

export const LENGTH_JOB_ATTRIBUTE = 'Meter(s) Per Roll';
export const NOMINAL_WEIGHT_JOB_ATTRIBUTE = 'Kg(s) Per Roll';
export const ROLL_JOB_ATTRIBUTE = 'Roll(s)';

export const PRINT_JOB_CARD = 'Print Job Card';
export const PRINT_ROLL_LABELS = 'Print Roll Label';
export const PRINT_PALLET_LABEL = 'Print Pallet Label';
export const PRINT_CORE_LABEL = 'Print Core Label';
export const PRINT_ENVELOP_LABEL = 'Print Envelop Label';
export const PRINT_CERTIFICATE_OF_CONFORMANCE = 'Print CoC';

export const JOB_SHIFTS_COLUMNS = ['Shift', 'Operator', 'Produced', 'Wastes', 'Operation'];

export const JOB_DISPATCH_NOTE_TABLE_NAME = 'JOB_DISPATCH_NOTE_TABLE';
export const JOB_DISPATCH_NOTE_LIST_COLUMNS = [
  {
    name: 'Disp. Note No.',
    key: 'dispatchNote.dispatchNoteNumber',
    dataSource: ['dispatchNote.id'],
    isCompulsory: true,
    isSortable: true,
    type: 'link',
    urlPrefix: '/b/dispatchNotes',
    isSelected: true,
    group: '',
  },
  {
    name: 'Product Code / SKU',
    key: 'product.productCode',
    dataSource: ['productId', 'product.name'],
    isCompulsory: true,
    isSortable: false,
    type: 'link-two-layer',
    urlPrefix: PRODUCTS_URL,
    isSelected: true,
    group: '',
  },
  {
    name: 'Sent Date',
    key: 'dispatchNote.dispatchDate',
    dataSource: ['dispatchNote.dispatchDate'],
    type: 'date',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Qty',
    key: 'quantity',
    dataSource: ['quantity', 'product.measurement.shortName'],
    type: 'qty',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];

export const JOB_REPORT_FILE_NAME = `jobs-report-${formatDate(moment().toString(), 'DD MMMM YYYY, hh:mm:ss')}.csv`;

export const JOB_ADVANCED_SEARCH_CONFIG = [
  {
    name: 'SalesOrder_salesOrderId.ContactCompany_customerId.id',
    label: 'Customers',
    type: 'multi',
    selected: [],
    placeholder: 'All customers...',
    promiseFn: getCustomerListAsyncSearch,
    optionLabel: 'name',
    sortBy: 'name',
    testId: 'job-advanced-search-customers',
    className: 'normal-width',
  },
  {
    name: 'machineId',
    label: 'Machines',
    type: 'multi',
    selected: [],
    placeholder: 'All machines...',
    promiseFn: getMachineListAsyncSearch,
    optionLabel: 'name',
    sortBy: 'name',
    testId: 'job-advanced-search-machines',
    className: 'normal-width',
  },
  {
    name: 'operatorId',
    label: 'Operators',
    type: 'multi',
    selected: [],
    placeholder: 'All operators...',
    promiseFn: getUserListAsyncSearch,
    optionLabel: 'firstName',
    sortBy: 'firstName',
    testId: 'job-advanced-search-operators',
    className: 'normal-width',
  },
  {
    name: 'scheduledAt>=',
    label: 'Scheduled Date',
    type: 'date',
    selected: '',
    placeholder: 'From ',
    testId: 'job-advanced-search-scheduled-from',
  },
  {
    name: 'scheduledAt<=',
    type: 'date',
    selected: '',
    placeholder: 'To',
    testId: 'job-advanced-search-scheduled-to',
    className: 'remove-1-gutter-before',
  },
  {
    name: 'SalesOrder_salesOrderId.orderDate>=',
    label: 'Order Date',
    type: 'date',
    selected: '',
    placeholder: 'From',
    testId: 'job-advanced-search-orderDate-from',
  },
  {
    name: 'SalesOrder_salesOrderId.orderDate<=',
    type: 'date',
    selected: '',
    placeholder: 'To',
    testId: 'job-advanced-search-orderDate-to',
    className: 'remove-1-gutter-before',
  },
  {
    name: 'edd>=',
    label: 'EDD',
    type: 'date',
    selected: '',
    placeholder: 'From',
    testId: 'job-advanced-search-edd-from',
    className: 'remove-1-gutter-before',
  },
  {
    name: 'edd<=',
    type: 'date',
    selected: '',
    placeholder: 'To',
    testId: 'job-advanced-search-edd-to',
    className: 'remove-2-gutter-before',
  },
];
