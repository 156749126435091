import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { getWarehouseListAsyncSearch } from '../../../../services/WarehouseService';
import AddressWithController from '../../../../shared/form/hookForms/AddressWithController/AddressWithController';
import AsyncSearchWithController from '../../../../shared/form/hookForms/AsyncSearchWithController';
import iFacility from '../../../../types/warehouse/iFacility';
import { FACILITY_ATTRIBUTES } from './constants';
import { iLabelValuePair } from '../../../../shared/UITypes/types';

const FacilityModalWrapper = styled.div`
  padding-bottom: 3rem;
  > div {
    margin-bottom: 0.5rem;
  }
`;
const FacilityModalBody = ({
  control,
  errors,
  onChange,
  facility,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  facility?: iFacility;
}) => {
  return (
    <FacilityModalWrapper>
      {FACILITY_ATTRIBUTES.map((attribute: iLabelValuePair) => (
        <AsyncSearchWithController
          key={attribute.label}
          name={attribute.value}
          label={attribute.label}
          defaultValue={_.get(facility, attribute.value)}
          control={control}
          onChange={onChange}
          errors={errors}
          isRequired
          testId={attribute.value}
          optionLabel={['name', 'locationCode']}
          promiseFn={(keyword: string) =>
            getWarehouseListAsyncSearch({
              like: `name:${keyword},locationCode:${keyword}`,
              filter: 'allowParts:true',
            })
          }
        />
      ))}
      <AddressWithController
        label={'Address'}
        name={'address'}
        control={control}
        onChange={onChange}
        errors={errors}
        defaultValue={_.get(facility, 'address')}
        isRequired
      />
    </FacilityModalWrapper>
  );
};

export default FacilityModalBody;
