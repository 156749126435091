// eslint-disable-next-line import/no-cycle
import ProdAttributes from './leftPanel/tabContents/prodAttributes/ProdAttributes';
import ProdBOM from './leftPanel/tabContents/ProdBOM/ProdBOM';
import ProdGeneralInfo from './leftPanel/tabContents/ProdGeneralInfo';
import ProdOperations from './leftPanel/tabContents/ProdOperations';
import ProdPurchase from './leftPanel/tabContents/prodPurchase/ProdPurchase';
import ProdSell from './leftPanel/tabContents/prodSell/ProdSell';
import ProdThirdParty from './leftPanel/tabContents/ProdThirdParty';

export const FAKE_RIGHT_TOP_ATTRIBUTES = [
  {
    label: 'Unit Price (Ex.)',
    value: '123',
    prefix: '$',
    postfix: 'kg',
    fieldName: 'unitPrice',
  },
  { label: 'Stock on Hand', value: '102,230 kg', fieldName: 'stockOnHand' },
  {
    label: 'Average Cost (Ex.)',
    value: '99.93',
    prefix: '$',
    postfix: 'kg',
    fieldName: 'averageCost',
  },
];
// value must be accordance with component name in component_map
export const TAB_ITEMS = [
  { label: 'General', value: 'generalInfo' },
  { label: 'Attributes', value: 'productAttributes' },
  { label: 'BOM', value: 'bom' },
  { label: 'Production Operations', value: 'productionOperations' },
  {
    label: 'Purchase',
    value: 'purchase',
  },
  {
    label: 'Sell',
    value: 'sell',
  },
  {
    label: 'Third Party',
    value: 'thirdParty',
  },
];

export const COMPONENT_MAP = {
  generalInfo: ProdGeneralInfo,
  productAttributes: ProdAttributes,
  bom: ProdBOM,
  productionOperations: ProdOperations,
  purchase: ProdPurchase,
  sell: ProdSell,
  thirdParty: ProdThirdParty,
};

// label is conditional field, value is the corresponding component(component name in component_map)
export const TAB_ITEMS_CONDITIONS = [
  { label: 'isForMake', value: 'productionOperations' },
  { label: 'isForSell', value: 'sell' },
  { label: 'isForPurchase', value: 'purchase' },
  { label: 'requireThirdPartyService', value: 'thirdParty' },
];

export const CHANGE_PRODUCT_ATTRIBUTE_SET_WARNING =
  'By comfirming this action, your current Product Attributes and corresponding values will be replaced by those from the selected set';
