import moment from 'moment';
import { numberRound } from '../../../services/UtilsService';
import { iSum } from './Dashboard.types';

const defaultFormat = 'YYYY-MM-DD';

export const getStartDayOfMonth = (date: string, formatDate?: string) => {
  return moment(date)
    .startOf('month')
    .format(formatDate || defaultFormat);
};
export const getEndDayOfMonth = (date: string, formatDate?: string) => {
  return moment(date)
    .endOf('month')
    .format(formatDate || defaultFormat);
};
export const sortBySum = (sums: Array<iSum>) => {
  return sums.sort((a: iSum, b: iSum) => b.sum - a.sum);
};
export const getSummary = (sums: Array<iSum>) => {
  const sorted = sortBySum(sums);
  return sorted
    .filter((item: iSum) => item.sum)
    .map((item: iSum) => `${numberRound(item.sum, 3)} ${item.unitName}`)
    .join(', ');
};
