import styled from 'styled-components';
import { DASHBOARD_INSSTOCKS } from './Dashboard.constants';

export const PartitionTitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #172b4d;
  }
  .date-range {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #172b4d;
  }
`;
export const PartitionBodyWrapper = styled.div`
  padding: 1rem 0 1rem 0.5rem;
`;
export const ProductionMachineWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  .machine-jobs {
    min-width: 310px;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  .machine-jobs:nth-last-child(1) {
    padding-right: 0rem;
  }
  .machine-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #172b4d;
  }
`;
export const SalesWrapper = styled.div`
  padding-right: 1.5rem;
  .table-wrapper {
    display: block;
    overflow-x: auto;
  }
`;
export const PurchaseWrapper = styled.div`
  padding-left: 1.5rem;
  .table-wrapper {
    display: block;
    overflow-x: auto;
  }
`;

export const StocksTableWrapper = styled.div`
  th[data-testid='${DASHBOARD_INSSTOCKS}-column-requiredByJobs--head--cell'] {
    width: 25rem;
    min-width: 20rem;
  }
  label[data-testid='align-top--checkbox-label'] {
    margin-top: -3px;
  }
`;
