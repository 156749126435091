import React from 'react';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Button from '@atlaskit/button';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import { RootState } from '../../../redux/makeReduxStore';
import { formatDate } from '../../../services/UtilsService';

type iLabelKeyPair = {
  label: string;
  key: string;
};
const headers: Array<iLabelKeyPair> = [
  { label: 'Product Code/SKU', key: 'product.productCode' },
  { label: 'Product Name', key: 'product.name' },
  { label: 'Moving Qty', key: 'movingQty' },
  { label: 'Unit', key: 'product.measurement.shortName' },
  {
    label: 'Batch/Lot No.',
    key: 'reference',
  },
  { label: 'From Location', key: 'locationName' },
  { label: 'Location Code', key: 'locationCode' },
];

const ExportCsv = ({ data }: { data: Array<iItemLocationTransfer> }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const addExtraInfo = () => {
    const operatorLabel = ['Operator', 'Operated at'];
    const operatorInfo = [
      `${_.get(user, 'firstName')} ${_.get(user, 'lastName')}`,
      formatDate(moment().toString(), 'DD MMMM YYYY, HH:mm:ss'),
    ];

    const transferLabel = headers.map((h: iLabelKeyPair) => h.label);
    const transferInfo = data.map((item: iItemLocationTransfer) =>
      headers.map((column: iLabelKeyPair) => _.get(item, column.key)),
    );
    return [operatorLabel, operatorInfo, transferLabel, ...transferInfo];
  };

  return (
    <CSVLink
      data={addExtraInfo()}
      filename={`stockTransfer-${formatDate(
        moment().toString(),
        'DD MMMM YYYY, hh:mm:ss',
      )}.csv`}
    >
      <Button appearance={'primary'}>Download transfer file</Button>
    </CSVLink>
  );
};

export default ExportCsv;
