import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/makeReduxStore';
import CustomizeTab from '../../../../shared/tab/CustomizeTab';
import PDFSOSettings from './PDFSOSettings';
import PDFPrintSettings from './PDFPrintSettings';
import PDFManifestSettings from './PDFManifestSettings';
import ModulingService from '../../../../services/ModulingService';
import { ModuleCode } from '../../../../types/iModuleCode';
import {
  COMPANY_PREFERENCE_CODE_AO_SETTINGS,
  COMPANY_PREFERENCE_CODE_DN_SETTINGS,
  COMPANY_PREFERENCE_CODE_PO_SETTINGS,
} from '../../../../types/company/iProfileCompanyPreference';
import PDFCertificateOfConformanceSettings from './PDFCertificateOfConformanceSettings';

const Wrapper = styled.div``;

const CompanyPrintingPreferenceTabPanel = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const company = user?.company;

  const tabs = [
    {
      label: 'Sales',
      content: company && <PDFSOSettings company={company} />,
    },
    {
      label: 'Purchase Orders',
      content: company && (
        <PDFPrintSettings
          company={company}
          entityName={'PurchaseOrder'}
          settingsCode={COMPANY_PREFERENCE_CODE_PO_SETTINGS}
        />
      ),
    },
    {
      label: 'Dispatch Notes',
      content: company && (
        <PDFPrintSettings
          company={company}
          entityName={'DispatchNote'}
          settingsCode={COMPANY_PREFERENCE_CODE_DN_SETTINGS}
        />
      ),
    },
    {
      label: 'Dispatch Manifest',
      content: company && <PDFManifestSettings company={company} />,
    },
    {
      label: 'Certificate of Conformance',
      content: company && <PDFCertificateOfConformanceSettings company={company} />,
    },
  ];
  if (
    ModulingService.checkIsModuleEnabled({
      checkedModule: ModuleCode.PURCHASE_AO,
      companyModules: user?.companyModules || [],
    })
  ) {
    tabs.push({
      label: 'Acquisition Orders',
      content: company && (
        <PDFPrintSettings
          company={company}
          entityName={'AcquisitionOrder'}
          settingsCode={COMPANY_PREFERENCE_CODE_AO_SETTINGS}
        />
      ),
    });
  }

  return (
    <Wrapper>
      <CustomizeTab components={tabs} />
    </Wrapper>
  );
};

export default CompanyPrintingPreferenceTabPanel;
