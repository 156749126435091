/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import SectionMessage from '@atlaskit/section-message';
import { iTemplateColumn } from '../../shared/DataImport.types';
import Collapse from '../../../../../shared/collapse/Collapse';
import ReadImport from './ReadImport';
import NoFooterModal from '../../../../../shared/modal/NoFooterModal';
import { MAX_ROWS, STEP2_TEXT } from '../../shared/DataImport.constants';
import { checkHeaders } from '../../shared/DataImport.util';
import { SpaceVerticalContainer } from '../../../../../shared/styles/styles';

const StepDownload = ({
  handleDataRead,
  columns,
  isReset,
}: {
  handleDataRead: (result: any[][], reset?: boolean | undefined) => void;
  columns: Array<iTemplateColumn>;
  isReset: boolean;
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();

  const onDataReadWithCheckHeaders = (result: any[][]) => {
    // empty file
    if (result.length === 0) {
      handleDataRead([]);
      return;
    }
    // check columns
    const error = checkHeaders({ read: result[0], columns });
    // check passed
    if (!error) {
      handleDataRead(result.splice(0, MAX_ROWS));
    }
    setErrorMessage(error);
  };
  return (
    <>
      <Collapse text={STEP2_TEXT} defaultOpen>
        <ReadImport
          handleDataRead={onDataReadWithCheckHeaders}
          isReset={isReset}
        />
      </Collapse>
      <NoFooterModal
        isOpen={!!errorMessage}
        modalHeading={'Wrong template detected'}
        modalBody={
          <>
            <SectionMessage appearance={'warning'} title={errorMessage}>
              <div />
            </SectionMessage>
            <SpaceVerticalContainer className={'one'} />
          </>
        }
        onCancel={
          // clear check results
          () => setErrorMessage(undefined)
          // handleDataRead([], true);
        }
      />
    </>
  );
};

export default StepDownload;
