import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import moment from 'moment';
import iUser from '../../../types/user/iUser';
import { getDeleteBtn, getHeads } from '../../../components/settings/utils';
import { handleNullException } from '../../../services/UtilsService';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';

const columns = ['Names', 'Role', 'Email', 'Created', 'Updated', 'Operation'];
const UserList = ({
  users,
  onDelete,
  onEdit,
  isLoading,
}: {
  users: Array<iUser>;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  isLoading: boolean;
}) => {
  const getNameContent = (user: iUser) => (
    <AvatarItem
      testId={`user-list-avatar-${user.id}`}
      avatar={<Avatar src={user.userAvatarUrl} />}
      primaryText={`${user.firstName} ${user.lastName}`}
      secondaryText={user.username}
      onClick={() => onEdit(user.id)}
    />
  );

  const getRows = () => {
    return users
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iUser) => ({
        cells: [
          { key: item.username, content: getNameContent(item) },
          { content: handleNullException(item, 'role.name') },
          { content: item.email },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'createdAt')}
                operator={handleNullException(item, 'createdBy.firstName')}
              />
            ),
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'updatedAt')}
                operator={handleNullException(item, 'updatedBy.firstName')}
              />
            ),
          },
          {
            content: getDeleteBtn({
              id: item.id,
              answer: item.username,
              onClick: onDelete,
            }),
          },
        ],
      }));
  };

  return (
    <DynamicTable
      head={getHeads(columns, 'settings-user')}
      rows={getRows()}
      testId={'settings-user-list-table'}
      isLoading={isLoading}
    />
  );
};

export default UserList;
