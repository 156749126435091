/* eslint-disable camelcase */
import Spinner from '@atlaskit/spinner';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import SectionMessage from '@atlaskit/section-message';
import BackendPage from '../../../layout/BackendPage';
import ChannelService from '../../../services/integration/ChannelService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import Sidebar from '../sidebar/Sidebar';
import ChannelScriptHelper from '../../../shared/integrations/ChannelScriptHelper';
import iChannel from '../../../types/integration/iChannel';

const IntegrationCallback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [channel, setChannel] = useState<iChannel | undefined>(undefined);

  const urlSearchParams = queryString.parse(window.location.search);

  useEffect(() => {
    const channelIdStr = `${urlSearchParams.state || ''}`.trim();
    if (channelIdStr === '') {
      return;
    }
    let isCanceled = false;
    setIsLoading(true);
    ChannelService.getChannel(channelIdStr, {
      include: 'script',
    })
      .then(resp => {
        if (isCanceled) return;
        setChannel(resp);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    // eslint-disable-next-line consistent-return
    return () => {
      isCanceled = true;
    };
  }, [urlSearchParams.state]);

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (!channel || !channel.script) {
      return (
        <SectionMessage appearance={'warning'} title={'Invalid Channel'}>
          Can NOT find Channel Script Info. for the provided Channel
        </SectionMessage>
      );
    }
    return ChannelScriptHelper.getChannelSetupCallbackHandler({
      channel,
      channelScript: channel.script,
    });
  };

  return (
    <BackendPage pageHeader={<></>} leftMenu={<Sidebar />}>
      {getContent()}
    </BackendPage>
  );
};

export default IntegrationCallback;
