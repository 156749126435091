import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import imageCompression from 'browser-image-compression';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Button, { LoadingButton, ButtonGroup } from '@atlaskit/button';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Range from '@atlaskit/range';
import { uploadImage } from '../../services/AssetService';
import { getCroppedImg } from './ImageCrop.util';
import { apiErrorToast } from '../toast/Toast';
import { CropWrapper } from './ImageCrop.style';
import ImagePicker from './ImagePicker';

const options = {
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const ImageCrop = ({
  onUploaded,
  onCancel,
}: {
  onUploaded: (url: string) => void;
  onCancel: () => void;
}) => {
  const [acceptedImage, setAcceptedImage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, pixels) => {
    setCroppedAreaPixels(pixels);
  }, []);

  const onPickImage = (url: string) => setAcceptedImage(url);

  const uploadCroppedImage = async () => {
    setIsLoading(true);
    if (!acceptedImage) return;
    try {
      const croppedImage = await getCroppedImg({
        imageSrc: acceptedImage,
        pixelCrop: croppedAreaPixels,
      });

      const compressedFile = await imageCompression(
        croppedImage as File,
        options,
      );

      const formData = new FormData();
      formData.append('image', compressedFile);
      const data = await uploadImage(formData);
      setIsLoading(false);
      onUploaded(data.asset.url);
    } catch (e) {
      apiErrorToast(e);
      setIsLoading(false);
    }
  };

  return (
    <ModalTransition>
      <Modal testId={'modal'}>
        <ModalHeader>
          <ModalTitle>Upload Company Logo</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {!acceptedImage ? (
            <ImagePicker onPickImage={onPickImage} />
          ) : (
            <>
              <CropWrapper>
                <Button
                  iconAfter={<EditorCloseIcon label={'close-crop'} />}
                  onClick={() => setAcceptedImage(undefined)}
                  className={'close-crop'}
                  spacing={'none'}
                  testId={'close-crop'}
                />
                <Cropper
                  image={acceptedImage}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </CropWrapper>
              <Range
                step={0.1}
                min={1}
                max={3}
                value={zoom}
                onChange={(value: number) => setZoom(value)}
                testId={'crop-range'}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <div />
          <ButtonGroup>
            <Button
              appearance="subtle"
              isDisabled={isLoading}
              onClick={onCancel}
              testId={'cancel'}
            >
              Cancel
            </Button>
            <LoadingButton
              appearance="primary"
              onClick={uploadCroppedImage}
              isLoading={isLoading}
              testId={'upload'}
              isDisabled={!acceptedImage}
            >
              Upload
            </LoadingButton>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
};

export default ImageCrop;
