import { iKeyValuePairs } from '../../shared/UITypes/types';
import AppService from '../AppService';
import { iChannelJobEntity } from '../../types/integration/iChannelJob';

const endPoint = '/channelJob';

const getChannelJobs = async (config?: iKeyValuePairs) => {
  return AppService.get(endPoint, {
    include: 'channel',
    ...config,
  }).then(({ data }) => data);
};

const getOpenChannelJobs = async (config = {}) => {
  return AppService.get(endPoint, config).then(({ data }) => data);
};

const getChannelJobById = async (channelJobId: string, params = {}): Promise<iChannelJobEntity> => {
  return AppService.get(`${endPoint}/${channelJobId}`, params).then(({ data }) => data);
};

export default {
  getChannelJobs,
  getOpenChannelJobs,
  getChannelJobById,
};
