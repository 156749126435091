import React from 'react';
import _ from 'lodash';
import Checkbox from '@atlaskit/checkbox';
import iProduct from '../../../types/product/iProduct';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import { formatDate, handleNullException } from '../../../services/UtilsService';
import { iKeyValuePairs, iRowContent } from '../../../shared/UITypes/types';
import { getDeleteBtn } from '../../settings/utils';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import { PRODUCTS_URL } from '../../../shared/UrlMap';
import iProductAttribute from '../../../types/product/iProductAttribute';
import iProductAttributeValue from '../../../types/product/iProductAttributeValue';

export const getHeads = (columns: Array<string>, tableName: string) => {
  const cells = columns.map((column: string) => {
    switch (column) {
      case 'Operation':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'SKU':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
          content: 'ProductCode / SKU',
          isSortable: true,
        };
      default:
        return {
          key: column,
          content: column,
          testId: `${tableName}-table-column-${column}`,
        };
    }
  });
  return { cells };
};

export const getRows = (
  data: Array<iProduct>,
  columns: Array<string>,
  onEdit: (name: string, value: boolean, id: string) => void,
  onDelete: (id: string) => void,
  prodAttrsCluster: Array<iProductAttribute>,
) => {
  const getCheckbox = (name: string, defaultValue: boolean, id: string) => (
    <Checkbox
      testId={`product-list-${name}`}
      isChecked={defaultValue}
      name={name}
      onChange={() => onEdit(name, defaultValue, id)}
    />
  );
  const mapList = () =>
    data.map((prod: iProduct) => {
      const prodAttrs = handleNullException(prod, 'productAttributeValues') || [];
      const prodAttrNameValuePair = prodAttrs.reduce((acc: iKeyValuePairs, cur: iProductAttributeValue) => {
        const pAttrName = prodAttrsCluster
          .filter((attr: iProductAttribute) => attr.name !== 'Type')
          .find((p: iProductAttribute) => p.id === cur.attributeId)?.name;

        return pAttrName ? { ...acc, [pAttrName]: cur.value || '' } : acc;
      }, {});

      const row = {
        id: prod.id,
        SKU: (
          <ComposeSecondaryText secondaryText={prod.name}>
            <LinkBtnUrl btnName={prod.productCode} url={`${PRODUCTS_URL}/${prod.id}`} />
          </ComposeSecondaryText>
        ),
        Type: handleNullException(prod, 'productType.name'),
        'UnitPrice(Inc.)': prod?.isForSell ? handleNullException(prod, 'unitPrice') : null,
        StockOnHand: handleNullException(prod, 'stockOnHand'),
        'TotalValue(ex.)': handleNullException(prod, 'totalValue'),
        Unit: handleNullException(prod, 'measurement.shortName'),
        isForTemplate: getCheckbox('isTemplate', prod.isTemplate, prod.id),
        isForSell: getCheckbox('isForSell', prod.isForSell, prod.id),
        isForPurchase: getCheckbox('isForPurchase', prod.isForPurchase, prod.id),
        isForMake: getCheckbox('isForMake', prod.isForMake, prod.id),
        isForWaste: getCheckbox('isForWaste', prod.isForWaste, prod.id),
        Created: (
          <CreatedOrUpdated
            operatedAt={handleNullException(prod, 'createdAt')}
            operator={handleNullException(prod, 'createdBy.firstName')}
          />
        ),
        Updated: (
          <CreatedOrUpdated
            operatedAt={handleNullException(prod, 'updatedAt')}
            operator={handleNullException(prod, 'updatedBy.firstName')}
          />
        ),
        ...prodAttrNameValuePair,
        Operation: getDeleteBtn({
          id: prod.id,
          answer: prod.productCode || 'sku n/a',
          onClick: onDelete,
        }),
      };
      return row;
    });

  return mapList().map((row: iRowContent) => {
    const cells = columns.map((column: string, index: number) => ({
      key: `column - ${index}`,
      content: _.get(row, column) ? row[column] : '',
    }));
    return { cells, testId: 'product-row' };
  });
};

export const getProductDict = (prod: iProduct): iKeyValuePairs => {
  return {
    id: prod.id,
    SKU: handleNullException(prod, 'productCode'),
    Type: handleNullException(prod, 'productType.name'),
    'UnitPrice(Inc.)': prod?.isForSell ? handleNullException(prod, 'unitPrice') : null,
    StockOnHand: handleNullException(prod, 'stockOnHand'),
    'TotalValue(ex.)': handleNullException(prod, 'totalValue'),
    Unit: handleNullException(prod, 'measurement.name'),
    isForTemplate: handleNullException(prod, 'isForTemplate', false),
    isForSell: handleNullException(prod, 'isForSell', false),
    isForPurchase: handleNullException(prod, 'isForPurchase', false),
    isForMake: handleNullException(prod, 'isForMake', false),
    isForWaste: handleNullException(prod, 'isForWaste', false),
    Created: `${handleNullException(prod, 'createdBy.firstName')}@${formatDate(
      handleNullException(prod, 'createdAt'),
    )}`,
    Updated: `${handleNullException(prod, 'updatedBy.firstName')}@${formatDate(
      handleNullException(prod, 'updatedAt'),
    )}`,
  };
};

const getValueFromKnownMap = (columnName: string, product: iProduct) => {
  const dictionary = getProductDict(product);
  return dictionary[columnName];
};

export const getProdAttrNameValuePair = (product: iProduct, prodAttrsCluster: Array<iProductAttribute>) => {
  const prodAttrs = handleNullException(product, 'productAttributeValues') || [];
  const prodAttrNameValuePair = prodAttrs.reduce((acc: iKeyValuePairs, cur: iProductAttributeValue) => {
    const pAttrName = prodAttrsCluster
      .filter((attr: iProductAttribute) => attr.name !== 'Type')
      .find((p: iProductAttribute) => p.id === cur.attributeId)?.name;

    return pAttrName ? { ...acc, [pAttrName]: cur.value || '' } : acc;
  }, {});
  return prodAttrNameValuePair;
};

const getValueFromProductAttr = (columnName: string, product: iProduct, prodAttrsCluster: Array<iProductAttribute>) => {
  const prodAttrDict = getProdAttrNameValuePair(product, prodAttrsCluster);
  return prodAttrDict[columnName];
};

export const extractValueFromColumnName = (
  columnName: string,
  product: iProduct,
  prodAttrsCluster: Array<iProductAttribute>,
) => {
  const value =
    getValueFromKnownMap(columnName, product) || getValueFromProductAttr(columnName, product, prodAttrsCluster) || '';
  return value;
};

export const getReportArrSegments = (
  data: iProduct[],
  selectedColumnNames: string[],
  prodAttrsCluster: Array<iProductAttribute>,
) => {
  const reportCells = data.map((item: iProduct) =>
    selectedColumnNames.map(columnName => extractValueFromColumnName(columnName, item, prodAttrsCluster)),
  );
  return [selectedColumnNames, ...reportCells];
};
