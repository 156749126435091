import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from '@atlaskit/spinner';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import { getEntityCategoryList } from '../../services/CategoryService';
import {
  createStockTake,
  deactivateStockTake,
  getStockTakeList,
  updateStockTake,
} from '../../services/StockTakeService';
import PageTitleWithAddOne from '../../shared/heading/PageTitleWithAddOne';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../../shared/hooks/useShowHideColumnHook/useColumnHook';
import useStatusSelector from '../../shared/hooks/useStatusSelector';
import SearchBar from '../../shared/search/SearchBar';
import { FlexContainer } from '../../shared/styles/styles';
import { ALL } from '../sales/constants';
import { LIST_CREATE_HINT, LIST_PAGE_NAME, STOCK_TAKE_COLUMNS } from './shared/StockTake.constant';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import CustomizePagination from '../../shared/pagination/CustomizePagination';
import DeleteByRetype from '../../shared/deleteWithReType/DeleteByReType';
import StockTakeTable from './list/StockTakeTable';
import iStockTake from '../../types/stockTake/iStockTake';
import CreateModalBody from './list/CreateModalBody';
import { handleNullException } from '../../services/UtilsService';
import { STOCK_TAKE_URL } from '../../shared/UrlMap';
import PaginationDetail from '../../shared/pagination/paginationDetail/PaginationDetail';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_STOCK_TAKES, ACCESS_CODE_WAREHOUSES } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE, ACCESS_CAN_READ } from '../../types/settings/UserAccess/iRoleAccess';

const StockTakeList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const history = useHistory();
  const {
    state,
    edit,
    onOpenAddModal,
    onSearch,
    onFilter,
    onSetSort,
    onSetCurrentPage,
    onOpenDeleteModal,
    onCloseModal,
    onSubmit,
    onDelete,
    onRefreshList,
  } = useListCrudHook<iStockTake>({
    getFn: getStockTakeList,
    updateFn: updateStockTake,
    createFn: createStockTake,
    deleteFn: deactivateStockTake,
    keywordColumns: 'stocktakeNum',
    sort: 'updatedAt:DESC',
    notRenderWithoutFilter: true,
    paginationApplied: true,
    createCallBack: (id: string) => history.push(`${STOCK_TAKE_URL}/${id}`),
  });
  const { selected, getTypeSelectorComponent, categories, isStatusLoading } = useStatusSelector({
    type: 'StockTake',
    isMulti: true,
    getFn: getEntityCategoryList,
  });
  const { columns, getShowHideColumns } = useColumnHooksUpgrade(LIST_PAGE_NAME, STOCK_TAKE_COLUMNS);
  const { control, setValue, errors, handleSubmit } = useForm({});

  const canCreate = AccessService.hasAccess(ACCESS_CODE_STOCK_TAKES, ACCESS_CAN_CREATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_STOCK_TAKES, ACCESS_CAN_DELETE, user);

  const getPageHeader = () => (
    <>
      <PageTitleWithAddOne
        title={LIST_PAGE_NAME}
        onClickAddBtn={onOpenAddModal}
        className={'space-below'}
        isDisabled={!canCreate}
      />
      <FlexContainer className={'space-between'}>
        {getTypeSelectorComponent()}
        <div>
          <SearchBar keyword={edit.keyword} onSearch={onSearch} placeholder={'search via No....'} />
        </div>
      </FlexContainer>
    </>
  );

  useEffect(
    () => {
      if (!isStatusLoading) {
        const filter = selected.includes(ALL) || selected.length === 0 ? '' : `statusId:${selected.join('|')}`;
        onFilter(filter);
      }
    },
    //  eslint-disable-next-line
    [JSON.stringify(selected)],
  );

  const getContent = () => {
    if (state.isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <FlexContainer className={'space-between space-below'}>
          {!!state.total && !!state.from && !!state.to && (
            <PaginationDetail from={state.from} to={state.to} total={state.total} onRefreshResults={onRefreshList} />
          )}
          {getShowHideColumns()}
        </FlexContainer>
        <StockTakeTable
          data={state.data}
          onSetSort={onSetSort}
          onOpenDeleteModal={onOpenDeleteModal}
          isLoading={state.isLoading}
          columns={columns
            .filter(col => {
              return !(col.key === 'operation' && !canDelete);
            })
            .map(col => {
              if (
                col.key === 'warehouseLocation.locationCode' &&
                !AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_READ, user)
              ) {
                return {
                  ...col,
                  type: 'text',
                };
              }
              return col;
            })}
          categories={categories}
        />
        <CustomizeModal
          isOpen={edit.isModalOpen && !edit.delTargetId}
          onConfirm={handleSubmit(onSubmit)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
          modalBody={<CreateModalBody control={control} onChange={setValue} errors={errors} />}
          modalHeading={'Creating a stock take'}
          confirmBtnName={'Create'}
          bottom={<small>{LIST_CREATE_HINT}</small>}
        />
        {!!edit.delTargetId && (
          <DeleteByRetype
            isOpen={edit.isModalOpen}
            onClose={onCloseModal}
            onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
            answer={handleNullException(edit.target, 'stocktakeNum')}
            isConfirming={state.isConfirming}
          />
        )}
        {!!state.total && !!state.perPage && !!state.currentPage && (
          <CustomizePagination
            range={Math.ceil(state.total / state.perPage)}
            currentPage={state.currentPage}
            onChange={onSetCurrentPage}
          />
        )}
      </>
    );
  };

  return <BackendPage pageHeader={getPageHeader()}>{getContent()}</BackendPage>;
};

export default StockTakeList;
