import React from 'react';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import { Container, Format } from '@atlaskit/badge';
import { useSelector } from 'react-redux';
import iJob from '../../../../types/job/iJob';
import iMachine from '../../../../types/machine/iMachine';
import { JobCardWrapper } from './JobCard.style';
import { FlexContainer } from '../../../../shared/styles/styles';
import { useJobSchContext } from '../../JobScheduleContext';
import { JOB_DETAILS_URL, PRODUCT_DETAIL_URL } from '../../../../shared/UrlMap';
import { iLabelValuePair } from '../../../../shared/UITypes/types';
import {
  formatDate,
  getJobAttrsValues,
  getProdAttrs,
  handleNullException,
  hasKey,
  numberFormat,
} from '../../../../services/UtilsService';
import HoverDotDropdown from '../hoverDots/HoverDots';
import LockJob from '../hoverDots/LockJob';
import { RootState } from '../../../../redux/makeReduxStore';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_JOBS, ACCESS_CODE_PRODUCTS } from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

const JobCard = ({
  job,
  date,
  shift,
  isDisabled,
}: {
  job: iJob;
  date: string;
  shift: iLabelValuePair;
  isDisabled?: boolean;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadJobs = AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_READ, user);
  const canReadProducts = AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user);
  const { columns, prodAttributes: prodAttrsCluster, machines } = useJobSchContext();
  const flexibleContents = () => {
    const jobAttrs = getJobAttrsValues(job.jobAttributes);
    const prodAttrs = getProdAttrs(job.product.productAttributeValues, prodAttrsCluster);
    return {
      ...jobAttrs,
      ...prodAttrs,
      status: job.status.name,
      'product name': job.product.name,
      order: handleNullException(job, 'orderNumber') || handleNullException(job, 'salesOrder.orderNumber'),
      customer: handleNullException(job, 'customerName') || handleNullException(job, 'salesOrder.customer.name'),
      EDD: formatDate(handleNullException(job, 'edd') || handleNullException(job, 'salesOrder.edd'), 'DD MMM YYYY'),
      // 'scheduled at': formatDate(job.scheduledAt),
      'qty remaining': job.qtyToMake,
    };
  };
  return (
    <JobCardWrapper key={job.id}>
      {isDisabled ? null : (
        <HoverDotDropdown>
          <LockJob date={date} shift={shift} jobId={job.id} moveDisabled={!!job.moveDisabled} />
        </HoverDotDropdown>
      )}

      <FlexContainer className={'space-between'}>
        <div className={'job-number'}>
          {canReadJobs ? (
            <a href={JOB_DETAILS_URL.replace(':id', job.id)} className={'button-style-href'}>
              {job.jobNumber}
            </a>
          ) : (
            job.jobNumber
          )}
        </div>
        <div className={'qty-to-make'}>
          {`${numberFormat(job.qtyToMake)} ${handleNullException(job, 'product.measurement.shortName')}`}
        </div>
      </FlexContainer>

      <div className={'product-code'}>
        {canReadProducts ? (
          <a href={PRODUCT_DETAIL_URL.replace(':id', job.product.id)} className={'button-style-href'}>
            {job.product.productCode}
          </a>
        ) : null}
      </div>
      {columns.map((column: string) => (
        <div key={column}>
          <small>{`${column}: `.toUpperCase()}</small>
          <span className={column}>{hasKey(flexibleContents(), column) ? flexibleContents()[column] : ''}</span>
        </div>
      ))}
      <FlexContainer className={'space-between'}>
        <FlexContainer>
          <UserAvatarCircleIcon
            label={'avatar'}
            size={'medium'}
            // primaryColor={'white'}
          />
          <span>
            {handleNullException(job, 'customerOrderRef') || handleNullException(job, 'salesOrder.customerRef')}
          </span>
        </FlexContainer>
        <div>
          <Container textColor="white" backgroundColor={'rgb(66, 82, 110)'}>
            <Format>{machines.find((m: iMachine) => m.id === job.machineId)?.name}</Format>
          </Container>
        </div>
      </FlexContainer>
    </JobCardWrapper>
  );
};

export default JobCard;
