import React from 'react';
import styled from 'styled-components';
// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import Spinner from '@atlaskit/spinner';
import {
  getAttachmentList,
  uploadAttachment,
  updateAttachment,
  deactivateAttachment,
} from '../../../services/AssetService';
import iAttachment from '../../../types/attachment/iAttachment';
import Thumbnail from '../../generalAttachments/Thumbnail';
import Uploader from '../../generalAttachments/Uploader';
import DeleteByRetype from '../../deleteWithReType/DeleteByReType';
import useListCrudHook from '../useListCrudHook/useListCrudHook';
import LoadMoreThumbnail from '../../generalAttachments/LoadMoreInThumbnail';

const ThumbnailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-wrap: nowrap;
  overflow: auto;

  max-width: 100%;
  box-sizing: border-box;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  .spinner-wrapper {
    min-height: 40px;
  }
  .my-swiper-slides {
    margin-left: 0px;
    .slide-thumbnail-wrapper {
      width: fit-content !important;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 1.5rem;
      font-weight: 900;
      color: black;
    }
    &.small-arrow {
      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 0.5rem;
        font-weight: 900;
        color: black;
      }
    }
  }
`;
const THUMBNAILS_ONE_TIME = 5;
// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const useAttachmentHook = ({
  entityId,
  entityName,
  childrenForUploader = <CameraFilledIcon label={'camera'} testId={'upload-trigger'} />,
  isTablet = false,
  thumbnailSize = 'normal',
}: {
  entityId: string;
  entityName: string;
  childrenForUploader?: React.ReactNode;
  isTablet?: boolean;
  thumbnailSize?: string;
}) => {
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onSubmit,
    onOpenDeleteModal,
    onSetCurrentPage,
  } = useListCrudHook<iAttachment>({
    getFn: getAttachmentList,
    createFn: uploadAttachment,
    updateFn: updateAttachment,
    deleteFn: deactivateAttachment,
    filter: `entityId:${entityId},entityName:${entityName}`,
    sort: 'updatedAt:DESC',
    loadMoreApplied: true,
    perPage: THUMBNAILS_ONE_TIME,
  });
  const getUploader = () => {
    return (
      <Uploader onUploadFile={onSubmit} extraData={() => ({ entityId, entityName })}>
        {childrenForUploader}
      </Uploader>
    );
  };

  const loadMore = () => {
    if (state.total && state.data.length < state.total) {
      onSetCurrentPage((state.currentPage || 0) + 1);
    }
  };

  const onInit = (swiper: SwiperCore) => {
    if (state.currentPage && state.total && state.currentPage > 1) {
      swiper.slideTo(state.data.length - THUMBNAILS_ONE_TIME - 1);
    }
  };
  const getThumbnail = (isDisabled = false) => {
    return (
      <>
        <ThumbnailsContainer>
          {state.isLoading || state.isConfirming ? (
            <div className={'spinner-wrapper'}>
              <Spinner size={'medium'} />
            </div>
          ) : (
            <Swiper
              slidesPerView={THUMBNAILS_ONE_TIME}
              spaceBetween={5}
              // slidesPerGroup={3}
              loop={false}
              // pagination
              // loopFillGroupWithBlank
              navigation
              className={`my-swiper-slides  ${thumbnailSize === 'small' ? 'small-arrow' : ''}`}
              onInit={onInit}
            >
              {state.data.map((attachment: iAttachment) => (
                <SwiperSlide key={attachment.id} className={'slide-thumbnail-wrapper'}>
                  <Thumbnail
                    attachment={attachment}
                    onRemove={onOpenDeleteModal}
                    isTablet={isTablet}
                    size={thumbnailSize}
                    isDisabled={isDisabled}
                  />
                </SwiperSlide>
              ))}
              {!!state.total && !!state.to && state.to < state.total && (
                <SwiperSlide key={'load-more'} className={'slide-thumbnail-wrapper'}>
                  <LoadMoreThumbnail onClick={loadMore} size={thumbnailSize} />
                </SwiperSlide>
              )}
            </Swiper>
          )}
        </ThumbnailsContainer>

        <DeleteByRetype
          isOpen={edit.isModalOpen && !!edit.delTargetId}
          onClose={onCloseModal}
          onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
          answer={edit.target?.asset.fileName}
          isConfirming={state.isConfirming}
        />
      </>
    );
  };
  return { getUploader, getThumbnail, files: state.data, total: state.total || 0 };
};

export default useAttachmentHook;
