export const AUTH_URL_LOGIN = '/login';
export const AUTH_SIGN_UP_URL = '/signup';
export const AUTH_ACTIVATE_URL = '/activateCompany';
export const AUTH_RETRIEVAL_URL = '/retrieval';
export const AUTH_RESET_PASSWORD_URL = '/resetPassword';
export const BACK_END_URL = '/b/';
export const USER_PROFILE_URL = '/b/profile';
export const CUSTOMER_URL = '/b/customer';
export const SUPPLIER_URL = '/b/supplier';
export const SERVICE_PROVIDER_URL = '/b/serviceProvider';
export const CUSTOMER_DETAIL_URL = '/b/customer/:id';
export const SUPPLIER_DETAIL_URL = '/b/supplier/:id';
export const SERVICE_PROVIDER_DETAIL_URL = '/b/serviceProvider/:id';
export const JOB_URL = '/b/job';
export const JOB_DETAILS_URL = '/b/job/:id';
export const JOB_ATTRIBUTES_URL = '/b/jobAttribute';
export const JOB_SCHEDULER_URL = '/b/job/scheduler';
export const SALES_URL = '/b/sales';
export const SALES_INVOICE_URL = '/b/invoice/sales';
export const SALES_ORDER_DETAIL_URL = '/b/sales/:id';
export const PURCHASES_URL = '/b/purchases';
export const PURCHASE_ORDER_DETAIL_URL = '/b/purchases/:id';
export const PURCHASE_ORDER_RECEIVING_URL = '/b/purchases/receiving/:id';
export const PRODUCTS_URL = '/b/products';
export const PRODUCT_DETAIL_URL = '/b/products/:id';
export const PRODUCT_TYPES_URL = '/b/productTypes';
export const PRODUCT_ATTRIBUTE_SETS_URL = '/b/productAttributeSets';
export const PRODUCT_ATTRIBUTE_SET_DETAIL_URL = '/b/productAttributeSets/:id';
export const BOM_ATTRIBUTES_URL = '/b/bomAttributes';
export const WAREHOUSE_URL = '/b/warehouse';
export const WAREHOUSE_DETAIL_URL = '/b/warehouse/:id';
export const ITEM_LOCATIONS_URL = '/b/itemLocations';
export const SETTINGS_URL = '/b/settings';
export const SETTINGS_MACHINES_URL = '/b/settings/machines';
export const SETTINGS_USERS_URL = '/b/settings/users';
export const SETTINGS_ROLES_URL = '/b/settings/roles';
export const SETTINGS_ROLE_DETAILS_URL = '/b/settings/role/:id';
export const SETTINGS_COMPANY_URL = '/b/settings/company';
export const SETTINGS_TAX_URL = '/b/settings/tax';
export const SETTINGS_UNIT_URL = '/b/settings/unit';
export const SETTINGS_SHIFTS_URL = '/b/settings/shifts';
export const SETTINGS_PALLETS_URL = '/b/settings/palletSystems';
export const SETTINGS_COURIERS_URL = '/b/settings/couriers';
export const SETTINGS_DATA_IMPORT_URL = '/b/settings/dataImport';
export const SETTINGS_DATA_IMPORT_DETAIL_URL = '/b/settings/dataImport/:id';
export const SETTINGS_WORKFLOW_EDIT_URL = '/b/settings/workflow/:id';
export const SETTINGS_PRINTING_URL = '/b/settings/printing';
export const SETTINGS_ENTITY_STATUS_TYPES_URL = '/b/settings/entityStatusType';
export const SETTINGS_ENTITY_STATUS_TYPE_URL = '/b/settings/entityStatusType/:id';
export const SETTINGS_WRAPPINGS_URL = '/b/settings/wrappingMethods';
export const DISPATCH_NOTES_URL = '/b/dispatchNotes';
export const DISPATCH_NOTE_DETAIL_URL = '/b/dispatchNotes/:id';
export const STOCK_TRANSFER_URL = '/b/stockTransfer';
export const STOCK_TAKE_URL = '/b/stockTake';
export const STOCK_TAKE_DETAIL_URL = '/b/stockTake/:id';
export const NCR_DETAIL_URL = '/b/NCR/:id';
export const NCRS_URL = '/b/NCR';
export const FAULT_CATEGORY_URL = '/b/faultCategory';
export const AOS_URL = '/b/acquisitionOrders';
export const STOCK_FORECAST_URL = '/b/stockForecast';
export const REPORT_PURCHASE_HISTORY_URL = '/b/reports/purchaseHistory';
export const AO_DETAIL_URL = '/b/acquisitionOrders/:id';
export const SETTINGS_INTEGRATIONS_URL = '/b/settings/integrations';
export const SETTINGS_INTEGRATIONS_CALLBACK_URL = '/b/settings/integrations/callback';
export const CHANNEL_DETAIL_URL = '/b/settings/channel/:id';
export const SERVICE_TYPE_URL = '/b/serviceType';
export const BILLING_HOME_URL = '/b/billing';
