import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import styled from 'styled-components';
import iJobAttribute from '../../../../types/job/iJobAttribute';
import CustomizedLabel from '../../../../shared/form/hookForms/CustomizedLabel';
import InputWithController from '../../../../shared/form/hookForms/InputWithController';
import SelectWithController from '../../../../shared/form/hookForms/SelectWithController';
import { iLabelValuePair } from '../../../../shared/UITypes/types';

const JobAttributeWrapper = styled.div`
  margin-top: 10px;
  .job-attribute-content {
    display: flex;
    align-items: flex-start;
    .value-wrapper {
      flex-grow: 1;
      > div {
        display: flex;
        margin-top: 0px !important;
        flex-direction: column;
        > label {
          margin-bottom: 0px;
        }
      }
    }
    .unit-wrapper {
      max-width: 150px;
      display: flex;
      align-items: flex-start;
    }
  }
`;
const CreateJobAttribute = ({
  jobAttribute,
  onChange,
  control,
  errors,
  units,
  defaultValue,
}: {
  jobAttribute: iJobAttribute;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //  eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  units: Array<iLabelValuePair>;
  defaultValue?: string;
}) => {
  return (
    <JobAttributeWrapper className={'job-attribute-wrapper'}>
      <div className={'job-attribute-label'}>
        <CustomizedLabel label={jobAttribute.name} />
      </div>
      <div className={'job-attribute-content'}>
        <div className={'value-wrapper'}>
          <InputWithController
            label={''}
            defaultValue={defaultValue || (jobAttribute.jobAttributeValues && jobAttribute.jobAttributeValues.value)}
            name={`${jobAttribute.id}-value`}
            onChange={onChange}
            control={control}
            testId={'job-attribute-value'}
            isRequired={false}
            isNumeric
            errors={errors}
            triggerOnChangeForDefaultValue={false}
          />
        </div>
        <div className={'unit-wrapper'}>
          <SelectWithController
            label={''}
            options={units}
            placeholder={'unit...'}
            name={`${jobAttribute.id}-postfix`}
            onChange={onChange}
            control={control}
            testId={`${jobAttribute.id}-job-attribute-unit`}
            isRequired={false}
            errors={errors}
            className={'min-width-120 non-margin-top'}
            isClearable
          />
        </div>
      </div>
    </JobAttributeWrapper>
  );
};

export default CreateJobAttribute;
