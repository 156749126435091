import React from 'react';
import Button from '@atlaskit/button';
import { DELETE } from '../../../../shared/constants/ActionConstants';
import { DividerWrapper, PopUpContentContainer, PrintBtnName } from '../../../../shared/styles/styles';
import GeneralPrintBtn from '../../../../shared/buttons/GeneralPrintBtn';
import { printLocationLabel } from '../../../../services/PrintService';
import { CREATE_SUB_LOCATION, MOVE_WAREHOUSE } from '../../shared/Warehouse.constants';

const WarehouseMoreActions = ({
  onOpenDeleteModal,
  onOpenCreateModal,
  onOpenMoveModal,
  id,
}: {
  onOpenDeleteModal?: () => void;
  onOpenCreateModal?: () => void;
  onOpenMoveModal?: () => void;
  id?: string;
}) => {
  return (
    <PopUpContentContainer className={'more-actions-popup-content'}>
      {typeof onOpenCreateModal === 'function' && (
        <Button className={'popup-item'} onClick={onOpenCreateModal} testId={'create-warehouse-modal-trigger'}>
          {CREATE_SUB_LOCATION}
        </Button>
      )}
      {typeof onOpenMoveModal === 'function' && (
        <Button className={'popup-item'} onClick={onOpenMoveModal} testId={'move-warehouse-modal-trigger'}>
          {MOVE_WAREHOUSE}
        </Button>
      )}
      {id && (
        <GeneralPrintBtn serviceFunc={() => printLocationLabel(id)}>
          <PrintBtnName>Print Location Label</PrintBtnName>
        </GeneralPrintBtn>
      )}
      {typeof onOpenDeleteModal === 'function' && (
        <>
          <DividerWrapper />
          <Button className={'popup-item'} onClick={onOpenDeleteModal} testId={'delete-warehouse-modal-trigger'}>
            {DELETE}
          </Button>
        </>
      )}
    </PopUpContentContainer>
  );
};

export default WarehouseMoreActions;
