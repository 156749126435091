import React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import LinkBtn from '../../../../shared/buttons/LinkBtn';
import { SeparatorWrapper } from '../../../../shared/styles/styles';

const PaymentInfo = ({
  isShowPayments,
  lessPayment,
  amountDue,
  onClick,
}: {
  isShowPayments: boolean;
  lessPayment?: number;
  amountDue?: number;
  onClick: () => void;
}) => {
  if (!isShowPayments) return null;
  return (
    <>
      <SeparatorWrapper className={'groove'} />
      <Grid>
        <GridColumn medium={7}>
          <div className={'accountBookTitle'}>
            {'Less '}
            <LinkBtn
              btnName={'Payment'}
              //    eslint-disable-next-line
              onClick={onClick}
            />
          </div>
        </GridColumn>
        <GridColumn medium={5}>
          <div className={'accountBookValue'}>{lessPayment}</div>
        </GridColumn>
      </Grid>
      <Grid>
        <GridColumn medium={7}>
          <div className={'accountBookTitle bold'}>AMOUNT DUE</div>
        </GridColumn>
        <GridColumn medium={5}>
          <div className={'accountBookValue bold'}>{amountDue}</div>
        </GridColumn>
      </Grid>
    </>
  );
};

export default PaymentInfo;
