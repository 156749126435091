import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InlineEdit from '@atlaskit/inline-edit';
import { ErrorMessage } from '@atlaskit/form';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import { DatePicker } from '@atlaskit/datetime-picker';
import { formatDate } from '../../../services/UtilsService';
import { DATE_PLACEHOLDER } from '../../constants/TimePlaceholderConstants';

const ReadViewContainer = styled.div`
  display: flex;
  line-height: ${(gridSize() * 2.5) / fontSize()};
  max-width: 100%;
  min-height: ${(gridSize() * 2.5) / fontSize()}em;
  padding: ${gridSize()}px 0px;
  word-break: break-word;

  color: ${props => (props.defaultValue === '' ? '#7a869a' : '#091E42')};
`;

type iState = {
  value: string;
  focused: boolean;
};
const initialState: iState = {
  value: '',
  focused: false,
};
const DivWrapper = styled.div``;
const InlineEditDatePicker = ({
  name,
  defaultValue,
  label,
  onConfirm,
  isRequired = false,
  testId = 'inline-edit-date-picker',
  isDisabled = false,
}: {
  defaultValue?: string;
  label: string;
  name: string;
  onConfirm?: (name: string, newValue: string | null) => void;
  isRequired?: boolean;
  testId?: string;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(s => ({
      ...s,
      value: defaultValue || initialState.value,
    }));
  }, [defaultValue]);

  // eslint-disable-next-line
  const changeValue = (value: any) => {
    // value is null or is default value
    // don't change it
    if (value === defaultValue) {
      setState({
        ...state,
        focused: false,
      });
      return;
    }
    setState(preState => ({
      ...preState,
      value,
      focused: false,
    }));
    if (typeof onConfirm === 'function') {
      onConfirm(name, value || null);
    }
  };
  const validate = (value?: string) => {
    // isRequired
    if (isRequired && !value) {
      return 'is required';
    }
    return undefined;
  };

  return (
    <DivWrapper id={name} tabIndex={0} onFocus={() => setState(preState => ({ ...preState, focused: true }))}>
      <InlineEdit
        label={label}
        onConfirm={changeValue}
        editView={({ errorMessage, ...fieldProps }) => (
          <>
            <DatePicker
              // eslint-disable-next-line
              {...fieldProps}
              dateFormat="Do MMM YYYY"
              testId={testId}
              placeholder={DATE_PLACEHOLDER}
              isDisabled={isDisabled}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
        defaultValue={state.value}
        readView={() => (
          <ReadViewContainer defaultValue={state.value}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!state.value
              ? isDisabled
                ? 'disabled'
                : 'Click to enter value'
              : formatDate(state.value, 'Do MMM  YYYY')}
          </ReadViewContainer>
        )}
        isEditing={!isDisabled && state.focused}
        onEdit={() => setState({ ...state, focused: true })}
        onCancel={() => setState({ ...state, focused: false })}
        validate={validate}
      />
    </DivWrapper>
  );
};
export default InlineEditDatePicker;
