import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import printJS from 'print-js';
import PrintsParamsModal from '../../../modal/CustomizeModal';
import PalletLabelModalBody from './PalletLabelModalBody';
import ChevronDownIconPopup from '../../../popup/ChevronDownIconPopup';
import PrintsItems from './PrintsMenu';
import RollLabelModalBody from './RollLabelModalBody';
import iJobAttribute from '../../../../types/job/iJobAttribute';
import { iParams } from '../../../UITypes/types';
import { apiErrorToast } from '../../../toast/Toast';
// import { roundUpJobAttribute } from '../JobDetails.utils';
import {
  LENGTH_JOB_ATTRIBUTE,
  NOMINAL_WEIGHT_JOB_ATTRIBUTE,
  PRINT_PALLET_LABEL,
  PRINT_ROLL_LABELS,
  ROLL_JOB_ATTRIBUTE,
  PRINT_JOB_CARD,
  PRINT_CORE_LABEL,
  PRINT_ENVELOP_LABEL,
  PRINT_CERTIFICATE_OF_CONFORMANCE,
} from '../../constants';
import {
  printCertificateOfConformance,
  printCoreLabel,
  printEnvelopLabel,
  printJobCard,
  printPalletLabel,
  printRollLabel,
} from '../../../../services/PrintService';

type iState = {
  printType?: string;
  isLoading: boolean;
};
const initialState: iState = {
  isLoading: false,
};

const getTargetJobAttributes = (attrs: Array<iJobAttribute>) => {
  const mapped = [LENGTH_JOB_ATTRIBUTE, NOMINAL_WEIGHT_JOB_ATTRIBUTE, ROLL_JOB_ATTRIBUTE].map((name: string) => {
    const jobAttr = attrs.find((attr: iJobAttribute) => attr.name === name);
    return {
      prefix: jobAttr?.jobAttributeValues.prefix,
      postfix: jobAttr?.jobAttributeValues.postfix,
      value: jobAttr?.jobAttributeValues.value,
      // && roundUpJobAttribute(jobAttr),
    };
  });
  return { length: mapped[0], nominalWeight: mapped[1], roll: mapped[2] };
};
const printsNeedModal = [PRINT_PALLET_LABEL, PRINT_ROLL_LABELS];

const Prints = ({
  jobId,
  jobAttributes,
  qtyTotal,
}: {
  jobId: string;
  jobAttributes: Array<iJobAttribute>;
  qtyTotal: number;
}) => {
  const [state, setState] = useState(initialState);
  const { length, nominalWeight, roll } = getTargetJobAttributes(jobAttributes);
  const { control, setValue, errors, handleSubmit } = useForm({});

  const onPrint = async (type: string, data?: iParams) => {
    let response = null;
    setState(preState => ({ ...preState, isLoading: true }));
    try {
      switch (type) {
        case PRINT_JOB_CARD:
          response = await printJobCard(jobId);
          break;
        case PRINT_CORE_LABEL:
          if (typeof roll === 'undefined' || Number.isNaN(roll.value)) {
            return;
          }
          response = await printCoreLabel(jobId, {
            start: '1',
            end: Math.ceil(Number(roll.value)).toString(),
          });
          break;
        case PRINT_PALLET_LABEL:
          response = await printPalletLabel(jobId, {
            ...(data as iParams),
            // totalQty: qtyTotal.toString(),
          });
          break;
        case PRINT_ROLL_LABELS:
          if (typeof roll.value === 'undefined' || Number.isNaN(roll.value)) {
            return;
          }
          response = await printRollLabel(jobId, {
            ...(data as iParams),
            printAll: Math.ceil(Number(roll.value)).toString(),
          });
          break;
        case PRINT_ENVELOP_LABEL:
          response = await printEnvelopLabel(jobId, {});
          break;
        case PRINT_CERTIFICATE_OF_CONFORMANCE:
          response = await printCertificateOfConformance(jobId, {});
          break;
        default:
          break;
      }
      if (response) {
        printJS({ printable: response, type: 'pdf', showModal: true });
      }
      setState({ ...state, isLoading: false, printType: undefined });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isLoading: false, printType: undefined });
    }
  };

  const onSelectPrintItem = async (type: string) => {
    setState(preState => ({ ...preState, printType: type }));
    if (!printsNeedModal.includes(type)) {
      await onPrint(type);
    }
  };
  // eslint-disable-next-line
  const onModalConfirm = async (data: any) => {
    if (typeof state.printType === 'undefined') return;
    await onPrint(state.printType, data);
  };
  const onModalClose = () => {
    setState({ ...state, printType: undefined });
  };

  const getModalBody = () => {
    if (typeof state.printType === 'undefined') return null;
    switch (state.printType) {
      case PRINT_PALLET_LABEL:
        return <PalletLabelModalBody control={control} errors={errors} onChange={setValue} defaultQty={qtyTotal} />;
      case PRINT_ROLL_LABELS:
        return (
          <RollLabelModalBody
            control={control}
            errors={errors}
            onChange={setValue}
            length={length}
            nominalWeight={nominalWeight}
          />
        );
      default:
        break;
    }
    return null;
  };
  return (
    <>
      <ChevronDownIconPopup btnName={'Print'}>
        <PrintsItems onSelectPrintItem={onSelectPrintItem} selected={state.printType} isLoading={state.isLoading} />
      </ChevronDownIconPopup>
      <PrintsParamsModal
        isOpen={!!state.printType && printsNeedModal.includes(state.printType)}
        onConfirm={handleSubmit(onModalConfirm)}
        onCancel={onModalClose}
        confirmBtnName={'Print'}
        modalBody={getModalBody()}
        modalHeading={<span>{state.printType}</span>}
        isConfirming={state.isLoading}
        isDisabled={Object.keys(errors).length > 0}
      />
    </>
  );
};

export default Prints;
