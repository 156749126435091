import React from 'react';
import { handleNullException } from '../../../../services/UtilsService';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import iJob from '../../../../types/job/iJob';
import iProduct from '../../../../types/product/iProduct';
import { iSingleSelectOption } from './NCRDetail.type';

export const getProductOptionLabel = (option: iSingleSelectOption<iProduct>) =>
  !option ? null : (
    <ComposeSecondaryText
      secondaryText={handleNullException(option.value, 'name')}
    >
      {handleNullException(option.value, 'productCode')}
    </ComposeSecondaryText>
  );

export const getJobOptionLabel = (option: iSingleSelectOption<iJob>) =>
  !option ? null : (
    <ComposeSecondaryText
      secondaryText={handleNullException(option.value, 'product.productCode')}
    >
      {handleNullException(option.value, 'jobNumber')}
    </ComposeSecondaryText>
  );

export const getGeneralOptionLabel = <T extends { id: string }>(
  option: iSingleSelectOption<T>,
  field: string,
) => (!option ? null : handleNullException(option.value, field));
