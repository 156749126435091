import React from 'react';
import styled from 'styled-components';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { numberRound } from '../../services/UtilsService';
import { SeparatorWrapper } from '../styles/styles';

const PriceSummaryContainer = styled.div`
  min-width: 20rem;
  font-weight: 500;
  flex-grow: 0.1;
  padding-right: 16px;
  .accountBookTitle {
    text-align: end;
    padding-right: 16px;
    padding-bottom: 8px;
  }
  .accountBookValue {
    text-align: start;
    padding-left: 4px;
    padding-bottom: 8px;
    ::before {
      content: '$';
    }
  }
  .bold {
    font-weight: bold;
    font-size: large;
  }
`;
//  eslint-disable-next-line
type iLabelAnyValuePair = { label: string; value: any };

type iTotalPrice = {
  marginAmt?: number | null;
  subTotal?: number;
  taxAmt?: number;
  totalAmt?: number;
  children?: React.ReactNode;
};
const TotalPrice = ({ subTotal, taxAmt, totalAmt, marginAmt, children }: iTotalPrice) => {
  const columns: Array<iLabelAnyValuePair> = [
    { label: 'SubTotal', value: subTotal },
    { label: 'GST', value: taxAmt },
    { label: 'Margin', value: marginAmt },
    { label: 'Total', value: totalAmt },
  ];
  const getClassName = (isTitle: boolean, isBold: boolean) => {
    return `${isTitle ? 'accountBookTitle' : 'accountBookValue'} ${isBold ? 'bold' : ''}`;
  };
  return (
    <PriceSummaryContainer>
      <Page>
        {columns.map((item: iLabelAnyValuePair) => (
          <div key={item.label}>
            <Grid>
              <GridColumn medium={7}>
                <div className={getClassName(true, item.label === 'Total')}>{item.label}</div>
              </GridColumn>
              <GridColumn medium={5}>
                <div className={getClassName(false, item.label === 'Total')}>{numberRound(item.value || 0, 2)}</div>
              </GridColumn>
            </Grid>
            {item.label === 'Margin' && <SeparatorWrapper className={'solid mutual'} />}
          </div>
        ))}
        {children}
      </Page>
    </PriceSummaryContainer>
  );
};

export default TotalPrice;
