import React from 'react';
import styled from 'styled-components';
import CommentList from '../../../shared/comments/CommentList';
import LogList from '../../../shared/log/LogList';
import CustomizeTab from '../../../shared/tab/CustomizeTab';
import Investigation from './investigations/Investigation';

const ActivitySectionContainer = styled.div`
  margin-top: 5rem;
`;

const NCRDetActivities = ({
  modelName,
  modelId,
  isDisabled,
}: {
  modelName: string;
  modelId: string;
  isDisabled?: boolean;
}) => {
  const tabs = [
    {
      label: 'Investigations',
      content: (
        <Investigation issueId={modelId} canCreate={!isDisabled} canUpdate={!isDisabled} canDelete={!isDisabled} />
      ),
    },
    {
      label: 'Comments',
      content: <CommentList modelName={modelName} modelId={modelId} />,
    },
    {
      label: 'History',
      content: <LogList modelName={modelName} modelId={modelId} />,
    },
  ];

  return (
    <ActivitySectionContainer>
      <CustomizeTab components={tabs} />
    </ActivitySectionContainer>
  );
};

export default NCRDetActivities;
