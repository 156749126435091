import React from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import MoveModalBody from '../move/MoveModalBody';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import { iWarehouseUI } from '../../type/types';
import iWarehouseLocation from '../../../../types/warehouse/iWarehouse';

type iParam = {
  isOpen: boolean;
  onClose: () => void;
  //    eslint-disable-next-line
  onConfirm: (data: any) => void;
  isConfirming?: boolean;
  moveFrom: iWarehouseUI | iWarehouseLocation;
};

const MoveWarehouseModal = ({
  isOpen,
  onClose,
  onConfirm,
  isConfirming,
  moveFrom,
}: iParam) => {
  const { control, setValue, errors, handleSubmit, watch } = useForm({});
  //  eslint-disable-next-line
  const onConfirmMiddleWare = (data: any) => {
    if (_.get(data, 'asRoot')) {
      onConfirm({ toLocationId: null, fromLocationId: moveFrom.id });
      return;
    }
    const toLocationId = _.get(data, 'toLocation.id');
    onConfirm({ toLocationId, fromLocationId: moveFrom.id });
  };

  return (
    <CustomizeModal
      isOpen={isOpen}
      isConfirming={isConfirming}
      onConfirm={handleSubmit(onConfirmMiddleWare)}
      onCancel={onClose}
      isDisabled={Object.keys(errors).length > 0}
      modalHeading={`Move Location(${moveFrom?.name})`}
      modalBody={
        <MoveModalBody
          control={control}
          errors={errors}
          onChange={setValue}
          watch={watch}
        />
      }
    />
  );
};
export default MoveWarehouseModal;
