import React, { useState } from 'react';
import { LoadingButton } from '@atlaskit/button';
import { useForm } from 'react-hook-form';
import BackToLogin from '../shared/BackToLogin';
import RenderForm from '../shared/RenderForm';
import CompanyLogo from '../../../shared/company/CompanyLogo';
import AppPageCentered from '../shared/AppPageCentered';
import RedirectAfterSuccessAction from '../shared/RedirectToActivation';
import { signUp } from '../../../services/AuthService';
import { generateInitialValues } from '../shared/Form.utils';
import { SpaceVerticalContainer } from '../../../shared/styles/styles';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { SIGN_UP_FIELDS, SIGN_UP_SUCCESS_HINT } from '../shared/Auth.constants';

type iState = {
  isLoading: boolean;
  success: boolean;
};
const initialState: iState = {
  isLoading: false,
  success: false,
};
const CompanySignUp = () => {
  const [state, setState] = useState(initialState);
  const { control, errors, handleSubmit } = useForm({
    defaultValues: generateInitialValues(SIGN_UP_FIELDS),
    reValidateMode: 'onBlur',
  });
  //  eslint-disable-next-line
  const onSubmit = async (data: any) => {
    setState({ ...state, isLoading: true });
    try {
      await signUp(data);
      setState({
        ...state,
        isLoading: false,
        success: true,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isLoading: false });
    }
  };

  return (
    <AppPageCentered>
      <CompanyLogo />
      {state.success ? (
        <RedirectAfterSuccessAction message={SIGN_UP_SUCCESS_HINT} />
      ) : (
        <>
          <RenderForm
            control={control}
            fields={SIGN_UP_FIELDS}
            errors={errors}
          />
          <SpaceVerticalContainer className={'half'} />
          <LoadingButton
            appearance={'primary'}
            isLoading={state.isLoading}
            shouldFitContainer
            onClick={handleSubmit(onSubmit)}
          >
            Sign Up
          </LoadingButton>
        </>
      )}
      <SpaceVerticalContainer className={'quarter'} />
      <BackToLogin />
    </AppPageCentered>
  );
};

export default CompanySignUp;
