import React from 'react';
import _ from 'lodash';
import {
  currencyFormat,
  formatDate,
  getCategoryCode,
  handleNullException,
} from '../../../../../../services/UtilsService';
import { iRowContent } from '../../../../../../shared/UITypes/types';
import iPurchaseOrderItem from '../../../../../../types/purchases/iPurchaseOrderItem';
import { getCreatedOrUpdatedAt } from '../../../../../settings/utils';
import LinkBtnUrl from '../../../../../../shared/buttons/LinkBtnUrl';
import iEntityCategory from '../../../../../../types/status/iEntityCategory';
import StatusLozenge from '../../../../../../shared/statusComponent/StatusLozenge';
import ConversionText from '../../../../../../shared/text/ConversionText';

export const getHeads = (columns: Array<string>, tableName: string) => {
  const cells = columns.map((column: string) => {
    switch (column) {
      case 'Operation':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      default:
        return {
          key: column,
          content: column,
          testId: `${tableName}-table-column-${column}`,
        };
    }
  });
  return { cells };
};

export const getRows = (
  data: Array<iPurchaseOrderItem>,
  columns: Array<string>,
  categories: Array<iEntityCategory>,
) => {
  const mapList = () =>
    data.map((po: iPurchaseOrderItem) => {
      const row = {
        id: po.id,
        'PO No.': (
          <LinkBtnUrl
            btnName={po.purchaseOrder?.orderNumber || 'po n/a'}
            url={`/b/purchases/${po.purchaseOrder?.id}`}
          />
        ),
        Status: (
          <StatusLozenge
            status={handleNullException(po, 'purchaseOrder.status')}
            categoryCode={getCategoryCode(
              handleNullException(po, 'purchaseOrder.status'),
              categories,
            )}
          />
        ),
        Supplier: (
          <LinkBtnUrl
            btnName={handleNullException(po, 'purchaseOrder.supplier.name')}
            url={`/b/supplier/${po.purchaseOrder?.supplierId}`}
          />
        ),
        Reference: handleNullException(po, 'purchaseOrder.supplierRef'),
        'Unit Price': currencyFormat(handleNullException(po, 'unitPrice')),
        Qty: (
          <ConversionText
            value={handleNullException(po, 'qty')}
            productUnit={handleNullException(
              po,
              'product.measurement.shortName',
            )}
            contactCompanyUnit={handleNullException(
              po,
              'measurement.shortName',
            )}
            conversion={handleNullException(po, 'unitConversion') || 1}
          />
        ),
        'Received Qty': `${handleNullException(
          po,
          'receivedQty',
        )} ${handleNullException(po, 'product.measurement.shortName')}`,
        'Order Date': formatDate(
          handleNullException(po, 'purchaseOrder.orderDate'),
          'DD MMM YYYY',
        ),
        ETA: formatDate(
          handleNullException(po, 'purchaseOrder.eta'),
          'DD MMM YYYY',
        ),
        Created: getCreatedOrUpdatedAt({
          operatedAt: po.purchaseOrder?.createdAt,
          operator: po.purchaseOrder?.createdBy?.username,
        }),
        Updated: getCreatedOrUpdatedAt({
          operatedAt: po.purchaseOrder?.updatedAt,
          operator: po.purchaseOrder?.updatedBy?.username,
        }),
      };
      return row;
    });

  return mapList().map((row: iRowContent) => {
    const cells = columns.map((column: string) => ({
      content: _.get(row, column) ? row[column] : '',
    }));
    return { cells, testId: 'product-po-row' };
  });
};
