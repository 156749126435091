import { iItemLocationTransfer } from './StockTransfer.type';

export const mergeRecords = (
  newRecords: Array<iItemLocationTransfer>,
  oldRecords: Array<iItemLocationTransfer>,
) => {
  const tempRecords = [...oldRecords];
  newRecords.forEach((newR: iItemLocationTransfer) => {
    const oldRecordIndex = tempRecords.findIndex(
      (r: iItemLocationTransfer) => r.id === newR.id,
    );
    if (oldRecordIndex > -1) {
      tempRecords.splice(oldRecordIndex, 1, newR);
    } else {
      tempRecords.push(newR);
    }
  });
  return tempRecords;
};
