import React from 'react';
import AppPage from '../../../layout/AppPage';
import CenteredDiv from '../../../shared/CenteredDiv';
import { FullHeightWrapper } from '../../../shared/styles/styles';

const AppPageCentered = ({ children }: { children: React.ReactNode }) => {
  return (
    <AppPage>
      <FullHeightWrapper data-testid={'full-height-wrapper'}>
        <CenteredDiv className={'auth-div'}>{children}</CenteredDiv>
      </FullHeightWrapper>
    </AppPage>
  );
};

export default AppPageCentered;
