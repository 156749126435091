import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../../shared/constants/AsyncConstants';
import { iKeyValuePairs, iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

export const getProducts = (config?: iParams) => {
  return AppService.get('/product', {
    include: 'measurement,createdBy,updatedBy,productType,attributeSet,productAttributeValues',
    ...config,
  }).then(({ data }) => data);
};
export const updateProduct = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/product/${id}`, updateContent).then(({ data }) => data);
};
export const getProductDetail = (id: string) => {
  return AppService.get(`/product/${id}`, {
    include: 'measurement,bomItems,attributeSet',
  }).then(({ data }) => data);
};
export const deactivateProduct = (id: string) => {
  return AppService.delete(`/product/${id}`).then(({ data }) => data);
};
export const createProduct = (postData: iKeyValuePairs) => {
  return AppService.post('/product', postData).then(({ data }) => data);
};

export const createProductFromTemplate = (
  templateId: string,
  // name: string,
  postData: iParams,
) => {
  return AppService.post(`/product/template/${templateId}`, postData).then(({ data }) => data);
};

export const getProductListAsyncSearch = (config?: iParams) => {
  return AppService.get('/product', {
    include: 'measurement',
    ...config,
    perPage: ASYNC_SEARCH_PER_PAGE,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
  }).then(({ data }) => data);
};
export default {
  getProducts,
  updateProduct,
  deactivateProduct,
  getProductDetail,
  createProduct,
  getProductListAsyncSearch,
};
