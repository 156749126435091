import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';

const ModalContentWrapper = styled.div`
  min-height: 40px;
  .retype-confirm-info {
    display: block;
    margin: 4px 0px 8px;
  }
`;

const RetypeModalContent = ({
  answer,
  onTypeAnswer,
  confirmInfo,
}: {
  answer: string;
  onTypeAnswer: (isRightAnswer: boolean) => void;
  confirmInfo?: React.ReactNode;
}) => {
  const [value, setValue] = useState('');

  // clean state when unmount
  useEffect(() => {
    return () => {
      onTypeAnswer(false);
    };
  }, [onTypeAnswer]);

  //  eslint-disable-next-line
  const onChange = (event: any) => {
    setValue(event.target.value);
    onTypeAnswer(event.target.value.trim() === answer.trim());
  };
  return (
    <ModalContentWrapper className={'modal-content-wrapper'}>
      {confirmInfo || (
        <span className={'retype-confirm-info'}>
          Please type in <strong>{answer}</strong> to confirm
        </span>
      )}
      <Textfield
        value={value}
        onChange={onChange}
        isCompact
        testId={'textfield-enter-answer-confirmation'}
      />
    </ModalContentWrapper>
  );
};

export default RetypeModalContent;
