import React from 'react';
import moment from 'moment';
import iSalesOrderDetail from '../../../types/sales/iSalesOrder';
import { iConfigColumn } from '../../despatchNote/shared/DispatchNote.type';
import SummaryDispatchNoteItem from './SummaryDispatchNoteItem';
import SummarySalesOrder from './SummarySalesOrder';
import { formatDate } from '../../../services/UtilsService';
import { DATE_FORMAT } from '../shared/Dashboard.constants';
import iDispatchNoteItem from '../../../types/dispatchNote/iDispatchNoteItem';
import { iSalesState } from '../shared/Dashboard.types';

export const getRows = ({
  state,
  columns,
  date,
}: {
  state: iSalesState;
  columns: Array<iConfigColumn>;
  date: string;
}) => {
  //  eslint-disable-next-line
  const getCells = ({ day, fixedCell }: { day?: string; fixedCell: any }) => {
    return columns.map((column: iConfigColumn) => {
      const { key, type } = column;
      switch (type) {
        case 'sort-type':
          return fixedCell;
        case 'dispatchedMonth':
          return {
            key,
            content: (
              <SummaryDispatchNoteItem
                data={
                  day
                    ? state.dispatchedMonth.filter((item: iDispatchNoteItem) =>
                        moment(item.dispatchNote.dispatchDate).isSame(
                          moment(day),
                          'day',
                        ),
                      )
                    : state.dispatchedMonth
                }
              />
            ),
          };
        case 'invoicedMonth':
          return {
            key,
            content: (
              <SummarySalesOrder
                data={
                  day
                    ? state.invoicedMonth.filter((item: iSalesOrderDetail) =>
                        moment(item.invoiceDate).isSame(moment(day), 'day'),
                      )
                    : state.invoicedMonth
                }
              />
            ),
          };
        case 'receivedMonth':
          return {
            key,
            content: (
              <SummarySalesOrder
                data={
                  day
                    ? state.receivedMonth.filter((item: iSalesOrderDetail) =>
                        moment(item.orderDate).isSame(moment(day), 'day'),
                      )
                    : state.receivedMonth
                }
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
  };

  return [
    {
      cells: getCells({
        day: date,
        fixedCell: {
          content: formatDate(date, DATE_FORMAT),
        },
      }),
    },
    {
      cells: getCells({
        fixedCell: {
          content: formatDate(date, 'MMM YYYY'),
        },
      }),
    },
  ];
};
