import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import CheckboxWithController from '../../../../shared/form/hookForms/CheckboxWithController';
import { iWarehouseUI } from '../../type/types';

const ModalContainer = styled.div`
  min-height: 40px;
`;

type iParam = {
  isOpen?: boolean;
  onClose?: () => void;
  //    eslint-disable-next-line
  onConfirm?: (data: any) => void;
  isConfirming?: boolean;
  warehouse?: iWarehouseUI;
};

const ChangeAllowPartsModal = ({
  isOpen,
  onClose,
  onConfirm,
  isConfirming,
  warehouse,
}: iParam) => {
  const { control, setValue, getValues, errors, handleSubmit } = useForm({});
  const collectData = () => {
    const data = getValues();
    if (typeof onConfirm === 'function') {
      onConfirm(data);
    }
  };
  const getModalContent = () => (
    <>
      <CheckboxWithController
        defaultValue={!warehouse?.allowParts}
        label={'Allow Parts'}
        name={'allowParts'}
        onChange={setValue}
        control={control}
      />
    </>
  );
  const getModal = () => (
    <Modal
      testId="warehouse-change-allow-part-modal"
      actions={[
        {
          text: 'Confirm',
          onClick: handleSubmit(collectData),
          appearance: 'primary',
          isDisabled: Object.keys(errors).length > 0,
          testId: 'change-allow-parts',
        },
        { text: 'Cancel', onClick: onClose, testId: 'cancel-btn-in-modal' },
      ]}
      onClose={onClose}
      heading={`Are you sure to modify ${warehouse?.name}?`}
    >
      <ModalContainer className={'modal-container'}>
        {isConfirming ? (
          <Spinner testId="spinner-in-modal" size={'small'} />
        ) : (
          getModalContent()
        )}
      </ModalContainer>
    </Modal>
  );
  if (!isOpen) return null;
  return <ModalTransition>{isOpen && getModal()}</ModalTransition>;
};

export default ChangeAllowPartsModal;
