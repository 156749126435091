import React, { createContext, useContext, useState, useEffect } from 'react';
import iJob from '../../../types/job/iJob';
import iJobCategory from '../../../types/job/iJobCategory';
import iJobStatus from '../../../types/job/iJobStatus';
import iMachine from '../../../types/machine/iMachine';
import { getMachines } from '../../../services/Settings/MachineService';
import { AVOID_PAGINATION_PER_PAGE } from '../../../shared/constants/AsyncConstants';
import { STATUS_CATEGORY_FINISHED } from '../../../shared/job/constants';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { getJobCategories, getJobListConfig } from '../../../services/JobService';

type iDashboardJobContext = {
  isLoading: boolean;
  scheduledJobs: Array<iJob>;
  unscheduledJobs: Array<iJob>;
  machines: Array<iMachine>;
  jobStatusCategories: Array<iJobCategory>;
};
const initialJobContext: iDashboardJobContext = {
  isLoading: true,
  machines: [],
  jobStatusCategories: [],
  scheduledJobs: [],
  unscheduledJobs: [],
};

const DashboardJobContext = createContext<iDashboardJobContext>(initialJobContext);
const DashboardJobContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(initialJobContext);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const machines: Array<iMachine> = await getMachines({
          sort: 'name:ASC',
        });
        const jobStatusCategories: Array<iJobCategory> = await getJobCategories();
        const statuses: Array<iJobStatus> = jobStatusCategories.reduce(
          (acc: Array<iJobStatus>, cur: iJobCategory) =>
            cur.name === STATUS_CATEGORY_FINISHED ? acc : [...acc, ...cur.jobStatuses],

          [],
        );
        const { data } = await getJobListConfig({
          filter: `statusId:${statuses.map((item: iJobStatus) => item.id).join('|')}`,
          include: 'product.measurement,status',
          perPage: AVOID_PAGINATION_PER_PAGE,
          currentPage: '1',
        });
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          machines,
          scheduledJobs: data.filter((item: iJob) => item.scheduledAt),
          unscheduledJobs: data.filter((item: iJob) => !item.scheduledAt),
          jobStatusCategories,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);

  return <DashboardJobContext.Provider value={{ ...state }}>{children}</DashboardJobContext.Provider>;
};
export const useDashboardJobContext = () => useContext(DashboardJobContext);
export default DashboardJobContextProvider;
