import React from 'react';
import styled from 'styled-components';
import { Label } from '@atlaskit/field-base';
import { Grid, GridColumn } from '@atlaskit/page';
import iIssue from '../../../types/NCR/iIssue';
import iJob from '../../../types/job/iJob';
import iContactCompany from '../../../types/contactCompany/iContactCompany';
import iSalesOrderDetail from '../../../types/sales/iSalesOrder';
import DetailToggles from './DetailToggles';
import LogFooter from '../../../shared/log/LogFooter';
import InlineEdit from '../../../shared/form/InlineEdit';
import InlineEditSelect from '../../../shared/form/InlineEditSelect';
import InlineEditDatePicker from '../../../shared/form/inlineEdit/InlineEditDatePicker';
import InlineEditAsyncSelect from '../../../shared/form/inlineEdit/asyncSelect/InlineEditAsyncSelect';
import { getCustomerListAsyncSearch } from '../../../services/ContactCompanyService';
import { getJobListAsyncSearch } from '../../../services/JobService';
import { getSalesOrderListAsyncSearch } from '../../../services/SalesOrderService';
import { handleNullException } from '../../../services/UtilsService';
import { CHECK_OPTIONS, NO_OPTION, TOGGLE_ATTRIBUTES, YES_OPTION } from './shared/NCRDetail.constant';
import { iToggleAttribute } from './shared/NCRDetail.type';
import { iDetailUpdateFieldAndEagerLoadFn, iDetailUpdateOneFieldFn } from '../../../shared/UITypes/types';
import { getGeneralOptionLabel, getJobOptionLabel } from './shared/NCRDetail.utils';

const RightPanelWrapper = styled.div`
  margin-top: -1rem;
  margin-left: 2rem;
`;
type iSingleSelectOption<T> = iOption<T> | null | undefined;

type iOption<T> = {
  label: string;
  value: T;
};

const DetailRightPanel = ({
  detail,
  onUpdate,
  onUpdateFieldAndEagerLoad,
  isDisabled = false,
}: {
  detail?: iIssue;
  onUpdate: iDetailUpdateOneFieldFn;
  onUpdateFieldAndEagerLoad: iDetailUpdateFieldAndEagerLoadFn;
  isDisabled?: boolean;
}) => {
  const getToggleAttributes = () =>
    TOGGLE_ATTRIBUTES.map((attr: iToggleAttribute) => ({
      ...attr,
      isChecked: handleNullException(detail, attr.name) || false,
    }));

  if (typeof detail === 'undefined') {
    return null;
  }
  return (
    <RightPanelWrapper>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={1}>
          <Label label={'Customer:'} />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEditAsyncSelect
            name={'customerId'}
            defaultValue={handleNullException(detail, 'customer')}
            formatOptionLabel={(option: iSingleSelectOption<iContactCompany>) => getGeneralOptionLabel(option, 'name')}
            promiseFn={(keyword: string) => getCustomerListAsyncSearch({ like: `name:${keyword}` })}
            label={''}
            onConfirm={(name: string, value: iContactCompany | null) =>
              onUpdateFieldAndEagerLoad(name, value, 'customer')
            }
            readViewField={'name'}
            testId={'customerId'}
            placeholder={'search by name...'}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={1}>
          <Label label={'Customer NCR Ref:'} />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEdit
            name={'customerReference'}
            defaultValue={handleNullException(detail, 'customerReference')}
            label={''}
            onConfirm={onUpdate}
            isDisabled={isDisabled}
            testId={'customerReference'}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={1}>
          <Label label={'Reporter:'} />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEdit
            name={'reporter'}
            defaultValue={handleNullException(detail, 'reporter')}
            label={''}
            onConfirm={onUpdate}
            testId={'reporter'}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={1}>
          <Label label={'Reported Date:'} />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEditDatePicker
            name={'reportedDate'}
            defaultValue={handleNullException(detail, 'reportedDate')}
            label={''}
            onConfirm={onUpdate}
            testId={'reportedDate'}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={1}>
          <Label label={'Job:'} />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEditAsyncSelect
            name={'jobId'}
            defaultValue={handleNullException(detail, 'job')}
            formatOptionLabel={getJobOptionLabel}
            promiseFn={(keyword: string) => getJobListAsyncSearch({ like: `jobNumber:${keyword}` })}
            label={''}
            onConfirm={(name: string, value: iJob | null) => onUpdateFieldAndEagerLoad(name, value, 'job')}
            readViewField={'jobNumber'}
            testId={'jobId'}
            placeholder={'search by job number...'}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={1}>
          <Label label={'Sale Order:'} />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEditAsyncSelect
            name={'salesOrderId'}
            defaultValue={handleNullException(detail, 'salesOrder')}
            formatOptionLabel={(option: iSingleSelectOption<iSalesOrderDetail>) =>
              getGeneralOptionLabel(option, 'orderNumber')
            }
            promiseFn={(keyword: string) => getSalesOrderListAsyncSearch({ like: `orderNumber:${keyword}` })}
            label={''}
            onConfirm={(name: string, value: iSalesOrderDetail | null) =>
              onUpdateFieldAndEagerLoad(name, value, 'salesOrder')
            }
            readViewField={'orderNumber'}
            testId={'salesOrderId'}
            placeholder={'search by order number...'}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={1}>
          <Label label={'Customer Order Ref:'} />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEdit
            name={'customerOrderReference'}
            defaultValue={handleNullException(detail, 'customerOrderReference')}
            label={''}
            onConfirm={onUpdate}
            testId={'customerOrderReference'}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={1}>
          <Label label={'Sample Sent:'} />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEditSelect
            name={'isSampleSent'}
            defaultValue={handleNullException(detail, 'isSampleSent') ? YES_OPTION : NO_OPTION}
            label={''}
            selectOptions={CHECK_OPTIONS}
            onConfirm={(name: string, newValue: string | null) => onUpdate(name, newValue === YES_OPTION.value)}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      {handleNullException(detail, 'isSampleSent') && (
        <Grid layout={'fluid'} spacing={'compact'}>
          <GridColumn medium={1}>
            <Label label={'Sample Sent Date:'} />
          </GridColumn>
          <GridColumn medium={3}>
            <InlineEditDatePicker
              name={'sampleSentDate'}
              defaultValue={handleNullException(detail, 'sampleSentDate')}
              label={''}
              onConfirm={onUpdate}
              testId={'sampleSentDate'}
            />
          </GridColumn>
        </Grid>
      )}
      <DetailToggles attributes={getToggleAttributes()} onToggle={onUpdate} isDisabled={isDisabled} />
      {handleNullException(detail, 'hasCreditArranged') && (
        <Grid layout={'fluid'} spacing={'compact'}>
          <GridColumn medium={1}>
            <Label label={'Credit Reference:'} />
          </GridColumn>
          <GridColumn medium={3}>
            <InlineEdit
              name={'creditReference'}
              defaultValue={handleNullException(detail, 'creditReference')}
              label={''}
              onConfirm={onUpdate}
              testId={'creditReference'}
              isDisabled={isDisabled}
            />
          </GridColumn>
        </Grid>
      )}
      <LogFooter
        created={{
          operator: handleNullException(detail, 'createdBy.firstName'),
          time: handleNullException(detail, 'createdAt'),
        }}
        updated={{
          operator: handleNullException(detail, 'updatedBy.firstName'),
          time: handleNullException(detail, 'updatedAt'),
        }}
      />
    </RightPanelWrapper>
  );
};

export default DetailRightPanel;
