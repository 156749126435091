import { iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

/**
 *
 * @param config must set paidForEntityName and paidForEntityId to get payments for an entity
 * example: filter=paidForEntityName:SalesOrder,paidForEntityId:a4e67b44-e128-406e-b2cd-36abd0d16397
 * @returns paginated payments
 */
export const getPayments = (config?: iParams) => {
  return AppService.get('/payment', {
    ...config,
    include: 'method,createdBy,updatedBy',
  }).then(({ data }) => data);
};

export default {
  getPayments,
};
