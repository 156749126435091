import React, { useRef, useState } from 'react';
import { ButtonProps, LoadingButton } from '@atlaskit/button';
import { clearInterval, setInterval } from 'timers';
import CustomizeModal, { iCustomizeModal } from '../../../modal/CustomizeModal';
import ChannelJobService from '../../../../services/integration/ChannelJobService';
import { addToastForAPIResponse, apiErrorToast } from '../../../toast/Toast';
import ChannelEntityService from '../../../../services/integration/ChannelEntityService';
import { iChannelEntity } from '../../../../types/integration/iChannelEntity';
import { iChannelJobEntity } from '../../../../types/integration/iChannelJob';

type iWooCommerceSynBtn = {
  modalParam: iCustomizeModal;
  btnName: string;
  btnParams?: ButtonProps;
  channelJobCreationFn: () => Promise<iChannelJobEntity>;
  onSaved: (savedChannelEntity: iChannelEntity) => void;
};
const WooCommerceSynBtn = ({ btnParams, modalParam, channelJobCreationFn, onSaved, btnName }: iWooCommerceSynBtn) => {
  const [isSyncing, setIsSyncing] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  const onClose = () => {
    if (ref.current) {
      clearInterval(ref.current);
      ref.current = null;
    }
    setIsSyncing(false);
  };

  const handleClick = async () => {
    setIsSyncing(true);
    try {
      setIsSyncing(true);
      const channelJob = await channelJobCreationFn();
      const intervalId = setInterval(async () => {
        const channelJobReloaded = await ChannelJobService.getChannelJobById(channelJob.id);
        if (channelJobReloaded.status === 'PROCESSED') {
          clearInterval(intervalId);
          addToastForAPIResponse('success', 'Product created.');
          setIsSyncing(false);
          const channelEntities = await ChannelEntityService.getChannelEntitiesByEntityJobId(channelJob.id);
          if (channelEntities.data && channelEntities.data.length > 0) {
            onSaved(channelEntities.data[0]);
          }
        }
        if (channelJobReloaded.status === 'FAILED') {
          const errorDetails = channelJobReloaded.error.message || '';
          clearInterval(intervalId);
          addToastForAPIResponse('error', `Product creation error: ${errorDetails}`);
          setIsSyncing(false);
        }
      }, 1000);
      ref.current = intervalId;
    } catch (error) {
      setIsSyncing(false);
      apiErrorToast(error);
    }
  };

  const getModal = () => {
    if (isSyncing !== true) {
      return null;
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <CustomizeModal {...modalParam} onCancel={() => onClose()} isOpen customizedBtn={<></>} />;
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <LoadingButton {...btnParams} onClick={() => handleClick()} isLoading={isSyncing} testId={'sync-btn'}>
        {btnName}
      </LoadingButton>
      {getModal()}
    </>
  );
};

export default WooCommerceSynBtn;
