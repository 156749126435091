import AppService from './AppService';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';

export const getJobSchedule = (config?: iParams) => {
  return AppService.get('/jobSchedule', {
    include:
      'machine,status,product,product.measurement,jobAttributes,operator,product.productAttributeValues,salesOrder.customer',
    ...config,
  }).then(({ data }) => data);
};

export const getUnscheduledJobs = (config?: iParams) => {
  return AppService.get('/job', {
    include:
      'machine,status,product,product.measurement,jobAttributes,operator,product.productAttributeValues,salesOrder.customer',
    ...config,
  }).then(({ data }) => data);
};

//  eslint-disable-next-line
export const scheduleJobs = (config?: any) => {
  return AppService.put('/jobSchedule', config).then(({ data }) => data);
};
export const getDisabledShiftList = (config?: iParams) => {
  return AppService.get('/disabledShift', config).then(({ data }) => data);
};
//  eslint-disable-next-line
export const createDisabledShiftJobSchedule = (postData: iKeyValuePairs) => {
  return AppService.post('/disabledShift', postData).then(({ data }) => data);
};
//  eslint-disable-next-line
export const deactivateDisabledShiftJobSchedule = (id: string) => {
  return AppService.delete(`/disabledShift/${id}`).then(({ data }) => data);
};

export const getJobScheduleDashboard = (config?: iParams) => {
  return AppService.get('/jobSchedule', {
    include: 'product.measurement',
    ...config,
  }).then(({ data }) => data);
};
export const getUnscheduledJobsDashboard = (config?: iParams) => {
  return AppService.get('/job', {
    include: 'product.measurement,jobAttributes,salesOrder,salesOrderItem.measurement',
    ...config,
  }).then(({ data }) => data);
};

export default {
  getJobSchedule,
  getUnscheduledJobs,
  scheduleJobs,
  getDisabledShiftList,
  createDisabledShiftJobSchedule,
  deactivateDisabledShiftJobSchedule,
  getJobScheduleDashboard,
  getUnscheduledJobsDashboard,
};
