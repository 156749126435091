/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import { getEntityCategoryList } from '../../../../services/CategoryService';
import EntityStatusService from '../../../../services/EntityStatusService';
import { handleNullException } from '../../../../services/UtilsService';
import DndTable from '../../../../shared/dndTable/DndTable';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../../../../shared/hooks/useShowHideColumnHook/useColumnHook';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import ModuleEnabledWrapper from '../../../../shared/modulize/ModuleEnabledWrapper';
import CustomizePagination from '../../../../shared/pagination/CustomizePagination';
import SearchBar from '../../../../shared/search/SearchBar';
import { FlexContainer } from '../../../../shared/styles/styles';
import { ModuleCode } from '../../../../types/iModuleCode';
import iEntityCategory from '../../../../types/status/iEntityCategory';
import iEntityStatus from '../../../../types/status/iEntityStatus';
import { iSingleSelectOption } from '../../../NCR/detail/shared/NCRDetail.type';
import { ENTITY_STATUS_COLUMNS } from '../EntityStatusType.constants';
import { getRows } from './EntityStatusList.tableHelper';
import WorkflowEditBtn from '../../../../shared/workflow/WorkflowEditBtn';
import PageTitle from '../../../../shared/heading/PageTitle';
import { SETTINGS_WORKFLOW_EDIT_URL } from '../../../../shared/UrlMap';

const TableContainer = styled.div`
  margin-top: 1rem;
`;

export const getStatusOptionLabel = (option: iSingleSelectOption<iEntityStatus>) =>
  !option ? null : handleNullException(option.value, 'name');

const EntityStatus = ({
  typeId,
  typeName,
}: {
  typeId: string;
  typeName: string;
  // eslint-disable-next-line react/no-unused-prop-types
  onUpdateOneField?: (name: string, newValue: any, needRefetch: boolean) => Promise<any>;
}) => {
  const history = useHistory();
  const [categories, setCategories] = useState<iEntityCategory[]>([]);

  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onSearch,
    onReorder,
    onSetCurrentPage,
    onOpenEditModal,
  } = useListCrudHook<iEntityStatus>({
    getFn: EntityStatusService.getEntityStatusList,
    deleteFn: EntityStatusService.deleteEntityStatus,
    createFn: EntityStatusService.createEntityStatus,
    updateFn: EntityStatusService.updateEntityStatus,
    keywordColumns: 'name',
    filter: `entityStatusTypeId:${typeId}`,
    perPage: 10,
    sort: 'sortOrder:ASC',
  });

  useEffect(() => {
    getEntityCategoryList().then(cates => setCategories(cates));
  }, []);

  const { columns } = useColumnHooksUpgrade('entityStatus', ENTITY_STATUS_COLUMNS);

  const rows = useMemo(() => getRows(state.data, onOpenEditModal, onDelete, columns, categories), [
    categories,
    columns,
    onDelete,
    onOpenEditModal,
    state.data,
  ]);

  const getPageTile = () => {
    return (
      <PageTitle
        title={typeName}
        className={'space-above'}
        titleClassName={'large'}
        actionButtons={<WorkflowEditBtn appearance={'default'} entityStatusTypeId={typeId} />}
      />
    );
  };

  return (
    <div style={{ marginTop: '-2rem' }}>
      <FlexContainer className={'space-between'}>
        <ModuleEnabledWrapper checkedModule={ModuleCode.SETTINGS_STATUSES} exception={getPageTile()}>
          {getPageTile()}
        </ModuleEnabledWrapper>

        <div style={{ flexGrow: 0, marginTop: '1rem' }}>
          <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search status name...'} />
        </div>
      </FlexContainer>

      <TableContainer>
        <DndTable
          isLoading={state.isLoading}
          data={state.data}
          onReorder={onReorder}
          columns={columns}
          getRows={rows}
          contentColumnNumber={5}
        />
      </TableContainer>

      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={() => history.push(SETTINGS_WORKFLOW_EDIT_URL.replace(':id', typeId))}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        modalBody={<p>All creating, editing and deleting functionalities are moved to the workflow editing page</p>}
        modalHeading={'Feature moved.'}
        confirmBtnName={'Go to Workflow'}
        width={'small'}
      />

      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </div>
  );
};

export default EntityStatus;
