import React from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';

const EditDeleteButtonGroup = ({
  onEditClick,
  onDeleteClick,
  testIdPrefix,
}: {
  onEditClick: () => void;
  onDeleteClick: () => void;
  testIdPrefix?: string;
}) => {
  return (
    <ButtonGroup>
      <Button
        onClick={onEditClick}
        appearance="subtle-link"
        testId={testIdPrefix ? `${testIdPrefix}-edit-button` : 'edit-button'}
        spacing="compact"
        className={'edit-button'}
      >
        Edit
      </Button>
      <Button
        onClick={onDeleteClick}
        appearance="subtle-link"
        testId={
          testIdPrefix ? `${testIdPrefix}-delete-button` : 'delete-button'
        }
        spacing="compact"
        className={'delete-button'}
      >
        Delete
      </Button>
    </ButtonGroup>
  );
};

export default EditDeleteButtonGroup;
