import React from 'react';
import _ from 'lodash';
import { iLabelValuePair } from './types';
import ComposeSecondaryText from '../../shared/text/ComposeSecondaryText';

export const getTwoLayerOption = (label: string) => {
  return (
    <>
      <div style={{ fontWeight: 500 }}>{label.split(',')[0]}</div>
      <div style={{ fontSize: '0.7rem' }}>{label.split(',')[1]}</div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapLabelValuePair = (list: any[], attribute: string) => {
  return list.map(item => ({
    label: item[attribute],
    value: `${item.id}:${item[attribute]}`,
  }));
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapLabelValuePairObjPayload = (list: any[], attribute: string) => {
  return list.map(item => ({
    label: item[attribute],
    value: item,
  }));
};

export const mapLabelValuePairSameValue = (list: string[]) => {
  return list.map(item => ({
    label: item,
    value: item,
  }));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapLabelValuePairIdPayload = (list: any[], attribute: string) => {
  return list.map(item => ({
    label: item[attribute],
    value: item.id,
  }));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapLabelValuePairProduct = (list: any[], attributes: string[]) => {
  return list.map(item => {
    return {
      label: (
        <ComposeSecondaryText secondaryText={_.get(item, attributes[1])}>
          {_.get(item, attributes[0])}
        </ComposeSecondaryText>
      ),
      value: `${item.id}:${item[attributes[0]]}:${item[attributes[1]]}`,
    };
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapLabelValuePairObject = (list: any[], attributes: string[]) => {
  return list.map(item => {
    return {
      label: (
        <ComposeSecondaryText secondaryText={_.get(item, attributes[1])}>
          {_.get(item, attributes[0])}
        </ComposeSecondaryText>
      ),
      value: item,
    };
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createOptions = (list: any[]): iLabelValuePair[] => {
  return list.map(item => ({
    label: item.name,
    value: item.id,
  }));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createOption = (obj: any): iLabelValuePair => {
  return obj
    ? {
        label: obj.name,
        value: obj.id,
      }
    : {
        label: 'Select...',
        value: '',
      };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectContainsInvalidValues = (obj: any) => {
  return Object.values(obj).some(elem => !elem);
};

export default {
  createOption,
  createOptions,
  mapLabelValuePair,
  objectContainsInvalidValues,
  mapLabelValuePairSameValue,
};
