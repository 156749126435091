import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Label } from '@atlaskit/field-base';
import CustomizePagination from '../../../../../shared/pagination/CustomizePagination';
import SearchBar from '../../../../../shared/search/SearchBar';
import { iModalBodyData } from './CreateDNItem.types';
import { CreateTableWrapper } from './CreateSO.style';
import { getHeads } from '../../../../../services/UtilsService';
import { getRows } from './SharedTableHelper';
import {
  FlexContainer,
  SpaceVerticalContainer,
} from '../../../../../shared/styles/styles';

const ModalBodyRender = <T extends { id: string }>(
  props: iModalBodyData<T>,
) => {
  const {
    state,
    keyword,
    onSearch,
    renderSpecialContent,
    categories,
    onSetCurrentPage,
    columns,
    pageName,
    isLoading,
  } = props;
  return (
    <>
      <FlexContainer className={'space-between'}>
        <Label label="Select an item  to add to the Dispatch note:" />
        <div style={{ flexGrow: 0.1 }}>
          <SearchBar
            onSearch={onSearch}
            keyword={keyword}
            placeholder={'Search by name/code/ordernum...'}
          />
        </div>
      </FlexContainer>

      <CreateTableWrapper>
        <DynamicTable
          head={getHeads(columns, pageName)}
          rows={getRows({
            data: state.data,
            columns,
            categories,
            renderSpecialContent,
          })}
          testId={pageName}
          isLoading={isLoading}
          emptyView={<div>No Item available</div>}
        />
      </CreateTableWrapper>

      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
      <SpaceVerticalContainer className={'half'} />
    </>
  );
};

export default ModalBodyRender;
