/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import Button from '@atlaskit/button';
import StepDownload from './step1/StepDownload';
import StepRead from './step2/StepRead';
import StepSubmit from './step3/StepSubmit';
import { SpaceVerticalContainer } from '../../../../shared/styles/styles';
import { iPrefills, iTemplateColumn } from '../shared/DataImport.types';
import { getParametersByPageName } from '../shared/DataImport.util';
import { apiErrorToast } from '../../../../shared/toast/Toast';

type iState = {
  isLoading: boolean;
  data: any[][];
  isReset: boolean;
  flexColumns: Array<iTemplateColumn>;
};
const initialState: iState = {
  isLoading: true,
  data: [],
  isReset: false,
  flexColumns: [],
};
const GeneralImportSteps = ({
  detailPageName,
  prefills,
}: {
  detailPageName: string;
  prefills: iPrefills;
}) => {
  const [state, setState] = useState(initialState);
  const { columns, example, getFlexColumnsFn } = getParametersByPageName(
    detailPageName,
  );
  useEffect(() => {
    let isCancelled = false;
    const fetchFlexColumns = async () => {
      try {
        if (typeof getFlexColumnsFn !== 'function') {
          setState(prevState => ({ ...prevState, isLoading: false }));
          return;
        }
        const flexColumns = await getFlexColumnsFn();
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          flexColumns,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
      }
    };
    fetchFlexColumns();
    return () => {
      isCancelled = true;
    };
  }, [getFlexColumnsFn]);

  const handleDataRead = (result: any[][], reset?: boolean) =>
    reset
      ? setState({ ...state, isReset: true, data: [] })
      : setState({ ...state, data: result, isReset: false });

  return (
    <>
      <StepDownload
        detailPageName={detailPageName}
        columns={[...columns, ...state.flexColumns]}
        example={example}
      />
      <SpaceVerticalContainer className={'half'} />
      <StepRead
        handleDataRead={handleDataRead}
        isReset={state.isReset}
        columns={[...columns, ...state.flexColumns]}
      />
      <SpaceVerticalContainer className={'half'} />
      {state.data.length > 0 && (
        <StepSubmit
          data={state.data}
          detailPageName={detailPageName}
          prefills={prefills}
          columns={columns}
        >
          <Button
            appearance={'primary'}
            onClick={() => handleDataRead([], true)}
          >
            Import Another File
          </Button>
        </StepSubmit>
      )}
    </>
  );
};

export default GeneralImportSteps;
