import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { iConfigColumn } from '../../../UITypes/types';
import iContactCompanyProduct from '../../../../types/contactCompany/iContactCompanyProduct';
import { getHeads } from '../../../../services/UtilsService';
import { SERVICE_PROVIDER_TABLE_NAME } from './ServiceProvider.constants';
import { ServiceProviderTableWrapper } from './ServiceProvider.style';
import { getRows } from './TableHelper';

const ServiceProviderTable = ({
  data,
  isLoading,
  columns,
  jobId,
}: {
  data: Array<iContactCompanyProduct>;
  isLoading: boolean;
  columns: Array<iConfigColumn>;
  jobId: string;
}) => {
  return (
    <ServiceProviderTableWrapper>
      <DynamicTable
        head={getHeads(columns, SERVICE_PROVIDER_TABLE_NAME)}
        rows={getRows({
          data,
          columns,
          jobId,
        })}
        testId={SERVICE_PROVIDER_TABLE_NAME}
        isLoading={isLoading}
      />
    </ServiceProviderTableWrapper>
  );
};

export default ServiceProviderTable;
