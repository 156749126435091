import { iConfigColumn } from '../../../../../shared/UITypes/types';
import { JOB_URL, PRODUCTS_URL, SALES_URL } from '../../../../../shared/UrlMap';

export const SELECT_SO_ITEMS = 'select-soItem-for-dnItem';
export const SO_ITEM_CREATE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.id', 'product.name'],
    isCompulsory: true,
    type: 'link-secondary',
    urlPrefix: PRODUCTS_URL,
    group: '',
  },
  {
    name: 'SO',
    key: 'salesOrder.orderNumber',
    dataSource: ['salesOrder.id'],
    type: 'link',
    urlPrefix: SALES_URL,
    group: '',
  },
  {
    name: 'SO Status',
    key: 'salesOrder.status',
    dataSource: ['salesOrder.status'],
    type: 'status',
    isSelected: true,
    group: '',
  },
  {
    name: 'Customer order reference',
    key: 'salesOrder.customerRef',
    dataSource: [''],
    type: 'text',
    isSelected: true,
    group: '',
  },
  // {
  //   name: 'Unit Price',
  //   key: 'unitPrice',
  //   dataSource: [
  //     'product.measurement.shortName',
  //     'unitPriceMeasurement.shortName',
  //   ],
  //   type: 'unit-price',
  //   isSelected: true,
  //   group: '',
  // },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: [
      'unitConversion',
      'measurement.shortName',
      'product.measurement.shortName',
    ],
    type: 'conversion-text',
    isSelected: true,
    group: '',
  },
  // {
  //   name: 'Total(Inc GST)',
  //   key: 'totalPrice',
  //   dataSource: [],
  //   type: 'money',
  //   isSelected: true,
  //   group: '',
  // },
  {
    name: '',
    key: 'operation',
    dataSource: ['salesOrder.shippingAddress'],
    type: 'operation',
    isCompulsory: true,
    group: '',
  },
];

export const SELECT_JOBS = 'select-job-for-dnItem';
export const JOB_CREATE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Job',
    key: 'jobNumber',
    dataSource: [
      'id',
      'qtyToMake',
      'qtyTotal',
      'product.measurement.shortName',
    ],
    type: 'link-secondary-slash',
    urlPrefix: JOB_URL,
    group: '',
    isSortable: true,
  },
  {
    name: 'Job Status',
    key: 'status',
    dataSource: ['status'],
    type: 'job-status',
    isSelected: true,
    group: '',
  },
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.id', 'product.name'],
    isCompulsory: true,
    type: 'link-secondary',
    urlPrefix: PRODUCTS_URL,
    group: '',
  },
  {
    name: 'SO',
    key: 'salesOrder.orderNumber',
    dataSource: ['salesOrder.id'],
    type: 'link',
    urlPrefix: SALES_URL,
    group: '',
  },
  {
    name: 'SO Status',
    key: 'salesOrder.status',
    dataSource: ['salesOrder.status'],
    type: 'status',
    isSelected: true,
    group: '',
  },
  {
    name: 'Customer order reference',
    key: 'salesOrder.customerRef',
    dataSource: [''],
    type: 'text',
    isSelected: true,
    group: '',
  },
  // {
  //   name: 'Unit Price',
  //   key: 'salesOrderItem.unitPrice',
  //   dataSource: [
  //     'product.measurement.shortName',
  //     'salesOrderItem.unitPriceMeasurement.shortName',
  //   ],
  //   type: 'unit-price',
  //   isSelected: true,
  //   group: '',
  // },
  {
    name: 'Quantity',
    key: 'salesOrderItem.qty',
    dataSource: [
      'salesOrderItem.unitConversion',
      'salesOrderItem.measurement.shortName',
      'product.measurement.shortName',
    ],
    type: 'conversion-text',
    isSelected: true,
    group: '',
  },
  // {
  //   name: 'Total(Inc GST)',
  //   key: 'salesOrderItem.totalPrice',
  //   dataSource: [],
  //   type: 'money',
  //   isSelected: true,
  //   group: '',
  // },
  {
    name: '',
    key: 'operation',
    dataSource: ['salesOrder.shippingAddress'],
    type: 'operation',
    isCompulsory: true,
    group: '',
  },
];

export const DN_SHIPPING_WORDING = 'Dispatch Note shipping address:';
export const SO_SHIPPING_WORDING = 'Sales Order shipping address:';
export const SERVICE_PRODIVDER_SHIPPING =
  'The service provider shipping address';
