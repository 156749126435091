import React, { useState } from 'react';
import Textfield from '@atlaskit/textfield';
import { ErrorMessage, HelperMessage } from '@atlaskit/form';
import { Label } from '@atlaskit/field-base';
import iChannelScript, {
  CHANNEL_SCRIPT_SCRIPT_MYOB,
  CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE,
} from '../../../../types/integration/iChannelScript';

type iChannelCreationForm = {
  channelScript?: iChannelScript;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (fieldName: string, newValue: any) => void;
  setFormValid?: (isValid: boolean) => void;
};
const ChannelCreationForm = ({ channelScript, onChange, setFormValid }: iChannelCreationForm) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errors, setErrors] = useState<any>({});

  if (!channelScript) {
    return null;
  }

  const getErrorMsg = (errKey: string) => {
    if (!(errKey in errors)) {
      return null;
    }
    return <ErrorMessage>{errors[errKey]}</ErrorMessage>;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateStoreUrl = (event: any) => {
    const urlErrors: { storeUrl?: string } = {};
    const url = `${event.currentTarget?.value || ''}`.trim();
    if (url === '') {
      urlErrors.storeUrl = 'Store Url is required';
    }
    const expression = /^https?:\/\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    if (!url.match(regex)) {
      urlErrors.storeUrl = 'Please provide a valid url.';
    }

    if (Object.keys(urlErrors).length === 0 && setFormValid) {
      setFormValid(true);
    }
    setErrors(urlErrors);
  };

  if (channelScript.script === CHANNEL_SCRIPT_SCRIPT_MYOB) {
    return null;
  }

  if (channelScript.script === CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE) {
    return (
      <fieldset>
        <Label label={'Store URL'} />
        <Textfield
          name={'storeUrl'}
          placeholder={'https://your-wordpress-url.com'}
          onChange={evt => onChange && onChange('storeUrl', evt.currentTarget.value)}
          onBlur={validateStoreUrl}
        />
        <HelperMessage>The url of your wordpress base url, eg: https://your-wordpress-url.com.</HelperMessage>
        {getErrorMsg('storeUrl')}
      </fieldset>
    );
  }
  return null;
};

export default ChannelCreationForm;
