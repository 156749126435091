import AppService from './AppService';
import { iParams } from '../shared/UITypes/types';

export const getEntityCategoryList = (config?: iParams) => {
  return AppService.get('/entityStatusCategory', {
    ...config,
    include: 'entityStatuses.entityStatusType',
  }).then(({ data }) => data);
};

export default {
  getEntityCategoryList,
};
