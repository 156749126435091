import React, { useEffect, useState } from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import { LoadingButton } from '@atlaskit/button';
import styled from 'styled-components';
import { Label } from '@atlaskit/field-base';
import BackendPage from '../../../layout/BackendPage';
import { FlexContainer, PageTitle } from '../../../shared/styles/styles';
import MultiSelect from '../../../shared/form/MultiSelect';
import { iLabelValuePair } from '../../../shared/UITypes/types';
import ContactCompanyService from '../../../services/ContactCompanyService';
import { getProductListAsyncSearch } from '../../../services/product/ProductService';
import DatePickerWithoutReadview from '../../../shared/form/DatePickerWithoutReadview';
import ResetBtn from '../../../shared/buttons/ResetBtn';
import { getExportReportName, mapOptionToValue } from '../../../services/UtilsService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { getPurchaseOrderItems } from '../../../services/PurchaseOrderItemService';
import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../../../shared/constants/AsyncConstants';
import iPurchaseOrderItem from '../../../types/purchases/iPurchaseOrderItem';
import { iPagination } from '../../../types/iPagination';
import PurchaseOrderItemsTable from './PurchaseOrderItemsTable';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { getEntityCategoryList } from '../../../services/CategoryService';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import ExportReport from '../../../shared/exportReport/ExportReport';
import { getReportArrSegments, purchaseHistoryColumns } from './Helper/TableHelper';
import PaginationDetail from '../../../shared/pagination/paginationDetail/PaginationDetail';
import useColumnHooksUpgrade from '../../../shared/hooks/useShowHideColumnHook/useColumnHook';

const HeaderWrapper = styled.div`
  .date-from-and-to {
    width: 100%;
    display: flex;
    > div {
      flex: 1;
    }
  }
`;

const PurchaseHistoryReport = () => {
  const [data, setData] = useState<(iPagination & { data: iPurchaseOrderItem[] }) | null>(null);
  const [statusCategories, setStatusCategories] = useState<iEntityCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [supplierIds, setSupplierIds] = useState<iLabelValuePair[]>([]);
  const [productIds, setProductIds] = useState<iLabelValuePair[]>([]);
  const [dateFrom, setDateFrom] = useState<string | undefined>(undefined);
  const [dateTo, setDateTo] = useState<string | undefined>(undefined);
  const { columns, getShowHideColumns } = useColumnHooksUpgrade('purchaseHistory', purchaseHistoryColumns);

  useEffect(() => {
    if (statusCategories.length > 0) {
      return;
    }
    setIsLoading(true);
    Promise.all([getEntityCategoryList()])
      .then(resp => {
        setStatusCategories(resp[0]);
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [statusCategories]);

  const onMultiSelect = (name: string, newValues: iLabelValuePair[]) => {
    if (!name) return;

    switch (name) {
      case 'supplierIds': {
        setSupplierIds(newValues);
        setData(null);
        break;
      }
      case 'productIds': {
        setProductIds(newValues);
        setData(null);
        break;
      }
      default: {
        break;
      }
    }
  };

  const onPickDate = (name: string, newValues?: string) => {
    if (!name) return;
    if (name === 'dateFrom') {
      setDateFrom(newValues);
    }
    if (name === 'dateTo') {
      setDateTo(newValues);
    }
    setData(null);
  };

  const getSearchFn = (pageNo: string) => {
    const purchaseDatesStrs = [];
    if (`${dateFrom || ''}`.trim() !== '') {
      purchaseDatesStrs.push(`purchaseOrder.orderDate>=${dateFrom}`);
    }
    if (`${dateTo || ''}`.trim() !== '') {
      purchaseDatesStrs.push(`purchaseOrder.orderDate<=${dateTo}`);
    }
    const productIdsStr = mapOptionToValue(productIds).join('|');
    const supplierIdsStr = mapOptionToValue(supplierIds).join('|');
    const filters = [];
    if (purchaseDatesStrs.length > 0) {
      filters.push(purchaseDatesStrs.join(','));
    }
    if (productIdsStr.trim() !== '') {
      filters.push(`productId:${productIdsStr}`);
    }
    if (supplierIdsStr.trim() !== '') {
      filters.push(`purchaseOrder.supplierId:${supplierIdsStr}`);
    }
    return getPurchaseOrderItems({
      perPage: ASYNC_SEARCH_PER_PAGE,
      currentPage: pageNo,
      filter: filters.join(','),
      sort: 'updatedAt:desc',
    });
  };

  const onSearch = (pageNo = ASYNC_SEARCH_CURRENT_PAGE) => {
    setIsLoading(true);
    getSearchFn(pageNo)
      .then(resp => {
        setData(resp);
      })
      .catch(error => {
        apiErrorToast(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onReset = () => {
    setData(null);
    setProductIds([]);
    setSupplierIds([]);
    setDateFrom(undefined);
    setDateTo(undefined);
  };

  const getExportBtn = () => {
    if (data === null) {
      return null;
    }
    return (
      <ExportReport
        perPage={data.perPage}
        total={data.total}
        getFn={(currentPage: number) => getSearchFn(`${currentPage}`)}
        onFormatReport={(poItems: iPurchaseOrderItem[]) =>
          getReportArrSegments(
            poItems,
            columns.map(column => ({
              ...column,
              ...(column.type.trim().toLowerCase() === 'date' ? { dataSource: ['DD/MMM/YYYY'] } : {}),
            })),
          )
        }
        reportFileName={getExportReportName('PurchaseHistory')}
      />
    );
  };

  const getSearchPanel = () => {
    return (
      <Grid layout={'fluid'} spacing={'cosy'}>
        <GridColumn medium={3}>
          <Label label={'PO order date:'} />
          <div className={'date-from-and-to'}>
            <DatePickerWithoutReadview
              name={'dateFrom'}
              dateFormat="DD MMM YYYY"
              defaultValue={dateFrom || ''}
              onConfirm={onPickDate}
              placeholder={'From'}
              testId={'po-orderDate-from'}
            />
            <DatePickerWithoutReadview
              name={'dateTo'}
              dateFormat="DD MMM YYYY"
              defaultValue={dateTo || ''}
              onConfirm={onPickDate}
              placeholder={'To'}
              testId={'po-orderDate-to'}
            />
          </div>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label={'Suppliers'} />
          <MultiSelect
            defaultValue={supplierIds}
            name={'supplierIds'}
            onConfirm={onMultiSelect}
            placeholder={'Search Suppliers...'}
            promiseFn={ContactCompanyService.getSuppliers}
            optionLabel={'name'}
            sortBy={'name'}
          />
        </GridColumn>
        <GridColumn medium={4}>
          <Label label={'Products'} />
          <MultiSelect
            defaultValue={productIds}
            name={'productIds'}
            onConfirm={onMultiSelect}
            placeholder={'Search Products by sku, name'}
            promiseFn={async options => {
              if (!options || `${options.like || ''}`.trim() === '') {
                return getProductListAsyncSearch;
              }
              const [, keyword] = options.like.split(':');
              return getProductListAsyncSearch({ like: `productCode:${keyword},name:${keyword}` });
            }}
            optionLabel={'name'}
            sortBy={'name'}
          />
        </GridColumn>
        <GridColumn medium={2}>
          <FlexContainer style={{ marginTop: '45px' }}>
            <LoadingButton onClick={() => onSearch()} appearance={'primary'} isLoading={isLoading}>
              Search
            </LoadingButton>
            <ResetBtn onClick={onReset} />
            {getExportBtn()}
          </FlexContainer>
        </GridColumn>
      </Grid>
    );
  };

  const resultSummaryPanel = () => {
    return (
      <FlexContainer className={'space-between space-above'}>
        {!!data?.total && !!data?.from && !!data?.to ? (
          <PaginationDetail from={data?.from} to={data?.to} total={data?.total} onRefreshResults={() => onSearch()} />
        ) : (
          <div />
        )}
        <div
          style={{
            fontSize: '0.8rem',
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: 0.02,
          }}
        >
          {getShowHideColumns()}
        </div>
      </FlexContainer>
    );
  };

  const getPageHeader = () => {
    return (
      <HeaderWrapper>
        <FlexContainer className={'space-between'}>
          <FlexContainer>
            <PageTitle>Purchase History</PageTitle>
          </FlexContainer>
          <div />
        </FlexContainer>
        <div>{getSearchPanel()}</div>
        {resultSummaryPanel()}
      </HeaderWrapper>
    );
  };

  const getBody = () => {
    if (statusCategories.length <= 0) {
      return null;
    }
    return (
      <div className={'result-wrapper'}>
        <PurchaseOrderItemsTable
          data={data?.data || []}
          isLoading={isLoading}
          statusCategories={statusCategories}
          selectedColumns={columns}
        />
        {!!data?.total && !!data?.perPage && !!data?.currentPage && (
          <CustomizePagination
            range={Math.ceil(data?.total / data?.perPage)}
            currentPage={data.currentPage}
            onChange={(pageNo: number) => onSearch(`${pageNo}`)}
          />
        )}
      </div>
    );
  };

  return <BackendPage pageHeader={getPageHeader()}>{getBody()}</BackendPage>;
};

export default PurchaseHistoryReport;
