import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  createProdAttribute,
  deleteProdAttribute,
  getProdAttributes,
  updateProdAttribute,
} from '../../../services/product/ProductAttributeService';
import iProductAttribute from '../../../types/product/iProductAttribute';
import { PRODUCT_ATTRIBUTES, PRODUCT_ATTRIBUTE_COLUMNS } from '../constants';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import PageTitleWithAddOne from '../../../shared/heading/PageTitleWithAddOne';
import { FlexContainer } from '../../../shared/styles/styles';
import SearchBar from '../../../shared/search/SearchBar';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import ProdAttributeEditModal from './ProdAttributeEditModal';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import DndTable from '../../../shared/dndTable/DndTable';
import useColumnHooksUpgrade from '../../../shared/hooks/useShowHideColumnHook/useColumnHook';
import { getRows } from './ProdAttributeList.tableHelper';
import { iConfigColumn } from '../../../shared/UITypes/types';
import DataTable from '../../../shared/tableHelper/DataTable';

const TableContainer = styled.div`
  margin-top: 2rem;

  th.name {
    width: 15rem;
    min-width: 7rem;
  }

  th.description {
    width: 15rem;
    min-width: 7rem;
  }
  th.type {
    width: 10rem;
    min-width: 6rem;
  }
  th.options {
    width: 30rem;
    min-width: 8rem;
  }

  th.createdAt {
    min-width: 10rem;
  }

  th.updatedAt {
    min-width: 10rem;
  }

  th.operation {
    min-width: 1rem;
  }
`;

const ProdAttribute = ({
  setId,
  canCreate = true,
  canUpdate = true,
  canDelete = true,
}: {
  setId: string;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
}) => {
  const { control, setValue, errors, handleSubmit, watch } = useForm();

  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onSearch,
    onSubmit,
    onReorder,
    onSetCurrentPage,
    onOpenEditModal,
  } = useListCrudHook<iProductAttribute>({
    getFn: getProdAttributes,
    deleteFn: deleteProdAttribute,
    createFn: createProdAttribute,
    updateFn: updateProdAttribute,
    keywordColumns: 'name',
    filter: `attributeSetId:${setId}`,
    perPage: 10,
    sort: 'sortOrder:ASC',
  });

  const { columns } = useColumnHooksUpgrade('productAttribute', PRODUCT_ATTRIBUTE_COLUMNS);

  const onCreateOrUpdateAttribute = (data: { [key: string]: string }) => {
    onSubmit({
      ...data,
      attributeSetId: setId,
      name: data.name,
      description: data.description,
      type: data.type,
      options: data.type === 'select' ? JSON.stringify(data.options) : '',
      sortOrder: 0,
    });
  };

  const getFilteredColumns = (cols: iConfigColumn[]) => {
    return cols.filter(col => {
      if (col.key === 'dragHandlerIcon' && !canUpdate) {
        return false;
      }
      if (col.key === 'operation' && !canDelete) {
        return false;
      }
      return true;
    });
  };

  return (
    <>
      <FlexContainer className={'space-between'}>
        <PageTitleWithAddOne
          title={PRODUCT_ATTRIBUTES}
          onClickAddBtn={onOpenAddModal}
          className={'space-above'}
          classNameTitle={'medium'}
          isCustomizedTitle
          isDisabled={!canCreate}
        />
        <div style={{ flexGrow: 0 }}>
          <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search attribute name...'} />
        </div>
      </FlexContainer>

      <TableContainer>
        {canUpdate ? (
          <DndTable
            isLoading={state.isLoading}
            data={state.data}
            onReorder={onReorder}
            columns={getFilteredColumns(columns)}
            getRows={getRows(state.data, onOpenEditModal, onDelete, getFilteredColumns(columns))}
            contentColumnNumber={6}
          />
        ) : (
          <DataTable
            rows={getRows(
              state.data,
              onOpenEditModal,
              onDelete,
              getFilteredColumns(columns).map(col => {
                if (col.key === 'name' && !canUpdate) {
                  return {
                    ...col,
                    type: 'text',
                  };
                }
                return col;
              }),
            )}
            head={{ cells: getFilteredColumns(columns).map(col => ({ content: col.name, key: col.key })) }}
          />
        )}
      </TableContainer>

      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onCreateOrUpdateAttribute)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <ProdAttributeEditModal
            control={control}
            errors={errors}
            onChange={setValue}
            target={edit.target}
            watch={watch}
          />
        }
        modalHeading={edit.target ? edit.target.name : 'Create an Attribute'}
        confirmBtnName={edit.target ? 'Save' : 'Create'}
        width={'large'}
        height={'40vh'}
        shouldScrollInViewport
      />

      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );
};

export default ProdAttribute;
