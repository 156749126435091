import React from 'react';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import _ from 'lodash';
import ConCalReadEditView from './ConCalReadEditView';
import IndenpendentLabel from '../form/hookForms/IndenpendentLabel';
import { ErrorMsg } from '../styles/styles';
import { NUMBER_ROUND_DECIMAL } from '../constants/ActionConstants';

const ConversionCalculateController = ({
  label,
  name,
  control,
  defaultValue,
  isDisabled = false,
  testId = 'input-react-hook-form',
  errors,
  isRequired = true,
  min,
  productUnit,
  contactCompanyUnit,
  conversion,
}: {
  label: string;
  name: string;
  //  eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: string | number;
  isDisabled?: boolean;
  testId?: string;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  isRequired?: boolean;
  min?: number;
  conversion: number;
  productUnit: string;
  contactCompanyUnit: string;
}) => {
  return (
    <>
      <IndenpendentLabel
        label={label}
        htmlFor={testId}
        isRequired={isRequired}
      />
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        rules={{
          required: {
            value: isRequired,
            message: 'is Required',
          },
          min: {
            value: min || 0,
            message: `must greater than ${min}`,
          },
        }}
        render={({ onChange, value, onBlur }) => (
          <ConCalReadEditView
            onChange={onChange}
            value={value}
            productUnit={productUnit}
            contactCompanyUnit={contactCompanyUnit}
            conversion={conversion}
            isDisabled={isDisabled}
            name={name}
            onBlur={() => {
              onChange(_.round(value, NUMBER_ROUND_DECIMAL));
              onBlur();
            }}
          />
        )}
      />
      {_.get(errors, name) && (
        <ErrorMsg>{_.get(errors, name).message}</ErrorMsg>
      )}
    </>
  );
};

export default ConversionCalculateController;
