import React from 'react';
import Document48Icon from '@atlaskit/icon-file-type/glyph/document/48';
import { SpaceVerticalContainer } from '../../../../../shared/styles/styles';
import { READ_DES, READ_TITLE } from '../../shared/DataImport.constants';

const ReadFrame = () => {
  return (
    <div className={'reader-trigger'}>
      <Document48Icon label={'file'} />
      <SpaceVerticalContainer className={'half'} />
      <div className={'description'}>{READ_TITLE}</div>
      <div className={'size'}>{READ_DES}</div>
    </div>
  );
};

export default ReadFrame;
