import React, { useState } from 'react';
import Button, { LoadingButton } from '@atlaskit/button';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import RenderForm from '../shared/RenderForm';
import { generateInitialValues } from '../shared/Form.utils';
import { login } from '../../../services/AuthService';
import { addToastForAPIResponse } from '../../../shared/toast/Toast';
import { FlexContainer, SpaceVerticalContainer } from '../../../shared/styles/styles';
import { CREATE_ACCOUNT, CREATE_ACCOUNT_PRE, FORGOT_PASSWORD, USER_LOGIN_FIELDS } from '../shared/Auth.constants';
import { AUTH_RETRIEVAL_URL, AUTH_SIGN_UP_URL, BACK_END_URL } from '../../../shared/UrlMap';
import {isEnterpriseMode} from '../../../services/UtilsService';

type iState = {
  isLoading: boolean;
};
const initialState: iState = {
  isLoading: false,
};
const LoginFormWithForgot = () => {
  const history = useHistory();
  const [state, setState] = useState(initialState);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: generateInitialValues(USER_LOGIN_FIELDS),
    reValidateMode: 'onBlur',
  });

  //    eslint-disable-next-line
  const onSubmit = (data: any) => {
    setState({
      ...state,
      isLoading: true,
    });
    login(data.username, data.password)
      .then(() => {
        history.push(BACK_END_URL);
      })
      .catch(() => {
        addToastForAPIResponse('error', 'Incorrect Credential');
        setState({
          ...state,
          isLoading: false,
        });
      });
  };
  return (
    <>
      <RenderForm control={control} fields={USER_LOGIN_FIELDS} errors={errors} />
      <SpaceVerticalContainer className={'quarter'} />

      <FlexContainer className={'space-between'}>
        <div />
        <Button appearance="subtle-link" onClick={() => history.push(AUTH_RETRIEVAL_URL)}>
          {FORGOT_PASSWORD}
        </Button>
      </FlexContainer>
      <SpaceVerticalContainer className={'quarter'} />
      <LoadingButton
        appearance={'primary'}
        isLoading={state.isLoading}
        shouldFitContainer
        onClick={handleSubmit(onSubmit)}
      >
        Login
      </LoadingButton>
      {isEnterpriseMode() === true ? null : (
        <>
          <SpaceVerticalContainer className={'quarter'} />
          <FlexContainer className={'space-between'}>
            <div>{CREATE_ACCOUNT_PRE}</div>
            <Button appearance="subtle-link" onClick={() => history.push(AUTH_SIGN_UP_URL)}>
              {CREATE_ACCOUNT}
            </Button>
          </FlexContainer>
        </>
      )}
    </>
  );
};

export default LoginFormWithForgot;
