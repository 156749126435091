import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import iProductImage from '../../types/product/iProductImage';
import DeleteByReType from '../deleteWithReType/DeleteByReType';

const Wrapper = styled.div`
  position: relative;
  background-color: lightgray;
  img {
    object-fit: contain;
    object-position: center center;
    width: 100%;
    height: 20rem;
  }
  .buttons {
    display: inline-block;
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
  }
`;

type iProductImageViewer = {
  productImage: iProductImage;
  onDelete?: (imageId: string) => Promise<void>;
  canDelete?: boolean;
};
const ProductImageViewer = ({ productImage, onDelete, canDelete = true }: iProductImageViewer) => {
  const [isShowingDeletePanel, setIsShowingDeletePanel] = useState(false);

  const handleConfirmDelete = () => {
    if (!onDelete) {
      return;
    }
    setIsShowingDeletePanel(false);
    onDelete(productImage.id);
  };

  const getDeletingPopup = () => {
    if (isShowingDeletePanel !== true) {
      return null;
    }
    return (
      <DeleteByReType
        isOpen={isShowingDeletePanel}
        onClose={() => setIsShowingDeletePanel(false)}
        onConfirm={() => handleConfirmDelete()}
      />
    );
  };

  const getDeleteBtn = () => {
    if (!onDelete || !canDelete) {
      return null;
    }
    return (
      <div className={'buttons'}>
        <Button
          appearance={'danger'}
          iconBefore={<TrashIcon label={'delete'} />}
          onClick={() => setIsShowingDeletePanel(true)}
        />
      </div>
    );
  };
  return (
    <Wrapper className={'image-viewer'}>
      {getDeleteBtn()}
      <img src={productImage.url} alt={'error'} data-testid={'img'} />
      {getDeletingPopup()}
    </Wrapper>
  );
};

export default ProductImageViewer;
