import React, { useEffect, useState } from 'react';
import SectionMessage from '@atlaskit/section-message';
import { LoadingButton } from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import iChannel from '../../../../types/integration/iChannel';
import iProduct from '../../../../types/product/iProduct';
import { iChannelEntity } from '../../../../types/integration/iChannelEntity';
import WooCommerceConnectorService from '../../../../services/integration/connectors/WooCommerceConnectorService';
import { SpaceVerticalContainer } from '../../../styles/styles';
import { apiErrorToast } from '../../../toast/Toast';
import iWooCommerceProduct from '../types/WooCommerceProductType';
import ChannelEntityService from '../../../../services/integration/ChannelEntityService';
import WooCommerceSynBtn from './WooCommerceSynBtn';

type iWooCommerceProductCreatePanel = {
  channel: iChannel;
  product: iProduct;
  onSaved: (channelEntity: iChannelEntity) => void;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WooCommerceProductCreatePanel = ({ channel, product, onSaved }: iWooCommerceProductCreatePanel) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [channelProductWithSameSku, setChannelProductWithSameSku] = useState<iWooCommerceProduct | undefined>(
    undefined,
  );

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    WooCommerceConnectorService.getProducts(channel.id, {
      sku: product.productCode,
      status: 'publish',
    })
      .then(resp => {
        if (isCanceled) return;
        if (resp.length > 0) {
          setChannelProductWithSameSku(
            resp.sort(
              (prod1: iWooCommerceProduct, prod2: iWooCommerceProduct) => prod1.date_created > prod2.date_created,
            )[0],
          );
        }
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [channel, product]);

  const linkProduct = () => {
    if (!channelProductWithSameSku) {
      return;
    }
    setIsSaving(true);
    ChannelEntityService.createChannelEntity({
      channelId: channel.id,
      externalId: `${channelProductWithSameSku.id}`,
      entityName: 'Product',
      entityId: product.id,
      externalObj: channelProductWithSameSku,
    })
      .then(resp => {
        setIsSaving(false);
        onSaved(resp);
      })
      .catch(err => {
        setIsSaving(false);
        apiErrorToast(err);
      });
  };

  const getContent = () => {
    if (channelProductWithSameSku) {
      return (
        <>
          <SectionMessage
            title={`Can not find any info for: ${product.productCode}`}
            appearance="warning"
            testId={'link-product-msg'}
          >
            <p>
              You can start linking the Product info from <b>{channel.name}</b> <br />
              with the same Product Code / SKU (<b>{product.productCode}</b>).
            </p>
            <LoadingButton appearance={'link'} onClick={() => linkProduct()} isLoading={isSaving} testId={'link-btn'}>
              Link Product
            </LoadingButton>
          </SectionMessage>
          <SpaceVerticalContainer />
        </>
      );
    }
    return (
      <>
        <SectionMessage title={`Can not find any info for: ${product.productCode}`} appearance="warning">
          <p data-testid={'creating-msg'}>
            You can start creating a product in <b>{channel.name}</b>.
          </p>
          <WooCommerceSynBtn
            btnName={'Create Product'}
            modalParam={{
              shouldScrollInViewport: true,
              modalBody: (
                <>
                  <Spinner />
                </>
              ),
              modalHeading: `Creating product under ${channel.name}`,
              cancelBtnName: 'Close',
            }}
            btnParams={{
              appearance: 'link',
            }}
            channelJobCreationFn={() => WooCommerceConnectorService.createProduct(channel.id, product.id)}
            onSaved={onSaved}
          />
        </SectionMessage>
        <SpaceVerticalContainer />
      </>
    );
  };

  if (isLoading) {
    return <Spinner />;
  }

  return <div>{getContent()}</div>;
};

export default WooCommerceProductCreatePanel;
