import iBaseType from '../iBaseType';
// eslint-disable-next-line import/no-cycle
import iEntityStatus from './iEntityStatus';

export const CATEGORY_CODE_NEW = 'STATUS_CATEGORY_NEW';
export const CATEGORY_CODE_WIP = 'STATUS_CATEGORY_IN_PROGRESS';
export const CATEGORY_CODE_FINISHED = 'STATUS_CATEGORY_FINISHED';

type iEntityCategory = iBaseType & {
  code: string;
  name: string;
  entityStatuses: Array<iEntityStatus>;
};

export default iEntityCategory;
