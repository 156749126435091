import React from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';

import { useSelector } from 'react-redux';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import { SETTINGS_ENTITY_STATUS_TYPES_URL } from '../../../shared/UrlMap';
import iEntityStatusType from '../../../types/status/iEntityStatusType';
import ModulingService from '../../../services/ModulingService';
import { RootState } from '../../../redux/makeReduxStore';
import { ModuleCode } from '../../../types/iModuleCode';

const TableContainer = styled.div``;

const EntityStatusTypeListTable = ({ data, isLoading }: { data: Array<iEntityStatusType>; isLoading: boolean }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const moduleEnabledData = ModulingService.filterListByModule({
    list: data,
    checkedModule: ModuleCode.PURCHASE_AO,
    companyModules: user?.companyModules || [],
    filterFn: (item: iEntityStatusType) => item.entityName.toUpperCase() !== 'AcquisitionOrder'.toUpperCase(),
  });

  const getRows = () => {
    return moduleEnabledData
      ?.filter((item: iEntityStatusType) => item.entityName.toUpperCase() !== 'PurchaseOrderReceiving'.toUpperCase())
      .sort((a, b) => (a.entityName > b.entityName ? 1 : -1))
      .map((item: iEntityStatusType) => ({
        cells: [
          {
            key: item.entityName,
            content: <LinkBtnUrl btnName={item.entityName} url={`${SETTINGS_ENTITY_STATUS_TYPES_URL}/${item.id}`} />,
          },
        ],
      }));
  };

  return (
    <TableContainer>
      <DynamicTable
        head={{
          cells: [
            {
              key: 'entityName',
              testId: 'settings-entity-status-type-list-table-column-entityName',
              content: 'Type Name',
              isSortable: true,
            },
          ],
        }}
        rows={getRows()}
        testId={'settings-entity-status-type-list-table'}
        isLoading={isLoading}
        rowsPerPage={10}
        defaultPage={1}
      />
    </TableContainer>
  );
};

export default EntityStatusTypeListTable;
