import { iConfigColumn } from '../../../shared/UITypes/types';
import iContactCompanyRole from '../../../types/contactCompany/iContactCompanyRole';
import iContactCompanyType from '../../../types/contactCompany/iContactCompanyType';

export const generateFlexColumns = (
  types: Array<iContactCompanyType>,
  typeName: string,
) => {
  const targetContCompType = types.find(
    (type: iContactCompanyType) => type.name.toLowerCase() === typeName,
  );
  if (
    typeof targetContCompType === 'undefined' ||
    typeof targetContCompType.roles === 'undefined' ||
    targetContCompType.roles.length === 0
  )
    return [];
  return targetContCompType.roles.map((role: iContactCompanyRole) => ({
    name: role.name,
    key: role.id,
    dataSource: ['contactCompanyContacts', 'contact.name'],
    type: 'flexColumn',
    isSelected: true,
    group: '',
  }));
};

export const insertFlexToColumns = (
  inserts: Array<iConfigColumn>,
  columns: Array<iConfigColumn>,
  insertPosition: number,
) => {
  const cloneColumns = [...columns];
  cloneColumns.splice(insertPosition, 0, ...inserts);
  return cloneColumns;
};
