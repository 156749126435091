import React from 'react';
import styled from 'styled-components';
import Select, { ValueType } from '@atlaskit/select';
import { iLabelValuePair } from '../../../shared/UITypes/types';

type iSelectProps = {
  minWidth: number;
};
const SelectWrapper = styled.div`
  min-width: ${(props: iSelectProps) => props.minWidth}px;
`;
const Taxbase = ({
  name,
  value,
  options,
  onChange,
  minWidth = 200,
  isDisabled,
}: {
  name: string;
  value?: string;
  options: Array<iLabelValuePair>;
  onChange: (name: string, value: string) => void;
  minWidth?: number;
  isDisabled?: boolean;
}) => {
  const onChangeMiddleWare = (newValue: ValueType<iLabelValuePair>) => {
    if (!newValue) return;
    const pair = newValue as iLabelValuePair;
    if (pair.value === value) return;
    onChange(name, pair.value);
  };

  return (
    <SelectWrapper minWidth={minWidth}>
      <Select<iLabelValuePair>
        openMenuOnFocus
        options={options}
        onChange={onChangeMiddleWare}
        isMulti={false}
        value={options.find((option: iLabelValuePair) => option.value === value)}
        isDisabled={isDisabled}
      />
    </SelectWrapper>
  );
};

export default Taxbase;
