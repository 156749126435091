import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@atlaskit/button';
import { FlexContainer } from '../../../shared/styles/styles';
import { AUTH_URL_LOGIN } from '../../../shared/UrlMap';
import { BACK_LOGIN } from './Auth.constants';

const BackToLogin = () => {
  const history = useHistory();
  return (
    <FlexContainer className={'space-between'} data-testid={'flex-container'}>
      <div />
      <Button
        appearance="subtle-link"
        onClick={() => history.push(AUTH_URL_LOGIN)}
        testId={'back-login'}
      >
        {BACK_LOGIN}
      </Button>
    </FlexContainer>
  );
};

export default BackToLogin;
