import React from 'react';
import styled from 'styled-components';
import { ActionMeta, PopupSelect, ValueType } from '@atlaskit/select';
import Button from '@atlaskit/button';
import { UNSCHEDULED_SORT_ITEMS } from '../shared/Schedule.constant';
import { iSortOrderType } from '../../../../shared/UITypes/types';
import { FlexContainer } from '../../../../shared/styles/styles';
import { addToastForAPIResponse } from '../../../../shared/toast/Toast';
import { iUnscheSortOption, iUnSortState } from '../shared/Schedule.type';

const ButtonWrapper = styled.div`
  width: 100%;
  .sort-by-button {
    text-align: left;
  }
`;
const SpanWrapper = styled.span`
  height: 32px;
  width: 24px;
  position: relative;
  background: rgba(9, 30, 66, 0.04);
  &:hover {
    background: rgba(9, 30, 66, 0.08);
    cursor: pointer;
    ::before {
      border-bottom: 5px solid rgb(179, 186, 197);
    }
    ::after {
      border-top: 5px solid rgb(179, 186, 197);
    }
  }
  &::before {
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgb(223, 225, 230);
    display: block;
    height: 0;
    position: absolute;
    right: 8px;
    width: 0;
    bottom: 17px;
    content: ' ';
  }
  &::after {
    border-width: 5px;
    border-style: solid;
    border-color: rgb(223, 225, 230) transparent transparent;
    display: block;
    height: 0;
    position: absolute;
    right: 8px;
    width: 0;
    top: 17px;
    content: ' ';
  }
  &.ASC::before {
    border-color: transparent transparent rgb(94, 108, 132);
  }
  &.DESC::after {
    border-color: rgb(94, 108, 132) transparent transparent;
  }
`;
const SORT_BY_TITLE = 'Sort by:';

const UnScheduleSort = ({
  sortKey,
  sortOrder,
  onSetSort,
}: {
  sortKey?: iUnscheSortOption;
  sortOrder: iSortOrderType;
  onSetSort: (data: iUnSortState) => void;
}) => {
  const onChangeSortKey = async (
    option: ValueType<iUnscheSortOption>,
    //  eslint-disable-next-line
    action: ActionMeta<iUnscheSortOption>,
  ) => {
    //    value is null/undefined
    if (!option) return;
    const convert = option as iUnscheSortOption;
    onSetSort({ sortKey: convert });
  };

  const onSetSortOrder = () => {
    if (!sortKey) {
      addToastForAPIResponse('error', 'pls select what you want to sort');
      return;
    }
    const newOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    onSetSort({ sortOrder: newOrder });
  };

  return (
    <FlexContainer className={'space-between'}>
      <PopupSelect
        options={UNSCHEDULED_SORT_ITEMS.filter(
          (item: iUnscheSortOption) => item.value !== sortKey?.value,
        )}
        onChange={onChangeSortKey}
        target={({ ref }) => (
          <ButtonWrapper>
            <Button
              testId="popup-trigger-btn"
              ref={ref}
              shouldFitContainer
              className={'sort-by-button'}
            >
              {SORT_BY_TITLE}
              {
                UNSCHEDULED_SORT_ITEMS.find(
                  (item: iUnscheSortOption) => item.value === sortKey?.value,
                )?.label
              }
            </Button>
          </ButtonWrapper>
        )}
      />
      <SpanWrapper
        className={`arrows ${sortOrder}`}
        onClick={onSetSortOrder}
        data-testid={'sort-order'}
      />
    </FlexContainer>
  );
};

export default UnScheduleSort;
