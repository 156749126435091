import React, { useState, useEffect } from 'react';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import { TimePicker } from '@atlaskit/datetime-picker';
import _ from 'lodash';

import { Field } from '@atlaskit/form';
import { timeSlot } from '../../helperFn';
import { ErrorMsg } from '../../styles/styles';
import { TIME_PLACEHOLDER } from '../../constants/TimePlaceholderConstants';

type iState = {
  value: string;
};
const initialState: iState = {
  value: '',
};
const TimePickerWithController = ({
  label,
  name,
  control,
  defaultValue,
  onChange,
  isRequired = true,
  errors,
  testId = 'time-picker-react-hook-form',
  placeholder = TIME_PLACEHOLDER,
}: {
  label: string;
  name: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: string;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  isRequired?: boolean;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  testId?: string;
  placeholder?: string;
}) => {
  const [state, setState] = useState(initialState);
  const handleValueChange = (newValue: string) => {
    setState({ ...state, value: newValue });
    onChange(name, newValue, { shouldValidate: true });
  };
  useEffect(() => {
    setState(preState => ({
      ...preState,
      value: defaultValue?.slice(0, -3) || '',
    }));
  }, [defaultValue]);

  const validateInput = (newValue: string) => {
    if (isRequired && !newValue) {
      return false;
    }
    return true;
  };

  const getErrorMessage = () => {
    if (isRequired) {
      return 'is required';
    }

    return undefined;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue?.slice(0, -3) || state.value}
      rules={{
        validate: newValue => validateInput(newValue),
      }}
      render={ctrlProps => (
        <Field
          key={name}
          name={name}
          label={
            isRequired ? (
              <div>
                {label}
                <span style={{ color: 'red' }}>*</span>
              </div>
            ) : (
              label
            )
          }
        >
          {({ fieldProps }) => (
            <>
              {/* <CustomizedLabel label={label} /> */}
              <TimePicker
                // eslint-disable-next-line
                {...ctrlProps}
                // eslint-disable-next-line
                {...fieldProps}
                onChange={handleValueChange}
                value={state.value}
                parseInputValue={() => state.value}
                timeFormat="HH:mm"
                testId={testId}
                isInvalid={_.get(errors, name)}
                times={timeSlot(30)}
                placeholder={placeholder}
              />
              {_.get(errors, name) && <ErrorMsg>{getErrorMessage()}</ErrorMsg>}
            </>
          )}
        </Field>
      )}
    />
  );
};

export default TimePickerWithController;
