import React from 'react';
import { useParams } from 'react-router-dom';
import Detail from '../../components/product/detail/Detail';
import { iRouteTypeId } from '../../shared/contactCompany/detail/types';

const ProductDetail = () => {
  const { id } = useParams<iRouteTypeId>();
  return <Detail id={id} />;
};

export default ProductDetail;
