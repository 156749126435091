import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import moment from 'moment';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';
import styled from 'styled-components';

import iChannel from '../../../../types/integration/iChannel';
import { FlexContainer } from '../../../../shared/styles/styles';
import iChannelScript from '../../../../types/integration/iChannelScript';
import ChannelScriptHelper from '../../../../shared/integrations/ChannelScriptHelper';

type iIntegrationChannelItem = { channel: iChannel; channelScript?: iChannelScript };
const Wrapper = styled.div`
  .logo {
    object-fit: contain;
    width: 100%;
    height: 50px;
  }
  [data-testid='not-onboarded--container'] {
    position: relative;
    > span {
      position: absolute;
      right: -5px;
      top: -5px;
      height: 23px;
      width: 23px;
    }
  }
`;

const IntegrationChannelItem = ({ channel, channelScript }: iIntegrationChannelItem) => {
  return (
    <Wrapper>
      <FlexContainer className="flex-start space-between">
        <img className="logo" src={channelScript && ChannelScriptHelper.getLogoUrl(channelScript)} alt="channel logo" />
        {!channel.isOnBoarded && (
          <Tooltip content="Setup is not complete." testId={'not-onboarded'}>
            <EditorWarningIcon label="warning" primaryColor={'red'} />
          </Tooltip>
        )}
      </FlexContainer>
      <div className="title">{channel.name}</div>
      <div className="subTitle">Last updated: {moment(channel.createdAt).toLocaleString()}</div>
    </Wrapper>
  );
};

export default IntegrationChannelItem;
