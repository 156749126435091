import React from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import Modal, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog';
import SectionMessage from '@atlaskit/section-message';
import {
  DELETE_INVALID_TEXT,
  NOT_ALLOW_DELETE,
} from '../../pages/warehouse/shared/Warehouse.constants';

const WarningModal = ({
  isOpen,
  onCancel,
  modalHeading = NOT_ALLOW_DELETE,
  modalBody = DELETE_INVALID_TEXT,
  bottom = null,
  closeBtnName = 'Close',
  width,
  height,
}: {
  isOpen?: boolean;
  onCancel: () => void;
  modalHeading?: React.ReactNode;
  modalBody?: React.ReactNode;
  bottom?: React.ReactNode;
  closeBtnName?: string;
  width?: 'small' | 'medium' | 'large' | 'x-large' | number | string;
  height?: number | string;
}) => {
  const getModal = () => (
    <Modal
      testId={'warning-modal'}
      components={{
        Footer: () => {
          return (
            <ModalFooter>
              <div>{bottom}</div>
              <ButtonGroup>
                <Button
                  onClick={onCancel}
                  testId={'warning-modal-close-button'}
                >
                  {closeBtnName}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          );
        },
      }}
      onClose={onCancel}
      heading={modalHeading}
      width={width || 'medium'}
      height={height || 'auto'}
    >
      <SectionMessage appearance="warning">{modalBody}</SectionMessage>
    </Modal>
  );
  return isOpen ? <ModalTransition>{getModal()}</ModalTransition> : null;
};

export default WarningModal;
