import React from 'react';
import Button from '@atlaskit/button';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';

import { FlexContainer } from '../../../styles/styles';
import { reloadJobBom } from '../../../../services/JobService';
import { apiErrorToast } from '../../../toast/Toast';
import { iDetailUpdateFn } from '../../../UITypes/types';
import iJob from '../../../../types/job/iJob';
import { handleNullException } from '../../../../services/UtilsService';
import SelfManagedConfirmWithReType from '../../../deleteWithReType/SelfManagedConfirmWithReType';

const JobBomCopyFrom = ({
  job,
  onUpdate,
  isDisabled,
}: {
  job: iJob;
  onUpdate: iDetailUpdateFn;
  isDisabled?: boolean;
}) => {
  const onReloadJobBom = async (id: string) => {
    try {
      const data = await reloadJobBom(id);
      await onUpdate({ bomDetails: data.bomDetails });
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const getReloadBomsConfirmInfo = (): React.ReactNode => {
    return (
      <span className={'retype-confirm-info'}>
        Prior to overwriting the existing BOM, please type in{' '}
        <strong>{handleNullException(job, 'product.productCode') || 'N/A'}</strong> to confirm.
      </span>
    );
  };

  return (
    <>
      <FlexContainer className={'space-between space-below'}>
        <div />
        <SelfManagedConfirmWithReType
          answer={handleNullException(job, 'product.productCode') || 'N/A'}
          onConfirm={() => onReloadJobBom(job.id)}
          popupHeading={`Are you sure to reload BOMs from ${handleNullException(job, 'product.productCode')} ?`}
          isDelete={false}
          confirmInfo={getReloadBomsConfirmInfo()}
          confirmBtnAppearance={'primary'}
          confirmBtnName={'Confirm'}
        >
          <Button
            className={'popup-item'}
            testId={'jobBom-reload-button'}
            iconBefore={<DocumentsIcon label={'reloadBom'} />}
            isDisabled={isDisabled}
          >
            Reload BOMs
          </Button>
        </SelfManagedConfirmWithReType>
      </FlexContainer>
    </>
  );
};

export default JobBomCopyFrom;
