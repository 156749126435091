import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import SectionMessage from '@atlaskit/section-message';
import iChannel from '../../../../types/integration/iChannel';
import { addToastForAPIResponse, apiErrorToast } from '../../../toast/Toast';
import { FlexContainer } from '../../../styles/styles';
import ChannelService from '../../../../services/integration/ChannelService';
import { iTaxBase } from '../../../../types/iTaxRate';
import { getTaxBases } from '../../../../services/Settings/TaxService';
import { createOption, createOptions } from '../../../../pages/sales/utilities';
import SelectSingle from '../../../form/SingleSelect';

type iWooCommerceDefaultTaxBaseMapper = {
  channel: iChannel;
  onSaved: (updatedChannel: iChannel) => void;
};

const Wrapper = styled.div`
  .defaultTaxBaseId {
    min-width: 10rem;
    width: 100%;
  }
`;
const WooCommerceDefaultTaxBaseMapper = ({ channel, onSaved }: iWooCommerceDefaultTaxBaseMapper) => {
  const [isLoading, setIsLoading] = useState(false);
  const [taxBases, setTaxBases] = useState<iTaxBase[]>([]);
  const [channelDefaultTaxBase, setChannelDefaultTaxBase] = useState<iTaxBase | null>(null);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);

    getTaxBases()
      .then(resp => {
        if (isCanceled) return;
        setTaxBases(resp);
        const selectedTaxBases = resp.filter(
          (taxBase: iTaxBase) => taxBase.id === (channel.settings.defaultTaxBaseId || ''),
        );
        setChannelDefaultTaxBase(selectedTaxBases.length > 0 ? selectedTaxBases[0] : null);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [channel]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (value: string) => {
    setIsLoading(true);
    ChannelService.updateChannel(channel.id, {
      settings: {
        ...channel.settings,
        defaultTaxBaseId: value,
      },
    })
      .then(resp => {
        addToastForAPIResponse('success', 'Default TaxBase updated.');
        if (onSaved) {
          onSaved(resp);
        }
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getWarningMessage = () => {
    if (channelDefaultTaxBase !== null) {
      return null;
    }
    return (
      <SectionMessage appearance={'error'} title={'Default TaxBase Required.'}>
        <p>Leaving Default TaxBase Empty will cause issue when orders are coming from [{channel.name}]</p>
      </SectionMessage>
    );
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Wrapper>
      {getWarningMessage()}
      <div className={'title-wrapper'}>
        <p>
          <small>All orders from [{channel.name}] will be created with below:</small>
        </p>
      </div>
      <FlexContainer className={'space-below'}>
        <SelectSingle
          className={'defaultTaxBaseId'}
          name={'defaultTaxBaseId'}
          defaultValue={createOption(channelDefaultTaxBase)}
          label={''}
          selectOptions={createOptions(taxBases)}
          onConfirm={(name, value) => handleSelect(value)}
        />
      </FlexContainer>
    </Wrapper>
  );
};

export default WooCommerceDefaultTaxBaseMapper;
