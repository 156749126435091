import { ucFirst } from '../../../../services/UtilsService';
import { iConfigColumn } from '../../../UITypes/types';

export const ADDRESS_FIELDS = [
  'name',
  'street',
  'suburb',
  'state',
  'postcode',
  'country',
];
export const COMPANY_ADDRESS_COLUMNS: Array<iConfigColumn> = [
  ...ADDRESS_FIELDS.map((field: string) => ({
    name: ucFirst(field),
    key: `address.${field}`,
    dataSource: [],
    type: 'text',
  })),
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
  },
  { name: '', key: 'edit', type: 'edit', dataSource: ['id'] },
  {
    name: '',
    key: 'delete',
    type: 'delete',
    dataSource: ['id'],
  },
];
