import React, { useState, useEffect } from 'react';
import PartitionTitle from '../shared/PartitionTitle';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { PartitionBodyWrapper, SalesWrapper } from '../shared/Dashboard.style';
import { getSOListConfig } from '../../../services/SalesOrderService';
import { getDNItemConfig } from '../../../services/DispatchNoteItemService';
import { iSalesState } from '../shared/Dashboard.types';
import {
  DASHBOARD_SALES,
  DASHBOARD_SALES_COLUMNS,
} from '../shared/Dashboard.constants';
import {
  getEndDayOfMonth,
  getStartDayOfMonth,
} from '../shared/Dashboard.utils';
import DashBoardSalesTable from './DashboardSalesTable';

const initialState: iSalesState = {
  isLoading: true,
  receivedMonth: [],
  dispatchedMonth: [],
  invoicedMonth: [],
};

const DashBoardSales = ({ date }: { date: string }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const receivedMonthFilter = `orderDate>=${getStartDayOfMonth(
          date,
        )},orderDate<=${getEndDayOfMonth(date)}`;
        const invoicedMonthFilter = `invoiceDate>=${getStartDayOfMonth(
          date,
        )},invoiceDate<=${getEndDayOfMonth(date)}`;
        const dispatchedMonthAdvancedFilter = `DispatchNote_dispatchNoteId_id.dispatchDate>=${getStartDayOfMonth(
          date,
        )},DispatchNote_dispatchNoteId_id.dispatchDate<=${getEndDayOfMonth(
          date,
        )}`;
        const { data: receivedMonth } = await getSOListConfig({
          filter: receivedMonthFilter,
          perPage: '1000',
          currentPage: '1',
          include:
            'salesOrderItems.product.measurement,salesOrderItems.measurement',
        });
        const { data: invoicedMonth } = await getSOListConfig({
          filter: invoicedMonthFilter,
          perPage: '1000',
          currentPage: '1',
          include:
            'salesOrderItems.product.measurement,salesOrderItems.measurement',
        });
        const { data: dispatchedMonth } = await getDNItemConfig({
          include:
            'salesOrderItem.measurement,salesOrderItem.product.measurement,dispatchNote',
          advancedFilter: dispatchedMonthAdvancedFilter,
          perPage: '1000',
          currentPage: '1',
        });
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          receivedMonth,
          invoicedMonth,
          dispatchedMonth,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [date]);

  return (
    <SalesWrapper>
      <PartitionTitle title={DASHBOARD_SALES} dateFrom={date} dateTo={date} />
      <PartitionBodyWrapper className={'dashboard-sales'}>
        <DashBoardSalesTable
          columns={DASHBOARD_SALES_COLUMNS}
          date={date}
          state={state}
        />
      </PartitionBodyWrapper>
    </SalesWrapper>
  );
};

export default DashBoardSales;
