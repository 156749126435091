import React, { useEffect, useState } from 'react';
import { Edge, Node, useEdges, useNodes } from 'react-flow-renderer';
import { useForm } from 'react-hook-form';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import styled from 'styled-components';
import SectionMessage from '@atlaskit/section-message';
import Button from '@atlaskit/button';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import { FlexContainer } from '../../../shared/styles/styles';
import SelectWithController from '../../../shared/form/hookForms/SelectWithController';
import iEntityStatus from '../../../types/status/iEntityStatus';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import { getStatusEdge, NODE_ID_ANY_STATUS_PREFIX } from '../../../shared/workflow/diagram/WorkflowDiagramHelper';
import WorkflowTransitDisplay from './WorkflowTransitDisplay';
import { iWFDiagramState } from '../../../shared/workflow/diagram/WorkflowDiagramReducer';

const Wrapper = styled.div`
  [aria-label='arrow-icon'] {
    margin-top: 30px;
  }
  .transit-name-wrapper {
    > div {
      width: 100%;
    }
  }
`;

type iWorkflowTransitionEditModal = {
  edge?: Edge;
  onCancel: () => void;
  isOpen: boolean;
  state: iWFDiagramState;
};
const WorkflowTransitionEditModal = ({ onCancel, isOpen, edge, state }: iWorkflowTransitionEditModal) => {
  const { control, setValue, errors, handleSubmit } = useForm();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAnyStatusEdge, setIsAnyStatusEdge] = useState(false);
  const [statusMap, setStatusMap] = useState<{ [key: string]: iEntityStatus }>({});
  const [error, setError] = useState('');
  const nodes = useNodes();
  const edges = useEdges();

  useEffect(() => {
    setStatusMap(
      nodes.reduce((map, node: Node) => {
        if (!('status' in node.data)) {
          return map;
        }
        return {
          ...map,
          [node.data.status.id]: node.data.status,
        };
      }, {}),
    );
  }, [nodes]);

  useEffect(() => {
    setIsAnyStatusEdge(`${edge?.source || ''}`.startsWith(NODE_ID_ANY_STATUS_PREFIX));
  }, [edge]);

  const getStatusOptions = () => {
    const statuses = Object.values(statusMap);
    return statuses.map((status: iEntityStatus) => ({
      label: status.name,
      value: status.id,
    }));
  };

  const saveTransition = (data: { [key: string]: string }) => {
    const { fromStatus, toStatus, name } = data;
    if (isAnyStatusEdge === true) {
      state.setEdges(
        edges.map(edg => {
          if (edg.id === edge?.id) {
            return {
              ...edg,
              label: name,
              selected: false,
            };
          }
          return edg;
        }),
      );
      return;
    }

    if (!(fromStatus in statusMap) || !(data.toStatus in statusMap)) {
      return;
    }

    if (fromStatus === toStatus) {
      setError('"From Status" and "To Status" can NOT be the same.');
      return;
    }

    state.setEdges([
      ...edges.filter(edg => edg.id !== edge?.id),
      getStatusEdge(statusMap[fromStatus], statusMap[toStatus], name),
    ]);
  };

  const getErrorPanel = () => {
    if (error.trim() === '') {
      return null;
    }
    return <SectionMessage appearance={'error'}>{error}</SectionMessage>;
  };

  const getModalHeading = () => {
    if (isDeleting === true) {
      return 'Are you sure?';
    }
    return edge ? 'Updating transition' : 'Creating transition';
  };

  const getConfirmBtnName = () => {
    if (isDeleting === true) {
      return 'Confirm Delete';
    }
    return edge ? 'Update' : 'Create';
  };

  const handleOnConfirm = () => {
    if (isDeleting === true) {
      state.setEdges(edges.filter(edg => edg.id !== edge?.id));
      return;
    }
    handleSubmit(saveTransition)();
  };

  const getTransitEditForm = () => {
    if (edge && isAnyStatusEdge === true) {
      return <WorkflowTransitDisplay edge={edge} />;
    }
    return (
      <FlexContainer className={'space-between'}>
        <SelectWithController
          name={'fromStatus'}
          label={'From Status:'}
          defaultValue={edge?.source || ''}
          control={control}
          onChange={setValue}
          errors={errors}
          options={getStatusOptions()}
          isRequired
          isClearable
        />
        <ArrowRightIcon label={'arrow-icon'} />
        <SelectWithController
          name={'toStatus'}
          label={'To Status:'}
          defaultValue={edge?.target || ''}
          control={control}
          onChange={setValue}
          errors={errors}
          options={getStatusOptions()}
          isRequired
          isClearable
        />
      </FlexContainer>
    );
  };

  const getModalBoy = () => {
    if (isDeleting === true) {
      if (!edge) {
        return null;
      }
      return (
        <>
          <p>Are you sure you want to delete this transition?</p>
          <WorkflowTransitDisplay edge={edge} />
        </>
      );
    }
    return (
      <Wrapper>
        <p>Transitions connect statuses. They represent actions people take to move statuses through your workflow.</p>
        {getTransitEditForm()}
        <FlexContainer className={'transit-name-wrapper'}>
          <InputWithController
            ctrlClassName={'transition-name'}
            label={'Name'}
            name={'name'}
            defaultValue={`${edge?.label || ''}`}
            control={control}
            onChange={setValue}
            placeholder={'Give your transition a name'}
            errors={errors}
            isRequired
          />
        </FlexContainer>
        {getErrorPanel()}
      </Wrapper>
    );
  };

  const getConfirmDeleteBtn = () => {
    if (!edge || isDeleting === true || isAnyStatusEdge === true) {
      return null;
    }

    return (
      <Button appearance={'danger'} onClick={() => setIsDeleting(true)}>
        Delete
      </Button>
    );
  };

  return (
    <CustomizeModal
      shouldScrollInViewport
      onCancel={onCancel}
      isOpen={isOpen}
      modalHeading={getModalHeading()}
      onConfirm={handleOnConfirm}
      confirmBtnName={getConfirmBtnName()}
      confirmBtnAppearance={isDeleting === true ? 'danger' : 'primary'}
      bottom={getConfirmDeleteBtn()}
      modalBody={getModalBoy()}
    />
  );
};

export default WorkflowTransitionEditModal;
