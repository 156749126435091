import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import InlineEditSelect from './InlineEditSelect';
import { iLabelValuePair } from '../UITypes/types';
import { apiErrorToast } from '../toast/Toast';
import { compareStr, handleNullException } from '../../services/UtilsService';

type iState = {
  isLoading: boolean;
  data: Array<iLabelValuePair>;
};

const initialState: iState = {
  isLoading: true,
  data: [],
};

const InlineEditSelectFetchOptions = <T extends { id: string }>({
  label,
  name,
  promiseFn,
  defaultValue,
  isClearable = false,
  onSelect,
  targetField = 'name',
  readViewFitContainerWidth,
  isDisabled,
}: {
  label?: string;
  name: string;
  promiseFn: () => Promise<Array<T>>;
  defaultValue?: string;
  isClearable?: boolean;
  //  eslint-disable-next-line
  onSelect: (
    fieldName: string,
    newValue: string | number | boolean | null,
  ) => void;
  targetField?: string;
  readViewFitContainerWidth?: boolean;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchOptions = async () => {
      try {
        const options: Array<T> = await promiseFn();
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          data: options
            .sort((a: T, b: T) => compareStr(handleNullException(a, targetField), handleNullException(b, targetField)))
            .map((item: T) => ({
              label: handleNullException(item, targetField),
              value: item.id,
            })),
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchOptions();
    return () => {
      isCancelled = true;
    };
  }, [promiseFn, targetField]);
  if (state.isLoading) return <Spinner />;
  return (
    <InlineEditSelect
      readViewFitContainerWidth={readViewFitContainerWidth}
      name={name}
      defaultValue={state.data.find((mapped: iLabelValuePair) => mapped.value === defaultValue)}
      selectOptions={state.data}
      label={label}
      onConfirm={onSelect}
      testId={`select-${name}`}
      isClearable={isClearable}
      isDisabled={isDisabled}
    />
  );
};

export default InlineEditSelectFetchOptions;
