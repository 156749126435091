import React from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import SelfManagePopup from '../../../shared/popup/SelfManagePopup';

const MoveButtonGroup = ({
  length,
  onConfirm,
  onCancel,
}: {
  length: number;
  onConfirm?: () => void;
  onCancel?: () => void;
}) => {
  return (
    <ButtonGroup data-testid={'button-group'}>
      {onCancel ? (
        <Button testId={'cancel-button'}>
          <SelfManagePopup
            onClick={onCancel}
            popupHeading={'Are you sure to clear the list ?'}
            popupContent={'Please confirm before you abort the products selected?'}
          >
            Cancel
          </SelfManagePopup>
        </Button>
      ) : null}
      {onConfirm ? (
        <LoadingButton
          onClick={onConfirm}
          appearance={'primary'}
          testId={'move-button'}
          isDisabled={length === 0}
          // isLoading={isConfirming}
        >
          Move above {length} items
        </LoadingButton>
      ) : null}
    </ButtonGroup>
  );
};

export default MoveButtonGroup;
