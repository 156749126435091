import { iConfigColumn } from '../../../shared/UITypes/types';
import { CUSTOMER_URL, SUPPLIER_URL } from '../../../shared/UrlMap';

export const CUSTOMER = 'customer';
export const SUPPLIER = 'supplier';
export const SERVICE_PROVIDER = 'serviceProvider';
export const CUSTOMER_PAGE_NAME = 'Customers';
export const SUPPLIER_PAGE_NAME = 'Suppliers';
export const SERVICE_PROVIDER_PAGE_NAME = 'Service Providers';
export const CUSTOMER_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Name',
    key: 'name',
    dataSource: ['id'],
    urlPrefix: CUSTOMER_URL,
    type: 'link',
    isSelected: true,
    group: '',
    isSortable: true,
  },
  {
    name: 'Billing Addr.',
    key: 'billingAddress',
    dataSource: ['billingAddress'],
    type: 'address',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Shipping Addr.',
    key: 'shippingAddress',
    dataSource: ['shippingAddress'],
    type: 'address',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Email',
    key: 'email',
    dataSource: ['email'],
    type: 'email',
    group: '',
    isSortable: false,
  },
  {
    name: 'Phone',
    key: 'phone',
    dataSource: ['phone'],
    type: 'phone',
    group: '',
    isSortable: false,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'delete',
    dataSource: [],
    type: 'delete',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];
export const CUSTOMER_INSERT_INDEX = 5;

export const SUPPLIER_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Name',
    key: 'name',
    dataSource: ['id'],
    urlPrefix: SUPPLIER_URL,
    type: 'link',
    isSelected: true,
    group: '',
    isSortable: true,
  },
  {
    name: 'Address',
    key: 'billingAddress',
    dataSource: ['billingAddress'],
    type: 'address',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Email',
    key: 'email',
    dataSource: ['email'],
    type: 'email',
    group: '',
    isSortable: false,
  },
  {
    name: 'Phone',
    key: 'phone',
    dataSource: ['phone'],
    type: 'phone',
    group: '',
    isSortable: false,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'delete',
    dataSource: [],
    type: 'delete',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];
export const SUPPLIER_INSERT_INDEX = 4;
