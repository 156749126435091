import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { useSelector } from 'react-redux';
import { iRouteTypeId } from '../../../shared/contactCompany/detail/types';
import BackendPage from '../../../layout/BackendPage';
import LogFooter from '../../sales/salesDetail/LogFooter';
import { AttachmentsWrapper, Page, SpinnerWrapper } from '../../../shared/styles/styles';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';
import { iTaxBase, iTaxRate } from '../../../types/iTaxRate';
import DetailPageHeader from './DetailPageHeader';
import { getPurchaseOrderDetail } from '../../../services/PurchaseService';
import { addToastForAPIResponse } from '../../../shared/toast/Toast';
import { getTaxBases, getTaxRates } from '../../../services/Settings/TaxService';
import PODetailEdit from './PODetailEdit';
import PurchaseOrderItem from './PurchaseOrderItem';
import POTabs from './POTabs';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { getEntityCategoryList } from '../../../services/CategoryService';
import GeneralAttachments from '../../../shared/generalAttachments/GeneralAttachments';
import { classifyEntityCategoryByStatusId } from '../../../services/UtilsService';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PURCHASE_ORDERS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';

const PurchaseOrderDetail = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_UPDATE, user);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [localPO, setLocalPO] = useState<iPurchaseOrderDetail>();
  const [taxRates, setTaxRates] = useState<iTaxRate[]>([]);
  const [taxBases, setTaxBases] = useState<iTaxBase[]>([]);
  const [version, setVersion] = useState(0);
  const [localCategories, setLocalCategories] = useState<Array<iEntityCategory>>([]);

  const { id } = useParams<iRouteTypeId>();

  useEffect(() => {
    let mounted = true;
    const fetchPO = async () => {
      setIsLoading(true);
      try {
        const PO = await getPurchaseOrderDetail(id);
        const taxRateData: iTaxRate[] = await getTaxRates();
        const taxBaseData: iTaxBase[] = await getTaxBases();
        const categories: Array<iEntityCategory> = await getEntityCategoryList({
          filter: 'entityStatuses.entityStatusType.entityName:PurchaseOrder',
        });

        if (mounted) {
          setLocalPO(PO);
          setTaxRates(taxRateData);
          setTaxBases(taxBaseData);
          setLocalCategories(categories);
        }
      } catch (e) {
        addToastForAPIResponse('error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPO();

    return () => {
      mounted = false;
    };
  }, [id, version]);

  const onRefresh = () => {
    setVersion(prev => prev + 1);
  };
  const { isFinished } = classifyEntityCategoryByStatusId(localCategories, localPO?.statusId);
  const getPageHeader = () => (
    <DetailPageHeader order={localPO} id={id} categories={localCategories} onRefresh={onRefresh} />
  );
  const getPageFooter = () => <LogFooter order={localPO} />;

  return isLoading && version === 0 ? (
    <SpinnerWrapper>
      <Spinner size="xlarge" />
    </SpinnerWrapper>
  ) : (
    <BackendPage pageHeader={getPageHeader()} pageFooter={getPageFooter()}>
      <Page>
        <PODetailEdit order={localPO} id={id} onRefresh={onRefresh} isDisabled={!canUpdate || isFinished} />
        <PurchaseOrderItem
          orderId={id}
          order={localPO}
          taxRates={taxRates}
          taxBases={taxBases}
          isDisabled={!canUpdate || isFinished}
        />
        <AttachmentsWrapper>
          <GeneralAttachments entityId={id} entityName={'PurchaseOrder'} canCreate={canUpdate} canDelete={canUpdate} />
        </AttachmentsWrapper>
        <POTabs poId={id} />
      </Page>
    </BackendPage>
  );
};

export default PurchaseOrderDetail;
