import React, { useState } from 'react';
import moment from 'moment/moment';
import { SortOrderType } from '@atlaskit/dynamic-table/types';
import styled from 'styled-components';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { getDeleteBtn, getHeads } from '../../components/settings/utils';
import iRole, { ROLE_NAME_DEFAULT_ADMIN } from '../../types/settings/UserAccess/iRole';
import CreatedOrUpdated from '../createdOrUpdated/CreatedOrUpdated';
import { handleNullException } from '../../services/UtilsService';
import iRoleAccess from '../../types/settings/UserAccess/iRoleAccess';
import LinkBtnUrl from '../buttons/LinkBtnUrl';
import { SETTINGS_ROLE_DETAILS_URL } from '../UrlMap';
import DataTable from '../tableHelper/DataTable';

const columns = ['Name', 'Accesses', 'Created', 'Updated'];
type iRoleList = {
  roles: iRole[];
  onDelete: (id: string) => void;
  isLoading?: boolean;
  onSort?: (sortStr: string) => void;
};
const Wrapper = styled.div`
  td {
    vertical-align: top;
  }
  th:first-child {
    width: 13rem;
  }
  .access-table {
    font-size: 10px;
    th,
    td {
      padding: 0px;
      height: auto;
    }
    tbody {
      border: none;
    }
    td.can-access {
      color: #36b37e;
      text-align: center;
    }
    .can-access-title {
      text-align: center;
    }
  }
`;
const RoleList = ({ roles, onDelete, isLoading, onSort }: iRoleList) => {
  const [sortOrder, setSortOrder] = useState<SortOrderType | undefined>(undefined);
  const [sortKey, setSortKey] = useState(undefined);

  const getAccessRows = (roleAccesses: iRoleAccess[]) => {
    return (
      <table className={'access-table'} data-testid={'access-table'}>
        <thead>
          <tr>
            <th>Name</th>
            <th className={'can-access-title'}>Read</th>
            <th className={'can-access-title'}>Update</th>
            <th className={'can-access-title'}>Create</th>
            <th className={'can-access-title'}>Admin</th>
          </tr>
        </thead>
        <tbody>
          {roleAccesses
            .sort((roleAccess1, roleAccess2) =>
              `${roleAccess1.access?.name || ''}` > `${roleAccess2.access?.name || ''}` ? 1 : -1,
            )
            .map(roleAccess => (
              <tr key={roleAccess.id}>
                <td>{roleAccess.access?.name}</td>
                <td className={'can-access'}>
                  {roleAccess.canRead ? <CheckIcon label={`can read ${roleAccess.access?.name}`} /> : null}
                </td>
                <td className={'can-access'}>
                  {roleAccess.canUpdate ? <CheckIcon label={`can update ${roleAccess.access?.name}`} /> : null}
                </td>
                <td className={'can-access'}>
                  {roleAccess.canCreate ? <CheckIcon label={`can create ${roleAccess.access?.name}`} /> : null}
                </td>
                <td className={'can-access'}>
                  {roleAccess.canDelete ? <CheckIcon label={`can delete ${roleAccess.access?.name}`} /> : null}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const getRows = () => {
    return roles
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iRole) => ({
        cells: [
          {
            key: item.name,
            content: (
              <>
                {item.name === ROLE_NAME_DEFAULT_ADMIN ? (
                  <>
                    <div>{item.name}</div>
                    <small className={'description'}>{item.description}</small>
                  </>
                ) : (
                  <>
                    <div>
                      <LinkBtnUrl btnName={item.name} url={SETTINGS_ROLE_DETAILS_URL.replace(':id', item.id)} />
                    </div>
                    <small className={'description'} data-testid={'description'}>
                      {item.description}
                    </small>
                  </>
                )}
              </>
            ),
          },
          { content: getAccessRows(item.roleAccesses || []) },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'createdAt')}
                operator={handleNullException(item, 'createdBy.firstName')}
              />
            ),
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'updatedAt')}
                operator={handleNullException(item, 'updatedBy.firstName')}
              />
            ),
          },
          {
            content:
              item.name === ROLE_NAME_DEFAULT_ADMIN
                ? null
                : getDeleteBtn({
                    id: item.id,
                    answer: item.name,
                    onClick: onDelete,
                  }),
          },
        ],
      }));
  };

  return (
    <Wrapper>
      <DataTable
        head={getHeads(columns, 'settings-roles')}
        rows={getRows()}
        testId={'settings-role-list-table'}
        isLoading={isLoading}
        loadingSpinnerSize="large"
        rowsPerPage={10}
        defaultPage={1}
        onSort={
          //  eslint-disable-next-line
          (data: any) => {
            if (!onSort) {
              return;
            }
            setSortKey(data.key);
            setSortOrder(data.sortOrder);
            onSort(`${data.key.toLowerCase()}:${data.sortOrder}`);
          }
        }
        sortKey={sortKey}
        sortOrder={sortOrder}
      />
    </Wrapper>
  );
};

export default RoleList;
