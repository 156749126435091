import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { Grid, GridColumn } from '@atlaskit/page';
import { ButtonGroup } from '@atlaskit/button';
import { useSelector } from 'react-redux';
import BackendPage from '../../../layout/BackendPage';
import useDetailHook from '../../../shared/hooks/useDetailHook/useDetailHook';
import iIssue from '../../../types/NCR/iIssue';
import DetailLeftPanel from './DetailLeftPanel';
import DetailRightPanel from './DetailRightPanel';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import PopupContent from '../../../shared/popup/GeneralPopupContent';
import DeleteByRetype from '../../../shared/deleteWithReType/DeleteByReType';
import iEntityCategory from '../../../types/status/iEntityCategory';
import StatusMenu from '../../../shared/statusComponent/StatusMenu';
import BreadcrumbsTwoLayers from '../../../shared/heading/BreadcrumbsTwoLayers';
import { handleNullException } from '../../../services/UtilsService';
import { FlexContainer, PageTitle } from '../../../shared/styles/styles';
import { emailBodyTpl, emailSubjectTpl, PAGE_NAME } from './shared/NCRDetail.constant';
import { getEntityCategoryList } from '../../../services/CategoryService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { deactivateNCR, getNCRDetail, updateNCR } from '../../../services/NCRService';
import { iRouteTypeId } from '../../../shared/UITypes/types';
import { NCRS_URL } from '../../../shared/UrlMap';
import GeneralEmailBtn from '../../../shared/buttons/GeneralEmailBtn';
import GeneralPrintBtn from '../../../shared/buttons/GeneralPrintBtn';
import { printNCR } from '../../../services/PrintService';
import { getContactCompany } from '../../../services/ContactCompanyService';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_WARRANTY } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE, ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';
import { STATUS_CATEGORY_FINISHED } from '../../../shared/constants/StatusCategories';

type iSupportState = {
  categories: Array<iEntityCategory>;
};
const initialSupportState: iSupportState = {
  categories: [],
};

const NCRDetail = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const history = useHistory();
  const { id } = useParams<iRouteTypeId>();
  const [supportState, setSupportState] = useState(initialSupportState);
  const {
    state,
    edit,
    onUpdateOneField,
    onUpdateEagerLoadObject,
    onOpenDeleteModal,
    onDelete,
    onCloseModal,
  } = useDetailHook<iIssue>({
    id: `${id}`,
    getFn: useCallback(
      (ncrId: string) =>
        getNCRDetail(ncrId, {
          include: 'product.measurement,customer,job,salesOrder,status.entityStatusCategory',
        }),
      [],
    ),
    updateFn: updateNCR,
    deleteFn: deactivateNCR,
    deleteCallback: () => history.push(NCRS_URL),
  });

  useEffect(() => {
    let isCancelled = false;
    const fetchDetail = async () => {
      try {
        const categories: Array<iEntityCategory> = await getEntityCategoryList({
          filter: 'entityStatuses.entityStatusType.entityName:Issue',
        });
        if (isCancelled) return;
        setSupportState({ categories });
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
      }
    };
    fetchDetail();
    return () => {
      isCancelled = true;
    };
  }, []);

  const getPageHeader = () => {
    return (
      <FlexContainer className={'space-between'}>
        <div>
          <BreadcrumbsTwoLayers
            name={PAGE_NAME}
            url={NCRS_URL}
            subName={handleNullException(state, 'data.issueNumber')}
          />
          <FlexContainer>
            <PageTitle className={'space-right'}>{handleNullException(state, 'data.issueNumber')}</PageTitle>
            <StatusMenu
              defaultValue={handleNullException(state, 'data.statusId')}
              categories={supportState.categories}
              onSelect={(newValue: string) => onUpdateOneField('statusId', newValue, true)}
              isDisabled={!AccessService.hasAccess(ACCESS_CODE_WARRANTY, ACCESS_CAN_UPDATE, user)}
            />
          </FlexContainer>
        </div>
        <ButtonGroup>
          {state.data?.issueNumber && (
            <GeneralEmailBtn
              orderNum={state.data?.issueNumber}
              serviceFunc={() => printNCR(id)}
              recipientServiceFunc={() => getContactCompany(handleNullException(state, 'data.customerId'))}
              emailSubjectTpl={emailSubjectTpl}
              emailBodyTpl={emailBodyTpl}
            >
              Email
            </GeneralEmailBtn>
          )}
          <GeneralPrintBtn serviceFunc={() => printNCR(id)}>Print</GeneralPrintBtn>
          {`${state.data?.status?.entityStatusCategory?.code || ''}` !== STATUS_CATEGORY_FINISHED &&
          AccessService.hasAccess(ACCESS_CODE_WARRANTY, ACCESS_CAN_DELETE, user) ? (
            <MoreIconPopup>
              <PopupContent id={id} onOpenDeleteModal={onOpenDeleteModal} />
            </MoreIconPopup>
          ) : null}
        </ButtonGroup>
      </FlexContainer>
    );
  };
  /**
   *
   * @param name field name
   * @param value (<any extends{id:string}>|null)
   * @param eagerLoadField eager load field
   * send one request if success update field&eager load field
   * potential risk: the latter object not fully cover the previous eager load field
   * like value=iProduct doesn't have iMeasurement
   */
  const onUpdateFieldAndEagerLoad = async (
    name: string,
    //  eslint-disable-next-line
    value: any,
    eagerLoadField: string,
  ) => {
    try {
      await onUpdateOneField(name, value ? value.id : null);
      onUpdateEagerLoadObject({ [eagerLoadField]: value });
    } catch (error) {
      apiErrorToast(error);
    }
  };
  if (state.isLoading) {
    return (
      <BackendPage>
        <Spinner />
      </BackendPage>
    );
  }
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={8}>
          <DetailLeftPanel
            detail={state.data}
            onUpdate={onUpdateOneField}
            onUpdateFieldAndEagerLoad={onUpdateFieldAndEagerLoad}
            isDisabled={
              `${state.data?.status?.entityStatusCategory?.code || ''}` === STATUS_CATEGORY_FINISHED ||
              !AccessService.hasAccess(ACCESS_CODE_WARRANTY, ACCESS_CAN_UPDATE, user)
            }
          />
        </GridColumn>
        <GridColumn medium={4}>
          <DetailRightPanel
            detail={state.data}
            onUpdate={onUpdateOneField}
            onUpdateFieldAndEagerLoad={onUpdateFieldAndEagerLoad}
            isDisabled={
              `${state.data?.status?.entityStatusCategory?.code || ''}` === STATUS_CATEGORY_FINISHED ||
              !AccessService.hasAccess(ACCESS_CODE_WARRANTY, ACCESS_CAN_UPDATE, user)
            }
          />
        </GridColumn>
      </Grid>
      <DeleteByRetype
        isOpen={edit.isDeleteModalOpen}
        onClose={onCloseModal}
        onConfirm={() => onDelete(id)}
        answer={handleNullException(state, 'data.issueNumber')}
      />
    </BackendPage>
  );
};

export default NCRDetail;
