import React from 'react';
import styled from 'styled-components';

const AddsOnContainer = styled.span`
  margin: 0px 8px;
  width: 100%;
  text-align: right;
`;
const AddsOn = ({ value }: { value?: string }) => {
  return value ? (
    <AddsOnContainer className={'adds-on-container'}>{value}</AddsOnContainer>
  ) : null;
};
export default AddsOn;
