import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BackendPage from '../../../layout/BackendPage';
import FaultCategoryListTable from './FaultCategoryListTable';
import CreateAndEditModalBody from './CreateAndEditModalBody';
import DeleteByRetype from '../../../shared/deleteWithReType/DeleteByReType';
import PageTitleWithAddOne from '../../../shared/heading/PageTitleWithAddOne';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import SearchBar from '../../../shared/search/SearchBar';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import iFaultCategory from '../../../types/NCR/iFaultCategory';
import { FlexContainer } from '../../../shared/styles/styles';
import { FAULT_CATEGORY_LIST_COLUMNS, FAULT_CATEGORY_PAGE_NAME } from './FaultCategoryList.constant';
import {
  createFaultCategory,
  deleteFaultCategory,
  getFaultCategories,
  updateFaultCategory,
} from '../../../services/FaultCategoryService';
import { ACCESS_CODE_WARRANTY } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE } from '../../../types/settings/UserAccess/iRoleAccess';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { RootState } from '../../../redux/makeReduxStore';

const FaultCategoryList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const { control, setValue, errors, handleSubmit } = useForm();
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onOpenDeleteModal,
    onSubmit,
    onSearch,
    onSetCurrentPage,
    onSetSort,
  } = useListCrudHook<iFaultCategory>({
    getFn: getFaultCategories,
    deleteFn: deleteFaultCategory,
    createFn: createFaultCategory,
    updateFn: updateFaultCategory,
    keywordColumns: 'name,comments',
    perPage: 10,
    sort: 'createdAt:DESC',
    paginationApplied: true,
  });

  const getPageHeader = () => (
    <FlexContainer className={'space-between'}>
      <PageTitleWithAddOne
        title={FAULT_CATEGORY_PAGE_NAME}
        onClickAddBtn={onOpenAddModal}
        className={'space-below'}
        isCustomizedTitle
        isDisabled={!AccessService.hasAccess(ACCESS_CODE_WARRANTY, ACCESS_CAN_DELETE, user)}
      />
      <FlexContainer>
        <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search by name/des...'} />
      </FlexContainer>
    </FlexContainer>
  );

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <>
        <FaultCategoryListTable
          isLoading={state.isLoading}
          data={state.data}
          onDelete={onOpenDeleteModal}
          onEdit={onOpenEditModal}
          onSetSort={onSetSort}
          columns={FAULT_CATEGORY_LIST_COLUMNS.filter(col => {
            if (col.key === 'operation' && !AccessService.hasAccess(ACCESS_CODE_WARRANTY, ACCESS_CAN_DELETE, user)) {
              return false;
            }
            return true;
          }).map(col => {
            if (col.key === 'name' && !AccessService.hasAccess(ACCESS_CODE_WARRANTY, ACCESS_CAN_DELETE, user)) {
              return {
                ...col,
                type: 'text',
              };
            }
            return col;
          })}
        />
        {!!state.total && !!state.perPage && !!state.currentPage && (
          <CustomizePagination
            range={Math.ceil(state.total / state.perPage)}
            currentPage={state.currentPage}
            onChange={onSetCurrentPage}
          />
        )}
        <CustomizeModal
          isOpen={edit.isModalOpen && !edit.delTargetId}
          onConfirm={handleSubmit(onSubmit)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
          modalBody={
            <CreateAndEditModalBody control={control} onChange={setValue} errors={errors} target={edit.target} />
          }
          modalHeading={edit.target ? 'Update' : 'Create'}
        />
        {edit.delTargetId && (
          <DeleteByRetype
            isOpen={edit.isModalOpen}
            onClose={onCloseModal}
            onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
            answer={edit.target?.name}
            isConfirming={state.isConfirming}
          />
        )}
      </>
    </BackendPage>
  );
};

export default FaultCategoryList;
