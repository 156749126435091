import { iConfigColumn } from '../../../shared/UITypes/types';

export const SERVICE_TYPE_PAGE_NAME = 'Service Type';
export const SERVICE_TYPE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Name',
    key: 'name',
    dataSource: ['id'],
    type: 'link',
    isSelected: true,
    group: '',
    isSortable: true,
  },
  {
    name: 'Description',
    key: 'description',
    dataSource: [''],
    type: 'text',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'delete',
    dataSource: [],
    type: 'delete',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];
