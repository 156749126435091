import React from 'react';
import useListCrudHook from '../../hooks/useListCrudHook/useListCrudHook';
import CustomizePagination from '../../pagination/CustomizePagination';
import { iChannelEntity } from '../../../types/integration/iChannelEntity';
import LogTable from './LogTable';
import iChannel from '../../../types/integration/iChannel';
import ChannelEntityService from '../../../services/integration/ChannelEntityService';
import ChannelJobService from '../../../services/integration/ChannelJobService';

const ChannelLogs = ({ channel }: { channel: iChannel }) => {
  const { state, onSetSort, onSetCurrentPage } = useListCrudHook<iChannelEntity>({
    getFn: ChannelJobService.getChannelJobs,
    deleteFn: ChannelEntityService.deactivateChannelEntity,
    createFn: ChannelEntityService.createChannelEntity,
    updateFn: ChannelEntityService.updateChannelEntity,
    filter: `channelId:${channel.id}`,
    sort: 'createdAt:DESC',
    paginationApplied: true,
  });

  return (
    <>
      <LogTable data={state.data} isLoading={state.isLoading} onSetSort={onSetSort} />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );
};

export default ChannelLogs;
