/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AsyncCreatableSelect as AsyncCreatable,
  AsyncSelect,
} from '@atlaskit/select';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import _ from 'lodash';
import { ErrorMsg } from '../../styles/styles';
import { mapLabelValuePairObjPayload } from '../../../pages/sales/utilities';
import CustomizedLabel from './CustomizedLabel';
import { iParams } from '../../UITypes/types';

const SelectWrapper = styled.div`
  min-width: 200px;
  &.min-width-120 {
    min-width: 120px;
  }
  .validation-error > div {
    border-color: #de350b;
  }
`;

type iOption = {
  label: string;
  value: any;
};

const createOption = (inputValue: string): iOption => ({
  label: inputValue,
  value: `:${inputValue.toLowerCase().replace(/\W/g, '')}`,
});

const AsyncCreatableWithController = ({
  name,
  label,
  placeholder,
  control,
  onChange,
  promiseFn,
  optionLabel,
  promiseFnConfig,
  testId = 'asyncCreatableSelect-react-hook-form',
  errors,
  className,
  filterFn,
  onCreate,
  defaultValue,
  isRequired = false,
  isCreatable = true,
  isDisabled = false,
}: {
  name: string;
  label?: string;
  placeholder?: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  promiseFn: (config?: { [key: string]: string }) => Promise<any>;
  optionLabel: string;
  promiseFnConfig?: iParams;
  testId?: string;
  isRequired?: boolean;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  valid?: boolean;
  className?: string;
  filterFn?: (item: any) => boolean;
  onCreate?: (name: string) => Promise<any>;
  defaultValue?: iOption;
  isCreatable?: boolean;
  isDisabled?: boolean;
}) => {
  const [value, setValue] = useState<iOption>();

  useEffect(() => {
    if (typeof onChange === 'function' && defaultValue?.label) {
      setValue(defaultValue);
      onChange(name, defaultValue.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, JSON.stringify(defaultValue)]);

  const promiseOptions = (
    inputValue: string,
    callback: (options: iOption[]) => void,
  ) => {
    promiseFn({
      ...promiseFnConfig,
      like: `${optionLabel}:${inputValue}`,
    }).then(({ data: items }) => {
      let filtered = items;
      if (filterFn) {
        filtered = items.filter(filterFn);
      }
      const mappedOptions = mapLabelValuePairObjPayload(filtered, optionLabel);
      if (mappedOptions) {
        callback(mappedOptions);
      }
    });
  };

  const debouncedOptions = _.debounce(promiseOptions, 700);

  const handleValueChange = (
    selected: iOption,
    // actionMeta: iActionTypes,
  ) => {
    setValue(selected);

    if (selected) {
      onChange(name, selected.value, { shouldValidate: true });
    } else {
      onChange(name, null, { shouldValidate: true });
    }
  };

  const handleCreateOption = async (userInputValue: string) => {
    const option = createOption(userInputValue);
    setValue(option);

    if (onCreate) {
      const created = await onCreate(userInputValue);
      onChange(name, created, { shouldValidate: true });
    }
  };

  const Component = isCreatable ? AsyncCreatable : AsyncSelect;
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
      }}
      defaultValue={defaultValue?.value || null}
      render={ctrlProps => (
        <SelectWrapper className={className}>
          <CustomizedLabel
            label={label}
            isRequired={isRequired}
            htmlFor={testId}
          />
          {/* {isCreatable ? ( */}
          <Component
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...ctrlProps}
            isClearable
            loadOptions={debouncedOptions}
            allowCreateWhileLoading
            onCreateOption={handleCreateOption}
            onChange={(selected: any) => handleValueChange(selected)}
            value={value}
            placeholder={placeholder}
            classNamePrefix={testId}
            className={`${testId} asyncCreatableSelect-react-hook-form ${
              _.get(errors, name) && 'validation-error'
            }`}
            isDisabled={isDisabled}
          />
          {/* ) : (
            <AsyncSelect
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...ctrlProps}
              isClearable
              loadOptions={debouncedOptions}
              onChange={(selected: any) => handleValueChange(selected)}
              value={value}
              placeholder={placeholder}
              classNamePrefix={testId}
              className={`${testId} asyncCreatableSelect-react-hook-form ${
                _.get(errors, name) && 'validation-error'
              }`}
              isDisabled={isDisabled}
            />
          )} */}

          {_.get(errors, name) && <ErrorMsg>must select an element</ErrorMsg>}
        </SelectWrapper>
      )}
    />
  );
};

export default AsyncCreatableWithController;
