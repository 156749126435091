import { iJobDetailSupState } from './JobDetail.type';

export const JOB_DETAIL_PAGE_NAME = 'job detail';
export const initialSupState: iJobDetailSupState = {
  machines: [],
  palletSystems: [],
  bomItemAttributes: [],
  jobCategories: [],
  wrappingMethods: [],
  isLoading: true,
  canPrint: false,
};
