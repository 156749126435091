import React, { useState } from 'react';
import { ButtonGroup } from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { getContactCompany } from '../../../services/ContactCompanyService';
import { printPurchaseOrder } from '../../../services/PrintService';
import { deactivatePurchaseOrder, updatePurchaseOrder } from '../../../services/PurchaseService';
import GeneralEmailBtn from '../../../shared/buttons/GeneralEmailBtn';
import GeneralPrintBtn from '../../../shared/buttons/GeneralPrintBtn';
import PopupContent from '../../../shared/contactCompany/detail/popup/PopupContent';
import DeleteByReType from '../../../shared/deleteWithReType/DeleteByReType';
import FlexSpaceBetweenContainer from '../../../shared/job/jobDetails/styles/FlexSpaceBetweenContainer';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/Toast';
import { PURCHASE_ORDER_RECEIVING_URL, PURCHASES_URL } from '../../../shared/UrlMap';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import { createPoReceiving } from '../../../services/PoReceivingService';
import { FlexContainer, PageTitle, SelectorWrapper } from '../../../shared/styles/styles';
import StatusMenu from '../../../shared/statusComponent/StatusMenu';
import iEntityCategory, { CATEGORY_CODE_FINISHED, CATEGORY_CODE_WIP } from '../../../types/status/iEntityCategory';
import SelfManagePopup from '../../../shared/popup/SelfManagePopup';
import { handleNullException } from '../../../services/UtilsService';
import BreadcrumbsTwoLayers from '../../../shared/heading/BreadcrumbsTwoLayers';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CODE_STOCK_RECEIVING } from '../../../types/settings/UserAccess/iAccess';
import {
  ACCESS_CAN_CREATE,
  ACCESS_CAN_DELETE,
  ACCESS_CAN_UPDATE,
} from '../../../types/settings/UserAccess/iRoleAccess';

const Wrapper = styled.div`
  margin-bottom: 1rem;
  > * {
    margin: 0.5rem 0;
  }

  & .Breadcrumb {
    font-size: 0.9rem;
    color: ${colors.N100};
    font-weight: 500;
  }
`;

const emailSubjectTpl = 'Please find [REF_NUM] from [COMPANY_NAME]';
const emailBodyTpl = `Hi there,

Please click the link below to download the purchase order.
[PDF_URL]

Feel free to contact us if you require anything further.

Kind Regards
[SENT_FROM]
[COMPANY_NAME]`;

const DetailPageHeader = ({
  order,
  categories,
  id,
  onRefresh,
}: {
  order?: iPurchaseOrderDetail;
  categories: Array<iEntityCategory>;
  id: string;
  onRefresh: () => void;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReceivePO = AccessService.hasAccess(ACCESS_CODE_STOCK_RECEIVING, ACCESS_CAN_CREATE, user);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_UPDATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_DELETE, user);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isReceiveModalOpen, setIsReceiveModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const history = useHistory();

  const { control, setValue, errors, handleSubmit, getValues } = useForm();

  const onUpdateDetail = async (fieldName: string, fieldValue: string | boolean | number | null | undefined) => {
    if (typeof order === 'undefined') {
      return;
    }
    try {
      await updatePurchaseOrder(id, { [fieldName]: fieldValue });
      onRefresh();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const onOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const onDelete = async () => {
    if (!order) return;
    try {
      await deactivatePurchaseOrder(id);
      history.push(`${PURCHASES_URL}`);
    } catch (error) {
      setIsDeleteModalOpen(false);
      apiErrorToast(error);
    }
  };

  const onOpenReceiveModal = () => {
    setIsReceiveModalOpen(true);
  };

  const onCreateReceive = async (data: { [key: string]: string }) => {
    const created = await createPoReceiving({
      reference: data.docketNumber,
      purchaseOrderId: id,
    });
    history.push(PURCHASE_ORDER_RECEIVING_URL.replace(':id', created.id));
  };

  const getReceivingModal = () => (
    <Page>
      <Grid layout={'fluid'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'docketNumber'}
            label={'Docket Number'}
            defaultValue={''}
            control={control}
            onChange={setValue}
            errors={errors}
            isRequired
            testId={'po-receiving-docketNumber'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );

  const getMoreBtn = () => {
    if (order?.status.entityStatusCategory?.code === CATEGORY_CODE_FINISHED) {
      return null;
    }
    if (order?.status.entityStatusCategory?.code !== CATEGORY_CODE_WIP && !canDelete) {
      return null;
    }
    return (
      <MoreIconPopup>
        {order?.status.entityStatusCategory?.code === CATEGORY_CODE_WIP && canReceivePO && (
          <PopupContent id={id} onOpenModal={onOpenReceiveModal} btnName={'Receive'} />
        )}
        {canDelete && <PopupContent id={id} onOpenModal={onOpenDeleteModal} btnName={'Delete'} />}
      </MoreIconPopup>
    );
  };

  return (
    <>
      <FlexSpaceBetweenContainer>
        <Wrapper>
          <BreadcrumbsTwoLayers name={'Purchase Orders'} subName={`PO: ${order?.orderNumber}`} url={PURCHASES_URL} />

          <FlexContainer>
            <PageTitle className={'small space-right'}>Purchase Order: {order?.orderNumber}</PageTitle>
            <SelectorWrapper>
              <StatusMenu
                defaultValue={order?.statusId}
                categories={categories}
                onSelect={(newValue: string) => onUpdateDetail('statusId', newValue)}
                isDisabled={!canUpdate}
              />
            </SelectorWrapper>
          </FlexContainer>
        </Wrapper>
        <ButtonGroup>
          {order?.orderNumber && (
            <GeneralEmailBtn
              orderNum={order?.orderNumber}
              serviceFunc={() => printPurchaseOrder(id)}
              recipientServiceFunc={() => getContactCompany(handleNullException(order, 'supplierId'))}
              emailSubjectTpl={emailSubjectTpl}
              emailBodyTpl={emailBodyTpl}
              onWarning={() => setIsWarningModalOpen(true)}
              shouldWarningModalOpen={!order?.eta}
            >
              Email
            </GeneralEmailBtn>
          )}
          <GeneralPrintBtn
            serviceFunc={() => printPurchaseOrder(id)}
            onWarning={() => setIsWarningModalOpen(true)}
            shouldWarningModalOpen={!order?.eta}
          >
            Print
          </GeneralPrintBtn>
          {getMoreBtn()}
        </ButtonGroup>
      </FlexSpaceBetweenContainer>
      {isDeleteModalOpen && (
        <DeleteByReType
          isOpen={id !== ''}
          onConfirm={onDelete}
          onClose={() => setIsDeleteModalOpen(false)}
          answer={order?.orderNumber || 'order number N/A'}
        />
      )}
      {isReceiveModalOpen && (
        <CustomizeModal
          isOpen={isReceiveModalOpen}
          onConfirm={handleSubmit(onCreateReceive)}
          onCancel={() => setIsReceiveModalOpen(false)}
          isDisabled={Object.keys(errors).length > 0 || !getValues().docketNumber}
          modalBody={getReceivingModal()}
          modalHeading={'Receive PO'}
        />
      )}

      {isWarningModalOpen && (
        <SelfManagePopup
          isPopupOpen={isWarningModalOpen}
          isCancelBtn={false}
          onClick={() => setIsWarningModalOpen(false)}
          popupHeading={'Please provide an ETA date before sending the PO.'}
          popupContent={<span />}
          confirmBtnName={'OK'}
          confirmBtnAppearance={'primary'}
        />
      )}
    </>
  );
};
export default DetailPageHeader;
