import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import Image48Icon from '@atlaskit/icon-file-type/glyph/image/48';
import { DropzoneWrapper } from './ImageCrop.style';

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 0,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};
const activeStyle = {
  borderColor: '#fff',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const ImagePicker = ({
  onPickImage,
}: {
  onPickImage: (acceptedFileUrl: string) => void;
}) => {
  const handleDrop = (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 0) return;
    const url = URL.createObjectURL(acceptedFiles[0]);
    onPickImage(url);
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    onDrop: handleDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );
  return (
    <DropzoneWrapper>
      <div
        //  eslint-disable-next-line
        {...getRootProps({ style })}
      >
        <div className={'dropzone'}>
          <input
            //  eslint-disable-next-line
            {...getInputProps()}
          />
          <Image48Icon label={'pick-image'} />
          <p>Drag and drop your image here, or click to select</p>
        </div>
      </div>
    </DropzoneWrapper>
  );
};

export default ImagePicker;
