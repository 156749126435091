import React from 'react';
// import styled from 'styled-components';
import HipchatMediaAttachmentCountIcon from '@atlaskit/icon/glyph/hipchat/media-attachment-count';
import { colors } from '@atlaskit/theme';

// type iColorType = 'green' | 'red';
const ColorfulDotIcon = () => {
  return (
    <HipchatMediaAttachmentCountIcon
      testId={'colorful-dot-icon'}
      primaryColor={colors.G500}
      label={'dot'}
      size={'medium'}
    />
  );
};

export default ColorfulDotIcon;
