/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Button from '@atlaskit/button';
import PreCheckProductImport from './PreCheckProductImport';
import ProductImportSteps from './ProductImportSteps';
import { SpaceVerticalContainer } from '../../../../../shared/styles/styles';
import { IMPORT_ANOTHER_FILE } from '../../shared/DataImport.constants';
import { iPrefills } from '../../shared/DataImport.types';
import { handleNullException } from '../../../../../services/UtilsService';
import { getParametersByPageName } from '../../shared/DataImport.util';
import { getProductFlexColumnsFn } from './ProductImport.util';

type iState = {
  preCheckPassed: boolean;
  prefills: iPrefills;
  isLoading: boolean;
};
const initialState: iState = {
  preCheckPassed: false,
  prefills: {
    productTypeId: undefined,
    productAttributeSetId: undefined,
    productAttributes: [],
  },
  isLoading: true,
};
const ProductImport = ({ detailPageName }: { detailPageName: string }) => {
  const [state, setState] = useState(initialState);
  const { columns, example } = getParametersByPageName(detailPageName);
  const onPreCheckResult = (preCheckResult: iPrefills) =>
    setState({ ...state, prefills: { ...state.prefills, ...preCheckResult } });

  return (
    <>
      <PreCheckProductImport
        onPreCheckResult={onPreCheckResult}
        prefills={state.prefills}
      />
      <SpaceVerticalContainer className={'half'} />
      {handleNullException(state.prefills, 'productTypeId') &&
        handleNullException(state.prefills, 'productAttributeSetId') && (
          <ProductImportSteps
            key={JSON.stringify(state.prefills)}
            detailPageName={detailPageName}
            prefills={state.prefills}
            columns={[
              ...columns,
              ...getProductFlexColumnsFn(state.prefills.productAttributes),
            ]}
            example={example}
          >
            <Button
              appearance={'primary'}
              onClick={() => setState(initialState)}
            >
              {IMPORT_ANOTHER_FILE}
            </Button>
          </ProductImportSteps>
        )}
    </>
  );
};

export default ProductImport;
