import iJob from '../../../../types/job/iJob';
import iProductAttribute from '../../../../types/product/iProductAttribute';
import iProductAttributeValue from '../../../../types/product/iProductAttributeValue';
import iBomItemAttribute from '../../../../types/product/iBomItemAttribute';
import iBomItem from '../../../../types/product/iBomItem';
import iJobCategory from '../../../../types/job/iJobCategory';
import { iKeyValuePairs, iRowContent } from '../../../../shared/UITypes/types';
import { handleNullException, numberRound, numberToPercentage } from '../../../../services/UtilsService';
import {
  STATUS_CATEGORY_FINISHED,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_NEW,
} from '../../../../shared/constants/StatusCategories';
import { CUSTOMER_URL, PRODUCTS_URL, SALES_URL } from '../../../../shared/UrlMap';
import iUser from '../../../../types/user/iUser';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_CUSTOMERS,
  ACCESS_CODE_INVOICES,
  ACCESS_CODE_PRODUCTS,
  ACCESS_CODE_QUOTES,
  ACCESS_CODE_SALES_ORDERS,
} from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

export const getAlternativeField = (job: iJob, mainField: string, backUpField?: string) =>
  handleNullException(job, mainField) || (backUpField && handleNullException(job, backUpField));

const getUrl = (job: iJob, urlPrefix: string, mainField: string, backUpField?: string) => {
  const value = getAlternativeField(job, mainField, backUpField);
  if (value) return `${urlPrefix}/${value}`;
  return undefined;
};

export const getOrderAttributes = (job: iJob, user?: iUser) => [
  {
    label: 'Product',
    value: handleNullException(job, 'product.productCode'),
    url: AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user)
      ? getUrl(job, PRODUCTS_URL, 'product.id')
      : undefined,
  },
  {
    label: 'Customer',
    value: getAlternativeField(job, 'customerName', 'salesOrder.customer.name'),
    url: AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_READ, user)
      ? getUrl(job, CUSTOMER_URL, 'salesOrder.customer.id')
      : undefined,
  },
  {
    label: 'Order',
    value: getAlternativeField(job, 'orderNumber', 'salesOrder.orderNumber'),
    url:
      AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_READ, user) ||
      AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_READ, user) ||
      AccessService.hasAccess(ACCESS_CODE_QUOTES, ACCESS_CAN_READ, user)
        ? getUrl(job, SALES_URL, 'salesOrder.id')
        : undefined,
  },
  {
    label: 'Customer Order Ref',
    value: getAlternativeField(job, 'customerOrderRef', 'salesOrder.customerRef'),
  },
];

export const getProductAttributes = (
  productAttributes: iProductAttribute[],
  productAttributeValues: iProductAttributeValue[],
) => {
  return productAttributes
    .sort((pAttrA, pAttrB) => pAttrA.sortOrder - pAttrB.sortOrder)
    .map(pAttr => ({
      label: pAttr.name,
      value: productAttributeValues.some(pAttrVal => pAttrVal.attributeId === pAttr.id)
        ? productAttributeValues
            .filter(pAttrVal => pAttrVal.attributeId === pAttr.id)
            .map(pAttrVal => pAttrVal.value)
            .join(',')
        : '',
    }));
};
export const getBomItemHeads = (attrs: Array<iBomItemAttribute>) => {
  const before = [
    { key: 'sku', content: 'SKU / Product Code' },
    { key: 'name', content: 'Name' },
  ];
  const after = [
    { key: 'percentage', content: 'Percentage' },
    {
      key: 'needAmount',
      content: 'Need Amount',
      testId: 'bomItem-head-alignRight',
    },
    {
      key: 'totalAmount',
      content: 'Total Amount',
      testId: 'bomItem-head-alignRight',
    },
  ];
  const dynamicHeads = attrs.map((attr: iBomItemAttribute) => ({
    key: attr.name,
    content: attr.name,
  }));
  return [...before, ...dynamicHeads, ...after];
};

export const getBomItemsRows = (job: iJob, attrCluster: Array<iBomItemAttribute>) => {
  const getDynamicRows = (bomDetail: iBomItem) =>
    attrCluster.reduce((acc: iKeyValuePairs, attr: iBomItemAttribute) => {
      const attributeValue = bomDetail.bomItemAttributes.find((bomAttr: iBomItemAttribute) => bomAttr.id === attr.id)
        ?.bomItemAttributeValues;
      return {
        ...acc,
        [attr.name]: attributeValue ? attributeValue.value : '',
      };
    }, {});

  return job.bomDetails
    .sort((bomDetailA: iBomItem, bomDetailB: iBomItem) => {
      return bomDetailA.sortOrder - bomDetailB.sortOrder;
    })
    .map((bomDetail: iBomItem) => {
      const row: iRowContent = {
        sku: bomDetail.material.productCode,
        name: bomDetail.material.name,
        ...getDynamicRows(bomDetail),
        percentage: numberToPercentage(bomDetail.materialQty, 2).toString(),
        needAmount: `${numberRound(bomDetail.materialQty * job.qtyToMake)} ${handleNullException(
          bomDetail,
          'material.measurement.shortName',
        )}`,
        totalAmount: `${numberRound(bomDetail.materialQty * job.qtyTotal)} ${handleNullException(
          bomDetail,
          'material.measurement.shortName',
        )}`,
      };
      return row;
    });
};

export const sortJobCategories = (categories: Array<iJobCategory>) => {
  const WHOLE = [STATUS_CATEGORY_NEW, STATUS_CATEGORY_IN_PROGRESS, STATUS_CATEGORY_FINISHED];
  return categories.sort((a: iJobCategory, b: iJobCategory) => WHOLE.indexOf(a.name) - WHOLE.indexOf(b.name));
};
