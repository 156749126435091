import React from 'react';
import styled from 'styled-components';
import CommentList from '../../../shared/comments/CommentList';
import LogList from '../../../shared/log/LogList';
import CustomizeTab from '../../../shared/tab/CustomizeTab';

const ActivitySectionContainer = styled.div`
  margin-top: 5rem;
`;

type iActivityTab = {
  label: string;
  content: React.ReactNode;
};

const Activities = ({ dispatchNoteId, extraTabs = [] }: { dispatchNoteId: string; extraTabs?: iActivityTab[] }) => {
  const tabs = [
    ...extraTabs,
    {
      label: 'Comments',
      content: <CommentList modelName={'DispatchNote'} modelId={dispatchNoteId} />,
    },
    {
      label: 'History',
      content: <LogList modelName={'DispatchNote'} modelId={dispatchNoteId} />,
    },
  ];

  return (
    <ActivitySectionContainer>
      <CustomizeTab components={tabs} />
    </ActivitySectionContainer>
  );
};

export default Activities;
