/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { iPageCustomizeParameter } from './DataImport.types';
import {
  onSubmitContactCompany,
  previewContactCompany,
} from './DataImport.util';
import {
  onSubmitProduct,
  previewProduct,
} from '../detail/product/ProductImport.util';
import {
  iConfigColumn,
  iLabelValuePair,
  iParams,
} from '../../../../shared/UITypes/types';
import {
  CUSTOMER_URL,
  PRODUCTS_URL,
  SERVICE_PROVIDER_URL,
  SETTINGS_DATA_IMPORT_URL,
  SUPPLIER_URL,
} from '../../../../shared/UrlMap';
import {
  BOM_CONFIG,
  BOM_TEMPLATE_DATA,
  CONTACTCOMPANY_CONFIG,
  CONTACTCOMPANY_TEMPLATE_DATA,
  PRODUCT_CONFIG,
  PRODUCT_TEMPLATE_DATA,
} from './Template.constants';
import ProductImport from '../detail/product/ProductImport';
import ContactCompanyImport from '../detail/contactCompany/ContactCompanyImport';
import {
  getBOMFlexColumnsFn,
  onSubmitBOM,
  previewBOM,
} from '../detail/bom/BOMImport.util';
import BOMImport from '../detail/bom/BOMImport';

export const LIST_PAGE_NAME = 'Data Import';
export const LIST_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Name',
    key: 'value',
    dataSource: ['label'],
    isCompulsory: true,
    isSortable: true,
    type: 'link-secondary',
    urlPrefix: SETTINGS_DATA_IMPORT_URL,
    isSelected: true,
    group: '',
  },
];

export const CUSTOMER_IMPORT = 'Customers Import';
export const SUPPLIER_IMPORT = 'Suppliers Import';
export const SERVICE_PROVIDER_IMPORT = 'Service Providers Import';
export const PRODUCT_IMPORT = 'Products Import';
export const BOM_IMPORT = 'BOMs Import';
export const LIST_DATA: Array<iLabelValuePair> = [
  {
    label: 'Bulk create your customers form CSV',
    value: CUSTOMER_IMPORT,
  },
  {
    label: 'Bulk create your suppliers from CSV',
    value: SUPPLIER_IMPORT,
  },
  {
    label: 'Bulk create your 3rd party service providers from CSV',
    value: SERVICE_PROVIDER_IMPORT,
  },
  {
    label: 'Bulk create your products from CSV',
    value: PRODUCT_IMPORT,
  },
  {
    label: 'Bulk create your bill of materials from CSV',
    value: BOM_IMPORT,
  },
];
export const MAX_ROWS = 201;
export const STEP1_TEXT = 'Step 1: Download the CSV template';
export const DOWNLOAD_TEXT = 'Download template CSV file';
export const STEP2_TEXT =
  'Step 2: Fill the template file with your data and upload below:';
export const READ_TITLE = 'Click or drag a file to this area';
export const READ_DES = `Maximum file size: ${MAX_ROWS - 1} rows`;
export const STEP3_TEXT = 'Step 3: Preview your data and submit';

export const ERROR_TEXT =
  'Find error(s) in your file, please click on the status icon to view the error';

export const SUBMIT_BUTTON_NAME = 'Start importing these [REF_NUM] records';
// .replace('[REF_NUM]', orderNum)
export const MODAL_HEADING_TEXT = 'About to import [REF_NUM] record(s)';

export const MODAL_BODY_TITLE =
  'Note: this process can NOT reverse automatically!';
export const MODAL_BODY_DESC =
  'DO NOT close this window during the importing, until it’s finished';

export const IMPORT_MODAL_HEADING_TEXT = 'Importing [REF_NUM] record(s)';
export const FINISH_MODAL_HEADING_TEXT =
  'Finished importing [REF_NUM] record(s)';

export const PAGE_CUSTOMIZE_PARAMETER: iPageCustomizeParameter = {
  [CUSTOMER_IMPORT]: {
    columns: CONTACTCOMPANY_CONFIG,
    submitFn: onSubmitContactCompany,
    url: CUSTOMER_URL,
    example: CONTACTCOMPANY_TEMPLATE_DATA,
    previewFn: previewContactCompany,
  },
  [SUPPLIER_IMPORT]: {
    columns: CONTACTCOMPANY_CONFIG,
    submitFn: onSubmitContactCompany,
    url: SUPPLIER_URL,
    example: CONTACTCOMPANY_TEMPLATE_DATA,
    previewFn: previewContactCompany,
  },
  [SERVICE_PROVIDER_IMPORT]: {
    columns: CONTACTCOMPANY_CONFIG,
    submitFn: onSubmitContactCompany,
    url: SERVICE_PROVIDER_URL,
    example: CONTACTCOMPANY_TEMPLATE_DATA,
    previewFn: previewContactCompany,
  },
  [PRODUCT_IMPORT]: {
    columns: PRODUCT_CONFIG,
    submitFn: onSubmitProduct,
    url: PRODUCTS_URL,
    example: PRODUCT_TEMPLATE_DATA,
    previewFn: previewProduct,
  },
  [BOM_IMPORT]: {
    columns: BOM_CONFIG,
    submitFn: onSubmitBOM,
    url: PRODUCTS_URL,
    example: BOM_TEMPLATE_DATA,
    previewFn: previewBOM,
    getFlexColumnsFn: getBOMFlexColumnsFn,
  },
};
export const CONTACT_TYPE: iParams = {
  [CUSTOMER_IMPORT]: 'customer',
  [SUPPLIER_IMPORT]: 'supplier',
  [SERVICE_PROVIDER_IMPORT]: 'serviceProvider',
};

export const BOOLEANS: Array<string> = ['yes', 'no'];

export const COMPONENT_MAP = {
  [CUSTOMER_IMPORT]: ContactCompanyImport,
  [SUPPLIER_IMPORT]: ContactCompanyImport,
  [SERVICE_PROVIDER_IMPORT]: ContactCompanyImport,
  [PRODUCT_IMPORT]: ProductImport,
  [BOM_IMPORT]: BOMImport,
};

export const PRODUCT_PRE_TEXT =
  'Please select product type&product attribute set';
export const IMPORT_ANOTHER_FILE = 'Import Another File';
