import React from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';

const SaveCancelButtonGroup = ({
  isConfirming,
  onConfirm,
  onCancel,
  testIdPrefix,
}: {
  isConfirming?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  testIdPrefix?: string;
}) => {
  return (
    <ButtonGroup data-testid={'save-cancel-button-group'}>
      <LoadingButton
        onClick={onConfirm}
        appearance={'primary'}
        testId={
          testIdPrefix ? `${testIdPrefix}-confirm-button` : 'confirm-button'
        }
        isLoading={isConfirming}
      >
        Save
      </LoadingButton>
      <Button
        onClick={onCancel}
        testId={
          testIdPrefix ? `${testIdPrefix}-cancel-button` : 'cancel-button'
        }
      >
        Cancel
      </Button>
    </ButtonGroup>
  );
};

export default SaveCancelButtonGroup;
