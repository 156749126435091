import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const endPoint = '/bomItemAttribute';
export const getBomAttributeByName = (name: string) => {
  return AppService.get(endPoint, { filter: `name:${name}` }).then(({ data }) => data);
};

export const getBomAttributeDetail = (id: string, params = {}) => {
  return AppService.get(`${endPoint}/${id}`, params).then(({ data }) => data);
};

export const getBomItemAttributes = (config?: iParams) => {
  return AppService.get(endPoint, {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const createBomAttribute = (postData: iParams) => {
  return AppService.post(endPoint, postData).then(({ data }) => data);
};

export const deleteBomAttribute = (id: string, params = {}) => {
  return AppService.delete(`${endPoint}/${id}`, params).then(({ data }) => data);
};

export const updateBomAttribute = (id: string, updateContent: iParams) => {
  return AppService.put(`${endPoint}/${id}`, updateContent).then(({ data }) => data);
};

export const updateBomAttributeValue = (id: string, updateContent: { [key: string]: string | boolean | number }) => {
  return AppService.put(`/bomItemAttributeValue/${id}`, updateContent).then(({ data }) => data);
};

export const createBomAttributeValue = (postData: iKeyValuePairs) => {
  return AppService.post('/bomItemAttributeValue', postData).then(({ data }) => data);
};

export default {
  getBomAttributeByName,
  createBomAttributeValue,
  updateBomAttributeValue,
  getBomItemAttributes,
  getBomAttributeDetail,
  deleteBomAttribute,
  createBomAttribute,
  updateBomAttribute,
};
