import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iPalletSystem from '../../../types/iPalletSystem';
import InputWithController from '../../../shared/form/hookForms/InputWithController';

const PalletEditModal = ({
  control,
  errors,
  onChange,
  target,
}: {
  //    eslint-disable-next-line
    control: Control<Record<string, any>>;
  //    eslint-disable-next-line
    onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
    errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iPalletSystem;
}) => {
  return (
    <Page>
      <Grid layout={'fluid'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'name'}
            label={'Name'}
            defaultValue={target?.name}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'settings-pallet-modal-name'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default PalletEditModal;
