import AppService from './AppService';

//  eslint-disable-next-line
export const transfer = (postData: any) => {
  return AppService.post('/stockTransfer', postData).then(({ data }) => data);
};

export default {
  transfer,
};
