import AppService from '../AppService';
import iChannelScriptType from '../../types/integration/iChannelScriptType';

const getChannelScriptTypes = (params = {}) => {
  return AppService.get('/channelScriptType', params).then(resp => resp.data);
};

const getChannelScriptTypesForAccounting = () => {
  return AppService.get('/channelScriptType', {
    filter: 'name:Accounting',
  }).then(({ data }) => {
    return data as iChannelScriptType[];
  });
};

export default {
  getChannelScriptTypesForAccounting,
  getChannelScriptTypes,
};
