import React, { useState, useRef } from 'react';
import Avatar from '@atlaskit/avatar';
import TextArea from '../form/TextArea';
import iComment from '../../types/comment/iComment';
import { FlexContainer } from '../styles/styles';
import SaveCancelButtonGroup from './SaveCancelButtonGroup';
import EditDeleteButtonGroup from './EditDeleteButtonGroup';
import { formatDate } from '../../services/UtilsService';

type iState = {
  value?: string;
};
const initialState: iState = {};
const CommentItem = ({
  comment,
  isConfirming,
  isReadView,
  editTarget,
  onOffReadView,
  onOpenDelete,
  onEdit,
  onCancel,
}: {
  comment: iComment;
  isConfirming?: boolean;
  onOpenDelete: (id: string) => void;
  onEdit: (value?: string) => void;
  isReadView: boolean;
  onOffReadView: (id: string) => void;
  editTarget?: iComment;
  onCancel: () => void;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [state, setState] = useState(initialState);
  const onCleanup = () => {
    setState({ ...state, value: undefined });
    onCancel();
  };
  const onEditView = () => {
    onOffReadView(comment.id);
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
        textAreaRef.current.select();
      }
    }, 0);
  };
  const onConfirm = () => {
    onEdit(state.value);
  };
  const getHeader = () => {
    return (
      <div className={'comment-item-header'}>
        <span className={'comment-item-username'}>
          {comment.updatedBy?.username || ''}
        </span>
        <span className={'comment-item-date'}>
          {formatDate(comment.updatedAt) || ''}
        </span>
      </div>
    );
  };
  const getReadView = () => (
    <>
      <p>{comment.content}</p>
      <EditDeleteButtonGroup
        onDeleteClick={() => onOpenDelete(comment.id)}
        onEditClick={onEditView}
        testIdPrefix={comment.id}
      />
    </>
  );
  const getEditView = () => (
    <>
      <TextArea
        name={`${comment.id}-comment-textarea`}
        onChange={(name: string, newValue: string) => {
          setState({ ...state, value: newValue });
        }}
        minimumRows={4}
        defaultValue={comment.content}
        textareaRef={textAreaRef}
        debouncePeriod={100}
      />
      <SaveCancelButtonGroup
        isConfirming={isConfirming}
        onCancel={onCleanup}
        onConfirm={onConfirm}
        testIdPrefix={comment.id}
      />
    </>
  );

  return (
    <FlexContainer data-testid={'comment-item'}>
      <div className={'item-avatar'}>
        <Avatar
          appearance="circle"
          src={comment.updatedBy?.userAvatarUrl}
          size="medium"
          testId={'item-avatar'}
        />
      </div>
      <div className={'comment-item-view'} data-testid={'comment-item-view'}>
        {getHeader()}
        {editTarget && editTarget.id === comment.id && !isReadView
          ? getEditView()
          : getReadView()}
      </div>
    </FlexContainer>
  );
};

export default CommentItem;
