import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iContactCompanyProduct from '../../../types/contactCompany/iContactCompanyProduct';
import iProduct from '../../../types/product/iProduct';
import ServiceProviderKeyInfo from './ServiceProviderKeyInfo';
import CCPModalCommonFields from '../CCPModalCommonFields';
import AsyncSearchWithController from '../../form/hookForms/AsyncSearchWithController';
import { getProductListAsyncSearch } from '../../../services/product/ProductService';
// import { SpaceVerticalContainer } from '../../styles/styles';
import { getPSTListAsyncSearch } from '../../../services/product/ProductServiceTypeService';
import TextAreaWithController from '../../form/hookForms/TextAreaWithController';

const CCPServiceProviderModal = ({
  control,
  errors,
  onChange,
  watch,
  target,
  fixedProduct,
}: {
  // eslint-disable-next-line
  control: Control<Record<string, any>>;
  // eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  // eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  // eslint-disable-next-line
  watch: (names?: string | string[]) => any;
  target?: iContactCompanyProduct;
  fixedProduct?: iProduct;
}) => {
  const modalBodyTop = fixedProduct ? (
    <ServiceProviderKeyInfo
      defaultValue={target?.contactCompany}
      onChange={onChange}
      control={control}
      errors={errors}
    />
  ) : (
    <Grid spacing={'compact'}>
      <GridColumn medium={12}>
        <AsyncSearchWithController
          name={'product'}
          label={'Product'}
          defaultValue={target?.product}
          control={control}
          onChange={onChange}
          promiseFn={(keyword: string) =>
            getProductListAsyncSearch({
              like: `productCode:${keyword},name:${keyword}`,
            })
          }
          optionLabel={['productCode', 'name']}
          errors={errors}
          isRequired
          testId={'product'}
        />
      </GridColumn>
    </Grid>
  );

  return (
    <Page>
      {modalBodyTop}
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <AsyncSearchWithController
            name={'serviceType'}
            label={'Service type'}
            defaultValue={target?.serviceType}
            control={control}
            onChange={onChange}
            promiseFn={(keyword: string) =>
              getPSTListAsyncSearch({
                like: `description:${keyword},name:${keyword}`,
              })
            }
            optionLabel={['name']}
            errors={errors}
            isRequired
            testId={'service-type'}
          />
        </GridColumn>
      </Grid>

      <CCPModalCommonFields
        control={control}
        errors={errors}
        onChange={onChange}
        watch={watch}
        measurementLabel={'Service Provider'}
        fixedProduct={fixedProduct}
        target={target}
      />
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <TextAreaWithController
            name={'instructions'}
            label={'Instructions'}
            defaultValue={target?.instructions}
            control={control}
            onChange={onChange}
            errors={errors}
            minimumRows={5}
            isRequired={false}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default CCPServiceProviderModal;
