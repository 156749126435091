import React from 'react';
import Select, { ValueType } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import iContactCompanyAddress from '../../../../types/contactCompany/iContactCompanyAddress';
import ComposeSecondaryText from '../../../text/ComposeSecondaryText';
import { assembleAddress } from '../../../../services/UtilsService';
import { useContactCompanyContext } from '../context/ContactCompanyContext';

type iOption<T> = {
  label: string;
  value: T;
};
type iSingleSelectOption<T> = iOption<T> | null | undefined;

const AddressSelector = ({
  selectedAddressId,
  onChange,
  isDisabled = false,
}: {
  selectedAddressId?: string;
  onChange: (value: null | string) => void;
  isDisabled?: boolean;
}) => {
  const { addressContext } = useContactCompanyContext();

  const getOptions = () => {
    if (addressContext.state.isLoading) return [];
    return addressContext.state.data.map((item: iContactCompanyAddress) => ({
      label: item.id,
      value: item,
    }));
  };

  const getValue = () => {
    return getOptions().find((item: iOption<iContactCompanyAddress>) => item.value.addressId === selectedAddressId);
  };

  const getAddressOptionLabel = (option: iSingleSelectOption<iContactCompanyAddress>) => {
    if (!option) return null;
    return (
      <ComposeSecondaryText secondaryText={assembleAddress(option.value.address)}>
        {option.value.address?.name}
      </ComposeSecondaryText>
    );
  };
  const onChangeMiddleWare = (value: ValueType<iOption<iContactCompanyAddress>>) => {
    if (!value) {
      onChange(null);
      return;
    }
    onChange((value as iOption<iContactCompanyAddress>).value.addressId);
  };
  if (addressContext.state.isLoading) return <Spinner />;
  return (
    <Select
      options={getOptions()}
      value={getValue() || null}
      formatOptionLabel={getAddressOptionLabel}
      onChange={onChangeMiddleWare}
      isClearable
      isDisabled={isDisabled}
    />
  );
};

export default AddressSelector;
