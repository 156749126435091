import React from 'react';
import styled from 'styled-components';
import ProductToggle from './ProdToggle';

type iAttribute = {
  title: string;
  description?: string;
  name: string;
  isChecked?: boolean;
};
const TogglesWrapper = styled.div`
  margin-top: 3.5rem;
  margin-bottom: 1.5rem;
  .not-first-child {
    margin-top: 0.8rem;
  }
`;
const ProductToggles = ({
  attributes,
  onToggle,
  isDisabled,
}: {
  attributes: Array<iAttribute>;
  onToggle: (name: string, value: boolean) => void;
  isDisabled?: boolean;
}) => {
  return (
    <TogglesWrapper>
      {attributes.map((a: iAttribute, index: number) => (
        <ProductToggle
          attribute={a}
          key={a.name}
          onToggle={onToggle}
          className={index !== 0 ? 'not-first-child' : undefined}
          isDisabled={isDisabled}
        />
      ))}
    </TogglesWrapper>
  );
};

export default ProductToggles;
