import React from 'react';
import iJob from '../../../types/job/iJob';
import { iConfigColumn } from '../../despatchNote/shared/DispatchNote.type';
import {
  DASHBOARD_SCHEDULED,
  DASHBOARD_UNSCHEDULED,
} from '../shared/Dashboard.constants';
import SummaryJobs from './SummaryJobs';

export const getRows = ({
  unscheduledJobs,
  scheduledJobs,
  columns,
}: {
  unscheduledJobs: Array<iJob>;
  scheduledJobs: Array<iJob>;
  columns: Array<iConfigColumn>;
}) => {
  //  eslint-disable-next-line
  const getCells = (jobs: Array<iJob>, fixCell: any) =>
    columns.map((column: iConfigColumn) => {
      const { key: machineId, type } = column;
      if (type === 'sort-type') return fixCell;
      return {
        key: machineId,
        content: (
          <SummaryJobs
            jobs={jobs.filter((job: iJob) => job.machineId === machineId)}
          />
        ),
      };
    });

  return [
    {
      cells: getCells(scheduledJobs, { content: DASHBOARD_SCHEDULED }),
    },
    {
      cells: getCells(unscheduledJobs, { content: DASHBOARD_UNSCHEDULED }),
    },
  ];
};
