import { ModuleCode } from '../../types/iModuleCode';
import { iMenuItem, iPrimaryMenuExceptHome, SUB_MENU_NAME } from '../UITypes/types';
import {
  AOS_URL,
  BOM_ATTRIBUTES_URL,
  CUSTOMER_URL,
  DISPATCH_NOTES_URL,
  FAULT_CATEGORY_URL,
  ITEM_LOCATIONS_URL,
  JOB_ATTRIBUTES_URL,
  JOB_SCHEDULER_URL,
  JOB_URL,
  NCRS_URL,
  PRODUCT_ATTRIBUTE_SETS_URL,
  PRODUCT_TYPES_URL,
  PRODUCTS_URL,
  PURCHASES_URL,
  REPORT_PURCHASE_HISTORY_URL,
  SALES_INVOICE_URL,
  SALES_URL,
  SERVICE_PROVIDER_URL,
  SERVICE_TYPE_URL,
  STOCK_FORECAST_URL,
  STOCK_TAKE_URL,
  STOCK_TRANSFER_URL,
  SUPPLIER_URL,
  WAREHOUSE_URL,
} from '../UrlMap';
import {
  ACCESS_CODE_ACQUISITION_ORDERS,
  ACCESS_CODE_CUSTOMERS,
  ACCESS_CODE_DISPATCH_NOTES,
  ACCESS_CODE_INVOICES,
  ACCESS_CODE_JOB_ATTRIBUTES,
  ACCESS_CODE_JOB_SCHEDULER,
  ACCESS_CODE_JOBS,
  ACCESS_CODE_PRODUCT_ATTRIBUTES,
  ACCESS_CODE_PRODUCTS,
  ACCESS_CODE_PURCHASE_ORDERS,
  ACCESS_CODE_QUOTES,
  ACCESS_CODE_REPORTS,
  ACCESS_CODE_SALES_ORDERS,
  ACCESS_CODE_SERVICE_PROVIDERS,
  ACCESS_CODE_STOCK_RECEIVING,
  ACCESS_CODE_STOCK_TAKES,
  ACCESS_CODE_STOCK_TRANSFER,
  ACCESS_CODE_SUPPLIERS,
  ACCESS_CODE_WAREHOUSES,
  ACCESS_CODE_WARRANTY,
} from '../../types/settings/UserAccess/iAccess';

export const contactsSubMenu: Array<iMenuItem> = [
  {
    label: 'Customers',
    value: CUSTOMER_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_CUSTOMERS],
  },
  { label: 'Suppliers', value: SUPPLIER_URL, accessCodes: [ACCESS_CODE_SUPPLIERS] },
  {
    label: 'Service Providers',
    value: SERVICE_PROVIDER_URL,
    hasSeparator: true,
    moduleCode: ModuleCode.SERVICE_PROVIDER,
    accessCodes: [ACCESS_CODE_SERVICE_PROVIDERS],
  },
  {
    label: 'Service Types',
    value: SERVICE_TYPE_URL,
    moduleCode: ModuleCode.SERVICE_PROVIDER,
    accessCodes: [ACCESS_CODE_SERVICE_PROVIDERS],
  },
];
export const makesSubMenu: iMenuItem[] = [
  {
    label: 'Jobs',
    value: JOB_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_JOBS],
  },
  {
    label: 'Job Attributes',
    value: JOB_ATTRIBUTES_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_JOB_ATTRIBUTES],
  },
  { label: 'Jobs Scheduler', value: JOB_SCHEDULER_URL, hasSeparator: true, accessCodes: [ACCESS_CODE_JOB_SCHEDULER] },
];
export const purchasesSubMenu: iMenuItem[] = [
  {
    label: 'Acquisition Orders',
    value: AOS_URL,
    hasSeparator: false,
    moduleCode: ModuleCode.PURCHASE_AO,
    accessCodes: [ACCESS_CODE_ACQUISITION_ORDERS],
  },
  {
    label: 'Purchase Orders',
    value: PURCHASES_URL,
    hasSeparator: true,
    accessCodes: [ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CODE_STOCK_RECEIVING],
  },
];
export const salesSubMenu: Array<iMenuItem> = [
  {
    label: 'Sales Orders',
    value: SALES_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_SALES_ORDERS, ACCESS_CODE_QUOTES],
  },
  {
    label: 'Sales Orders - Invoices',
    value: SALES_INVOICE_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_INVOICES],
  },
  { label: 'Dispatch Notes', value: DISPATCH_NOTES_URL, hasSeparator: true, accessCodes: [ACCESS_CODE_DISPATCH_NOTES] },
];

export const stockSubMenu: iMenuItem[] = [
  {
    label: 'Warehouses',
    value: WAREHOUSE_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_WAREHOUSES],
  },
  {
    label: 'Item Locations',
    value: ITEM_LOCATIONS_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_WAREHOUSES, ACCESS_CODE_PRODUCTS],
  },
  {
    label: 'Products',
    value: PRODUCTS_URL,
    hasSeparator: true,
    accessCodes: [ACCESS_CODE_PRODUCTS],
  },
  {
    label: 'Product Types',
    value: PRODUCT_TYPES_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_PRODUCTS],
  },
  {
    label: 'Product Attribute Sets',
    value: PRODUCT_ATTRIBUTE_SETS_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_PRODUCT_ATTRIBUTES],
  },
  {
    label: 'Bom Attributes',
    value: BOM_ATTRIBUTES_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_PRODUCT_ATTRIBUTES],
  },
  {
    label: 'Stock Transfer',
    value: STOCK_TRANSFER_URL,
    hasSeparator: true,
    accessCodes: [ACCESS_CODE_STOCK_TRANSFER],
  },
  {
    label: 'Stock Take',
    value: STOCK_TAKE_URL,
    hasSeparator: false,
    accessCodes: [ACCESS_CODE_STOCK_TAKES],
  },
];

export const issuesSubMenu: Array<iMenuItem> = [
  {
    label: 'NCRs',
    value: NCRS_URL,
    accessCodes: [ACCESS_CODE_WARRANTY],
  },
  { label: 'NCR Faults', value: FAULT_CATEGORY_URL, accessCodes: [ACCESS_CODE_WARRANTY] },
];

export const reportsSubMenu: Array<iMenuItem> = [
  {
    label: 'Stock Required',
    value: STOCK_FORECAST_URL,
    accessCodes: [ACCESS_CODE_REPORTS],
  },
  {
    label: 'Purchase History',
    value: REPORT_PURCHASE_HISTORY_URL,
    hasSeparator: true,
    accessCodes: [ACCESS_CODE_REPORTS],
  },
];

// maintain the top nav menus here:
// one popup/subMenu becomes unique,does not follow the same logic as others;
// 1.  middleware between TopNav and TopNavPopup:
//     make a change in TopNav from Array.map(general) to specific like
//       <SalesPopup />,
//       <PurchasesPopup />,
//       <SpecificPopup />,
//
// 2.  handle in TopNav/turn TopNavPopup to be a middleware:
//     add a new property in iMenuItem and handle it in TopNavPopup or add a sub component for TopNavPopup
export const PRIMARY_MENUS_EXCEPT_HOME: Array<iPrimaryMenuExceptHome> = [
  {
    name: SUB_MENU_NAME.Sales,
    subMenu: salesSubMenu,
    accessCodes: [ACCESS_CODE_QUOTES, ACCESS_CODE_INVOICES, ACCESS_CODE_SALES_ORDERS, ACCESS_CODE_DISPATCH_NOTES],
  },
  {
    name: SUB_MENU_NAME.Purchases,
    subMenu: purchasesSubMenu,
    accessCodes: [ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CODE_ACQUISITION_ORDERS],
  },
  {
    name: SUB_MENU_NAME.Makes,
    subMenu: makesSubMenu,
    accessCodes: [ACCESS_CODE_JOBS, ACCESS_CODE_JOB_ATTRIBUTES, ACCESS_CODE_JOB_SCHEDULER],
  },
  {
    name: SUB_MENU_NAME.Stock,
    subMenu: stockSubMenu,
    accessCodes: [
      ACCESS_CODE_WAREHOUSES,
      ACCESS_CODE_STOCK_TAKES,
      ACCESS_CODE_STOCK_RECEIVING,
      ACCESS_CODE_PRODUCTS,
      ACCESS_CODE_STOCK_TRANSFER,
      ACCESS_CODE_PRODUCT_ATTRIBUTES,
    ],
  },
  {
    name: SUB_MENU_NAME.Issues,
    subMenu: issuesSubMenu,
    accessCodes: [ACCESS_CODE_WARRANTY],
  },
  {
    name: SUB_MENU_NAME.Reports,
    subMenu: reportsSubMenu,
    accessCodes: [ACCESS_CODE_REPORTS],
  },
  {
    name: SUB_MENU_NAME.Contacts,
    subMenu: contactsSubMenu,
    accessCodes: [ACCESS_CODE_CUSTOMERS, ACCESS_CODE_SUPPLIERS, ACCESS_CODE_SERVICE_PROVIDERS],
  },
];
