import iBaseType from '../iBaseType';
import iProfileCompany from './iProfileCompany';

export const COMPANY_PREFERENCE_CODE_PRODUCT_FILM_CAL_SETTINGS = 'PRODUCT_FILM_CALC_SETTINGS';
export const COMPANY_PREFERENCE_CODE_JOB_FILM_CAL_SETTINGS = 'JOB_FILM_CALC_SETTINGS';
export const COMPANY_PREFERENCE_CODE_FORM_FILM_CAL_SETTINGS = 'FORM_FILM_CALC_SETTINGS';
export const COMPANY_PREFERENCE_CODE_BOM_FILM_CAL_SETTINGS = 'BOM_FILM_CALC_SETTINGS';
export const COMPANY_PREFERENCE_CODE_SO_SETTINGS = 'SO_SETTINGS';
export const COMPANY_PREFERENCE_CODE_PO_SETTINGS = 'PO_SETTINGS';
export const COMPANY_PREFERENCE_CODE_DN_SETTINGS = 'DN_SETTINGS';
export const COMPANY_PREFERENCE_CODE_AO_SETTINGS = 'AO_SETTINGS';
export const COMPANY_PREFERENCE_CODE_COC_SETTINGS = 'COC_SETTINGS';

export const COMPANY_PREFERENCE_KEY_COC_ID_MATERIAL = 'COC_ID_MATERIAL';

type iProfileCompanyPreference = iBaseType & {
  companyId: string;
  code: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  company?: iProfileCompany;
};

export default iProfileCompanyPreference;
