import React from 'react';
import { Label } from '@atlaskit/field-base';
import { Grid, GridColumn } from '@atlaskit/page';
import QtyUpdate from './QtyUpdate';
import { iDetailUpdateFn } from '../../../UITypes/types';

const QtyProcessing = ({
  qtyToMake,
  qtyTotal,
  unit,
  onUpdate,
  isDisabled,
}: {
  qtyToMake: number;
  qtyTotal: number;
  unit?: string;
  onUpdate: iDetailUpdateFn;
  isDisabled?: boolean;
}) => {
  return (
    <Grid layout={'fluid'}>
      <GridColumn medium={2.5}>
        <Label label={'Processing'} />
      </GridColumn>
      <GridColumn medium={3}>
        <div className={'large-text'}>
          <QtyUpdate
            qtyToMake={qtyToMake}
            qtyTotal={qtyTotal}
            unit={unit}
            onUpdate={onUpdate}
            isDisabled={isDisabled}
          />
        </div>
      </GridColumn>
    </Grid>
  );
};

export default QtyProcessing;
