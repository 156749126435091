/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import Button, { LoadingButton } from '@atlaskit/button';
import { Label } from '@atlaskit/field-base';
import InfoIcon from '@atlaskit/icon/glyph/info';
import Modal, {
  FooterComponentProps,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { CreatableSelect } from '@atlaskit/select';
import type { ValueType } from '@atlaskit/select/types';
import TextArea from '@atlaskit/textarea';
import Textfield from '@atlaskit/textfield';
import { G300 } from '@atlaskit/theme/colors';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/makeReduxStore';
import iContactCompany from '../../types/contactCompany/iContactCompany';
import iUser from '../../types/user/iUser';
import { FlexSpaceBetweenContainer } from '../contactCompany/style';
import { apiErrorToast } from '../toast/Toast';
import { iLabelValuePair } from '../UITypes/types';

const emailLineBreak = '%0D%0A';

const GeneralEmailBtn = ({
  orderNum,
  serviceFunc,
  recipientServiceFunc,
  children,
  emailSubjectTpl,
  emailBodyTpl,
  shouldWarningModalOpen = false,
  onWarning,
}: {
  orderNum: string;
  serviceFunc: () => Promise<string>;
  recipientServiceFunc: () => Promise<iContactCompany>;
  children: ReactNode;
  emailSubjectTpl: string;
  emailBodyTpl: string;
  shouldWarningModalOpen?: boolean;
  onWarning?: () => void;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const {
    firstName,
    lastName,
    company: { name: companyName },
  } = user as iUser;

  const [isPDFFetching, setIsPDFFetching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [emailSubject, setEmailSubject] = useState(
    emailSubjectTpl
      .replace('[REF_NUM]', orderNum)
      .replace('[COMPANY_NAME]', companyName),
  );
  const [emailBody, setEmailBody] = useState(
    emailBodyTpl
      .replace('[REF_NUM]', orderNum)
      .replace('[SENT_FROM]', `${firstName} ${lastName}`)
      .replace('[COMPANY_NAME]', companyName),
  );
  const [emailRecipients, setEmailRecipients] = useState<string[]>();
  const [contacts, setContacts] = useState<iLabelValuePair[]>();
  const [selectedContacts, setSelectedContacts] = useState<iLabelValuePair[]>();

  useEffect(() => {
    recipientServiceFunc().then(contactCompany => {
      if (contactCompany.email) {
        const contactsArr = [
          {
            label: contactCompany.email,
            value: contactCompany.email,
          },
        ];
        setContacts(contactsArr);
        setSelectedContacts(contactsArr);
        setEmailRecipients(contactsArr.map(c => c.value));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailOrder = () => {
    setIsPDFFetching(true);
    serviceFunc()
      .then(pdfLink => {
        setIsPDFFetching(false);
        setIsModalOpen(true);
        setPdfUrl(pdfLink);
        setEmailBody(emailBody.replace('[PDF_URL]', pdfLink));
      })
      .catch(apiErrorToast)
      .finally(() => {
        setIsPDFFetching(false);
      });
  };
  return (
    <>
      <LoadingButton
        className={'popup-item'}
        onClick={shouldWarningModalOpen ? onWarning : () => emailOrder()}
        isLoading={isPDFFetching}
        testId="print-order"
        isSelected
      >
        {children}
      </LoadingButton>
      {isModalOpen && (
        <ModalTransition>
          <Modal
            testId="email-modal"
            components={{
              Footer: (props: FooterComponentProps) => {
                return (
                  <ModalFooter {...props}>
                    <a href={pdfUrl} target="blank">
                      <FlexSpaceBetweenContainer>
                        <InfoIcon primaryColor={G300} label="Info" />
                        Download {orderNum}
                      </FlexSpaceBetweenContainer>
                    </a>
                    <div>
                      <Button
                        appearance="primary"
                        onClick={() => {
                          setIsModalOpen(false);
                          window.location.href = `mailto:${emailRecipients?.join(
                            ';',
                          )}?subject=${emailSubject}&body=${emailBody.replace(
                            /(?:\r|\n|\r\n)/g,
                            emailLineBreak,
                          )}`;
                          setSelectedContacts(contacts);
                          setEmailRecipients(
                            contacts ? contacts.map(c => c.value) : [],
                          );
                        }}
                        isDisabled={
                          emailRecipients === undefined ||
                          emailRecipients.length === 0
                        }
                      >
                        Confirm
                      </Button>
                      <Button
                        appearance={props.appearance}
                        onClick={props.onClose}
                      >
                        Close
                      </Button>
                    </div>
                  </ModalFooter>
                );
              },
            }}
            onClose={() => setIsModalOpen(false)}
            heading="Please select recipients"
          >
            <>
              <Label label="Recipients" />
              <CreatableSelect
                className="multi-select"
                classNamePrefix="react-select"
                options={contacts}
                value={selectedContacts}
                isMulti
                isSearchable
                placeholder="Please choose recipients from the list"
                onChange={(selectedArr: ValueType<iLabelValuePair>) => {
                  setSelectedContacts(
                    selectedArr ? (selectedArr as iLabelValuePair[]) : [],
                  );
                  setEmailRecipients(
                    selectedArr
                      ? (selectedArr as iLabelValuePair[]).map(
                          (select: iLabelValuePair) => select.value,
                        )
                      : [],
                  );
                }}
                onCreateOption={(inputVal: string) => {
                  const mergedContacts = selectedContacts
                    ? [
                        ...selectedContacts,
                        {
                          label: inputVal,
                          value: inputVal,
                        },
                      ]
                    : [
                        {
                          label: inputVal,
                          value: inputVal,
                        },
                      ];
                  setSelectedContacts(mergedContacts);
                  setEmailRecipients(
                    mergedContacts.map(select => select.value),
                  );
                }}
              />
              <Label label="Subject" />
              <Textfield
                value={emailSubject}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmailSubject(e.target.value)
                }
              />
              <Label label="Body" />
              <TextArea
                value={emailBody}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setEmailBody(e.target.value)
                }
              />
            </>
          </Modal>
        </ModalTransition>
      )}
    </>
  );
};

export default GeneralEmailBtn;
