import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import { uploadImage } from '../../services/AssetService';
import { iImage, iImageCallbackType } from './ImageTypes';
import { apiErrorToast } from '../toast/Toast';

const options = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
// upload one image
const uploadSingleImage = async (file: File, callback: iImageCallbackType) => {
  const id = uuidv4();
  const reader = new FileReader();
  reader.onload = e => {
    if (e && e.target && e.target.result) {
      callback({
        id,
        uploading: true,
        rawSrc: e.target.result as string,
      });
    }
  };
  reader.readAsDataURL(file);
  try {
    const compressedFile = await imageCompression(file, options);
    const formData = new FormData();
    formData.append('image', compressedFile);
    const data = await uploadImage(formData);

    if (data && data.asset && data.asset.url) {
      callback({
        id,
        uploading: false,
        url: data.asset.url as string,
      });
    }
  } catch (error) {
    callback({ id, uploading: false });
    apiErrorToast(error);
  }
};
export const onImagesSelected = (
  files: FileList | null,
  callback: (img: iImage) => void,
) => {
  if (files) {
    Array.from(files).forEach((file: File) =>
      uploadSingleImage(file, callback),
    );
  }
};
