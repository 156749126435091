import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const updateContact = (
  contactId: string,
  updateData: iKeyValuePairs,
) => {
  return AppService.put(`/contact/${contactId}`, updateData).then(
    ({ data }) => data,
  );
};
export const createContact = (config: iKeyValuePairs) => {
  return AppService.post('/contact', config).then(({ data }) => data);
};
export const removeContact = (id: string) => {
  return AppService.delete(`/contact/${id}`).then(({ data }) => data);
};
export const getContactList = (config?: iParams) => {
  return AppService.get('/contact', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const checkDuplicateContact = (targetValue: string, excludeId = '') => {
  return AppService.get('/contact/isIdDuplicate', {
    targetValue,
    excludeId,
  }).then(({ data }) => data);
};

export const validateContactPassword = (password: string) => {
  return AppService.get('/contact/validatePassword', { password }).then(
    ({ data }) => data,
  );
};

export default {
  updateContact,
  createContact,
  removeContact,
  getContactList,
  checkDuplicateContact,
  validateContactPassword,
};
