/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { iKeyValuePairs } from '../../../../../shared/UITypes/types';
import iBomItemAttribute from '../../../../../types/product/iBomItemAttribute';
import iProduct from '../../../../../types/product/iProduct';
import { createBOM } from '../../../../../services/BOMService';
import { handleNullException } from '../../../../../services/UtilsService';
import { AVOID_PAGINATION_PER_PAGE } from '../../../../../shared/constants/AsyncConstants';
import {
  createBomAttributeValue,
  getBomItemAttributes,
} from '../../../../../services/BomAttributeService';
import {
  iImportDataResult,
  iPrefills,
  iTemplateColumn,
} from '../../shared/DataImport.types';

export const getBOMFlexColumnsFn = async () => {
  const { data } = await getBomItemAttributes({
    include: '',
    perPage: AVOID_PAGINATION_PER_PAGE,
    currentPage: '1',
  });
  return (data as Array<iBomItemAttribute>).map((item: iBomItemAttribute) => ({
    name: item.name,
    accessor: 'bomItemAttributeId',
    defaultValue: item.id,
  }));
};

export const onSubmitBOM = async (
  pageName: string,
  data: any[][],
  recordResult: (eachResult: iImportDataResult) => void,
  previewResults: Array<iImportDataResult>,
  columns: Array<iTemplateColumn>,
  prefills?: any,
) => {
  const productsInDatabase: Array<iProduct> = handleNullException(
    prefills,
    'products',
  );
  for (let i = 0; i < data.length; i++) {
    if (previewResults[i].skipped) {
      recordResult(previewResults[i]);
      //  eslint-disable-next-line
      continue;
    }
    let targetProduct: iProduct | undefined;
    try {
      const { bomItem, bomItemAttribute } = columns.reduce(
        (
          acc: {
            bomItem: iKeyValuePairs;
            bomItemAttribute: Array<iKeyValuePairs>;
          },
          curColumn: iTemplateColumn,
          curIndex: number,
        ) => {
          const cellValue = data[i][curIndex];
          const { accessor, defaultValue } = curColumn;
          switch (accessor) {
            case 'bomItemAttributeId': {
              if (!cellValue) return acc;
              return {
                bomItem: acc.bomItem,
                bomItemAttribute: [
                  ...acc.bomItemAttribute,
                  {
                    [accessor]: defaultValue,
                    value: cellValue,
                  } as iKeyValuePairs,
                ],
              };
            }
            case 'productId': {
              targetProduct = productsInDatabase.find(
                (item: iProduct) => item.productCode === cellValue,
              );
              return {
                bomItem: {
                  ...acc.bomItem,
                  [accessor]: productsInDatabase.find(
                    (item: iProduct) => item.productCode === cellValue,
                  )?.id,
                },
                bomItemAttribute: acc.bomItemAttribute,
              };
            }
            case 'materialId':
              return {
                bomItem: {
                  ...acc.bomItem,
                  [accessor]: productsInDatabase.find(
                    (item: iProduct) => item.productCode === cellValue,
                  )?.id,
                },
                bomItemAttribute: acc.bomItemAttribute,
              };

            case 'materialQty':
              return {
                bomItem: { ...acc.bomItem, [accessor]: cellValue },
                bomItemAttribute: acc.bomItemAttribute,
              };

            default:
              return acc;
          }
        },
        { bomItem: {}, bomItemAttribute: [] },
      );
      //  eslint-disable-next-line
      const createdBomItem: iBomItemAttribute = await createBOM({
        ...bomItem,
        sortOrder: 1,
      });
      for (let j = 0; j < bomItemAttribute.length; j++) {
        //  eslint-disable-next-line
        await createBomAttributeValue({
          ...bomItemAttribute[j],
          bomItemId: createdBomItem.id,
        });
      }
      recordResult({ created: targetProduct });
    } catch (error) {
      const errorMessage =
        handleNullException(error, 'response.data.message') ||
        handleNullException(error, 'response.data.error') ||
        'Unfortunately, it fails';
      recordResult({ failed: true, messages: [errorMessage] });
    }
  }
};

export const previewBOM = (
  data: any[][],
  configColumns: Array<iTemplateColumn>,
  //    eslint-disable-next-line
  prefills: iPrefills,
) => {
  const productsInDatabase = handleNullException(prefills, 'products');
  const previewResults: Array<iImportDataResult> = data.map((row: any[]) => {
    const messages = configColumns.map(
      (column: iTemplateColumn, columnIndex: number) => {
        const { name, validation, errorMessage, accessor } = column;
        const cell = row[columnIndex];
        if (validation && !validation(cell)) {
          return errorMessage;
        }
        if (accessor === 'productId' || accessor === 'materialId') {
          const product: iProduct | undefined = productsInDatabase.find(
            (item: iProduct) => item.productCode === cell,
          );
          if (!product) {
            return `${name} not existed in database`;
          }
          if (accessor === 'productId' && product && !product.isForMake) {
            return `${name} is not for make`;
          }
          return undefined;
        }
        return undefined;
      },
    );
    const errorMessages = messages.filter((item: string | undefined) => item);
    if (errorMessages.length > 0) {
      return { skipped: true, messages: errorMessages as Array<string> };
    }
    return { previewSuccess: true };
  });
  return previewResults;
};
