import React from 'react';
import { printAcquisitionOrder } from '../../../services/PrintService';
import {
  assembleAddress,
  formatDate,
  getCategoryCode,
  handleMoney,
  handleNullException,
} from '../../../services/UtilsService';
import GeneralPrintBtn from '../../../shared/buttons/GeneralPrintBtn';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import PopupContent from '../../../shared/popup/GeneralPopupContent';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import StatusLozenge from '../../../shared/statusComponent/StatusLozenge';
import { PrintBtnName } from '../../../shared/styles/styles';
import { iConfigColumn } from '../../../shared/UITypes/types';
import iAcquisitionOrder from '../../../types/acquisitionOrder/iAcquisitionOrder';
import iEntityCategory from '../../../types/status/iEntityCategory';

export const getRows = ({
  data,
  onOpenDeleteModal,
  columns,
  categories,
}: {
  data: Array<iAcquisitionOrder>;
  onOpenDeleteModal?: (id: string) => void;
  columns: Array<iConfigColumn>;
  categories: Array<iEntityCategory>;
}) => {
  return data.map((item: iAcquisitionOrder) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'date':
          return {
            key: handleNullException(item, key),
            content: formatDate(handleNullException(item, key), 'Do MMM YYYY'),
          };
        case 'money':
          return {
            key: handleNullException(item, key),
            content: handleMoney(item, key, 2),
          };
        case 'address':
          return {
            key: handleNullException(item, key),
            content: assembleAddress(handleNullException(item, key)),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
              />
            ),
          };
        case 'status':
          return {
            key: handleNullException(item, `${key}.id`),
            content: (
              <StatusLozenge
                status={handleNullException(item, key)}
                categoryCode={getCategoryCode(handleNullException(item, key), categories)}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        case 'operation':
          return {
            content: (
              <MoreIconPopup>
                <PopupContent id={item.id} onOpenDeleteModal={onOpenDeleteModal}>
                  <GeneralPrintBtn serviceFunc={() => printAcquisitionOrder(item.id)}>
                    <PrintBtnName>Print</PrintBtnName>
                  </GeneralPrintBtn>
                  {/* <CreatePO
                    id={item.id}
                    onOpenCreatePOModal={onOpenCreatePOModal}
                  /> */}
                </PopupContent>
              </MoreIconPopup>
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'admin-despatch-note-row' };
  });
};
