import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iItemLocation from '../../../types/warehouse/iItemLocation';
import { handleNullException } from '../../../services/UtilsService';
import { iConfigColumn } from '../../../shared/UITypes/types';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import InputWithController from '../../../shared/form/hookForms/InputWithController';

export const getRows = ({
  columns,
  data,
  onChange,
  control,
  errors,
  records,
}: {
  columns: Array<iConfigColumn>;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  data?: Array<iItemLocation>;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  records: Array<iItemLocationTransfer>;
}) => {
  if (typeof data === 'undefined') return [];
  return data.map((item: iItemLocation) => {
    const cells = columns.map((column: iConfigColumn, index: number) => {
      const { key, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'input-with-controller':
          return {
            content: (
              <div style={{ marginTop: '-8px' }}>
                <InputWithController
                  label={''}
                  isNumeric
                  isRequired={false}
                  name={handleNullException(item, 'id')}
                  defaultValue={records
                    .find(
                      (record: iItemLocationTransfer) => record.id === item.id,
                    )
                    ?.movingQty.toString()}
                  onChange={onChange}
                  control={control}
                  errors={errors}
                  autoFocus={index === 0}
                  isDisabled={Number(handleNullException(item, 'qty')) <= 0}
                  range={{
                    min: 0,
                    max: Number(handleNullException(item, 'qty')),
                  }}
                />
              </div>
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};
