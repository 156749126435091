import React from 'react';
import { useParams } from 'react-router-dom';
import CCPProdContComp from '../../../../../../shared/CCPProductContactCompany/CCPProdContComp';
import { SpaceVerticalContainer } from '../../../../../../shared/styles/styles';
import iParamTypes from '../../../../../../types/iParamTypes';
import iProduct from '../../../../../../types/product/iProduct';
import ProdPurchasePOs from './ProdPurchasePOs';

function ProdPurchase({ product, isDisabled }: { product?: iProduct; isDisabled?: boolean }) {
  const { id } = useParams<iParamTypes>();
  const productId = product?.id || id;

  return (
    <>
      <CCPProdContComp
        startFieldId={id}
        fixedProduct={product}
        isCustomer={false}
        canUpdate={!isDisabled}
        canCreate={!isDisabled}
        canDelete={!isDisabled}
      />
      <SpaceVerticalContainer className={'five'} />
      <ProdPurchasePOs productId={productId} />
    </>
  );
}

export default ProdPurchase;
