import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '@atlaskit/spinner';
import BackendPage from '../../../layout/BackendPage';
import Sidebar from '../sidebar/Sidebar';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import { PageTitle, SpaceWrapper } from '../../../shared/styles/styles';
import { RootState } from '../../../redux/makeReduxStore';
import BillingPlanOverview from './components/BillingPlanOverview';
import BillingSubscriptionService from '../../../services/billing/BillingSubscriptionService';
import iBillingSubscription from '../../../types/billing/iBillingSubscription';
import BillingPaymentInfo from './components/BillingPaymentInfo';
import BillingHistory from './components/BillingHistory';

const PageHeader = () => (
  <>
    <BreadcrumbInHeader subName={'Billing'} />
    <PageTitle data-testid={'page-title'}>Plan Overview</PageTitle>
  </>
);

const BillingHomePage = () => {
  const [loading, setLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState<iBillingSubscription[]>(
    [],
  );
  const { user } = useSelector((s: RootState) => s.auth);
  const company = user?.company;

  useEffect(() => {
    let isCancelled = false;
    if (loading !== true || !company) {
      return undefined;
    }
    const fetchSubscription = () => {
      BillingSubscriptionService.getAllSubscriptionsForCompany(company.id).then(
        resp => {
          if (isCancelled) {
            return;
          }
          setSubscriptions(resp);
          setLoading(false);
        },
      );
    };
    fetchSubscription();
    return () => {
      isCancelled = true;
    };
  }, [loading, company]);

  const getContent = () => {
    if (loading === true) {
      return <Spinner />;
    }
    return (
      <>
        <SpaceWrapper className={'space-bottom-5'}>
          <BillingPlanOverview
            subscription={{ ...subscriptions[0], company }}
          />
        </SpaceWrapper>
        <SpaceWrapper className={'space-bottom-5'}>
          <BillingPaymentInfo />
        </SpaceWrapper>
        <SpaceWrapper className={'space-bottom-5'}>
          <BillingHistory />
        </SpaceWrapper>
      </>
    );
  };

  if (!company) {
    return null;
  }
  return (
    <BackendPage pageHeader={<PageHeader />} leftMenu={<Sidebar />}>
      {getContent()}
    </BackendPage>
  );
};

export default BillingHomePage;
