import React, { useState } from 'react';
import Popup from '@atlaskit/popup';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import { ButtonItem, Section } from '@atlaskit/menu';
import { iMenuItem } from '../UITypes/types';
import ModuleEnabledWrapper from '../modulize/ModuleEnabledWrapper';
import RestrictedAccessDiv from '../UserAccess/RestrictedAccessDiv';

type iState = {
  isOpen: boolean;
};
const initialState: iState = {
  isOpen: false,
};

const PopupItemWrapper = styled.div``;

const TopNavPopup = ({ name, subMenu }: { name: string; subMenu: Array<iMenuItem> }) => {
  const [state, setState] = useState(initialState);
  const history = useHistory();

  const showPopup = (isOpen: boolean) => {
    setState({
      ...state,
      isOpen,
    });
  };

  const getMenuItems = () => {
    return (
      <PopupItemWrapper data-testid={`nav-${name}`}>
        {subMenu.map((sub: iMenuItem, index: number) => {
          const buttonItem = (
            <Section hasSeparator={index !== 0 && sub.hasSeparator} key={`section-${name}-${sub.label}`}>
              <ButtonItem onClick={() => history.push(sub.value)} testId={`nav-${name}-${sub.label}`}>
                {sub.label}
              </ButtonItem>
            </Section>
          );
          // not every menu item is related with module
          return (
            <RestrictedAccessDiv
              expectedUserAccessCodes={sub.accessCodes || []}
              hideWhenDenied
              expectedCodeMatchedWithAny
              key={`module-wrapper-${name}-${sub.label}`}
            >
              {sub.moduleCode ? (
                <ModuleEnabledWrapper checkedModule={sub.moduleCode}>{buttonItem}</ModuleEnabledWrapper>
              ) : (
                buttonItem
              )}
            </RestrictedAccessDiv>
          );
        })}
      </PopupItemWrapper>
    );
  };

  return (
    <Popup
      isOpen={state.isOpen}
      placement={'bottom-start'}
      content={getMenuItems}
      onClose={() => showPopup(false)}
      trigger={(
        triggerProps: any, //eslint-disable-line
      ) => (
        <PrimaryDropdownButton
          {...triggerProps} //eslint-disable-line
          tooltip={''}
          onClick={() => showPopup(!state.isOpen)}
          testId={`nav-${name}-popup-trigger`}
        >
          {name}
        </PrimaryDropdownButton>
      )}
    />
  );
};
export default TopNavPopup;
