import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import styled from 'styled-components';

import iSalesOrderItem from '../../../../../../types/sales/iSalesOrderItem';
import iEntityCategory from '../../../../../../types/status/iEntityCategory';
import { getHeads, getRows } from './SoTableHelper';

const TableWrapper = styled.div`
  margin: 1rem auto 3rem;
`;

const ProdSellSoTable = ({
  soItems,
  isLoading,
  columns,
  categories,
}: {
  soItems: Array<iSalesOrderItem>;
  isLoading: boolean;
  columns: Array<string>;
  categories: Array<iEntityCategory>;
}) => {
  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(columns, 'product-sell-so')}
        rows={getRows(soItems, columns, categories)}
        testId={'product-sell-so'}
        isLoading={isLoading}
      />
    </TableWrapper>
  );
};
export default ProdSellSoTable;
