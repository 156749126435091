import { AOS_URL, PRODUCTS_URL, PURCHASES_URL, SUPPLIER_URL } from '../../../shared/UrlMap';
import { iConfigColumn } from '../../despatchNote/shared/DispatchNote.type';

export const AO_PAGE_NAME = 'Acquisition Orders';
export const AO_DETAIL_PAGE_NAME = 'Acquisition Order';
export const AO_SHORTHAND = 'AO';
export const PURCHASED_PRODUCTS_TABLE_NAME = 'AO-Purchased-Products';
export const CREATE_PO = 'Create PO';
export const CREATE_PO_WARNING_CONTENT =
  'Not allow to create any purchase order against an empty acquisition order, please add some products';
export const CREATE_PO_WARNING_HEAD = 'Invalid to raise a PO against an empty AO';

export const AO_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'AO No.',
    key: 'orderNumber',
    dataSource: ['id'],
    isCompulsory: true,
    isSortable: true,
    type: 'link',
    urlPrefix: AOS_URL,
    isSelected: true,
    group: '',
  },
  {
    name: 'Status',
    key: 'status',
    dataSource: ['status'],
    type: 'status',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Reference',
    key: 'supplierRef',
    dataSource: [''],
    type: 'text',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Supplier',
    key: 'supplier.name',
    dataSource: ['supplier.id'],
    urlPrefix: SUPPLIER_URL,
    type: 'link',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'ETA',
    key: 'eta',
    dataSource: [''],
    type: 'date',
    isSelected: true,
    group: '',
    isSortable: true,
  },
  {
    name: 'Order Date',
    key: 'orderDate',
    dataSource: [''],
    type: 'date',
    isSelected: true,
    group: '',
    isSortable: true,
  },
  {
    name: 'Total Amt.',
    key: 'totalAmt',
    dataSource: [''],
    type: 'money',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
    isSelected: true,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];

export const AO_ITEM_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.id', 'product.name'],
    isCompulsory: true,
    type: 'link-secondary',
    urlPrefix: PRODUCTS_URL,
  },
  {
    name: 'Supplier Product Code',
    key: 'supplierProductCode',
    dataSource: [''],
    type: 'text',
    isSelected: true,
  },
  {
    name: 'Description',
    key: 'description',
    dataSource: [''],
    type: 'inlineEdit-text',
    isSelected: true,
  },
  {
    name: 'Unit Price',
    key: 'unitPrice',
    dataSource: ['unitPriceMeasurement.shortName', 'product.measurement.shortName'],
    type: 'inlineEdit-money',
    isSelected: true,
  },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: ['unitConversion', 'measurement.shortName', 'product.measurement.shortName'],
    type: 'inlineEdit-conversion',
    isSelected: true,
  },
  {
    name: 'Confirmed Qty',
    key: 'confirmedQty',
    dataSource: ['unitConversion', 'measurement.shortName', 'product.measurement.shortName'],
    type: 'inlineEdit-conversion',
    isSelected: true,
  },

  {
    name: 'Purchased Qty',
    key: 'purchasedQty',
    dataSource: ['unitConversion', 'measurement.shortName', 'product.measurement.shortName'],
    type: 'conversion-text',
    isSelected: true,
  },
  {
    name: 'Disc %',
    key: 'discountPercentage',
    dataSource: [''],
    type: 'inlineEdit-percentage',
    isSelected: true,
  },
  {
    name: 'Tax',
    key: 'taxRateId',
    dataSource: [''],
    type: 'taxRate-select',
    isSelected: true,
  },
  {
    name: 'Total (Inc GST)',
    key: 'totalPrice',
    dataSource: [],
    type: 'money',
    isSelected: true,
  },
  {
    name: '',
    key: 'delete',
    dataSource: ['id'],
    type: 'delete',
    isCompulsory: true,
  },
];

export const PURCHASED_PRODUCTS_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.id', 'product.name'],
    isCompulsory: true,
    type: 'link-secondary',
    urlPrefix: PRODUCTS_URL,
  },
  {
    name: 'PO No.',
    key: 'purchaseOrder.orderNumber',
    dataSource: ['purchaseOrder.id'],
    type: 'link',
    isSelected: true,
    group: '',
    isSortable: false,
    urlPrefix: PURCHASES_URL,
  },
  {
    name: 'Supplier Product Code',
    key: 'supplierProductCode',
    dataSource: [''],
    type: 'text',
    isSelected: true,
  },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: ['unitConversion', 'measurement.shortName', 'product.measurement.shortName'],
    type: 'conversion-text',
    isSelected: true,
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
    isSelected: true,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];
