import styled from 'styled-components';

export const ReadImportWrapper = styled.div`
  width: 50%;
  .reader-trigger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justity-content: center;
    .description {
      font-size: 20px;
      color: #172b4d;
    }
    .size {
      font-size: 16px;
      color: #172b4d;
    }
  }
`;

export const PreviewTableWrapper = styled.div`
  display: block;
  overflow-x: auto;
  th[data-testid='Row No.--head--cell'],
  th[data-testid='status--head--cell'] {
    position: -webkit-sticky;
    position: sticky;
  }
`;

export const DataInfoWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  &.success {
    .lozenge {
      color: #14da0c;
    }
  }
  &.error {
    .lozenge {
      color: #da290c;
    }
  }
  &.warning {
    .lozenge {
      color: #f6ae27;
    }
  }
`;
// file in csv reader, z-index is 10
export const SelectWidthWrapper = styled.div`
  min-width: 200px;
  max-width: 300px;
  z-index: 11;
`;

export const SectionMessageWidthWrapper = styled.div`
  width: 60%;
`;
