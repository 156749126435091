import moment from 'moment';

//  eslint-disable-next-line
import {
  iJsContext,
  iScheduledState,
  iSupportState,
  iUnScheduledState,
  iDisabledState,
  iUnscheSortOption,
} from './Schedule.type';

export const GAPS = [
  { label: 'Week', value: '6' },
  { label: 'Month', value: '29' },
];

export const DATE_SHIFT_CONNECTOR = '*';
export const PAGE_NAME = 'Jobs Scheduler';
export const UNSCHEDULED_TITLE = 'Unscheduled jobs';
export const DAY_SHIFT = 'day';
export const NIGHT_SHIFT = 'night';
export const UNSCHEDULED = 'unscheduled';

export const unScheduledInitialState: iUnScheduledState = {
  isShow: false,
  isLoading: false,
  jobs: [],
};
export const scheduledInitialState: iScheduledState = {
  isLoading: false,
  data: [],
  shiftsSort: [],
};
export const disabledInitialState: iDisabledState = {
  isModalOpen: false,
  isConfirming: false,
  isDisable: true,
  disabledShifts: [],
};
export const supportInitialState: iSupportState = {
  machines: [],
  jobAttributes: [],
  prodAttributes: [],
  statuses: [],
};
export const jsContextInitialState: iJsContext = {
  ...supportInitialState,
  unSchState: unScheduledInitialState,
  schState: scheduledInitialState,
  disabledShifts: [],
  columns: [],
  columnLists: [],
  periods: GAPS,
  selectedPeriod: GAPS[0].value,
  startDate: moment().toString(),
};
export const DEFAULT_COLUMNS = [];
export const COLUMN_NAME = 'job-schedule-columns';
export const POSITION_FIXED_COLUMNS = [
  'status',
  'product name',
  'order',
  'customer',
  // 'qty total',
  // 'expected delivery date',
  'EDD',
  'qty remaining',
];
//  scheduled at will change after move/reorder
// 'scheduled at',

export const UNSCHEDULED_SORT_ITEMS: Array<iUnscheSortOption> = [
  {
    label: 'EDD',
    value: 'edd,salesOrder.edd',
    type: 'date',
  },
  { label: 'status', value: 'status.sortOrder', type: 'number' },
  {
    label: 'customer name',
    value: 'customerName,salesOrder.customer.name',
    type: 'text',
  },
  {
    label: 'customer ref',
    value: 'customerOrderRef,salesOrder.customerRef',
    type: 'text',
  },
];

export const UNSCHEDULED_SORT_KEY = 'unschedule_sort';
