import React, { useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import DeleteModalContent from './DeleteModalContent';
import { randomIntFromInterval } from '../../services/MathHelper';

const ModalContainer = styled.div`
  min-height: 40px;
`;

type iParam = {
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  answer?: string;
  isConfirming?: boolean;
  isDeletable?: boolean;
  warningMsg?: string;
};

const DeleteByRetype = ({
  isOpen,
  onClose,
  onConfirm,
  answer = `${randomIntFromInterval(9999, 999999)}`,
  isConfirming,
  isDeletable = true,
  warningMsg,
}: iParam) => {
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  if (!isOpen) return null;
  const getModalContent = () =>
    !isDeletable ? (
      <Modal
        testId="delete-retype-modal-warningMsg"
        actions={[{ text: 'Cancel', onClick: onClose }]}
        onClose={onClose}
        heading={`Warning: ${warningMsg}`}
      />
    ) : (
      <Modal
        testId="delete-retype-modal"
        actions={[
          {
            text: 'Delete',
            onClick: onConfirm,
            isDisabled: !isCorrectAnswer,
            appearance: 'danger',
            isLoading: isConfirming,
          },
          { text: 'Cancel', onClick: onClose },
        ]}
        onClose={onClose}
        heading={'Are you sure to delete it?'}
      >
        <ModalContainer className={'delete-retype-container'}>
          <DeleteModalContent answer={answer} onTypeAnswer={setIsCorrectAnswer} />
        </ModalContainer>
      </Modal>
    );

  return <ModalTransition>{isOpen && getModalContent()}</ModalTransition>;
};

export default DeleteByRetype;
