/* eslint-disable import/no-cycle */
import { emailPattern } from '../../../../shared/constants/RegexConstants';
import { iTemplateColumn } from './DataImport.types';
import {
  isBooleanValidation,
  isNumberValidation,
  isRequiredNumberValidation,
  isRequiredValidation,
} from './DataImportValidation.util';

export const FIXED_COLUMNS: Array<iTemplateColumn> = [
  {
    name: 'Roll No.',
    accessor: 'index',
  },
  {
    name: 'Status',
    accessor: 'status',
  },
];
export const ADDRESS_CONFIG: Array<iTemplateColumn> = [
  {
    name: 'Street',
    accessor: 'street',
  },
  {
    name: 'Suburb',
    accessor: 'suburb',
  },
  {
    name: 'State',
    accessor: 'state',
  },
  {
    name: 'Postcode',
    accessor: 'postcode',
    ...isNumberValidation('Postcode'),
  },
  {
    name: 'Country',
    accessor: 'country',
    defaultValue: 'Australia',
  },
];
export const CONTACTCOMPANY_CONFIG: Array<iTemplateColumn> = [
  {
    name: 'Name(*)',
    accessor: 'name',
    validation: (item: string | number) => !!item,
    errorMessage: 'Name(*) is required',
  },
  {
    name: 'Email',
    accessor: 'email',
    validation: (item: string | number) => !!emailPattern.test(item.toString()),
    errorMessage: 'Invalid email address',
  },
  {
    name: 'Phone',
    accessor: 'phone',
  },
  {
    name: 'ABN',
    accessor: 'abn',
  },
  ...ADDRESS_CONFIG,
];

export const CONTACTCOMPANY_TEMPLATE_DATA = [
  'Sysbox Pty Ltd',
  'leilei@gmail.com',
  '04123456789',
  '041234567',
  'unit 108, 45 gilby road',
  'Mount Waverley',
  'Vic',
  '3149',
  'Au',
];

export const PRODUCT_CONFIG: Array<iTemplateColumn> = [
  {
    name: 'ProductCode(*)',
    accessor: 'productCode',
    ...isRequiredValidation('ProductCode(*)'),
  },
  {
    name: 'ProductName',
    accessor: 'name',
  },
  {
    name: 'ShortDescription',
    accessor: 'shortDescription',
  },
  {
    name: 'UnitPrice',
    accessor: 'unitPrice',
    ...isNumberValidation('UnitPrice'),
  },
  {
    name: 'UOM',
    accessor: 'measurementId',
    errorMessage: 'UOM not existed in database',
  },
  {
    name: 'StockOnHand',
    accessor: 'stockOnHand',
    ...isNumberValidation('StockOnHand'),
  },
  {
    name: 'TotalValue($)',
    accessor: 'totalValue',
    ...isNumberValidation('TotalValue($)'),
  },
  {
    name: 'IsForSell',
    accessor: 'isForSell',
    ...isBooleanValidation('IsForSell'),
  },
  {
    name: 'IsForPurchase',
    accessor: 'isForPurchase',
    ...isBooleanValidation('IsForPurchase'),
  },
  {
    name: 'IsForMake',
    accessor: 'isForMake',
    ...isBooleanValidation('IsForMake'),
  },
  {
    name: 'IsForWaste',
    accessor: 'isForWaste',
    ...isBooleanValidation('IsForWaste'),
  },
];

export const PRODUCT_TEMPLATE_DATA = [
  // code
  'Sysbox Pty Ltd-Code',
  // name
  'Sysbox Pty Ltd-Name',
  // shortDescription
  'most popular sass',
  // unitPrice
  10,
  // uom
  'kg',
  // stockOnHand
  12,
  // totalValue
  1200.12,
  // isForSell
  'True',
  // isForPurchase
  'True',
  // isForMake
  'False',
  // isForWaste
  'False',
];

export const BOM_CONFIG: Array<iTemplateColumn> = [
  {
    name: 'ProductCode(*)',
    accessor: 'productId',
    ...isRequiredValidation('ProductCode(*)'),
  },
  {
    name: 'Material ProductCode(*)',
    accessor: 'materialId',
    ...isRequiredValidation('Material ProductCode(*)'),
  },
  {
    name: 'Material Amount to make a product(*)',
    accessor: 'materialQty',
    ...isRequiredNumberValidation('Material Amount'),
  },
];

export const BOM_TEMPLATE_DATA = [
  // product code
  'ProductCode',
  // material code
  'MaterialProductCode',
  // amount
  '0.2',
];
