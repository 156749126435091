import React from 'react';
import Avatar from '@atlaskit/avatar';
import { ButtonGroup, LoadingButton } from '@atlaskit/button';
import useAttachmentHook from '../../../../shared/hooks/useAttachmentHook/useAttachmentHook';
import iIssueInvestigation from '../../../../types/NCR/iIssueInvestigation';
import { FlexContainer, LabelValue } from '../../../../shared/styles/styles';
import { formatDate, handleNullException } from '../../../../services/UtilsService';

const InvestigationItem = ({
  investigation,
  isConfirming,
  onOpenDelete,
  //  eslint-disable-next-line
  onEdit,
  canDelete = true,
  canUpdate = true,
}: {
  investigation: iIssueInvestigation;
  isConfirming?: boolean;
  onOpenDelete: (id: string) => void;
  onEdit: (value?: string) => void;
  canDelete?: boolean;
  canUpdate?: boolean;
}) => {
  const { getUploader, getThumbnail } = useAttachmentHook({
    entityId: investigation.id,
    entityName: 'IssueInvestigation',
    thumbnailSize: 'small',
  });
  const getHeader = () => {
    return (
      <div className={'comment-item-header'}>
        <span className={'item-firstName'}>{handleNullException(investigation, 'updatedBy.firstName')}</span>
        <span> added @ </span>
        <span className={'item-date'}>{formatDate(investigation.updatedAt) || ''}</span>
      </div>
    );
  };
  return (
    <>
      <FlexContainer>
        <div className={'item-avatar'}>
          <Avatar
            appearance="circle"
            src={handleNullException(investigation, 'updatedBy.userAvatarUrl')}
            size="medium"
            testId={'item-avatar'}
          />
        </div>
        <div className={'comment-item-view'}>
          <FlexContainer>
            {getHeader()}
            <ButtonGroup>
              {canUpdate && getUploader()}
              {canDelete && (
                <LoadingButton
                  onClick={() => onOpenDelete(investigation.id)}
                  appearance="subtle-link"
                  spacing="compact"
                  className={'delete-button'}
                  isLoading={isConfirming}
                >
                  Delete
                </LoadingButton>
              )}
            </ButtonGroup>
          </FlexContainer>
          <LabelValue>{investigation.comments}</LabelValue>
          {getThumbnail(!canUpdate)}
        </div>
      </FlexContainer>
    </>
  );
};

export default InvestigationItem;
