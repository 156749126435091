/* eslint-disable consistent-return */
/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import InlineEdit from '@atlaskit/inline-edit';
import { ErrorMessage } from '@atlaskit/form';
import { emailPattern } from '../constants/RegexConstants';
import InlineEditReadView from './InlineEditReadView';
import { isStrNumeric } from '../../services/UtilsService';
import MathHelper from '../../services/MathHelper';

type iState = {
  value: string;
  focused: boolean;
};
const initialState: iState = {
  value: '',
  focused: false,
};
const DivWrapper = styled.div``;
type iInlineEdit = {
  name: string;
  defaultValue?: string;
  label?: string;
  onConfirm?: (name: string, newValue: string) => void;
  prefix?: string | React.ReactChild;
  postfix?: string;
  needFormatValue?: boolean;
  hideActionButtons?: boolean;
  isRequired?: boolean;
  isNumeric?: boolean;
  isEmail?: boolean;
  isDisabled?: boolean;
  min?: { minValue: number };
  range?: { min: number; max: number };
  testId?: string;
  isLargeText?: boolean;
  isUnitConversion?: boolean;
  contactCompanyUnit?: string;
  productUnit?: string;
  conversion?: string;
  multiplier?: number;
  isZeroNull?: boolean;
  editingPostfix?: string;
};
export default ({
  name,
  defaultValue,
  label,
  onConfirm,
  prefix,
  postfix,
  needFormatValue = false,
  hideActionButtons = false,
  isRequired = false,
  isNumeric = false,
  isEmail = false,
  isDisabled = false,
  min,
  range,
  testId = 'inline-edit',
  isLargeText = false,
  isUnitConversion = false,
  contactCompanyUnit,
  productUnit,
  conversion,
  multiplier,
  isZeroNull = false,
  editingPostfix,
}: iInlineEdit) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(s => ({
      ...s,
      value: defaultValue || initialState.value,
    }));
  }, [defaultValue]);

  // eslint-disable-next-line
  const changeValue = (value: any) => {
    // value is null or is default value
    // don't change it
    if ((!value && !defaultValue) || value === defaultValue) {
      setState({
        ...state,
        focused: false,
      });
      return;
    }

    if (isZeroNull && Number(value) === 0) {
      setState(preState => ({
        ...preState,
        value: '',
        focused: false,
      }));
    } else {
      setState(preState => ({
        ...preState,
        value,
        focused: false,
      }));
    }

    if (typeof onConfirm === 'function') {
      if (isStrNumeric(value) && typeof multiplier !== 'undefined') {
        const valueToSave = Number(value) * multiplier;
        onConfirm(name, valueToSave.toString());
      } else {
        onConfirm(name, value);
      }
    }
  };
  const validate = (value?: string) => {
    // escape , for number
    const escapeComma = value?.toString().replace(',', '');
    // isRequired
    if (isRequired && value === '') {
      return 'is required';
    }
    // is a number
    if (isNumeric && Number.isNaN(Number(escapeComma))) {
      return 'must be a number';
    }

    if (min && Number(escapeComma) < min.minValue) {
      return `cannot be less than ${min.minValue}`;
    }

    if (isEmail && !value?.match(emailPattern)) {
      return 'Invalid email format';
    }

    // is in a range
    if (
      range &&
      (Number.isNaN(Number(escapeComma)) || Number(escapeComma) < range.min || Number(escapeComma) > range.max)
    ) {
      return `must be in ${range.min}-${range.max}`;
    }
    return undefined;
  };

  const handleUnitConversion = () => {
    if (!conversion || !contactCompanyUnit || !productUnit) return '';
    const converted = Number(conversion);

    if (Number.isNaN(converted)) return '';

    return `${contactCompanyUnit} = ${MathHelper.mul(Number(state.value), converted)} ${productUnit}`;
  };

  return (
    <DivWrapper
      id={name}
      tabIndex={0}
      onFocus={() => setState(preState => ({ ...preState, focused: true }))}
      data-testid={`div-wrapper-${testId}`}
    >
      <InlineEdit
        label={label}
        onConfirm={changeValue}
        editView={({ errorMessage, ...fieldProps }) => (
          <>
            <Textfield
              //  eslint-disable-next-line
              {...fieldProps}
              autoFocus
              onFocus={e => e.target.select()}
              css={{
                fontSize: 'inherit',
                fontWeight: 'inherit',
                lineHeight: 'inherit',
                '& > [data-ds--text-field--input]': {
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  lineHeight: 'inherit',
                },
              }}
              isInvalid={fieldProps.isInvalid}
              testId={testId}
              elemAfterInput={editingPostfix}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
        defaultValue={state.value}
        hideActionButtons={hideActionButtons}
        readView={() => (
          <InlineEditReadView
            value={state.value}
            prefix={prefix}
            postfix={isUnitConversion ? handleUnitConversion() : postfix}
            isLargeText={isLargeText}
            needFormatValue={needFormatValue}
            isDisabled={isDisabled}
          />
        )}
        isEditing={!isDisabled && state.focused}
        onEdit={() => setState({ ...state, focused: true })}
        onCancel={() => setState({ ...state, focused: false })}
        validate={validate}
      />
    </DivWrapper>
  );
};
