import React from 'react';
import Button from '@atlaskit/button';
import iJob from '../../../../../types/job/iJob';
import { PopUpContentContainer } from '../../../../styles/styles';
import { DELETE_JOB } from '../../../constants';

const PopupContent = ({
  job,
  onOpenDeleteJobModal,
}: {
  job: iJob;
  onOpenDeleteJobModal: (job: iJob) => void;
}) => {
  return (
    <PopUpContentContainer className={'jobList-popup-content'}>
      {
        // <Button className={'popup-item'}>{DOWNLOAD_JOB_CARD}</Button>
      }
      {
        //  <Button className={'popup-item'}>{CLONE_JOB}</Button>
      }
      <Button
        className={'popup-item'}
        onClick={() => onOpenDeleteJobModal(job)}
        testId={'delete-job-button'}
      >
        {DELETE_JOB}
      </Button>
    </PopUpContentContainer>
  );
};

export default PopupContent;
