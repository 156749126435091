import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Grid, GridColumn } from '@atlaskit/page';
import Spinner from '@atlaskit/spinner';
import iContactCompany from '../../types/contactCompany/iContactCompany';
import Collapse from '../collapse/Collapse';
import ContCompanyKeyInfo from './ContCompanyKeyInfo';
import CustomizedLabel from '../form/hookForms/CustomizedLabel';
import AsyncCreatableWithController from '../form/hookForms/AsyncCreatableWithController';
import { apiErrorToast } from '../toast/Toast';
import { mapLabelValuePairObjPayload } from '../../pages/sales/utilities';
import {
  createContactCompany,
  getContactCompanies,
  getContactCompanyTypeList,
} from '../../services/ContactCompanyService';
import { ucFirst } from '../../services/UtilsService';
import iContactCompanyType, {
  CONTACT_COMPANY_TYPE_CUSTOMER,
  CONTACT_COMPANY_TYPE_SERVICE_PROVIDER,
  CONTACT_COMPANY_TYPE_SUPPLIER,
} from '../../types/contactCompany/iContactCompanyType';
import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../constants/AsyncConstants';
import { iParams } from '../UITypes/types';

const Wrapper = styled.div`
  .collapse-button {
    padding-left: 0px;
  }
`;

export type iContactCompanyTypeName =
  | typeof CONTACT_COMPANY_TYPE_SUPPLIER
  | typeof CONTACT_COMPANY_TYPE_CUSTOMER
  | typeof CONTACT_COMPANY_TYPE_SERVICE_PROVIDER;

type iAsyncCreatableContactCompany = {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  defaultValue?: iContactCompany;
  contactCompanyTypeName: iContactCompanyTypeName;
};

const AsyncCreatableContactCompany = ({
  control,
  errors,
  onChange,
  defaultValue,
  contactCompanyTypeName,
}: iAsyncCreatableContactCompany) => {
  const [contactCompany, setContactCompany] = useState<iContactCompany>();
  const [contactCompanyTypes, setContactCompanyTypes] = useState<iContactCompanyType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    setContactCompany(() => defaultValue);
    getContactCompanyTypeList()
      .then(resp => {
        if (isCanceled) return;
        setContactCompanyTypes(resp);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isCanceled = true;
    };
  }, [defaultValue]);

  const onCreate = async (name: string) => {
    try {
      const cCompanyTypes: Array<iContactCompanyType> = await getContactCompanyTypeList();
      const typeId = cCompanyTypes.find(
        (c: iContactCompanyType) => c.name.toLowerCase() === contactCompanyTypeName.toLowerCase(),
      )?.id;
      if (!typeId) return;
      const newContCompany = await createContactCompany(name, typeId);
      //  eslint-disable-next-line
      return newContCompany;
    } catch (error) {
      apiErrorToast(error);
      //  eslint-disable-next-line
      return null;
    }
  };
  //    eslint-disable-next-line
  const onChangeMiddleWare = (name: string, value: any, config?: Object) => {
    setContactCompany(value || undefined);
    onChange(name, value?.id || null, config);
  };

  //  eslint-disable-next-line
  const onUpdateContComp = (name: string, value: any) => {
    if (typeof contactCompany === 'undefined') return;
    setContactCompany({ ...contactCompany, [name]: value });
  };

  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <>
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <AsyncCreatableWithController
            label={ucFirst(contactCompanyTypeName)}
            name={contactCompanyTypeName !== CONTACT_COMPANY_TYPE_SUPPLIER ? 'customerId' : 'supplierId'}
            control={control}
            onChange={onChangeMiddleWare}
            errors={errors}
            promiseFn={(config?: iParams) =>
              getContactCompanies({
                ...config,
                filter: `typeId:${contactCompanyTypes
                  .filter(type => type.name === contactCompanyTypeName)
                  .map(type => type.id)
                  .join('|')}`,
                include: 'shippingAddress,billingAddress',
                currentPage: ASYNC_SEARCH_CURRENT_PAGE,
                perPage: ASYNC_SEARCH_PER_PAGE,
              })
            }
            onCreate={onCreate}
            optionLabel={'name'}
            isRequired
            defaultValue={defaultValue ? mapLabelValuePairObjPayload([defaultValue], 'name')[0] : undefined}
            isCreatable={false}
          />
        </GridColumn>
      </Grid>

      {contactCompany && (
        <Grid spacing={'compact'}>
          <GridColumn medium={12}>
            <Wrapper>
              <Collapse
                text={<CustomizedLabel label={`${ucFirst(contactCompanyTypeName)} Details`} />}
                defaultOpen={false}
                iconSize={'small'}
              >
                <ContCompanyKeyInfo
                  contComp={contactCompany}
                  isShowShippingAddress={contactCompanyTypeName !== CONTACT_COMPANY_TYPE_SUPPLIER}
                  onUpdateContComp={onUpdateContComp}
                />
              </Collapse>
            </Wrapper>
          </GridColumn>
        </Grid>
      )}
    </>
  );
};

export default AsyncCreatableContactCompany;
