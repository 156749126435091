import React, { useEffect, useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import Spinner from '@atlaskit/spinner';
import iProductAttributeSet from '../../../../types/product/iProductAttributeSet';
import iProductType from '../../../../types/product/iProductType';
import { getProdAttributeSets } from '../../../../services/product/ProductAttributeSetService';
import { getProductTypes } from '../../../../services/product/ProductTypeService';
import { apiErrorToast } from '../../../toast/Toast';
import ProductEditModal from '../../../../components/product/list/ProductEditModal';

type iState = {
  isLoading: boolean;
  productTypes: Array<iProductType>;
  productAttributeSets: Array<iProductAttributeSet>;
};
const initialState: iState = {
  isLoading: true,
  productAttributeSets: [],
  productTypes: [],
};
const ProductBody = ({
  control,
  onChange,
  errors,
  //  eslint-disable-next-line
  watch,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  //    eslint-disable-next-line
  watch: (names?: string | string[]) => any;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const productTypes = await getProductTypes();
        const productAttributeSets = await getProdAttributeSets();
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          productTypes,
          productAttributeSets,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);
  if (state.isLoading) return <Spinner />;
  return (
    <ProductEditModal
      control={control}
      onChange={onChange}
      errors={errors}
      watch={watch}
      types={state.productTypes}
      attributeSets={state.productAttributeSets}
    />
  );
};

export default ProductBody;
