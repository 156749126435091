import iChannel from '../../types/integration/iChannel';
import AppService from '../AppService';

const endPoint = '/channel';
const getChannelsForAccounting = (isOnboardedRequired = false, params = {}): Promise<iChannel[]> => {
  const isOnboardedRequiredParam = isOnboardedRequired ? ',isOnBoarded:1' : '';
  return AppService.get(endPoint, {
    advancedFilter: `ChannelScript_scriptId.ChannelScriptType_typeId.name:Accounting${isOnboardedRequiredParam}`,
    ...params,
  }).then(({ data }) => data);
};

const getChannelsForSales = (isOnboardedRequired = false, params = {}): Promise<iChannel[]> => {
  const isOnboardedRequiredParam = isOnboardedRequired ? ',isOnBoarded:1' : '';
  return AppService.get(endPoint, {
    advancedFilter: `ChannelScript_scriptId.ChannelScriptType_typeId.name:Sales${isOnboardedRequiredParam}`,
    ...params,
  }).then(({ data }) => data);
};

const createChannel = (data = {}): Promise<iChannel> => {
  return AppService.post(endPoint, data).then(resp => resp.data);
};

const getChannels = (params = {}) => {
  return AppService.get(endPoint, params).then(({ data }) => data);
};

const getChannel = (id: string, params = {}): Promise<iChannel> => {
  return AppService.get(`${endPoint}/${id}`, params).then(({ data }) => data);
};

//  eslint-disable-next-line
const updateChannel = (channelId: string, postData: any): Promise<iChannel> => {
  return AppService.put(`${endPoint}/${channelId}`, postData).then(({ data }) => data);
};

const deactivateChannel = (channelId: string) => {
  return AppService.delete(`${endPoint}/${channelId}`).then(({ data }) => data);
};

export default {
  getChannels,
  getChannelsForAccounting,
  getChannelsForSales,
  createChannel,
  getChannel,
  updateChannel,
  deactivateChannel,
};
