/* eslint-disable */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */

import { iKeyValuePairs } from '../../../../../shared/UITypes/types';
import iUnitOfMeasurements from '../../../../../types/iUnitOfMeasurement';
import iProduct from '../../../../../types/product/iProduct';
import {
  iImportDataResult,
  iTemplateColumn,
} from '../../shared/DataImport.types';
import { handleNullException } from '../../../../../services/UtilsService';
import { createProduct } from '../../../../../services/product/ProductService';
import iProductAttribute from '../../../../../types/product/iProductAttribute';
import {
  BOOLEANS,
  isBooleanValidation,
  isNumberValidation,
  isSelectValidation,
} from '../../shared/DataImportValidation.util';

import { createProdAttributeValue } from '../../../../../services/product/ProductAttributeValueService';

export const getProductFlexColumnsFn = (
  productAttributes: Array<iProductAttribute>,
): Array<iTemplateColumn> => {
  return productAttributes
    .sort(
      (a: iProductAttribute, b: iProductAttribute) => a.sortOrder - b.sortOrder,
    )
    .map((item: iProductAttribute) => {
      const { type, options, name, id } = item;
      const column = { accessor: 'attributeId', defaultValue: id };
      switch (type.toUpperCase()) {
        case 'SELECT':
          return {
            ...column,
            name: `${name}(${(JSON.parse(options) as Array<string>).join(
              '|',
            )})`,
            ...isSelectValidation(JSON.parse(options) as Array<string>, name),
          };
        case 'CHECKBOX':
          return {
            ...column,
            name: `${name}(${BOOLEANS.join('|')})`,
            ...isBooleanValidation(name),
          };
        case 'NUMERIC':
          return {
            ...column,
            name: `${name}(${type})`,
            ...isNumberValidation(name),
          };
        default:
          return {
            ...column,
            name: `${name}(${type})`,
          };
      }
    });
};

export const onSubmitProduct = async (
  pageName: string,
  data: any[][],
  recordResult: (eachResult: iImportDataResult) => void,
  previewResults: Array<iImportDataResult>,
  columns: Array<iTemplateColumn>,
  prefills?: any,
) => {
  if (
    !handleNullException(prefills, 'productTypeId') ||
    !handleNullException(prefills, 'productAttributeSetId')
  ) {
    return;
  }
  const unitMeasurements: Array<iUnitOfMeasurements> = handleNullException(
    prefills,
    'unitMeasurements',
  );
  for (let i = 0; i < data.length; i++) {
    if (previewResults[i].skipped) {
      //  eslint-disable-next-line
      recordResult(previewResults[i]);
      continue;
    }
    try {
      const { product, productAttributes } = columns.reduce(
        (
          acc: {
            product: iKeyValuePairs;
            productAttributes: Array<iKeyValuePairs>;
          },
          curColumn: iTemplateColumn,
          curIndex: number,
        ) => {
          const cellValue = data[i][curIndex];
          const { accessor, defaultValue } = curColumn;
          switch (accessor) {
            case 'attributeId': {
              if (!cellValue) return acc;
              return {
                product: acc.product,
                productAttributes: [
                  ...acc.productAttributes,
                  {
                    [accessor]: defaultValue,
                    value: cellValue,
                  } as iKeyValuePairs,
                ],
              };
            }
            case 'isForSell':
              return {
                product: {
                  ...acc.product,
                  [accessor]:
                    (cellValue as string).toUpperCase() ===
                    BOOLEANS[0].toUpperCase(),
                },
                productAttributes: acc.productAttributes,
              };

            case 'isForMake':
              return {
                product: {
                  ...acc.product,
                  [accessor]:
                    (cellValue as string).toUpperCase() ===
                    BOOLEANS[0].toUpperCase(),
                },
                productAttributes: acc.productAttributes,
              };

            case 'isForPurchase':
              return {
                product: {
                  ...acc.product,
                  [accessor]:
                    (cellValue as string).toUpperCase() ===
                    BOOLEANS[0].toUpperCase(),
                },
                productAttributes: acc.productAttributes,
              };

            case 'IsForWaste':
              return {
                product: {
                  ...acc.product,
                  [accessor]:
                    (cellValue as string).toUpperCase() ===
                    BOOLEANS[0].toUpperCase(),
                },
                productAttributes: acc.productAttributes,
              };

            case 'measurementId':
              return {
                product: {
                  ...acc.product,
                  [accessor]:
                    unitMeasurements.find(
                      (item: iUnitOfMeasurements) =>
                        item.shortName.toUpperCase() ===
                        (cellValue as string).toUpperCase(),
                    )?.id || null,
                },
                productAttributes: acc.productAttributes,
              };
            default:
              return {
                product: {
                  ...acc.product,
                  [accessor]: cellValue,
                },
                productAttributes: acc.productAttributes,
              };
          }
        },
        { product: {}, productAttributes: [] },
      );
      const created: iProduct = await createProduct({
        ...product,
        productTypeId: handleNullException(prefills, 'productTypeId'),
        attributeSetId: handleNullException(prefills, 'productAttributeSetId'),
      });
      for (let j = 0; j < productAttributes.length; j++) {
        //  eslint-disable-next-line
        await createProdAttributeValue({
          ...productAttributes[j],
          productId: created.id,
        });
      }
      recordResult({ created });
    } catch (error) {
      const errorMessage =
        handleNullException(error, 'response.data.message') ||
        handleNullException(error, 'response.data.error') ||
        'Unfortunately, it fails';
      recordResult({ failed: true, messages: [errorMessage] });
    }
  }
};

export const previewProduct = (
  data: any[][],
  configColumns: Array<iTemplateColumn>,
  prefills: any,
) => {
  const unitMeasurements = handleNullException(prefills, 'unitMeasurements');
  const previewResults: Array<iImportDataResult> = data.map((row: any[]) => {
    const messages = configColumns.map(
      (column: iTemplateColumn, columnIndex: number) => {
        const { validation, errorMessage, accessor } = column;
        const cell = row[columnIndex];
        if (validation && !validation(cell)) {
          return errorMessage;
        }
        if (accessor === 'measurementId') {
          const target = unitMeasurements.find(
            (item: iUnitOfMeasurements) =>
              item.shortName.toUpperCase() === (cell as string).toUpperCase(),
          );
          if (cell && !target) {
            return errorMessage;
          }
        }
        return undefined;
      },
    );
    const errorMessages = messages.filter((item: string | undefined) => item);
    if (errorMessages.length > 0) {
      return { skipped: true, messages: errorMessages as Array<string> };
    }
    return { previewSuccess: true };
  });
  return previewResults;
};
