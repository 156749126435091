import React, { ReactNode, useEffect, useState } from 'react';
import _ from 'lodash';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import { Checkbox } from '@atlaskit/checkbox';
import { Field } from '@atlaskit/form';
import { ErrorMsg } from '../../styles/styles';

const CheckboxWithController = ({
  label,
  name,
  control,
  defaultValue,
  onChange,
  testId = 'checkbox-react-hook-form',
  errors,
  isRequired = true,
  isDisabled = false,
}: {
  label: string | ReactNode;
  name: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: boolean;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  testId?: string;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  isRequired?: boolean;
  isDisabled?: boolean;
}) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(!!defaultValue);
  }, [defaultValue]);

  const handleValueChange = (checked: boolean) => {
    setValue(checked);
    if (isRequired) {
      onChange(name, checked, { shouldDirty: true });
      return;
    }
    onChange(name, checked);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || false}
      render={ctrlProps => (
        <Field key={name} name={name} label={label}>
          {({ fieldProps }) => (
            <>
              <Checkbox
                // className="hideCheckboxForiOS"
                // eslint-disable-next-line
                {...ctrlProps}
                // eslint-disable-next-line
                {...fieldProps}
                name={name}
                isChecked={value}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  const val = event.currentTarget.checked;
                  handleValueChange(val);
                }}
                testId={testId}
                isInvalid={_.get(errors, name)}
                isDisabled={isDisabled}
              />
              {_.get(errors, name) && (
                <ErrorMsg>{_.get(errors, name).message}</ErrorMsg>
              )}
            </>
          )}
        </Field>
      )}
    />
  );
};

export default CheckboxWithController;
