import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerList } from '../../services/ContactCompanyService';
import { CUSTOMER_URL } from '../../shared/UrlMap';
import { CUSTOMER, CUSTOMER_COLUMNS, CUSTOMER_INSERT_INDEX, CUSTOMER_PAGE_NAME } from './list/ContactCompany.constants';
import ContactCompanyList from './list/ContactCompanyList';
import { RootState } from '../../redux/makeReduxStore';
import { ACCESS_CODE_CUSTOMERS } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE } from '../../types/settings/UserAccess/iRoleAccess';
import AccessService from '../../services/Settings/UserAccess/AccessService';

const CustomerList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  return (
    <ContactCompanyList
      typeName={CUSTOMER}
      pageName={CUSTOMER_PAGE_NAME}
      getFn={getCustomerList}
      urlPrefix={CUSTOMER_URL}
      fixColumns={CUSTOMER_COLUMNS}
      flexInsertIndex={CUSTOMER_INSERT_INDEX}
      canCreate={AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_CREATE, user)}
      canDelete={AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_DELETE, user)}
    />
  );
};

export default CustomerList;
