import React from 'react';
import { printSalesOrder } from '../../../../services/PrintService';
// import { getCreatedOrUpdatedAt } from '../../../../components/settings/utils';
import {
  assembleAddress,
  formatDate,
  getCategoryCode,
  handleMoney,
  handleNullException,
} from '../../../../services/UtilsService';
import GeneralPrintBtn from '../../../../shared/buttons/GeneralPrintBtn';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import CreatedOrUpdated from '../../../../shared/createdOrUpdated/CreatedOrUpdated';
import PopupContent from '../../../../shared/popup/GeneralPopupContent';
import MoreIconPopup from '../../../../shared/popup/MoreIconPopup';
import StatusLozenge from '../../../../shared/statusComponent/StatusLozenge';
import { PrintBtnName } from '../../../../shared/styles/styles';
import iSalesOrderDetail from '../../../../types/sales/iSalesOrder';
import iEntityCategory from '../../../../types/status/iEntityCategory';
import { iConfigColumn } from '../../../despatchNote/shared/DispatchNote.type';
import iUser from '../../../../types/user/iUser';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_INVOICES,
  ACCESS_CODE_QUOTES,
  ACCESS_CODE_SALES_ORDERS,
} from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE } from '../../../../types/settings/UserAccess/iRoleAccess';
import { SO_TYPE_NAME_INVOICE, SO_TYPE_NAME_QUOTE, SO_TYPE_NAME_SALES_ORDER } from '../../salesDetail/constants';

export const getHeads = (columns: Array<iConfigColumn>, tableName: string) => {
  const cells = columns.map((column: iConfigColumn) => {
    switch (column.type) {
      case 'operation':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      default:
        return {
          key: column.key,
          content: column.name,
          testId: `${tableName}-column-${column.key}`,
          isSortable: !!column.isSortable,
        };
    }
  });
  return { cells };
};

const canDeleteByType = (so: iSalesOrderDetail, user?: iUser) => {
  if (so.type?.name.toUpperCase() === SO_TYPE_NAME_QUOTE) {
    return AccessService.hasAccess(ACCESS_CODE_QUOTES, ACCESS_CAN_DELETE, user);
  }
  if (so.type?.name.toUpperCase() === SO_TYPE_NAME_SALES_ORDER) {
    return AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_DELETE, user);
  }
  if (so.type?.name.toUpperCase() === SO_TYPE_NAME_INVOICE) {
    return AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_DELETE, user);
  }
  return false;
};

export const getRows = (
  data: Array<iSalesOrderDetail>,
  onOpenDeleteModal: (id: string) => void,
  columns: Array<iConfigColumn>,
  categories: Array<iEntityCategory>,
  user?: iUser,
) => {
  return data.map((so: iSalesOrderDetail) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type, alternativeKey } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(so, key),
            content: handleNullException(so, key),
          };
        case 'date':
          return {
            key: handleNullException(so, key) || handleNullException(so, alternativeKey || ''),
            content:
              formatDate(handleNullException(so, key), 'DD MMM YYYY') ||
              formatDate(handleNullException(so, alternativeKey || ''), 'DD MMM YYYY'),
          };
        case 'money':
          return {
            key: handleNullException(so, key),
            content: handleMoney(so, key, 2),
          };
        case 'address':
          return {
            key: handleNullException(so, key),
            content: assembleAddress(handleNullException(so, key)),
          };
        case 'link':
          return {
            key: handleNullException(so, key) || handleNullException(so, alternativeKey || ''),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(so, key) || handleNullException(so, alternativeKey || '')}
                url={`${column.urlPrefix}/${handleNullException(so, dataSource[0])}`}
              />
            ),
          };
        case 'status':
          return {
            key: handleNullException(so, `${key}.id`),
            content: (
              <StatusLozenge
                status={handleNullException(so, key)}
                categoryCode={getCategoryCode(handleNullException(so, key), categories)}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(so, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(so, key)}
                operator={handleNullException(so, dataSource[0])}
              />
            ),
          };
        case 'count':
          return {
            key: handleNullException(so, key),
            content: handleNullException(so, dataSource[0])?.length,
          };
        case 'operation':
          return {
            content: (
              <MoreIconPopup>
                <PopupContent id={so.id} onOpenDeleteModal={canDeleteByType(so, user) ? onOpenDeleteModal : undefined}>
                  <GeneralPrintBtn serviceFunc={() => printSalesOrder(so.id)}>
                    <PrintBtnName>Print</PrintBtnName>
                  </GeneralPrintBtn>
                </PopupContent>
              </MoreIconPopup>
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'admin-salesOrder-row' };
  });
};

export const getReportArrSegments = (
  data: iSalesOrderDetail[],
  selectedColumns: iConfigColumn[],
  // categories: Array<iEntityCategory>,
) => {
  const selectedColumnNames = selectedColumns
    .map((column: iConfigColumn) => column.name)
    .filter((columnName: string) => columnName !== '');

  const reportCells = data.map((item: iSalesOrderDetail) => {
    return selectedColumns.map((column: iConfigColumn) => {
      const { key, dataSource, type, alternativeKey } = column;
      switch (type) {
        case 'text':
          return handleNullException(item, key);
        case 'link':
          return handleNullException(item, key) || handleNullException(item, alternativeKey || '');
        case 'address':
          return assembleAddress(handleNullException(item, key));
        case 'date':
          return (
            formatDate(handleNullException(item, key), 'DD MMM YYYY') ||
            formatDate(handleNullException(item, alternativeKey || ''), 'DD MMM YYYY')
          );
        case 'money':
          return handleMoney(item, key, 2);
        case 'status':
          return handleNullException(item, 'status.name');
        case 'count':
          return handleNullException(item, dataSource[0])?.length;
        case 'history':
          return `${handleNullException(item, dataSource[0])} @ ${formatDate(
            handleNullException(item, key),
            'DD MMM YYYY',
          )}`;
        default:
          return '';
      }
    });
  });

  return [selectedColumnNames, ...reportCells];
};
