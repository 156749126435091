import React from 'react';
import Spinner from '@atlaskit/spinner';
import PartitionTitle from '../shared/PartitionTitle';
import OrdersByMachineTable from './JobsByMachineTable';
import { DASHBOARD_ORDERS_BY_MACHINE } from '../shared/Dashboard.constants';
import { PartitionBodyWrapper } from '../shared/Dashboard.style';
import { useDashboardJobContext } from '../jobContext/DashboardJobContext';

//  eslint-disable-next-line
const JobsByMachine = ({ date }: { date: string }) => {
  const {
    isLoading,
    machines,
    unscheduledJobs,
    scheduledJobs,
  } = useDashboardJobContext();

  return (
    <>
      <PartitionTitle
        title={DASHBOARD_ORDERS_BY_MACHINE}
        dateFrom={''}
        dateTo={''}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <PartitionBodyWrapper>
          <OrdersByMachineTable
            machines={machines}
            scheduledJobs={scheduledJobs}
            unscheduledJobs={unscheduledJobs}
          />
        </PartitionBodyWrapper>
      )}
    </>
  );
};

export default JobsByMachine;
