import React, { useState, useEffect, useReducer } from 'react';
import { addToastForAPIResponse, apiErrorToast } from '../../toast/Toast';
import { reducer, ActionKind, iState, iAction } from './reducer';

type iEditState<T> = {
  version: number;
  isDeleteModalOpen: boolean;
  target?: T;
};

type iProps = {
  id: string;
  //  eslint-disable-next-line
  getFn: (id: string) => any;
  //  eslint-disable-next-line
  updateFn?: (id: string, content: any) => any;
  deleteFn?: (id: string) => void;
  deleteCallback?: () => void;
};

const useDetailHook = <T extends { id: string }>({
  id,
  getFn,
  updateFn,
  deleteFn,
  deleteCallback,
}: iProps) => {
  const initialState: iState<T> = {
    data: undefined,
    isLoading: true,
    isConfirming: false,
  };
  const initialEditState: iEditState<T> = {
    isDeleteModalOpen: false,
    version: 0,
  };
  const [state, dispatch] = useReducer<React.Reducer<iState<T>, iAction<T>>>(
    reducer,
    initialState,
  );
  const [edit, setEdit] = useState(initialEditState);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      dispatch({ type: ActionKind.Loading, payload: {} });
      try {
        //  eslint-disable-next-line
        const fetchResult: T = await getFn(id);
        if (isCancelled) return;
        dispatch({
          type: ActionKind.Loaded,
          payload: { initial: fetchResult },
        });
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        dispatch({
          type: ActionKind.Loaded,
          payload: { initial: undefined },
        });
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [getFn, id, edit.version]);

  const onUpdate = async (
    //  eslint-disable-next-line
    updateContent: any,
    // fieldName: string,
    // //  eslint-disable-next-line
    // fieldValue: any,
    needRefetch?: boolean,
  ) => {
    if (typeof updateFn === 'undefined') return;
    dispatch({ type: ActionKind.Confirming, payload: {} });
    try {
      await updateFn(id, updateContent);
      if (needRefetch) {
        const reFetchResult: T = await getFn(id);
        dispatch({
          type: ActionKind.Loaded,
          payload: { initial: reFetchResult },
        });
        return;
      }
      dispatch({
        type: ActionKind.Updated,
        payload: { updateContent },
      });
      addToastForAPIResponse('success');
    } catch (error) {
      dispatch({ type: ActionKind.Confirmed, payload: {} });
      apiErrorToast(error);
    }
  };

  const onUpdateOneField = async (
    //  eslint-disable-next-line
    // updateContent: any,
    fieldName: string,
    //  eslint-disable-next-line
    fieldValue: any,
    needRefetch?: boolean,
  ) => {
    if (typeof updateFn === 'undefined') return;
    dispatch({ type: ActionKind.Confirming, payload: {} });
    try {
      await updateFn(id, { [fieldName]: fieldValue });
      if (needRefetch) {
        const reFetchResult: T = await getFn(id);
        dispatch({
          type: ActionKind.Loaded,
          payload: { initial: reFetchResult },
        });
        return;
      }
      dispatch({
        type: ActionKind.Updated,
        payload: { updateContent: { [fieldName]: fieldValue } },
      });
      addToastForAPIResponse('success');
    } catch (error) {
      dispatch({ type: ActionKind.Confirmed, payload: {} });
      apiErrorToast(error);
    }
  };

  const onDelete = async (deleteTargetId: string) => {
    try {
      if (typeof deleteFn !== 'function' || deleteTargetId !== id) return;
      dispatch({ type: ActionKind.Confirming, payload: {} });
      await deleteFn(id);
      if (typeof deleteCallback !== 'undefined') {
        deleteCallback();
      }
      dispatch({ type: ActionKind.Confirmed, payload: {} });
    } catch (error) {
      apiErrorToast(error);
      dispatch({ type: ActionKind.Confirmed, payload: {} });
    }
  };

  const onUpdateEagerLoadObject = (
    //  eslint-disable-next-line
    updateContent: any,
    // fieldName: string,
    // //  eslint-disable-next-line
    // fieldValue: any,
  ) =>
    dispatch({
      type: ActionKind.Updated,
      payload: { updateContent },
    });

  const onOpenDeleteModal = () =>
    setEdit({
      ...edit,
      isDeleteModalOpen: true,
    });

  const onCloseModal = () =>
    setEdit({
      ...edit,
      isDeleteModalOpen: false,
    });

  const onRefresh = () => setEdit({ ...edit, version: edit.version + 1 });

  return {
    state,
    edit,
    onUpdate,
    onUpdateOneField,
    onUpdateEagerLoadObject,
    onOpenDeleteModal,
    onDelete,
    onCloseModal,
    onRefresh,
  };
};

export default useDetailHook;
