import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';
import LinkBtnUrl from '../../shared/buttons/LinkBtnUrl';
import iProductAttributeSet from '../../types/product/iProductAttributeSet';
import CreatedOrUpdated from '../../shared/createdOrUpdated/CreatedOrUpdated';
import { handleNullException } from '../../services/UtilsService';
import { getDeleteBtn, getHeads } from '../../components/settings/utils';
import { PRODUCT_ATTRIBUTE_SETS_URL } from '../../shared/UrlMap';

const TableWrapper = styled.div`
  th[data-testid='product-attributeSet-table-column-Name--head--cell'] {
    width: 10%;
    min-width: 5rem;
  }

  th[data-testid='product-attributeSet-table-column-Description--head--cell'] {
    width: 60%;
    min-width: 20rem;
  }

  td:last-child {
    max-width: 2rem;
  }
`;

const COLUMNS = ['Name', 'Description', 'CreatedBy', 'UpdatedBy', 'Operation'];

const ProdAttributeSetsTable = ({
  data,
  onDelete,
  isLoading,
  canDelete = true,
}: {
  data: iProductAttributeSet[];
  onDelete: (id: string) => void;
  isLoading: boolean;
  canDelete?: boolean;
}) => {
  const getRows = () => {
    return data
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iProductAttributeSet) => ({
        cells: [
          {
            key: item.name,
            content: <LinkBtnUrl btnName={item.name} url={`${PRODUCT_ATTRIBUTE_SETS_URL}/${item.id}`} />,
          },
          {
            content: item.description,
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'createdAt')}
                operator={handleNullException(item, 'createdBy.firstName')}
              />
            ),
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'updatedAt')}
                operator={handleNullException(item, 'updatedBy.firstName')}
              />
            ),
          },
          {
            content: canDelete
              ? getDeleteBtn({
                  id: item.id,
                  answer: item.name || 'n/a',
                  onClick: onDelete,
                })
              : null,
          },
        ],
      }));
  };

  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(COLUMNS, 'product-attributeSet')}
        rows={getRows()}
        testId={'product-attributeSet-list-table'}
        isLoading={isLoading}
        rowsPerPage={10}
        defaultPage={1}
      />
    </TableWrapper>
  );
};

export default ProdAttributeSetsTable;
