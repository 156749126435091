import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../../shared/constants/AsyncConstants';
import { iKeyValuePairs, iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

export const getProdAttributeValues = (config?: iKeyValuePairs) => {
  return AppService.get('/productAttributeValue', {
    include: '',
    ...config,
  }).then(({ data }) => data);
};

export const updateProdAttributeValue = (
  id: string,
  updateContent: { [key: string]: string | number | boolean | null },
) => {
  return AppService.put(`/productAttributeValue/${id}`, updateContent).then(({ data }) => data);
};

export const createProdAttributeValue = (postContent: { [key: string]: string | number | boolean | null }) => {
  return AppService.post('/productAttributeValue', postContent).then(({ data }) => data);
};

export const deleteProdAttributeValue = (id: string) => {
  return AppService.delete(`/productAttributeValue/${id}`).then(({ data }) => data);
};

export const cloneProdAttributeValues = (sourceProductId: string, targetProductId: string) => {
  return AppService.post('/productAttributeValue/clone', {
    sourceProductId,
    targetProductId,
  }).then(({ data }) => data);
};

export const getProductAttributeValuesAsyncSearch = (config?: iParams) => {
  return AppService.get('/productAttributeValue', {
    ...config,
    include: 'attributeSet',
    perPage: ASYNC_SEARCH_PER_PAGE,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
  }).then(({ data }) => data);
};

export default {
  getProdAttributeValues,
  createProdAttributeValue,
  updateProdAttributeValue,
  deleteProdAttributeValue,
  getProductAttributeValuesAsyncSearch,
  cloneProdAttributeValues,
};
