import SectionMessage from '@atlaskit/section-message';
import React from 'react';
import {
  FlexContainer,
  SpaceVerticalContainer,
} from '../../../../../../shared/styles/styles';
import DataInfo from '../../../shared/DataInfo';

const FinalSummary = ({
  success,
  failed,
  skipped,
  children,
}: {
  success: number;
  failed: number;
  skipped: number;
  children: React.ReactNode;
}) => {
  return (
    <SectionMessage title={'Import Result Summary'} appearance={'info'}>
      <FlexContainer>
        {success > 0 && (
          <DataInfo
            desc={' record(s) successfully imported.'}
            lozenge={success}
            appearance={'success'}
          />
        )}
        {failed > 0 && (
          <DataInfo
            desc={' record(s) failed.'}
            lozenge={failed}
            appearance={'error'}
          />
        )}
        {skipped > 0 && (
          <DataInfo
            desc={' record(s) skipped.'}
            lozenge={skipped}
            appearance={'warning'}
          />
        )}
      </FlexContainer>
      <SpaceVerticalContainer className={'quarter'} />
      {children}
    </SectionMessage>
  );
};

export default FinalSummary;
