import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { Grid } from '@atlaskit/page';

import { useSelector } from 'react-redux';
import SearchBar from '../../shared/search/SearchBar';
import JobFilter from '../../shared/job/jobs/filter/JobFilter';
import Jobs from '../../shared/job/jobs/Jobs';
import iJobStatus from '../../types/job/iJobStatus';
import iJobCategory from '../../types/job/iJobCategory';
import PageHeading from '../../shared/heading/PageHeading';
import { apiErrorToast } from '../../shared/toast/Toast';
import { getJobCategories } from '../../services/JobService';
import { ucFirst } from '../../services/UtilsService';
import {
  COLUMN_NAME_IN_STORAGE,
  DEFAULT_COLUMNS,
  FIXED_COLUMNS,
  JOB_ADVANCED_SEARCH_CONFIG,
  JOB_ALL,
  MUST_COLUMNS_AFTER,
  MUST_COLUMNS_BEFORE,
  STATUS_CATEGORY_FINISHED,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_NEW,
} from '../../shared/job/constants';
import Advanced from '../../shared/contactCompany/list/Advanced';
import { AdvancedSearchSectionWrapper, FlexContainer } from '../../shared/styles/styles';
import useAdvancedSearchHooks from '../../shared/advancedSearch/AdvancedSearch';
import PartiallyFinished from './jobList/PartiallyFinished';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_JOBS } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE } from '../../types/settings/UserAccess/iRoleAccess';

type iState = {
  selectedTypes: Array<string>;
  statuses: Array<iJobStatus>;
  keyword: string;
  isLoading: boolean;
  isAdvancedSearch: boolean;
  categories: Array<iJobCategory>;
  normalFilter: string;
};
const initialState = {
  selectedTypes: [],
  keyword: '',
  statuses: [],
  isLoading: true,
  isAdvancedSearch: false,
  categories: [],
  normalFilter: '',
};

const sortCategories = (categories: Array<iJobCategory>) => {
  const WHOLE = [STATUS_CATEGORY_NEW, STATUS_CATEGORY_IN_PROGRESS, STATUS_CATEGORY_FINISHED];
  return categories.sort((a: iJobCategory, b: iJobCategory) => WHOLE.indexOf(a.name) - WHOLE.indexOf(b.name));
};

const JobList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [state, setState] = useState<iState>(initialState);

  const { getAdvancedFilterStrArr, getSearchControllers } = useAdvancedSearchHooks(JOB_ADVANCED_SEARCH_CONFIG);

  useEffect(() => {
    let isCancelled = false;
    const loadJobCategories = async () => {
      setState(preState => ({ ...preState, isLoading: true }));
      try {
        const categories = await getJobCategories();

        const sortedCategories = sortCategories(categories);
        if (isCancelled) return;
        let defaultSelected: Array<string> = [];
        const statuses: Array<iJobStatus> = sortedCategories.reduce((acc: Array<iJobStatus>, cur: iJobCategory) => {
          if (cur.name !== STATUS_CATEGORY_FINISHED) {
            defaultSelected = [...defaultSelected, ...cur.jobStatuses.map((s: iJobStatus) => s.id)];
          }
          return [...acc, ...cur.jobStatuses.sort((a: iJobStatus, b: iJobStatus) => a.sortOrder - b.sortOrder)];
        }, []);
        setState(preState => ({
          ...preState,
          isLoading: false,
          statuses,
          selectedTypes: defaultSelected,
          categories,
          normalFilter: `statusId:${defaultSelected.join('|')}`,
        }));
      } catch (error) {
        apiErrorToast(error);
        setState(preState => ({ ...preState, isLoading: false }));
      }
    };
    loadJobCategories();
    return () => {
      isCancelled = true;
    };
  }, []);

  const onSelectType = (value: string) => {
    // when nothing is selected, auto select 'JOB_ALL'
    if (state.selectedTypes.length === 1 && state.selectedTypes.includes(value)) {
      setState({ ...state, selectedTypes: [JOB_ALL], normalFilter: '' });
      return;
    }
    const newSelectedTypes = state.selectedTypes.includes(value)
      ? state.selectedTypes.filter((type: string) => type !== value)
      : [...state.selectedTypes, value];
    setState({
      ...state,
      selectedTypes: [...newSelectedTypes],
      normalFilter: newSelectedTypes.includes(JOB_ALL) ? '' : `statusId:${newSelectedTypes.join('|')}`,
    });
  };
  const getPartiallyFinished = () => {
    const finishedStatusIds =
      state.categories
        .find((c: iJobCategory) => c.name === STATUS_CATEGORY_FINISHED)
        ?.jobStatuses.map((s: iJobStatus) => s.id)
        .join('|') || '';
    const filterStr = `statusId:${finishedStatusIds},qtyToMake>0`;
    return filterStr;
  };
  const onSetPartiallyFinished = () => {
    const filterStr = getPartiallyFinished();
    setState({
      ...state,
      normalFilter: filterStr,
      selectedTypes:
        state.categories
          .find((c: iJobCategory) => c.name === STATUS_CATEGORY_FINISHED)
          ?.jobStatuses.map((s: iJobStatus) => s.id) || state.selectedTypes,
    });
  };

  const getPageHeader = (children?: React.ReactNode) => (
    <>
      <PageHeading className={'space-below'} content={ucFirst('Jobs')} />
      <JobFilter
        options={[
          { label: JOB_ALL, value: JOB_ALL },
          ...state.statuses.map((s: iJobStatus) => ({
            label: s.name.toUpperCase(),
            value: s.id,
          })),
        ]}
        onSelectJobType={onSelectType}
        selectedTypes={state.selectedTypes}
      >
        <FlexContainer>
          <PartiallyFinished onClick={onSetPartiallyFinished} filter={getPartiallyFinished()} />
          <SearchBar onSearch={(value: string) => setState({ ...state, keyword: value })} />
          <Advanced onClick={() => setState({ ...state, isAdvancedSearch: !state.isAdvancedSearch })} />
        </FlexContainer>
      </JobFilter>
      {state.isAdvancedSearch && (
        <AdvancedSearchSectionWrapper>
          <Grid layout={'fluid'} spacing={'cosy'}>
            {getSearchControllers()}
            {children}
          </Grid>
        </AdvancedSearchSectionWrapper>
      )}
    </>
  );

  const getAdvancedFilterStr = () => {
    // job -> SalesOrder salesOrder -> ContactCompany customer.id
    const filterArr = [state.normalFilter, ...getAdvancedFilterStrArr()].filter(strFilter => strFilter !== '');
    return filterArr.join(',');
  };

  const getConfig = () => ({
    filter: getAdvancedFilterStr(),
    like: state.keyword === '' ? undefined : `jobNumber:${state.keyword},orderNumber:${state.keyword}`,
    sort: 'scheduledAt:DESC',
  });

  if (state.isLoading) return <Spinner size={'large'} />;

  return (
    <Jobs
      isIndependent={false}
      getHeader={getPageHeader}
      config={getConfig()}
      columns={{
        name: COLUMN_NAME_IN_STORAGE,
        default: DEFAULT_COLUMNS,
        mustBefore: MUST_COLUMNS_BEFORE,
        mustAfter: MUST_COLUMNS_AFTER,
        positionFixed: FIXED_COLUMNS,
      }}
      canDelete={AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_DELETE, user)}
    />
  );
};

export default JobList;
