import React, { useState } from 'react';
import { LoadingButton } from '@atlaskit/button';
import RedirectAfterSuccessAction from './RedirectToActivation';
import { RESEND, RESEND_HINT } from './Auth.constants';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { resendToken } from '../../../services/AuthService';
import { iParams } from '../../../shared/UITypes/types';

type iState = {
  isLoading: boolean;
  success: boolean;
};
const initialState: iState = {
  isLoading: false,
  success: false,
};
const ResendToken = ({
  resendSubmitData,
  children,
}: {
  resendSubmitData: iParams;
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(initialState);
  const onSubmit = async () => {
    setState({ ...state, isLoading: true });
    try {
      await resendToken(resendSubmitData);
      setState({
        ...state,
        isLoading: false,
        success: true,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isLoading: false });
    }
  };
  if (state.success) return <div>{children}</div>;
  return (
    <RedirectAfterSuccessAction message={RESEND_HINT}>
      <LoadingButton
        appearance={'primary'}
        isLoading={state.isLoading}
        shouldFitContainer
        onClick={onSubmit}
        testId={RESEND}
      >
        {RESEND}
      </LoadingButton>
    </RedirectAfterSuccessAction>
  );
};

export default ResendToken;
