/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import Spinner from '@atlaskit/spinner';
import iContactCompanyType from '../../../../types/contactCompany/iContactCompanyType';
import InputWithController from '../../../form/hookForms/InputWithController';
import { getContactCompanyTypeList } from '../../../../services/ContactCompanyService';
import { CUSTOMER, SUPPLIER } from '../Create.constant';
import { apiErrorToast } from '../../../toast/Toast';

type iState = {
  isLoading: boolean;
  contactCompanyTypes: Array<iContactCompanyType>;
};
const initialState: iState = {
  isLoading: true,
  contactCompanyTypes: [],
};
const ContactCompanyBody = ({
  control,
  onChange,
  errors,
  isCustomer,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  isCustomer: boolean;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const contactCompanyTypes = await getContactCompanyTypeList();
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          contactCompanyTypes,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);
  if (state.isLoading) return <Spinner />;
  return (
    <>
      <InputWithController
        label={'Name'}
        name={'name'}
        onChange={onChange}
        control={control}
        errors={errors}
        testId={'input-name'}
        isRequired
      />
      <div style={{ display: 'none' }}>
        <InputWithController
          label={'typeId'}
          name={'typeId'}
          onChange={onChange}
          control={control}
          errors={errors}
          defaultValue={
            state.contactCompanyTypes.find(
              (c: iContactCompanyType) =>
                c.name.toLowerCase() ===
                (isCustomer ? CUSTOMER.toLowerCase() : SUPPLIER.toLowerCase()),
            )?.id
          }
        />
      </div>
    </>
  );
};

export default ContactCompanyBody;
