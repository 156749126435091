import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Controller, Control, DeepMap, FieldError, useForm } from 'react-hook-form';
import _ from 'lodash';
import { HelperMessage } from '@atlaskit/form';
import { ErrorMsg } from '../../../styles/styles';
import CustomizedLabel from '../CustomizedLabel';
import CustomizeModal from '../../../modal/CustomizeModal';
import AddressModalBody from './AddressModalBody';
import iAddress from '../../../../types/contactCompany/iAddress';
import AddressService from '../../../../services/AddressService';
import { apiErrorToast } from '../../../toast/Toast';
import { assembleAddress } from '../../../../services/UtilsService';

const AddressWrapper = styled.div`
  .address {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3333333333333333;
    padding: 10px 0 5px;
    min-height: 12px;
  }
  .placeholder {
    font-weight: 400;
    padding-left: 0.2rem;
    color: #7a869a;
    margin-top: 8px;
  }
  margin-bottom: 8px;
  cursor: pointer;
`;
type iAddressState = {
  address?: iAddress;
  isOpen: boolean;
  isConfirming: boolean;
};
const addressInitialState: iAddressState = {
  isOpen: false,
  isConfirming: false,
};
const AddressWithController = ({
  label,
  name,
  control,
  defaultValue,
  onChange,
  helpMessage,
  testId = 'address-react-hook-form',
  errors,
  isRequired = true,
}: {
  label: string;
  name: string;
  //  eslint-disable-next-line
  control: Control<Record<string, any>>;
  defaultValue?: iAddress;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  helpMessage?: string;
  testId?: string;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  isRequired?: boolean;
}) => {
  const [addressState, setAddressState] = useState(addressInitialState);
  const {
    control: addressControl,
    setValue: addressSetValue,
    errors: addressErrors,
    handleSubmit: addressHandleSubmit,
  } = useForm({});

  useEffect(() => {
    setAddressState(prevState => ({ ...prevState, address: defaultValue }));
  }, [defaultValue]);

  const onClose = () => setAddressState({ ...addressState, isOpen: false });
  //  eslint-disable-next-line
  const onAddressSubmit = async (data: any) => {
    setAddressState({ ...addressState, isConfirming: true });
    try {
      const address =
        typeof defaultValue === 'undefined'
          ? await AddressService.createAddress(data)
          : await AddressService.editAddress(defaultValue.id, data);
      onChange(name, address, { shouldValidate: true });
      setAddressState({
        ...addressState,
        address,
        isConfirming: false,
        isOpen: false,
      });
    } catch (error) {
      apiErrorToast(error);
      setAddressState({ ...addressState, isConfirming: false });
    }
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || null}
        rules={{
          required: isRequired,
        }}
        render={() => (
          <AddressWrapper onClick={() => setAddressState({ ...addressState, isOpen: true })}>
            <CustomizedLabel label={label} htmlFor={testId} isRequired={isRequired} />
            {addressState.address ? (
              <div className={'address'}>{assembleAddress(addressState.address)}</div>
            ) : (
              <div className={'placeholder'}>Click to enter value</div>
            )}
            <HelperMessage>{helpMessage}</HelperMessage>
            {_.get(errors, name) && <ErrorMsg>is required</ErrorMsg>}
          </AddressWrapper>
        )}
      />
      <CustomizeModal
        isOpen={addressState.isOpen}
        isConfirming={addressState.isConfirming}
        onConfirm={addressHandleSubmit(onAddressSubmit)}
        onCancel={onClose}
        isDisabled={Object.keys(addressErrors).length > 0}
        confirmBtnName={addressState.address ? 'Update' : 'Create'}
        modalHeading={addressState.address ? 'Update address' : 'Create address'}
        modalBody={
          <AddressModalBody
            control={addressControl}
            errors={addressErrors}
            onChange={addressSetValue}
            address={addressState.address}
          />
        }
      />
    </>
  );
};

export default AddressWithController;
