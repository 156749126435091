import React, { useEffect } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import DynamicTable from '@atlaskit/dynamic-table';
import Spinner from '@atlaskit/spinner';
import SectionMessage from '@atlaskit/section-message';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import iItemLocation from '../../../types/warehouse/iItemLocation';
import SearchBar from '../../../shared/search/SearchBar';
import { ADD_PROD_COLUMNS } from '../sharedComponents/StockTransfer.constants';
import { getRows } from './AddProdTableHelper';
import { getItemLocationList } from '../../../services/ItemLocationService';
import { FlexContainer } from '../../../shared/styles/styles';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import { getHeads } from '../../../services/UtilsService';

const AddProdsTable = ({
  control,
  onChange,
  errors,
  warehouseLocationId,
  records,
  onMovingQtyChange,
  onNoProduct,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  warehouseLocationId: string;
  records: Array<iItemLocationTransfer>;
  //    eslint-disable-next-line
  onMovingQtyChange: (name: string, value: any) => void;
  onNoProduct: (isNoProduct: boolean) => void;
}) => {
  const {
    state,
    edit,
    onSetCurrentPage,
    onSearch,
  } = useListCrudHook<iItemLocation>({
    getFn: getItemLocationList,
    filter: `warehouseLocationId:${warehouseLocationId},qty>0`,
    sort: 'createdAt:DESC',
    paginationApplied: true,
    keywordColumns: 'reference,product.name,product.productCode',
    perPage: 5,
  });
  //    eslint-disable-next-line
  const onChangeMiddleWare = (name: string, value: any, config?: any) => {
    // console.log(value);
    const itemLocation = state.data.find(
      (item: iItemLocation) => item.id === name,
    );
    if (itemLocation) {
      onMovingQtyChange(name, { ...itemLocation, movingQty: Number(value) });
    }
    onChange(name, value, config);
  };

  // can not directly call onNoProduct on the second if
  // can not call onNoProduct to change parent's state when current component is rendering
  // after load, no data then trigger callback
  // if search by a keyword, should not trigger callback
  useEffect(() => {
    onNoProduct(
      !state.isLoading &&
        typeof state.total !== 'undefined' &&
        state.total === 0 &&
        !edit.keyword,
    );
  }, [state.total, state.isLoading, edit.keyword, onNoProduct]);

  if (state.isLoading) return <Spinner />;

  if (
    !state.isLoading &&
    typeof state.total !== 'undefined' &&
    state.total === 0 &&
    !edit.keyword
  ) {
    return (
      <SectionMessage appearance={'warning'}>
        No products stored here
      </SectionMessage>
    );
  }
  return (
    <>
      <FlexContainer className={'space-between'}>
        <div />
        <div style={{ flexGrow: 0, minWidth: '250px' }}>
          <SearchBar
            keyword={edit.keyword}
            onSearch={onSearch}
            placeholder={'search by product code/batch....'}
          />
        </div>
      </FlexContainer>
      <DynamicTable
        head={getHeads(ADD_PROD_COLUMNS, 'addProds')}
        rows={getRows({
          columns: ADD_PROD_COLUMNS,
          data: state.data,
          control,
          onChange: onChangeMiddleWare,
          errors,
          records,
        })}
        testId={'stock-transfer-table'}
      />
      {!!state.perPage && !!state.currentPage && !!state.total && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );
};

export default AddProdsTable;
