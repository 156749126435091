/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line
import {
  // @ts-ignore
  StickyTable,
  // @ts-ignore
  Row,
  // @ts-ignore
  Cell,
} from 'react-sticky-table';
import { iPagination } from '../../../../../../types/iPagination';
import CustomizePagination from '../../../../../../shared/pagination/CustomizePagination';
import { PAGINATION_PRE_PAGE } from '../../../../../../shared/constants/AsyncConstants';

const PreviewDataTable = ({
  data,
  columns,
}: {
  data: any[][];
  columns: Array<string>;
}) => {
  const initialState: iPagination = {
    from: 1,
    to: data.length > PAGINATION_PRE_PAGE ? PAGINATION_PRE_PAGE : data.length,
    total: data.length,
    perPage: PAGINATION_PRE_PAGE,
    currentPage: 1,
  };
  const [state, setState] = useState<iPagination>(initialState);
  const onPageChange = (page: number) => {
    // when page=2
    const from = state.perPage * (page - 1) + 1;
    // from:11
    const to = state.perPage * page;
    // to:20
    setState({ ...state, from, to, currentPage: page });
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <StickyTable leftStickyColumnCount={2}>
          <Row>
            {columns.map((column: string) => (
              <Cell key={`header-${column}`}>{column}</Cell>
            ))}
          </Row>
          {data.slice(state.from - 1, state.to).map((row: any[]) => (
            <Row key={uuidv4()}>
              {row.map((cell: any) => (
                <Cell key={uuidv4()}>{cell}</Cell>
              ))}
            </Row>
          ))}
        </StickyTable>
      </div>
      <CustomizePagination
        range={Math.ceil(state.total / state.perPage)}
        currentPage={state.currentPage}
        onChange={onPageChange}
      />
    </>
  );
};

export default PreviewDataTable;
