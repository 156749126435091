import React, { useState } from 'react';
import { LoadingButton } from '@atlaskit/button';
import iUser from '../../types/user/iUser';
import CompanyExceptionEmptyState from './CompanyExceptionEmptyState';
import { initializeCompany } from '../../services/AuthService';
import { apiErrorToast } from '../toast/Toast';

type iState = { isLoading: boolean };
const initialState: iState = {
  isLoading: false,
};
const CompanyNotOnBoard = ({ user }: { user: iUser }) => {
  const [state, setState] = useState(initialState);
  const initialize = async () => {
    try {
      setState({ ...state, isLoading: true });
      await initializeCompany(user.id);
      window.location.reload();
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isLoading: false });
    }
  };
  const getPrimaryAction = () => (
    <LoadingButton
      appearance="primary"
      isLoading={state.isLoading}
      onClick={initialize}
    >
      Initialize
    </LoadingButton>
  );

  const props = {
    header: 'The company initialization is not done',
    description:
      'Prior to surf the MIESoft, we have to setup some raw,basic but vital data, Please complete the initialization first; if you did,contact MIESoft support to see the issue.',
    primaryAction: getPrimaryAction(),
  };
  // eslint-disable-next-line
  return <CompanyExceptionEmptyState {...props} />;
};

export default CompanyNotOnBoard;
