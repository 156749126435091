import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Popup from '@atlaskit/popup';
import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import { ButtonItem, Section } from '@atlaskit/menu';
import { useSelector } from 'react-redux';
import CustomizeModal from '../../modal/CustomizeModal';
import { handleNullException } from '../../../services/UtilsService';
import { apiErrorToast } from '../../toast/Toast';
import { createWarehouse } from '../../../services/WarehouseService';
import { createSalesOrder } from '../../../services/SalesOrderService';
import { createPurchaseOrder } from '../../../services/PurchaseService';
import { createNCR } from '../../../services/NCRService';
import { createProduct, createProductFromTemplate } from '../../../services/product/ProductService';
import { createContactCompanyRefactor } from '../../../services/ContactCompanyService';
import {
  COMPONENT_MAP,
  CUSTOMER,
  CREATE_NAME,
  PURCHASE_ORDER,
  SUPPLIER,
  //  eslint-disable-next-line
  WAREHOUSE_lOCATION,
  SALES_ORDER,
  NCR,
  PRODUCT,
  getCreateSubMenu,
} from './Create.constant';
import { RootState } from '../../../redux/makeReduxStore';

type iCreateSubMenu = {
  label: string;
  value: string;
  prefix: string;
  isHidden?: boolean;
};
type iState = {
  isOpen: boolean;
  createAgainst?: iCreateSubMenu;
  isConfirming: boolean;
};
const initialState: iState = {
  isOpen: false,
  isConfirming: false,
};
const PopupWrapper = styled.div`
  .is-primary {
    background-color: rgb(0, 82, 204);
    color: rgb(255, 255, 255);
  }
`;
const PopupItemWrapper = styled.div``;

const CreatePopup = () => {
  const history = useHistory();
  const [state, setState] = useState(initialState);
  const { control, setValue, errors, handleSubmit, watch } = useForm();

  const { user } = useSelector((s: RootState) => s.auth);
  const subMenuItems = getCreateSubMenu(user).filter((sub: iCreateSubMenu) => sub.isHidden !== true);

  const showPopup = (isOpen: boolean) => {
    setState({
      ...state,
      isOpen,
    });
  };
  const getMenuItems = () => {
    return (
      <PopupItemWrapper data-testid={`nav-${CREATE_NAME}`}>
        {subMenuItems.map((sub: iCreateSubMenu, index: number) => (
          <Section hasSeparator={index !== 0} key={`${CREATE_NAME}-${sub.label}`}>
            <ButtonItem
              onClick={() => setState({ ...state, createAgainst: sub })}
              testId={`nav-${CREATE_NAME}-${sub.label}`}
            >
              {sub.label}
            </ButtonItem>
          </Section>
        ))}
      </PopupItemWrapper>
    );
  };
  const getPopupItemModalBody = (componentName?: string) => {
    if (typeof componentName === 'undefined') return null;
    if (handleNullException(COMPONENT_MAP, componentName)) {
      const Component = handleNullException(COMPONENT_MAP, componentName);
      return <Component control={control} onChange={setValue} errors={errors} watch={watch} />;
    }
    return null;
  };
  //  eslint-disable-next-line
  const onCreate = async (data: any) => {
    if (typeof state.createAgainst === 'undefined') return;
    setState({ ...state, isConfirming: true });
    try {
      let created;
      switch (state.createAgainst.value) {
        case CUSTOMER:
        case SUPPLIER:
          created = await createContactCompanyRefactor(data);
          break;
        //  eslint-disable-next-line
        case WAREHOUSE_lOCATION:
          created = await createWarehouse(data);
          break;
        case SALES_ORDER:
          created = await createSalesOrder(data);
          break;
        case PURCHASE_ORDER:
          created = await createPurchaseOrder(data);
          break;
        case NCR:
          created = await createNCR({
            ...data,
            productId: data.product.id,
            jobId: data.job.id,
            reportedQty: Number(data.reportedQty),
            reportedDate: data.reportedDate,
            customerId: data.customerId,
            isSampleReceived: data.isSampleReceived === 'yes',
            sampleReceivedDate: data.sampleReceivedDate,
          });
          break;
        case PRODUCT:
          created = data.templateId
            ? await createProductFromTemplate(data.templateId, {
                name: data.name,
                productCode: data.productCode,
              })
            : await createProduct(data);
          break;
        default:
          break;
      }
      if (!created) return;
      setState({
        ...state,
        isConfirming: false,
        createAgainst: undefined,
      });
      history.push(`${state.createAgainst.prefix}/${handleNullException(created, 'id')}`);
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };

  if (subMenuItems.length <= 0) {
    return null;
  }

  return (
    <PopupWrapper>
      <Popup
        isOpen={state.isOpen}
        placement={'bottom-start'}
        content={getMenuItems}
        onClose={() => showPopup(false)}
        trigger={(
          triggerProps: any, //eslint-disable-line
        ) => (
          <PrimaryDropdownButton
            {...triggerProps} //eslint-disable-line
            tooltip={''}
            onClick={() => showPopup(!state.isOpen)}
            testId={`nav-${CREATE_NAME}-popup-trigger`}
            className={'is-primary'}
          >
            {CREATE_NAME}
          </PrimaryDropdownButton>
        )}
      />
      {state.createAgainst && (
        <CustomizeModal
          isOpen={!!state.createAgainst}
          onCancel={() => setState({ ...state, createAgainst: undefined })}
          onConfirm={handleSubmit(onCreate)}
          modalHeading={`Create a ${state.createAgainst.label}`}
          modalBody={getPopupItemModalBody(state.createAgainst.value)}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
          confirmBtnName={'Create'}
        />
      )}
    </PopupWrapper>
  );
};
export default CreatePopup;
