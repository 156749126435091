import React from 'react';
import styled from 'styled-components';

const CenteredDivWrapper = styled.div`
  position: relative;
  .centered-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export default ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <CenteredDivWrapper
      data-testid={'centered-div-wrapper'}
      className={`centered-div-wrapper ${className}`}
    >
      <div className={'centered-div'}>{children}</div>
    </CenteredDivWrapper>
  );
};
