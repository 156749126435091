import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Grid, GridColumn } from '@atlaskit/page';
import { useSelector } from 'react-redux';
import iAcquisitionOrder from '../../types/acquisitionOrder/iAcquisitionOrder';
import BackendPage from '../../layout/BackendPage';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import SearchBar from '../../shared/search/SearchBar';
import useStatusSelector from '../../shared/hooks/useStatusSelector';
import PageTitleWithAddOne from '../../shared/heading/PageTitleWithAddOne';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import CustomizePagination from '../../shared/pagination/CustomizePagination';
import DeleteByRetype from '../../shared/deleteWithReType/DeleteByReType';
import useColumnHooksUpgrade from '../../shared/hooks/useShowHideColumnHook/useColumnHook';
import SpinWhenLoading from '../../shared/spin/SpinWhenLoading';
import AcquisitionOrderTable from './list/AcquisitionOrderTable';
import AOPOModalBody from '../../shared/asyncContactCompany/createModalBody/AOPOModalBody';
import { ALL } from '../../shared/constants/StatusCategories';
import { AOS_URL } from '../../shared/UrlMap';
import { AdvancedSearchSectionWrapper, FlexContainer } from '../../shared/styles/styles';
import { getEntityCategoryList } from '../../services/CategoryService';
import { AO_COLUMNS, AO_PAGE_NAME } from './shared/AO.constants';
import { createAO, deleteAO, getAOList, updateAO } from '../../services/AcquisitionOrderService';
import PaginationDetail from '../../shared/pagination/paginationDetail/PaginationDetail';
import useAdvancedSearchHooks from '../../shared/advancedSearch/AdvancedSearch';
import { PO_ADVANCED_SEARCH_CONFIGS } from '../purchases/constants';
import Advanced from '../../shared/contactCompany/list/Advanced';
import ExportReport from '../../shared/exportReport/ExportReport';
import { getReportArrSegments } from '../purchases/list/TableHelper';
import { getExportReportName } from '../../services/UtilsService';
import { ACQUISITION_ORDER } from '../../shared/topNavPopups/createPopup/Create.constant';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_ACQUISITION_ORDERS, ACCESS_CODE_SUPPLIERS } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE, ACCESS_CAN_READ } from '../../types/settings/UserAccess/iRoleAccess';

const AcquisitionOrderList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canCreate = AccessService.hasAccess(ACCESS_CODE_ACQUISITION_ORDERS, ACCESS_CAN_CREATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_ACQUISITION_ORDERS, ACCESS_CAN_DELETE, user);
  const canReadSupplier = AccessService.hasAccess(ACCESS_CODE_SUPPLIERS, ACCESS_CAN_READ, user);

  const history = useHistory();
  const { control, setValue, errors, handleSubmit, watch } = useForm();
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onSearch,
    onSetCurrentPage,
    onSetSort,
    onOpenDeleteModal,
    onFilter,
    onSubmit,
    onRefreshList,
  } = useListCrudHook<iAcquisitionOrder>({
    getFn: getAOList,
    deleteFn: deleteAO,
    createFn: createAO,
    updateFn: updateAO,
    sort: 'orderNumber:DESC',
    paginationApplied: true,
    notRenderWithoutFilter: true,
    createCallBack: (id: string) => history.push(`${AOS_URL}/${id}`),
    perPage: 10,
    keywordColumns: 'orderNumber',
  });
  const { selected, getTypeSelectorComponent, categories, isStatusLoading } = useStatusSelector({
    type: 'AcquisitionOrder',
    isMulti: true,
    getFn: getEntityCategoryList,
  });

  const { columns, getShowHideColumns } = useColumnHooksUpgrade('acquisitionOrder', AO_COLUMNS);

  const { getAdvancedFilterStrArr, getSearchControllers } = useAdvancedSearchHooks(PO_ADVANCED_SEARCH_CONFIGS, false);

  // report request
  const getAdvancedFilterStr = () => {
    const filter = selected.includes(ALL) || selected.length === 0 ? '' : `statusId:${selected.join('|')}`;

    const advancedSearchStr = getAdvancedFilterStrArr()
      .filter(strFilter => strFilter !== '')
      .join(',');

    if (advancedSearchStr) {
      return `${advancedSearchStr},${filter}`;
    }
    return filter;
  };

  const getPageHeader = () => (
    <>
      <PageTitleWithAddOne
        title={AO_PAGE_NAME}
        onClickAddBtn={onOpenAddModal}
        className={'space-below'}
        isDisabled={!canCreate}
      />
      <FlexContainer className={'space-between'}>
        {getTypeSelectorComponent()}
        <FlexContainer>
          <SearchBar keyword={edit.keyword} onSearch={onSearch} placeholder={'search via No....'} />
          <Advanced onClick={() => setIsAdvancedSearch(prev => !prev)} />
        </FlexContainer>
      </FlexContainer>
      {isAdvancedSearch && (
        <AdvancedSearchSectionWrapper>
          <Grid layout={'fluid'} spacing={'cosy'}>
            {getSearchControllers()}

            <GridColumn medium={0.5}>
              <ExportReport
                className={'margin-above margin-below'}
                total={state.total}
                perPage={state.perPage}
                getFn={(currentPage: number) =>
                  getAOList({
                    currentPage: currentPage.toString(),
                    perPage: state.perPage?.toString() || '10',
                    advancedFilter: getAdvancedFilterStr() || '',
                    sort: 'createdAt:DESC',
                    like: edit.keyword ? `orderNumber:${edit.keyword},supplier.name:${edit.keyword}` : '',
                  })
                }
                onFormatReport={orders => getReportArrSegments(orders, columns)}
                reportFileName={getExportReportName(ACQUISITION_ORDER)}
              />
            </GridColumn>
          </Grid>
        </AdvancedSearchSectionWrapper>
      )}
    </>
  );

  useEffect(
    () => {
      if (!isStatusLoading) {
        const filter = selected.includes(ALL) || selected.length === 0 ? '' : `statusId:${selected.join('|')}`;

        const advancedSearchStr = getAdvancedFilterStrArr()
          .filter(strFilter => strFilter !== '')
          .join(',');

        if (advancedSearchStr) {
          onFilter(`${advancedSearchStr},${filter}`);
        } else {
          onFilter(filter);
        }
      }
    },
    //  eslint-disable-next-line
    [JSON.stringify(selected), JSON.stringify(getAdvancedFilterStrArr())],
  );

  if (state.isLoading) return <SpinWhenLoading />;

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <FlexContainer className={'space-between space-below'}>
        {!!state.total && !!state.from && !!state.to && (
          <PaginationDetail from={state.from} to={state.to} total={state.total} onRefreshResults={onRefreshList} />
        )}
        {getShowHideColumns()}
      </FlexContainer>
      <AcquisitionOrderTable
        data={state.data}
        onSetSort={onSetSort}
        onOpenDeleteModal={canDelete ? onOpenDeleteModal : undefined}
        isLoading={state.isLoading}
        columns={columns.map(col => {
          if (col.key === 'supplier.name' && !canReadSupplier) {
            return {
              ...col,
              type: 'text',
            };
          }
          return col;
        })}
        categories={categories}
      />

      <CustomizeModal
        isOpen={edit.isModalOpen && !edit.delTargetId}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <AOPOModalBody control={control} onChange={setValue} errors={errors} watch={watch} isCustomer={false} />
        }
        modalHeading={'Creating a Acquisition Order for'}
        confirmBtnName={'Create'}
      />
      <DeleteByRetype
        isOpen={edit.isModalOpen && !!edit.delTargetId}
        onClose={onCloseModal}
        onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
        answer={edit.target?.orderNumber}
        isConfirming={state.isConfirming}
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </BackendPage>
  );
};

export default AcquisitionOrderList;
