import React, { useState } from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import Button from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';

import iReceivingItem from '../../../types/purchases/iReceivingItem';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import LinkBtn from '../../../shared/buttons/LinkBtn';
import { getDeleteBtn, getHeads } from '../../../components/settings/utils';
import AsyncSearch from '../../../shared/asyncSearch/AsyncSearch';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import iProduct from '../../../types/product/iProduct';
import { getProductDetail, getProductListAsyncSearch } from '../../../services/product/ProductService';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import Activities from '../../../shared/activitySection/GeneralActivity';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import {
  createPoReceivingItem,
  deletePoReceivingItem,
  getPoReceivingItems,
  updatePoReceivingItem,
} from '../../../services/PoReceivingItemService';
import GeneralAttachments from '../../../shared/generalAttachments/GeneralAttachments';
import ReceivingProductModal from './ReceivingProductModal';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import iPoReceiving from '../../../types/purchases/iPoReceiving';
import iPurchaseOrderItem from '../../../types/purchases/iPurchaseOrderItem';
import { FlexContainer } from '../../../shared/styles/styles';
import { PO_RECEIVING_PRODUCT_ASYNC_SEARCH, PO_RECEIVING_STATUS_CLOSED_CATEGORY } from '../constants';
import ApiModal from '../../../shared/modal/ApiModal';
import { submitPoReceiving } from '../../../services/PoReceivingService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { PRODUCTS_URL } from '../../../shared/UrlMap';
import { handleNullException } from '../../../services/UtilsService';
import { getQtyBeforeConversion } from '../Purchase.utilities';

const ReceivingItemsContainer = styled.div`
  width: 100%;
`;

const TableWrapper = styled.div`
  padding: 1rem 0 0 1rem;
`;

const AsyncSearchWrapper = styled.div`
  width: 20rem;
  margin: 0 0 0 1rem;
`;

const AttachmentsWrapper = styled.div`
  display: inline-block;
  margin-top: 3rem;
  padding-left: 1rem;
  max-width: 100%;
`;

const ActivitiesWrapper = styled.div`
  padding-left: 1rem;
`;

const Title = styled.h3`
  margin-left: 1rem;
`;

const COLUMNS = ['Product', 'Supplier Product Code', 'Quantity', 'To Location', 'Lot / Batch No.', 'Operation'];

const ReceivingItems = ({
  id,
  warehouseLocations,
  poReceiving,
  onRefresh,
}: {
  id: string;
  warehouseLocations: iWarehouseLocation[];
  poReceiving?: iPoReceiving;
  onRefresh: () => void;
}) => {
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onSubmit,
  } = useListCrudHook<iReceivingItem>({
    getFn: getPoReceivingItems,
    createFn: createPoReceivingItem,
    updateFn: updatePoReceivingItem,
    deleteFn: deletePoReceivingItem,
    filter: `purchaseOrderReceivingId:${id}`,
    paginationApplied: true,
    perPage: 10,
  });
  const [targetProduct, setTargetProduct] = useState<iProduct>();
  const [targetPoItem, setTargetPoItem] = useState<iPurchaseOrderItem>();
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

  const { control, setValue, errors, handleSubmit } = useForm();

  const onOpenEdit = (item: iReceivingItem) => {
    // make sure local poItem is updated when editing
    setTargetPoItem(item.purchaseOrderItem);
    onOpenEditModal(item.id);
  };

  const getNameContent = (item: iReceivingItem) => (
    <ComposeSecondaryText secondaryText={item.product.name}>
      <LinkBtn btnName={item.product.productCode} onClick={() => onOpenEdit(item)} />
    </ComposeSecondaryText>
  );

  const getNameRedirect = (item: iReceivingItem) => (
    <ComposeSecondaryText secondaryText={item.product.name}>
      <LinkBtnUrl btnName={item.product.productCode} url={`${PRODUCTS_URL}/${item.productId}`} />
    </ComposeSecondaryText>
  );

  const onSelect = async (payload: string) => {
    if (!payload) return;
    const productId = payload.split(':')[0];
    try {
      const localProduct = await getProductDetail(productId);
      // poItem
      const target = poReceiving?.purchaseOrder?.purchaseOrderItems.find(item => item.productId === localProduct.id);
      setTargetPoItem(target);
      setTargetProduct(localProduct);
      onOpenAddModal();
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onCreateReceivingItem = (data: { [key: string]: string }) => {
    onSubmit({
      ...data,
      productId: targetProduct?.id,
      purchaseOrderReceivingId: id,
      purchaseOrderItemId: targetPoItem?.id,
      qty: targetPoItem ? Number(data.qty) * Number(targetPoItem.unitConversion) : Number(data.qty),
    });
  };

  const onCloseSubmitModal = () => {
    setIsSubmitModalOpen(false);
    onRefresh();
  };

  const getRows = () => {
    return state.data
      ?.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
      .map((item: iReceivingItem) => ({
        cells: [
          {
            key: item.product.productCode,
            content:
              poReceiving?.status?.entityStatusCategory?.code === PO_RECEIVING_STATUS_CLOSED_CATEGORY
                ? getNameRedirect(item)
                : getNameContent(item),
          },
          {
            content: handleNullException(item, 'purchaseOrderItem.supplierProductCode'),
          },
          {
            content: getQtyBeforeConversion(item),
          },
          {
            content: (
              <ComposeSecondaryText secondaryText={item.toWarehouseLocation.locationCode}>
                <LinkBtnUrl
                  btnName={item.toWarehouseLocation?.name}
                  url={`/b/warehouse/${item.toWarehouseLocationId}`}
                />
              </ComposeSecondaryText>
            ),
          },
          { content: `${item.reference}` },
          {
            content:
              poReceiving?.status?.entityStatusCategory?.code === PO_RECEIVING_STATUS_CLOSED_CATEGORY
                ? null
                : getDeleteBtn({
                    id: item.id,
                    answer: item.product.name || 'Name n/a',
                    onClick: onDelete,
                  }),
          },
        ],
      }));
  };

  return (
    <>
      <ReceivingItemsContainer>
        <Title>Receiving Items</Title>
        <TableWrapper>
          <DynamicTable
            head={getHeads(COLUMNS, 'po-receiving-items')}
            rows={getRows()}
            testId={'po-receiving-items-table'}
            isLoading={state.isLoading}
          />
        </TableWrapper>
      </ReceivingItemsContainer>

      {poReceiving?.status?.entityStatusCategory?.code !== PO_RECEIVING_STATUS_CLOSED_CATEGORY && (
        <FlexContainer className="space-between">
          <AsyncSearchWrapper>
            <AsyncSearch
              id={PO_RECEIVING_PRODUCT_ASYNC_SEARCH}
              onSelect={onSelect}
              promiseFn={(keyword: string) =>
                getProductListAsyncSearch({
                  like: `productCode:${keyword},name:${keyword}`,
                  filter: 'isForPurchase:true or requireThirdPartyService:true',
                })
              }
              optionLabel={['productCode', 'name']}
              searchBarPlaceholder={'Search productCode/name to add...'}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              // filterFn={(item: any) => item.isForPurchase === true}
            />
          </AsyncSearchWrapper>

          <Button isSelected onClick={() => setIsSubmitModalOpen(true)} isDisabled={state.data.length === 0}>
            Submit
          </Button>
        </FlexContainer>
      )}

      <AttachmentsWrapper>
        <GeneralAttachments entityId={id} entityName={'PurchaseOrderReceiving'} />
      </AttachmentsWrapper>

      <ActivitiesWrapper>
        <Activities modelId={id} modelName={'PurchaseOrderReceiving'} />
      </ActivitiesWrapper>

      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onCreateReceivingItem)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <ReceivingProductModal
            productId={edit.target ? edit.target?.productId : targetProduct?.id}
            control={control}
            errors={errors}
            onChange={setValue}
            target={edit.target}
            warehouseLocations={warehouseLocations}
            targetPoItem={targetPoItem}
          />
        }
        modalHeading={
          edit.target
            ? `Receiving Product: ${edit.target?.product.productCode}`
            : `Receiving Product: ${targetProduct?.productCode}`
        }
        confirmBtnName={'Confirm'}
        width={'80%'}
        height={'50vh'}
      />
      {isSubmitModalOpen && (
        <ApiModal
          isOpen={isSubmitModalOpen}
          onConfirm={() => submitPoReceiving(id)}
          onCancel={onCloseSubmitModal}
          modalHeading={'Are you sure to submit this PO receiving?'}
          modalBody={
            <SectionMessage appearance="warning">
              <p>Please make sure you receive all items in the delivery docket.</p>
            </SectionMessage>
          }
          confirmBtnName={'Submit'}
          successFlagTitle={'Received!'}
        />
      )}
    </>
  );
};

export default ReceivingItems;
