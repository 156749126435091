import React from 'react';
import { useParams } from 'react-router-dom';
import { CUSTOMER } from '../../shared/contactCompany/constants';
import ContactCompanyDetail from '../../shared/contactCompany/detail/ContactCompanyDetail';
import { iRouteTypeId } from '../../shared/contactCompany/detail/types';
import { CUSTOMER_URL } from '../../shared/UrlMap';
import { CUSTOMER_PAGE_NAME } from './list/ContactCompany.constants';

const CustomerDetail = () => {
  const { id } = useParams<iRouteTypeId>();
  return <ContactCompanyDetail id={id} type={CUSTOMER} urlPrefix={CUSTOMER_URL} upperPageName={CUSTOMER_PAGE_NAME} />;
};

export default CustomerDetail;
