import React from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import iSalesOrderType from '../../../../types/sales/iSalesOrderType';
import {
  FilterBtnWrapper,
  statusSelectorStyle,
} from '../../../../shared/styles/styles';

interface iFilterProps {
  options: iSalesOrderType[];
  onSelectType: (type: string) => void;
  pageName: string;
  selectedTypes: string[];
}

const TypeFilter = ({
  options,
  onSelectType,
  pageName,
  selectedTypes,
}: iFilterProps) => {
  return (
    <ButtonGroup>
      {options.map(elem => {
        return (
          <FilterBtnWrapper key={`${pageName}-type-${elem?.id}`}>
            <Button
              isSelected={selectedTypes.includes(elem?.id)}
              onClick={() => onSelectType(elem?.id)}
              shouldFitContainer
              style={statusSelectorStyle}
            >
              {elem?.name}
            </Button>
          </FilterBtnWrapper>
        );
      })}
    </ButtonGroup>
  );
};

export default TypeFilter;
