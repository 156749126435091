/*  eslint-disable */
import React, { useState } from 'react';
import _ from 'lodash';
import iContactCompanyProduct from '../../../../../types/contactCompany/iContactCompanyProduct';
import CustomizeModal from '../../../../modal/CustomizeModal';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CreteDNModalBody from './CreteDNModalBody';
import { CREATE_DN } from '../ServiceProvider.constants';
import { addToastForAPIResponse, apiErrorToast } from '../../../../toast/Toast';
import { DISPATCH_NOTES_URL } from '../../../../UrlMap';
import { createDispatchNote } from '../../../../../services/DispatchNoteService';
import { createDispatchNoteItem } from '../../../../../services/DispatchNoteItemService';
import { CreateModalBodyWrapper } from '../ServiceProvider.style';
import { NUMBER_ROUND_DECIMAL } from '../../../../constants/ActionConstants';

type iState = {
  isOpen: boolean;
  isConfirming: boolean;
};
const initialState: iState = {
  isOpen: false,
  isConfirming: false,
};
const CreatePOServiceProvider = ({
  jobId,
  ccp,
  onCancel,
}: {
  jobId: string;
  ccp: iContactCompanyProduct;
  onCancel: () => void;
}) => {
  const history = useHistory();
  const [isConfirming, setIsConfirming] = useState(false);
  const { control, setValue, errors, handleSubmit, watch} = useForm({});
  //  eslint-disable-next-line
  const onSubmit = async (data: any) => {
    setIsConfirming(true);
    try {
      const { id } = await createDispatchNote(data);
      await createDispatchNoteItem({
        dispatchNoteId: id,
        quantity: _.round(data.qty, NUMBER_ROUND_DECIMAL),
        jobId,
      });
      addToastForAPIResponse('success');
      setIsConfirming(false);
      history.push(`${DISPATCH_NOTES_URL}/${id}`);
    } catch (e) {
      apiErrorToast(e);
      setIsConfirming(false);
    }
  };
  return (
    <CustomizeModal
      isOpen={true}
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onCancel}
      confirmBtnName={'Create'}
      modalBody={
        <CreateModalBodyWrapper>
          <CreteDNModalBody
            control={control}
            errors={errors}
            onChange={setValue}
            watch={watch}
            ccp={ccp}
          />
        </CreateModalBodyWrapper>
      }
      modalHeading={<span>{CREATE_DN}</span>}
      isConfirming={isConfirming}
      isDisabled={Object.keys(errors).length > 0}
    />
  );
};

export default CreatePOServiceProvider;
