export const CUSTOMER = 'customer';
export const SUPPLIER = 'supplier';
export const SALE_COLUMNS = [
  'Type',
  'Order No.',
  'Total Amt.',
  'Amt Due',
  'Created',
  'Updated',
  'Operation',
];
export const PURCHASE_COLUMNS = [
  'PO No.',
  'Reference',
  'ETA',
  'Total Amt.',
  'Created',
  'Updated',
  'Operation',
];
