import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { ButtonGroup } from '@atlaskit/button';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import JobDetails from '../../shared/job/jobDetails/JobDetails';
import JobDetailRouteType from '../../shared/job/jobDetails/types/JobDetailRouteType';
import iJob from '../../types/job/iJob';
import Prints from '../../shared/job/jobDetails/print/Prints';
import MoreIconPopup from '../../shared/popup/MoreIconPopup';
import PopupContent from '../../shared/popup/GeneralPopupContent';
import DeleteByRetype from '../../shared/deleteWithReType/DeleteByReType';
import useDetailHook from '../../shared/hooks/useDetailHook/useDetailHook';
import BreadcrumbsTwoLayers from '../../shared/heading/BreadcrumbsTwoLayers';
import Title from '../../shared/job/jobDetails/styles/Title';
import JobStatusMenu from './jobDetail/jobStatus/JobStatusMenu';
import { handleNullException } from '../../services/UtilsService';
import { sortJobCategories } from './jobDetail/shared/JobDetail.utils';
import { getBomItemAttributes } from '../../services/BomAttributeService';
import { initialSupState } from './jobDetail/shared/JobDetail.constants';
import { FlexContainer } from '../../shared/styles/styles';
import { JOB_URL } from '../../shared/UrlMap';
import { apiErrorToast } from '../../shared/toast/Toast';
import { deactivateJob, getJob, getJobCategories, updateJob } from '../../services/JobService';
import CompanyPreferenceService from '../../services/Settings/CompanyPreferenceService';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_JOBS } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE, ACCESS_CAN_UPDATE } from '../../types/settings/UserAccess/iRoleAccess';

const Job = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_UPDATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_DELETE, user);
  const history = useHistory();
  const { id: jobId } = useParams<JobDetailRouteType>();
  const { state, edit, onUpdate, onOpenDeleteModal, onDelete, onCloseModal, onRefresh } = useDetailHook<iJob>({
    id: jobId,
    getFn: getJob,
    updateFn: updateJob,
    deleteFn: deactivateJob,
    deleteCallback: () => history.push(JOB_URL),
  });
  const [supState, setSupState] = useState(initialSupState);

  useEffect(() => {
    let isCancelled = false;

    Promise.all([getBomItemAttributes(), getJobCategories(), CompanyPreferenceService.isForFilmCompany()])
      .then(resp => {
        if (isCancelled) return;
        const [bomItemAttributes, jobCategories, isForFilmCompany] = resp;
        setSupState(prevState => ({
          ...prevState,
          bomItemAttributes,
          jobCategories: sortJobCategories(jobCategories),
          isLoading: false,
          canPrint: isForFilmCompany,
        }));
      })
      .catch(error => {
        if (isCancelled) return;
        setSupState(prevState => ({
          ...prevState,
          isLoading: false,
          canPrint: false,
        }));
        apiErrorToast(error);
      });

    return () => {
      isCancelled = true;
    };
  }, []);

  const getPageHeader = () => {
    if (typeof state.data === 'undefined') {
      return null;
    }
    return (
      <FlexContainer className={'space-between'}>
        <div>
          <BreadcrumbsTwoLayers name={'Jobs'} subName={state.data.jobNumber} url={JOB_URL} />
          <FlexContainer>
            <Title className={'space-right'}>Job #: {state.data.jobNumber}</Title>
            <JobStatusMenu
              categories={supState.jobCategories}
              defaultValue={handleNullException(state.data, 'statusId')}
              onSelect={(value: string) => onUpdate({ statusId: value })}
              isDisabled={!canUpdate}
            />
          </FlexContainer>
        </div>
        <ButtonGroup>
          {supState.canPrint === true ? (
            <Prints jobId={state.data.id} jobAttributes={state.data.jobAttributes} qtyTotal={state.data.qtyTotal} />
          ) : null}
          {canDelete ? (
            <MoreIconPopup>
              <PopupContent id={jobId} onOpenDeleteModal={onOpenDeleteModal} />
            </MoreIconPopup>
          ) : null}
        </ButtonGroup>
      </FlexContainer>
    );
  };

  if (supState.isLoading) {
    return (
      <BackendPage>
        <Spinner />
      </BackendPage>
    );
  }
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <JobDetails
        detail={state.data}
        bomItemAttributes={supState.bomItemAttributes}
        onUpdate={onUpdate}
        onRefresh={onRefresh}
      />
      <DeleteByRetype
        isOpen={edit.isDeleteModalOpen}
        onClose={onCloseModal}
        onConfirm={() => onDelete(jobId)}
        answer={state.data?.jobNumber}
      />
    </BackendPage>
  );
};

export default Job;
