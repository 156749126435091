import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { useSelector } from 'react-redux';
import iJob from '../../../types/job/iJob';
import iBomItemAttribute from '../../../types/product/iBomItemAttribute';
import OrderAttributes from './orderAttrs/OrderAttributes';
import JobBom from './bomItems/JobBom';
import GeneralAttachments from '../../generalAttachments/GeneralAttachments';
import JobBomCopyFrom from './bomItems/JobBomCopyFrom';
import JobNotes from './jobNotes/JobNotes';
import ProductAttributesData from './productAttrs/ProductAttributesData';
import JobTabs from './tabs/JobTabs';
import EditableColumns from './editableColumns/EditableColumns';
import JobAttributes from './jobAttrs/JobAttributes';
import QtyProcessing from './qtyProcessing/QtyProcessing';
import ServiceProvider from './serviceProvider/ServiceProvider';
import ReAssociateSOItem from './reAssociateSOItem/ReAssociateSOItem';
import { JobDetailsWrapper } from './styles/JobDetails.style';
import { iDetailUpdateFn } from '../../UITypes/types';
import { getOrderAttributes } from '../../../pages/job/jobDetail/shared/JobDetail.utils';
import { handleNullException } from '../../../services/UtilsService';
import { AttachmentsWrapper } from '../../styles/styles';
import ModuleEnabledWrapper from '../../modulize/ModuleEnabledWrapper';
import { ModuleCode } from '../../../types/iModuleCode';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_INVOICES,
  ACCESS_CODE_JOBS,
  ACCESS_CODE_QUOTES,
  ACCESS_CODE_SALES_ORDERS,
  ACCESS_CODE_SERVICE_PROVIDERS,
} from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';
import RestrictedAccessDiv from '../../UserAccess/RestrictedAccessDiv';

const JobDetails = ({
  detail,
  bomItemAttributes,
  onUpdate,
  onRefresh,
}: {
  detail?: iJob;
  bomItemAttributes: Array<iBomItemAttribute>;
  onUpdate: iDetailUpdateFn;
  onRefresh: () => void;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_UPDATE, user);
  const canReadSOs = AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_UPDATE, user);
  const canReadQuotes = AccessService.hasAccess(ACCESS_CODE_QUOTES, ACCESS_CAN_UPDATE, user);
  const canReadInvoices = AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_UPDATE, user);

  if (typeof detail === 'undefined') return null;

  return (
    <JobDetailsWrapper>
      <Page>
        <Grid layout={'fluid'} spacing={'compact'}>
          <GridColumn medium={8}>
            <div className={'left-panel'}>
              <OrderAttributes attributes={getOrderAttributes(detail, user)} />
              <JobNotes detail={detail} onUpdate={onUpdate} isDisabled={!canUpdate} />
              <ProductAttributesData
                productId={detail.productId}
                productAttributeSetId={detail.product.attributeSetId}
              />
              <AttachmentsWrapper>
                <GeneralAttachments
                  entityId={detail.id}
                  entityName={'Job'}
                  canDelete={canUpdate}
                  canCreate={canUpdate}
                />
              </AttachmentsWrapper>
              <JobBom
                job={detail}
                onUpdate={onUpdate}
                bomItemAttributes={bomItemAttributes}
                canCreate={canUpdate}
                canDelete={canUpdate}
                canUpdate={canUpdate}
              >
                <JobBomCopyFrom job={detail} onUpdate={onUpdate} isDisabled={!canUpdate} />
              </JobBom>
            </div>
          </GridColumn>
          <GridColumn medium={4}>
            <QtyProcessing
              qtyTotal={handleNullException(detail, 'qtyTotal')}
              qtyToMake={handleNullException(detail, 'qtyToMake')}
              unit={handleNullException(detail, 'product.measurement.shortName')}
              onUpdate={onUpdate}
              isDisabled={!canUpdate}
            />
            <EditableColumns detail={detail} onUpdate={onUpdate} isDisabled={!canUpdate} />
            <JobAttributes detail={detail} onRefresh={onRefresh} isDisabled={!canUpdate} />
            {canReadSOs || canReadQuotes || canReadInvoices ? (
              <ReAssociateSOItem detail={detail} onUpdate={onUpdate} />
            ) : null}

            <RestrictedAccessDiv expectedUserAccessCodes={[ACCESS_CODE_SERVICE_PROVIDERS]} hideWhenDenied>
              <ModuleEnabledWrapper checkedModule={ModuleCode.SERVICE_PROVIDER}>
                <ServiceProvider detail={detail} />
              </ModuleEnabledWrapper>
            </RestrictedAccessDiv>
          </GridColumn>
        </Grid>
      </Page>
      <JobTabs detail={detail} />
    </JobDetailsWrapper>
  );
};

export default JobDetails;
