import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { iConfigColumn } from '../../../shared/UITypes/types';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';
import { DASHBOARD_PURCHASE } from '../shared/Dashboard.constants';
import { getHeads } from '../../../services/UtilsService';
import { getRows } from './TableHelper';

const PurchaseTable = ({
  columns,
  //   statusCategoryList,
  purchaseOrderMonth,
  date,
}: {
  columns: Array<iConfigColumn>;
  //   statusCategoryList: Array<iEntityCategory>;
  purchaseOrderMonth: Array<iPurchaseOrderDetail>;
  date: string;
}) => {
  const getFixedColumn = () => ({
    key: 'sort-type',
    name: '',
    isSelected: true,
    dataSource: [],
    type: 'sort-type',
  });

  return (
    <div className={'table-wrapper'}>
      <DynamicTable
        head={getHeads([getFixedColumn(), ...columns], DASHBOARD_PURCHASE)}
        rows={getRows({
          purchaseOrderMonth,
          columns: [getFixedColumn(), ...columns],
          date,
        })}
        testId={'dashboard-purchase-table'}
      />
    </div>
  );
};

export default PurchaseTable;
