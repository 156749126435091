import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 1.5rem 0;
  & > div {
    padding: 0.5rem 0;
    color: rgb(9, 30, 66);
  }

  .create-avatar {
    // margin-right: 1rem;
    display: flex;
    align-items: center;
  }
  .comments-input-create {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    min-width: 200px;
  }
  .faultCategory-select-create {
    margin-top: -8px;
  }
  .item-avatar {
    margin-right: 1rem;
    margin-bottom: auto;
  }
  .item-firstName,
  .item-date {
    font-weight: 700;
    font-size: 14px;
    color: RGB(23, 43, 77);
  }
  .item-date {
    margin-right: 0.5rem;
  }
`;
