/* eslint-disable import/no-cycle */
import React from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Button from '@atlaskit/button';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import { DOWNLOAD_TEXT } from '../../shared/DataImport.constants';
import { formatDate } from '../../../../../services/UtilsService';
import { SpaceVerticalContainer } from '../../../../../shared/styles/styles';
import { iTemplateColumn } from '../../shared/DataImport.types';

const DownloadTemplate = ({
  detailPageName,
  columns,
  example,
}: {
  detailPageName: string;
  columns: Array<iTemplateColumn>;
  example: Array<string>;
}) => {
  return (
    <CSVLink
      data={[columns.map((column: iTemplateColumn) => column.name), example]}
      filename={`${detailPageName} Template-${formatDate(
        moment().toString(),
        'DD MMMM YYYY,hh:mm',
      )}`}
    >
      <SpaceVerticalContainer className={'quarter'} />
      <Button iconBefore={<DownloadIcon label={'download'} />}>
        {DOWNLOAD_TEXT}
      </Button>
    </CSVLink>
  );
};
export default DownloadTemplate;
