import React, { useCallback } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import iStockTake from '../../../../types/stockTake/iStockTake';
import iStockTakeItem from '../../../../types/stockTake/iStockTakeItem';
import { getStockTakeItemListByStockTakeId } from '../../../../services/StockTakeItemService';
import { handleNullException, getHeads } from '../../../../services/UtilsService';
import { iConfigColumn, iParams } from '../../../../shared/UITypes/types';
import { FlexContainer } from '../../../../shared/contactCompany/style';
import { FINISHED_STOCK_TAKE_ITEM_TABLE, FINISH_PREVIEW_TABLE_COLUMNS } from '../../shared/StockTake.constant';
import { ItemsTitle } from '../../shared/StockTake.style';
import { SpaceVerticalContainer } from '../../../../shared/styles/styles';
import AttachmentsAndActivity from '../AttachmentsAndActivity';

const getRows = (data: Array<iStockTakeItem>, columns: Array<iConfigColumn>) => {
  return data.map((item: iStockTakeItem) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'text-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[0])}>
                {handleNullException(item, key)}
              </ComposeSecondaryText>
            ),
          };
        case 'text-arrow-text':
          return {
            key: handleNullException(item, key),
            content: (
              <FlexContainer>
                <div>{handleNullException(item, dataSource[0])}</div>
                <ArrowRightIcon label={'arrow-right'} />
                <div>{handleNullException(item, key)}</div>
              </FlexContainer>
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};
const Finished = ({ stockTakeDetail, canUpdate = true }: { stockTakeDetail: iStockTake; canUpdate?: boolean }) => {
  const { id: stockTakeId } = stockTakeDetail;
  const getFnCallback = useCallback((config?: iParams) => getStockTakeItemListByStockTakeId(stockTakeId, config), [
    stockTakeId,
  ]);
  const { state } = useListCrudHook<iStockTakeItem>({
    getFn: getFnCallback,
    paginationApplied: true,
    sort: 'createdAt:DESC',
    filter: 'isUserInput:false',
  });

  return (
    <>
      <ItemsTitle>Items</ItemsTitle>
      <DynamicTable
        head={getHeads(FINISH_PREVIEW_TABLE_COLUMNS, FINISHED_STOCK_TAKE_ITEM_TABLE)}
        rows={getRows(state.data, FINISH_PREVIEW_TABLE_COLUMNS)}
        isLoading={state.isLoading}
      />
      <SpaceVerticalContainer />
      <AttachmentsAndActivity
        entityId={handleNullException(stockTakeDetail, 'id')}
        entityName={'StockTake'}
        canDelete={canUpdate}
        canCreate={canUpdate}
      />
    </>
  );
};

export default Finished;
