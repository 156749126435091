import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import { DatePicker } from '@atlaskit/datetime-picker';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';

const DateMoverWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  color: rgb(23, 43, 77);
  height: 32px;
  width: 200px;
  margin-right: 1rem;
  .show-date {
    font-size: 16px;
    margin: 0px 1.5rem;
    line-height: 32px;
  }
  .arrow-icon {
    background: white;
  }
  .start-date {
    flex-grow: 1;
  }
`;
const DateMover = ({
  date = moment().toString(),
  gap = '7',
  onChangeDate,
}: {
  date?: string;
  gap?: string;
  onChangeDate: (newDate: string) => void;
}) => {
  return (
    <DateMoverWrapper>
      <Button
        iconBefore={
          <ChevronLeftLargeIcon label={'left-arrow'} size={'large'} />
        }
        onClick={() =>
          onChangeDate(moment(date).subtract(gap, 'day').toString())
        }
        spacing={'none'}
        className={'arrow-icon'}
        testId={'left-arrow'}
      />
      <div className={'start-date'}>
        <DatePicker
          id="datepicker-job-scheduler-mover"
          onChange={onChangeDate}
          dateFormat={'DD MMM YYYY'}
          value={date}
          testId={'date-picker-job-schedule-mover'}
        />
      </div>

      <Button
        iconBefore={
          <ChevronRightLargeIcon label={'right-arrow'} size={'large'} />
        }
        onClick={() => onChangeDate(moment(date).add(gap, 'day').toString())}
        spacing={'none'}
        className={'arrow-icon'}
        testId={'right-arrow'}
      />
    </DateMoverWrapper>
  );
};

export default DateMover;
