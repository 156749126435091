import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { iWFDiagramState } from '../../../shared/workflow/diagram/WorkflowDiagramReducer';
import CustomizeModal from '../../../shared/modal/CustomizeModal';

const Wrapper = styled.div`
  .text-danger {
    color: red !important;
  }
`;
type iWorkflowValidator = {
  state: iWFDiagramState;
};
const WorkflowValidator = ({ state }: iWorkflowValidator) => {
  const [showingErrors, setShowingErrors] = useState(false);

  if (state.errors.length <= 0) {
    return null;
  }

  const getErrorsPanel = () => {
    if (showingErrors !== true) {
      return null;
    }
    return (
      <CustomizeModal
        onCancel={() => setShowingErrors(false)}
        isOpen={showingErrors === true}
        modalHeading={'Errors in your workflow'}
        cancelNotAllowed
        modalBody={
          <div>
            <ul>
              {state.errors.map((errorMsg, index) => {
                // eslint-disable-next-line react/no-array-index-key
                return <li key={`error-${index}`}>{errorMsg}</li>;
              })}
            </ul>
          </div>
        }
        confirmBtnName={'OK'}
        onConfirm={() => setShowingErrors(false)}
      />
    );
  };

  return (
    <Wrapper>
      <Button
        appearance={'subtle-link'}
        className={'error-btn'}
        onClick={() => setShowingErrors(true)}
        iconBefore={
          <span className={'text-danger'}>
            <ErrorIcon label={'error'} />
          </span>
        }
      >
        Errors in workflow
      </Button>
      {getErrorsPanel()}
    </Wrapper>
  );
};

export default WorkflowValidator;
