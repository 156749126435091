import React, { useState } from 'react';
import styled from 'styled-components';
import iChannel from '../../../../types/integration/iChannel';
import { FlexContainer } from '../../../styles/styles';
import { SALES_URL } from '../../../UrlMap';
import InlineEdit from '../../../form/InlineEdit';
import ChannelService from '../../../../services/integration/ChannelService';
import { addToastForAPIResponse, apiErrorToast } from '../../../toast/Toast';

type iWooCommerceOrderNoPrefixEditor = {
  channel: iChannel;
  onSaved: (updatedChannel: iChannel) => void;
};

const Wrapper = styled.div``;
const WooCommerceOrderNoPrefixEditor = ({ channel, onSaved }: iWooCommerceOrderNoPrefixEditor) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = (newValue: string) => {
    setIsLoading(true);
    ChannelService.updateChannel(channel.id, {
      ...channel,
      settings: {
        ...channel.settings,
        orderPrefix: newValue,
      },
    })
      .then(resp => {
        addToastForAPIResponse('success', 'Order No. Prefix updated.');
        if (onSaved) {
          onSaved(resp);
        }
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Wrapper>
      <div className={'title-wrapper'}>
        <p>
          <small>
            All order pulled from [{channel.name}] will be having the order number like{' '}
            {channel.settings.orderPrefix || ''}1234. It will make it{' '}
            {`${channel.settings.orderPrefix || ''}`.trim() === '' ? 'HARD' : 'EASIER'} to search on{' '}
            <a href={SALES_URL}>Order Listing</a> Page
          </small>
        </p>
      </div>
      <FlexContainer className={'space-below'}>
        <InlineEdit
          isDisabled={isLoading}
          name={'orderPrefix'}
          defaultValue={channel.settings.orderPrefix || ''}
          label={''}
          onConfirm={(name, value) => handleSave(value)}
          testId={'orderPrefix'}
        />
      </FlexContainer>
    </Wrapper>
  );
};

export default WooCommerceOrderNoPrefixEditor;
