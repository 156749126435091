import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getBOMs = (config?: { [key: string]: string }) => {
  return AppService.get('/bomItem', {
    include: 'material,material.measurement,bomItemAttributes',
    ...config,
  }).then(({ data }) => data);
};

export const getBOM = (id: string) => {
  return AppService.get(`/bomItem/${id}`, {
    include: 'material,material.measurement,bomItemAttributes',
  }).then(({ data }) => data);
};

export const createBOM = (postData: iKeyValuePairs) => {
  return AppService.post('/bomItem', postData).then(({ data }) => data);
};

export const updateBOM = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/bomItem/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const deleteBOM = (id: string) => {
  return AppService.delete(`/bomItem/${id}`).then(({ data }) => data);
};

export const copyBomItems = (postData: { [key: string]: string }) => {
  return AppService.post('/bomItem/copyFromProduct', postData).then(
    ({ data }) => data,
  );
};

export const getStockForecast = (
  jobStatusIds: string,
  scheduledAt: string,
  productTypeIds = '',
) => {
  return AppService.get('/bomItem/requiredStocks', {
    jobStatusIds,
    scheduledAt,
    productTypeIds,
  }).then(({ data }) => data);
};

export const getStockForecastConfig = (config?: iParams) => {
  return AppService.get('/bomItem/requiredStocks', config).then(
    ({ data }) => data,
  );
};

export default {
  getBOMs,
  getBOM,
  updateBOM,
  createBOM,
  deleteBOM,
  copyBomItems,
  getStockForecast,
  getStockForecastConfig,
};
