import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { ALLOW_PARTS } from '../../../../pages/warehouse/shared/Warehouse.constants';
import CheckboxWithController from '../../../form/hookForms/CheckboxWithController';
import InputWithController from '../../../form/hookForms/InputWithController';

const WarehouseBody = ({
  control,
  onChange,
  errors,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
}) => {
  return (
    <>
      <InputWithController
        label={'Name'}
        name={'name'}
        onChange={onChange}
        control={control}
        errors={errors}
        testId={'input-name'}
      />
      <InputWithController
        label={'Location Code'}
        name={'locationCode'}
        onChange={onChange}
        control={control}
        testId={'input-location-code'}
      />
      <CheckboxWithController
        defaultValue={false}
        label={ALLOW_PARTS}
        name={'allowParts'}
        onChange={onChange}
        control={control}
      />
    </>
  );
};

export default WarehouseBody;
