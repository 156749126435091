import React from 'react';

const CardBtn = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => (
  <button
    type="button"
    className="cardBtn"
    onClick={() => {
      onClick();
    }}
  >
    {children}
  </button>
);

export default CardBtn;
