import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { iConfigColumn } from '../../../shared/UITypes/types';
import iStockForecast from '../../../types/product/iStockForecast';
import { DASHBOARD_INSSTOCKS } from '../shared/Dashboard.constants';
import { getHeads, getRows } from './TableHelper';
import iProduct from '../../../types/product/iProduct';
import { StocksTableWrapper } from '../shared/Dashboard.style';

const InsStocksTable = ({
  isLoading,
  stocks,
  date,
  columns,
  renderSpecialHeads,
  renderSpecialRows,
}: {
  isLoading: boolean;
  stocks: Array<iStockForecast>;
  date: string;
  columns: Array<iConfigColumn>;
  renderSpecialHeads: () => JSX.Element;
  renderSpecialRows: (item: iProduct, column: iConfigColumn) => JSX.Element;
}) => {
  return (
    <StocksTableWrapper>
      <DynamicTable
        head={getHeads({
          columns,
          tableName: DASHBOARD_INSSTOCKS,
          renderSpecialHeads,
        })}
        rows={getRows({
          stocks,
          date,
          columns,
          renderSpecialRows,
        })}
        isLoading={isLoading}
        emptyView={<div>No Match</div>}
        testId={DASHBOARD_INSSTOCKS}
        defaultSortKey={'product.productCode'}
        defaultSortOrder={'ASC'}
      />
    </StocksTableWrapper>
  );
};

export default InsStocksTable;
