import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ButtonGroup } from '@atlaskit/button';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import BreadcrumbsTwoLayers from '../../shared/heading/BreadcrumbsTwoLayers';
import PopupContent from '../../shared/popup/GeneralPopupContent';
import MoreIconPopup from '../../shared/popup/MoreIconPopup';
import DeleteByRetype from '../../shared/deleteWithReType/DeleteByReType';
import StatusMenu from '../../shared/statusComponent/StatusMenu';
import SpinWhenLoading from '../../shared/spin/SpinWhenLoading';
import AODetailEdit from './detail/AODetailEdit';
import AOItems from './detail/AOItems/AOItems';
import AOActivities from './detail/AOActivities';
import useDetailHook from '../../shared/hooks/useDetailHook/useDetailHook';
import iEntityCategory from '../../types/status/iEntityCategory';
import iAcquisitionOrder from '../../types/acquisitionOrder/iAcquisitionOrder';
import { apiErrorToast } from '../../shared/toast/Toast';
import { iRouteTypeId } from '../../shared/contactCompany/detail/types';
import { AOS_URL } from '../../shared/UrlMap';
import { getEntityCategoryList } from '../../services/CategoryService';
import { deleteAO, getAODetail, updateAO } from '../../services/AcquisitionOrderService';
import {
  AttachmentsWrapper,
  FlexContainer,
  PageTitle,
  SpaceVerticalContainer,
  ZIndexWrapper,
} from '../../shared/styles/styles';
import { AO_DETAIL_PAGE_NAME, AO_PAGE_NAME, AO_SHORTHAND } from './shared/AO.constants';
import iAcquisitionOrderItem from '../../types/acquisitionOrder/iAcquisitionOrderItem';
import GeneralAttachments from '../../shared/generalAttachments/GeneralAttachments';
import GeneralEmailBtn from '../../shared/buttons/GeneralEmailBtn';
import { printAcquisitionOrder } from '../../services/PrintService';
import { getContactCompany } from '../../services/ContactCompanyService';
import { handleNullException } from '../../services/UtilsService';
import GeneralPrintBtn from '../../shared/buttons/GeneralPrintBtn';
import SelfManagePopup from '../../shared/popup/SelfManagePopup';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_ACQUISITION_ORDERS } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE, ACCESS_CAN_UPDATE } from '../../types/settings/UserAccess/iRoleAccess';

const emailSubjectTpl = 'Please find [REF_NUM] from [COMPANY_NAME]';
const emailBodyTpl = `Hi there,

Please click the link below to download the acquisition order.
[PDF_URL]

Feel free to contact us if you require anything further.

Kind Regards
[SENT_FROM]
[COMPANY_NAME]`;

type iSupportState = {
  // taxBases: Array<iTaxBase>;
  isConfirming: boolean;
  categories: Array<iEntityCategory>;
  AOItems: Array<iAcquisitionOrderItem>;
  // CCPs: iContactCompanyProduct[];
};
const initialSupportState: iSupportState = {
  // taxBases: [],
  isConfirming: false,
  categories: [],
  AOItems: [],
  // CCPs: [],
};
const AcquisitionOrderDetail = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canUpdate = AccessService.hasAccess(ACCESS_CODE_ACQUISITION_ORDERS, ACCESS_CAN_UPDATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_ACQUISITION_ORDERS, ACCESS_CAN_DELETE, user);
  const { id } = useParams<iRouteTypeId>();
  const history = useHistory();
  const [isEtaWarningModalOpen, setIsEtaWarningModalOpen] = useState(false);
  const {
    state,
    edit,
    onOpenDeleteModal,
    onDelete,
    onUpdate,
    // onUpdateEagerLoadObject,
    onCloseModal,
    // onRefresh,
  } = useDetailHook<iAcquisitionOrder>({
    id,
    getFn: getAODetail,
    updateFn: updateAO,
    deleteFn: deleteAO,
    deleteCallback: () => history.push(AOS_URL),
  });

  const [supportState, setSupportState] = useState(initialSupportState);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      try {
        const categories: Array<iEntityCategory> = await getEntityCategoryList({
          filter: 'entityStatuses.entityStatusType.entityName:AcquisitionOrder',
        });
        // const ccp = await getContactCompanyProducts({
        //   filter: `contactCompanyId:${state.data?.supplierId}`,
        // });
        if (isCancelled) return;
        setSupportState(prevState => ({ ...prevState, categories }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);

  const getPageHeader = () => {
    return (
      <FlexContainer className={'space-between'}>
        <div>
          <BreadcrumbsTwoLayers
            name={AO_PAGE_NAME}
            url={AOS_URL}
            subName={`${AO_SHORTHAND}: ${state.data?.orderNumber}`}
          />
          <FlexContainer>
            <PageTitle className={'small space-right'}>
              {`${AO_DETAIL_PAGE_NAME}: ${state.data?.orderNumber}`}
            </PageTitle>
            <ZIndexWrapper>
              <StatusMenu
                defaultValue={state.data?.statusId || undefined}
                categories={supportState.categories}
                onSelect={(newValue: string) => onUpdate({ statusId: newValue })}
                isDisabled={!canUpdate}
              />
            </ZIndexWrapper>
          </FlexContainer>
        </div>
        <ButtonGroup>
          {state.data?.orderNumber && (
            <GeneralEmailBtn
              orderNum={state.data?.orderNumber}
              serviceFunc={() => printAcquisitionOrder(id)}
              recipientServiceFunc={() => getContactCompany(handleNullException(state.data, 'supplierId'))}
              emailSubjectTpl={emailSubjectTpl}
              emailBodyTpl={emailBodyTpl}
              onWarning={() => setIsEtaWarningModalOpen(true)}
              shouldWarningModalOpen={!state.data?.eta}
            >
              Email
            </GeneralEmailBtn>
          )}
          <GeneralPrintBtn
            serviceFunc={() => printAcquisitionOrder(id)}
            onWarning={() => setIsEtaWarningModalOpen(true)}
            shouldWarningModalOpen={!state.data?.eta}
          >
            Print
          </GeneralPrintBtn>
          {canDelete ? (
            <MoreIconPopup>
              <PopupContent id={id} onOpenDeleteModal={onOpenDeleteModal} />
            </MoreIconPopup>
          ) : null}
        </ButtonGroup>
      </FlexContainer>
    );
  };
  const updateAOItems = useCallback(
    (items: Array<iAcquisitionOrderItem>) => setSupportState(prevState => ({ ...prevState, AOItems: items })),
    [],
  );

  if (state.isLoading) return <SpinWhenLoading />;

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <AODetailEdit detail={state.data} onUpdateDetail={onUpdate} isDisabled={!canUpdate} />
      <SpaceVerticalContainer className={'two'} />
      <AOItems
        AOId={id}
        LocalAODetail={state.data}
        updateAOItems={updateAOItems}
        canUpdate={canUpdate}
        canDelete={canUpdate}
        canCreate={canUpdate}
      />
      <SpaceVerticalContainer className={'one'} />
      <AttachmentsWrapper>
        <GeneralAttachments entityId={id} entityName={'AcquisitionOrder'} canCreate={canUpdate} canDelete={canUpdate} />
      </AttachmentsWrapper>
      <AOActivities AOId={id} AOItemIds={supportState.AOItems.map((item: iAcquisitionOrderItem) => item.id)} />
      <DeleteByRetype
        isOpen={edit.isDeleteModalOpen}
        onClose={onCloseModal}
        onConfirm={() => onDelete(id)}
        answer={state.data?.orderNumber}
        isConfirming={state.isConfirming}
      />

      {isEtaWarningModalOpen && (
        <SelfManagePopup
          isPopupOpen={isEtaWarningModalOpen}
          isCancelBtn={false}
          onClick={() => setIsEtaWarningModalOpen(false)}
          popupHeading={'Please provide an ETA date before sending the AO.'}
          popupContent={<span />}
          confirmBtnName={'OK'}
          confirmBtnAppearance={'primary'}
        />
      )}
    </BackendPage>
  );
};

export default AcquisitionOrderDetail;
