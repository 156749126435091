/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { SuccessProgressBar } from '@atlaskit/progress-bar';
import PreviewDataSummary from './preview/PreviewDataSummary';
import ActionButtons from './preview/ActionButtons';
import ErrorHip from './preview/ErrorHip';
import CustomizeModal from '../../../../../shared/modal/CustomizeModal';
import PreviewDataTable from './preview/PreviewDataTable';
import SubmitSummary from './submit/SubmitSummary';
import FinalSummary from './submit/FinalSummary';
import { FIXED_COLUMNS } from '../../shared/Template.constants';
import { formatDate } from '../../../../../services/UtilsService';
import {
  iImportDataResult,
  iPrefills,
  iTemplateColumn,
} from '../../shared/DataImport.types';
import {
  FlexContainer,
  SpaceHorizontalContainer,
  SpaceVerticalContainer,
} from '../../../../../shared/styles/styles';
import {
  calculateStatus,
  getParametersByPageName,
  prepareDownloadResult,
  preparePreviewResult,
  prepareSubmittedResult,
} from '../../shared/DataImport.util';
import {
  FINISH_MODAL_HEADING_TEXT,
  IMPORT_MODAL_HEADING_TEXT,
  MODAL_HEADING_TEXT,
  SUBMIT_BUTTON_NAME,
} from '../../shared/DataImport.constants';

type iState = {
  isOpen: boolean;
  isSubmitted: boolean;
  results: Array<iImportDataResult>;
};
const initialState: iState = {
  isOpen: false,
  isSubmitted: false,
  results: [],
};
const HandleData = ({
  detailPageName,
  data,
  prefills,
  columns,
  children,
}: {
  detailPageName: string;
  data: any[][];
  prefills: iPrefills;
  columns: Array<iTemplateColumn>;
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(initialState);
  const { submitFn, url, previewFn } = getParametersByPageName(detailPageName);
  const previewResults = previewFn(data, columns, prefills);
  const { skippedLength, successLength, failedLength } = calculateStatus(
    state.results,
  );
  const previewSuccessLength = previewResults.filter(
    (item: iImportDataResult) => item.previewSuccess,
  ).length;
  const previewSkippedLength = previewResults.filter(
    (item: iImportDataResult) => item.skipped,
  ).length;

  const headers = [...FIXED_COLUMNS, ...columns].map(
    (item: iTemplateColumn) => item.name,
  );
  const recordResult = (result: iImportDataResult) =>
    setState(prevState => ({
      ...prevState,
      results: [...prevState.results, result],
    }));

  const onSubmit = async () => {
    setState({ ...state, isOpen: true });
    await submitFn(
      detailPageName,
      data,
      recordResult,
      previewResults,
      columns,
      prefills,
    );
  };

  if (state.isSubmitted) {
    return (
      <>
        <SpaceVerticalContainer className={'half'} />
        <FinalSummary
          skipped={skippedLength}
          success={successLength}
          failed={failedLength}
        >
          <FlexContainer>
            <CSVLink
              data={[headers, ...prepareDownloadResult(state.results, data)]}
              filename={`${detailPageName} Result-${formatDate(
                moment().toString(),
                'DD MMMM YYYY,hh:mm',
              )}`}
            >
              download results
            </CSVLink>
            <SpaceHorizontalContainer className={'quarter'} />
            {children}
          </FlexContainer>
        </FinalSummary>
        <SpaceVerticalContainer className={'half'} />
        <PreviewDataTable
          columns={headers}
          data={prepareSubmittedResult(state.results, data, url)}
        />
      </>
    );
  }
  return (
    <>
      <SpaceVerticalContainer className={'half'} />
      <PreviewDataTable
        columns={headers}
        data={preparePreviewResult(previewResults, data)}
      />
      <ErrorHip isShown={previewSkippedLength > 0} />
      <SpaceVerticalContainer className={'half'} />
      <PreviewDataSummary
        invalidLength={previewSkippedLength}
        validLength={previewSuccessLength}
      />
      <SpaceVerticalContainer className={'half'} />
      <ActionButtons
        isDisabled={previewSkippedLength === data.length}
        onSubmit={onSubmit}
        btnName={SUBMIT_BUTTON_NAME.replace(
          '[REF_NUM]',
          `${previewSuccessLength}`,
        )}
        modalHeading={MODAL_HEADING_TEXT.replace(
          '[REF_NUM]',
          `${previewSuccessLength}`,
        )}
      />
      <CustomizeModal
        isOpen={state.isOpen}
        onConfirm={() =>
          setState({ ...state, isOpen: false, isSubmitted: true })
        }
        onCancel={() => setState({ ...state, isOpen: false })}
        modalBody={
          failedLength + successLength === previewSuccessLength ? (
            <SubmitSummary
              failedLength={failedLength}
              successLength={successLength}
            />
          ) : (
            <SuccessProgressBar
              value={(failedLength + successLength) / previewSuccessLength}
            />
          )
        }
        isConfirming={failedLength + successLength !== previewSuccessLength}
        modalHeading={(failedLength + successLength === previewSuccessLength
          ? FINISH_MODAL_HEADING_TEXT
          : IMPORT_MODAL_HEADING_TEXT
        ).replace('[REF_NUM]', `${previewSuccessLength}`)}
        cancelNotAllowed
      />
    </>
  );
};

export default HandleData;
