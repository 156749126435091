import moment from 'moment';
import _ from 'lodash';
import iAddress from '../types/contactCompany/iAddress';
import iJobAttribute from '../types/job/iJobAttribute';
import iProductAttribute from '../types/product/iProductAttribute';
import iProductAttributeValue from '../types/product/iProductAttributeValue';
import iEntityCategory from '../types/status/iEntityCategory';
import iEntityStatus from '../types/status/iEntityStatus';
import { iKeyValuePairs, iLabelValuePair } from '../shared/UITypes/types';
import { iConfigColumn } from '../pages/despatchNote/shared/DispatchNote.type';
import {
  STATUS_CATEGORY_FINISHED,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_NEW,
} from '../shared/constants/StatusCategories';
import iProduct from '../types/product/iProduct';
import iPurchaseOrderItem from '../types/purchases/iPurchaseOrderItem';
import iSalesOrderItem from '../types/sales/iSalesOrderItem';
import iContactCompanyProduct from '../types/contactCompany/iContactCompanyProduct';
import { CUSTOMER, SERVICE_PROVIDER, SUPPLIER } from '../pages/contactCompany/list/ContactCompany.constants';
import { CUSTOMER_URL, SERVICE_PROVIDER_URL, SUPPLIER_URL } from '../shared/UrlMap';
import iJobCategory from '../types/job/iJobCategory';
import iJobStatus from '../types/job/iJobStatus';
import { NUMBER_ROUND_DECIMAL } from '../shared/constants/ActionConstants';
import iContactCompany from '../types/contactCompany/iContactCompany';
import iContactCompanyAddress from '../types/contactCompany/iContactCompanyAddress';
import MathHelper from './MathHelper';

export const ucFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};
//  2345 to 2,345
export const numberFormat = (str: string | number) => {
  const convert = Number(str);
  return Number.isNaN(convert) ? str.toString() : new Intl.NumberFormat().format(convert);
};

//  default round to integer
export const numberRound = (num: string | number | null, decimal?: number) => {
  if (num === null) return null;
  const convert = Number(num);
  //  eslint-disable-next-line no-nested-ternary
  const result = Number.isNaN(convert) ? num : decimal !== undefined ? _.round(convert, decimal) : _.round(convert, 0);
  return result.toLocaleString();
};

export const numberToPercentage = (num: string | number, float?: number) => {
  const convert = Number(num);
  const roundDecimal = float || 2;
  return Number.isNaN(convert) ? num : `${parseFloat((convert * 100).toString()).toFixed(roundDecimal)}%`;
};

export const numberToPercentageWithoutSuffix = (num: string | number | undefined, float?: number) => {
  const convert = Number(num);
  const roundDecimal = float || 2;
  return Number.isNaN(convert) ? num : `${parseFloat((convert * 100).toString()).toFixed(roundDecimal)}`;
};

export const percentageToNumber = (percent: string, float?: number) => {
  const convert = Number(percent);
  const roundDecimal = float || 2;
  return Number.isNaN(convert) ? percent : `${parseFloat((convert / 100).toString()).toFixed(roundDecimal)}`;
};

export const compareStr = (strA: string | null, strB: string | null) => {
  if (strA === null) {
    return 1;
  }
  if (strB === null) {
    return -1;
  }
  if (strA < strB) {
    return -1;
  }
  if (strA > strB) {
    return 1;
  }
  return 0;
};

export const compareDate = (date1?: string, date2?: string) => {
  if (!date1 && !date2) {
    return 0;
  }
  // -1 blank first, 1 blank last
  if (!date1 && date2) return -1;
  if (date1 && !date2) return 1;
  const compare = moment(date1).diff(moment(date2));
  if (compare > 0) return 1;
  if (compare < 0) return -1;
  return 0;
};

export const formatDate = (date?: string | null, format?: string) => {
  if (!date) return '';
  const defaultFormat = format || 'DD MMM YYYY, h a';
  //  moment.locale('en-au');
  return moment(date).format(defaultFormat);
};
export const calculateDurationToNow = (date?: string) => {
  if (!date) return '';
  return moment.duration(moment(date).diff(moment.now())).asHours();
};

export const addsOnValue = (value: string | number, prefix?: string, postfix?: string) => {
  let viewValue = value;
  if (prefix) {
    viewValue = `${prefix} ${viewValue}`;
  }
  if (postfix) {
    viewValue = `${viewValue} ${postfix}`;
  }
  return viewValue;
};

export const currencyFormatter = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AUD',
  });
  return formatter.format(value);
};

export const mapToLabelValuePair = <T extends { id: string; name: string }>(list: Array<T>) => {
  if (list?.length === 0) return [];
  //    eslint-disable-next-line
  return list?.map((l: T) => ({ label: l.name, value: l.id }));
};

export const getDefaultvalueForSelect = <T extends { id: string; name: string }>(
  list: Array<T>,
  defaultValue?: string | null,
) => {
  if (!defaultValue || list.length === 0) return null;
  const option = list.find((item: T) => item.id === defaultValue);
  if (typeof option === 'undefined') return null;
  return { label: option.name, value: option.id };
};

//  eslint-disable-next-line
export const hasKey = <O>(obj: O, key: keyof any): key is keyof O => {
  return key in obj;
};

export const assembleAddress = (address?: iAddress): string => {
  if (!address) return '';

  const street = !address.street ? '' : address.street;
  const suburb = !address.suburb ? '' : address.suburb;
  const state = !address.state ? '' : address.state;
  const postcode = !address.postcode ? '' : address.postcode;
  const country = !address.country ? '' : address.country;

  return street === '' && suburb === '' && state === '' && postcode === '' && country === ''
    ? ''
    : `${street}, ${suburb} ${state} ${postcode} ${country}`;
};

export const mapJobAttributeForColumns = (attrs: Array<iJobAttribute>) => attrs.map((attr: iJobAttribute) => attr.name);

export const mapProdAttributeForColumns = (prodAttrs: Array<iProductAttribute>) => {
  const attrs = prodAttrs.map((attr: iProductAttribute) => attr.name);
  return _.uniq(attrs).sort();
};

export const getJobAttrsValues = (attrs: Array<iJobAttribute>) => {
  return attrs.reduce((acc: iKeyValuePairs, cur: iJobAttribute) => {
    return {
      ...acc,
      [cur.name]: numberRound(cur.jobAttributeValues.value, 0) || '',
    };
  }, {});
};

export const getProdAttrs = (attrs: Array<iProductAttributeValue>, prodAttrsCluster: Array<iProductAttribute>) => {
  return attrs.reduce((acc: iKeyValuePairs, cur: iProductAttributeValue) => {
    const pAttrName = prodAttrsCluster.find((p: iProductAttribute) => p.id === cur.attributeId)?.name;
    return pAttrName ? { ...acc, [pAttrName]: cur.value || '' } : acc;
  }, {});
};

export const handleNullException = (
  //  eslint-disable-next-line
  object: any,
  columnName: string,
  isNumber?: boolean,
) => {
  const getValue = _.get(object, columnName);
  if (_.isNull(getValue) || _.isUndefined(getValue)) {
    return isNumber ? '0' : '';
  }
  return getValue;
};

// is str numeric
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStrNumeric = (str: any) => {
  if (typeof str !== 'string') return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
};

export const currencyFormat = (num: number) => {
  return `$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const handleMoney = (
  //  eslint-disable-next-line
  object: any,
  columnName: string,
  decimal?: number,
  dollarSign?: boolean,
) => {
  //  by default, with dollar sign
  const sign = typeof dollarSign === 'undefined' ? true : dollarSign;
  const getValue = _.get(object, columnName);
  if (_.isNull(getValue) || _.isUndefined(getValue) || Number.isNaN(Number(getValue))) return '';

  const afterRound = decimal ? _.round(Number(getValue), decimal) : _.round(Number(getValue), 0);

  return sign ? `$${afterRound.toLocaleString()}` : afterRound.toLocaleString();
};

export const handleNumber = (
  //  eslint-disable-next-line
  object: any,
  columnName: string,
) => {
  const getValue = _.get(object, columnName);
  if (_.isNull(getValue) || _.isUndefined(getValue)) {
    return '';
  }
  const convertNumber = Number(getValue);
  if (Number.isNaN(convertNumber)) {
    return '';
  }
  return convertNumber.toLocaleString();
};
/**
 *
 * @returns there isnot code on job category, use name instead
 */
export const getJobCategoryCode = (
  //  eslint-disable-next-line
  status: any,
  categories: Array<iJobCategory>,
) => {
  if (!status) return '';
  const category = categories.find((c: iJobCategory) => c.id === (status as iJobStatus).jobStatusCategoryId);
  return category ? category.name : '';
};

export const getCategoryCode = (
  //  eslint-disable-next-line
  status: any,
  categories: Array<iEntityCategory>,
) => {
  if (!status) return '';
  const category = categories.find((c: iEntityCategory) => c.id === (status as iEntityStatus).entityStatusCategoryId);
  return category ? category.code : '';
};
export const getCategoryName = (
  //  eslint-disable-next-line
  status: any,
  categories: Array<iEntityCategory>,
) => {
  if (!status) return '';
  const category = categories.find((c: iEntityCategory) => c.id === (status as iEntityStatus).entityStatusCategoryId);
  return category ? category.name : '';
};

export const getHeads = (columns: Array<iConfigColumn>, tableName: string) => {
  const cells = columns.map((column: iConfigColumn) => {
    switch (column.type) {
      case 'operation':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      case 'delete':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      default:
        return {
          key: column.key,
          content: column.name,
          testId: `${tableName}-column-${column.key}`,
          isSortable: !!column.isSortable,
        };
    }
  });
  return { cells };
};

export const classifyEntityCategoryByStatusId = (categories: Array<iEntityCategory>, statusId?: string) => {
  const result = { isNew: false, isWIP: false, isFinished: false };
  if (typeof statusId === 'undefined') return result;
  const category = categories.find((c: iEntityCategory) =>
    c.entityStatuses.find((s: iEntityStatus) => s.id === statusId),
  );
  if (typeof category === 'undefined') return result;
  switch (category.code) {
    case STATUS_CATEGORY_NEW:
      result.isNew = true;
      break;
    case STATUS_CATEGORY_IN_PROGRESS:
      result.isWIP = true;
      break;
    case STATUS_CATEGORY_FINISHED:
      result.isFinished = true;
      break;
    default:
      break;
  }
  return result;
};

export const getStatusByCategories = (categories: Array<iEntityCategory>, statusId?: string) => {
  const category = categories.find((c: iEntityCategory) =>
    c.entityStatuses.some((e: iEntityStatus) => e.id === statusId),
  );
  if (category) {
    return category.entityStatuses.find((e: iEntityStatus) => e.id === statusId);
  }
  return undefined;
};

export const mapOptionToValue = (options: iLabelValuePair[]) => {
  return options.map(o => o.value);
};

/* intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > _([1,2,3]).intersperse(0)
 * [1,0,2,0,3]
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const intersperse = (arr: any[], sep: string) => {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    (xs, x) => {
      return xs.concat([sep, x]);
    },
    [arr[0]],
  );
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getDaysMore = (date: string, numberOfDays: number, dateFormat = 'YYYY-MM-DD') => {
  const newDate = moment(date, dateFormat).add(numberOfDays, 'day').format(dateFormat);
  return newDate;
};
/**
 * highest priority,lastest in columnName
 */
export const handleNullExceptionMultiFields = (
  //  eslint-disable-next-line
  object: any,
  columnName: string,
  defaultValue?: number | string | boolean,
) => {
  //  eslint-disable-next-line
  let result: any = typeof defaultValue === 'undefined' ? '' : defaultValue;
  const fields = columnName.split(',');
  fields.forEach((field: string) => {
    const getValue = _.get(object, field);
    result = _.isNull(getValue) || _.isUndefined(getValue) ? result : getValue;
  });
  return result;
};

export const prepareConversionEquation = ({
  value,
  conversion,
  contactCompanyUnit,
  productUnit,
}: {
  value: number;
  conversion: number;
  productUnit: string;
  contactCompanyUnit: string;
}) => {
  if (productUnit.trim().toUpperCase() === contactCompanyUnit.trim().toUpperCase() && conversion === 1) {
    return productUnit;
  }
  return `${contactCompanyUnit} = ${MathHelper.mul(value, conversion)} ${productUnit}`;
};

export const getUnitConversionSuffix = (
  inputValue: string | number,
  product?: iProduct,
  targetItem?: iPurchaseOrderItem | iSalesOrderItem | iContactCompanyProduct,
) => {
  let suffix = '';
  let productQty;
  const input = Number.isNaN(Number(inputValue)) ? 0 : Number(inputValue);
  const inputMeasure = targetItem?.measurement?.shortName || handleNullException(product, 'measurement.shortName');
  const productMeasure = handleNullException(product, 'measurement.shortName');
  // there is a target item
  if (targetItem?.id) {
    if (
      Number(targetItem.unitConversion) === 1 &&
      inputMeasure?.trim().toUpperCase() === productMeasure?.trim().toUpperCase()
    ) {
      suffix = `${productMeasure}`;
      productQty = inputValue ? Number(inputValue) : '';
    } else {
      suffix = `${inputMeasure} = ${
        // eslint-disable-next-line radix
        numberRound(Number(targetItem.unitConversion) * input, NUMBER_ROUND_DECIMAL)
      } ${productMeasure}`;

      productQty = inputValue
        ? numberRound(Number(targetItem.unitConversion) * Number(inputValue), NUMBER_ROUND_DECIMAL)
        : '';
    }
  }

  // there is not a target item
  if (!targetItem?.id) {
    suffix = `${productMeasure}`;
    productQty = inputValue ? Number(inputValue) : '';
  }

  const qtyString = productQty?.toString();

  return { suffix, qtyString };
};

export const validateIsDirty = (
  watchFn: (names?: string | string[]) => unknown,
  //  eslint-disable-next-line
  defaultValue: any,
  validateFields: Array<string>,
) => {
  const isTakeAction = validateFields.some((field: string) => watchFn(field));
  const isAnyChange = validateFields.some(
    (field: string) => defaultValue && !_.eq(handleNullException(defaultValue, field), watchFn(field)),
  );
  return defaultValue ? isTakeAction && isAnyChange : isTakeAction;
};

export const getUrlPrefixByContactCompanyType = (type: string) => {
  switch (type.toUpperCase()) {
    case CUSTOMER.toUpperCase():
      return CUSTOMER_URL;

    case SUPPLIER.toUpperCase():
      return SUPPLIER_URL;
    case SERVICE_PROVIDER.toUpperCase():
      return SERVICE_PROVIDER_URL;
    default:
      return '';
  }
};

/**
 * @Description: This helper fn fits in <AddressSelectWithController/> by providing it select options
 * @Params: 1.company address list (required) 2. contactCompany object (optional)
 * @Return: an array of formatted company address object
 */
export const getShippingAddressOptions = (
  localCompanyAddressList: iContactCompanyAddress[],
  localContactCompany?: iContactCompany,
) => {
  if (localCompanyAddressList.length === 0 || !localContactCompany) return [];
  if (localContactCompany.shippingAddressId === localContactCompany.billingAddressId) {
    return localCompanyAddressList.map((item: iContactCompanyAddress) => ({
      label: item.id,
      value: item,
    }));
  }

  // avoid to use billing address for shipping destination
  return localCompanyAddressList
    .filter((item: iContactCompanyAddress) => item.addressId !== localContactCompany.billingAddressId)
    .map((item: iContactCompanyAddress) => ({
      label: item.id,
      value: item,
    }));
};

export const getExportReportName = (prefix: string) => {
  return `${prefix}-report-${formatDate(moment().toISOString(), 'DD MMMM YYYY, hh:mm:ss')}.csv`;
};

export const isEnterpriseMode = () => {
  return `${process.env.REACT_APP_IS_ENTERPRISE || ''}`.trim().toLowerCase() === 'true';
};

export const CUSTOMER_REPORT_FILE_NAME = getExportReportName('customers');
export const SUPPLIER_REPORT_FILE_NAME = getExportReportName('suppliers');
export const SERVICE_PROVIDER_REPORT_FILE_NAME = getExportReportName('service-providers');

export const splitStringIntoWordsArray = (string: string) => {
  return (string.match(/\b(\w+)\b/g) || [string]).map(str => str.replace(/[\W_]+/g, ''));
};

export const removeLastSlashFromUrl = (url: string) => {
  return url.replace(/(\/)*$/, '');
};

export const getCurrentBaseUrl = () => {
  return `${window.location.protocol.replace(/(:)*$/, '')}://${window.location.host}`;
};
