import React, { useCallback } from 'react';
import ReactFlow, { addEdge, Controls, Edge, EdgeChange, MiniMap, Node, NodeChange } from 'react-flow-renderer';
import { WorkflowDiagramWrapper } from './WorkflowDiagramStyle';
import { workFlowEdge } from './WorkflowDiagramHelper';

export type iWorkflowDiagram = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  setEdges: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setNodes: Function;
  onNodesChange: (nodesChange: NodeChange[]) => void;
  onEdgesChange: (edgesChange: EdgeChange[]) => void;
  nodes: Node[];
  edges: Edge[];
};

const WorkflowDiagram = ({ setEdges, onEdgesChange, onNodesChange, nodes, edges }: iWorkflowDiagram) => {
  const onConnect = useCallback(connection => setEdges((eds: Edge[]) => addEdge(connection, eds)), [setEdges]);

  return (
    <WorkflowDiagramWrapper className={'workflow-diagram'}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        edgeTypes={workFlowEdge}
      >
        <MiniMap />
        <Controls />
      </ReactFlow>
    </WorkflowDiagramWrapper>
  );
};

export default WorkflowDiagram;
