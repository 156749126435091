import { useState, useEffect } from 'react';
import { iPagination } from '../../../types/iPagination';
import { apiErrorToast } from '../../toast/Toast';
import { iParams } from '../../UITypes/types';

type iPaginateResults<T> = iPagination & {
  data: Array<T>;
};
type iState = {
  length?: number;
  isLoading: boolean;
};
const initialState: iState = {
  isLoading: true,
};
const paginationConfig = { currentPage: '1', perPage: '1' };

type iProps = {
  //  eslint-disable-next-line
  getFn: (config?: iParams) => any;
  config?: iParams;
  notRenderWithoutConfig?: boolean;
};
const useListLengthHook = <T extends { id: string }>({
  getFn,
  config,
  notRenderWithoutConfig = true,
}: iProps) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      if (typeof config === 'undefined' && notRenderWithoutConfig) {
        setState(prevState => ({ ...prevState, isLoading: false }));
        return;
      }
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const configAddPagination =
          typeof config === 'undefined'
            ? paginationConfig
            : { ...config, ...paginationConfig };
        //  eslint-disable-next-line
        const fetchResult: iPaginateResults<T> = await getFn(
          configAddPagination,
        );
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          length: fetchResult.total,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFn, JSON.stringify(config), notRenderWithoutConfig]);

  return {
    state,
  };
};

export default useListLengthHook;
