import AppService from '../AppService';
import iProfileCompanyPreference, {
  COMPANY_PREFERENCE_CODE_FORM_FILM_CAL_SETTINGS,
} from '../../types/company/iProfileCompanyPreference';
import { iPaginatedData } from '../../types/iPaginatedData';

const endPoint = '/profileCompanyPreference';
const getCompanyPreferences = (
  config = {},
): Promise<iProfileCompanyPreference[] | iPaginatedData<iProfileCompanyPreference>> => {
  return AppService.get(endPoint, config).then(({ data }) => data);
};

const getCompanyPreference = (id: string, config = {}): Promise<iProfileCompanyPreference> => {
  return AppService.get(`${endPoint}/${id}`, config).then(({ data }) => data);
};

const updateCompanyPreference = (id: string, params = {}): Promise<iProfileCompanyPreference> => {
  return AppService.put(`${endPoint}/${id}`, params).then(({ data }) => data);
};

const isForFilmCompany = (): Promise<boolean> => {
  return getCompanyPreferences({
    perPage: 1,
    currentPage: 1,
    filter: `code:${COMPANY_PREFERENCE_CODE_FORM_FILM_CAL_SETTINGS}`,
    sort: 'updatedAt:ASC',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }).then(data => Number(data?.total || 0) > 0);
};

export default { getCompanyPreferences, getCompanyPreference, updateCompanyPreference, isForFilmCompany };
