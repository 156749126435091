import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid, GridColumn } from '@atlaskit/page';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import iProduct from '../../types/product/iProduct';
import PageTitleWithAddOne from '../../shared/heading/PageTitleWithAddOne';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import SearchBar from '../../shared/search/SearchBar';
import ProductListTable from '../../components/product/list/ProductListTable';
import CustomizePagination from '../../shared/pagination/CustomizePagination';
import ProductEditModal from '../../components/product/list/ProductEditModal';
import {
  createProduct,
  createProductFromTemplate,
  deactivateProduct,
  getProducts,
  updateProduct,
} from '../../services/product/ProductService';
import { AdvancedSearchSectionWrapper, FlexContainer } from '../../shared/styles/styles';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooks from '../../shared/hooks/useShowHideColumns';
import {
  COLUMN_NAME,
  COLUMNS,
  FLEXIBLE_COLUMNS,
  MUST_COLUMNS_AFTER,
  MUST_COLUMNS_BEFORE,
  PRODUCT_ADVANCED_SEARCH_CONFIGS,
  PRODUCT_REPORT_FILE_NAME,
} from '../../components/product/constants';
import ShowHideColumns from '../../shared/showHideColumns/ShowHideColumns';
import iProductType from '../../types/product/iProductType';
import { getProductTypes } from '../../services/product/ProductTypeService';
import { apiErrorToast } from '../../shared/toast/Toast';
import { getProdAttributeSets } from '../../services/product/ProductAttributeSetService';
import iProductAttributeSet from '../../types/product/iProductAttributeSet';
import { PRODUCTS_URL } from '../../shared/UrlMap';
import PaginationDetail from '../../shared/pagination/paginationDetail/PaginationDetail';
import Advanced from '../../shared/contactCompany/list/Advanced';
import useAdvancedSearchHooks from '../../shared/advancedSearch/AdvancedSearch';
import iProductAttribute from '../../types/product/iProductAttribute';
import { getProdAttributes } from '../../services/product/ProductAttributeService';
import { mapProdAttributeForColumns } from '../../services/UtilsService';
import ExportReport from '../../shared/exportReport/ExportReport';
import { getReportArrSegments } from '../../components/product/list/TableHelper';
import { iParams } from '../../shared/UITypes/types';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCTS } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE } from '../../types/settings/UserAccess/iRoleAccess';

type iIsForState = {
  name?: string;
  value?: boolean;
};
const isForInitialState: iIsForState = {};
const ProductList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const history = useHistory();

  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onSearch,
    onSetCurrentPage,
    onSetSort,
    onSubmit,
    onRefreshList,
    onAdvancedFilter,
  } = useListCrudHook<iProduct>({
    getFn: getProducts,
    createFn: createProduct,
    updateFn: updateProduct,
    deleteFn: deactivateProduct,
    keywordColumns: 'name,productCode',
    paginationApplied: true,
    sort: 'createdAt:DESC',
    createCallBack: (id: string) => history.push(`${PRODUCTS_URL}/${id}`),
  });
  const { control, setValue, errors, handleSubmit, watch } = useForm();
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [isFor, setIsFor] = useState(isForInitialState);
  const [productTypes, setProductTypes] = useState<Array<iProductType>>([]);
  const [productAttributeSets, setProductAttributeSets] = useState<iProductAttributeSet[]>([]);
  const [prodAttributes, setProdAttributes] = useState<Array<iProductAttribute>>([]);

  const [selectedColumns, onToggleColumn] = useColumnHooks(COLUMN_NAME, {
    default: COLUMNS,
    whole: [
      ...MUST_COLUMNS_BEFORE,
      ...FLEXIBLE_COLUMNS,
      ...mapProdAttributeForColumns(prodAttributes).filter((attr: string) => attr !== 'Type'),
      ...MUST_COLUMNS_AFTER,
    ],
  });

  const onEditIsForCheckbox = (name: string, value: boolean, id: string) => {
    setIsFor({ ...isFor, name, value });
    onOpenEditModal(id);
  };

  const { getAdvancedFilterStrArr, getSearchControllers } = useAdvancedSearchHooks(
    PRODUCT_ADVANCED_SEARCH_CONFIGS,
    true,
    productAttributeSets,
  );

  useEffect(() => {
    let isCancelled = false;
    const fetchTypes = async () => {
      try {
        const types = await getProductTypes();
        const attributeSets = await getProdAttributeSets();
        const prodAttrs = await getProdAttributes();
        if (isCancelled) return;
        setProductTypes(types);
        setProductAttributeSets(attributeSets);
        setProdAttributes(prodAttrs);
      } catch (error) {
        apiErrorToast(error);
      }
    };
    fetchTypes();
    return () => {
      isCancelled = true;
    };
  }, []);

  const getAdvancedFilterStr = () => {
    // job -> SalesOrder salesOrder -> ContactCompany customer.id
    const filterArr = getAdvancedFilterStrArr().filter(strFilter => strFilter !== '');
    return filterArr.join(',');
  };

  useEffect(() => {
    const filter = getAdvancedFilterStrArr()
      .filter(strFilter => strFilter !== '')
      .join(',');
    onAdvancedFilter(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(getAdvancedFilterStrArr())]);

  const columnsListForShowHide = [
    { label: 'Product', value: FLEXIBLE_COLUMNS },
    {
      label: 'Product Attributes',
      value: mapProdAttributeForColumns(prodAttributes).filter((attr: string) => attr !== 'Type'),
    },
  ];

  const getPageHeader = () => (
    <>
      <FlexContainer className={'space-between'}>
        <PageTitleWithAddOne
          title={'Products'}
          onClickAddBtn={onOpenAddModal}
          isDisabled={!AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_CREATE, user)}
        />
        <FlexContainer>
          <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search by name/code...'} />
          <Advanced onClick={() => setIsAdvancedSearch(prev => !prev)} />
        </FlexContainer>
      </FlexContainer>
      {isAdvancedSearch && (
        <AdvancedSearchSectionWrapper>
          <Grid layout={'fluid'} spacing={'cosy'}>
            {getSearchControllers()}

            <GridColumn medium={0.5}>
              <ExportReport
                className={'margin-above margin-below'}
                total={state.total}
                perPage={state.perPage}
                getFn={(currentPage: number) =>
                  getProducts({
                    currentPage: currentPage.toString(),
                    perPage: state.perPage?.toString() || '10',
                    advancedFilter: getAdvancedFilterStr(),
                    sort: 'createdAt:DESC',
                    // like:
                    //   edit.keyword &&
                    //   `name:${edit.keyword},productCode:${edit.keyword}`,
                  })
                }
                onFormatReport={products =>
                  getReportArrSegments(
                    products,
                    selectedColumns.filter((columnName: string) => columnName !== 'Operation'),
                    prodAttributes,
                  )
                }
                reportFileName={PRODUCT_REPORT_FILE_NAME}
              />
            </GridColumn>
          </Grid>
        </AdvancedSearchSectionWrapper>
      )}
    </>
  );

  const onCreateProduct = async (data: iParams) => {
    if (data.templateId) {
      try {
        const createdFromTemplate = await createProductFromTemplate(data.templateId, {
          name: data.name,
          productCode: data.productCode,
        });
        history.push(`${PRODUCTS_URL}/${createdFromTemplate?.id}`);
      } catch (e) {
        apiErrorToast(e);
      }
    } else {
      onSubmit({
        name: data.name,
        productCode: data.productCode, // unique
        productTypeId: data.productTypeId,
        attributeSetId: data.attributeSetId,
      });
    }
  };

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <FlexContainer className={'space-between space-below'}>
        {!!state.total && !!state.from && !!state.to ? (
          <PaginationDetail from={state.from} to={state.to} total={state.total} onRefreshResults={onRefreshList} />
        ) : (
          <div />
        )}
        <ShowHideColumns
          onToggleColumns={onToggleColumn}
          selectedColumns={selectedColumns}
          columnLists={columnsListForShowHide}
        />
      </FlexContainer>

      <ProductListTable
        data={state.data}
        onDelete={onDelete}
        onEdit={onEditIsForCheckbox}
        onSetSort={onSetSort}
        isLoading={state.isLoading}
        columns={selectedColumns.filter((col: string) => {
          return !(
            `${col || ''}`.trim().toLowerCase() === 'operation' &&
            !AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_DELETE, user)
          );
        })}
        prodAttrs={prodAttributes}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onCreateProduct)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <ProductEditModal
            control={control}
            errors={errors}
            onChange={setValue}
            watch={watch}
            targetProduct={edit.target}
            isFor={isFor}
            types={productTypes}
            attributeSets={productAttributeSets}
          />
        }
        modalHeading={edit.target ? `Are you sure to change ${isFor.name} ?` : 'Create'}
        confirmBtnName={'Save'}
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </BackendPage>
  );
};

export default ProductList;
