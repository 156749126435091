import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from '@atlaskit/spinner';
import iContactCompany from '../../../types/contactCompany/iContactCompany';
import iContactCompanyType from '../../../types/contactCompany/iContactCompanyType';
import { iConfigColumn, iParams } from '../../../shared/UITypes/types';
import SearchBar from '../../../shared/search/SearchBar';
import PaginationDetail from '../../../shared/pagination/paginationDetail/PaginationDetail';
import BackendPage from '../../../layout/BackendPage';
import CreateModalBody from './CreateModalBody';
import ContactCompanyTable from './ContactCompanyTable';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import DeleteByRetype from '../../../shared/deleteWithReType/DeleteByReType';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import PageTitleWithAddOne from '../../../shared/heading/PageTitleWithAddOne';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../../../shared/hooks/useShowHideColumnHook/useColumnHook';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { FlexContainer } from '../../../shared/styles/styles';
import { generateFlexColumns, insertFlexToColumns } from './ContactCompany.utils';
import {
  removeContactCompany,
  getContactCompanyTypeList,
  createContactCompanyRefactor,
} from '../../../services/ContactCompanyService';
import ExportReport from '../../../shared/exportReport/ExportReport';
import { getReportArrSegments } from './TableHelper';
import { getExportReportName } from '../../../services/UtilsService';

type iState = {
  types: Array<iContactCompanyType>;
  isLoading: boolean;
};
const initialState: iState = {
  types: [],
  isLoading: true,
};
type iContactCompanyList = {
  typeName: string;
  pageName: string;
  //  eslint-disable-next-line
  getFn: (config?: iParams) => any;
  urlPrefix: string;
  fixColumns: Array<iConfigColumn>;
  flexInsertIndex: number;
  canCreate?: boolean;
  canDelete?: boolean;
};
const ContactCompanyList = ({
  typeName,
  pageName,
  getFn,
  urlPrefix,
  fixColumns,
  flexInsertIndex,
  canCreate = true,
  canDelete = true,
}: iContactCompanyList) => {
  const history = useHistory();
  const { control, setValue, errors, handleSubmit } = useForm();
  const [supState, setSupState] = useState(initialState);
  const {
    state,
    edit,
    onCreate,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onSearch,
    onSetCurrentPage,
    onSetSort,
    onOpenDeleteModal,
    onRefreshList,
  } = useListCrudHook<iContactCompany>({
    getFn,
    createFn: createContactCompanyRefactor,
    deleteFn: removeContactCompany,
    createCallBack: (id: string) => history.push(`${urlPrefix}/${id}`),
    sort: 'name:ASC',
    paginationApplied: true,
    keywordColumns: 'name,phone,email',
  });

  const { columns, getShowHideColumns } = useColumnHooksUpgrade(
    typeName,
    insertFlexToColumns(generateFlexColumns(supState.types, typeName), fixColumns, flexInsertIndex),
    supState.isLoading,
  );

  //    eslint-disable-next-line
  const onCreateMiddle = (data: any) => {
    const typeId = supState.types.find(
      (item: iContactCompanyType) => item.name.toLowerCase() === typeName.toLocaleLowerCase(),
    )?.id;
    if (!typeId) return;
    onCreate({
      ...data,
      typeId,
    });
  };

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setSupState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const types: Array<iContactCompanyType> = await getContactCompanyTypeList();
        if (isCancelled) return;
        setSupState(prevState => ({ ...prevState, types, isLoading: false }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setSupState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);

  const getPageHeader = () => {
    if (state.isLoading) return <Spinner />;
    return (
      <>
        <FlexContainer className={'space-between'}>
          <PageTitleWithAddOne
            title={pageName}
            onClickAddBtn={onOpenAddModal}
            className={'space-below'}
            isDisabled={!canCreate}
          />
          <FlexContainer>
            <SearchBar keyword={edit.keyword} onSearch={onSearch} placeholder={'search via name....'} />
          </FlexContainer>
        </FlexContainer>
      </>
    );
  };

  return (
    <BackendPage pageHeader={getPageHeader()}>
      <FlexContainer className={'space-between space-below'} data-testid={'tbl-header'}>
        {!!state.total && !!state.from && !!state.to ? (
          <PaginationDetail from={state.from} to={state.to} total={state.total} onRefreshResults={onRefreshList} />
        ) : (
          <div />
        )}
        <div
          style={{
            display: 'flex',
            flexGrow: 0.03,
            justifyContent: 'space-between',
          }}
        >
          {!!state.total && (
            <ExportReport
              total={state.total}
              perPage={state.perPage}
              getFn={(currentPage: number) =>
                getFn({
                  currentPage: currentPage.toString(),
                  perPage: state.perPage?.toString() || '10',
                  sort: 'createdAt:DESC',
                  like: edit.keyword ? `name:${edit.keyword}` : '',
                })
              }
              onFormatReport={companys => getReportArrSegments(companys, columns)}
              reportFileName={getExportReportName(typeName)}
            />
          )}
          {getShowHideColumns()}
        </div>
      </FlexContainer>
      <ContactCompanyTable
        pageName={pageName}
        data={state.data}
        columns={columns.filter(col => [...(canDelete ? [] : ['delete'])].indexOf(col.key) < 0)}
        onOpenDeleteModal={onOpenDeleteModal}
        onSetSort={onSetSort}
        isLoading={state.isLoading}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen && !edit.delTargetId}
        onConfirm={handleSubmit(onCreateMiddle)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={<CreateModalBody control={control} onChange={setValue} errors={errors} />}
        modalHeading={`Creating a ${pageName}`}
        confirmBtnName={'Create'}
      />
      <DeleteByRetype
        isOpen={edit.isModalOpen && !!edit.delTargetId}
        onClose={onCloseModal}
        onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
        answer={edit.target?.name}
        isConfirming={state.isConfirming}
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </BackendPage>
  );
};

export default ContactCompanyList;
