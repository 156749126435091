import React, { useState } from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';
import { v4 as uuid } from 'uuid';

import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import iJob from '../../../../types/job/iJob';
import iBomItemAttribute from '../../../../types/product/iBomItemAttribute';
import { iDetailUpdateFn, iSortOrderType } from '../../../UITypes/types';
import { getDeleteBtn } from '../../../../components/settings/utils';
import InlineEdit from '../../../form/InlineEdit';
import {
  handleNullException,
  numberToPercentageWithoutSuffix,
  percentageToNumber,
} from '../../../../services/UtilsService';
import AsyncSearch from '../../../asyncSearch/AsyncSearch';
import { getProductDetail, getProductListAsyncSearch } from '../../../../services/product/ProductService';
import { apiErrorToast } from '../../../toast/Toast';
import ComposeSecondaryText from '../../../text/ComposeSecondaryText';
import LinkBtnUrl from '../../../buttons/LinkBtnUrl';
import { getBomAttributeDetail } from '../../../../services/BomAttributeService';
import { getBomItemAttrHeads, getBomItemAttrRows, getHeads, getOrderType, getSortKey } from './JobBom.helper';
import { PRODUCTS_URL } from '../../../UrlMap';
import { BOM_ATTRIBUTE_HOPPER } from '../../constants';
import { RootState } from '../../../../redux/makeReduxStore';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCTS } from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

const AsyncSearchWrapper = styled.div`
  width: 50%;
  min-width: 12rem;
  margin-bottom: 2rem;
`;

const InlineEditContainer = styled.div`
  padding-bottom: 0.5rem;
`;

const JobBom = ({
  job,
  onUpdate,
  bomItemAttributes,
  children,
  canCreate = true,
  canDelete = true,
  canUpdate = true,
}: {
  job: iJob;
  onUpdate: iDetailUpdateFn;
  bomItemAttributes: Array<iBomItemAttribute>;
  children: React.ReactNode;
  canCreate?: boolean;
  canDelete?: boolean;
  canUpdate?: boolean;
}) => {
  const columns = [
    'Sku / Product Code',
    ...getBomItemAttrHeads(bomItemAttributes),
    'Percentage',
    'Need Amt',
    'Total Amt',
    'Operation',
  ];

  const { user } = useSelector((s: RootState) => s.auth);
  const canReadProduct = AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user);

  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  const onConfirmBomAttributeValue = async (
    name: string,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bomItem: any,
  ) => {
    if (!job || !bomItem) return;

    const bomItemId = name.split(':')[0];
    const bomItemAttrId = name.split(':')[1]; // appended on input

    // check if current bomItem attributes has the target attr to update
    const attrFound = bomItem.bomItemAttributes?.find((attr: iBomItemAttribute) => attr.id === bomItemAttrId);

    let attrData;
    let bomItemAttributeValues;
    let attributeToStore;
    let updatedAttributeArray;

    if (!attrFound) {
      // get Attribute from db
      attrData = await getBomAttributeDetail(bomItemAttrId);
      const isHopper = handleNullException(attrData, 'name').toUpperCase() === BOM_ATTRIBUTE_HOPPER;

      // eslint-disable-next-line no-nested-ternary
      const attributeValueStored = !isHopper
        ? Number(percentageToNumber(value, 4)) === 0
          ? ''
          : Number(percentageToNumber(value, 4))
        : value;
      bomItemAttributeValues = {
        createdAt: new Date(),
        id: uuid(),
        bomItemId,
        bomItemAttributeId: bomItemAttrId,
        value: attributeValueStored,
      };
      attributeToStore = { ...attrData, bomItemAttributeValues };
      updatedAttributeArray = [...bomItem.bomItemAttributes, attributeToStore];
    }

    if (attrFound) {
      attrData = attrFound;
      const isHopper = handleNullException(attrData, 'name').toUpperCase() === BOM_ATTRIBUTE_HOPPER;

      // eslint-disable-next-line no-nested-ternary
      const attributeValueStored = !isHopper
        ? Number(percentageToNumber(value, 4)) === 0
          ? ''
          : Number(percentageToNumber(value, 4))
        : value;

      // check if this attribute has value
      attributeToStore = attrData.bomItemAttributeValues?.value
        ? {
            ...attrData,
            bomItemAttributeValues: {
              ...attrData.bomItemAttributeValues,
              value: attributeValueStored,
            },
          }
        : {
            ...attrData,
            bomItemAttributeValues: {
              createdAt: new Date(),
              id: uuid(),
              bomItemId,
              bomItemAttributeId: bomItemAttrId,
              value: attributeValueStored,
            },
          };

      const bomItemAttributesArrayCopy = bomItem.bomItemAttributes;
      const targetAttributeIndex = _.findIndex(bomItemAttributesArrayCopy, (attribute: iBomItemAttribute) => {
        return attribute.id === bomItemAttrId;
      });

      // if attribute already exists, replace it with new one insteadof append
      bomItemAttributesArrayCopy.splice(targetAttributeIndex, 1, attributeToStore);
      updatedAttributeArray = [...bomItemAttributesArrayCopy];
    }

    const updatedBomItem = {
      ...bomItem,
      bomItemAttributes: updatedAttributeArray,
    };

    const jobBomDetailsArrayCopy = job.bomDetails;
    const targetBomItemIndex = _.findIndex(jobBomDetailsArrayCopy, item => {
      return item.id === bomItemId;
    });

    jobBomDetailsArrayCopy.splice(targetBomItemIndex, 1, updatedBomItem);

    onUpdate({ bomDetails: jobBomDetailsArrayCopy }, true);
  };

  const onDelete = (itemId: string) => {
    const newBomArr = job?.bomDetails.filter(item => item.id !== itemId);
    try {
      onUpdate({ bomDetails: newBomArr });
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onSelect = async (payload: string) => {
    if (!payload || !job) return;
    try {
      const materialId = payload.split(':')[0];
      const materialData = await getProductDetail(materialId);

      const newBomItemId = uuid();
      const newBomItem = {
        id: newBomItemId,
        materialId,
        materialQty: 1,
        sortOrder: 0,
        material: materialData,
        createdAt: new Date(),
        bomItemAttributes: [],
      };

      onUpdate({ bomDetails: [...job?.bomDetails, newBomItem] }, true);
      setTimeout(() => document.getElementById(`${newBomItemId}:materialQty`)?.focus(), 200);
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onConfirmBomItem = (name: string, newValue: string) => {
    const targetId = name.split(':')[0];
    if (!targetId || !job?.bomDetails) return;
    const newArr = job.bomDetails;
    const targetItem = newArr.find(item => item.id === targetId);
    if (!targetItem) return;
    // find the row to update
    const targetIndex = _.findIndex(newArr, item => {
      return item.id === targetId;
    });
    // eslint-disable-next-line no-unused-expressions
    newArr.splice(targetIndex, 1, {
      ...targetItem,
      [`${name.split(':')[1]}`]: Number(percentageToNumber(newValue, 4)),
    });

    onUpdate({ bomDetails: newArr }, true);
  };

  const getRows = () => {
    return (
      job.bomDetails
        ?.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((item: any) => ({
          cells: [
            {
              key: handleNullException(item, 'material.productCode'),
              content: (
                <ComposeSecondaryText secondaryText={handleNullException(item, 'material.name')}>
                  {canReadProduct ? (
                    <LinkBtnUrl
                      btnName={handleNullException(item, 'material.productCode')}
                      url={`${PRODUCTS_URL}/${item.materialId}`}
                    />
                  ) : (
                    handleNullException(item, 'material.productCode')
                  )}
                </ComposeSecondaryText>
              ),
            },
            ...getBomItemAttrRows(item, bomItemAttributes, onConfirmBomAttributeValue, !canUpdate),
            {
              key: numberToPercentageWithoutSuffix(item.materialQty, 2),
              content: (
                <InlineEditContainer>
                  <InlineEdit
                    name={`${item.id}:materialQty`}
                    defaultValue={numberToPercentageWithoutSuffix(item.materialQty, 2)?.toString()}
                    label={''}
                    onConfirm={onConfirmBomItem}
                    postfix={'%'}
                    needFormatValue
                    hideActionButtons
                    isNumeric
                    isRequired
                    min={{ minValue: 0 }}
                    isDisabled={!canUpdate}
                  />
                </InlineEditContainer>
              ),
            },
            {
              key: `${handleNullException(item, 'needAmt')} ${item.material.measurement.shortName}`,
              content: `${handleNullException(item, 'needAmt')} ${item.material.measurement.shortName}`,
            },
            {
              key: `${handleNullException(item, 'totalAmt')} ${item.material.measurement.shortName}`,
              content: `${handleNullException(item, 'totalAmt')} ${item.material.measurement.shortName}`,
            },
            {
              content: canDelete
                ? getDeleteBtn({
                    id: item.id,
                    answer: item.material.productCode || 'n/a',
                    onClick: onDelete,
                  })
                : null,
            },
          ],
        }))
    );
  };

  return (
    <>
      {children}
      <DynamicTable
        head={getHeads(
          columns.filter(col => !(col === 'Operation' && !canDelete)),
          'job-BOM',
        )}
        rows={getRows()}
        testId={'job-BOM-list-table'}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSort={(data: any) => {
          setSortKey(data.key);
          setSortOrder(data.sortOrder);
          localStorage.setItem('sortKey', data.key);
          localStorage.setItem('sortOrder', data.sortOrder);
        }}
        sortOrder={sortOrder}
        sortKey={sortKey}
        defaultSortOrder={getOrderType(localStorage.getItem('sortOrder'))}
        defaultSortKey={getSortKey(localStorage.getItem('sortKey'))}
      />
      <AsyncSearchWrapper>
        <AsyncSearch
          onSelect={onSelect}
          promiseFn={(keyword: string) =>
            getProductListAsyncSearch({
              like: `productCode:${keyword},name:${keyword}`,
            })
          }
          optionLabel={['productCode', 'name']}
          searchBarPlaceholder={'Search product code or name to add as BOM...'}
          isDisabled={!canCreate}
        />
      </AsyncSearchWrapper>
    </>
  );
};

export default JobBom;
