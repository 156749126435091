import React from 'react';
import { useSelector } from 'react-redux';
import ContactCompanyList from './list/ContactCompanyList';
import { getSupplierList } from '../../services/ContactCompanyService';
import { SUPPLIER } from '../../shared/contactCompany/constants';
import { SUPPLIER_URL } from '../../shared/UrlMap';
import { SUPPLIER_COLUMNS, SUPPLIER_INSERT_INDEX, SUPPLIER_PAGE_NAME } from './list/ContactCompany.constants';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_SUPPLIERS } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE } from '../../types/settings/UserAccess/iRoleAccess';
import { RootState } from '../../redux/makeReduxStore';

const SupplierList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  return (
    <ContactCompanyList
      typeName={SUPPLIER}
      pageName={SUPPLIER_PAGE_NAME}
      getFn={getSupplierList}
      urlPrefix={SUPPLIER_URL}
      fixColumns={SUPPLIER_COLUMNS}
      flexInsertIndex={SUPPLIER_INSERT_INDEX}
      canCreate={AccessService.hasAccess(ACCESS_CODE_SUPPLIERS, ACCESS_CAN_CREATE, user)}
      canDelete={AccessService.hasAccess(ACCESS_CODE_SUPPLIERS, ACCESS_CAN_DELETE, user)}
    />
  );
};

export default SupplierList;
