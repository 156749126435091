import React, { useState } from 'react';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import TextArea from '@atlaskit/textarea';
import styled from 'styled-components';
import { fontSize, gridSize, fontFamily } from '@atlaskit/theme/constants';
import _ from 'lodash';
import CustomizedLabel from './CustomizedLabel';
import { ErrorMsg } from '../../styles/styles';

export type textareaProps = {
  placeholder?: string;
  label?: string;
  helper?: React.ReactNode;
  name: string;
  //  eslint-disable-next-line
  control: Control<Record<string, any>>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange?: (name: string, newValue: string, config?: Object) => void;
  testId?: string;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  defaultValue?: string;
  appStyle?: 'default' | 'danger' | 'success' | 'warning';
  minimumRows?: number;
  appearance?: 'standard' | 'subtle' | 'none';
  isDisabled?: boolean;
  isRequired?: boolean;
  //  eslint-disable-next-line
  textareaRef?: React.RefObject<HTMLTextAreaElement>;
  debouncePeriod?: number;
};

const TextAreaWrapper = styled.div`
  &.danger {
    .helper {
      color: #de350b;
    }
  }
  .label {
    font-size: ${fontSize() / 17}rem;
    margin: ${gridSize() / 2}px 0;
    display: block;
    font-style: inherit;
    line-height: 1.3333333333333333;
    color: #6b778c;
    font-weight: 600;
    font-family: ${fontFamily()};
  }
  .helper {
    font-size: ${fontSize() / 18}rem;
    padding: ${gridSize() / 2}px;
  }
`;
export default ({
  defaultValue,
  placeholder,
  label,
  helper,
  name,
  appStyle,
  control,
  onChange,
  testId = 'textArea-react-hook-form',
  errors,
  isRequired = true,
  minimumRows,
  appearance,
  isDisabled = false,
  //   eslint-disable-next-line
  textareaRef,
  debouncePeriod = 1000,
}: textareaProps) => {
  const [input, setInput] = useState(defaultValue);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [debouncedInput, setDebouncedInput] = useState(defaultValue);

  const debounce = _.debounce((inputValue: string) => {
    //  setDebouncedInput(inputValue);
    if (!onChange) {
      return;
    }
    onChange(name, inputValue, { shouldValidate: true });
  }, debouncePeriod);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    debounce(e.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      rules={{
        required: isRequired,
      }}
      render={ctrlProps => (
        <TextAreaWrapper className={appStyle || 'default'}>
          {label ? (
            <CustomizedLabel
              label={label}
              htmlFor={testId}
              isRequired={isRequired}
            />
          ) : null}
          <TextArea
            // eslint-disable-next-line
            {...ctrlProps}
            name={name}
            value={input}
            placeholder={placeholder}
            testId={name}
            onChange={handleOnChange}
            minimumRows={minimumRows}
            appearance={appearance}
            isDisabled={isDisabled}
            //  eslint-disable-next-line
          ref={textareaRef}
          />
          {helper ? <div className={'helper'}>{helper}</div> : null}
          {_.get(errors, name) && <ErrorMsg>is required</ErrorMsg>}
        </TextAreaWrapper>
      )}
    />
  );
};
