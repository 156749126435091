import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import iProfileCompany from '../../../../types/company/iProfileCompany';
import InlineRichTextEditor from '../../../../shared/form/InlineRichTextEditor';
import iProfileCompanyPreference, {
  COMPANY_PREFERENCE_CODE_COC_SETTINGS,
  COMPANY_PREFERENCE_KEY_COC_ID_MATERIAL,
} from '../../../../types/company/iProfileCompanyPreference';
import CompanyPreferenceService from '../../../../services/Settings/CompanyPreferenceService';
import { addToastForAPIResponse, apiErrorToast } from '../../../../shared/toast/Toast';

const Wrapper = styled.div`
  .section {
    border-bottom: 1px #ddd solid;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    :last-child {
      border-bottom: none;
    }
  }
`;
type iPDFCertificateOfConformanceSettings = {
  company: iProfileCompany;
};
const PDFCertificateOfConformanceSettings = ({ company }: iPDFCertificateOfConformanceSettings) => {
  const [cocSettings, setCocSettings] = useState<iProfileCompanyPreference | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    CompanyPreferenceService.getCompanyPreferences({
      filter: `companyId:${company.id},code:${COMPANY_PREFERENCE_CODE_COC_SETTINGS}`,
      perPage: 1,
      currentPage: 1,
      order: 'updatedAt:DESC',
    })
      .then(resp => {
        if (isCanceled) return;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const settings = resp.data || [];
        setCocSettings(settings.length <= 0 ? null : settings[0]);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [company]);

  const updateField = (name: string, value: string) => {
    if (!cocSettings) {
      return;
    }
    setIsSaving(true);
    CompanyPreferenceService.updateCompanyPreference(cocSettings.id, {
      value: {
        ...cocSettings.value,
        [name]: value,
      },
    })
      .then(resp => {
        setCocSettings(resp);
        addToastForAPIResponse('success', 'updated successfully.');
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      <InlineRichTextEditor
        label={'Static wording for Materials'}
        testId={'coc_materials'}
        defaultValue={
          COMPANY_PREFERENCE_KEY_COC_ID_MATERIAL in (cocSettings?.value || {})
            ? `${cocSettings?.value[COMPANY_PREFERENCE_KEY_COC_ID_MATERIAL] || ''}`
            : ''
        }
        disabled={isSaving}
        onChangeConfirmed={newText => updateField(COMPANY_PREFERENCE_KEY_COC_ID_MATERIAL, newText)}
      />
    </Wrapper>
  );
};

export default PDFCertificateOfConformanceSettings;
