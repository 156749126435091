import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import SectionMessage from '@atlaskit/section-message';
import { Label } from '@atlaskit/field-base';
import { clearInterval, setInterval } from 'timers';
import Spinner from '@atlaskit/spinner';
import Checkbox from '@atlaskit/checkbox';
import { LoadingButton } from '@atlaskit/button';
import iChannel from '../../../../types/integration/iChannel';
import iProduct from '../../../../types/product/iProduct';
import { iChannelEntity } from '../../../../types/integration/iChannelEntity';
import iWooCommerceProduct from '../types/WooCommerceProductType';
import InlineRichTextEditor from '../../../form/InlineRichTextEditor';
import WooCommerceProductCategoryPanel from './WooCommerceProductCategoryPanel';
import WooCommerceConnectorService from '../../../../services/integration/connectors/WooCommerceConnectorService';
import { addToastForAPIResponse, apiErrorToast } from '../../../toast/Toast';
import ChannelEntityService from '../../../../services/integration/ChannelEntityService';
import InlineEdit from '../../../form/InlineEdit';
import ChannelJobService from '../../../../services/integration/ChannelJobService';
import CustomizeModal from '../../../modal/CustomizeModal';
import { FlexContainer } from '../../../styles/styles';
import ProductImageService from '../../../../services/product/ProductImageService';

type iWooCommerceProductEditPanel = {
  channel: iChannel;
  product: iProduct;
  channelEntity: iChannelEntity;
  onSaved: (channelEntity: iChannelEntity) => void;
  isDisabled?: boolean;
};

const Wrapper = styled.div``;
const WooCommerceProductEditPanel = ({
  channel,
  product,
  channelEntity,
  onSaved,
  isDisabled,
}: iWooCommerceProductEditPanel) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isGettingImages, setIsGettingImages] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);
  const externalProduct: iWooCommerceProduct = channelEntity.externalObj;
  const getLink = () => {
    if (!externalProduct.permalink) {
      return null;
    }

    return (
      <a href={channelEntity.externalObj.permalink} target={'__blank'} data-testid={'product-'}>
        <ShortcutIcon label="link" /> View Product [{product.productCode}] under [<b>{channel.name}</b>]
      </a>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleConfirm = async (name: string, newValue: any) => {
    const newParams = {
      // ...channelEntity.externalObj,
      [name]: newValue,
    };
    if (name === 'manage_stock' && newValue === true) {
      newParams.stock_quantity = product.stockOnHand || 0;
    }
    try {
      setIsSaving(true);
      const resp = await WooCommerceConnectorService.updateProduct(channelEntity.channelId, channelEntity.externalId, {
        requestData: newParams,
        entityName: 'Product',
        entityId: product.id,
      });

      const intervalId = setInterval(async () => {
        const updatedJob = await ChannelJobService.getChannelJobById(resp.id);
        if (updatedJob.response === null && updatedJob.error === null) {
          return;
        }

        if (updatedJob.response !== null) {
          clearInterval(intervalId);
          addToastForAPIResponse('success', 'Product Updated.');
          setIsSaving(false);
          const res = await ChannelEntityService.getChannelEntitiesByEntityJobId(updatedJob.id);
          if (res.data && res.data.length > 0) {
            onSaved(res.data[0]);
          }
          return;
        }

        if (updatedJob.error !== null) {
          clearInterval(intervalId);
          apiErrorToast('WooCommerce Product Update Failed.');
          setIsSaving(false);
        }
      }, 1000);
      ref.current = intervalId;
    } catch (err) {
      setIsSaving(false);
      apiErrorToast(err);
    }
  };

  const forceSyncImages = () => {
    setIsGettingImages(true);
    ProductImageService.getImages({
      filter: `productId:${product.id}`,
    })
      .then(res => {
        if (res.data && res.data.length > 0) {
          handleConfirm(
            'images',
            res.data.map(img => ({ src: img.url })),
          );
        }
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsGettingImages(false);
      });
  };

  const onClose = () => {
    if (ref.current) {
      clearInterval(ref.current);
      ref.current = null;
    }
    setIsSaving(false);
  };

  const getModal = () => {
    if (!isSaving) {
      return null;
    }
    return (
      <CustomizeModal
        isOpen
        onCancel={() => onClose()}
        customizedBtn={<></>}
        modalHeading={'Saving...'}
        modalBody={
          <>
            <Spinner />
          </>
        }
        shouldScrollInViewport
      />
    );
  };

  return (
    <Wrapper>
      {getLink()}
      <SectionMessage appearance={'info'} title={externalProduct.name}>
        Any changes on below will only change product information under {channel.name}
      </SectionMessage>
      <div>
        <Label label={'Product Images'} />
        <LoadingButton
          appearance={'default'}
          onClick={() => forceSyncImages()}
          isLoading={isGettingImages}
          isDisabled={isDisabled}
        >
          Upload all current product images
        </LoadingButton>
      </div>
      <div>
        <Label label={'Product Categories'} />
        <WooCommerceProductCategoryPanel
          channel={channel}
          onSelected={values =>
            handleConfirm(
              'categories',
              values.map(value => ({ id: value.value })),
            )
          }
          defaultValues={externalProduct.categories.map(cate => cate.id)}
          disabled={isDisabled}
        />
      </div>
      <FlexContainer className={'even-split with-gap'}>
        <div>
          <InlineEdit
            name={'regular_price'}
            isDisabled={isSaving || isDisabled}
            label={'Unit Price'}
            testId={'unit-price'}
            defaultValue={`${externalProduct.regular_price || 0}`}
            onConfirm={(name, newText) => handleConfirm('regular_price', newText)}
          />
        </div>
        <FlexContainer className={'even-split with-gap'}>
          <div>
            <Label label={'Manage Stock?'} />
            <Checkbox
              isChecked={externalProduct.manage_stock || false}
              onChange={() => handleConfirm('manage_stock', !externalProduct.manage_stock)}
              isDisabled={isDisabled}
            />
          </div>
          {externalProduct.manage_stock && (
            <InlineEdit
              name={'stock_quantity'}
              isDisabled={isSaving || isDisabled}
              label={'Stock on Hand'}
              testId={'soh'}
              defaultValue={`${externalProduct.stock_quantity || 0}`}
              onConfirm={(name, newText) => handleConfirm('stock_quantity', newText)}
            />
          )}
        </FlexContainer>
      </FlexContainer>
      <div className={'full-width'}>
        <InlineRichTextEditor
          disabled={isSaving || isDisabled}
          label={'Short Description'}
          testId={'shortDescription'}
          defaultValue={externalProduct.short_description || ''}
          onChangeConfirmed={newText => handleConfirm('short_description', newText)}
        />
      </div>
      <div className={'full-width'}>
        <InlineRichTextEditor
          disabled={isSaving || isDisabled}
          label={'Full Description'}
          testId={'fullDescription'}
          defaultValue={externalProduct.description || ''}
          onChangeConfirmed={newText => handleConfirm('description', newText)}
        />
      </div>
      {getModal()}
    </Wrapper>
  );
};

export default WooCommerceProductEditPanel;
