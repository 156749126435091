import React from 'react';
import { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { iLabelValuePair } from '../../../../shared/UITypes/types';
import { useJobSchContext } from '../../JobScheduleContext';

const LockJob = ({
  date,
  shift,
  jobId,
  moveDisabled,
}: {
  date: string;
  shift: iLabelValuePair;
  jobId: string;
  moveDisabled: boolean;
}) => {
  const { onToggleLock } = useJobSchContext();
  return (
    <DropdownItemGroup>
      <DropdownItem
        onMouseDown={() =>
          typeof onToggleLock === 'function' &&
          onToggleLock(date, shift, jobId, !moveDisabled)
        }
      >
        {moveDisabled ? 'Release' : 'Lock'}
      </DropdownItem>
    </DropdownItemGroup>
  );
};

export default LockJob;
