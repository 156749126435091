import React, { useRef } from 'react';
import styled from 'styled-components';
import { apiErrorToast } from '../toast/Toast';

const UploadTriggerWrapper = styled.div`
  display: flex;
  .title {
    font-weight: 400;
    font-size: 16px;
    color: RGB(23, 43, 77);
    margin-right: 0.5rem;
  }
  .invisible-input {
    display: none;
  }
`;

type iUploader = {
  //    eslint-disable-next-line
  onUploadFile: (data: any) => void;
  //    eslint-disable-next-line
  extraData: () => {[key: string]: any};
  children: React.ReactNode;
  className?: string;
  acceptTypes?: string[];
  multiple?: boolean;
};
const Uploader = ({
  extraData,
  onUploadFile,
  children,
  className,
  multiple = false,
  acceptTypes = ['.xlsx', '.xls', 'image/*', '.doc', '.docx', '.ppt', '.pptx', '.txt', '.pdf'],
}: iUploader) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const openFileBrowser = () => {
    return fileRef.current?.click();
  };

  const uploadSingleFile = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('attachedFile', file);
      const data = await extraData();
      if (typeof data === 'object' && Object.keys(data).length > 0) {
        Object.keys(data).map(key => {
          formData.append(key, data[key]);
          return null;
        });
      }
      onUploadFile(formData);
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const onUploadFiles = () => {
    if (!fileRef || !fileRef.current || !fileRef.current.files || fileRef.current.files.length === 0) {
      return;
    }
    Array.from(fileRef.current.files).forEach((file: File) => uploadSingleFile(file));
  };

  return (
    <UploadTriggerWrapper onClick={openFileBrowser} className={className}>
      {/* <span className={'title'}>Attachments({length})</span> */}
      {children}
      <input
        className={'invisible-input'}
        type="file"
        multiple={multiple}
        accept={acceptTypes.join(', ')}
        ref={fileRef}
        onChange={() => onUploadFiles()}
        data-testid={'upload-file'}
      />
    </UploadTriggerWrapper>
  );
};

export default Uploader;
