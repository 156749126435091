/* eslint-disable import/no-cycle */
import React from 'react';
import { iTemplateColumn } from '../../shared/DataImport.types';
import Collapse from '../../../../../shared/collapse/Collapse';
import DownloadTemplate from './DownloadTemplate';
import { STEP1_TEXT } from '../../shared/DataImport.constants';

const StepDownload = ({
  detailPageName,
  columns,
  example,
}: {
  detailPageName: string;
  columns: Array<iTemplateColumn>;
  example: Array<string>;
}) => {
  return (
    <Collapse text={STEP1_TEXT} defaultOpen>
      <DownloadTemplate
        detailPageName={detailPageName}
        columns={columns}
        example={example}
      />
    </Collapse>
  );
};

export default StepDownload;
