import React, { useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { handleNullException, mapToLabelValuePair } from '../../../services/UtilsService';
import CheckboxWithController from '../../../shared/form/hookForms/CheckboxWithController';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import SelectWithController from '../../../shared/form/hookForms/SelectWithController';

import iProduct from '../../../types/product/iProduct';
import iProductAttributeSet from '../../../types/product/iProductAttributeSet';
import iProductType from '../../../types/product/iProductType';
import { SpaceVerticalContainer } from '../../../shared/styles/styles';
import { getProductListAsyncSearch } from '../../../services/product/ProductService';
import AsyncSearchWithController from '../../../shared/form/hookForms/AsyncSearchWithController';

const ProductEditModal = ({
  control,
  errors,
  onChange,
  watch,
  targetProduct,
  isFor,
  types,
  attributeSets,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  //    eslint-disable-next-line
  watch: (names?: string | string[]) => any;
  targetProduct?: iProduct;
  isFor?: {
    name?: string;
    value?: boolean;
  };
  types: Array<iProductType>;
  attributeSets: Array<iProductAttributeSet>;
}) => {
  const [localSelectedTemplate, setLocalSelectedTemplate] = useState<iProduct>();

  const getDefaultAttributeId = (productTypeId?: string) => {
    if (typeof productTypeId === 'undefined') return undefined;
    return types.find((t: iProductType) => t.id === productTypeId)?.attributeSetId;
  };
  //    eslint-disable-next-line
  const onChangeProductType = (name: string, value: any, config?: Object) => {
    onChange(name, value, config);
    // null/undefined value, do not take any further action
    if (!value) return;
    const defaultAttributeId = getDefaultAttributeId(value);
    // null/undefined related value, do not take any further action
    if (!defaultAttributeId) return;
    onChange('attributeSetId', defaultAttributeId, config);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onSelectTemplate = (name: string, value: iProduct, config?: Object) => {
    // const selected = templates.find(
    //   (template: iProduct) => template.id === value,
    // );

    setLocalSelectedTemplate(value);
    onChange(name, value.id, config);
  };
  return targetProduct && isFor && isFor.name ? (
    <CheckboxWithController
      label={isFor.name}
      name={isFor.name}
      defaultValue={!isFor.value}
      control={control}
      onChange={onChange}
      errors={errors}
      isRequired
      // isDisabled
      testId={`product-edit-modal-${isFor.name}`}
    />
  ) : (
    <>
      <AsyncSearchWithController
        name={'templateId'}
        label={'Template (optional)'}
        onChange={onSelectTemplate}
        promiseFn={(keyword: string) =>
          getProductListAsyncSearch({
            like: `productCode:${keyword},name:${keyword}`,
            filter: 'isTemplate:true',
          })
        }
        optionLabel={['productCode', 'name']}
        control={control}
        errors={errors}
        isRequired={false}
        isClearable
        testId={'product-create-modal-templates'}
      />
      <SelectWithController
        name={'productTypeId'}
        label={'Product Type'}
        options={mapToLabelValuePair<iProductType>(types)}
        defaultValue={localSelectedTemplate && handleNullException(localSelectedTemplate, 'productTypeId')}
        control={control}
        onChange={onChangeProductType}
        errors={errors}
        isRequired
        isDisabled={!!localSelectedTemplate}
        testId={'product-create-modal-type'}
      />

      <SelectWithController
        name={'attributeSetId'}
        label={'Product Attribute Set'}
        options={mapToLabelValuePair<iProductAttributeSet>(attributeSets)}
        defaultValue={
          localSelectedTemplate
            ? getDefaultAttributeId(handleNullException(localSelectedTemplate, 'productTypeId'))
            : getDefaultAttributeId(watch('productTypeId'))
        }
        control={control}
        onChange={onChange}
        errors={errors}
        isRequired
        isDisabled={!!localSelectedTemplate}
        testId={'product-create-modal-attributeSet'}
      />
      <InputWithController
        name={'productCode'}
        label={'Product Code'}
        defaultValue={targetProduct?.productCode}
        control={control}
        onChange={onChange}
        errors={errors}
        isRequired
        testId={'product-create-modal-productCode'}
      />
      <InputWithController
        name={'name'}
        label={'Product Name'}
        defaultValue={localSelectedTemplate ? handleNullException(localSelectedTemplate, 'name') : targetProduct?.name}
        control={control}
        onChange={onChange}
        errors={errors}
        isRequired
        testId={'product-create-modal-name'}
      />
      <SpaceVerticalContainer className={'five'} />
    </>
  );
};

export default ProductEditModal;
