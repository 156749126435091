import styled from 'styled-components';

export const ProductTypeTableWrapper = styled.div`
  th[data-testid='productType-table-column-Operation--head--cell'] {
    min-width: 36px;
    max-width: 50px;
  }

  th[data-testid='productType-table-column-Default Sell flag--head--cell'],
  th[data-testid='productType-table-column-Default Make flag--head--cell'],
  th[data-testid='productType-table-column-Default Waste flag--head--cell'] {
    max-width: 55px;
  }

  th[data-testid='productType-table-column-Default Purchase flag--head--cell'] {
    min-width: 75px;
  }
`;
