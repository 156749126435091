import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { getHeads } from '../../../components/settings/utils';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { getRows } from './Helper/TableHelper';
import iPurchaseOrderItem from '../../../types/purchases/iPurchaseOrderItem';
import { iConfigColumn } from '../../../shared/UITypes/types';

type iPurchaseOrderItemsTable = {
  data: iPurchaseOrderItem[];
  isLoading: boolean;
  tableName?: string;
  statusCategories: iEntityCategory[];
  selectedColumns: iConfigColumn[];
};
const PurchaseOrderItemsTable = ({
  data,
  isLoading,
  statusCategories,
  selectedColumns,
  tableName = 'po-item-orderItem-tbl',
}: iPurchaseOrderItemsTable) => {
  return (
    <DynamicTable
      head={getHeads(
        selectedColumns.filter(col => `${col.name}`.trim() !== '').map(col => col.name),
        tableName,
      )}
      rows={getRows(data, statusCategories, selectedColumns)}
      isFixedSize
      isLoading={isLoading}
      emptyView={<div>No results found.</div>}
    />
  );
};

export default PurchaseOrderItemsTable;
