/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@atlaskit/button';
import UploadIcon from '@atlaskit/icon/glyph/upload';
import Spinner from '@atlaskit/spinner';
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { clearInterval, setInterval } from 'timers';
import { formatDate } from '../../../services/UtilsService';
import CustomizeModal from '../../modal/CustomizeModal';
import { FlexContainer } from '../../styles/styles';
import { addToastForAPIResponse, apiErrorToast } from '../../toast/Toast';
import { iChannelEntity } from '../../../types/integration/iChannelEntity';
import { iChannelJobEntity } from '../../../types/integration/iChannelJob';
import MyobUserSelectContainer from './styled/MyobUserSelectContainer';
import myobLogoSVG from '../../integrations/svgs/myob-logo.svg';
import ChannelEntityService from '../../../services/integration/ChannelEntityService';
import ChannelJobService from '../../../services/integration/ChannelJobService';

const MyobEntitySyncBtn = ({
  channelId,
  entityId,
  entityName,
  promiseFnForCreation,
  promiseFnForUpdate,
  labelAttributePath,
  isDisabled,
}: {
  channelId: string;
  entityId: string;
  entityName: string;
  promiseFnForCreation: (channelId: string, entityId: string) => Promise<iChannelJobEntity>;
  promiseFnForUpdate: (channelId: string, entityId: string, externalId: string) => Promise<iChannelJobEntity>;
  labelAttributePath: string;
  isDisabled?: boolean;
}) => {
  const [channelEntities, setChannelEntities] = useState<iChannelEntity[]>();
  const [isSyncing, setIsSyncing] = useState(false);
  const ref = useRef<any>(null);

  const isExistingChannelEntities = channelEntities && channelEntities.length > 0;
  const labelWording = isExistingChannelEntities ? 'Update in MYOB' : 'Create in MYOB';
  const entityLabel = isExistingChannelEntities ? _.get(channelEntities![0].externalObj, labelAttributePath) : '';

  useEffect(() => {
    const fetchData = async () => {
      const channelEntitiesResp = await ChannelEntityService.getChannelEntities(channelId, entityName, entityId);
      setChannelEntities(channelEntitiesResp);
    };
    fetchData();
  }, [channelId, entityId, entityName]);

  const onClose = () => {
    if (ref.current) {
      clearInterval(ref.current);
      ref.current = null;
    }
    setIsSyncing(false);
  };
  const syncHandler = async () => {
    try {
      if (!promiseFnForCreation) {
        throw new Error('promiseFnForCreation is undefined');
      }
      setIsSyncing(true);
      // TODO check if entity exist
      const channelJob = isExistingChannelEntities
        ? await promiseFnForUpdate(channelId, entityId, channelEntities![0].externalId)
        : await promiseFnForCreation(channelId, entityId);
      const intervalId = setInterval(async () => {
        const channelJobReloaded = await ChannelJobService.getChannelJobById(channelJob.id);
        if (channelJobReloaded.response === null && channelJobReloaded.error === null) {
          return;
        }
        if (channelJobReloaded.response !== null) {
          clearInterval(intervalId);
          addToastForAPIResponse('success', 'MYOB sync success.');
          const resp = await ChannelEntityService.getChannelEntitiesByEntityJobId(channelJob.id);
          if (resp.data && resp.data.length > 0) {
            setChannelEntities(resp.data);
          }
          setIsSyncing(false);
          return;
        }
        if (channelJobReloaded.error !== null) {
          const errorDetails = channelJobReloaded.error.Errors.map((err: any) => err.AdditionalDetails).join(',');
          clearInterval(intervalId);
          addToastForAPIResponse('error', `MYOB sync failed: ${errorDetails}`);
          setIsSyncing(false);
        }
      }, 1000);
      ref.current = intervalId;
    } catch (error) {
      setIsSyncing(false);
      apiErrorToast(error);
    }
  };

  return (
    <>
      <MyobUserSelectContainer>
        <FlexContainer className="align-items-center">
          <div>
            {entityLabel}
            <br />
            <small>{channelEntities && channelEntities.length > 0 && formatDate(channelEntities[0].updatedAt)}</small>
          </div>
          <div>
            <Button
              iconBefore={<UploadIcon label={labelWording} />}
              isDisabled={isSyncing || isDisabled}
              onClick={syncHandler}
            >
              {labelWording}
            </Button>
          </div>
          <img className="logo" src={myobLogoSVG} alt="channel logo" />
        </FlexContainer>
      </MyobUserSelectContainer>
      <CustomizeModal
        isOpen={isSyncing}
        isDisabled={isSyncing}
        customizedBtn={onClose}
        onCancel={onClose}
        modalBody={
          <>
            <Spinner />
          </>
        }
        modalHeading={labelWording}
        cancelBtnName="Close"
      />
    </>
  );
};

export default MyobEntitySyncBtn;
