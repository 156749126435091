import React from 'react';
import _ from 'lodash';
import iDispatchNoteItem from '../../../types/dispatchNote/iDispatchNoteItem';
import Summary from '../shared/Summary';
import { handleNullExceptionMultiFields } from '../../../services/UtilsService';

const SummaryDispatchNoteItem = ({
  data,
}: {
  data: Array<iDispatchNoteItem>;
}) => {
  const getSums = () => {
    const grouped = _.groupBy(data, (item: iDispatchNoteItem) =>
      handleNullExceptionMultiFields(
        item,
        'salesOrderItem?.product.measurement.shortName,salesOrderItem?.measurement?.shortName',
      ),
    );
    const sums = Object.keys(grouped).map((unitName: string) => {
      const sum = grouped[unitName].reduce(
        (acc: number, cur: iDispatchNoteItem) => acc + (cur.quantity || 0),
        0,
      );
      return { unitName, sum };
    });
    return sums;
  };
  return (
    <Summary data={getSums()}>
      {Object.keys(_.groupBy(data, 'salesOrderItem.orderId')).length} Order(s)
    </Summary>
  );
};

export default SummaryDispatchNoteItem;
