import React from 'react';
import MyobChannelForm from './MyobChannelForm';
import MyobChannelOnboardingForm from './MyobChannelOnboardingForm';
import { iChannelEditPanelComponentParams } from '../../integrations/ChannelScriptHelperTypes';

const MyobChannelEditPanel = ({ channel, channelScript, onSaved }: iChannelEditPanelComponentParams) => {
  return (
    <>
      {channel.isOnBoarded ? (
        <MyobChannelForm channel={channel} callback={onSaved} channelScript={channelScript} />
      ) : (
        <MyobChannelOnboardingForm channel={channel} callback={onSaved} />
      )}
    </>
  );
};

export default MyobChannelEditPanel;
