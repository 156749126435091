import React from 'react';
import _ from 'lodash';
import { HelperMessage } from '@atlaskit/form';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import Textfield from '@atlaskit/textfield';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { iHookFormField } from '../../../shared/UITypes/types';
import IndenpendentLabel from '../../../shared/form/hookForms/IndenpendentLabel';
import { ErrorMsg } from '../../../shared/styles/styles';

const RenderForm = ({
  control,
  errors,
  fields,
}: {
  //  eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  fields: Array<iHookFormField>;
}) => {
  return (
    <Page>
      <Grid spacing={'compact'} layout={'fluid'}>
        {fields.map((field: iHookFormField) => (
          <GridColumn key={field.label} medium={field.size}>
            <IndenpendentLabel
              label={field.label}
              htmlFor={field.value}
              isRequired={!!field.rules.required}
            />
            <Controller
              name={field.value}
              control={control}
              rules={field.rules}
              render={({ onChange, value, onBlur }) => (
                <Textfield
                  label={field.label}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  type={field.type || 'text'}
                  testId={field.value}
                  id={field.value}
                  isDisabled={!!field.isDisabled}
                />
              )}
            />

            {_.get(errors, field.value) && (
              <ErrorMsg data-testid={'error-msg'}>
                {_.get(errors, field.value).message}
              </ErrorMsg>
            )}
            {field.helperMessage && (
              <HelperMessage>{field.helperMessage}</HelperMessage>
            )}
          </GridColumn>
        ))}
      </Grid>
    </Page>
  );
};

export default RenderForm;
