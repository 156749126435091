import React from 'react';
import { useForm } from 'react-hook-form';
import iContactCompanyProduct from '../../../types/contactCompany/iContactCompanyProduct';
import iProduct from '../../../types/product/iProduct';
import CCPList from '../CCPList';
import ProductsCustomerContext from '../ProductCustomerContext';
import CustomizeModal from '../../modal/CustomizeModal';
import PlusIconAfterTitle from '../../../components/settings/PlusIconAfterTitle';
import DeleteByRetype from '../../deleteWithReType/DeleteByReType';
import useListCrudHook from '../../hooks/useListCrudHook/useListCrudHook';
import CustomizePagination from '../../pagination/CustomizePagination';
import CCPServiceProviderModal from './CCPServiceProviderModal';
import { filterColumns } from '../TableHelper';
import { SpaceVerticalContainer, FlexContainer } from '../../styles/styles';
import { CONTCOMP_FIXED_FILTER, PRODUCT_FIXED_FILTER } from '../CCPProductCustomer/ProductCustomer.constants';
import { PRODUCTS_SERVICE_PROVIDER } from './ServiceProvider.constants';
import {
  getContactCompanyProducts,
  createContactCompanyProduct,
  updateContactCompanyProduct,
  deleteContactCompanyProduct,
} from '../../../services/ContactCompanyProductService';

type iCCPServiceProvider = {
  startFieldId: string;
  fixedProduct?: iProduct;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
};
const CCPServiceProvider = ({
  startFieldId,
  fixedProduct,
  canCreate = true,
  canUpdate = true,
  canDelete = true,
}: iCCPServiceProvider) => {
  const contCompType = 'ServiceProvider';
  const contCompWording = 'service provider';

  const filter = fixedProduct
    ? `productId:${startFieldId},contactCompany.type.name:${contCompType}`
    : `contactCompanyId:${startFieldId},contactCompany.type.name:${contCompType}`;

  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenDeleteModal,
    onOpenAddModal,
    onOpenEditModal,
    onSubmit,
    onSetCurrentPage,
  } = useListCrudHook<iContactCompanyProduct>({
    getFn: getContactCompanyProducts,
    createFn: createContactCompanyProduct,
    updateFn: updateContactCompanyProduct,
    deleteFn: deleteContactCompanyProduct,
    filter,
    sort: 'updatedAt:DESC',
    notRenderWithoutFilter: true,
    paginationApplied: true,
  });

  const { control, setValue, errors, handleSubmit, getValues, watch } = useForm();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmitMiddle = async (data: any) => {
    //    the place contactCompanyId from
    //    eslint-disable-next-line
    const contactCompanyId = fixedProduct
      ? data.serviceProviderId
      : startFieldId;

    onSubmit({
      contactCompanyId,
      code: data.code,
      unitPrice: data.unitPrice,
      unitConversion: data.unitConversion,
      measurementId: data.measurement.id,
      productId: fixedProduct ? startFieldId : data.product.id,
      unitPriceMeasurementId: data.unitPriceMeasurementId,
      serviceTypeId: data.serviceType.id,
      instructions: data.instructions,
    });
  };

  const columns = filterColumns(PRODUCTS_SERVICE_PROVIDER, fixedProduct ? PRODUCT_FIXED_FILTER : CONTCOMP_FIXED_FILTER);

  /**
   * isCustomer validate customerId
   * !isCustomer or is supplier validate code and supplierId
   * not redundant create not errors not value
   */
  const formValidate = () => {
    const commonValidate =
      Object.keys(errors).length > 0 ||
      !getValues().unitPrice ||
      !getValues().unitConversion ||
      !getValues().measurement;

    if (fixedProduct) {
      return commonValidate || !getValues().serviceProviderId;
    }
    return commonValidate || !getValues().product;
  };

  const getModalHeading = () => {
    if (fixedProduct) {
      return edit.target ? edit.target.contactCompany.name : `Please select a ${contCompWording} to create`;
    }
    return edit.target ? edit.target.product.productCode : 'Please select a product to create';
  };

  const getPageTitle = () => {
    const entityName = fixedProduct ? contCompWording : 'product';
    const forEntityName = fixedProduct ? 'product' : contCompWording;
    return `${state.total} ${entityName}(s) for this ${forEntityName}`;
  };

  return (
    <ProductsCustomerContext>
      <div data-testid={'products-wrapper'}>
        <SpaceVerticalContainer className={'half'} />
        <FlexContainer className={'space-between'}>
          <PlusIconAfterTitle
            pageTitle={getPageTitle()}
            onClick={onOpenAddModal}
            titleSize={'small'}
            isDisabled={!canCreate}
          />
        </FlexContainer>
        <SpaceVerticalContainer className={'half'} />
        <CCPList
          list={state.data}
          onOpenDeleteModal={canDelete ? onOpenDeleteModal : () => null}
          onOpenEditModal={canUpdate ? onOpenEditModal : () => null}
          isLoading={state.isLoading}
          columns={columns.filter(
            col => [...(canUpdate ? [] : ['instructions']), ...(canDelete ? [] : ['delete'])].indexOf(col.key) < 0,
          )}
          fixedProduct={!!fixedProduct}
          isCustomer={false}
        />
        <CustomizeModal
          isOpen={edit.isModalOpen && !edit.delTargetId}
          onConfirm={handleSubmit(onSubmitMiddle)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={formValidate()}
          modalBody={
            <CCPServiceProviderModal
              control={control}
              errors={errors}
              onChange={setValue}
              watch={watch}
              target={edit.target}
              fixedProduct={fixedProduct}
            />
          }
          modalHeading={getModalHeading()}
          confirmBtnName={'Save'}
        />
        {edit.delTargetId && (
          <DeleteByRetype
            isOpen={!!edit.delTargetId}
            answer={fixedProduct ? edit.target?.contactCompany.name : edit.target?.product.productCode}
            onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
            onClose={onCloseModal}
          />
        )}
        {!!state.total && !!state.perPage && !!state.currentPage && (
          <CustomizePagination
            range={Math.ceil(state.total / state.perPage)}
            currentPage={state.currentPage}
            onChange={onSetCurrentPage}
          />
        )}
      </div>
    </ProductsCustomerContext>
  );
};

export default CCPServiceProvider;
