import React from 'react';
import Textfield from '@atlaskit/textfield';
import styled from 'styled-components';
import { fontSize, gridSize, fontFamily } from '@atlaskit/theme/constants';

export type textfieldProps = {
  placeholder?: string;
  label?: React.ReactNode;
  helper?: React.ReactNode;
  name: string;
  onChange?: (newValue: string) => void;
  defaultValue?: string;
  type?: string;
  appStyle?: 'default' | 'danger' | 'success' | 'warning';
  isDisabled?: boolean;
  onPrecheck?: (e: React.FocusEvent<HTMLInputElement>) => void;
  testId?: string;
};
const TextFieldWrapper = styled.div`
  &.danger {
    .helper {
      color: #de350b;
    }
  }
  .label {
    font-size: ${fontSize() / 17}rem;
    margin: ${gridSize() / 2}px 0;
    display: block;
    font-style: inherit;
    line-height: 1.3333333333333333;
    color: #6b778c;
    font-weight: 600;
    font-family: ${fontFamily()};
  }
  .helper {
    font-size: ${fontSize() / 18}rem;
    padding: ${gridSize() / 2}px;
  }
`;
export default ({
  defaultValue,
  placeholder,
  label,
  helper,
  name,
  type,
  appStyle,
  onChange,
  isDisabled = false,
  onPrecheck,
  testId,
}: textfieldProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return;
    }
    onChange(e.target.value);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onPrecheck !== 'function') return;
    onPrecheck(e);
  };

  return (
    <TextFieldWrapper className={appStyle || 'default'}>
      {label ? (
        <label className={'label'} htmlFor={name}>
          {label}
        </label>
      ) : null}
      <Textfield
        value={defaultValue}
        type={type}
        placeholder={placeholder}
        testId={testId || name}
        onChange={handleOnChange}
        isDisabled={isDisabled}
        onBlur={handleOnBlur}
      />
      {helper ? <div className={'helper'}>{helper}</div> : null}
    </TextFieldWrapper>
  );
};
