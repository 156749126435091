import React, { useState } from 'react';
import styled from 'styled-components';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Grid, GridColumn } from '@atlaskit/page';
import iContactCompany from '../../../types/contactCompany/iContactCompany';
import ContCompanyKeyInfo from '../../asyncContactCompany/ContCompanyKeyInfo';
import Collapse from '../../collapse/Collapse';
import AsyncCreatableWithController from '../../form/hookForms/AsyncCreatableWithController';
import CustomizedLabel from '../../form/hookForms/CustomizedLabel';
import { getServiceProviderListAsyncSearch } from '../../../services/ContactCompanyService';
import { mapLabelValuePairObjPayload } from '../../../pages/sales/utilities';

const Wrapper = styled.div`
  .collapse-button {
    padding-left: 0px;
  }
`;
const ServiceProviderKeyInfo = ({
  control,
  errors,
  onChange,
  defaultValue,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  defaultValue?: iContactCompany;
}) => {
  const collapseText = 'Service Provider Detail';
  const [contactCompany, setContactCompany] = useState<
    iContactCompany | undefined
  >(defaultValue);

  //    eslint-disable-next-line
  const onChangeMiddleWare = (name: string, value: any, config?: Object) => {
    setContactCompany(value || undefined);
    onChange(name, value?.id || null, config);
  };

  //  eslint-disable-next-line
  const onUpdateContComp = (name: string, value: any) => {
    if (typeof contactCompany === 'undefined') return;
    setContactCompany({ ...contactCompany, [name]: value });
  };

  return (
    <>
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <AsyncCreatableWithController
            label={'Service Provider'}
            name={'serviceProviderId'}
            control={control}
            onChange={onChangeMiddleWare}
            errors={errors}
            promiseFn={getServiceProviderListAsyncSearch}
            optionLabel={'name'}
            isRequired
            defaultValue={
              defaultValue
                ? mapLabelValuePairObjPayload([defaultValue], 'name')[0]
                : undefined
            }
            isCreatable={false}
          />
        </GridColumn>
      </Grid>

      {contactCompany && (
        <Grid spacing={'compact'}>
          <GridColumn medium={12}>
            <Wrapper>
              <Collapse
                text={<CustomizedLabel label={collapseText} />}
                defaultOpen={false}
                iconSize={'small'}
              >
                <ContCompanyKeyInfo
                  contComp={contactCompany}
                  isShowShippingAddress
                  onUpdateContComp={onUpdateContComp}
                />
              </Collapse>
            </Wrapper>
          </GridColumn>
        </Grid>
      )}
    </>
  );
};

export default ServiceProviderKeyInfo;
