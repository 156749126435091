import AppService from '../AppService';

export const updateUserProfile = (name: string, newValue: string) => {
  return AppService.put('/auth/profile', { [name]: newValue }).then(({ data }) => {
    return data;
    // return { user: data.user, isAuthenticated: true };
  });
};

export default {
  updateUserProfile,
};
