import React, { useState } from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';
import { useSelector } from 'react-redux';
import { iSortOrderType } from '../../../../shared/UITypes/types';
import iSalesOrderDetail from '../../../../types/sales/iSalesOrder';
import iEntityCategory from '../../../../types/status/iEntityCategory';
import { iConfigColumn } from '../../../despatchNote/shared/DispatchNote.type';
import { getHeads, getRows } from './TableHelper';
import { SO_TABLE_NAME, SO_TABLE_TEST_ID } from '../../constants';
import { RootState } from '../../../../redux/makeReduxStore';

const TableWrapper = styled.div`
  th[data-testid='${SO_TABLE_NAME}-column-type--head--cell'] {
    width: 3rem;
    min-width: 3rem;
  }

  th[data-testid='${SO_TABLE_NAME}-column-orderNumber--head--cell'] {
    width: 8rem;
    min-width: 8rem;
  }

  th[data-testid='${SO_TABLE_NAME}-column-totalAmt--head--cell'],
  th[data-testid='${SO_TABLE_NAME}-column-totalAmtDue--head--cell'] {
    width: 6rem;
    min-width: 6rem;
  }

  th[data-testid='${SO_TABLE_NAME}-column-shippingAddress--head--cell'],
  th[data-testid='${SO_TABLE_NAME}-column-billingAddress--head--cell'] {
    width: 20rem;
    min-width: 12rem;
  }

  th[data-testid='${SO_TABLE_NAME}-column-updatedAt--head--cell'],
  th[data-testid='${SO_TABLE_NAME}-column-createdAt--head--cell'] {
    width: 11rem;
    min-width: 11rem;
  }

  th[data-testid='${SO_TABLE_NAME}-column-operation--head--cell'] {
    width: 2rem;
    min-width: 2rem;
  }
`;

interface iSalesListTableProps {
  salesOrders: iSalesOrderDetail[];
  isLoading: boolean;
  onOpenDeleteModal: (id: string) => void;
  onSetSort: (sortStr: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<iConfigColumn> | any;
  categories: Array<iEntityCategory>;
}

const SalesListTable = ({
  salesOrders,
  isLoading,
  onOpenDeleteModal,
  onSetSort,
  columns,
  categories,
}: iSalesListTableProps) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(columns, SO_TABLE_NAME)}
        rows={getRows(salesOrders, onOpenDeleteModal, columns, categories, user)}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        onSort={
          //  eslint-disable-next-line
          (data: any) => {
            setSortKey(data.key);
            setSortOrder(data.sortOrder);
            onSetSort(`${data.key}:${data.sortOrder}`);
          }
        }
        sortKey={sortKey}
        sortOrder={sortOrder}
        testId={SO_TABLE_TEST_ID}
      />
    </TableWrapper>
  );
};

export default SalesListTable;
