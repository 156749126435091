import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iMachine from '../../../types/machine/iMachine';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import AsyncSearchWithController from '../../../shared/form/hookForms/AsyncSearchWithController';
import { getWarehouseListAsyncSearch } from '../../../services/WarehouseService';

const Wrapper = styled.div`
  min-height: 15rem;
`;
const MachineEditModal = ({
  control,
  errors,
  onChange,
  targetMachine,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  targetMachine?: iMachine;
}) => {
  return (
    <Wrapper>
      <Page>
        <Grid layout={'fluid'}>
          <GridColumn medium={6}>
            <InputWithController
              name={'name'}
              label={'Machine Name'}
              defaultValue={targetMachine?.name}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              testId={'settings-machine-modal-name'}
            />
            <AsyncSearchWithController
              name={'warehouseLocationId'}
              label={'Default Warehouse location'}
              defaultValue={targetMachine?.warehouseLocation}
              //  eslint-disable-next-line
              onChange={(name: string, value: any, config?: Object) =>
                onChange(name, _.get(value, 'id'), config)
              }
              errors={errors}
              control={control}
              optionLabel={['name', 'locationCode']}
              promiseFn={(keyword: string) =>
                getWarehouseListAsyncSearch({
                  like: `name:${keyword},locationCode:${keyword}`,
                })
              }
              isRequired
            />
          </GridColumn>
        </Grid>
      </Page>
    </Wrapper>
  );
};

export default MachineEditModal;
