import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iJob from '../../../../types/job/iJob';
import { getHeads } from './Heads';
import { getRows } from './Rows';
import iProductAttribute from '../../../../types/product/iProductAttribute';

const JobListTable = ({
  jobList,
  isLoading,
  onOpenDeleteJobModal,
  columns,
  prodAttrs,
}: {
  jobList: Array<iJob>;
  isLoading: boolean;
  onOpenDeleteJobModal: (job: iJob) => void;
  columns: Array<string>;
  prodAttrs: Array<iProductAttribute>;
}) => {
  return (
    <DynamicTable
      testId={'jobs-tbl'}
      caption={''}
      head={getHeads(columns)}
      rows={getRows(jobList, onOpenDeleteJobModal, columns, prodAttrs)}
      isLoading={isLoading}
      loadingSpinnerSize={'large'}
    />
  );
};
export default JobListTable;
