import AppService from './AppService';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';

export const getCompanyAddressList = (config?: iParams) => {
  return AppService.get('/companyAddress', {
    include: 'createdBy,updatedBy,address',
    ...config,
  }).then(({ data }) => data);
};

export const deactivateCompanyAddress = (id: string) => {
  return AppService.delete(`/companyAddress/${id}`).then(({ data }) => data);
};

export const createCompanyAddress = (postData: iKeyValuePairs) => {
  return AppService.post('/companyAddress', postData).then(({ data }) => data);
};

export const updateCompanyAddress = (
  id: string,
  updateContent: iKeyValuePairs,
) => {
  return AppService.put(`/companyAddress/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const getCompanyAddress = (id: string, config?: iParams) => {
  return AppService.get(`/companyAddress/${id}`, {
    include: 'createdBy,updatedBy,address',
    ...config,
  }).then(({ data }) => data);
};

// export const getCompanyAddressListAsyncSearch = (config?: iParams) => {
//   return AppService.get('/companyAddress', {
//     include: 'measurement',
//     ...config,
//     perPage: ASYNC_SEARCH_PER_PAGE,
//     currentPage: ASYNC_SEARCH_CURRENT_PAGE,
//   }).then(({ data }) => data);
// };

export default {
  getCompanyAddressList,
  deactivateCompanyAddress,
  createCompanyAddress,
  updateCompanyAddress,
  getCompanyAddress,
  // getCompanyAddressListAsyncSearch,
};
