import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';

import InlineEdit from '../../../../../../shared/form/InlineEdit';
import iBomItem from '../../../../../../types/product/iBomItem';
import iBomItemAttribute from '../../../../../../types/product/iBomItemAttribute';
import iBomItemAttributeValue from '../../../../../../types/product/iBomItemAttributeValue';
import { getDeleteBtn } from '../../../../../settings/utils';
import { iBomItemAttributeValueParams } from './ProdBom.type';
import {
  currencyFormat,
  handleNullException,
  numberToPercentageWithoutSuffix,
  percentageToNumber,
} from '../../../../../../services/UtilsService';
import {
  getBomItemAttrHeads,
  getHeads,
  getOrderType,
  getSortKey,
} from '../../../../../../shared/job/jobDetails/bomItems/JobBom.helper';
import { iSortOrderType } from '../../../../../../shared/UITypes/types';
import ComposeSecondaryText from '../../../../../../shared/text/ComposeSecondaryText';
import LinkBtnUrl from '../../../../../../shared/buttons/LinkBtnUrl';
import { PRODUCTS_URL } from '../../../../../../shared/UrlMap';
import { BOM_ATTRIBUTE_HOPPER } from '../../../../../../shared/job/constants';
import MathHelper from '../../../../../../services/MathHelper';

const BomListTableWrapper = styled.div``;

const InlineEditContainer = styled.div`
  padding-bottom: 0.5rem;
`;
const BEFORE_COLUMNS = ['Sku / Product Code'];
const AFTER_COLUMNS = ['Amount', 'Est. Cost', 'Operation'];

const BomListTable = ({
  data,
  onDelete,
  onConfirmBomItem,
  onConfirmBomAttributeValue,
  isLoading,
  bomItemAttrCluster,
  canDelete = true,
  canUpdate = true,
}: {
  data: Array<iBomItem>;
  onDelete: (id: string) => void;
  onConfirmBomItem: (name: string, newValue: string) => void;
  onConfirmBomAttributeValue: (params: iBomItemAttributeValueParams) => void;
  isLoading: boolean;
  bomItemAttrCluster: Array<iBomItemAttribute>;
  canUpdate?: boolean;
  canDelete?: boolean;
}) => {
  const columns = [...BEFORE_COLUMNS, ...getBomItemAttrHeads(bomItemAttrCluster), ...AFTER_COLUMNS];

  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  const getBomItemAttrContent = (
    bomItemId: string,
    bomItemAttr: iBomItemAttribute,
    bomItemAttrValue?: iBomItemAttributeValue,
  ) => {
    const isHopper = bomItemAttr.name.toUpperCase() === BOM_ATTRIBUTE_HOPPER;
    const defaultPercentageValue = numberToPercentageWithoutSuffix(bomItemAttrValue?.value, 2);
    return (
      <InlineEditContainer>
        <InlineEdit
          name={`${bomItemId}-${bomItemAttr.id}`}
          defaultValue={isHopper ? handleNullException(bomItemAttrValue, 'value') : defaultPercentageValue}
          label={''}
          onConfirm={(name: string, value: string) =>
            onConfirmBomAttributeValue({
              bomItemId,
              bomItemAttributeId: bomItemAttr.id,
              bomItemAttributeValueId: bomItemAttrValue?.id || '',
              // value: isHopper ? value : Number(percentageToNumber(value, 4)),
              // eslint-disable-next-line no-nested-ternary
              value: !isHopper
                ? Number(percentageToNumber(value, 4)) === 0
                  ? ''
                  : Number(percentageToNumber(value, 4))
                : value,
            })
          }
          hideActionButtons
          postfix={isHopper ? undefined : '%'}
          isNumeric={!isHopper}
          isDisabled={!canUpdate}
          // isZeroNull
        />
      </InlineEditContainer>
    );
  };

  const getBomItemAttrRows = (item: iBomItem) =>
    bomItemAttrCluster.map((attr: iBomItemAttribute) => {
      const bomItemAttrValue = item.bomItemAttributes.find((b: iBomItemAttribute) => b.id === attr.id)
        ?.bomItemAttributeValues;
      return {
        key: handleNullException(bomItemAttrValue, 'value'),
        content: getBomItemAttrContent(item.id, attr, bomItemAttrValue),
      };
    });

  const getRows = () => {
    return data
      ?.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
      .map((item: iBomItem) => ({
        cells: [
          {
            key: handleNullException(item, 'material.productCode'),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, 'material.name')}>
                <LinkBtnUrl
                  btnName={handleNullException(item, 'material.productCode')}
                  url={`${PRODUCTS_URL}/${item.materialId}`}
                />
              </ComposeSecondaryText>
            ),
          },
          ...getBomItemAttrRows(item),
          {
            key: handleNullException(item, 'materialQty'),
            content: (
              <InlineEditContainer>
                <InlineEdit
                  name={`${item.id}:materialQty`}
                  defaultValue={`${item.materialQty}`}
                  label={''}
                  onConfirm={onConfirmBomItem}
                  postfix={handleNullException(item.material, 'measurement.shortName')}
                  needFormatValue
                  hideActionButtons
                  isNumeric
                  isRequired
                  min={{ minValue: 0 }}
                  isDisabled={!canUpdate}
                />
              </InlineEditContainer>
            ),
          },
          {
            key: MathHelper.mul(item.materialQty, item.material.averageCost),
            content: currencyFormat(item.materialQty * item.material.averageCost),
          },
          {
            content: canDelete
              ? getDeleteBtn({
                  id: item.id,
                  answer: item.material.productCode || 'sku n/a',
                  onClick: onDelete,
                })
              : null,
          },
        ],
      }));
  };

  return (
    <BomListTableWrapper>
      <DynamicTable
        head={getHeads(columns, 'product-BOM')}
        rows={getRows()}
        testId={'product-BOM-list-table'}
        isLoading={isLoading}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSort={(sortData: any) => {
          setSortKey(sortData.key);
          setSortOrder(sortData.sortOrder);
          localStorage.setItem('sortKey', sortData.key);
          localStorage.setItem('sortOrder', sortData.sortOrder);
        }}
        sortOrder={sortOrder}
        sortKey={sortKey}
        defaultSortOrder={getOrderType(localStorage.getItem('sortOrder'))}
        defaultSortKey={getSortKey(localStorage.getItem('sortKey'))}
      />
    </BomListTableWrapper>
  );
};

export default BomListTable;
