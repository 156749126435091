import { iKeyValuePairs } from '../shared/UITypes/types';
import AppService from './AppService';

const endPoint = '/address';

const getAddresses = (params = {}) => {
  return AppService.get(endPoint, params).then(({ data }) => data);
};

const getAddress = (id: string, params = {}) => {
  return AppService.get(`${endPoint}/${id}`, params).then(({ data }) => data);
};

const createAddress = (content: iKeyValuePairs) => {
  return AppService.post(endPoint, content).then(({ data }) => data);
};

const updateAddress = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`${endPoint}/${id}`, updateContent).then(({ data }) => data);
};

const deleteAddress = (id: string, params = {}) => {
  return AppService.delete(`${endPoint}/${id}`, params).then(({ data }) => data);
};

export default {
  getAddresses,
  createAddress,
  editAddress: updateAddress,
  getAddressDetail: getAddress,
  deleteAddress,
};
