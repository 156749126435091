export const MUST_COLUMNS_BEFORE = ['PO No.'];
export const MUST_COLUMNS_AFTER = ['Operation'];
export const FLEXIBLE_COLUMNS = [
  'Status',
  'Supplier',
  'Reference',
  'Unit Price',
  'Qty',
  'Received Qty',
  'Order Date',
  'ETA',
  'Created',
  'Updated',
];
export const COLUMNS = [...MUST_COLUMNS_BEFORE, ...FLEXIBLE_COLUMNS];
export const COLUMN_NAME = 'product-po-columns';
