import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import SectionMessage from '@atlaskit/section-message';
import Button from '@atlaskit/button';
import { iChannelCreationCallBackParams } from '../../../integrations/ChannelScriptHelperTypes';
import ChannelService from '../../../../services/integration/ChannelService';
import { apiErrorToast } from '../../../toast/Toast';

const WooCommerceChannelSetupCallback = (params: iChannelCreationCallBackParams) => {
  const { channel } = params;
  const [isChecking, setIsChecking] = useState(true);
  const [isOnboarded, setIsOnBoarded] = useState(
    `${channel.settings.auth?.consumer_key || ''}`.trim() !== '' &&
      `${channel.settings.auth?.consumer_secret || ''}`.trim() !== '',
  );

  useEffect(() => {
    if (isOnboarded === true) {
      setIsChecking(false);
      return;
    }

    let isCanceled = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timer: any;
    const start = (triedTimes = 0) => {
      return setTimeout(() => {
        if (triedTimes > 10) {
          clearTimeout(timer);
          setIsChecking(false);
          return;
        }
        ChannelService.getChannel(channel.id)
          .then(resp => {
            if (isCanceled) return;
            const onBoarded =
              `${resp.settings.auth?.consumer_key || ''}`.trim() !== '' &&
              `${resp.settings.auth?.consumer_secret || ''}`.trim() !== '';
            if (onBoarded) {
              setIsOnBoarded(onBoarded);
              setIsChecking(false);
            } else {
              timer = start(triedTimes + 1);
            }
          })
          .catch(error => {
            if (isCanceled) return;
            apiErrorToast(error);
          });
      }, 1000);
    };

    timer = start();
    // eslint-disable-next-line consistent-return
    return () => {
      isCanceled = true;
      clearTimeout(timer);
    };
  }, [channel, isOnboarded]);

  const getContent = () => {
    if (isChecking) {
      return <Spinner testId={'loading'} />;
    }
    if (!isOnboarded) {
      return (
        <SectionMessage appearance={'warning'} title={'Error Occurred'}>
          <div>Error Occurred during connecting, please try again by click the button below</div>
        </SectionMessage>
      );
    }
    return (
      <SectionMessage appearance={'info'} title={'Connected Successfully'}>
        <p>
          <b>{channel.name}</b> connected successfully
        </p>
        <Button appearance={'primary'} href={`/b/settings/channel/${channel.id}`}>
          Continue
        </Button>
      </SectionMessage>
    );
  };

  return <div className={'MyobChannelSetupCallback'}>{getContent()}</div>;
};

export default WooCommerceChannelSetupCallback;
