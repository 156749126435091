import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Label } from '@atlaskit/field-base';
import { Grid, GridColumn } from '@atlaskit/page';

import SectionMessage from '@atlaskit/section-message';
import Textfield from '@atlaskit/textfield';
import { LabelValue } from '../../../../../../shared/styles/styles';
import { getProductListAsyncSearch } from '../../../../../../services/product/ProductService';
import AsyncSearchWithController from '../../../../../../shared/form/hookForms/AsyncSearchWithController';
import { apiErrorToast } from '../../../../../../shared/toast/Toast';
import iProduct from '../../../../../../types/product/iProduct';
import { handleNullException } from '../../../../../../services/UtilsService';
import { getProdAttributeValues } from '../../../../../../services/product/ProductAttributeValueService';
import iProductAttributeValue from '../../../../../../types/product/iProductAttributeValue';
import { getProdAttributes } from '../../../../../../services/product/ProductAttributeService';
import iProductAttribute from '../../../../../../types/product/iProductAttribute';

const AsyncSearchWrapper = styled.div`
  width: 90%;
  min-width: 15rem;
  min-height: 20rem;
  overflow-y: hidden;
  margin: 1rem auto;

  & .copy-from-label {
    margin-bottom: 0.5rem;
  }
`;

const RetypeWrapper = styled.div`
  margin-top: 2rem;
  .soft-copy-confirm-info {
    display: block;
    margin: 4px 0px 8px;
  }
`;

const SourceAttributeListScrollable = styled.div`
  width: 90%;
  height: 12rem;
  overflow-y: auto;
  margin-top: 2rem;
  border: 1px solid grey;
  padding: 1rem;
`;

const CopyAttributeValueModal = ({
  control,
  errors,
  onChange,
  watch,
  onCheckRetypeAnswer,
  targetProduct,
}: {
  //    eslint-disable-next-line
    control: Control<Record<string, any>>;
  //    eslint-disable-next-line
    onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
    errors?: DeepMap<Record<string, any>, FieldError>;
  //    eslint-disable-next-line
    watch: (names?: string | string[]) => any;
  onCheckRetypeAnswer: (isRightAnswer: boolean) => void;
  targetProduct?: iProduct;
}) => {
  const sourceProductId = watch('sourceProductId');
  const [retypeValue, setRetypeValue] = useState('');
  const [sourceProduct, setSourceProduct] = useState<iProduct>();
  const [sourceAttributes, setSourceAttributes] = useState<iProductAttribute[]>([]);
  const [sourceAttributeValues, setSourceAttributeValues] = useState<iProductAttributeValue[]>([]);
  const [shouldDisplaySourceInfo, setShouldDisplaySourceInfo] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (!sourceProductId) {
        setShouldDisplaySourceInfo(false);
        return;
      }
      try {
        const attributes = await getProdAttributes({
          filter: `attributeSetId:${sourceProduct?.attributeSetId}`,
        });
        const attributeValues = await getProdAttributeValues({
          filter: `productId:${sourceProductId}`,
        });
        setShouldDisplaySourceInfo(true);
        setSourceAttributes(attributes);
        setSourceAttributeValues(attributeValues);
      } catch (e) {
        apiErrorToast(e);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceProductId, JSON.stringify(sourceProduct)]);

  const onSelectSourceProduct = (
    name: string,
    value: iProduct,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: any,
  ) => {
    if (!value) {
      setShouldDisplaySourceInfo(false);
      return;
    }
    setSourceProduct(value);
    onChange(name, value.id, config);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRetypeInputChange = (event: any) => {
    setRetypeValue(event.target.value);
    onCheckRetypeAnswer(event.target.value.trim() === handleNullException(sourceProduct, 'productCode'));
  };

  const dict = sourceAttributeValues.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acc: any, attrValue: iProductAttributeValue) => ({
      ...acc,
      [attrValue.attributeId]: {
        id: attrValue.id,
        value: attrValue.value,
        prefix: attrValue.prefix,
        postfix: attrValue.postfix,
      },
    }),
    {},
  );

  return (
    <>
      <AsyncSearchWrapper>
        <div className={'copy-from-label'}>Copy attribute values from another product</div>
        <AsyncSearchWithController
          name={'sourceProductId'}
          label={'Source Product'}
          onChange={onSelectSourceProduct}
          promiseFn={(keyword: string) =>
            getProductListAsyncSearch({
              like: `productCode:${keyword},name:${keyword}`,
            })
          }
          optionLabel={['productCode', 'name']}
          control={control}
          errors={errors}
          isRequired={false}
          isClearable
          testId={'product-create-modal-templates'}
        />

        {shouldDisplaySourceInfo ? (
          <>
            <Grid layout={'fluid'}>
              <GridColumn medium={6}>
                <Label label="SKU" />
                <LabelValue>{handleNullException(sourceProduct, 'productCode')}</LabelValue>
              </GridColumn>
              <GridColumn medium={6}>
                <Label label="Name" />
                <LabelValue>{handleNullException(sourceProduct, 'name')}</LabelValue>
              </GridColumn>
            </Grid>

            <SourceAttributeListScrollable>
              <Grid layout={'fluid'}>
                {sourceAttributes
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((attribute: iProductAttribute) => (
                    <GridColumn medium={3} key={attribute?.id}>
                      <Label label={handleNullException(attribute, 'name')} />
                      <LabelValue>{handleNullException(dict[attribute.id], 'value')}</LabelValue>
                    </GridColumn>
                  ))}
              </Grid>
            </SourceAttributeListScrollable>

            {handleNullException(targetProduct, 'attributeSetId') ===
              handleNullException(sourceProduct, 'attributeSetId') && (
              <RetypeWrapper className={'copyFrom-retype-wrapper'}>
                <span className={'soft-copy-confirm-info'}>
                  Before you copy it, please type{' '}
                  <strong>{handleNullException(sourceProduct, 'productCode') || 'n/a'}</strong> to confirm
                </span>
                <Textfield
                  value={retypeValue}
                  onChange={onRetypeInputChange}
                  isCompact
                  testId={'textfield-enter-answer'}
                />
              </RetypeWrapper>
            )}

            {/* check if attribute set matches */}
            <div style={{ marginTop: '1rem' }}>
              {handleNullException(targetProduct, 'attributeSetId') ===
              handleNullException(sourceProduct, 'attributeSetId') ? (
                <SectionMessage appearance="warning">
                  <p>
                    By confirm the copying action, your current attribute values will be replaced by those from selected
                    product.
                  </p>
                </SectionMessage>
              ) : (
                <SectionMessage appearance="error">
                  <p>Cannot clone attribute values from the source product as it has a different attribute set.</p>
                </SectionMessage>
              )}
            </div>
          </>
        ) : null}
      </AsyncSearchWrapper>
    </>
  );
};

export default CopyAttributeValueModal;
