import { iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

export const getPalletSystems = (config?: iParams) => {
  return AppService.get('/palletSystem', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const deactivatePallet = (id: string) => {
  return AppService.delete(`/palletSystem/${id}`).then(({ data }) => data);
};

export const createPallet = (postData: iParams) => {
  return AppService.post('/palletSystem', postData).then(({ data }) => data);
};

export const updatePallet = (id: string, updateContent: iParams) => {
  return AppService.put(`/palletSystem/${id}`, updateContent).then(({ data }) => data);
};

export default {
  getPalletSystems,
  deactivatePallet,
  createPallet,
  updatePallet,
};
