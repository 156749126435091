import React, { useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { removedAuthentication } from '../../redux/reduxers/auth.slice';
import { AUTH_URL_LOGIN } from '../UrlMap';
import { logout } from '../../services/AuthService';

const LogoutBtnWrapper = styled.div``;
const initialState = {
  confirming: false,
  loggingOut: false,
};
export default ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClicked = () => {
    setState({
      ...state,
      confirming: true,
    });
  };

  const close = () => {
    setState({
      ...state,
      confirming: false,
    });
  };

  const doLogout = () => {
    setState({
      ...state,
      loggingOut: true,
    });
    logout().then(() => {
      dispatch(removedAuthentication());
      history.push(AUTH_URL_LOGIN);
    });
  };

  const getModalContent = () => {
    if (state.confirming !== true) return null;
    return (
      <Modal
        actions={[
          { text: 'Confirm', onClick: doLogout, testId: 'confirm-button' },
          { text: 'Cancel', testId: 'cancel-button', onClick: close },
        ]}
        onClose={close}
        heading="Are you sure to logout?"
        testId="logout-modal-box"
      >
        <p>Please confirm you are about to logout.</p>
      </Modal>
    );
  };

  const getBtn = () => {
    if (state.loggingOut !== true) {
      return (
        <LogoutBtnWrapper onClick={handleClicked} data-testid={'logout-btn'}>
          {children}
        </LogoutBtnWrapper>
      );
    }
    return <Spinner />;
  };

  return (
    <>
      {getBtn()}
      <ModalTransition>{getModalContent()}</ModalTransition>
    </>
  );
};
