import { iConfigColumn } from '../../UITypes/types';

export const PRODUCTS_SERVICE_PROVIDER: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    // dataSource: ['id'],
    dataSource: ['product.name'],
    isSortable: true,
    type: 'edit-trigger-seconday',
    isSelected: true,
    isCompulsory: true,
    group: '',
  },
  {
    name: 'Company',
    key: 'contactCompany.name',
    dataSource: ['id'],
    isSortable: true,
    type: 'edit-trigger',
    isSelected: true,
  },
  {
    name: 'Service Type',
    key: 'serviceType.name',
    dataSource: [''],
    type: 'text',
    isSelected: true,
  },
  {
    name: 'Unit Price',
    key: 'unitPrice',
    dataSource: ['unitPriceMeasurement.shortName', 'product.measurement.shortName'],
    type: 'money-unit',
    isSelected: true,
    group: '',
  },
  {
    name: 'Service Provider Unit',
    key: 'measurement.shortName',
    dataSource: ['product.measurement.shortName'],
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Conversion',
    key: 'unitConversion',
    dataSource: ['measurement.shortName', 'product.measurement.shortName'],
    type: 'conversion-unit',
    isSelected: true,
    group: '',
  },
  {
    name: '',
    key: 'instructions',
    dataSource: [],
    type: 'instructions-edit',
    isCompulsory: true,
  },
  {
    name: '',
    key: 'delete',
    dataSource: ['id'],
    type: 'delete',
    isCompulsory: true,
  },
];
