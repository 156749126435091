import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Spinner from '@atlaskit/spinner';

import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import PlusIconAfterTitle from '../../../components/settings/PlusIconAfterTitle';
import BackendPage from '../../../layout/BackendPage';
import Sidebar from '../sidebar/Sidebar';
import {
  createTaxRate,
  deleteTaxRate,
  getTaxBases,
  getTaxRates,
  updateTaxRate,
} from '../../../services/Settings/TaxService';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import { iTaxRate } from '../../../types/iTaxRate';
import TaxRateEditModal from './TaxRateEditModal';
import TaxRateListTable from './TaxRateListTable';
import { createOption, createOptions, objectContainsInvalidValues } from '../../sales/utilities';
import SelectSingle from '../../../shared/form/SingleSelect';
import { RootState } from '../../../redux/makeReduxStore';
import { addToastForAPIResponse } from '../../../shared/toast/Toast';
import CompanyProfileService from '../../../services/Settings/CompanyProfileService';
import { SpinnerWrapper } from '../../../shared/styles/styles';

const NotesHeading = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: #6c798f;
`;

const TaxBaseWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
const TaxRateWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;

  width: 45%;
  float: right;
  margin: -0.4rem auto 0;

  @media screen and (max-width: 1440px) {
    display: flex;
    flex-direction: column;

    width: 50%;
    float: left;
    margin-top: -0.4rem;
  }
`;

const InlineEditContainer = styled.div`
  width: 14rem;
  min-width: 14rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
`;

const TableContainer = styled.div`
  display: inline-block;
  width: 50%;

  td:nth-child(n + 3):nth-child(-n + 4) {
    width: 15%;
    min-width: 10rem;
  }

  td:last-child {
    max-width: 2rem;
  }

  @media screen and (max-width: 1440px) {
    width: 80%;
  }
`;

const TaxRateSetting = () => {
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onSubmit,
    onRefresh,
  } = useListCrudHook<iTaxRate>({
    getFn: getTaxRates,
    createFn: createTaxRate,
    updateFn: updateTaxRate,
    deleteFn: deleteTaxRate,
  });
  const { user } = useSelector((s: RootState) => s.auth);
  const [company, setCompany] = useState(user?.company);
  const [companyId, setCompanyId] = useState(company?.id || null);
  const [taxBases, setTaxBases] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [taxRates, setTaxRates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { control, setValue, errors, handleSubmit } = useForm();

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!mounted) return;
        if (!companyId) return;
        const companyData = await CompanyProfileService.getCompanyProfile(companyId);
        const taxBaseData = await getTaxBases();
        const taxRateData = await getTaxRates();

        if (mounted) {
          setCompany(companyData);
          setCompanyId(companyData.id);
          setTaxBases(taxBaseData);
          setTaxRates(taxRateData);
          setIsLoading(false);
        }
      } catch (e) {
        addToastForAPIResponse('error');
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [companyId]);

  const onSelectTax = async (name: string, newValue: string) => {
    try {
      await CompanyProfileService.updateCompanyProfile(name.split(':')[0], {
        [`${name.split(':')[1]}`]: newValue,
      });
      addToastForAPIResponse('success');
      onRefresh();
    } catch (e) {
      addToastForAPIResponse('error');
    }
  };

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'Tax rates'} />
      <PlusIconAfterTitle pageTitle={'Tax rates'} onClick={onOpenAddModal} />
    </>
  );

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <TableContainer>
        <TaxRateListTable
          taxRates={state.data}
          onDelete={onDelete}
          onEdit={onOpenEditModal}
          isLoading={state.isLoading}
        />
      </TableContainer>

      {isLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <RightSection>
          <TaxBaseWrapper>
            <InlineEditContainer className="tax-select">
              <NotesHeading>Default tax on Purchase Order</NotesHeading>
              <SelectSingle
                name={`${companyId}:poTaxBaseId`}
                defaultValue={createOption(company?.poTaxBase)}
                label={''}
                selectOptions={createOptions(taxBases)}
                onConfirm={onSelectTax}
              />
            </InlineEditContainer>

            <InlineEditContainer className="tax-select">
              <NotesHeading>Default tax on Sales Order</NotesHeading>
              <SelectSingle
                name={`${companyId}:soTaxBaseId`}
                defaultValue={createOption(company?.soTaxBase)}
                label={''}
                selectOptions={createOptions(taxBases)}
                onConfirm={onSelectTax}
              />
            </InlineEditContainer>
          </TaxBaseWrapper>

          <TaxRateWrapper>
            <InlineEditContainer className="tax-select">
              <NotesHeading>Default tax on Purchase Order Item</NotesHeading>
              <SelectSingle
                name={`${companyId}:poTaxRateId`}
                defaultValue={createOption(company?.poTaxRate)}
                label={''}
                selectOptions={createOptions(state.data)}
                onConfirm={onSelectTax}
              />
            </InlineEditContainer>

            <InlineEditContainer className="tax-select">
              <NotesHeading>Default tax on Sales Order Item</NotesHeading>
              <SelectSingle
                name={`${companyId}:soTaxRateId`}
                defaultValue={createOption(company?.soTaxRate)}
                label={''}
                selectOptions={createOptions(state.data)}
                onConfirm={onSelectTax}
              />
            </InlineEditContainer>
          </TaxRateWrapper>
        </RightSection>
      )}

      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0 || objectContainsInvalidValues(control.getValues())}
        modalBody={
          <TaxRateEditModal control={control} errors={errors} onChange={setValue} targetTaxRate={edit.target} />
        }
        modalHeading={edit.target ? edit.target.name : 'Create'}
        confirmBtnName={'Save'}
      />
    </BackendPage>
  );
};

export default TaxRateSetting;
