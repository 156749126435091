import React, { useState } from 'react';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import AfterTransferDescription from './AfterTransferDescription';
import TableToggle from './TableToggle';
import TransferProdsTable from '../sharedComponents/TransferProdsTable';
import { COLUMNS_EXCLUDE_DELETE } from '../sharedComponents/StockTransfer.constants';
import { iItemLocationTransfer } from '../sharedComponents/StockTransfer.type';
import { FlexContainer } from '../../../shared/styles/styles';

const AfterTransfer = ({
  destination,
  transferred,
  onRefresh,
}: {
  destination?: iWarehouseLocation;
  transferred: Array<iItemLocationTransfer>;
  onRefresh: () => void;
}) => {
  const [state, setState] = useState(true);
  //  eslint-disable-next-line
  const onOpenDeleteModal = (id: string) => {};
  if (!destination) return null;
  return (
    <>
      <AfterTransferDescription
        destination={destination}
        data={transferred}
        onRefresh={onRefresh}
      />
      <FlexContainer className={'space-below space-between'}>
        <TableToggle state={state} onClick={() => setState(!state)} />
        {/* <ExportCsv data={transferred} /> */}
      </FlexContainer>

      {state && (
        <TransferProdsTable
          data={transferred}
          onOpenDeleteModal={onOpenDeleteModal}
          columns={COLUMNS_EXCLUDE_DELETE}
        />
      )}
    </>
  );
};

export default AfterTransfer;
