import { iLabelValuePair } from '../../../../shared/UITypes/types';

export const FACILITY_ATTRIBUTES: Array<iLabelValuePair> = [
  { label: 'Goods inwards', value: 'defaultInwardsLocation' },
  { label: 'Goods outwards', value: 'defaultOutwardsLocation' },
  { label: 'Lost and found', value: 'lostAndFoundLocation' },
];
export const CREATE_FIELDS = [
  'address',
  ...FACILITY_ATTRIBUTES.map((a: iLabelValuePair) => a.value),
];
