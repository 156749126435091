import React from 'react';
import AtlaskitEmptyState, { EmptyStateProps } from '@atlaskit/empty-state';

type iEmptyState = EmptyStateProps;
const EmptyState = (props: iEmptyState) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AtlaskitEmptyState {...props} />;
};

export default EmptyState;
