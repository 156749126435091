import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import AddIcon from '@atlaskit/icon/glyph/add';
import { getHeads } from '../../../../services/UtilsService';
import { getRows } from '../../../tableHelper/SharedTableHelper';
import iSalesOrderItem from '../../../../types/sales/iSalesOrderItem';
import { RE_ASSOCIATE_COLUMNS, TABLE_NAME } from './ReAssociate.constants';
import { iConfigColumn } from '../../../UITypes/types';
import ElementWithPopup from '../../../contactCompany/list/table/ElementWithPopup';
import { AddIconWrapper } from '../styles/JobDetails.style';

const ReAssociateBody = ({
  salesOrderItems,
  onConfirmSOItem,
}: {
  salesOrderItems: Array<iSalesOrderItem>;
  onConfirmSOItem: (soItemId: string) => void;
}) => {
  const renderSpecialContent = (item: iSalesOrderItem, column: iConfigColumn) => {
    const { type } = column;
    switch (type) {
      case 'operation':
        return (
          <ElementWithPopup
            onClick={() => onConfirmSOItem(item.id)}
            popupHeading={'Are you sure to start the re-association?'}
          >
            <AddIconWrapper>
              <AddIcon label="buy" primaryColor={'#42526e'} testId={'add-icon'} />
            </AddIconWrapper>
          </ElementWithPopup>
        );
      default:
        return <div />;
    }
  };

  return (
    <DynamicTable
      head={getHeads(RE_ASSOCIATE_COLUMNS, TABLE_NAME)}
      rows={getRows({
        data: salesOrderItems,
        columns: RE_ASSOCIATE_COLUMNS,
        categories: [],
        renderSpecialContent,
      })}
      testId={TABLE_NAME}
      emptyView={<div>No Item available</div>}
    />
  );
};

export default ReAssociateBody;
