import React from 'react';
import DispatchNoteList from '../../../../../pages/despatchNote/DispatchNoteList';

const DNInJob = ({ jobId }: { jobId: string }) => {
  return (
    <DispatchNoteList
      independent={false}
      extraAdvancedFilter={`DispatchNoteItem_id_dispatchNoteId.jobId:${jobId}`}
    />
  );
};

export default DNInJob;
