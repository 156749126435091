import React, { useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iEntityCategory from '../../../types/status/iEntityCategory';
import iAcquisitionOrder from '../../../types/acquisitionOrder/iAcquisitionOrder';
import { iConfigColumn, iSortOrderType } from '../../../shared/UITypes/types';
import { getRows } from './TableHelper';
import { AO_PAGE_NAME } from '../shared/AO.constants';
import { Wrapper } from '../shared/AO.styles';
import { getHeads } from '../../../services/UtilsService';

const AcquisitionOrderTable = ({
  data,
  isLoading,
  columns,
  categories,
  onSetSort,
  onOpenDeleteModal,
}: {
  isLoading: boolean;
  data: Array<iAcquisitionOrder>;
  columns: Array<iConfigColumn>;
  categories: Array<iEntityCategory>;
  onSetSort: (sortStr: string) => void;
  onOpenDeleteModal?: (id: string) => void;
}) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  return (
    <Wrapper>
      <DynamicTable
        head={getHeads(columns, AO_PAGE_NAME)}
        rows={getRows({
          data,
          onOpenDeleteModal,
          columns,
          categories,
        })}
        isLoading={isLoading}
        onSort={
          //  eslint-disable-next-line
          (data: any) => {
            setSortKey(data.key);
            setSortOrder(data.sortOrder);
            onSetSort(`${data.key}:${data.sortOrder}`);
          }
        }
        sortKey={sortKey}
        sortOrder={sortOrder}
      />
    </Wrapper>
  );
};

export default AcquisitionOrderTable;
