import React from 'react';
import { useSelector } from 'react-redux';
import { getContactCompanyProducts } from '../../../../services/ContactCompanyProductService';
import iContactCompanyProduct from '../../../../types/contactCompany/iContactCompanyProduct';
import iJob from '../../../../types/job/iJob';
import useListCrudHook from '../../../hooks/useListCrudHook/useListCrudHook';
import CustomizePagination from '../../../pagination/CustomizePagination';
import ServiceProviderTable from './ServiceProviderTable';
import { SpaceVerticalContainer } from '../../../styles/styles';
import { SERVICE_PROVIDER_COLUMNS } from './ServiceProvider.constants';
import { RootState } from '../../../../redux/makeReduxStore';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_SERVICE_PROVIDERS } from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

const ServiceProvider = ({ detail }: { detail: iJob }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadServiceProvider = AccessService.hasAccess(ACCESS_CODE_SERVICE_PROVIDERS, ACCESS_CAN_READ, user);

  const { id, productId } = detail;
  // per product&per service provider => per record
  // state.contactCompany is unique
  const { state, onSetCurrentPage } = useListCrudHook<iContactCompanyProduct>({
    getFn: getContactCompanyProducts,
    filter: `productId:${productId},contactCompany.type.name:ServiceProvider`,
    sort: 'updatedAt:DESC',
    notRenderWithoutFilter: true,
    paginationApplied: true,
  });
  // don't need 3-rd party service
  if (!detail.product.requireThirdPartyService) {
    return null;
  }
  return (
    <>
      <SpaceVerticalContainer className={'one'} />
      <ServiceProviderTable
        data={state.data}
        isLoading={state.isLoading}
        columns={SERVICE_PROVIDER_COLUMNS.map(col => {
          if (col.key === 'contactCompany.name' && !canReadServiceProvider) {
            return {
              ...col,
              type: 'text',
            };
          }
          return col;
        })}
        jobId={id}
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );
};

export default ServiceProvider;
