import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import Page, { Grid, GridColumn } from '@atlaskit/page';

import { iTaxRate } from '../../../types/iTaxRate';
import InputWithController from '../../../shared/form/hookForms/InputWithController';

const TaxRateEditModal = ({
  control,
  errors,
  onChange,
  targetTaxRate,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  targetTaxRate?: iTaxRate;
}) => {
  return (
    <Page>
      <Grid layout={'fluid'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'name'}
            label={'Tax Name'}
            defaultValue={targetTaxRate?.name}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'settings-tax-modal-name'}
          />
        </GridColumn>
        <GridColumn medium={6}>
          <InputWithController
            name={'rate'}
            label={'Rate'}
            defaultValue={targetTaxRate?.rate.toString()}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            isNumeric
            suffix={'%'}
            testId={'settings-tax-modal-rate'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default TaxRateEditModal;
