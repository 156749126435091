import React from 'react';
// import iJob from '../../../../types/job/iJob';
import CreateAganistSOHOC from '../createDNItem/createAganistSO/CreateAganistSOHOC';
import CreateAganistJob from '../createDNItem/createAganistJob/CreateAganistJobHOC';
// import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
// import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
// import { handleNullException } from '../../../../services/UtilsService';
// import { JOB_URL } from '../../../../shared/UrlMap';
import {
  FlexContainer,
  SpaceHorizontalContainer,
} from '../../../../shared/styles/styles';
import iAddress from '../../../../types/contactCompany/iAddress';

// type iSingleSelectOption<T> = iOption<T> | null | undefined;
// type iOption<T> = {
//   label: string;
//   value: T;
// };

const AddBySearchJob = ({
  dispatchNoteId,
  customerId,
  onCreate,
  DNShippingAddress,
  DNItemLength,
  isServiceProvider = false,
  customerShippingAddress,
}: {
  dispatchNoteId: string;
  customerId?: string;
  //  eslint-disable-next-line
  onCreate: (data: any) => Promise<void>;
  DNShippingAddress?: iAddress;
  DNItemLength?: number;
  isServiceProvider?: boolean;
  customerShippingAddress?: iAddress;
}) => {
  return (
    <FlexContainer>
      <CreateAganistJob
        DNId={dispatchNoteId}
        customerId={customerId}
        onCreate={onCreate}
        DNItemLength={DNItemLength}
        DNShippingAddress={DNShippingAddress}
        isServiceProvider={isServiceProvider}
        customerShippingAddress={customerShippingAddress}
      />
      <SpaceHorizontalContainer className={'half'} />
      <CreateAganistSOHOC
        DNId={dispatchNoteId}
        customerId={customerId}
        onCreate={onCreate}
        DNItemLength={DNItemLength}
        DNShippingAddress={DNShippingAddress}
      />
    </FlexContainer>
  );
};

export default AddBySearchJob;
