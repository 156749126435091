import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { ReactFlowProvider, useEdgesState, useNodesState } from 'react-flow-renderer';
import { iRouteTypeId } from '../../shared/UITypes/types';
import WorkflowEditLayout from './component/WorkflowEditLayout';
import { SETTINGS_ENTITY_STATUS_TYPE_URL } from '../../shared/UrlMap';
import WorkflowDiagram from '../../shared/workflow/diagram/WorkflowDiagram';
import WorkflowEditRightMenu from './component/WorkflowEditRightMenu';
import WorkflowEditButtons from './component/WorkflowEditButtons';
import useWorkflowDiagramHook from '../../shared/workflow/diagram/useWorkflowDiagramHook';
import { EDGE_ID_START_EDGE, validateGraph } from '../../shared/workflow/diagram/WorkflowDiagramHelper';
import WorkflowEditCreateButtons from './component/WorkflowEditCreateButtons';
import WorkflowTransitionEditModal from './component/WorkflowTransitionEditModal';
import WorkflowValidator from './component/WorkflowValidator';

const WorkflowEdit = () => {
  const history = useHistory();
  const { id: entityStatusTypeId } = useParams<iRouteTypeId>();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { state, setErrors } = useWorkflowDiagramHook(entityStatusTypeId, setNodes, setEdges);

  const validateDiagram = () => {
    setErrors(validateGraph(nodes, edges));
  };

  const onPreSaveWorkFlow = () => {
    const errors = validateGraph(nodes, edges);
    if (errors.length > 0) {
      setErrors(errors);
      return false;
    }
    return true;
  };

  if (state.isLoading === true) {
    return <Spinner />;
  }

  if (
    !state.workflow ||
    !state.entityStatusType ||
    !state.entityStatusType.entityStatuses ||
    state.entityStatusCategories.length <= 0
  ) {
    return null;
  }

  const handleOnCancel = () => {
    if (!state.entityStatusType) {
      return;
    }
    history.push(SETTINGS_ENTITY_STATUS_TYPE_URL.replace(':id', state.entityStatusType.id));
  };

  const getEditEdgePanel = () => {
    const selectedEdges = (edges || []).filter(edge => edge.selected === true && edge?.id !== EDGE_ID_START_EDGE);
    if (selectedEdges.length <= 0) {
      return null;
    }
    return (
      <WorkflowTransitionEditModal
        onCancel={() => setEdges(edges.map(edge => ({ ...edge, selected: false })))}
        state={state}
        edge={selectedEdges[0]}
        isOpen
      />
    );
  };

  return (
    <ReactFlowProvider>
      <WorkflowEditLayout
        entityStatusType={state.entityStatusType}
        createButtons={<WorkflowEditCreateButtons state={state} className={'create-btn'} />}
        rightSideBar={<WorkflowEditRightMenu state={state} />}
        errorDisplay={<WorkflowValidator state={state} />}
        updateBtns={<WorkflowEditButtons state={state} onCancel={handleOnCancel} onPreCheck={onPreSaveWorkFlow} />}
      >
        <>
          <WorkflowDiagram
            setEdges={setEdges}
            setNodes={setNodes}
            onNodesChange={(...props) => {
              validateDiagram();
              onNodesChange(...props);
            }}
            onEdgesChange={(...props) => {
              validateDiagram();
              onEdgesChange(...props);
            }}
            nodes={nodes}
            edges={edges}
          />
          {getEditEdgePanel()}
        </>
      </WorkflowEditLayout>
    </ReactFlowProvider>
  );
};

export default WorkflowEdit;
