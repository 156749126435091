import moment from 'moment';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import queryString from 'query-string';
import { iChannelCreationCallBackParams } from '../../integrations/ChannelScriptHelperTypes';
import ChannelScriptService from '../../../services/integration/ChannelScriptService';
import ChannelService from '../../../services/integration/ChannelService';

const MyobChannelSetupCallback = (params: iChannelCreationCallBackParams) => {
  const { channel, channelScript } = params;
  const history = useHistory();

  useEffect(() => {
    const handle = async () => {
      const urlSearchParams = queryString.parse(window.location.search);
      const myobRedirectUrl = process.env.REACT_APP_MYOB_REDIRECT_URL || '';
      const {
        // eslint-disable-next-line camelcase
        auth: { access_token, refresh_token, expires_in },
      } = await ChannelScriptService.getChannelScriptAccessToken(
        channelScript.id,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        urlSearchParams.code || '',
        myobRedirectUrl,
      );
      const updatedChannel = await ChannelService.updateChannel(channel.id, {
        settings: {
          auth: {
            accessToken: access_token,
            refreshToken: refresh_token,
            accessTokenExpiresAt: moment().add(expires_in, 'seconds').toISOString(),
            refreshTokenExpiresAt: moment().add(1, 'year').toISOString(),
          },
        },
        isOnBoarded: false,
      });
      history.push(`/b/settings/channel/${updatedChannel.id}`);
    };

    handle();
  }, [channel, channelScript, history]);

  return (
    <div className={'MyobChannelSetupCallback'}>
      <Spinner />
    </div>
  );
};

export default MyobChannelSetupCallback;
