import Page, { Grid, GridColumn } from '@atlaskit/page';
import React, { useState } from 'react';
import Toggle from '@atlaskit/toggle';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import Tooltip from '@atlaskit/tooltip';
import { Label } from '@atlaskit/field-base';
import iContact from '../../../../types/contactCompany/iContact';
import AvatarWithController from '../../../form/hookForms/AvatarWithController';
import InputWithController from '../../../form/hookForms/InputWithController';
import { handleNullException } from '../../../../services/UtilsService';
import {
  checkDuplicateContact,
  validateContactPassword,
} from '../../../../services/ContactService';
import PasswordRuleSection from '../../../../pages/settings/users/PasswordRuleSection';

const ContactModalBody = ({
  control,
  errors,
  onChange,
  watch,
  setError,
  target,
  isCustomer = true,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  //    eslint-disable-next-line
  watch: (names?: string | string[]) => any;
  setError: (name: string, error: FieldError) => void;
  target?: iContact;
  isCustomer?: boolean;
}) => {
  const [isPortalEnabled, setIsPortalEnabled] = useState(!!target?.password);

  //  eslint-disable-next-line
  const confirmPassword = (newValue: any) => {
    const confirmPwdErrorMessage = 'must enter a same password';
    const password = watch('password');
    if (!newValue && !password) return true;
    if (newValue !== password) return confirmPwdErrorMessage;
  };

  const checkDuplicateEmail = async (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    if (!event.target.value.trim()) return;
    const { isDuplicate } = await checkDuplicateContact(
      event.target.value.trim(),
      handleNullException(target, 'id'),
    );

    if (isDuplicate)
      setError('email', {
        type: 'manual',
        message: 'This email has been taken.',
      });
  };

  const checkPasswordComplexity = async (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    if (!event.target.value.trim()) return;
    const { isValid } = await validateContactPassword(
      event.target.value.trim(),
    );

    if (!isValid)
      setError('password', {
        type: 'manual',
        message: 'Invalid password',
      });
  };

  return (
    <Page>
      <Grid spacing="cosy">
        <GridColumn medium={4}>
          <AvatarWithController
            name={'avatarUrl'}
            defaultValue={target?.avatarUrl || undefined}
            onChange={onChange}
            errors={errors}
            control={control}
          />
        </GridColumn>
        <GridColumn medium={8}>
          <Grid layout={'fluid'}>
            <GridColumn medium={8}>
              <InputWithController
                name={'name'}
                label={'Name'}
                defaultValue={target?.name}
                control={control}
                onChange={onChange}
                errors={errors}
              />
            </GridColumn>
          </Grid>
          <Grid layout={'fluid'}>
            <GridColumn medium={4}>
              <InputWithController
                name={'position'}
                label={'Position'}
                defaultValue={target?.position}
                control={control}
                onChange={onChange}
                errors={errors}
                isRequired={false}
              />
            </GridColumn>
            <GridColumn medium={4}>
              <InputWithController
                name={'phone'}
                label={'Phone'}
                defaultValue={target?.phone}
                control={control}
                onChange={onChange}
                errors={errors}
                isRequired={false}
              />
            </GridColumn>
          </Grid>

          <Grid layout={'fluid'}>
            <GridColumn medium={8}>
              <InputWithController
                name={'email'}
                label={'Email'}
                defaultValue={target?.email}
                control={control}
                onChange={onChange}
                errors={errors}
                isEmail={isPortalEnabled}
                type={'email'}
                isRequired={isPortalEnabled}
                // externalValidate={checkDuplicateEmail}
                onPreCheck={checkDuplicateEmail}
              />
            </GridColumn>
          </Grid>

          {isCustomer && (
            <>
              <Grid layout={'fluid'}>
                <GridColumn medium={4}>
                  <Label
                    label={'Customer Portal'}
                    htmlFor={'toggle-customer-portal'}
                  />
                </GridColumn>
              </Grid>
              <Grid layout={'fluid'}>
                <GridColumn medium={4}>
                  <Tooltip
                    content={
                      isPortalEnabled
                        ? 'Disable customer portal'
                        : 'Enable customer portal'
                    }
                  >
                    <Toggle
                      defaultChecked={isPortalEnabled}
                      id="toggle-customer-portal"
                      onChange={() => setIsPortalEnabled(prev => !prev)}
                    />
                  </Tooltip>
                </GridColumn>
              </Grid>
            </>
          )}

          {isCustomer && isPortalEnabled && (
            <>
              <Grid layout={'fluid'}>
                <GridColumn medium={4}>
                  <InputWithController
                    name={'password'}
                    label={'Password'}
                    control={control}
                    onChange={onChange}
                    errors={errors}
                    type={'password'}
                    onPreCheck={checkPasswordComplexity}
                    isRequired={isPortalEnabled}
                  />
                </GridColumn>
                <GridColumn medium={4}>
                  <InputWithController
                    name={'confirm-password'}
                    label={'Confirm password'}
                    control={control}
                    onChange={onChange}
                    errors={errors}
                    type={'password'}
                    externalValidate={confirmPassword}
                    isRequired={isPortalEnabled}
                  />
                </GridColumn>
              </Grid>
              <Grid layout={'fluid'}>
                <GridColumn medium={8}>
                  <PasswordRuleSection />
                </GridColumn>
              </Grid>
            </>
          )}
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default ContactModalBody;
