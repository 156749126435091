import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import BackendPage from '../../layout/BackendPage';
import WelcomeMsg from '../../shared/user/WelcomeMsg';
import { RootState } from '../../redux/makeReduxStore';
import InlineEdit from '../../shared/form/InlineEdit';
import PasswordReset from '../../shared/user/PasswordReset';
import UserAvatarUpload from '../../shared/user/UserAvatarUpload';
import { updateUserProfile } from '../../services/Settings/UserProfileService';
import { userAuthenticated } from '../../redux/reduxers/auth.slice';
import { addToastForAPIResponse } from '../../shared/toast/Toast';

export default () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const dispatch = useDispatch();
  // update profile
  const onConfirm = async (name: string, newValue: string) => {
    const res = await updateUserProfile(name, newValue);
    if (res) {
      dispatch(userAuthenticated(res));
      // toast
      addToastForAPIResponse('success');
    } else {
      addToastForAPIResponse('error');
    }
  };
  return (
    <BackendPage pageHeader={<WelcomeMsg />}>
      <Page>
        <Grid spacing="cosy">
          <GridColumn medium={4}>
            <UserAvatarUpload name={'userAvatarUrl'} defaultValue={user?.userAvatarUrl} onConfirm={onConfirm} />
          </GridColumn>
          <GridColumn medium={8}>
            <Grid layout={'fluid'}>
              <GridColumn medium={4}>
                <InlineEdit
                  name={'firstName'}
                  label={'First name'}
                  defaultValue={user?.firstName}
                  onConfirm={onConfirm}
                />
              </GridColumn>
              <GridColumn medium={4}>
                <InlineEdit name={'lastName'} label={'Last name'} defaultValue={user?.lastName} onConfirm={onConfirm} />
              </GridColumn>
              <GridColumn medium={4}>
                <InlineEdit name={'username'} label={'Username'} defaultValue={user?.username} onConfirm={onConfirm} />
              </GridColumn>
              <GridColumn medium={4}>
                <InlineEdit name={'email'} label={'Email'} defaultValue={user?.email} onConfirm={onConfirm} />
              </GridColumn>
            </Grid>
            <hr />
            <PasswordReset name={'password'} onConfirm={onConfirm} />
          </GridColumn>
        </Grid>
      </Page>
    </BackendPage>
  );
};
