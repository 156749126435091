import { iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

const getAllBillingAccounts = () => {
  return AppService.get('/billingAccount').then(({ data }) => data);
};

const getPaymentMethods = (config?: iParams) => {
  return AppService.get('/billingAccount/paymentMethod', config || {}).then(
    ({ data }) => data,
  );
};

const getInvoices = (config?: iParams) => {
  return AppService.get('/billingAccount/invoice', config || {}).then(
    ({ data }) => data,
  );
};

export default {
  getPaymentMethods,
  getAllBillingAccounts,
  getInvoices,
};
