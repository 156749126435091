import DynamicTable from '@atlaskit/dynamic-table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPurchaseOrderItems } from '../../../../services/PurchaseOrderItemService';
import { getHeads, handleNullException } from '../../../../services/UtilsService';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import CreatedOrUpdated from '../../../../shared/createdOrUpdated/CreatedOrUpdated';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import ConversionText from '../../../../shared/text/ConversionText';
import { apiErrorToast } from '../../../../shared/toast/Toast';
import { iConfigColumn } from '../../../../shared/UITypes/types';
import iPurchaseOrderItem from '../../../../types/purchases/iPurchaseOrderItem';
import { PURCHASED_PRODUCTS_COLUMNS, PURCHASED_PRODUCTS_TABLE_NAME } from '../../shared/AO.constants';
import { RootState } from '../../../../redux/makeReduxStore';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PURCHASE_ORDERS } from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

type iState = {
  isLoading: boolean;
  data: Array<iPurchaseOrderItem>;
};
const initialState: iState = {
  isLoading: true,
  data: [],
};
const getRows = ({ data, columns }: { data: Array<iPurchaseOrderItem>; columns: Array<iConfigColumn> }) => {
  return data.map((item: iPurchaseOrderItem) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'link-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                <LinkBtnUrl
                  btnName={handleNullException(item, key)}
                  url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
                />
              </ComposeSecondaryText>
            ),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
              />
            ),
          };
        case 'conversion-text':
          return {
            key: handleNullException(item, key),
            content: (
              <ConversionText
                value={handleNullException(item, key)}
                conversion={handleNullException(item, dataSource[0])}
                contactCompanyUnit={
                  handleNullException(item, dataSource[1]) || handleNullException(item, dataSource[2])
                }
                productUnit={handleNullException(item, dataSource[2])}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};

const PurchasedProducts = ({ AOItemIds }: { AOItemIds: Array<string> }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadPO = AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_READ, user);

  const ids = AOItemIds.join('|');
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      if (ids.length === 0) return;
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await getPurchaseOrderItems({
          currentPage: '1',
          perPage: '50',
          filter: `acquisitionOrderItemId:${ids}`,
        });
        if (isCancelled) return;
        setState(prevState => ({ ...prevState, isLoading: false, data }));
      } catch (error) {
        if (isCancelled) return;
        setState(prevState => ({ ...prevState, isLoading: false }));
        apiErrorToast(error);
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [ids]);
  return (
    <DynamicTable
      head={getHeads(PURCHASED_PRODUCTS_COLUMNS, PURCHASED_PRODUCTS_TABLE_NAME)}
      rows={getRows({
        data: state.data,
        columns: PURCHASED_PRODUCTS_COLUMNS.map(col => {
          if (col.key === 'purchaseOrder.orderNumber' && !canReadPO) {
            return {
              ...col,
              type: 'text',
            };
          }
          return col;
        }),
      })}
      testId={PURCHASED_PRODUCTS_TABLE_NAME}
      isLoading={state.isLoading}
    />
  );
};

export default PurchasedProducts;
