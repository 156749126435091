import { iChannelEntity, iChannelEntityInput } from '../../types/integration/iChannelEntity';
import AppService from '../AppService';
import { iPaginatedResult } from '../../types/iPagination';

const endPoint = '/channelEntity';
const createChannelEntity = (channelEntityBody: iChannelEntityInput) => {
  return AppService.post(endPoint, channelEntityBody).then(({ data }) => data);
};

const updateChannelEntity = (id: string, channelEntityBody: iChannelEntityInput) => {
  return AppService.put(`${endPoint}/${id}`, channelEntityBody).then(({ data }) => data);
};

const getChannelEntities = (
  channelId: string,
  entityName: string,
  entityId: string,
  params = {},
): Promise<iChannelEntity[]> => {
  return AppService.get(endPoint, {
    filter: `channelId:${channelId},entityName:${entityName},entityId:${entityId}`,
    ...params,
  }).then(({ data }) => data.data);
};
const getChannelEntitiesByEntityJobId = (
  channelJobId: string,
  params = {},
): Promise<iPaginatedResult<iChannelEntity>> => {
  return AppService.get(endPoint, {
    filter: `channelJobId:${channelJobId}`,
    ...params,
  }).then(({ data }) => data);
};

const deactivateChannelEntity = (id: string) => {
  return AppService.delete(`${endPoint}/${id}`).then(({ data }) => data);
};

export default {
  getChannelEntities,
  getChannelEntitiesByEntityJobId,
  createChannelEntity,
  updateChannelEntity,
  deactivateChannelEntity,
};
