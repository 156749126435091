import styled from 'styled-components';

export const GRID_PADDING = 8;

export const Wrapper = styled.div`
  th {
    padding: 4px 8px;
    border: none;
    color: rgb(94, 108, 132);
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 600;
    position: relative;
    text-align: left;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
      width: 2rem;
      min-width: 2rem;
    }

    &:last-child {
      width: 2rem;
      min-width: 2rem;
    }
  }

  tr:hover {
    background-color: rgb(250, 251, 252);
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? 'rgb(250, 251, 252)' : '#fff',
    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

export const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightgrey' : '#fff',
  padding: GRID_PADDING,
  width: '100%',
});
