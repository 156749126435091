import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Grid, GridColumn } from '@atlaskit/page';
import { useSelector } from 'react-redux';
import iDispatchNote from '../../types/dispatchNote/iDispatchNote';
import BackendPage from '../../layout/BackendPage';
import Advanced from '../../shared/contactCompany/list/Advanced';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import SearchBar from '../../shared/search/SearchBar';
import GeneralPrintBtn from '../../shared/buttons/GeneralPrintBtn';
import DispatchNoteTable from './list/DispatchNoteTable';
import useStatusSelector from '../../shared/hooks/useStatusSelector';
import PageTitleWithAddOne from '../../shared/heading/PageTitleWithAddOne';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import CustomizePagination from '../../shared/pagination/CustomizePagination';
import DeleteByRetype from '../../shared/deleteWithReType/DeleteByReType';
import useColumnHooksUpgrade from '../../shared/hooks/useShowHideColumnHook/useColumnHook';
import { AdvancedSearchSectionWrapper, FlexContainer } from '../../shared/styles/styles';
import { getEntityCategoryList } from '../../services/CategoryService';
import { printDispatchNoteManifest } from '../../services/PrintService';
import { DISPATCH_NOTES_URL } from '../../shared/UrlMap';
import { ALL, DISPATCH_COLUMNS, PAGE_NAME } from './shared/DispatchNote.constant';
import {
  createDispatchNote,
  getDispatchNoteList,
  removeDispatchNote,
  updateDispatchNote,
} from '../../services/DispatchNoteService';
import PaginationDetail from '../../shared/pagination/paginationDetail/PaginationDetail';
import DnModalBody from './list/DnModalBody';
import useAdvancedSearchHooks from '../../shared/advancedSearch/AdvancedSearch';
import { DN_ADVANCED_SEARCH_CONFIGS } from '../sales/constants';
import ExportReport from '../../shared/exportReport/ExportReport';
import { getExportReportName } from '../../services/UtilsService';
import { DISPATCH_NOTE } from '../../shared/topNavPopups/createPopup/Create.constant';
import { getReportArrSegments } from './list/TableHelper';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_CUSTOMERS,
  ACCESS_CODE_DISPATCH_NOTES,
  ACCESS_CODE_INVOICES,
} from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE, ACCESS_CAN_READ } from '../../types/settings/UserAccess/iRoleAccess';
import IChannel from '../../types/integration/iChannel';
import ChannelService from '../../services/integration/ChannelService';
import { apiErrorToast } from '../../shared/toast/Toast';

type iDispatchNoteList = {
  independent?: boolean;
  extraFilter?: string;
  extraAdvancedFilter?: string;
  jsonFilter?: string;
};
const DispatchNoteList = ({ independent = true, extraFilter, extraAdvancedFilter, jsonFilter }: iDispatchNoteList) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canCreate = AccessService.hasAccess(ACCESS_CODE_DISPATCH_NOTES, ACCESS_CAN_CREATE, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_DISPATCH_NOTES, ACCESS_CAN_DELETE, user);
  const canReadCustomers = AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_READ, user);
  const canReadInvoices = AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_READ, user);

  const [selectedDNIds, setSelectedDNIds] = useState<string[]>([]);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [channels, setChannels] = useState<IChannel[]>([]);

  const history = useHistory();
  const { control, setValue, errors, handleSubmit, watch } = useForm();
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onSearch,
    onSetCurrentPage,
    onSetSort,
    onOpenDeleteModal,
    onFilter,
    onSubmit,
    onRefreshList,
  } = useListCrudHook<iDispatchNote>({
    getFn: getDispatchNoteList,
    deleteFn: removeDispatchNote,
    createFn: createDispatchNote,
    updateFn: updateDispatchNote,
    filter: extraFilter,
    advancedFilter: extraAdvancedFilter,
    jsonFilter,
    sort: 'updatedAt:DESC',
    paginationApplied: true,
    notRenderWithoutFilter: true,
    createCallBack: (id: string) => history.push(`${DISPATCH_NOTES_URL}/${id}`),
    keywordColumns: 'dispatchNoteNumber',
  });
  const { selected, getTypeSelectorComponent, categories, isStatusLoading } = useStatusSelector({
    type: 'DispatchNote',
    isMulti: true,
    getFn: getEntityCategoryList,
  });
  const afterInvoicesColumns = ['createdAt', 'updatedAt', 'operation'];
  const { columns, getShowHideColumns } = useColumnHooksUpgrade('dispatchNote', [
    ...DISPATCH_COLUMNS.filter(col => afterInvoicesColumns.indexOf(col.key) < 0),
    ...channels.map(channel => ({
      name: `${channel.script?.name} - ${channel.name}`,
      key: `channel-${channel.id}`,
      dataSource: [`${channel.id}`, `${channel.script?.id || ''}`, `${channel.script?.name || ''}`],
      isCompulsory: false,
      isSortable: false,
      type: 'channelInvoice',
      group: 'Channels',
    })),
    ...DISPATCH_COLUMNS.filter(col => afterInvoicesColumns.indexOf(col.key) >= 0),
  ]);
  const { getAdvancedFilterStrArr, getSearchControllers } = useAdvancedSearchHooks(DN_ADVANCED_SEARCH_CONFIGS, false);

  useEffect(() => {
    let isCanceled = false;

    ChannelService.getChannelsForAccounting(true, {
      include: 'script',
    })
      .then(resp => {
        if (isCanceled) return;
        setChannels(resp);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      });

    return () => {
      isCanceled = true;
    };
  }, [user]);

  // for report export
  const getAdvancedFilterStr = () => {
    const filterStatus = selected.includes(ALL) || selected.length === 0 ? '' : `statusId:${selected.join('|')}`;

    const filterStr = [filterStatus].filter((elem: string) => elem !== '').join(',');

    const advancedSearchStr = getAdvancedFilterStrArr()
      .filter(strFilter => strFilter !== '')
      .join(',');

    if (advancedSearchStr) {
      return `${advancedSearchStr},${filterStr}`;
    }
    return filterStr;
  };

  const getPageHeader = () => (
    <>
      <PageTitleWithAddOne
        title={PAGE_NAME}
        onClickAddBtn={onOpenAddModal}
        className={'space-below'}
        isDisabled={!canCreate}
      />
      <FlexContainer className={'space-between'}>
        {getTypeSelectorComponent()}
        <FlexContainer>
          <SearchBar keyword={edit.keyword} onSearch={onSearch} placeholder={'search via No....'} />
          <Advanced onClick={() => setShowAdvanceSearch(prev => !prev)} />
        </FlexContainer>
      </FlexContainer>
      {showAdvanceSearch === true ? (
        <AdvancedSearchSectionWrapper>
          <Grid layout={'fluid'} spacing={'cosy'}>
            {getSearchControllers()}
            <GridColumn medium={0.5}>
              <ExportReport
                className={'margin-above margin-below'}
                total={state.total}
                perPage={state.perPage}
                getFn={(currentPage: number) =>
                  getDispatchNoteList({
                    currentPage: currentPage.toString(),
                    perPage: state.perPage?.toString() || '10',
                    advancedFilter: getAdvancedFilterStr() || '',
                    sort: 'createdAt:DESC',
                    like: edit.keyword
                      ? `orderNumber:${edit.keyword},customerRef:${edit.keyword},invoiceNumber:${edit.keyword}`
                      : '',
                  })
                }
                onFormatReport={dns =>
                  getReportArrSegments(
                    dns,
                    columns.filter(col => col.name !== ''),
                  )
                }
                reportFileName={getExportReportName(DISPATCH_NOTE)}
              />
            </GridColumn>
          </Grid>
        </AdvancedSearchSectionWrapper>
      ) : null}
    </>
  );

  useEffect(
    () => {
      if (!isStatusLoading && independent) {
        const filterStr = selected.includes(ALL) || selected.length === 0 ? '' : `statusId:${selected.join('|')}`;
        const advancedSearchStr = getAdvancedFilterStrArr()
          .filter(strFilter => strFilter !== '')
          .join(',');

        if (advancedSearchStr) {
          onFilter(`${advancedSearchStr},${filterStr}`);
        } else {
          onFilter(filterStr);
        }
      }
    },
    //  eslint-disable-next-line
    [JSON.stringify(selected), JSON.stringify(getAdvancedFilterStrArr())],
  );

  const onCheck = (id: string, value: boolean) => {
    if (value) {
      // add selected Id to the array
      setSelectedDNIds([...selectedDNIds, id]);
    } else {
      // remove selected Id from the array
      const arrAfterRemove = selectedDNIds.filter(cur => cur !== id);
      setSelectedDNIds(arrAfterRemove);
    }
  };

  const getPageBody = () => (
    <>
      <FlexContainer className={'space-between space-below'}>
        {!!state.total && !!state.from && !!state.to && independent ? (
          <PaginationDetail from={state.from} to={state.to} total={state.total} onRefreshResults={onRefreshList} />
        ) : (
          <div />
        )}
        <div
          style={{
            fontSize: '0.8rem',
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: 0.02,
          }}
        >
          <GeneralPrintBtn
            serviceFunc={() => printDispatchNoteManifest(selectedDNIds)}
            isDisabled={selectedDNIds.length === 0}
          >
            Print Manifest
          </GeneralPrintBtn>
          {getShowHideColumns()}
        </div>
      </FlexContainer>
      <DispatchNoteTable
        data={state.data}
        onSetSort={onSetSort}
        onOpenDeleteModal={canDelete ? onOpenDeleteModal : undefined}
        onCheck={onCheck}
        isLoading={state.isLoading}
        columns={columns.map(col => {
          if (col.key === 'customerId' && !canReadCustomers) {
            return {
              ...col,
              isDisabled: true,
            };
          }
          if ((col.key === 'invoices' || col.key.startsWith('channel-')) && !canReadInvoices) {
            return {
              ...col,
              isDisabled: true,
            };
          }
          return col;
        })}
        categories={categories}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen && !edit.delTargetId}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <DnModalBody
            control={control}
            onChange={setValue}
            errors={errors}
            watch={watch}
            searchServiceProvider
            isCustomer
          />
        }
        modalHeading={'Creating a Dispatch Note for'}
        confirmBtnName={'Create'}
      />
      <DeleteByRetype
        isOpen={edit.isModalOpen && !!edit.delTargetId}
        onClose={onCloseModal}
        onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
        answer={edit.target?.dispatchNoteNumber}
        isConfirming={state.isConfirming}
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );

  return independent ? <BackendPage pageHeader={getPageHeader()}>{getPageBody()}</BackendPage> : getPageBody();
};

export default DispatchNoteList;
