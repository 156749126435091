import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import Page, { Grid, GridColumn } from '@atlaskit/page';

import iUnitOfMeasurements from '../../../types/iUnitOfMeasurement';
import InputWithController from '../../../shared/form/hookForms/InputWithController';

const UnitEditModal = ({
  control,
  errors,
  onChange,
  targetUnit,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  targetUnit?: iUnitOfMeasurements;
}) => {
  return (
    <Page>
      <Grid layout={'fluid'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'shortName'}
            label={'Name'}
            defaultValue={targetUnit?.shortName}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'settings-unit-modal-shortName'}
          />
        </GridColumn>
        <GridColumn medium={6}>
          <InputWithController
            name={'name'}
            label={'Description'}
            defaultValue={targetUnit?.name}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'settings-unit-modal-name'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default UnitEditModal;
