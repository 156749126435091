import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid, GridColumn } from '@atlaskit/page';
import { useSelector } from 'react-redux';
import {
  createPurchaseOrder,
  deactivatePurchaseOrder,
  getPurchaseOrderList,
  updatePurchaseOrder,
} from '../../../services/PurchaseService';
import { AdvancedSearchSectionWrapper, FlexContainer } from '../../../shared/styles/styles';
import SearchBar from '../../../shared/search/SearchBar';
import Advanced from '../../../shared/contactCompany/list/Advanced';
import PurchaseOrderListTable from './PurchaseOrderListTable';
import BackendPage from '../../../layout/BackendPage';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import { getEntityCategoryList } from '../../../services/CategoryService';
import useStatusSelector from '../../../shared/hooks/useStatusSelector';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import useColumnHooksUpgrade from '../../../shared/hooks/useShowHideColumnHook/useColumnHook';
import { ALL } from '../../despatchNote/shared/DispatchNote.constant';
import DeleteByReType from '../../../shared/deleteWithReType/DeleteByReType';
import { PO_ADVANCED_SEARCH_CONFIGS, PO_COLUMNS, PO_LIST_TITLE } from '../constants';
import PageTitleWithAddOne from '../../../shared/heading/PageTitleWithAddOne';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import { PURCHASES_URL } from '../../../shared/UrlMap';
import AOPOModalBody from '../../../shared/asyncContactCompany/createModalBody/AOPOModalBody';
import PaginationDetail from '../../../shared/pagination/paginationDetail/PaginationDetail';
import useAdvancedSearchHooks from '../../../shared/advancedSearch/AdvancedSearch';
import ExportReport from '../../../shared/exportReport/ExportReport';
import { getExportReportName } from '../../../services/UtilsService';
import { PURCHASE_ORDER } from '../../../shared/topNavPopups/createPopup/Create.constant';
import { getReportArrSegments } from './TableHelper';
import ModulingService from '../../../services/ModulingService';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_ACQUISITION_ORDERS,
  ACCESS_CODE_PURCHASE_ORDERS,
  ACCESS_CODE_SUPPLIERS,
} from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE, ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';

const PurchaseOrderList = ({
  independent = true,
  extraFilter,
  extraAdvancedFilter,
}: {
  independent?: boolean;
  extraFilter?: string;
  extraAdvancedFilter?: string;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadSupplier = AccessService.hasAccess(ACCESS_CODE_SUPPLIERS, ACCESS_CAN_READ, user);
  const canReadAO = AccessService.hasAccess(ACCESS_CODE_ACQUISITION_ORDERS, ACCESS_CAN_READ, user);
  const canDelete = AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_DELETE, user);
  const canCreate = AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_CREATE, user);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const history = useHistory();
  const { control, setValue, errors, handleSubmit, watch } = useForm();
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onSearch,
    onSetCurrentPage,
    onSetSort,
    onOpenDeleteModal,
    onFilter,
    onAdvancedFilter,
    onSubmit,
    onRefreshList,
  } = useListCrudHook<iPurchaseOrderDetail>({
    getFn: getPurchaseOrderList,
    deleteFn: deactivatePurchaseOrder,
    createFn: createPurchaseOrder,
    updateFn: updatePurchaseOrder,
    keywordColumns: 'orderNumber,supplier.name',
    filter: extraFilter,
    advancedFilter: extraAdvancedFilter,
    sort: 'createdAt:DESC',
    paginationApplied: true,
    notRenderWithoutFilter: true,
    createCallBack: (id: string) => history.push(`${PURCHASES_URL}/${id}`),
  });

  const { columns, getShowHideColumns } = useColumnHooksUpgrade(
    'purchaseOrder',
    ModulingService.filterConfigColumnsByModule({ list: PO_COLUMNS, companyModules: user?.companyModules || [] }),
  );

  const { selected, getTypeSelectorComponent, categories, isStatusLoading } = useStatusSelector({
    type: 'PurchaseOrder',
    isMulti: true,
    getFn: getEntityCategoryList,
  });

  const { getAdvancedFilterStrArr, getSearchControllers } = useAdvancedSearchHooks(PO_ADVANCED_SEARCH_CONFIGS, false);

  useEffect(() => {
    if (!isStatusLoading && independent) {
      const filter = selected.includes(ALL) || selected.length === 0 ? '' : `statusId:${selected.join('|')}`;

      const advancedSearchStr = getAdvancedFilterStrArr()
        .filter(strFilter => strFilter !== '')
        .join(',');

      if (advancedSearchStr) {
        onFilter(`${advancedSearchStr},${filter}`);
      } else {
        onFilter(filter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selected), JSON.stringify(getAdvancedFilterStrArr())]);

  // report request
  const getAdvancedFilterStr = () => {
    const filter = selected.includes(ALL) || selected.length === 0 ? '' : `statusId:${selected.join('|')}`;

    const advancedSearchStr = getAdvancedFilterStrArr()
      .filter(strFilter => strFilter !== '')
      .join(',');

    if (advancedSearchStr) {
      return `${advancedSearchStr},${filter}`;
    }
    return filter;
  };

  useEffect(() => {
    if (extraAdvancedFilter) {
      onAdvancedFilter(extraAdvancedFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraAdvancedFilter]);

  // useEffect(() => {
  //   const filter = getAdvancedFilterStrArr()
  //     .filter(strFilter => strFilter !== '')
  //     .join(',');
  //   onFilter(filter);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [JSON.stringify(getAdvancedFilterStrArr())]);

  const getPageHeader = () => (
    <>
      <PageTitleWithAddOne
        title={PO_LIST_TITLE}
        onClickAddBtn={onOpenAddModal}
        className={'space-below'}
        isDisabled={!canCreate}
      />
      <FlexContainer className={'space-between'}>
        {getTypeSelectorComponent()}
        <FlexContainer>
          <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search PO or supplier'} />
          <Advanced onClick={() => setIsAdvancedSearch(prev => !prev)} />
        </FlexContainer>
      </FlexContainer>
      {isAdvancedSearch && (
        <AdvancedSearchSectionWrapper>
          <Grid layout={'fluid'} spacing={'cosy'}>
            {getSearchControllers()}

            <GridColumn medium={0.5}>
              <ExportReport
                className={'margin-above margin-below'}
                total={state.total}
                perPage={state.perPage}
                getFn={(currentPage: number) =>
                  getPurchaseOrderList({
                    currentPage: currentPage.toString(),
                    perPage: state.perPage?.toString() || '10',
                    advancedFilter: getAdvancedFilterStr() || '',
                    sort: 'createdAt:DESC',
                    like: edit.keyword ? `orderNumber:${edit.keyword},supplier.name:${edit.keyword}` : '',
                  })
                }
                onFormatReport={orders => getReportArrSegments(orders, columns)}
                reportFileName={getExportReportName(PURCHASE_ORDER)}
              />
            </GridColumn>
          </Grid>
        </AdvancedSearchSectionWrapper>
      )}
    </>
  );
  const getPageBody = () => {
    if (!AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_READ, user)) {
      return null;
    }
    return (
      <div data-testid={'purchaseOrders-wrapper'}>
        <FlexContainer className={'space-between space-below'}>
          {!!state.total && !!state.from && !!state.to && independent ? (
            <PaginationDetail from={state.from} to={state.to} total={state.total} onRefreshResults={onRefreshList} />
          ) : (
            <div />
          )}
          {getShowHideColumns()}
        </FlexContainer>
        <PurchaseOrderListTable
          isLoading={state.isLoading}
          purchaseOrders={state.data}
          onOpenDeleteModal={canDelete ? onOpenDeleteModal : undefined}
          onSetSort={onSetSort}
          columns={columns.map(col => {
            if (col.key === 'supplier.id' && !canReadSupplier) {
              return {
                ...col,
                isDisabled: true,
              };
            }
            if (col.key === 'aos' && !canReadAO) {
              return {
                ...col,
                isDisabled: true,
              };
            }
            return col;
          })}
          categories={categories}
        />
        <CustomizeModal
          isOpen={edit.isModalOpen && !edit.delTargetId}
          onConfirm={handleSubmit(onSubmit)}
          onCancel={onCloseModal}
          isConfirming={state.isConfirming}
          isDisabled={Object.keys(errors).length > 0}
          modalBody={
            <AOPOModalBody control={control} onChange={setValue} errors={errors} watch={watch} isCustomer={false} />
          }
          modalHeading={'Creating a Purchase Order for'}
          confirmBtnName={'Create'}
        />
        {!!state.total && !!state.perPage && !!state.currentPage && (
          <CustomizePagination
            range={Math.ceil(state.total / state.perPage)}
            currentPage={state.currentPage}
            onChange={onSetCurrentPage}
          />
        )}
        <DeleteByReType
          isOpen={!!edit.delTargetId && edit.isModalOpen}
          onClose={onCloseModal}
          onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
          answer={
            state.data.find((order: iPurchaseOrderDetail) => order.id === edit.target?.id)?.orderNumber ||
            'order number N/A'
          }
          isConfirming={state.isConfirming}
          isDeletable
        />
      </div>
    );
  };

  return independent ? <BackendPage pageHeader={getPageHeader()}>{getPageBody()}</BackendPage> : getPageBody();
};

export default PurchaseOrderList;
