import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import iContact from '../../../../types/contactCompany/iContact';
import CreatedOrUpdated from '../../../createdOrUpdated/CreatedOrUpdated';
import TransparentIconBtn from '../../../buttons/TransparentIconBtn';
import { iConfigColumn } from '../../../UITypes/types';
import { FlexEndWrapper } from '../../../../pages/stockTransfer/sharedComponents/StockTransfer.style';
import {
  getHeads,
  handleNullException,
} from '../../../../services/UtilsService';
import ColorfulDotIcon from '../../../icons/ColorfulDotIcon';
import { TableWrapper } from './Contact.style';
import { TABLE_NAME } from './Contact.constant';

const getRows = (
  data: Array<iContact>,
  columns: Array<iConfigColumn>,
  onOpenDeleteModal: (id: string) => void,
  //  eslint-disable-next-line
  onOpenEditModal: (id: string) => void,
) => {
  return data.map((item: iContact) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'password':
          return {
            content: handleNullException(item, key) && <ColorfulDotIcon />,
          };
        case 'avatar-text-link':
          return {
            key: handleNullException(item, key),
            content: (
              <AvatarItem
                avatar={
                  <Avatar src={handleNullException(item, dataSource[0])} />
                }
                primaryText={handleNullException(item, key)}
                onClick={() =>
                  onOpenEditModal(handleNullException(item, dataSource[1]))
                }
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        case 'delete':
          return {
            content: (
              <FlexEndWrapper>
                <TransparentIconBtn
                  icon={<TrashIcon label={'remove'} />}
                  onClick={() => onOpenDeleteModal(item.id)}
                />
              </FlexEndWrapper>
            ),
          };
        default:
          return { content: handleNullException(item, key) };
      }
    });
    return { cells, testId: 'customer-contacts-table' };
  });
};

const ContactTable = ({
  data,
  onOpenDeleteModal,
  onOpenEditModal,
  columns,
}: {
  data: Array<iContact>;
  onOpenDeleteModal: (id: string) => void;
  onOpenEditModal: (id: string) => void;
  columns: Array<iConfigColumn>;
}) => {
  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(columns, TABLE_NAME)}
        rows={getRows(data, columns, onOpenDeleteModal, onOpenEditModal)}
        isFixedSize
      />
    </TableWrapper>
  );
};

export default ContactTable;
