import Button from '@atlaskit/button';
import React, { ReactNode } from 'react';
import { PopUpContentContainer } from '../../../styles/styles';

const PopupContent = ({
  id,
  onOpenModal,
  children = <></>,
  btnName,
}: {
  id: string;
  onOpenModal?: (id: string) => void;
  children?: ReactNode;
  btnName: string;
}) => {
  return (
    <PopUpContentContainer className={'list-popup-content'}>
      {children}
      {onOpenModal ? (
        <Button className={'popup-item'} onClick={() => onOpenModal(id)} testId={'delete-button'}>
          {btnName}
        </Button>
      ) : null}
    </PopUpContentContainer>
  );
};

export default PopupContent;
