import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';

import iContactCompanyProduct from '../../types/contactCompany/iContactCompanyProduct';
import iProduct from '../../types/product/iProduct';
import BasicModalBody from '../asyncContactCompany/createModalBody/BasicModalBody';
import CCPModalCommonFields from './CCPModalCommonFields';
import AsyncSearchWithController from '../form/hookForms/AsyncSearchWithController';
import { getProductListAsyncSearch } from '../../services/product/ProductService';
import { SpaceVerticalContainer } from '../styles/styles';

const CCPModal = ({
  control,
  errors,
  onChange,
  watch,
  target,
  fixedProduct,
  isCustomer,
}: {
  // eslint-disable-next-line
  control: Control<Record<string, any>>;
  // eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  // eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  // eslint-disable-next-line
  watch: (names?: string | string[]) => any;
  target?: iContactCompanyProduct;
  fixedProduct?: iProduct;
  isCustomer: boolean;
}) => {
  if (fixedProduct) {
    return (
      <Page>
        <BasicModalBody
          defaultValue={target?.contactCompany}
          onChange={onChange}
          control={control}
          errors={errors}
          watch={watch}
          isCustomer={isCustomer}
          verticalSpaceBeforeSelect={'two'}
        >
          <CCPModalCommonFields
            control={control}
            errors={errors}
            onChange={onChange}
            watch={watch}
            measurementLabel={isCustomer ? 'Customer' : 'Supplier'}
            fixedProduct={fixedProduct}
            target={target}
          />
          {/* // space for async search company */}
          <SpaceVerticalContainer className={'two'} data-testid={'SpaceVerticalContainer'} />
        </BasicModalBody>
      </Page>
    );
  }
  return (
    <>
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <AsyncSearchWithController
            name={'product'}
            label={'Product'}
            defaultValue={target?.product}
            control={control}
            onChange={onChange}
            promiseFn={(keyword: string) =>
              getProductListAsyncSearch({
                like: `productCode:${keyword},name:${keyword}`,
              })
            }
            optionLabel={['productCode', 'name']}
            errors={errors}
            isRequired
            testId={'product'}
          />
        </GridColumn>
      </Grid>
      <CCPModalCommonFields
        control={control}
        errors={errors}
        onChange={onChange}
        watch={watch}
        measurementLabel={isCustomer ? 'Customer' : 'Supplier'}
        fixedProduct={fixedProduct}
        target={target}
      />
      {/* // space for async search product */}
      <SpaceVerticalContainer className={'five'} data-testid={'SpaceVerticalContainer'} />
    </>
  );
};

export default CCPModal;
