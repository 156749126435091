import AppService from '../../AppService';
import { iChannelJobEntity } from '../../../types/integration/iChannelJob';

const getMyobEntities = (entityName: string, searchColumns: string[]) => (channelId: string, keyword: string) => {
  const myobFilterStr = searchColumns.map(column => `substringof('${keyword}', ${column}) eq true`).join(' or ');
  const filterStr = searchColumns.length > 0 ? `?$filter=${myobFilterStr}` : '';
  return AppService.get(`/channel/${channelId}/${entityName}${filterStr}`).then(({ data }) => {
    return { data: data.Items };
  });
};

const getMyobCustomers = (channelId: string, keyword: string) => {
  return getMyobEntities('customer', ['FirstName', 'LastName', 'CompanyName'])(channelId, keyword);
};

const getMyobSuppliers = (channelId: string, keyword: string) => {
  return getMyobEntities('supplier', ['FirstName', 'LastName', 'CompanyName'])(channelId, keyword);
};

const getMyobTaxRates = (channelId: string, keyword: string) => {
  return getMyobEntities('taxRate', ['Code'])(channelId, keyword);
};

const getMyobProducts = (channelId: string, keyword: string) => {
  return getMyobEntities('product', ['Name', 'Number'])(channelId, keyword);
};

const getMyobInvoices = (channelId: string, keyword: string) => {
  return getMyobEntities('invoice', [])(channelId, keyword);
};

const getMyobSalesOrders = (channelId: string, keyword: string) => {
  return getMyobEntities('salesOrder', [])(channelId, keyword);
};

const getMyobPurchaseOrders = (channelId: string, keyword: string) => {
  return getMyobEntities('purchaseOrder', ['Number', 'SupplierInvoiceNumber'])(channelId, keyword);
};

const createMyobInvoice = async (channelId: string, salesOrderId: string): Promise<iChannelJobEntity> => {
  return AppService.post(`/channel/${channelId}/invoice`, {
    entityId: salesOrderId,
  }).then(({ data }) => data);
};

const updateMyobInvoice = async (
  channelId: string,
  salesOrderId: string,
  externalId: string,
): Promise<iChannelJobEntity> => {
  return AppService.put(`/channel/${channelId}/invoice/${externalId}`, {
    entityId: salesOrderId,
  }).then(({ data }) => data);
};

const createMyobPurchaseOrder = async (channelId: string, purchaseOrderId: string): Promise<iChannelJobEntity> => {
  return AppService.post(`/channel/${channelId}/purchaseOrder`, {
    entityId: purchaseOrderId,
  }).then(({ data }) => data);
};

const updateMyobPurchaseOrder = async (
  channelId: string,
  salesOrderId: string,
  externalId: string,
): Promise<iChannelJobEntity> => {
  return AppService.put(`/channel/${channelId}/purchaseOrder/${externalId}`, {
    entityId: salesOrderId,
  }).then(({ data }) => data);
};

const getCompanyProfile = (channelId: string) => {
  return AppService.get(`/channel/${channelId}/companyFile`).then(({ data }) => data);
};

export default {
  getCompanyProfile,
  getMyobCustomers,
  getMyobSuppliers,
  getMyobTaxRates,
  getMyobProducts,
  getMyobInvoices,
  getMyobSalesOrders,
  getMyobPurchaseOrders,

  createMyobInvoice,
  updateMyobInvoice,
  createMyobPurchaseOrder,
  updateMyobPurchaseOrder,
};
