import { iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';
import iProductImage from '../../types/product/iProductImage';
import { iPaginatedData } from '../../types/iPaginatedData';

const endPoint = '/productImage';

const getImages = (config?: iParams): Promise<iPaginatedData<iProductImage>> => {
  return AppService.get(endPoint, config).then(({ data }) => data);
};

const createImage = (postData: FormData): Promise<iProductImage> => {
  return AppService.uploadImage(endPoint, postData).then(({ data }) => data);
};

const updateImage = (id: string, postData = {}): Promise<iProductImage> => {
  return AppService.put(`${endPoint}/${id}`, postData).then(({ data }) => data);
};

const deleteImage = (id: string): Promise<iProductImage> => {
  return AppService.delete(`${endPoint}/${id}`).then(({ data }) => data);
};

export default {
  getImages,
  createImage,
  updateImage,
  deleteImage,
};
