/* eslint-disable @typescript-eslint/no-explicit-any */
export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getSortOrderArrToUpdate = (list: any[]) => {
  return list.map(cur => {
    return cur.cells
      .filter((cell: any) => cell.type === 'sortOrder')
      .reduce(
        (acc: any, curCell: any) => ({
          id: cur.key,
          sortOrder: curCell.key,
        }),
        {},
      );
  });
};
