import React, { useState, useEffect } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { useSelector } from 'react-redux';
import iProduct from '../../../../types/product/iProduct';
import iAcquisitionOrderItem from '../../../../types/acquisitionOrder/iAcquisitionOrderItem';
import iContactCompanyProduct from '../../../../types/contactCompany/iContactCompanyProduct';
import AddOrderItem from '../../../../shared/addOrderItem/AddOrderItem';
import TotalPrice from '../../../../shared/totalPrice/TotalPrice';
import Taxbase from '../Taxbase';
import DeleteByRetype from '../../../../shared/deleteWithReType/DeleteByReType';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import {
  createAOItem,
  deleteAOItem,
  getAOItemList,
  updateAOItem,
} from '../../../../services/AcquisitionOrderItemService';
import { getHeads, handleNullException, mapToLabelValuePair } from '../../../../services/UtilsService';
import { AO_DETAIL_PAGE_NAME, AO_ITEM_COLUMNS } from '../../shared/AO.constants';
import { getRows } from './TableHelper';
import { iTaxBase, iTaxRate } from '../../../../types/iTaxRate';
import { getTaxBases, getTaxRates } from '../../../../services/Settings/TaxService';
import { FlexContainer } from '../../../../shared/styles/styles';
import { isPriceRelateField } from '../../shared/AO.utils';
import iAcquisitionOrder from '../../../../types/acquisitionOrder/iAcquisitionOrder';
import { getAODetail, updateAO } from '../../../../services/AcquisitionOrderService';
import { apiErrorToast } from '../../../../shared/toast/Toast';
import { iConfigColumn, iKeyValuePairs } from '../../../../shared/UITypes/types';
import { AOItemsTableWrapper } from '../../shared/AO.styles';
import { RootState } from '../../../../redux/makeReduxStore';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCTS } from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

type iState = {
  taxRates: Array<iTaxRate>;
  taxBases: Array<iTaxBase>;
  LocalAODetail?: iAcquisitionOrder;
  isLoading: boolean;
};

const AOItems = ({
  AOId,
  LocalAODetail,
  updateAOItems,
  canUpdate = true,
  canDelete = true,
  canCreate = true,
}: {
  AOId: string;
  // onRefreshAODetail: () => void;
  LocalAODetail?: iAcquisitionOrder;
  updateAOItems: (items: Array<iAcquisitionOrderItem>) => void;
  canUpdate?: boolean;
  canDelete?: boolean;
  canCreate?: boolean;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadProducts = AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user);
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenDeleteModal,
    onEdit,
    onCreate,
  } = useListCrudHook<iAcquisitionOrderItem>({
    getFn: getAOItemList,
    deleteFn: deleteAOItem,
    createFn: createAOItem,
    updateFn: updateAOItem,
    paginationApplied: true,
    sort: 'createdAt:DESC',
    filter: `orderId:${AOId}`,
    createCallBack: (createdId: string) => setTimeout(() => document.getElementById(`${createdId}:qty`)?.click(), 200),
  });
  const initialState: iState = {
    taxBases: [],
    taxRates: [],
    LocalAODetail,
    isLoading: false,
  };
  const [supState, setSupState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      try {
        const taxRates = await getTaxRates();
        const taxBases = await getTaxBases();
        if (isCancelled) return;
        setSupState(prevState => ({ ...prevState, taxRates, taxBases }));
      } catch (error) {
        apiErrorToast(error);
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(
    () => {
      updateAOItems(state.data);
    },
    //  eslint-disable-next-line
    [state.data.length, updateAOItems],
  );

  const reloadLocalAoDetail = async (data?: iKeyValuePairs) => {
    if (typeof supState.LocalAODetail === 'undefined') return;
    setSupState({ ...supState, isLoading: true });
    try {
      if (typeof data !== 'undefined') {
        await updateAO(AOId, data);
      }
      const reloaded = await getAODetail(AOId);
      setSupState({ ...supState, isLoading: false, LocalAODetail: reloaded });
    } catch (error) {
      apiErrorToast(error);
      setSupState({ ...supState, isLoading: false });
    }
  };

  const onEditItem = async (
    id: string,
    fieldName: string,
    //  eslint-disable-next-line
    fieldValue: any,
  ) => {
    await onEdit(id, { [fieldName]: fieldValue });
    if (isPriceRelateField(fieldName)) {
      await reloadLocalAoDetail();
    }
  };

  const onDeleteItem = async (id: string) => {
    await onDelete(id);
    await reloadLocalAoDetail();
  };

  // const onCreateItem = (product: iProduct | null) => {
  //   if (!product) return;
  //   onCreate({ productId: product.id, orderId: AOId });
  // };
  const onSelect = async (payload: iProduct | iContactCompanyProduct, isCCP = false) => {
    if (!payload) return;
    const productId = isCCP ? handleNullException(payload, 'productId') : handleNullException(payload, 'id');
    await onCreate({ orderId: AOId, productId });
  };

  const getFilteredColumns = (cols: iConfigColumn[]) => {
    return cols
      .filter(col => {
        return !(col.key === 'delete' && !canDelete);
      })
      .map(col => {
        if (col.key === 'product.productCode' && !canReadProducts) {
          return {
            ...col,
            type: 'text-secondary',
          };
        }
        return {
          ...col,
          isDisabled: !canUpdate,
        };
      });
  };

  return (
    <>
      <FlexContainer className={'space-between'}>
        <div />
        <Taxbase
          name={'taxBaseId'}
          value={supState.LocalAODetail?.taxBaseId}
          options={mapToLabelValuePair(supState.taxBases)}
          onChange={(name: string, value: string) => reloadLocalAoDetail({ [name]: value })}
          isDisabled={!canUpdate}
        />
      </FlexContainer>
      <AOItemsTableWrapper>
        <DynamicTable
          head={getHeads(getFilteredColumns(AO_ITEM_COLUMNS), AO_DETAIL_PAGE_NAME)}
          rows={getRows({
            data: state.data,
            onOpenDeleteModal,
            onEditItem,
            columns: getFilteredColumns(AO_ITEM_COLUMNS),
            taxRates: supState.taxRates,
          })}
          testId={AO_DETAIL_PAGE_NAME}
          isLoading={state.isLoading}
        />
      </AOItemsTableWrapper>
      <FlexContainer className={'space-between flex-start'}>
        {/* <CreateByAsyncProduct
          onSelect={onCreateItem}
          filter={'isForPurchase:true'}
        /> */}
        <AddOrderItem order={LocalAODetail} isCustomer={false} onSelect={onSelect} isDisabled={!canCreate} />
        <TotalPrice
          subTotal={supState.LocalAODetail?.subTotal || undefined}
          taxAmt={supState.LocalAODetail?.taxAmt || undefined}
          totalAmt={supState.LocalAODetail?.totalAmt || undefined}
        />
      </FlexContainer>

      {edit.delTargetId && (
        <DeleteByRetype
          isOpen={edit.isModalOpen}
          onClose={onCloseModal}
          onConfirm={() => edit.delTargetId && onDeleteItem(edit.delTargetId)}
          answer={handleNullException(edit, 'target.product.productCode')}
          isConfirming={state.isConfirming}
        />
      )}
    </>
  );
};

export default AOItems;
