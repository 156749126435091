import React from 'react';
import Button from '@atlaskit/button';
import { BtnTransparentWrapper } from '../styles/styles';

const TransparentIconBtn = ({
  icon,
  iconAfter,
  onClick,
  btnName,
  testId = 'transparent-icon-button',
}: {
  icon: React.ReactChild;
  iconAfter?: React.ReactChild;
  onClick: () => void;
  btnName?: string;
  testId?: string;
}) => (
  <BtnTransparentWrapper>
    <Button
      testId={testId}
      spacing={'none'}
      onClick={onClick}
      iconBefore={icon}
      iconAfter={iconAfter}
      className={'transparent-button'}
    >
      {btnName}
    </Button>
  </BtnTransparentWrapper>
);
export default TransparentIconBtn;
