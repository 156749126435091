import AppService from './AppService';

type iParams = {
  [key: string]: string;
};
export const printRollLabel = (jobId: string, params: iParams) => {
  return AppService.get(`/jobPrint/${jobId}/rollLabel`, { ...params }).then(({ data: { url } }) => url);
};
export const printCoreLabel = (jobId: string, params: iParams) => {
  return AppService.get(`/jobPrint/${jobId}/coreLabel`, { ...params }).then(({ data: { url } }) => url);
};
export const printPalletLabel = (jobId: string, params: iParams) => {
  return AppService.get(`/jobPrint/${jobId}/palletLabel`, { ...params }).then(({ data: { url } }) => url);
};

export const printEnvelopLabel = (jobId: string, params: iParams) => {
  return AppService.get(`/jobPrint/${jobId}/envelopLabel`, { ...params }).then(({ data: { url } }) => url);
};

export const printCertificateOfConformance = (jobId: string, params: iParams) => {
  return AppService.get(`/jobPrint/${jobId}/certificateOfConformance`, { ...params }).then(({ data: { url } }) => url);
};

export const printJobCard = (jobId: string) => {
  return AppService.get(`/jobPrint/${jobId}/jobCard`).then(({ data: { url } }) => url);
};

export const printSalesOrder = (id: string) => {
  return AppService.post('/orderPrint/salesOrder', { id }).then(({ data: { url } }) => url);
};

export const printPurchaseOrder = (id: string) => {
  return AppService.post('/orderPrint/purchaseOrder', { id }).then(({ data: { url } }) => url);
};

export const printLocationLabel = (id: string) => {
  return AppService.get(`/warehouseLocation/label/${id}`).then(({ data: { url } }) => url);
};

export const printDispatchNote = (id: string) => {
  return AppService.post(`/dispatchNotePrint/dispatchNote/${id}`, {}).then(({ data: { url } }) => url);
};

export const printDispatchNoteManifest = (ids: string[]) => {
  return AppService.post('/dispatchNotePrint/manifest', {
    dispatchNoteIds: ids,
  }).then(({ data: { url } }) => url);
};

export const printAcquisitionOrder = (id: string) => {
  return AppService.post('/orderPrint/acquisitionOrder', { id }).then(({ data: { url } }) => url);
};

export const printNCR = (id: string) => {
  return AppService.get(`/issuePrint/${id}/ncr`, { id }).then(({ data: { url } }) => url);
};

export default {
  printRollLabel,
  printCoreLabel,
  printPalletLabel,
  printEnvelopLabel,
  printJobCard,
  printSalesOrder,
  printPurchaseOrder,
  printLocationLabel,
  printDispatchNote,
  printDispatchNoteManifest,
  printAcquisitionOrder,
  printNCR,
};
