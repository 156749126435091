import React, { useState } from 'react';
import _ from 'lodash';
import { AsyncSelect } from '@atlaskit/select';

type iSingleSelectOption<T> = iOption<T> | null | undefined;

type iOption<T> = {
  label: string;
  value: T;
};

type iProps<T> = {
  onSelect: (payload: T | null) => void;
  //  eslint-disable-next-line
  promiseFn: (keyword: string) => Promise<any>;
  searchBarPlaceholder?: string;
  shouldControlRenderValue?: boolean;
  formatOptionLabel: (option: iSingleSelectOption<T>) => React.ReactNode;
  clearAfterSelect?: boolean;
  isDisabled?: boolean;
};

const AsyncSearchFormOption = <T extends { id: string }>({
  onSelect,
  promiseFn,
  searchBarPlaceholder,
  shouldControlRenderValue = false,
  formatOptionLabel,
  clearAfterSelect = true,
  isDisabled,
}: iProps<T>) => {
  const [value, setValue] = useState<iSingleSelectOption<T>>();

  const promiseOptions = (inputValue: string, callback: (options: iOption<T>[]) => void) => {
    promiseFn(inputValue).then(({ data: items }) => {
      callback(
        items.map((item: T) => ({
          label: item.id,
          value: item,
        })),
      );
    });
  };

  const debouncedOptions = _.debounce(promiseOptions, 700);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (newSelectInput: any) => {
    if (newSelectInput === null || typeof newSelectInput === 'undefined') {
      onSelect(null);
      setValue(null);
      return;
    }
    onSelect(_.get(newSelectInput, 'value'));
    if (clearAfterSelect) {
      setValue(null);
      return;
    }
    setValue(newSelectInput as iSingleSelectOption<T>);
  };

  return (
    <>
      <AsyncSelect
        isClearable
        value={value}
        loadOptions={debouncedOptions}
        placeholder={searchBarPlaceholder || 'Search...'}
        onChange={onChange}
        controlShouldRenderValue={shouldControlRenderValue}
        autoFocus
        formatOptionLabel={formatOptionLabel}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default AsyncSearchFormOption;
