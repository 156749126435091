import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';
import LocalStorageService from './LocalStorageService';

export const getLocalAuthToken = () => {
  const token = LocalStorageService.getToken();
  return typeof token !== 'string' ? '' : token;
};

export const removeLocalAuthToken = () => {
  LocalStorageService.removeToken();
};

export const login = (username: string, password: string) => {
  return AppService.post('/auth', { username, password }).then(({ data }) => {
    LocalStorageService.setToken(data.token);
    LocalStorageService.setCompanyId(data.user.companyId);
    return { user: data.user, isAuthenticated: true };
  });
};

export const logout = () => {
  return AppService.delete('/auth').then(res => {
    removeLocalAuthToken();
    LocalStorageService.removeCompanyId();
    return res;
  });
};

export const getMe = () => {
  return AppService.get('/auth/getMe').then(({ data }) => data);
};

export const signUp = (config: iParams) => {
  return AppService.post('/auth/signUp', config).then(({ data }) => data);
};

export const validateTempToken = (token: string) => {
  return AppService.post('/auth/validateTempToken', { token }).then(
    ({ data }) => data,
  );
};
export const retrieval = (config: iParams) => {
  return AppService.post('/auth/retrieval', config).then(({ data }) => data);
};

export const resetPassword = (config: iParams) => {
  return AppService.post('/auth/resetPassword', config).then(
    ({ data }) => data,
  );
};
export const resendToken = (config: iParams) => {
  return AppService.post('/auth/resendTempToken', config).then(
    ({ data }) => data,
  );
};
export const activateCompany = (config: iParams) => {
  return AppService.post('/auth/activateCompany', config).then(
    ({ data }) => data,
  );
};

export const initializeCompany = (userId: string) => {
  return AppService.post('/auth/initializeCompany', { userId }).then(
    ({ data }) => data,
  );
};
export default {
  getLocalAuthToken,
  removeLocalAuthToken,
  login,
  logout,
  getMe,
  initializeCompany,
  activateCompany,
  resendToken,
  resetPassword,
  retrieval,
  validateTempToken,
  signUp,
};
