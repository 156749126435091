import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import useListLengthHook from '../../../shared/hooks/useListLengthHook/useListLengthHook';
import { getJobs } from '../../../services/JobService';
import { SpaceHorizontalContainer } from '../../../shared/styles/styles';

const PartiallyFinished = ({
  onClick,
  btnName = 'Partially Finished',
  filter,
}: {
  onClick: () => void;
  btnName?: string;
  filter: string;
}) => {
  const getFn = useCallback(
    () => (filter ? getJobs(1, 1, { filter }) : undefined),
    [filter],
  );

  const { state } = useListLengthHook({
    getFn,
    notRenderWithoutConfig: false,
  });

  if (typeof state.length === 'number' && state.length > 0) {
    return (
      <>
        <Button
          onClick={onClick}
          spacing={'none'}
          appearance={'danger'}
          testId={'partially-finished-button'}
        >
          {state.length} {btnName}
        </Button>
        <SpaceHorizontalContainer className={'half'} />
      </>
    );
  }
  return null;
};

export default PartiallyFinished;

/* return (
    <Button onClick={onClickPartially} isSelected={isSelected}>
      {btnName}
    </Button>
  ); */
