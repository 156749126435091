import React, { useState } from 'react';
import Button from '@atlaskit/button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import iProduct from '../../../../types/product/iProduct';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import DashboardCreatePOModalBody from './DashboardCreatePOModalBody';
import { PURCHASES_URL } from '../../../../shared/UrlMap';
import { createPurchaseOrder } from '../../../../services/PurchaseService';
import { handleNullException } from '../../../../services/UtilsService';
import { createPOItemConfig } from '../../../../services/PurchaseOrderItemService';
import { addToastForAPIResponse, apiErrorToast } from '../../../../shared/toast/Toast';

type iState = {
  isOpen: boolean;
  isConfirming: boolean;
};
const initialState: iState = {
  isOpen: false,
  isConfirming: false,
};
const CreatePO = ({ products }: { products: Array<iProduct> }) => {
  const [state, setState] = useState(initialState);
  const history = useHistory();
  const openModal = () => setState({ ...state, isOpen: true });
  const closeModal = () => setState({ ...state, isOpen: false });
  const { control, setValue, errors, handleSubmit, watch } = useForm({});

  //  eslint-disable-next-line
  const onSubmit = async (data: any) => {
    setState({ ...state, isConfirming: true });
    try {
      const { id } = await createPurchaseOrder(data);
      for (let i = 0; i < products.length; i++) {
        const productId = products[i].id;
        const qty = Number(handleNullException(data, `${productId}-qty`));
        //  eslint-disable-next-line
        await createPOItemConfig({
          orderId: id,
          productId,
          qty,
        });
      }
      addToastForAPIResponse('success');
      setState({ ...state, isConfirming: false });
      history.push(`${PURCHASES_URL}/${id}`);
    } catch (e) {
      apiErrorToast(e);
      setState({ ...state, isConfirming: false });
    }
  };

  if (products.length === 0) return null;
  return (
    <>
      <Button isSelected onClick={openModal}>{`Create PO From ${products.length} selected`}</Button>
      <CustomizeModal
        isOpen={state.isOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={closeModal}
        confirmBtnName={'Create'}
        modalBody={
          <DashboardCreatePOModalBody
            control={control}
            errors={errors}
            onChange={setValue}
            watch={watch}
            products={products}
          />
        }
        modalHeading={<span>Create</span>}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
      />
    </>
  );
};

export default CreatePO;
