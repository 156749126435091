import React from 'react';
import { Label } from '@atlaskit/field-base';
import LinkBtnUrl from '../../buttons/LinkBtnUrl';
import InlineBlock from './styles/InlineBlock';
import { LabelValue } from '../../styles/styles';

const VerticalLabel = ({
  label,
  value,
  isFirstChild,
  url,
  className,
}: {
  label: string;
  value: string;
  isFirstChild?: boolean;
  url?: string;
  className?: string;
}) => (
  <InlineBlock className={className}>
    <Label isFirstChild={isFirstChild} label={label} />
    <LabelValue>
      {url ? <LinkBtnUrl btnName={value} url={url} /> : value}
    </LabelValue>
  </InlineBlock>
);

export default VerticalLabel;
