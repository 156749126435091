import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { handleMoney, handleNullException, handleNumber, mapToLabelValuePair } from '../../../../services/UtilsService';
import TransparentIconBtn from '../../../../shared/buttons/TransparentIconBtn';
import { iConfigColumn } from '../../../../shared/UITypes/types';
import iAcquisitionOrderItem from '../../../../types/acquisitionOrder/iAcquisitionOrderItem';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import InlineEdit from '../../../../shared/form/InlineEdit';
import Taxbase from '../Taxbase';
import { iTaxRate } from '../../../../types/iTaxRate';
import ConversionText from '../../../../shared/text/ConversionText';
import { MarginTopWrapper } from '../../../../shared/styles/styles';
import ConversionCalculateText from '../../../../shared/conversionCalculate/ConCalculateText';

export const getRows = ({
  data,
  onOpenDeleteModal,
  onEditItem,
  columns,
  taxRates,
}: {
  data: Array<iAcquisitionOrderItem>;
  onOpenDeleteModal: (id: string) => void;
  onEditItem: (id: string, fieldName: string, fieldValue: string | number | null) => void;
  columns: Array<iConfigColumn>;
  taxRates: Array<iTaxRate>;
}) => {
  return data.map((item: iAcquisitionOrderItem) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type, isDisabled } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'money':
          return {
            key: handleNullException(item, key),
            content: handleMoney(item, key, 2),
          };
        case 'number':
          return {
            key: handleNullException(item, key),
            content: handleNumber(item, key),
          };
        case 'link-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                {isDisabled ? (
                  handleNullException(item, key)
                ) : (
                  <LinkBtnUrl
                    btnName={handleNullException(item, key)}
                    url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
                  />
                )}
              </ComposeSecondaryText>
            ),
          };
        case 'text-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                {handleNullException(item, key)}
              </ComposeSecondaryText>
            ),
          };
        case 'inlineEdit-text':
          return {
            key: handleNullException(item, key),
            content: (
              <InlineEdit
                name={key}
                defaultValue={handleNullException(item, key)}
                onConfirm={(name: string, value: string) => onEditItem(item.id, name, value)}
                hideActionButtons
                isDisabled={isDisabled}
              />
            ),
          };
        case 'inlineEdit-conversion':
          return {
            key: handleNullException(item, key),
            content: (
              <ConversionCalculateText
                name={`${handleNullException(item, 'id')}:${key}`}
                defaultValue={handleNullException(item, key).toString()}
                onConfirm={(name: string, value: string | number) => onEditItem(item.id, key, value)}
                conversion={handleNullException(item, dataSource[0]) || 1}
                productUnit={handleNullException(item, dataSource[2])}
                contactCompanyUnit={
                  handleNullException(item, dataSource[1]) || handleNullException(item, dataSource[2])
                }
                isDisabled={isDisabled}
              />
              // <InlineEdit
              //   name={`${handleNullException(item, 'id')}:${key}`}
              //   defaultValue={handleNullException(item, key).toString()}
              //   //  eslint-disable-next-line
              //   onConfirm={(name: string, value: string) =>
              //     onEditItem(item.id, key, value)
              //   }
              //   postfix={prepareConversionEquation({
              //     value: handleNullException(item, key),
              //     conversion: handleNullException(item, dataSource[0]) || 1,
              //     productUnit: handleNullException(item, dataSource[2]),
              //     contactCompanyUnit:
              //       handleNullException(item, dataSource[1]) ||
              //       handleNullException(item, dataSource[2]),
              //   })}
              //   editingPostfix={
              //     handleNullException(item, dataSource[1]) ||
              //     handleNullException(item, dataSource[2])
              //   }
              //   isRequired
              //   isNumeric
              //   needFormatValue
              //   hideActionButtons
              // />
            ),
          };
        case 'inlineEdit-money':
          return {
            key: handleNullException(item, key),
            content: (
              <InlineEdit
                name={key}
                defaultValue={handleNullException(item, key).toString()}
                onConfirm={(name: string, value: string) => onEditItem(item.id, name, value)}
                hideActionButtons
                isNumeric
                prefix={'$'}
                postfix={` / ${handleNullException(item, dataSource[0]) || handleNullException(item, dataSource[1])}`}
                needFormatValue
                isDisabled={isDisabled}
              />
            ),
          };
        case 'inlineEdit-percentage':
          return {
            key: handleNullException(item, key),
            content: (
              <InlineEdit
                name={key}
                defaultValue={handleNullException(item, key).toString()}
                onConfirm={(name: string, value: string) => onEditItem(item.id, name, value)}
                hideActionButtons
                isNumeric
                postfix={'%'}
                isDisabled={isDisabled}
              />
            ),
          };
        case 'taxRate-select':
          return {
            key: handleNullException(item, key),
            content: (
              <Taxbase
                name={key}
                options={mapToLabelValuePair(taxRates)}
                value={handleNullException(item, key)}
                onChange={(name: string, value: string) => onEditItem(item.id, name, value)}
                minWidth={120}
                isDisabled={isDisabled}
              />
            ),
          };
        case 'conversion-text':
          return {
            key: handleNullException(item, key),
            content: (
              <MarginTopWrapper className={'small'}>
                <ConversionText
                  value={handleNullException(item, key)}
                  conversion={handleNullException(item, dataSource[0])}
                  contactCompanyUnit={
                    handleNullException(item, dataSource[1]) || handleNullException(item, dataSource[2])
                  }
                  productUnit={handleNullException(item, dataSource[2])}
                />
              </MarginTopWrapper>
            ),
          };
        case 'delete':
          return {
            key: handleNullException(item, key),
            content: (
              <TransparentIconBtn
                icon={<TrashIcon label="delete" />}
                onClick={() => onOpenDeleteModal(handleNullException(item, 'id'))}
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'AO-items' };
  });
};
