import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../shared/constants/AsyncConstants';
import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getJobs = (
  currentPage: number,
  perPage: number,
  config: { filter?: string; like?: string; sort?: string },
) => {
  const basicConfig = {
    include:
      'machine,status,product,product.measurement,jobAttributes,operator,product.productAttributeValues,salesOrder.customer,createdBy,updatedBy',
    perPage: perPage.toString(),
    currentPage: currentPage.toString(),
  };
  const addLikeConfig = config.like
    ? {
        ...basicConfig,
        like: config.like,
      }
    : basicConfig;

  const addFilterConfig = config.filter ? { ...addLikeConfig, advancedFilter: config.filter } : addLikeConfig;

  const addSortConfig = config.sort ? { ...addFilterConfig, sort: config.sort } : addFilterConfig;

  return AppService.get('/job', addSortConfig).then(({ data }) => data);
};

export const getJob = (id: string) => {
  return AppService.get(`/job/${id}`, {
    include: 'palletSystem,machine,status,product.measurement,jobAttributes,salesOrder.customer',
  }).then(({ data }) => data);
};

export const getFormTaggableList = (jobId: string) => {
  return AppService.get('/formTaggable', { filter: `jobId:${jobId}` }).then(({ data }) => {
    return data;
  });
};
export const updateJob = (
  jobId: string,
  //  eslint-disable-next-line
  postData: any,
) => {
  return AppService.put(`/job/${jobId}`, postData).then(({ data }) => data);
};

export const updateJobAttributeValue = (
  attributeValueId: string,
  //  eslint-disable-next-line
  postData: any,
) => {
  return AppService.put(`/jobAttributeValue/${attributeValueId}`, postData).then(({ data }) => data);
};

//  eslint-disable-next-line
export const createJobAttributeValue = (postData: any) => {
  return AppService.post('/jobAttributeValue', postData).then(({ data }) => data);
};

export const deactivateJob = (jobId: string) => {
  return AppService.delete(`/job/${jobId}`).then(({ data }) => data);
};

export const getJobCategories = () => {
  return AppService.get('/jobStatusCategory', {
    include: 'jobStatuses',
  }).then(({ data }) => data);
};

// eslint-disable-next-line
export const createJob = (postData: any) => {
  return AppService.post('/job', postData).then(({ data }) => {
    return data;
  });
};

export const getJobShiftJob = (config?: { [key: string]: string }) => {
  return AppService.get('/jobShiftJob', {
    include: 'jobShiftWastes,jobShift,jobShift.operator,jobShiftWastes.product.measurement,job.product.measurement',
    ...config,
  }).then(({ data }) => data);
};

export const deleteJobShiftJob = (id: string) => {
  return AppService.delete(`/jobShiftJob/${id}`).then(({ data }) => data);
};

//  async search force pagination
export const getJobListAsyncSearch = (config?: iParams) => {
  return AppService.get('/job', {
    ...config,
    include: 'product.measurement,salesOrder',
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

export const getJobStatuses = (config?: iParams) => {
  return AppService.get('/jobStatus', config).then(({ data }) => data);
};

export const getJobStatusListAsyncSearch = (config?: iParams) => {
  return AppService.get('/jobStatus', {
    ...config,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

export const reloadJobBom = (jobId: string) => {
  return AppService.put(`/job/${jobId}/reloadBOM`, {}).then(({ data }) => data);
};
/**
 * @param config can't avoid include
 * need to refactor list crud hook to accept customized include
 * @returns
 */
export const getJobListConfig = (config?: iParams) => {
  return AppService.get('/job', {
    include: 'product.measurement,salesOrder.status,salesOrder.shippingAddress,salesOrderItem.measurement,status',
    ...config,
  }).then(({ data }) => data);
};
export default {
  getJobs,
  getJob,
  getFormTaggableList,
  updateJob,
  createJobAttributeValue,
  updateJobAttributeValue,
  deactivateJob,
  getJobCategories,
  createJob,
  getJobShiftJob,
  deleteJobShiftJob,
  getJobListAsyncSearch,
  getJobStatuses,
  getJobStatusListAsyncSearch,
  reloadJobBom,
};
