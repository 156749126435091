import React from 'react';
import { ButtonGroup } from '@atlaskit/button';
import iProduct from '../../../../types/product/iProduct';
import AsyncSearchFormOptionLabel from '../../../../shared/asyncSearch/AsyncSearchFormOptionLabel';
import { FlexContainer } from '../../../../shared/styles/styles';
import { getProductOptionLabel } from '../../../NCR/detail/shared/NCRDetail.utils';
import { SearchWrapper } from '../../shared/StockTake.style';
import { getProductListAsyncSearch } from '../../../../services/product/ProductService';

const FoundItemOperation = ({
  onSelect,
  children,
  isDisabled,
}: {
  onSelect: (selected: iProduct | null) => void;
  children: React.ReactNode;
  isDisabled?: boolean;
}) => {
  return (
    <FlexContainer className={'space-between'}>
      <SearchWrapper>
        <AsyncSearchFormOptionLabel
          onSelect={onSelect}
          promiseFn={(keyword: string) =>
            getProductListAsyncSearch({
              like: `productCode:${keyword},name:${keyword}`,
            })
          }
          searchBarPlaceholder={'Search by productCode/name...'}
          formatOptionLabel={getProductOptionLabel}
          isDisabled={isDisabled}
        />
      </SearchWrapper>
      <ButtonGroup>
        {/* <Button appearance={'default'} onClick={onOpenCancelModal}>
          Cancel
        </Button> */}
        {children}
      </ButtonGroup>
    </FlexContainer>
  );
};

export default FoundItemOperation;
