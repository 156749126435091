import styled from 'styled-components';
import { SELECT_SO_ITEMS } from './CreateDNItem.constants';

export const CreateTableWrapper = styled.div`
  th {
    padding-left: 4px;
    padding-right: 4px;
  }
  th[data-testid='${SELECT_SO_ITEMS}-column-product.productCode--head--cell'] {
    width: 12rem;
    min-width: 12rem;
  }
  th[data-testid='${SELECT_SO_ITEMS}-column-unitPrice--head--cell'] {
    width: 8rem;
    min-width: 8rem;
  }
  th[data-testid='${SELECT_SO_ITEMS}-column-operation--head--cell'] {
    width: 3rem;
    min-width: 2rem;
  }
`;
