import axios from 'axios';
import { iKeyValuePairs } from '../shared/UITypes/types';
import LocalStorageService from './LocalStorageService';
import { removeLastSlashFromUrl } from './UtilsService';

type iConfigParams = {
  [key: string]: string;
};
type iParams = {
  [key: string]: string | boolean | number | null | undefined | string[];
};

export const OP_AND = 'OP_AND';
export const OP_OR = 'OP_OR';
export const OP_LIKE = 'OP_LIKE';
export const OP_EQ = 'OP_EQ';
export const OP_GTE = 'OP_GTE';
export const OP_GT = 'OP_GT';
export const OP_LT = 'OP_LT';
export const OP_LTE = 'OP_LTE';
export const OP_NOT = 'OP_NOT';

const getEndPointUrl = (url: string) => {
  return `${removeLastSlashFromUrl(process.env.REACT_APP_API_END_POINT || '')}${url}`;
};

const getHeaders = () => {
  const token = LocalStorageService.getToken();
  if (!token || token === '') {
    return undefined;
  }
  const companyId = LocalStorageService.getCompanyId();
  if (!companyId || companyId === '') {
    return undefined;
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      comid: companyId,
    },
  };
};

const getUrlParams = (params: iConfigParams = {}) => {
  const paramString =
    typeof params === 'object' && Object.keys(params).length > 0 ? new URLSearchParams(params).toString() : '';
  return paramString === '' ? '' : `?${paramString}`;
};

const get = (url: string, params: iConfigParams = {}) => {
  const urlString = `${getEndPointUrl(url)}${getUrlParams(params)}`;
  return axios.get(urlString, getHeaders());
};

const post = (url: string, params: iParams) => {
  return axios.post(getEndPointUrl(url), params, getHeaders());
};

const put = (url: string, params: iKeyValuePairs) => {
  return axios.put(getEndPointUrl(url), params, getHeaders());
};

const remove = (url: string, params: iConfigParams = {}) => {
  return axios.delete(`${getEndPointUrl(url)}${getUrlParams(params)}`, getHeaders());
};
const getUploadHeaders = () => {
  const token = LocalStorageService.getToken();
  if (!token || token === '') {
    return undefined;
  }
  const companyId = LocalStorageService.getCompanyId();
  if (!companyId || companyId === '') {
    return undefined;
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
      comid: companyId,
    },
  };
};
const uploadImage = (url: string, params: FormData) => {
  return axios.post(getEndPointUrl(url), params, getUploadHeaders());
};

export default {
  get,
  post,
  put,
  delete: remove,
  uploadImage,
};
