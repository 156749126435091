import React from 'react';
import styled from 'styled-components';
import { LoadingButton } from '@atlaskit/button';
import { Section } from '@atlaskit/menu';
import {
  PRINT_CERTIFICATE_OF_CONFORMANCE,
  PRINT_CORE_LABEL,
  PRINT_ENVELOP_LABEL,
  PRINT_JOB_CARD,
  PRINT_PALLET_LABEL,
  PRINT_ROLL_LABELS,
} from '../../constants';

const PopupWrapper = styled.div`
  width: 150px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  & > div {
    margin: 0 4px;
  }
  .print-item {
    display: block;
    background-color: #ffffff;
    color: #172b4d !important;
    font-weight: 400;
  }
`;
const items = [
  [PRINT_JOB_CARD],
  [PRINT_ROLL_LABELS, PRINT_PALLET_LABEL, PRINT_CORE_LABEL, PRINT_ENVELOP_LABEL, PRINT_CERTIFICATE_OF_CONFORMANCE],
];

//  eslint-disable-next-line
const PrintsMenu = ({
  onSelectPrintItem,
  selected,
  isLoading = false,
}: {
  onSelectPrintItem: (str: string) => void;
  selected?: string;
  isLoading?: boolean;
}) => {
  return (
    <>
      <PopupWrapper data-testid={'print-popup'}>
        {items.map((item: Array<string>, index: number) => (
          <Section hasSeparator={index !== 0} key={`print-section-${item[0]}`} testId={`print-section-${item[0]}`}>
            {item.map((str: string) => (
              <LoadingButton
                onClick={() => onSelectPrintItem(str)}
                className={'print-item'}
                key={`print-item-${str}`}
                isLoading={selected === str && isLoading}
                testId={`print-item-${str}`}
              >
                {str}
              </LoadingButton>
            ))}
          </Section>
        ))}
      </PopupWrapper>
    </>
  );
};
export default PrintsMenu;
