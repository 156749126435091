import React, { useState } from 'react';
import styled from 'styled-components';
import { LoadingButton } from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import { colors } from '@atlaskit/theme';
import { apiErrorToast } from '../toast/Toast';

const BtnTransparentWrapper = styled.div`
  display: flex;
  .transparent-button {
    background-color: ${colors.backgroundActive};
  }
`;
type iState = {
  isLoading: boolean;
};
const initialState: iState = {
  isLoading: false,
};
/**
 *
 * if necessary, can pass in the icon/btnName to make it more generic
 */
const CreateBtnPlusIcon = ({
  createFn,
  onCreateCallback,
}: {
  //  eslint-disable-next-line
  createFn: () => Promise<any>;
  onCreateCallback?: (id?: string) => void;
}) => {
  const [state, setState] = useState(initialState);
  const onCreate = async () => {
    setState({ ...state, isLoading: true });
    try {
      const created = await createFn();
      setState({ ...state, isLoading: false });
      if (typeof onCreateCallback === 'function') {
        onCreateCallback(created ? created.id : undefined);
      }
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isLoading: false });
    }
  };
  return (
    <BtnTransparentWrapper>
      <LoadingButton
        testId={'transparent-icon-button'}
        spacing={'none'}
        onClick={onCreate}
        iconBefore={<AddIcon label="buy" primaryColor={'#42526e'} />}
        className={'transparent-button'}
        isLoading={state.isLoading}
      />
    </BtnTransparentWrapper>
  );
};

export default CreateBtnPlusIcon;
