import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import styled from 'styled-components';
import InputWithController from '../../../form/hookForms/InputWithController';
import SelectWithController from '../../../form/hookForms/SelectWithController';
import { LOGO_OPTIONS } from '../../constants';

const PalletLabelWrapper = styled.div`
  margin-bottom: 6rem;
`;
const PalletLabelModalBody = ({
  control,
  errors,
  onChange,
  defaultQty,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  defaultQty: number;
}) => {
  return (
    <PalletLabelWrapper data-testid={'modal-body-pallet-system'}>
      <InputWithController
        name={'totalQty'}
        label={'Quantity'}
        defaultValue={defaultQty}
        control={control}
        onChange={onChange}
        errors={errors}
        isNumeric
        testId={'print-pallet-total-qty'}
      />
      <SelectWithController
        name={'includeLogo'}
        label={'Print With Logo or not'}
        control={control}
        options={LOGO_OPTIONS}
        defaultValue={'true'}
        onChange={onChange}
        errors={errors}
        testId={'print-pallet-system-logo'}
      />
    </PalletLabelWrapper>
  );
};

export default PalletLabelModalBody;
