/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import iAddress from '../../../../types/contactCompany/iAddress';
import iContactCompany from '../../../../types/contactCompany/iContactCompany';
import iContactCompanyType from '../../../../types/contactCompany/iContactCompanyType';
import PreviewStatus from './PreviewStatus';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import AddressService from '../../../../services/AddressService';
import { handleNullException, handleNullExceptionMultiFields, hasKey } from '../../../../services/UtilsService';
import { iParams } from '../../../../shared/UITypes/types';
import { createCompanyAddress } from '../../../../services/ContactCompanyAddressService';
import { ADDRESS_CONFIG, CONTACTCOMPANY_CONFIG } from './Template.constants';
import { createContactCompanyConfig } from '../../../../services/ContactCompanyService';
import { iImportDataResult, iPrefills, iTemplateColumn } from './DataImport.types';
import { COMPONENT_MAP, CONTACT_TYPE, PAGE_CUSTOMIZE_PARAMETER } from './DataImport.constants';

export const getTypeIdByPageName = (pageName: string, types: Array<iContactCompanyType>) => {
  return types.find(
    (type: iContactCompanyType) =>
      type.name.toUpperCase() === (handleNullException(CONTACT_TYPE, pageName) as string).toUpperCase(),
  )?.id;
};

export const previewContactCompany = (
  data: any[][],
  configColumns: Array<iTemplateColumn>,
  //  eslint-disable-next-line
  prefills?: any,
) => {
  const previewResults: Array<iImportDataResult> = data.map((row: any[]) => {
    const messages = configColumns.map((column: iTemplateColumn, columnIndex: number) => {
      const { validation, errorMessage } = column;
      if (validation && !validation(row[columnIndex])) {
        return errorMessage;
      }
      return undefined;
    });
    const errorMessages = messages.filter((message: string | undefined) => message);
    if (errorMessages.length > 0) {
      return { skipped: true, messages: errorMessages as Array<string> };
    }
    return { previewSuccess: true };
  });
  return previewResults;
};

export const onSubmitContactCompany = async (
  pageName: string,
  data: any[][],
  recordResult: (eachResult: iImportDataResult) => void,
  previewResults: Array<iImportDataResult>,
  prefills?: iPrefills,
) => {
  const typeId = handleNullException(prefills, 'typeId');
  if (!typeId) return;

  for (let i = 0; i < data.length; i++) {
    if (previewResults[i].skipped) {
      recordResult(previewResults[i]);
      //  eslint-disable-next-line
      continue;
    }
    try {
      const { contactCompany, address } = CONTACTCOMPANY_CONFIG.reduce(
        (acc: { contactCompany: iParams; address: iParams }, cur: iTemplateColumn, curIndex: number) =>
          ADDRESS_CONFIG.find((item: iTemplateColumn) => item.name === cur.name)
            ? {
                ...acc,
                address: {
                  ...acc.address,
                  [cur.accessor]: data[i][curIndex] || cur.defaultValue,
                },
              }
            : {
                ...acc,
                contactCompany: {
                  ...acc.contactCompany,
                  [cur.accessor]: data[i][curIndex],
                },
              },
        { contactCompany: {}, address: {} },
      );

      const addressCreated: iAddress | undefined =
        Object.keys(address).length > 0
          ? // eslint-disable-next-line
            await AddressService.createAddress(address)
          : undefined;
      // eslint-disable-next-line
      const created: iContactCompany = await createContactCompanyConfig({
        ...contactCompany,
        shippingAddressId: addressCreated?.id || null,
        billingAddressId: addressCreated?.id || null,
        typeId,
      });
      if (addressCreated) {
        // eslint-disable-next-line
        await createCompanyAddress({
          contactCompanyId: created.id,
          addressId: addressCreated.id,
        });
      }
      recordResult({ created });
    } catch (error) {
      const errorMessage =
        handleNullException(error, 'response.data.message') ||
        handleNullException(error, 'response.data.error') ||
        'Unfortunately, it fails';
      recordResult({ failed: true, messages: [errorMessage] });
    }
  }
};

export const prepareDownloadResult = (results: iImportDataResult[], data: any[][]) => {
  return data.map((item: any[], index: number) => {
    //  eslint-disable-next-line
    const status = results[index].skipped
      ? 'skipped'
      : results[index].failed
      ? 'failed'
      : 'success';
    if (status === 'success') {
      return [index + 1, status, ...item];
    }
    return [index + 1, `${status}: ${results[index].messages?.join(';\n')}`, ...item];
  });
};

export const prepareSubmittedResult = (results: iImportDataResult[], data: any[][], url: string) => {
  return data.map((item: any[], index: number) => {
    //  eslint-disable-next-line
    const status = results[index].skipped
      ? 'warning'
      : results[index].failed
      ? 'error'
      : 'success';
    return [
      index + 1,
      status === 'success' ? (
        <LinkBtnUrl
          btnName={handleNullExceptionMultiFields(results[index].created, 'name,productCode')}
          url={`${url}/${handleNullException(results[index].created, 'id')}`}
        />
      ) : (
        <PreviewStatus appearance={status} message={results[index].messages} />
      ),
      ...item,
    ];
  });
};
export const preparePreviewResult = (results: iImportDataResult[], data: any[][]) => {
  return data.map((item: any[], index: number) => {
    //  eslint-disable-next-line
    const status = results[index].skipped ? 'warning' : 'success';
    return [index + 1, <PreviewStatus appearance={status} message={results[index].messages} />, ...item];
  });
};

export const calculateStatus = (results: Array<iImportDataResult>) => {
  return {
    skippedLength: results.filter((item: iImportDataResult) => item.skipped).length,
    previewSuccessLength: results.filter((item: iImportDataResult) => !item.skipped).length,
    successLength: results.filter((item: iImportDataResult) => item.created).length,
    failedLength: results.filter((item: iImportDataResult) => item.failed).length,
  };
};
export const getParametersByPageName = (pageName: string) => {
  return handleNullException(PAGE_CUSTOMIZE_PARAMETER, pageName);
};

export const getPageBodyComponent = (pageName: string) => {
  if (hasKey(COMPONENT_MAP, pageName)) {
    const Component = COMPONENT_MAP[pageName];
    return <Component detailPageName={pageName} />;
  }
  return null;
};

export const getSelectLabelValuePair = <T extends { id: string; name: string }>(source: Array<T>, value?: string) => {
  const target = source.find((item: T) => item.id === value);
  return target ? { label: target.name, value: target.id } : null;
};

export const checkHeaders = ({
  read,
  columns,
}: {
  // eslint-disable-next-line
  read: any[];
  columns: Array<iTemplateColumn>;
}) => {
  const headersCheckFailMessage = 'Please use the latest template';
  if (read.length !== columns.length) {
    return headersCheckFailMessage;
  }
  if (
    columns.some(
      (item: iTemplateColumn, index: number) => item.name.toUpperCase() !== (read[index] as string).toUpperCase(),
    )
  ) {
    return headersCheckFailMessage;
  }
  return undefined;
};
