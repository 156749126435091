import { iConfigColumn } from '../../../../../../shared/UITypes/types';
import { PRODUCTS_URL } from '../../../../../../shared/UrlMap';

export const INSERT_INDEX = 1;
export const PRODBOM_COPY_FROM_COLUMS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'material.productCode',
    dataSource: ['material.id', 'material.name'],
    isSortable: false,
    type: 'link-secondary',
    urlPrefix: PRODUCTS_URL,
    isSelected: true,
    group: '',
  },
  {
    name: 'Amount',
    key: 'materialQty',
    dataSource: ['material.measurement.shortName'],
    type: 'text-postfix',
    isSelected: true,
    group: '',
    isSortable: false,
  },
  /* currencyFormat(
    item.materialQty * item.material.averageCost,
    )
    */
  {
    name: 'Est. Cost',
    key: 'estimate-cost',
    dataSource: ['materialQty', 'material.averageCost'],
    type: 'mul-money',
    isSelected: true,
    group: '',
    isSortable: false,
  },
];
