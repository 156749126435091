import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import iSalesOrderDetail from '../../../../types/sales/iSalesOrder';
import { iChannelEntity } from '../../../../types/integration/iChannelEntity';
import ChannelEntityService from '../../../../services/integration/ChannelEntityService';
import { apiErrorToast } from '../../../toast/Toast';
import ChannelService from '../../../../services/integration/ChannelService';
import iChannel from '../../../../types/integration/iChannel';
import { CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE } from '../../../../types/integration/iChannelScript';
import ChannelScriptHelper from '../../../integrations/ChannelScriptHelper';
import { FlexContainer } from '../../../styles/styles';

type iWooCommerceOrderDetails = {
  salesOrder: iSalesOrderDetail;
};

const Wrapper = styled.div`
  .logo-wrapper {
    width: 3rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
`;

const WooCommerceSalesOrderDetails = ({ salesOrder }: iWooCommerceOrderDetails) => {
  const [isLoading, setIsLoading] = useState(false);
  const [channelEntity, setChannelEntity] = useState<iChannelEntity | null>(null);
  const [channel, setChannel] = useState<iChannel | null>(null);

  useEffect(() => {
    if (
      `${salesOrder.createdFromEntityName || ''}`.trim().toUpperCase() !== 'CHANNEL' ||
      !salesOrder.createdFromEntityId
    ) {
      return undefined;
    }
    let isCancelled = false;

    setIsLoading(true);
    Promise.all([
      ChannelEntityService.getChannelEntities(salesOrder.createdFromEntityId, 'SalesOrder', salesOrder.id),
      ChannelService.getChannel(salesOrder.createdFromEntityId, {
        include: 'script',
      }),
    ])
      .then(resp => {
        if (isCancelled) return;
        setChannelEntity(resp[0].length > 0 ? resp[0][0] : null);
        setChannel(resp[1]);
      })
      .catch(err => {
        if (isCancelled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCancelled) return;
        setIsLoading(false);
      });

    return () => {
      isCancelled = true;
    };
  }, [salesOrder]);

  const getPaymentInfo = () => {
    if (!channelEntity?.externalObj.payment_method_title) {
      return null;
    }
    return (
      <>
        <div>
          Payment Method: <b>{channelEntity.externalObj.payment_method_title}</b>
        </div>
        <div>
          Paid: <b>{channelEntity.externalObj.set_paid ? 'Yes' : 'No'}</b>
        </div>
      </>
    );
  };

  const getShippingInfo = () => {
    if (
      !channelEntity?.externalObj ||
      !channelEntity.externalObj.shipping_lines ||
      channelEntity.externalObj.shipping_lines.length <= 0
    ) {
      return null;
    }

    return (
      <>
        <div>
          Shipping:{' '}
          <b>
            {channelEntity.externalObj.shipping_lines[0].method_title} ($
            {channelEntity.externalObj.shipping_lines[0].total})
          </b>
        </div>
      </>
    );
  };

  const getCustomerNotes = () => {
    const notes = `${channelEntity?.externalObj.customer_note || ''}`.trim();
    if (notes === '') {
      return null;
    }
    return (
      <>
        <div>
          Customer Notes: <b>{notes}</b>
        </div>
      </>
    );
  };

  if (isLoading) {
    return <Spinner />;
  }
  if (!channelEntity || !channel || channel.script?.script !== CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE) {
    return null;
  }

  return (
    <Wrapper>
      <FlexContainer className={'with-gap'}>
        <div className={'logo-wrapper'}>
          <img src={ChannelScriptHelper.getLogoUrl(channel.script)} alt={channel.name} />
        </div>
        <div>{channel.name}</div>
      </FlexContainer>
      <small className={'description'}>
        <div>
          Order number: <b>{channelEntity.externalObj.number}</b>
        </div>
        {getPaymentInfo()}
        {getShippingInfo()}
        {getCustomerNotes()}
      </small>
    </Wrapper>
  );
};

export default WooCommerceSalesOrderDetails;
