import iBaseType from '../iBaseType';
import iEntityStatus from './iEntityStatus';

export const ENTITY_STATUS_TYPE_DISPATCH_NOTE = 'DispatchNote';

type iEntityStatusType = iBaseType & {
  entityName: string;
  startingStatusId?: string;
  startingStatus?: iEntityStatus;
  entityStatuses?: iEntityStatus[];
};

export default iEntityStatusType;
