import React from 'react';
import Button from '@atlaskit/button';

const LinkBtn = ({
  btnName,
  onClick,
  testId,
  className,
}: {
  btnName: string;
  onClick: () => void;
  testId?: string;
  className?: string;
}) => (
  <Button
    testId={testId || 'link-button'}
    className={className || 'link-button'}
    appearance={'link'}
    spacing={'none'}
    onClick={onClick}
  >
    {btnName}
  </Button>
);
export default LinkBtn;
