/**
 * a+ overwrite onClick
 * Link from react-router-dom
 * either way can avoid refresh the whole
 * use a overwrite onClick here
 * Invariant failed: You should not use <Link> outside a <Router> for tests if Link
 */
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  .button-style-href {
    font-weight: 500;
    &:focus {
      outline: none;
    }
  }
`;
const LinkBtnUrl = ({
  btnName,
  url,
  children,
}: {
  btnName: string;
  url: string;
  children?: React.ReactNode;
}) => {
  const history = useHistory();
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    history.push(url);
  };
  return (
    <Wrapper>
      <a
        href={url}
        className={'button-style-href'}
        data-testid={'link-button'}
        onClick={onClick}
      >
        {btnName}
      </a>
      {children}
    </Wrapper>
  );
};

export default LinkBtnUrl;
