import React, { useEffect, useState } from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iContactCompanyProduct from '../../../../../types/contactCompany/iContactCompanyProduct';
import CustomizedLabel from '../../../../form/hookForms/CustomizedLabel';
import ComposeSecondaryText from '../../../../text/ComposeSecondaryText';
import AsyncCreatableWithController from '../../../../form/hookForms/AsyncCreatableWithController';
import LinkBtnUrl from '../../../../buttons/LinkBtnUrl';
import ConversionCalculateController from '../../../../conversionCalculate/ConCalculateController';
import { PRODUCTS_URL } from '../../../../UrlMap';
// import { QtyInputContainer } from '../../../../CCPProductContactCompany/CCPCreateOrder/CCPCreateOrder.style';
import {
  getContactCompany,
  getServiceProviderListAsyncSearch,
} from '../../../../../services/ContactCompanyService';
import {
  handleNullException,
  getShippingAddressOptions,
  // getUnitConversionSuffix,
} from '../../../../../services/UtilsService';

import { mapLabelValuePairObjPayload } from '../../../../../pages/sales/utilities';
import {
  FlexContainer,
  SpaceVerticalContainer,
} from '../../../../styles/styles';

import iContactCompany from '../../../../../types/contactCompany/iContactCompany';
import AddressSelectWithController from '../../../../form/hookForms/CompanyAddressSelectorWithController';
import iContactCompanyAddress from '../../../../../types/contactCompany/iContactCompanyAddress';
import { getCompanyAddressList } from '../../../../../services/ContactCompanyAddressService';
import { apiErrorToast } from '../../../../toast/Toast';

const CreteDNModalBody = ({
  control,
  errors,
  onChange,
  watch,
  ccp,
}: {
  // eslint-disable-next-line
  control: Control<Record<string, any>>;
  // eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  // eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  watch: (names?: string | string[]) => string;
  ccp: iContactCompanyProduct;
}) => {
  const contactCompanyId = watch('customerId');

  const [
    localContactCompany,
    setLocalContactCompany,
  ] = useState<iContactCompany>();
  const [localCompanyAddressList, setLocalCompanyAddressList] = useState<
    iContactCompanyAddress[]
  >([]);

  useEffect(() => {
    if (!contactCompanyId) {
      // setLocalContactCompany(undefined);
      return;
    }
    const getData = async () => {
      try {
        const contactCompany = await getContactCompany(contactCompanyId);
        const { data } = await getCompanyAddressList({
          filter: `contactCompanyId:${contactCompanyId}`,
        });
        setLocalContactCompany(contactCompany);
        setLocalCompanyAddressList(data);
      } catch (e) {
        apiErrorToast(e);
      }
    };

    getData();
  }, [contactCompanyId]);

  return (
    <Page>
      <Grid testId={'full-grid'}>
        <GridColumn medium={12}>
          <AsyncCreatableWithController
            label={'Service Provider'}
            name={'customerId'}
            control={control}
            // eslint-disable-next-line
            onChange={(name: string, value: any, config?: Object) =>
              onChange(name, value.id, config)
            }
            errors={errors}
            promiseFn={getServiceProviderListAsyncSearch}
            optionLabel={'name'}
            isRequired
            defaultValue={
              ccp.contactCompany
                ? mapLabelValuePairObjPayload([ccp.contactCompany], 'name')[0]
                : undefined
            }
            isCreatable={false}
            isDisabled
          />
        </GridColumn>
        <GridColumn medium={12}>
          <AddressSelectWithController
            name={'shippingAddressId'}
            label={'Shipping Address'}
            control={control}
            defaultValue={
              contactCompanyId
                ? handleNullException(localContactCompany, 'shippingAddressId')
                : undefined
            }
            options={getShippingAddressOptions(
              localCompanyAddressList,
              localContactCompany,
            )}
            onChange={onChange}
            errors={errors}
            isRequired
            isClearable={false}
            customizedStyle={{ menu: () => ({ overflowY: 'visible' }) }}
          />
        </GridColumn>
        <GridColumn medium={12}>
          <SpaceVerticalContainer className={'half'} />
          <FlexContainer className={'space-between'}>
            <CustomizedLabel label={'Dispatch Items'} />
            <CustomizedLabel label={'Quantity'} />
          </FlexContainer>
          <SpaceVerticalContainer className={'quarter'} />
          <FlexContainer className={'space-between flex-start'}>
            <ComposeSecondaryText
              secondaryText={handleNullException(ccp, 'product.name')}
            >
              <LinkBtnUrl
                btnName={handleNullException(ccp, 'product.productCode')}
                url={`${PRODUCTS_URL}/${handleNullException(ccp, 'productId')}`}
              />
            </ComposeSecondaryText>
            <ConversionCalculateController
              label={''}
              name={'qty'}
              defaultValue={'0'}
              control={control}
              errors={errors}
              contactCompanyUnit={handleNullException(
                ccp,
                'measurement.shortName',
              )}
              productUnit={handleNullException(
                ccp,
                'product.measurement.shortName',
              )}
              conversion={Number(ccp.unitConversion) || 1}
            />
          </FlexContainer>
        </GridColumn>
      </Grid>
      <Grid>
        <SpaceVerticalContainer className={'two'} />
      </Grid>
    </Page>
  );
};

export default CreteDNModalBody;
