/* eslint-disable import/no-cycle */
import iBaseType from '../iBaseType';

export const CHANNEL_SCRIPT_SCRIPT_MYOB = 'MyobConnector';
export const CHANNEL_SCRIPT_SCRIPT_WOOCOMMERCE = 'WooCommerceConnector';

type iChannelScript = iBaseType & {
  name: string;
  typeId: string;
  script: string;
  authType: string;
  settings?: string;
};

export default iChannelScript;
