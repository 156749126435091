import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import InlineToggle from '../../../../shared/form/InlineToggle';

type iAttribute = {
  title: string;
  description?: string;
  isChecked?: boolean;
  name: string;
};
const ToggleWrapper = styled.div`
  .toggle-label {
    display: flex;
  }
  .toggle-title {
    font-weight: 500;
    font-size: 16px;
    color: #172b4d;
    margin-right: 4rem;
  }
  .toggle-description {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #6b778c;
  }
`;
type iProductToggle = {
  attribute: iAttribute;
  onToggle: (name: string, value: boolean) => void;
  className?: string;
  isDisabled?: boolean;
};
const ProductToggle = ({ attribute, onToggle, className, isDisabled }: iProductToggle) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    setIsChecked(!!attribute.isChecked);
  }, [attribute.isChecked]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
    onToggle(attribute.name, event.currentTarget.checked);
  };

  return (
    <ToggleWrapper className={className}>
      <div className={'toggle-label'}>
        {attribute.title && <div className={'toggle-title'}>{attribute.title}</div>}
        <InlineToggle
          name={attribute.name}
          isChecked={isChecked}
          onChange={onChange}
          testId={attribute.name}
          isDisabled={isDisabled}
        />
      </div>
      {attribute.description && <div className={'toggle-description'}>{attribute.description}</div>}
    </ToggleWrapper>
  );
};

export default ProductToggle;
