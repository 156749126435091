import React, { useState } from 'react';
import { LoadingButton } from '@atlaskit/button';
import { Node, useEdges, useNodes } from 'react-flow-renderer';
import iWorkflow from '../../../types/workflow/iWorkflow';
import WorkflowService from '../../../services/WorkflowService';
import { addToastForAPIResponse } from '../../../shared/toast/Toast';
import { iWFDiagramState } from '../../../shared/workflow/diagram/WorkflowDiagramReducer';
import {
  formatDiagramForWorkFlow,
  getStatusNode,
  NODE_ID_NEW_NODE,
} from '../../../shared/workflow/diagram/WorkflowDiagramHelper';
import EntityStatusService from '../../../services/EntityStatusService';
import iEntityStatus from '../../../types/status/iEntityStatus';

type iWorkflowEditButtons = {
  onPreCheck?: () => boolean;
  onSaveSuccessfully?: (newWorkflow: iWorkflow) => void;
  state: iWFDiagramState;
  onCancel: () => void;
};
const WorkflowEditButtons = ({ state, onCancel, onSaveSuccessfully, onPreCheck }: iWorkflowEditButtons) => {
  const [isSaving, setIsSaving] = useState(false);
  const nodes = useNodes();
  const edges = useEdges();

  if (!state.workflow) {
    return null;
  }

  const handleSave = async () => {
    if (!state.workflow) {
      return;
    }

    if (onPreCheck && onPreCheck() !== true) {
      return;
    }
    setIsSaving(true);
    try {
      let newEdges = [...edges];
      const usedStatusIds: string[] = [];
      // update and insert new statuses
      const newNodes = await Promise.all(
        nodes.map(async (node: Node) => {
          if (!('status' in node.data)) {
            return node;
          }
          if (`${node.data.status.id || ''}`.startsWith(NODE_ID_NEW_NODE)) {
            const newStatus = await EntityStatusService.createEntityStatus({
              name: node.data?.status.name,
              code: node.data?.status.code,
              description: node.data?.status.description,
              entityStatusCategoryId: node.data?.status.entityStatusCategoryId,
              entityStatusTypeId: state.entityStatusTypeId,
            });
            usedStatusIds.push(newStatus.id);
            const newNode = getStatusNode(newStatus, node.position);
            newEdges = [
              ...newEdges.map(edge => ({
                ...edge,
                source: edge.source === node.id ? newNode.id : edge.source,
                target: edge.target === node.id ? newNode.id : edge.target,
              })),
            ];
            return newNode;
          }
          const newStatus = await EntityStatusService.updateEntityStatus(node.data?.status.id, node.data?.status);
          usedStatusIds.push(newStatus.id);
          return getStatusNode(newStatus, node.position);
        }),
      );

      // delete every other statuses
      await Promise.all(
        state.entityStatuses
          .filter(status => usedStatusIds.indexOf(status.id) < 0)
          .map((status: iEntityStatus) => {
            return EntityStatusService.deleteEntityStatus(status.id);
          }),
      );

      const updatedWorkflow = await WorkflowService.updateWorkflow(state.workflow.id, {
        wf: formatDiagramForWorkFlow(newNodes, newEdges, state),
      });

      addToastForAPIResponse('success', 'Workflow saved successfully.');
      setIsSaving(false);
      if (onSaveSuccessfully) {
        onSaveSuccessfully(updatedWorkflow);
      } else {
        window.location.reload();
      }
    } catch (err) {
      addToastForAPIResponse('error', err.message);
      setIsSaving(false);
    }
  };

  return (
    <>
      <LoadingButton
        appearance={'primary'}
        onClick={handleSave}
        isLoading={isSaving === true}
        isDisabled={state.errors.length > 0}
      >
        Update workflow
      </LoadingButton>
      <LoadingButton appearance={'subtle'} onClick={onCancel} isDisabled={isSaving === true}>
        Discard changes
      </LoadingButton>
    </>
  );
};

export default WorkflowEditButtons;
