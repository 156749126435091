import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@atlaskit/button';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../redux/makeReduxStore';
import CompanyProfileService from '../../../services/Settings/CompanyProfileService';
import { BILLING_HOME_URL } from '../../UrlMap';

export const DISPLAYING_MAX_DAYS = 14;

const getTextPostFix = (days: number) => {
  if (days > 1) {
    return `in ${days} days`;
  }

  if (days === 1) {
    return 'in 1 day';
  }

  return 'today';
};

const getText = (expiryDays: number, isForTrail = false) => {
  if (expiryDays >= DISPLAYING_MAX_DAYS) {
    return '';
  }
  return `${isForTrail === true ? 'Trial ends' : 'Expires'} ${getTextPostFix(expiryDays)}`;
};

const CompanyExpiryBtn = () => {
  const history = useHistory();
  const { user } = useSelector((s: RootState) => s.auth);
  if (!user) {
    return null;
  }
  const { company } = user;
  if (!company || (!company.expiry && !company.trialExpiresAt)) {
    return null;
  }

  const { expiresInDays, isForTrial } = CompanyProfileService.getExpiryInfo(company);
  if (expiresInDays === null) {
    return null;
  }

  const text = getText(expiresInDays, isForTrial);
  if (text === '') {
    return null;
  }

  return (
    <Button
      testId={'company-expiry-btn'}
      appearance="warning"
      href={BILLING_HOME_URL}
      onClick={() => history.push(BILLING_HOME_URL)}
    >
      {text}
    </Button>
  );
};

export default CompanyExpiryBtn;
