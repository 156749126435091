import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const endPoint = '/workFlow';
const getWorkflow = (workflowId: string, params = {}) => {
  return AppService.get(`${endPoint}/${workflowId}`, params).then(({ data }) => data);
};
const getWorkflows = (config?: iParams) => {
  return AppService.get(endPoint, config).then(({ data }) => data);
};

const updateWorkflow = (workflowId: string, params = {}) => {
  return AppService.put(`${endPoint}/${workflowId}`, params).then(({ data }) => data);
};

export default {
  getWorkflow,
  getWorkflows,
  updateWorkflow,
};
