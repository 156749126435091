import styled from 'styled-components';

export const SearchWrapper = styled.div`
  max-width: 200px;
  min-width: 120px;
`;
export const JobScheduleListWrapper = styled.div`
  height: calc(100vh - 250px);
  overflow: scroll;
  .day-shift-wrapper,
  .night-shift-wrapper {
    display: flex;
  }
  .day-shift-wrapper > div,
  .night-shift-wrapper > div {
    margin: 0px 1rem;
  }
  .day-shift-wrapper > div:first-child,
  .night-shift-wrapper > div:first-child {
    margin: 0px 1rem 0px 0px;
  }
  .day-shift-wrapper > div:last-child,
  .night-shift-wrapper > div:last-child {
    margin: 0px 0rem 0px 1rem;
  }
  .job-schedule-wrapper {
    background: rgb(244, 245, 247);
    padding: 8px 8px 0px 8px;
    display: flex;
    justify-content: space-between;
  }

  [data-testid='multi-sum'] {
    cursor: pointer;
  }
`;

export const UnScheduledWrapper = styled.div`
  background: rgb(244, 245, 247);
  padding: 8px;
  min-height: 100%;
  .space-bottom {
    padding: 0px 8px 8px 8px;
  }
  .shift-name {
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
  }
`;

export const JobScheduleListWrapperBackup = styled.div`
  display: flex;
  &. > div {
    margin: 0px 1rem;
  }
  & > div:first-child {
    margin: 0px 1rem 0px 0px;
  }
  & > div:last-child {
    margin: 0px 0rem 0px 1rem;
  }
  .job-schedule-wrapper {
    background: rgb(244, 245, 247);
    padding: 8px 8px 0px 8px;
    display: flex;
    justify-content: space-between;
  }
  .shift-name {
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
  }
`;
