import { Editor } from '@tinymce/tinymce-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import CheckIcon from '@atlaskit/icon/glyph/check';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Label } from '@atlaskit/field-base';
import Spinner from '@atlaskit/spinner';

export type iRichTextEditor = {
  defaultValue?: string;
  height?: number;
  plugins?: string[];
  toolBar?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings?: any;
  onChangeConfirmed?: (text: string) => void;
  onCancel?: () => void;
  testId?: string;
  inline?: boolean;
  isConfirming?: boolean;
  label?: React.ReactElement | string;
  name?: string;
  disabled?: boolean;
};

const defaultPlugins = [
  'advlist',
  'lists',
  'autolink',
  'link',
  'image',
  'charmap',
  'preview',
  // 'anchor',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'code',
];
const defaultToolBars = [
  'undo redo',
  'bold italic underline strikethrough forecolor backcolor',
  'alignleft aligncenter alignright alignjustify',
  'bullist numlist',
  'outdent indent',
  'link',
  'removeformat fullscreen',
];

const Wrapper = styled.div`
  position: relative;
  min-height: 10rem;
  .tox-statusbar__branding {
    display: none;
  }
  [data-testid='confirm-btns'] {
    background: white;
    position: absolute;
    right: 20px;
    bottom: 0px;
  }
  .loading-div {
    position: absolute;
    background: rgba(10, 10, 10, 0.4);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    .spinner-wrapper {
      color: white;
      width: 50%;
      margin: 8rem auto;
    }
  }
`;
const RichTextEditor = ({
  label,
  defaultValue,
  isConfirming,
  plugins,
  toolBar,
  settings = {},
  onChangeConfirmed,
  onCancel,
  height = 450,
  testId,
  inline,
  name,
  disabled,
}: iRichTextEditor) => {
  const [value, setValue] = useState(defaultValue || '');
  const [isFocused, setIsFocused] = useState(false);
  const [isInitialising, setIsInitialising] = useState(true);

  const onCancelChange = () => {
    setValue(defaultValue || '');
    setIsFocused(false);
    if (onCancel) {
      onCancel();
    }
  };

  const onConfirmChange = () => {
    setIsFocused(false);
    if (onChangeConfirmed) {
      onChangeConfirmed(value);
    }
  };

  const getConfirmBtns = () => {
    if (!isFocused) {
      return null;
    }

    return (
      <div data-testid={'confirm-btns'}>
        <ButtonGroup>
          <LoadingButton
            testId={'confirm-btn'}
            appearance={'primary'}
            iconBefore={<CheckIcon label={'Confirm'} />}
            isLoading={isConfirming === true}
            onClick={() => onConfirmChange()}
          />
          <Button
            testId={'cancel-btn'}
            appearance={'default'}
            iconBefore={<CrossIcon label={'Cancel'} />}
            onClick={() => onCancelChange()}
          />
        </ButtonGroup>
      </div>
    );
  };

  const getLabel = () => {
    if (!label) {
      return null;
    }
    if (typeof label === 'string') {
      return <Label label={label} />;
    }
    return label;
  };

  const getLoadingDiv = () => {
    if (isInitialising !== true) {
      return null;
    }
    return (
      <div className={'loading-div'}>
        <div className={'spinner-wrapper'}>
          <Spinner appearance="invert" />
          <div>Initialising Editor ...</div>
        </div>
      </div>
    );
  };

  return (
    <Wrapper data-testid={testId || 'rich-text-editor-wrapper'}>
      {getLabel()}
      {getLoadingDiv()}
      <Editor
        disabled={disabled}
        textareaName={name}
        inline={inline}
        value={value}
        onInit={() => setIsInitialising(false)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => onConfirmChange()}
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY || ''}
        onEditorChange={(newText: string) => setValue(newText)}
        init={{
          height,
          menubar: true,
          plugins: plugins || defaultPlugins,
          toolbar: toolBar || defaultToolBars.join('|'),
          removed_menuitems: 'newdocument',
          ...settings,
        }}
      />
      {getConfirmBtns()}
    </Wrapper>
  );
};

export default RichTextEditor;
