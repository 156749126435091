import React, { useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { iBtnAppearance } from '../UITypes/types';

const BtnWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
const initialState = {
  showPopup: false,
};

const defaultHeading = 'Are you sure to start?';
const defaultPopupContent = <p>Please confirm you are about to start it.</p>;

const SelfManagePopup = ({
  isPopupOpen = false,
  isCancelBtn = true,
  onClick,
  popupHeading,
  popupContent,
  isDisabled = false,
  confirmBtnAppearance,
  confirmBtnName,
  children,
}: {
  isPopupOpen?: boolean;
  isCancelBtn?: boolean;
  onClick?: () => void;
  popupHeading?: string;
  popupContent?: React.ReactNode;
  isDisabled?: boolean;
  confirmBtnAppearance?: iBtnAppearance;
  confirmBtnName?: string;
  children?: React.ReactNode;
}) => {
  const [state, setState] = useState(initialState);

  const handleClicked = () => {
    setState({
      ...state,
      showPopup: true,
    });
  };
  const close = () => {
    setState({
      ...state,
      showPopup: false,
    });
  };
  const doConfirm = () => {
    setState({
      ...state,
      showPopup: false,
    });
    if (typeof onClick === 'function') {
      onClick();
    }
  };
  const getModalContent = () => {
    if (!state.showPopup && !isPopupOpen) return null;
    return (
      <Modal
        testId="simple-self-manage-modal"
        actions={
          isCancelBtn
            ? [
                {
                  text: confirmBtnName || 'Confirm',
                  onClick: doConfirm,
                  isDisabled,
                  appearance: confirmBtnAppearance || 'primary',
                  testId: 'confirm-button',
                },
                {
                  text: 'Cancel',
                  onClick: close,
                  testId: 'cancel-button',
                },
              ]
            : [
                {
                  text: confirmBtnName || 'Confirm',
                  onClick: doConfirm,
                  isDisabled,
                  appearance: confirmBtnAppearance || 'primary',
                  testId: 'confirm-button',
                },
              ]
        }
        onClose={close}
        heading={popupHeading || defaultHeading}
      >
        {popupContent || defaultPopupContent}
      </Modal>
    );
  };

  const getBtn = () => (
    <BtnWrapper onClick={handleClicked}>{children}</BtnWrapper>
  );

  return (
    <>
      {getBtn()}
      <ModalTransition>{getModalContent()}</ModalTransition>
    </>
  );
};
export default SelfManagePopup;
