/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Spinner from '@atlaskit/spinner';
import Button from '@atlaskit/button';
import StepDownload from '../step1/StepDownload';
import StepRead from '../step2/StepRead';
import { SpaceVerticalContainer } from '../../../../../shared/styles/styles';
import { iKeyValuePairs } from '../../../../../shared/UITypes/types';
import { iPrefills, iTemplateColumn } from '../../shared/DataImport.types';
import iProduct from '../../../../../types/product/iProduct';
import { getProducts } from '../../../../../services/product/ProductService';
import { AVOID_PAGINATION_PER_PAGE } from '../../../../../shared/constants/AsyncConstants';
import { apiErrorToast } from '../../../../../shared/toast/Toast';
import StepSubmit from '../step3/StepSubmit';
import { getParametersByPageName } from '../../shared/DataImport.util';
import { IMPORT_ANOTHER_FILE } from '../../shared/DataImport.constants';

type iState = {
  data: any[][];
  isLoadingFlexColumns: boolean;
  flexColumns: Array<iTemplateColumn>;
  isLoadingExtraPrefills: boolean;
  extraPrefills: { products: Array<iProduct> };
  isReset: boolean;
};
const initialState: iState = {
  isLoadingFlexColumns: true,
  isLoadingExtraPrefills: false,
  data: [],
  extraPrefills: { products: [] },
  isReset: false,
  flexColumns: [],
};
const BOMImportSteps = ({
  detailPageName,
  prefills,
}: {
  detailPageName: string;
  prefills: iPrefills;
}) => {
  const [state, setState] = useState(initialState);
  const { columns, example, getFlexColumnsFn } = getParametersByPageName(
    detailPageName,
  );
  // load flex columns for template
  useEffect(() => {
    let isCancelled = false;
    const fetchFlexColumns = async () => {
      try {
        if (typeof getFlexColumnsFn !== 'function') {
          setState(prevState => ({
            ...prevState,
            isLoadingFlexColumns: false,
          }));
          return;
        }
        setState(prevState => ({
          ...prevState,
          isLoadingFlexColumns: true,
        }));
        const flexColumns = await getFlexColumnsFn();
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          flexColumns,
          isLoadingFlexColumns: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({
          ...prevState,
          isLoadingFlexColumns: false,
        }));
      }
    };
    fetchFlexColumns();
    return () => {
      isCancelled = true;
    };
  }, [getFlexColumnsFn]);

  // load product list for preview
  useEffect(() => {
    let isCancelled = false;
    const fetchProducts = async () => {
      if (state.data.length < 2) return;
      try {
        setState(prevState => ({ ...prevState, isLoadingExtraPrefills: true }));
        const [, ...rows] = state.data;
        const productCodes = rows
          .reduce((acc: Array<string>, curRow: any[]) => {
            return [...acc, curRow[0], curRow[1]];
          }, [])
          .filter((item: string) => item);
        const uniqProductCodes = _.uniq(productCodes);
        // .map((item: string) =>
        //   encodeURIComponent(item),
        // );
        const { data } = await getProducts({
          filter: `productCode:${uniqProductCodes.join('|')}`,
          currentPage: '1',
          // maxmium row is 200, at most have 400 uniq product code
          perPage: AVOID_PAGINATION_PER_PAGE,
          include: 'createdBy',
        });
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoadingExtraPrefills: false,
          extraPrefills: { products: data },
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({
          ...prevState,
          isLoadingExtraPrefills: false,
        }));
      }
    };
    fetchProducts();
    return () => {
      isCancelled = true;
    };
  }, [JSON.stringify(state.data)]);

  // if need to clean the imported file, use handleDataRead like GeneralImportSteps
  const handleDataRead = (result: any[][]) =>
    setState({ ...state, data: result, isReset: false });

  if (state.isLoadingFlexColumns) {
    return <Spinner />;
  }
  return (
    <>
      <StepDownload
        detailPageName={detailPageName}
        columns={[...columns, ...state.flexColumns]}
        example={example}
      />
      <SpaceVerticalContainer className={'half'} />
      <StepRead
        handleDataRead={handleDataRead}
        isReset={state.isReset}
        columns={[...columns, ...state.flexColumns]}
      />
      <SpaceVerticalContainer className={'half'} />
      {state.data.length > 0 &&
        (state.isLoadingExtraPrefills ? (
          <Spinner />
        ) : (
          <StepSubmit
            data={state.data}
            detailPageName={detailPageName}
            prefills={{ ...prefills, ...state.extraPrefills }}
            columns={[...columns, ...state.flexColumns]}
          >
            <Button
              appearance={'primary'}
              onClick={() => setState({ ...state, isReset: true, data: [] })}
            >
              {IMPORT_ANOTHER_FILE}
            </Button>
          </StepSubmit>
        ))}
    </>
  );
};

export default BOMImportSteps;
