import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { gridSize } from '@atlaskit/theme/constants';

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &.space-below {
    padding-bottom: 1rem;
  }

  &.space-above {
    padding-top: 1rem;
  }
`;
export const FlexSpaceAroundContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;
export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &.space-below {
    padding-bottom: 1rem;
  }
`;
export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  &.margin-padding {
    padding: 0.5em;
    margin: 2em 0 0.5em 0;
    width: fit-content;
  }
`;
export const MarginLeftContainer = styled.div`
  background-color: ${colors.backgroundActive};
  margin-left: ${gridSize() * 2}px;
`;
export const MarginBottomContainer = styled.div`
  margin-bottom: ${gridSize() * 2}px;
`;
export const SearchContainer = styled.div`
  max-width: 200px;
`;
export const PaddingContainer = styled.div`
  padding: ${gridSize() * 1.5}px ${gridSize() * 3}px;
`;
