import React, { useState } from 'react';
import moment from 'moment';
import Checkbox from '@atlaskit/checkbox';
import DynamicTable from '@atlaskit/dynamic-table';
import iProductType from '../../types/product/iProductType';
import { iSortOrderType } from '../../shared/UITypes/types';
import CreatedOrUpdated from '../../shared/createdOrUpdated/CreatedOrUpdated';
import LinkBtn from '../../shared/buttons/LinkBtn';
import { getDeleteBtn, getHeads } from '../../components/settings/utils';
import { handleNullException } from '../../services/UtilsService';
import { ProductTypeTableWrapper } from './ProductType.style';

const COLUMNS = [
  'Name',
  'Description',
  'Default Attribute Set',
  'Default Sell flag',
  'Default Purchase flag',
  'Default Make flag',
  'Default Waste flag',
  'Default Units of Measure',
  'Created',
  'Updated',
  'Operation',
];

const ProductTypeListTable = ({
  data,
  onDelete,
  onEdit,
  onEditFlag,
  onSetSort,
  isLoading,
  canUpdate = true,
  canDelete = true,
}: {
  data: Array<iProductType>;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onEditFlag: (name: string, value: boolean, id: string) => void;
  onSetSort: (sortStr: string) => void;
  isLoading: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
}) => {
  const [sortSequence, setSortSequence] = useState<iSortOrderType>('ASC');

  const getNameContent = (item: iProductType) =>
    canUpdate ? <LinkBtn btnName={item.name} onClick={() => onEdit(item.id)} /> : item.name;

  const getCheckbox = (name: string, defaultValue: boolean, id: string) => (
    <Checkbox
      testId={`productType-list-${name}`}
      isChecked={defaultValue}
      name={name}
      onChange={() => onEditFlag(name, defaultValue, id)}
      isDisabled={!canUpdate}
    />
  );

  const getRows = () => {
    return data
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iProductType) => ({
        cells: [
          { key: item.name, content: getNameContent(item) },
          { content: item.description },
          { content: handleNullException(item, 'attributeSet.name') },
          { content: getCheckbox('isForSell', item.isForSell, item.id) },
          {
            content: getCheckbox('isForPurchase', item.isForPurchase, item.id),
          },
          { content: getCheckbox('isForMake', item.isForMake, item.id) },
          { content: getCheckbox('isForWaste', item.isForWaste, item.id) },
          { content: handleNullException(item, 'measurement.shortName') },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'updatedAt')}
                operator={handleNullException(item, 'updatedBy.firstName')}
              />
            ),
          },

          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'updatedAt')}
                operator={handleNullException(item, 'updatedBy.firstName')}
              />
            ),
          },
          {
            content: canDelete
              ? getDeleteBtn({
                  id: item.id,
                  answer: item.name || 'n/a',
                  onClick: onDelete,
                })
              : null,
          },
        ],
      }));
  };

  const onSort = () => {
    setSortSequence(sortSequence === 'ASC' ? 'DESC' : 'ASC');
    onSetSort(`name:${sortSequence === 'ASC' ? 'DESC' : 'ASC'}`);
  };

  return (
    <ProductTypeTableWrapper>
      <DynamicTable
        head={getHeads(COLUMNS, 'productType')}
        rows={getRows()}
        testId={'productType-list-table'}
        isLoading={isLoading}
        defaultSortKey={'Name'}
        defaultSortOrder={'ASC'}
        onSort={onSort}
        sortKey={'Name'}
        sortOrder={sortSequence}
      />
    </ProductTypeTableWrapper>
  );
};

export default ProductTypeListTable;
