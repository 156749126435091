import { iKeyValuePairs, iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

export const getProdAttributeSets = (config?: iKeyValuePairs) => {
  return AppService.get('/productAttributeSet', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const getProdAttributeSetDetail = (id: string) => {
  return AppService.get(`/productAttributeSet/${id}`, {
    include: 'productAttributes',
  }).then(({ data }) => data);
};

export const createProdAttributeSet = (postData: iParams) => {
  return AppService.post('/productAttributeSet', postData).then(({ data }) => data);
};

export const updateProdAttributeSet = (id: string, updateContent: iParams) => {
  return AppService.put(`/productAttributeSet/${id}`, updateContent).then(({ data }) => data);
};

export const deleteProdAttributeSet = (id: string) => {
  return AppService.delete(`/productAttributeSet/${id}`).then(({ data }) => data);
};

export const cloneProdAttributeSet = (id: string, postData: iParams) => {
  return AppService.post(`/productAttributeSet/${id}/clone`, postData).then(({ data }) => data);
};

export default {
  getProdAttributeSets,
  createProdAttributeSet,
  updateProdAttributeSet,
  deleteProdAttributeSet,
  cloneProdAttributeSet,
};
