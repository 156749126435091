import React from 'react';
import _ from 'lodash';
import iJob from '../../../types/job/iJob';
import Summary from '../shared/Summary';
import MathHelper from '../../../services/MathHelper';

const SummaryJobs = ({ jobs }: { jobs: Array<iJob> }) => {
  const getSums = () => {
    const groupedJobs = _.groupBy(jobs, 'product.measurement.shortName');
    const sums = Object.keys(groupedJobs).map((unitName: string) => {
      const sum = groupedJobs[unitName].reduce(
        (acc: number, cur: iJob) => MathHelper.add(acc, cur.qtyTotal || 0),
        0,
      );
      return { unitName, sum };
    });
    return sums;
  };
  return <Summary data={getSums()}>{jobs.length} Job(s)</Summary>;
};

export default SummaryJobs;
