import React, { useState, useEffect } from 'react';
import { CreatableSelect } from '@atlaskit/select';
import { iLabelValuePair } from '../UITypes/types';

const createOption = (input: string) => {
  return {
    label: input,
    value: input,
  };
};

const initialState = {
  inputValue: '',
  value: [],
};

type iState = {
  inputValue: string;
  value: iLabelValuePair[];
};

const CreatableMultiSelect = ({
  name,
  placeholder,
  defaultValue,
  onChange,
  testId = 'creatable-select',
  isClearable = false,
  isMulti = false,
  isDisabled = false,
}: {
  name: string;
  placeholder?: string;
  defaultValue?: iLabelValuePair[];
  //    eslint-disable-next-line
  onChange: (name: string, value: any) => void;
  testId?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState<iState>(initialState);

  useEffect(() => {
    if (defaultValue) {
      setState({
        ...state,
        value: defaultValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultValue)]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (value: any) => {
    setState({
      ...state,
      value: value ? (value as iLabelValuePair[]) : [],
    });

    onChange(name, value);
  };

  const handleInputChange = (inputValue: string) => {
    setState({ ...state, inputValue });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (event: any) => {
    const { inputValue, value } = state;

    if (!inputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        // eslint-disable-next-line no-case-declarations
        const mergedValArr = [...value, createOption(inputValue)];
        setState({
          inputValue: '',
          value: mergedValArr,
        });
        onChange(name, mergedValArr);
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      name={name}
      inputValue={state.inputValue}
      value={state.value}
      inputId={testId}
      //  eslint-disable-next-line
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      menuIsOpen={false}
      classNamePrefix={testId}
      isClearable={isClearable}
      isMulti={isMulti}
      isDisabled={isDisabled}
    />
  );
};

export default CreatableMultiSelect;
