import styled from 'styled-components';
import {
  statusCategoryAny,
  statusCategoryFinished,
  statusCategoryInProgress,
  statusCategoryNew,
} from '../../styles/styles';

export const WorkflowDiagramWrapper = styled.div`
  width: 100%;
  height: 100%;

  .react-flow__handle {
    background: transparent;
    border: none;
  }

  .react-flow__node {
    width: auto;
    min-width: 150px;

    &.start-node {
      background: rgb(61, 75, 102);
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      :before {
        display: block;
        content: 'Start';
        color: white;
        text-transform: uppercase;
        font-size: 9px;
        margin-left: -4px;
        margin-top: 3px;
      }
    }

    &.any-node {
      ${statusCategoryAny};
      text-transform: uppercase;
      padding: 2px 4px;
      width: auto;
      min-width: auto;
      font-size: 9px;
      .react-flow__handle {
        cursor: none;
        background: transparent;
        pointer-events: none;
        border: none;
      }
    }

    &.selected {
      border-width: 2px;
    }

    &.cate-STATUS_CATEGORY_NEW {
      ${statusCategoryNew}
    }

    &.cate-STATUS_CATEGORY_IN_PROGRESS {
      ${statusCategoryInProgress}
    }

    &.cate-STATUS_CATEGORY_FINISHED {
      ${statusCategoryFinished}
    }
  }

  .start-edge {
    .react-flow__edge-textbg {
      fill: #cecece;
    }
  }

  .react-flow__attribution {
    display: none;
  }
`;
