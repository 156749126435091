import { iLabelValuePair } from '../../../../shared/UITypes/types';
import { iToggleAttribute } from './NCRDetail.type';

export const PAGE_NAME = 'NCRs';
export const TOGGLE_ATTRIBUTES: Array<iToggleAttribute> = [
  {
    title: 'Proposed Action Implemented?',
    description: 'Has the proposed action been implemented',
    name: 'isProposedActionImplemented',
  },
  {
    title: 'Production Advised?',
    description: 'Has the production line been advised',
    name: 'isProductionAdvisedOfProblem',
  },
  {
    title: 'Customer Been Advised?',
    description: 'Has the customer been advised with the resolution',
    name: 'isCustomerAdvisedOfFindings',
  },
  {
    title: 'Credit Been Arranged?',
    description: 'Has any credit been arranged',
    name: 'hasCreditArranged',
  },
];

export const YES_OPTION = { label: 'Yes', value: 'YES' };
export const NO_OPTION = { label: 'No', value: 'NO' };
export const CHECK_OPTIONS: Array<iLabelValuePair> = [YES_OPTION, NO_OPTION];

export const emailSubjectTpl = 'Please find [REF_NUM] from [COMPANY_NAME]';
export const emailBodyTpl = `Hi there,

Please click the link below to download the NCR report.
[PDF_URL]

Feel free to contact us if you require anything further.

Kind Regards
[SENT_FROM]
[COMPANY_NAME]`;
