import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import SectionMessage from '@atlaskit/section-message';
import Button from '@atlaskit/button';
import { iDetailUpdateFn } from '../../../UITypes/types';
import iJob from '../../../../types/job/iJob';
import iSalesOrderItem from '../../../../types/sales/iSalesOrderItem';
import { getSODetailConfig } from '../../../../services/SalesOrderService';
import { apiErrorToast } from '../../../toast/Toast';
import ReAssociateBody from './ReAssociateBody';
import NoFooterModal from '../../../modal/NoFooterModal';

type iState = {
  isLoading: boolean;
  isOpen: boolean;
  salesOrderItems: Array<iSalesOrderItem>;
};
const inititalState: iState = {
  isLoading: true,
  isOpen: false,
  salesOrderItems: [],
};
const ReAssociateSOItem = ({
  detail,
  //  eslint-disable-next-line
  onUpdate,
}: {
  detail: iJob;
  onUpdate: iDetailUpdateFn;
}) => {
  const { salesOrderId, salesOrderItemId, productId } = detail;
  const [state, setState] = useState(inititalState);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      // old jobs do not have related salesOrder
      if (!salesOrderId) {
        setState(prevState => ({ ...prevState, isLoading: false }));
        return;
      }
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));
        const { salesOrderItems } = await getSODetailConfig(salesOrderId, {
          include:
            'salesOrderItems.product.measurement,salesOrderItems.measurement,salesOrderItems.unitPriceMeasurement',
        });
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          salesOrderItems,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [salesOrderId]);

  const onConfirmSOItem = (newSalesOrderItemId: string) => {
    setState({ ...state, isOpen: false });
    onUpdate({ salesOrderItemId: newSalesOrderItemId });
  };

  const getModal = () => {
    if (state.isOpen !== true) {
      return null;
    }

    return (
      <NoFooterModal
        isOpen={state.isOpen}
        modalHeading={'Re-associate the relationship between the Job and SalesOrderItem'}
        modalBody={
          <ReAssociateBody
            salesOrderItems={state.salesOrderItems.filter((item: iSalesOrderItem) => item.productId === productId)}
            onConfirmSOItem={onConfirmSOItem}
          />
        }
        onCancel={() => setState({ ...state, isOpen: false })}
      />
    );
  };

  // old jobs-sales order id is null
  if (!salesOrderId) {
    return null;
  }
  if (state.isLoading) {
    return <Spinner />;
  }
  if (state.salesOrderItems.length <= 0) {
    return null;
  }
  if (state.salesOrderItems.find((item: iSalesOrderItem) => item.id === salesOrderItemId)) {
    return null;
  }

  return (
    <>
      <SectionMessage
        appearance={'error'}
        title={'SalesOrderItem is deleted/not existed'}
        testId={'error-section-message'}
      >
        <div>Relationship between job and salesOrderItem is missing</div>
        <Button
          appearance={'link'}
          spacing={'none'}
          onClick={() => setState({ ...state, isOpen: true })}
          testId={'re-associate-button'}
        >
          Re-Associate
        </Button>
      </SectionMessage>
      {getModal()}
    </>
  );
};

export default ReAssociateSOItem;
