import { iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

const getAllSubscriptionsForCompany = (companyId: string, config?: iParams) => {
  return AppService.get('/billingSubscription', {
    include: 'billingPlan,billingAccount',
    ...(config || {}),
  }).then(({ data }) => data);
};

const getSetupIntent = (config?: iParams) => {
  return AppService.post('/billingSubscription/setupIntent', config || {}).then(
    ({ data }) => data,
  );
};

const getPaymentIntent = (config?: iParams) => {
  return AppService.post(
    '/billingSubscription/paymentIntent',
    config || {},
  ).then(({ data }) => data);
};

export default {
  getAllSubscriptionsForCompany,
  getSetupIntent,
  getPaymentIntent,
};
