import AppService from '../AppService';

export const getTaxRates = (config?: { [key: string]: string }) => {
  return AppService.get('/taxRate', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const createTaxRate = (postData: { [key: string]: string }) => {
  return AppService.post('/taxRate', postData).then(({ data }) => data);
};

export const deleteTaxRate = (id: string) => {
  return AppService.delete(`/taxRate/${id}`).then(({ data }) => data);
};

export const updateTaxRate = (id: string, updateContent: { [key: string]: string | number }) => {
  return AppService.put(`/taxRate/${id}`, updateContent).then(({ data }) => data);
};

export const getTaxBases = () => {
  return AppService.get('/taxBase').then(({ data }) => data);
};

export default {
  getTaxRates,
  createTaxRate,
  deleteTaxRate,
  updateTaxRate,
  getTaxBases,
};
