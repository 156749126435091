import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import Button, { ButtonProps } from '@atlaskit/button';
import { useHistory } from 'react-router-dom';
import iWorkflow from '../../types/workflow/iWorkflow';
import { SETTINGS_WORKFLOW_EDIT_URL } from '../UrlMap';
import WorkflowService from '../../services/WorkflowService';

const WorkflowEditBtn = ({ entityStatusTypeId, ...props }: ButtonProps & { entityStatusTypeId: string }) => {
  const history = useHistory();
  const [workflow, setWorkflow] = useState<iWorkflow | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (workflow !== null) {
      return;
    }
    let isCanceled = false;
    WorkflowService.getWorkflows({
      filter: `entityId:${entityStatusTypeId},entityName:EntityStatusType`,
    })
      .then(res => {
        if (isCanceled) {
          return;
        }
        setWorkflow(res.data[0] || null);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    // eslint-disable-next-line consistent-return
    return () => {
      isCanceled = true;
    };
  }, [entityStatusTypeId, workflow]);

  if (isLoading === true) {
    return <Spinner />;
  }

  if (workflow === null) {
    return null;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...props} onClick={() => history.push(SETTINGS_WORKFLOW_EDIT_URL.replace(':id', entityStatusTypeId))}>
      Workflow
    </Button>
  );
};

export default WorkflowEditBtn;
