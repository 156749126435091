/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import SectionMessage from '@atlaskit/section-message';
import { iPrefills } from '../../shared/DataImport.types';
import iContactCompanyType from '../../../../../types/contactCompany/iContactCompanyType';
import { apiErrorToast } from '../../../../../shared/toast/Toast';
import { getTypeIdByPageName } from '../../shared/DataImport.util';
import { handleNullException } from '../../../../../services/UtilsService';
import { getContactCompanyTypeList } from '../../../../../services/ContactCompanyService';
import { SectionMessageWidthWrapper } from '../../shared/DataImport.style';

type iState = {
  isLoading: boolean;
};
const initialState: iState = {
  isLoading: true,
};
const PreCheckContactCompany = ({
  onPreCheckResult,
  prefills,
  detailPageName,
}: {
  onPreCheckResult: (preCheckResult: iPrefills) => void;
  prefills: iPrefills;
  detailPageName: string;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      try {
        setState(prev => ({
          ...prev,
          isLoading: true,
        }));
        const cCompanyTypes: Array<iContactCompanyType> = await getContactCompanyTypeList();
        if (isCancelled) return;
        const typeId = getTypeIdByPageName(detailPageName, cCompanyTypes);
        setState(prev => ({
          ...prev,
          isLoading: false,
        }));
        onPreCheckResult({ typeId });
      } catch (error) {
        apiErrorToast(error);
        setState(prev => ({
          ...prev,
          isLoading: false,
        }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [detailPageName, onPreCheckResult]);

  if (state.isLoading) return <Spinner />;
  if (!handleNullException(prefills, 'typeId'))
    return (
      <SectionMessageWidthWrapper>
        <SectionMessage
          title={`Cannot start ${detailPageName}`}
          appearance="warning"
        >
          <p>Please contact Admin to add a contact-company-type for you</p>
        </SectionMessage>
      </SectionMessageWidthWrapper>
    );
  return null;
};

export default PreCheckContactCompany;
