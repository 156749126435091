import React, { useEffect, useState } from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import iContact from '../../../../types/contactCompany/iContact';
import iContactCompany from '../../../../types/contactCompany/iContactCompany';
import iContactCompanyRole from '../../../../types/contactCompany/iContactCompanyRole';
import iPalletSystem from '../../../../types/iPalletSystem';
import iWrappingMethod from '../../../../types/iWrappingMethod';
import MyobEntitySelector from '../../../channel/myob/MyobEntitySelector';
import IntegrationLinkEntityWithAccountingChannels from '../../../../pages/settings/integrations/IntegrationLinkEntityWithAccountingChannels';
import InlineEdit from '../../../form/InlineEdit';
import InlineEditLabel from '../../../form/inlineEdit/InlineEditLabel';
import InlineEditSelect from '../../../form/InlineEditSelect';
import TextArea from '../../../form/TextArea';
import AddressSelector from '../address/AddressSelector';
import { InlineEditContainer, SpaceVerticalContainer } from '../../../styles/styles';
import { apiErrorToast } from '../../../toast/Toast';
import { getPalletSystems } from '../../../../services/Settings/PalletSystemService';
import { getDefaultvalueForSelect, handleNullException, mapToLabelValuePair } from '../../../../services/UtilsService';
import { getWrappingMethods } from '../../../../services/Settings/WrappingMethodService';
import { getContactForRole, mapContactsForSelectOptions, onConfirm, onConfirmSelect } from '../Helper';
import MyobConnectorService from '../../../../services/integration/connectors/MyobConnectorService';

type iState = {
  isLoading: boolean;
  palletSystemList: Array<iPalletSystem>;
  wrappingMethodList: Array<iWrappingMethod>;
};
const initialState: iState = {
  isLoading: true,
  palletSystemList: [],
  wrappingMethodList: [],
};
const CustomerDetailEdit = ({
  contactCompany,
  updateLocalContactCompany,
  contacts,
  isDisabled = false,
}: {
  contactCompany?: iContactCompany;
  //  eslint-disable-next-line
  updateLocalContactCompany: (columnName: string, newValue: any) => void;
  contacts?: Array<iContact>;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const palletSystemList: Array<iPalletSystem> = await getPalletSystems({
          sort: 'name:ASC',
        });
        const wrappingMethodList: Array<iWrappingMethod> = await getWrappingMethods({
          sort: 'name:ASC',
        });
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          palletSystemList,
          wrappingMethodList,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <Page>
      <Grid layout={'fluid'}>
        <GridColumn medium={2}>
          <InlineEdit
            name={'name'}
            label={'Name'}
            defaultValue={contactCompany?.name}
            onConfirm={(name: string, value: string) =>
              onConfirm(name, value, updateLocalContactCompany, contactCompany)
            }
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={2}>
          <InlineEdit
            name={'email'}
            label={'Email'}
            defaultValue={contactCompany?.email}
            onConfirm={(name: string, value: string) =>
              onConfirm(name, value, updateLocalContactCompany, contactCompany)
            }
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={2}>
          <InlineEdit
            name={'phone'}
            label={'Phone'}
            defaultValue={contactCompany?.phone}
            onConfirm={(name: string, value: string) =>
              onConfirm(name, value, updateLocalContactCompany, contactCompany)
            }
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={2}>
          <InlineEdit
            name={'abn'}
            label={'ABN'}
            defaultValue={contactCompany?.abn}
            onConfirm={(name: string, value: string) =>
              onConfirm(name, value, updateLocalContactCompany, contactCompany)
            }
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={2}>
          <InlineEditSelect
            name={'defaultPalletSystemId'}
            label={'Pallet system'}
            defaultValue={getDefaultvalueForSelect(state.palletSystemList, contactCompany?.defaultPalletSystemId)}
            selectOptions={mapToLabelValuePair(state.palletSystemList)}
            onConfirm={(name: string, value: string | null) =>
              onConfirm(name, value, updateLocalContactCompany, contactCompany)
            }
            isClearable
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={2}>
          <InlineEditSelect
            name={'defaultWrappingMethodId'}
            label={'Wrapping Method'}
            defaultValue={getDefaultvalueForSelect(state.wrappingMethodList, contactCompany?.defaultWrappingMethodId)}
            selectOptions={mapToLabelValuePair(state.wrappingMethodList)}
            onConfirm={(name: string, value: string | null) =>
              onConfirm(name, value, updateLocalContactCompany, contactCompany)
            }
            isClearable
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'}>
        {contactCompany?.type.roles?.map((role: iContactCompanyRole) => (
          <GridColumn medium={2} key={role.id}>
            <InlineEditSelect
              name={role.id}
              label={`${role.name} Contact`}
              defaultValue={getContactForRole(role.id, contactCompany, contacts)}
              selectOptions={mapContactsForSelectOptions(contacts)}
              onConfirm={(roleId: string, contactId: string | null) =>
                onConfirmSelect(roleId, contactId, updateLocalContactCompany, contactCompany)
              }
              isDisabled={isDisabled}
            />
          </GridColumn>
        ))}
        <GridColumn medium={4}>
          <SpaceVerticalContainer className={'quarter'} />
          <InlineEditLabel label={'Shipping Address'} />
          <AddressSelector
            selectedAddressId={contactCompany?.shippingAddressId}
            onChange={(value: null | string) =>
              onConfirm('shippingAddressId', value, updateLocalContactCompany, contactCompany)
            }
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={4}>
          <SpaceVerticalContainer className={'quarter'} />
          <InlineEditLabel label={'Billing Address'} />
          <AddressSelector
            selectedAddressId={contactCompany?.billingAddressId}
            onChange={(value: null | string) =>
              onConfirm('billingAddressId', value, updateLocalContactCompany, contactCompany)
            }
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'}>
        <GridColumn medium={4}>
          <InlineEditContainer>
            <TextArea
              name={'deliveryInstructions'}
              label="Delivery Instructions"
              defaultValue={handleNullException(contactCompany, 'deliveryInstructions')}
              onChange={(name: string, value: string) =>
                onConfirm(name, value, updateLocalContactCompany, contactCompany)
              }
              minimumRows={4}
              debouncePeriod={700}
              isDisabled={isDisabled}
            />
          </InlineEditContainer>
        </GridColumn>
        <GridColumn medium={4}>
          <InlineEditContainer>
            <TextArea
              name={'description'}
              label="Notes"
              defaultValue={handleNullException(contactCompany, 'description')}
              onChange={(name: string, value: string) =>
                onConfirm(name, value, updateLocalContactCompany, contactCompany)
              }
              minimumRows={4}
              debouncePeriod={700}
              isDisabled={isDisabled}
            />
          </InlineEditContainer>
        </GridColumn>
        {contactCompany && (
          <GridColumn medium={6}>
            <InlineEditContainer>
              <IntegrationLinkEntityWithAccountingChannels
                getChannelEntitySelector={channelId => (
                  <MyobEntitySelector
                    key={channelId}
                    label="MYOB Contact"
                    channelId={channelId}
                    entityId={contactCompany.id}
                    entityName="ContactCompany"
                    promiseFn={MyobConnectorService.getMyobCustomers}
                    optionLabel={['CompanyName', 'DisplayID']}
                    isDisabled={isDisabled}
                  />
                )}
              />
            </InlineEditContainer>
          </GridColumn>
        )}
      </Grid>
    </Page>
  );
};

export default CustomerDetailEdit;
