import React from 'react';
import { useForm } from 'react-hook-form';

import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import PlusIconAfterTitle from '../../../components/settings/PlusIconAfterTitle';
import BackendPage from '../../../layout/BackendPage';
import Sidebar from '../sidebar/Sidebar';

import { createUnit, deleteUnit, getUnits, updateUnit } from '../../../services/Settings/UnitMeasurementService';
import { FlexSpaceBetweenContainer } from '../../../shared/contactCompany/style';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import SearchBar from '../../../shared/search/SearchBar';
import iUnitOfMeasurements from '../../../types/iUnitOfMeasurement';
import UnitEditModal from './UnitEditModal';
import UnitListTable from './UnitListTable';
import { objectContainsInvalidValues } from '../../sales/utilities';

const UnitSetting = () => {
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onSearch,
    onSubmit,
    onSetSort,
  } = useListCrudHook<iUnitOfMeasurements>({
    getFn: getUnits,
    createFn: createUnit,
    updateFn: updateUnit,
    deleteFn: deleteUnit,
    keywordColumns: 'shortName',
    sort: 'shortName:ASC',
  });

  const { control, setValue, errors, handleSubmit } = useForm();

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'Units of Measure'} />
      <FlexSpaceBetweenContainer>
        <PlusIconAfterTitle pageTitle={'Units of Measure'} onClick={onOpenAddModal} />
        <div style={{ flexGrow: 0 }}>
          <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search unit name...'} />
        </div>
      </FlexSpaceBetweenContainer>
    </>
  );

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <UnitListTable
        units={state.data}
        onDelete={onDelete}
        onEdit={onOpenEditModal}
        onSetSort={onSetSort}
        isLoading={state.isLoading}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0 || objectContainsInvalidValues(control.getValues())}
        modalBody={<UnitEditModal control={control} errors={errors} onChange={setValue} targetUnit={edit.target} />}
        modalHeading={edit.target ? edit.target.shortName : 'Create'}
        confirmBtnName={'Save'}
      />
    </BackendPage>
  );
};

export default UnitSetting;
