import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../shared/constants/AsyncConstants';
import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const getEntityStatusList = (config?: iParams) => {
  return AppService.get('/entityStatus', {
    ...config,
    include: 'entityStatusType,entityStatusCategory,createdBy,updatedBy',
  }).then(({ data }) => data);
};

const getEntityStatuses = (config?: iParams) => {
  return AppService.get('/entityStatus', config).then(({ data }) => data);
};

const getNextStatuses = (id: string, config?: iParams) => {
  return AppService.get(`/entityStatus/${id}/next`, config).then(({ data }) => data);
};

const createEntityStatus = (postContent: iParams) => {
  return AppService.post('/entityStatus', postContent).then(({ data }) => data);
};

const updateEntityStatus = (id: string, updateContent: iParams) => {
  return AppService.put(`/entityStatus/${id}`, updateContent).then(({ data }) => data);
};

const deleteEntityStatus = (id: string) => {
  return AppService.delete(`/entityStatus/${id}`).then(({ data }) => data);
};

const getEntityStatusListAsyncSearch = (config?: iParams) => {
  return AppService.get('/entityStatus', {
    ...config,
    perPage: ASYNC_SEARCH_PER_PAGE,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
  }).then(({ data }) => data);
};

export default {
  getEntityStatuses,
  getEntityStatusList,
  getNextStatuses,
  createEntityStatus,
  updateEntityStatus,
  deleteEntityStatus,
  getEntityStatusListAsyncSearch,
};
