import React, { useState } from 'react';
import moment from 'moment';
import DynamicTable from '@atlaskit/dynamic-table';
import Button from '@atlaskit/button';
import styled from 'styled-components';

import iUnitOfMeasurements from '../../../types/iUnitOfMeasurement';
import {
  // getCreatedOrUpdatedAt,
  getDeleteBtn,
  getHeads,
} from '../../../components/settings/utils';
import { iSortOrderType } from '../../../shared/UITypes/types';
import { handleNullException } from '../../../services/UtilsService';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';

const TableContainer = styled.div`
  th:nth-child(2) {
    width: 30%;
    min-width: 15rem;
  }

  td:nth-child(n + 3):nth-child(-n + 4) {
    width: 20%;
    min-width: 12rem;
  }
`;

const COLUMNS = ['Name', 'Description', 'Created', 'Updated', 'Operation'];

const UnitListTable = ({
  units,
  onDelete,
  onEdit,
  onSetSort,
  isLoading,
}: {
  units: Array<iUnitOfMeasurements>;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onSetSort: (sortStr: string) => void;
  isLoading: boolean;
}) => {
  const [sortSequence, setSortSequence] = useState<iSortOrderType>('ASC');

  const getNameContent = (unit: iUnitOfMeasurements) => (
    <Button
      appearance={'link'}
      spacing={'none'}
      onClick={() => onEdit(unit.id)}
      testId={`unit-list-linkBtn-${unit.id}`}
    >
      {unit.shortName}
    </Button>
  );

  const getRows = () => {
    return units
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iUnitOfMeasurements) => ({
        cells: [
          { key: item.shortName, content: getNameContent(item) },
          { content: item.name },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'createdAt')}
                operator={handleNullException(item, 'createdBy.firstName')}
              />
            ),
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'updatedAt')}
                operator={handleNullException(item, 'updatedBy.firstName')}
              />
            ),
          },
          {
            content: getDeleteBtn({
              id: item.id,
              answer: item.shortName || 'name n/a',
              onClick: onDelete,
            }),
          },
        ],
      }));
  };

  const onSort = () => {
    setSortSequence(sortSequence === 'ASC' ? 'DESC' : 'ASC');
    onSetSort(`shortName:${sortSequence === 'ASC' ? 'DESC' : 'ASC'}`);
  };

  return (
    <TableContainer>
      <DynamicTable
        head={getHeads(COLUMNS, 'settings-unit')}
        rows={getRows()}
        testId={'settings-unit-list-table'}
        isLoading={isLoading}
        onSort={onSort}
        sortKey={'Name'}
        defaultSortKey={'Name'}
        defaultSortOrder={sortSequence}
        sortOrder={sortSequence}
        rowsPerPage={10}
        defaultPage={1}
      />
    </TableContainer>
  );
};

export default UnitListTable;
