import React, { useState } from 'react';
import { colors } from '@atlaskit/theme';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonGroup } from '@atlaskit/button';

import {
  deletePoReceiving,
  updatePoReceiving,
} from '../../../services/PoReceivingService';
import PopupContent from '../../../shared/contactCompany/detail/popup/PopupContent';
import DeleteByReType from '../../../shared/deleteWithReType/DeleteByReType';
import FlexSpaceBetweenContainer from '../../../shared/job/jobDetails/styles/FlexSpaceBetweenContainer';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import {
  addToastForAPIResponse,
  apiErrorToast,
} from '../../../shared/toast/Toast';
import { PURCHASES_URL } from '../../../shared/UrlMap';
import iPoReceiving from '../../../types/purchases/iPoReceiving';
import {
  FlexContainer,
  PageTitle,
  SelectorWrapper,
} from '../../../shared/styles/styles';
import StatusMenu from '../../../shared/statusComponent/StatusMenu';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { PO_RECEIVING_STATUS_CLOSED_CATEGORY } from '../constants';

const Wrapper = styled.div`
  margin-bottom: 1rem;
  > * {
    margin: 0.5rem 0;
  }

  & .Breadcrumb {
    font-size: 0.9rem;
    color: ${colors.N100};
    font-weight: 500;
  }
`;

const Breadcrumb = ({ children }: { children: React.ReactNode }) => (
  <div className="Breadcrumb">{children}</div>
);

const Header = ({
  poReceiving,
  categories,
  id,
  poId,
  onRefresh,
}: {
  poReceiving?: iPoReceiving;
  categories: Array<iEntityCategory>;
  id: string;
  poId?: string;
  onRefresh: () => void;
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const history = useHistory();

  const onUpdateDetail = async (
    fieldName: string,
    fieldValue: string | boolean | number | null | undefined,
  ) => {
    if (typeof poReceiving === 'undefined') {
      return;
    }
    try {
      await updatePoReceiving(id, { [fieldName]: fieldValue });
      onRefresh();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const onOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const onDelete = async () => {
    try {
      await deletePoReceiving(id);
      if (poId) {
        history.push(`/b/purchases/${poId}`);
      } else {
        history.push(`${PURCHASES_URL}`);
      }
    } catch (error) {
      setIsDeleteModalOpen(false);
      apiErrorToast(error);
    }
  };

  return (
    <>
      <FlexSpaceBetweenContainer>
        <Wrapper>
          <Breadcrumb>
            <Link to="/b/purchases" className="Breadcrumb__Link">
              Purchase Orders
            </Link>{' '}
            /{' '}
            <Link
              to={`/b/purchases/${poReceiving?.purchaseOrder?.id}`}
              className="Breadcrumb__Link"
            >
              {`PO: ${poReceiving?.purchaseOrder?.orderNumber}`}
            </Link>{' '}
            / Receiving: {poReceiving?.reference}
          </Breadcrumb>

          <FlexContainer>
            <PageTitle className={'small space-right'}>
              Receiving PO: {poReceiving?.purchaseOrder?.orderNumber}
            </PageTitle>
            <SelectorWrapper>
              <StatusMenu
                defaultValue={poReceiving?.statusId}
                categories={categories}
                onSelect={(newValue: string) =>
                  onUpdateDetail('statusId', newValue)
                }
              />
            </SelectorWrapper>
          </FlexContainer>
        </Wrapper>

        <ButtonGroup>
          <MoreIconPopup>
            {poReceiving?.status?.entityStatusCategory?.code !==
              PO_RECEIVING_STATUS_CLOSED_CATEGORY && (
              <PopupContent
                id={id}
                onOpenModal={onOpenDeleteModal}
                btnName={'Delete'}
              />
            )}
          </MoreIconPopup>
        </ButtonGroup>
      </FlexSpaceBetweenContainer>
      {isDeleteModalOpen && (
        <DeleteByReType
          isOpen={id !== ''}
          onConfirm={onDelete}
          onClose={() => setIsDeleteModalOpen(false)}
          answer={poReceiving?.reference || 'reference number N/A'}
        />
      )}
    </>
  );
};

export default Header;
