import React from 'react';
import styled from 'styled-components';
import { handleNullException, numberToPercentageWithoutSuffix } from '../../../../services/UtilsService';
import iBomItem from '../../../../types/product/iBomItem';
import iBomItemAttribute from '../../../../types/product/iBomItemAttribute';
import iBomItemAttributeValue from '../../../../types/product/iBomItemAttributeValue';
import InlineEdit from '../../../form/InlineEdit';
import { BOM_ATTRIBUTE_HOPPER } from '../../constants';

const InlineEditContainer = styled.div`
  padding-bottom: 0.5rem;
`;

export const getOrderType = (orderType: string | null) => {
  if (orderType === 'ASC' || orderType === 'DESC') {
    return orderType;
  }
  return undefined;
};

export const getSortKey = (sortKeyFromStorage: string | null) => {
  if (!sortKeyFromStorage) return '';
  return sortKeyFromStorage;
};

export const getHeads = (columns: Array<string>, tableName: string) => {
  const cells = columns.map((column: string) => {
    switch (column.toUpperCase()) {
      case 'OPERATION':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'ADD':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      case 'DELETE':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      default:
        return {
          key: column,
          content: column,
          testId: `${tableName}-table-column-${column}`,
          isSortable: true,
        };
    }
  });
  return { cells };
};

export const getBomItemAttrHeads = (cluster: Array<iBomItemAttribute>) =>
  cluster.sort((a, b) => a.sortOrder - b.sortOrder).map((item: iBomItemAttribute) => item.name);

export const getBomItemAttrContent = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bomItem: any,
  bomItemAttr: iBomItemAttribute,
  onConfirmBomAttributeValue: (
    name: string,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bomItem: any,
  ) => void,
  bomItemAttrValue?: iBomItemAttributeValue,
  isDisabled?: boolean,
) => {
  const isHopper = bomItemAttr.name.toUpperCase() === BOM_ATTRIBUTE_HOPPER;
  const defaultPercentageValue = numberToPercentageWithoutSuffix(bomItemAttrValue?.value, 2);

  return (
    <InlineEditContainer>
      <InlineEdit
        name={`${bomItem.id}:${bomItemAttr.id}`}
        defaultValue={
          // eslint-disable-next-line no-nested-ternary
          !isHopper
            ? Number(defaultPercentageValue) === 0
              ? ''
              : defaultPercentageValue
            : handleNullException(bomItemAttrValue, 'value')
        }
        label={''}
        onConfirm={(name: string, value: string) => onConfirmBomAttributeValue(name, value, bomItem)}
        hideActionButtons
        postfix={isHopper ? undefined : '%'}
        isNumeric={!isHopper}
        isZeroNull={!isHopper}
        isDisabled={isDisabled}
      />
    </InlineEditContainer>
  );
};

export const getBomItemAttrRows = (
  item: iBomItem,
  bomItemAttributes: iBomItemAttribute[],
  onConfirmBomAttributeValue: (
    name: string,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bomItem: any,
  ) => void,
  isDisabled?: boolean,
) =>
  bomItemAttributes.map((attr: iBomItemAttribute) => {
    const bomItemAttrValue = item.bomItemAttributes?.find((b: iBomItemAttribute) => b.id === attr.id)
      ?.bomItemAttributeValues;
    return {
      key: handleNullException(bomItemAttrValue, 'value'),
      content: getBomItemAttrContent(item, attr, onConfirmBomAttributeValue, bomItemAttrValue, isDisabled),
    };
  });
