import React from 'react';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { formatDate } from '../../services/UtilsService';

const FooterContainer = styled.div`
  border-top: 1px solid ${colors.placeholderText};
  display: flex;
  flex-direction: column;
  .footer-item {
    margin-top: 5px;
  }
  margin-top: 1rem;
`;
type iOperation = {
  operator?: string;
  time?: string;
};
const LogFooter = ({
  created,
  updated,
}: {
  created?: iOperation;
  updated?: iOperation;
}) => {
  return (
    <FooterContainer>
      {created && (
        <small className={'footer-item'}>
          Created by {created.operator || ''} @ {formatDate(created.time)}
        </small>
      )}
      {updated && (
        <small className={'footer-item'}>
          Updated by {updated.operator || ''} @ {formatDate(updated.time)}
        </small>
      )}
    </FooterContainer>
  );
};

export default LogFooter;
