import React from 'react';
import Activities from '../../../shared/activitySection/GeneralActivity';
import GeneralAttachments from '../../../shared/generalAttachments/GeneralAttachments';

const AttachmentsAndActivity = ({
  entityId,
  entityName,
  canCreate,
  canDelete,
}: {
  entityId: string;
  entityName: string;
  canCreate?: boolean;
  canDelete?: boolean;
}) => {
  return (
    <>
      <GeneralAttachments entityId={entityId} entityName={entityName} canCreate={canCreate} canDelete={canDelete} />
      <Activities modelId={entityId} modelName={'entityName'} />
    </>
  );
};

export default AttachmentsAndActivity;
