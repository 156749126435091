import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import iJob from '../../../types/job/iJob';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import { JOB_URL } from '../../../shared/UrlMap';
import { MAXIMUM_JOBS } from '../shared/Dashboard.constants';
import { FlexContainer } from '../../../shared/styles/styles';
import { handleNullException } from '../../../services/UtilsService';
import { NA } from '../../../shared/constants/ActionConstants';
import MathHelper from '../../../services/MathHelper';

const ProductionJob = ({ title, jobs }: { title: string; jobs: Array<iJob> }) => {
  const getJobQuantityInfo = (job: iJob) => {
    const post = `${job.qtyTotal || NA} ${handleNullException(job, 'product.measurement.shortName')}`;
    if (!job.qtyTotal || !job.qtyToMake || job.qtyTotal === job.qtyToMake) return post;
    return `${MathHelper.sub(job.qtyTotal, job.qtyToMake)}/${post}`;
  };
  return (
    <Page>
      {jobs.slice(0, MAXIMUM_JOBS).map((job: iJob, index: number) => (
        <Grid spacing={'compact'} layout={'fluid'} key={job.id}>
          <GridColumn medium={3}>{index === 0 && title}</GridColumn>
          <GridColumn medium={5}>
            <LinkBtnUrl key={job.id} btnName={job.jobNumber} url={`${JOB_URL}/${job.id}`} />
          </GridColumn>
          <GridColumn medium={4}>
            <FlexContainer className={'space-between'}>
              <div />
              <Lozenge appearance="success">{getJobQuantityInfo(job)}</Lozenge>
            </FlexContainer>
          </GridColumn>
        </Grid>
      ))}
    </Page>
  );
};

export default ProductionJob;
