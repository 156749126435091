import React, { useState, useEffect } from 'react';
import Page from '@atlaskit/page';
import Spinner from '@atlaskit/spinner';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailPageHeader from './header/Header';
import { apiErrorToast } from '../../../shared/toast/Toast';
import iSalesOrderDetail from '../../../types/sales/iSalesOrder';
import SODetailEdit from './SODetailEdit';
import SalesOrderItem from './SalesOrderItem';
import LogFooter from './LogFooter';
import iSalesOrderType from '../../../types/sales/iSalesOrderType';
import { getSalesOrderDetail, getSalesOrderTypes } from '../../../services/SalesOrderService';
import { iTaxBase, iTaxRate } from '../../../types/iTaxRate';
import { getTaxBases, getTaxRates } from '../../../services/Settings/TaxService';
import { AttachmentsWrapper, SpinnerWrapper } from '../../../shared/styles/styles';
import BackendPage from '../../../layout/BackendPage';
import { getJobs } from '../../../services/JobService';
import iUnitOfMeasurements from '../../../types/iUnitOfMeasurement';
import iPalletSystem from '../../../types/iPalletSystem';
import iMachine from '../../../types/machine/iMachine';
import { getMachines } from '../../../services/Settings/MachineService';
import { getPalletSystems } from '../../../services/Settings/PalletSystemService';
import { getJobAttributes } from '../../../services/JobAttributeService';
import { getUnits } from '../../../services/Settings/UnitMeasurementService';
import { iCreateJobParams } from '../types';
import SOTabs from './SOTabs';
import { iRouteTypeId } from '../../../shared/contactCompany/detail/types';
import iEntityCategory, { CATEGORY_CODE_FINISHED } from '../../../types/status/iEntityCategory';
import { getEntityCategoryList } from '../../../services/CategoryService';
import GeneralAttachments from '../../../shared/generalAttachments/GeneralAttachments';
import useListLengthHook from '../../../shared/hooks/useListLengthHook/useListLengthHook';
import { getPayments } from '../../../services/integration/PaymentService';
import { INVOICE, SO_TYPE_NAME_INVOICE, SO_TYPE_NAME_QUOTE, SO_TYPE_NAME_SALES_ORDER } from './constants';
import PaymentInfo from './payments/PaymentInfo';
import ContactCompanyContextProvider from '../../../shared/contactCompany/detail/context/ContactCompanyContext';
import iChannel from '../../../types/integration/iChannel';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_INVOICES,
  ACCESS_CODE_QUOTES,
  ACCESS_CODE_SALES_ORDERS,
} from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE, ACCESS_CAN_READ, ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';
import RestrictedAccessEmptyState from '../../../shared/UserAccess/RestrictedAccessEmptyState';

const initialState: iCreateJobParams = {
  machines: [],
  palletSystems: [],
  jobAttributes: [],
  units: [],
};
const SalesOrderDetail = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const { id } = useParams<iRouteTypeId>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [localSalesOrderDetail, setLocalSalesOrderDetail] = useState<iSalesOrderDetail>();

  const canReadQuotes = AccessService.hasAccess(ACCESS_CODE_QUOTES, ACCESS_CAN_READ, user);
  const canReadSOs = AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_READ, user);
  const canReadInvoices = AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_READ, user);
  const canRead =
    // eslint-disable-next-line no-nested-ternary
    localSalesOrderDetail?.type?.name === SO_TYPE_NAME_INVOICE
      ? canReadInvoices
      : // eslint-disable-next-line no-nested-ternary
      localSalesOrderDetail?.type?.name === SO_TYPE_NAME_SALES_ORDER
      ? canReadSOs
      : localSalesOrderDetail?.type?.name === SO_TYPE_NAME_QUOTE
      ? canReadQuotes
      : true;

  const canUpdateQuotes = AccessService.hasAccess(ACCESS_CODE_QUOTES, ACCESS_CAN_UPDATE, user);
  const canUpdateSOs = AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_UPDATE, user);
  const canUpdateInvoices = AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_UPDATE, user);
  const canUpdate =
    // eslint-disable-next-line no-nested-ternary
    localSalesOrderDetail?.type?.name === SO_TYPE_NAME_INVOICE
      ? canUpdateInvoices
      : // eslint-disable-next-line no-nested-ternary
      localSalesOrderDetail?.type?.name === SO_TYPE_NAME_SALES_ORDER
      ? canUpdateSOs
      : localSalesOrderDetail?.type?.name === SO_TYPE_NAME_QUOTE
      ? canUpdateQuotes
      : true;

  const canDeleteQuotes = AccessService.hasAccess(ACCESS_CODE_QUOTES, ACCESS_CAN_DELETE, user);
  const canDeleteSOs = AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_DELETE, user);
  const canDeleteInvoices = AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_DELETE, user);
  const canDelete =
    // eslint-disable-next-line no-nested-ternary
    localSalesOrderDetail?.type?.name === SO_TYPE_NAME_INVOICE
      ? canDeleteInvoices
      : // eslint-disable-next-line no-nested-ternary
      localSalesOrderDetail?.type?.name === SO_TYPE_NAME_SALES_ORDER
      ? canDeleteSOs
      : localSalesOrderDetail?.type?.name === SO_TYPE_NAME_QUOTE
      ? canDeleteQuotes
      : true;

  const isFinished = `${localSalesOrderDetail?.status.entityStatusCategory?.code || ''}` === CATEGORY_CODE_FINISHED;

  const [salesOrderTypes, setSalesOrderTypes] = useState<iSalesOrderType[]>([]);
  const [taxRates, setTaxRates] = useState<iTaxRate[]>([]);
  const [taxBases, setTaxBases] = useState<iTaxBase[]>([]);
  const [jobs, setJobs] = useState([]);
  const [createJobParams, setCreateJobParams] = useState(initialState);
  const [localCategories, setLocalCategories] = useState<Array<iEntityCategory>>([]);
  const [jobsVersion, setJobsVersion] = useState<number>(0);
  const [givenTabName, setGivenTabName] = useState<string>();
  const [isIntegrated, setIsIntegrated] = useState<boolean>(false);
  const { state: paymentState } = useListLengthHook({
    getFn: getPayments,
    config:
      localSalesOrderDetail?.type?.name === INVOICE
        ? { filter: `paidForEntityName:SalesOrder,paidForEntityId:${id}` }
        : undefined,
  });

  const perPage = 50;

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const SO = await getSalesOrderDetail(id);
        const salesOrderTypeData: iSalesOrderType[] = await getSalesOrderTypes({
          filter: `name:${SO_TYPE_NAME_SALES_ORDER}|${SO_TYPE_NAME_QUOTE}`,
        });
        const taxRateData: iTaxRate[] = await getTaxRates();
        const taxBaseData: iTaxBase[] = await getTaxBases();
        const { data } = await getJobs(1, perPage, {
          filter: `salesOrderId:${id}`,
        });
        const machines = await getMachines();
        const palletSystems = await getPalletSystems();
        const jobAttributes = await getJobAttributes({ sort: 'sortOrder:ASC' });
        const units = await getUnits();
        const categories: Array<iEntityCategory> = await getEntityCategoryList({
          filter: 'entityStatuses.entityStatusType.entityName:SalesOrder',
        });

        if (mounted) {
          setLocalSalesOrderDetail(SO);
          setSalesOrderTypes(salesOrderTypeData);
          setTaxRates(taxRateData);
          setTaxBases(taxBaseData);
          setJobs(data);
          setLocalCategories(categories);

          setCreateJobParams(preState => ({
            ...preState,
            machines: machines.map((m: iMachine) => ({
              label: m.name,
              value: m.id,
            })),
            palletSystems: palletSystems.map((p: iPalletSystem) => ({
              label: p.name,
              value: p.id,
            })),
            units: units.map((u: iUnitOfMeasurements) => ({
              label: u.shortName,
              value: u.shortName,
            })),
            jobAttributes,
          }));
        }
      } catch (error) {
        apiErrorToast(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [id]);

  const getPageHeader = () => (
    <DetailPageHeader
      order={localSalesOrderDetail}
      types={salesOrderTypes}
      categories={localCategories}
      isOrderDeletable={jobs.length === 0}
      id={id}
      integrated={isIntegrated}
      canDelete={canDelete && !isFinished}
      canUpdate={canUpdate}
    />
  );
  const getPageFooter = () => <LogFooter order={localSalesOrderDetail} />;

  const refreshJobList = () => setJobsVersion(jobsVersion + 1);

  const getLessPayment = () => {
    return localSalesOrderDetail && localSalesOrderDetail.totalAmt && localSalesOrderDetail.totalAmtDue
      ? localSalesOrderDetail?.totalAmt - localSalesOrderDetail?.totalAmtDue
      : undefined;
  };

  const checkIsIntegrated = (channels: iChannel[]) => {
    if (channels.length >= 1) {
      setIsIntegrated(true);
    }
  };

  const getContent = () => {
    if (isLoading || paymentState.isLoading) {
      return (
        <SpinnerWrapper>
          <Spinner size="xlarge" />
        </SpinnerWrapper>
      );
    }

    if (!canRead) {
      return (
        <RestrictedAccessEmptyState
          user={user}
          description={`You don't access to ${localSalesOrderDetail?.type?.name || 'Order'}`}
        />
      );
    }

    return (
      <BackendPage pageHeader={getPageHeader()} pageFooter={getPageFooter()}>
        <Page>
          <ContactCompanyContextProvider contactCompanyId={id}>
            <SODetailEdit
              order={localSalesOrderDetail}
              id={id}
              checkIsIntegrated={checkIsIntegrated}
              isDisabled={!canUpdate || isFinished}
            />
          </ContactCompanyContextProvider>

          <SalesOrderItem
            id={id}
            order={localSalesOrderDetail}
            taxRates={taxRates}
            taxBases={taxBases}
            createCallback={refreshJobList}
            createJobParams={createJobParams}
            canUpdate={canUpdate && !isFinished}
            canCreate={canUpdate && !isFinished}
            canDelete={canUpdate && !isFinished}
          >
            <PaymentInfo
              isShowPayments={typeof paymentState.length === 'number' && paymentState.length > 0}
              lessPayment={getLessPayment()}
              amountDue={localSalesOrderDetail?.totalAmtDue}
              onClick={() => setGivenTabName('Payments')}
            />
          </SalesOrderItem>
          <AttachmentsWrapper>
            <GeneralAttachments
              entityId={id}
              entityName={'SalesOrder'}
              canDelete={canUpdate && !isFinished}
              canCreate={canUpdate}
            />
          </AttachmentsWrapper>
          <SOTabs
            orderId={id}
            jobsVersion={jobsVersion}
            givenTabName={givenTabName}
            isShowPayments={typeof paymentState.length === 'number' && paymentState.length > 0}
          />
        </Page>
      </BackendPage>
    );
  };

  return getContent();
};

export default SalesOrderDetail;
