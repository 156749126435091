import React from 'react';
import CommentList from '../../../shared/comments/CommentList';
import LogList from '../../../shared/log/LogList';
import CustomizeTab from '../../../shared/tab/CustomizeTab';

const WarehouseTabs = ({ id }: { id: string }) => {
  const tabs = [
    {
      label: 'Comments',
      content: <CommentList modelName={'WarehouseLocation'} modelId={id} />,
    },
    {
      label: 'History',
      content: <LogList modelName={'WarehouseLocation'} modelId={id} />,
    },
  ];
  return <CustomizeTab components={tabs} />;
};

export default WarehouseTabs;
