import React from 'react';
import styled from 'styled-components';

const TriggerWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: rgb(224, 32, 32);
  cursor: pointer;
  margin-right: 0.5rem;
`;
const UnScheduledTrigger = ({
  count,
  onClick,
}: {
  count: number;
  onClick: () => void;
}) => {
  return (
    <TriggerWrapper onClick={onClick}>{count} unscheduled jobs</TriggerWrapper>
  );
};

export default UnScheduledTrigger;
