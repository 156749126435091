import React, { useState } from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import moment from 'moment';
import Tooltip from '@atlaskit/tooltip';
import Toggle from '@atlaskit/toggle';

import { useSelector } from 'react-redux';
import { SALES_ORDER_ITEM_TABLE_COLUMNS, SALES_ORDER_ITEM_TABLE_COLUMNS_HIDE_PRICE } from '../constants';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import iSalesOrderItem from '../../../types/sales/iSalesOrderItem';
import iSalesOrderDetail from '../../../types/sales/iSalesOrder';
import InlineEdit from '../../../shared/form/InlineEdit';
import SelectSingle from '../../../shared/form/SingleSelect';
import { getSalesOrderDetail, updateSalesOrder } from '../../../services/SalesOrderService';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/Toast';
import {
  createSalesOrderItem,
  deleteSalesOrderItem,
  updateSalesOrderItem,
} from '../../../services/SalesOrderItemService';
import { createOption, createOptions } from '../utilities';
import { iTaxBase, iTaxRate } from '../../../types/iTaxRate';
import { handleNullException, handleNullExceptionMultiFields, numberRound } from '../../../services/UtilsService';
import ElementWithPopup from '../../../shared/contactCompany/list/table/ElementWithPopup';
import { iCreateJobParams } from '../types';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import { getHeads } from '../../../components/settings/utils';
import { PRODUCT_DETAIL_URL } from '../../../shared/UrlMap';
import { getUnitPriceMeasure } from '../../purchases/Purchase.utilities';
import CreateJob from './createJob/CreateJob';
import { FlexContainer } from '../../../shared/styles/styles';
import iContactCompanyProduct from '../../../types/contactCompany/iContactCompanyProduct';
import AddOrderItem from '../../../shared/addOrderItem/AddOrderItem';
import iProduct from '../../../types/product/iProduct';
import TotalPrice from '../../../shared/totalPrice/TotalPrice';
import ConversionCalculateText from '../../../shared/conversionCalculate/ConCalculateText';
import InlineEditTextarea from '../../../shared/form/InlineEditTextarea';
import { SO_TYPE_NAME_INVOICE } from './constants';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_JOBS, ACCESS_CODE_PRODUCTS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';

const SelectorWrapper = styled.div`
  min-width: 8rem;
`;

const InlineEditContainer = styled.div`
  padding-bottom: 0.5rem;

  &.tax-select {
    padding-bottom: 0;
  }
`;

const TableWrapper = styled.div`
  min-height: 2.5rem;
  position: relative;
  margin-top: 1rem;

  table[data-testid='sales-item-list-table--table'] {
    tr {
      vertical-align: top;
    }
    td {
      padding-top: 1rem;
    }
    td[data-testid='sales-item-list-table--cell-1'],
    td[data-testid='sales-item-list-table--cell-3'],
    td[data-testid='sales-item-list-table--cell-4'] {
      padding-top: 0rem;
    }
    td[data-testid='sales-item-list-table--cell-5'] {
      padding-top: 0.6rem;
    }
    th[data-testid='soItems-table-column-Product Code / SKU--head--cell'] {
      width: 15rem;
      min-width: 11rem;
    }

    th[data-testid='soItems-table-column-Quantity--head--cell'] {
      width: 14rem;
      min-width: 14rem;
    }
    th[data-testid='soItems-table-column-Unit Price--head--cell'] {
      width: 10rem;
      min-width: 10rem;
    }
    th[data-testid='soItems-table-column-Disc %--head--cell'] {
      width: 5rem;
      min-width: 5rem;
    }
    th[data-testid='soItems-table-column-Tax--head--cell'] {
      width: 8rem;
      min-width: 8rem;
    }
    th[data-testid='soItems-table-column-Total (Inc GST)--head--cell'] {
      width: 7rem;
      min-width: 7rem;
    }

    td:nth-last-child(1),
    td:nth-last-child(2) {
      width: 1.5rem;
      min-width: 1.5rem;
    }
  }

  .total-price,
  .margin-amt {
    text-align: right;
  }
`;

interface iProps {
  id: string;
  order?: iSalesOrderDetail;
  taxRates: iTaxRate[];
  taxBases: iTaxBase[];
  createCallback: () => void;
  createJobParams: iCreateJobParams;
  children?: React.ReactNode;
  canUpdate?: boolean;
  canCreate?: boolean;
  canDelete?: boolean;
}

const SalesOrderItem = ({
  id,
  order,
  taxRates,
  taxBases,
  createCallback,
  createJobParams,
  children,
  canCreate,
  canUpdate,
  canDelete,
}: iProps) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadProducts = AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user);
  const canCreateJob = AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_CREATE, user);

  const [localOrderDetail, setLocalOrderDetail] = useState(order);
  const [shouldDisplayPrice, setShouldDisplayPrice] = useState(true);

  const isInvoice = handleNullException(localOrderDetail, 'type.name').toUpperCase() === SO_TYPE_NAME_INVOICE;

  const fetchSalesOrderData = async () => {
    try {
      const data = await getSalesOrderDetail(id);
      setLocalOrderDetail(data);
    } catch (error) {
      apiErrorToast(error);
    }
  };

  // update Sales Order Item
  const onConfirmSalesOrderItem = async (name: string, newValue: string | number) => {
    try {
      await updateSalesOrderItem(name.split(':')[0], {
        [`${name.split(':')[1]}`]: newValue,
      });
      addToastForAPIResponse('success');
      fetchSalesOrderData();
    } catch (error) {
      apiErrorToast(error);
    }
  };

  // update Sales Order Detail
  const onConfirmSelect = async (targetId: string, newValue: string) => {
    try {
      await updateSalesOrder(targetId, {
        taxBaseId: newValue,
      });
      fetchSalesOrderData();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const getDeleteBtn = (targetId: string, onClick: (targetId: string) => void) => {
    return (
      <ElementWithPopup
        onClick={() => onClick(targetId)}
        popupContent={'Are you sure to delete it?'}
        popupHeading={"please confirm you're about to delete it"}
      >
        <TrashIcon label="delete" />
      </ElementWithPopup>
    );
  };

  const onDelete = async (deleteItemId: string) => {
    try {
      await deleteSalesOrderItem(deleteItemId);
      fetchSalesOrderData();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const getAddJobBtn = (
    salesOrderItem: iSalesOrderItem,
    //  eslint-disable-next-line
    createCallback: () => void,
  ) => {
    return salesOrderItem.product?.isForMake ? (
      <CreateJob
        targetItem={salesOrderItem}
        params={createJobParams}
        defaultPalletSystemId={localOrderDetail?.customer.defaultPalletSystemId}
        salesOrderId={id}
        createCallback={createCallback}
      />
    ) : null;
  };

  const getNameContent = (item: iSalesOrderItem) => (
    <ComposeSecondaryText secondaryText={item.product.name}>
      {canReadProducts ? (
        <LinkBtnUrl btnName={item.product.productCode} url={`${PRODUCT_DETAIL_URL.replace(':id', item.productId)}`} />
      ) : (
        `${item.product.productCode || ''}`
      )}
    </ComposeSecondaryText>
  );

  const getRows = () => {
    return localOrderDetail?.salesOrderItems
      ?.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
      .map((item: iSalesOrderItem) => ({
        cells: [
          {
            key: item.product.productCode,
            content: getNameContent(item),
          },
          {
            content: (
              <InlineEditContainer>
                <InlineEditTextarea
                  name={`${item.id}:description`}
                  defaultValue={handleNullException(item, 'description')}
                  label={''}
                  onConfirm={onConfirmSalesOrderItem}
                  hideActionButtons
                  isDisabled={!canUpdate}
                />
              </InlineEditContainer>
            ),
          },
          {
            content: (
              <ConversionCalculateText
                name={`${item.id}:qty`}
                defaultValue={`${item.qty}`}
                onConfirm={onConfirmSalesOrderItem}
                conversion={item.unitConversion || 1}
                productUnit={handleNullException(item, 'product.measurement.shortName')}
                contactCompanyUnit={handleNullExceptionMultiFields(
                  item,
                  'product.measurement.shortName,measurement.shortName',
                )}
                isDisabled={!canUpdate}
              />
            ),
          },
          {
            content: shouldDisplayPrice && (
              <InlineEditContainer>
                <InlineEdit
                  name={`${item.id}:unitPrice`}
                  defaultValue={`${item.unitPrice}`}
                  label={''}
                  onConfirm={onConfirmSalesOrderItem}
                  prefix={'$'}
                  postfix={`/ ${getUnitPriceMeasure(item)}`}
                  needFormatValue
                  hideActionButtons
                  isRequired
                  isNumeric
                  isDisabled={!canUpdate}
                />
              </InlineEditContainer>
            ),
          },
          {
            content: shouldDisplayPrice && (
              <InlineEditContainer>
                <InlineEdit
                  name={`${item.id}:discountPercentage`}
                  defaultValue={`${item.discountPercentage}`}
                  label={''}
                  onConfirm={onConfirmSalesOrderItem}
                  postfix={'%'}
                  needFormatValue
                  hideActionButtons
                  isRequired
                  isNumeric
                  isDisabled={!canUpdate}
                />
              </InlineEditContainer>
            ),
          },
          {
            content: shouldDisplayPrice && (
              <InlineEditContainer className="tax-select">
                <SelectSingle
                  name={`${item.id}:taxRateId`}
                  defaultValue={createOption(taxRates.filter(type => type.id === item.taxRateId)[0])}
                  label={''}
                  selectOptions={createOptions(taxRates)}
                  onConfirm={onConfirmSalesOrderItem}
                  isDisabled={!canUpdate}
                />
              </InlineEditContainer>
            ),
          },
          {
            content: shouldDisplayPrice ? (
              <div className={'margin-amt'}>$ {numberRound(item.marginAmt || 0, 2)}</div>
            ) : null,
          },
          {
            content: shouldDisplayPrice ? (
              <div className={'total-price'}>$ {numberRound(item.totalPrice, 2)}</div>
            ) : null,
          },
          {
            content: isInvoice || !canCreateJob ? '' : getAddJobBtn(item, createCallback),
          },
          { content: isInvoice || !canDelete ? '' : getDeleteBtn(item.id, onDelete) },
        ],
      }));
  };

  // select a product to create an order item
  const onSelect = async (payload: iProduct | iContactCompanyProduct, isCCP = false) => {
    try {
      if (!payload) return;
      const productId = isCCP ? handleNullException(payload, 'productId') : handleNullException(payload, 'id');
      const created = await createSalesOrderItem(id, productId);
      await fetchSalesOrderData();
      setTimeout(() => document.getElementById(`${created.id}:qty`)?.click(), 200);
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  return (
    <>
      <TableWrapper>
        <FlexContainer className={'space-below flex-end'}>
          <div style={{ margin: '1rem 2rem 0 0' }}>
            <Tooltip content={shouldDisplayPrice ? 'Hide unit price' : 'Display unit price'} position={'left'}>
              <Toggle id="toggle-unit-price" onChange={() => setShouldDisplayPrice(prev => !prev)} defaultChecked />
            </Tooltip>
          </div>
          <SelectorWrapper>
            <SelectSingle
              name={id}
              defaultValue={createOption(localOrderDetail?.taxBase)}
              label={''}
              selectOptions={createOptions(taxBases)}
              onConfirm={onConfirmSelect}
              isDisabled={!canUpdate}
            />
          </SelectorWrapper>
        </FlexContainer>

        <DynamicTable
          head={getHeads(
            shouldDisplayPrice ? SALES_ORDER_ITEM_TABLE_COLUMNS : SALES_ORDER_ITEM_TABLE_COLUMNS_HIDE_PRICE,
            'soItems',
          )}
          rows={getRows()}
          testId={'sales-item-list-table'}
        />
      </TableWrapper>

      <FlexContainer className={'space-between flex-start'}>
        {isInvoice ? <div /> : <AddOrderItem onSelect={onSelect} order={order} isDisabled={!canCreate} />}
        <TotalPrice
          marginAmt={localOrderDetail?.marginAmt}
          subTotal={localOrderDetail?.subTotal}
          taxAmt={localOrderDetail?.taxAmt}
          totalAmt={localOrderDetail?.totalAmt}
        >
          {children}
        </TotalPrice>
      </FlexContainer>
    </>
  );
};

export default SalesOrderItem;
