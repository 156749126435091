import styled from 'styled-components';

const IntegrationCard = styled.div`
  border-radius: 3px;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 12px 24px -6px rgba(9, 30, 66, 0.25);
  height: 120px;
  width: 220px;
  overflow: hidden;
  display: flex;
  margin-right: 10px;
  padding: 15px;
  align-items: flex-start;
  flex-direction: column;
  margin: 0px 10px 10px 0px;
  background: RGBA(9, 30, 66, 0.08);
  .logo {
    width: 100px;
    background: white;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .title {
    color: RGB(107, 119, 140);
    font-size: 14px;
  }
  .subTitle {
    color: RGB(107, 119, 140);
    font-size: 9px;
  }
  .addIntegrationBtn {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    background: #efeff2 !important;
    align-items: center !important;
    color: RGB(66, 82, 110) !important;
    font-size: 35px !important;
  }
  .borderBox {
    display: border-box;
  }
  .cardBtn {
    border: none;
    width: 100%;
    height: 100%;
    text-align: left;
    cursor: pointer;
  }
`;
export default IntegrationCard;
