import { iConfigColumn } from '../../../shared/UITypes/types';

export const ENTITY_STATUS_LIST = 'Entity Statuses';
export const ENTITY_STATUS_COLUMNS: Array<iConfigColumn> = [
  {
    name: '',
    key: 'dragHandlerIcon',
    dataSource: [],
    type: 'icon',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
  {
    name: 'Name',
    key: 'name',
    dataSource: ['id', 'description'],
    isCompulsory: true,
    isSortable: true,
    type: 'link-two-layer',
    isSelected: true,
    group: '',
  },
  {
    name: 'Code',
    key: 'code',
    dataSource: ['code'],
    isCompulsory: true,
    isSortable: true,
    type: 'text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Status Category',
    key: 'entityStatusCategory',
    dataSource: ['entityStatusCategory.code'],
    type: 'status',
    isSelected: true,
    group: '',
  },
  {
    name: 'Sort Order',
    key: 'sortOrder',
    dataSource: ['sortOrder'],
    type: 'sortOrder',
    isSortable: true,
    isSelected: true,
    group: '',
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
    isSelected: true,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
    isSelected: true,
  }
];
