import React, { useState, useEffect } from 'react';
import { TabWrapper } from './CustomizeTab.style';
import TabItems from './TabItems';

export type iTab = {
  label: string;
  content: React.ReactNode;
  isHidden?: boolean;
};
const CustomizeTab = ({ components, givenTabName }: { components: Array<iTab>; givenTabName?: string }) => {
  const defaultSelected = components.length > 0 ? components[0].label : undefined;
  const [state, setState] = useState(defaultSelected);

  const onSelect = (label: string) => setState(label);
  const getTabContent = () => {
    const selectContent = components.find((comp: iTab) => comp.label === state);
    return selectContent ? selectContent.content : null;
  };
  useEffect(
    () => {
      if (!givenTabName) return;
      const givenTab = components.find((comp: iTab) => comp.label === givenTabName)?.label;
      if (givenTab) setState(givenTab);
    },
    // eslint-disable-next-line
    [givenTabName],
  );
  return (
    <TabWrapper data-testid={'customize-tab'}>
      <TabItems
        selected={state}
        items={components.filter(item => item.isHidden !== true).map((comp: iTab) => comp.label)}
        onSelect={onSelect}
      />
      {getTabContent()}
    </TabWrapper>
  );
};

export default CustomizeTab;
