import React, { useEffect } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iContactCompanyProduct from '../../types/contactCompany/iContactCompanyProduct';
import { useProductCustomerContext } from './ProductCustomerContext';
import { getRows } from './TableHelper';
import { apiErrorToast } from '../toast/Toast';
import { iConfigColumn } from '../UITypes/types';
import { getSalesOrderItems } from '../../services/SalesOrderItemService';
import { handleNullException, getHeads } from '../../services/UtilsService';

const CCPList = ({
  list,
  onOpenDeleteModal,
  onOpenEditModal,
  isLoading,
  columns,
  fixedProduct,
  isCustomer,
}: {
  list: Array<iContactCompanyProduct>;
  onOpenDeleteModal: (id: string) => void;
  onOpenEditModal: (id: string) => void;
  isLoading: boolean;
  columns: Array<iConfigColumn>;
  fixedProduct: boolean;
  isCustomer: boolean;
}) => {
  const { onUpdateLastSO } = useProductCustomerContext();

  // factor to load SalesOrderItems
  const contactCompanyIds = list.map((item: iContactCompanyProduct) => item.contactCompanyId).join('|');
  const productIds = list.map((item: iContactCompanyProduct) => item.productId).join('|');

  useEffect(
    () => {
      let isCancelled = false;
      const fetchData = async () => {
        // supplier side does not need to fetch SalesOrderItem
        if (!isCustomer) return;
        try {
          const effects = await Promise.all(
            list.map(async (item: iContactCompanyProduct) => {
              return getSalesOrderItems({
                sort: 'createdAt:DESC',
                filter: `salesOrder.customerId:${item.contactCompanyId},productId:${item.productId}`,
              });
            }),
          );

          if (isCancelled) return;
          //    eslint-disable-next-line
          effects.forEach((soItems: any, index: number) => {
            if (typeof onUpdateLastSO !== 'undefined') {
              const items = Array.isArray(soItems) ? soItems : handleNullException(soItems, 'data');

              const key = fixedProduct ? list[index].contactCompanyId : list[index].productId;

              onUpdateLastSO(key, items.length > 0 ? items[0] : undefined);
            }
          });
        } catch (e) {
          if (isCancelled) return;
          apiErrorToast(e);
        }
      };
      fetchData();
      return () => {
        isCancelled = true;
      };
    },
    //    eslint-disable-next-line
    [contactCompanyIds, productIds, isCustomer],
  );
  return (
    <DynamicTable
      head={getHeads(columns, 'contCompProd-supplier')}
      rows={getRows({
        data: list,
        onOpenDeleteModal,
        onOpenEditModal,
        columns,
        fixedProduct,
        isCustomer,
      })}
      testId={'contCompProd-supplier'}
      isLoading={isLoading}
    />
  );
};

export default CCPList;
