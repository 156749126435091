import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import styled from 'styled-components';

import iPurchaseOrderItem from '../../../../../../types/purchases/iPurchaseOrderItem';
import { getHeads, getRows } from './PoTableHelper';
import iEntityCategory from '../../../../../../types/status/iEntityCategory';

const TableWrapper = styled.div`
  margin: 1rem auto 3rem;
`;

const ProdPurchasePoTable = ({
  poItems,
  isLoading,
  columns,
  categories,
}: {
  poItems: Array<iPurchaseOrderItem>;
  isLoading: boolean;
  columns: Array<string>;
  categories: Array<iEntityCategory>;
}) => {
  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(columns, 'product-purchase-po')}
        rows={getRows(poItems, columns, categories)}
        testId={'product-purchase-po'}
        isLoading={isLoading}
      />
    </TableWrapper>
  );
};

export default ProdPurchasePoTable;
