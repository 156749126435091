import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../../shared/constants/AsyncConstants';
import { iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

export const getUsers = (config?: { [key: string]: string }) => {
  return AppService.get('/user', {
    include: 'createdBy,updatedBy,defaultOperatingMachine,defaultFacility',
    ...config,
  }).then(({ data }) => data);
};

export const deactivateUser = (id: string) => {
  return AppService.delete(`/user/${id}`).then(({ data }) => data);
};
//  eslint-disable-next-line
export const createUser = (postData: any) => {
  return AppService.post('/user', postData).then(({ data }) => data);
};

export const updateUser = (id: string, updateContent: { [key: string]: string }) => {
  return AppService.put(`/user/${id}`, updateContent).then(({ data }) => data);
};

export const getUserListAsyncSearch = (config?: iParams) => {
  return AppService.get('/user', {
    ...config,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

export const checkDuplicateUser = (targetValue: string, excludeId = '') => {
  return AppService.get('/user/isIdDuplicate', {
    targetValue,
    excludeId,
  }).then(({ data }) => data);
};

export const validateUserPassword = (password: string) => {
  return AppService.get('/user/validatePassword', { password }).then(({ data }) => data);
};

export default {
  getUsers,
  deactivateUser,
  createUser,
  updateUser,
  getUserListAsyncSearch,
  checkDuplicateUser,
  validateUserPassword,
};
