export const isPriceRelateField = (fieldName: string) => {
  const upperCase = fieldName.toUpperCase();
  if (
    upperCase.includes('PRICE') ||
    upperCase.includes('QTY') ||
    upperCase.includes('TAX')
  ) {
    return true;
  }
  return false;
};
