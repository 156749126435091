import React from 'react';
import {
  handleNullException,
  handleNullExceptionMultiFields,
  prepareConversionEquation,
} from '../../services/UtilsService';
import ConversionText from '../../shared/text/ConversionText';
import iContactCompanyProduct from '../../types/contactCompany/iContactCompanyProduct';
import iPurchaseOrderItem from '../../types/purchases/iPurchaseOrderItem';
import iReceivingItem from '../../types/purchases/iReceivingItem';
import iSalesOrderItem from '../../types/sales/iSalesOrderItem';
import MathHelper from '../../services/MathHelper';

export const getUnitPriceMeasure = (item: iContactCompanyProduct | iPurchaseOrderItem | iSalesOrderItem) => {
  return handleNullException(item, 'unitPriceMeasurementId')
    ? `${handleNullException(item, 'unitPriceMeasurement.shortName')}`
    : `${handleNullException(item, 'product.measurement.shortName')}`;
};

// qty in product measure
export const getQtyAfterConversion = (item: iPurchaseOrderItem, isQty: boolean) => {
  return isQty
    ? `${item.qty} ${
        item.measurement?.shortName || handleNullException(item, 'product.measurement.shortName')
      } = ${MathHelper.mul(Number(item.unitConversion), item.qty)} ${handleNullException(
        item,
        'product.measurement.shortName',
      )}`
    : `${item.receivedQty} ${
        item.measurement?.shortName || handleNullException(item, 'product.measurement.shortName')
      } = ${MathHelper.mul(Number(item.unitConversion), item.receivedQty)} ${handleNullException(
        item,
        'product.measurement.shortName',
      )}`;
};

export const displayQtyConversion = (item: iPurchaseOrderItem) => {
  const conversion = handleNullExceptionMultiFields(item, 'unitConversion') || 1;
  const { qty } = item;
  return (
    <ConversionText
      value={qty}
      conversion={conversion}
      contactCompanyUnit={handleNullExceptionMultiFields(item, 'product.measurement.shortName,measurement.shortName')}
      productUnit={handleNullException(item, 'product.measurement.shortName')}
    />
  );
};

export const displayQtyConversionAsString = (item: iPurchaseOrderItem) => {
  const conversion = handleNullExceptionMultiFields(item, 'unitConversion') || 1;
  const { qty } = item;
  const contactCompanyUnit = handleNullExceptionMultiFields(
    item,
    'product.measurement.shortName,measurement.shortName',
  );
  const productUnit = handleNullException(item, 'product.measurement.shortName');
  const postFix = prepareConversionEquation({
    value: qty,
    conversion,
    contactCompanyUnit,
    productUnit,
  });
  return `${qty} ${postFix}`;
};

// qty in po measure
export const getQtyBeforeConversion = (item: iReceivingItem) => {
  const conversion = handleNullExceptionMultiFields(item, 'purchaseOrderItem.unitConversion') || 1;
  const qty = MathHelper.div(item.qty, conversion);
  return (
    <ConversionText
      value={qty}
      conversion={conversion}
      contactCompanyUnit={handleNullExceptionMultiFields(
        item,
        'product.measurement.shortName,purchaseOrderItem.measurement.shortName',
      )}
      productUnit={handleNullException(item, 'product.measurement.shortName')}
    />
  );
};

// qty in po measure
export const getReceivedQtyBeforeConversion = (item: iPurchaseOrderItem) => {
  const conversion: number = item.unitConversion || 1;
  const beforeConversionQty = MathHelper.div(item.receivedQty, conversion);
  return (
    <ConversionText
      value={beforeConversionQty}
      conversion={conversion}
      contactCompanyUnit={handleNullExceptionMultiFields(item, 'product.measurement.shortName,measurement.shortName')}
      productUnit={handleNullException(item, 'product.measurement.shortName')}
    />
  );
};
