import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import iContactCompanyProduct from '../../../../types/contactCompany/iContactCompanyProduct';
import ConversionCalculateText from '../../../../shared/conversionCalculate/ConCalculateText';
import { apiErrorToast } from '../../../../shared/toast/Toast';
import { getContactCompanyProducts } from '../../../../services/ContactCompanyProductService';
import { AVOID_PAGINATION_PER_PAGE } from '../../../../shared/constants/AsyncConstants';
import {
  handleNullException,
  handleNullExceptionMultiFields,
} from '../../../../services/UtilsService';

type iState = {
  isLoading: boolean;
  ccpList: Array<iContactCompanyProduct>;
};
const initialState: iState = {
  isLoading: true,
  ccpList: [],
};
const QtyByCCP = ({
  qty,
  contactCompanyId,
  productId,
  productUnit,
  onEdit,
  isDisabled,
}: {
  qty: number | string;
  contactCompanyId?: string;
  productId: string;
  productUnit: string;
  onEdit: (value: string | number) => void;
  isDisabled: boolean;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await getContactCompanyProducts({
          filter: `contactCompanyId:${contactCompanyId},productId:${productId}`,
          currentPage: '1',
          perPage: AVOID_PAGINATION_PER_PAGE,
        });
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          ccpList: data,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [contactCompanyId, productId]);

  if (state.isLoading) return <Spinner />;
  return (
    <ConversionCalculateText
      name={'quantity'}
      defaultValue={qty}
      onConfirm={(name: string, value: string | number) => onEdit(value)}
      conversion={
        state.ccpList.length === 0
          ? 1
          : handleNullException(state.ccpList[0], 'unitConversion') || 1
      }
      productUnit={productUnit}
      contactCompanyUnit={
        state.ccpList.length === 0
          ? productUnit
          : handleNullExceptionMultiFields(
              state.ccpList[0],
              'product.measurement.shortName,measurement.shortName',
            )
      }
      isDisabled={isDisabled}
    />
  );
};

export default QtyByCCP;
