import React from 'react';

import { useSelector } from 'react-redux';
import CommentList from '../../../shared/comments/CommentList';
import LogList from '../../../shared/log/LogList';
import CustomizeTab from '../../../shared/tab/CustomizeTab';
import PoReceiveList from './poReceives/PoReceiveList';
import ReceivedProductList from './receivedProducts/ReceivedProductList';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCTS, ACCESS_CODE_STOCK_RECEIVING } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';

const POTabs = ({ poId }: { poId: string }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const tabs = [
    {
      label: 'PO Receives',
      content: <PoReceiveList poId={poId} />,
      isHidden: !AccessService.hasAccess(ACCESS_CODE_STOCK_RECEIVING, ACCESS_CAN_READ, user),
    },
    {
      label: "Recv'd Products",
      content: <ReceivedProductList poId={poId} />,
      isHidden: !AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user),
    },
    {
      label: 'Comments',
      content: <CommentList modelName={'PurchaseOrder'} modelId={poId} />,
    },
    {
      label: 'History',
      content: <LogList modelName={'PurchaseOrder'} modelId={poId} />,
    },
  ].filter(col => col.isHidden !== true);
  return <CustomizeTab components={tabs} />;
};

export default POTabs;
