import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import CheckboxWithController from '../../shared/form/hookForms/CheckboxWithController';
import InputWithController from '../../shared/form/hookForms/InputWithController';
import iProductType from '../../types/product/iProductType';
import SelectWithController from '../../shared/form/hookForms/SelectWithController';
import iUnitOfMeasurements from '../../types/iUnitOfMeasurement';
import iProductAttributeSet from '../../types/product/iProductAttributeSet';
import { SpaceVerticalContainer } from '../../shared/styles/styles';

const ProductTypeEditModal = ({
  control,
  errors,
  onChange,
  target,
  isFor,
  units,
  attributeSets,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iProductType;
  isFor?: {
    name?: string;
    value?: boolean;
  };
  units: Array<iUnitOfMeasurements>;
  attributeSets: Array<iProductAttributeSet>;
}) => {
  // eslint-disable-next-line no-nested-ternary
  return target && isFor && isFor.name ? (
    <CheckboxWithController
      label={isFor.name}
      name={isFor.name}
      defaultValue={!isFor.value}
      control={control}
      onChange={onChange}
      errors={errors}
      isRequired
      testId={`productType-editFlag-modal-${isFor.name}`}
    />
  ) : (
    <>
      <Page>
        <Grid layout={'fluid'}>
          <GridColumn medium={4}>
            <InputWithController
              name={'name'}
              label={'Product Type Name'}
              defaultValue={target?.name}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              testId={'productType-modal-name'}
            />
          </GridColumn>
          <GridColumn medium={8}>
            <InputWithController
              name={'description'}
              label={'Description'}
              defaultValue={target?.description}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired={false}
              testId={'productType-modal-description'}
            />
          </GridColumn>
        </Grid>
        <Grid layout={'fluid'}>
          <GridColumn medium={3}>
            <CheckboxWithController
              label={'Default Sell Flag'}
              name={'isForSell'}
              defaultValue={target?.isForSell}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              testId={'productType-modal-isForSell'}
            />
          </GridColumn>
          <GridColumn medium={3}>
            <CheckboxWithController
              label={'Default Purchase Flag'}
              name={'isForPurchase'}
              defaultValue={target?.isForPurchase}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              testId={'productType-modal-isForPurchase'}
            />
          </GridColumn>
          <GridColumn medium={3}>
            <CheckboxWithController
              label={'Default Make Flag'}
              name={'isForMake'}
              defaultValue={target?.isForMake}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              testId={'productType-modal-isForMake'}
            />
          </GridColumn>
          <GridColumn medium={3}>
            <CheckboxWithController
              label={'Default Waste Flag'}
              name={'isForWaste'}
              defaultValue={target?.isForWaste}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              testId={'productType-modal-isForWaste'}
            />
          </GridColumn>
        </Grid>
        <Grid layout={'fluid'}>
          <GridColumn medium={6}>
            <SelectWithController
              name={'measurementId'}
              label={'Default Units of Measure'}
              defaultValue={target?.measurementId}
              control={control}
              onChange={onChange}
              options={units?.map((unit: iUnitOfMeasurements) => ({
                label: unit.shortName,
                value: unit.id,
              }))}
              testId={'productType-modal-unit'}
              errors={errors}
              isRequired
            />
          </GridColumn>
          <GridColumn medium={6}>
            <SelectWithController
              name={'attributeSetId'}
              label={'Default Attribute Set'}
              defaultValue={target?.attributeSetId}
              control={control}
              onChange={onChange}
              options={attributeSets.map((item: iProductAttributeSet) => ({
                label: item.name,
                value: item.id,
              }))}
              testId={'productType-modal-attribute'}
              errors={errors}
              isRequired
            />
          </GridColumn>
        </Grid>
        <SpaceVerticalContainer className={'ten'} />
      </Page>
    </>
  );
};

export default ProductTypeEditModal;
