import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import DynamicTable from '@atlaskit/dynamic-table';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import iStockTakeItem from '../../../../types/stockTake/iStockTakeItem';
import DeleteByRetype from '../../../../shared/deleteWithReType/DeleteByReType';
import FoundItemOperation from './FoundItemOperation';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import FoundItemModalBody from './FoundItemModalBody';
import iStockTake from '../../../../types/stockTake/iStockTake';
import iProduct from '../../../../types/product/iProduct';
import { iConfigColumn, iParams } from '../../../../shared/UITypes/types';
import { ItemsTitle } from '../../shared/StockTake.style';
import { getRows } from './TableHelper';
import { getHeads, handleNullException } from '../../../../services/UtilsService';
import { FOUND_ITEMS_TITLE, FOUND_ITEM_TABLE_COLUMNS } from '../../shared/StockTake.constant';
import {
  createStockTakeItem,
  deactivateStockTakeItem,
  getStockTakeItemListByStockTakeId,
  updateStockTakeItem,
} from '../../../../services/StockTakeItemService';
import { iCategoryClassify } from '../../shared/StockTake.type';

type iState = {
  selectedProduct?: iProduct;
  isLoading: boolean;
};

const initialSupState: iState = {
  isLoading: false,
};

const FoundItemsInBin = ({
  stockTakeDetail,
  locationCode,
  categoryClassify,
  children,
  canCreate = true,
  canDelete = true,
  canUpdate = true,
}: {
  stockTakeDetail: iStockTake;
  locationCode: string;
  categoryClassify: iCategoryClassify;
  children: React.ReactNode;
  canCreate?: boolean;
  canDelete?: boolean;
  canUpdate?: boolean;
}) => {
  const { isWIP } = categoryClassify;
  const { id: stockTakeId } = stockTakeDetail;
  const [supState, setSupState] = useState(initialSupState);
  const getFnCallback = useCallback((config?: iParams) => getStockTakeItemListByStockTakeId(stockTakeId, config), [
    stockTakeId,
  ]);
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onOpenDeleteModal,
    onSubmit,
  } = useListCrudHook<iStockTakeItem>({
    getFn: getFnCallback,
    updateFn: updateStockTakeItem,
    deleteFn: deactivateStockTakeItem,
    createFn: createStockTakeItem,
    paginationApplied: true,
    sort: 'createdAt:DESC',
    filter: 'isUserInput:true',
  });
  const { control, setValue, errors, handleSubmit } = useForm();
  const onSelect = (selectedProduct: iProduct | null) => {
    if (!selectedProduct) {
      setSupState({ ...supState, selectedProduct: undefined });
      return;
    }
    setSupState({ ...supState, selectedProduct });
    onOpenAddModal();
  };
  //  eslint-disable-next-line
  const onCreate = (data: any) => {
    if (!supState.selectedProduct) return;
    onSubmit({
      ...data,
      productId: supState.selectedProduct.id,
      stockTakeId,
      isUserInput: true,
      qtyFrom: 0,
    });
  };
  const onCloseModalMiddle = () => {
    setSupState({ ...supState, selectedProduct: undefined });
    onCloseModal();
  };

  const getHeading = () => {
    if (edit.target) {
      return `Update for ${edit.target.product?.productCode}/${edit.target.product?.name}`;
    }
    if (supState.selectedProduct) {
      return `Find ${supState.selectedProduct.productCode}/${supState.selectedProduct.name} here`;
    }
    return 'Error';
  };

  const getFilteredColumns = (cols: iConfigColumn[]) => {
    return cols.filter(col => {
      return !(col.key === 'delete' && !canDelete);
    });
  };

  return (
    <>
      <ItemsTitle>{`${FOUND_ITEMS_TITLE}${locationCode}`}</ItemsTitle>
      <DynamicTable
        head={getHeads(getFilteredColumns(FOUND_ITEM_TABLE_COLUMNS), FOUND_ITEMS_TITLE)}
        rows={getRows(
          state.data,
          getFilteredColumns(FOUND_ITEM_TABLE_COLUMNS).map(col => {
            if (col.key === 'product.name' && !canUpdate) {
              return {
                ...col,
                type: 'text-secondary',
              };
            }
            return col;
          }),
          onOpenEditModal,
          onOpenDeleteModal,
          isWIP,
        )}
      />
      {isWIP && (
        <FoundItemOperation onSelect={onSelect} isDisabled={!canCreate}>
          {children}
        </FoundItemOperation>
      )}

      <CustomizeModal
        isOpen={edit.isModalOpen && !edit.delTargetId}
        onConfirm={!edit.target ? handleSubmit(onCreate) : handleSubmit(onSubmit)}
        onCancel={onCloseModalMiddle}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <FoundItemModalBody control={control} onChange={setValue} errors={errors} targetStockTakeItem={edit.target} />
        }
        modalHeading={getHeading()}
      />
      {edit.delTargetId && (
        <DeleteByRetype
          isOpen={edit.isModalOpen}
          onClose={onCloseModal}
          onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
          answer={handleNullException(edit, 'target.product.productCode')}
          isConfirming={state.isConfirming}
        />
      )}
    </>
  );
};

export default FoundItemsInBin;
