import React from 'react';
import Button from '@atlaskit/button';

const Advanced = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button appearance={'link'} onClick={onClick}>
      Advanced
    </Button>
  );
};

export default Advanced;
