/* eslint-disable  */
import React, { useState } from 'react';
import Button from '@atlaskit/button';
import StepDownload from '../step1/StepDownload';
import StepRead from '../step2/StepRead';
import { SpaceVerticalContainer } from '../../../../../shared/styles/styles';
import { iKeyValuePairs } from '../../../../../shared/UITypes/types';
import ProductSubmit from './ProductSubmit';
import { iPrefills, iTemplateColumn } from '../../shared/DataImport.types';

type iState = {
  isLoading: boolean;
  data: any[][];
  isReset: boolean;
};
const initialState: iState = {
  isLoading: false,
  data: [],
  isReset: false,
};
const ProductImportSteps = ({
  detailPageName,
  prefills,
  columns,
  example,
  children,
}: {
  detailPageName: string;
  prefills: iPrefills;
  columns: Array<iTemplateColumn>;
  example: Array<string>;
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(initialState);

  // if need to clean the imported file, use handleDataRead like GeneralImportSteps
  const handleDataRead = async (result: any[][]) =>
    setState({ ...state, data: result, isReset: false });

  return (
    <>
      <StepDownload
        detailPageName={detailPageName}
        columns={columns}
        example={example}
      />
      <SpaceVerticalContainer className={'half'} />
      <StepRead
        handleDataRead={handleDataRead}
        isReset={state.isReset}
        columns={columns}
      />
      <SpaceVerticalContainer className={'half'} />
      {state.data.length > 0 && (
        <ProductSubmit
          data={state.data}
          detailPageName={detailPageName}
          prefills={prefills}
          columns={columns}
        >
          {children}
        </ProductSubmit>
      )}
    </>
  );
};

export default ProductImportSteps;
