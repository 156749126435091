/* eslint-disable import/no-named-as-default-member */
import Spinner from '@atlaskit/spinner';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import iContact from '../../../types/contactCompany/iContact';
import iContactCompany from '../../../types/contactCompany/iContactCompany';
import BackendPage from '../../../layout/BackendPage';
import Jobs from '../../job/jobs/Jobs';
import LogList from '../../log/LogList';
import CustomizeTab, { iTab } from '../../tab/CustomizeTab';
import CCPProdContComp from '../../CCPProductContactCompany/CCPProdContComp';
import CommentList from '../../comments/CommentList';
import DeleteByRetype from '../../deleteWithReType/DeleteByReType';
import ContactBC from './contact/Contact';
import CustomerDetailEdit from './customer/CustomerDetailEdit';
import SalesOrdersInCustomer from './customer/SalesOrdersInCustomer';
import DetailHeading from './DetailHeading';
import DetailNav from './DetailNav';
import PurchaseOrdersInSupplier from './supplier/PurchaseOrdersInSupplier';
import SupplierDetailEdit from './supplier/SupplierDetailEdit';
import { apiErrorToast } from '../../toast/Toast';
import ContactService from '../../../services/ContactService';
import { hasKey } from '../../../services/UtilsService';
import { CUSTOMER, SUPPLIER } from '../constants';
import ContactCompanyService from '../../../services/ContactCompanyService';
import {
  COLUMN_NAME_IN_STORAGE,
  DEFAULT_COLUMNS,
  FIXED_COLUMNS,
  MUST_COLUMNS_AFTER,
  MUST_COLUMNS_BEFORE,
} from '../../job/constants';
import { ADDRESS, CONTACTS, JOBS, PERSONNEL, PRODUCTS, PURCHASE_ORDERS, SALES_ORDERS } from './constants';
import CCPServiceProvider from '../../CCPProductContactCompany/CCPServiceProvider/CCPServiceProvider';
import DNInServiceProvider from './serviceProvider/DNInServiceProvider';
import ContactCompanyContextProvider from './context/ContactCompanyContext';
import CompanyAddress from './address/CompanyAddress';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { RootState } from '../../../redux/makeReduxStore';
import {
  ACCESS_CODE_CUSTOMERS,
  ACCESS_CODE_DISPATCH_NOTES,
  ACCESS_CODE_INVOICES,
  ACCESS_CODE_JOBS,
  ACCESS_CODE_PRODUCTS,
  ACCESS_CODE_PURCHASE_ORDERS,
  ACCESS_CODE_QUOTES,
  ACCESS_CODE_SALES_ORDERS,
  ACCESS_CODE_SERVICE_PROVIDERS,
  ACCESS_CODE_SUPPLIERS,
} from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_DELETE, ACCESS_CAN_READ, ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';
import { SERVICE_PROVIDER } from '../../../pages/contactCompany/list/ContactCompany.constants';

type iContactCompanyDetail = {
  id: string;
  type: string;
  urlPrefix: string;
  upperPageName: string;
};
const ContactCompanyDetail = ({ id, type, urlPrefix, upperPageName }: iContactCompanyDetail) => {
  const history = useHistory();
  const { user } = useSelector((s: RootState) => s.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [contactCompany, setContactCompany] = useState<iContactCompany>();
  const [contacts, setContacts] = useState<Array<iContact>>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  //  eslint-disable-next-line
  const updateLocalContactCompany = (columnName: string, newValue: any) => {
    if (!contactCompany) return;
    if (hasKey(contactCompany, columnName)) {
      setContactCompany({
        ...contactCompany,
        [columnName]: newValue,
      });
    }
  };
  const addContact = (add: iContact) => {
    setContacts([add, ...contacts]);
  };
  const removeContact = (remove: iContact) => {
    setContacts(contacts.filter((contact: iContact) => contact.id !== remove.id));
  };
  const editContact = (edit: iContact) => {
    setContacts(contacts.map((contact: iContact) => (contact.id === edit.id ? edit : contact)));
  };

  //  eslint-disable-next-line
  const onOpenDeleteModal = (deleteId: string) => {
    setIsDeleteModalOpen(true);
  };
  const onDelete = async () => {
    if (!contactCompany) return;
    try {
      //  eslint-disable-next-line
      await ContactCompanyService.removeContactCompany(contactCompany.id);
      history.push(urlPrefix);
    } catch (error) {
      setIsDeleteModalOpen(false);
      apiErrorToast(error);
    }
  };
  useEffect(() => {
    let isCancelled = false;
    const loadCustomer = async () => {
      setIsLoading(true);
      try {
        //  eslint-disable-next-line
        const contactCompanyDetail = await ContactCompanyService.getContactCompany(id);
        //  eslint-disable-next-line
        const contactCompanyAllContacts = await ContactService.getContactList({
          filter: `contactCompanyId:${id}`,
        });
        if (isCancelled) return;
        setContactCompany(contactCompanyDetail);
        setContacts(contactCompanyAllContacts);
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadCustomer();
    return () => {
      isCancelled = true;
    };
  }, [id]);

  const commonTabs = [
    {
      label: 'Comments',
      content: <CommentList modelName={'ContactCompany'} modelId={id} />,
    },
    {
      label: 'History',
      content: <LogList modelName={'ContactCompany'} modelId={id} />,
    },
  ];
  const supplierTabs: iTab[] = [
    {
      label: PERSONNEL,
      content: (
        <ContactBC
          companyId={contactCompany?.id}
          contacts={contacts}
          handleAdd={addContact}
          handleRemove={removeContact}
          handleEdit={editContact}
          isCustomer={false}
          isDisabled={!AccessService.hasAccess(ACCESS_CODE_SUPPLIERS, ACCESS_CAN_UPDATE, user)}
        />
      ),
    },
    {
      label: ADDRESS,
      content: (
        <CompanyAddress
          companyId={contactCompany?.id}
          isDisabled={!AccessService.hasAccess(ACCESS_CODE_SUPPLIERS, ACCESS_CAN_UPDATE, user)}
        />
      ),
    },
    {
      label: PURCHASE_ORDERS,
      content: <PurchaseOrdersInSupplier id={id} />,
      isHidden: !AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_READ, user),
    },
    {
      label: PRODUCTS,
      content: (
        <CCPProdContComp
          startFieldId={id}
          isCustomer={false}
          canCreate={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
          canUpdate={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
          canDelete={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_DELETE, user)}
        />
      ),
      isHidden: !AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user),
    },
    ...commonTabs,
  ];
  const customerTabs: iTab[] = [
    {
      label: CONTACTS,
      content: (
        <ContactBC
          companyId={contactCompany?.id}
          contacts={contacts}
          handleAdd={addContact}
          handleRemove={removeContact}
          handleEdit={editContact}
          isCustomer
          isDisabled={!AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_UPDATE, user)}
        />
      ),
    },
    {
      label: ADDRESS,
      content: (
        <CompanyAddress
          companyId={contactCompany?.id}
          isDisabled={!AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_UPDATE, user)}
        />
      ),
    },
    {
      label: SALES_ORDERS,
      content: <SalesOrdersInCustomer id={id} />,
      isHidden:
        !AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_READ, user) &&
        !AccessService.hasAccess(ACCESS_CODE_QUOTES, ACCESS_CAN_READ, user) &&
        !AccessService.hasAccess(ACCESS_CODE_INVOICES, ACCESS_CAN_READ, user),
    },
    {
      label: PRODUCTS,
      content: (
        <CCPProdContComp
          startFieldId={id}
          isCustomer
          canCreate={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
          canUpdate={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
          canDelete={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_DELETE, user)}
        />
      ),
      isHidden: !AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user),
    },
    {
      label: JOBS,
      content: (
        <Jobs
          isIndependent
          config={{
            filter: `SalesOrder_salesOrderId.customerId:${id}`,
            sort: 'updatedAt:DESC',
          }}
          columns={{
            name: COLUMN_NAME_IN_STORAGE,
            default: DEFAULT_COLUMNS,
            mustBefore: MUST_COLUMNS_BEFORE,
            mustAfter: MUST_COLUMNS_AFTER,
            positionFixed: FIXED_COLUMNS,
          }}
          canDelete={AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_DELETE, user)}
        />
      ),
      isHidden: !AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_READ, user),
    },
    ...commonTabs,
  ];

  const serviceProviderTabs: iTab[] = [
    {
      label: CONTACTS,
      content: (
        <ContactBC
          companyId={contactCompany?.id}
          contacts={contacts}
          handleAdd={addContact}
          handleRemove={removeContact}
          handleEdit={editContact}
          isCustomer
          isDisabled={!AccessService.hasAccess(ACCESS_CODE_SERVICE_PROVIDERS, ACCESS_CAN_UPDATE, user)}
        />
      ),
    },
    {
      label: ADDRESS,
      content: (
        <CompanyAddress
          companyId={contactCompany?.id}
          isDisabled={!AccessService.hasAccess(ACCESS_CODE_SERVICE_PROVIDERS, ACCESS_CAN_UPDATE, user)}
        />
      ),
    },
    {
      label: PURCHASE_ORDERS,
      content: <PurchaseOrdersInSupplier id={id} />,
      isHidden: !AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_READ, user),
    },
    {
      label: PRODUCTS,
      content: (
        <CCPServiceProvider
          startFieldId={id}
          canCreate={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
          canUpdate={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
          canDelete={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_DELETE, user)}
        />
      ),
      isHidden: !AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user),
    },
    {
      label: 'Dispatch Notes',
      content: <DNInServiceProvider id={id} />,
      isHidden: !AccessService.hasAccess(ACCESS_CODE_DISPATCH_NOTES, ACCESS_CAN_READ, user),
    },
    {
      label: JOBS,
      content: (
        <Jobs
          isIndependent
          config={{
            filter: `SalesOrder_salesOrderId.customerId:${id}`,
            sort: 'updatedAt:DESC',
          }}
          columns={{
            name: COLUMN_NAME_IN_STORAGE,
            default: DEFAULT_COLUMNS,
            mustBefore: MUST_COLUMNS_BEFORE,
            mustAfter: MUST_COLUMNS_AFTER,
            positionFixed: FIXED_COLUMNS,
          }}
          canDelete={AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_DELETE, user)}
        />
      ),
      isHidden: !AccessService.hasAccess(ACCESS_CODE_JOBS, ACCESS_CAN_READ, user),
    },
    ...commonTabs,
  ];

  const getOnOpenDeleteModal = () => {
    switch (type) {
      case SUPPLIER: {
        return AccessService.hasAccess(ACCESS_CODE_SUPPLIERS, ACCESS_CAN_DELETE, user) ? onOpenDeleteModal : undefined;
      }
      case CUSTOMER: {
        return AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_DELETE, user) ? onOpenDeleteModal : undefined;
      }
      case SERVICE_PROVIDER: {
        return AccessService.hasAccess(ACCESS_CODE_SERVICE_PROVIDERS, ACCESS_CAN_DELETE, user)
          ? onOpenDeleteModal
          : undefined;
      }
      default: {
        return onOpenDeleteModal;
      }
    }
  };
  const getPageHeader = () => (
    <>
      <DetailNav
        contactCompanyName={contactCompany && contactCompany.name}
        contactCompanyType={upperPageName}
        urlPrefix={urlPrefix}
      />
      <DetailHeading
        name={contactCompany ? contactCompany.name : ''}
        id={contactCompany ? contactCompany.id : ''}
        onOpenDeleteModal={getOnOpenDeleteModal()}
      />
    </>
  );
  if (isLoading) return <Spinner />;
  if (!contactCompany) return null;
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <ContactCompanyContextProvider contactCompanyId={id}>
        {type === SUPPLIER ? (
          <SupplierDetailEdit
            contactCompany={contactCompany}
            updateLocalContactCompany={updateLocalContactCompany}
            contacts={contacts}
            isDisabled={!AccessService.hasAccess(ACCESS_CODE_SUPPLIERS, ACCESS_CAN_UPDATE, user)}
          />
        ) : (
          <CustomerDetailEdit
            contactCompany={contactCompany}
            updateLocalContactCompany={updateLocalContactCompany}
            contacts={contacts}
            isDisabled={!AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_UPDATE, user)}
          />
        )}
        <CustomizeTab
          components={
            //  eslint-disable-next-line
            type === CUSTOMER ? customerTabs : type === SUPPLIER ? supplierTabs : serviceProviderTabs
          }
        />
      </ContactCompanyContextProvider>
      {isDeleteModalOpen && (
        <DeleteByRetype
          isOpen={isDeleteModalOpen}
          onConfirm={onDelete}
          onClose={() => setIsDeleteModalOpen(false)}
          answer={contactCompany.name}
        />
      )}
    </BackendPage>
  );
};

export default ContactCompanyDetail;
