import { iKeyValuePairs, iParams } from '../shared/UITypes/types';

import AppService from './AppService';

export const getSalesOrderItems = (config?: iParams) => {
  return AppService.get('/salesOrderItem', {
    include:
      'salesOrder,salesOrder.createdBy,salesOrder.updatedBy,salesOrder.shippingAddress,salesOrder.status,salesOrder.type,salesOrder.customer,product.measurement,measurement,unitPriceMeasurement',
    ...config,
  }).then(({ data }) => data);
};

export const updateSalesOrderItem = (
  id: string,
  updatedContent: { [key: string]: string | number },
) => {
  return AppService.put(`/salesOrderItem/${id}`, updatedContent).then(
    ({ data }) => data,
  );
};

export const createSalesOrderItem = (
  orderId: string,
  productId: string,
  qty = 0,
) => {
  return AppService.post('/salesOrderItem', {
    orderId,
    productId,
    qty,
  }).then(({ data }) => data);
};

export const deleteSalesOrderItem = (id: string) => {
  return AppService.delete(`/salesOrderItem/${id}`).then(({ data }) => data);
};

export const createSOItemConfig = (postData: iKeyValuePairs) => {
  return AppService.post('/salesOrderItem', postData).then(({ data }) => data);
};

export const getSalesOrderItem = (id: string, config?: iParams) => {
  return AppService.get(`/salesOrderItem/${id}`, config).then(
    ({ data }) => data,
  );
};

export default {
  getSalesOrderItems,
  updateSalesOrderItem,
  createSalesOrderItem,
  deleteSalesOrderItem,
  createSOItemConfig,
  getSalesOrderItem,
};
