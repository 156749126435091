import React from 'react';
import BreadcrumbInHeader from '../../../../components/settings/BreadcrumbInHeader';
import BackendPage from '../../../../layout/BackendPage';
import Sidebar from '../../sidebar/Sidebar';
import DataImportList from './DataImportList';
import { LIST_COLUMNS, LIST_DATA } from '../shared/DataImport.constants';

const DataImport = () => {
  return (
    <BackendPage
      pageHeader={<BreadcrumbInHeader subName={'Data Imports'} />}
      leftMenu={<Sidebar />}
    >
      <DataImportList data={LIST_DATA} columns={LIST_COLUMNS} />
    </BackendPage>
  );
};

export default DataImport;
