import React, { useState, useEffect } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import Spinner from '@atlaskit/spinner';
import iFaultCategory from '../../../../types/NCR/iFaultCategory';
import InputWithController from '../../../../shared/form/hookForms/InputWithController';
import SelectWithController from '../../../../shared/form/hookForms/SelectWithController';
import { apiErrorToast } from '../../../../shared/toast/Toast';
import { getFaultCategories } from '../../../../services/FaultCategoryService';
import { SpaceVerticalContainer } from '../../../../shared/styles/styles';

type iState = {
  isLoading: boolean;
  faultCategories: Array<iFaultCategory>;
};
const initialState: iState = {
  isLoading: true,
  faultCategories: [],
};
const CreateBody = ({
  control,
  errors,
  onChange,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const faultCategories = await getFaultCategories();
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          faultCategories,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);
  if (state.isLoading) return <Spinner />;
  return (
    <>
      <SelectWithController
        label={'Fault Type'}
        name={'faultCategoryId'}
        onChange={onChange}
        control={control}
        options={state.faultCategories.map((faultCategory: iFaultCategory) => ({
          label: faultCategory.name,
          value: faultCategory.id,
        }))}
        testId={'faultCategoryId'}
        errors={errors}
      />
      <InputWithController
        label={'Comments'}
        name={'comments'}
        onChange={onChange}
        control={control}
        testId={'comments'}
        isRequired
        errors={errors}
      />
      <SpaceVerticalContainer className={'two'} />
    </>
  );
};

export default CreateBody;
