import React from 'react';
import styled from 'styled-components';
import CommentList from '../comments/CommentList';
import LogList from '../log/LogList';
import CustomizeTab from '../tab/CustomizeTab';

const ActivitySectionContainer = styled.div`
  margin-top: 5rem;
`;

const Activities = ({
  modelId,
  modelName,
}: {
  modelId: string;
  modelName: string;
}) => {
  const tabs = [
    {
      label: 'Comments',
      content: <CommentList modelName={modelName} modelId={modelId} />,
    },
    {
      label: 'History',
      content: <LogList modelName={modelName} modelId={modelId} />,
    },
  ];

  return (
    <ActivitySectionContainer>
      <h3>Activities:</h3>
      <CustomizeTab components={tabs} />
    </ActivitySectionContainer>
  );
};

export default Activities;
