import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import iJob from '../../../types/job/iJob';
import iMachine from '../../../types/machine/iMachine';
import iJobCategory from '../../../types/job/iJobCategory';
import iJobStatus from '../../../types/job/iJobStatus';
import ProductionJob from './ProductionJob';
import { getJobListAsyncSearch } from '../../../services/JobService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { NEXT_JOB, RUNNING_JOB } from '../shared/Dashboard.constants';
import { STATUS_CATEGORY_IN_PROGRESS } from '../../../shared/job/constants';
import { SpaceVerticalContainer } from '../../../shared/styles/styles';

type iState = {
  isLoading: boolean;
  jobs: Array<iJob>;
};
const initialState: iState = {
  isLoading: true,
  jobs: [],
};
const ProductionMachine = ({
  machine,
  jobStatusCategories,
  statusFilterConfig,
}: {
  machine: iMachine;
  jobStatusCategories: Array<iJobCategory>;
  statusFilterConfig: string;
}) => {
  const { id: machineId, name: machineName } = machine;
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      try {
        const { data: jobs } = await getJobListAsyncSearch({
          filter: `machineId:${machineId},statusId:${statusFilterConfig},current:true`,
          sort: 'scheduledAt:ASC',
        });
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          jobs,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [machineId, statusFilterConfig]);

  const getRunningJob = () => {
    if (state.jobs.length === 0) {
      return { runningJobs: [], nextJobs: [] };
    }
    const [runningJob, ...others] = state.jobs;
    const category = jobStatusCategories.find((c: iJobCategory) =>
      c.jobStatuses.find(
        (status: iJobStatus) => status.id === runningJob.statusId,
      ),
    );
    if (category && category.name === STATUS_CATEGORY_IN_PROGRESS) {
      return { runningJobs: [runningJob], nextJobs: others };
    }
    return { runningJobs: [], nextJobs: state.jobs };
  };

  return (
    <div className={'machine-jobs'}>
      <div className={'machine-name'}>{machineName}</div>
      {state.isLoading && <Spinner />}
      {!state.isLoading && (
        <>
          <SpaceVerticalContainer className={'half'} />
          <ProductionJob
            jobs={getRunningJob().runningJobs}
            title={RUNNING_JOB}
          />
          <SpaceVerticalContainer className={'quarter'} />
          <ProductionJob jobs={getRunningJob().nextJobs} title={NEXT_JOB} />
        </>
      )}
    </div>
  );
};

export default ProductionMachine;
