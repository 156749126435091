import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

const endPoint = '/entityStatusType';
const createEntityStatusType = (postContent: iParams) => {
  return AppService.post(endPoint, postContent).then(({ data }) => data);
};

const updateEntityStatusType = (id: string, updateContent: iParams) => {
  return AppService.put(`${endPoint}/${id}`, updateContent).then(({ data }) => data);
};

const getEntityStatusTypes = (params = {}) => {
  return AppService.get(endPoint, {
    include: 'entityStatuses,startingStatus,createdBy,updatedBy',
    ...params,
  }).then(({ data }) => data);
};

const getEntityStatusTypeDetail = (id: string, params = {}) => {
  return AppService.get(`${endPoint}/${id}`, {
    include: 'entityStatuses,startingStatus',
    ...params,
  }).then(({ data }) => data);
};

export default {
  createEntityStatusType,
  updateEntityStatusType,
  getEntityStatusTypes,
  getEntityStatusTypeDetail,
};
