export const CONTACTS = 'Contacts';
export const SALES_ORDERS = 'Sales Orders';
export const CHANGE_HISTORY = 'Change History';
export const PERSONNEL = 'Personnel';
export const PURCHASE_ORDERS = 'Purchase Orders';
export const JOBS = 'Jobs';
export const PRODUCTS = 'Products';
export const ADDRESS = 'Address';

export const DELETE = 'Delete';
export const CLONE = 'Clone';
