import React, { useState } from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';

const SearchIconContainer = styled.span`
  display: flex;
  cursor: pointer;
`;
const SearchBar = ({
  onSearch,
  keyword = '',
  placeholder,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSearch: (keyword: any) => void;
  keyword?: string;
  placeholder?: string;
}) => {
  const [value, setValue] = useState(keyword);
  //  eslint-disable-next-line
  const onChange = (event: any) => {
    setValue(event.target.value);
  };
  //  eslint-disable-next-line
  const onEnterPress = (event: any) => {
    onSearch(event.target.value);
  };
  return (
    <Textfield
      elemAfterInput={
        <SearchIconContainer onClick={() => onSearch(value)}>
          <EditorSearchIcon label={'search'} />
        </SearchIconContainer>
      }
      value={value}
      onKeyPress={evt => evt.key === 'Enter' && onEnterPress(evt)}
      onChange={onChange}
      placeholder={placeholder || 'Search...'}
      isCompact
    />
  );
};
export default SearchBar;
