import React from 'react';
import Lozenge, { ThemeAppearance } from '@atlaskit/lozenge';
import iStatus from '../../types/status/iEntityStatus';
import {
  STATUS_CATEGORY_NEW,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_FINISHED,
} from '../constants/StatusCategories';
import { FlexContainer } from '../styles/styles';

const decideAppearance = (categoryName?: string): ThemeAppearance => {
  switch (categoryName) {
    case STATUS_CATEGORY_NEW:
      return 'default';
    case STATUS_CATEGORY_IN_PROGRESS:
      return 'inprogress';
    case STATUS_CATEGORY_FINISHED:
      return 'success';
    default:
      return 'default';
  }
};
const StatusLozenge = ({
  status,
  categoryCode,
  categoryName,
  children,
  isCategoryLozenge = false,
  testId,
}: {
  status?: iStatus;
  categoryCode?: string;
  categoryName?: string;
  children?: React.ReactChild;
  isCategoryLozenge?: boolean;
  testId?: string;
}) => {
  return (
    <Lozenge appearance={decideAppearance(categoryCode)}>
      <FlexContainer data-testid={testId}>
        {isCategoryLozenge ? <div>{categoryName}</div> : <div>{status?.name}</div>}
        {children}
      </FlexContainer>
    </Lozenge>
  );
};

export default StatusLozenge;
