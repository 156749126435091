import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const createFacility = (content: iParams) => {
  return AppService.post('/facility', content).then(({ data }) => data);
};

export const updateFacility = (id: string, updateContent: iParams) => {
  return AppService.put(`/facility/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const getFacilityDetail = (id: string) => {
  return AppService.get(`/facility/${id}`, {
    include:
      'defaultInwardsLocation,defaultOutwardsLocation,lostAndFoundLocation,address',
  }).then(({ data }) => data);
};

export const deactivateFacility = (id: string) => {
  return AppService.delete(`/facility/${id}`).then(({ data }) => data);
};

export const searchFacility = (config?: iParams) => {
  return AppService.get('/facility', config).then(({ data }) => data.data);
};

export default {
  getFacilityDetail,
  createFacility,
  updateFacility,
  deactivateFacility,
  searchFacility,
};
