import React from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import DateTimePickerWithController from '../../form/hookForms/DateTimePickerWithController';
import InputWithController from '../../form/hookForms/InputWithController';
import BasicModalBody from './BasicModalBody';

const CreateSOPOModalBody = ({
  control,
  errors,
  onChange,
  watch,
  isCustomer,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  watch: (names?: string | string[]) => unknown;
  isCustomer: boolean;
}) => {
  return (
    <BasicModalBody
      control={control}
      errors={errors}
      onChange={onChange}
      watch={watch}
      isCustomer={isCustomer}
      verticalSpaceBeforeSelect={'eight'}
    >
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <InputWithController
            label={'Supplier Reference number'}
            name={'supplierRef'}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired={false}
          />
        </GridColumn>
      </Grid>
      <Grid spacing={'compact'}>
        <GridColumn medium={12}>
          <DateTimePickerWithController
            label={'Order Date'}
            name={'orderDate'}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            isDateOnly
            isDefaultNow
          />
        </GridColumn>
      </Grid>
    </BasicModalBody>
  );
};

export default CreateSOPOModalBody;
