import React, { useState } from 'react';
import _ from 'lodash';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import AsyncSearchWithController from '../../../shared/form/hookForms/AsyncSearchWithController';
import DateTimePickerWithController from '../../../shared/form/hookForms/DateTimePickerWithController';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import { getWarehouseListAsyncSearch } from '../../../services/WarehouseService';
import iFacility from '../../../types/warehouse/iFacility';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import { getFacilityDetail } from '../../../services/FacilityService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { SpaceVerticalContainer } from '../../../shared/styles/styles';

type iState = {
  facility: iFacility | null;
};
const initialState: iState = {
  facility: null,
};

const CreateModalBody = ({
  control,
  errors,
  onChange,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
}) => {
  const [state, setState] = useState(initialState);
  const loadFacility = async (value: iWarehouseLocation) => {
    if (!value || !value.facilityId) {
      setState({ ...state, facility: null });
      return;
    }
    try {
      const facility = await getFacilityDetail(value.facilityId);
      setState({ ...state, facility });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, facility: null });
    }
  };
  return (
    <Page>
      <Grid spacing="compact">
        <GridColumn medium={12}>
          <AsyncSearchWithController
            name={'warehouseLocationId'}
            label={'For warehouse location'}
            //  eslint-disable-next-line
            onChange={(name: string, value: any, config?: Object) => {
              onChange(name, _.get(value, 'id'), config);
              loadFacility(value);
            }}
            errors={errors}
            control={control}
            optionLabel={['name', 'locationCode']}
            promiseFn={(keyword: string) =>
              getWarehouseListAsyncSearch({
                like: `name:${keyword},locationCode:${keyword}`,
              })
            }
            isRequired
            placeholder={'search by name/locationCode...'}
          />
        </GridColumn>
        <GridColumn medium={12}>
          <AsyncSearchWithController
            name={'lostAndFoundLocationId'}
            label={'Lost&Found location (auto-populate by selecting a For warehouse location)'}
            defaultValue={state.facility === null ? undefined : state.facility.lostAndFoundLocation}
            //  eslint-disable-next-line
            onChange={(name: string, value: any, config?: Object) =>
              onChange(name, _.get(value, 'id'), config)
            }
            errors={errors}
            control={control}
            optionLabel={['name', 'locationCode']}
            promiseFn={(keyword: string) =>
              getWarehouseListAsyncSearch({
                like: `name:${keyword},locationCode:${keyword}`,
              })
            }
            isRequired
            placeholder={'search by name/locationCode...'}
            isDisabled
          />
        </GridColumn>
        <GridColumn medium={6}>
          <DateTimePickerWithController
            name={'scheduledAt'}
            label={'Schedule At'}
            //  eslint-disable-next-line
            onChange={onChange}
            errors={errors}
            control={control}
            isRequired
          />
        </GridColumn>
        <GridColumn medium={12}>
          <InputWithController
            name={'memo'}
            label={'Notes'}
            //  eslint-disable-next-line
            onChange={onChange}
            errors={errors}
            control={control}
            isRequired={false}
          />
        </GridColumn>
      </Grid>
      <SpaceVerticalContainer className={'five'} />
    </Page>
  );
};

export default CreateModalBody;
