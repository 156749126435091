import React from 'react';
import moment from 'moment';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import iJob from '../../../../types/job/iJob';
import { DATE_SHIFT_CONNECTOR } from '../shared/Schedule.constant';
import { iLabelValuePair } from '../../../../shared/UITypes/types';
import { getListStyle } from '../shared/Schedule.utils';
//  eslint-disable-next-line
import { useJobSchContext } from '../../JobScheduleContext';
import { iDisabledShift } from '../shared/Schedule.type';
//  eslint-disable-next-line
import DraggableList from './DraggableList';
//  eslint-disable-next-line
import ShiftTitle from '../shared/ShiftTitle';

const ScheduleDroppable = ({
  date,
  shift,
  jobs,
  isDisabled,
}: {
  date: string;
  shift: iLabelValuePair;
  jobs: Array<iJob>;
  isDisabled?: boolean;
}) => {
  const { disabledShifts } = useJobSchContext();
  const isCurrentShiftDisabled = () => {
    return !!disabledShifts.find(
      (d: iDisabledShift) =>
        d.shiftId === shift.value && moment(d.date, 'YYYY-MM-DD').toString() === moment(date, 'DD/MM/YYYY').toString(),
    );
  };
  return (
    <Droppable
      key={date + DATE_SHIFT_CONNECTOR + shift.value}
      droppableId={date + DATE_SHIFT_CONNECTOR + shift.value}
      isDropDisabled={isCurrentShiftDisabled() || isDisabled}
    >
      {(providedDrop: DroppableProvided, snapshotDrop: DroppableStateSnapshot) => (
        <div
          ref={providedDrop.innerRef}
          style={getListStyle(snapshotDrop.isDraggingOver, isCurrentShiftDisabled())}
          //    eslint-disable-next-line
          {...providedDrop.droppableProps}
        >
          <ShiftTitle shift={shift} date={date} isDisabled={isDisabled} />
          <DraggableList jobs={jobs} shift={shift} date={date} isDragDisabled={isDisabled} />
          {providedDrop.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ScheduleDroppable;
