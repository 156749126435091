import AppService from '../../AppService';
import { iChannelJobEntity } from '../../../types/integration/iChannelJob';

const getProducts = (channelId: string, params = {}) => {
  return AppService.get(`/channel/${channelId}/product`, params).then(({ data }) => data);
};

const getProductCategories = (channelId: string, params = {}) => {
  return AppService.get(`/channel/${channelId}/productCategory`, params).then(({ data }) => data);
};

const createProduct = async (channelId: string, productId: string): Promise<iChannelJobEntity> => {
  return AppService.post(`/channel/${channelId}/product`, {
    entityId: productId,
  }).then(({ data }) => data);
};

const updateProduct = async (
  channelId: string,
  UID: string | number,
  params = {},
  forceSync = false,
): Promise<iChannelJobEntity> => {
  const forceSyncString = forceSync === true ? '?forceSync=true' : '';
  return AppService.put(`/channel/${channelId}/product/${UID}${forceSyncString}`, params).then(({ data }) => data);
};

const getOrderStatuses = (channelId: string): Promise<string[]> => {
  return AppService.get(`/channel/${channelId}/orderStatus`).then(({ data }) => data);
};

export default {
  getProducts,
  getProductCategories,
  createProduct,
  updateProduct,
  getOrderStatuses,
};
