import { useEffect, useReducer } from 'react';
import WorkflowService from '../../../services/WorkflowService';
import EntityStatusTypeService from '../../../services/EntityStatusTypeService';
import { getEntityCategoryList } from '../../../services/CategoryService';
import { WKDiagramActionKind, WKDiagramInitialState, WKDiagramReducer } from './WorkflowDiagramReducer';
import { addToastForAPIResponse } from '../../toast/Toast';
import { initDiagram } from './WorkflowDiagramHelper';
import iWorkflow from '../../../types/workflow/iWorkflow';

// eslint-disable-next-line @typescript-eslint/ban-types
const useWorkflowDiagramHook = (entityStatusTypeId: string, setNodes: Function, setEdges: Function) => {
  const [state, dispatch] = useReducer(WKDiagramReducer, WKDiagramInitialState);

  useEffect(() => {
    let isCancelled = false;
    dispatch({ type: WKDiagramActionKind.Loading });
    Promise.all([
      WorkflowService.getWorkflows({
        filter: `entityId:${entityStatusTypeId},entityName:EntityStatusType`,
      }),
      EntityStatusTypeService.getEntityStatusTypeDetail(entityStatusTypeId, {
        include: 'entityStatuses.entityStatusCategory',
      }),
      getEntityCategoryList(),
    ])
      .then(res => {
        if (isCancelled) {
          return;
        }
        const workflow = res[0].data[0] || null;
        const entityStatuses = res[1].entityStatuses || [];
        const { nodes, edges } = initDiagram(entityStatuses, workflow);
        setNodes(nodes);
        setEdges(edges);
        dispatch({
          type: WKDiagramActionKind.Update,
          payload: {
            errors: [],
            initials: { nodes: nodes || [], edges: edges || [] },
            setNodes,
            setEdges,
            entityStatusTypeId,
            isLoading: false,
            workflow,
            entityStatusType: res[1],
            entityStatusCategories: res[2],
            entityStatuses,
          },
        });
      })
      .catch(error => {
        addToastForAPIResponse('error', error.message);
      })
      .finally(() => {
        if (isCancelled) {
          return;
        }
        dispatch({ type: WKDiagramActionKind.Loaded });
      });
    return () => {
      isCancelled = true;
    };
  }, [entityStatusTypeId, setNodes, setEdges]);

  const setWorkflow = (newWorkFlow: iWorkflow) => {
    dispatch({
      type: WKDiagramActionKind.Update,
      payload: {
        ...state,
        workflow: newWorkFlow,
      },
    });
  };

  const setErrors = (errors: string[]) => {
    dispatch({
      type: WKDiagramActionKind.Update,
      payload: {
        ...state,
        errors,
      },
    });
  };

  return {
    state,
    setErrors,
    setWorkflow,
  };
};

export default useWorkflowDiagramHook;
