import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import BackendPage from '../../../layout/BackendPage';
import {
  createMachine,
  deactivateMachine,
  getMachines,
  updateMachine,
} from '../../../services/Settings/MachineService';
import iMachine from '../../../types/machine/iMachine';
import MachineListTable from './MachineListTable';
import CustomizeModal from '../../../shared/modal/CustomizeModal';
import MachineEditModal from './MachineEditModal';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import PlusIconAfterTitle from '../../../components/settings/PlusIconAfterTitle';
import { objectContainsInvalidValues } from '../../sales/utilities';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import Sidebar from '../sidebar/Sidebar';
import { getWarehouseLocationPathNames } from '../../../services/WarehouseService';
import { iParams } from '../../../shared/UITypes/types';
import { apiErrorToast } from '../../../shared/toast/Toast';

const MachineSetting = () => {
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenEditModal,
    onSubmit,
    onSetSort,
  } = useListCrudHook<iMachine>({
    getFn: getMachines,
    createFn: createMachine,
    updateFn: updateMachine,
    deleteFn: deactivateMachine,
  });

  const [idNameMapping, setIdNameMapping] = useState<iParams>();
  const { control, setValue, errors, handleSubmit } = useForm();

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'Machines'} />
      <PlusIconAfterTitle pageTitle={'Machines'} onClick={onOpenAddModal} />
    </>
  );

  useEffect(() => {
    let mounted = true;
    const fetchIdNameMap = async () => {
      if (typeof state.data === 'undefined') return;
      try {
        const machineLocationIds = state.data.map((machine: iMachine) => machine.warehouseLocationId);
        const stringIds = JSON.stringify(machineLocationIds);
        const { idNameMap } = await getWarehouseLocationPathNames(stringIds);
        if (!mounted) return;
        setIdNameMapping(idNameMap);
      } catch (e) {
        if (!mounted) return;
        apiErrorToast(e);
      }
    };
    fetchIdNameMap();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.data)]);

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <MachineListTable
        machines={state.data}
        idNameMapping={idNameMapping}
        onSetSort={onSetSort}
        onDelete={onDelete}
        onEdit={onOpenEditModal}
        isLoading={state.isLoading}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0 || objectContainsInvalidValues(control.getValues())}
        modalBody={
          <MachineEditModal control={control} errors={errors} onChange={setValue} targetMachine={edit.target} />
        }
        modalHeading={edit.target ? edit.target.name : 'Create'}
        confirmBtnName={'Save'}
      />
    </BackendPage>
  );
};

export default MachineSetting;
