import React from 'react';
import { FlexContainer } from '../../../../../../shared/styles/styles';
import DataInfo from '../../../shared/DataInfo';

const DataSummary = ({
  validLength,
  invalidLength,
}: {
  validLength: number;
  invalidLength: number;
}) => {
  return (
    <FlexContainer>
      {validLength > 0 && (
        <DataInfo
          desc={' record(s) can be imported.'}
          lozenge={validLength}
          appearance={'success'}
        />
      )}
      {invalidLength > 0 && (
        <DataInfo
          desc={' record(s) will be skipped.'}
          lozenge={invalidLength}
          appearance={'warning'}
        />
      )}
    </FlexContainer>
  );
};

export default DataSummary;
