import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';
import { Grid } from '@atlaskit/page';

import { useSelector } from 'react-redux';
import SearchBar from '../../../../shared/search/SearchBar';
import BackendPage from '../../../../layout/BackendPage';
import iItemLocation from '../../../../types/warehouse/iItemLocation';
import CustomizePagination from '../../../../shared/pagination/CustomizePagination';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import {
  ITEM_LOCATION_COLUMNS,
  ITEM_LOCATION_LIST_TITLE,
  ITEM_LOCATION_TABLE_NAME,
} from '../../shared/Warehouse.constants';
import { getRows } from './TableHelper';
import { getHeads } from '../../../../services/UtilsService';
import { getItemLocationList } from '../../../../services/ItemLocationService';
import { AdvancedSearchSectionWrapper, FlexContainer, PageTitle } from '../../../../shared/styles/styles';
import Advanced from '../../../../shared/contactCompany/list/Advanced';
import useAdvancedSearchHooks from '../../../../shared/advancedSearch/AdvancedSearch';
import { ITEMLOCATION_ADVANCED_SEARCH_CONFIG } from '../../../itemLocation/ItemLocation.constants';
import PaginationDetail from '../../../../shared/pagination/paginationDetail/PaginationDetail';
import { RootState } from '../../../../redux/makeReduxStore';
import AccessService from '../../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCTS, ACCESS_CODE_WAREHOUSES } from '../../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../../types/settings/UserAccess/iRoleAccess';

const ProductListWrapper = styled.div`
  .product-list-heading {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
`;

const ItemLocationList = ({
  warehouseLocationId,
  isIndependentPage = false,
}: {
  warehouseLocationId?: string;
  isIndependentPage?: boolean;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [isAdvancedSearch, setIsAdSearch] = useState(false);

  const { getAdvancedFilterStrArr, getSearchControllers } = useAdvancedSearchHooks(ITEMLOCATION_ADVANCED_SEARCH_CONFIG);

  const { state, edit, onSetCurrentPage, onSearch, onFilter, onRefreshList } = useListCrudHook<iItemLocation>({
    getFn: getItemLocationList,
    filter: isIndependentPage ? '' : `warehouseLocationId:${warehouseLocationId}`,
    sort: 'createdAt:DESC',
    paginationApplied: true,
    keywordColumns: isIndependentPage
      ? 'reference,product.name,warehouseLocation.name,warehouseLocation.locationCode'
      : 'reference,product.name',
    perPage: 10,
  });

  useEffect(() => {
    if (isIndependentPage) {
      const advancedFilterArr = [...getAdvancedFilterStrArr()].filter(strFilter => strFilter !== '');
      // refactor after sync with develop
      onFilter(advancedFilterArr.join(','));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(getAdvancedFilterStrArr())]);

  const getPageHeader = () => (
    <>
      <FlexContainer className={'space-between'}>
        {isIndependentPage ? (
          <>
            <PageTitle>{ITEM_LOCATION_LIST_TITLE}</PageTitle>
            <FlexContainer>
              <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search...'} />
              <Advanced onClick={() => setIsAdSearch(prev => !prev)} />
            </FlexContainer>
          </>
        ) : (
          <>
            <h4 className={'product-list-heading'}>{`${state.total} product(s) stored here`}</h4>
            <div style={{ flexGrow: 0, minWidth: '250px' }}>
              <SearchBar keyword={edit.keyword} onSearch={onSearch} placeholder={'Search by product name/batch....'} />
            </div>
          </>
        )}
      </FlexContainer>
      {isAdvancedSearch && (
        <AdvancedSearchSectionWrapper>
          <Grid layout={'fluid'} spacing={'cosy'}>
            {getSearchControllers()}
          </Grid>
        </AdvancedSearchSectionWrapper>
      )}
    </>
  );

  const getPageBody = () => (
    <>
      <FlexContainer className={'space-between space-below'}>
        {!!state.total && !!state.from && !!state.to && (
          <PaginationDetail from={state.from} to={state.to} total={state.total} onRefreshResults={onRefreshList} />
        )}
        <div />
      </FlexContainer>
      <DynamicTable
        head={getHeads(ITEM_LOCATION_COLUMNS, ITEM_LOCATION_TABLE_NAME)}
        rows={getRows(
          state.data,
          ITEM_LOCATION_COLUMNS.map(col => {
            if (
              col.key === 'product.productCode' &&
              !AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user)
            ) {
              return {
                ...col,
                urlPrefix: undefined,
              };
            }
            if (
              col.key === 'warehouseLocation.name' &&
              !AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_READ, user)
            ) {
              return {
                ...col,
                urlPrefix: undefined,
              };
            }
            return col;
          }),
        )}
        isLoading={state.isLoading}
        isFixedSize
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </>
  );

  return isIndependentPage ? (
    <BackendPage pageHeader={getPageHeader()}>{getPageBody()}</BackendPage>
  ) : (
    <ProductListWrapper>
      {getPageHeader()}
      {getPageBody()}
    </ProductListWrapper>
  );
};

export default ItemLocationList;
