import Spinner from '@atlaskit/spinner';
import React, { useEffect, useState } from 'react';
import ChannelService from '../../../services/integration/ChannelService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import iChannel from '../../../types/integration/iChannel';

const IntegrationLinkEntityWithAccountingChannels = ({
  getChannelEntitySelector,
  checkIsIntegrated,
}: {
  getChannelEntitySelector: (channelId: string) => React.ReactNode;
  checkIsIntegrated?: (channels: iChannel[]) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [channels, setChannels] = useState<iChannel[]>([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const channelsResp = await ChannelService.getChannelsForAccounting(true);
        setChannels([...channelsResp]);
        if (typeof checkIsIntegrated === 'function') checkIsIntegrated([...channelsResp]);
        setIsLoading(false);
      } catch (error) {
        apiErrorToast(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && channels.length > 0 && channels.map(({ id: channelId }) => getChannelEntitySelector(channelId))}
    </>
  );
};

export default IntegrationLinkEntityWithAccountingChannels;
