import moment from 'moment';
import { getProductTypeListAsyncSearch } from '../../services/product/ProductTypeService';
import { formatDate } from '../../services/UtilsService';

export const MUST_COLUMNS_BEFORE = ['SKU'];
export const MUST_COLUMNS_AFTER = ['Operation'];
export const FLEXIBLE_COLUMNS = [
  'Type',
  'UnitPrice(Inc.)',
  'StockOnHand',
  'TotalValue(ex.)',
  'Unit',
  'isForTemplate',
  'isForSell',
  'isForPurchase',
  'isForMake',
  'isForWaste',
  'Created',
  'Updated',
];
export const COLUMNS = [...MUST_COLUMNS_BEFORE, ...FLEXIBLE_COLUMNS, ...MUST_COLUMNS_AFTER];
export const COLUMN_NAME = 'product-list-columns';

export const PRODUCT_FLAGS = [
  { label: 'isForMake', value: 'isForMake' },
  { label: 'isForPurchase', value: 'isForPurchase' },
  { label: 'isForSell', value: 'isForSell' },
  { label: 'isForWaste', value: 'isForWaste' },
  { label: 'isTemplate', value: 'isTemplate' },
];

export const PRODUCT_ADVANCED_SEARCH_CONFIGS = [
  {
    name: 'productTypeId',
    label: 'Product Types',
    type: 'multi',
    selected: [],
    placeholder: 'All product types...',
    promiseFn: getProductTypeListAsyncSearch,
    optionLabel: 'name',
    sortBy: 'name',
    removable: false,
    testId: 'product-advanced-search-productTypes',
    className: 'normal-width',
  },
  {
    name: '',
    label: 'Flags',
    type: 'checkbox-select',
    options: PRODUCT_FLAGS,
    selected: [],
    placeholder: '',
    removable: false,
    testId: 'product-advanced-search-product-flags',
  },
];

export const PRODUCT_REPORT_FILE_NAME = `products-report-${formatDate(
  moment().toString(),
  'DD MMMM YYYY, hh:mm:ss',
)}.csv`;
