import { iParams } from '../../shared/UITypes/types';
import AppService from '../AppService';

const getCourierList = (config?: iParams) => {
  return AppService.get('/courier', config).then(({ data }) => data);
};

export default {
  getCourierList,
};
