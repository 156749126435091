import moment from 'moment';
import { getAlternativeField } from '../../../pages/job/jobDetail/shared/JobDetail.utils';
import { formatDate, handleNullException, numberRound } from '../../../services/UtilsService';
import iJob from '../../../types/job/iJob';
import iJobAttribute from '../../../types/job/iJobAttribute';
import iProductAttribute from '../../../types/product/iProductAttribute';
import iProductAttributeValue from '../../../types/product/iProductAttributeValue';
import { iKeyValuePairs } from '../../UITypes/types';

export const getDict = (job: iJob): iKeyValuePairs => {
  return {
    id: job.id,
    Job: job.jobNumber,
    Order: getAlternativeField(job, 'salesOrder.orderNumber', 'orderNumber'),
    Customer: getAlternativeField(job, 'salesOrder.customer.name', 'customerName'),
    'Customer Ref': getAlternativeField(job, 'salesOrder.customerRef', 'customerOrderRef'),
    'Product Name': handleNullException(job, 'product.name'),
    'Product Code': handleNullException(job, 'product.productCode'),
    Machine: handleNullException(job, 'machine.name'),
    Operator: handleNullException(job, 'operator.username'),
    Status: handleNullException(job, 'status.name'),
    'Scheduled Date': job.scheduledAt ? moment(job.scheduledAt).format('DD MMM YYYY') : '',
    'Order Date': job.salesOrder?.orderDate ? moment(job.salesOrder?.orderDate).format('DD MMM YYYY') : '',
    EDD: job.salesOrder?.edd ? moment(job.salesOrder?.edd).format('DD MMM YYYY') : '',
    'Qty to Make': job.qtyToMake || '',
    'Qty Total': job.qtyTotal || '',
    UOM: handleNullException(job, 'product.measurement.shortName'),
    Created: `${handleNullException(job, 'createdBy.firstName')}@${formatDate(handleNullException(job, 'createdAt'))}`,
    Updated: `${handleNullException(job, 'updatedBy.firstName')}@${formatDate(handleNullException(job, 'updatedAt'))}`,
  };
};

const getValueFromKnownMap = (columnName: string, job: iJob) => {
  const dictionary = getDict(job);
  return dictionary[columnName];
};

export const getJobAttrNameValuePair = (job: iJob) => {
  const jobAttrNameValuePair = job.jobAttributes.reduce((acc: iKeyValuePairs, cur: iJobAttribute) => {
    return {
      ...acc,
      [cur.name]: numberRound(cur.jobAttributeValues.value, 0) || '',
    };
  }, {});
  return jobAttrNameValuePair;
};

const getValueFromJobAttr = (columnName: string, job: iJob) => {
  const jobAttrDict = getJobAttrNameValuePair(job);
  return jobAttrDict[columnName];
};

export const getProdAttrNameValuePair = (job: iJob, prodAttrsCluster: Array<iProductAttribute>) => {
  const prodAttrs = handleNullException(job, 'product.productAttributeValues') || [];
  const prodAttrNameValuePair = prodAttrs.reduce((acc: iKeyValuePairs, cur: iProductAttributeValue) => {
    const pAttrName = prodAttrsCluster.find((p: iProductAttribute) => p.id === cur.attributeId)?.name;
    return pAttrName ? { ...acc, [pAttrName]: cur.value || '' } : acc;
  }, {});
  return prodAttrNameValuePair;
};

const getValueFromProductAttr = (columnName: string, job: iJob, prodAttrsCluster: Array<iProductAttribute>) => {
  const prodAttrDict = getProdAttrNameValuePair(job, prodAttrsCluster);
  return prodAttrDict[columnName];
};

const extractValueFromColumnName = (columnName: string, job: iJob, prodAttrsCluster: Array<iProductAttribute>) => {
  const value =
    getValueFromKnownMap(columnName, job) ||
    getValueFromJobAttr(columnName, job) ||
    getValueFromProductAttr(columnName, job, prodAttrsCluster) ||
    '';
  return value;
};

export const getReportArrSegments = (
  data: iJob[],
  selectedColumnNames: string[],
  prodAttrsCluster: Array<iProductAttribute>,
) => {
  const reportCells = data.map((item: iJob) =>
    selectedColumnNames.map(columnName => extractValueFromColumnName(columnName, item, prodAttrsCluster)),
  );
  return [selectedColumnNames, ...reportCells];
};

