/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Label } from '@atlaskit/field-base';
import Page, { Grid, GridColumn } from '@atlaskit/page';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import BackendPage from '../../../layout/BackendPage';
import Sidebar from '../sidebar/Sidebar';
import { RootState } from '../../../redux/makeReduxStore';

import InlineEdit from '../../../shared/form/InlineEdit';
import UpdateAddressPopup from '../../../shared/popup/UpdateAddressPopup';
import { AddressPlaceholderWrapper, LabelValue, SpaceVerticalContainer } from '../../../shared/styles/styles';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/Toast';
import { iAddressContent } from '../../../types/iAddressContent';
import CompanyProfileService from '../../../services/Settings/CompanyProfileService';
import AddressService from '../../../services/AddressService';
import { getMe } from '../../../services/AuthService';
import { userAuthenticated } from '../../../redux/reduxers/auth.slice';
import { assembleAddress } from '../../../services/UtilsService';
import EditableCompanyLogo from './EditableCompanyLogo';
import iAddress from '../../../types/contactCompany/iAddress';

const InlineEditContainer = styled.div`
  max-width: 25rem;
  margin: 1rem 0;
`;

const AddressContainer = styled.div`
  margin: 1rem 0;
`;

const CompanyProfileSetting = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const company = user?.company;
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState<iAddress | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!company || !company.id || !company.addressId) {
      return;
    }
    let isCanceled = false;

    setIsLoading(true);
    AddressService.getAddressDetail(company.addressId)
      .then(resp => {
        setAddress(resp);
      })
      .catch(err => {
        addToastForAPIResponse('error', err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line consistent-return
    return () => {
      isCanceled = true;
    };
  }, [company]);

  const onConfirm = async (key: string, value: string) => {
    if (!company) return;
    try {
      await CompanyProfileService.updateCompanyProfile(company.id, {
        [`${key}`]: value,
      });
      getMe().then(res => {
        dispatch(userAuthenticated(res));
      });
      addToastForAPIResponse('success');
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onUpdateAddress = async (updateContent: iAddressContent) => {
    if (!company) return;
    try {
      if (!company.addressId) {
        const { id } = await AddressService.createAddress(updateContent);
        await CompanyProfileService.updateCompanyProfile(company.id, {
          addressId: id,
        });
      } else {
        await AddressService.editAddress(company.addressId, updateContent);
      }
      getMe().then(res => {
        dispatch(userAuthenticated(res));
      });
      addToastForAPIResponse('success');
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const getAddressEdit = () => {
    return (
      <AddressContainer>
        <Label label="Address" />
        {isLoading === true ? (
          <Spinner />
        ) : (
          <UpdateAddressPopup
            address={address}
            heading={'Address'}
            onClick={(data: iAddressContent) => onUpdateAddress(data)}
          >
            <LabelValue>
              {assembleAddress(address) || <AddressPlaceholderWrapper>Click to enter value</AddressPlaceholderWrapper>}
            </LabelValue>
          </UpdateAddressPopup>
        )}
      </AddressContainer>
    );
  };

  return (
    <BackendPage leftMenu={<Sidebar />}>
      <Page>
        <Grid layout={'fluid'} spacing={'compact'}>
          <GridColumn medium={4}>
            <InlineEditContainer>
              <InlineEdit
                name={'name'}
                defaultValue={company?.name}
                label={'Company Name'}
                onConfirm={onConfirm}
                isRequired
              />
            </InlineEditContainer>
          </GridColumn>
          <GridColumn medium={2}>
            <InlineEditContainer>
              <InlineEdit name={'abn'} defaultValue={company?.abn} label={'ABN'} onConfirm={onConfirm} isRequired />
            </InlineEditContainer>
          </GridColumn>
          <GridColumn medium={3}>
            <InlineEditContainer>
              <InlineEdit name={'phone'} defaultValue={company?.phone} label={'Phone'} onConfirm={onConfirm} />
            </InlineEditContainer>
          </GridColumn>
          <GridColumn medium={3}>
            <InlineEditContainer>
              <InlineEdit name={'email'} defaultValue={company?.email} label={'Email'} isEmail onConfirm={onConfirm} />
            </InlineEditContainer>
          </GridColumn>
        </Grid>
        {getAddressEdit()}
        <Grid layout={'fluid'} spacing={'compact'}>
          <GridColumn medium={4}>
            <Label label="Company logo" />
            <EditableCompanyLogo companyLogo={company?.logoUrl} onConfirm={onConfirm} />
            <SpaceVerticalContainer className={'half'} />
          </GridColumn>
        </Grid>
      </Page>
    </BackendPage>
  );
};

export default CompanyProfileSetting;
