import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getNCRIssueInvestigationList = (config?: iParams) => {
  return AppService.get('/issueInvestigation', {
    ...config,
    include: 'createdBy,updatedBy',
  }).then(({ data }) => data);
};

export const deactivateNCRIssueInvestigation = (id: string) => {
  return AppService.delete(`/issueInvestigation/${id}`).then(
    ({ data }) => data,
  );
};

export const createNCRIssueInvestigation = (postData: iKeyValuePairs) => {
  return AppService.post('/issueInvestigation', postData).then(
    ({ data }) => data,
  );
};

export const updateNCRIssueInvestigation = (
  id: string,
  updateContent: iKeyValuePairs,
) => {
  return AppService.put(`/issueInvestigation/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export default {
  getNCRIssueInvestigationList,
  updateNCRIssueInvestigation,
  createNCRIssueInvestigation,
  deactivateNCRIssueInvestigation,
};
