import AppService from './AppService';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../shared/constants/AsyncConstants';

export const getCustomerList = (config?: iParams) => {
  return AppService.get('/company/customer', {
    ...config,
    include:
      'shippingAddress,billingAddress,type.roles,createdBy,updatedBy,contactCompanyContacts.contact,contactCompanyContacts.role',
  }).then(({ data }) => data);
};

const getCustomers = (config?: iParams) => {
  return AppService.get('/company/customer', config);
};

const getSuppliers = (config?: iParams) => {
  return AppService.get('/company/supplier', config);
};

export const getSupplierList = (config?: iParams) => {
  return AppService.get('/company/supplier', {
    ...config,
    include:
      'shippingAddress,billingAddress,type.roles,createdBy,updatedBy,contactCompanyContacts.contact,contactCompanyContacts.role',
  }).then(({ data }) => data);
};

export const getServiceProviderList = (config?: iParams) => {
  return AppService.get('/company/serviceProvider', {
    ...config,
    include:
      'shippingAddress,billingAddress,type.roles,createdBy,updatedBy,contactCompanyContacts.contact,contactCompanyContacts.role',
  }).then(({ data }) => data);
};

export const removeContactCompany = (id: string) => {
  return AppService.delete(`/company/${id}`).then(({ data }) => data);
};

export const createContactCompany = (name: string, typeId: string) => {
  return AppService.post('/company', { name, typeId }).then(({ data }) => data);
};

export const getContactCompanies = (config: iParams = {}) => {
  return AppService.get('/company', config).then(({ data }) => data);
};

export const createContactCompanyRefactor = (postData: iKeyValuePairs) => {
  return AppService.post('/company', postData).then(({ data }) => data);
};
export const updateContactCompany = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/company/${id}`, updateContent).then(({ data }) => data);
};

export const getContactCompany = (id: string) => {
  return AppService.get(`/company/${id}`, {
    include:
      'shippingAddress,billingAddress,type.roles,createdBy,updatedBy,contactCompanyContacts.contact,contactCompanyContacts.role',
  }).then(({ data }) => data);
};

export const getContactCompanyTypeList = (config?: { [key: string]: string }) => {
  return AppService.get('/companyType', {
    ...config,
    include: 'roles',
  }).then(({ data }) => data);
};
export const createAndUpdateContactCompanyContact = (roleId: string, contactId: string | null, companyId: string) => {
  return AppService.put(`/company/assignRole/${companyId}`, {
    roleId,
    contactId,
  }).then(({ data }) => data);
};

export const getCustomerListAsyncSearch = (config?: iParams) => {
  return AppService.get('/company/customer', {
    ...config,
    include: 'shippingAddress,billingAddress',
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

export const getSupplierListAsyncSearch = (config?: iParams) => {
  return AppService.get('/company/supplier', {
    ...config,
    include: 'shippingAddress,billingAddress',
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

export const getServiceProviderListAsyncSearch = (config?: iParams) => {
  return AppService.get('/company/serviceProvider', {
    ...config,
    include: 'shippingAddress,billingAddress',
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};
export const createContactCompanyConfig = (config: iKeyValuePairs) => {
  return AppService.post('/company', config).then(({ data }) => data);
};

export default {
  getContactCompanies,
  getCustomers,
  getSuppliers,
  getCustomerList,
  getSupplierList,
  getServiceProviderList,
  getContactCompany,
  removeContactCompany,
  createContactCompany,
  getContactCompanyTypeList,
  createAndUpdateContactCompanyContact,
  getSupplierListAsyncSearch,
  getCustomerListAsyncSearch,
  createContactCompanyRefactor,
  getServiceProviderListAsyncSearch,
};
