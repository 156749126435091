/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import styled from 'styled-components';

import SpinWhenLoading from '../spin/SpinWhenLoading';
import { iConfigColumn, iSortOrder } from '../UITypes/types';
import { getSortOrderArrToUpdate, reorder } from './DndTable.helper';
import { getItemStyle, getListStyle, Wrapper } from './DndTable.style';

const TD = styled.td`
  &.td-0 {
    padding-left: 0;
    width: 20px;
  }
`;

const DndTable = ({
  isLoading,
  data,
  onReorder,
  columns,
  getRows,
  contentColumnNumber,
}: {
  isLoading: boolean;
  data: any[];
  onReorder: (reorderedArr: iSortOrder[]) => void;
  columns: Array<iConfigColumn>;
  // getRows: () => any[];
  getRows: any[];
  contentColumnNumber: number;
}) => {
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    // const getItems = getRows();
    setItems(getRows);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getRows]);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(items, result.source.index, result.destination.index);
    setItems(reorderedItems);
    const sortOrderToUpdate = getSortOrderArrToUpdate(reorderedItems);
    onReorder(sortOrderToUpdate);
  };

  TD.defaultProps = {
    width: `${100 / contentColumnNumber}%`,
  };

  if (isLoading) return <SpinWhenLoading />;
  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <table ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              <thead>
                <tr>
                  {columns
                    .filter((col: iConfigColumn) => col.type !== 'sortOrder')
                    .map((col: iConfigColumn) => (
                      <th key={col.key} className={col.key}>
                        {col.name}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {items.map((item: any, index: number) => (
                  <Draggable key={item.key} draggableId={item.key} index={index}>
                    {(providedDrop: DraggableProvided, snapshotDrop: DraggableStateSnapshot) => (
                      <tr
                        ref={providedDrop.innerRef}
                        {...providedDrop.draggableProps}
                        {...providedDrop.dragHandleProps}
                        style={{
                          ...getItemStyle(snapshotDrop.isDragging, providedDrop.draggableProps.style),
                        }}
                      >
                        {item.cells
                          ?.filter((cell: any) => cell.type !== 'sortOrder')
                          .map((cell: any, i?: number) => {
                            return (
                              <TD key={`${cell.key}-${Math.random()}`} className={i === 0 ? `td-${i}` : undefined}>
                                {cell.content}
                              </TD>
                            );
                          })}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
};

export default DndTable;
