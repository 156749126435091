import React from 'react';
import {
  getCreatedOrUpdatedAt,
  getDeleteBtn,
} from '../../../../components/settings/utils';
import {
  getCategoryCode,
  handleNullException,
  hasKey,
} from '../../../../services/UtilsService';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import StatusLozenge from '../../../../shared/statusComponent/StatusLozenge';
import { iRowContent } from '../../../../shared/UITypes/types';
import iPoReceiving from '../../../../types/purchases/iPoReceiving';
import iEntityCategory from '../../../../types/status/iEntityCategory';
import {
  PO_RECEIVES_DOCKET_NUMBER_ALIAS,
  PO_RECEIVING_STATUS_CLOSED_CATEGORY,
} from '../../constants';

export const getHeads = (columns: Array<string>, tableName: string) => {
  const cells = columns.map((column: string) => {
    switch (column) {
      case 'Operation':
        return {
          key: column,
          testId: `${tableName}-column-${column}`,
        };
      case PO_RECEIVES_DOCKET_NUMBER_ALIAS:
        return {
          key: column,
          testId: `${tableName}-column-${column}`,
          content: column,
          isSortable: true,
        };
      default:
        return {
          key: column,
          content: column,
          testId: `${tableName}-column-${column}`,
        };
    }
  });
  return { cells };
};

export const getRows = (
  data: Array<iPoReceiving>,
  onDelete: (id: string) => void,
  columns: Array<string>,
  categories: Array<iEntityCategory>,
) => {
  const mapList = () => {
    return data.map((poReceiving: iPoReceiving) => {
      const row = {
        id: poReceiving.id,
        [PO_RECEIVES_DOCKET_NUMBER_ALIAS]: (
          <LinkBtnUrl
            btnName={handleNullException(poReceiving, 'reference')}
            url={`/b/purchases/receiving/${poReceiving.id}`}
          />
        ),
        Status: (
          <StatusLozenge
            status={handleNullException(poReceiving, 'status')}
            categoryCode={getCategoryCode(
              handleNullException(poReceiving, 'status'),
              categories,
            )}
          />
        ),
        'No of Line Items': poReceiving.purchaseOrderReceivingItems
          ? poReceiving.purchaseOrderReceivingItems.length
          : 0,
        Created: getCreatedOrUpdatedAt({
          operatedAt: poReceiving.createdAt,
          operator: poReceiving.updatedBy?.username,
        }),
        Updated: getCreatedOrUpdatedAt({
          operatedAt: poReceiving.updatedAt,
          operator: poReceiving.updatedBy?.username,
        }),
        Operation:
          poReceiving.status?.entityStatusCategory?.code ===
          PO_RECEIVING_STATUS_CLOSED_CATEGORY
            ? null
            : getDeleteBtn({
                id: poReceiving.id,
                answer: poReceiving.reference || 'Docket # or Ref. n/a',
                onClick: onDelete,
              }),
      };
      return row;
    });
  };

  return mapList().map((row: iRowContent) => {
    const cells = columns.map((column: string) => ({
      content: hasKey(row, column) ? row[column] : '',
    }));
    return { cells, testId: 'admin-po-receives-row' };
  });
};
