import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import BackendPage from '../../../layout/BackendPage';
import Sidebar from '../sidebar/Sidebar';
import CustomizePagination from '../../../shared/pagination/CustomizePagination';
import BreadcrumbInHeader from '../../../components/settings/BreadcrumbInHeader';
import { FlexSpaceBetweenContainer } from '../../../shared/contactCompany/style';
import PlusIconAfterTitle from '../../../components/settings/PlusIconAfterTitle';
import SearchBar from '../../../shared/search/SearchBar';
import useListCrudHook from '../../../shared/hooks/useListCrudHook/useListCrudHook';
import RoleService from '../../../services/Settings/UserAccess/RoleService';
import RoleList from '../../../shared/UserAccess/RoleList';
import iRole from '../../../types/settings/UserAccess/iRole';
import RoleEditPopup from '../../../shared/UserAccess/RoleEditPopup';
import { SETTINGS_ROLE_DETAILS_URL } from '../../../shared/UrlMap';

const RolesPage = () => {
  const history = useHistory();
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onSearch,
    onSubmit,
    onSetSort,
    onSetCurrentPage,
  } = useListCrudHook<iRole>({
    getFn: useCallback(
      (config = {}) =>
        RoleService.getRoleList({
          ...config,
          filter: 'isActive:1',
          include: 'createdBy,updatedBy,roleAccesses.access',
        }),
      [],
    ),

    createFn: RoleService.createRole,
    updateFn: RoleService.updateRole,
    deleteFn: RoleService.deleteRole,
    keywordColumns: 'name',
    paginationApplied: true,
    createCallBack: (id: string) => history.push(SETTINGS_ROLE_DETAILS_URL.replace(':id', id)),
    //  by default each page has 30 items
    // perPage: 1,
  });

  const getPageHeader = () => (
    <>
      <BreadcrumbInHeader subName={'Roles'} />
      <FlexSpaceBetweenContainer>
        <PlusIconAfterTitle pageTitle={'Roles'} onClick={onOpenAddModal} />
        <div style={{ flexGrow: 0 }}>
          <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'search by name'} />
        </div>
      </FlexSpaceBetweenContainer>
    </>
  );

  const getModal = () => {
    return (
      <RoleEditPopup
        role={edit.target}
        isOpen={edit.isModalOpen}
        onCancel={onCloseModal}
        onSave={onSubmit}
        isSaving={state.isConfirming}
      />
    );
  };

  return (
    <BackendPage pageHeader={getPageHeader()} leftMenu={<Sidebar />}>
      <RoleList roles={state.data} onDelete={onDelete} isLoading={state.isLoading} onSort={onSetSort} />
      {state.total && state.perPage && state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
      {getModal()}
    </BackendPage>
  );
};

export default RolesPage;
