import AppService from '../AppService';
import { iKeyValuePairs } from '../../shared/UITypes/types';

export const getCouriers = (config?: iKeyValuePairs) => {
  return AppService.get('/courier', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const deactivateCourier = (id: string) => {
  return AppService.delete(`/courier/${id}`).then(({ data }) => data);
};

export const createCourier = (postData: iKeyValuePairs) => {
  return AppService.post('/courier', postData).then(({ data }) => data);
};

export const updateCourier = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/courier/${id}`, updateContent).then(({ data }) => data);
};

export default {
  getCouriers,
  deactivateCourier,
  createCourier,
  updateCourier,
};
