import React from 'react';
import { useSelector } from 'react-redux';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import { RootState } from '../../../redux/makeReduxStore';

const SidebarHeader = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  return (
    <AvatarItem
      avatar={<Avatar size={'medium'} src={user?.company?.logoUrl} testId={'sidebar-avatar'} appearance={'square'} />}
      primaryText={<h4>{user?.company.name}</h4>}
      secondaryText={user?.company.abn}
      testId={'sidebar-avatar-item'}
    />
  );
};

export default SidebarHeader;
