import DynamicTable from '@atlaskit/dynamic-table';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { getHeads } from '../../../components/settings/utils';
import { formatDate, handleNullException } from '../../../services/UtilsService';
import LinkBtn from '../../buttons/LinkBtn';
import CustomizeModal from '../../modal/CustomizeModal';
import { iSortOrderType } from '../../UITypes/types';
import { iChannelJobEntity } from '../../../types/integration/iChannelJob';
import {
  CHANNEL_JOB_LOGS_COLUMN,
  CHANNEL_JOB_LOGS_TABLE_NAME,
  CHANNEL_JOB_LOGS_TEST_ID,
} from '../myob/Channel.constants';

const TableWrapper = styled.div`
  td {
    width: 5rem;
    max-width: 8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const LogTable = ({
  data,
  isLoading,
  onSetSort,
}: {
  data: iChannelJobEntity[];
  isLoading: boolean;
  onSetSort: (sortStr: string) => void;
}) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  const [preOpen, setPreOpen] = useState(false);
  const [modalContent, setModalContent] = useState<string>('');

  const onOpenModal = (content: string) => {
    setPreOpen(true);
    setModalContent(content);
  };

  const onCloseModal = () => {
    setPreOpen(false);
    setModalContent('');
  };

  const getLinkBtn = (content: string) => {
    return content !== 'null' ? <LinkBtn btnName={content} onClick={() => onOpenModal(content)} /> : null;
  };

  const getRows = () => {
    return data
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iChannelJobEntity) => ({
        cells: [
          {
            key: handleNullException(item, 'transactionId'),
            content: `${formatDate(handleNullException(item, 'createdAt'), 'YYYY-MM-DD HH:mm:ss')}`,
          },
          {
            key: handleNullException(item, 'status'),
            content: handleNullException(item, 'status'),
          },
          {
            key: handleNullException(item, 'request'),
            content: getLinkBtn(JSON.stringify(item.request, null, 2)),
          },
          {
            key: handleNullException(item, 'response'),
            content: getLinkBtn(JSON.stringify(item.response, null, 2)),
          },
          {
            key: handleNullException(item, 'error'),
            content: getLinkBtn(JSON.stringify(item.error, null, 2)),
          },
        ],
      }));
  };

  return (
    <TableWrapper>
      <h4 style={{ margin: '1rem 0' }}>Logs</h4>
      <DynamicTable
        head={getHeads(CHANNEL_JOB_LOGS_COLUMN, CHANNEL_JOB_LOGS_TABLE_NAME)}
        rows={getRows()}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        onSort={
          //  eslint-disable-next-line
              (data: any) => {
            setSortKey(data.key);
            setSortOrder(data.sortOrder);
            onSetSort(`${data.key}:${data.sortOrder}`);
          }
        }
        sortKey={sortKey}
        sortOrder={sortOrder}
        testId={CHANNEL_JOB_LOGS_TEST_ID}
      />
      <CustomizeModal
        isOpen={preOpen}
        onCancel={onCloseModal}
        cancelBtnName={'Close'}
        customizedBtn={<div />}
        modalHeading={''}
        modalBody={
          <div>
            <pre style={{ height: 'auto' }}>{modalContent}</pre>
          </div>
        }
        width={'150%'}
      />
    </TableWrapper>
  );
};

export default LogTable;
