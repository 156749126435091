import React, { useEffect, useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iDispatchNote from '../../../types/dispatchNote/iDispatchNote';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { iSortOrderType } from '../../../shared/UITypes/types';
import { getHeads, getRows } from './TableHelper';
import { DispatchNoteTableWrapper } from '../shared/DispatchNote.style';
import { TABLE_TEST_ID, TABLE_NAME } from '../shared/DispatchNote.constant';
import { iConfigColumn } from '../shared/DispatchNote.type';
import { getSalesOrderTypes } from '../../../services/SalesOrderService';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { SO_TYPE_NAME_INVOICE } from '../../sales/salesDetail/constants';

const DispatchNoteTable = ({
  data,
  onSetSort,
  isLoading,
  columns,
  onOpenDeleteModal,
  onCheck,
  categories,
}: {
  data: Array<iDispatchNote>;
  isLoading: boolean;
  columns: Array<iConfigColumn>;
  onSetSort: (sortStr: string) => void;
  onOpenDeleteModal?: (id: string) => void;
  onCheck: (id: string, value: boolean) => void;
  categories: Array<iEntityCategory>;
}) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [invoiceTypeId, setInvoiceTypeId] = useState('');

  useEffect(() => {
    let isCanceled = false;
    setLoadingTypes(true);

    getSalesOrderTypes()
      .then(resp => {
        if (isCanceled) return;
        const invoiceTypes = resp.filter(soType => soType.name === SO_TYPE_NAME_INVOICE);
        setInvoiceTypeId(invoiceTypes.length > 0 ? invoiceTypes[0].id : '');
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setLoadingTypes(false);
      });

    return () => {
      isCanceled = true;
    };
  }, []);

  return (
    <DispatchNoteTableWrapper>
      <DynamicTable
        head={getHeads(columns, TABLE_NAME)}
        rows={getRows(data, onOpenDeleteModal, onCheck, columns, categories, invoiceTypeId)}
        testId={TABLE_TEST_ID}
        isLoading={isLoading || loadingTypes}
        onSort={
          //  eslint-disable-next-line
          (data: any) => {
            setSortKey(data.key);
            setSortOrder(data.sortOrder);
            onSetSort(`${data.key}:${data.sortOrder}`);
          }
        }
        sortKey={sortKey}
        sortOrder={sortOrder}
      />
    </DispatchNoteTableWrapper>
  );
};

export default DispatchNoteTable;
