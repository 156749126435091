import {
  createAndUpdateContactCompanyContact,
  updateContactCompany,
} from '../../../services/ContactCompanyService';
import iContact from '../../../types/contactCompany/iContact';
import iContactCompany from '../../../types/contactCompany/iContactCompany';
import iContactCompanyContact from '../../../types/contactCompany/iContactCompanyContact';
import { addToastForAPIResponse } from '../../toast/Toast';

export const getContactForRole = (
  roleId: string,
  contactCompany?: iContactCompany,
  contacts?: Array<iContact>,
) => {
  if (!contactCompany || !contactCompany.contactCompanyContacts)
    return undefined;
  const roleContact = contactCompany.contactCompanyContacts.find(
    (item: iContactCompanyContact) => item.roleId === roleId,
  );
  const roleContactExistedInContacts = contacts?.find(
    (contact: iContact) => contact.id === roleContact?.contactId,
  );
  if (roleContactExistedInContacts)
    return {
      label: roleContactExistedInContacts.name,
      value: roleContactExistedInContacts.id,
    };
  return undefined;
};

export const mapContactsForSelectOptions = (contacts?: Array<iContact>) => {
  if (!contacts) return [];
  return contacts.map((contact: iContact) => ({
    label: contact.name,
    value: contact.id,
  }));
};

export const onConfirmSelect = async (
  roleId: string,
  contactId: string | null,
  //  eslint-disable-next-line
  callback: (name: string, value: any) => void,
  contactCompany?: iContactCompany,
) => {
  if (!contactCompany) return;
  try {
    const response = await createAndUpdateContactCompanyContact(
      roleId,
      contactId,
      contactCompany.id,
    );
    if (!contactCompany.contactCompanyContacts) {
      callback('contactCompanyContacts', [response]);
    } else {
      callback('contactCompanyContacts', [
        ...contactCompany.contactCompanyContacts.filter(
          (c: iContactCompanyContact) => c.id !== response.id,
        ),
        response,
      ]);
    }
    addToastForAPIResponse('success');
  } catch (error) {
    addToastForAPIResponse('error');
  }
};
export const onConfirm = async (
  name: string,
  value: string | number | boolean | null,
  callback: (name: string, value: string | number | boolean | null) => void,
  contactCompany?: iContactCompany,
) => {
  if (!contactCompany) return;
  try {
    const response = await updateContactCompany(contactCompany.id, {
      [name]: value,
    });
    if (response) {
      callback(name, value);
      addToastForAPIResponse('success');
    }
  } catch (error) {
    addToastForAPIResponse('error');
  }
};
