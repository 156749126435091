import React from 'react';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import { PageTitle, AddOneBtnWrapper, FlexContainer } from '../../shared/styles/styles';

const PlusIconAfterTitle = ({
  pageTitle,
  onClick,
  titleSize,
  isDisabled = false,
}: {
  pageTitle: string;
  onClick: () => void;
  titleSize?: string;
  isDisabled?: boolean;
}) => {
  return (
    <FlexContainer>
      <PageTitle className={titleSize}>{pageTitle}</PageTitle>
      {isDisabled ? null : (
        <AddOneBtnWrapper className={'space-left'}>
          <Button
            iconBefore={<AddIcon label="add" size={'small'} />}
            onClick={onClick}
            shouldFitContainer
            testId={'add-button'}
          />
        </AddOneBtnWrapper>
      )}
    </FlexContainer>
  );
};

export default PlusIconAfterTitle;
