import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import ProgressBar, { SuccessProgressBar } from '@atlaskit/progress-bar';
import CustomizeModal from '../../../modal/CustomizeModal';
import QtyUpdateModal from './QtyUpdateModal';
import { apiErrorToast } from '../../../toast/Toast';
import { FlexContainer } from '../../../styles/styles';
import { addsOnValue } from '../../../../services/UtilsService';
import { iDetailUpdateFn } from '../../../UITypes/types';

type iState = {
  isModalOpen: boolean;
  isConfirming: boolean;
};
const initialState: iState = {
  isModalOpen: false,
  isConfirming: false,
};
const ProgressBarWrapper = styled.div`
  box-sizing: border-box;
  padding: 1rem 1rem 1rem 0;
  color: rgb(0, 82, 204);
  &:hover {
    cursor: pointer;
  }
  .bar-description {
    color: rgb(66, 82, 110);
    font-size: 12px;
  }
  &.disabled {
    cursor: not-allowed;
  }
`;
const QtyUpdate = ({
  qtyToMake,
  qtyTotal,
  unit,
  onUpdate,
  isDisabled,
}: {
  qtyToMake: number;
  qtyTotal: number;
  unit?: string;
  onUpdate: iDetailUpdateFn;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState(initialState);
  const { control, setValue, errors, handleSubmit, watch } = useForm();
  //    eslint-disable-next-line
  const onSubmit = async (data: any) => {
    setState({ ...state, isConfirming: true });
    try {
      await onUpdate(data, true);
      setState({ ...state, isConfirming: false, isModalOpen: false });
    } catch (error) {
      setState({ ...state, isConfirming: false });
      apiErrorToast(error);
    }
  };
  return (
    <>
      <ProgressBarWrapper
        className={isDisabled ? 'disabled' : ''}
        onClick={() => (isDisabled ? null : setState({ ...state, isModalOpen: true }))}
      >
        {qtyToMake > 0 ? <ProgressBar value={(qtyTotal - qtyToMake) / qtyTotal} /> : <SuccessProgressBar value={1} />}
        <FlexContainer className={'space-between'}>
          <div className={'bar-description'}>
            {`${addsOnValue(_.round(qtyTotal - qtyToMake, 2), undefined, unit)} made`}
          </div>
          <div className={'bar-description'}>{`${addsOnValue(qtyToMake, undefined, unit)} remaining`}</div>
        </FlexContainer>
      </ProgressBarWrapper>
      <CustomizeModal
        isOpen={state.isModalOpen}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={() => setState({ ...state, isModalOpen: false })}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <QtyUpdateModal
            control={control}
            watch={watch}
            errors={errors}
            onChange={setValue}
            qtyToMake={qtyToMake}
            qtyTotal={qtyTotal}
            unit={unit}
          />
        }
        modalHeading={'Quantity Processing'}
        confirmBtnName={'Save'}
      />
    </>
  );
};

export default QtyUpdate;
