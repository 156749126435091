import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SectionMessage from '@atlaskit/section-message';
import { useSelector } from 'react-redux';
import iProduct from '../../../types/product/iProduct';
import BackendPage from '../../../layout/BackendPage';
import PopupContent from '../../../shared/popup/GeneralPopupContent';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import DeleteByRetype from '../../../shared/deleteWithReType/DeleteByReType';
import DetailRightPanel from './rightPanel/DetailRightPanel';
import DetailLeftPanel from './leftPanel/DetailLeftPanel';
import Activities from './activitySection/Activities';
import BreadcrumbsTwoLayers from '../../../shared/heading/BreadcrumbsTwoLayers';
import GeneralAttachments from '../../../shared/generalAttachments/GeneralAttachments';
import { ProductDetailWrapper } from './shared/ProductDetail.style';
import { PRODUCTS_URL } from '../../../shared/UrlMap';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/Toast';
import { AttachmentsWrapper, FlexContainer, PageTitle, SpaceVerticalContainer } from '../../../shared/styles/styles';
import { deactivateProduct, getProductDetail, updateProduct } from '../../../services/product/ProductService';
import { RootState } from '../../../redux/makeReduxStore';
import { ACCESS_CODE_PRODUCTS } from '../../../types/settings/UserAccess/iAccess';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CAN_DELETE, ACCESS_CAN_UPDATE } from '../../../types/settings/UserAccess/iRoleAccess';

const TemplateWarning = styled.div`
  margin-top: 1rem;
  width: 20rem;
  border-radius: 5px;
`;

type iState = {
  type: string;
  version: number;
  prodDetail?: iProduct;
  isModalOpen: boolean;
};
const initialState: iState = {
  type: 'generalInfo',
  version: 0,
  prodDetail: undefined,
  isModalOpen: false,
};

const Detail = ({ id }: { id: string }) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const history = useHistory();
  const [state, setState] = useState(initialState);
  const onRedirect = (url: string) => history.push(url);
  //  eslint-disable-next-line
  const onOpenDeleteModal = (targetId: string) =>
    setState({ ...state, isModalOpen: true });
  // update product
  const onUpdateProduct = async (name: string, newValue: string | number | boolean | null) => {
    if (!state.prodDetail) {
      addToastForAPIResponse('error', "can't find product.id");
      return;
    }
    try {
      await updateProduct(state.prodDetail.id, { [name]: newValue });
      setState({ ...state, version: state.version + 1 });
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const onSwitch = (type: string) => setState({ ...state, type });
  const onDelete = async () => {
    try {
      await deactivateProduct(id);
      onRedirect(PRODUCTS_URL);
    } catch (error) {
      apiErrorToast(error);
    }
  };

  useEffect(() => {
    let isCancelled = false;
    const fetchProductDetail = async () => {
      try {
        // todo: use id when product list page ready
        const prodDetail = await getProductDetail(id);
        if (isCancelled) return;
        // every time update product donot reset type to generalInfo
        setState(prevState => ({
          ...prevState,
          prodDetail,
          // type: 'generalInfo',
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
      }
    };
    fetchProductDetail();
    return () => {
      isCancelled = true;
    };
  }, [id, state.version]);

  const getPageHeader = () => {
    return (
      <FlexContainer className={'space-between'}>
        <div>
          <BreadcrumbsTwoLayers name={'Products'} subName={state.prodDetail?.name || ''} url={PRODUCTS_URL} />
          <PageTitle>{state.prodDetail?.name}</PageTitle>
          {state.prodDetail?.isTemplate && (
            <TemplateWarning>
              <SectionMessage appearance="warning">
                <p>This Product is a Template.</p>
              </SectionMessage>
            </TemplateWarning>
          )}
        </div>
        {AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_DELETE, user) ? (
          <MoreIconPopup>
            <PopupContent id={id} onOpenDeleteModal={onOpenDeleteModal} />
          </MoreIconPopup>
        ) : null}
      </FlexContainer>
    );
  };

  if (typeof state.prodDetail === 'undefined') {
    return null;
  }
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <ProductDetailWrapper>
        <div className={'left-panel'} data-testid={'left-panel'}>
          <DetailLeftPanel
            product={state.prodDetail}
            onUpdateProduct={onUpdateProduct}
            onSwitchTab={onSwitch}
            selectedTab={state.type}
            isDisabled={!AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
          />
          <SpaceVerticalContainer />
          <AttachmentsWrapper>
            <GeneralAttachments
              entityId={id}
              entityName={'Product'}
              canCreate={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
              canDelete={AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_UPDATE, user)}
            />
          </AttachmentsWrapper>
          <Activities productId={id} />
        </div>
        <div className={'right-panel'} data-testid={'right-panel'}>
          <DetailRightPanel product={state.prodDetail} onUpdateProduct={onUpdateProduct} />
        </div>
      </ProductDetailWrapper>
      <DeleteByRetype
        isOpen={state.isModalOpen}
        onClose={() => setState({ ...state, isModalOpen: false })}
        onConfirm={onDelete}
      />
    </BackendPage>
  );
};

export default Detail;
