import Select from '@atlaskit/select';
import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { iWooCommerceCategory } from '../types/WooCommerceProductType';
import WooCommerceConnectorService from '../../../../services/integration/connectors/WooCommerceConnectorService';
import iChannel from '../../../../types/integration/iChannel';
import { apiErrorToast } from '../../../toast/Toast';
import { iLabelValuePair } from '../../../UITypes/types';

type iWooCommerceProductCategoryPanel = {
  defaultValues?: string[] | number[];
  channel: iChannel;
  testId?: string;
  onSelected: (options: iLabelValuePair[]) => void;
  disabled?: boolean;
};
type iWooCommerceProductCategoryMap = {
  [key: number]: iWooCommerceCategory;
};
const WooCommerceProductCategoryPanel = ({
  channel,
  testId,
  onSelected,
  defaultValues = [],
  disabled = false,
}: iWooCommerceProductCategoryPanel) => {
  const [wooCommerceCategoryMap, setWooCommerceCategoryMap] = useState<iWooCommerceProductCategoryMap>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCanceled = false;

    const getCategories = async (pageNo = 1) => {
      setIsLoading(true);
      try {
        const pageSize = 100;
        const categories = await WooCommerceConnectorService.getProductCategories(channel.id, {
          page: pageNo,
          per_page: pageSize,
        });
        setWooCommerceCategoryMap(prevState => ({
          ...prevState,
          ...categories.reduce((map: iWooCommerceProductCategoryMap, wooCommerceCategory: iWooCommerceCategory) => {
            return {
              ...map,
              [wooCommerceCategory.id]: wooCommerceCategory,
            };
          }, {}),
        }));

        if (isCanceled) return;
        if (categories.length > 0 && categories.length >= pageSize) {
          await getCategories(pageNo + 1);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        if (isCanceled) return;
        setIsLoading(false);
        apiErrorToast(error);
      }
    };

    getCategories();
    return () => {
      isCanceled = true;
    };
  }, [channel]);

  const getOptions = () => {
    const getPath = (wooCommerceCategory: iWooCommerceCategory): string => {
      const parentId = wooCommerceCategory.parent || 0;
      if (parentId === 0 || !(parentId in wooCommerceCategoryMap)) {
        return wooCommerceCategory.name;
      }
      const parentPath = getPath(wooCommerceCategoryMap[parentId]);
      return `${parentPath} / ${wooCommerceCategory.name}`;
    };
    return Object.values(wooCommerceCategoryMap)
      .map(wooCommerceCategory => ({
        label: getPath(wooCommerceCategory),
        value: wooCommerceCategory.id,
      }))
      .sort((opt1, opt2) => (opt1.label > opt2.label ? 1 : -1));
  };

  const getValues = () => {
    if (defaultValues.length === 0) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const valueStrings = defaultValues.map((value: string | number) => `${value}`);
    return getOptions().filter(option => valueStrings.indexOf(`${option.value}`) >= 0);
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Select
      isDisabled={disabled}
      defaultValue={getValues()}
      isMulti
      isSearchable
      onChange={values => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSelected(values || []);
      }}
      options={getOptions()}
      placeholder={'Product categories ...'}
      testId={testId || 'product-categories'}
    />
  );
};

export default WooCommerceProductCategoryPanel;
