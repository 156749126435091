import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BackendPage from '../../layout/BackendPage';
import {
  createProdAttributeSet,
  deleteProdAttributeSet,
  getProdAttributeSets,
  updateProdAttributeSet,
} from '../../services/product/ProductAttributeSetService';

import PageTitleWithAddOne from '../../shared/heading/PageTitleWithAddOne';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizeModal from '../../shared/modal/CustomizeModal';
import SearchBar from '../../shared/search/SearchBar';
import { FlexContainer } from '../../shared/styles/styles';
import { apiErrorToast } from '../../shared/toast/Toast';
import iProductAttributeSet from '../../types/product/iProductAttributeSet';
import ProdAttributeSetEditModal from './ProdAttributeSetEditModal';
import ProdAttributeSetsTable from './ProdAttributeSetsTable';
import { RootState } from '../../redux/makeReduxStore';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_PRODUCT_ATTRIBUTES } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE } from '../../types/settings/UserAccess/iRoleAccess';

const ProdAttributeSets = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const { state, edit, onCloseModal, onDelete, onOpenAddModal, onSearch } = useListCrudHook<iProductAttributeSet>({
    getFn: getProdAttributeSets,
    createFn: createProdAttributeSet,
    updateFn: updateProdAttributeSet,
    deleteFn: deleteProdAttributeSet,
    keywordColumns: 'name',
    sort: 'createdAt:DESC',
  });

  const { control, setValue, errors, handleSubmit } = useForm();
  const history = useHistory();

  const getPageHeader = () => (
    <FlexContainer className={'space-between'}>
      <PageTitleWithAddOne
        title={'Product Attribute Sets'}
        onClickAddBtn={onOpenAddModal}
        isDisabled={!AccessService.hasAccess(ACCESS_CODE_PRODUCT_ATTRIBUTES, ACCESS_CAN_CREATE, user)}
      />
      <div style={{ flexGrow: 0 }}>
        <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search by name...'} />
      </div>
    </FlexContainer>
  );

  const onCreate = async (data: { [key: string]: string }) => {
    try {
      const created = await createProdAttributeSet({
        ...data,
        name: data.name,
        description: data.description,
      });
      history.push(`/b/productAttributeSets/${created.id}`);
    } catch (e) {
      apiErrorToast(e);
    }
  };
  return (
    <BackendPage pageHeader={getPageHeader()}>
      <ProdAttributeSetsTable
        data={state.data}
        onDelete={onDelete}
        isLoading={state.isLoading}
        canDelete={AccessService.hasAccess(ACCESS_CODE_PRODUCT_ATTRIBUTES, ACCESS_CAN_DELETE, user)}
      />
      <CustomizeModal
        isOpen={edit.isModalOpen}
        onConfirm={handleSubmit(onCreate)}
        onCancel={onCloseModal}
        isConfirming={state.isConfirming}
        isDisabled={Object.keys(errors).length > 0}
        modalBody={
          <ProdAttributeSetEditModal control={control} errors={errors} onChange={setValue} target={edit.target} />
        }
        modalHeading={edit.target ? edit.target.name : 'Create Attribute Set'}
        confirmBtnName={'Create'}
        width={edit.target ? 'xlarge' : 'medium'}
        height={edit.target ? '70vh' : '30vh'}
      />
    </BackendPage>
  );
};

export default ProdAttributeSets;
