/* eslint-disable import/no-cycle */
import {
  NCRS_URL,
  PRODUCTS_URL,
  PURCHASES_URL,
  SALES_URL,
  WAREHOUSE_URL,
  CUSTOMER_URL,
  SUPPLIER_URL,
} from '../../UrlMap';
import CreateNCRModalBody from '../../../pages/NCR/list/CreateNCRModalBody';
import CustomerBody from './popupModalBody/CustomerBody';
import ProductBody from './popupModalBody/ProductBody';
import SupplierBody from './popupModalBody/SupplierBody';
import WarehouseBody from './popupModalBody/WarehouseBody';
import POBody from './popupModalBody/POBody';
import SOBody from './popupModalBody/SOBody';
import iUser from '../../../types/user/iUser';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import {
  ACCESS_CODE_CUSTOMERS,
  ACCESS_CODE_PRODUCTS,
  ACCESS_CODE_PURCHASE_ORDERS,
  ACCESS_CODE_SALES_ORDERS,
  ACCESS_CODE_SUPPLIERS,
  ACCESS_CODE_WAREHOUSES,
  ACCESS_CODE_WARRANTY,
} from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE } from '../../../types/settings/UserAccess/iRoleAccess';

export const SALES_ORDER = 'salesOrder';
export const PURCHASE_ORDER = 'purchaseOrder';
export const ACQUISITION_ORDER = 'acquisitionOrder';
//  eslint-disable-next-line
export const WAREHOUSE_lOCATION = 'warehouseLocation';
export const CUSTOMER = 'customer';
export const SUPPLIER = 'supplier';
export const NCR = 'NCR';
export const PRODUCT = 'product';
export const DISPATCH_NOTE = 'dispatchNote';

export const COMPONENT_MAP = {
  [CUSTOMER]: CustomerBody,
  [SUPPLIER]: SupplierBody,
  [PURCHASE_ORDER]: POBody,
  [SALES_ORDER]: SOBody,
  [WAREHOUSE_lOCATION]: WarehouseBody,
  [NCR]: CreateNCRModalBody,
  [PRODUCT]: ProductBody,
};

export const getCreateSubMenu = (user?: iUser) => [
  {
    label: 'Sales Order',
    value: SALES_ORDER,
    prefix: SALES_URL,
    isHidden: !AccessService.hasAccess(ACCESS_CODE_SALES_ORDERS, ACCESS_CAN_CREATE, user),
  },
  {
    label: 'Purchase Order',
    value: PURCHASE_ORDER,
    prefix: PURCHASES_URL,
    isHidden: !AccessService.hasAccess(ACCESS_CODE_PURCHASE_ORDERS, ACCESS_CAN_CREATE, user),
  },
  {
    label: 'Warehouse Location',
    value: WAREHOUSE_lOCATION,
    prefix: WAREHOUSE_URL,
    isHidden: !AccessService.hasAccess(ACCESS_CODE_WAREHOUSES, ACCESS_CAN_CREATE, user),
  },
  {
    label: 'NCR',
    value: NCR,
    prefix: NCRS_URL,
    isHidden: !AccessService.hasAccess(ACCESS_CODE_WARRANTY, ACCESS_CAN_CREATE, user),
  },
  {
    label: 'Product',
    value: PRODUCT,
    prefix: PRODUCTS_URL,
    isHidden: !AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_CREATE, user),
  },
  {
    label: 'Customer',
    value: CUSTOMER,
    prefix: CUSTOMER_URL,
    isHidden: !AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_CREATE, user),
  },
  {
    label: 'Supplier',
    value: SUPPLIER,
    prefix: SUPPLIER_URL,
    isHidden: !AccessService.hasAccess(ACCESS_CODE_SUPPLIERS, ACCESS_CAN_CREATE, user),
  },
];

export const CREATE_NAME = 'Create';
