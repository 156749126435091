/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import React from 'react';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import Spinner from '@atlaskit/spinner';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import iProductImage from '../../types/product/iProductImage';
import Uploader from '../generalAttachments/Uploader';
import { getItemStyle, getListStyle } from '../dndTable/DndTable.style';
import { reorder } from '../dndTable/DndTable.helper';
import { iSortOrder } from '../UITypes/types';

type iProductImageDnDList = {
  productImages: iProductImage[];
  productId: string;
  selectedImage?: iProductImage;
  onSelect?: (image: iProductImage) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCreate?: (data: any) => Promise<void>;
  onReorder?: (reorderedArr: iSortOrder[]) => void;
  isCreating?: boolean;
  canCreate?: boolean;
};

const itemWidth = '3.4rem';
const Wrapper = styled.div`
  position: relative;
  [data-rbd-droppable-id='droppable'] {
    padding: 0px !important;
    overflow-x: auto !important;
    .thumbnail-list {
      display: block;
    }
  }
  [data-rbd-placeholder-context-id] {
    display: none !important;
  }
  .image-item {
    display: inline-block;
    width: ${itemWidth};
    height: 2.1rem;
    padding: 2px;
    margin-right: 4px;
    cursor: pointer;
    border: 2px rgba(255, 255, 255, 0) solid;
    :hover {
      border-color: lightgray;
    }

    &.selected {
      border-color: darkgoldenrod;
    }

    &.create-btn {
      position: absolute;
      right: 0px;
      top: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #6b778c;
      font-size: 9px;
      background-color: #f4f5f7;
      :hover {
        border-color: transparent;
        color: #091e42;
      }
    }

    img {
      object-fit: contain;
      object-position: center center;
      width: 100%;
      height: 100%;
    }
  }
`;
const ProductImageDnDList = ({
  productId,
  productImages,
  selectedImage,
  onSelect,
  onCreate,
  isCreating,
  onReorder,
  canCreate = true,
}: iProductImageDnDList) => {
  const handleSelectImage = (productImage: iProductImage) => {
    if (!onSelect) {
      return;
    }
    onSelect(productImage);
  };

  const getCreateBtn = () => {
    if (!onCreate || !canCreate) {
      return null;
    }
    if (isCreating === true) {
      return (
        <div className={'image-item create-btn loading'}>
          <Spinner />
        </div>
      );
    }
    return (
      <Uploader
        onUploadFile={onCreate}
        extraData={() => ({ productId })}
        className={'image-item create-btn'}
        acceptTypes={['image/*']}
        multiple
      >
        <CameraFilledIcon label={'upload'} testId={'upload-trigger'} />
        <div>Upload</div>
      </Uploader>
    );
  };

  const onReRange = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination || !onReorder) {
      return;
    }

    const reorderedItems = reorder(productImages, result.source.index, result.destination.index);
    const sortOrderToUpdate = reorderedItems.map((item, index) => ({ id: item.id, sortOrder: index }));
    onReorder(sortOrderToUpdate);
  };

  const getDraggableContent = (productImage: iProductImage) => (
    providedDrop: DraggableProvided,
    snapshotDrop: DraggableStateSnapshot,
  ) => {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        className={`image-item ${selectedImage?.id === productImage.id ? 'selected' : ''}`}
        onClick={() => handleSelectImage(productImage)}
        ref={providedDrop.innerRef}
        {...providedDrop.draggableProps}
        {...providedDrop.dragHandleProps}
        style={{
          ...getItemStyle(snapshotDrop.isDragging, providedDrop.draggableProps.style),
        }}
      >
        <img src={productImage.url} alt={'error'} />
      </div>
    );
  };

  const getDroppableContent = (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
        style={{
          ...getListStyle(snapshot.isDraggingOver),
        }}
      >
        <div
          className={'thumbnail-list'}
          style={{ width: `calc(${itemWidth} * ${productImages.length + 1} + 10rem )` }}
        >
          {productImages
            .sort((img1, img2) => (img1.sortOrder > img2.sortOrder ? 1 : -1))
            .map((productImage, index) => {
              return (
                <Draggable key={productImage.id} draggableId={productImage.id} index={index}>
                  {getDraggableContent(productImage)}
                </Draggable>
              );
            })}
        </div>
        {provided.placeholder}
      </div>
    );
  };

  const getList = () => {
    return (
      <DragDropContext onDragEnd={onReRange}>
        <Droppable droppableId="droppable" direction="horizontal">
          {getDroppableContent}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <Wrapper className={'product-image-dnd-list'}>
      {getList()} {getCreateBtn()}
    </Wrapper>
  );
};

export default ProductImageDnDList;
