import React, { useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import iContactCompanyProduct from '../../../../../types/contactCompany/iContactCompanyProduct';
import CustomizeModal from '../../../../modal/CustomizeModal';
import { CREATE_PO } from '../ServiceProvider.constants';
import CCPCreateOrderModalBody from '../../../../CCPProductContactCompany/CCPCreateOrder/CCPCreateOrderModalBody';
import { addToastForAPIResponse, apiErrorToast } from '../../../../toast/Toast';
import { createPurchaseOrder } from '../../../../../services/PurchaseService';
import { createPOItemConfig } from '../../../../../services/PurchaseOrderItemService';
import { PURCHASES_URL } from '../../../../UrlMap';
import { CreateModalBodyWrapper } from '../ServiceProvider.style';
import { NUMBER_ROUND_DECIMAL } from '../../../../constants/ActionConstants';

const CreatePOServiceProvider = ({
  jobId,
  ccp,
  onCancel,
}: {
  jobId: string;
  ccp: iContactCompanyProduct;
  onCancel: () => void;
}) => {
  const history = useHistory();
  const [isConfirming, setIsConfirming] = useState(false);
  const { control, setValue, errors, handleSubmit } = useForm({});
  //  eslint-disable-next-line
  const onSubmit = async (data: any) => {
    setIsConfirming(true);
    try {
      const { id } = await createPurchaseOrder(data);
      await createPOItemConfig({
        orderId: id,
        productId: ccp.productId,
        // double insure the qty is rounded
        qty: _.round(data.qty, NUMBER_ROUND_DECIMAL),
        jobId,
      });
      addToastForAPIResponse('success');
      setIsConfirming(false);
      history.push(`${PURCHASES_URL}/${id}`);
    } catch (e) {
      apiErrorToast(e);
      setIsConfirming(false);
    }
  };
  return (
    <CustomizeModal
      isOpen
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onCancel}
      confirmBtnName={'Create'}
      modalBody={
        <CreateModalBodyWrapper>
          <CCPCreateOrderModalBody
            control={control}
            errors={errors}
            onChange={setValue}
            isCustomer={false}
            isServiceProvider
            ccp={ccp}
          />
        </CreateModalBodyWrapper>
      }
      modalHeading={<span>{CREATE_PO}</span>}
      isConfirming={isConfirming}
      isDisabled={Object.keys(errors).length > 0}
    />
  );
};

export default CreatePOServiceProvider;
