import React from 'react';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';

import { getDeleteBtn } from '../../components/settings/utils';
import { handleNullException } from '../../services/UtilsService';
import LinkBtn from '../../shared/buttons/LinkBtn';
import CreatedOrUpdated from '../../shared/createdOrUpdated/CreatedOrUpdated';
import iJobAttribute from '../../types/job/iJobAttribute';
import { iConfigColumn } from '../despatchNote/shared/DispatchNote.type';

export const getRows = (
  data: Array<iJobAttribute>,
  onEdit: (id: string) => void,
  onDelete: (id: string) => void,
  columns: Array<iConfigColumn>,
) => {
  return data.map((attr: iJobAttribute) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'modalLink':
          return {
            key: handleNullException(attr, key),
            content: (
              <LinkBtn
                btnName={handleNullException(attr, key)}
                onClick={() => onEdit(handleNullException(attr, dataSource[0]))}
                testId={`jobAttribute-list-linkBtn-${handleNullException(
                  attr,
                  dataSource[0],
                )}`}
              />
            ),
          };
        case 'text':
          return {
            key: handleNullException(attr, key),
            content: handleNullException(attr, key),
          };
        case 'sortOrder':
          return {
            type: 'sortOrder',
            key: handleNullException(attr, key),
            content: handleNullException(attr, key),
          };
        case 'history':
          return {
            key: handleNullException(attr, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(attr, key)}
                operator={handleNullException(attr, dataSource[0])}
              />
            ),
          };
        case 'operation':
          return {
            key: `${attr.name}-operation`,
            content: getDeleteBtn({
              id: attr.id,
              answer: attr.name || 'Attribute Name n/a',
              onClick: onDelete,
            }),
          };
        case 'icon':
          return {
            key: `${attr.name}-icon`,
            content: <DragHandlerIcon label={'dragHandlerIcon'} />,
          };
        default:
          return { content: '' };
      }
    });
    return {
      cells,
      testId: 'admin-job-attribute-row',
      key: attr.id,
    };
  });
};
