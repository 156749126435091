import React from 'react';
import Button from '@atlaskit/button';

import moment from 'moment';
import DynamicTable from '@atlaskit/dynamic-table';
import styled from 'styled-components';
import iShift from '../../../types/iShift';
import {
  // getCreatedOrUpdatedAt,
  getDeleteBtn,
  getHeads,
} from '../../../components/settings/utils';
import { handleNullException } from '../../../services/UtilsService';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';

const TableContainer = styled.div`
  td:nth-child(n + 2):nth-child(-n + 5) {
    width: 15%;
    min-width: 10rem;
  }

  td:last-child {
    max-width: 2rem;
  }
`;

const COLUMNS = [
  'Name',
  'Start Time',
  'End Time',
  'Created',
  'Updated',
  'Operation',
];

const ShiftListTable = ({
  shifts,
  onDelete,
  onEdit,
  isLoading,
}: {
  shifts: Array<iShift>;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  isLoading: boolean;
}) => {
  const getNameContent = (shift: iShift) => (
    <Button
      appearance={'link'}
      spacing={'none'}
      onClick={() => onEdit(shift.id)}
    >
      {shift.name}
    </Button>
  );

  const getRows = () => {
    return shifts
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iShift) => ({
        cells: [
          { key: item.name, content: getNameContent(item) },
          { content: item.startTime.slice(0, -3) },
          { content: item.endTime.slice(0, -3) },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'createdAt')}
                operator={handleNullException(item, 'createdBy.firstName')}
              />
            ),
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'updatedAt')}
                operator={handleNullException(item, 'updatedBy.firstName')}
              />
            ),
          },
          {
            content: getDeleteBtn({
              id: item.id,
              answer: item.name || 'name n/a',
              onClick: onDelete,
            }),
          },
        ],
      }));
  };

  return (
    <TableContainer>
      <DynamicTable
        head={getHeads(COLUMNS, 'settings-shifts')}
        rows={getRows()}
        testId={'settings-shift-list-table'}
        isLoading={isLoading}
      />
    </TableContainer>
  );
};

export default ShiftListTable;
