import { Content, Main, PageLayout, TopNavigation } from '@atlaskit/page-layout';
import { AtlassianNavigation, CustomProductHome } from '@atlaskit/atlassian-navigation';
import React from 'react';
import styled from 'styled-components';
import iEntityStatusType from '../../../types/status/iEntityStatusType';

const Wrapper = styled.div`
  a[class$='-CustomProductHome'][href='#'] {
    display: none;
  }
`;

type iWorkflowEditLayout = {
  entityStatusType: iEntityStatusType;
  rightSideBar: React.ReactElement;
  createButtons: React.ReactElement;
  children: React.ReactElement;
  updateBtns: React.ReactElement;
  errorDisplay: React.ReactElement;
};
const WorkflowEditLayout = ({
  entityStatusType,
  updateBtns,
  children,
  errorDisplay,
  rightSideBar,
  createButtons,
}: iWorkflowEditLayout) => {
  const getHome = () => {
    return (
      <CustomProductHome
        href="#"
        siteTitle={`Editing workflow for ${entityStatusType.entityName}`}
        iconAlt=""
        iconUrl={''}
        logoAlt=""
        logoUrl={''}
        logoMaxWidth={0}
      />
    );
  };

  return (
    <Wrapper>
      <PageLayout>
        <TopNavigation>
          <AtlassianNavigation
            label="Workflow Editor"
            primaryItems={[]}
            renderProductHome={getHome}
            renderNotifications={() => errorDisplay}
            renderProfile={() => updateBtns}
            renderCreate={() => createButtons}
          />
        </TopNavigation>
        <Content>
          <Main>{children}</Main>
          {rightSideBar}
        </Content>
      </PageLayout>
    </Wrapper>
  );
};

export default WorkflowEditLayout;
