import React from 'react';
import styled from 'styled-components';

const CompanyLogoWrapper = styled.div`
  display: flex;
  margin: 0 auto 20px;
  align-items: center;
  justify-content: center;
`;
const CompanyImageWrapper = styled.img`
  object-fit: cover;
  display: block;
  max-width: 100%;
`;
const CompanyLogo = ({
  src = process.env.REACT_APP_COMPANY_LOGO,
  alt = process.env.REACT_APP_APP_NAME,
}: {
  src?: string;
  alt?: string;
}) => {
  return (
    <CompanyLogoWrapper data-testid={'company-logo'}>
      <CompanyImageWrapper src={src} alt={alt} />
    </CompanyLogoWrapper>
  );
};
export default CompanyLogo;
