import { DatePicker } from '@atlaskit/datetime-picker';
import { Label } from '@atlaskit/field-base';
import { Grid, GridColumn } from '@atlaskit/page';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import AddressService from '../../../services/AddressService';
import { getCompanyAddressList } from '../../../services/ContactCompanyAddressService';
import { getSalesOrderDetail, updateSalesOrder } from '../../../services/SalesOrderService';
import { assembleAddress, handleNullException } from '../../../services/UtilsService';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import InlineEdit from '../../../shared/form/InlineEdit';
import TextArea from '../../../shared/form/TextArea';
import UpdateAddressPopup from '../../../shared/popup/UpdateAddressPopup';
import { AddressPlaceholderWrapper, LabelValue, FlexContainer } from '../../../shared/styles/styles';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/Toast';
import { CUSTOMER_URL } from '../../../shared/UrlMap';
import iContactCompanyAddress from '../../../types/contactCompany/iContactCompanyAddress';
import { iAddressContent } from '../../../types/iAddressContent';
import iChannel from '../../../types/integration/iChannel';
import iSalesOrderDetail from '../../../types/sales/iSalesOrder';
import MyobEntitySyncBtn from '../../../shared/channel/myob/MyobEntitySyncBtn';
import IntegrationLinkEntityWithAccountingChannels from '../../settings/integrations/IntegrationLinkEntityWithAccountingChannels';
import MyobConnectorService from '../../../services/integration/connectors/MyobConnectorService';

import WooCommerceSalesOrderDetails from '../../../shared/channel/wooCommerce/salesOrder/WooCommerceSalesOrderDetails';
import { SO_TYPE_NAME_INVOICE } from './constants';
import { RootState } from '../../../redux/makeReduxStore';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_CUSTOMERS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';

const InlineEditContainer = styled.div`
  max-width: 30rem;
  &:first-child {
    margin-top: 1rem;
  }
  &:last-child {
    margin-top: 1rem;
  }
`;
const DatePickerContainer = styled.div`
  width: 90%;
  margin-top: -0.2rem;
`;

const SODetailEdit = ({
  id,
  order,
  checkIsIntegrated,
  isDisabled,
}: {
  id: string;
  order?: iSalesOrderDetail;
  isDisabled?: boolean;
  checkIsIntegrated: (channels: iChannel[]) => void;
}) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadCustomers = AccessService.hasAccess(ACCESS_CODE_CUSTOMERS, ACCESS_CAN_READ, user);

  const [localOrderDetail, setLocalOrderDetail] = useState<iSalesOrderDetail>();
  const customer = order?.customer;
  const contactCompanyId = customer?.id;

  const [localCompanyAddressList, setLocalCompanyAddressList] = useState([]);

  useEffect(() => {
    if (!contactCompanyId) {
      return;
    }
    const getData = async () => {
      try {
        const orderDetail = await getSalesOrderDetail(id);
        const { data } = await getCompanyAddressList({
          filter: `contactCompanyId:${contactCompanyId}`,
        });
        setLocalOrderDetail(orderDetail);
        setLocalCompanyAddressList(data);
      } catch (e) {
        apiErrorToast(e);
      }
    };

    getData();
  }, [contactCompanyId, id]);

  const getOptions = () => {
    if (localCompanyAddressList.length === 0) return [];
    return localCompanyAddressList.map((item: iContactCompanyAddress) => ({
      label: item.id,
      value: item,
    }));
  };

  const onRefresh = async () => {
    try {
      const salesOrderData = await getSalesOrderDetail(id);
      setLocalOrderDetail(salesOrderData);
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const onUpdateAddress = async (addressType: string, updateContent: iAddressContent) => {
    if (!customer) return;
    try {
      const addressId =
        addressType === 'shippingAddress'
          ? handleNullException(localOrderDetail, 'shippingAddress.id')
          : handleNullException(localOrderDetail, 'billingAddress.id');

      const isSameId =
        handleNullException(localOrderDetail, 'shippingAddress.id') ===
        handleNullException(localOrderDetail, 'billingAddress.id');

      //  eslint-disable-next-line
      const updateAddressResponse = addressId
        ? isSameId
          ? await AddressService.createAddress(updateContent)
          : await AddressService.editAddress(addressId, updateContent)
        : await AddressService.createAddress(updateContent);

      const updated = await updateSalesOrder(id, {
        [`${addressType}Id`]: updateAddressResponse.id,
        [`${addressType}`]: updateAddressResponse,
      });

      setLocalOrderDetail({ ...updated });

      addToastForAPIResponse('success');
      onRefresh();
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const onConfirm = async (name: string, newValue: string) => {
    const nullable = newValue || null;
    try {
      await updateSalesOrder(name.split(':')[0], {
        [`${name.split(':')[1]}`]: nullable,
      });
      addToastForAPIResponse('success');
      onRefresh();
    } catch (error) {
      apiErrorToast(error);
    }
  };

  return (
    <>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="Customer" />
          <LabelValue>
            {canReadCustomers ? (
              <LinkBtnUrl
                url={`${CUSTOMER_URL}/${handleNullException(localOrderDetail, 'customerId')}`}
                btnName={customer?.name || ''}
              />
            ) : (
              `${customer?.name || ''}`
            )}
          </LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Email" />
          <LabelValue>{customer?.email}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Phone" />
          <LabelValue>{customer?.phone}</LabelValue>
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEditContainer className="customer-order-ref">
            <InlineEdit
              name={`${id}:customerRef`}
              defaultValue={`${handleNullException(localOrderDetail, 'customerRef')}`}
              label={'Customer Order Reference'}
              onConfirm={onConfirm}
              isDisabled={isDisabled}
            />
          </InlineEditContainer>
        </GridColumn>
      </Grid>

      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <Label label="Shipping Address" />
          <UpdateAddressPopup
            address={localOrderDetail?.shippingAddress}
            heading={'Shipping Address'}
            onClick={(data: iAddressContent) => onUpdateAddress('shippingAddress', data)}
            needAddressSelection
            options={getOptions()}
            isDisabled={isDisabled}
            // contactCompanyId={customer?.id}
          >
            <LabelValue>
              {assembleAddress(localOrderDetail?.shippingAddress) || (
                <AddressPlaceholderWrapper>Click to enter value</AddressPlaceholderWrapper>
              )}
            </LabelValue>
          </UpdateAddressPopup>
        </GridColumn>
        <GridColumn medium={3}>
          <Label label="Billing Address" />
          <UpdateAddressPopup
            address={localOrderDetail?.billingAddress}
            heading={'Billing Address'}
            onClick={(data: iAddressContent) => onUpdateAddress('billingAddress', data)}
            isDisabled={isDisabled}
          >
            <LabelValue>
              {assembleAddress(localOrderDetail?.billingAddress) || (
                <AddressPlaceholderWrapper>Click to enter value</AddressPlaceholderWrapper>
              )}
            </LabelValue>
          </UpdateAddressPopup>
        </GridColumn>

        <GridColumn medium={3}>
          {handleNullException(localOrderDetail, 'type.name').toUpperCase() === SO_TYPE_NAME_INVOICE ? (
            <DatePickerContainer className="so-invoiceDate">
              <Label htmlFor="SO-invoiceDate-datepicker" label={'Invoice Date'} />
              <DatePicker
                id={'SO-invoiceDate-datepicker'}
                onChange={(value: string) => onConfirm(`${id}:invoiceDate`, value)}
                dateFormat="DD MMM YYYY"
                defaultValue={handleNullException(order, 'invoiceDate')}
                value={handleNullException(localOrderDetail, 'invoiceDate')}
                placeholder={' '}
                testId={'SO-invoiceDate-datepicker'}
                isDisabled={isDisabled}
              />
            </DatePickerContainer>
          ) : (
            <DatePickerContainer className="so-orderDate">
              <Label htmlFor="SO-orderDate-datepicker" label={'Order Date'} />
              <DatePicker
                id={'SO-orderDate-datepicker'}
                onChange={(value: string) => onConfirm(`${id}:orderDate`, value)}
                dateFormat="DD MMM YYYY"
                defaultValue={handleNullException(order, 'orderDate')}
                value={handleNullException(localOrderDetail, 'orderDate')}
                placeholder={' '}
                testId={'SO-orderDate-datepicker'}
                isDisabled={isDisabled}
              />
            </DatePickerContainer>
          )}
        </GridColumn>
        <GridColumn medium={3}>
          <DatePickerContainer className="so-edd">
            <Label htmlFor="SO-edd-datepicker" label={'Expected Delivery Date'} />
            <DatePicker
              id={'SO-edd-datepicker'}
              onChange={(value: string) => onConfirm(`${id}:edd`, value)}
              dateFormat="DD MMM YYYY"
              defaultValue={handleNullException(order, 'edd')}
              value={handleNullException(localOrderDetail, 'edd')}
              placeholder={' '}
              testId={'SO-edd-datepicker'}
              isDisabled={isDisabled}
            />
          </DatePickerContainer>
        </GridColumn>
      </Grid>
      <FlexContainer className={'even-split with-gap'}>
        <InlineEditContainer>
          <TextArea
            name={`${id}:notes`}
            label="Notes"
            defaultValue={handleNullException(localOrderDetail, 'notes')}
            onChange={onConfirm}
            minimumRows={4}
            debouncePeriod={500}
            isDisabled={isDisabled}
          />
        </InlineEditContainer>
        {order && <WooCommerceSalesOrderDetails salesOrder={order} />}
      </FlexContainer>
      {order && handleNullException(localOrderDetail, 'type.name').toUpperCase() === SO_TYPE_NAME_INVOICE && (
        <Grid layout={'fluid'} spacing={'compact'}>
          <GridColumn medium={6}>
            <IntegrationLinkEntityWithAccountingChannels
              getChannelEntitySelector={channelId => (
                <MyobEntitySyncBtn
                  key={channelId}
                  channelId={channelId}
                  entityId={order.id}
                  entityName="SalesOrder"
                  promiseFnForCreation={MyobConnectorService.createMyobInvoice}
                  promiseFnForUpdate={MyobConnectorService.updateMyobInvoice}
                  labelAttributePath="Number"
                  isDisabled={isDisabled}
                />
              )}
              checkIsIntegrated={checkIsIntegrated}
            />
          </GridColumn>
        </Grid>
      )}
    </>
  );
};

export default SODetailEdit;
