/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { AsyncSelect } from '@atlaskit/select';
import _ from 'lodash';

import { mapLabelValuePairObject, mapLabelValuePairProduct } from '../../pages/sales/utilities';
import { iActionTypes } from '../../types/iActionTypes';

type iOption = {
  label?: string | JSX.Element;
  value?: string;
};

interface iProps {
  id?: string;
  onSelect?: (payload: any, actionMeta: iActionTypes) => void;
  promiseFn: (keyword: string) => Promise<any>;
  optionLabel: string[];
  searchBarPlaceholder?: string;
  filterFn?: (item: any) => boolean;
  shouldControlRenderValue?: boolean;
  idPayload?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  className?: string;
  isDisabled?: boolean;
  value?: any;
  clearAfterSelect?: boolean;
  defaultOptions?: boolean;
}

const AsyncSearch = ({
  id,
  onSelect,
  promiseFn,
  optionLabel,
  searchBarPlaceholder,
  filterFn,
  className,
  shouldControlRenderValue = false,
  idPayload = true,
  isClearable = true,
  isMulti = false,
  isDisabled = false,
  value = null,
  clearAfterSelect = true,
  defaultOptions = false,
}: iProps) => {
  // const [value] = useState(defaultValue);

  const promiseOptions = (inputValue: string, callback: (options: iOption[]) => void) => {
    promiseFn(inputValue).then(({ data: items }) => {
      let filtered = items;
      if (filterFn) {
        filtered = items.filter(filterFn);
      }
      const mappedOptions = idPayload
        ? mapLabelValuePairProduct(filtered, optionLabel)
        : mapLabelValuePairObject(filtered, optionLabel);
      if (mappedOptions) {
        callback(mappedOptions);
      }
    });
  };

  const debouncedOptions = _.debounce(promiseOptions, 700);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (newSelectInput: any, actionMeta: iActionTypes) => {
    if (typeof onSelect === 'function') {
      if (isMulti === true) {
        onSelect(newSelectInput, actionMeta);
      } else {
        onSelect(newSelectInput?.value, actionMeta);
      }
    }
  };

  return (
    <>
      <AsyncSelect
        defaultOptions={defaultOptions}
        isDisabled={isDisabled}
        className={className}
        id={id}
        isClearable={isClearable}
        blurInputOnSelect
        value={clearAfterSelect === true ? null : value}
        loadOptions={debouncedOptions}
        placeholder={searchBarPlaceholder || 'Search...'}
        onChange={onChange}
        controlShouldRenderValue={shouldControlRenderValue}
        isMulti={isMulti}
        autoFocus
      />
    </>
  );
};

export default AsyncSearch;
