import React from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import { iLabelValuePair } from '../UITypes/types';
import { statusSelectorStyle } from '../styles/styles';

const TypeFilter = ({
  options,
  selectedTypes,
  onSelect,
  testIdPrefix = 'type-selector',
}: {
  options: Array<iLabelValuePair>;
  selectedTypes: Array<string>;
  onSelect: (select: string) => void;
  testIdPrefix?: string;
}) => {
  return (
    <ButtonGroup>
      {options.map((type: iLabelValuePair) => {
        return (
          <Button
            key={type.label}
            isSelected={selectedTypes.includes(type.value)}
            onClick={() => onSelect(type.value)}
            testId={`${testIdPrefix}-${type.label}`}
            shouldFitContainer
            style={statusSelectorStyle}
          >
            {type.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default TypeFilter;
