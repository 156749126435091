import AppService from './AppService';

export const getShifts = (config?: { [key: string]: string }) => {
  return AppService.get('/shift', {
    include: 'createdBy,updatedBy',
    ...config,
  }).then(({ data }) => data);
};

export const createShift = (postData: { [key: string]: string }) => {
  return AppService.post('/shift', postData).then(({ data }) => data);
};

export const deleteShift = (id: string) => {
  return AppService.delete(`/shift/${id}`).then(({ data }) => data);
};

export const updateShift = (
  id: string,
  updateContent: { [key: string]: string },
) => {
  return AppService.put(`/shift/${id}`, updateContent).then(({ data }) => data);
};

export default { getShifts, createShift, deleteShift, updateShift };
