import React from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import HipchatDialOutIcon from '@atlaskit/icon/glyph/hipchat/dial-out';
import EmailIcon from '@atlaskit/icon/glyph/email';
import Tooltip from '@atlaskit/tooltip';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import TransparentIconBtn from '../../../shared/buttons/TransparentIconBtn';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import iContactCompany from '../../../types/contactCompany/iContactCompany';
import iContactCompanyContact from '../../../types/contactCompany/iContactCompanyContact';
import { iConfigColumn } from '../../../shared/UITypes/types';
import {
  assembleAddress,
  formatDate,
  handleNullException,
} from '../../../services/UtilsService';

/**
 * dataSource[0]=>condition to find item from array
 * dataSource[1]=>found item's attribute to display
 */
const getFlexColumnContent = (
  item: iContactCompany,
  targetRoleId: string,
  dataSource: Array<string>,
) => {
  const contactCompanyContacts: Array<iContactCompanyContact> = handleNullException(
    item,
    dataSource[0],
  );
  if (!contactCompanyContacts || contactCompanyContacts.length === 0) {
    return '';
  }
  const contactCompanyContact = contactCompanyContacts.find(
    (itemC: iContactCompanyContact) => itemC.role?.id === targetRoleId,
  );
  return handleNullException(contactCompanyContact, dataSource[1]);
};

export const getRows = ({
  data,
  onOpenDeleteModal,
  columns,
}: {
  data: Array<iContactCompany>;
  onOpenDeleteModal: (id: string) => void;
  columns: Array<iConfigColumn>;
}) => {
  return data.map((item: iContactCompany) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'address':
          return {
            key: handleNullException(item, key),
            content: assembleAddress(handleNullException(item, key)),
          };
        case 'phone':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key) && (
              <Tooltip content={handleNullException(item, key)}>
                <a href={`tel:${handleNullException(item, key)}`}>
                  <HipchatDialOutIcon label={handleNullException(item, key)} />
                </a>
              </Tooltip>
            ),
          };
        case 'email':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key) && (
              <Tooltip content={handleNullException(item, key)}>
                <a href={`mailto:${handleNullException(item, key)}`}>
                  <EmailIcon label={handleNullException(item, key)} />
                </a>
              </Tooltip>
            ),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(
                  item,
                  dataSource[0],
                )}`}
              />
            ),
          };
        case 'flexColumn':
          return {
            key: handleNullException(item, key),
            content: getFlexColumnContent(item, key, dataSource),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        case 'delete':
          return {
            content: (
              <TransparentIconBtn
                onClick={() => onOpenDeleteModal(item.id)}
                icon={<TrashIcon label="delete" />}
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'contactCompany-table' };
  });
};

export const getReportArrSegments = (
  data: iContactCompany[],
  selectedColumns: iConfigColumn[],
) => {
  const selectedColumnNames = selectedColumns
    .map((column: iConfigColumn) => column.name)
    .filter((columnName: string) => columnName !== '');

  const reportCells = data.map((item: iContactCompany) => {
    return selectedColumns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return handleNullException(item, key);
        case 'link':
          return handleNullException(item, key);
        case 'address':
          return assembleAddress(handleNullException(item, key));
        case 'email':
          return handleNullException(item, key);
        case 'phone':
          return handleNullException(item, key);
        case 'history':
          return `${handleNullException(item, dataSource[0])} @ ${formatDate(
            handleNullException(item, key),
            'DD MMM YYYY',
          )}`;
        case 'flexColumn':
          return getFlexColumnContent(item, key, dataSource);
        default:
          return '';
      }
    });
  });

  return [selectedColumnNames, ...reportCells];
};
