import { useState, useEffect } from 'react';

type iInitialValue = {
  default: Array<string>;
  whole: Array<string>;
};

const getStoredColumns = (key: string, initialValue: iInitialValue) => {
  const savedValues = localStorage.getItem(key);
  if (savedValues) return JSON.parse(savedValues);
  return initialValue.default;
};
const useColumnHooks = (key: string, initialValue: iInitialValue) => {
  const [value, setValue] = useState(() => getStoredColumns(key, initialValue));

  const onToggleColumn = (column: string, isSelected: boolean) => {
    const newColumns = isSelected
      ? [...value, column]
      : [...value.filter((i: string) => i !== column)];

    const sorted: Array<string> = [];
    initialValue.whole.forEach((cColumn: string) => {
      if (newColumns.includes(cColumn)) {
        sorted.push(cColumn);
      }
    });
    setValue(sorted);
  };

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, onToggleColumn];
};
export default useColumnHooks;
