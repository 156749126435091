import styled from 'styled-components';

const MyobUserSelectContainer = styled.div`
  .logo {
    width: 100px;
    background: white;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

export default MyobUserSelectContainer;
