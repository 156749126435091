import React from 'react';
import { useForm } from 'react-hook-form';
import InvestigationItem from './InvestigationItem';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizeModal from '../../../../shared/modal/CustomizeModal';
import LoadMore from '../../../../shared/pagination/loadMore/LoadMore';
import iIssueInvestigation from '../../../../types/NCR/iIssueInvestigation';
import CreateBody from './CreateBody';
import CreateInvestigation from './CreateInvestigation';
import { Wrapper } from './Investigation.style';
import {
  createNCRIssueInvestigation,
  deactivateNCRIssueInvestigation,
  getNCRIssueInvestigationList,
  updateNCRIssueInvestigation,
} from '../../../../services/NCRIssueInvestigationService';

type iInvestigation = { issueId: string; canCreate?: boolean; canUpdate?: boolean; canDelete?: boolean };
const Investigation = ({ issueId, canCreate = true, canUpdate = true, canDelete = true }: iInvestigation) => {
  const {
    state,
    edit,
    onCloseModal,
    onDelete,
    onOpenAddModal,
    onOpenDeleteModal,
    onSubmit,
    onSetCurrentPage,
  } = useListCrudHook<iIssueInvestigation>({
    getFn: getNCRIssueInvestigationList,
    createFn: createNCRIssueInvestigation,
    updateFn: updateNCRIssueInvestigation,
    deleteFn: deactivateNCRIssueInvestigation,
    paginationApplied: true,
    sort: 'updatedAt:desc',
    filter: `issueId:${issueId}`,
  });
  const { control, setValue, errors, handleSubmit } = useForm();
  const onLoadMore = () => {
    const currentPage = state.currentPage ? state.currentPage : 1;
    onSetCurrentPage(currentPage + 1);
  };
  //    eslint-disable-next-line
  const onCreateMiddleware = (data: any) => {
    onSubmit({ ...data, issueId });
  };
  const isNotEnd = () => {
    if (typeof state.to === 'undefined' || typeof state.total === 'undefined') {
      return false;
    }
    return state.total > state.to;
  };
  return (
    <Wrapper>
      {canCreate && <CreateInvestigation onOpenAddModal={onOpenAddModal} />}
      {state.data.map((investigation: iIssueInvestigation) => (
        <InvestigationItem
          key={investigation.id}
          investigation={investigation}
          onOpenDelete={onOpenDeleteModal}
          onEdit={onSubmit}
          canDelete={canDelete}
          canUpdate={canUpdate}
        />
      ))}
      {isNotEnd() && <LoadMore isLoading={state.isLoading} onClick={onLoadMore} />}
      {edit.delTargetId && (
        <CustomizeModal
          isOpen={edit.isModalOpen}
          isConfirming={state.isConfirming}
          onConfirm={() => edit.delTargetId && onDelete(edit.delTargetId)}
          onCancel={onCloseModal}
          confirmBtnName={'Delete'}
          confirmBtnAppearance={'danger'}
          modalHeading={<span>Are you sure to delete it?</span>}
          modalBody={<p>Please confirm you are about to delete it.</p>}
          testIdPrefix={'investigation-delete'}
        />
      )}
      <CustomizeModal
        isOpen={edit.isModalOpen && !edit.delTargetId}
        isConfirming={state.isConfirming}
        onConfirm={handleSubmit(onCreateMiddleware)}
        onCancel={onCloseModal}
        modalHeading={'Create an investigation'}
        modalBody={<CreateBody control={control} onChange={setValue} errors={errors} />}
      />
    </Wrapper>
  );
};

export default Investigation;
