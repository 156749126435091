import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import iChannel from '../../../../types/integration/iChannel';
import { FlexContainer } from '../../../styles/styles';
import ChannelService from '../../../../services/integration/ChannelService';
import { addToastForAPIResponse, apiErrorToast } from '../../../toast/Toast';
import AsyncSearch from '../../../asyncSearch/AsyncSearch';
import { CHANNEL_SCRIPT_SCRIPT_MYOB } from '../../../../types/integration/iChannelScript';
import MyobConnectorService from '../../../../services/integration/connectors/MyobConnectorService';

type iWooCommerceDefaultGuestCustomerMyobSelector = {
  channel: iChannel;
  onSaved: (updatedChannel: iChannel) => void;
};

const Wrapper = styled.div`
  .myob-search {
    width: 100%;
  }
`;
const WooCommerceDefaultGuestCustomerMyobSelector = ({
  channel,
  onSaved,
}: iWooCommerceDefaultGuestCustomerMyobSelector) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [myobChannel, setMyobChannel] = useState<iChannel | null>(null);
  const [channelSettingChannel, setchannelSettingChannel] = useState<iChannel | null>(null);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    ChannelService.getChannels({
      filter: `script.script:${CHANNEL_SCRIPT_SCRIPT_MYOB}`,
      include: 'script',
    })
      .then(resp => {
        if (isCanceled) return;
        setMyobChannel(resp[0] && resp[0].isOnBoarded === true ? resp[0] : null);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [channel]);

  useEffect(() => {
    const channelId = `${channel.settings.defaultGuestChannelInfo?.channelId || ''}`.trim();
    if (channelId === '') {
      return undefined;
    }
    let isCanceled = false;
    setIsLoading(true);
    ChannelService.getChannel(channelId)
      .then(resp => {
        if (isCanceled) return;
        setchannelSettingChannel(resp);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [channel]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelect = (externalObj: any) => {
    if (!myobChannel) return;
    setIsSaving(true);
    ChannelService.updateChannel(channel.id, {
      ...channel,
      settings: {
        ...channel.settings,
        defaultGuestChannelInfo: { channelId: myobChannel.id, externalObj },
      },
    })
      .then(resp => {
        addToastForAPIResponse('success', 'Default Guest MYOB Acc. updated.');
        if (onSaved) {
          onSaved(resp);
        }
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getCurrentValue = () => {
    if (!channelSettingChannel) {
      return undefined;
    }
    const label = `${channel.settings.defaultGuestChannelInfo?.externalObj.DisplayID || ''}`.trim();
    const value = `${channel.settings.defaultGuestChannelInfo?.externalObj.UID || ''}`.trim();
    if (label === '' || value === '') {
      return undefined;
    }
    return {
      label,
      value,
    };
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!myobChannel) {
    return null;
  }

  return (
    <Wrapper>
      <div className={'title-wrapper'}>
        <p>
          <small>
            All order created by GUEST from [{channel.name}] will be created and link to the {myobChannel.name} [MYOB]
            Customer.
          </small>
        </p>
      </div>
      <FlexContainer className={'space-below'}>
        <AsyncSearch
          isClearable
          idPayload={false}
          clearAfterSelect={false}
          shouldControlRenderValue
          isDisabled={isSaving}
          className={'myob-search'}
          onSelect={value => onSelect(value)}
          value={getCurrentValue()}
          promiseFn={async (keyword: string) => MyobConnectorService.getMyobCustomers(myobChannel.id, keyword)}
          optionLabel={['DisplayID']}
          searchBarPlaceholder={'Search ..., case sensitive'}
        />
      </FlexContainer>
    </Wrapper>
  );
};

export default WooCommerceDefaultGuestCustomerMyobSelector;
