import React from 'react';
import { useSelector } from 'react-redux';
import ContactCompanyList from './list/ContactCompanyList';
import { getServiceProviderList } from '../../services/ContactCompanyService';
import { SERVICE_PROVIDER_URL } from '../../shared/UrlMap';
import {
  CUSTOMER_COLUMNS,
  CUSTOMER_INSERT_INDEX,
  SERVICE_PROVIDER,
  SERVICE_PROVIDER_PAGE_NAME,
} from './list/ContactCompany.constants';
import { iConfigColumn } from '../../shared/UITypes/types';
import AccessService from '../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_SERVICE_PROVIDERS } from '../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_CREATE, ACCESS_CAN_DELETE } from '../../types/settings/UserAccess/iRoleAccess';
import { RootState } from '../../redux/makeReduxStore';

const ServiceProviderList = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  // third party not include, create a generic component for not include third party,
  // like error boundary fall back
  //  if(not) return <GenericComponent />
  return (
    <ContactCompanyList
      typeName={SERVICE_PROVIDER}
      pageName={SERVICE_PROVIDER_PAGE_NAME}
      getFn={getServiceProviderList}
      urlPrefix={SERVICE_PROVIDER_URL}
      fixColumns={CUSTOMER_COLUMNS.map((item: iConfigColumn) =>
        item.key === 'name' ? { ...item, urlPrefix: SERVICE_PROVIDER_URL } : item,
      )}
      flexInsertIndex={CUSTOMER_INSERT_INDEX}
      canCreate={AccessService.hasAccess(ACCESS_CODE_SERVICE_PROVIDERS, ACCESS_CAN_CREATE, user)}
      canDelete={AccessService.hasAccess(ACCESS_CODE_SERVICE_PROVIDERS, ACCESS_CAN_DELETE, user)}
    />
  );
};

export default ServiceProviderList;
