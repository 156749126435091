import { useState } from 'react';

type iProps<T> = {
  //  eslint-disable-next-line
  whole: Array<T>;
  isInitialEmpty?: boolean;
};
const useRowCheckboxHook = <T extends { id: string }>({
  whole,
  isInitialEmpty = true,
}: iProps<T>) => {
  const [state, setState] = useState(isInitialEmpty ? [] : whole);

  const selectOneElement = (selected: T) => {
    const newState = state.find((item: T) => item.id === selected.id)
      ? state.filter((item: T) => item.id !== selected.id)
      : [...state, selected];
    setState(newState);
  };

  const selectAll = () =>
    state.length === whole.length ? setState([]) : setState(whole);

  return {
    selectOneElement,
    selectAll,
    state,
  };
};

export default useRowCheckboxHook;
