import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';

import iMachine from '../../../types/machine/iMachine';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import {
  // getCreatedOrUpdatedAt,
  getDeleteBtn,
  getHeads,
} from '../../../components/settings/utils';
import { handleNullException } from '../../../services/UtilsService';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import { iParams, iSortOrderType } from '../../../shared/UITypes/types';

const TableWrapper = styled.div`
  td:nth-child(n + 2):nth-child(-n + 3) {
    width: 20%;
    min-width: 12rem;
  }

  td:last-child {
    max-width: 2rem;
  }
`;

export const COLUMNS = [
  'Machine Name',
  'Default Warehouse',
  'Created',
  'Updated',
  'Operation',
];

const MachineListTable = ({
  machines,
  idNameMapping,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSetSort,
  onDelete,
  onEdit,
  isLoading,
}: {
  machines: Array<iMachine>;
  idNameMapping?: iParams;
  onSetSort: (sortStr: string) => void;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  isLoading: boolean;
}) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  const getNameContent = (machine: iMachine) => (
    <Button
      appearance={'link'}
      spacing={'none'}
      onClick={() => onEdit(machine.id)}
      testId={`machines-list-linkBtn-${machine.id}`}
    >
      {machine.name}
    </Button>
  );

  const getRows = () => {
    return machines
      ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      .map((item: iMachine) => ({
        cells: [
          { key: item.name, content: getNameContent(item) },
          {
            content: (
              <ComposeSecondaryText
                secondaryText={handleNullException(
                  idNameMapping,
                  item.warehouseLocationId || '',
                )}
              >
                <LinkBtnUrl
                  btnName={handleNullException(item, 'warehouseLocation.name')}
                  url={`/b/warehouse/${handleNullException(
                    item,
                    'warehouseLocation.id',
                  )}`}
                />
              </ComposeSecondaryText>
            ),
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'createdAt')}
                operator={handleNullException(item, 'createdBy.firstName')}
              />
            ),
          },
          {
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, 'updatedAt')}
                operator={handleNullException(item, 'updatedBy.firstName')}
              />
            ),
          },
          {
            content: getDeleteBtn({
              id: item.id,
              answer: item.name || 'name n/a',
              onClick: onDelete,
            }),
          },
        ],
      }));
  };

  return (
    <TableWrapper>
      <DynamicTable
        head={getHeads(COLUMNS, 'settings-machine')}
        rows={getRows()}
        testId={'settings-machine-list-table'}
        isLoading={isLoading}
        rowsPerPage={10}
        defaultPage={1}
        onSort={
          //  eslint-disable-next-line
          (data: any) => {
            setSortKey(data.key);
            setSortOrder(data.sortOrder);
            // onSetSort(`${data.key}:${data.sortOrder}`);
          }
        }
        sortKey={sortKey}
        sortOrder={sortOrder}
      />
    </TableWrapper>
  );
};

export default MachineListTable;
