import React from 'react';
import { useParams } from 'react-router-dom';
import ContactCompanyDetail from '../../shared/contactCompany/detail/ContactCompanyDetail';
import { iRouteTypeId } from '../../shared/contactCompany/detail/types';
import { SERVICE_PROVIDER_URL } from '../../shared/UrlMap';
import {
  SERVICE_PROVIDER,
  SERVICE_PROVIDER_PAGE_NAME,
} from './list/ContactCompany.constants';

const ServiceProviderDetail = () => {
  const { id } = useParams<iRouteTypeId>();
  return (
    <ContactCompanyDetail
      id={id}
      type={SERVICE_PROVIDER}
      urlPrefix={SERVICE_PROVIDER_URL}
      upperPageName={SERVICE_PROVIDER_PAGE_NAME}
    />
  );
};

export default ServiceProviderDetail;
