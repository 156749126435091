import React from 'react';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Modal, {
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { WidthNames } from '@atlaskit/modal-dialog/dist/types/shared-variables';
import TransparentIconBtn from '../buttons/TransparentIconBtn';

const defaultModalHeading = <span>Are you sure to start?</span>;
const defaultModalBody = <p>Please confirm you are about to start it.</p>;
const NoFooterModal = ({
  isOpen,
  onCancel,
  modalHeading = defaultModalHeading,
  modalBody = defaultModalBody,
  testIdPrefix,
  width = 'medium',
}: {
  isOpen?: boolean;
  onCancel: () => void;
  modalHeading?: React.ReactNode;
  modalBody?: React.ReactNode;
  testIdPrefix?: string;
  width?: number | string | WidthNames;
}) => {
  const getModal = () => (
    <Modal
      testId={
        testIdPrefix ? `${testIdPrefix}-customize-modal` : 'customize-modal'
      }
      components={{
        Header: () => {
          return (
            <ModalHeader>
              <ModalTitle>{modalHeading}</ModalTitle>
              <TransparentIconBtn
                icon={<CrossIcon label={'cross'} />}
                onClick={onCancel}
              />
            </ModalHeader>
          );
        },
      }}
      onClose={onCancel}
      width={width}
    >
      {modalBody}
    </Modal>
  );
  return isOpen ? <ModalTransition>{getModal()}</ModalTransition> : null;
};

export default NoFooterModal;
