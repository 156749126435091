import React, { useEffect } from 'react';
import { Label } from '@atlaskit/field-base';
import DynamicTable from '@atlaskit/dynamic-table';
import iAcquisitionOrderItem from '../../../../types/acquisitionOrder/iAcquisitionOrderItem';
import CustomizePagination from '../../../../shared/pagination/CustomizePagination';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import useStatusSelector from '../../../../shared/hooks/useStatusSelector';
import { getEntityCategoryList } from '../../../../services/CategoryService';
import { getHeads } from '../../../../services/UtilsService';
import { getRows } from './TableHelper';
import { getAOItemListEagerLoadOrder } from '../../../../services/AcquisitionOrderItemService';
import { SpaceVerticalContainer } from '../../../../shared/styles/styles';
import { CreatePOAOItemsTableWrapper } from './shared/CreatePO.style';
import {
  AO_ITEM_CREATE_COLUMNS,
  SELECT_AO_ITEMS,
} from './shared/CreatePO.constants';

const CreateModalBody = ({
  onCreateCallback,
  supplierId,
  POId,
}: {
  onCreateCallback: (id?: string) => void;
  supplierId?: string;
  POId: string;
}) => {
  const {
    state,
    onFilter,
    onSetCurrentPage,
  } = useListCrudHook<iAcquisitionOrderItem>({
    getFn: getAOItemListEagerLoadOrder,
    paginationApplied: true,
    sort: 'createdAt:DESC',
    notRenderWithoutFilter: true,
  });

  const { selected, categories, isStatusLoading } = useStatusSelector({
    type: 'AcquisitionOrder',
    isMulti: true,
    getFn: getEntityCategoryList,
  });

  useEffect(
    () => {
      if (!isStatusLoading) {
        onFilter(
          `confirmedQty>0,confirmedQty>{purchasedQty},acquisitionOrder.supplierId:${supplierId},acquisitionOrder.statusId:${selected.join(
            '|',
          )}`,
        );
      }
    },
    //  eslint-disable-next-line
    [JSON.stringify(selected), supplierId],
  );
  return (
    <>
      <Label label="Select a product to add to the Purhcase order:" />
      <CreatePOAOItemsTableWrapper>
        <DynamicTable
          head={getHeads(AO_ITEM_CREATE_COLUMNS, SELECT_AO_ITEMS)}
          rows={getRows({
            data: state.data,
            onCreateCallback,
            columns: AO_ITEM_CREATE_COLUMNS,
            POId,
            categories,
          })}
          testId={SELECT_AO_ITEMS}
          isLoading={state.isLoading}
          emptyView={<div>Empty Acquisition Order</div>}
        />
      </CreatePOAOItemsTableWrapper>

      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
      <SpaceVerticalContainer className={'half'} />
    </>
  );
};

export default CreateModalBody;
