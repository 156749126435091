import React, { useState } from 'react';
import Popup from '@atlaskit/popup';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';

const ChevronDownIconPopup = ({
  btnName,
  children,
}: {
  btnName?: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={() => children}
      trigger={triggerProps => (
        <Button
          //    eslint-disable-next-line
          {...triggerProps}
          id="popup-trigger"
          className={isOpen ? 'popup-open' : ''}
          onClick={() => setIsOpen(!isOpen)}
          iconAfter={<ChevronDownIcon label={'print'} />}
          testId="popup-trigger-btn"
          isSelected
        >
          {btnName}
        </Button>
      )}
      placement={'bottom-end'}
      autoFocus
    />
  );
};

export default ChevronDownIconPopup;
