import React from 'react';
import _ from 'lodash';
import iSalesOrderItem from '../../../../../../types/sales/iSalesOrderItem';
import iEntityCategory from '../../../../../../types/status/iEntityCategory';
import LinkBtnUrl from '../../../../../../shared/buttons/LinkBtnUrl';
import StatusLozenge from '../../../../../../shared/statusComponent/StatusLozenge';
import {
  currencyFormat,
  formatDate,
  getCategoryCode,
  handleNullException,
} from '../../../../../../services/UtilsService';
import { getCreatedOrUpdatedAt } from '../../../../../settings/utils';
import { iRowContent } from '../../../../../../shared/UITypes/types';
import ConversionText from '../../../../../../shared/text/ConversionText';
import { NUMBER_ROUND_DECIMAL } from '../../../../../../shared/constants/ActionConstants';

export const getHeads = (columns: Array<string>, tableName: string) => {
  const cells = columns.map((column: string) => {
    switch (column) {
      case 'Operation':
        return {
          key: column,
          testId: `${tableName}-table-column-${column}`,
        };
      default:
        return {
          key: column,
          content: column,
          testId: `${tableName}-table-column-${column}`,
        };
    }
  });
  return { cells };
};

export const getRows = (
  data: Array<iSalesOrderItem>,
  columns: Array<string>,
  categories: Array<iEntityCategory>,
) => {
  const mapList = () =>
    data?.map((soItem: iSalesOrderItem) => {
      const row = {
        id: soItem.id,
        'SO No.': (
          <LinkBtnUrl
            btnName={soItem.salesOrder?.orderNumber || 'so n/a'}
            url={`/b/sales/${soItem.salesOrder?.id}`}
          />
        ),
        Status: (
          <StatusLozenge
            status={handleNullException(soItem, 'salesOrder.status')}
            categoryCode={getCategoryCode(
              handleNullException(soItem, 'salesOrder.status'),
              categories,
            )}
          />
        ),
        Type: handleNullException(soItem, 'salesOrder.type.name'),
        Customer: (
          <LinkBtnUrl
            btnName={handleNullException(soItem, 'salesOrder.customer.name')}
            url={`/b/customer/${soItem.salesOrder?.customerId}`}
          />
        ),
        Reference: handleNullException(soItem, 'salesOrder.customerRef'),
        'Unit Price': currencyFormat(handleNullException(soItem, 'unitPrice')),
        Qty: (
          <ConversionText
            value={handleNullException(soItem, 'qty')}
            productUnit={handleNullException(
              soItem,
              'product.measurement.shortName',
            )}
            contactCompanyUnit={handleNullException(
              soItem,
              'measurement.shortName',
            )}
            conversion={handleNullException(soItem, 'unitConversion') || 1}
            decimal={NUMBER_ROUND_DECIMAL}
          />
        ),
        'Order Date': formatDate(
          handleNullException(soItem, 'salesOrder.orderDate'),
          'DD MMM YYYY',
        ),
        EDD: formatDate(
          handleNullException(soItem, 'salesOrder.edd'),
          'DD MMM YYYY',
        ),
        Created: getCreatedOrUpdatedAt({
          operatedAt: soItem.salesOrder?.createdAt,
          operator: soItem.salesOrder?.createdBy?.username,
        }),
        Updated: getCreatedOrUpdatedAt({
          operatedAt: soItem.salesOrder?.updatedAt,
          operator: soItem.salesOrder?.updatedBy?.username,
        }),
      };
      return row;
    });

  return mapList().map((row: iRowContent) => {
    const cells = columns.map((column: string) => ({
      content: _.get(row, column) ? row[column] : '',
    }));
    return { cells, testId: 'product-so-row' };
  });
};
