import {
  ASYNC_SEARCH_CURRENT_PAGE,
  ASYNC_SEARCH_PER_PAGE,
} from '../shared/constants/AsyncConstants';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getWarehousesByParentId = (parentId?: string) => {
  return AppService.get('/warehouseLocation', {
    filter: `parentId:${parentId || null}`,
  }).then(({ data }) => data);
};
export const getWarehouseDetailById = (id: string) => {
  return AppService.get('/warehouseLocation', {
    include: 'facility,facility.address,createdBy,updatedBy',
    filter: `id:${id}`,
  }).then(({ data }) => data);
};
export const getWarehouseDetail = (id: string) => {
  return AppService.get(`/warehouseLocation/${id}`, {
    include: 'createdBy,updatedBy,facility.lostAndFoundLocation',
  }).then(({ data }) => data);
};

export const getProductsUnderWarehouse = (path: string) => {
  return AppService.get('/product', {
    include: 'warehouseLocations,measurement',
    like: `warehouseLocations.path:${path}`,
  }).then(({ data }) => data);
};

export const updateWarehouse = (id: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/warehouseLocation/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const deactivateWarehouse = (id: string) => {
  return AppService.delete(`/warehouseLocation/${id}`).then(({ data }) => data);
};

// eslint-disable-next-line
export const createWarehouse = (postData: any) => {
  return AppService.post('/warehouseLocation', postData).then(
    ({ data }) => data,
  );
};
// eslint-disable-next-line
export const moveWarehouse = (postData: any) => {
  return AppService.post('/warehouseLocation/move', postData).then(
    ({ data }) => data,
  );
};

export const getWarehouseList = (config?: iParams) => {
  return AppService.get('/warehouseLocation', {
    ...config,
  }).then(({ data }) => data);
};

export const getWarehouseListAsyncSearch = (config?: iParams) => {
  return AppService.get('/warehouseLocation', {
    ...config,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

export const getWarehouseLocationPathNames = (ids: string) => {
  return AppService.get('/warehouseLocation/names', {
    ids,
  }).then(({ data }) => data);
};

export const isWIPWarehouseLocation = (id: string) => {
  return AppService.get(`/warehouseLocation/${id}/isWIP`).then(
    ({ data }) => data,
  );
};

export default {
  getWarehouseDetailById,
  getWarehousesByParentId,
  getProductsUnderWarehouse,
  updateWarehouse,
  deactivateWarehouse,
  getWarehouseListAsyncSearch,
  getWarehouseDetail,
  getWarehouseList,
  getWarehouseLocationPathNames,
  moveWarehouse,
  isWIPWarehouseLocation,
};
