import React from 'react';

const StripeDisclaimer = () => {
  return (
    <>
      All transactions are securely encrypted by{' '}
      <a href={'https://stripe.com'} target={'__blank'}>
        Stripe
      </a>
    </>
  );
};

export default StripeDisclaimer;
