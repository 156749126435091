import React from 'react';
import { useParams } from 'react-router-dom';
import { iRouteTypeId } from '../../shared/contactCompany/detail/types';
import Detail from './detail/Detail';

const WarehouseDetail = () => {
  const { id } = useParams<iRouteTypeId>();
  return <Detail id={id} />;
};
export default WarehouseDetail;
