import React from 'react';
import { iConfigColumn } from '../UITypes/types';
import iEntityCategory from '../../types/status/iEntityCategory';
import LinkBtnUrl from '../buttons/LinkBtnUrl';
import StatusLozenge from '../statusComponent/StatusLozenge';
import ComposeSecondaryText from '../text/ComposeSecondaryText';
import ConversionText from '../text/ConversionText';
import {
  handleNullException,
  handleMoney,
  handleNullExceptionMultiFields,
  handleNumber,
  getCategoryCode,
} from '../../services/UtilsService';

export const getRows = <T extends { id: string }>({
  data,
  columns,
  categories,
  renderSpecialContent,
}: {
  data: Array<T>;
  columns: Array<iConfigColumn>;
  categories: Array<iEntityCategory>;
  renderSpecialContent: (item: T, column: iConfigColumn) => JSX.Element;
}) => {
  return data.map((item: T) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'text-postfix':
          return {
            key: handleNullException(item, key),
            content: `${handleNullException(item, key)} ${handleNullException(item, dataSource[0])}`,
          };
        case 'money':
          return {
            key: handleNullException(item, key),
            content: handleMoney(item, key, 2),
          };
        case 'unit-price':
          return {
            key: handleNullException(item, key),
            content: `${handleMoney(item, key, 2)} / ${handleNullExceptionMultiFields(item, dataSource.join(','))}`,
          };
        case 'number':
          return {
            key: handleNullException(item, key),
            content: handleNumber(item, key),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
              />
            ),
          };
        case 'link-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText secondaryText={handleNullException(item, dataSource[1])}>
                <LinkBtnUrl
                  btnName={handleNullException(item, key)}
                  url={`${column.urlPrefix}/${handleNullException(item, dataSource[0])}`}
                />
              </ComposeSecondaryText>
            ),
          };
        case 'conversion-text':
          return {
            key: handleNullException(item, key),
            content: (
              <ConversionText
                value={handleNullException(item, key)}
                conversion={handleNullException(item, dataSource[0])}
                contactCompanyUnit={
                  handleNullException(item, dataSource[1]) || handleNullException(item, dataSource[2])
                }
                productUnit={handleNullException(item, dataSource[2])}
              />
            ),
          };
        case 'status':
          return {
            content: (
              <StatusLozenge
                status={handleNullException(item, key)}
                categoryCode={getCategoryCode(handleNullException(item, key), categories)}
              />
            ),
          };

        default:
          return { content: renderSpecialContent(item, column) };
      }
    });
    return { cells };
  });
};
