import React, { useState } from 'react';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import CreateModalBody from './CreateModalBody';
import TransparentIconBtn from '../../../../shared/buttons/TransparentIconBtn';
import NoFooterModal from '../../../../shared/modal/NoFooterModal';

type iState = {
  isOpen: boolean;
};
const initialState: iState = {
  isOpen: false,
};
const CreateAganistAO = ({
  POId,
  supplierId,
  onCreateCallback,
}: {
  POId: string;
  supplierId?: string;
  onCreateCallback: (id?: string) => void;
}) => {
  const [state, setState] = useState(initialState);
  const onCloseModal = () => setState(initialState);
  const afterCreatePo = (id?: string) => {
    onCloseModal();
    onCreateCallback(id);
  };
  return (
    <>
      <TransparentIconBtn
        icon={
          <EditorAddIcon
            size={'small'}
            label={'add'}
            testId={'create-poItem-ao'}
          />
        }
        btnName={'AO'}
        onClick={() => setState({ ...state, isOpen: true })}
      />
      <NoFooterModal
        isOpen={state.isOpen}
        onCancel={onCloseModal}
        modalHeading={'Creating a PO item aganist an AO Item'}
        modalBody={
          <CreateModalBody
            POId={POId}
            onCreateCallback={afterCreatePo}
            supplierId={supplierId}
          />
        }
        width={'80%'}
      />
    </>
  );
};

export default CreateAganistAO;
