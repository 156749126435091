import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import InputWithController from '../../../../shared/form/hookForms/InputWithController';

const DisableShiftModal = ({
  control,
  errors,
  onChange,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
}) => {
  return (
    <InputWithController
      name={'reason'}
      label={'Reason to disable'}
      control={control}
      onChange={onChange}
      errors={errors}
      isRequired
      testId={'disable-reason'}
    />
  );
};

export default DisableShiftModal;
