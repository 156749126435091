import React from 'react';
import { iHookFormField } from '../../../shared/UITypes/types';
import PasswordRuleSection from '../../settings/users/PasswordRuleSection';
import { validatePassword } from './Form.utils';
import {
  HOOK_FORM_REQUIRED,
  GIRDCOLUMN_MAX_SIZE,
  HOOK_FORM_EMAIL,
} from '../../../shared/constants/HookFormRulesConstants';

export const USER_ACTIVATE_FIELDS: Array<iHookFormField> = [
  {
    label: 'First Name',
    value: 'firstName',
    rules: {
      required: HOOK_FORM_REQUIRED,
    },
    size: GIRDCOLUMN_MAX_SIZE / 2,
    isDisabled: true,
  },
  {
    label: 'Last Name',
    value: 'lastName',
    rules: {
      required: HOOK_FORM_REQUIRED,
    },
    size: GIRDCOLUMN_MAX_SIZE / 2,
    isDisabled: true,
  },
  {
    label: 'Company',
    value: 'company.name',
    rules: {
      required: HOOK_FORM_REQUIRED,
    },
    size: GIRDCOLUMN_MAX_SIZE,
    isDisabled: true,
  },
  {
    label: 'Email',
    value: 'email',
    rules: {
      required: HOOK_FORM_REQUIRED,
      pattern: HOOK_FORM_EMAIL,
    },
    size: GIRDCOLUMN_MAX_SIZE,
    isDisabled: true,
  },
  {
    label: 'Password',
    value: 'password',
    rules: {
      required: HOOK_FORM_REQUIRED,
      validate: {
        asyncValidate: async value =>
          (await validatePassword(value)) || 'invalid password',
      },
    },
    size: GIRDCOLUMN_MAX_SIZE,
    helperMessage: <PasswordRuleSection />,
    type: 'password',
  },
  {
    label: 'Confirm Password',
    value: 'confirmPassword',
    rules: {
      required: HOOK_FORM_REQUIRED,
    },
    size: GIRDCOLUMN_MAX_SIZE,
    compareTarget: 'password',
    type: 'password',
  },
];
export const SIGN_UP_FIELDS: Array<iHookFormField> = [
  {
    label: 'First Name',
    value: 'firstName',
    rules: {
      required: HOOK_FORM_REQUIRED,
    },
    size: GIRDCOLUMN_MAX_SIZE / 2,
  },
  {
    label: 'Last Name',
    value: 'lastName',
    rules: {
      required: HOOK_FORM_REQUIRED,
    },
    size: GIRDCOLUMN_MAX_SIZE / 2,
  },
  {
    label: 'Your company name',
    value: 'companyName',
    rules: {
      required: HOOK_FORM_REQUIRED,
    },
    size: GIRDCOLUMN_MAX_SIZE,
  },
  {
    label: 'Email',
    value: 'email',
    rules: {
      required: HOOK_FORM_REQUIRED,
      pattern: HOOK_FORM_EMAIL,
    },
    size: GIRDCOLUMN_MAX_SIZE,
  },
];
export const FORGOT_PASSWORD = 'Forgot Password?';
export const FORGOT_PASSWORD_DESCRIPTION =
  'Enter the email address associated with your account.';
export const FORGOT_SUBMIT = 'Send Link For Password Change';
export const RESET_PASSWORD = 'Create a new password';
export const FORGOT_PASSWORD_FIELDS: Array<iHookFormField> = [
  {
    label: 'Email',
    value: 'email',
    rules: {
      required: HOOK_FORM_REQUIRED,
      pattern: HOOK_FORM_EMAIL,
    },
    size: GIRDCOLUMN_MAX_SIZE,
  },
];
export const RESET_PASSWORD_FIELDS: Array<iHookFormField> = [
  {
    label: 'Password',
    value: 'password',
    rules: {
      required: HOOK_FORM_REQUIRED,
      validate: {
        asyncValidate: async value =>
          (await validatePassword(value)) || 'invalid password',
      },
    },
    size: GIRDCOLUMN_MAX_SIZE,
    helperMessage: <PasswordRuleSection />,
    type: 'password',
  },
  {
    label: 'Confirm Password',
    value: 'confirmPassword',
    rules: {
      required: HOOK_FORM_REQUIRED,
    },
    size: GIRDCOLUMN_MAX_SIZE,
    compareTarget: 'password',
    type: 'password',
  },
];

export const USER_LOGIN_FIELDS: Array<iHookFormField> = [
  {
    label: 'Username',
    value: 'username',
    rules: {
      required: HOOK_FORM_REQUIRED,
    },
    size: GIRDCOLUMN_MAX_SIZE,
  },
  {
    label: 'Password',
    value: 'password',
    rules: {
      required: HOOK_FORM_REQUIRED,
    },
    size: GIRDCOLUMN_MAX_SIZE,
    type: 'password',
  },
];
export const EXPIRE_MINUTE = 10;
export const CREATE_ACCOUNT = 'Create Account';
export const CREATE_ACCOUNT_PRE = "Don't have an account?";
export const ACTIVATION_SUCCESS_HINT =
  'Your account has been activated successfully; Please click on the link below to login';
export const RESET_SUCCESS_HINT =
  'The password has been reset successfully; Please click on the link below to login';
export const SIGN_UP_SUCCESS_HINT = `A link has been sent to your email, please follow the link to complete the activation in ${EXPIRE_MINUTE} minutes`;
export const RETRIEVAL_HINT = `A link has been sent to your email, please follow the link to reset your password in ${EXPIRE_MINUTE} minutes`;
export const BACK_LOGIN = 'Back to Login';
export const RESEND_HINT =
  'It seems like your token is expired, Please click on the link below to get a new one';
export const RESEND = 'Resend';
export const ENTERPRISE_RETRIEVAL_HINT =
  'You have to contact the admin to reset your password';
export const NOT_ACTIVATE_HINT =
  'Your account is not activated, please follow the link sent to your email to activate your account first.';
