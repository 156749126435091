/* eslint-disable no-unused-expressions */
export function timeSlot(step: number) {
  //  step = 间隔的分钟
  const date = new Date();
  date.setHours(0o0); // 时分秒设置从零点开始
  date.setSeconds(0o0);
  date.setUTCMinutes(0o0);

  const timeArr = [];
  const slotNum = (24 * 60) / step; // 算出多少个间隔
  for (let f = 0; f < slotNum; f++) {
    //  stepM * f = 24H*60M
    // arr.push(new Date(Number(date.getTime()) + Number(step*60*1000*f)))   //  标准时间数组
    const time = new Date(
      Number(date.getTime()) + Number(step * 60 * 1000 * f),
    ); // 获取：零点的时间 + 每次递增的时间
    let hour = '';
    let sec = '';
    time.getHours() < 10
      ? (hour = `0${time.getHours()}`)
      : (hour = time.getHours().toString()); // 获取小时
    time.getMinutes() < 10
      ? (sec = `0${time.getMinutes()}`)
      : (sec = time.getMinutes().toString()); // 获取分钟
    timeArr.push(`${hour}:${sec}`);
  }
  return timeArr;
}

export default { timeSlot };
