import styled from 'styled-components';
import { G200 } from '@atlaskit/theme/colors';

export const JobCardWrapper = styled.div`
  position: relative;
  padding: 14px 10px;
  border-radius: 4px;
  font-size: 13px;
  & > div {
    margin-bottom: 10px;
  }
  & > div:last-child {
    margin-bottom: 0px;
  }
  .job-number {
    font-weight: 700;
    color: rgb(107, 119, 140);
  }
  .qty-to-make {
    font-weight: 400;
    color: rgb(107, 119, 140);
  }
  .product-code {
    font-weight: 400;
    color: rgb(0, 0, 0);
  }
  .sales-order {
    display: flex;
    & > span:first-child {
      background-color: ${G200};
      border-radius: 4px;
      margin-right: 4px;
    }
  }
  .avatar-by-name {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: rgb(0, 184, 217);
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      color: rgb(255, 255, 255);
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
  }
  .button-style-href {
    font-weight: 500;
    &:focus {
      outline: none;
    }
  }
  &:hover {
    .hover-dots {
      display: block;
    }
  }
  .EDD {
    font-weight: bold;
  }
`;
