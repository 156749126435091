import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { getRows } from './TableHelper';
import { StockTransferWrapper } from './StockTransfer.style';
import { iItemLocationTransfer } from './StockTransfer.type';
import { iConfigColumn } from '../../../shared/UITypes/types';
import { getHeads } from '../../../services/UtilsService';

const TransferProdsTable = ({
  data,
  onOpenDeleteModal,
  columns,
  canDelete = true,
}: {
  data: Array<iItemLocationTransfer>;
  onOpenDeleteModal: (id: string) => void;
  columns: Array<iConfigColumn>;
  canDelete?: boolean;
}) => {
  return (
    <StockTransferWrapper>
      <DynamicTable
        head={getHeads(columns, 'stockTransfer')}
        rows={getRows(
          data,
          columns.filter(col => {
            return !(col.type === 'delete' && !canDelete);
          }),
          onOpenDeleteModal,
        )}
        testId={'stock-transfer-table'}
      />
    </StockTransferWrapper>
  );
};

export default TransferProdsTable;
