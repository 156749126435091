import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import InlineEdit from '../../../../shared/form/InlineEdit';
import iProfileCompany from '../../../../types/company/iProfileCompany';
import { addToastForAPIResponse, apiErrorToast } from '../../../../shared/toast/Toast';
import InlineRichTextEditor from '../../../../shared/form/InlineRichTextEditor';
import CompanyPreferenceService from '../../../../services/Settings/CompanyPreferenceService';
import iProfileCompanyPreference from '../../../../types/company/iProfileCompanyPreference';

const Wrapper = styled.div``;
type iPurchaseOrderSettings = {
  company: iProfileCompany;
  entityName: string;
  settingsCode: string;
};
const PDFPrintSettings = ({ company, entityName, settingsCode }: iPurchaseOrderSettings) => {
  const printingsKey = 'PRINTING';
  const [soSettings, setSoSettings] = useState<iProfileCompanyPreference | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    CompanyPreferenceService.getCompanyPreferences({
      filter: `companyId:${company.id},code:${settingsCode}`,
      perPage: 1,
      currentPage: 1,
      order: 'updatedAt:DESC',
    })
      .then(resp => {
        if (isCanceled) return;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const settings = resp.data || [];
        setSoSettings(settings.length <= 0 ? null : settings[0]);
      })
      .catch(err => {
        if (isCanceled) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [company, entityName, settingsCode]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateField = (name: string, value: any) => {
    if (!soSettings) {
      return;
    }
    setIsSaving(true);
    CompanyPreferenceService.updateCompanyPreference(soSettings.id, {
      value: {
        ...soSettings.value,
        [printingsKey]: {
          ...soSettings.value[printingsKey],
          [name]: value,
        },
      },
    })
      .then(resp => {
        setSoSettings(resp);
        addToastForAPIResponse('success', 'updated successfully.');
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      <InlineEdit
        label={`Title for ${entityName} PDF`}
        name={`title_${entityName}`}
        isDisabled={isSaving}
        onConfirm={updateField}
        defaultValue={soSettings?.value[printingsKey] ? soSettings?.value[printingsKey][`title_${entityName}`] : ''}
      />
      <InlineRichTextEditor
        label={'Delivery Policies on PDF'}
        testId={`deliveryPolicies_${entityName}`}
        defaultValue={
          soSettings?.value[printingsKey] ? soSettings?.value[printingsKey][`deliveryPolicies_${entityName}`] : ''
        }
        disabled={isSaving}
        onChangeConfirmed={newText => updateField(`deliveryPolicies_${entityName}`, newText)}
      />
      <InlineRichTextEditor
        label={'Policies on PDF'}
        testId={`policies_${entityName}`}
        defaultValue={soSettings?.value[printingsKey] ? soSettings?.value[printingsKey][`policies_${entityName}`] : ''}
        disabled={isSaving}
        onChangeConfirmed={newText => updateField(`policies_${entityName}`, newText)}
      />
    </Wrapper>
  );
};

export default PDFPrintSettings;
