import React from 'react';
import {
  AtlassianNavigation,
  CustomProductHome,
  PrimaryButton,
  PrimaryButtonProps,
  useOverflowStatus,
  Settings,
} from '@atlaskit/atlassian-navigation';
import { ButtonItem } from '@atlaskit/menu';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BACK_END_URL, SETTINGS_COMPANY_URL } from '../../shared/UrlMap';
import UserAvatarPopup from '../../shared/user/UserAvatarPopup';
import { RootState } from '../../redux/makeReduxStore';
import TopNavPopup from '../../shared/topNavPopups/TopNavPopup';
import CreatePopup from '../../shared/topNavPopups/createPopup/CreatePopup';
import ScheduleShortcut from '../../shared/topNavPopups/ScheduleShortcut';
import CompanyExpiryBtn from '../../shared/company/billing/CompanyExpiryBtn';
import { PRIMARY_MENUS_EXCEPT_HOME } from '../../shared/topNavPopups/subMenus';
import { iPrimaryMenuExceptHome } from '../../shared/UITypes/types';
import RestrictedAccessDiv from '../../shared/UserAccess/RestrictedAccessDiv';
import { ACCESS_CODE_JOB_SCHEDULER, ACCESS_CODE_SYSTEM_SETTINGS } from '../../types/settings/UserAccess/iAccess';

const ResponsiveBtn = (props: PrimaryButtonProps) => {
  const overflowStatus = useOverflowStatus();
  return overflowStatus.isVisible ? (
    <PrimaryButton {...props} /> // eslint-disable-line
  ) : (
    <ButtonItem>{props.children}</ButtonItem> // eslint-disable-line
  );
};

const customIcon = '';
export default () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const history = useHistory();

  const navToHome = () => {
    history.push(BACK_END_URL);
  };

  const getCustomLogo = () => {
    if (!user || !user.company) {
      return '';
    }
    return user.company.logoUrl || '';
  };

  const logoBtn = () => (
    <CustomProductHome
      // href={BACK_END_URL}
      onClick={navToHome}
      iconAlt={user?.company.name || 'Logo'}
      iconUrl={customIcon}
      logoAlt={user?.company.name || 'Logo'}
      logoUrl={getCustomLogo()}
      logoMaxWidth={300}
      testId={'nav-logo'}
    />
  );

  const UserProfile = () => {
    return <UserAvatarPopup />;
  };

  const NavSettings = () => (
    <RestrictedAccessDiv expectedUserAccessCodes={[ACCESS_CODE_SYSTEM_SETTINGS]} hideWhenDenied>
      <Settings onClick={() => history.push(SETTINGS_COMPANY_URL)} tooltip="system settings" testId={'nav-settings'} />
    </RestrictedAccessDiv>
  );

  const primaryItemsExceptHome = () =>
    PRIMARY_MENUS_EXCEPT_HOME.map((item: iPrimaryMenuExceptHome) => (
      <RestrictedAccessDiv expectedUserAccessCodes={item.accessCodes || []} expectedCodeMatchedWithAny hideWhenDenied>
        <TopNavPopup key={`top-nav-${item.name}`} name={item.name} subMenu={item.subMenu} />
      </RestrictedAccessDiv>
    ));

  return (
    <AtlassianNavigation
      label="site"
      primaryItems={[
        <ResponsiveBtn
          // href={BACK_END_URL}
          onClick={navToHome}
          testId={'nav-home'}
        >
          Home
        </ResponsiveBtn>,
        ...primaryItemsExceptHome(),
      ]}
      renderProductHome={logoBtn}
      // renderAppSwitcher={SwitcherPopup}
      renderCreate={CreatePopup}
      renderHelp={() => (
        <RestrictedAccessDiv expectedUserAccessCodes={[ACCESS_CODE_JOB_SCHEDULER]} hideWhenDenied>
          <ScheduleShortcut />
        </RestrictedAccessDiv>
      )}
      renderNotifications={CompanyExpiryBtn}
      renderProfile={UserProfile}
      // renderSearch={DefaultSearch}
      renderSettings={NavSettings}
    />
  );
};
