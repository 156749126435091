export const getHeads = (columns: Array<string>) => {
  const cells = columns.map((column: string) => {
    switch (column.toUpperCase()) {
      case 'OPERATION':
        return {
          key: column,
          testId: 'jobList-operation-cell',
        };
      case 'PRODUCT NAME':
        return {
          key: column,
          content: column,
          testId: 'jobList-product-cell',
        };
      case 'PRODUCT CODE':
        return {
          key: column,
          content: column,
          testId: 'jobList-product-cell',
        };
      default:
        return {
          key: column,
          content: column,
        };
    }
  });
  return { cells };
};
