import React, { useState } from 'react';
import RetypeModalContent from '../confirmWithRetype/RetypeModalContent';
import ElementWithPopup from '../contactCompany/list/table/ElementWithPopup';
import { iBtnAppearance } from '../UITypes/types';
import DeleteModalContent from './DeleteModalContent';

const ConfirmWithReType = ({
  answer,
  onConfirm,
  children,
  popupHeading,
  isDelete = true,
  confirmInfo,
  confirmBtnAppearance = 'danger',
  confirmBtnName = 'Delete',
}: {
  answer: string;
  onConfirm: () => void;
  children: React.ReactNode;
  popupHeading?: string;
  isDelete?: boolean;
  confirmInfo?: React.ReactNode;
  confirmBtnAppearance?: iBtnAppearance;
  confirmBtnName?: string;
}) => {
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  return (
    <ElementWithPopup
      onClick={onConfirm}
      popupContent={
        isDelete ? (
          <DeleteModalContent
            answer={answer}
            onTypeAnswer={setIsCorrectAnswer}
          />
        ) : (
          <RetypeModalContent
            answer={answer}
            onTypeAnswer={setIsCorrectAnswer}
            confirmInfo={confirmInfo}
          />
        )
      }
      popupHeading={popupHeading || 'Are you sure to continue?'}
      isDisabled={!isCorrectAnswer}
      confirmBtnAppearance={confirmBtnAppearance}
      confirmBtnName={confirmBtnName}
    >
      {children}
    </ElementWithPopup>
  );
};

export default ConfirmWithReType;
