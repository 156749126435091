import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import iEntityCategory from '../../../types/status/iEntityCategory';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';
import { iConfigColumn } from '../../../shared/UITypes/types';
import iEntityStatus from '../../../types/status/iEntityStatus';
import PartitionTitle from '../shared/PartitionTitle';
import PurchaseTable from './PurchaseTable';
import useColumnHooksUpgrade from '../../../shared/hooks/useShowHideColumnHook/useColumnHook';
import { DASHBOARD_PURCHASE } from '../shared/Dashboard.constants';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { getEntityCategoryList } from '../../../services/CategoryService';
import { FlexContainer } from '../../../shared/styles/styles';
import {
  PartitionBodyWrapper,
  PurchaseWrapper,
} from '../shared/Dashboard.style';
import { getPurchaseOrderListDashboard } from '../../../services/PurchaseService';
import {
  getEndDayOfMonth,
  getStartDayOfMonth,
} from '../shared/Dashboard.utils';
import {
  STATUS_CATEGORY_FINISHED,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_NEW,
} from '../../../shared/constants/StatusCategories';

type iState = {
  isPOLoading: boolean;
  isCategoryLoading: boolean;
  categoryList: Array<iEntityCategory>;
  purchaseOrderMonth: Array<iPurchaseOrderDetail>;
};
const initialState: iState = {
  isCategoryLoading: true,
  isPOLoading: true,
  categoryList: [],
  purchaseOrderMonth: [],
};
const WHOLE_CATEGORY = [
  STATUS_CATEGORY_NEW,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_FINISHED,
];
const Purchase = ({ date }: { date: string }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isPOLoading: true }));
      try {
        const purchaseOrderFilter = `eta>=${getStartDayOfMonth(
          date,
        )},eta<=${getEndDayOfMonth(date)}`;
        const purchaseOrderMonth: Array<iPurchaseOrderDetail> = await getPurchaseOrderListDashboard(
          {
            filter: purchaseOrderFilter,
          },
        );
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isPOLoading: false,
          purchaseOrderMonth,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isPOLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [date]);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isCategoryLoading: true }));
      try {
        const categoryList: Array<iEntityCategory> = await getEntityCategoryList(
          {
            filter: 'entityStatuses.entityStatusType.entityName:PurchaseOrder',
          },
        );
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          isCategoryLoading: false,
          categoryList,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isCategoryLoading: false }));
      }
    };
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);
  const getColumns = (): Array<iConfigColumn> => {
    const columns = state.categoryList
      .sort(
        (a: iEntityCategory, b: iEntityCategory) =>
          WHOLE_CATEGORY.indexOf(a.code) - WHOLE_CATEGORY.indexOf(b.code),
      )
      .reduce((acc: Array<iConfigColumn>, cur: iEntityCategory) => {
        const statusColumns: Array<iConfigColumn> = cur.entityStatuses
          .sort(
            (a: iEntityStatus, b: iEntityStatus) => a.sortOrder - b.sortOrder,
          )
          .map((status: iEntityStatus) => ({
            key: status.id,
            name: status.name,
            isSelected: cur.code === STATUS_CATEGORY_IN_PROGRESS,
            dataSource: [],
            type: 'normal-column',
            group: '',
          }));
        return [...acc, ...statusColumns];
      }, []);
    return columns;
  };
  const { columns, getShowHideColumns } = useColumnHooksUpgrade(
    `dashboard-${DASHBOARD_PURCHASE}`,
    getColumns(),
    state.isCategoryLoading,
  );
  if (state.isCategoryLoading || state.isPOLoading) return <Spinner />;
  return (
    <PurchaseWrapper>
      <FlexContainer className={'space-between'}>
        <PartitionTitle
          title={DASHBOARD_PURCHASE}
          dateFrom={date}
          dateTo={date}
        />
        {getShowHideColumns()}
      </FlexContainer>
      <PartitionBodyWrapper>
        <PurchaseTable
          columns={columns}
          date={date}
          purchaseOrderMonth={state.purchaseOrderMonth}
        />
      </PartitionBodyWrapper>
    </PurchaseWrapper>
  );
};

export default Purchase;
