import React from 'react';
import WooCommerceEditForm from './WooCommerceEditForm';
import { iChannelEditPanelComponentParams } from '../../../integrations/ChannelScriptHelperTypes';
import WooCommerceOnBoardingForm from './WooCommerceOnBoardingForm';

const WooCommerceEditPanel = ({ channel, channelScript, onSaved }: iChannelEditPanelComponentParams) => {
  if (channel.isOnBoarded) {
    return <WooCommerceEditForm channel={channel} callback={onSaved} channelScript={channelScript} />;
  }

  return <WooCommerceOnBoardingForm channel={channel} onSaved={onSaved} />;
};

export default WooCommerceEditPanel;
