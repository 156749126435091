import styled from 'styled-components';
import { SELECT_AO_ITEMS } from './CreatePO.constants';

type iMaxWidthWrapperProps = {
  maxWidth?: number;
};
export const CreatePOAOItemsTableWrapper = styled.div`
  th {
    padding-left: 4px;
    padding-right: 4px;
  }
  th[data-testid='${SELECT_AO_ITEMS}-column-product.productCode--head--cell'] {
    width: 10rem;
    min-width: 10rem;
  }
  th[data-testid='${SELECT_AO_ITEMS}-column-qty--head--cell'],
  th[data-testid='${SELECT_AO_ITEMS}-column-confirmedQty--head--cell'],
  th[data-testid='${SELECT_AO_ITEMS}-column-purchasedQty--head--cell'] {
    width: 12rem;
    min-width: 12rem;
  }
  th[data-testid='${SELECT_AO_ITEMS}-column-unitPrice--head--cell'] {
    width: 8rem;
    min-width: 8rem;
  }
  th[data-testid='${SELECT_AO_ITEMS}-column-operation--head--cell'] {
    width: 3rem;
    min-width: 2rem;
  }
`;

export const MaxWidthWrapper = styled.div`
  max-width: ${(props: iMaxWidthWrapperProps) =>
    props.maxWidth ? props.maxWidth : 300}px;
`;
