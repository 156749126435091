import React, { useState, useEffect } from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import iProduct from '../../../../types/product/iProduct';
import iContactCompanyProduct from '../../../../types/contactCompany/iContactCompanyProduct';
import BasicModalBody from '../../../../shared/asyncContactCompany/createModalBody/BasicModalBody';
import InputWithController from '../../../../shared/form/hookForms/InputWithController';
import DateTimePickerWithController from '../../../../shared/form/hookForms/DateTimePickerWithController';
import CustomizedLabel from '../../../../shared/form/hookForms/CustomizedLabel';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import { PRODUCTS_URL } from '../../../../shared/UrlMap';
import { QtyInputContainer } from '../../../../shared/CCPProductContactCompany/CCPCreateOrder/CCPCreateOrder.style';
import { getCCPConfig } from '../../../../services/ContactCompanyProductService';
import { apiErrorToast } from '../../../../shared/toast/Toast';
import { AVOID_PAGINATION_PER_PAGE } from '../../../../shared/constants/AsyncConstants';
import {
  FlexContainer,
  SpaceVerticalContainer,
} from '../../../../shared/styles/styles';
import {
  getUnitConversionSuffix,
  handleNullException,
} from '../../../../services/UtilsService';

type iState = {
  isLoading: boolean;
  ccp: Array<iContactCompanyProduct>;
};
const initialState: iState = {
  isLoading: false,
  ccp: [],
};
const DashboardCreatePOModalBody = ({
  control,
  errors,
  onChange,
  watch,
  products,
}: {
  // eslint-disable-next-line
  control: Control<Record<string, any>>;
  // eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  // eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  watch: (names?: string | string[]) => unknown;
  products: Array<iProduct>;
}) => {
  const [state, setState] = useState(initialState);
  const contactCompanyId = watch('supplierId');
  useEffect(
    () => {
      let isCancelled = false;
      const fetchCCP = async () => {
        if (!contactCompanyId) return;
        try {
          setState(prevState => ({ ...prevState, isLoading: true }));
          const { data } = await getCCPConfig({
            filter: `contactCompanyId:${watch(
              'supplierId',
            )},productId:${products
              .map((item: iProduct) => item.id)
              .join('|')}`,
            include: 'measurement,product.measurement',
            currentPage: '1',
            perPage: AVOID_PAGINATION_PER_PAGE,
          });
          if (isCancelled) return;
          setState(prevState => ({ ...prevState, ccp: data, isLoading: true }));
        } catch (error) {
          if (isCancelled) return;
          apiErrorToast(error);
          setState(prevState => ({ ...prevState, isLoading: false }));
        }
      };
      fetchCCP();
      return () => {
        isCancelled = true;
      };
    },
    // eslint-disable-next-line
    [contactCompanyId],
  );
  return (
    <Page>
      <BasicModalBody
        isCustomer={false}
        onChange={onChange}
        watch={watch}
        errors={errors}
        control={control}
        verticalSpaceBeforeSelect={'five'}
      >
        <Grid spacing={'compact'}>
          <GridColumn medium={6}>
            <InputWithController
              label={'Supplier Reference'}
              name={'supplierRef'}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired={false}
            />
          </GridColumn>
          <GridColumn medium={6}>
            <DateTimePickerWithController
              label={'Order Date'}
              name={'orderDate'}
              control={control}
              onChange={onChange}
              errors={errors}
              isRequired
              isDateOnly
              isDefaultNow
            />
          </GridColumn>
          <GridColumn medium={12}>
            <SpaceVerticalContainer className={'half'} />
            <FlexContainer className={'space-between'}>
              <CustomizedLabel label={'PO Items'} />
              <CustomizedLabel label={'Quantity'} />
            </FlexContainer>
          </GridColumn>
          {products.map((product: iProduct) => (
            <GridColumn medium={12} key={product.id}>
              <SpaceVerticalContainer className={'quarter'} />
              <FlexContainer className={'space-between flex-start'}>
                <ComposeSecondaryText
                  secondaryText={handleNullException(product, 'name')}
                >
                  <LinkBtnUrl
                    btnName={handleNullException(product, 'productCode')}
                    url={`${PRODUCTS_URL}/${handleNullException(
                      products[0],
                      'id',
                    )}`}
                  />
                </ComposeSecondaryText>
                <QtyInputContainer>
                  <InputWithController
                    label={''}
                    name={`${product.id}-qty`}
                    defaultValue={'0'}
                    control={control}
                    onChange={onChange}
                    errors={errors}
                    isUnitConversion
                    getUnitConversionSuffix={value =>
                      getUnitConversionSuffix(
                        value,
                        product,
                        state.ccp.find(
                          (item: iContactCompanyProduct) =>
                            item.productId === product.id,
                        ),
                      )
                    }
                    isNumeric
                  />
                </QtyInputContainer>
              </FlexContainer>
            </GridColumn>
          ))}
        </Grid>
      </BasicModalBody>
    </Page>
  );
};

export default DashboardCreatePOModalBody;
