import { iConfigColumn } from '../../../shared/UITypes/types';
import { PRODUCTS_URL } from '../../../shared/UrlMap';

export const DASHBOARD_PRODUCTION = 'Production';
export const RUNNING_JOB = 'Running:';
export const NEXT_JOB = 'Next:';
export const MAXIMUM_JOBS = 3;
export const DATE_FORMAT = 'DD MMM YYYY';
export const DASHBOARD_ORDERS_BY_MACHINE = 'Jobs by machine';
export const DASHBOARD_SCHEDULED = 'Scheduled';
export const DASHBOARD_UNSCHEDULED = 'Unscheduled';
export const DASHBOARD_PURCHASE = 'Purchases';
export const DASHBOARD_SALES = 'Sales';
export const DASHBOARD_FIXED_COLUMNS: iConfigColumn = {
  key: 'sort-type',
  name: '',
  isSelected: true,
  dataSource: [],
  type: 'sort-type',
};
// key&type must be the same as the key in the following state
export const DASHBOARD_SALES_COLUMNS: Array<iConfigColumn> = [
  DASHBOARD_FIXED_COLUMNS,
  {
    key: 'receivedMonth',
    name: 'Orders Received',
    isSelected: true,
    dataSource: [],
    type: 'receivedMonth',
  },
  {
    key: 'dispatchedMonth',
    name: 'Orders Dispatched',
    isSelected: true,
    dataSource: [],
    type: 'dispatchedMonth',
  },
  {
    key: 'invoicedMonth',
    name: 'Orders Invoiced',
    isSelected: true,
    dataSource: [],
    type: 'invoicedMonth',
  },
];
export const DATE_PERIOD = 7;
export const DASHBOARD_INSSTOCKS = 'Insufficient stocks';
export const DASHBOARD_INSSTOCKS_COLUMNS: Array<iConfigColumn> = [
  {
    name: '',
    key: 'rowCheckbox',
    dataSource: [],
    type: 'rowCheckbox',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.id', 'product.name'],
    isCompulsory: true,
    type: 'link-secondary',
    urlPrefix: PRODUCTS_URL,
    isSortable: true,
  },
  {
    name: 'Stock On Hand',
    key: 'product.stockOnHand',
    dataSource: ['product.measurement.shortName'],
    isSelected: true,
    type: 'number-postfix',
  },
  {
    name: 'Required Qty',
    key: 'requiredQty',
    dataSource: ['product.measurement.shortName'],
    isSelected: true,
    type: 'number-postfix',
  },
  {
    name: 'Insufficient Qty',
    key: 'insufficientQty',
    dataSource: [
      'requiredQty',
      'product.stockOnHand',
      'product.measurement.shortName',
    ],
    isSelected: true,
    type: 'number-minue-postfix',
  },
  {
    name: 'Purchased Qty',
    key: 'purchasedQty',
    dataSource: [],
    isSelected: true,
    type: 'sum-purchaseOrderItems',
  },
  {
    name: 'Required By Jobs',
    key: 'requiredByJobs',
    dataSource: [],
    isSelected: true,
    type: 'related-elements',
  },
];
