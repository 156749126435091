/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_URL_LOGIN } from './UrlMap';
import { getMe, removeLocalAuthToken } from '../services/AuthService';
import PageInit from './PageInit';
import { RootState } from '../redux/makeReduxStore';
import { userAuthenticated, removedAuthentication } from '../redux/reduxers/auth.slice';
import ModuleEnabledWrapper from './modulize/ModuleEnabledWrapper';
import ModulingService from '../services/ModulingService';
import ModuleException from './modulize/ModuleException';

type iState = {
  loading?: boolean;
};
const initialState: iState = {
  loading: true,
};
export default (props: RouteProps) => {
  const [state, setState] = useState(initialState);
  const { isAuthenticated, user } = useSelector((s: RootState) => s.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user !== undefined) return;
    let isCancelled = false;
    const getCurrentUser = () => {
      setState(s => ({ ...s, loading: true }));
      getMe()
        .then(res => {
          if (isCancelled === true) return;
          dispatch(userAuthenticated(res));
          setState(s => ({ ...s, loading: false }));
        })
        .catch(() => {
          if (isCancelled === true) return;
          setState(s => ({ ...s, loading: false }));
          removeLocalAuthToken();
          dispatch(removedAuthentication());
        });
    };
    getCurrentUser();
    // eslint-disable-next-line
    return () => {
      isCancelled = true;
    };
  }, [dispatch, user]);

  const redirectLoginComponent = () => <Redirect to={{ pathname: AUTH_URL_LOGIN }} />;

  //  loading
  if (state.loading === true) {
    return <PageInit />;
  }
  // not authenticated
  if (isAuthenticated !== true) {
    return <Route {...props} component={redirectLoginComponent} render={undefined} />;
  }
  // module related
  if (ModulingService.getCheckedModuleFromPath(props.path)) {
    return (
      <ModuleEnabledWrapper
        checkedModule={ModulingService.getCheckedModuleFromPath(props.path)}
        exception={<ModuleException />}
      >
        <Route {...props} />
      </ModuleEnabledWrapper>
    );
  }
  // general
  return <Route {...props} />;
};
