import React from 'react';

import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G400 } from '@atlaskit/theme/colors';
import Flag from '@atlaskit/flag';

const SuccessFlag = ({ title }: { title: string }) => (
  <Flag
    appearance="success"
    icon={<SuccessIcon label="Success" secondaryColor={G400} />}
    id="success"
    key="success"
    title={title}
  />
);

export default SuccessFlag;
