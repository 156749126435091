import { ASYNC_SEARCH_CURRENT_PAGE, ASYNC_SEARCH_PER_PAGE } from '../shared/constants/AsyncConstants';
import { iKeyValuePairs, iParams } from '../shared/UITypes/types';

import AppService from './AppService';
import iSalesOrderType from '../types/sales/iSalesOrderType';

export const getSalesOrderList = (config?: iParams, override = false) => {
  return AppService.get('/salesOrder', {
    ...(override === true
      ? {}
      : {
          include: 'salesOrderItems,customer,shippingAddress,billingAddress,type,createdBy,updatedBy,status,jobs,type',
        }),
    ...config,
  }).then(({ data }) => data);
};

export const getSalesOrderDetail = (id: string) => {
  return AppService.get(`/salesOrder/${id}`, {
    include:
      'customer,shippingAddress,billingAddress,taxBase,type,createdBy,updatedBy,salesOrderItems,salesOrderItems.product.measurement,salesOrderItems.product.palletSystem,status.entityStatusCategory,salesOrderItems.measurement,salesOrderItems.unitPriceMeasurement',
  }).then(({ data }) => data);
};

export const createSalesOrder = (postData: iKeyValuePairs) => {
  return AppService.post('/salesOrder', {
    ...postData,
  }).then(({ data }) => data);
};

export const updateSalesOrder = (orderId: string, updateContent: iKeyValuePairs) => {
  return AppService.put(`/salesOrder/${orderId}`, updateContent).then(({ data }) => data);
};

export const deactivateSale = (id: string) => {
  return AppService.delete(`/salesOrder/${id}`).then(({ data }) => data);
};

export const getSalesOrderTypes = (config?: iParams): Promise<iSalesOrderType[]> => {
  return AppService.get('/salesOrderType', config).then(({ data }) => data);
};

export const getSalesOrderListAsyncSearch = (config?: iParams) => {
  return AppService.get('/salesOrder', {
    ...config,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
    perPage: ASYNC_SEARCH_PER_PAGE,
  }).then(({ data }) => data);
};

export const createInvoiceFromDispatchNote = (dispatchNoteId: string) => {
  return AppService.post(`/salesOrder/dispatchNote/${dispatchNoteId}`, {}).then(({ data }) => data);
};

export const getSOListConfig = (config?: iParams) => {
  return AppService.get('/salesOrder', config).then(({ data }) => data);
};
export const getSODetailConfig = (id: string, config?: iParams) => {
  return AppService.get(`/salesOrder/${id}`, config).then(({ data }) => data);
};

export default {
  getSalesOrderList,
  deactivateSale,
  getSalesOrderDetail,
  createSalesOrder,
  getSalesOrderTypes,
  updateSalesOrder,
  getSalesOrderListAsyncSearch,
  createInvoiceFromDispatchNote,
  getSOListConfig,
  getSODetailConfig,
};
