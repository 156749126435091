import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';

import InputWithController from '../../../shared/form/hookForms/InputWithController';
import iWrappingMethod from '../../../types/iWrappingMethod';

const WrappingMethodEditModal = ({
  control,
  errors,
  onChange,
  target,
}: {
  //    eslint-disable-next-line
      control: Control<Record<string, any>>;
  //    eslint-disable-next-line
      onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
      errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iWrappingMethod;
}) => {
  return (
    <Page>
      <Grid layout={'fluid'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'name'}
            label={'Name'}
            defaultValue={target?.name}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'settings-wrappingMethod-modal-name'}
          />
        </GridColumn>
        <GridColumn medium={6}>
          <InputWithController
            name={'description'}
            label={'Description'}
            defaultValue={target?.description}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired={false}
            testId={'settings-wrappingMethod-modal-description'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default WrappingMethodEditModal;
