import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

const RedirectToCreatedModal = ({
  isOpen,
  onClose,
  onConfirm,
  heading = 'Create successfully',
}: {
  isOpen?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  heading?: string;
}) => {
  if (!isOpen) return null;
  const getModalContent = () => (
    <Modal
      testId="alert-modal"
      actions={[
        {
          text: 'See details',
          onClick: onConfirm,
          appearance: 'primary',
          testId: 'confirm-button',
        },
        {
          text: 'Stay on the page',
          onClick: onClose,
          testId: 'cancel-button',
        },
      ]}
      onClose={onClose}
      heading={heading}
    >
      <span>A new warehouse is created successfully</span>
      <p> Would you like to see the details or continue creating others?</p>
    </Modal>
  );

  return <ModalTransition>{getModalContent()}</ModalTransition>;
};
export default RedirectToCreatedModal;
