import styled from 'styled-components';

const Title = styled.h3`
  margin-top: 0;
  &.space-right {
    margin-right: 0.5rem;
  }
`;

export default Title;
