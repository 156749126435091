import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { useParams } from 'react-router-dom';
import { Content, LeftSidebar, Main } from '@atlaskit/page-layout';

import { iRouteTypeId } from '../../../shared/contactCompany/detail/types';
import { Page, SpinnerWrapper } from '../../../shared/styles/styles';
import { addToastForAPIResponse } from '../../../shared/toast/Toast';
import BackendPage from '../../../layout/BackendPage';
import Header from './Header';
import PoDetail from './PoDetail';
import ReceivingItems from './ReceivingItems';
import { getPoReceivingDetail } from '../../../services/PoReceivingService';
import iPoReceiving from '../../../types/purchases/iPoReceiving';
import PoItems from './PoItems';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import { getWarehouseList } from '../../../services/WarehouseService';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { getEntityCategoryList } from '../../../services/CategoryService';

type iState = {
  // isDeleteModalOpen: boolean;
  version: number;
};

const initialState: iState = {
  version: 0,
};

const PoReceiving = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalState, setModalState] = useState(initialState);
  const [localPoReceiving, setLocalPoReceiving] = useState<iPoReceiving>();
  const [localWarehouseLocations, setLocalWarehouseLocations] = useState<
    iWarehouseLocation[]
  >([]);
  const [localCategories, setLocalCategories] = useState<
    Array<iEntityCategory>
  >([]);

  // po receiving id from url
  const { id } = useParams<iRouteTypeId>();

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const poReceivingData = await getPoReceivingDetail(id);
        const warehouseLocations = await getWarehouseList();
        const categories: Array<iEntityCategory> = await getEntityCategoryList({
          filter:
            'entityStatuses.entityStatusType.entityName:PurchaseOrderReceiving',
        });
        if (!mounted) return;
        setLocalPoReceiving(poReceivingData);
        setLocalWarehouseLocations(warehouseLocations);
        setLocalCategories(categories);
      } catch (e) {
        addToastForAPIResponse('error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
  }, [id, modalState.version]);

  const onRefresh = () => {
    setModalState({ ...modalState, version: modalState.version + 1 });
  };

  const getPageHeader = () => (
    <Header
      poReceiving={localPoReceiving}
      categories={localCategories}
      id={id}
      poId={localPoReceiving?.purchaseOrder?.id}
      onRefresh={onRefresh}
    />
  );

  return isLoading && modalState.version === 0 ? (
    <SpinnerWrapper>
      <Spinner size="xlarge" />
    </SpinnerWrapper>
  ) : (
    <BackendPage pageHeader={getPageHeader()}>
      <Page>
        <PoDetail
          poReceiving={localPoReceiving}
          id={id}
          onRefresh={onRefresh}
        />
        <Content>
          <div css={{ overflowY: 'hidden' }}>
            <LeftSidebar
              isFixed={false}
              width={660}
              id={'page-left-table'}
              shouldPersistWidth
            >
              <PoItems poReceiving={localPoReceiving} />
            </LeftSidebar>
          </div>
          <Main>
            <ReceivingItems
              id={id}
              warehouseLocations={localWarehouseLocations}
              poReceiving={localPoReceiving}
              onRefresh={onRefresh}
            />
          </Main>
        </Content>
      </Page>
    </BackendPage>
  );
};

export default PoReceiving;
