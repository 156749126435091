/* eslint-disable import/no-cycle */
import React, { useCallback, useState } from 'react';
import { iPrefills } from '../../shared/DataImport.types';
import GeneralImportSteps from '../GeneralImportSteps';
import PreCheckContactCompany from './PreCheckContactCompany';
import { SpaceVerticalContainer } from '../../../../../shared/styles/styles';
import { handleNullException } from '../../../../../services/UtilsService';

type iState = {
  preCheckPassed: boolean;
  prefills: iPrefills;
  isLoading: boolean;
};
const initialState: iState = {
  preCheckPassed: false,
  prefills: { typeId: undefined },
  isLoading: true,
};
const ContactCompanyImport = ({
  detailPageName,
}: {
  detailPageName: string;
}) => {
  const [state, setState] = useState(initialState);
  const onPreCheckResult = useCallback(
    (preCheckResult: iPrefills) =>
      setState(prevState => ({
        ...prevState,
        prefills: { ...prevState.prefills, ...preCheckResult },
      })),
    [],
  );
  return (
    <>
      <PreCheckContactCompany
        onPreCheckResult={onPreCheckResult}
        prefills={state.prefills}
        detailPageName={detailPageName}
      />
      <SpaceVerticalContainer className={'half'} />
      {handleNullException(state.prefills, 'typeId') && (
        <GeneralImportSteps
          key={JSON.stringify(state.prefills)}
          detailPageName={detailPageName}
          prefills={state.prefills}
        />
      )}
    </>
  );
};

export default ContactCompanyImport;
