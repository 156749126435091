import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { iConfigColumn } from '../../../pages/despatchNote/shared/DispatchNote.type';
import ShowHideColumns from './ShowHideColumns';

const getSelectedColumns = (columns: iConfigColumn[]) => columns.filter((c: iConfigColumn) => c.isSelected);

// const getStoredColumns = (
//   key: string,
//   columns: Array<iConfigColumn>,
//   isLoadingFlexColumns?: boolean,
// ) => {
//   console.log(isLoadingFlexColumns);
//   console.log(isLoadingFlexColumns);
//   if (isLoadingFlexColumns) return [];
//   const savedValues = localStorage.getItem(key);
//   if (savedValues) {
//     //  eslint-disable-next-line
//     const savedColumns: Array<any> = JSON.parse(savedValues);
//     // remove previous config in storage
//     //  eslint-disable-next-line
//     if (savedColumns.some((save: any) => typeof save === 'object')) {
//       localStorage.removeItem(key);
//       return columns;
//     }
//     const savedColumnsConfig = savedColumns.reduce(
//       (acc: Array<iConfigColumn>, curColumnKey: string) => {
//         const config = columns.find(
//           (c: iConfigColumn) => c.key === curColumnKey,
//         );
//         return config ? [...acc, config] : acc;
//       },
//       [],
//     );
//     return savedColumnsConfig;
//   }
//   return columns;
// };

const useColumnHooksUpgrade = (key: string, columns: Array<iConfigColumn>, isLoadingFlexColumns?: boolean) => {
  const [value, setValue] = useState<Array<iConfigColumn>>([]);
  const onToggleColumn = (columnName: string, isSelected: boolean) => {
    const newValue = value.map((c: iConfigColumn) => (c.name === columnName ? { ...c, isSelected } : c));

    setValue(() => newValue);
    localStorage.setItem(
      key,
      JSON.stringify(newValue.filter((v: iConfigColumn) => v.isSelected).map((v: iConfigColumn) => v.key)),
    );
  };
  const getChangeableColumns = () => {
    const grouped = _.groupBy(columns, 'group');
    return Object.keys(grouped).map((label: string) => ({
      label,
      value: _.get(grouped, label)
        .filter((c: iConfigColumn) => !c.isCompulsory)
        .map((c: iConfigColumn) => c.name),
    }));
  };
  const getShowHideColumns = () => (
    <ShowHideColumns
      selectedColumns={getSelectedColumns(value).map((c: iConfigColumn) => c.name)}
      onToggleColumns={onToggleColumn}
      columnLists={getChangeableColumns()}
    />
  );

  useEffect(
    () => {
      const getStoredColumns = (
        keyParam: string,
        columnsParam: Array<iConfigColumn>,
        isLoadingFlexColumnsParam?: boolean,
      ) => {
        if (isLoadingFlexColumnsParam) return;
        const savedValues = localStorage.getItem(keyParam);
        if (savedValues) {
          //  eslint-disable-next-line
          const savedColumns: Array<any> = JSON.parse(savedValues);
          // remove previous config in storage
          //  eslint-disable-next-line
          if (
            //  eslint-disable-next-line
            savedColumns.some((save: any) => typeof save === 'object') ||
            savedColumns.length === 0
          ) {
            localStorage.removeItem(keyParam);
            setValue(() => columnsParam);
            return;
          }

          const savedColumnsConfig = columnsParam.map((c: iConfigColumn) => ({
            ...c,
            isSelected: savedColumns.includes(c.key),
          }));
          setValue(() => savedColumnsConfig);
          return;
        }
        setValue(() => columnsParam);
      };
      getStoredColumns(key, columns, isLoadingFlexColumns);
    },
    //  eslint-disable-next-line
    [key, isLoadingFlexColumns, JSON.stringify(columns)],
  );

  return {
    columns: getSelectedColumns(value),
    getShowHideColumns,
  };
};
export default useColumnHooksUpgrade;
