import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Checkbox from '@atlaskit/checkbox';
import Tooltip from '@atlaskit/tooltip';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { ucFirst } from '../../../../services/UtilsService';
import { iLabelValuePair } from '../../../../shared/UITypes/types';
//  eslint-disable-next-line
import { useJobSchContext } from '../../JobScheduleContext';
import { iDisabledShift } from './Schedule.type';

const ShiftWrapper = styled.div`
  .title {
    padding-bottom: 8px;
    line-height: 24px;
    display: flex;
    align-items: center;
    .shift-name {
      font-weight: 500;
      font-size: 14px;
      color: rgb(23, 43, 77);
    }
  }
  .reason {
    width: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgb(23, 43, 77);
    padding-bottom: 8px;
    .reason-text {
      padding-left: 4px;
    }
  }
`;

const ShiftTitle = ({ date, shift, isDisabled }: { date: string; shift: iLabelValuePair; isDisabled?: boolean }) => {
  const { disabledShifts, toggleShifts } = useJobSchContext();
  const getCurrentDisableShift = () =>
    disabledShifts.find(
      (d: iDisabledShift) =>
        d.shiftId === shift.value && moment(d.date, 'YYYY-MM-DD').toString() === moment(date, 'DD/MM/YYYY').toString(),
    );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (toggleShifts) {
      toggleShifts(moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'), [shift], e.currentTarget.checked);
    }
  };
  return (
    <ShiftWrapper>
      <div className={'title'}>
        <Tooltip content={'Click to block/un-block the shift'}>
          <Checkbox isChecked={!!getCurrentDisableShift()} onChange={onChange} isDisabled={isDisabled} />
        </Tooltip>
        <div className={'shift-name'}>{ucFirst(shift.label)}</div>
      </div>
      {getCurrentDisableShift() && (
        <div className={'reason'}>
          <WarningIcon label={'warning'} primaryColor={'rgb(255, 139, 0)'} size={'small'} />
          <span className={'reason-text'}>{getCurrentDisableShift()?.reason}</span>
        </div>
      )}
    </ShiftWrapper>
  );
};

export default ShiftTitle;
