import styled from 'styled-components';
import React from 'react';
import moment from 'moment';
import iBillingSubscription from '../../../../types/billing/iBillingSubscription';

const SubscriptionCardWrapper = styled.div`
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 5px;
  display: flex;
  .title-wrapper {
    width: 15%;
  }
  .content-wrapper {
    flex: auto;
  }
`;

const BillingPlanOverview = ({
  subscription,
}: {
  subscription: iBillingSubscription;
}) => {
  return (
    <SubscriptionCardWrapper>
      <div className={'title-wrapper'} data-testid={'title'}>
        Current Plan:
      </div>
      <div className={'content-wrapper'}>
        <div data-testid={'name'}>
          {subscription.billingPlan?.name || 'unk'}
          {/* <small>[<Button appearance={'link'} className={'chgBtn'}>Change</Button>]</small>*/}
        </div>
        <div data-testid={'users'}>
          <small>Users:</small> {subscription.company?.maxNoOfUsers || 'unk'}
        </div>
        <div data-testid={'expiry'}>
          <small>Expiry:</small>{' '}
          {subscription.company?.expiry
            ? moment(subscription.company?.expiry).format('ll')
            : 'n/a'}
        </div>
      </div>
    </SubscriptionCardWrapper>
  );
};

export default BillingPlanOverview;
