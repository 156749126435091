import React from 'react';
import FlexSpaceBetweenContainer from '../styles/FlexSpaceBetweenContainer';
import { iOrderAttribute } from '../types/JobInfoType';
import VerticalLabel from '../VerticalLabel';

const OrderAttributes = ({ attributes }: { attributes: iOrderAttribute[] }) => (
  <FlexSpaceBetweenContainer>
    {attributes.map(attr => (
      <VerticalLabel
        key={attr.label}
        label={attr.label}
        value={attr.value}
        url={attr.url}
        isFirstChild
        className={'vertical-margin-only'}
      />
    ))}
  </FlexSpaceBetweenContainer>
);

export default OrderAttributes;
