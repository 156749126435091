export const COLUMN_NAME_IN_STORAGE = 'admin-jobList-saleOrder-columns';
export const FIXED_COLUMNS = [
  'Product Name',
  'Product Code',
  'Machine',
  'Operator',
  'Status',
  'Scheduled Date',
  'Qty to Make',
  'Qty Total',
];
export const MUST_COLUMNS_BEFORE = ['Job'];
export const MUST_COLUMNS_AFTER = ['Operation'];
export const DEFAULT_COLUMNS = [
  ...MUST_COLUMNS_BEFORE,
  'Product Name',
  'Product Code',
  'Machine',
  'Operator',
  'Status',
  'Scheduled Date',
  ...MUST_COLUMNS_AFTER,
];

export const SO_TYPE_NAME_QUOTE = 'QUOTE';
export const SO_TYPE_NAME_SALES_ORDER = 'ORDER';
export const SO_TYPE_NAME_INVOICE = 'INVOICE';
export const INVOICE = SO_TYPE_NAME_INVOICE;
