import {
  ASYNC_SEARCH_CURRENT_PAGE,
  ASYNC_SEARCH_PER_PAGE,
} from '../shared/constants/AsyncConstants';
import { iParams } from '../shared/UITypes/types';
import AppService from './AppService';

export const getContactCompanyProducts = (config?: {
  [key: string]: string;
}) => {
  return AppService.get('/contactCompanyProduct', {
    include:
      'product,product.measurement,contactCompany,contactCompany.type,measurement,unitPriceMeasurement,serviceType',
    ...config,
  }).then(({ data }) => data);
};

export const createContactCompanyProduct = (postData: {
  [key: string]: string;
}) => {
  return AppService.post('/contactCompanyProduct', postData).then(
    ({ data }) => data,
  );
};

export const updateContactCompanyProduct = (
  id: string,
  updateContent: { [key: string]: string },
) => {
  return AppService.put(`/contactCompanyProduct/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const deleteContactCompanyProduct = (id: string) => {
  return AppService.delete(`/contactCompanyProduct/${id}`).then(
    ({ data }) => data,
  );
};

export const getCCPListAsyncSearch = (config?: iParams) => {
  return AppService.get('/contactCompanyProduct', {
    include: 'product',
    ...config,
    perPage: ASYNC_SEARCH_PER_PAGE,
    currentPage: ASYNC_SEARCH_CURRENT_PAGE,
  }).then(({ data }) => data);
};

export const getCCPConfig = (config?: iParams) => {
  return AppService.get('/contactCompanyProduct', config).then(
    ({ data }) => data,
  );
};

export default {
  getContactCompanyProducts,
  createContactCompanyProduct,
  updateContactCompanyProduct,
  deleteContactCompanyProduct,
  getCCPListAsyncSearch,
  getCCPConfig,
};
