import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
import { Label } from '@atlaskit/field-base';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import InlineEdit from '../../../shared/form/InlineEdit';
import InlineCheckbox from '../../../shared/form/InlineCheckbox';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import { handleNullException } from '../../../services/UtilsService';
import { LabelValue } from '../../../shared/styles/styles';
import { iDetailUpdateOneFieldFn, iParams } from '../../../shared/UITypes/types';
import { ALLOW_PARTS } from '../shared/Warehouse.constants';
import { getWarehouseLocationPathNames } from '../../../services/WarehouseService';
import { apiErrorToast } from '../../../shared/toast/Toast';

// const SpaceTopWrapper = styled.div`
//   padding-top: 0.6rem;
// `;
const defaultFacilityLocationIds = {
  lostAndFoundLocationId: undefined,
  defaultInwardsLocationId: undefined,
  defaultOutwardsLocationId: undefined,
};
type iState = {
  isLoading: boolean;
  idNameMap?: iParams;
};
const initialState: iState = {
  isLoading: false,
};
const BasicDetail = ({
  warehouse,
  onUpdate,
  isDisabled,
}: {
  warehouse: iWarehouseLocation;
  onUpdate: iDetailUpdateOneFieldFn;
  isDisabled?: boolean;
}) => {
  const { facility } = warehouse;
  const { lostAndFoundLocationId, defaultInwardsLocationId, defaultOutwardsLocationId } =
    facility || defaultFacilityLocationIds;
  const [state, setState] = useState(initialState);
  useEffect(() => {
    let isCancelled = false;
    const fetchLocationPathName = async () => {
      if (
        typeof lostAndFoundLocationId === 'undefined' ||
        typeof defaultInwardsLocationId === 'undefined' ||
        typeof defaultOutwardsLocationId === 'undefined'
      )
        return;
      try {
        const ids = [defaultInwardsLocationId, defaultOutwardsLocationId, lostAndFoundLocationId];
        const stringIds = JSON.stringify(ids);
        const { idNameMap } = await getWarehouseLocationPathNames(stringIds);
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          idNameMap,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({ ...prevState, isLoading: false }));
      }
    };
    fetchLocationPathName();
    return () => {
      isCancelled = true;
    };
  }, [lostAndFoundLocationId, defaultInwardsLocationId, defaultOutwardsLocationId]);
  return (
    <Page>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={3}>
          <InlineEdit
            name={'name'}
            label={'Name'}
            defaultValue={warehouse?.name}
            onConfirm={onUpdate}
            isRequired
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEdit
            name={'locationCode'}
            label={'Code'}
            defaultValue={warehouse.locationCode}
            onConfirm={onUpdate}
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineCheckbox
            defaultValue={warehouse.allowParts}
            name={'allowParts'}
            onConfirm={onUpdate}
            label={ALLOW_PARTS}
            isDisabled={isDisabled}
          />
        </GridColumn>
        <GridColumn medium={3}>
          <InlineEdit
            name={'description'}
            label={'Description'}
            defaultValue={warehouse.description}
            onConfirm={onUpdate}
            isDisabled={isDisabled}
          />
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        {handleNullException(state, `idNameMap.${defaultInwardsLocationId}`) && (
          <GridColumn medium={3}>
            <Label label="Goods inwards" />
            <LabelValue>{handleNullException(state, `idNameMap.${defaultInwardsLocationId}`)}</LabelValue>
          </GridColumn>
        )}
        {handleNullException(state, `idNameMap.${defaultOutwardsLocationId}`) && (
          <GridColumn medium={3}>
            <Label label="Goods outwards" />
            <LabelValue>{handleNullException(state, `idNameMap.${defaultOutwardsLocationId}`)}</LabelValue>
          </GridColumn>
        )}
        {handleNullException(state, `idNameMap.${lostAndFoundLocationId}`) && (
          <GridColumn medium={3}>
            <Label label="Lost&found" />
            <LabelValue>{handleNullException(state, `idNameMap.${lostAndFoundLocationId}`)}</LabelValue>
          </GridColumn>
        )}
        <GridColumn medium={1.5}>
          <Label label="Created" />
          <LabelValue>
            <CreatedOrUpdated
              operatedAt={handleNullException(warehouse, 'createdAt')}
              operator={handleNullException(warehouse, 'createdBy.firstName')}
            />
          </LabelValue>
        </GridColumn>
        <GridColumn medium={1.5}>
          <Label label="Updated" />
          <LabelValue>
            <CreatedOrUpdated
              operatedAt={handleNullException(warehouse, 'updatedAt')}
              operator={handleNullException(warehouse, 'updatedBy.firstName')}
            />
          </LabelValue>
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default BasicDetail;
