import React, { useState } from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import DynamicTable from '@atlaskit/dynamic-table';
import iProductServiceType from '../../../types/contactCompany/iProductServiceType';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import LinkBtn from '../../../shared/buttons/LinkBtn';
import TransparentIconBtn from '../../../shared/buttons/TransparentIconBtn';
import { getHeads, handleNullException } from '../../../services/UtilsService';
import { iConfigColumn, iSortOrderType } from '../../../shared/UITypes/types';

export const getRows = ({
  data,
  onOpenDeleteModal,
  onOpenEditModal,
  columns,
}: {
  data: Array<iProductServiceType>;
  onOpenDeleteModal: (id: string) => void;
  onOpenEditModal: (id: string) => void;
  columns: Array<iConfigColumn>;
}) => {
  return data.map((item: iProductServiceType) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: <LinkBtn btnName={handleNullException(item, key)} onClick={() => onOpenEditModal(item.id)} />,
          };

        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        case 'delete':
          return {
            content: (
              <TransparentIconBtn onClick={() => onOpenDeleteModal(item.id)} icon={<TrashIcon label="delete" />} />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'service-type-table' };
  });
};

const ServiceTypeTable = ({
  pageName,
  data,
  isLoading,
  columns,
  onSetSort,
  onOpenDeleteModal,
  onOpenEditModal,
  canDelete = true,
  canUpdate = true,
}: {
  pageName: string;
  data: Array<iProductServiceType>;
  isLoading: boolean;
  columns: Array<iConfigColumn>;
  onSetSort: (sortStr: string) => void;
  onOpenDeleteModal: (id: string) => void;
  onOpenEditModal: (id: string) => void;
  canDelete?: boolean;
  canUpdate?: boolean;
}) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  return (
    <DynamicTable
      testId={'serviceTypeTbl'}
      head={getHeads(columns, pageName)}
      rows={getRows({
        data,
        onOpenDeleteModal,
        onOpenEditModal,
        columns: columns
          .filter(col => [...(canDelete ? [] : ['delete'])].indexOf(col.key) < 0)
          .map(col => (`${col.key}`.trim().toLowerCase() === 'name' && !canUpdate ? { ...col, type: 'text' } : col)),
      })}
      isLoading={isLoading}
      onSort={
        //  eslint-disable-next-line
        (data: any) => {
          setSortKey(data.key);
          setSortOrder(data.sortOrder);
          onSetSort(`${data.key}:${data.sortOrder}`);
        }
      }
      sortKey={sortKey}
      sortOrder={sortOrder}
    />
  );
};

export default ServiceTypeTable;
