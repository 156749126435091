import React from 'react';
import { getAOList } from '../../../services/AcquisitionOrderService';
import {
  assembleAddress,
  formatDate,
  getCategoryCode,
  handleMoney,
  handleNullException,
} from '../../../services/UtilsService';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import StatusLozenge from '../../../shared/statusComponent/StatusLozenge';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';
import iEntityCategory from '../../../types/status/iEntityCategory';
import { iConfigColumn } from '../../despatchNote/shared/DispatchNote.type';

import FetchRelatedElements from './FetchRelatedElements';
import { AOS_URL } from '../../../shared/UrlMap';
import CompanyLinkBtn from '../../../shared/buttons/CompanyLinkBtn';
import PopupContent from '../../../shared/popup/GeneralPopupContent';
import GeneralPrintBtn from '../../../shared/buttons/GeneralPrintBtn';
import { printPurchaseOrder } from '../../../services/PrintService';
import { PrintBtnName } from '../../../shared/styles/styles';
// import iAcquisitionOrder from '../../../types/acquisitionOrder/iAcquisitionOrder';

export const getHeads = (columns: Array<iConfigColumn>, tableName: string) => {
  const cells = columns.map((column: iConfigColumn) => {
    switch (column.type) {
      case 'operation':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      default:
        return {
          key: column.key,
          content: column.name,
          testId: `${tableName}-column-${column.key}`,
          isSortable: !!column.isSortable,
        };
    }
  });
  return { cells };
};

export const getRows = (
  data: Array<iPurchaseOrderDetail>,
  onOpenDeleteModal: ((id: string) => void) | undefined,
  columns: Array<iConfigColumn>,
  categories: Array<iEntityCategory>,
) => {
  return data.map((po: iPurchaseOrderDetail) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type, isDisabled } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(po, key),
            content: handleNullException(po, key),
          };
        case 'date':
          return {
            key: handleNullException(po, key) || 'ZZZ',
            content: formatDate(handleNullException(po, key), 'DD MMM YYYY'),
          };
        case 'money':
          return {
            key: handleNullException(po, key),
            content: handleMoney(po, key, 2),
          };
        case 'address':
          return {
            key: handleNullException(po, key),
            content: assembleAddress(handleNullException(po, key)),
          };
        case 'link':
          return {
            key: handleNullException(po, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(po, key)}
                url={`${column.urlPrefix}/${handleNullException(po, dataSource[0])}`}
              />
            ),
          };
        case 'company-link':
          return {
            key: handleNullException(po, key),
            content: isDisabled ? (
              `${handleNullException(po, dataSource[0]).name || ''}`
            ) : (
              <CompanyLinkBtn company={handleNullException(po, dataSource[0])} />
            ),
          };
        case 'status':
          return {
            key: handleNullException(po, `${key}.id`),
            content: (
              <StatusLozenge
                status={handleNullException(po, key)}
                categoryCode={getCategoryCode(handleNullException(po, key), categories)}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(po, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(po, key)}
                operator={handleNullException(po, dataSource[0])}
              />
            ),
          };
        case 'operation':
          return {
            content: (
              <MoreIconPopup>
                <PopupContent id={po.id} onOpenDeleteModal={onOpenDeleteModal}>
                  <GeneralPrintBtn serviceFunc={() => printPurchaseOrder(po.id)}>
                    <PrintBtnName>Print</PrintBtnName>
                  </GeneralPrintBtn>
                </PopupContent>
              </MoreIconPopup>
            ),
          };
        case 'related-elements':
          return {
            content: (
              <FetchRelatedElements
                key={po.id}
                fetchFn={() =>
                  getAOList({
                    advancedFilter: `AcquisitionOrderItem_id_orderId.PurchaseOrderItem_id_acquisitionOrderItemId.orderId:${po.id}`,
                    sort: 'createdAt:DESC',
                  })
                }
                renderType={'link'}
                dataSource={['id', 'orderNumber']}
                urlPrefix={AOS_URL}
                readOnly={isDisabled}
              />
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: 'admin-purchaseOrder-row' };
  });
};

export const getReportArrSegments = (
  data: iPurchaseOrderDetail[],
  selectedColumns: iConfigColumn[],
  // categories: Array<iEntityCategory>,
) => {
  const selectedColumnNames = selectedColumns
    .map((column: iConfigColumn) => column.name)
    .filter((columnName: string) => columnName !== '');

  const reportCells = data.map((item: iPurchaseOrderDetail) => {
    return selectedColumns.map((column: iConfigColumn) => {
      const { key, dataSource, type, alternativeKey } = column;
      switch (type) {
        case 'text':
          return handleNullException(item, key);
        case 'link':
          return handleNullException(item, key) || handleNullException(item, alternativeKey || '');
        case 'company-link':
          return handleNullException(item, 'supplier.name');
        case 'address':
          return assembleAddress(handleNullException(item, key));
        case 'date':
          return (
            formatDate(handleNullException(item, key), 'DD MMM YYYY') ||
            formatDate(handleNullException(item, alternativeKey || ''), 'DD MMM YYYY')
          );
        case 'money':
          return handleMoney(item, key, 2);
        case 'status':
          return handleNullException(item, 'status.name');
        case 'history':
          return `${handleNullException(item, dataSource[0])} @ ${formatDate(
            handleNullException(item, key),
            'DD MMM YYYY',
          )}`;
        // case 'related-elements':
        //   // eslint-disable-next-line no-case-declarations
        //   const AOs = await getAOList({
        //     advancedFilter: `AcquisitionOrderItem_id_orderId.PurchaseOrderItem_id_acquisitionOrderItemId.orderId:${item.id}`,
        //     sort: 'createdAt:DESC',
        //   });
        //   console.log(AOs);
        //   return AOs.data
        //     .map((ao: iAcquisitionOrder) => ao.orderNumber)
        //     .toString();
        default:
          return '';
      }
    });
  });

  return [selectedColumnNames, ...reportCells];
};
