import React, { useState } from 'react';
import { Label } from '@atlaskit/field-base';
import styled from 'styled-components';
import RichTextEditor, { iRichTextEditor } from './RichTextEditor';

type iInlineRichTextEditor = iRichTextEditor;

const Wrapper = styled.div`
  .viewer {
    width: 100%;
    min-height: 10rem;
    max-height: 20rem;
    display: block;
    overflow: auto;
    cursor: pointer;
    padding: 5px;
    :hover {
      background: #ebecf0;
    }

    &.disabled {
      cursor: auto;
      :hover {
      }
    }
  }
`;
const InlineRichTextEditor = ({
  defaultValue,
  onChangeConfirmed,
  label,
  testId,
  disabled,
  ...props
}: iInlineRichTextEditor) => {
  const [isEditing, setIsEditing] = useState(false);

  const getLabel = () => {
    if (!label) {
      return null;
    }
    if (typeof label === 'string') {
      return <Label label={label} />;
    }
    return label;
  };

  const handleOnChangConfirmed = (newText: string) => {
    setIsEditing(false);
    if (newText !== defaultValue && onChangeConfirmed) {
      onChangeConfirmed(newText);
    }
  };

  const getContent = () => {
    if (!isEditing) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          dangerouslySetInnerHTML={{ __html: defaultValue || '' }}
          className={`viewer ${disabled ? 'disabled' : ''}`}
          data-testid={'viewer'}
          onClick={() => (disabled ? null : setIsEditing(true))}
        />
      );
    }
    return (
      <RichTextEditor
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        disabled={disabled}
        defaultValue={defaultValue}
        onChangeConfirmed={handleOnChangConfirmed}
        onCancel={() => setIsEditing(false)}
      />
    );
  };

  return (
    <Wrapper data-testid={testId || 'InlineRichTextEditor'}>
      {getLabel()}
      {getContent()}
    </Wrapper>
  );
};

export default InlineRichTextEditor;
