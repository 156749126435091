import React, { useState } from 'react';
import _ from 'lodash';
import ConCalReadEditView from './ConCalReadEditView';
import { ErrorMsg } from '../styles/styles';
import { NUMBER_ROUND_DECIMAL } from '../constants/ActionConstants';

type iState = {
  value: string | number | undefined;
  errorMessage?: string;
};

const ConversionCalculateText = ({
  label,
  name,
  onConfirm,
  defaultValue,
  isDisabled = false,
  isRequired = true,
  productUnit,
  contactCompanyUnit,
  conversion,
}: {
  label?: string;
  name: string;
  onConfirm: (name: string, newValue: string | number) => void;
  defaultValue?: string | number;
  isDisabled?: boolean;
  isRequired?: boolean;
  conversion: number;
  productUnit: string;
  contactCompanyUnit: string;
}) => {
  const initialState: iState = {
    value: defaultValue || '',
  };
  const [state, setState] = useState(initialState);
  //  eslint-disable-next-line
  const getValue = (e: any) => {
    if (typeof e === 'string' || typeof e === 'number') {
      return e;
    }
    return e.currentTarget.value || '';
  };
  //  eslint-disable-next-line
  const onChange = (e: any) => {
    const extractedValue = getValue(e);
    if (isRequired && extractedValue === '') {
      setState({
        ...state,
        value: extractedValue,
        errorMessage: 'is Required',
      });
    } else {
      setState({ ...state, value: extractedValue, errorMessage: undefined });
    }
  };

  const onBlur = () => {
    if (
      state.errorMessage ||
      Number(state.value) === Number(initialState.value)
    )
      return;
    onConfirm(
      name,
      state.value ? _.round(Number(state.value), NUMBER_ROUND_DECIMAL) : '',
    );
  };

  return (
    <>
      <ConCalReadEditView
        onBlur={onBlur}
        onChange={onChange}
        value={state.value}
        productUnit={productUnit}
        contactCompanyUnit={contactCompanyUnit}
        conversion={conversion}
        isDisabled={isDisabled}
        label={label}
        name={name}
      />
      {state.errorMessage && <ErrorMsg>{state.errorMessage}</ErrorMsg>}
    </>
  );
};

export default ConversionCalculateText;
