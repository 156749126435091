import React from 'react';
import { colors } from '@atlaskit/theme';
import { FlexSpaceBetweenContainer } from '../style';
import PopupContent from './popup/PopupContent';
import MoreIconPopup from '../../popup/MoreIconPopup';

const DetailHeading = ({
  name,
  id,
  onOpenDeleteModal,
}: {
  name: string;
  id: string;
  onOpenDeleteModal?: (id: string) => void;
}) => {
  return (
    <FlexSpaceBetweenContainer>
      <h3>{name}</h3>
      <div style={{ backgroundColor: `${colors.B50}` }}>
        {onOpenDeleteModal ? (
          <MoreIconPopup>
            <PopupContent id={id} onOpenModal={onOpenDeleteModal} btnName={'Delete'} />
          </MoreIconPopup>
        ) : null}
      </div>
    </FlexSpaceBetweenContainer>
  );
};

export default DetailHeading;
