import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import iPayment from '../../../../types/integration/iPayment';
import useListCrudHook from '../../../../shared/hooks/useListCrudHook/useListCrudHook';
import { getPayments } from '../../../../services/integration/PaymentService';
import { PAYMENTS_COLUMNS, PAYMENTS_LIST_NAME } from './PaymentList.constants';
import { formatDate, getHeads, handleMoney, handleNullException } from '../../../../services/UtilsService';
import CustomizePagination from '../../../../shared/pagination/CustomizePagination';
import CreatedOrUpdated from '../../../../shared/createdOrUpdated/CreatedOrUpdated';
import { iConfigColumn } from '../../../../shared/UITypes/types';

export const getRows = (data: Array<iPayment>, columns: Array<iConfigColumn>) => {
  return data.map((item: iPayment) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'date':
          return {
            key: handleNullException(item, key),
            content: formatDate(handleNullException(item, key), 'Do MMM YYYY'),
          };
        case 'money':
          return {
            key: handleNullException(item, key),
            content: handleMoney(item, key, 2),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        default:
          return { content: handleNullException(item, key) };
      }
    });
    return { cells, testId: 'item-location' };
  });
};
const PaymentList = ({ modelName, modelId }: { modelName: string; modelId: string }) => {
  const { state, onSetCurrentPage } = useListCrudHook<iPayment>({
    getFn: getPayments,
    filter: `paidForEntityName:${modelName},paidForEntityId:${modelId}`,
    sort: 'createdAt:DESC',
    paginationApplied: true,
    perPage: 10,
  });
  return (
    <div>
      <DynamicTable
        head={getHeads(PAYMENTS_COLUMNS, PAYMENTS_LIST_NAME)}
        rows={getRows(state.data, PAYMENTS_COLUMNS)}
        isLoading={state.isLoading}
        isFixedSize
      />
      {!!state.total && !!state.perPage && !!state.currentPage && (
        <CustomizePagination
          range={Math.ceil(state.total / state.perPage)}
          currentPage={state.currentPage}
          onChange={onSetCurrentPage}
        />
      )}
    </div>
  );
};

export default PaymentList;
