import { RegisterOptions } from 'react-hook-form';
import React from 'react';
import { ModuleCode } from '../../types/iModuleCode';
import { iAccessCode } from '../../types/settings/UserAccess/iAccess';

export type iLabelValuePair = {
  label: string;
  value: string;
};

export type iAdvancedLabelValuePair = {
  label: string | number | React.ReactElement;
  value: string;
};

export type iLabelArrayValuePair = {
  label: string;
  value: Array<string>;
};

export type iTableHead = {
  content?: string;
  key: string;
  isSortable?: boolean;
  truncate?: boolean;
  width?: number;
  testId?: string;
};

export type iParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type iBtnAppearance =
  | 'link'
  | 'default'
  | 'danger'
  | 'primary'
  | 'subtle'
  | 'subtle-link'
  | 'warning'
  | undefined;

export type iAddressPopup = {
  name?: string;
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
};

export type iColumnsProps = {
  name: string;
  default: Array<string>;
  mustBefore: Array<string>;
  mustAfter: Array<string>;
  positionFixed: Array<string>;
};
export type iSortOrderType = 'ASC' | 'DESC' | undefined;

export type iKeyValuePairs = {
  [key: string]: string | boolean | number | null | undefined;
};

export type iFetchResult<T> = {
  data: Array<T>;
  from?: number;
  to?: number;
  currentPage?: number;
  perPage?: number;
  total?: number;
};

export type iRowContent = {
  [key: string]: string | boolean | number | React.ReactNode;
};

export type iConfigColumn = {
  name: string;
  key: string;
  dataSource: Array<string>;
  urlPrefix?: string;
  type: string;
  isCompulsory?: boolean;
  isSortable?: boolean;
  defaultSelected?: boolean;
  group?: string;
  isSelected?: boolean;
  moduleCode?: ModuleCode;
  isDisabled?: boolean;
};

export type iDetailUpdateFn = (
  // eslint-disable-next-line
  updateContent: any,
  needRefetch?: boolean,
) => // eslint-disable-next-line
Promise<any>;

export type iDetailUpdateOneFieldFn = (
  fieldName: string,
  // eslint-disable-next-line
  fieldValue: any,
  needRefetch?: boolean,
) => // eslint-disable-next-line
Promise<any>;

export type iDetailUpdateEagerLoadFn = (
  // eslint-disable-next-line
  updateContent: any,
  needRefetch?: boolean,
) => void;

export type iDetailUpdateFieldAndEagerLoadFn = (
  name: string,
  // eslint-disable-next-line
  value: any,
  eagerLoadField: string,
) => // eslint-disable-next-line
Promise<any>;

export type iRouteTypeId = {
  id: string;
};

export type iSortOrder = {
  id: string;
  sortOrder: number;
};

export type iOption = {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

export type iLabelKeyPair = {
  label: string;
  key: string;
};

export type iHookFormField = iLabelValuePair & {
  type?: 'text' | 'number' | 'password' | 'email';
  // eslint-disable-next-line
  initialValue?: any;
  rules: RegisterOptions;
  helperMessage?: string | React.ReactNode;
  size: number;
  isDisabled?: boolean;
  compareTarget?: string;
};

export type iValidationPassword = {
  isValid: boolean;
  suggestions: Array<string>;
};

export type iStripeInvoice = {
  created: number;
  // eslint-disable-next-line camelcase
  amount_due: number;
  // eslint-disable-next-line camelcase
  invoice_pdf?: string;
  id: string;
};

export type iMenuItem = {
  label: string;
  value: string;
  hasSeparator?: boolean;
  moduleCode?: ModuleCode;
  accessCodes?: iAccessCode[];
};

export enum SUB_MENU_NAME {
  Sales = 'Sales',
  Purchases = 'Purchases',
  Makes = 'Makes',
  Stock = 'Stock',
  Issues = 'RMA',
  Reports = 'Reports',
  Contacts = 'Contacts',
}
export type iPrimaryMenuExceptHome = {
  name: SUB_MENU_NAME;
  subMenu: Array<iMenuItem>;
  accessCodes?: iAccessCode[];
};
