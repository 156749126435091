import React from 'react';
import { useParams } from 'react-router-dom';
import { SUPPLIER } from '../../shared/contactCompany/constants';
import ContactCompanyDetail from '../../shared/contactCompany/detail/ContactCompanyDetail';
import { iRouteTypeId } from '../../shared/contactCompany/detail/types';
import { SUPPLIER_URL } from '../../shared/UrlMap';
import { SUPPLIER_PAGE_NAME } from './list/ContactCompany.constants';

const SupplierDetail = () => {
  const { id } = useParams<iRouteTypeId>();
  return <ContactCompanyDetail id={id} type={SUPPLIER} urlPrefix={SUPPLIER_URL} upperPageName={SUPPLIER_PAGE_NAME} />;
};

export default SupplierDetail;
