import Button from '@atlaskit/button';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import Spinner from '@atlaskit/spinner';
import moment from 'moment';
import React, { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import Tooltip from '@atlaskit/tooltip';
import styled from 'styled-components';
import ChannelService from '../../../services/integration/ChannelService';
import CheckboxWithController from '../../form/hookForms/CheckboxWithController';
import Textfield from '../../form/Textfield';
import { FlexContainer } from '../../styles/styles';
import { apiErrorToast } from '../../toast/Toast';
import iChannel from '../../../types/integration/iChannel';
import myobLogoSVG from '../../integrations/svgs/myob-logo.svg';
import { getCurrentBaseUrl, handleNullException } from '../../../services/UtilsService';
import iChannelScript from '../../../types/integration/iChannelScript';
import ChannelScriptService from '../../../services/integration/ChannelScriptService';
import { SETTINGS_INTEGRATIONS_CALLBACK_URL } from '../../UrlMap';

const MyobFormContainer = styled.div`
  .firstCol {
    margin-right: 10px;
    min-width: 400px;
  }
  .secondCol {
    min-width: 200px;
  }
  .linkBtn {
    margin-left: 10px;
    margin-bottom: 4px;
  }
  .logo {
    max-width: 220px;
  }
  .logo img {
    width: 100%;
  }
`;

type iMyobChannelForm = {
  channel: iChannel;
  channelScript: iChannelScript;
  callback: (channelResp: iChannel) => void;
};
const MYOB_APP_URL = 'https://app.myob.com/#/au/{PROFILE_ID}/dashboard';
const MyobChannelForm = ({ channel, channelScript, callback }: iMyobChannelForm) => {
  const { control } = useForm({});
  const [isGettingOauthUrl, setIsGettingOauthUrl] = useState(false);

  const myobOauthHandler = () => {
    setIsGettingOauthUrl(true);
    ChannelScriptService.getChannelScriptOauth2Url(channelScript.id, {
      redirectUrl:
        process.env.REACT_APP_MYOB_REDIRECT_URL || `${getCurrentBaseUrl()}${SETTINGS_INTEGRATIONS_CALLBACK_URL}`,
      channelId: channel.id,
    })
      .then(resp => {
        const { url: redirectUrl } = resp;
        window.location.href = `${redirectUrl}&state=${channel.id}`;
      })
      .catch(err => {
        apiErrorToast(err);
      })
      .finally(() => {
        setIsGettingOauthUrl(false);
      });
  };

  const handleOrderNumOverwriteChange = async (name: string, value: string) => {
    try {
      const updatedChannel = await ChannelService.updateChannel(channel.id, {
        settings: {
          ...channel.settings,
          [name]: value,
        },
      });
      callback(updatedChannel);
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const infoWithToolTip = (info: string | ReactNode, text?: string) => {
    return (
      <div style={{ display: 'flex' }}>
        {text || ''}
        <Tooltip content={info}>
          <EditorInfoIcon label={'test'} size={'small'} />
        </Tooltip>
      </div>
    );
  };

  const getToolTipText = (type: string) => {
    return (
      <div>
        <div>{`- Enabled, all ${type}s will be created in MYOB with systems' ${type} number`}</div>
        <div>{`- Disabled, all ${type}s will be created in MYOB with MYOB's auto generated ${type} number.`}</div>
      </div>
    );
  };

  return (
    <MyobFormContainer>
      <FlexContainer className="flex-start space-between">
        <div>
          <FlexContainer className="space-between align-items-flex-end">
            <div className="firstCol">
              <Textfield defaultValue={channel.name} label="Name" name="name" isDisabled />
              <Textfield
                defaultValue={handleNullException(channel, 'settings.auth.accessToken')}
                label="Oauth Access Token"
                name="oauthAccessToken"
                isDisabled
              />
            </div>
            <FlexContainer className="space-between align-items-flex-end">
              <div className="secondCol">
                <a
                  href={MYOB_APP_URL.replace('{PROFILE_ID}', handleNullException(channel, 'settings.companyFileId'))}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ShortcutIcon label="link" />
                </a>
                <Textfield
                  defaultValue={moment(
                    handleNullException(channel, 'settings.auth.accessTokenExpiresAt'),
                  ).toLocaleString()}
                  label="Expiry At"
                  name="oauthAccessToken"
                  isDisabled
                />
              </div>
              <div className="linkBtn">
                <Button appearance="primary" onClick={myobOauthHandler} isDisabled={isGettingOauthUrl}>
                  {isGettingOauthUrl ? <Spinner /> : 'Reconnect'}
                </Button>
              </div>
            </FlexContainer>
          </FlexContainer>
        </div>

        <div className="logo">
          <img src={myobLogoSVG} alt="MYOB logo" />
        </div>
      </FlexContainer>

      <CheckboxWithController
        defaultValue={channel.settings?.overwriteInvoiceNumber === true}
        label={infoWithToolTip(getToolTipText('Invoice'), 'Overwrite Invoice Number')}
        name={'overwriteInvoiceNumber'}
        onChange={handleOrderNumOverwriteChange}
        control={control}
      />
      <CheckboxWithController
        defaultValue={channel.settings?.overwritePurchaseOrderNumber === true}
        label={infoWithToolTip(getToolTipText('Purchase Order'), 'Overwrite Purchase Order')}
        name={'overwritePurchaseOrderNumber'}
        onChange={handleOrderNumOverwriteChange}
        control={control}
      />
    </MyobFormContainer>
  );
};

export default MyobChannelForm;
