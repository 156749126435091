import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ActionMeta, ValueType, PopupSelect } from '@atlaskit/select';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import iJobCategory from '../../../../types/job/iJobCategory';
import iJobStatus from '../../../../types/job/iJobStatus';
import JobStatusLozenge from './JobStatusLozenge';
import './JobStatusMenu.css';

const ButtonWrapper = styled.div`
  .status-menu-button {
    background-color: transparent;
    align-items: center;
    &:hover {
      background-color: transparent;
    }
  }
`;
type iOption = {
  label: React.ReactNode;
  value: string;
  status: iJobStatus;
  categoryCode: string;
};
type iState = {
  options: Array<iOption>;
  value?: iOption;
  wholeOptions: Array<iOption>;
};
const initialState: iState = {
  options: [],
  wholeOptions: [],
};
const StatusMenu = ({
  defaultValue,
  onSelect,
  categories,
  isDisabled,
}: {
  defaultValue?: string;
  isDisabled?: boolean;
  onSelect: (newValue: string) => void;
  categories: Array<iJobCategory>;
}) => {
  const [state, setState] = useState(initialState);
  useEffect(
    () => {
      const generateOptions = () =>
        categories.reduce((acc: Array<iOption>, category: iJobCategory) => {
          const statusInCategory = category.jobStatuses.reduce(
            (accStatus: Array<iOption>, status: iJobStatus) => [
              ...accStatus,
              {
                label: <JobStatusLozenge status={status} categoryCode={category.name} />,
                value: status.id,
                status,
                categoryCode: category.name,
              },
            ],
            [],
          );
          return [...acc, ...statusInCategory];
        }, []);
      const wholeOptions = generateOptions();
      setState(prevState => ({ ...prevState, wholeOptions }));
    }, //  eslint-disable-next-line
    [JSON.stringify(categories)],
  );

  useEffect(
    () => {
      setState(prevState => ({
        ...prevState,
        value: prevState.wholeOptions.find((option: iOption) => option.value === defaultValue),
        options: prevState.wholeOptions.filter((option: iOption) => option.value !== defaultValue),
      }));
    },
    //  eslint-disable-next-line
    [defaultValue, JSON.stringify(state.wholeOptions)],
  );

  const onChange = async (
    value: ValueType<iOption>,
    //  eslint-disable-next-line
    action: ActionMeta<iOption>,
  ) => {
    //    value is null/undefined
    if (!value) return;
    const statusId = (value as iOption).value;
    onSelect(statusId);
  };
  return (
    <PopupSelect
      options={state.options}
      onChange={onChange}
      isSearchable={false}
      isDisabled={isDisabled}
      target={({ ref }) => (
        <ButtonWrapper>
          <Button
            testId="popup-trigger-btn"
            spacing={'none'}
            ref={ref}
            className={'status-menu-button'}
            isDisabled={isDisabled}
          >
            <JobStatusLozenge status={state.value?.status} categoryCode={state.value?.categoryCode}>
              <ChevronDownIcon label={'print'} />
            </JobStatusLozenge>
          </Button>
        </ButtonWrapper>
      )}
      maxMenuWidth={'200'}
      minMenuWidth={'120'}
      className={'status-menu'}
      classNamePrefix={'status-menu-prefix'}
    />
  );
};

export default StatusMenu;
