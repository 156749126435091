import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Control, DeepMap, FieldError } from 'react-hook-form';

import iCourier from '../../../types/courier/iCourier';
import InputWithController from '../../../shared/form/hookForms/InputWithController';

const CourierEditModal = ({
  control,
  errors,
  onChange,
  target,
}: {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iCourier;
}) => {
  return (
    <Page>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={6}>
          <InputWithController
            name={'name'}
            label={'Courier Name'}
            defaultValue={target?.name}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'settings-courier-modal-name'}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default CourierEditModal;
