import React from 'react';
import { formatDate } from '../../../services/UtilsService';
import { PartitionTitleWrapper } from './Dashboard.style';
import { SpaceHorizontalContainer } from '../../../shared/styles/styles';
import { DATE_FORMAT } from './Dashboard.constants';

const PartitionTitle = ({
  title,
  dateFrom,
  dateTo,
  className,
}: {
  title: string;
  dateFrom: string;
  dateTo: string;
  className?: string;
}) => {
  const getDateText = () => {
    if (formatDate(dateFrom) === formatDate(dateTo)) {
      return formatDate(dateFrom, DATE_FORMAT);
    }
    return `${formatDate(dateFrom, DATE_FORMAT)} ~ ${formatDate(
      dateTo,
      DATE_FORMAT,
    )}`;
  };
  return (
    <PartitionTitleWrapper className={className}>
      <div className={'title'}>{title}</div>
      <SpaceHorizontalContainer className={'quarter'} />
      {dateFrom && <div className={'date-range'}>[{getDateText()}]</div>}
    </PartitionTitleWrapper>
  );
};

export default PartitionTitle;
