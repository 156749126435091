import { iConfigColumn } from '../../../UITypes/types';

export const TABLE_NAME = 'contacts';
export const PASSWORD = 'Password';
export const CUSTOMER_CONTACT_TABLE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Name',
    key: 'name',
    dataSource: ['avatarUrl', 'id'],
    type: 'avatar-text-link',
    isSortable: true,
  },
  {
    name: 'Position',
    key: 'position',
    dataSource: [''],
    type: 'text',
  },
  {
    name: 'Email',
    key: 'email',
    dataSource: [''],
    type: 'email',
  },
  {
    name: 'Phone',
    key: 'phone',
    dataSource: [''],
    type: 'phone',
  },
  {
    name: PASSWORD,
    key: 'password',
    dataSource: [''],
    type: 'password',
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
  },
  {
    name: '',
    key: 'delete',
    type: 'delete',
    dataSource: ['id'],
  },
];

export const SUPPLIER_CONTACT_TABLE_COLUMNS: Array<iConfigColumn> = CUSTOMER_CONTACT_TABLE_COLUMNS.filter(
  (c: iConfigColumn) => c.name !== PASSWORD,
);
