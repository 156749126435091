import { iConfigColumn } from '../../shared/UITypes/types';

export const BOM_ATTRIBUTE_LIST_TITLE = 'BOM Attributes';
export const BOM_ATTRIBUTE_TABLE_NAME = 'BOM_ATTRIBUTE_LIST';
export const BOM_ATTRIBUTE_TABLE_TEST_ID = 'BOM_ATTRIBUTE_LIST';

export const BOM_ATTRIBUTE_COLUMNS: Array<iConfigColumn> = [
  {
    name: '',
    key: 'dragHandlerIcon',
    dataSource: [],
    type: 'icon',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
  {
    name: 'Name',
    key: 'name',
    dataSource: ['id'],
    isCompulsory: true,
    isSortable: true,
    type: 'modalLink',
    isSelected: true,
    group: '',
  },
  {
    name: 'Sort Order',
    key: 'sortOrder',
    dataSource: ['sortOrder'],
    type: 'sortOrder',
    isSortable: true,
    isSelected: true,
    group: '',
  },
  {
    name: 'Created',
    key: 'createdAt',
    dataSource: ['createdBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
    isSelected: true,
  },
  {
    name: 'Updated',
    key: 'updatedAt',
    dataSource: ['updatedBy.firstName'],
    type: 'history',
    group: '',
    isSortable: false,
    isSelected: true,
  },
  {
    name: '',
    key: 'operation',
    dataSource: [],
    type: 'operation',
    isCompulsory: true,
    isSelected: true,
    group: '',
  },
];
