import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import store from './redux/makeReduxStore';
import 'react-notifications-component/dist/theme.css';
import './App.css';
import '@atlaskit/css-reset';
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';

import {
  AUTH_URL_LOGIN,
  BACK_END_URL,
  USER_PROFILE_URL,
  CUSTOMER_URL,
  SUPPLIER_URL,
  CUSTOMER_DETAIL_URL,
  SUPPLIER_DETAIL_URL,
  JOB_DETAILS_URL,
  JOB_SCHEDULER_URL,
  SALES_URL,
  SALES_ORDER_DETAIL_URL,
  PURCHASES_URL,
  PURCHASE_ORDER_DETAIL_URL,
  PURCHASE_ORDER_RECEIVING_URL,
  PRODUCTS_URL,
  WAREHOUSE_URL,
  WAREHOUSE_DETAIL_URL,
  JOB_URL,
  SETTINGS_UNIT_URL,
  SETTINGS_MACHINES_URL,
  SETTINGS_USERS_URL,
  SETTINGS_TAX_URL,
  SETTINGS_COMPANY_URL,
  SETTINGS_SHIFTS_URL,
  PRODUCT_DETAIL_URL,
  PRODUCT_TYPES_URL,
  PRODUCT_ATTRIBUTE_SETS_URL,
  DISPATCH_NOTES_URL,
  DISPATCH_NOTE_DETAIL_URL,
  STOCK_TRANSFER_URL,
  NCR_DETAIL_URL,
  SETTINGS_COURIERS_URL,
  NCRS_URL,
  STOCK_TAKE_URL,
  STOCK_TAKE_DETAIL_URL,
  PRODUCT_ATTRIBUTE_SET_DETAIL_URL,
  FAULT_CATEGORY_URL,
  ITEM_LOCATIONS_URL,
  JOB_ATTRIBUTES_URL,
  AOS_URL,
  STOCK_FORECAST_URL,
  AO_DETAIL_URL,
  SETTINGS_PALLETS_URL,
  BOM_ATTRIBUTES_URL,
  CHANNEL_DETAIL_URL,
  SETTINGS_INTEGRATIONS_URL,
  SETTINGS_INTEGRATIONS_CALLBACK_URL,
  SETTINGS_ENTITY_STATUS_TYPES_URL,
  SETTINGS_ENTITY_STATUS_TYPE_URL,
  SETTINGS_URL,
  SETTINGS_WRAPPINGS_URL,
  SERVICE_PROVIDER_URL,
  SERVICE_PROVIDER_DETAIL_URL,
  SERVICE_TYPE_URL,
  SALES_INVOICE_URL,
  SETTINGS_DATA_IMPORT_URL,
  SETTINGS_DATA_IMPORT_DETAIL_URL,
  AUTH_SIGN_UP_URL,
  AUTH_ACTIVATE_URL,
  AUTH_RETRIEVAL_URL,
  AUTH_RESET_PASSWORD_URL,
  BILLING_HOME_URL,
  SETTINGS_WORKFLOW_EDIT_URL,
  REPORT_PURCHASE_HISTORY_URL,
  SETTINGS_PRINTING_URL,
  SETTINGS_ROLES_URL,
  SETTINGS_ROLE_DETAILS_URL,
} from './shared/UrlMap';
import ProtectedRoute from './shared/ProtectedRoute';
import UserProfilePage from './pages/user/UserProfilePage';
import CustomerDetail from './pages/contactCompany/CustomerDetail';
import SupplierDetail from './pages/contactCompany/SupplierDetail';
import Job from './pages/job/Job';
import JobList from './pages/job/JobList';
import SalesPage from './pages/sales/salesList/SalesOrderList';
import SalesDetail from './pages/sales/salesDetail/SalesOrderDetail';
import Purchases from './pages/purchases/list/PurchaseOrderList';
import PurchaseDetail from './pages/purchases/detail/PurchaseOrderDetail';
import PoReceiving from './pages/purchases/receiving/PoReceiving';
import Product from './pages/product/ProductList';
import WarehouseList from './pages/warehouse/WarehouseList';
import WarehouseDetail from './pages/warehouse/WarehouseDetail';
import MachineSetting from './pages/settings/machines/MachineSetting';
import UserSetting from './pages/settings/users/UserSetting';
import UnitSetting from './pages/settings/unitMeasurements/UnitSetting';
import TaxRateSetting from './pages/settings/taxRates/TaxRateSetting';
import CompanyProfileSetting from './pages/settings/companyProfile/CompanyProfileSetting';
import ShiftSetting from './pages/settings/shifts/ShiftSetting';
import ProductDetail from './pages/product/ProductDetail';
import ProductTypeList from './pages/productTypes/ProductTypeList';
import ProductAttributeSets from './pages/productAttributeSets/ProdAttributeSets';
import DispatchNoteList from './pages/despatchNote/DispatchNoteList';
import DispatchNoteDetail from './pages/despatchNote/DispatchNoteDetail';
import StockTransfer from './pages/stockTransfer/StockTransfer';
import CourierSetting from './pages/settings/courier/CourierSetting';
import StockTakeList from './pages/stockTake/StockTakeList';
import StockTakeDetail from './pages/stockTake/StockTakeDetail';
import NCRDetail from './pages/NCR/detail/NCRDetail';
import NCRList from './pages/NCR/list/NCRList';
import ProdAttributeSetDetail from './pages/productAttributeSets/detail/ProdAttributeSetDetail';
import FaultCategoryList from './pages/NCR/faultCategory/FaultCategoryList';
import ItemLocations from './pages/itemLocation/ItemLocations';
import JobAttributeList from './pages/jobAttribute/JobAttributeList';
import AcquisitionOrderList from './pages/acquisitionOrder/AcquisitionOrderList';
import StockForecast from './pages/stockForecast/StockForecast';
import AcquisitionOrderDetail from './pages/acquisitionOrder/AcquisitionOrderDetail';
import PalletSetting from './pages/settings/pallets/PalletSetting';
import BomAttributeList from './pages/bomAttribute/BomAttributeList';
import CustomerList from './pages/contactCompany/CustomerList';
import SupplierList from './pages/contactCompany/SupplierList';
import JobScheduleWithContext from './pages/job/schedule/JobScheduleWithContext';
import ChannelDetail from './pages/settings/channels/ChannelDetail';
import IntegrationSetting from './pages/settings/integrations/IntegrationSetting';
import IntegrationCallback from './pages/settings/integrations/IntegrationCallback';
import EntityStatusTypeSetting from './pages/settings/entityStatusTypes/EntityStatusTypeSetting';
import EntityStatusTypeDetail from './pages/settings/entityStatusTypes/detail/EntityStatusTypeDetail';
import WrappingMethodSetting from './pages/settings/wrappingMethods/WrappingMethodSetting';
import ServiceProviderList from './pages/contactCompany/ServiceProviderList';
import ServiceProviderDetail from './pages/contactCompany/ServiceProviderDetail';
import ServiceTypeList from './pages/contactCompany/serviceType/ServiceTypeList';
import DashboardPage from './pages/dashboard/DashboardPage';
import DataImport from './pages/settings/dataImport/list/DataImport';
import DataImportDetail from './pages/settings/dataImport/detail/DataImportDetail';
import Activate from './pages/auth/activate/Activate';
import LoginPage from './pages/auth/login/LoginPage';
import ResetPassword from './pages/auth/reset/ResetPassword';
import CompanySignUp from './pages/auth/signup/CompanySignUp';
import RetrievalEnterpriseCheck from './pages/auth/retrieval/RetrievalEnterpriseCheck';
import BillingHomePage from './pages/settings/billing/BillingHomePage';
import WorkflowEdit from './pages/workflow/WorkflowEdit';
import PurchaseHistoryReport from './pages/reports/PurchaseHistory/PurchaseHistoryReport';
import PrintingSettingPage from './pages/settings/printing/PrintingSettingPage';
import RolesPage from './pages/settings/roles/RolesPage';
import RoleDetailsPage from './pages/settings/roles/RoleDetailsPage';
import {
  ACCESS_CODE_CUSTOMERS,
  ACCESS_CODE_INVOICES,
  ACCESS_CODE_JOB_ATTRIBUTES,
  ACCESS_CODE_JOB_SCHEDULER,
  ACCESS_CODE_JOBS,
  ACCESS_CODE_SUPPLIERS,
  ACCESS_CODE_SYSTEM_SETTINGS,
  ACCESS_CODE_QUOTES,
  ACCESS_CODE_SALES_ORDERS,
  ACCESS_CODE_PRODUCT_ATTRIBUTES,
  ACCESS_CODE_PURCHASE_ORDERS,
  ACCESS_CODE_STOCK_RECEIVING,
  ACCESS_CODE_PRODUCTS,
  ACCESS_CODE_WAREHOUSES,
  ACCESS_CODE_STOCK_TRANSFER,
  ACCESS_CODE_REPORTS,
  ACCESS_CODE_SERVICE_PROVIDERS,
  ACCESS_CODE_DISPATCH_NOTES,
  ACCESS_CODE_WARRANTY,
  ACCESS_CODE_STOCK_TAKES,
  ACCESS_CODE_ACQUISITION_ORDERS,
  iAccessCode,
} from './types/settings/UserAccess/iAccess';
import RestrictedAccessDiv from './shared/UserAccess/RestrictedAccessDiv';

export default () => {
  const getRestrictedPage = (
    children: React.ReactElement,
    accessCodes: iAccessCode[],
    requiresAnyCodes = false,
  ) => () => {
    return (
      <RestrictedAccessDiv expectedUserAccessCodes={accessCodes} expectedCodeMatchedWithAny={requiresAnyCodes}>
        {children}
      </RestrictedAccessDiv>
    );
  };

  const getSettingsPage = (children: React.ReactElement) => getRestrictedPage(children, [ACCESS_CODE_SYSTEM_SETTINGS]);

  return (
    <Provider store={store}>
      <ReactNotification />
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to={AUTH_URL_LOGIN} />
          </Route>
          <Route exact path={AUTH_URL_LOGIN} component={LoginPage} />
          <Route exact path={AUTH_SIGN_UP_URL} component={CompanySignUp} />
          <Route exact path={AUTH_ACTIVATE_URL} component={Activate} />
          <Route exact path={AUTH_RETRIEVAL_URL} component={RetrievalEnterpriseCheck} />
          <Route exact path={AUTH_RESET_PASSWORD_URL} component={ResetPassword} />

          <ProtectedRoute exact path={BACK_END_URL} component={DashboardPage} />
          <ProtectedRoute exact path={USER_PROFILE_URL} component={UserProfilePage} />
          <ProtectedRoute exact path={ITEM_LOCATIONS_URL} component={ItemLocations} />

          <ProtectedRoute
            exact
            path={SALES_URL}
            component={getRestrictedPage(
              <SalesPage />,
              [ACCESS_CODE_INVOICES, ACCESS_CODE_QUOTES, ACCESS_CODE_SALES_ORDERS],
              true,
            )}
          />
          <ProtectedRoute
            exact
            path={SALES_ORDER_DETAIL_URL}
            component={getRestrictedPage(
              <SalesDetail />,
              [ACCESS_CODE_INVOICES, ACCESS_CODE_QUOTES, ACCESS_CODE_SALES_ORDERS],
              true,
            )}
          />
          <ProtectedRoute
            exact
            path={SALES_INVOICE_URL}
            component={getRestrictedPage(<SalesPage startWithInvoiceList />, [ACCESS_CODE_INVOICES])}
          />

          <ProtectedRoute exact path={JOB_URL} component={getRestrictedPage(<JobList />, [ACCESS_CODE_JOBS])} />
          {/* this line needs to be here to avoid being overwritten by job details url */}
          <ProtectedRoute
            exact
            path={JOB_SCHEDULER_URL}
            component={getRestrictedPage(<JobScheduleWithContext />, [ACCESS_CODE_JOB_SCHEDULER])}
          />
          <ProtectedRoute exact path={JOB_DETAILS_URL} component={getRestrictedPage(<Job />, [ACCESS_CODE_JOBS])} />
          <ProtectedRoute
            exact
            path={JOB_ATTRIBUTES_URL}
            component={getRestrictedPage(<JobAttributeList />, [ACCESS_CODE_JOB_ATTRIBUTES])}
          />
          <ProtectedRoute
            exact
            path={BOM_ATTRIBUTES_URL}
            component={getRestrictedPage(<BomAttributeList />, [ACCESS_CODE_PRODUCT_ATTRIBUTES])}
          />

          {/* System Settings */}
          <ProtectedRoute exact path={SETTINGS_URL} component={getSettingsPage(<CompanyProfileSetting />)} />
          <ProtectedRoute exact path={SETTINGS_COMPANY_URL} component={getSettingsPage(<CompanyProfileSetting />)} />
          <ProtectedRoute exact path={SETTINGS_USERS_URL} component={getSettingsPage(<UserSetting />)} />
          <ProtectedRoute exact path={SETTINGS_ROLES_URL} component={getSettingsPage(<RolesPage />)} />
          <ProtectedRoute exact path={SETTINGS_ROLE_DETAILS_URL} component={getSettingsPage(<RoleDetailsPage />)} />
          <ProtectedRoute exact path={SETTINGS_MACHINES_URL} component={getSettingsPage(<MachineSetting />)} />
          <ProtectedRoute exact path={SETTINGS_PALLETS_URL} component={getSettingsPage(<PalletSetting />)} />
          <ProtectedRoute exact path={SETTINGS_UNIT_URL} component={getSettingsPage(<UnitSetting />)} />
          <ProtectedRoute exact path={SETTINGS_TAX_URL} component={getSettingsPage(<TaxRateSetting />)} />
          <ProtectedRoute exact path={SETTINGS_SHIFTS_URL} component={getSettingsPage(<ShiftSetting />)} />
          <ProtectedRoute exact path={SETTINGS_COURIERS_URL} component={getSettingsPage(<CourierSetting />)} />
          <ProtectedRoute exact path={SETTINGS_WRAPPINGS_URL} component={getSettingsPage(<WrappingMethodSetting />)} />
          <ProtectedRoute
            exact
            path={SETTINGS_ENTITY_STATUS_TYPES_URL}
            component={getSettingsPage(<EntityStatusTypeSetting />)}
          />
          <ProtectedRoute
            exact
            path={SETTINGS_ENTITY_STATUS_TYPE_URL}
            component={getSettingsPage(<EntityStatusTypeDetail />)}
          />
          <ProtectedRoute exact path={SETTINGS_DATA_IMPORT_URL} component={getSettingsPage(<DataImport />)} />
          <ProtectedRoute
            exact
            path={SETTINGS_DATA_IMPORT_DETAIL_URL}
            component={getSettingsPage(<DataImportDetail />)}
          />
          <ProtectedRoute exact path={SETTINGS_WORKFLOW_EDIT_URL} component={getSettingsPage(<WorkflowEdit />)} />
          <ProtectedRoute exact path={SETTINGS_PRINTING_URL} component={getSettingsPage(<PrintingSettingPage />)} />
          <ProtectedRoute exact path={SETTINGS_INTEGRATIONS_URL} component={getSettingsPage(<IntegrationSetting />)} />
          <ProtectedRoute exact path={SETTINGS_INTEGRATIONS_CALLBACK_URL} component={IntegrationCallback} />
          <ProtectedRoute exact path={CHANNEL_DETAIL_URL} component={getSettingsPage(<ChannelDetail />)} />
          <ProtectedRoute exact path={BILLING_HOME_URL} component={getSettingsPage(<BillingHomePage />)} />

          {/* Contacts */}
          <ProtectedRoute
            exact
            path={CUSTOMER_URL}
            component={getRestrictedPage(<CustomerList />, [ACCESS_CODE_CUSTOMERS])}
          />
          <ProtectedRoute
            exact
            path={CUSTOMER_DETAIL_URL}
            component={getRestrictedPage(<CustomerDetail />, [ACCESS_CODE_CUSTOMERS])}
          />
          <ProtectedRoute
            exact
            path={SUPPLIER_URL}
            component={getRestrictedPage(<SupplierList />, [ACCESS_CODE_SUPPLIERS])}
          />
          <ProtectedRoute
            exact
            path={SUPPLIER_DETAIL_URL}
            component={getRestrictedPage(<SupplierDetail />, [ACCESS_CODE_SUPPLIERS])}
          />
          <ProtectedRoute
            exact
            path={SERVICE_PROVIDER_URL}
            component={getRestrictedPage(<ServiceProviderList />, [ACCESS_CODE_SERVICE_PROVIDERS])}
          />
          <ProtectedRoute
            exact
            path={SERVICE_PROVIDER_DETAIL_URL}
            component={getRestrictedPage(<ServiceProviderDetail />, [ACCESS_CODE_SERVICE_PROVIDERS])}
          />
          <ProtectedRoute
            exact
            path={SERVICE_TYPE_URL}
            component={getRestrictedPage(<ServiceTypeList />, [ACCESS_CODE_SERVICE_PROVIDERS])}
          />

          {/* Purchases */}
          <ProtectedRoute
            exact
            path={PURCHASES_URL}
            component={getRestrictedPage(<Purchases />, [ACCESS_CODE_PURCHASE_ORDERS])}
          />
          <ProtectedRoute
            exact
            path={PURCHASE_ORDER_DETAIL_URL}
            component={getRestrictedPage(<PurchaseDetail />, [ACCESS_CODE_PURCHASE_ORDERS])}
          />
          <ProtectedRoute
            exact
            path={AOS_URL}
            component={getRestrictedPage(<AcquisitionOrderList />, [ACCESS_CODE_ACQUISITION_ORDERS])}
          />
          <ProtectedRoute
            exact
            path={AO_DETAIL_URL}
            component={getRestrictedPage(<AcquisitionOrderDetail />, [ACCESS_CODE_ACQUISITION_ORDERS])}
          />
          <ProtectedRoute
            exact
            path={PURCHASE_ORDER_RECEIVING_URL}
            component={getRestrictedPage(<PoReceiving />, [ACCESS_CODE_STOCK_RECEIVING])}
          />
          <ProtectedRoute
            exact
            path={PRODUCTS_URL}
            component={getRestrictedPage(<Product />, [ACCESS_CODE_PRODUCTS])}
          />
          <ProtectedRoute
            exact
            path={PRODUCT_DETAIL_URL}
            component={getRestrictedPage(<ProductDetail />, [ACCESS_CODE_PRODUCTS])}
          />
          <ProtectedRoute
            exact
            path={WAREHOUSE_URL}
            component={getRestrictedPage(<WarehouseList />, [ACCESS_CODE_WAREHOUSES])}
          />
          <ProtectedRoute
            exact
            path={WAREHOUSE_DETAIL_URL}
            component={getRestrictedPage(<WarehouseDetail />, [ACCESS_CODE_WAREHOUSES])}
          />
          <ProtectedRoute
            exact
            path={STOCK_TRANSFER_URL}
            component={getRestrictedPage(<StockTransfer />, [ACCESS_CODE_STOCK_TRANSFER])}
          />

          <ProtectedRoute
            exact
            path={PRODUCT_TYPES_URL}
            component={getRestrictedPage(<ProductTypeList />, [ACCESS_CODE_PRODUCTS])}
          />
          <ProtectedRoute
            exact
            path={PRODUCT_ATTRIBUTE_SETS_URL}
            component={getRestrictedPage(<ProductAttributeSets />, [ACCESS_CODE_PRODUCT_ATTRIBUTES])}
          />
          <ProtectedRoute
            exact
            path={PRODUCT_ATTRIBUTE_SET_DETAIL_URL}
            component={getRestrictedPage(<ProdAttributeSetDetail />, [ACCESS_CODE_PRODUCT_ATTRIBUTES])}
          />
          <ProtectedRoute
            exact
            path={DISPATCH_NOTES_URL}
            component={getRestrictedPage(<DispatchNoteList />, [ACCESS_CODE_DISPATCH_NOTES])}
          />
          <ProtectedRoute
            exact
            path={DISPATCH_NOTE_DETAIL_URL}
            component={getRestrictedPage(<DispatchNoteDetail />, [ACCESS_CODE_DISPATCH_NOTES])}
          />

          {/* Warranty */}
          <ProtectedRoute exact path={NCRS_URL} component={getRestrictedPage(<NCRList />, [ACCESS_CODE_WARRANTY])} />
          <ProtectedRoute
            exact
            path={NCR_DETAIL_URL}
            component={getRestrictedPage(<NCRDetail />, [ACCESS_CODE_WARRANTY])}
          />
          <ProtectedRoute
            exact
            path={FAULT_CATEGORY_URL}
            component={getRestrictedPage(<FaultCategoryList />, [ACCESS_CODE_WARRANTY])}
          />

          {/* Stock Takes */}
          <ProtectedRoute
            exact
            path={STOCK_TAKE_URL}
            component={getRestrictedPage(<StockTakeList />, [ACCESS_CODE_STOCK_TAKES])}
          />
          <ProtectedRoute
            exact
            path={STOCK_TAKE_DETAIL_URL}
            component={getRestrictedPage(<StockTakeDetail />, [ACCESS_CODE_STOCK_TAKES])}
          />

          <ProtectedRoute
            exact
            path={STOCK_FORECAST_URL}
            component={getRestrictedPage(<StockForecast />, [ACCESS_CODE_REPORTS])}
          />
          <ProtectedRoute
            exact
            path={REPORT_PURCHASE_HISTORY_URL}
            component={getRestrictedPage(<PurchaseHistoryReport />, [ACCESS_CODE_REPORTS])}
          />
        </Switch>
      </Router>
    </Provider>
  );
};
