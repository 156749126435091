import AppService from '../AppService';
import iChannelScript from '../../types/integration/iChannelScript';
import iChannelScriptType from '../../types/integration/iChannelScriptType';

const getChannelScripts = (params = {}) => {
  return AppService.get('/channelScript', params).then(resp => resp.data);
};

const getChannelScriptsForAccounting = () => {
  return AppService.get('/channelScript?include=type&advancedFilter=ChannelScriptType_typeId.name:Accounting').then(
    ({ data }) => {
      return data as (iChannelScript & { type: iChannelScriptType })[];
    },
  );
};

const getChannelScriptOauth2Url = (channelScriptId: string, params = {}) => {
  return AppService.get(`/channelScript/${channelScriptId}/oauth2/getAuthUrl`, params).then(({ data }) => data);
};

const getChannelScriptAccessToken = (channelScriptId: string, accessCode: string, redirectUrl: string) => {
  return AppService.post(`/channelScript/${channelScriptId}/oauth2/getAccessToken`, { accessCode, redirectUrl }).then(
    ({ data }) => {
      return data;
    },
  );
};

export default {
  getChannelScripts,
  getChannelScriptsForAccounting,
  getChannelScriptOauth2Url,
  getChannelScriptAccessToken,
};
