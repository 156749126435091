import React from 'react';
import { getUrlPrefixByContactCompanyType } from '../../services/UtilsService';
import iContactCompany from '../../types/contactCompany/iContactCompany';
import { SERVICE_PROVIDER_URL } from '../UrlMap';
import LinkBtnUrl from './LinkBtnUrl';

const CompanyLinkBtn = ({ company }: { company: iContactCompany }) => {
  const { name, id, type } = company;
  const urlPrefix = getUrlPrefixByContactCompanyType(type.name);
  if (!urlPrefix) return null;

  if (urlPrefix === SERVICE_PROVIDER_URL)
    return (
      <LinkBtnUrl url={`${urlPrefix}/${id}`} btnName={name}>
        {' [service provider]'}
      </LinkBtnUrl>
    );
  return <LinkBtnUrl url={`${urlPrefix}/${id}`} btnName={name} />;
};

export default CompanyLinkBtn;
