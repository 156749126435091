import React, { useState, useContext, createContext } from 'react';
import iSalesOrderItem from '../../types/sales/iSalesOrderItem';

type iState = {
  [key: string]: iSalesOrderItem | undefined;
};
const initialState: iState = {};
type iContextState = {
  lastSOs: iState;
  onUpdateLastSO?: (key: string, value?: iSalesOrderItem) => void;
};
const initalContextState: iContextState = {
  lastSOs: {},
};
const PCContext = createContext(initalContextState);

const ProductsCustomerContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(initialState);
  const onUpdateLastSO = (key: string, value?: iSalesOrderItem) =>
    setState(prevState => ({ ...prevState, [key]: value }));

  return (
    <PCContext.Provider
      value={{
        lastSOs: state,
        onUpdateLastSO,
      }}
    >
      {children}
    </PCContext.Provider>
  );
};
export const useProductCustomerContext = () => useContext(PCContext);
export default ProductsCustomerContext;
