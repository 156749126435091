import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import { SpaceVerticalContainer } from '../../../../../../shared/styles/styles';
import { ERROR_TEXT } from '../../../shared/DataImport.constants';

const ErrorHip = ({ isShown }: { isShown: boolean }) => {
  if (!isShown) return null;
  return (
    <>
      <SpaceVerticalContainer className={'half'} />
      <div style={{ maxWidth: 'fit-content' }}>
        <SectionMessage appearance={'warning'}>
          <span>{ERROR_TEXT}</span>
        </SectionMessage>
      </div>
    </>
  );
};

export default ErrorHip;
