import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import ComposeSecondaryText from '../../../../shared/text/ComposeSecondaryText';
import LinkBtnUrl from '../../../../shared/buttons/LinkBtnUrl';
import {
  getHeads,
  handleNullException,
} from '../../../../services/UtilsService';
import { LIST_PAGE_NAME } from '../shared/DataImport.constants';
import {
  iConfigColumn,
  iLabelValuePair,
} from '../../../../shared/UITypes/types';

const getRows = ({
  data,
  columns,
}: {
  data: Array<iLabelValuePair>;
  columns: Array<iConfigColumn>;
}) => {
  return data.map((item: iLabelValuePair) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'link-secondary':
          return {
            key: handleNullException(item, key),
            content: (
              <ComposeSecondaryText
                secondaryText={handleNullException(item, dataSource[0])}
              >
                <LinkBtnUrl
                  btnName={handleNullException(item, key)}
                  url={`${column.urlPrefix}/${handleNullException(item, key)}`}
                />
              </ComposeSecondaryText>
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells, testId: LIST_PAGE_NAME };
  });
};

const DataImportList = ({
  data,
  columns,
}: {
  data: Array<iLabelValuePair>;
  columns: Array<iConfigColumn>;
}) => {
  return (
    <DynamicTable
      head={getHeads(columns, LIST_PAGE_NAME)}
      rows={getRows({ data, columns })}
    />
  );
};

export default DataImportList;
