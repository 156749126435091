import React, { useEffect, useState } from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import { Label } from '@atlaskit/field-base';
import Spinner from '@atlaskit/spinner';
import { Control, DeepMap, FieldError } from 'react-hook-form';

import { LabelValue, SpinnerWrapper } from '../../../shared/styles/styles';
import { addToastForAPIResponse } from '../../../shared/toast/Toast';
import InputWithController from '../../../shared/form/hookForms/InputWithController';
import SelectWithController from '../../../shared/form/hookForms/SelectWithController';
import iReceivingItem from '../../../types/purchases/iReceivingItem';
import iWarehouseLocation from '../../../types/warehouse/iWarehouse';
import { getProductDetail } from '../../../services/product/ProductService';
import iProduct from '../../../types/product/iProduct';
import iPurchaseOrderItem from '../../../types/purchases/iPurchaseOrderItem';
import { getMe } from '../../../services/AuthService';
import iUser from '../../../types/user/iUser';
import { getUnitConversionSuffix, handleNullException } from '../../../services/UtilsService';

const ReceivingProductModal = ({
  productId,
  control,
  errors,
  onChange,
  target,
  warehouseLocations,
  targetPoItem,
}: {
  productId?: string;
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  target?: iReceivingItem;
  warehouseLocations: iWarehouseLocation[];
  targetPoItem?: iPurchaseOrderItem;
}) => {
  const [localProduct, setLocalProduct] = useState<iProduct>();
  const [localUser, setLocalUser] = useState<iUser>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (!productId) return;
      setIsLoading(true);
      try {
        const product = await getProductDetail(productId);
        const { user } = await getMe();

        if (!mounted) return;
        setLocalProduct(product);
        setLocalUser(user);
        setIsLoading(false);
      } catch (e) {
        if (!mounted) return;
        setIsLoading(false);
        addToastForAPIResponse('error');
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [productId]);

  return isLoading ? (
    <SpinnerWrapper>
      <Spinner size="xlarge" />
    </SpinnerWrapper>
  ) : (
    <>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={4}>
          <Label label="Product SKU" />
          <LabelValue>{localProduct?.productCode}</LabelValue>
        </GridColumn>
        <GridColumn medium={4}>
          <Label label="Product Name" />
          <LabelValue>{localProduct?.name}</LabelValue>
        </GridColumn>
        <GridColumn medium={4}>
          <Label label="Supplier Product Code / SKU" />
          <LabelValue>
            {target ? target?.purchaseOrderItem?.supplierProductCode : targetPoItem?.supplierProductCode}
          </LabelValue>
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={12}>
          <Label label="Product Description" />
          <LabelValue>{localProduct?.shortDescription}</LabelValue>
        </GridColumn>
      </Grid>
      <Grid layout={'fluid'} spacing={'compact'}>
        <GridColumn medium={5}>
          <SelectWithController
            name={'toWarehouseLocationId'}
            label={'To Location'}
            defaultValue={
              target
                ? target.toWarehouseLocationId
                : handleNullException(localUser, 'defaultFacility.defaultInwardsLocationId')
            }
            control={control}
            onChange={onChange}
            options={warehouseLocations
              ?.filter(location => location.allowParts === true)
              .map((location: iWarehouseLocation) => ({
                label: location.name,
                value: location.id,
              }))}
            testId={'receiving-product-modal-toLocation'}
            errors={errors}
            isRequired
          />
        </GridColumn>
        <GridColumn medium={5}>
          <InputWithController
            name={'qty'}
            label={'Receiving Qty'}
            defaultValue={target ? target.qty / Number(target.purchaseOrderItem?.unitConversion || 1) : undefined}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            isNumeric
            testId={'receiving-product-modal-receivingQty'}
            isUnitConversion
            getUnitConversionSuffix={value => getUnitConversionSuffix(value, localProduct, targetPoItem)}
          />
        </GridColumn>
        <GridColumn medium={2}>
          <InputWithController
            name={'reference'}
            label={'Lot / Batch No.'}
            defaultValue={target?.reference}
            control={control}
            onChange={onChange}
            errors={errors}
            isRequired
            testId={'receiving-product-modal-batchNumber'}
          />
        </GridColumn>
      </Grid>
    </>
  );
};

export default ReceivingProductModal;
