import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomizeModal from '../../../modal/CustomizeModal';
import ContactModalBody from './ContactModalBody';
import PageTitleWithAddOne from '../../../heading/PageTitleWithAddOne';
import DeleteByRetype from '../../../deleteWithReType/DeleteByReType';
import ContactTable from './ContactTable';
import iContact from '../../../../types/contactCompany/iContact';
import { apiErrorToast } from '../../../toast/Toast';
import { SpaceVerticalContainer } from '../../../styles/styles';
import { compareStr, handleNullException } from '../../../../services/UtilsService';
import { createContact, removeContact, updateContact } from '../../../../services/ContactService';
import { CUSTOMER_CONTACT_TABLE_COLUMNS, SUPPLIER_CONTACT_TABLE_COLUMNS } from './Contact.constant';

type iState = {
  isConfirming: boolean;
  deleteTarget?: iContact;
  isModalOpen: boolean;
  target?: iContact;
};
const initialState: iState = {
  isConfirming: false,
  isModalOpen: false,
};
const ContactBC = ({
  companyId,
  contacts,
  handleAdd,
  handleRemove,
  handleEdit,
  isCustomer = true,
  isDisabled = false,
}: {
  companyId?: string;
  contacts?: Array<iContact>;
  handleAdd: (add: iContact) => void;
  handleRemove: (add: iContact) => void;
  handleEdit: (add: iContact) => void;
  isCustomer?: boolean;
  isDisabled?: boolean;
}) => {
  const [state, setState] = useState(initialState);
  const { control, setValue, errors, handleSubmit, watch, setError } = useForm({});

  const onOpenDeleteModal = (id: string) => {
    const target = contacts?.find((c: iContact) => c.id === id);
    setState({ ...state, deleteTarget: target });
  };

  const onOpenEditModal = (id: string) => {
    if (isDisabled) {
      return;
    }
    const target = contacts?.find((c: iContact) => c.id === id);
    setState({ ...state, isModalOpen: true, target });
  };

  const onOpenAddModal = () => setState({ ...state, isModalOpen: true, target: undefined });
  //  eslint-disable-next-line
  const onAdd = async (content: any) => {
    if (!companyId) {
      return;
    }
    setState({ ...state, isConfirming: true });
    try {
      const response = await createContact({
        ...content,
        contactCompanyId: companyId,
      });
      handleAdd(response);
      setState({
        ...state,
        isConfirming: false,
        isModalOpen: false,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };
  const onDelete = async (deleteItemId: string) => {
    setState({ ...state, isConfirming: true });
    try {
      const response = await removeContact(deleteItemId);
      handleRemove(response);
      setState({
        ...state,
        isConfirming: false,
        deleteTarget: undefined,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };
  //  eslint-disable-next-line
  const onEdit = async (id: string, newValue: any) => {
    setState({ ...state, isConfirming: true });
    try {
      const editResponse = await updateContact(id, newValue);
      handleEdit(editResponse);
      setState({
        ...state,
        isConfirming: false,
        isModalOpen: false,
        target: undefined,
      });
    } catch (error) {
      apiErrorToast(error);
      setState({ ...state, isConfirming: false });
    }
  };
  const onCloseModal = () =>
    setState({
      ...state,
      isModalOpen: false,
      target: undefined,
      deleteTarget: undefined,
    });
  //  eslint-disable-next-line
  const onSubmit = (data: any) => {
    if (!state.target) {
      onAdd(data);
      return;
    }
    //  when update a customer's contact, password is not required, can be null/'' but need to be removed
    if (!handleNullException(data, 'password') && isCustomer) {
      // delete data.password;
      onEdit(state.target.id, { ...data, clearPassword: true });
      return;
    }
    onEdit(state.target.id, data);
  };
  if (typeof contacts === 'undefined') return null;
  return (
    <div data-testid={'contacts-wrapper'}>
      <PageTitleWithAddOne
        onClickAddBtn={onOpenAddModal}
        title={`${contacts.length} contacts`}
        classNameTitle={'small space-right'}
        isDisabled={isDisabled}
      />
      <SpaceVerticalContainer className={'half'} />
      <ContactTable
        data={contacts.sort((a: iContact, b: iContact) => compareStr(b.updatedAt, a.updatedAt))}
        onOpenDeleteModal={onOpenDeleteModal}
        onOpenEditModal={onOpenEditModal}
        columns={(isCustomer ? CUSTOMER_CONTACT_TABLE_COLUMNS : SUPPLIER_CONTACT_TABLE_COLUMNS).filter(item =>
          isDisabled ? ['delete'].indexOf(item.key) < 0 : true,
        )}
      />
      <DeleteByRetype
        isOpen={!!state.deleteTarget}
        answer={handleNullException(state.deleteTarget, 'name')}
        onClose={onCloseModal}
        isConfirming={state.isConfirming}
        onConfirm={() => state.deleteTarget && onDelete(state.deleteTarget.id)}
      />
      <CustomizeModal
        isOpen={state.isModalOpen}
        onCancel={onCloseModal}
        onConfirm={handleSubmit(onSubmit)}
        isDisabled={Object.keys(errors).length > 0}
        modalHeading={state.target ? 'Update' : 'Create'}
        confirmBtnName={'Save'}
        modalBody={
          <ContactModalBody
            control={control}
            errors={errors}
            onChange={setValue}
            watch={watch}
            setError={setError}
            target={state.target}
            isCustomer={isCustomer}
          />
        }
      />
    </div>
  );
};

export default ContactBC;
