import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DynamicTable from '@atlaskit/dynamic-table';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { useSelector } from 'react-redux';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import InlineEdit from '../../../shared/form/InlineEdit';
import SelectSingle from '../../../shared/form/SingleSelect';
import ElementWithPopup from '../../../shared/contactCompany/list/table/ElementWithPopup';
import ComposeSecondaryText from '../../../shared/text/ComposeSecondaryText';
import iPurchaseOrderItem from '../../../types/purchases/iPurchaseOrderItem';
import iProduct from '../../../types/product/iProduct';
import iAcquisitionOrderItem from '../../../types/acquisitionOrder/iAcquisitionOrderItem';
import iPurchaseOrderDetail from '../../../types/purchases/iPurchaseOrderDetail';
import { createOption, createOptions } from '../../sales/utilities';
import { iTaxBase, iTaxRate } from '../../../types/iTaxRate';
import { handleNullException, handleNullExceptionMultiFields, numberRound } from '../../../services/UtilsService';
import { PURCHASE_ORDER_ITEM_TABLE_COLUMNS } from '../constants';
import { AOS_URL, PRODUCT_DETAIL_URL } from '../../../shared/UrlMap';
// import { getAOItemList } from '../../../services/AcquisitionOrderItemService';
import { getHeads } from '../../../components/settings/utils';
import { addToastForAPIResponse, apiErrorToast } from '../../../shared/toast/Toast';
import {
  getPurchaseOrderDetail,
  // getPurchaseOrderList,
  updatePurchaseOrder,
} from '../../../services/PurchaseService';
import {
  createPurchaseOrderItem,
  deletePurchaseOrderItem,
  // getPurchaseOrderItems,
  updatePurchaseOrderItem,
} from '../../../services/PurchaseOrderItemService';
import { getReceivedQtyBeforeConversion, getUnitPriceMeasure } from '../Purchase.utilities';
// import OverQtyHint from './OverQtyHint';
import CreateAganistAO from './createAganistAO/CreateAganistAO';
import { FlexContainer } from '../../../shared/styles/styles';
import TotalPrice from '../../../shared/totalPrice/TotalPrice';
// import CreateByAsyncProduct from '../../../shared/createByAsyncSearchProduct/CreateByAsyncProduct';
import iContactCompanyProduct from '../../../types/contactCompany/iContactCompanyProduct';
import AddOrderItem from '../../../shared/addOrderItem/AddOrderItem';
import ConversionCalculateText from '../../../shared/conversionCalculate/ConCalculateText';
import ModuleEnabledWrapper from '../../../shared/modulize/ModuleEnabledWrapper';
import { RootState } from '../../../redux/makeReduxStore';
import { ModuleCode } from '../../../types/iModuleCode';
import ModulingService from '../../../services/ModulingService';
import AccessService from '../../../services/Settings/UserAccess/AccessService';
import { ACCESS_CODE_ACQUISITION_ORDERS, ACCESS_CODE_PRODUCTS } from '../../../types/settings/UserAccess/iAccess';
import { ACCESS_CAN_READ } from '../../../types/settings/UserAccess/iRoleAccess';

const SelectorWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -3rem;
  min-width: 7.7rem;
`;

const InlineEditContainer = styled.div`
  padding-bottom: 0.5rem;

  &.tax-select {
    padding-bottom: 0;
  }
`;

const TableWrapper = styled.div`
  min-height: 2.5rem;
  position: relative;
  margin-top: 6rem;

  th[data-testid='poItems-table-column-Product Code / SKU--head--cell'] {
    width: 15rem;
    min-width: 11rem;
  }
  th[data-testid='poItems-table-column-Supplier Product Code--head--cell'] {
    width: 9rem;
    min-width: 9rem;
  }
  th[data-testid='poItems-table-column-Quantity--head--cell'] {
    width: 14rem;
    min-width: 14rem;
  }
  th[data-testid='poItems-table-column-Unit Price--head--cell'] {
    width: 10rem;
    min-width: 10rem;
  }
  th[data-testid='poItems-table-column-Disc %--head--cell'] {
    width: 5rem;
    min-width: 5rem;
  }
  th[data-testid='poItems-table-column-Tax--head--cell'] {
    width: 8rem;
    min-width: 8rem;
  }

  th[data-testid='poItems-table-column-Total (Inc GST)--head--cell'] {
    width: 7rem;
    min-width: 7rem;
  }

  td:nth-last-child(1) {
    width: 1.5rem;
    min-width: 1.5rem;
  }
`;

interface iProps {
  orderId: string;
  order?: iPurchaseOrderDetail;
  taxRates: iTaxRate[];
  taxBases: iTaxBase[];
  isDisabled?: boolean;
}

type iState = {
  acquisitionOrderItems: Array<iAcquisitionOrderItem>;
  allRelatedPOItems: Array<iPurchaseOrderItem>;
  isLoading: boolean;
};
const initialState: iState = {
  acquisitionOrderItems: [],
  allRelatedPOItems: [],
  isLoading: false,
};
const PurchaseOrderItem = ({ orderId, order, taxRates, taxBases, isDisabled = false }: iProps) => {
  const [localOrderDetail, setLocalOrderDetail] = useState(order);
  const [supState, setSupState] = useState(initialState);
  const { user } = useSelector((s: RootState) => s.auth);
  const canReadAO = AccessService.hasAccess(ACCESS_CODE_ACQUISITION_ORDERS, ACCESS_CAN_READ, user);
  const canReadProducts = AccessService.hasAccess(ACCESS_CODE_PRODUCTS, ACCESS_CAN_READ, user);

  // fetch PO data locally
  const fetchPurchaseOrderData = async () => {
    try {
      const data = await getPurchaseOrderDetail(orderId);
      setLocalOrderDetail(data);
    } catch (e) {
      apiErrorToast(e);
    }
  };

  // update PO Item
  const onConfirmPurchaseOrderItem = async (name: string, newValue: string | number) => {
    try {
      const poItemId = name.split(':')[0];
      const poItemField = name.split(':')[1];
      await updatePurchaseOrderItem(poItemId, {
        [poItemField]: newValue,
      });
      if (poItemField.toLowerCase() === 'qty') {
        setSupState({
          ...supState,
          allRelatedPOItems: supState.allRelatedPOItems.map((item: iPurchaseOrderItem) =>
            item.id === poItemId ? { ...item, qty: Number(newValue) } : item,
          ),
        });
      }

      fetchPurchaseOrderData();
      addToastForAPIResponse('success');
    } catch (e) {
      apiErrorToast(e);
    }
  };

  // update PO Detail
  const onConfirmSelect = async (id: string, newValue: string) => {
    try {
      await updatePurchaseOrder(id, {
        taxBaseId: newValue,
      });
      addToastForAPIResponse('success');
      fetchPurchaseOrderData();
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const getDeleteBtn = (id: string, onClick: (id: string) => void) => {
    return (
      <ElementWithPopup
        onClick={() => onClick(id)}
        popupContent={'Are you sure to delete it?'}
        popupHeading={"please confirm you're about to delete it"}
      >
        <TrashIcon label="delete" />
      </ElementWithPopup>
    );
  };

  const onDelete = async (deleteItemId: string) => {
    try {
      await deletePurchaseOrderItem(deleteItemId);
      fetchPurchaseOrderData();
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const getNameContent = (item: iPurchaseOrderItem) => (
    <ComposeSecondaryText secondaryText={item.product.name}>
      {canReadProducts ? (
        <LinkBtnUrl btnName={item.product.productCode} url={PRODUCT_DETAIL_URL.replace(':id', item.productId)} />
      ) : (
        item.product.productCode
      )}
    </ComposeSecondaryText>
  );

  const getRows = () => {
    return localOrderDetail?.purchaseOrderItems
      ?.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
      .map((item: iPurchaseOrderItem) => {
        const cells = [
          {
            key: item.product.productCode,
            content: getNameContent(item),
          },
          { content: item.supplierProductCode },
          {
            content:
              handleNullException(item, 'acquisitionOrderItem.acquisitionOrder') &&
              (canReadAO ? (
                <LinkBtnUrl
                  btnName={handleNullException(item, 'acquisitionOrderItem.acquisitionOrder.orderNumber')}
                  url={`${AOS_URL}/${handleNullException(item, 'acquisitionOrderItem.acquisitionOrder.id')}`}
                />
              ) : (
                handleNullException(item, 'acquisitionOrderItem.acquisitionOrder.orderNumber')
              )),
          },
          {
            content: (
              <InlineEditContainer>
                <InlineEdit
                  name={`${item.id}:name`}
                  defaultValue={handleNullException(item, 'description')}
                  onConfirm={onConfirmPurchaseOrderItem}
                  hideActionButtons
                  isDisabled={isDisabled}
                />
              </InlineEditContainer>
            ),
          },
          {
            content: (
              <ConversionCalculateText
                name={`${item.id}:qty`}
                defaultValue={`${item.qty}`}
                onConfirm={onConfirmPurchaseOrderItem}
                conversion={item.unitConversion || 1}
                productUnit={handleNullException(item, 'product.measurement.shortName')}
                contactCompanyUnit={handleNullExceptionMultiFields(
                  item,
                  'product.measurement.shortName,measurement.shortName',
                )}
                isDisabled={isDisabled}
              />
            ),
          },
          {
            content: getReceivedQtyBeforeConversion(item),
          },
          {
            content: (
              <InlineEditContainer>
                <InlineEdit
                  name={`${item.id}:unitPrice`}
                  defaultValue={`${item.unitPrice}`}
                  onConfirm={onConfirmPurchaseOrderItem}
                  prefix={'$'}
                  postfix={`/ ${getUnitPriceMeasure(item)}`}
                  needFormatValue
                  hideActionButtons
                  isNumeric
                  isRequired
                  isDisabled={isDisabled}
                />
              </InlineEditContainer>
            ),
          },
          {
            content: (
              <InlineEditContainer>
                <InlineEdit
                  name={`${item.id}:discountPercentage`}
                  defaultValue={`${item.discountPercentage}`}
                  onConfirm={onConfirmPurchaseOrderItem}
                  postfix={'%'}
                  needFormatValue
                  hideActionButtons
                  isNumeric
                  isRequired
                  isDisabled={isDisabled}
                />
              </InlineEditContainer>
            ),
          },
          {
            content: (
              <InlineEditContainer className="tax-select">
                <SelectSingle
                  name={`${item.id}:taxRateId`}
                  defaultValue={createOption(taxRates.filter(type => type.id === item.taxRateId)[0])}
                  label={''}
                  selectOptions={createOptions(taxRates)}
                  onConfirm={onConfirmPurchaseOrderItem}
                  isDisabled={isDisabled}
                />
              </InlineEditContainer>
            ),
          },
          { content: `$ ${numberRound(item.totalPrice, 2)}` },
          { content: !isDisabled && getDeleteBtn(item.id, onDelete) },
        ];
        return {
          cells: ModulingService.filterListByModule({
            list: cells,
            // targetIndexs: [2],
            checkedModule: ModuleCode.PURCHASE_AO,
            companyModules: user?.companyModules || [],
            // eslint-disable-next-line
            filterFn: (cell: any, index: number) => index !== 2,
          }),
        };
      });
  };

  const onSelect = async (payload: iProduct | iContactCompanyProduct, isCCP = false) => {
    try {
      if (!payload) return;
      const productId = isCCP ? handleNullException(payload, 'productId') : handleNullException(payload, 'id');
      const created = await createPurchaseOrderItem(orderId, productId);
      await fetchPurchaseOrderData();
      setTimeout(() => document.getElementById(`${created.id}:qty`)?.click(), 200);
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  const afterCreate = async (createdId?: string) => {
    try {
      await fetchPurchaseOrderData();
      setTimeout(() => document.getElementById(`${createdId}:qty`)?.focus(), 200);
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };

  return (
    <>
      <TableWrapper>
        <SelectorWrapper>
          <SelectSingle
            name={orderId}
            defaultValue={createOption(localOrderDetail?.taxBase)}
            label={''}
            selectOptions={createOptions(taxBases)}
            onConfirm={onConfirmSelect}
            isDisabled={isDisabled}
          />
        </SelectorWrapper>
        <DynamicTable
          head={getHeads(
            ModulingService.filterListByModule({
              list: PURCHASE_ORDER_ITEM_TABLE_COLUMNS,
              // targetIndexs: [2],
              checkedModule: ModuleCode.PURCHASE_AO,
              companyModules: user?.companyModules || [],
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              filterFn: (cell: any, index: number) => index !== 2,
            }),
            'poItems',
          )}
          rows={getRows()}
          testId={'purchase-item-list-table'}
        />
      </TableWrapper>

      <FlexContainer className={'space-between flex-start'}>
        {isDisabled ? (
          <div />
        ) : (
          <>
            <AddOrderItem order={order} onSelect={onSelect} isCustomer={false}>
              {/* <SpaceHorizontalContainer className={'half'} /> */}
              <ModuleEnabledWrapper checkedModule={ModuleCode.PURCHASE_AO}>
                <CreateAganistAO
                  POId={orderId}
                  onCreateCallback={afterCreate}
                  supplierId={localOrderDetail?.supplierId}
                />
              </ModuleEnabledWrapper>
            </AddOrderItem>
          </>
        )}
        <TotalPrice
          subTotal={localOrderDetail?.subTotal}
          totalAmt={localOrderDetail?.totalAmt}
          taxAmt={localOrderDetail?.taxAmt}
        />
      </FlexContainer>
    </>
  );
};

export default PurchaseOrderItem;
