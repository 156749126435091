import React from 'react';
import LinkBtnUrl from '../../../shared/buttons/LinkBtnUrl';
import MoreIconPopup from '../../../shared/popup/MoreIconPopup';
import PopupContent from '../../../shared/popup/GeneralPopupContent';
import StatusLozenge from '../../../shared/statusComponent/StatusLozenge';
import iEntityCategory from '../../../types/status/iEntityCategory';
import CreatedOrUpdated from '../../../shared/createdOrUpdated/CreatedOrUpdated';
import { iConfigColumn, iParams } from '../../../shared/UITypes/types';
import {
  assembleAddress,
  formatDate,
  getCategoryCode,
  handleMoney,
  handleNullException,
} from '../../../services/UtilsService';
import iStockTake from '../../../types/stockTake/iStockTake';

export const getRows = (
  data: Array<iStockTake>,
  onOpenDeleteModal: (id: string) => void,
  columns: Array<iConfigColumn>,
  categories: Array<iEntityCategory>,
  idNameMap?: iParams,
) => {
  return data.map((item: iStockTake) => {
    const cells = columns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'datetime':
          return {
            key: handleNullException(item, key),
            content: formatDate(
              handleNullException(item, key),
              'Do MMMM  YYYY, h:mm a',
            ),
          };
        case 'money':
          return {
            key: handleNullException(item, key),
            content: handleMoney(item, key, 2),
          };
        case 'address':
          return {
            key: handleNullException(item, key),
            content: assembleAddress(handleNullException(item, key)),
          };
        case 'link':
          return {
            key: handleNullException(item, key),
            content: (
              <LinkBtnUrl
                btnName={handleNullException(item, key)}
                url={`${column.urlPrefix}/${handleNullException(
                  item,
                  dataSource[0],
                )}`}
              />
            ),
          };
        case 'status':
          return {
            key: handleNullException(item, `${key}.id`),
            content: (
              <StatusLozenge
                status={handleNullException(item, key)}
                categoryCode={getCategoryCode(
                  handleNullException(item, key),
                  categories,
                )}
              />
            ),
          };
        case 'history':
          return {
            key: handleNullException(item, key),
            content: (
              <CreatedOrUpdated
                operatedAt={handleNullException(item, key)}
                operator={handleNullException(item, dataSource[0])}
              />
            ),
          };
        case 'text-idNameMap':
          return {
            key: handleNullException(item, key),
            content: handleNullException(
              idNameMap,
              handleNullException(item, key),
            ),
          };
        case 'operation':
          return {
            content: (
              <MoreIconPopup>
                <PopupContent
                  id={item.id}
                  onOpenDeleteModal={onOpenDeleteModal}
                />
              </MoreIconPopup>
            ),
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};
