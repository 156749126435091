import React, { useEffect, useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Grid, GridColumn } from '@atlaskit/page';

import Spinner from '@atlaskit/spinner';
import iContactCompany from '../../../types/contactCompany/iContactCompany';
import iContactCompanyAddress from '../../../types/contactCompany/iContactCompanyAddress';
import { apiErrorToast } from '../../../shared/toast/Toast';
import { getContactCompany } from '../../../services/ContactCompanyService';
import { getCompanyAddressList } from '../../../services/ContactCompanyAddressService';
import BasicModalBody from '../../../shared/asyncContactCompany/createModalBody/BasicModalBody';
import AddressSelectWithController from '../../../shared/form/hookForms/CompanyAddressSelectorWithController';
import { handleNullException } from '../../../services/UtilsService';

type iDnModalBody = {
  //    eslint-disable-next-line
  control: Control<Record<string, any>>;
  //    eslint-disable-next-line
  onChange: (name: string, value: any, config?: Object) => void;
  //    eslint-disable-next-line
  errors?: DeepMap<Record<string, any>, FieldError>;
  watch: (names?: string | string[]) => string;
  isCustomer: boolean;
  searchServiceProvider?: boolean;
};
const DnModalBody = ({ control, errors, onChange, watch, isCustomer, searchServiceProvider = false }: iDnModalBody) => {
  const contactCompanyId = watch('customerId');

  const [isLoading, setIsLoading] = useState(false);
  const [localContactCompany, setLocalContactCompany] = useState<iContactCompany>();
  const [localCompanyAddressList, setLocalCompanyAddressList] = useState([]);

  useEffect(() => {
    if (!contactCompanyId) {
      // setLocalContactCompany(undefined);
      return;
    }
    let isCanceled = false;
    setIsLoading(true);
    Promise.all([
      getContactCompany(contactCompanyId),
      getCompanyAddressList({
        filter: `contactCompanyId:${contactCompanyId}`,
      }),
    ])
      .then(resp => {
        if (isCanceled === true) return;
        const [contactCompany, { data }] = resp;
        setLocalContactCompany(contactCompany);
        setLocalCompanyAddressList(data);
      })
      .catch(err => {
        if (isCanceled === true) return;
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled === true) return;
        setIsLoading(false);
      });
    // eslint-disable-next-line consistent-return
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isCanceled = true;
    };
  }, [contactCompanyId]);

  const getOptions = () => {
    if (localCompanyAddressList.length === 0 || !localContactCompany) return [];
    if (localContactCompany.shippingAddressId === localContactCompany.billingAddressId) {
      return localCompanyAddressList.map((item: iContactCompanyAddress) => ({
        label: item.id,
        value: item,
      }));
    }

    // avoid use billing address for shipping
    return localCompanyAddressList
      .filter((item: iContactCompanyAddress) => item.addressId !== localContactCompany.billingAddressId)
      .map((item: iContactCompanyAddress) => ({
        label: item.id,
        value: item,
      }));
  };

  return (
    <BasicModalBody
      control={control}
      errors={errors}
      onChange={onChange}
      watch={watch}
      isCustomer={isCustomer}
      verticalSpaceBeforeSelect={'eight'}
      searchServiceProvider={searchServiceProvider}
    >
      {isLoading === true ? (
        <Spinner />
      ) : (
        <Grid spacing={'compact'}>
          <GridColumn medium={12}>
            <AddressSelectWithController
              name={'shippingAddressId'}
              label={'Shipping Address'}
              control={control}
              defaultValue={
                contactCompanyId ? handleNullException(localContactCompany, 'shippingAddressId') : undefined
              }
              options={getOptions()}
              onChange={onChange}
              errors={errors}
              isRequired
              isClearable={false}
              customizedStyle={{ menu: () => ({ overflowY: 'visible' }) }}
            />
          </GridColumn>
        </Grid>
      )}
    </BasicModalBody>
  );
};

export default DnModalBody;
