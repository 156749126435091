import { iConfigColumn } from '../../../UITypes/types';
import { PRODUCTS_URL } from '../../../UrlMap';

export const TABLE_NAME = 're-associate-table';
export const RE_ASSOCIATE_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Product',
    key: 'product.productCode',
    dataSource: ['product.id', 'product.name'],
    isCompulsory: true,
    type: 'link-secondary',
    urlPrefix: PRODUCTS_URL,
    group: '',
  },
  {
    name: 'Quantity',
    key: 'qty',
    dataSource: [
      'unitConversion',
      'measurement.shortName',
      'product.measurement.shortName',
    ],
    type: 'conversion-text',
    isSelected: true,
    group: '',
  },
  {
    name: 'Unit Price',
    key: 'unitPrice',
    dataSource: [
      'product.measurement.shortName',
      'unitPriceMeasurement.shortName',
    ],
    type: 'unit-price',
    isSelected: true,
    group: '',
  },
  {
    name: 'Total(Inc GST)',
    key: 'totalPrice',
    dataSource: [],
    type: 'money',
    isSelected: true,
    group: '',
  },
  {
    name: '',
    key: 'operation',
    dataSource: ['salesOrder.shippingAddress'],
    type: 'operation',
    isCompulsory: true,
    group: '',
  },
];
